import React from 'react';
import { Link } from 'react-router-dom';

import config from '~/config';
import Helpers from '~/utils/Helpers';
import { SOURCECLEAR_COMPONENTS } from '~/constants/ModelConstants';
import VeracodeLogo from '~/images/sca-veracode-logo-white.png';

interface LoggedOutHeaderProps {
  isOrgSlugInvalid: boolean;
}

function LoggedOutHeader(props: LoggedOutHeaderProps) {
  const orgSlug = Helpers.getOrgSlug();
  const { isOrgSlugInvalid } = props;

  return (
    <section className="logged-out container--full bg-color--black-dark zIndex-7--nav">
      <section className="container flex align-items--center phone--flex--flex-direction--column phone--no-center overflow--hidden">
        <div className="col-2-10 phone--col-1-1 pb-- flex align-items--center height--78">
          {isOrgSlugInvalid || orgSlug === 'app' ? (
            <a
              className="link--plain link--no-underline phone--col-9-10"
              href={orgSlug === 'app' ? config.WWW_URL : config.FRONTEND_HOST_URL}
            >
              <img src={VeracodeLogo} alt="Veracode" className="vc-logo" />
            </a>
          ) : (
            <Link className="link--plain link--no-underline phone--col-9-10" to={`/`}>
              <img src={VeracodeLogo} alt="Veracode" className="vc-logo" />
            </Link>
          )}
          <a className="toggle text--right desk--hide phone--col-1-10">
            <i className="fas fa-bars color--white font--20" />
          </a>
        </div>
        <div className="col-8-10 phone--col-1-1 flex justify-content--end align-items--center phone--flex--flex-direction--column phone--pb top--nav">
          <li className="color--black-dark navbar__item">
            <a
              className="btn btn--nav-default font--14 font-family--space-mono-bold"
              href={`${config.VC_REGISTRY_URL}/${SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.slug}/search`}
            >
              {SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.name.toUpperCase()}
            </a>
          </li>
          <li className="color--black-dark navbar__item color--white">
            <span className="font--14 font-family--space-mono-bold">|</span>
          </li>
          <li className="color---dark navbar__item">
            {isOrgSlugInvalid ? (
              <a
                className="btn btn--nav-default font--14 font-family--space-mono-bold"
                href={`${config.FRONTEND_HOST_URL}/login`}
              >
                LOGIN
              </a>
            ) : (
              <Link
                className="btn btn--nav-default font--14 font-family--space-mono-bold"
                to={`/login`}
              >
                LOGIN
              </Link>
            )}
          </li>
        </div>
      </section>
    </section>
  );
}

export default LoggedOutHeader;
