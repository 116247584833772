import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PopoverWithEscKey from '~/components/PopoverWithEscKey';

import * as projectSettingsActions from '~/actions/projectSettings';
import * as projectBranchActions from '~/actions/projectBranch';
import * as popoverActions from '~/actions/popover';
import * as repoActions from '~/actions/repo';
import { ProjectBranchState } from '~/reducers/projectBranchState.types';

interface ProjectSettingsDeleteProjectSectionProps extends RouteComponentProps {
  projectId: string;
  projectBranchState: ProjectBranchState;
  repoDataById: object;
  popoverState: object;
  messageState: object;
}
class ProjectSettingsDeleteProjectSection extends React.Component<
  ProjectSettingsDeleteProjectSectionProps & ReturnType<typeof mapDispatchToProps>
> {
  saveDefaultBranchSettings() {
    const { projectId, projectBranchState = {} } = this.props;
    const { selectedDefaultBranch } = projectBranchState;

    this.props.projectBranchActions.updateDefaultBranch(projectId, selectedDefaultBranch);
  }

  deleteRepository(repo) {
    const { history, match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    this.props.repoActions.deleteRepository(repo).then(res => {
      if (res.success) {
        history.push(`/workspaces/${teamId}/projects`);
      }
    });
  }

  toggleDeleteClosed = () => {
    const { projectId } = this.props;
    const deletePopoverId = `project-delete-${projectId}`;
    this.props.popoverActions.closePopover(deletePopoverId);
  };

  toggleDeleteOpen = () => {
    const { projectId } = this.props;
    const deletePopoverId = `project-delete-${projectId}`;
    this.props.popoverActions.openPopover(deletePopoverId);
  };

  render() {
    const { projectId, repoDataById = {}, popoverState = {}, messageState = {} } = this.props;

    const { [projectId]: projectData = {} } = repoDataById;
    const { name: projectName = '' } = projectData;
    const deletePopoverId = `project-delete-${projectId}`;
    const isDeleteOpen = popoverState[deletePopoverId] || false;
    const { messages = {} } = messageState;
    const deleteRepoError = messages.DELETE_REPO_FAILURE;

    const deleteConfirmContent = (
      <div className="grid width--400">
        <div className="grid__item col-1-1 flex flex--justify-content--space-between">
          <div className="font--h6 text--bold">Are you sure?</div>
          <div>
            <i className="sci sci__close sci--sm" onClick={() => this.toggleDeleteClosed()} />
          </div>
        </div>
        <div className="grid__item col-1-1 mt- font--h7">
          Deleting a project <span className="text--bold">cannot</span> be undone. Data for all
          scans, branches, and tags for <span className="text--bold">{projectData.name}</span> will
          be deleted for yourself and every member of your team.
        </div>
        <div className="grid__item col-1-2 mt-">
          <button
            className="col-1-1 btn--danger font--h7 pv--"
            onClick={() => this.deleteRepository(projectData)}
          >
            Confirm
          </button>
        </div>
        <div className="grid__item col-1-2 mt-">
          <button
            className="col-1-1 btn--default--clear font--h7 pv--"
            onClick={() => this.toggleDeleteClosed()}
          >
            Cancel
          </button>
        </div>
        <div
          className={
            'grid__item col-1-1 color--danger mt- text--center' +
            (deleteRepoError ? ' is-showing-50' : ' is-hiding')
          }
        >
          Insufficent privileges to delete <span className="text--bold">{projectData.name}</span>.
          Please ask a team admin to delete.
        </div>
      </div>
    );

    return (
      <div>
        <h6 data-automation-id="ProjectSettingsDeleteProjectSection-Title" className="text--uppercase bo-b--1 border-color--muted-light pb---">Delete Project</h6>
        <p>
          Deleting a project is permanent and cannot be undone. When you delete a project, data for
          all scans, branches, and tags for <span className="text--bold">{projectName}</span> will
          be permanently deleted. Rescanning a repository of the same name in the future will create
          a new project and will not restore prior scans from this project.
        </p>
        <PopoverWithEscKey
          isOpen={isDeleteOpen}
          body={deleteConfirmContent}
          className="zIndex-9--dialog"
          preferPlace="bottom"
          onOuterAction={() => this.toggleDeleteClosed()}
        >
          <button
            className="btn btn--danger ph pv- flex align-items--baseline cursor--pointer color--muted"
            onClick={isDeleteOpen ? this.toggleDeleteClosed : this.toggleDeleteOpen}
            title="Delete project"
          >
            Delete project
          </button>
        </PopoverWithEscKey>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projectBranchState: state.projectBranchState,
    repoDataById: state.repoDataById,
    popoverState: state.popoverState,
    messageState: state.messageState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    projectBranchActions: bindActionCreators(projectBranchActions, dispatch),
    projectSettingsActions: bindActionCreators(projectSettingsActions, dispatch),
    repoActions: bindActionCreators(repoActions, dispatch),
    popoverActions: bindActionCreators(popoverActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectSettingsDeleteProjectSection)
);
