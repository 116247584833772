import React from 'react';
import { AVAILABLE_REGIONS } from '~/constants/ModelConstants';

const RegionHelpers = function () {
    
  const isRegionValid = (region: string) => {
    if (!region) return false; // Fail fast when undefined, null or empty string...

    return AVAILABLE_REGIONS.includes(region.toUpperCase());
  };

  const getRegionEnvVarInstruction = (region: string) => {
    if (isRegionValid(region)) {
      // JSX handles the elements (those enclosed in tags).
      return (
        <>
          , and set the <code>SRCCLR_REGION</code> environment variable to {region.toUpperCase()}
        </>
      );
    }

    return '';
  };

  return {
    getRegionEnvVarInstruction,
  };
};
export default RegionHelpers();
