import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Switch, matchPath, RouteComponentProps } from 'react-router-dom';
import { renderRoutesInPath } from '~/utils/RouterUtils';
import WorkspaceScanDateDropdown from '~/containers/WorkspaceScanDateDropdown';
import * as agentActions from '~/actions/agents';
import * as teamGroupsActions from '~/actions/teamGroups';
import * as reportScopeActions from '~/actions/reportScope';
import * as reportsActions from '~/actions/reports';
import * as reportFiltersActions from '~/actions/reportFilters';
import * as statsActions from '~/actions/stats';
import * as teamActions from '~/actions/team';
import * as integrationActions from '~/actions/integration';
import * as navigationActions from '~/actions/navigation';
import Helpers from '~/utils/Helpers';
import { RootState } from '~/reducers';

interface WorkspaceProps extends RouteComponentProps {
  routes: any[];
  navigationState: object;
  orgState: App.OrgState;
  reportScope: object;
  teamState: App.TeamState;
}

class Workspace extends Component<WorkspaceProps & ReturnType<typeof mapDispatchToProps>> {
  componentDidMount() {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    this.checkForTeamAccess(teamId);
    this.fetchTeamData();
    this.props.integrationActions.fetchIntegrations();
    this.props.agentActions.fetchAgentReleases();
  }

  // refresh and fetch data when team id changes via team id route param or workspace switcher change
  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId: newTeamId } = params;
    const { teamId: oldTeamId } = prevProps.match.params;

    if (oldTeamId !== newTeamId) {
      this.checkForTeamAccess(newTeamId);
      this.props.statsActions.resetStatsData();
      this.fetchTeamData(newTeamId);
    }
  }

  componentWillUnmount() {
    this.props.reportsActions.resetReportState();
  }

  checkForTeamAccess(teamId) {
    const { teamState, history } = this.props;
    const { teams = [] } = teamState;
    const hasAccess = teams.some(team => team.id === teamId);

    if (!hasAccess) {
      history.replace('/no-access');
    }
  }

  fetchTeamData(newTeamId?: string) {
    const { match, teamState } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    const { teams } = teamState;
    const activeTeam = teams.find(team => team.id === teamId);

    const hasManageGroupsPermission = activeTeam?.permissions?.manageGroups;

    this.fetchStats(newTeamId);

    if (hasManageGroupsPermission) {
      this.props.teamGroupsActions.fetchTeamGroups(newTeamId || teamId);
    }
    this.props.teamActions.fetchIssueData(newTeamId || teamId);
    this.props.navigationActions.updateActiveTeamParent(newTeamId || teamId);
  }

  fetchStats = (newTeamId?: string) => {
    const { match, teamState } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    const { teams } = teamState;
    const activeTeam = teams.find(team => team.id === teamId);
    const hasReportsPermission = activeTeam?.permissions?.reports;

    if (hasReportsPermission) {
      this.props.statsActions.fetchWorkspaceStats(newTeamId || teamId);
    }
  };

  updateReportScope(field, value) {
    const { match, navigationState } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    const { activeReportType } = navigationState;

    this.props.reportScopeActions.updateReportScope(field, value);
    this.fetchStats();
    if (activeReportType) {
      this.props.reportsActions.fetchReport(teamId, activeReportType);
    }
  }

  render() {
    const { navigationState, match, reportScope, routes, location } = this.props;
    const { activeReportType } = navigationState;
    const onlyShowFavorites = reportScope.favoriteReposOnly;
    const { pathname } = location;

    const matchProfile =
      matchPath(pathname, {
        path: `/workspaces/:teamId/projects/:projectId`,
      }) || {};

    const { params = {} } = matchProfile;
    const { projectId } = params;

    return (
      <>
        {activeReportType && !projectId && (
          <div className="col-1-1 flex flex--content">
            <div className="col-1-1">
              <div className="grid min-height--50">
                <div className="grid__item col-1-1 mt mb- flex align-items--center">
                  <div className="font--h7 mr inline-block">
                    Projects:
                    <span
                      data-e2e="report-repos-all"
                      className={`cursor--pointer ${
                        !onlyShowFavorites ? 'text--bold' : 'color--muted'
                      }`}
                      onClick={() => this.updateReportScope('favoriteReposOnly', false)}
                    >
                      {' '}
                      All{' '}
                    </span>
                    |
                    <span
                      data-e2e="report-repos-starred"
                      className={`cursor--pointer ${
                        onlyShowFavorites ? 'text--bold' : 'color--muted'
                      }`}
                      onClick={() => this.updateReportScope('favoriteReposOnly', true)}
                    >
                      {' '}
                      Starred{' '}
                    </span>
                  </div>
                  {Helpers.hasScanDateEnabled() && (
                    <div className="grid">
                      <div
                        className="grid__item col-1-1 flex align-items--center"
                        data-automation-id="Workspace--scanDateFilterAndLabel"
                      >
                        <span className="font--h7">Scan Date:</span>
                        <div className="width--200 pl-">
                          <WorkspaceScanDateDropdown />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <Switch children={renderRoutesInPath(routes, match.path)} />
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    navigationState: state.navigationState,
    orgState: state.orgState,
    reportScope: state.reportScope,
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    agentActions: bindActionCreators(agentActions as any, dispatch),
    navigationActions: bindActionCreators(navigationActions as any, dispatch),
    teamGroupsActions: bindActionCreators(teamGroupsActions as any, dispatch),
    reportScopeActions: bindActionCreators(reportScopeActions as any, dispatch),
    statsActions: bindActionCreators(statsActions as any, dispatch),
    teamActions: bindActionCreators(teamActions as any, dispatch),
    reportFiltersActions: bindActionCreators(reportFiltersActions as any, dispatch),
    reportsActions: bindActionCreators(reportsActions as any, dispatch),
    integrationActions: bindActionCreators(integrationActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);
