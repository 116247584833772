import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';

import * as teamActions from '~/actions/team';
import SourceClearModal from '~/components/SourceClearModal';
import { RouteComponentProps } from 'react-router-dom';

interface MatchParams {
  teamId?: string;
}

interface TeamActions {
  saveTeamName: (teamId: string, teamNameInputValue: string) => {};
  updateWorkspaceName: (value: string) => {};
  fetchTeams: () => {};
  deleteTeam: (teamId: string) => Promise<{ success: boolean }>;
  removeTeamFromIssueSettingsData: (teamId: string) => {};
  showModal: (modelType: string) => string;
}

interface SettingsProfilePageProps extends RouteComponentProps<MatchParams> {
  teamActions: TeamActions;
  teamState: {
    nameWillBe: string;
    showModal: string;
    teams: [];
    validWorkspaceName: boolean;
  };
  myState: object;
  validWorkspaceName?: boolean;
}

export function SettingsProfilePage(props: SettingsProfilePageProps) {
  let teamNameInput = useRef(null);
  const teamState = useSelector((state: any) => {
    const { teamState } = state;
    return teamState;
  });
  const dispatch = useDispatch();
  const { teamActions } = settingsProfilePageActions(dispatch);

  useEffect(() => {
    const { match } = props;
    const { params = {} as MatchParams } = match;
    const { teamId } = params;
    const { teams = [] } = teamState;
    const activeTeam = teams.find(team => team.id === teamId) || {};
    if (teamNameInput.current) {
      teamNameInput.current.value = activeTeam.name || '';
    }
  }, []);

  const saveTeamName = e => {
    e.preventDefault();
    const { match } = props;
    const { params = {} } = match;
    const { teamId } = params;
    teamActions.saveTeamName(teamId, teamNameInput.current.value);
  };

  const updateWorkspaceName = e => {
    const { target = {} } = e;
    const { value = '' } = target;
    teamActions.updateWorkspaceName(value);
  };

  function deleteTeam(e) {
    e.preventDefault();
    const { history, match } = props;

    const { params = {} } = match;
    const { teamId } = params;

    const redirectUrl = '/portfolio';

    teamActions.deleteTeam(teamId).then(res => {
      if (res.success) {
        teamActions.fetchTeams();
        teamActions.removeTeamFromIssueSettingsData(teamId);
        history.push(redirectUrl);
      }
    });
  }

  const showSettingsModal = modalType => {
    teamActions.showModal(modalType);
  };

  const { match } = props;

  const { params = {} } = match;
  const { teamId } = params;
  const { teams = [], showModal, nameWillBe = '', validWorkspaceName = true } = teamState;

  const activeTeam = teams.find(team => team.id === teamId) || {};
  const { permissions = {} } = activeTeam;
  const { update, delete: deleteTeams } = permissions;

  const shouldDisableButton = nameWillBe.length < 1 || activeTeam.name === nameWillBe;

  return (
    <section className="grid mt mb++">
      <Helmet>
        <title>Workspace Settings</title>
      </Helmet>
      <div className="grid__item col-1-1">
        <h3 data-automation-id="SettingsProfilePage-Title">Workspace Settings</h3>
      </div>
      <div className="grid__item col-1-2 grid mt">
        <div className="grid__item col-1-1">
          <form onSubmit={e => saveTeamName(e)}>
            <div className="flex align-items--center">
              <label className="color--muted-dark font--h7 mb0" htmlFor="team-name">
                Workspace Name
              </label>
              <input
                id="team-name"
                name="team-name"
                type="text"
                className="ml control--text"
                disabled={!update}
                ref={ref => (teamNameInput.current = ref)}
                onChange={e => updateWorkspaceName(e)}
              />
            </div>
            <div className="grid__item col-1-1 text--right mt-">
              <button
                type="submit"
                disabled={!update || shouldDisableButton}
                className="ph pv- btn btn--success font-family--body-light font--h7"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        <div className="grid__item col-1-1 mt">
          <div className="bo-b--1 border-color--muted-light col-1-1" />
        </div>
        <div className="grid__item col-1-1">
          {deleteTeams && (
            <div className="grid">
              <div className="grid__item col-1-1 mt flex justify-content--space-between align-items--center">
                <div>This action cannot be undone.</div>
                <div
                  className="btn btn--danger-clear pv- ph text--right"
                  onClick={() => showSettingsModal('DELETE_TEAM')}
                  data-automation-id="SettingsProfilePage-deleteTeamModal"
                >
                  Delete Workspace
                </div>
                <SourceClearModal
                  isOpen={showModal === 'DELETE_TEAM'}
                  onClose={() => showSettingsModal('')}
                  title="Delete Workspace"
                  width={400}
                >
                  <form className="grid" onSubmit={e => deleteTeam(e)}>
                    <div className="grid__item col-1-1">
                      This will permanently delete the workspace. Are you sure?
                    </div>
                    <div className="grid__item col-1-1 flex justify-content--end mt">
                      <button
                        className="col-1-5 pv-"
                        type="button"
                        onClick={() => showSettingsModal('')}
                      >
                        Cancel
                      </button>
                      <button className="btn--danger ph pv- ml-" type="submit">
                        Yes, Delete
                      </button>
                    </div>
                  </form>
                </SourceClearModal>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

function settingsProfilePageActions(dispatch) {
  return {
    teamActions: bindActionCreators(teamActions as any, dispatch),
  };
}

export default SettingsProfilePage;
