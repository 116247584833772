import React from 'react';
import errorImage from '~/images/Opps_error.svg';

function NotFoundPage() {
  return (
    <div className="container pt+++">
      <div className="pageNotFound">
        <img className="image" src={errorImage} alt="Veracode" />
        <div className="text--center text1">Page not found</div>
        <div className="text--center text2">
          It either does not exist or you do not have permission to view it.
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
