import React from 'react';
import { connect } from 'react-redux';

import { RouteComponentProps, withRouter } from 'react-router-dom';
interface HomePageProps extends RouteComponentProps {
  myState: object;
}
class HomePage extends React.Component<HomePageProps, {}> {
  componentDidMount() {
    const { myState, history } = this.props;
    const { fetchMeResolved } = myState;

    if (fetchMeResolved) {
      history.replace(`/portfolio`);
    } else {
      // user is logged out - send to `/signup`
      history.replace(`/signup`);
    }
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    myState: state.myState,
  };
}

export default withRouter(connect(mapStateToProps)(HomePage));
