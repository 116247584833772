import produce from 'immer';
import { TeamPolicyState, UnifiedPoliciesState } from '~/reducers/unifiedPoliciesState.types';
import {
  CLEAR_SAVED_FLAG_UNIFIED_POLICY,
  FETCH_UNIFIED_ASSIGNED_POLICY,
  FETCH_UNIFIED_ASSIGNED_POLICY_FAILURE,
  FETCH_UNIFIED_ASSIGNED_POLICY_SUCCESS,
  FETCH_UNIFIED_ASSIGNED_POLICY_SYNC_FAILURE,
  FETCH_UNIFIED_POLICIES,
  FETCH_UNIFIED_POLICIES_FAILURE,
  FETCH_UNIFIED_POLICIES_SUCCESS,
  SAVE_UNIFIED_POLICY_ASSIGNMENT,
  SAVE_UNIFIED_POLICY_ASSIGNMENT_FAILURE,
  SAVE_UNIFIED_POLICY_ASSIGNMENT_SUCCESS,
  UnifiedPoliciesAction,
} from '~/actions/unifiedPolicies';

const defaultState: UnifiedPoliciesState = {
  policies: [],
  isFetchingPolicies: false,
  teamPolicies: {},
  isFetchPoliciesFailed: false,
};

const defaultTeamPolicyState: TeamPolicyState = {
  assignedPolicy: null,
  isFetchingAssignedPolicy: false,
  isFetchAssignedPolicyFailed: false,
  isSyncFailed: false,
  isSaved: false,
  isSaveFailed: false,
  isSaving: false,
};

const unifiedPoliciesState = (state = defaultState, action: UnifiedPoliciesAction) =>
  produce(state, (draft: UnifiedPoliciesState) => {
    const getNewTeamState = (newState: Partial<TeamPolicyState>) => ({
      ...defaultTeamPolicyState,
      ...('teamId' in action ? state.teamPolicies[action.teamId] : undefined),
      ...newState,
    });
    switch (action.type) {
      case FETCH_UNIFIED_POLICIES:
        draft.isFetchingPolicies = true;
        draft.isFetchPoliciesFailed = false;
        break;
      case FETCH_UNIFIED_ASSIGNED_POLICY:
        draft.teamPolicies[action.teamId] = getNewTeamState({
          isFetchingAssignedPolicy: true,
        });
        break;
      case FETCH_UNIFIED_ASSIGNED_POLICY_FAILURE:
        draft.teamPolicies[action.teamId] = getNewTeamState({
          isFetchingAssignedPolicy: false,
          isFetchAssignedPolicyFailed: true,
        });
        break;
      case FETCH_UNIFIED_ASSIGNED_POLICY_SYNC_FAILURE:
        draft.teamPolicies[action.teamId] = getNewTeamState({
          isFetchingAssignedPolicy: false,
          isSyncFailed: true,
        });
        break;
      case FETCH_UNIFIED_POLICIES_SUCCESS:
        draft.policies = action.policies;
        draft.isFetchingPolicies = false;
        break;
      case FETCH_UNIFIED_ASSIGNED_POLICY_SUCCESS:
        draft.teamPolicies[action.payload.teamId] = getNewTeamState({
          assignedPolicy: action.payload.assignedPolicy,
          isFetchingAssignedPolicy: false,
        });
        break;
      case FETCH_UNIFIED_POLICIES_FAILURE:
        draft.isFetchingPolicies = false;
        draft.isFetchPoliciesFailed = true;
        break;
      case SAVE_UNIFIED_POLICY_ASSIGNMENT:
        draft.teamPolicies[action.teamId] = getNewTeamState({
          isSaving: true,
          isSaveFailed: false,
        });
        break;
      case SAVE_UNIFIED_POLICY_ASSIGNMENT_SUCCESS:
        draft.teamPolicies[action.payload.teamId] = getNewTeamState({
          assignedPolicy: action.payload.assignedPolicy,
          isSaving: false,
          isSaved: true,
        });
        break;
      case SAVE_UNIFIED_POLICY_ASSIGNMENT_FAILURE:
        draft.teamPolicies[action.teamId] = getNewTeamState({
          isSaving: false,
          isSaveFailed: true,
        });
        break;
      case CLEAR_SAVED_FLAG_UNIFIED_POLICY:
        draft.teamPolicies[action.teamId] = getNewTeamState({
          isSaved: false,
          isSaveFailed: false,
        });
        break;
    }
  });

export default unifiedPoliciesState;
