import React, { Component } from 'react';
import ErrorService from '~/utils/ErrorService';
import GenericError from '~/components/Errors/GenericError';

interface ErrorBoundaryProps {
  redirectMessage?: string;
  redirectUrl?: string;
}

interface ErrorBoundaryState {
  error: any | null;
  errorInfo: any | null;
  errorId: string | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      errorId: null,
    };
  }
  componentDidCatch(error, errorInfo) {
    ErrorService.capture(error, errorInfo);
    const errorId = ErrorService.lastEventId();
    this.setState({
      error,
      errorInfo,
      errorId,
    });
  }
  render() {
    const errorMessage = 'An error has occurred';
    const contactInfo = (
      <div>
        Please try again or
        <a
          href="mailto:support@veracode.com?subject=Request For Veracode SCA Support"
          className="link--obvious"
        >
          {' '}
          contact support
        </a>{' '}
        with the error code below if the issue persists.
      </div>
    );
    const { errorInfo } = this.state;
    if (errorInfo) {
      return (
        <div
          className="container flex align-items--center justify-content--center height--75vh"
          data-test="ErrorBoundary-error-info"
        >
          <GenericError
            errorMessage={errorMessage}
            ctaLinkText={this.props.redirectMessage}
            ctaLinkUrl={this.props.redirectUrl}
            errorId={this.state.errorId}
          >
            {contactInfo}
          </GenericError>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
