const ApiConstants = {
  agentReleasesURL: '/agent-releases/latest',

  billingAccountURL: (orgId: string) => {
    return `/orgs/${orgId}/billing/account`;
  },

  billingCatalogURL: '/billing/catalog',

  billingChangePlanURL: (orgId: string) => {
    return `/orgs/${orgId}/billing/change-plan`;
  },

  billingDashboardURL: (orgId: string) => {
    return `/orgs/${orgId}/billing/dashboard`;
  },

  billingOveragesURL: (orgId: string) => {
    return `/orgs/${orgId}/billing/overages`;
  },

  billingPaymentMethodURL: (orgId: string) => {
    return `/orgs/${orgId}/billing/payment-method`;
  },

  fetchAgentEventsURL: '/user/scans/events',

  fetchChangePlanDetailsURL: (orgId: string, productPlanId: string) => {
    return `/orgs/${orgId}/billing/change-plan?productPlanId=${productPlanId}`;
  },

  fetchGroupsURL: (orgId: string, org: App.Org) => {
    return `/orgs/${orgId || org.id}/groups`;
  },

  fetchInvoiceURL: (orgId: string, invoiceId: string) => {
    return `/orgs/${orgId}/billing/invoices/${invoiceId}`;
  },

  fetchOrgAgentsURL: (orgId: string) => {
    return `/orgs/${orgId}/agents`;
  },

  fetchOrgCommittersURL: (orgId: string) => {
    return `/orgs/${orgId}/committers/active`;
  },

  fetchOrgUsersURL: (org: App.Org, withGroups: boolean) => {
    return `/orgs/${org.id}/users${withGroups ? '?with=groups' : ''}`;
  },

  fetchTeamCommittersURL: (teamId: string) => {
    return `/teams/${teamId}/committers/active`;
  },

  fetchScanUsageUrl: (orgId: string) => {
    return `/orgs/${orgId}/usage`;
  },

  fetchTeamGroupsURL: (teamId: string) => {
    return `/teams/${teamId}/groups`;
  },

  fetchTeamUsersURL: (teamId: string) => {
    return `/teams/${teamId}/users`;
  },

  fetchWorkspaceInvitesURL: (teamId: string) => {
    return `/teams/${teamId}/invites`;
  },

  getIssuesDetails: (issueId: string) => {
    return `/repo-issues/${issueId}/details`;
  },

  getIssuesEvents: (issueId: string) => {
    return `/repo-issues/${issueId}/events`;
  },

  getIntegrationIssuesCreatedURL: (repoScanId: string, issueId: string) => {
    return `/repo-scans/${repoScanId}/issues/${issueId}/integrations`;
  },

  deleteIntegrationURL: (integrationId: string) => {
    return `/org/integrations/${integrationId}`;
  },

  fetchTicketTemplateURL: (integrationId: string, pageNum: number) => {
    return `/org/integrations/${integrationId}/jira/templates?pageNum=${pageNum}`;
  },

  createJiraIntegrationURL: () => {
    return `/org/integrations/setup/JIRA`;
  },

  createJiraPremIntegration: () => {
    return `/org/integrations/setup/JIRA_PREM`;
  },

  getJiraOAuthLinkURL: (setupId: string) => {
    return `/org/integrations/setup/${setupId}/auth`;
  },

  jiraIntegrationSetupCallbackURL: (
    setupId: string,
    oauth_token: string | string[],
    oauth_verifier: string | string[]
  ) => {
    return `/org/integrations/setup/${setupId}/callback?oAuthToken=${oauth_token}&oAuthVerifier=${oauth_verifier}`;
  },

  getJiraProjectDetailURL: (integrationId: string) => {
    return `/org/integrations/${integrationId}/jira/projects`;
  },

  getJiraProjectIssueTypesURL: (integrationId: string, projectKey: string) => {
    return `/org/integrations/${integrationId}/jira/projects/${projectKey}/issuetypes`;
  },

  // used to fetch both assignees and reporters. defaults to first 50 users only
  getJiraUsersURL: (integrationId: string, projectKey: string) => {
    return `/org/integrations/${integrationId}/jira/projects/${projectKey}/users`;
  },

  getJiraUsersByIdURL: (integrationId: string, projectKey: string, id: string) => {
    return `/org/integrations/${integrationId}/jira/projects/${projectKey}/users?userName=${encodeURIComponent(
      id
    )}`;
  },

  getJiraProjectFields: (integrationId: string, projectKey: string, issueTypeId: string) => {
    return `/org/integrations/${integrationId}/jira/projects/${projectKey}/issuetypes/${issueTypeId}/fields`;
  },

  getJiraEpicsURL: (integrationId: string, projectKey: string) => {
    return `/org/integrations/${integrationId}/jira/projects/${projectKey}/epics`;
  },

  getJiraLabelsURL: (integrationId: string, searchKey: string) => {
    return `/org/integrations/${integrationId}/jira/labels?searchKey=${encodeURIComponent(
      searchKey
    )}`;
  },

  // statuses and fields endpoints are conveniently provided by HATEOAS links so are not currently used
  // just saved here for reference
  getJiraStatusURL: (integrationId: string, projectKey: string, issueTypeId: string) => {
    return `/org/integrations/${integrationId}/jira/projects/${projectKey}/issuetypes/${issueTypeId}/statuses`;
  },

  getJiraFieldsURL: (integrationId: string, projectKey: string, issueTypeId: string) => {
    return `/org/integrations/${integrationId}/jira/projects/${projectKey}/issuetypes/${issueTypeId}/fields`;
  },

  jiraTemplateURL: (integrationId: string) => {
    return `/org/integrations/${integrationId}/jira/templates`;
  },

  updateJiraTemplateURL: (integrationId: string, templateId: string) => {
    return `/org/integrations/${integrationId}/jira/templates/${templateId}`;
  },

  createJiraTicketURL: (integrationId: string, selectedIssueId: string) => {
    return `/org/integrations/${integrationId}/jira/issues/${selectedIssueId}`;
  },

  getProjectBranchesURL: (projectId: string, searchTerm: string) => {
    return `/repos/${projectId}/branches?searchTerm=${searchTerm}&size=100`;
  },

  groupsByOrgURL: (orgId: string) => {
    return `/orgs/${orgId}/groups`;
  },

  groupsMultiUpdateURL: '/groups/multi-update',

  groupsMultiDeleteURL: '/groups/multi-delete',

  licenseURL: '/licenses',

  groupsURL: (groupId: string) => {
    return `/groups/${groupId}`;
  },

  integrationsURL: (pageNum: string) => {
    return `/org/integrations?pageNum=${pageNum}`;
  },

  membershipMultiDeleteURL: (membershipType: string) => {
    return `/group-${membershipType}/multi-delete`;
  },

  orgTeamsURL: (org: App.Org) => {
    return `/orgs/${org.id}/teams`;
  },

  pendingInvitesURL: (org: App.Org) => {
    return `/orgs/${org.id}/invites`;
  },

  pdfReportURL: (teamId: string) => {
    return `/teams/${teamId}/reports?type=pdf`;
  },

  updateDefaultBranchURL: (projectId: string) => {
    return `/repos/${projectId}/branches:default-branch`;
  },

  updateRepoNameUrl: (projectId: string) => {
    return `/repos/${projectId}`;
  },

  // new policies endpoints
  // see docs: https://wiki.veracode.local/pages/viewpage.action?spaceKey=SCA&title=Org-Level+Rules+-+Platform+Endpoints
  // org settings endpoints
  orgPolicySettingsUrl: orgId => {
    return `/orgs/${orgId}/policy-settings`;
  },
  // ws policy endpoints
  workspacePolicyURL: (workspaceId: string) => {
    return `/teams/${workspaceId}/policy`;
  },
  orgPolicyURL: (orgId: string) => {
    return `/orgs/${orgId}/policy`;
  },
  // policy controls endpoints
  orgPolicyRevisionsUrl: (orgId: string) => {
    return `/orgs/${orgId}/policy/revisions`;
  },

  workspacePolicyRevisionsUrl: (workspaceId: string) => {
    return `/teams/${workspaceId}/policy/revisions`;
  },

  defaultPoliciesUrl: () => {
    return 'policies/default';
  },

  //insights vulnerabilities endpoint
  insightsVulnerabilitiesUrl: (teamId: string) => {
    return `/teams/${teamId}/reports/insights/vulns`;
  },

  //insights issues endpoint
  insightsIssuesUrl: (teamId: string) => {
    return `/teams/${teamId}/reports/insights/issues`;
  },

  //insights projects endpoint
  insightsProjectsUrl: (teamId: string) => {
    return `/teams/${teamId}/reports/insights/projects`;
  },
};

export default ApiConstants;
