import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';

import * as toastrActions from '~/actions/toastr';

import ApiConstants from '../constants/ApiConstants';

export const FILTER_GROUPS_BY_INPUT = 'FILTER_GROUPS_BY_INPUT';
export const FILTER_GROUP_USERS_BY_INPUT = 'FILTER_GROUP_USERS_BY_INPUT';
export const FILTER_GROUP_WORKSPACES_BY_INPUT = 'FILTER_GROUP_WORKSPACES_BY_INPUT';
export const UPDATE_ACTIVE_TAB = 'UPDATE_ACTIVE_TAB';
export const RESET_GROUP_DATA = 'RESET_GROUP_DATA';
export const RESET_GROUP_DETAILS = 'RESET_GROUP_DETAILS';
export const RESET_GROUP_SORT = 'RESET_GROUP_SORT';
export const RESET_GROUP_QUICK_VIEWS = 'RESET_GROUP_QUICK_VIEWS';
export const RESET_SELECTED_GROUPS = 'RESET_SELECTED_GROUPS';
export const SET_GROUP_DATA_TO_ACTIVE_GROUP = 'SET_GROUP_DATA_TO_ACTIVE_GROUP';
export const SET_GROUP_DETAILS = 'SET_GROUP_DETAILS';
export const SHOW_GROUP_MODAL = 'SHOW_GROUP_MODAL';
export const TOGGLE_GROUP_ADMIN_FIELD = 'TOGGLE_GROUP_ADMIN_FIELD';
export const TOGGLE_GROUP_QUICK_VIEW = 'TOGGLE_GROUP_QUICK_VIEW';
export const TOGGLE_GROUP_USERS_ADMIN_FILTER = 'TOGGLE_GROUP_USERS_ADMIN_FILTER';
export const UPDATE_CREATE_GROUP_STAGE = 'UPDATE_CREATE_GROUP_STAGE';
export const UPDATE_GROUP_USERS_FILTER = 'UPDATE_GROUP_USERS_FILTER';
export const UPDATE_GROUP_TEAM_SORT = 'UPDATE_GROUP_TEAM_SORT';
export const UPDATE_GROUP_USER_SORT = 'UPDATE_GROUP_USER_SORT';
export const UPDATE_GROUPS = 'UPDATE_GROUPS';
export const UPDATE_GROUP_DATA = 'UPDATE_GROUP_DATA';
export const UPDATE_IS_FETCHING_GROUP_DETAILS = 'UPDATE_IS_FETCHING_GROUP_DETAILS';
export const UPDATE_ORG_GROUP_SORT = 'UPDATE_ORG_GROUP_SORT';
export const UPDATE_SELECTED_GROUP_USERS = 'UPDATE_SELECTED_GROUP_USERS';
export const UPDATE_SELECTED_GROUP_WORKSPACES = 'UPDATE_SELECTED_GROUP_WORKSPACES';
export const UPDATE_SELECTED_GROUPS = 'UPDATE_SELECTED_GROUPS';
export const FETCH_GROUPS_REQUEST = 'FETCH_GROUPS_REQUEST';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAILURE = 'FETCH_GROUPS_FAILURE';
export const UPDATE_GROUPS_REQUEST = 'UPDATE_GROUPS_REQUEST';
export const UPDATE_GROUPS_SUCCESS = 'UPDATE_GROUPS_SUCCESS';
export const UPDATE_GROUPS_FAILURE = 'UPDATE_GROUPS_FAILURE';

export const fetchGroupsRequest = () => ({
  type: FETCH_GROUPS_REQUEST,
});

export const fetchGroupsSuccess = () => ({
  type: FETCH_GROUPS_SUCCESS,
});

export const fetchGroupsFailure = () => ({
  type: FETCH_GROUPS_FAILURE,
});
export const updateGroupsRequest = () => ({
  type: UPDATE_GROUPS_REQUEST,
});

export const updateGroupsSuccess = () => ({
  type: UPDATE_GROUPS_SUCCESS,
});

export const updateGroupsFailure = () => ({
  type: UPDATE_GROUPS_FAILURE,
});

export const fetchGroups = orgId => (dispatch, getState) => {
  const { orgState } = getState();
  const { org = {} } = orgState;
  dispatch(fetchGroupsRequest());
  return ApiService.get(ApiConstants.fetchGroupsURL(orgId, org))
    .then(res => {
      dispatch(updateGroups(res));
      dispatch(fetchGroupsSuccess());
      return res;
    })
    .catch(error => {
      dispatch(updateGroups([]));
      dispatch(fetchGroupsFailure());
      ErrorService.capture('Error fetching groups', error);
      return [];
    });
};

export const fetchGroupDetails = group => dispatch => {
  dispatch(updateIsFetchingGroupDetails(true));
  const { numUsers, numTeams, _links = {} } = group;
  const { users = {}, teams = {} } = _links;
  if (!numUsers && !numTeams) {
    dispatch(setGroupDetails({ users: [], workspaces: [] }));
  } else {
    Promise.all([fetchGroupData(users.href), fetchGroupData(teams.href)]).then(res => {
      const [users, teams] = res;
      dispatch(setGroupDetails({ users, teams }));
    });
  }
};

export const fetchGroupData = href => {
  return ApiService.get(href)
    .then(res => {
      return res;
    })
    .catch(error => {
      ErrorService.capture('Error fetching group data', error);

      return [];
    });
};

export const updateIsFetchingGroupDetails = bool => ({
  type: UPDATE_IS_FETCHING_GROUP_DETAILS,
  bool,
});

/*
 * Team Multi-Update
 * Do-all-the-things endpoint for updating group user/workspace memberships
 * Can be used to add users/workspaces or edit admin/role status of current users/workspaces
 * groupRequests - Array of "requests", each one an object with the following shape:
 {
  groupId: NUMBER,
  users: [{userId: NUMBER, admin: BOOL}, ... ],
  teams: [{teamId: STRING, role: STRING('ADMIN' || 'USER')}, ... ],
 }
 */
export const groupMultiUpdate = groupRequests => (dispatch, getState) => {
  const { orgState } = getState();
  const { org = {} } = orgState;
  dispatch(updateGroupsRequest());
  return ApiService.post(ApiConstants.groupsMultiUpdateURL, { data: { data: groupRequests } })
    .then(res => {
      const editedGroup = res[0] || {};
      dispatch(showModal());
      dispatch(updateGroupsSuccess());
      dispatch(fetchGroups(org.id)).then(groups => {
        const activeGroup = groups.find(group => group.id === editedGroup.id) || {};

        dispatch(fetchGroupDetails(activeGroup));
      });

      return editedGroup;
    })
    .catch(error => {
      ErrorService.capture('Error with multi--update group request', error);
      dispatch(updateGroupsFailure());
      dispatch(
        toastrActions.addToastr({
          id: 'GROUP_MULT_ADD_ERROR',
          level: 'error',
          title: 'Error updating group',
          message:
            error.message ||
            'There was an error updating your group. Please check that all information is accurate and try again.',
        })
      );
      return {};
    });
};

export const deleteGroup = groupId => (dispatch, getState) => {
  const { orgState } = getState();
  const { org = {} } = orgState;

  return ApiService.del(ApiConstants.groupsURL(groupId))
    .then(() => {
      dispatch(showModal());
      dispatch(fetchGroups(org.id));
      return { success: true };
    })
    .catch(error => {
      ErrorService.capture('Error deleting group', error);
      dispatch(
        toastrActions.addToastr({
          id: 'GROUP_DELETE_ERROR',
          level: 'error',
          title: 'Error deleting group',
          message: error.message || 'There was an error deleting your group.',
        })
      );
      dispatch(showModal());
      return {};
    });
};

export const bulkGroupDelete = groupIds => (dispatch, getState) => {
  const { orgState } = getState();
  const { org = {} } = orgState;

  ApiService.post(ApiConstants.groupsMultiDeleteURL, { data: { data: groupIds } })
    .then(() => {
      dispatch(showModal());
      dispatch(resetSelectedGroups());
      dispatch(fetchGroups(org.id));
    })
    .catch(error => {
      ErrorService.capture('Error deleting group', error);
      dispatch(
        toastrActions.addToastr({
          id: 'BULK_GROUP_DELETE_ERROR',
          level: 'error',
          title: 'Error deleting group(s)',
          message: error.message || 'There was an error deleting your group(s).',
        })
      );
      dispatch(showModal());
    });
};

/*
 * remove memberships
 * utility method that removes teams or users from a group
 * membershipIds: ARRAY of NUMBERS
 * membershipType: STRING ('users' || 'teams')
 * groupId: STRING || NUMBER (optional)
 */
export const removeMemberships = (membershipIds, membershipType, groupId) => (
  dispatch,
  getState
) => {
  const { orgState } = getState();
  const { org = {} } = orgState;
  const prettyMap = {
    teams: 'workspaces',
    users: 'users',
  };

  return ApiService.post(ApiConstants.membershipMultiDeleteURL(membershipType), {
    data: membershipIds,
  })
    .then(() => {
      dispatch(fetchGroups(org.id)).then(groups => {
        if (groupId) {
          const activeGroup = groups.find(group => group.id === parseInt(groupId)) || {};

          dispatch(fetchGroupDetails(activeGroup));
        }
      });
      return { success: true };
    })
    .catch(error => {
      ErrorService.capture(`Error removing ${membershipType} from group`, error);
      dispatch(
        toastrActions.addToastr({
          id: `ERROR_REMOVING_${membershipType}`,
          level: 'error',
          title: `Error removing ${prettyMap[membershipType]}`,
          message: error.message || `There was an error removing the ${prettyMap[membershipType]}.`,
        })
      );
      return {};
    });
};

export const updateGroups = groups => ({
  type: UPDATE_GROUPS,
  groups,
});

export const updateGroupData = (field = '', value = '') => ({
  type: UPDATE_GROUP_DATA,
  field,
  value,
});

export const resetGroupData = () => ({
  type: RESET_GROUP_DATA,
});

export const setGroupDataToActiveGroup = activeGroup => ({
  type: SET_GROUP_DATA_TO_ACTIVE_GROUP,
  activeGroup,
});

export const submitDetailsUpdate = (groupData, groupId) => (dispatch, getState) => {
  const { orgState } = getState();
  const { org = {} } = orgState;
  ApiService.put(ApiConstants.groupsURL(groupId), { data: groupData })
    .then(() => {
      dispatch(showModal());
      dispatch(fetchGroups(org.id));
    })
    .catch(error => {
      ErrorService.capture('Error updating group data', error);
      dispatch(
        toastrActions.addToastr({
          id: 'UPDATE_GROUP_ERROR',
          level: 'error',
          title: 'Error updating group details',
          message:
            error.message ||
            'There was an error updating your group details. Please check that all information is accurate and try again.',
        })
      );
    });
};

export const toggleGroupAdminField = groupId => ({
  type: TOGGLE_GROUP_ADMIN_FIELD,
  groupId,
});

export const createGroup = (groupData, orgId) => dispatch => {
  return ApiService.post(ApiConstants.groupsByOrgURL(orgId), { data: groupData })
    .then(res => {
      dispatch(showModal());
      dispatch(fetchGroups(orgId));
      return res;
    })
    .catch(error => {
      ErrorService.capture('Error creating group', error);
      dispatch(
        toastrActions.addToastr({
          id: 'CREATE_GROUP_ERROR',
          level: 'error',
          title: 'Error creating group',
          message:
            error.message ||
            'There was an error creating your group. Please check that all information is accurate and try again.',
        })
      );
      return {};
    });
};

export const updateSelectedGroups = groupId => ({
  type: UPDATE_SELECTED_GROUPS,
  groupId,
});

export const filterGroupsByInput = value => ({
  type: FILTER_GROUPS_BY_INPUT,
  value,
});

export const updateActiveTab = tab => ({
  type: UPDATE_ACTIVE_TAB,
  tab,
});

export const showModal = (modalType?) => ({
  type: SHOW_GROUP_MODAL,
  modalType,
});

export const updateCreateGroupStage = stage => ({
  type: UPDATE_CREATE_GROUP_STAGE,
  stage,
});

/* group users actions */
export const setGroupDetails = group => {
  const { teams: workspaces = [], users = [] } = group;

  return {
    type: SET_GROUP_DETAILS,
    users,
    workspaces,
  };
};

export const updateSelectedGroupUsers = userId => ({
  type: UPDATE_SELECTED_GROUP_USERS,
  userId,
});

export const resetGroupDetails = () => ({
  type: RESET_GROUP_DETAILS,
});

export const filterGroupUsersByInput = (value, groupId) => ({
  type: FILTER_GROUP_USERS_BY_INPUT,
  value,
  groupId,
});

export const updateGroupUsersFilter = value => ({
  type: UPDATE_GROUP_USERS_FILTER,
  value,
});

export const toggleGroupUsersAdminFilter = groupId => ({
  type: TOGGLE_GROUP_USERS_ADMIN_FILTER,
  groupId,
});

/* group workspaces actions */
export const updateSelectedGroupWorkspaces = workspaceId => ({
  type: UPDATE_SELECTED_GROUP_WORKSPACES,
  workspaceId,
});

export const filterGroupWorkspacesByInput = (value, groupId) => ({
  type: FILTER_GROUP_WORKSPACES_BY_INPUT,
  value,
  groupId,
});

export const resetSelectedGroups = () => ({
  type: RESET_SELECTED_GROUPS,
});

export const updateSort = column => ({
  type: UPDATE_ORG_GROUP_SORT,
  column,
});

export const updateGroupUserSort = column => ({
  type: UPDATE_GROUP_USER_SORT,
  column,
});

export const updateGroupTeamSort = column => ({
  type: UPDATE_GROUP_TEAM_SORT,
  column,
});

export const resetGroupSort = () => ({
  type: RESET_GROUP_SORT,
});

export const toggleGroupQuickView = (groupId, column) => ({
  type: TOGGLE_GROUP_QUICK_VIEW,
  groupId,
  column,
});

export const resetGroupQuickViews = () => ({
  type: RESET_GROUP_QUICK_VIEWS,
});
