import * as actions from '~/actions/userManagementQuickView';

export const defaultState = {
  groups: [],
  workspaces: [],
  users: [],
  isFetching: false,
};

const userManagementQuickViewState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_QUICK_VIEW_DATA:
      return {
        ...state,
        [action.dataType]: action.data,
        isFetching: false,
      };
    case actions.FETCH_QUICK_VIEW_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
};

export default userManagementQuickViewState;
