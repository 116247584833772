import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FEATURE_SLUG_MAP } from '~/constants/ModelConstants';

import OrgPaidStatus from '~/utils/OrgPaidStatus';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import * as navigationActions from '~/actions/navigation';

import SidebarNavOption from '~/components/SidebarNavOption';

import { WorkspaceNavOptionsProps } from '~/containers/WorkspaceNavOptions.types';

import * as agentActions from '~/actions/agents';

class WorkspaceNavOptions extends Component<
  WorkspaceNavOptionsProps & ReturnType<typeof mapDispatchToProps>
> {
  componentDidMount() {
    const { expandedWorkspace } = this.props;
    this.props.navigationActions.toggleWorkspaceManagement(true);
    this.props.agentActions.fetchTeamAgents(expandedWorkspace.id);
  }
  // fetch workspace agents, when the workspace is toggled.
  componentDidUpdate(prevProps) {
    const { expandedWorkspace: nextExpandedWorkspace } = this.props;
    const { expandedWorkspace: prevExpandedWorkspace } = prevProps;
    if (prevExpandedWorkspace.id !== nextExpandedWorkspace.id) {
      this.props.navigationActions.toggleWorkspaceManagement(true);
      this.props.agentActions.fetchTeamAgents(nextExpandedWorkspace.id);
    }
  }

  toggleWorkspaceManagement() {
    const { navigationState } = this.props;
    const { isWorkspaceManagementExpanded } = navigationState;

    this.props.navigationActions.toggleWorkspaceManagement(!isWorkspaceManagementExpanded);
  }

  checkWorkspacePermissions(permissions) {
    const {
      update,
      manageProjects,
      manageAgents,
      listGroups,
      listUsers,
      readPolicy,
      notifications,
    } = permissions;

    return (
      update ||
      manageProjects ||
      manageAgents ||
      listGroups ||
      listUsers ||
      readPolicy ||
      notifications
    );
  }

  render() {
    const {
      expandedWorkspace,
      statsByWorkspaceId,
      orgState,
      location,
      navigationState,
      teamState,
      agentState,
      vcPageState: { shouldShowVeracodePage },
      myState,
    } = this.props;

    const { agents } = agentState;
    const { org } = orgState;
    const plan = org.plan || {};
    const { id } = plan;
    const isOrgNewHobby = id === 1001;
    const showPolicies =
      FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.POLICIES, org) ||
      OrgPaidStatus.isOrgEnterpriseOrTrial(org);

    const showGroups =
      FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org) ||
      OrgPaidStatus.isOrgEnterpriseOrTrial(org);
    const { pathname = '' } = location;
    const { isWorkspaceManagementExpanded } = navigationState;
    const { id: workspaceId } = expandedWorkspace;
    const stats = statsByWorkspaceId[workspaceId] || {};
    const { data = {}, isFetching } = stats;
    const { issues, repos, vulnerabilities, licenses, libraries } = data;
    const { teams = [] } = teamState;
    const activeTeam = teams.find(team => team.id === workspaceId) || {};
    const { permissions = {} } = activeTeam;
    const {
      reports,
      update,
      manageAgents,
      manageGroups,
      readPolicy,
      notifications,
      listUsers,
    } = permissions;
    const managementOptions = [];
    const showWorkspaceSubNav = this.checkWorkspacePermissions(permissions);
    const isOrgPaidOrTrial = OrgPaidStatus.isOrgPaidOrTrial(org);
    const isUnifiedPolicy = myState?.me?.permissions?.unifiedPolicy || false;
    let issueCount = 0;
    for (const type in issues) {
      issueCount += issues[type];
    }
    const reportOptions = [
      {
        title: 'Issues',
        toLocation: `/workspaces/${expandedWorkspace.id}/issues`,
        count: issueCount,
        isActive: pathname.includes(`${expandedWorkspace.id}/issues`),
      },
      {
        title: 'Projects',
        toLocation: `/workspaces/${expandedWorkspace.id}/projects`,
        count: repos,
        isActive: pathname.includes(`${expandedWorkspace.id}/projects`),
      },
      {
        title: 'Vulnerabilities',
        toLocation: `/workspaces/${expandedWorkspace.id}/vulnerabilities`,
        count: vulnerabilities,
        isActive: pathname.includes(`${expandedWorkspace.id}/vulnerabilities`),
      },
      {
        title: 'Libraries',
        toLocation: `/workspaces/${expandedWorkspace.id}/libraries`,
        count: libraries,
        isActive: pathname.includes(`${expandedWorkspace.id}/libraries`),
      },
      {
        title: 'Licenses',
        toLocation: `/workspaces/${expandedWorkspace.id}/licenses`,
        count: licenses,
        isActive: pathname.includes(`${expandedWorkspace.id}/licenses`),
      },
    ];
    const agentsLocation = `/workspaces/${expandedWorkspace.id}/agents${
      expandedWorkspace.repoCount > 0 || agents.length > 0 ? '' : '/new'
    }`;

    if (expandedWorkspace.userId) {
      managementOptions.push({
        title: 'Agents',
        toLocation: agentsLocation,
        isActive: pathname.includes('/agents'),
      });
    } else {
      // team admin that isn't org owner/admin --> agents, group-less users, settings
      // team admin that isn't org owner/admin --> groups/users
      // org owner/admin --> everything
      // !org owner/admin --> read-only policies

      // new rule: new personal plans may only have one user so the users page is useless and they won't see it.

      if (update) {
        managementOptions.push({
          title: 'Settings',
          toLocation: `/workspaces/${expandedWorkspace.id}/settings`,
          isActive: pathname.includes('/settings'),
        });
      }

      if (manageAgents) {
        managementOptions.push({
          title: 'Agents',
          toLocation: agentsLocation,
          isActive: pathname.includes('/agents'),
        });
      }

      if (!shouldShowVeracodePage) {
        if (listUsers && !isOrgNewHobby) {
          managementOptions.push({
            title: 'Users',
            toLocation: `/workspaces/${expandedWorkspace.id}/user-management/users`,
            isActive: pathname.includes('/users'),
          });
        }
      }
      // in VC mode, listUsers permission is always false
      if ((manageGroups || listUsers) && showGroups) {
        managementOptions.push({
          title: 'Teams',
          toLocation: `/workspaces/${expandedWorkspace.id}/user-management/teams`,
          isActive: pathname.includes('/teams'),
        });
      }
    }
    const rulesOptionTitle = isUnifiedPolicy ? 'Policy Assignment' : 'Rules';
    if (readPolicy && showPolicies) {
      managementOptions.push({
        title: rulesOptionTitle,
        toLocation: `/workspaces/${expandedWorkspace.id}/rules`,
        isActive: pathname.includes('/rules'),
      });
    }

    if (notifications && isOrgPaidOrTrial) {
      managementOptions.push({
        title: 'Notifications',
        toLocation: `/workspaces/${expandedWorkspace.id}/notifications`,
        isActive: pathname.includes('/notifications'),
      });
    }

    return (
      <div className="flex flex--flex-direction--column" data-automation-id="WorkspaceNav">
        {reports &&
          reportOptions.map(option => (
            <SidebarNavOption
              key={option.title}
              option={option}
              fontSize="font--h7"
              isStatsFetching={isFetching}
            />
          ))}

        {reports && showWorkspaceSubNav && (
          <div className="col-1-1 pl0 pv-- pr-">
            <div className="bo-b--1 border-color--muted" />
          </div>
        )}

        {showWorkspaceSubNav && managementOptions.length ? (
          <div data-automation-id="WorkspaceSubNav">
            <div
              className={`col-1-1 pv- flex flex--justify-content--space-between align-items--center ${
                reports && 'cursor--pointer hover-background--white-light'
              }`}
              onClick={reports ? () => this.toggleWorkspaceManagement() : undefined}
            >
              <div className="pl- font--h7" data-automation-id="WorkspaceSubNav-Heading">
                Manage Workspace
              </div>
              {reports && (
                <div className="pr-" data-automation-id="WorkspaceSubNav-Toggle">
                  <i
                    className={`sci sci--fw sci--sm ${
                      isWorkspaceManagementExpanded ? 'sci__caret--down' : 'sci__caret--right'
                    }`}
                  />
                </div>
              )}
            </div>
            <div
              className={`col-1-1 ${
                isWorkspaceManagementExpanded || !reports ? 'is-showing-200' : 'is-hiding'
              }`}
            >
              <div className="col-1-1 pl">
                {managementOptions.map(option => (
                  <SidebarNavOption
                    key={option.title}
                    option={option}
                    verticalPaddingClass="pv--"
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentState: state.agentState,
    myState: state.myState,
    navigationState: state.navigationState,
    orgState: state.orgState,
    teamState: state.teamState,
    statsByWorkspaceId: state.statsByWorkspaceId,
    vcPageState: state.vcPageState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigationActions: bindActionCreators(navigationActions as any, dispatch),
    agentActions: bindActionCreators(agentActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceNavOptions);
