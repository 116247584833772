import produce from 'immer';
import { combineReducers } from 'redux';
import * as actions from '~/actions/projectBranch';
import _ from 'lodash';
import { USE_LAST_SCANNED } from '~/constants/ModelConstants';
import Helpers from '~/utils/Helpers';

const defaultBranchState = {
  branchesById: {},
  isFetchingBranches: false,
  fetchBranchErr: undefined,
};

const defaultSearchBranchState = {
  branchesById: {},
  isFetchingBranches: false,
  fetchBranchErr: undefined,
};

const defaultUpdateBranchState = {
  isUpdating: false,
  updateError: undefined,
  updateSuccess: false,
};

const sortBranches = (branches: string[]) => {
  const sorted = _.sortBy(branches);
  const deduped = _.sortedUniq(sorted);

  return deduped;
};

const projectBranchState = () => {
  const branchState = (state = defaultBranchState, action) => {
    return produce(state, draft => {
      switch (action.type) {
        case actions.FETCH_PROJECT_BRANCH_REQUEST:
          draft.isFetchingBranches = true;
          draft.fetchBranchErr = undefined;
          break;
        case actions.FETCH_PROJECT_BRANCH_SUCCESS:
          // check if a property key with the current projectId exists, if it does, then append
          // action.branches to the array and sort it.
          if (draft.branchesById && draft.branchesById[action.projectId]) {
            draft.branchesById = {
              ...draft.branchesById,
              [action.projectId]: sortBranches([
                ...draft.branchesById[action.projectId],
                ...action.branches,
              ]),
            };
          } else {
            // if not, create a new property key with the current projectId with the sorted
            // array of action.branches
            draft.branchesById = {
              ...draft.branchesById,
              [action.projectId]: sortBranches(action.branches),
            };
          }
          if (Helpers.hasLatestScanEnabled()) {
            //if the 'use last scan' feature enabled, add the 'use last scanned' option as first option in default branch selection
            draft.branchesById[action.projectId].unshift(USE_LAST_SCANNED);
          }

          draft.isFetchingBranches = false;
          draft.fetchBranchErr = undefined;
          break;
        case actions.FETCH_PROJECT_BRANCH_FAILURE:
          draft.branchesById = {};
          draft.isFetchingBranches = false;
          draft.fetchBranchErr = action.error;
          break;
        case actions.UPDATE_BRANCH_LIST:
          // check if a property key with the current projectId exists, if it does, then append
          // action.branches to the array and sort it.
          if (draft.branchesById && draft.branchesById[action.projectId]) {
            draft.branchesById = {
              ...draft.branchesById,
              [action.projectId]: sortBranches([
                ...draft.branchesById[action.projectId],
                ...action.branches,
              ]),
            };
          } else {
            // if not, create a new property key with the current projectId with the sorted
            // array of action.branches
            draft.branchesById = {
              ...draft.branchesById,
              [action.projectId]: sortBranches(action.branches),
            };
          }
          break;
      }
    });
  };

  /**
   * Selected default branch in the control (ie dropdown)
   */
  const selectedDefaultBranch = (state = '', action) => {
    switch (action.type) {
      case actions.UPDATE_SELECTED_DEFAULT_BRANCH:
        return action.selectedDefaultBranch;
      default:
        return state;
    }
  };

  const updateBranchState = (state = defaultUpdateBranchState, action) => {
    switch (action.type) {
      case actions.FETCH_PROJECT_BRANCH_REQUEST:
        return defaultUpdateBranchState;

      case actions.UPDATE_DEFAULT_BRANCH_REQUEST:
        return {
          isUpdating: true,
          updateError: undefined,
          updateSuccess: false,
        };

      case actions.UPDATE_DEFAULT_BRANCH_SUCCESS:
        return {
          isUpdating: false,
          updateError: undefined,
          updateSuccess: true,
        };

      case actions.UPDATE_DEFAULT_BRANCH_FAILURE:
        return {
          isUpdating: false,
          updateError: action.error,
          updateSuccess: false,
        };

      case actions.RESET_UPDATE_DEFAULT_BRANCH:
        return {
          isUpdating: false,
          updateError: undefined,
          updateSuccess: false,
        };

      default:
        return state;
    }
  };

  const searchBranchState = (state = defaultSearchBranchState, action) => {
    return produce(state, draft => {
      switch (action.type) {
        case actions.SEARCH_PROJECT_BRANCH_REQUEST:
          draft.isFetchingBranches = true;
          draft.fetchBranchErr = undefined;
          break;
        case actions.SEARCH_PROJECT_BRANCH_SUCCESS:
          draft.branchesById = {
            [action.projectId]: action.branches,
          };
          draft.isFetchingBranches = false;
          draft.fetchBranchErr = undefined;
          break;
        case actions.SEARCH_PROJECT_BRANCH_FAILURE:
          draft.branchesById = {};
          draft.isFetchingBranches = false;
          draft.fetchBranchErr = action.error;
          break;
      }
    });
  };

  return combineReducers({
    branchState,
    selectedDefaultBranch,
    updateBranchState,
    searchBranchState,
  });
};

export default projectBranchState();
