import React from 'react';

import { VCAlertBoxLevels, VCAlertBoxProps } from '~/types/VCAlertBoxLevels';

type VCAlertBoxMap = {
  className: string;
  icon: string;
};

function VCAlertBox(props: VCAlertBoxProps): JSX.Element {
  const { message, type } = props;

  const { className, icon }: VCAlertBoxMap = ((type: VCAlertBoxLevels) => {
    // // TODO, use actual VC icons for Veracode Alert Box once available
    switch (type) {
      case 'SUCCESS':
        return { className: 'vcAlertBox__level--success', icon: 'tbd' };
      case 'INFO':
        return { className: 'vcAlertBox__level--info', icon: 'sci__detail-information' };
      case 'WARNING':
        return { className: 'vcAlertBox__level--info', icon: 'sci__vulnerability' };
      case 'SECURITY':
        return { className: 'vcAlertBox__level--security', icon: 'tbd' };
      default:
        throw new Error('Bad notification level');
    }
  })(type);

  return (
    <div className={`vcAlertBoxContainer ${className}`}>
      <div className="vcAlertBoxIconContainer">
        <i className={`sci sci--md ${icon}`} />
      </div>

      <p className="vcAlertBoxMessage">{message}</p>
    </div>
  );
}

export default VCAlertBox;
