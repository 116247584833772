import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';

import * as teamActions from '~/actions/team';
import * as welcomeActions from '~/actions/welcome';

interface WorkspaceWelcomePageProps extends RouteComponentProps {
  teamActions: object;
  welcomeActions: object;
  welcomeState: object;
}

class WorkspaceWelcomePage extends React.Component<WorkspaceWelcomePageProps, {}> {
  componentDidMount() {
    this.props.welcomeActions.fetchAgentEvents();
  }

  trackWelcomeFlowStep(target) {
    this.props.welcomeActions.trackWelcomeFlowStep(target);
  }

  finishWelcomeMode() {
    this.props.welcomeActions.trackWelcomeFlowStep('VIEW');
    this.props.teamActions.fetchTeams();
  }

  render() {
    const { match, welcomeState } = this.props;
    const { params = {} } = match;
    const { isAgentSetup, isScanComplete, isScanPersisted } = welcomeState;
    const { teamId } = params;

    return (
      <div className="container">
        <Helmet>
          <title>Welcome to SourceClear</title>
        </Helmet>
        <div className="grid mb++">
          <div className="grid__item col-1-6" />
          <div className="grid__item col-2-3">
            <div className="col-1-1 mt bg-color--primary color--white text--center p--">
              <div className="font--h2">Welcome to SourceClear</div>
            </div>
            <div className="mt+ pt col-1-1 text--center font--h5">
              Thank you for signing up! Get started in 3 easy steps.
            </div>
            <div className="grid grid--full mt+">
              <div className="grid__item flex flex-direction--column justify-content--space-between bo-r--1 border-color--white-dark bo--dashed z-index--10">
                <div className="mt">
                  <div
                    className={`font--h5 col-1-1 text--center font-family--space-mono-bold ${
                      isAgentSetup && 'color--success'
                    }`}
                  >
                    1. SET UP SCANNER
                  </div>
                  <div className="mt- ph text--center font--h7">
                    The SourceClear scanner is a command line utility that detects open-source
                    libraries and vulnerabilities in your code.
                  </div>
                </div>
                <div className="col-1-1 flex justify-content--center mv">
                  <Link
                    to={`/workspaces/${teamId}/welcome/agent`}
                    onClick={() => this.trackWelcomeFlowStep('SET_UP')}
                    className={`${
                      isAgentSetup ? 'btn--success' : 'btn--primary'
                    } bo-rad--0 bo--0 pv- col-1-3 font-family--space-mono-bold flex align-items--center justify-content--center`}
                  >
                    {isAgentSetup && <i className="sci sci__check pr--" />}
                    SET UP
                  </Link>
                </div>
              </div>
              <div className="grid__item flex flex-direction--column justify-content--space-between bo-r--1 border-color--white-dark bo--dashed z-index--10">
                <div className="mt">
                  <div
                    className={`font--h5 col-1-1 text--center font-family--space-mono-bold ${
                      isScanComplete && 'color--success'
                    }`}
                  >
                    2. SCAN YOUR CODE
                  </div>
                  <div className="mt- ph text--center font--h7">
                    Scan your code in minutes, or try one of our example projects.
                  </div>
                </div>
                <div className="col-1-1 flex justify-content--center mv">
                  {isAgentSetup ? (
                    <Link
                      to={`/workspaces/${teamId}/welcome/scan`}
                      onClick={() => this.trackWelcomeFlowStep('SCAN')}
                      className={`${
                        isScanComplete ? 'btn--success' : 'btn--primary'
                      } bo-rad--0 bo--0 pv- col-1-3 font-family--space-mono-bold flex align-items--center justify-content--center`}
                    >
                      {isScanComplete && <i className="sci sci__check pr--" />}
                      SCAN
                    </Link>
                  ) : (
                    <button
                      className="btn--success-clear bo-rad--0 bo--0 pv- col-1-3 font-family--space-mono-bold disabled"
                      disabled
                    >
                      SCAN
                    </button>
                  )}
                </div>
              </div>
              <div className="grid__item flex flex-direction--column justify-content--space-between">
                <div className="mt">
                  <div className="font--h5 col-1-1 text--center font-family--space-mono-bold">
                    3. VIEW SCAN RESULTS
                  </div>
                  <div className="mt- ph text--center font--h7">
                    View a report of issues found, including vulnerabilities, outdated libraries,
                    and undesirable licenses.
                  </div>
                </div>
                <div className="col-1-1 flex justify-content--center mv">
                  {isAgentSetup ? (
                    <Link
                      to={
                        isScanPersisted
                          ? `/workspaces/${teamId}/projects`
                          : `/workspaces/${teamId}/welcome/scan-help`
                      }
                      onClick={() => this.finishWelcomeMode()}
                      className="btn--primary bo-rad--0 bo--0 pv- col-1-3 font-family--space-mono-bold"
                    >
                      VIEW
                    </Link>
                  ) : (
                    <button
                      className="btn--success-clear bo-rad--0 bo--0 pv- col-1-3 font-family--space-mono-bold disabled"
                      disabled
                    >
                      VIEW
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    welcomeState: state.welcomeState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    teamActions: bindActionCreators(teamActions, dispatch),
    welcomeActions: bindActionCreators(welcomeActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceWelcomePage);
