import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';
import _ from 'lodash';

import OrgPaidStatus from '~/utils/OrgPaidStatus';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';

import { FEATURE_SLUG_MAP, errorMessageTextMap } from '~/constants/ModelConstants';

import { withOrgPermissionCheck } from '~/utils/Permissions';

import * as orgActions from '~/actions/org';
import * as orgUserActions from '~/actions/orgUser';
import * as meActions from '~/actions/me';
import * as popoverActions from '~/actions/popover';

import PopoverWithEscKey from '~/components/PopoverWithEscKey';
import SourceClearModal from '~/components/SourceClearModal';
import LoaderWrapper from '~/components/LoaderWrapper';

interface OrgProfilePageProps {
  meActions: object;
  orgActions: object;
  orgUserActions: object;
  popoverActions: object;
  popoverState: object;
  myState: App.MyState;
  orgState: App.OrgState;
  orgUserState: object;
  validOrgName?: boolean;
}
class OrgProfilePage extends React.Component<OrgProfilePageProps, {}> {
  componentDidMount() {
    const { orgState } = this.props;
    const { org = {} } = orgState;
    const samlSelfManage = FeatureFlagHelper.isFeatureEnabledForOrg(
      FEATURE_SLUG_MAP.SAML_SELF_MANAGE,
      org
    );

    this.props.orgUserActions.fetchOrgUsers();

    if (samlSelfManage && !this.hasExistingSamlSetup()) {
      this.props.orgActions.fetchSamlSetup();
    }
  }

  componentDidUpdate(prevProps) {
    const { orgState: currentOrgState } = this.props;
    const { orgState: prevOrgState } = prevProps;
    const { org: currentOrg = {} } = currentOrgState;
    const { org: prevOrg = {} } = prevOrgState;
    const samlSelfManage = FeatureFlagHelper.isFeatureEnabledForOrg(
      FEATURE_SLUG_MAP.SAML_SELF_MANAGE,
      prevOrg
    );

    if (currentOrg.id !== prevOrg.id && samlSelfManage && !this.hasExistingSamlSetup()) {
      this.props.orgActions.fetchSamlSetup();
    }
  }

  componentWillUnmount() {
    const { orgState } = this.props;
    const { orgNameDirty } = orgState;

    if (orgNameDirty) {
      this.props.meActions.fetchMe();
    }
  }

  updateOrgNameValue(e) {
    const { value } = e.target;
    this.props.orgActions.updateOrgNameValue(value);
  }

  saveOrgName(e, orgId) {
    e.preventDefault();
    const form = e.target;
    const { orgName = {} } = form;
    const { value } = orgName;

    this.props.orgActions.saveOrgName(value, orgId);
  }

  saveSamlSetup(orgId) {
    this.props.orgActions.saveSamlSetup(orgId);
  }

  toggleDeleteOrgPopover() {
    const { popoverState } = this.props;

    if (popoverState.deleteOrg) {
      this.closeDeleteOrgPopover('deleteOrg');
    } else {
      this.props.popoverActions.openPopover('deleteOrg');
    }
  }

  closeDeleteOrgPopover() {
    this.props.popoverActions.closePopover('deleteOrg');
  }

  confirmDeleteOrg(orgId) {
    this.props.orgActions.deleteOrg(orgId);
  }

  upgradeUserToAdmin(e, orgId) {
    e.preventDefault();
    const userId = this.refs.userToUpgrade.value;

    this.props.orgActions.updateUserStatus(orgId, userId, 'OWNER', 'withGroups');
  }

  removeUserFromAdmins(orgId, userId) {
    this.props.orgActions.updateUserStatus(orgId, userId, 'USER', 'withGroups');
  }

  handleSamlMetadataInputChange(e) {
    const { value, id } = e.target;
    this.props.orgActions.updateSamlMetadataValue(value);
    this.props.orgActions.validateField('samlInputRaw', id); //simultaneously perform validation
  }

  samlDomainsOnChange(e) {
    const { value, id } = e.target;
    this.props.orgActions.updateSamlDomainsValue(value);
    this.props.orgActions.validateField('samlInputRaw', id);
  }

  samlGroupNameAttrOnChange(e) {
    const { value } = e.target;
    this.props.orgActions.updateSamlGroupNameAttr(value);
  }

  /*
    toggleSamlAllowOrgOwnerPasswordLogin() {
      const { orgState } = this.props;
      const { saml = {} } = orgState;
      const { allowOrgOwnerPasswordLogin } = saml;

      this.props.orgActions.toggleSamlAllowOrgOwnerPasswordLogin(!allowOrgOwnerPasswordLogin);
    }
    */
  showModal(modalType) {
    this.props.orgActions.showModal(modalType);
  }

  handleCancelSamlSetupEditClick(e) {
    e.preventDefault();

    if (this.samlSetupHasChanges()) {
      this.showModal('CANCEL_SAML_SETUP');
    } else {
      this.props.orgActions.updateSamlSetupMode('VIEW'); //If no changes, cancelling simply means setting to VIEW mode.
    }
  }

  samlSetupHasChanges() {
    const { orgState } = this.props;
    const { saml, samlInputRaw } = orgState;
    const {
      samlDomains: currSamlDomainsArr = [],
      samlMetadata: currSamlMetadata = '',
      groupNameAttribute = '',
    } = saml;
    const {
      samlDomains: samlDomainsInput = '',
      samlMetadata: samlMetadataInput = '',
      groupNameAttribute: groupNameAttributeInput = '',
    } = samlInputRaw;
    const samlDomainsInputArr = samlDomainsInput.split(/\s+|,/).filter(it => !!it);
    const samlDomainsHasChanges = !_.isEqual(samlDomainsInputArr.sort(), currSamlDomainsArr.sort());
    const samlMetadataHasChanges = !_.isEqual(samlMetadataInput, currSamlMetadata);
    const samlGroupNameAttributeHasChanges = !_.isEqual(
      groupNameAttributeInput,
      groupNameAttribute
    );

    return samlDomainsHasChanges || samlMetadataHasChanges || samlGroupNameAttributeHasChanges;
  }

  confirmCancelSamlSetupEdit() {
    this.props.orgActions.cancelSamlSetupEdit();
    this.showModal(); //closes modal
  }

  handleSamlSetupModeClick(e, mode) {
    e.preventDefault();
    this.props.orgActions.updateSamlSetupMode(mode);
  }

  hasExistingSamlSetup() {
    const { orgState } = this.props;
    const { saml } = orgState;
    const { samlDomains: currSamlDomains = '', samlMetadata: currSamlMetadata = '' } = saml; //existing saml config

    return !!currSamlDomains && !!currSamlMetadata;
  }

  render() {
    const { orgState, orgUserState, popoverState, myState } = this.props;
    const {
      org,
      samlInputRaw,
      isSamlSaving,
      samlSetupMode,
      fieldValidations = {},
      showModal,
    } = orgState;
    const { orgUsers = [] } = orgUserState;
    const { id: orgId, name = '', validOrgName = true } = org;
    const showGroups =
      FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org) ||
      OrgPaidStatus.isOrgEnterpriseOrTrial(org);
    const { samlDomains = '', samlMetadata = '', groupNameAttribute = '' } = samlInputRaw;
    const { me = {} } = myState;
    const { orgRoles = [] } = me;

    const showSamlSettings =
      FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.SAML_SELF_MANAGE, org) &&
      orgRoles.includes('OWNER');

    //validations
    const { samlInputRaw: samlFormGroup = {} } = fieldValidations;
    const {
      samlDomains: samlDomainsField = {},
      samlMetadata: samlMetadataField = {},
    } = samlFormGroup;
    const {
      isInvalid: isSamlMetadataFieldInvalid,
      errors: samlMetadataFieldErrors = [],
    } = samlMetadataField;
    const {
      isInvalid: isSamlDomainsFieldInvalid,
      errors: samlDomainsFieldErrors = [],
    } = samlDomainsField;
    const orgNameError = !validOrgName ? errorMessageTextMap.orgNameError : '';
    const shouldDisableButton = name.length < 3 || name.length > 20 || !!orgNameError;

    const hasExistingSamlSetup = this.hasExistingSamlSetup();
    const isSamlFormInvalid =
      samlMetadataFieldErrors.length > 0 || samlDomainsFieldErrors.length > 0 || !samlMetadata;
    const isSamlSetupViewOnly = samlSetupMode === 'VIEW' && !!hasExistingSamlSetup; //If there's existing saml setup and mode is VIEW, make controls view-only

    const isDeleteOrgPopoverOpen = popoverState.deleteOrg;
    const adminUsers = [];
    const nonAdminUsers = [];
    const deleteOrgContent = (
      <div className="grid">
        <div className="grid__item col-1-1 font--h7 color--danger">
          This will delete all data, all workspaces, and all user accounts for the organization.{' '}
          <strong>It cannot be undone.</strong> Are you sure?
        </div>
        <div className="grid__item col-1-1 mt">
          <div className="grid">
            <div className="grid__item col-1-2">
              <button
                className="btn--default font--h7 col-1-1"
                onClick={() => this.closeDeleteOrgPopover()}
              >
                Cancel
              </button>
            </div>
            <div className="grid__item col-1-2">
              <button
                className="btn--danger font--h7 col-1-1"
                onClick={() => this.confirmDeleteOrg(orgId)}
              >
                Confirm delete organization
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    orgUsers.forEach(user => {
      const { roles = [] } = user;

      roles.includes('ADMIN') || roles.includes('OWNER')
        ? adminUsers.push(user.user)
        : nonAdminUsers.push(user.user);
    });

    return (
      <div className="grid grid--wide mt">
        <Helmet>
          <title>Organization Settings</title>
        </Helmet>
        <div className="grid__item col-1-1">
          <div className="font--h3" data-e2e={'OrgProfilePage-Title'}>
            Organization Settings
          </div>
        </div>

        {/**
         * Organization name section
         */}
        <div className="grid__item col-1-1 grid mt">
          <div className="grid__item col-1-1">
            <form onSubmit={e => this.saveOrgName(e, orgId)} className="grid__item grid">
              <div className="col-5-7 grid__item">
                <div className="bo-b--1 grid border-color--muted-light pb mb">
                  <div className="col-2-9">
                    <label htmlFor="org-name" className="color--black mt--">
                      Organization name
                    </label>
                  </div>
                  <div className="col-7-9">
                    <div className="col-1-1">
                      <input
                        type="text"
                        className="control--text col-1-1"
                        id="org-name"
                        name="orgName"
                        value={name}
                        onChange={e => this.updateOrgNameValue(e)}
                      />
                    </div>
                    <div className={`col-1-1 height--50`}>
                      <div className={`${orgNameError ? 'is-showing-50' : 'is-hiding'}`}>
                        <div className="mt- color--danger">{orgNameError}</div>
                      </div>
                    </div>
                    <div className="grid__item col-1-1 text--right mt-">
                      <button
                        className="btn--success font-family--body-light pv- ph"
                        disabled={shouldDisableButton}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/**
           * SAML Settings section
           */}
          {showSamlSettings && (
            <div className="grid__item col-1-1">
              <form
                onSubmit={e => {
                  e.preventDefault();
                  this.showModal('SAVE_SAML_SETUP');
                }}
                className="grid__item grid"
              >
                <div className="col-5-7 grid__item">
                  <div className="bo-b--1 grid border-color--muted-light pb">
                    <div className="col-1-1 pb">
                      You can use SAML to integrate your single sign-on (SSO) solution such as Okta
                      and PingOne with SourceClear.
                    </div>
                    {hasExistingSamlSetup && (
                      <div className="col-1-1 pb">
                        Your SAML integration is set up with the values below.{' '}
                        <a
                          className="link--obvious"
                          onClick={() => this.props.orgActions.updateSamlSetupMode('EDIT')}
                        >
                          {' '}
                          Edit your configuration
                        </a>{' '}
                        to make changes.
                      </div>
                    )}
                    <div className="col-2-9">
                      <label htmlFor="samlDomains" className="color--black mt--">
                        Domains of email <br />
                        addresses used by <br />
                        SAML users
                      </label>
                    </div>
                    <div className="col-7-9">
                      <div className="col-1-1">
                        <textarea
                          id="samlDomains"
                          className={`control--text col-1-1 font-family--mono ${
                            isSamlDomainsFieldInvalid
                              ? 'border-color--danger'
                              : 'border-color--muted'
                          }`}
                          onChange={this.samlDomainsOnChange.bind(this)}
                          placeholder="Domains of the email addresses of SAML users, separated by space or newline"
                          required
                          rows="3"
                          value={samlDomains}
                          disabled={isSamlSetupViewOnly}
                        />
                        {samlDomainsFieldErrors.length > 0 && (
                          <ul className={`color--danger mt-`}>
                            {samlDomainsFieldErrors.map((error, i) => (
                              <li key={i}>{error}</li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="col-2-9 mt">
                      <label htmlFor="samlMetadata" className="color--black mt--">
                        SAML identity provider <br />
                        metadata document
                      </label>
                    </div>
                    <div className="col-7-9 mt">
                      <div className="col-1-1">
                        <textarea
                          id="samlMetadata"
                          className={`control--text col-1-1 font-family--mono ${
                            isSamlMetadataFieldInvalid
                              ? 'border-color--danger'
                              : 'border-color--muted'
                          }`}
                          placeholder="Paste in the XML metadata from your SAML identity provider."
                          value={samlMetadata}
                          onChange={this.handleSamlMetadataInputChange.bind(this)}
                          rows="20"
                          required
                          disabled={isSamlSetupViewOnly}
                        />
                      </div>
                      {samlMetadataFieldErrors.length > 0 && (
                        <ul className={`color--danger mt-`}>
                          {samlMetadataFieldErrors.map((error, i) => (
                            <li key={i}>{error}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                    {showGroups && (
                      <div className="col-1-1 flex">
                        <div className="col-2-9 mt flex flex--align-items--top">
                          <label htmlFor="groupNameAttribute" className="color--black mt--">
                            Teams mapping <br />
                            attribute name
                          </label>
                          <a onClick={() => this.showModal('SAML_GROUP_INFO')} className="mt--">
                            <i className="fas fa-info-circle font--14 color--primary" />
                          </a>
                        </div>
                        <div className="col-7-9 mt">
                          <div className="col-1-1">
                            <input
                              type="text"
                              className="control--text font-family--mono col-1-1 border-color--muted"
                              id="groupNameAttribute"
                              name="groupNameAttribute"
                              value={groupNameAttribute}
                              disabled={isSamlSetupViewOnly}
                              onChange={this.samlGroupNameAttrOnChange.bind(this)}
                              placeholder={`(Optional)`}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {/*
                    <div className="col-1-1 mt">
                      <div className="bo--1 border-color--primary-dark bg-color--primary-extra-light ph- pv-- color--primary-dark">
                        <div className="flex align-items--center">
                          <i className="sci sci__detail-information font--h5" />
                            <div className="pl- pr pv--">
                              Once SSO is turned on, all users must log in through SSO. You can allow <strong>org owners</strong> to log in with a username and password in addition to SAML, though we recommend you turn this off after testing with your Identity Provider.
                            </div>
                          </div>
                      </div>
                    </div>,
                    <div className="col-1-1 mt">
                      <div className="col-7-9 pull-right">
                        <div className="col-1-1">
                          <label className="label--checkbox col-1-1 mb-- flex align-items--center">
                            <input type="checkbox" name="allowOrgOwnerPasswordLogin" onChange={() => this.toggleSamlAllowOrgOwnerPasswordLogin()} checked={allowOrgOwnerPasswordLogin}/>
                            <span className="react--checkbox" />
                              <span className="font--13">Continue to allow org owners to log in with passwords</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    */}
                    <div className="col-1-1">
                      <div className="grid__item col-1-1 text--right mt-">
                        {isSamlSetupViewOnly ? (
                          <button
                            className="btn--success font-family--body-light pv- ph"
                            onClick={e => this.handleSamlSetupModeClick(e, 'EDIT')}
                          >
                            <LoaderWrapper
                              isLoaderShowing={isSamlSaving}
                              loaderType={'SPINNER'}
                              size="font--14"
                            >
                              Edit
                            </LoaderWrapper>
                          </button>
                        ) : (
                          <div>
                            <button
                              className={`font-family--body-light pv- ph mr--- ${
                                isSamlSaving ? 'invisible' : ''
                              }`}
                              onClick={e => this.handleCancelSamlSetupEditClick(e)}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn--success font-family--body-light pv- ph"
                              disabled={isSamlSaving || isSamlFormInvalid}
                            >
                              <LoaderWrapper
                                isLoaderShowing={isSamlSaving}
                                loaderType={'SPINNER'}
                                size="font--14"
                              >
                                Save
                              </LoaderWrapper>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}

          {/**
           * Delete Org section
           */}

          <div className="grid__item col-1-1 mt mb">
            <div className="col-5-7 grid__item">
              <div className="grid">
                <div className="col-2-9">
                  <label htmlFor="org-name" className="color--black mt--">
                    Delete organization
                  </label>
                </div>
                <div className="col-7-9 text--right">
                  <PopoverWithEscKey
                    className="zIndex-9--overlay max-width--500"
                    isOpen={isDeleteOrgPopoverOpen}
                    body={deleteOrgContent}
                    preferPlace="bottom"
                    onOuterAction={() => this.closeDeleteOrgPopover()}
                  >
                    <button
                      className="btn--danger-clear ph pv-"
                      onClick={() => this.toggleDeleteOrgPopover()}
                    >
                      Delete organization
                    </button>
                  </PopoverWithEscKey>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SourceClearModal
          isOpen={showModal === 'SAVE_SAML_SETUP'}
          title="Confirm SAML Setup"
          onClose={() => this.showModal()}
          width={400}
        >
          {hasExistingSamlSetup ? (
            <div className="pt-">
              You are changing your SAML setup. Active user sessions will not be affected. Be sure
              to verify that all inputs are correct before saving. Saving incorrect SAML
              configuration information could lock yourself and others out of SourceClear. Do you
              want to continue?
            </div>
          ) : (
            <div className="pt-">
              You are setting up SAML. Once it is set up, only org owners will have the option to
              log in using a username and password. Users without the org owner role must log in
              through an Identity Provider. Do you want to continue?
            </div>
          )}
          <div className="flex flex--justify-content--end mt">
            <button
              className={`pv- col-1-5 mr- ${isSamlSaving ? 'invisible' : ''}`}
              onClick={() => this.showModal()}
            >
              Cancel
            </button>
            <button
              className="btn--success pv- ph-- col-1-4 mr-"
              onClick={() => this.saveSamlSetup(orgId)}
              disabled={isSamlSaving}
            >
              <LoaderWrapper isLoaderShowing={isSamlSaving} loaderType={'SPINNER'} size="font--14">
                Yes
              </LoaderWrapper>
            </button>
          </div>
        </SourceClearModal>

        <SourceClearModal
          isOpen={showModal === 'CANCEL_SAML_SETUP'}
          title="Cancel Changes"
          onClose={() => this.showModal()}
          width={400}
        >
          <div className="pt-">
            Your changes to this SAML configuration will not be saved. <br />
            Do you want to cancel changes?
          </div>
          <div className="flex flex--justify-content--end mt">
            <button className={`pv- col-1-5 mr---`} onClick={() => this.showModal()}>
              No
            </button>
            <button
              className={`btn--danger pv- ph-- col-2-5 mr--`}
              onClick={() => this.confirmCancelSamlSetupEdit()}
            >
              Yes, cancel changes
            </button>
          </div>
        </SourceClearModal>

        <SourceClearModal
          isOpen={showModal === 'SAML_GROUP_INFO'}
          title="Team Mapping Attribute Name"
          onClose={() => this.showModal()}
          width={450}
        >
          <div className="pt-">
            <p>
              This setting allows you to automatically place each user into a SourceClear team based
              on your Identity Provider (IdP) settings.
            </p>

            <h5>How It Works</h5>

            <p>
              Fill in the name of the SAML attribute in your IdP that contains the name of the team
              for each user (example: "scGroupName").
            </p>
            <p>
              When SourceClear receives the SAML assertion, we will add the user to a SourceClear{' '}
              team of the same name. If the team does not exist, we will create it.
            </p>
            <p>
              <strong>Note:</strong> Changes to users&apos; teams in the IdP are not synced to
              SourceClear teams.
            </p>
            <ul>
              <li className="pb-">
                If the team name is changed in SourceClear but the attribute still asserts the same
                value from the IdP, then the next time the user authenticates, a new SourceClear{' '}
                team with the name from the IdP will be created again, and the user will belong to
                both teams in SourceClear.
              </li>
              <li className="pb-">
                Conversely, if the user&apos;s attribute value is changed in the IdP, then the next
                time the user authenticates, the user will belong to two teams: the previous
                SourceClear team, and the updated team specified by the IdP.
              </li>
              <li>
                If a user&apos;s mapping is removed from the IdP, the user is not removed from the
                corresponding SourceClear team.
              </li>
            </ul>
          </div>
          <div className="flex flex--justify-content--end mt">
            <button
              className={`btn--success pv- ph-- col-1-5 mr--`}
              onClick={() => this.showModal()}
            >
              Close
            </button>
          </div>
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myState: state.myState,
    orgState: state.orgState,
    orgUserState: state.orgUserState,
    popoverState: state.popoverState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    meActions: bindActionCreators(meActions, dispatch),
    orgActions: bindActionCreators(orgActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
    popoverActions: bindActionCreators(popoverActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOrgPermissionCheck(OrgProfilePage, 'update'));
