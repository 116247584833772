import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as MODEL from '~/constants/ModelConstants';

import SourceClearModal from '~/components/SourceClearModal';
import * as integrationModalActions from '~/actions/integrationModal';
import * as integrationByIdActions from '~/actions/integrationById';
import * as reportSelectedRowsActions from '~/actions/reportSelectedRows';

interface ConfirmDeleteTicketTemplateModalProps extends RouteComponentProps {
  integrationModalActions: object;
  integrationModalState: object;
  integrationByIdActions: object;
  reportSelectedRows: any[];
  reportSelectedRowsActions: object;
  orgState: App.OrgState;
}

class ConfirmDeleteTicketTemplateModal extends React.Component<
  ConfirmDeleteTicketTemplateModalProps,
  {}
> {
  onClose = () => {
    this.props.integrationModalActions.closeIntegrationModal();
  };

  handleDeleteTicketModel = () => {
    const { reportSelectedRows, match = {} } = this.props;
    const { params = {} } = match;
    const { integrationId } = params;

    if (reportSelectedRows.length > 0) {
      this.props.integrationByIdActions.deleteTicketTemplate(integrationId, reportSelectedRows);
    }

    this.props.reportSelectedRowsActions.clearSelectedRows();
    this.props.integrationModalActions.closeIntegrationModal();
  };

  render() {
    const { integrationModalState, reportSelectedRows } = this.props;
    const { openedModal } = integrationModalState;

    const ticketTemplateToDelete = reportSelectedRows.map(row => {
      return (
        <li className="font--h7 color--black-light" key={row.id}>
          {row.name} (for project: {row.jiraProjectName})
        </li>
      );
    });

    return (
      <SourceClearModal
        isOpen={!!openedModal[MODEL.DELETE_JIRA_TICKET_TEMPLATE]}
        title="Confirm Delete JIRA ticket template"
        onClose={this.onClose}
        width={600}
      >
        <div className="grid color--black-light pt-">
          <div className="grid__item col-1-1 font--h7">
            <strong>
              {' '}
              Ticket template
              {reportSelectedRows.length === 1 ? '' : 's'} to be deleted:{' '}
            </strong>
            <ul className="pt--"> {ticketTemplateToDelete} </ul>
          </div>

          <div className="grid__item col-1-1 font--h7 pt">
            If you delete this ticket template, the Resolved Status for any existing JIRA issue
            created from this ticket template will no longer update automatically.
            <strong> It cannot be undone.</strong> Are you sure?
          </div>

          <div className="col-1-1 flex flex--justify-content--end align-items--center mt+">
            <div className="col-1-2 text--right">
              <button className="ph mr-" onClick={() => this.onClose()}>
                {' '}
                Cancel{' '}
              </button>
              <button
                onClick={() => this.handleDeleteTicketModel()}
                className="btn--danger-clear ph"
              >
                Delete ticket template
              </button>
            </div>
          </div>
        </div>
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationModalState: state.integrationModalState,
    reportSelectedRows: state.reportSelectedRows,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
    integrationByIdActions: bindActionCreators(integrationByIdActions, dispatch),
    reportSelectedRowsActions: bindActionCreators(reportSelectedRowsActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteTicketTemplateModal)
);
