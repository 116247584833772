import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';

import * as meActions from '~/actions/me';
import * as toastrActions from '~/actions/toastr';

export const IS_SUBMITTING_PAYMENT = 'IS_SUBMITTING_PAYMENT';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PURCHASE_ORDER_REQUEST = 'PURCHASE_ORDER_REQUEST';
export const PURCHASE_ORDER_SUCCESS = 'PURCHASE_ORDER_SUCCESS';
export const PURCHASE_ORDER_ERROR = 'PURCHASE_ORDER_ERROR';
export const SHOW_PURCHASE_ORDER_SUCCESS_MODAL = 'SHOW_PURCHASE_ORDER_SUCCESS_MODAL';
export const REQUEST_PURCHASE_ORDER = 'REQUEST_PURCHASE_ORDER';
export const TOGGLE_PURCHASE_ORDER = 'TOGGLE_PURCHASE_ORDER';
export const UPDATE_PAYMENT_ERROR = 'UPDATE_PAYMENT_ERROR';
export const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS';

export const checkout = (result, planId, orgId, extraDetails) => dispatch => {
  dispatch(updatePaymentError(undefined));
  dispatch(isSubmittingPayment(true));
  const { token = {} } = result;
  const { email, id } = token;
  const subscriptionRequest = {
    productRatePlanChargeId: id,
    billingEmail: email,
    productRatePlanId: planId,
  };

  if (extraDetails.name) subscriptionRequest.name = extraDetails.name;
  if (extraDetails.phone) subscriptionRequest.phoneNumber = extraDetails.phone;

  return ApiService.post(`/orgs/${orgId}/billing/subscribe`, { data: subscriptionRequest })
    .then(
      () => {
        dispatch(paymentSuccess());
        dispatch(toastrActions.clearToastrs());
        dispatch(
          toastrActions.addToastr({
            id: 'UPGRADE_SUCCESS',
            level: 'success',
            title: 'Upgrade success',
            message: 'Thank you for subscribing. Your payment has been received.',
          })
        );

        // fetching me will update the org object with the new planType details
        return dispatch(meActions.fetchMe()).then(() => {
          return { success: true };
        });
      },
      err => {
        if (err && err.message) {
          const errorMessage = err.message || 'Payment service returned an error.';

          dispatch(updatePaymentError(errorMessage));
        } else {
          dispatch(updatePaymentError('Payment service is not available, please try again later'));
        }
        ErrorService.capture('Error updating billing subscription', err);
        return {};
      }
    )
    .catch(error => {
      dispatch(updatePaymentError('Payment service returned an error.'));
      ErrorService.capture('Error updating billing subscription', error);
      return {};
    });
};

export const isSubmittingPayment = bool => ({
  type: IS_SUBMITTING_PAYMENT,
  bool,
});

export const updatePaymentError = message => ({
  type: UPDATE_PAYMENT_ERROR,
  message,
});

export const updatePaymentStatus = (error, complete) => ({
  type: UPDATE_PAYMENT_STATUS,
  error,
  complete,
});

export const paymentSuccess = () => ({
  type: PAYMENT_SUCCESS,
});

export const togglePurchaseOrderOption = () => ({
  type: TOGGLE_PURCHASE_ORDER,
});

export const requestPurchaseOrder = (options, orgId) => dispatch => {
  dispatch(purchaseOrderRequest(options));

  return ApiService.post(`/orgs/${orgId}/billing/request-purchase-order`, { data: options })
    .then(
      (res = {}) => {
        dispatch(purchaseOrderSuccess(true));
        dispatch(showPurchaseOrderSuccessModal(true));
        return { ...res, success: true };
      },
      err => {
        const message = 'Payment service is not available, please try again later';
        if (err && err.message) {
          const errorMessage = err.message || message;
          dispatch(requestPurchaseOrderError(errorMessage));
        } else {
          dispatch(
            requestPurchaseOrderError('Payment service is not available, please try again later')
          );
        }
        ErrorService.capture('Error requesting purchase order', err);
        return {};
      }
    )
    .catch(err => {
      ErrorService.capture('Error requesting purchase order', err);
      return {};
    });
};

export const purchaseOrderRequest = ({ firstName, lastName, email, productPlan }) => ({
  type: PURCHASE_ORDER_REQUEST,
  fullName: `${firstName}, ${lastName}`,
  email,
  productPlan,
  meta: {
    snowplow: true,
    string1: email,
    string2: `${firstName}, ${lastName}`,
    string3: productPlan,
  },
});

export const purchaseOrderSuccess = bool => ({
  type: PURCHASE_ORDER_SUCCESS,
  bool,
});

export const showPurchaseOrderSuccessModal = bool => ({
  type: SHOW_PURCHASE_ORDER_SUCCESS_MODAL,
  bool,
});

export const requestPurchaseOrderError = message => ({
  type: PURCHASE_ORDER_ERROR,
  message,
});
