import React from 'react';

interface IssueSeverityFlagProps {
  severity: number;
  displayStyle: IssueSeverityFlagDisplayStyle;
}

export enum IssueSeverityFlagDisplayStyle {
  NUMBER,
  NUMBER_WITH_TEXT,
  DANGER_SIGN,
}

export function rangeBySeverity(severity: number | null) {
  if (inRange(severity, 0.1, 3.9)) {
    return 'LOW';
  } else if (inRange(severity, 4.0, 6.9)) {
    return 'MEDIUM';
  } else if (inRange(severity, 7.0, 8.9)) {
    return 'HIGH';
  } else if (inRange(severity, 9.0, 10.0)) {
    return 'CRITICAL';
  } else {
    return 'UNKNOWN';
  }
}

export const displayTextBySeverity = {
  CRITICAL: 'Critical',
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
  UNKNOWN: '-',
};

const inRange = (severity: number, lowerBoundInclusive: number, upperBoundInclusive: number) =>
  severity >= lowerBoundInclusive && severity <= upperBoundInclusive;

const IssueSeverityFlag: React.FunctionComponent<IssueSeverityFlagProps> = props => {
  const { severity, displayStyle } = props;
  const colorBySeverity = {
    CRITICAL: 'bg-color--critical',
    HIGH: 'bg-color--danger',
    MEDIUM: 'bg-color--warning',
    LOW: 'bg-color--warning-light',
    UNKNOWN: 'bg-color--muted-light',
  };

  const borderColorBySeverity = {
    CRITICAL: 'border-color--critical',
    HIGH: 'border-color--danger',
    MEDIUM: 'border-color--warning',
    LOW: 'border-color--warning-light',
    UNKNOWN: 'border-color--muted-light',
  };

  const severityRange = rangeBySeverity(severity);
  const color = colorBySeverity[severityRange];
  const displayText = displayTextBySeverity[severityRange];
  const borderColor = borderColorBySeverity[severityRange];
  const severityText = formatSeverity(severity);

  function formatSeverity(severity) {
    const severityString = (severity && severity.toString()) || '0';
    if (!severityString.includes('.')) {
      return `${severity}.0`;
    }

    return `${severity}`;
  }

  return (
    <div className={`flex`}>
      <span className={`flex flex--align-self--center ${color} pv---`}>
        {displayStyle !== IssueSeverityFlagDisplayStyle.DANGER_SIGN ? (
          <strong className={`color--white text--center ${color} pv-- width--40`}>
            {severityText}
          </strong>
        ) : (
          <i
            className={`color--white text--center font--h6 sci ${color} pv-- sci__vulnerability ph-`}
          />
        )}
      </span>
      {displayStyle === IssueSeverityFlagDisplayStyle.NUMBER_WITH_TEXT && (
        <span
          className={`flex align-items--center bo--1 bo-l--0 ph- width--100 font-family--heading color--black bg-color--white ${borderColor}`}
        >
          {displayText}
        </span>
      )}
    </div>
  );
};

export default IssueSeverityFlag;
