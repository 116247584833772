import React from 'react';
import moment from 'moment';

import SeverityFlag from '~/components/ReportComponents/SeverityFlag';
import ReportPagination from '~/components/ReportComponents/ReportPagination';
import Tooltip from '~/components/Tooltip';
import RegistryLink from '~/components/RegistryLink';

interface ReportExpandedVulnViewProps {
  data: object;
  onPageClick: (...args: any[]) => any;
  quickviewId: string;
  quickviewLink: string;
}

const ReportExpandedVulnView: React.FunctionComponent<ReportExpandedVulnViewProps> = props => {
  const { _embedded = {}, page = {} } = props.data;
  const { vulns = [] } = _embedded;

  const vulnEleList = vulns.map((vuln, index) => {
    return (
      <div className="grid grid--middle pv--" key={index}>
        <div className="grid__item col-1-6">
          {vuln.cve !== null && (
            <span>
              {' '}
              CVE-
              {vuln.cve}{' '}
            </span>
          )}
          {vuln.cve === null && <span> No CVE - Premium Data </span>}
        </div>
        <div className="grid__item col-2-5">
          <RegistryLink className="link--obvious" href={vuln._links.vulnerability.href}>
            {' '}
            {vuln.title}{' '}
          </RegistryLink>
        </div>
        <div className="grid__item col-1-10">
          <Tooltip content={vuln.cvss ? `CVS Score: ${vuln.cvss}` : null} id={`${index}-severity`}>
            <SeverityFlag severity={vuln.severity} hideText={true} />
          </Tooltip>
        </div>
        <div className="grid__item">
          {vuln.disclosureDate ? `${moment(vuln.disclosureDate).format('MMM D YYYY')}` : `-`}
        </div>
      </div>
    );
  });

  return (
    <div className="bo-t--1 border-color--muted-light pt pb-">
      <div className="grid pb-">
        <div className="grid__item text--bold col-1-6"> Ref </div>
        <div className="grid__item col-2-5 text--bold"> Vulnerability and Fix </div>
        <div className="grid__item col-1-10 text--bold"> Risk </div>
        <div className="grid__item text--bold"> Disclosure Date </div>
      </div>
      {vulnEleList}

      <ReportPagination
        page={page}
        onPageClick={props.onPageClick}
        quickviewId={props.quickviewId}
        quickviewLink={props.quickviewLink}
      />
    </div>
  );
};

export default ReportExpandedVulnView;
