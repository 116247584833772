const PackageManagerUrlHelper = function () {
  const getPath = (coordinateType, data = {}) => {
    switch (coordinateType) {
      case 'MAVEN':
        return getMvnPath(data.coord1, data.coord2);
      case 'BOWER':
        return getBowerPath(data.repoName);
      case 'GEM':
        return getRubygemPath(data.coord1);
      case 'PYPI':
        return getPypiPath(data.coord1);
      case 'NPM':
        return getNpmPath(data.coord1, data.coord2);
    }
  };

  const getMvnPath = (coord1, coord2) => {
    return `https://mvnrepository.com/artifact/${coord1}/${coord2}`;
  };

  const getMvnPathWithVersion = (coord1, coord2, version) => {
    const path = getMvnPath(coord1, coord2);
    return `${path}/${version}`;
  };

  const getBowerPath = repoName => {
    return `https://github.com/${repoName}`;
  };

  const getRubygemPath = coord1 => {
    return `https://rubygems.org/gems/${coord1}`;
  };

  const getPypiPath = coord1 => {
    return `https://pypi.python.org/pypi/${coord1}`;
  };

  const getNpmPath = coord1 => {
    return `https://www.npmjs.com/package/${coord1}`;
  };

  return {
    getPath,
    getMvnPath,
    getMvnPathWithVersion,
    getBowerPath,
    getRubygemPath,
    getPypiPath,
    getNpmPath,
  };
};

export default new PackageManagerUrlHelper();
