import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import * as popoverActions from '~/actions/popover';
import PopoverWithEscKey, { Placement } from '~/components/PopoverWithEscKey';

interface TooltipProps {
  popoverActions: object;
  popoverState: object;
  id?: string;
  place?: Placement;
  minWidthClass?: string;
  maxWidthClass?: string;
  width?: string;
  className?: string;
  textAlign?: string;
  fullWidth?: boolean;
  tipSize?: number;
  content?: string | object;
  active?: boolean;
  closeOnMouseOut?: boolean;
  theme?: 'light' | 'dark';
  isVCPopover?: boolean;
  children: React.ReactNode;
}

class Tooltip extends React.Component<TooltipProps, {}> {
  componentWillUnmount() {
    const { popoverState, id } = this.props;
    const isOpen = popoverState[id] || false;

    if (isOpen) {
      this.toggleClosed();
    }
  }

  isPopOverOpen = (): boolean => {
    const { popoverState, id } = this.props;
    return popoverState[id] || false;
  };

  toggleOpen = (): void => {
    if (!this.isPopOverOpen()) {
      this.props.popoverActions.openPopover(this.props.id);
    }
  };
  toggleClosed = (): void => {
    if (this.isPopOverOpen()) {
      this.props.popoverActions.closePopover(this.props.id);
    }
  };
  render() {
    const {
      children,
      place = 'bottom',
      minWidthClass = 'min-width--300',
      maxWidthClass = 'max-width--675',
      width,
      textAlign = 'text--left',
      content,
      fullWidth,
      active = true,
      closeOnMouseOut = true,
      tipSize = 7,
      theme = 'dark',
      isVCPopover,
      className,
    } = this.props;

    const isOpen = this.isPopOverOpen();
    const tooltipContent = <div className={textAlign}>{content}</div>;

    return (
      <PopoverWithEscKey
        isOpen={isOpen && active}
        fullWidth={fullWidth}
        body={tooltipContent}
        className={classNames('zIndex-9--dialog', className, minWidthClass, maxWidthClass, width)}
        place={place}
        onOuterAction={this.toggleClosed}
        theme={theme}
        tipSize={tipSize}
        isVCPopover={isVCPopover}
      >
        <div onMouseOver={this.toggleOpen} onMouseOut={closeOnMouseOut ? this.toggleClosed : null}>
          {children}
        </div>
      </PopoverWithEscKey>
    );
  }
}
function mapStateToProps(state) {
  return {
    popoverState: state.popoverState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    popoverActions: bindActionCreators(popoverActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tooltip);
