import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as modalActions from '~/actions/modal';
import { COMMENT_ONLY_MODAL, IGNORE_COMMENT_MODAL } from '~/constants/ModelConstants';
import SourceClearModal from '~/components/SourceClearModal';
import CommentIssueForm from '~/containers/CommentIssueForm';

interface CommentIssueModalProps {
  modalActions: object;
  modalState: object;
  onConfirm: (comment: string) => any;
  onCancel?: (...args: any[]) => any;
}

class CommentIssueModal extends React.Component<CommentIssueModalProps, {}> {
  closeModal = () => {
    const onCancel = this.props.onCancel;
    this.getCommentType() == COMMENT_ONLY_MODAL
      ? this.props.modalActions.closeModal(COMMENT_ONLY_MODAL)
      : this.props.modalActions.closeModal(IGNORE_COMMENT_MODAL);
    if (onCancel != null) {
      onCancel();
    }
  };

  onConfirm = (comment: string) => {
    this.props.onConfirm(comment);

    this.getCommentType() == COMMENT_ONLY_MODAL
      ? this.props.modalActions.closeModal(COMMENT_ONLY_MODAL)
      : this.props.modalActions.closeModal(IGNORE_COMMENT_MODAL);
  };

  getCommentType = () => {
    const { modalState = {} } = this.props;
    const { openedModal = {} } = modalState;

    return !openedModal[IGNORE_COMMENT_MODAL] ? COMMENT_ONLY_MODAL : IGNORE_COMMENT_MODAL;
  };

  render() {
    const { modalState = {} } = this.props;
    const { openedModal = {} } = modalState;

    return (
      <SourceClearModal
        isOpen={!!openedModal[IGNORE_COMMENT_MODAL] || !!openedModal[COMMENT_ONLY_MODAL]}
        title={'Add Comment'}
        onClose={() => this.closeModal()}
        closeWhenClickOutside={false}
        width={500}
      >
        <div>
          <CommentIssueForm
            handleSubmit={this.onConfirm}
            handleCancel={this.closeModal}
            type={this.getCommentType()}
          />
        </div>
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    modalState: state.modalState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentIssueModal);
