import * as MODEL from '~/constants/ModelConstants';

const JiraHelper = function () {
  const getJiraLink = (integrationServiceUrl, jiraKey) => {
    return `${integrationServiceUrl}/browse/${jiraKey}`;
  };

  // Get the labels from the template
  const getJiraLabelFromTemplate = integrationModalState => {
    const { fields = {} } = integrationModalState;
    const labels = fields[`jiraField-labels`];
    let preSelectedVal = labels || '';
    return preSelectedVal.split(',').filter(x => x.length > 0);
  };

  // Get the labels from user's JIRA instance
  const getJiraLabelsFromInstanceWithMetaData = integrationState => {
    const { jiraInfo = {} } = integrationState;
    const { [MODEL.JIRA_LABELS]: labelResponse = {} } = jiraInfo;
    const { data: labels = [], isFetching, err } = labelResponse;

    return {
      val: labels.map(x => x.label),
      isFetching,
      err,
    };
  };

  // Given issue type val, find out the issue for it
  const getJiraIssueTypeByVal = (selectedProject, issueTypeVal) => {
    const { issuetypes = [] } = selectedProject;
    return issuetypes.filter(issue => issue.name === issueTypeVal)[0] || {};
  };

  const getJiraEpicsFormatWithMetaData = integrationState => {
    const { jiraInfo = {} } = integrationState;
    const { [MODEL.JIRA_EPICS]: epicResponse = {} } = jiraInfo;
    const { data: val = [], isFetching, err } = epicResponse;

    return {
      val,
      isFetching,
      err,
    };
  };

  const getJiraReportersFormatWithMetaData = integrationState => {
    const { jiraInfo = {} } = integrationState;
    const { [MODEL.JIRA_REPORTERS]: userResponse = {} } = jiraInfo;
    const { data: val = [], isFetching, err } = userResponse;

    return {
      val,
      isFetching,
      err,
    };
  };

  const getJiraAsigneesFormatWithMetaData = integrationState => {
    const { jiraInfo = {} } = integrationState;
    const { [MODEL.JIRA_ASSIGNEES]: userResponse = {} } = jiraInfo;
    const { data: val = [], isFetching, err } = userResponse;

    return {
      val,
      isFetching,
      err,
    };
  };

  const getJiraStatusFormatWithMetaData = integrationState => {
    const { jiraInfo = {} } = integrationState;
    const { [MODEL.JIRA_STATUS]: statusResponse = {} } = jiraInfo;
    const { data: val = [], isFetching, err } = statusResponse;

    return {
      val,
      isFetching,
      err,
    };
  };

  // Formats both assignee and reporter, since they are of the same source
  const formatUserDropdownList = (dropdownVal = []) => {
    return dropdownVal.map(i => ({ label: i.displayName, value: i.displayName || i.accountId }));
  };

  const formatEpicDropdownList = (dropdownVal = []) => {
    return dropdownVal.map(i => ({ label: i.name ? `${i.name}, ${i.key}` : i.key, value: i.key }));
  };

  const formatPriorityDropdownList = (dropdownVal = []) => {
    return dropdownVal.map(i => ({ label: i.name, value: i.id.toString() }));
  };

  const formatFixVersionDropdownList = (dropdownVal = []) => {
    return dropdownVal.map(i => ({ label: i.name, value: i.id.toString() }));
  };

  const formatStatusDropdownList = (dropdownVal = []) => {
    return dropdownVal.map(i => ({ label: i.name, value: i.name }));
  };

  const formatMultiSelectDropdownList = (dropdownVal = []) => {
    return dropdownVal.map(i => ({ label: i.value, value: i.value }));
  };

  return {
    getJiraLink,
    getJiraLabelFromTemplate,
    getJiraLabelsFromInstanceWithMetaData,
    getJiraEpicsFormatWithMetaData,
    getJiraReportersFormatWithMetaData,
    getJiraAsigneesFormatWithMetaData,
    getJiraStatusFormatWithMetaData,
    getJiraIssueTypeByVal,

    formatUserDropdownList,
    formatEpicDropdownList,
    formatPriorityDropdownList,
    formatFixVersionDropdownList,
    formatStatusDropdownList,
    formatMultiSelectDropdownList,
  };
};

export default new JiraHelper();
