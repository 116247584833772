import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Tooltip from '~/components/Tooltip';
import ProjectDefaultBranch from '~/containers/ProjectDefaultBranch';

import * as projectBranchActions from '~/actions/projectBranch';
import { ProjectBranchState } from '~/reducers/projectBranchState.types';

interface ProjectSettingsDefaultBranchSectionProps {
  projectId: string;
  useLastScannedEnabled: boolean;
  projectBranchState: ProjectBranchState;
  orgState: App.OrgState;
}
class ProjectSettingsDefaultBranchSection extends React.Component<
  ProjectSettingsDefaultBranchSectionProps & ReturnType<typeof mapDispatchToProps>
> {
  saveDefaultBranchSettings = () => {
    const { projectId, projectBranchState } = this.props;
    const { selectedDefaultBranch } = projectBranchState;

    this.props.projectBranchActions.updateDefaultBranch(projectId, selectedDefaultBranch);
  };

  getErrorTooltip(tooltipId, errorMessage) {
    return (
      <div className="inline pl--">
        <Tooltip id={`${tooltipId}`} content={errorMessage}>
          <i className="sci__alerts sci color--warning font--16" />
        </Tooltip>
      </div>
    );
  }

  render() {
    const { projectId, useLastScannedEnabled, projectBranchState } = this.props;
    const { updateBranchState, branchState } = projectBranchState;
    const { isFetchingBranches = false, fetchBranchErr } = branchState;
    const { isUpdating = false, updateSuccess = false, updateError } = updateBranchState;

    return (
      <div>
        <h6 data-automation-id="ProjectSettingsDefaultBranchSection-Title" className="text--uppercase bo-b--1 border-color--muted-light pb---">Default Branch</h6>
        {useLastScannedEnabled ? (
          <>
            <p>
              The Veracode Platform displays issues, vulnerabilities, libraries, and licenses only
              from the default branch of each project on workspace summary pages. Additionally, when
              vulnerabilities are discovered or updated, Veracode checks the default branch for
              affected libraries and creates or updates issues as needed.
            </p>

            <p>
              Select Use Last Scanned to automatically update your default branch to the most
              recently scanned branch or tag.
            </p>
          </>
        ) : (
          <p>
            When a default branch is set, we will only display issues, vulnerabilities, libraries or
            licenses from the default branch. This will show up in two ways, the first being any
            list views will only show the items from the default branch when the the list is first
            loaded. Second, all of the counts across your workspace will only include counts from
            the default branch.
          </p>
        )}

        <div className="flex flex--align-items--center">
          <div className="col-1-3 inline-block">
            <ProjectDefaultBranch projectId={projectId} saveOnChange={false} />
          </div>
          <button
            className={`btn btn--success ml-- inline-block p-`}
            type="submit"
            onClick={() => this.saveDefaultBranchSettings()}
            disabled={isUpdating || isFetchingBranches}
          >
            Save
          </button>
          {isUpdating && (
            <i className="fa sci--sm fa-spin fa-spinner mh- color--primary font--14 ph0" />
          )}
          {updateSuccess && (
            <i className="fa sci--sm sci sci__check mh- color--primary font--14 ph0" />
          )}
          {updateError && this.getErrorTooltip(`update-default-branch-err`, updateError)}
          {fetchBranchErr && this.getErrorTooltip(`fetch-branch-err`, fetchBranchErr)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projectBranchState: state.projectBranchState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    projectBranchActions: bindActionCreators(projectBranchActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettingsDefaultBranchSection);
