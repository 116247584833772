import FileSaver from 'file-saver';

const PdfHelper = function () {
  const startPdfDownload = (blobData, teamName) => {
    FileSaver.saveAs(blobData, `WorkspaceReport_${teamName}.pdf`);
  };

  return { startPdfDownload };
};

export default new PdfHelper();
