import config from '~/config';
import React, { Fragment } from 'react';
import VeracodeFooterLogo from '~/images/sca-veracode-logo-footer.png';

interface FooterProps {
  organization?: {
    sso?: boolean;
  };
}

const Footer: React.FunctionComponent<FooterProps> = props => {
  const { organization = {} } = props;
  const { sso = false } = organization;

  return (
    <div className="container--full nav--footer bo-t--1 border-color--white">
      <section className="container color--white desk--pv tablet--pl phone--pt">
        <div className="grid grid--middle">
          <div className="grid__item col-5-6 tablet--col-1-1 phone--col-1-1">
            <div className="grid grid--middle grid--full">
              <div className="grid__item col-1-12 tablet--col-1-3 phone--col-1-1 phone--pl ">
                <div className="grid grid--middle">
                  <div className="grid__item col-1-1 tablet--col-1-4 phone--col-1-2">
                    <img src={VeracodeFooterLogo} alt="Veracode" className="vc-logo-footer" />
                  </div>
                </div>
              </div>

              <div className="grid__item col-11-12 tablet--col-2-3 phone--col-1-1">
                <div className="top--nav flex flex--align-items--center flex--justify-content--start phone--flex--flex-direction--column tablet--flex--flex-wrap--wrap color--white">
                  {!sso && (
                    <Fragment>
                      <div className="navbar__item tablet--bo-bl--1  phone--col-1-1 tablet--col-1-3">
                        <a
                          href={`${config.VC_WWW_URL}/about-us`}
                          className="btn color--white inline-block col-1-1 text--center pv- font--h6"
                        >
                          About us
                        </a>
                      </div>
                      <div className="nav--divider phone--hide" />
                      <div className="navbar__item tablet--bo-rbl--1  phone--col-1-1 phone--bo-t--1 tablet--col-1-3">
                        <a
                          href={`${config.VC_INFO_URL}/web-contact-us.html`}
                          className="btn color--white inline-block col-1-1 text--center pv- font--h6"
                        >
                          Contact
                        </a>
                      </div>
                      <div className="nav--divider phone--hide" />
                      <div className="navbar__item tablet--bo-b--1  phone--col-1-1 phone--bo-t--1 tablet--col-1-3">
                        <a
                          href={`${config.VC_WWW_URL}/blog`}
                          target="_blank"
                          className="btn color--white inline-block col-1-1 text--center pv- font--h6"
                        >
                          Blog
                        </a>
                      </div>
                      <div className="nav--divider phone--hide" />
                    </Fragment>
                  )}
                  <div className="navbar__item  phone--col-1-1 phone--bo-t--1 tablet--bo-l--1 tablet--col-1-3">
                    <a
                      href="https://status.srcclr.net/"
                      target="_blank"
                      className="btn color--white inline-block col-1-1 text--center pv- font--h6"
                    >
                      Status
                    </a>
                  </div>
                  <div className="nav--divider phone--hide" />
                  <div className="navbar__item  phone--col-1-1 phone--bo-t--1 tablet--bo-l--1 tablet--col-1-3">
                    <a
                      href={`${config.VC_WWW_URL}/about/careers`}
                      className="btn color--white inline-block col-1-1 text--center pv- font--h6"
                      target="_blank"
                    >
                      Jobs
                    </a>
                  </div>
                  <div className="nav--divider phone--hide" />
                  <div className="navbar__item tablet--col-1-3 tablet--bo-rl--1  phone--col-1-1 phone--bo-t--1">
                    <a
                      href={'mailto:support@veracode.com?subject=Request For SourceClear Support'}
                      className="btn color--white inline-block col-1-1 text--center pv- font--h6"
                    >
                      Support
                    </a>
                  </div>
                  <div className="nav--divider phone--hide" />
                  <div className="navbar__item tablet--col-1-3 tablet--bo-rl--1  phone--col-1-1 phone--bo-t--1">
                    <a
                      href="https://help.veracode.com"
                      className="btn color--white inline-block col-1-1 text--center pv- font--h6"
                    >
                      Docs
                    </a>
                  </div>
                  <div className="nav--divider phone--hide" />
                  <div className="navbar__item tablet--col-1-3 tablet--bo-rl--1  phone--col-1-1 phone--bo-t--1">
                    <a
                      href={`${config.VC_WWW_URL}/legal-privacy`}
                      className="btn color--white inline-block col-1-1 text--center pv- font--h6"
                    >
                      Legal
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!sso && (
            <div className="grid__item col-1-6 tablet--hide phone--hide">
              <div className="grid grid--middle grid--narrow grid--right">
                <div className="grid__item col-1-4">
                  <a
                    className="mh- color--white a--footer"
                    target="_blank"
                    href="https://twitter.com/veracode"
                  >
                    <i className="fab fa-twitter-square color-hover--primary sci--lg" />
                  </a>
                </div>
                <div className="grid__item col-1-4">
                  <a
                    className="mh- color--white a--footer"
                    target="_blank"
                    href="https://www.linkedin.com/company/veracode"
                  >
                    <i className="fab fa-linkedin color-hover--primary sci--lg" />
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Footer;
