import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import WebhookNotificationsListSection from '~/containers/WebhookNotificationsListSection';

import {
  NotificationsPageProps,
  NotificationPageMatchParams,
} from '~/containers/NotificationsPage.types';
class NotificationsPage extends Component<NotificationsPageProps> {
  render() {
    const { match } = this.props;
    const { params }: { params: NotificationPageMatchParams } = match;
    const { teamId } = params;

    /**
     * This component can be a wrapper for lists of different types of notifications.
     * Currently, we are listing the webhook notifications.
     */
    return (
      <div className="grid mt mb++">
        <Helmet>
          <title>Webhook Notifications</title>
        </Helmet>
        <div className="grid__item col-1-1 mb">
          <h3 data-automation-id="NotificationsPage-Title">Notifications</h3>
        </div>
        <WebhookNotificationsListSection teamId={teamId} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    workspaceNotificationsSettingsState: state.workspaceNotificationsSettingsState,
    orgState: state.orgState,
  };
}

export default connect(mapStateToProps)(NotificationsPage);
