import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import config from '~/config';
import * as billingActions from '~/actions/billing';
import * as paymentActions from '~/actions/payment';
import * as toastrActions from '~/actions/toastr';

interface UpdatePaymentStripeFormProps {
  billingActions: object;
  paymentActions: object;
  toastrActions: object;
  onClose: (...args: any[]) => any;
  successCallback?: (...args: any[]) => any;
  billingState: object;
  orgState: App.OrgState;
  paymentState: object;
}
class UpdatePaymentStripeForm extends React.Component<UpdatePaymentStripeFormProps, {}> {
  constructor(props) {
    super(props);
    this.stripe = Stripe(config.STRIPE_PUBLISHABLE_KEY);
    this.elements = this.stripe.elements();
    const stripeStyles = {
      base: {
        fontWeight: 300,
        fontSize: '16px',
        lineHeight: '24px',
        '::placeholder': {
          color: '#8698A1',
        },
      },
    };

    this.cardNumber = this.elements.create('cardNumber', {
      style: stripeStyles,
    });
    this.cardExpiry = this.elements.create('cardExpiry', {
      style: stripeStyles,
    });
    this.cardCvc = this.elements.create('cardCvc', { style: stripeStyles });
    this.postalCode = this.elements.create('postalCode', {
      style: stripeStyles,
    });
  }

  componentDidMount() {
    this.cardNumber.mount('#card-number');
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc.mount('#card-cvc');
    this.postalCode.mount('#postal-code');
  }

  stripe: any = null;
  elements: any = null;
  cardNumber: any = null;
  cardExpiry: any = null;
  cardCvc: any = null;
  postalCode: any = null;

  submitForm(e) {
    e.preventDefault();
    const { billingState, orgState, paymentState } = this.props;
    const { activePlanId } = paymentState;
    const { org = {} } = orgState;
    const { account = {} } = billingState;
    const extraDetails = {
      name: account.name || '',
      phoneNumber: account.phoneNumber || '',
      billingEmail: account.email || '',
    };

    this.stripe.createToken(this.cardNumber, extraDetails).then(result => {
      if (result.error) {
        this.props.paymentActions.updatePaymentError(result.error.message);
      } else {
        this.props.billingActions.submitPaymentMethod(result, activePlanId, org.id).then(res => {
          if (res.success && this.props.successCallback) {
            this.props.successCallback();
          }
        });
      }
    });
  }

  closeModal() {
    this.props.onClose();
  }

  render() {
    const { paymentState } = this.props;
    const { errorMessage } = paymentState;

    return (
      <form onSubmit={e => this.submitForm(e)} className="grid">
        <label
          htmlFor="card-number"
          className="grid__item mt- mb0 flex align-items--center col-1-3"
        >
          Card Number
        </label>
        <div className="grid__item col-2-3 mt- flex align-items--center">
          <div id="card-number" className="bo--1 col-1-1 ph- border-color--muted-dark" />
        </div>
        <label
          htmlFor="card-expiry"
          className="grid__item mt- mb0 flex align-items--center col-1-3"
        >
          Expiration Date
        </label>
        <div className="grid__item col-2-3 mt- flex align-items--center">
          <div id="card-expiry" className="bo--1 col-1-1 ph- border-color--muted-dark" />
        </div>
        <label htmlFor="card-cvc" className="grid__item mt- mb0 flex align-items--center col-1-3">
          CVC
        </label>
        <div className="grid__item col-2-3 mt- flex align-items--center">
          <div id="card-cvc" className="bo--1 col-1-1 ph- border-color--muted-dark" />
        </div>
        <label
          htmlFor="postal-code"
          className="grid__item mt- mb0 flex align-items--center col-1-3"
        >
          Postal Code
        </label>
        <div className="grid__item col-2-3 mt- flex align-items--center">
          <div id="postal-code" className="bo--1 col-1-1 ph- border-color--muted-dark" />
        </div>
        <div className="grid__item col-1-1">
          <div className={`mt- color--danger ${errorMessage ? 'is-showing-100' : 'is-hiding'}`}>
            {errorMessage}
          </div>
        </div>
        <div className="grid__item col-1-1 mt flex flex--justify-content--end">
          <button className="pv- col-1-5 mr-" onClick={() => this.closeModal()}>
            Cancel
          </button>
          <button className="btn--primary-clear pv- col-1-5" type="submit">
            Save
          </button>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    billingState: state.billingState,
    orgState: state.orgState,
    paymentState: state.paymentState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    billingActions: bindActionCreators(billingActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
    toastrActions: bindActionCreators(toastrActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentStripeForm);
