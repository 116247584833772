import React from 'react';
import ProIcon from '~/components/ProIcon';

interface ReportDetailsPageTabItemProps {
  isActive: boolean;
  onClick: (...args: any[]) => any;
  label: string;
  field: string;
  link?: string;
  showProIcon?: boolean;
}

const ReportDetailsPageTabItem: React.FunctionComponent<ReportDetailsPageTabItemProps> = props => {
  const { isActive, label, field, link = '', showProIcon = false } = props;
  let defaultBtnClass = 'btn';

  if (isActive) {
    defaultBtnClass = 'bg-color--black-light color--white';
  }

  function handleClick() {
    props.onClick(field, link);
  }

  return (
    <a className={`${defaultBtnClass} ph pv- bo-rad--0`} onClick={handleClick}>
      {showProIcon && <ProIcon className={isActive ? 'bg-color--black' : ''} />} {label}
    </a>
  );
};

export default ReportDetailsPageTabItem;
