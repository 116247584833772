import * as actions from '~/actions/repoScope';

const defaultState = {
  commit: null,
  branch: null,
  tag: null,
};

const repoScope = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_REPO_SCOPE:
      return {
        ...defaultState,
        [action.field]: action.value,
      };
    case actions.RESET_REPO_SCOPE:
      return defaultState;
    default:
      return state;
  }
};

export default repoScope;
