import * as actions from '~/actions/message';
import _ from 'lodash';

const defaultState = {
  messages: {},
};

const messageState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.ADD_MESSAGE: {
      const newMessages = _.cloneDeep(state.messages);

      newMessages[action.message.type] = action.message;
      return Object.assign({}, state, {
        messages: newMessages,
      });
    }
    case actions.REMOVE_MESSAGE: {
      const newMessages = _.cloneDeep(state.messages);

      newMessages[action.messageType] = undefined;
      return Object.assign({}, state, {
        messages: newMessages,
      });
    }
    case actions.CLEAR_MESSAGES:
      return Object.assign({}, state, {
        messages: {},
      });
    default:
      return state;
  }
};

export default messageState;
