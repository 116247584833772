import React from 'react';

import ProjectSettingsDeleteProjectSection from '~/containers/ProjectSettingsDeleteProjectSection';

interface ProjectSettingsOtherPageProps {
  projectId: string;
}
const ProjectSettingsOtherPage: React.FunctionComponent<ProjectSettingsOtherPageProps> = props => {
  const { projectId } = props;

  return (
    <div>
      <ProjectSettingsDeleteProjectSection projectId={projectId} />
    </div>
  );
};

export default ProjectSettingsOtherPage;
