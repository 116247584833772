import React from 'react';

interface InviteOrAddUsersProps {
  rawEmailsValue: string;
  validEmails: any[];
  invalidEmails: any[];
  removeUser: (...args: any[]) => any;
  toggleWorkspaceAdmin: (...args: any[]) => any;
  addEmails: (...args: any[]) => any;
  handleEmailsInput: (...args: any[]) => any;
}

const InviteOrAddUsers: React.FunctionComponent<InviteOrAddUsersProps> = props => {
  const { rawEmailsValue = '', validEmails = [], invalidEmails = [] } = props;
  function removeUser(email) {
    props.removeUser(email);
  }

  function toggleWorkspaceAdmin(email) {
    props.toggleWorkspaceAdmin(email);
  }

  function handleEmailsInput(e) {
    props.handleEmailsInput(e.target.value);
  }

  function addEmails() {
    props.addEmails();
  }

  return (
    <div className="grid grid--narrow">
      <div className="grid__item col-5-6 flex mt-">
        <textarea
          type="text"
          placeholder="Enter email addresses separated by commas, spaces, or newlines."
          className="control--text col-1-1 border-color--muted"
          value={rawEmailsValue}
          onChange={e => handleEmailsInput(e)}
          rows={5}
        />
      </div>
      <div className="grid__item col-1-6 flex align-items--end mt-">
        <button className="btn--primary-clear col-1-1 pv--" onClick={() => addEmails()}>
          Add
        </button>
      </div>
      {invalidEmails.length ? (
        <div className="grid__item col-1-1 mt-- color--danger">
          The following emails are invalid: {invalidEmails.join(', ')}
        </div>
      ) : (
        ''
      )}
      <div className={`grid__item col-1-1 ${validEmails.length ? 'is-showing-250' : 'is-hiding'}`}>
        <div className="bo--1 border-color--muted mt-">
          <div className="grid grid--full">
            <div className="grid__item col-1-24" />
            <div className="grid__item flex align-items--center col-2-5">
              <strong>Email</strong>
            </div>
            <div className="grid__item flex align-items--center">
              <strong>First Name</strong>
            </div>
            <div className="grid__item flex align-items--center">
              <strong>Last Name</strong>
            </div>
            <div className="grid__item flex align-items--center text--center">
              <strong>Workspace Admin</strong>
            </div>
            <div className="grid__item col-1-18" />
          </div>

          <div className="max-height--150 overflow--auto">
            {validEmails.map((user, index) => {
              const { email, firstName, lastName, role, id: userId } = user;

              return (
                <div className="grid grid--full hover-row mv-- mt-" key={`user-${index}`}>
                  <div className="grid__item col-1-24 flex justify-content--center align-items--center">
                    <i className={`fas fa-user${userId ? '' : '-o'}`} />
                  </div>
                  <div className="grid__item col-2-5 text--overflow">{email}</div>
                  <div className="grid__item">{firstName || '--'}</div>
                  <div className="grid__item">{lastName || '--'}</div>
                  <div className="grid__item text--center">
                    <label className="label--checkbox flex justify-content--center mb0">
                      <input
                        type="checkbox"
                        checked={role === 'WORKSPACE_ADMIN'}
                        onChange={() => toggleWorkspaceAdmin(email)}
                      />
                      <span className="react--checkbox mr0" />
                    </label>
                  </div>
                  <div className="grid__item col-1-18 justify-content--center align-items--center flex">
                    <button
                      className="p--- flex align-items--center"
                      onClick={() => removeUser(email)}
                    >
                      <i className="sci sci__trash color--danger-hover-row" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-1-1 flex">
          <div className="mt- ml--- flex align-items--center">
            <i className="fas fa-user mr--" /> Existing user
          </div>
          <div className="mt- ml- flex align-items--center">
            <i className="fas fa-user-o mr--" /> New user
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteOrAddUsers;
