import React from 'react';
import { connect } from 'react-redux';

import PaymentForm from '~/containers/PaymentForm';
import PlanDetailsList from '~/components/PlanDetailsList';
import proSVG from '~/images/plan-type--pro.svg';

import { RouteComponentProps } from 'react-router-dom';

interface PaymentPageProps extends RouteComponentProps {
  orgState: App.OrgState;
}
class PaymentPage extends React.Component<PaymentPageProps, {}> {
  componentDidMount() {
    const { orgState } = this.props;
    const { org = {} } = orgState;
    const { permissions = {} } = org;
    const { billing } = permissions;

    if (!billing) {
      history.replace('/org/settings/no-owner');
    }
  }

  render() {
    return (
      <div className="grid grid--wide mt+ mb++">
        <div className="grid__item col-1-2">
          <PaymentForm />
        </div>
        <div className="grid__item col-1-2 flex justify-content--center">
          <div className="flex col-4-5 bg-color--white-dark pb++">
            <div className="text--center col-1-1">
              <div className="font--h6 pb- mt">
                <strong>SourceClear Business</strong>
              </div>
              <PlanDetailsList tier="business" />
              <div className="mt flex">
                <div className="position--relative">
                  <img
                    src={proSVG}
                    alt=""
                    className="height--100 position--absolute"
                    style={{ left: '-30px' }}
                  />
                </div>
                <div className="col-2-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgState: state.orgState,
  };
}

export default connect(mapStateToProps)(PaymentPage);
