import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Helmet from 'react-helmet';
import Select from 'react-select';
import { RouteComponentProps } from 'react-router-dom';

import { errorMessageTextMap, integrationPrettyNameMap } from '~/constants/ModelConstants';
import Helpers from '~/utils/Helpers';
import SortHelper from '~/utils/SortHelper';

import AgentActivitySidebar from '~/containers/AgentActivitySidebar';
import AgentTokenControl from '~/components/AgentTokenControl';
import AgentUpgradeModal from '~/containers/AgentUpgradeModal';
import CopyToClipboard from '~/containers/CopyToClipboard';
import SidebarWrapper from '~/containers/SidebarWrapper';
import SourceClearLoader from '~/components/SourceClearLoader';
import SourceClearModal from '~/components/SourceClearModal';

import * as agentActions from '~/actions/agents';
import * as sidebarActions from '~/actions/sidebar';
import { RootState } from '~/reducers';
import { AgentState } from '~/reducers/agentState.types';

interface AgentConfigPageProps extends RouteComponentProps {
  agentState: AgentState;
  clipboardState: object;
  navigationState: object;
  messageState: object;
  sidebarState: object;
  teamState: App.TeamState;
  validAgentName: boolean;
}

interface AgentConfigPageState {
  clicked: boolean;
}

class AgentConfigPage extends Component<
  AgentConfigPageProps & ReturnType<typeof mapDispatchToProps>,
  AgentConfigPageState
> {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
  }
  componentDidMount() {
    const { teamState } = this.props;
    const { teams = [] } = teamState;

    if (teams.length) {
      this.props.agentActions.updateSelectedWorkspace({
        value: teams[0].id,
        label: teams[0].name,
      });
    }
    this.fetchAgentData();
  }

  componentDidUpdate(prevProps) {
    const prevAgentId = prevProps.match.params.agentId;
    const newAgentId = this.props.match.params.agentId;

    if (prevAgentId !== newAgentId) {
      this.fetchAgentData();
    }
  }

  componentWillUnmount() {
    this.props.agentActions.stopPolling();
    this.props.agentActions.clearRegeneratedToken();
    this.props.agentActions.resetAgentName();
    this.props.sidebarActions.closeSidebar('agent-activity');
  }

  fetchAgentData() {
    const { match } = this.props;
    const { params = {} } = match;
    const { agentId, teamId } = params;

    if (agentId) {
      this.props.agentActions.fetchAgentData(agentId, teamId);
    }
  }

  saveAgentName(e) {
    e.preventDefault();
    const { agentState, match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    const { editableAgentName, activeAgent = {} } = agentState;

    this.props.agentActions.saveAgentName(editableAgentName, activeAgent, teamId).then(res => {
      if (res.success) {
        this.showModal('');
      }
    });
  }

  updateAgentName(e) {
    const { value } = e.target;

    this.props.agentActions.updateAgentName(value);
  }

  cancelNameUpdate() {
    this.props.agentActions.resetAgentName();
    this.showModal('');
  }

  regenerateToken() {
    const { agentState } = this.props;
    const { activeAgent = {} } = agentState;

    this.props.agentActions.regenerateToken(activeAgent.id);
  }

  async confirmDelete(e) {
    e.preventDefault();
    const { agentState, match, history } = this.props;
    const { activeAgent } = agentState;
    const { params = {} } = match;
    const { teamId } = params;
    this.setState({ clicked: true });
    const res = await this.props.agentActions.confirmDelete(activeAgent, teamId);
    if (res.success) {
      this.showModal('');
      history.replace(teamId ? `/workspaces/${teamId}/agents` : '/org/settings/agents');
    } else {
      // an error occurred deleting agent so re-enable the button
      this.setState({ clicked: false });
    }
  }

  showModal(modalType) {
    this.props.agentActions.showModal(modalType);
  }

  toggleSidebar() {
    const { sidebarState } = this.props;
    const id = 'agent-activity';

    if (sidebarState[id]) {
      this.props.sidebarActions.closeSidebar(id);
    } else {
      this.props.sidebarActions.openSidebar(id);
    }
  }

  handleTokenGeneration() {
    const { agentState } = this.props;
    const { activeAgent = {} } = agentState;

    this.props.agentActions.regenerateToken(activeAgent.id);
  }

  toggleDownloadOption(option) {
    this.props.agentActions.updateInstallOption(option);
  }

  updateSelectedWorkspace(workspace) {
    this.props.agentActions.updateSelectedWorkspace(workspace);
  }

  render() {
    const { agentState, teamState, match } = this.props;
    const { params = {} } = match;
    const { teamId, agentId } = params;
    const { teams = [] } = teamState;
    const {
      activeAgent = {},
      editableAgentName = '',
      modalType,
      regeneratedTokenObj: tokenObj,
      agentReleases = {},
      activeInstallOption,
      selectedWorkspace = {},
      validAgentName,
    } = agentState;
    const teamOptions = teams.map(team => {
      return { value: team.id, label: team.name };
    });
    const { name, lastScan, agentVersion, type, createdDate, permissions = {} } = activeAgent;
    const { update, delete: deleteAgent } = permissions;
    const createdBy = activeAgent.createdBy || {};
    const agentSpecificLatest = agentReleases[type] && agentReleases[type].version;
    const isUpToDate =
      agentVersion && agentSpecificLatest
        ? SortHelper.getVersionSortValue(agentReleases[type].version) <=
          SortHelper.getVersionSortValue(agentVersion)
        : false;
    const reportPermission = Helpers.hasReportPermission({ teams, teamId });

    const agentNameError = !validAgentName ? errorMessageTextMap.agentNameError : '';
    const shouldDisableButton =
      editableAgentName.length < 3 || editableAgentName.length > 20 || !!agentNameError;

    if (!activeAgent.id) {
      return (
        <div className="mt">
          <SourceClearLoader />
        </div>
      );
    }

    return (
      <div className="grid mb++ mt col-1-1">
        <Helmet>
          <title>{teamId ? 'Workspace Agent' : 'Agent'}</title>
        </Helmet>
        <div className="grid__item col-1-1 flex justify-content--space-between">
          <div className="flex align-items--baseline" data-automation-id="AgentConfigPage-Title">
            <div className="font--h3">Agent</div>
            <div className="ml- font--h4 color-black-light">
              <strong>{name}</strong>
            </div>
            {update && (
              <div className="ml-- flex align-self--stretch align-items--end mb---">
                <button
                  className="color--muted-dark"
                  onClick={() => this.showModal('EDIT_AGENT_NAME')}
                >
                  <i className="sci sci__pencil" />
                </button>
              </div>
            )}
            {deleteAgent && (
              <div className="flex align-self--stretch align-items--end mb---">
                <button
                  className="color--muted-dark"
                  onClick={() => this.showModal('DELETE_AGENT')}
                >
                  <i className="sci sci__trash" />
                </button>
              </div>
            )}
          </div>
          {reportPermission && (
            <div className="flex align-items--center">
              <div
                className="link--obvious link--no-underline font--16 cursor--pointer flex align-items--center"
                onClick={() => this.toggleSidebar()}
                data-automation-id="AgentConfigPage-ShowActivity"
              >
                <i className="fas fa-clock mr--" /> Show Activity
              </div>
            </div>
          )}
        </div>
        <div className="grid__item col-1-1 mt--">
          <div className="bg-color--black-light color--white">
            <div className="grid p-">
              <div className="grid__item col-1-1">
                <div className="font--18 pb---">AGENT DETAILS</div>
              </div>
              <div className="grid__item col-1-2">
                <div className="flex mt-">
                  <strong>CI Type:</strong>
                  <div className="ml--">{integrationPrettyNameMap[type.toLowerCase()] || '--'}</div>
                </div>
                <div className="flex mt-">
                  <strong>Created By:</strong>
                  <div className="ml--">
                    {createdBy.id ? Helpers.formatUserName(createdBy) : '--'}
                  </div>
                </div>
                <div className="flex mt-">
                  <strong>Created Date:</strong>
                  <div className="ml--">{Helpers.formatDate(createdDate)}</div>
                </div>
              </div>
              <div className="grid__item col-1-2">
                <div className="flex mt-">
                  <strong>Latest Scan:</strong>
                  <div className="ml--">
                    {lastScan ? Helpers.formatDate(lastScan) : 'No scan data'}
                  </div>
                </div>
                <div className="flex mt-">
                  <strong>Version:</strong>
                  <div className="ml-- flex">
                    {agentVersion || '--'}
                    {!isUpToDate && agentSpecificLatest && (
                      <div className="ml-">
                        <button
                          className="btn--primary pv--- ph- -mt--"
                          onClick={() => this.showModal('GET_LATEST')}
                        >
                          Get Latest ({agentSpecificLatest})
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1 mt">
          <div className="font--h5">AGENT TOKEN</div>
        </div>
        <div className="grid__item col-4-5 mt-">
          {teamId ? (
            <div>
              In order for the SourceClear agent to connect to a specific workspace in your
              organization, SourceClear uses an agent auth token which acts as a password.
            </div>
          ) : (
            <div>
              To connect to your organization during scanning, SourceClear uses an agent auth token
              which acts as a password.
            </div>
          )}
          {teamId ? (
            <div className="mt--">
              If another user gets access to your token, that person will be able to use the
              SourceClear agent as if they were you. They can scan into your workspace, which taints
              your data. Keep your token private.
            </div>
          ) : (
            <div className="mt--">
              If another user gets access to your token, that person will be able to use the
              SourceClear agent as if they were you. If they can identify a workspace in your
              organization, they can scan into that workspace, which taints your data. Keep your
              token private.
            </div>
          )}
          {update && (
            <Fragment>
              <div className="mt--">
                You may want to regenerate this token if you believe it was compromised.
                Regenerating a token will invalidate the old token. Any agents using this token will
                no longer be able to scan. For detailed instructions on how to use this token in
                your specific setup, please{' '}
                <a
                  href="https://help.veracode.com/go/c_sc_agent_management"
                  target="_blank"
                  className="link--obvious"
                >
                  refer to the documentation
                </a>
                .
              </div>
              <div className="mt-">
                <AgentTokenControl
                  token={tokenObj.access_token}
                  handleTokenGeneration={() => this.handleTokenGeneration()}
                  isRegenerate={true}
                />
              </div>
              <div className="mt- bo--1 border-color--primary bg-color--primary-extra-light flex align-items--center color--primary-dark col-1-1 pv- ph--">
                <i className="fas fa-info-circle" />
                <div className="ml-">
                  Note: when you regenerate this token, it will only be displayed once. Please keep
                  it safe. Remember to update your settings with the new token.
                </div>
              </div>
            </Fragment>
          )}
        </div>

        {!teamId && (
          <div className="grid__item col-4-5 mt mb++">
            <div className="font--h5">SELECTING A WORKSPACE</div>
            <div className="mt--">
              This is an organization-level agent. It can be used to scan into any workspace.
            </div>
            <div className="mt-">
              To change the target workspace, replace the existing workspace "slug" with the desired
              workspace slug below. The slug is the string that follows <strong>--ws=</strong> in
              the terminal, or <strong>SRCCLR_WORKSPACE_SLUG=</strong> in your environment
              variables.
            </div>
            <div className="grid mt-">
              <div className="grid__item col-1-2">Target Workspace:</div>
              <div className="grid__item col-1-2">Workspace Slug:</div>
              <div className="grid__item col-1-2 mt---">
                <Select
                  name="workspace-selector"
                  className="policy col-1-1 srcclr-react-select-container"
                  classNamePrefix={'srcclr-react-select'}
                  value={teamOptions.find(option => option.value === selectedWorkspace)}
                  options={teamOptions}
                  onChange={workspace => this.updateSelectedWorkspace(workspace)}
                  isClearable={false}
                />
              </div>
              <div className="grid__item col-1-5 mt---">
                <CopyToClipboard
                  id="selected-workspace"
                  inputWidth="col-3-4"
                  value={selectedWorkspace.value || ''}
                />
              </div>
            </div>
          </div>
        )}

        <SourceClearModal
          isOpen={modalType === 'EDIT_AGENT_NAME'}
          onClose={() => this.cancelNameUpdate()}
          width={500}
          title="Rename Agent"
        >
          <form className="grid mt" onSubmit={e => this.saveAgentName(e)}>
            <div className="grid__item col-1-3 flex align-items--center">Agent Name</div>
            <div className="grid__item col-2-3 flex align-items--center">
              <input
                type="text"
                className="control--text col-1-1"
                value={editableAgentName}
                onChange={e => this.updateAgentName(e)}
              />
            </div>
            <div
              className={`grid__item col-1-1 color--danger ${
                agentNameError ? 'is-showing-50' : 'is-hiding'
              }`}
            >
              <div className="mt--">{agentNameError}</div>
            </div>
            <div className="grid__item col-1-1 mt flex justify-content--end">
              <button
                className="pv- col-1-5 mr-"
                onClick={() => this.cancelNameUpdate()}
                type="button"
              >
                Cancel
              </button>
              <button
                className="btn--primary pv- col-1-5"
                type="submit"
                disabled={shouldDisableButton}
              >
                Save
              </button>
            </div>
          </form>
        </SourceClearModal>

        <SourceClearModal
          isOpen={modalType === 'DELETE_AGENT'}
          onClose={() => this.showModal('')}
          width={500}
          title="Delete Agent"
        >
          <form className="grid mt" onSubmit={e => this.confirmDelete(e)}>
            <div className="grid__item col-1-1">
              Are you sure you want to delete <strong>{name}</strong>? Deleting agents is permanent
              and cannot be undone. When you delete an agent, any subsequent scans using the token
              for this agent will fail.
            </div>
            <div className="grid__item col-1-1 mt flex justify-content--end">
              <button className="pv- col-1-5 mr-" onClick={() => this.showModal('')} type="button">
                Cancel
              </button>
              <button
                className="btn--danger pv- col-1-5"
                type="submit"
                disabled={this.state.clicked}
              >
                Delete Agent
              </button>
            </div>
          </form>
        </SourceClearModal>

        <SourceClearModal
          isOpen={modalType === 'GET_LATEST'}
          onClose={() => this.showModal('')}
          width={500}
          title="Get Latest Agent"
        >
          <AgentUpgradeModal
            showModal={modalType => this.showModal(modalType)}
            type={type}
            activeInstallOption={activeInstallOption}
            toggleDownloadOption={option => this.toggleDownloadOption(option)}
          />
        </SourceClearModal>

        <SidebarWrapper
          id="agent-activity"
          title={
            <div className="flex align-items--center font--h6 text--bold">
              <i className="fas fa-clock mr- font--h4 color--primary" /> Agent Activity
            </div>
          }
          pullRight={true}
          sidebarClassName="bg-color--white-medium width--450"
          shadow={true}
        >
          <AgentActivitySidebar sidebarId="agent-activity" agentId={agentId} teamId={teamId} />
        </SidebarWrapper>
      </div>
    );
  }
}
function mapStateToProps(state: RootState) {
  return {
    agentState: state.agentState,
    clipboardState: state.clipboardState,
    navigationState: state.navigationState,
    messageState: state.messageState,
    sidebarState: state.sidebarState,
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    agentActions: bindActionCreators(agentActions as any, dispatch),
    sidebarActions: bindActionCreators(sidebarActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentConfigPage);
