import produce from 'immer';
import * as actions from '~/actions/reportDetailsPage';
import _ from 'lodash';
import { ReportDetailsPageState, ReportType } from '~/reducers/reportDetailsPageState.types';

const defaultState: ReportDetailsPageState = {
  [ReportType.ISSUE_VULNERABILITIES]: {
    expandedVulnerableMethodChain: '',
    isActiveTabLoading: false,
    isFetching: false,
    isFailure: false,
    isUpdateAdvisorDataFetching: false,
    tabData: {},
    tabSelected: 'theFix', //theFix, vulnMethods, dependencyGraph
    tabState: {},
    updateAdvisorData: {},
    details: {},
    isFetchingEvents: false,
    events: [],
  },
  [ReportType.ISSUE_LIBRARIES]: {
    isActiveTabLoading: false,
    isFetching: false,
    isFailure: false,
    isUpdateAdvisorDataFetching: false,
    tabData: {},
    tabSelected: 'theFix', //theFix
    tabState: {},
    updateAdvisorData: {},
    details: {},
    isFetchingEvents: false,
    events: [],
  },
  [ReportType.ISSUE_LICENSES]: {
    activeLicense: '', //currently selected license
    isActiveTabLoading: false,
    isFetching: false,
    isFailure: false,
    isLicenseContentDataFetching: false,
    licenseContentDataFetchFailure: '',
    licenseContentData: [],
    tabData: {},
    tabSelected: 'licenseContent', //licenseContent
    tabState: {},
    details: {},
    isFetchingEvents: false,
    events: [],
  },
};

const reportDetailsPageState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.UPDATE_DETAILS_PAGE_ACTIVE_TAB: {
        const currentDetailsPageState = state[action.reportType];
        draft[action.reportType] = {
          ...currentDetailsPageState,
          tabSelected: action.value,
        };
        break;
      }
      case actions.FETCH_ISSUE_EVENT_REQUEST: {
        const currentDetailsPageState = state[action.reportType];
        draft[action.reportType] = {
          ...currentDetailsPageState,
          isFetchingEvents: true,
        };
        break;
      }
      case actions.FETCH_ISSUE_EVENT_SUCCESS: {
        const currentDetailsPageState = state[action.reportType];
        draft[action.reportType] = {
          ...currentDetailsPageState,
          isFetchingEvents: false,
          events: action.events,
        };
        break;
      }
      case actions.FETCH_ISSUE_EVENT_FAILURE: {
        const currentDetailsPageState = state[action.reportType];
        draft[action.reportType] = {
          ...currentDetailsPageState,
          isFetchingEvents: false,
          events: [],
        };
        break;
      }
      case actions.FETCH_DETAILS_PAGE_REQUEST: {
        const currentDetailsPageState = state[action.reportType];
        draft[action.reportType] = {
          ...currentDetailsPageState,
          isFetching: true,
          isFailure: false,
        };
        break;
      }
      case actions.FETCH_DETAILS_PAGE_SUCCESS: {
        const currentDetailsPageState = state[action.reportType];
        const { content = {} } = action.response;
        const { issue = {} } = content;
        const { status } = issue;
        const tabSelected =
          status === 'FIXED' && action.reportType === 'ISSUE_VULNERABILITIES'
            ? 'vulnMethods'
            : 'theFix';
        draft[action.reportType] = {
          ...currentDetailsPageState,
          details: action.response,
          isFetching: false,
          isFailure: false,
          tabSelected,
          isFetchingEvents: false,
        };
        break;
      }
      case actions.FETCH_DETAILS_PAGE_FAILURE: {
        const currentDetailsPageState = state[action.reportType];
        draft[action.reportType] = {
          ...currentDetailsPageState,
          isFetching: false,
          isFailure: true,
          isFetchingEvents: false,
        };
        break;
      }
      case actions.SET_IS_LOADING_ACTIVE_TAB: {
        const currentDetailsPageState = state[action.reportType];
        draft[action.reportType] = {
          ...currentDetailsPageState,
          isActiveTabLoading: action.isLoading,
        };
        break;
      }
      case actions.UPDATE_ACTIVE_TAB_DATA_BY_REPORT_FIELD: {
        const newDetailsPageState = _.cloneDeep(state[action.reportType]);
        const newTabData = { ...newDetailsPageState.tabData };
        newTabData[action.field] = action.res;
        newDetailsPageState.tabData = newTabData;
        draft[action.reportType] = newDetailsPageState;
        break;
      }
      case actions.UPDATE_ACTIVE_TAB_STATE_BY_REPORT_FIELD: {
        const newDetailsPageState = _.cloneDeep(state[action.reportType]);
        const newStateData = { ...newDetailsPageState.tabState };
        newStateData[action.field] = action.val;
        newDetailsPageState.tabState = newStateData;
        draft[action.reportType] = newDetailsPageState;
        break;
      }
      case actions.UPDATE_REPORT_EXPANDED_VULN_METHOD_CHAIN: {
        const newDetailsPageState = _.cloneDeep(state[action.reportType]);
        let currentChain = newDetailsPageState.expandedVulnerableMethodChain;
        let newChain = action.chain === currentChain ? '' : action.chain;
        newDetailsPageState.expandedVulnerableMethodChain = newChain;
        draft[action.reportType] = newDetailsPageState;
        break;
      }
      case actions.FETCH_UPDATE_ADVISOR_DATA_REQUEST: {
        const currentDetailsPageState = state[action.reportType];
        draft[action.reportType] = {
          ...currentDetailsPageState,
          isUpdateAdvisorDataFetching: true,
        };
        break;
      }
      case actions.FETCH_UPDATE_ADVISOR_DATA_SUCCESS: {
        const currentDetailsPageState = state[action.reportType];
        draft[action.reportType] = {
          ...currentDetailsPageState,
          updateAdvisorData: action.response,
          isUpdateAdvisorDataFetching: false,
        };
        break;
      }
      case actions.FETCH_LICENSE_CONTENT_DATA_REQUEST: {
        const currentDetailsPageState = state[action.reportType];
        draft[action.reportType] = {
          ...currentDetailsPageState,
          isLicenseContentDataFetching: true,
        };
        break;
      }
      case actions.FETCH_LICENSE_CONTENT_DATA_SUCCESS: {
        const currentDetailsPageState = state[action.reportType];
        draft[action.reportType] = {
          ...currentDetailsPageState,
          licenseContentData: action.response,
          isLicenseContentDataFetching: false,
        };
        break;
      }
      case actions.FETCH_LICENSE_CONTENT_DATA_FAILURE: {
        const currentDetailsPageState = state[action.reportType];
        draft[action.reportType] = {
          ...currentDetailsPageState,
          licenseContentData: [],
          isLicenseContentDataFetching: false,
          licenseContentDataFetchFailure: action.err,
        };
        break;
      }
      case actions.TOGGLE_LICENSE: {
        const currentDetailsPageState = state[action.reportType];
        draft[action.reportType] = {
          ...currentDetailsPageState,
          activeLicense:
            currentDetailsPageState['activeLicense'] !== action.licenseName
              ? action.licenseName
              : '',
        };
        break;
      }
      case actions.RESET_REPORT: {
        draft[action.reportType] = defaultState[action.reportType];
      }
    }
  });
};

export default reportDetailsPageState;
