import React from 'react';

function SourceClearLoader() {
  return (
    <div className="zIndex-10--loadingPage col-1-1 flex flex--justify-content--center pv+ color--muted-dark">
      <div className="floating-balls--container">
        <div className="floating-ball floating-ball--1" />
        <div className="floating-ball floating-ball--2" />
        <div className="floating-ball floating-ball--3" />
        <div className="floating-ball floating-ball--4" />
        <div className="floating-ball floating-ball--5" />
      </div>
    </div>
  );
}

export default SourceClearLoader;
