import * as actions from '~/actions/userNotifications';

const userNotifications = (state = {}, action) => {
  switch (action.type) {
    case actions.FETCH_NOTIFICATION_SETTINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.SET_USER_NOTIFICATIONS_SETTINGS:
      return {
        ...state,
        settings: action.settings,
        isFetching: false,
        message: '',
      };
    case actions.FETCH_NOTIFICATION_SETTINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
      };
    case actions.UPDATE_NOTIFICATION_SETTINGS_REQUEST:
      return {
        ...state,
        isUpdating: true,
        isUpdatingType: action.notificationType,
      };
    case actions.UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdatingType: '',
        message: '',
      };
    case actions.UPDATE_NOTIFICATION_SETTINGS_FAILURE:
      return {
        ...state,
        isUpdating: false,
        isUpdatingType: '',
        message: action.message,
      };
    default:
      return state;
  }
};

export default userNotifications;
