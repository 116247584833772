import React, { PureComponent, Fragment } from 'react';
import classNames from 'classnames';
import VCDropdown from '~/views/containers/veracode/VCDropdownContainer';
import VCNavItemWithTooltip from './VCNavItemWithTooltip';
import VCNavIcon from '~/views/components/veracode/VCNavIcon';
import { VCNavigationItemsClick } from '~/utils/navServiceHelper.types';
import { VCIconComponentIcon } from '~/types/VCIconComponentIcon';

interface VCNavItemItem {
  click: VCNavigationItemsClick;
  isActive: boolean;
  isAccountMenu?: boolean;
  isDropdown: boolean;
  isHelpMenu?: boolean;
  isLink: boolean;
  label?: string;
  maxWidth?: number;
  dropdownMenuItems?: any[];
  openInNewWindow?: boolean;
  tooltipText?: string;
  icon: VCIconComponentIcon;
}

interface VCNavItemProps {
  id: number;
  align?: string;
  item: VCNavItemItem;
}

export class VCNavItem extends PureComponent<VCNavItemProps, {}> {
  render() {
    const { id, item, align } = this.props;
    const {
      click,
      icon,
      isAccountMenu,
      isActive,
      isDropdown,
      isHelpMenu,
      isLink,
      label,
      maxWidth,
      dropdownMenuItems,
      openInNewWindow,
      tooltipText = '',
    } = item;
    let navItem;
    const url = click && click.params.url;
    const shouldShowTooltip = !!tooltipText;

    if (isLink) {
      // currently renders the Home and Help Center icon and link
      navItem = (
        <a href={url} {...(openInNewWindow ? { target: '_blank' } : {})}>
          <VCNavIcon icon={icon} />
          {label}
        </a>
      );
    } else if (isDropdown) {
      navItem = (
        <VCDropdown
          id={id}
          label={label}
          icon={icon}
          isActive={isActive}
          isAccountMenu={isAccountMenu}
          maxWidth={maxWidth}
          dropdownMenuItems={dropdownMenuItems}
          align={align}
        />
      );
    } else {
      navItem = icon && (
        <Fragment>
          <VCNavIcon icon={icon} />
          <span>{label}</span>
        </Fragment>
      );
    }
    const renderNavItem = (navItem: React.Component | React.ReactElement) => {
      if (shouldShowTooltip) {
        return (
          <VCNavItemWithTooltip
            place={isAccountMenu ? 'left' : 'bottom'}
            tooltipId={id.toString()}
            tooltipText={tooltipText}
          >
            {navItem}
          </VCNavItemWithTooltip>
        );
      }
      return navItem;
    };

    return (
      <li
        className={classNames('navItem', {
          active: isActive,
          'account-menu': isAccountMenu,
          'help-menu': isHelpMenu,
        })}
        id={id.toString()}
      >
        {renderNavItem(navItem)}
      </li>
    );
  }
}
