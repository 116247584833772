import zxcvbn from 'zxcvbn';

import * as actions from '~/actions/password';
import * as myActions from '~/actions/me';

const defaultState = {
  password: '',
  confirmPassword: '',
  passwordStrength: {},
};

const passwordInputs = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_PASSWORD_INPUT: {
      const passwordStrength = zxcvbn(action.value);

      return {
        ...state,
        password: action.value,
        passwordStrength,
      };
    }
    case actions.UPDATE_CONFIRM_PASSWORD_INPUT:
      return {
        ...state,
        confirmPassword: action.value,
      };
    case actions.RESET_PASSWORD_STATE:
      return defaultState;
    case myActions.PASSWORD_CHANGE_SUCCESS:
      return defaultState;
    default:
      return state;
  }
};

export default passwordInputs;
