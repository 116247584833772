import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';

export const UPDATE_ACTIVE_REF_TYPE = 'UPDATE_ACTIVE_REF_TYPE';
export const UPDATE_SPECIFIC_OPTION = 'UPDATE_SPECIFIC_OPTION';
export const RESET_SPECIFIC_OPTIONS = 'RESET_SPECIFIC_OPTIONS';
export const VALIDATE_COMMIT_REQUEST = 'VALIDATE_COMMIT_REQUEST';
export const COMMIT_VALIDATION_SUCCESS = 'COMMIT_VALIDATION_SUCCESS';
export const COMMIT_VALIDATION_FAILURE = 'COMMIT_VALIDATION_FAILURE';

export const updateActiveRefType = refType => ({
  type: UPDATE_ACTIVE_REF_TYPE,
  refType,
});

export const updateSpecificOption = (option, refType) => ({
  type: UPDATE_SPECIFIC_OPTION,
  option,
  refType,
});

export const resetSpecificOptions = () => ({
  type: RESET_SPECIFIC_OPTIONS,
});

export const validateCommit = (value, repoId) => dispatch => {
  if (!value) {
    dispatch(commitValidationFailure());
    return Promise.resolve();
  } else {
    dispatch(isValidatingCommit());
  }
  const options = {
    commitHash: value,
  };

  return ApiService.post(`/repos/${repoId}/commits/validate`, { data: options })
    .then(res => {
      if (res && res.length) {
        dispatch(commitValidationSuccess(res[0]));
      } else {
        dispatch(commitValidationFailure());
      }
    })
    .catch(error => {
      dispatch(commitValidationFailure());
      ErrorService.capture('Error validating commit hash', { error });
    });
};

export const isValidatingCommit = () => ({
  type: VALIDATE_COMMIT_REQUEST,
});

export const commitValidationSuccess = commit => ({
  type: COMMIT_VALIDATION_SUCCESS,
  commit,
});

export const commitValidationFailure = () => ({
  type: COMMIT_VALIDATION_FAILURE,
});
