import produce from 'immer';
import * as startScanModalActions from '~/actions/startScanModal';
import { StartScanModalState } from '~/reducers/vcAppState/vcAppStateTypes/types';

const defaultState: StartScanModalState = {
  showModal: undefined,
  createNewApplication: false,
  isSelectFromExisting: false,
  selectedApplication: null,
};

const startScanModalState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case startScanModalActions.SHOW_START_SCAN_MODAL:
        draft.showModal = action.modalType;
        break;
      case startScanModalActions.CREATE_NEW_APPLICATION:
        draft.createNewApplication = true;
        draft.isSelectFromExisting = false;
        break;
      case startScanModalActions.SELECT_FROM_EXISTING:
        draft.isSelectFromExisting = true;
        draft.createNewApplication = false;
        break;
      case startScanModalActions.UPDATE_SELECTED_APPLICATION:
        draft.selectedApplication = action.application;
        break;
      case startScanModalActions.RESET_START_SCAN_MODAL_STATE:
        draft.isSelectFromExisting = false;
        draft.createNewApplication = false;
        draft.selectedApplication = null;
    }
  });
};

export default startScanModalState;
