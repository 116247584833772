import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as MODEL from '~/constants/ModelConstants';

import SourceClearModal from '~/components/SourceClearModal';
import * as integrationModalActions from '~/actions/integrationModal';

interface Step3ModalProps {
  integrationModalActions: object;
  integrationModalState: object;
  closeWhenClickOutside?: boolean;
}
class Step3Modal extends React.Component<Step3ModalProps, {}> {
  onClose = () => {
    this.props.integrationModalActions.closeIntegrationModal();
  };

  returnToStep2 = () => {
    this.props.integrationModalActions.openIntegrationModal(MODEL.CREATE_JIRA_STEP_2);
  };

  isModalJiraPrem() {
    const { integrationModalState } = this.props;
    return integrationModalState.isModalJiraPrem;
  }

  render() {
    const { integrationModalState, closeWhenClickOutside = false } = this.props;
    const { jiraIntegration = {} } = integrationModalState;
    const { [MODEL.CREATE_JIRA_STEP_2]: step2Response = {} } = jiraIntegration;
    const { authUrl = '' } = step2Response.data;

    return (
      <SourceClearModal
        isOpen={true}
        title={
          this.isModalJiraPrem()
            ? `Create ${MODEL.INTEGRATION_NAME_MAP.JIRA_ONPREM} Integration (Step 5 of 5)`
            : 'Create Jira Integration (Step 3 of 3)'
        }
        onClose={this.onClose}
        width={600}
        closeWhenClickOutside={closeWhenClickOutside}
      >
        <div className="pt ph">
          <div> Visit this URL to authorize SourceClear </div>
          <a href={authUrl} className="link--obvious">
            {' '}
            {authUrl}{' '}
          </a>
          <div className="pt">
            After you authorize SourceClear, you will be taken to a page to create an issue
            template.
          </div>
        </div>

        <div className="pt+ pb text--right">
          <button className="col-1-5 pv- mr-" onClick={() => this.onClose()}>
            Cancel
          </button>
          <button
            className="col-1-5 pv- mr- btn--primary-clear"
            onClick={() => this.returnToStep2()}
          >
            Back
          </button>
        </div>
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationModalState: state.integrationModalState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Step3Modal);
