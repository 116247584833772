import React from 'react';
interface PolicyControlAddControlButtonProps {
  addControl: () => void;
  editMode: boolean;
}
function PolicyControlAddControlButton(props: PolicyControlAddControlButtonProps) {
  const { addControl, editMode } = props;
  return (
    <div className="mt-">
      {editMode && (
        <button
          className="btn--primary pv- ph"
          onClick={() => addControl()}
          data-automation-id="PolicyControlAddControlButton"
        >
          Add control
        </button>
      )}
    </div>
  );
}

export default PolicyControlAddControlButton;
