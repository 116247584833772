import React from 'react';

interface EnvelopeExpiredProps {
  fillColor?: string;
  height?: string;
  width?: string;
}

const EnvelopeExpired: React.FunctionComponent<EnvelopeExpiredProps> = props => {
  const { fillColor = '#000', width = '93', height = '84' } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 93 84" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M87.233 77.72H5.767V34.15L45.05 57.683c.913.547 2.053.546 2.966-.002l39.217-23.55v43.59zM46.47 6.247l38.04 22.79-37.98 22.81-38.04-22.79 37.98-22.81zm45.067 20.28l-.1-.062L47.952.41c-.912-.547-2.053-.546-2.966 0L1.6 26.467s-.09.057-.138.083C.558 27.06 0 28.02 0 29.057v51.547c0 1.592 1.29 2.884 2.884 2.884h87.232c1.593 0 2.884-1.293 2.884-2.885v-51.57c0-1.04-.56-2-1.463-2.51zM43.45 40.458c0-.898.282-1.61.847-2.135.563-.525 1.307-.79 2.23-.79.973 0 1.742.265 2.306.79.564.525.846 1.237.846 2.134 0 .896-.283 1.613-.847 2.152-.564.538-1.333.807-2.306.807-.923 0-1.667-.27-2.23-.808-.565-.54-.847-1.256-.847-2.153zm.77-5.46c-.13-1.334-.065-2.468.192-3.404.256-.935.608-1.743 1.057-2.422.447-.68.96-1.27 1.537-1.77s1.115-.98 1.615-1.44c.5-.462.916-.943 1.25-1.443.332-.5.5-1.095.5-1.787 0-.897-.25-1.615-.75-2.153s-1.39-.807-2.672-.807c-.41 0-.847.045-1.308.134-.46.09-.923.218-1.384.385-.462.167-.904.36-1.326.577-.424.217-.802.44-1.135.67l-1.922-3.69c.974-.666 2.095-1.217 3.364-1.653 1.268-.436 2.8-.654 4.594-.654 2.41 0 4.293.584 5.652 1.75 1.358 1.166 2.038 2.736 2.038 4.71 0 1.307-.173 2.39-.52 3.25-.345.858-.775 1.588-1.287 2.19-.514.603-1.077 1.135-1.692 1.596-.616.46-1.18.954-1.692 1.48-.513.525-.95 1.134-1.307 1.826-.36.692-.54 1.576-.54 2.653H44.22z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};
export default EnvelopeExpired;
