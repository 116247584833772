import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import SidebarNav from '~/containers/SidebarNav';

interface RouteSplitterProps {
  from: string;
  to: string;
  component: any;
  location: {
    [key: string]: any;
  };
  routes: [];
  sidebar: string;
  centeredNoSidebar: boolean;
  redirect: boolean;
}

function RouteSplitter(initialProps: RouteSplitterProps) {
  const {
    component: Component,
    location,
    routes = [],
    redirect,
    sidebar,
    centeredNoSidebar,
    ...rest
  } = initialProps;

  if (redirect) {
    // redirect based on path specified in route.js, where
    // path: path where to redirect
    // from: string in path to be replaced from
    // to: string in path to be replace to
    const to = {
      pathname: location.pathname.replace(initialProps.from, initialProps.to),
      state: { status: 301 },
    };
    return <Redirect to={to} />;
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (sidebar) {
          return (
            <Fragment>
              <div className="flex phone--hide sidebar-wrapper">
                <SidebarNav sidebar={sidebar} />
              </div>
              <div className="pl pb+ phone--col-1-1 phone--p0 content-wrapper">
                <Component {...props} routes={routes} />
              </div>
            </Fragment>
          );
        } else if (centeredNoSidebar) {
          return (
            <Fragment>
              <Component {...props} routes={routes} />
            </Fragment>
          );
        }

        return (
          <div className="col-1-1 flex flex--content">
            <div className="col-1-1">
              <Component {...props} routes={routes} />
            </div>
          </div>
        );
      }}
    />
  );
}
export default RouteSplitter;
