import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import DropdownMenu from 'react-dd-menu';
import _ from 'lodash';

import SortHelper from '~/utils/SortHelper';
import Helpers from '~/utils/Helpers';
import { CLIENT_PAGINATION_LIMITS, integrationPrettyNameMap } from '~/constants/ModelConstants';

import Pagination from '~/components/Pagination';
import AgentUpgradeModal from '~/containers/AgentUpgradeModal';
import SourceClearModal from '~/components/SourceClearModal';
import SourceClearLoader from '~/components/SourceClearLoader';

import ReportActionsWrapper from '~/components/ReportComponents/ReportActionsWrapper';
import ReportHeaderRow from '~/components/ReportComponents/ReportHeaderRow';
import ReportHeaderItem from '~/components/ReportComponents/ReportHeaderItem';
import ReportStringFilter from '~/containers/ReportStringFilter';
import ReportRowWrapper from '~/components/ReportComponents/ReportRowWrapper';

import * as agentActions from '~/actions/agents';
import * as dropdownMenuActions from '~/actions/dropdownMenu';

interface AgentsListProps {
  agents: any[];
  search: string;
  pagination: object;
  sortAgentList: object;
  teamId?: string;
  zeroAgents?: boolean;
  isFetching?: boolean;
  handlePaginationClick: (...args: any[]) => any;
  updateStringFilter: (...args: any[]) => any;
  agentActions: object;
  dropdownMenuActions: object;
  agentState: object;
  dropdownMenuState: object;
}
export class AgentsList extends React.Component<AgentsListProps, {}> {
  constructor(props, context) {
    super(props, context);

    this.delayCloseDropdownMenu = _.debounce(id => this.closeDropdown(id), 50);
  }

  componentDidMount() {
    const { agentState } = this.props;
    const { activeInstallOption = '' } = agentState;
    this.props.agentActions.updateUpgradeActiveAgent(activeInstallOption);
  }

  componentWillUnmount() {
    this.props.dropdownMenuActions.resetDropdownMenuState();
    // reset string filter when navigating away from page
    this.updateStringFilter('FILTER_AGENT_LIST_BY_STRING', '');
  }

  closeDropdown(menuId) {
    this.props.dropdownMenuActions.closeDropdownMenu(menuId);
  }

  openDropdown(menuId) {
    this.props.dropdownMenuActions.openDropdownMenu(menuId);
  }

  handlePaginationClick(page) {
    this.props.handlePaginationClick(page);
  }

  updateStringFilter(field, value) {
    this.props.updateStringFilter(field, value);
  }

  updateSortField(field) {
    // client side sorting
    this.props.agentActions.updateAgentListSort(field);
  }

  showModal(modalType, agentType) {
    this.props.agentActions.updateUpgradeAgentType(agentType);
    this.props.agentActions.showModal(modalType);
  }

  toggleDownloadOption(activeInstallOption) {
    this.props.agentActions.updateUpgradeActiveAgent(activeInstallOption);
  }

  render() {
    const {
      agents = [],
      search = '',
      pagination = {},
      sortAgentList = {},
      teamId,
      dropdownMenuState,
      agentState,
      zeroAgents,
      isFetching,
    } = this.props;
    const { currentPage, totalPages } = pagination;
    const { openedDropdown } = dropdownMenuState;
    const { agentReleases, agentListUpgrade = {}, modalType } = agentState;
    const { agentType, activeInstallOption } = agentListUpgrade;
    const columnWidths = {
      name: 'col-1-5',
      type: 'col-1-6',
      lastScan: 'col-1-5',
      createdBy: 'col-1-5',
      version: 'col-1-8',
    };
    const agentsLimit = CLIENT_PAGINATION_LIMITS.agentList;
    const paginatedAgents =
      agents.length < agentsLimit
        ? agents
        : agents.slice(currentPage * agentsLimit, (currentPage + 1) * agentsLimit);

    const renderAgentRow = () => {
      if (isFetching) {
        return <SourceClearLoader />;
      } else if (zeroAgents) {
        return <div className="mt pl- font--h3 color--muted-dark">No agents configured.</div>;
      } else if (!paginatedAgents.length) {
        return <div className="mt pl- font--h3 color--muted-dark">No agents found.</div>;
      } else {
        return paginatedAgents.map(agent => {
          const { id, name, lastScan, agentVersion, type } = agent;
          const createdBy = agent.createdBy || {};
          const agentSpecificLatestVersion =
            agentReleases[agent.type] && agentReleases[agent.type].version;
          const isUpToDate =
            agentVersion && agentSpecificLatestVersion
              ? SortHelper.getVersionSortValue(agentSpecificLatestVersion) <=
                SortHelper.getVersionSortValue(agentVersion)
              : false;

          return (
            <ReportRowWrapper key={`agent-id--${id}`}>
              <div className={`grid__item ${columnWidths.name}`}>
                <Link
                  to={teamId ? `/workspaces/${teamId}/agents/${id}` : `/org/settings/agents/${id}`}
                  className="link--obvious"
                >
                  <strong>{name}</strong>
                </Link>
              </div>
              <div className={`grid__item ${columnWidths.type}`}>
                {integrationPrettyNameMap[type.toLowerCase()] || '--'}
              </div>
              <div className={`grid__item ${columnWidths.lastScan}`}>
                {lastScan ? Helpers.formatDate(lastScan) : '--'}
              </div>
              <div className={`grid__item text--overflow ${columnWidths.createdBy}`}>
                {createdBy.id ? Helpers.formatUserName(createdBy) : '--'}
              </div>
              <div className={`grid__item ${columnWidths.version}`}>{agentVersion || '--'}</div>
              <div className={`grid__item ${columnWidths.updateVersion} flex`}>
                {!isUpToDate && agentSpecificLatestVersion ? (
                  <div className="link--obvious" onClick={() => this.showModal('GET_LATEST', type)}>
                    Get version {agentSpecificLatestVersion}
                  </div>
                ) : (
                  <div>Up to date</div>
                )}
              </div>
            </ReportRowWrapper>
          );
        });
      }
    };

    return (
      <div className="grid">
        <div className="grid__item col-1-1">
          <ReportActionsWrapper>
            <div className="grid__item flex justify-content--end p-- align-items--center">
              <DropdownMenu
                animate={true}
                isOpen={!!openedDropdown['agents-actions']}
                close={() => this.delayCloseDropdownMenu('agents-actions')}
                menuAlign="right"
                textAlign="left"
                toggle={
                  <button
                    className={`p- btn--success`}
                    onClick={() => this.openDropdown('agents-actions')}
                  >
                    Actions
                  </button>
                }
              >
                <li className="dropdown-menu--li pr">
                  <Link
                    className="pv--"
                    to={teamId ? `/workspaces/${teamId}/agents/new` : `/org/settings/agents/new`}
                  >
                    Create
                  </Link>
                </li>
              </DropdownMenu>
            </div>
            <div className="grid__item col-1-1 p-- flex justify-content--space-between bg-color--white-medium">
              <div className="flex">
                <div className="width--240 flex">
                  <ReportStringFilter
                    label={'Search agents'}
                    field={'search'}
                    value={search}
                    onChange={(field, value) => this.updateStringFilter(field, value)}
                  />
                </div>
                <div className="pl- flex align-items--center">
                  {agents.length || 0} agent
                  {agents.length === 1 ? '' : 's'}
                </div>
              </div>
              <div className="flex">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages || 1}
                  handlePageClick={page => this.handlePaginationClick(page)}
                />
              </div>
            </div>
          </ReportActionsWrapper>
        </div>
        <div className="grid__item col-1-1 mt--">
          <ReportHeaderRow>
            <ReportHeaderItem
              label="Agent Name"
              field="name"
              widthClass={columnWidths.name}
              isSortable={true}
              onClick={field => this.updateSortField(field)}
              currentSort={sortAgentList}
            />
            <ReportHeaderItem label="CI Type" field="scmType" widthClass={columnWidths.type} />
            <ReportHeaderItem
              label="Latest Scan"
              field="latestScan"
              widthClass={columnWidths.lastScan}
            />
            <ReportHeaderItem
              label="Created By"
              field="createdBy"
              widthClass={columnWidths.createdBy}
            />
            <ReportHeaderItem label="Version" field="version" widthClass={columnWidths.version} />
            <ReportHeaderItem
              label="Update"
              field="update"
              widthClass={columnWidths.updateVersion}
            />
          </ReportHeaderRow>

          {renderAgentRow()}
        </div>

        <SourceClearModal
          isOpen={modalType === 'GET_LATEST'}
          onClose={() => this.showModal()}
          width={500}
          title="Get Latest Agent"
        >
          <AgentUpgradeModal
            showModal={modalType => this.showModal(modalType)}
            type={agentType}
            activeInstallOption={activeInstallOption}
            toggleDownloadOption={option => this.toggleDownloadOption(option)}
          />
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentState: state.agentState,
    dropdownMenuState: state.dropdownMenuState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    agentActions: bindActionCreators(agentActions, dispatch),
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentsList);
