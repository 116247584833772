import React from 'react';

const ReportHeaderRow: React.FunctionComponent<{}> = props => {
  const { children } = props;

  return (
    <div className="bo--1 border-color--white-dark pl- pv-" data-automation-id="ReportHeader">
      <div className="grid grid--middle grid--narrower">{children}</div>
    </div>
  );
};

export default ReportHeaderRow;
