import _ from 'lodash';

import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import Helpers from '~/utils/Helpers';
import { addMessage, clearMessages } from '~/actions/message';

import * as toastrActions from '~/actions/toastr';
import * as statsActions from '~/actions/stats';
import * as insightsActions from '~/actions/insights';
import * as projectScanHistoryActions from '~/actions/projectScanHistory';
import * as repoScopeActions from '~/actions/repoScope';
import ApiConstants from '~/constants/ApiConstants';

import { Dispatch } from 'redux';

export const UPDATE_REPO_DATA = 'UPDATE_REPO_DATA';
export const DELETE_REPO_FAILURE = 'DELETE_REPO_FAILURE';
export const FETCH_REPO_DATA_REQUEST = 'FETCH_REPO_DATA_REQUEST';
export const FETCH_REPO_DATA_FAILURE = 'FETCH_REPO_DATA_FAILURE';
export const UPDATE_REPO_NAME = 'UPDATE_REPO_NAME';
export const UPDATE_IS_SETTING_REPO_SCOPE_REQUEST = 'UPDATE_IS_SETTING_REPO_SCOPE_REQUEST';
export const UPDATE_IS_SETTING_REPO_SCOPE_SUCCESS = 'UPDATE_IS_SETTING_REPO_SCOPE_SUCCESS';
export const UPDATE_IS_SETTING_REPO_SCOPE_FAILURE = 'UPDATE_IS_SETTING_REPO_SCOPE_FAILURE';
export const UPDATE_IS_REFRESHING_PROJECT_DATA = 'UPDATE_IS_REFRESHING_PROJECT_DATA';

export const fetchRepoData = repoId => (dispatch: Dispatch) => {
  const endpoint = `/repos/${repoId}?with=refs`;

  dispatch(fetchRepoDataRequest());
  return ApiService.get(endpoint)
    .then(res => {
      dispatch(updateRepoData(res));
      return res;
    })
    .catch(err => {
      ErrorService.capture('Failed to fetch repo data', { repoId, err });
      dispatch(fetchRepoDataFailure(err));
    });
};

/**
 * fetchRepoDataAndSetRepoScope
 *
 * This async function is an extension of fetchRepoData() to include stages
 * such as setting of repoScope based on the given scope and repo's defined branches, tags and defaultBranch.
 *
 * This utilizes updateIsSettingRepoScopeXXX actions to track status of isSettingRepoScope property
 * from requesting for repo data to setting of scope. isSettingRepoScope helps in preventing
 * pre-mature rendering and hence the making of unwanted calls to /reports endpoint in which
 * repoScope is not loaded with accurate scope ahead of time.
 */
export const fetchRepoDataAndSetRepoScope = (repoId, scope, history) => (
  dispatch: Dispatch,
  getState
) => {
  // This and its partners ...Success() and ...Failure() are flags that are used to tell when the scoping is done
  // before performing any requests for reports, stats, etc. (see #refreshProjectData)
  dispatch(updateIsSettingRepoScopeRequest());

  const { navigationState } = getState();
  const { activeTeamParent: teamId } = navigationState;
  const { repoScanId } = scope;

  const fetchRepoDataAction = fetchRepoData(repoId);
  const fetchProjectScanByIdAction = projectScanHistoryActions.fetchProjectScanById(
    teamId,
    repoScanId
  );

  if (repoScanId) {
    // When the given scope contains repoScanId, repoScanId takes precedence
    // and is used to retrieve ref values (branch, etc.) to set repo scope.
    return Promise.all([dispatch(fetchRepoDataAction), dispatch(fetchProjectScanByIdAction)])
      .then(res => {
        const { _embedded = {} } = res[1];
        const { repoScans = [] } = _embedded;
        const scan = repoScans[0];

        const { branch, tag } = scan;

        dispatch(repoScopeActions.performRepoScopeChange({ branch, tag }, history));
        dispatch(updateIsSettingRepoScopeSuccess());
        return res;
      })
      .catch(error => {
        dispatch(updateIsSettingRepoScopeFailure(error));
        ErrorService.capture('Failed to fetch scan', error);
        return {};
      });
  } else {
    return dispatch(fetchRepoDataAction)
      .then(res => {
        const { defaultBranch = '' } = res;

        if (!_.isEmpty(scope)) {
          // Once repo data is received,
          // check if scope contains repo's branches/tags
          // If existing, update repo scope to specified branch/tag.
          // Otherwise, throw error and handle with a toastr.

          const { branches = [], tags = [] } = res;
          const { branch, tag } = scope;

          const maybeTagRefType = tag ? 'tag' : '';
          const refType = branch ? 'branch' : maybeTagRefType;

          const isBranchValid = branch && branches.includes(branch);
          const isTagValid = tag && tags.includes(tag);

          if (isBranchValid || isTagValid) {
            const maybeTagValue = isTagValid ? tag : '';
            const value = isBranchValid ? branch : maybeTagValue;
            dispatch(repoScopeActions.performRepoScopeChange({ [refType]: value }, history));
          } else {
            throw `Failed to set repo scope. ${Helpers.capFirst(refType)} not found`;
          }
        } else if (defaultBranch) {
          dispatch(repoScopeActions.performRepoScopeChange({ branch: defaultBranch }, history));
        }

        dispatch(updateIsSettingRepoScopeSuccess());
        return res;
      })
      .catch(error => {
        dispatch(updateIsSettingRepoScopeFailure(error));
        ErrorService.capture('Failed to retrieving repo data and setting repo scope', error);
        throw error;
      });
  }
};

export const saveRepoName = (projectId, projectName) => (dispatch: Dispatch) => {
  const options = {
    displayName: projectName,
  };
  return ApiService.put(ApiConstants.updateRepoNameUrl(projectId), { data: options })
    .then(() => {
      dispatch(fetchRepoData(projectId));
      return { success: true };
    })
    .catch(error => {
      ErrorService.capture('Error saving Project name', error);
      return { success: false };
    });
};

export const updateRepoName = value => ({
  type: UPDATE_REPO_NAME,
  value,
});

export const updateIsSettingRepoScopeRequest = () => ({
  type: UPDATE_IS_SETTING_REPO_SCOPE_REQUEST,
});

export const updateIsSettingRepoScopeSuccess = () => ({
  type: UPDATE_IS_SETTING_REPO_SCOPE_SUCCESS,
});

export const updateIsSettingRepoScopeFailure = error => ({
  type: UPDATE_IS_SETTING_REPO_SCOPE_FAILURE,
  message: error.message,
});

export const favoriteRepo = ({ id, link }) => (dispatch: Dispatch) => {
  return ApiService.put(link)
    .then(res => {
      dispatch(updateRepoData(res));
    })
    .catch(err => ErrorService.capture('Failed to favorite repo', { id, err }));
};

export const fetchRepoDataRequest = () => ({
  type: FETCH_REPO_DATA_REQUEST,
});

export const fetchRepoDataFailure = error => ({
  type: FETCH_REPO_DATA_FAILURE,
  message: error.message,
});

export const unfavoriteRepo = ({ id, link }) => (dispatch: Dispatch) => {
  return ApiService.del(link)
    .then(res => {
      dispatch(updateRepoData(res));
    })
    .catch(err => ErrorService.capture('Failed to unfavorite repo', { id, err }));
};

export const updateRepoData = response => ({
  type: UPDATE_REPO_DATA,
  response,
});

/*
  Until ref is by default in repoData, we have to make another API call to fetch
  repo data with ref in order for the state to be as expected.
*/
export const watchRepo = (id, link, getRef = true) => (dispatch: Dispatch) => {
  return ApiService.put(link)
    .then(res => {
      if (getRef) {
        dispatch(fetchRepoData(id));
      } else {
        dispatch(updateRepoData(res));
      }
    })
    .catch(err => ErrorService.capture('Failed to watch repo', err));
};

export const unwatchRepo = (id, link, getRef = true) => (dispatch: Dispatch) => {
  return ApiService.del(link)
    .then(res => {
      if (getRef) {
        dispatch(fetchRepoData(id));
      } else {
        dispatch(updateRepoData(res));
      }
    })
    .catch(err => ErrorService.capture('Failed to unwatch repo', err));
};

export const deleteRepository = repo => (dispatch: Dispatch, getState) => {
  const { navigationState } = getState();
  const { activeTeamParent: teamId } = navigationState;
  const endpoint = `/repos/${repo.id}`;

  return ApiService.del(endpoint)
    .then(
      () => {
        dispatch(clearMessages());
        dispatch(statsActions.fetchWorkspaceStats(teamId));
        dispatch(
          toastrActions.addToastr({
            id: `delete-repo-success`,
            level: 'success',
            title: 'Project successfully deleted',
            message: `Your project ${repo.name} has been deleted.`,
          })
        );
        return { success: true };
      },
      error => {
        dispatch(addMessage({ type: 'DELETE_REPO_FAILURE' }));
        ErrorService.capture('Failed to delete repository', { repo, error });
        return {};
      }
    )
    .catch(error => {
      dispatch(addMessage({ type: 'DELETE_REPO_FAILURE' }));
      ErrorService.capture('Failed to delete repository', { repo, error });
      return {};
    });
};

export const updateIsRefreshingProjectData = (isRefreshingProjectData: boolean) => ({
  type: UPDATE_IS_REFRESHING_PROJECT_DATA,
  isRefreshingProjectData,
});

/**
 * refreshProjectData
 *
 * Refresh of project data happens in two scenarios:
 * - When user loads the data for the first time, for example, through ?branch, /issues, /scans/<scanId> (see ProjectDetailsPage)
 * - When a branch is picked from the Branch picker (see RepoScopeSwitcher)
 *
 * A refresh is needed in the above cases as they modify scope, hence stats, insights and scan history state
 * need to be updated with every change of scope. Scopes (report or repo) are set by the time this function is called.
 */
export const refreshProjectData = (teamId, projectId) => (dispatch: Dispatch, getState) => {
  const state = getState();
  const { repoScope, reportScope } = state;
  const { branch = '', tag = '' } = repoScope;
  const { repoScanId = '' } = reportScope;

  const refType = (ref => {
    if (ref.branch) {
      return 'branch';
    }
    if (ref.tag) {
      return 'tag';
    }
  })(repoScope);

  const refFilterValue = {
    ref: branch || tag || '',
    refType,
  };

  dispatch(updateIsRefreshingProjectData(true));
  dispatch(statsActions.fetchProjectStats(teamId, projectId));
  dispatch(insightsActions.fetchProjectInsights(teamId, projectId));
  dispatch(projectScanHistoryActions.updateProjectScanFilter('refs', refFilterValue)); // Sets filter before fetching project scan history based on the refFilterValue
  dispatch(projectScanHistoryActions.fetchProjectScans(teamId))
    .then(res => {
      const { _embedded = {} } = res;
      const { repoScans = [] } = _embedded;

      // Get the ref value, if any is used
      const ref = branch || tag || '';

      // If scans are retrieved based on a ref (ie branch), track latest scan for the ref
      // and make that scan the selected item on Scan History list
      if (ref) {
        const latestBranchOrTagScan = repoScans[0];
        dispatch(
          projectScanHistoryActions.updateLatestScanByBranchOrTag(
            ref,
            refType,
            latestBranchOrTagScan
          )
        );
        if (!repoScanId) {
          // If repoScanId is not specified, select the latest in the branch
          dispatch(projectScanHistoryActions.updateSelectedProjectScan(latestBranchOrTagScan));
        }
      }

      // In the case where there is no branch, tag or defaultBranch, reports are not scoped and
      // none of the scans is selected
    })
    .finally(() => {
      dispatch(updateIsRefreshingProjectData(false));
    });
};
