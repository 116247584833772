import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Select from 'react-select';

import githubSrc from '~/images/github.png';
import SourceClearModal from '~/components/SourceClearModal';
import GithubIssuesCheckbox from '~/components/GithubIssuesCheckbox';

import * as integrationModalActions from '~/actions/integrationModal';
import * as teamActions from '~/actions/team';
import { INTEGRATION_NAME_MAP } from '~/constants/ModelConstants';

const { GITHUB } = INTEGRATION_NAME_MAP;

interface GithubIntegrationModalProps {
  integrationModalActions: object;
  integrationModalState: object;
  messageState: object;
  teamActions: object;
  teamState: object;
  closeWhenClickOutside?: boolean;
}
class GithubIntegrationModal extends React.Component<GithubIntegrationModalProps, {}> {
  onClose = () => {
    this.props.integrationModalActions.closeIntegrationModal();
  };

  toggleDisableGithubIssues = (enableGithubIssues, teamId) => {
    this.props.teamActions.toggleDisableGithubIssues(enableGithubIssues, teamId);
  };

  onWorkspaceSelected(workspace) {
    workspace && this.props.integrationModalActions.setSelectedWorkspaceTeam(workspace);
  }

  render() {
    const {
      teamState,
      messageState,
      integrationModalState,
      closeWhenClickOutside = false,
    } = this.props;
    const { openedModal } = integrationModalState;
    const { issueSettingsDataByTeamId = {}, teams = [] } = teamState;

    const { selectedWorkspaceTeam = {} } = integrationModalState;
    const selectedWorkspaceTeamList = teams.filter(team => team.id === selectedWorkspaceTeam.id);
    const issueSettingsData =
      selectedWorkspaceTeamList.length > 0
        ? issueSettingsDataByTeamId[selectedWorkspaceTeamList[0].id] || {}
        : {};

    const { messages = {} } = messageState;
    const { githubSaveSuccess } = messages;

    let workspaceList = teams.map(team => ({ value: team, label: team.name }));
    const isCreateView = openedModal.CREATE_GITHUB_INTEGRATION_MODAL;
    // Don't show workspace with Github integration created previously
    if (isCreateView) {
      workspaceList = workspaceList.filter(workspace => {
        return (
          issueSettingsDataByTeamId[workspace.value.id].disableGithubIssues ||
          selectedWorkspaceTeam.id === workspace.value.id
        );
      });
    }

    return (
      <SourceClearModal
        isOpen={true}
        title={isCreateView ? `Create ${GITHUB} Integration` : `Edit ${GITHUB} Integration`}
        onClose={this.onClose}
        width={600}
        minHeight={workspaceList.length > 0 ? 250 : 0}
        closeWhenClickOutside={closeWhenClickOutside}
      >
        {workspaceList.length > 0 ? (
          <div className="p">
            <div className="flex justify-content--center align-items--center pb">
              <div className="col-1-5">
                {' '}
                <strong> Workspace: </strong>
              </div>
              {isCreateView ? (
                <Select
                  className="col-4-5 srcclr-react-select-container"
                  classNamePrefix={'srcclr-react-select'}
                  isClearable={false}
                  name="github-workspace"
                  value={workspaceList.find(option => option.value === selectedWorkspaceTeam.name)}
                  options={workspaceList}
                  onChange={field => this.onWorkspaceSelected(field.value)}
                  placeholder={`Select a workspace to configure ${GITHUB} integration`}
                />
              ) : (
                <span className="col-4-5">{selectedWorkspaceTeam.name}</span>
              )}
            </div>

            {!_.isEmpty(selectedWorkspaceTeam) && (
              <span>
                <div className="col-1-1 bo-b--1 border-color--muted-light flex flex--align-items--flex-end pv-- mb">
                  <img src={githubSrc} alt="jira" className="col-1-12 pr-" />
                  <span className="font--h5">GitHub</span>
                </div>
                <GithubIssuesCheckbox
                  customText={`Activate ${GITHUB} integration for "${selectedWorkspaceTeam.name}"`}
                  toggleDisableGithubIssues={this.toggleDisableGithubIssues}
                  disableGithubIssues={issueSettingsData.disableGithubIssues}
                  teamId={selectedWorkspaceTeam.id}
                  saveSuccess={!!githubSaveSuccess}
                  disabled={false}
                />
                <div className="col-1-1 flex flex--justify-content--end align-items--center mt+">
                  <div className="col-1-2 text--right -mr">
                    <button className="ph btn--primary-clear" onClick={() => this.onClose()}>
                      {' '}
                      Done{' '}
                    </button>
                  </div>
                </div>
              </span>
            )}
          </div>
        ) : (
          <div className="p">
            All workspace have {GITHUB} integration activated.
            <div className="col-1-1 flex flex--justify-content--end align-items--center mt+">
              <div className="col-1-2 text--right -mr">
                <button className="ph btn--primary-clear" onClick={() => this.onClose()}>
                  {' '}
                  Close{' '}
                </button>
              </div>
            </div>
          </div>
        )}
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationModalState: state.integrationModalState,
    messageState: state.messageState,
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GithubIntegrationModal);
