import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reportDetailsPageActions from '~/actions/reportDetailsPage';

interface ReportDetailsPageTabSectionWrapperProps {
  link: string;
  reportType: string;
  field: string;
  loadOnComponentMount: boolean;
  reportDetailsPageActions: object;
}
class ReportDetailsPageTabSectionWrapper extends React.Component<
  ReportDetailsPageTabSectionWrapperProps,
  {}
> {
  componentDidMount() {
    const { reportType, field, link, loadOnComponentMount } = this.props;

    if (loadOnComponentMount) {
      this.props.reportDetailsPageActions.loadDetailPageActiveTab(reportType, field, link);
    }
  }

  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    reportDetailsPageActions: bindActionCreators(reportDetailsPageActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetailsPageTabSectionWrapper);
