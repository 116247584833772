import * as actions from '~/actions/signup';
import Helpers from '~/utils/Helpers';

export const defaultState = {
  firstName: undefined,
  lastName: undefined,
  vanityName: undefined,
  vanityError: undefined,
  signupFlowStage: undefined,
  companyName: undefined,
  orgName: undefined,
  orgNameError: false,
  inviteToken: undefined,
  location: {
    country: 'US',
    region: undefined,
  },
};

const signupState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_VANITY_NAME:
      return {
        ...state,
        vanityName: action.value,
      };
    case actions.UPDATE_VANITY_ERROR:
      return {
        ...state,
        vanityError: action.message,
      };
    case actions.UPDATE_SIGNUP_FLOW_STAGE:
      return {
        ...state,
        signupFlowStage: action.stage,
      };
    case actions.UPDATE_FIRST_NAME:
      return {
        ...state,
        firstName: action.value,
      };
    case actions.UPDATE_LAST_NAME:
      return {
        ...state,
        lastName: action.value,
      };
    case actions.UPDATE_COMPANY_NAME:
      return {
        ...state,
        companyName: action.value,
        validOrgName: Helpers.validateOrgName(action.value),
      };
    case actions.UPDATE_ORG_NAME:
      return {
        ...state,
        orgName: action.value,
        orgNameError: false,
      };
    case actions.UPDATE_ORG_NAME_ERROR:
      return {
        ...state,
        orgNameError: action.bool,
      };
    case actions.UPDATE_INVITE_TOKEN:
      return {
        ...state,
        inviteToken: action.token,
        signupFlowStage: 'ACCOUNT',
      };
    case actions.UPDATE_LOCATION_SELECTION:
      return {
        ...state,
        location: {
          country: action.area === 'country' ? action.selection : state.location.country,
          region: action.area === 'region' ? action.selection : undefined,
        },
      };
    case actions.RESET_SIGNUP_STATE:
      return defaultState;
    default:
      return state;
  }
};

export default signupState;
