import React from 'react';

const AccountLockedPage: React.FunctionComponent<{}> = () => {
  return (
    <div className="grid grid--center">
      <div className="grid__item col-1-3 mt++">
        <div className="panel panel--danger">
          <div className="panel__heading font--h7">Account Locked</div>
          <div className="panel__body">
            <p>Your account is locked. Please contact your administrator.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountLockedPage;
