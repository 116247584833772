import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import * as groupActions from '~/actions/group';
import * as orgActions from '~/actions/org';
import * as orgUserActions from '~/actions/orgUser';

import VCLinkWorkspaceToTeams from '~/components/workspaces/teams/VCLinkWorkspaceToTeams';
import { errorMessageTextMap } from '~/constants/ModelConstants';
import { Form, Header, Tooltip } from 'fluorine-ui';
import VCInfoBox from '~/views/components/veracode/VCInfoBox';

interface VCCreateWorkspaceModalProps extends RouteComponentProps {
  groupActions: object;
  orgActions: object;
  orgUserActions: object;
  groupState: object;
  orgState: App.OrgState;
  validWorkspaceName?: boolean;
  showAlert: boolean;
}
class VCCreateWorkspaceModal extends React.Component<VCCreateWorkspaceModalProps, {}> {
  componentDidMount() {
    const { groupState } = this.props;
    const { groups = [] } = groupState;

    if (!groups.length) {
      this.props.groupActions.fetchGroups();
    }

    this.props.orgActions.resetNewWorkspaceFlow();
    this.props.groupActions.resetSelectedGroups();
    this.props.orgUserActions.fetchOrgUsers();
    this.props.orgActions.updateNewWorkspaceName('');
  }

  updateNewWorkspaceName(e) {
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.orgActions.updateNewWorkspaceName(value);
  }

  updateSelectedGroups(groupId) {
    this.props.groupActions.updateSelectedGroups(groupId);
  }

  render() {
    const { orgState, groupState, showAlert } = this.props;
    const { newWorkspaceName = '', validWorkspaceName = true } = orgState;

    const { groups = [], selectedGroups: selectedGroupIds = {} } = groupState;
    const selectedGroups = [];
    const selectableGroups = [];

    const workspaceNameError = !validWorkspaceName ? errorMessageTextMap.workspaceNameError : '';

    groups.forEach(group => {
      if (selectedGroupIds[group.id]) {
        selectedGroups.push(group);
      } else {
        selectableGroups.push({
          label: group.name,
          value: group.id,
        });
      }
    });

    return (
      <div>
        {showAlert && (
          <div>
            <VCInfoBox message="To create an agent, first create a workspace." />
          </div>
        )}
        <p className="createWorkspaceText">
          Create workspaces to organize your project data.{' '}
          <a
            href="https://help.veracode.com/go/t_sca_standalone_upload"
            className="vcStartScanHelpLink"
            target="_blank"
          >
            {'Help'}
            <i className="fas fa-external-link" />
          </a>
        </p>
        <div className="grid__item col-1-1">
          <div className="grid">
            <div className="grid__item col-1-1 flex">
              <div className="grid__item col-1-5 mt- ml+ pl- mr-">
                Workspace Name:
                <span className="color--danger">*</span>
              </div>
              <div className="col-1-2 border-color--muted-light mt-">
                <Tooltip
                  content={'Please fill out this feild'}
                  position="bottom center"
                  wide
                  trigger={
                    <div className="vcTooltip">
                      <Form.Input
                        size="small"
                        placeholder="Workspace Name (required)"
                        value={newWorkspaceName}
                        onChange={e => this.updateNewWorkspaceName(e)}
                        autoComplete="off"
                        name="workspace"
                      />
                    </div>
                  }
                />
              </div>
            </div>
            <div className={`grid__item col-1-1 `}>
              <div className={`${workspaceNameError ? 'is-showing-50' : 'is-hiding'}`}>
                <div className="mt- color--danger">{workspaceNameError}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="grid__item col-1-1">
            <div className="ml-">
              <Header as="h2" mt={4}>
                Add Teams
              </Header>
            </div>
            <hr />
            <p className="createWorkspaceText">Add teams to give team members access.</p>
            <div className="col-1-1 mb+">
              <VCLinkWorkspaceToTeams
                selectableGroups={selectableGroups}
                selectedGroups={selectedGroups}
                updateSelectedGroups={groupId => this.updateSelectedGroups(groupId)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupState: state.groupState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions as any, dispatch),
    orgActions: bindActionCreators(orgActions as any, dispatch),
    orgUserActions: bindActionCreators(orgUserActions as any, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VCCreateWorkspaceModal));
