import React from 'react';

interface EnvelopeExpiredProps {
  fillColor?: string;
  height?: string;
  width?: string;
}

const EnvelopeExpired: React.FunctionComponent<EnvelopeExpiredProps> = props => {
  const { fillColor = '#000', width = '93', height = '84' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 93 84" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.1 36.628c0 2.152.424 4.206 1.188 6.087L24.42 38.6c-.057-.65-.09-1.307-.09-1.972 0-12.124 9.865-21.988 21.99-21.988 12.124 0 21.988 9.864 21.988 21.988 0 .744-.037 1.48-.11 2.205l-6.973 4.188c.846-1.962 1.316-4.123 1.316-6.392 0-8.944-7.276-16.22-16.22-16.22-8.945 0-16.22 7.276-16.22 16.22zm17.06-11.174c.53 0 .962.432.962.96V37.71c0 .53-.432.963-.96.963H39.47c-.528 0-.96-.432-.96-.96v-.962c0-.528.432-.96.96-.96h5.768v-9.373c0-.53.433-.96.962-.96h.96zM87.234 77.72H5.767V34.15L45.05 57.683c.913.547 2.053.546 2.966-.002l39.217-23.55v43.59zM46.47 6.247l38.04 22.79-37.98 22.81-38.04-22.79 37.98-22.81zm45.067 20.28l-.1-.062L47.952.41c-.912-.547-2.053-.546-2.966 0L1.6 26.467s-.09.057-.138.083C.558 27.06 0 28.02 0 29.057v51.547c0 1.592 1.29 2.884 2.884 2.884h87.232c1.593 0 2.884-1.293 2.884-2.885v-51.57c0-1.04-.56-2-1.463-2.51z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default EnvelopeExpired;
