import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import config from '~/config';
import Helpers from '~/utils/Helpers';
import { SAML_EMAIL } from '~/constants/ModelConstants';

import * as loginActions from '~/actions/login';

interface SamlLoginPageProps extends RouteComponentProps {
  loginActions: object;
  loginState: object;
  userStatus: object;
}
class SamlLoginPage extends React.Component<SamlLoginPageProps, any> {
  componentDidMount() {
    const { location, history, userStatus = {} } = this.props;
    const { roles = [] } = userStatus;
    const { search } = location;
    const query = queryString.parse(search) || {};

    if (roles.length && roles.includes('USER')) {
      history.push('/');
    }

    this.props.loginActions.updateLoginQuery(query);

    if (!query.email && localStorage && localStorage.getItem && localStorage.getItem(SAML_EMAIL)) {
      this.props.loginActions.updateSamlEmailValue(localStorage.getItem(SAML_EMAIL));
    }
  }

  componentDidUpdate(prevProps) {
    const { userStatus = {}, history } = prevProps;
    const { roles = [] } = userStatus;

    if (roles.length && roles.includes('USER')) {
      history.push('/');
    }
  }

  componentWillUnmount() {
    this.props.loginActions.resetState();
  }

  updateInputValue(e) {
    this.props.loginActions.updateSamlEmailValue(e.target.value);
  }

  render() {
    const { loginState, userStatus } = this.props;
    const { query = {}, samlEmailValue = '' } = loginState;
    const { autoLogout, error } = query;
    const orgSlug = Helpers.getOrgSlug();
    let errorMessage = '';

    if (userStatus.loggedIn) {
      return <div />;
    }

    if (error) {
      switch (error) {
        case 'saml-email':
          errorMessage = (
            <div className="grid__item col-1-1">
              <div className="panel panel--danger">
                <div className="panel__heading font--h6">Conflicting email accounts</div>
                <div className="panel__body">
                  <p>
                    Login failed because a SourceClear account associated with this email already
                    exists but was not created with your company's single-sign-on solution. To
                    proceed, login to SourceClear with your GitHub credentials and delete your
                    account from the personal settings page. Once the account is deleted, please go
                    to{' '}
                    <a
                      href={`${config.WWW_URL}/saml`}
                      className="link--obvious"
                    >{`${config.WWW_URL}/saml`}</a>{' '}
                    and login again. If you continue to have trouble, please{' '}
                    <a
                      href="mailto:support@veracode.com?subject=Request For SourceClear Support"
                      className="link--obvious"
                    >
                      contact support
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          );
          break;
        case 'saml-domain':
          errorMessage = (
            <div className="grid__item col-1-1">
              <div className="panel panel--danger mt">
                <div className="panel__heading font--h6">SSO not configured</div>
                <div className="panel__body">
                  <p>
                    Single-sign-on for this domain has not yet been configured. Please{' '}
                    <a
                      href="mailto:support@veracode.com?subject=Request For SourceClear Support"
                      className="link--obvious"
                    >
                      contact support
                    </a>{' '}
                    for further assistance.
                  </p>
                </div>
              </div>
            </div>
          );
          break;
        case 'social-auth':
          errorMessage = (
            <div className="grid__item col-1-1">
              <div className="panel panel--danger">
                <div className="panel__heading font--h6">Email domain requires single-sign-on</div>
                <div className="panel__body">
                  <p>Please login using your identity provider below.</p>
                </div>
              </div>
            </div>
          );
          break;
        case 'saml-required':
          errorMessage = (
            <div className="grid__item col-1-1">
              <div className="panel panel--danger">
                <div className="panel__heading font--h6">Email domain requires single-sign-on</div>
                <div className="panel__body">
                  <p>Please login using your identity provider below.</p>
                </div>
              </div>
            </div>
          );
          break;
        case 'saml-expired':
          errorMessage = (
            <div className="grid__item col-1-1">
              <div className="panel panel--danger">
                <div className="panel__heading font--h6">Re-authenticate</div>
                <div className="panel__body">
                  <p>
                    To keep your account as secure as possible, we require you to re-authenticate to
                    your SAML provider.
                  </p>
                </div>
              </div>
            </div>
          );
          break;
        default:
          errorMessage = (
            <div className="grid__item col-1-1">
              <div className="panel panel--danger">
                <div className="panel__heading font--h6">Error</div>
                <div className="panel__body">
                  <p>Oops, something went wrong. Please try again later.</p>
                </div>
              </div>
            </div>
          );
          break;
      }
    }

    return (
      <div className="container flex align-items--center justify-content--center height--75vh">
        <div className="grid grid--center col-2-5 mb++">
          {autoLogout && (
            <div className="grid__item col-1-1">
              <div className="panel panel--danger">
                <div className="panel__heading font--h6">Inactivity Notice</div>
                <div className="panel__body">
                  You were automatically signed out after being inactive for 30 minutes.
                </div>
              </div>
            </div>
          )}

          {errorMessage}

          <div className="grid__item col-1-1 text--center">
            <h3>Sign in to your organization</h3>
          </div>
          <div className="grid__item col-1-1 text--center mt+">
            <div className="font--h6">Your organization requires Single Sign-On</div>
          </div>
          <div className="grid__item col-1-1 mt-">
            <form action={`${config.API_URL}/signin/dispatch-to-idp`} method="POST" name="SAMLForm">
              <div className="grid grid--full">
                <div className="grid__item col-1-1">
                  <input
                    type="email"
                    className="font--h6 control--text ph- col-1-1"
                    name="email"
                    placeholder="Single-sign-on email"
                    required="true"
                    value={samlEmailValue}
                    onChange={e => this.updateInputValue(e)}
                  />
                  {orgSlug && <input type="hidden" name="org" value={orgSlug} />}
                </div>
                <div className="grid__item col-1-1 flex flex--justify-content--end">
                  <button className="btn--primary font--h5 col-1-1 mt-">
                    Sign in with your identity provider
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="grid__item col-1-1 text--center mt-">
            <div className="">
              If you're an organization owner,{' '}
              <Link to="/login" className="link--obvious">
                sign in here
              </Link>{' '}
              with your username and password.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginState: state.loginState,
    userStatus: state.userStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SamlLoginPage);
