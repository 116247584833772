import moment from 'moment';
import { PLAN_TYPE } from '~/constants/ModelConstants';

const OrgPaidStatus = function () {
  const isOrgPremium = (org: App.Org) => {
    const { license } = org;
    const { planType, trialEndDate } = license;

    return planType === 'ENTERPRISE' || (planType === 'PRO' && !trialEndDate);
  };

  // See https://github.com/sourceclear/platform-frontend/pull/648
  const isOrgTrialing = (org: App.Org) => {
    const { license } = org;
    const { trialEndDate, status } = license;

    // Cater for the case where new orgs will have a null value
    // for trialEndDate when it has ended
    // Handle case 4
    if (!!trialEndDate === false) {
      return false;
    }

    // At this point trialEndDate has a value, it happens for
    //  - legacy org who would always have a trialEndDate
    //  - new org who has a trialEndDate set
    // Handle cases 1, 2 and 3.
    return moment().isBefore(trialEndDate) && status === 'TRIAL';
  };

  const isOrgPaidOrTrial = (org: App.Org) => {
    return isOrgTrialing(org) || isOrgPremium(org);
  };

  const isOrgAbleToTrial = (org: App.Org) => {
    const { license } = org;

    return license.canTrial;
  };

  const calculateDaysLeft = endDate => {
    if (!endDate || isNaN(endDate.unix())) {
      return 0;
    }

    const oneDay = 24 * 60 * 60 * 1000;
    const todayDate = moment();
    const diffInMilliseconds = todayDate.diff(endDate);

    return Math.round(Math.abs(diffInMilliseconds / oneDay));
  };

  const isOrgEnterprise = (org: App.Org) => {
    const { license } = org;
    const { planType } = license;

    return planType === 'ENTERPRISE';
  };

  const isOrgEnterpriseOrTrial = (org: App.Org) => {
    return isOrgEnterprise(org) || isOrgTrialing(org);
  };

  // Helper method to check if org among the 3 possible plan states
  // Returns either FREE, PRO or ENTERPRISE
  const getOrgPlanState = (org: App.Org) => {
    if (!isOrgPaidOrTrial(org)) {
      return PLAN_TYPE.FREE;
    }

    return isOrgEnterprise(org) ? PLAN_TYPE.ENTERPRISE : PLAN_TYPE.PRO;
  };

  return {
    isOrgPaidOrTrial,
    isOrgAbleToTrial,
    isOrgEnterpriseOrTrial,
    calculateDaysLeft,
    isOrgEnterprise,
    getOrgPlanState,
  };
};

export default OrgPaidStatus();
