import React from 'react';
import { Link } from 'react-router-dom';
import Helpers from '~/utils/Helpers';
import { VC_USER_ROLES } from '~/constants/ModelConstants';

interface WorkspaceUsersQuickViewProps {
  data?: any[];
  showDetailsLink?: boolean;
  showGroups?: boolean;
  showDirectMembership: boolean;
}

const WorkspaceUsersQuickView: React.FunctionComponent<WorkspaceUsersQuickViewProps> = props => {
  const { data: users = [], showDetailsLink, showGroups, showDirectMembership } = props;
  const columnWidths = {
    email: showGroups ? 'col-1-4' : 'col-1-3',
    userRole: 'col-1-10',
    admin: showGroups ? 'col-1-7' : '',
    groups: 'col-1-6',
    direct: showGroups ? 'col-1-7' : '',
  };

  return (
    <div className="bo-v--1 border-color--muted mv-- pb-">
      <div className="bg-color--muted-light pv-- font--h7 pl- color--muted-dark mb-">
        <strong>USERS</strong>
      </div>
      <div className="grid col-1-1">
        <div className={`grid__item ${columnWidths.email}`}>
          <strong>Email</strong>
        </div>
        <div className={`grid__item ${columnWidths.firstName}`}>
          <strong>First Name</strong>
        </div>
        <div className={`grid__item ${columnWidths.lastName}`}>
          <strong>Last Name</strong>
        </div>
        <div className={`grid__item ${columnWidths.userRole}`}>
          <strong>User Role</strong>
        </div>
        <div className={`grid__item text--center ${columnWidths.admin}`}>
          <strong>Workspace Admin</strong>
        </div>
        {showGroups && (
          <div className={`grid__item text--center ${columnWidths.groups}`}>
            <strong>Access Through Team</strong>
          </div>
        )}
        {showDirectMembership && (
          <div className={`grid__item text--center ${columnWidths.direct}`}>
            <strong>Direct Membership</strong>
          </div>
        )}
      </div>

      <div className="overflow--auto max-height--300">
        {users.map(userObj => {
          const { user = {}, orgRoles = [] } = userObj;
          const membership = userObj.membership || {};
          const { id: userId, email, firstName, lastName } = user;
          const { roles = [], direct, numGroups } = membership;
          const userRole = orgRoles.find(role => role !== 'USER') || orgRoles[0] || ''; // orgRoles is always at least ['USER']
          const isOrgOwnerOrAdmin = orgRoles.includes('OWNER') || orgRoles.includes('ADMIN');

          return (
            <div className="grid col-1-1 mt--" key={`workspace-group-user-${userId}`}>
              <div className={`grid__item text--ellipsis ${columnWidths.email}`} title={email}>
                {showDetailsLink ? (
                  <strong>
                    <Link to={`/org/settings/users/${userId}`} className="link--obvious">
                      {email}
                    </Link>
                  </strong>
                ) : (
                  <div>{email}</div>
                )}
              </div>
              <div className={`grid__item ${columnWidths.firstName}`}>{firstName || '--'}</div>
              <div className={`grid__item ${columnWidths.lastName}`}>{lastName || '--'}</div>
              <div className={`grid__item flex align-items--center ${columnWidths.userRole}`}>
                {Helpers.capFirst(VC_USER_ROLES[userRole] || userRole)}
              </div>
              <div className={`grid__item text--center ${columnWidths.admin}`}>
                {roles.includes('WORKSPACE_ADMIN') || isOrgOwnerOrAdmin ? 'Yes' : '--'}
              </div>
              {showGroups && (
                <div
                  className={`grid__item flex justify-content--center ${columnWidths.groups} ${
                    numGroups === 0 ? 'color--muted' : ''
                  }`}
                >
                  {isOrgOwnerOrAdmin
                    ? '(All)'
                    : `${numGroups || 0} team${numGroups === 1 ? '' : 's'}`}
                </div>
              )}
              {showDirectMembership && (
                <div className={`grid__item text--center ${columnWidths.direct}`}>
                  {direct ? 'Yes' : '--'}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkspaceUsersQuickView;
