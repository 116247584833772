import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OrgPaidStatus from '~/utils/OrgPaidStatus';
import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';

import SourceClearLoader from '~/components/SourceClearLoader';

import * as reportScopeActions from '~/actions/reportScope';
import { RouteComponentProps } from 'react-router-dom';

interface RedirectFromScanProps extends RouteComponentProps {
  reportScopeActions: object;
  orgState: App.OrgState;
}
class RedirectFromScan extends React.Component<RedirectFromScanProps, {}> {
  componentDidMount() {
    const { orgState, match, history } = this.props;
    const { org } = orgState;
    const { params = {} } = match;
    const { teamId, scanId, page = '' } = params;
    const endpoint = `/teams/${teamId}/reports/repos`;

    if (OrgPaidStatus.isOrgPaidOrTrial(org)) {
      this.props.reportScopeActions.updateReportScope('repoScanId', parseInt(scanId));
    }

    ApiService.post(endpoint, {
      data: { scope: { repoScanId: scanId, repos: '*' }, filter: {} },
    })
      .then(res => {
        const { _embedded = {} } = res;
        const { repos = [] } = _embedded;

        if (repos.length) {
          const { id: repoId } = repos[0];
          const basePath = `/workspaces/${teamId}/projects/${repoId}`;

          history.replace(`${basePath}/${page ? page : 'issues'}`);
        } else {
          history.push('/404');
        }
      })
      .catch(error => {
        ErrorService.capture('Error fetching scan info', error);
        history.push('/404');
      });
  }

  render() {
    return (
      <div className="col-1-1 flex justify-content--center">
        <SourceClearLoader />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportScopeActions: bindActionCreators(reportScopeActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectFromScan);
