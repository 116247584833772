import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';

import config from '~/config';
import Helpers from '~/utils/Helpers';
import * as loginActions from '~/actions/login';
interface ForgotPasswordPageProps {
  loginActions: object;
  loginState: object;
}
class ForgotPasswordPage extends React.Component<ForgotPasswordPageProps, any> {
  componentWillUnmount() {
    this.props.loginActions.resetState();
  }

  sendResetPasswordEmail(e) {
    e.preventDefault();
    const { target = {} } = e;
    const { email = {} } = target;
    const { value } = email;
    const orgSlug = Helpers.getOrgSlug();
    const params = {
      email: value,
    };
    if (orgSlug && orgSlug !== 'app') {
      params.org = orgSlug;
    }

    if (value) {
      this.props.loginActions.sendResetPasswordEmail(params);
    }
  }

  render() {
    const { loginState } = this.props;
    const { sendPasswordResetEmail } = loginState;

    return (
      <div className="container flex align-items--center justify-content--center height--75vh">
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>
        <div className="grid grid--center col-2-5 mb++">
          <div className="grid__item col-1-1 text--center">
            <h3>Security for open-source code.</h3>
          </div>
          <div className="grid__item col-1-1 mt">
            Enter your email address and we will send you a link to reset your password.
          </div>
          <div className="grid__item col-1-1 mt-">
            <form onSubmit={e => this.sendResetPasswordEmail(e)}>
              <input
                type="email"
                name="email"
                required="true"
                placeholder="Enter your email address"
                className="control--text col-1-1"
              />
              {sendPasswordResetEmail === 'SUCCESS' && (
                <div className="mt-- col-1-1 text--center">
                  If we find an account matching that address, we will send a password-reset email.
                  If you do not receive an email within 5 minutes,{' '}
                  <a
                    href={config.APP_URL || 'https://app.sourceclear.io'}
                    className="link--obvious"
                  >
                    create an account
                  </a>{' '}
                  or{' '}
                  <a
                    href="mailto:support@veracode.com?subject=Request For SourceClear Support"
                    className="link--obvious"
                  >
                    contact support
                  </a>
                  .
                </div>
              )}
              {sendPasswordResetEmail === 'FAILURE' && (
                <div className="mt-- col-1-1 text--danger text--center">
                  Oops, something went wrong. Check your email address and try again. If you need
                  additional help, please{' '}
                  <a
                    href="mailto:support@veracode.com?subject=Request For SourceClear Support"
                    className="link--obvious"
                  >
                    contact support
                  </a>
                  .
                </div>
              )}
              <button className="btn--primary col-1-1 pv- mt- font--h6" type="submit">
                Send password reset email
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginState: state.loginState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
