import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';
import * as repoActions from '~/actions/repo';
import * as statsActions from '~/actions/stats';
import { USE_LAST_SCANNED } from '~/constants/ModelConstants';
import Helpers from '~/utils/Helpers';

export const FETCH_PROJECT_BRANCH_REQUEST = 'FETCH_PROJECT_BRANCH_REQUEST';
export const FETCH_PROJECT_BRANCH_SUCCESS = 'FETCH_PROJECT_BRANCH_SUCCESS';
export const FETCH_PROJECT_BRANCH_FAILURE = 'FETCH_PROJECT_BRANCH_FAILURE';

export const RESET_UPDATE_DEFAULT_BRANCH = 'RESET_UPDATE_DEFAULT_BRANCH';
export const UPDATE_DEFAULT_BRANCH_REQUEST = 'UPDATE_DEFAULT_BRANCH_REQUEST';
export const UPDATE_DEFAULT_BRANCH_SUCCESS = 'UPDATE_DEFAULT_BRANCH_SUCCESS';
export const UPDATE_DEFAULT_BRANCH_FAILURE = 'UPDATE_DEFAULT_BRANCH_FAILURE';

export const SEARCH_PROJECT_BRANCH_REQUEST = 'SEARCH_PROJECT_BRANCH_REQUEST';
export const SEARCH_PROJECT_BRANCH_SUCCESS = 'SEARCH_PROJECT_BRANCH_SUCCESS';
export const SEARCH_PROJECT_BRANCH_FAILURE = 'SEARCH_PROJECT_BRANCH_FAILURE';

export const UPDATE_BRANCH_LIST = 'UPDATE_BRANCH_LIST';
export const UPDATE_SELECTED_DEFAULT_BRANCH = 'UPDATE_SELECTED_DEFAULT_BRANCH';

export const getProjectBranchRequest = () => ({
  type: FETCH_PROJECT_BRANCH_REQUEST,
});

export const getProjectBranchSuccess = (projectId: string, branches: string[]) => ({
  type: FETCH_PROJECT_BRANCH_SUCCESS,
  projectId,
  branches,
});

export const getProjectBranchFailure = error => ({
  type: FETCH_PROJECT_BRANCH_FAILURE,
  error,
});

export const resetUpdateDefaultBranch = () => ({
  type: RESET_UPDATE_DEFAULT_BRANCH,
});

export const updateDefaultBranchRequest = () => ({
  type: UPDATE_DEFAULT_BRANCH_REQUEST,
});

export const updateDefaultBranchSuccess = () => ({
  type: UPDATE_DEFAULT_BRANCH_SUCCESS,
});

export const updateDefaultBranchFailure = error => ({
  type: UPDATE_DEFAULT_BRANCH_FAILURE,
  error,
});

export const searchProjectBranchRequest = () => ({
  type: SEARCH_PROJECT_BRANCH_REQUEST,
});

export const searchProjectBranchSuccess = (projectId: string, branches: string[]) => ({
  type: SEARCH_PROJECT_BRANCH_SUCCESS,
  projectId,
  branches,
});

export const searchProjectBranchFailure = error => ({
  type: SEARCH_PROJECT_BRANCH_FAILURE,
  error,
});

export const addSearchResultsToBranchList = (projectId: string, branches: string[]) => ({
  type: UPDATE_BRANCH_LIST,
  projectId,
  branches,
});

/*
 * A no-API request action that simply updates projectBranchState.selectedDefaultBranch
 */
export const updateSelectedDefaultBranch = (selectedDefaultBranch: string) => ({
  type: UPDATE_SELECTED_DEFAULT_BRANCH,
  selectedDefaultBranch,
});

/**
 * Gets project branches based on the search term
 */
export const getProjectBranches = (projectId: string, searchTerm = '') => dispatch => {
  dispatch(getProjectBranchRequest());

  return ApiService.get(ApiConstants.getProjectBranchesURL(projectId, searchTerm))
    .then(res => {
      dispatch(getProjectBranchSuccess(projectId, res.content));
    })
    .catch(err => {
      ErrorService.capture('Error fetching project branch with err: ', err);
      dispatch(getProjectBranchFailure(err ? err : 'An error has occurred. Please try again.'));
    });
};

export const searchProjectBranches = (projectId: string, searchTerm: string) => dispatch => {
  dispatch(searchProjectBranchRequest());

  /* Make a request to the API only if we can't find a match  */
  return ApiService.get(ApiConstants.getProjectBranchesURL(projectId, searchTerm))
    .then(res => {
      dispatch(searchProjectBranchSuccess(projectId, res.content));
    })
    .catch(err => {
      ErrorService.capture('Error fetching project branch with err: ', err);
      dispatch(searchProjectBranchFailure(err ? err : 'An error has occurred. Please try again.'));
    });
};

/**
 * Sends POST request to update default branch
 */
export const updateDefaultBranch = (projectId: string, defaultBranch: string) => (
  dispatch,
  getState
) => {
  dispatch(updateDefaultBranchRequest());

  const { navigationState } = getState();
  const { activeTeamParent: teamId } = navigationState;
  const useLatestScan = Helpers.hasLatestScanEnabled() && defaultBranch === USE_LAST_SCANNED;
  const repoBranchModel = {
    branch: useLatestScan ? null : defaultBranch,
    useLatest: useLatestScan,
  };

  return ApiService.post(ApiConstants.updateDefaultBranchURL(projectId), { data: repoBranchModel })
    .then(() => {
      dispatch(updateDefaultBranchSuccess());
      dispatch(repoActions.fetchRepoData(projectId));
      dispatch(statsActions.fetchWorkspaceStats(teamId)); //Update workspace stats to reflect counts from default branch
      dispatch(updateSelectedDefaultBranch(defaultBranch));
    })
    .catch(err => {
      ErrorService.capture('Error updating project default branch with err:', err);
      dispatch(updateDefaultBranchFailure(err ? err : 'An error has occurred. Please try again.'));
    });
};
