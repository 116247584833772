import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as MODEL from '~/constants/ModelConstants';
import Tooltip from '~/components/Tooltip';
import config from '~/config';
import VulnBox from '~/components/ReportComponents/VulnBox';
import ProIcon from '~/components/ProIcon';
import RegistryLink from '~/components/RegistryLink';
import * as upgradeModalActions from '~/actions/upgradeModal';

interface LibraryCatalogRowProps {
  rowValues: object;
  columnWidths: {
    [key: string]: string;
  };
  rowId: string;
  checked: boolean;
  onChecked: (...args: any[]) => any;
  isPaidOrTrialing?: boolean;
  upgradeModalActions: object;
}
export class LibraryCatalogRow extends React.Component<LibraryCatalogRowProps, {}> {
  onChecked = rowId => {
    return event => {
      this.props.onChecked(rowId, event.target.checked);
    };
  };

  toggleCtaPremiumVulnModalOpen = () => {
    this.props.upgradeModalActions.showUpgradeModal(MODEL.UPGRADE_MODAL_PREMIUM_VULN);
  };

  render() {
    const { rowValues, rowId, checked, columnWidths, isPaidOrTrialing } = this.props;
    const { updatesSinceVersion, instanceCommit = {} } = rowValues;
    const {
      criticalArtifactCount,
      highArtifactCount,
      mediumArtifactCount,
      lowArtifactCount,
      instance = {},
    } = instanceCommit;
    const { name: libraryName, version: libraryVersion, libraryId } = instance;

    return (
      <div className="bo--1 border-color--white-dark pl- pv-- mv--- color--muted-dark">
        <div className="grid grid--narrower grid--middle flex--align-items--stretch">
          <div className={`grid__item flex flex--align-items--center ${columnWidths.libraryName}`}>
            <Tooltip
              place="right"
              id={`${rowId}-libRow`}
              content={`${libraryName} ${libraryVersion}`}
            >
              <p className="text--overflow">
                <RegistryLink
                  href={`${config.REGISTRY_URL}/${MODEL.SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.slug}/libraries/${libraryId}?version=${libraryVersion}`}
                  className="link--obvious"
                >
                  {' '}
                  {libraryName}{' '}
                </RegistryLink>
              </p>
            </Tooltip>
          </div>

          <div className={`grid__item flex flex--align-items--center ${columnWidths.version}`}>
            {libraryVersion}
          </div>

          {!isPaidOrTrialing ? (
            <div
              className={`grid__item flex flex--align-items--center cursor--pointer ${columnWidths.vulns}`}
              onClick={this.toggleCtaPremiumVulnModalOpen}
            >
              <ProIcon />
            </div>
          ) : (
            <div
              className={`grid__item flex flex--align-items--center ${columnWidths.vulnerabilities}`}
            >
              <div className="col-11-12">
                <span className="text--center mr--">
                  <VulnBox level="CRITICAL" num={criticalArtifactCount} />
                </span>
                <span className="text--center mr--">
                  <VulnBox level="HIGH" num={highArtifactCount} />
                </span>
                <span className="text--center mr--">
                  <VulnBox level="MEDIUM" num={mediumArtifactCount} />
                </span>
                <span className="text--center mr--">
                  <VulnBox level="LOW" num={lowArtifactCount} />
                </span>
              </div>
            </div>
          )}

          <div className={`grid__item flex flex--align-items--center ${columnWidths.updatesSince}`}>
            {updatesSinceVersion}
          </div>

          <div
            className={`grid__item flex flex--align-items--center flex--justify-content--center ${columnWidths.select}`}
          >
            <label className="label--checkbox flex mb0">
              <div className="flex">
                <input
                  type="checkbox"
                  name={rowId}
                  id={rowId}
                  onChange={this.onChecked(rowId)}
                  checked={checked}
                />
                <span className={`react--checkbox mr0`} />
              </div>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    upgradeModalActions: bindActionCreators(upgradeModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LibraryCatalogRow);
