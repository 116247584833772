import * as actions from '~/actions/clipboard';

const defaultState = {
  clipboardSuccess: {},
  clipboardFailure: {},
};

const clipboardState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.ADD_TO_CLIPBOARD_SUCCESS:
      return {
        ...state,
        clipboardSuccess: {
          ...state.clipboardSuccess,
          [action.inputId]: true,
        },
      };
    case actions.REMOVE_FROM_CLIPBOARD_SUCCESS:
      return {
        ...state,
        clipboardSuccess: {
          ...state.clipboardSuccess,
          [action.inputId]: false,
        },
      };
    case actions.ADD_TO_CLIPBOARD_FAILURE:
      return {
        ...state,
        clipboardFailure: {
          ...state.clipboardFailure,
          [action.inputId]: true,
        },
      };
    case actions.REMOVE_FROM_CLIPBOARD_FAILURE:
      return {
        ...state,
        clipboardFailure: {
          ...state.clipboardFailure,
          [action.inputId]: false,
        },
      };
    default:
      return state;
  }
};

export default clipboardState;
