import React, { Component, createRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Scroll from 'react-scroll';
import _ from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReportActionsWrapper from '~/components/ReportComponents/ReportActionsWrapper';
import Pagination from '~/components/Pagination';
import ReportStringFilter from '~/containers/ReportStringFilter';
import * as pageSizeActions from '~/actions/pageSize';
import * as reportActions from '~/actions/reports';
import * as reportFilterActions from '~/actions/reportFilters';
import PageSizeDropdown from '~/containers/PageSizeDropdown';
import config from '~/config';

interface ReportHeaderProps extends RouteComponentProps {
  reportType: string;
  stringFilterPlaceholder: string;
  renderReportHeaderItems?: (...args: any[]) => React.ReactElement;
  renderSearchResultsMetadata?: (...args: any[]) => React.ReactElement;
  renderReportHeaderRow?: (...args: any[]) => React.ReactElement;
  reportsByType: object;
  reportFilterActions: object;
  reportFilterState: object;
  reportActions: object;
  pageSizeActions: object;
  pageSizeState: object;
}

class ReportHeader extends Component<ReportHeaderProps, {}> {
  constructor(props) {
    super(props);
    // In order to properly debounce, we have to manually bind
    // the following methods to 'this'
    this.reportTop = createRef();
    this.delayedRefreshReportData = _.debounce(this.refreshReportData, 300);
    this.scroll = Scroll.animateScroll;
  }
  componentWillUnmount() {
    const { reportFilterActions } = this.props;
    reportFilterActions.resetReportFilters();
  }

  refreshReportData = () => {
    const { match, pageSizeActions, reportActions, reportType } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    pageSizeActions.updatePageSizeValue(config.REPORT_DEFAULT_PAGE_SIZE);
    reportActions.fetchReport(teamId, reportType);
  };

  handleReportHeaderPaginationClick(page) {
    const { match, reportActions, reportType } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    const { current } = this.reportTop;
    const top = current.getBoundingClientRect().top;

    reportActions.fetchReport(teamId, reportType, { pageNum: page }).then(() => {
      if (top < document.body.scrollTop) {
        this.scroll.scrollTo(top, { smooth: true, duration: 300 });
      }
    });
  }

  handlePageSizeChange = (pageSize: number) => {
    const { match, reportActions, reportType, pageSizeActions } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    pageSizeActions.updatePageSizeValue(pageSize);
    reportActions.fetchReport(teamId, reportType);
  };

  updateReportHeaderStringFilter = (field, value) => {
    const { match, reportFilterActions, reportType } = this.props;
    const { params = {} } = match;
    const { projectId } = params;
    reportFilterActions.updateFilterValue(reportType, field, value, projectId);
    // debounce search input
    this.delayedRefreshReportData();
  };
  render() {
    const {
      reportsByType,
      reportFilterState,
      match,
      reportType,
      stringFilterPlaceholder,
      renderReportHeaderItems,
      renderReportHeaderRow,
      renderSearchResultsMetadata,
      pageSizeState: { pageSize },
    } = this.props;

    const { params = {} } = match;
    const { projectId } = params;
    const reportFilters =
      projectId && reportFilterState[projectId]
        ? reportFilterState[projectId][reportType] || {}
        : reportFilterState[reportType] || {};
    const { search = '' } = reportFilters;
    const { [reportType]: reportData = {} } = reportsByType;
    const { page = {} } = reportData;

    return (
      <div className="grid grid--full grid__item col-1-1">
        <div className="grid__item col-1-1" ref={this.reportTop}>
          <ReportActionsWrapper>
            <Fragment>
              {/* render report header items (checkboxes, dropdowns, CSV downloader, etc) */}
              {renderReportHeaderItems()}
            </Fragment>
            <div className="grid__item col-1-1 p-- flex flex--justify-content--space-between bg-color--white-medium">
              <div className="flex" data-automation-id="ReportHeader-Search">
                <div className="width--240 flex">
                  <ReportStringFilter
                    label={stringFilterPlaceholder}
                    field={'search'}
                    value={search}
                    onChange={(field, value) => this.updateReportHeaderStringFilter(field, value)}
                  />
                </div>
                <div
                  className="pl- flex align-items--center"
                  data-automation-id="ReportHeader-SearchResults"
                >
                  {/* render search results metadata */}
                  {renderSearchResultsMetadata()}
                </div>
              </div>
              <div className="flex">
                <PageSizeDropdown onChange={this.handlePageSizeChange} value={pageSize} />
                <Pagination
                  currentPage={page.number}
                  totalPages={page.totalPages || 1}
                  handlePageClick={page => this.handleReportHeaderPaginationClick(page)}
                />
              </div>
            </div>
          </ReportActionsWrapper>
        </div>
        <div className="grid__item col-1-1 mt--">
          {/* render a report header row */}
          {renderReportHeaderRow()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportsByType: state.reportsByType,
    reportFilterState: state.reportFilterState,
    pageSizeState: state.pageSizeState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
    reportFilterActions: bindActionCreators(reportFilterActions, dispatch),
    pageSizeActions: bindActionCreators(pageSizeActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportHeader));
