import React from 'react';

interface InviteWorkspacesQuickViewProps {
  data?: any[];
  showGroups?: boolean;
}

const InviteWorkspacesQuickView: React.FunctionComponent<InviteWorkspacesQuickViewProps> = props => {
  const { data: workspaces = [], showGroups } = props;
  const columnWidths = {
    name: showGroups ? 'col-1-4' : 'col-1-2',
    filler: showGroups ? 'col-1-8' : 'col-1-24',
  };

  return (
    <div className="bo-v--1 mv-- pb- border-color--muted">
      <div className="col-1-1 bg-color--muted-light pv-- font--h7 pl- color--muted-dark">
        <strong>WORKSPACES</strong>
      </div>
      <div className="grid mt-">
        <div className={`grid__item ${columnWidths.name}`}>
          <strong>Name</strong>
        </div>
        <div className={`grid__item text--center ${columnWidths.description}`}>
          <strong>Workspace Admin</strong>
        </div>
        {showGroups && (
          <div className={`grid__item text--center ${columnWidths.groups}`}>
            <strong>Access Through Team</strong>
          </div>
        )}
        {showGroups && (
          <div className={`grid__item text--center ${columnWidths.direct}`}>
            <strong>Invited Through Direct Assignment</strong>
          </div>
        )}
        <div className={`grid__item text--center ${columnWidths.filler}`} />
      </div>

      <div className="max-height--300 overflow--auto">
        {workspaces.map(workspaceObj => {
          const { team: workspace = {}, membership = {} } = workspaceObj;
          const { roles = [], direct, numGroups } = membership;
          const { name, id: workspaceId } = workspace;

          return (
            <div className="grid mt--" key={`invite-group-${workspaceId}`}>
              <div className={`grid__item ${columnWidths.name}`}>{name}</div>
              <div className={`grid__item text--center ${columnWidths.role}`}>
                {roles.includes('WORKSPACE_ADMIN') ? 'Yes' : '--'}
              </div>
              {showGroups && (
                <div className={`grid__item text--center ${columnWidths.groups}`}>
                  {numGroups} team
                  {numGroups === 1 ? '' : 's'}
                </div>
              )}
              {showGroups && (
                <div className={`grid__item text--center ${columnWidths.direct}`}>
                  {direct ? 'Yes' : '--'}
                </div>
              )}
              <div className={`grid__item text--center ${columnWidths.filler}`} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InviteWorkspacesQuickView;
