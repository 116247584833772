import React, { Component } from 'react';
import classNames from 'classnames';

interface VCTabProps {
  activeTab: string;
  label: string;
  id: string;
  onHandleTabClick: (id: string) => void;
}

class VCTab extends Component<VCTabProps> {
  render() {
    const { activeTab, id, label, onHandleTabClick } = this.props;
    const className = classNames('tab-list-item', {
      'tab-list-active': activeTab === id,
    });
    return (
      <div className={className} onClick={() => onHandleTabClick(id)}>
        {label}
      </div>
    );
  }
}

export default VCTab;
