import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import Helpers from '~/utils/Helpers';

import * as billingActions from '~/actions/billing';

import proSVG from '~/images/plan-type--pro.svg';

interface ScansRemainingWidgetProps {
  billingActions: object;
  billingState: object;
  orgState: App.OrgState;
}
class ScansRemainingWidget extends React.Component<ScansRemainingWidgetProps, {}> {
  componentDidMount() {
    const { orgState } = this.props;
    const { org = {} } = orgState;

    this.props.billingActions.fetchBillingDashboard(org.id);
  }

  render() {
    const { billingState, orgState } = this.props;
    const { org = {} } = orgState;
    const plan = org.plan || {};
    const { id } = plan;
    const isNewHobby = id === 1001;
    const { scanUsage = [] } = billingState;
    const scansUsed = Helpers.calculateScansUsed(scanUsage);
    let scansColor = 'color--success';
    if (scansUsed >= 10) {
      scansColor = 'color--danger';
    } else if (scansUsed >= 8) {
      scansColor = 'color--warning-light';
    }

    return (
      <div className="pl- pr- mt+">
        <div className="bo--1 bo-rad--6 border-color--muted-light ph- pt pb+ position--relative overflow--hidden">
          <div className="color--muted-dark text--center">You've used</div>
          <div className={`${scansColor} font--h4 text--center`}>
            <strong>
              {scansUsed} of {isNewHobby ? '5' : '10'}
            </strong>
          </div>
          <div className="text--center">free scans this month</div>
          <div className="mt- text--center color--muted-dark">
            Get more scans, premium vulnerability data, and scan history
          </div>
          <div className="mt- text--center">
            <Link className="pv-- ph- btn--primary-clear" to="/org/settings/subscribe">
              Upgrade Now
            </Link>
            <img
              src={proSVG}
              className="position--absolute bottom--neg-17 left--29 width--112"
              alt="Professional Plan Jet"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    billingState: state.billingState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    billingActions: bindActionCreators(billingActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScansRemainingWidget);
