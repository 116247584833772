import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Helmet from 'react-helmet';
import { FEATURE_SLUG_MAP, ORG_ROLES } from '~/constants/ModelConstants';
import Helpers from '~/utils/Helpers';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import UserWorkspacesList from '~/containers/UserWorkspacesList';

import * as orgActions from '~/actions/org';
import * as orgUserActions from '~/actions/orgUser';

import UserGroupsList from '~/containers/UserGroupsList';
import Tooltip from '~/components/Tooltip';
import SourceClearModal from '~/components/SourceClearModal';
import { RouteComponentProps } from 'react-router-dom';

interface OrgUserDetailsPageProps extends RouteComponentProps {
  orgActions: object;
  orgUserActions: object;
  myState: App.MyState;
  orgState: App.OrgState;
  orgUserState: object;
}

class OrgUserDetailsPage extends React.Component<OrgUserDetailsPageProps, {}> {
  componentDidMount() {
    const { orgState, orgUserState, match } = this.props;
    const { params = {} } = match;
    const { org = {} } = orgState;
    const { orgUsers = [], userGroups, isFetchingUserGroups } = orgUserState;
    const { userId } = params;

    if (org.id) {
      if (!orgUsers.length) {
        this.props.orgUserActions.fetchOrgUsers();
      } else {
        const activeOrgUser = orgUsers.find(userObj => userObj.user.id === parseInt(userId));

        if (activeOrgUser && !isFetchingUserGroups && !userGroups) {
          this.props.orgUserActions.fetchUserGroups(activeOrgUser);
        }
      }
    }

    this.props.orgUserActions.resetSelectedUserGroups();

    // Check if GROUPS feature is enabled. If not enabled, set Workspaces as default active tab
    const showGroups =
      FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org) ||
      OrgPaidStatus.isOrgEnterpriseOrTrial(org);

    if (!showGroups) {
      this.toggleActiveTab('WORKSPACES');
    }
  }

  componentDidUpdate(prevProps) {
    const { orgUserState, orgState, match } = this.props;
    const { org = {} } = orgState;
    const { orgState: prevOrgState } = prevProps;
    const { params = {} } = match;
    const { org: prevOrg = {} } = prevOrgState;
    const { orgUsers = [], userGroups, isFetchingUserGroups } = orgUserState;
    const { userId } = params;

    if (org.id !== prevOrg.id) {
      this.props.orgUserActions.fetchOrgUsers();
    }

    if (orgUsers.length) {
      const activeOrgUser = orgUsers.find(userObj => userObj.user.id === parseInt(userId));

      if (activeOrgUser && !isFetchingUserGroups && !userGroups) {
        this.props.orgUserActions.fetchUserGroups(activeOrgUser);
      }
    }
  }

  componentWillUnmount() {
    this.props.orgUserActions.resetUserDetails();
    this.props.orgUserActions.resetUserWorkspacesQuickViews();
  }

  confirmDeleteUser() {
    const { orgState, match, history } = this.props;
    const { org = {} } = orgState;
    const { params = {} } = match;
    const { userId } = params;

    this.props.orgActions.removeUserFromOrg(org.id, userId).then(res => {
      if (res.success) {
        this.showModal();
        history.replace(`/org/settings/users`);
      }
    });
  }

  showModal(modalType) {
    this.props.orgUserActions.showModal(modalType);
  }

  handleOrgRoleSelection(orgRole) {
    const { match, orgState } = this.props;
    const { params = {} } = match;
    const { userId } = params;
    const { org = {} } = orgState;

    this.props.orgActions.updateUserStatus(org.id, userId, orgRole.value).then(res => {
      if (res.success) {
        this.props.orgUserActions.fetchOrgUsers(userId);
        this.props.orgUserActions.resetSelectedUserGroups();
      }
    });
  }

  toggleActiveTab(tab) {
    this.props.orgUserActions.updateActiveTab(tab);
  }

  render() {
    const { match, orgUserState, orgState, myState } = this.props;
    const { me = {} } = myState;
    const { orgRoles = [] } = me;
    const { updatingUserRole, org } = orgState;
    const { sso } = org;
    const { params = {} } = match;
    const { userId, teamId } = params;
    const { orgUsers = [], showModal, activeTab } = orgUserState;
    const showGroups =
      FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org) ||
      OrgPaidStatus.isOrgEnterpriseOrTrial(org);
    const activeUser = orgUsers.find(userObj => userObj.user.id === parseInt(userId)) || {};
    const { user = {}, orgRoles: roles = [] } = activeUser;
    let userRole;
    const { email = '' } = user;
    let filteredOrgRoles = [...ORG_ROLES];

    if (roles.includes('OWNER')) {
      userRole = 'OWNER';
    } else if (roles.includes('ADMIN')) {
      userRole = 'ADMIN';
    } else {
      userRole = 'USER';
    }

    if (orgRoles.includes('ADMIN') && !orgRoles.includes('OWNER')) {
      filteredOrgRoles = ORG_ROLES.filter(role => role.value !== 'OWNER');
    }

    const hasAdminPerms = orgRoles.includes('OWNER') || orgRoles.includes('ADMIN');

    return (
      <div className="grid mt mb++">
        <Helmet>
          <title>User</title>
        </Helmet>
        <div className="grid__item col-1-1 flex align-items--baseline">
          <div className="font--h3">User</div>
          <div className="ml- font--h4 pb--- color--black-light">
            <strong>{Helpers.formatUserName(user)}</strong>
          </div>
          <div className="ml-- flex align-self--stretch align-items--end mb---">
            <button className="color--muted-dark" onClick={() => this.showModal('DELETE_USER')}>
              <i className="sci sci__trash" />
            </button>
          </div>
        </div>
        <div className="grid__item col-1-2 mt">
          <div>
            <strong>Email:</strong> {email}
          </div>
        </div>
        {!sso && (
          <div className="grid__item col-1-3 mt">
            <label className="label--checkbox flex align-items--center mb0">
              <div className="mr-">
                <strong>Role:</strong>
              </div>
              {userRole === 'OWNER' && !orgRoles.includes('OWNER') ? (
                <Tooltip
                  id="user-role"
                  fullWidth={true}
                  maxWidthClass="max-width--300"
                  content="Only organization owners can change the role of a fellow organization owner"
                >
                  <div className="control--text col-1-1 flex align-items--center color--black-light">
                    {userRole}
                  </div>
                </Tooltip>
              ) : (
                <Select
                  options={filteredOrgRoles}
                  className="col-7-8 srcclr-react-select-container"
                  classNamePrefix={'srcclr-react-select'}
                  name="org-roles"
                  value={filteredOrgRoles.find(option => option.value === userRole)}
                  isClearable={false}
                  onChange={role => this.handleOrgRoleSelection(role)}
                />
              )}
            </label>
          </div>
        )}
        <div className="grid__item col-1-6 flex align-items--center mt">
          <div
            className={`flex align-items--center ${
              updatingUserRole ? 'is-showing-100' : 'is-hiding'
            }`}
          >
            <i
              className={`${
                updatingUserRole === 'SUCCESS'
                  ? 'sci sci__check color--success-dark'
                  : 'fas fa-spin fa-spinner'
              }`}
            />
            <div className={`pl- ${updatingUserRole === 'SUCCESS' && 'color--success-dark'}`}>
              {updatingUserRole === 'SUCCESS' ? 'Success' : 'Saving'}
            </div>
          </div>
        </div>

        <div className="grid__item col-1-1 mt+">
          <div className="bo-b--3 border-color--black-light">
            <div className="grid">
              {showGroups && (
                <div className={`grid__item col-1-6`}>
                  <div
                    className={`flex justify-content--center align-items--center pv- ${
                      activeTab === 'GROUPS'
                        ? 'bg-color--black-light color--white'
                        : 'bg-color--white-dark cursor--pointer'
                    }`}
                    onClick={() => this.toggleActiveTab('GROUPS')}
                  >
                    <strong>Teams</strong>
                  </div>
                </div>
              )}
              <div className={`grid__item col-1-6`}>
                <div
                  className={`flex justify-content--center align-items--center pv- ${
                    activeTab === 'WORKSPACES'
                      ? 'bg-color--black-light color--white'
                      : 'bg-color--white-dark cursor--pointer'
                  }`}
                  onClick={() => this.toggleActiveTab('WORKSPACES')}
                >
                  <strong>Workspaces</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-1-1">
          <div className="grid__item col-1-1 mt--">
            {showGroups && activeTab === 'GROUPS' && (
              <UserGroupsList userId={userId} teamId={teamId} />
            )}
            {activeTab === 'WORKSPACES' && (
              <UserWorkspacesList
                userId={userId}
                teamId={teamId}
                showGroups={showGroups}
                hasAdminPerms={hasAdminPerms}
              />
            )}
          </div>
        </div>
        <SourceClearModal
          isOpen={showModal === 'DELETE_USER'}
          onClose={() => this.showModal()}
          title="Delete User"
          width={400}
        >
          <div className="grid">
            <div className="grid__item col-1-1 ">
              Are you sure you want to delete <strong>{Helpers.formatUserName(user)}</strong>? This
              will permanently delete their account and all their data, and cannot be undone.
            </div>
            <div className="grid__item col-1-1 flex justify-content--end mt">
              <button className="col-1-5 pv- mr-" onClick={() => this.showModal()}>
                Cancel
              </button>
              <button className="p- btn--danger" onClick={() => this.confirmDeleteUser()}>
                Delete User
              </button>
            </div>
          </div>
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myState: state.myState,
    orgState: state.orgState,
    orgUserState: state.orgUserState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orgActions: bindActionCreators(orgActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgUserDetailsPage);
