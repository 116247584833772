import React from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';
import { renderRoutesInPath } from '~/utils/RouterUtils';

interface PersonalSettingsWrapperProps extends RouteComponentProps {
  routes: any[];
}

const PersonalSettingsWrapper: React.FunctionComponent<PersonalSettingsWrapperProps> = props => {
  const { routes = [], match } = props;
  return (
    <div className="grid">
      <div className="grid__item col-1-1">
        <Switch children={renderRoutesInPath(routes, match.path)} />
      </div>
    </div>
  );
};
export default PersonalSettingsWrapper;
