import _ from 'lodash';
import moment from 'moment';
import axios, { AxiosRequestConfig } from 'axios';
import httpAdapter from 'axios/lib/adapters/http';

import config from '~/config';
import { getInterceptorFunctions } from '~/utils/ApiService.interceptors';

const isVeracodeDomain = _.includes(config.VERACODE_DOMAINS, window.location.hostname);

export const instance = axios.create({
  baseURL: isVeracodeDomain ? config.VC_API_URL : config.API_URL,
  withCredentials: true,
  adapter: httpAdapter,
});

export function formatUrl(path: string, isVeracodeDomain: boolean) {
  if (path.startsWith('http')) {
    return path;
  }
  const adjustedPath = path[0] !== '/' ? '/' + path : path;
  const url = isVeracodeDomain ? config.VC_API_URL : config.API_URL;
  return url + adjustedPath;
}

const publishPeformanceData = (start: moment.Moment, path: string) => {
  const endTime = moment();
  const timeDiff = endTime.diff(start);
  const performanceData = {
    name: 'XHR_PERFORMANCE_DATA',
    filterField: path || '',
    filterValue: timeDiff.toString(),
  };

  if (timeDiff >= (config.XHR_THRESHOLD || 3000) && window.snowplow) {
    window.snowplow('trackUnstructEvent', {
      schema: 'iglu:com.srcclr/fe_snowplow_events/jsonschema/1-0-3',
      data: performanceData,
    });
  }
};

const {
  onRequestFulfilled,
  onRequestRejected,
  onResponseFulfilled,
  onResponseRejected,
} = getInterceptorFunctions(isVeracodeDomain);
//request interceptor
instance.interceptors.request.use(onRequestFulfilled, onRequestRejected);

//response interceptor
instance.interceptors.response.use(onResponseFulfilled, onResponseRejected);

class ApiService {
  getV2<T>(path: string, config?: AxiosRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      const startTime = moment();
      instance
        .get<never, T>(formatUrl(path, isVeracodeDomain), config)
        .then(response => {
          publishPeformanceData(startTime, path);
          resolve(response);
        })
        .catch(err => reject(err));
    });
  }
  /**
   * @deprecated use getV2 for proper typing support
   */
  get(path: string, { params = {}, headers = {} } = {}) {
    return new Promise((resolve, reject) => {
      const startTime = moment();
      instance
        .get(formatUrl(path, isVeracodeDomain), {
          headers,
          params,
        })
        .then(res => {
          publishPeformanceData(startTime, path);
          resolve(res);
        })
        .catch(err => reject(err));
    });
  }

  post(path: string, { params = {}, data = {}, headers = {} } = {}) {
    return new Promise((resolve, reject) => {
      const startTime = moment();
      instance
        .post(formatUrl(path, isVeracodeDomain), data, {
          headers,
          params,
        })
        .then(res => {
          publishPeformanceData(startTime, path);
          resolve(res);
        })
        .catch(err => reject(err));
    });
  }

  put(path: string, { params = {}, data = {}, headers = {} } = {}) {
    return new Promise((resolve, reject) => {
      const startTime = moment();
      instance
        .put(formatUrl(path, isVeracodeDomain), data, {
          headers,
          params,
        })
        .then(res => {
          publishPeformanceData(startTime, path);
          resolve(res);
        })
        .catch(err => reject(err));
    });
  }

  patch(path: string, { params = {}, data = {}, headers = {} } = {}) {
    return new Promise((resolve, reject) => {
      const startTime = moment();
      instance
        .patch(formatUrl(path, isVeracodeDomain), data, {
          headers,
          params,
        })
        .then(res => {
          publishPeformanceData(startTime, path);
          resolve(res);
        })
        .catch(err => reject(err));
    });
  }

  del(path: string, { params = {}, headers = {} } = {}) {
    return new Promise((resolve, reject) => {
      const startTime = moment();
      instance
        .delete(formatUrl(path, isVeracodeDomain), {
          headers,
          params,
        })
        .then(res => {
          publishPeformanceData(startTime, path);
          resolve(res);
        })
        .catch(err => reject(err));
    });
  }
}

export default new ApiService();
