import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';

import * as twoFactorActions from '~/actions/twofactor';

import { RouteComponentProps } from 'react-router-dom';
interface TwoFactorLoginPageProps extends RouteComponentProps {
  actions: any;
  twoFactorState: object;
}
class TwoFactorLoginPage extends React.Component<TwoFactorLoginPageProps, {}> {
  constructor(props) {
    super(props);
    this.tokenRef = React.createRef();
  }
  tokenRef: React.RefObject<HTMLInputElement>;
  verifyTwoFactorToken = e => {
    e.preventDefault();
    const { history } = this.props;
    this.props.actions.verifyTwoFactorToken(this.tokenRef.current.value, history);
  };

  tryAgain = () => {
    const { twoFactorState } = this.props;
    const { isTokenInvalid } = twoFactorState;
    if (isTokenInvalid) {
      this.props.actions.updateIsTokenInvalid(false);
    }
  };

  sendSMS = () => {
    this.props.actions.sendSMS();
  };

  updateRememberComputer = () => {
    const { rememberComputer } = this.props.twoFactorState;
    this.props.actions.updateRememberComputer(!rememberComputer);
  };

  render() {
    const { twoFactorState } = this.props;
    const {
      isTwoFactorVerifying,
      isTokenInvalid,
      rememberComputer,
      isSMSing,
      sentSMS,
      smsError,
    } = twoFactorState;
    const isDone = false;
    return (
      <div className="container display-flex align-items--center justify-content--center">
        <Helmet>
          <title>Two-factor Login</title>
        </Helmet>
        <div className="grid col-1-1 mt">
          <div className="grid__item col-1-1">
            <div className="grid grid--center mt-">
              <div className="grid__item col-2-5">
                <div className="pt- font--h7">Enter your two-factor security code:</div>
                <form
                  name="twoFactorForm"
                  acceptCharset="UTF-8"
                  onSubmit={this.verifyTwoFactorToken}
                  autoComplete="off"
                  className="form form--login mt-"
                >
                  <div className="form__group form-group">
                    <input
                      type="text"
                      id="token"
                      className="col-1-1"
                      ref={this.tokenRef}
                      tabIndex="1"
                      placeholder="Token"
                      autoComplete="off"
                      required
                      onChange={this.tryAgain}
                    />
                  </div>

                  <div
                    className={`color--danger pv-- ${
                      isTokenInvalid ? 'inline-block' : 'invisible'
                    }`}
                  >
                    Invalid Token. Please try again.
                  </div>

                  <div className={`color--danger pv-- ${smsError ? 'inline-block' : 'invisible'}`}>
                    Error sending SMS token. Please try again.
                  </div>

                  <div className="form__group text--center mt-">
                    <button
                      className={`btn btn--lg pv- btn--primary col-1-1 mb- ${
                        isTwoFactorVerifying ? 'is-loading' : ''
                      }`}
                      tabIndex={3}
                      disabled={isTwoFactorVerifying || isDone}
                    >
                      {isTwoFactorVerifying && !isDone && <h5>Verifying...</h5>}
                      {!isTwoFactorVerifying && !isDone && <h5>Continue to SourceClear</h5>}
                      {isDone && <h5>Logging in...</h5>}
                    </button>

                    <button
                      className="btn btn--lg pv- btn--primary col-1-1 mb"
                      onClick={this.sendSMS}
                      disabled={isSMSing || sentSMS}
                    >
                      {!isSMSing && !sentSMS && <h5>Send Security Code via SMS</h5>}
                      {isSMSing && !sentSMS && <h5>SMS Sending...</h5>}
                      {sentSMS && <h5>SMS Sent.</h5>}
                    </button>

                    <label className="position--relative">
                      <input
                        type="checkbox"
                        checked={rememberComputer}
                        onChange={this.updateRememberComputer}
                      />
                      <span className="control--checkbox" />
                      Remember this computer for 30 days
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    twoFactorState: state.twoFactorState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(twoFactorActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorLoginPage);
