import * as actions from '~/actions/team';
import _ from 'lodash';
import Helpers from '~/utils/Helpers';

export const defaultState: App.TeamState = {
  teams: [],
  teamsFetchResolved: false,
  issueSettingsDataByTeamId: {},
  savingJira: false,
  selectedOrgWorkspaces: [],
  showModal: undefined,
};

const teamState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_TEAMS:
      return {
        ...state,
        teamsFetchResolved: true,
        teams: action.teams.map(teamObj => {
          return {
            ...teamObj.team,
            membership: teamObj.membership,
          };
        }),
      };
    case actions.UPDATE_ISSUE_SETTINGS_DATA: {
      const newIssueSettingsDataByTeamId = _.cloneDeep(state.issueSettingsDataByTeamId);
      let issueSettingsData;
      const settings = action.issueSettingsData && action.issueSettingsData.settings;
      const projects =
        settings && settings.projects && settings.projects.length
          ? settings.projects
          : [{ projectId: undefined, projectName: undefined, issueTypeId: undefined }];

      if (action.issueSettingsData && action.issueSettingsData.id) {
        issueSettingsData = settings;
        issueSettingsData.id = action.issueSettingsData.id;
        issueSettingsData.urlData = {};
        issueSettingsData.projects = projects;
      } else {
        issueSettingsData = {
          url: undefined,
          urlData: {},
          projects: [{ projectId: undefined, projectName: undefined, issueTypeId: undefined }],
          disableGithubIssues: false,
        };
      }

      newIssueSettingsDataByTeamId[action.teamId] = issueSettingsData;

      return {
        ...state,
        issueSettingsDataByTeamId: newIssueSettingsDataByTeamId,
      };
    }
    case actions.REMOVE_TEAM_FROM_ISSUE_SETTINGS_DATA: {
      const newIssueSettingsDataByTeamId = _.cloneDeep(state.issueSettingsDataByTeamId);
      delete newIssueSettingsDataByTeamId[action.teamId];

      return {
        ...state,
        issueSettingsDataByTeamId: newIssueSettingsDataByTeamId,
      };
    }
    case actions.SAVING_JIRA:
      return {
        ...state,
        savingJira: true,
      };
    case actions.JIRA_SAVE_SUCCESS:
      return {
        ...state,
        savingJira: false,
      };
    case actions.RESET_SELECTED_WORKSPACES:
      return {
        ...state,
        selectedOrgWorkspaces: [],
      };
    case actions.UPDATE_SELECTED_WORKSPACES: {
      const { selectedOrgWorkspaces = [] } = state;
      const filteredOrgWorkspaces = selectedOrgWorkspaces.filter(
        workspace => workspace.teamId !== action.teamId
      );

      return {
        ...state,
        selectedOrgWorkspaces:
          filteredOrgWorkspaces.length !== selectedOrgWorkspaces.length
            ? filteredOrgWorkspaces
            : selectedOrgWorkspaces.concat({ teamId: action.teamId, role: 'USER' }),
      };
    }
    case actions.TOGGLE_SELECTED_WORKSPACE_ADMIN_FIELD:
      return {
        ...state,
        selectedOrgWorkspaces: state.selectedOrgWorkspaces.map(workspace => {
          if (workspace.teamId === action.workspaceId) {
            workspace.role = workspace.role === 'USER' ? 'WORKSPACE_ADMIN' : 'USER';
          }
          return workspace;
        }),
      };
    case actions.SHOW_TEAM_MODAL:
      return {
        ...state,
        showModal: action.modalType,
      };
    case actions.UPDATE_WORKSPACE_NAME:
      return {
        ...state,
        nameWillBe: action.name,
        validWorkspaceName: Helpers.validateWorkspaceName(action.name),
      };
    default:
      return state;
  }
};

export default teamState;
