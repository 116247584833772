import React from 'react';

interface RepoScopeOptionsListProps {
  options: any[];
  refType: string;
  activeSpecificOption?: string;
  updateSpecificOption: (...args: any[]) => any;
}

const RepoScopeOptionsList: React.FunctionComponent<RepoScopeOptionsListProps> = props => {
  const { options, refType, activeSpecificOption } = props;

  function updateSpecificOption(option) {
    props.updateSpecificOption(option, refType);
  }

  return (
    <div className="">
      {options.map(option => {
        return (
          <div key={option} className="pl--- bo-b--1 border-color--muted-light">
            <label className="label--checkbox flex pv-- mb0">
              <div className="flex flex--align-items--center">
                <input
                  type="radio"
                  className="flex"
                  onChange={() => updateSpecificOption(option)}
                  value={option}
                  checked={option === activeSpecificOption}
                />
                <span className="react--radio" />
              </div>
              <div className="pl---">{option}</div>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default RepoScopeOptionsList;
