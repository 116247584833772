import produce from 'immer';
import * as actions from '~/actions/pageSize';
import config from '~/config';

interface ReportPageSizeState {
  pageSize: number;
}

const defaultState: ReportPageSizeState = {
  pageSize: config.REPORT_DEFAULT_PAGE_SIZE,
};

const pageSizeState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.UPDATE_PAGE_SIZE_VALUE:
        draft.pageSize = action.value;
        break;
      default:
        return state;
    }
  });
};

export default pageSizeState;
