import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Sticky from 'react-sticky-el';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import DropdownMenu from 'react-dd-menu';
import _ from 'lodash';

import OrgPaidStatus from '~/utils/OrgPaidStatus';
import { withOrgPermissionCheck } from '~/utils/Permissions';

import * as MODEL from '~/constants/ModelConstants';

import ReportHeaderRow from '~/components/ReportComponents/ReportHeaderRow';
import ReportHeaderItem from '~/components/ReportComponents/ReportHeaderItem';
import ReportActionsWrapper from '~/components/ReportComponents/ReportActionsWrapper';
import ReportStringFilter from '~/containers/ReportStringFilter';
import ReportBottom from '~/containers/ReportBottom';
import ReportFetchErrorMessage from '~/components/ReportFetchErrorMessage';
import SourceClearLoader from '~/components/SourceClearLoader';
import ProIcon from '~/components/ProIcon';

import OrgIntegrationRow from '~/components/OrgIntegrationRow';
import OrgIntegrationModal from '~/containers/OrgIntegrationModal';
import ConfirmDeleteModal from '~/containers/ConfirmDeleteModal';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as integrationActions from '~/actions/integration';
import * as reportFilterActions from '~/actions/reportFilters';
import * as reportSelectedRowsActions from '~/actions/reportSelectedRows';
import * as integrationModalActions from '~/actions/integrationModal';
import * as teamActions from '~/actions/team';
import * as upgradeModalActions from '~/actions/upgradeModal';
import { IntegrationState } from '~/reducers/integrationState.types';

const { JIRA_CLOUD, JIRA_ONPREM, JIRA_LEGACY, GITHUB } = MODEL.INTEGRATION_NAME_MAP;

const REPORT_TYPE = 'INTEGRATION';
const ACTION_DROPDOWN_ID = 'ORG_INTEGRATION_ACTION';

interface OrgIntegrationPageProps {
  dropdownMenuState: object;
  integrationState: IntegrationState;
  reportFilterState: object;
  reportSelectedRows: any[];
  orgState: App.OrgState;
  filterActions: object;
  teamState: object;
}
class OrgIntegrationPage extends Component<
  OrgIntegrationPageProps & ReturnType<typeof mapDispatchToProps>
> {
  componentDidMount() {
    this.props.integrationActions.fetchIntegrations();
    // Meant for the legacy JIRA PREM and github integration
    this.props.teamActions.fetchAllTeamsIntegrations();
  }

  componentWillUnmount() {
    this.props.reportSelectedRowsActions.clearSelectedRows();
  }

  delayCloseDropdownMenu = _.debounce(this.closeDropdownMenu, 50);

  loadMoreData = () => {
    const { integrationState } = this.props;
    const { data } = integrationState;
    const { number: pageNum, numberOfElements, size } = data;

    if ((pageNum + 1) * size < numberOfElements) {
      this.props.integrationActions.fetchIntegrations(pageNum + 1, true);
    }
  };

  updateStringFilter = (field, value) => {
    this.props.filterActions.updateFilterValue(REPORT_TYPE, field, value);
    this.clearRowSelection();
  };

  clearRowSelection = () => {
    this.props.reportSelectedRowsActions.clearSelectedRows();
  };

  handleRowSelectChange = integration => {
    this.props.reportSelectedRowsActions.updateSelectedRows(integration);
  };

  openIntegrationModal = integrationType => {
    this.props.integrationModalActions.openIntegrationModal(integrationType);
  };

  tryOpenDeleteIntegrationModal = () => {
    const { reportSelectedRows } = this.props;
    if (reportSelectedRows.length > 0) {
      this.props.integrationModalActions.openIntegrationModal(MODEL.DELETE_INTEGRATION_MODAL);
    }
  };

  closeDropdownMenu() {
    this.props.dropdownMenuActions.closeDropdownMenu(ACTION_DROPDOWN_ID);
  }

  toggleDropdown() {
    const { dropdownMenuState } = this.props;
    const { openedDropdown = {} } = dropdownMenuState;
    if (openedDropdown[ACTION_DROPDOWN_ID]) {
      this.closeDropdownMenu();
    } else {
      this.props.dropdownMenuActions.openDropdownMenu(ACTION_DROPDOWN_ID);
    }
  }

  onGithubEditIntegrationSelect = workspaceTeam => {
    this.props.integrationModalActions.setSelectedWorkspaceTeam(workspaceTeam);
    this.props.integrationModalActions.openIntegrationModal(MODEL.EDIT_GITHUB_INTEGRATION_MODAL);
  };

  onJIRAEnterpriseEditIntegrationSelect = workspaceTeam => {
    const { issueSettingsDataByTeamId = {} } = this.props.teamState;
    this.props.integrationModalActions.setSelectedWorkspaceTeam(workspaceTeam);
    this.props.integrationModalActions.setIssueSettingsToEdit(
      issueSettingsDataByTeamId[workspaceTeam.id]
    );
    this.props.integrationModalActions.openIntegrationModal(
      MODEL.EDIT_JIRA_ENTERPRISE_INTEGRATION_MODAL
    );
  };

  toggleCtaCreateIssueModalOpen(location) {
    this.props.upgradeModalActions.showUpgradeModal(
      MODEL.UPGRADE_MODAL_CREATE_INTEGRATION,
      '',
      location
    );
  }

  hasAtLeastOneLegacyIntegration = () => {
    const { teamState } = this.props;
    const { issueSettingsDataByTeamId = {} } = teamState;

    for (let key in issueSettingsDataByTeamId) {
      if (
        issueSettingsDataByTeamId[key].disableGithubIssues === false ||
        issueSettingsDataByTeamId[key].url
      ) {
        return true;
      }
    }

    return false;
  };

  render() {
    const {
      integrationState,
      reportFilterState,
      reportSelectedRows,
      dropdownMenuState,
      teamState,
      orgState,
    } = this.props;
    const { data, isFetching, errorMessage } = integrationState;
    const { content: integrations = [] } = data;
    const { [REPORT_TYPE]: integrationFilterState = {} } = reportFilterState;
    const { search = '' } = integrationFilterState;
    const { openedDropdown = {} } = dropdownMenuState;
    const { issueSettingsDataByTeamId = {}, teams = [] } = teamState;
    const { org } = orgState;
    const isPaidOrTrialing = OrgPaidStatus.isOrgPaidOrTrial(org);
    const hasAtLeastOneLegacyIntegration = this.hasAtLeastOneLegacyIntegration();
    const columnWidths = {
      name: 'col-2-5',
      type: 'col-1-5',
      workspace: 'col-1-3',
    };

    let filteredIntegration = integrations;
    if (search !== '') {
      filteredIntegration = integrations.filter(integration => {
        return integration.name.toUpperCase().indexOf(search.toUpperCase()) >= 0;
      });
    }

    const orgIntegrationRows = filteredIntegration.map((integration, index) => {
      const isRowSelected = reportSelectedRows.some(
        selectedRow => selectedRow.id === integration.id
      );
      return (
        <OrgIntegrationRow
          integration={integration}
          key={`${index}-${integration.id}`}
          onRowSelectChange={this.handleRowSelectChange}
          isSelected={isRowSelected}
          columnWidths={columnWidths}
          workspace="All"
          isPaidOrTrialing={isPaidOrTrialing}
        />
      );
    });

    let legacyIntegrationRows = [];
    for (let key in issueSettingsDataByTeamId) {
      const teamForKey = teams.filter(teams => teams.id === key)[0];
      const workspace = teamForKey.name;

      let showGithub = true;
      let showJiraEnterprise = true;
      if (search !== '') {
        showGithub =
          'GITHUB'.indexOf(search.toUpperCase()) >= 0 ||
          workspace.toUpperCase().indexOf(search.toUpperCase()) >= 0;
        showJiraEnterprise =
          'JIRA ENTERPRISE'.indexOf(search.toUpperCase()) >= 0 ||
          workspace.toUpperCase().indexOf(search.toUpperCase()) >= 0;
      }

      if (!issueSettingsDataByTeamId[key].disableGithubIssues) {
        const integration = {
          name: 'Github',
          id: `${key}-github`,
          type: 'Github',
          team: teamForKey,
        };
        const isRowSelected = reportSelectedRows.some(
          selectedRow => selectedRow.id === integration.id
        );
        if (showGithub) {
          legacyIntegrationRows.push(
            <OrgIntegrationRow
              integration={integration}
              key={`${key}-github`}
              onRowSelectChange={this.handleRowSelectChange}
              isSelected={isRowSelected}
              columnWidths={columnWidths}
              workspace={workspace}
              onGithubEditIntegrationSelect={this.onGithubEditIntegrationSelect}
              isPaidOrTrialing={isPaidOrTrialing}
            />
          );
        }
      }
      if (issueSettingsDataByTeamId[key].url) {
        const integration = {
          name: 'JIRA Enterprise',
          id: `${key}-jiraEnterprise`,
          type: 'JIRA Enterprise',
          team: teamForKey,
        };
        const isRowSelected = reportSelectedRows.some(
          selectedRow => selectedRow.id === integration.id
        );
        if (showJiraEnterprise) {
          legacyIntegrationRows.push(
            <OrgIntegrationRow
              integration={integration}
              key={`${key}-jiraEnterprise`}
              onRowSelectChange={this.handleRowSelectChange}
              isSelected={isRowSelected}
              columnWidths={columnWidths}
              workspace={workspace}
              onJIRAEnterpriseEditIntegrationSelect={this.onJIRAEnterpriseEditIntegrationSelect}
              isPaidOrTrialing={isPaidOrTrialing}
            />
          );
        }
      }
    }

    const concatIntegrationRow = [...orgIntegrationRows, ...legacyIntegrationRows];

    const header = (
      <div className="grid grid--full grid__item col-1-1 zIndex-1--screenForeground">
        <Helmet>
          <title>Integrations</title>
        </Helmet>
        <div className="pb--- font--h3 col-1-1" data-automation-id="OrgIntegrationPage-Title">
          Integrations
        </div>
        {!isPaidOrTrialing && integrations.length > 0 && hasAtLeastOneLegacyIntegration && (
          <div>
            Looking to edit your integrations?{' '}
            <Link className="link--obvious" to="/org/settings/subscribe">
              Upgrade now
            </Link>
          </div>
        )}
        <div className="grid__item col-1-1 pt">
          <Sticky>
            <ReportActionsWrapper>
              <div className="grid__item col-1-4">
                <ReportStringFilter
                  label={'Search integrations'}
                  field={'search'}
                  value={search}
                  onChange={this.updateStringFilter}
                />
              </div>
              <div className="grid__item col-2-3">
                {concatIntegrationRow.length} integration
                {concatIntegrationRow.length === 1 ? '' : 's'}
              </div>
              <div className="grid__item -mr">
                <DropdownMenu
                  textAlign="left"
                  animate={true}
                  align="right"
                  isOpen={!!openedDropdown[ACTION_DROPDOWN_ID]}
                  close={() => this.delayCloseDropdownMenu()}
                  toggle={
                    <button
                      data-automation-id="OrgIntegrationPage-Dropdown-Actions"
                      className="text--right p- btn--success"
                      onClick={() => this.toggleDropdown()}
                    >
                      Actions
                    </button>
                  }
                >
                  {isPaidOrTrialing ? (
                    <span>
                      <li>
                        <button 
                          data-automation-id="OrgIntegrationPage-Dropdown-CreateJiraCloudIntegration"
                          className="ph-"
                          onClick={() => this.openIntegrationModal(MODEL.CREATE_JIRA_STEP_1)}
                        >
                          Create {JIRA_CLOUD} Integration
                        </button>
                      </li>
                      <li>
                        <button
                          data-automation-id="OrgIntegrationPage-Dropdown-CreateJiraOnPremIntegration"
                          className="ph-"
                          onClick={() =>
                            this.openIntegrationModal(MODEL.CREATE_PROXY_CLIENT_STEP_1)
                          }
                        >
                          Create {JIRA_ONPREM} Integration
                        </button>
                      </li>
                      <li>
                        <button
                          data-automation-id="OrgIntegrationPage-Dropdown-CreateJiraLegacyIntegration"
                          className="ph-"
                          onClick={() =>
                            this.openIntegrationModal(
                              MODEL.CREATE_JIRA_ENTERPRISE_INTEGRATION_MODAL
                            )
                          }
                        >
                          Create {JIRA_LEGACY} Integration
                        </button>
                      </li>
                      <li>
                        <button
                          data-automation-id="OrgIntegrationPage-Dropdown-CreateGithubIntegration"
                          className="ph-"
                          onClick={() =>
                            this.openIntegrationModal(MODEL.CREATE_GITHUB_INTEGRATION_MODAL)
                          }
                        >
                          Create {GITHUB} Integration
                        </button>
                      </li>
                      <li className="bo-b--1 border-color--white-dark" />
                      <li
                        className={`${
                          reportSelectedRows.length === 0 ? 'color--muted' : 'color--danger'
                        }`}
                      >
                        <button
                          className={`ph- ${
                            reportSelectedRows.length === 0
                              ? 'disabled color--muted'
                              : 'color--danger cursor--pointer'
                          }`}
                          onClick={() => this.tryOpenDeleteIntegrationModal()}
                        >
                          Delete
                        </button>
                      </li>
                    </span>
                  ) : (
                    <span>
                      <li>
                        <button
                          onClick={() =>
                            this.toggleCtaCreateIssueModalOpen(
                              `INTEGRATION_LIST_CREATE_JIRA_CLOUD_INTEGRATION_BTN`
                            )
                          }
                        >
                          <ProIcon />
                          Create {JIRA_CLOUD} Integration
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() =>
                            this.toggleCtaCreateIssueModalOpen(
                              `INTEGRATION_LIST_CREATE_JIRA_CLOUD_INTEGRATION_BTN`
                            )
                          }
                        >
                          <ProIcon />
                          Create {JIRA_ONPREM} Integration
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() =>
                            this.toggleCtaCreateIssueModalOpen(
                              `INTEGRATION_LIST_CREATE_JIRA_ENTERPRISE_INTEGRATION_BTN`
                            )
                          }
                        >
                          <ProIcon />
                          Create {JIRA_LEGACY} Integration
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() =>
                            this.toggleCtaCreateIssueModalOpen(
                              `INTEGRATION_LIST_CREATE_GITHUB_INTEGRATION_BTN`
                            )
                          }
                        >
                          <ProIcon />
                          Create {GITHUB} Integration
                        </button>
                      </li>
                      <li className="bo-b--1 border-color--white-dark" />
                      <li
                        className={`${
                          reportSelectedRows.length === 0 ? 'color--muted' : 'color--danger'
                        }`}
                      >
                        <button
                          className={`ph- ${
                            reportSelectedRows.length === 0
                              ? 'disabled color--muted'
                              : 'color--danger cursor--pointer'
                          }`}
                          onClick={() => this.tryOpenDeleteIntegrationModal()}
                        >
                          Delete
                        </button>
                      </li>
                    </span>
                  )}
                </DropdownMenu>
              </div>
            </ReportActionsWrapper>
          </Sticky>
        </div>
        <div className="grid__item col-1-1 mt--">
          <ReportHeaderRow>
            <ReportHeaderItem
              label="Integration Name"
              field={'name'}
              widthClass={columnWidths.name}
            />
            <ReportHeaderItem label="Type" field={'type'} widthClass={columnWidths.type} />
            <ReportHeaderItem
              label="Workspace"
              field={'workspace'}
              widthClass={columnWidths.workspace}
            />
            <ReportHeaderItem label="Select" alignment="CENTER" />
          </ReportHeaderRow>
        </div>
        <ConfirmDeleteModal />
        <OrgIntegrationModal />
      </div>
    );

    if (isFetching && !integrations.length) {
      return (
        <div className="grid pt+">
          {header}
          <div className="grid__item col1-1 pt">
            <SourceClearLoader />
          </div>
        </div>
      );
    }

    if (errorMessage) {
      return (
        <div className="grid grid--center pt">
          {header}
          <div className="grid__item col-3-5 mt">
            <ReportFetchErrorMessage type={'integrations'} />
          </div>
        </div>
      );
    }

    const renderIntegrationRow = () => {
      if (concatIntegrationRow.length > 0) {
        return concatIntegrationRow;
      } else if (search.length > 0) {
        return <h3 className="ml color--muted mt+">No integrations found.</h3>;
      }
      return (
        <div className="bo--1 border-color--muted bg-color--white-light p">
          <div className="grid">
            <div className="grid__item col-1-1 text--center mt--">
              <div className="font--h6">You have 0 integrations set up currently.</div>
            </div>
            <div className="grid__item col-1-1 text--center mt-- font--h7">
              <div>
                Integrations allow you to create an issue from SourceClear into your integrations
              </div>
              <div>Set up an integration by clicking on the Actions button above.</div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="grid pt+">
        {header}
        <div className="grid__item col-1-1">{renderIntegrationRow()}</div>
        <div className="grid__item col-1-1">
          <ReportBottom onVisible={this.loadMoreData} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    integrationState: state.integrationState,
    reportFilterState: state.reportFilterState,
    reportSelectedRows: state.reportSelectedRows,
    orgState: state.orgState,
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions as any, dispatch),
    integrationActions: bindActionCreators(integrationActions as any, dispatch),
    filterActions: bindActionCreators(reportFilterActions as any, dispatch),
    reportSelectedRowsActions: bindActionCreators(reportSelectedRowsActions as any, dispatch),
    integrationModalActions: bindActionCreators(integrationModalActions as any, dispatch),
    teamActions: bindActionCreators(teamActions as any, dispatch),
    upgradeModalActions: bindActionCreators(upgradeModalActions as any, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOrgPermissionCheck(OrgIntegrationPage, 'integrations'));
