import React from 'react';

interface LogoutProps {
  onClick: (...args: any[]) => any;
}

const Logout: React.FunctionComponent<LogoutProps> = props => {
  return (
    <div
      className="grid grid--full grid--middle p-"
      onClick={() => props.onClick()}
      data-e2e={'Logout-logoutButton'}
    >
      <div className="grid__item col-1-6">
        <i className="sci sci__data--export" />
      </div>
      <div className="grid__item">Logout</div>
    </div>
  );
};

export default Logout;
