import React from 'react';
import { useSelector } from 'react-redux';
import UnifiedOrgPoliciesPage from '~/containers/UnifiedOrgPoliciesPage';
import { RootState } from '~/reducers';
import OrgPoliciesPage from '~/containers/OrgPoliciesPage';
import PolicyMigrationRulesPage from '~/containers/PolicyMigrationRulesPage';

const OrgPoliciesHub = () => {
  const permissions = useSelector((state: RootState) => state?.myState?.me?.permissions);
  const isUnifiedPolicy = permissions?.unifiedPolicy;
  const isPolicyMigrationInProgress = permissions?.unifiedPolicyMigration;

  if (isPolicyMigrationInProgress) {
    return <PolicyMigrationRulesPage />;
  } else {
    return isUnifiedPolicy ? <UnifiedOrgPoliciesPage /> : <OrgPoliciesPage />;
  }
};

export default OrgPoliciesHub;
