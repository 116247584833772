import * as actions from '~/actions/orgAgent';

import Helpers from '~/utils/Helpers';

import { CLIENT_PAGINATION_LIMITS } from '~/constants/ModelConstants';

export const defaultState = {
  orgAgents: [],
  filteredAgents: [],
  pagination: {},
  search: '',
  isFetchingOrgAgents: false,
};

const orgAgentState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_ORG_AGENTS:
      return {
        ...state,
        orgAgents: action.orgAgents,
        filteredAgents: action.orgAgents,
        isFetchingOrgAgents: false,
        pagination: {
          totalPages: Math.ceil(action.orgAgents.length / CLIENT_PAGINATION_LIMITS.agentList) || 1,
          currentPage: 0,
        },
      };
    case actions.FETCHING_ORG_AGENTS:
      return {
        ...state,
        isFetchingOrgAgents: true,
      };
    case actions.UPDATE_ORG_AGENT_FILTER_INPUT: {
      const filteredAgents = filterAgents(state.orgAgents, action.value);

      return {
        ...state,
        filteredAgents,
        search: action.value,
        pagination: {
          totalPages: Math.ceil(filteredAgents.length / CLIENT_PAGINATION_LIMITS.agentList) || 1,
          currentPage: 0,
        },
      };
    }
    case actions.UPDATE_ORG_AGENTS_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.currentPage,
        },
      };
    default:
      return state;
  }
};

export default orgAgentState;

const filterAgents = (agents, value = '') => {
  const filterValue = value.toLowerCase();

  return agents.filter(agent => {
    const createdBy = agent.createdBy || {};
    const displayName = Helpers.formatUserName(createdBy);

    return (
      agent.name.toLowerCase().includes(filterValue) ||
      displayName.toLowerCase().includes(filterValue)
    );
  });
};
