import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as passwordActions from '~/actions/password';
import { strengthProps } from '~/constants/ModelConstants';

interface ConfirmPasswordInputsProps {
  passwordActions: object;
  fontSizeClass?: string;
  passwordPlaceholderText?: string;
  errorToShow?: object;
  disableInputs?: boolean;
  autoFocus?: boolean;
  disableConfirmPassword?: boolean;
  clearInputErrors?: (...args: any[]) => any;
  passwordInputs: object;
}
class ConfirmPasswordInputs extends React.Component<ConfirmPasswordInputsProps, {}> {
  componentWillUnmount() {
    this.props.passwordActions.resetPasswordState();
  }

  updatePasswordInput(e) {
    this.props.passwordActions.updatePasswordInput(e.target.value);
    this.props.clearInputErrors && this.props.clearInputErrors();
  }

  updateConfirmPasswordInput(e) {
    this.props.passwordActions.updateConfirmPasswordInput(e.target.value);
    this.props.clearInputErrors && this.props.clearInputErrors();
  }

  render() {
    const {
      passwordInputs,
      errorToShow,
      disableInputs,
      fontSizeClass = '',
      passwordPlaceholderText,
      autoFocus = false,
    } = this.props;
    const { password, confirmPassword, passwordStrength = {} } = passwordInputs;
    const { score } = passwordStrength;
    const currentStrengthProps = strengthProps[score] || {};

    return (
      <div>
        <input
          autoComplete={'current-password'}
          type="password"
          className={`col-1-1 mt- control--text ph- ${fontSizeClass}`}
          name="password"
          placeholder={passwordPlaceholderText || 'Password'}
          value={password}
          onChange={e => this.updatePasswordInput(e)}
          required={true}
          autoFocus={autoFocus}
          pattern=".{8,}"
          title="8 characters minimum"
          disabled={disableInputs}
        />
        <div
          className={`grid grid--full flex mt-- ${password.length ? 'is-showing-50' : 'is-hiding'}`}
        >
          <div className="grid__item col-1-18">
            <i className="sci sci__lock text--muted" />
          </div>
          <div className={`grid__item smooth-width pv-- flex ${currentStrengthProps.width}`}>
            <div className={`col-1-1 ${currentStrengthProps.color}`} />
          </div>
          <div className="grid__item col-1-6 pl--">{currentStrengthProps.text}</div>
        </div>
        <div className="flex position--relative align-items--center mt--">
          <input
            autoComplete={'new-password'}
            type="password"
            className={`col-1-1 control--text ph- ${fontSizeClass}`}
            name="confirmPassword"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={e => this.updateConfirmPasswordInput(e)}
            required={true}
            disabled={disableInputs}
          />
          {confirmPassword.length && confirmPassword === password ? (
            <div className="flex">
              <i className="sci sci__check color--success position--relative left--neg-35 font--h5" />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className={errorToShow ? 'is-showing-50' : 'is-hiding'}>{errorToShow}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    passwordInputs: state.passwordInputs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    passwordActions: bindActionCreators(passwordActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPasswordInputs);
