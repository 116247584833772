import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';

import { RouteComponentProps } from 'react-router-dom';
import * as MODEL from '~/constants/ModelConstants';

import AgentSetupBreadcrumbs from '~/components/AgentSetupBreadcrumbs';
import InstallStepsCLI from '~/components/InstallStepsCLI';
import JenkinsInstallSteps from '~/components/JenkinsInstallSteps';
import MavenInstallSteps from '~/components/MavenInstallSteps';
import GradleInstallSteps from '~/components/GradleInstallSteps';
import BambooConfiguration from '~/components/BambooConfiguration';
import CircleConfiguration from '~/components/CircleConfiguration';
import CodeshipConfiguration from '~/components/CodeshipConfiguration';
import PipelinesConfiguration from '~/components/PipelinesConfiguration';
import TravisConfiguration from '~/components/TravisConfiguration';
import WindowsConfiguration from '~/components/WindowsConfiguration';
import AgentWorkspaceSelection from '~/containers/AgentWorkspaceSelection';

import * as agentActions from '~/actions/agents';
import * as statsActions from '~/actions/stats';
import * as welcomeActions from '~/actions/welcome';

interface AgentSetupPageProps extends RouteComponentProps {
  agentActions: object;
  statsActions: object;
  welcomeActions: object;
  agentState: object;
  messageState: object;
  teamState: object;
  welcomeState: object;
}
class AgentSetupPage extends React.Component<AgentSetupPageProps, {}> {
  componentDidMount() {
    const { agentState, teamState, match } = this.props;
    const { teams = [] } = teamState;
    const { params = {} } = match;
    const { integrationType, teamId } = params;
    const isFetchingAgentMessages = agentState.isFetchingAgentMessages;
    const scmAgentCheck = {
      github: true,
      ghe: true,
      bitbucket: true,
      gitserver: true,
      cli: true,
    };

    this.props.agentActions.updateIntegrationType(integrationType);

    if (teams.length) {
      this.props.agentActions.updateSelectedWorkspace({
        value: teams[0].id,
        label: teams[0].name,
      });
    }

    if (scmAgentCheck[integrationType]) {
      // initial post to create a new activation token
      this.createActivationToken();

      this.props.agentActions.allowPolling();
      if (!isFetchingAgentMessages) {
        this.props.agentActions.fetchAgentMessages(false, teamId);
      }
    }
  }

  componentWillUnmount() {
    this.props.agentActions.stopPolling();
    this.props.agentActions.clearPluginTokens();
    this.props.agentActions.clearActivationToken();
  }

  createActivationToken() {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId, integrationType } = params;
    const options = MODEL.agentTokenOptions[integrationType];

    options.teamId = teamId;
    this.props.agentActions.createActivationToken(options);
  }

  toggleDownloadOption(option) {
    this.props.agentActions.updateInstallOption(option);
  }

  updateSelectedWorkspace(workspace) {
    this.props.agentActions.updateSelectedWorkspace(workspace);
  }

  updateScanTips(scanTip) {
    this.props.welcomeActions.updateScanTips(scanTip);
  }

  fetchWorkspaceStats() {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    this.props.statsActions.fetchWorkspaceStats(teamId);
  }

  handleScriptTabChange = field => {
    this.props.agentActions.updateActiveScriptMode(field);
  };

  render() {
    const { agentState, welcomeState, teamState, messageState, match, history } = this.props;
    const { teams = [] } = teamState;
    const { params = {} } = match;
    const { integrationType, teamId } = params;
    const { scanTip } = welcomeState;
    const { activeScriptMode } = agentState;

    const { pathname } = this.props.location;
    const isOrgPage = pathname.startsWith('/org/');

    return (
      <div className="grid col-1-1 mb++">
        <Helmet>
          <title>{teamId ? 'Workspace Agent Setup' : 'Agent Setup'}</title>
        </Helmet>
        <div className="grid__item col-1-1 mt">
          <AgentSetupBreadcrumbs teamId={teamId} option={integrationType} />
        </div>
        <div className="grid__item col-3-5 mt">
          {integrationType === 'cli' && (
            <InstallStepsCLI
              history={history}
              toggleDownloadOption={option => this.toggleDownloadOption(option)}
              integrationType={integrationType}
              agentState={agentState}
              teams={teams}
              messageState={messageState}
              teamId={teamId}
              fetchWorkspaceStats={() => this.fetchWorkspaceStats()}
              updateSelectedWorkspace={workspace => this.updateSelectedWorkspace(workspace)}
              updateScanTips={scanTip => this.updateScanTips(scanTip)}
              scanTip={scanTip}
            />
          )}
          {integrationType === 'jenkins' && (
            <JenkinsInstallSteps
              integrationType={integrationType}
              teamId={teamId}
              onScriptTabChange={this.handleScriptTabChange}
              activeScriptMode={activeScriptMode}
              renderAgentWorkspaceSelection={() =>
                isOrgPage && <AgentWorkspaceSelection agentType={'JENKINS'} />
              }
            />
          )}
          {integrationType === 'maven' && (
            <MavenInstallSteps
              integrationType={integrationType}
              teamId={teamId}
              renderAgentWorkspaceSelection={() =>
                isOrgPage && <AgentWorkspaceSelection agentType={'MAVEN'} />
              }
            />
          )}
          {integrationType === 'gradle' && (
            <GradleInstallSteps
              integrationType={integrationType}
              teamId={teamId}
              agentState={agentState}
              renderAgentWorkspaceSelection={() =>
                isOrgPage && <AgentWorkspaceSelection agentType={'GRADLE'} />
              }
            />
          )}
          {integrationType === 'bamboo' && (
            <BambooConfiguration
              integrationType={integrationType}
              teamId={teamId}
              onScriptTabChange={this.handleScriptTabChange}
              activeScriptMode={activeScriptMode}
              isOrgPage={isOrgPage}
              renderAgentWorkspaceSelection={() =>
                isOrgPage && <AgentWorkspaceSelection agentType={'BAMBOO'} />
              }
            />
          )}
          {integrationType === 'circle' && (
            <CircleConfiguration
              integrationType={integrationType}
              teamId={teamId}
              onScriptTabChange={this.handleScriptTabChange}
              activeScriptMode={activeScriptMode}
              renderAgentWorkspaceSelection={() =>
                isOrgPage && <AgentWorkspaceSelection agentType={'CIRCLECI'} />
              }
            />
          )}
          {integrationType === 'codeship' && (
            <CodeshipConfiguration
              integrationType={integrationType}
              teamId={teamId}
              onScriptTabChange={this.handleScriptTabChange}
              activeScriptMode={activeScriptMode}
              renderAgentWorkspaceSelection={() =>
                isOrgPage && <AgentWorkspaceSelection agentType={'CODESHIP'} />
              }
            />
          )}
          {integrationType === 'pipelines' && (
            <PipelinesConfiguration
              integrationType={integrationType}
              teamId={teamId}
              onScriptTabChange={this.handleScriptTabChange}
              activeScriptMode={activeScriptMode}
              renderAgentWorkspaceSelection={() =>
                isOrgPage && <AgentWorkspaceSelection agentType={'BITBUCKET'} />
              }
            />
          )}
          {integrationType === 'travis' && (
            <TravisConfiguration
              integrationType={integrationType}
              teamId={teamId}
              renderAgentWorkspaceSelection={() =>
                isOrgPage && <AgentWorkspaceSelection agentType={'TRAVISCI'} />
              }
            />
          )}
          {integrationType === 'windows' && (
            <WindowsConfiguration
              teamId={teamId}
              integrationType={integrationType}
              toggleDownloadOption={option => this.toggleDownloadOption(option)}
              fetchWorkspaceStats={() => this.fetchWorkspaceStats()}
              updateScanTips={scanTip => this.updateScanTips(scanTip)}
              updateSelectedWorkspace={workspace => this.updateSelectedWorkspace(workspace)}
              scanTip={scanTip}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentState: state.agentState,
    messageState: state.messageState,
    teamState: state.teamState,
    welcomeState: state.welcomeState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    agentActions: bindActionCreators(agentActions, dispatch),
    statsActions: bindActionCreators(statsActions, dispatch),
    welcomeActions: bindActionCreators(welcomeActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentSetupPage);
