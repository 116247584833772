import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import * as navActions from '~/actions/navigation';
import * as teamActions from '~/actions/team';
import * as upgradeModalActions from '~/actions/upgradeModal';

import WorkspaceSwitcher from '~/containers/WorkspaceSwitcher';
import OrgNavOptions from '~/containers/OrgNavOptions';
import SettingsNavOptions from '~/containers/SettingsNavOptions';
import WorkspaceNavOptions from '~/containers/WorkspaceNavOptions';
import ScansRemainingWidget from '~/containers/ScansRemainingWidget';
import InviteToWorkspace from '~/containers/InviteToWorkspace';
import SourceClearModal from '~/components/SourceClearModal';
import VCSettingsNavOptions from '~/containers/VCSettingsNavOptions';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';

interface SidebarNavProps extends RouteComponentProps {
  navActions: object;
  agentActions: object;
  teamActions: object;
  sidebar: string;
  myState: App.MyState;
  orgState: App.OrgState;
  teamState: object;
  upgradeModalActions: object;
  vcPageState: VCPageState;
}
class SidebarNav extends React.Component<SidebarNavProps, {}> {
  componentDidMount() {
    this.props.navActions.checkUserAuth();
  }

  showModal(modalType) {
    this.props.teamActions.showModal(modalType);
  }

  render() {
    const {
      teamState,
      orgState,
      myState,
      match,
      history,
      sidebar,
      vcPageState: { shouldShowVeracodePage },
    } = this.props;
    const { location = {} } = history;
    const { isLoggedIn } = myState;
    const { org } = orgState;
    const { teams = [], showModal } = teamState;
    const { license } = org;
    const plan = org.plan || {};
    const { userLimit } = plan;
    const { planType } = license;
    const { params = {} } = match;
    const { teamId } = params;
    const expandedWorkspace = teams.find(team => team.id === teamId) || teams[0] || {};
    const { userId, permissions = {} } = expandedWorkspace;
    const { inviteUsers } = permissions;
    const isHobby = planType === 'OPEN';
    const backLinkText = shouldShowVeracodePage ? 'Back to Workspace List' : 'Back to Portfolio';

    if (!isLoggedIn) {
      return <div />;
    }

    if (shouldShowVeracodePage && (sidebar === 'PERSONAL' || sidebar === 'ORG')) {
      return (
        <div className="flex position--relative col-1-1" data-automation-id="SidebarNav">
          <Fragment>
            <div className="mv- col-1-1">
              <div data-automation-id="SidebarNav-BackLink">
                <Link
                  to={'/portfolio'}
                  className="link--obvious flex align-items--center hover--no-underline"
                >
                  <i className="fas fa-angle-left mr--" />
                  {backLinkText}
                </Link>
              </div>
              <div className="mb col-1-1">
                <VCSettingsNavOptions location={location} />
              </div>
            </div>
            <div className="h--100 w--1 bg-color--muted right--0 position--absolute" />
          </Fragment>
        </div>
      );
    } else {
      return (
        <div className="flex position--relative col-1-1" data-automation-id="SidebarNav">
          {sidebar === 'ORG' && (
            <div className="mb col-1-1">
              <OrgNavOptions location={location} />
              {isHobby && <ScansRemainingWidget />}
            </div>
          )}
          {sidebar === 'PERSONAL' && (
            <div className="mb col-1-1">
              <SettingsNavOptions location={location} />
            </div>
          )}

          {sidebar === 'WORKSPACE' && (
            <div className="mv- col-1-1">
              <div data-automation-id="SidebarNav-BackLink">
                <Link
                  to={'/portfolio'}
                  className="link--obvious flex align-items--center hover--no-underline"
                >
                  <i className="fas fa-angle-left mr--" />
                  {backLinkText}
                </Link>
              </div>

              <WorkspaceSwitcher
                workspaces={teams}
                expandedWorkspace={expandedWorkspace}
                workspaceId={teamId}
              />

              {expandedWorkspace.id && (
                <WorkspaceNavOptions expandedWorkspace={expandedWorkspace} location={location} />
              )}

              {!userId && inviteUsers && (!userLimit || userLimit !== 1) && (
                <div className="">
                  <button
                    className="col-23-24 mt- btn--primary-clear pv-- font--h7 cursor--pointer link--no-underline"
                    onClick={() => this.showModal('INVITE_TO_WORKSPACE')}
                  >
                    Invite
                  </button>
                </div>
              )}
            </div>
          )}
          <div className="h--100 w--1 bg-color--muted right--0 position--absolute" />
          <SourceClearModal
            isOpen={showModal === 'INVITE_TO_WORKSPACE'}
            onClose={() => this.showModal()}
            width={500}
            title="Invite to Workspace"
          >
            <InviteToWorkspace showModal={modalType => this.showModal(modalType)} teamId={teamId} />
          </SourceClearModal>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    agentState: state.agentState,
    myState: state.myState,
    orgState: state.orgState,
    teamState: state.teamState,
    vcPageState: state.vcPageState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navActions: bindActionCreators(navActions, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
    upgradeModalActions: bindActionCreators(upgradeModalActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarNav));
