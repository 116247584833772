import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';
import validator from 'validator';

import * as loginActions from '~/actions/login';

interface FindMyOrgPageProps {
  loginActions: object;
  loginState: object;
}
class FindMyOrgPage extends React.Component<FindMyOrgPageProps, {}> {
  findOrgsByEmail(e) {
    e.preventDefault();
    const { email = {} } = e.target;
    const { value } = email;

    if (validator.isEmail(value)) {
      this.props.loginActions.findOrgsByEmail(value);
    }
  }

  updateFindMyOrgsEmailValue(e) {
    const { value } = e.target;

    this.props.loginActions.updateFindMyOrgsEmailValue(value);
  }

  render() {
    const { loginState } = this.props;
    const { findMyOrgsEmail = '', findMyOrgsEmailStatus } = loginState;

    return (
      <div className="container flex align-items--center justify-content--center mt+">
        <Helmet>
          <title>Find My Organization</title>
        </Helmet>
        <form className="grid col-1-3" onSubmit={e => this.findOrgsByEmail(e)}>
          <div className="grid__item col-1-1">
            <h3>Find your organization</h3>
          </div>
          <div className="grid__item col-1-1 mt- color--muted-dark">Enter your email address</div>
          <div className="grid__item col-1-1 mt--">
            <input
              type="email"
              name="email"
              className="control--text col-1-1 font--h7"
              placeholder="you@example.com"
              value={findMyOrgsEmail}
              onChange={e => this.updateFindMyOrgsEmailValue(e)}
              required
            />
          </div>
          <div className="grid__item col-1-1 mt-- color--muted-dark">
            We'll send you an email with sign in links for any SourceClear organizations associated
            with this address.
          </div>
          <div className="grid__item col-1-1 mt--">
            <button className="btn--primary col-1-1 pv- font--h7">Send me a sign in email</button>
          </div>
          {findMyOrgsEmailStatus && (
            <div className="grid__item col-1-1 mt-- color--success-dark">
              <em className="pr--">{`Email sent to ${findMyOrgsEmailStatus}.`}</em>
            </div>
          )}
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginState: state.loginState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FindMyOrgPage);
