import React, { useState } from 'react';
import './toggleswitch.scss';
import { Link } from 'react-router-dom';

interface ToggleSwitchProps {
  description?: string;
  link: string;
}

const ToggleSwitch = (props: ToggleSwitchProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const toggle = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      {props.description && (
        <span className="flex mr- flex--align-items--center description">{props.description}</span>
      )}
      <Link className="flex flex--align-items--center" to={props.link}>
        <div className="toggle-switch" onClick={toggle}>
          <input type="checkbox" className="slider" checked={isChecked} />
          <span className="slider"></span>
        </div>
      </Link>
    </>
  );
};

export default ToggleSwitch;
