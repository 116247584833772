import React from 'react';

interface ReportDetailsPageUpdateAdvisorWrapperProps {
  link: string;
  onComponentDidMount: (...args: any[]) => any;
}

class ReportDetailsPageUpdateAdvisorWrapper extends React.Component<
  ReportDetailsPageUpdateAdvisorWrapperProps,
  {}
> {
  componentDidMount() {
    const { link } = this.props;
    this.props.onComponentDidMount(link);
  }

  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }
}

export default ReportDetailsPageUpdateAdvisorWrapper;
