import React from 'react';
import Select from 'react-select';

interface AddUserToGroupsProps {
  selectableUsers: any[];
  selectedUser?: boolean | object;
  selectedGroups: any[];
  toggleAdminField: (...args: any[]) => any;
  updateSelectedUsers: (...args: any[]) => any;
  buttons: React.ReactNode;
}

const AddUserToGroups: React.FunctionComponent<AddUserToGroupsProps> = props => {
  const { selectableUsers = [], selectedGroups = [], selectedUser } = props;

  function toggleAdminField(groupId) {
    props.toggleAdminField(groupId);
  }

  function updateSelectedUsers(user) {
    // selectedUsers is meant to keep track of mulitple users, but we only want one here.
    // so if there's already a selected user, remove them first
    if (selectedUser && selectedUser.value) {
      props.updateSelectedUsers(selectedUser.value);
    }

    // user will be empty if you're de-selecting the chosen user
    // in which case we've already removed them above
    if (user) {
      props.updateSelectedUsers(user.value);
    }
  }

  return (
    <div className="grid">
      <div className="grid__item col-1-1 mt-">
        <div>
          <strong className="font--h6">Choose User</strong>
        </div>
      </div>
      <div className="grid__item col-1-1 mt-">
        <Select
          options={selectableUsers}
          name="group-user"
          value={selectableUsers.find(option => option.value === selectedUser)}
          isClearable={true}
          onChange={user => updateSelectedUsers(user)}
          className={'srcclr-react-select-container'}
          classNamePrefix={'srcclr-react-select'}
        />
      </div>
      <div className="grid__item col-1-1 mt">
        <div>
          <strong className="font--h6">Choose Team Administrators</strong> (optional)
        </div>
        <div className="mt-">
          Users can be members or administrators of a team. Team administrators can change the team
          name and description, invite new users to the organization, add users to a team, remove
          users, and make other members team administrators.
        </div>
      </div>
      <div className="grid__item col-1-1 mt-">
        <div className="bo--1 border-color--black-light pv-">
          <div className="grid grid--full">
            <div className="grid__item col-1-2 pl-">
              <strong>Team Name</strong>
            </div>
            <div className="grid__item col-1-2 text--center">
              <strong>User Can Administer Team</strong>
            </div>
          </div>
          <div className="max-height--150 overflow--auto">
            {selectedGroups.map(group => {
              return (
                <div className="grid grid--full pv-- hover-row" key={`selected-${group.id}`}>
                  <div className="grid__item col-1-2 pl-">{group.name}</div>
                  <div className="grid__item col-1-2 flex align-items--center justify-content--center">
                    <label className="label--checkbox flex justify-content--center mb0">
                      <input
                        type="checkbox"
                        checked={group.stagedAdminStatus || false}
                        onChange={() => toggleAdminField(group.id)}
                      />
                      <span className="react--checkbox mr0" />
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {props.buttons}
    </div>
  );
};

export default AddUserToGroups;
