import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import _ from 'lodash';

import JiraProjectForm from '~/components/JiraProjectForm';
import SourceClearModal from '~/components/SourceClearModal';
import * as integrationModalActions from '~/actions/integrationModal';
import * as teamActions from '~/actions/team';

import { INTEGRATION_NAME_MAP } from '~/constants/ModelConstants';
const { JIRA_LEGACY } = INTEGRATION_NAME_MAP;

interface JiraEnterpriseIntegrationModalProps {
  integrationModalActions: object;
  integrationModalState: object;
  messageState: object;
  teamState: object;
  teamActions: object;
  closeWhenClickOutside?: boolean;
}
class JiraEnterpriseIntegrationModal extends React.Component<
  JiraEnterpriseIntegrationModalProps,
  {}
> {
  onClose = () => {
    this.props.integrationModalActions.closeIntegrationModal();
  };

  onWorkspaceSelected = workspace => {
    workspace && this.props.integrationModalActions.setSelectedWorkspaceTeam(workspace);
  };

  getCurrentTeamAndIssue = () => {
    const { teamState, integrationModalState } = this.props;
    const { issueSettingsDataByTeamId = {}, teams = [] } = teamState;
    const { selectedWorkspaceTeam = {}, currentIssueSettings } = integrationModalState;

    const selectedWorkspaceTeamList = teams.filter(team => team.id === selectedWorkspaceTeam.id);
    const selectedTeamId =
      selectedWorkspaceTeamList.length > 0 ? selectedWorkspaceTeamList[0].id : '';

    const issueSettingsData =
      selectedWorkspaceTeamList.length > 0
        ? issueSettingsDataByTeamId[selectedWorkspaceTeamList[0].id] || {}
        : {};
    return {
      // HACKERY, this is required as github and jira legacy is tighly coupled in the same object.
      // Every post request to update issue setting needs to ensure github is in there too
      issueSettingsData: { ...issueSettingsData, ...currentIssueSettings },
      selectedTeamId,
    };
  };

  handleSubmit = e => {
    e.preventDefault();

    const { issueSettingsData, selectedTeamId } = this.getCurrentTeamAndIssue();

    if (this.validateEmptyFields(issueSettingsData)) {
      this.props.teamActions.saveIssueSettingsData(issueSettingsData, selectedTeamId);
    } else {
      this.props.integrationModalActions.saveAttempted(selectedTeamId);
    }
  };

  handleUrlChange = e => {
    this.props.integrationModalActions.updateJiraUrl(e.target.value);
  };

  handleFocusLost = () => {
    const { issueSettingsData } = this.getCurrentTeamAndIssue();
    this.validateEmptyFields(issueSettingsData);
  };

  handleInputChange = (projectIndex, e) => {
    this.props.integrationModalActions.updateJiraProjectForm(
      projectIndex,
      e.target.name,
      e.target.value
    );
  };

  removeProject = projectIndex => {
    const { issueSettingsData } = this.getCurrentTeamAndIssue();

    issueSettingsData.projects.splice(projectIndex, 1);
    this.props.integrationModalActions.removeJiraProject(issueSettingsData);
    this.validateEmptyFields(issueSettingsData);
  };

  addProject = () => {
    this.props.integrationModalActions.addJiraProject();
  };

  validateEmptyFields = activeIssueSettingsData => {
    let isValid = true;
    const numberFields = ['projectId', 'issueTypeId'];

    activeIssueSettingsData.projects.forEach(project => {
      project.errors = [];
      if (!project.projectId || !project.projectName || !project.issueTypeId) {
        isValid = false;
        for (let key in project) {
          if (!project[key]) {
            project.errors.push(key);
          }
        }
      }

      numberFields.forEach(field => {
        if (project[field] && !Number(project[field])) {
          isValid = false;
          project.errors.push(field);
        }
      });
    });

    activeIssueSettingsData.urlData.error = !activeIssueSettingsData.url;
    isValid = activeIssueSettingsData.url && isValid;

    this.props.integrationModalActions.updateJiraProjectValidations(activeIssueSettingsData);
    return isValid;
  };

  render() {
    const {
      messageState,
      teamState,
      integrationModalState,
      closeWhenClickOutside = false,
    } = this.props;
    const { openedModal } = integrationModalState;
    const { messages = {} } = messageState;
    const { jiraSaveSuccess } = messages;

    const { savingJira, teams = [] } = teamState;

    const { issueSettingsData } = this.getCurrentTeamAndIssue();
    const { projects: jiraProjects = [], urlData: jiraUrlData = {} } = issueSettingsData;

    const { selectedWorkspaceTeam = {} } = integrationModalState;

    let workspaceList = teams.map(team => ({ value: team, label: team.name }));
    const isCreateView = openedModal.CREATE_JIRA_ENTERPRISE_INTEGRATION_MODAL;

    const { issueSettingsDataByTeamId = {} } = teamState;

    //Don't show workspace with JIRA legacy url set
    if (isCreateView) {
      workspaceList = workspaceList.filter(workspace => {
        return (
          !issueSettingsDataByTeamId[workspace.value.id].url ||
          selectedWorkspaceTeam.id === workspace.value.id
        );
      });
    }

    const saveButtonContent = () => {
      if (savingJira) {
        return (
          <div className="flex flex--align-items--center flex--justify-content--space-around">
            <i className="sci sci--spin sci__loading" /> Saving
          </div>
        );
      } else if (jiraSaveSuccess) {
        return (
          <div className="flex flex--align-items--center flex--justify-content--space-around">
            <i className="sci sci__check" /> Saved
          </div>
        );
      }
      return (
        <div className="flex flex--align-items--center flex--justify-content--space-around">
          Save
        </div>
      );
    };

    return (
      <SourceClearModal
        isOpen={true}
        title={
          isCreateView ? `Create ${JIRA_LEGACY} Integration` : `Edit ${JIRA_LEGACY} Integration`
        }
        onClose={this.onClose}
        width={600}
        minHeight={workspaceList.length > 0 ? 400 : 0}
        closeWhenClickOutside={closeWhenClickOutside}
      >
        {workspaceList.length > 0 ? (
          <div className="pt">
            <div className="flex justify-content--center align-items--center pb">
              <div className="col-1-5">
                {' '}
                <strong> Workspace: </strong>
              </div>
              {isCreateView ? (
                <Select
                  className="col-4-5 srcclr-react-select-container"
                  classNamePrefix={'srcclr-react-select'}
                  isClearable={false}
                  name="github-workspace"
                  value={workspaceList.find(option => option.value === selectedWorkspaceTeam.name)}
                  options={workspaceList}
                  onChange={field => this.onWorkspaceSelected(field.value)}
                  placeholder={`Select a workspace to configure ${JIRA_LEGACY} integration`}
                />
              ) : (
                <span className="col-4-5">{selectedWorkspaceTeam.name}</span>
              )}
            </div>

            {!_.isEmpty(selectedWorkspaceTeam) && (
              <div className="grid__item col-1-1">
                <form onSubmit={this.handleSubmit}>
                  <div className="grid flex justify-content--center align-items--center">
                    <div className="col-1-1 flex flex--align-items--center">
                      <div className="col-1-5">
                        <label htmlFor="" className="mt-">
                          Jira URL
                        </label>
                      </div>
                      <div className="col-4-5">
                        <input
                          type="text"
                          name="jiraUrl"
                          value={issueSettingsData.url || ''}
                          onChange={this.handleUrlChange}
                          onBlur={this.handleFocusLost}
                          className={
                            'control--text col-1-1 ph--' +
                            (jiraUrlData.error && jiraUrlData.saveAttempted
                              ? ' border-color--danger'
                              : '')
                          }
                          placeholder="Jira URL"
                        />
                        <div
                          className={
                            'color--danger' +
                            (jiraUrlData.error && jiraUrlData.saveAttempted
                              ? ' is-showing-50'
                              : ' is-hiding')
                          }
                        >
                          <p className="mt--">URL is a required field.</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-1-1 pt">
                      Ask your Jira adminstrator for the Project ID and IssueType ID or follow{' '}
                      <a
                        target="_blank"
                        href="https://confluence.atlassian.com/display/JIRA050/Finding+the+Id+for+Issue+Types"
                        className="link--obvious"
                      >
                        these instructions
                      </a>
                      &nbsp; to obtain the IssueType Id and then repeat the same steps to obtain the
                      Project ID.
                    </div>

                    <div className="col-1-1 overflow-y--scroll height--200">
                      {jiraProjects.map((projectObj, index) => {
                        return (
                          <JiraProjectForm
                            project={projectObj}
                            key={'index' + index}
                            handleInputChange={this.handleInputChange}
                            index={index}
                            removeProject={this.removeProject}
                            handleFocusLost={this.handleFocusLost}
                            disabled={false}
                          />
                        );
                      })}
                    </div>

                    <div className="col-1-1 flex flex--justify-content--space-between align-items--center mt+">
                      <div className="color--primary">
                        <span
                          className={'flex flex--align-items--center cursor--pointer'}
                          onClick={this.addProject}
                        >
                          <i className="sci sci__circle--add pr-" />
                          Add additional project
                        </span>
                      </div>
                      <div className="col-1-2 text--right">
                        <button className="ph mr" onClick={() => this.onClose()}>
                          {' '}
                          Cancel{' '}
                        </button>
                        <button className="btn--primary-clear mt- col-2-5" disabled={false}>
                          {saveButtonContent()}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        ) : (
          <div className="p">All workspace have {JIRA_LEGACY} activated.</div>
        )}
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationModalState: state.integrationModalState,
    teamState: state.teamState,
    messageState: state.messageState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JiraEnterpriseIntegrationModal);
