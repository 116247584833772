import React from 'react';

interface TabHeaderProps {
  className?: string;
}

const TabHeader: React.FunctionComponent<TabHeaderProps> = props => {
  const { children, className } = props;
  return <div className={className}>{children}</div>;
};

export default TabHeader;
