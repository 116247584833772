import { combineReducers, Reducer } from 'redux';
import produce from 'immer';
import _ from 'lodash';
import {
  LinkedApplication,
  VCApplicationState,
  VCApplicationsState,
  VCUpdateApplicationState,
} from '~/reducers/vcAppState/vcAppStateTypes/types';
import {
  FETCH_VC_APPLICATIONS_REQUEST,
  FETCH_VC_APPLICATIONS_SUCCESS,
  LINK_VC_APPLICATION_REQUEST,
  LINK_VC_APPLICATION_SUCCESS,
  LINK_VC_APPLICATION_FAILURE,
  UNLINK_VC_APPLICATION_REQUEST,
  UNLINK_VC_APPLICATION_SUCCESS,
  UNLINK_VC_APPLICATION_FAILURE,
  FETCH_LINKED_VC_APPLICATION_SUCCESS,
  FETCH_LINKED_VC_APPLICATION_REQUEST,
  FETCH_LINKED_VC_APPLICATION_FAILURE,
  RESET_UPDATE_LINK_STATES,
  RESET_VC_APPLICATIONS,
  UPDATE_SELECTED_DEFAULT_APPLICATION,
  RESET_SELECTED_DEFAULT_APPLICATION_VALUE,
  ADD_LINKED_APPLICATION,
  VCApplicationsActionTypes,
  FETCH_VC_APPLICATIONS_FAILURE,
} from '~/actions/vcAppActions/vcAppActionsTypes/types';

const defaultLinkedApplication: LinkedApplication = {
  appId: 'no-link',
  projectId: 'no-link',
  name: 'no-link',
  label: 'No link',
  value: 'No link',
};

const defaultVcApplicationsState: VCApplicationsState = {
  applications: [defaultLinkedApplication],
  fetchApplicationsStates: {
    isFetching: false,
    fetchSuccess: false,
    fetchFailure: false,
  },
};

const defaultUpdateApplicationState: VCUpdateApplicationState = {
  linkedApplication: {
    appId: '',
    projectId: '',
    name: '',
    label: '',
    value: '',
  },
  selectedDefaultApplication: {
    appId: '',
    projectId: '',
    name: '',
    label: '',
    value: '',
  },
  updateApplicationStates: {
    isUpdating: false,
    updateSuccess: false,
    updateFailure: false,
  },
  fetchLinkedApplicationStates: {
    isFetching: false,
    fetchSuccess: false,
    fetchFailure: false,
  },
  fetchLinkedApplicationErrorMessage: '',
};

const vcApplicationState = () => {
  function applicationsState(
    state: VCApplicationsState = defaultVcApplicationsState,
    action?: VCApplicationsActionTypes
  ) {
    return produce(state, draft => {
      switch (action.type) {
        case FETCH_VC_APPLICATIONS_REQUEST:
          draft.fetchApplicationsStates = {
            isFetching: true,
            fetchSuccess: false,
            fetchFailure: false,
          };
          break;
        case FETCH_VC_APPLICATIONS_SUCCESS:
          // merge and dedupe fetched applications with what's already in the store
          draft.applications = _.unionWith(draft.applications, action.applications, _.isEqual);
          draft.fetchApplicationsStates = {
            isFetching: false,
            fetchSuccess: true,
            fetchFailure: false,
          };
          break;
        case FETCH_VC_APPLICATIONS_FAILURE:
          draft.fetchApplicationsStates = {
            isFetching: false,
            fetchSuccess: false,
            fetchFailure: true,
          };
          break;
        case ADD_LINKED_APPLICATION:
          draft.applications.push(action.application);
          break;
        case RESET_VC_APPLICATIONS:
          // we always want 'No link' to appear as first item in the dropdown
          // so we reset applications with default 'No link' as its only element
          draft.applications = [defaultLinkedApplication];
          draft.fetchApplicationsStates = {
            isFetching: false,
            fetchSuccess: false,
            fetchFailure: false,
          };
          break;
      }
    });
  }

  function updateApplicationState(
    state: VCUpdateApplicationState = defaultUpdateApplicationState,
    action?: VCApplicationsActionTypes
  ) {
    return produce(state, draft => {
      switch (action.type) {
        case RESET_UPDATE_LINK_STATES:
          draft.updateApplicationStates = {
            isUpdating: false,
            updateSuccess: false,
            updateFailure: false,
          };
          break;
        case LINK_VC_APPLICATION_REQUEST:
          draft.updateApplicationStates = {
            isUpdating: true,
            updateSuccess: false,
            updateFailure: false,
          };
          break;
        case LINK_VC_APPLICATION_SUCCESS:
          draft.linkedApplication = action.linkedApp;
          draft.updateApplicationStates = {
            isUpdating: false,
            updateSuccess: true,
            updateFailure: false,
          };

          break;
        case LINK_VC_APPLICATION_FAILURE:
          draft.fetchLinkedApplicationErrorMessage = action.message;
          draft.updateApplicationStates = {
            isUpdating: false,
            updateSuccess: false,
            updateFailure: true,
          };
          break;
        case UNLINK_VC_APPLICATION_REQUEST:
          draft.updateApplicationStates = {
            isUpdating: true,
            updateSuccess: false,
            updateFailure: false,
          };
          break;
        case UNLINK_VC_APPLICATION_SUCCESS:
          draft.linkedApplication = defaultLinkedApplication;
          draft.updateApplicationStates = {
            isUpdating: false,
            updateSuccess: true,
            updateFailure: false,
          };
          break;
        case UNLINK_VC_APPLICATION_FAILURE:
          draft.fetchLinkedApplicationErrorMessage = action.message;
          draft.updateApplicationStates = {
            isUpdating: false,
            updateSuccess: false,
            updateFailure: true,
          };
          break;
        case FETCH_LINKED_VC_APPLICATION_SUCCESS:
          draft.linkedApplication = action.linkedApp;
          draft.selectedDefaultApplication = draft.linkedApplication;

          draft.fetchLinkedApplicationStates = {
            isFetching: false,
            fetchSuccess: true,
            fetchFailure: false,
          };
          break;
        case FETCH_LINKED_VC_APPLICATION_REQUEST:
          draft.fetchLinkedApplicationStates = {
            isFetching: true,
            fetchSuccess: false,
            fetchFailure: false,
          };
          break;
        case FETCH_LINKED_VC_APPLICATION_FAILURE:
          draft.fetchLinkedApplicationErrorMessage = action.message;
          draft.selectedDefaultApplication = defaultLinkedApplication;
          draft.fetchLinkedApplicationStates = {
            isFetching: false,
            fetchSuccess: false,
            fetchFailure: true,
          };
          break;
        case RESET_SELECTED_DEFAULT_APPLICATION_VALUE:
          draft.selectedDefaultApplication = {
            appId: '',
            projectId: '',
            name: '',
            label: '',
            value: '',
          };
          break;
        case UPDATE_SELECTED_DEFAULT_APPLICATION:
          draft.selectedDefaultApplication = action.selectedApplication;
      }
    });
  }

  const vcApplicationState: Reducer<VCApplicationState> = combineReducers({
    applicationsState,
    updateApplicationState,
  });

  return vcApplicationState;
};

export default vcApplicationState();
