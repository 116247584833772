export const UPDATE_WORKSPACE_LEVEL_SELECTED = 'UPDATE_WORKSPACE_LEVEL_SELECTED';
export const UPDATE_ORG_LEVEL_SELECTED = 'UPDATE_ORG_LEVEL_SELECTED';
export const UPDATE_SELECTED_WORKSPACE = 'UPDATE_SELECTED_WORKSPACE';
export const RESET_AGENT_MODAL_STATE = 'RESET_AGENT_MODAL_STATE';
export const SHOW_CREATE_AGENT_MODAL = 'SHOW_CREATE_AGENT_MODAL';

export const showModal = (modalType: string) => ({
  type: SHOW_CREATE_AGENT_MODAL,
  modalType,
});

export const resetAgentModalState = () => ({
  type: RESET_AGENT_MODAL_STATE,
});

export const workspaceLevelSelected = () => ({
  type: UPDATE_WORKSPACE_LEVEL_SELECTED,
});

export const orgLevelSelected = () => ({
  type: UPDATE_ORG_LEVEL_SELECTED,
});

export const updateWorkspace = (workspace: any) => ({
  type: UPDATE_SELECTED_WORKSPACE,
  workspace,
});
