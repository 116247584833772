import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import validator from 'validator';
import queryString from 'query-string';

import Helpers from '~/utils/Helpers';
import config from '~/config';
import AuthService from '~/utils/AuthService';
import NoOrgPage from '~/components/Errors/NoOrgPage';
import LoaderWrapper from '~/components/LoaderWrapper';
import * as loginActions from '~/actions/login';
import * as MODEL from '~/constants/ModelConstants';

interface LoginPageProps extends RouteComponentProps {
  loginActions: object;
  loginState: App.LoginState;
  userStatus: object;
}
class LoginPage extends Component<LoginPageProps> {
  constructor(props) {
    super(props);
    this.userEmail = React.createRef();
  }
  componentDidMount() {
    const { userStatus = {}, history } = this.props;
    const { location } = history;
    const { search } = location;
    const { roles = [] } = userStatus;
    const query = queryString.parse(search);

    if (roles.length && roles.includes('USER')) {
      // during the auth process, the user is redirected from the BE to /login with the session token
      // after the session token is processed, roles will include 'USER' if the user is logged in
      // in which case, redirect to `/portfolio` (the default page)
      // worth noting that any other redirects will have already been handled
      history.replace('/portfolio');
    }

    this.props.loginActions.updateLoginQuery(query);

    if (query.autoLogout) {
      history.replace('/login');
    }

    if (query.email) {
      const decodedURI = decodeURIComponent(query.email);
      if (query.saml) {
        this.updateSamlEmailValue({ target: { value: decodedURI } });
      } else {
        this.userEmail.current.value = decodedURI;
      }
    }

    if (localStorage && localStorage.getItem && localStorage.getItem(MODEL.SAML_EMAIL)) {
      const email = localStorage.getItem(MODEL.SAML_EMAIL);
      const isValidEmail = validator.isEmail(email);

      this.props.loginActions.updateSamlEmailValue(email, isValidEmail);
    }

    this.props.loginActions.checkOrgDomain(Helpers.getOrgSlug()).then((res = {}) => {
      const { available, samlEnabled } = res;

      //available === true means, org does *not* exist
      if (!available && samlEnabled) {
        this.props.loginActions.updateIsSamlEnabled(true);
        this.props.loginActions.updateLoginMode('SAML');
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { loginState, history } = this.props;
    const { orgMigrationStatus } = loginState;
    const { orgMigrationStatus: prevOrgMigrationStatus } = prevProps.loginState as App.LoginState;

    if (orgMigrationStatus !== prevOrgMigrationStatus) {
      let redirectUrl: string;
      let isSunsetted: boolean;
      let isMigrated: boolean;

      if (orgMigrationStatus !== 'ACTIVE') {
        isSunsetted = orgMigrationStatus === 'SUNSETTED';
        isMigrated = orgMigrationStatus === 'MIGRATED';
      }

      if (isSunsetted) {
        redirectUrl = '/plan-unsupported';
      }

      if (isMigrated) {
        redirectUrl = '/org-moved';
      }

      if (isMigrated || isSunsetted) {
        history.replace(redirectUrl);
      }
    }
  }

  componentWillUnmount() {
    this.props.loginActions.resetState();
  }
  userEmail: React.RefObject<HTMLInputElement> = null;

  loginWithUsername(e) {
    e.preventDefault();
    const twoFactor = AuthService.getTwoFactor();
    const rememberComputerToken = twoFactor ? `?rememberComputerToken=${twoFactor}` : '';
    const { target = {} } = e;
    const { username, password } = target;
    const orgSlug = Helpers.getOrgSlug();
    const params: {
      username: string;
      password: string;
      org?: string;
    } = {
      username: username.value,
      password: password.value,
      org: undefined,
    };

    if (orgSlug && orgSlug !== 'app') {
      params.org = orgSlug;
    }

    this.props.loginActions.loginWithUsername(params, rememberComputerToken);
  }

  updateSamlEmailValue(e) {
    this.props.loginActions.updateSamlEmailValue(e.target.value);
  }

  updateLoginMode(mode) {
    this.props.loginActions.updateLoginMode(mode);
  }

  render() {
    const { loginState, userStatus = {} } = this.props;
    const {
      isFetchingToken,
      query = {},
      loginError,
      samlEmailValue,
      isSamlEnabled,
      isCheckingOrgDomain,
      isOrgDomainAvailable,
      loginMode = 'PASSWORD',
    } = loginState;

    const { status } = loginError;
    const { autoLogout, error } = query;
    const orgSlug = Helpers.getOrgSlug();

    let errorMessage: JSX.Element;

    if (userStatus.loggedIn) {
      return <div />;
    }

    if (error) {
      switch (error) {
        case 'github-email':
          errorMessage = (
            <div className="grid__item col-1-1">
              <div className="panel panel--danger mt">
                <div className="panel__heading font--h7">Duplicate GitHub accounts</div>
                <div className="panel__body">
                  <p>
                    SourceClear requires that all accounts are created with a unique email address.
                    Your attempt to login with GitHub failed because the email associated with the
                    account already exists in SourceClear and is associated with a different
                    account. Please change your primary email address in GitHub or{' '}
                    <a
                      href="mailto:support@veracode.com?subject=Request For SourceClear Support"
                      className="link--obvious"
                    >
                      contact support
                    </a>{' '}
                    for further assistance.
                  </p>
                </div>
              </div>
            </div>
          );
          break;
        default:
          errorMessage = (
            <div className="grid__item col-1-1">
              <div className="panel panel--danger mt">
                <div className="panel__heading font--h7">Error</div>
                <div className="panel__body">
                  <p>Oops, something went wrong. Please try again later.</p>
                </div>
              </div>
            </div>
          );
          break;
      }
    }

    const signingInDisplay = (
      <div className="grid__item text--center col-1-1">
        <h3>
          Signing in
          <span className="loading-ellipsis ellipsis--1">.</span>
          <span className="loading-ellipsis ellipsis--2">.</span>
          <span className="loading-ellipsis ellipsis--3">.</span>
        </h3>
      </div>
    );

    const signUpForm = (
      <div className="grid__item col-1-1 flex flex--justify-content--center">
        <form method="POST" onSubmit={e => this.loginWithUsername(e)} className="col-1-1">
          <input
            autoComplete={'username email'}
            type="email"
            className="col-1-1 font--h7 control--text ph-"
            name="username"
            placeholder="Email"
            required={true}
            ref={this.userEmail}
            autoFocus
            data-e2e="LoginPage-username"
          />
          <input
            autoComplete={'current-password'}
            type="password"
            className="col-1-1 mt- font--h7 control--text ph-"
            name="password"
            placeholder="Password"
            required={true}
            data-e2e="LoginPage-password"
          />
          <div className={`color--danger ${status === 401 ? 'is-showing-50' : 'is-hiding'}`}>
            <div className="mt-">Incorrect email or password. Please try again.</div>
          </div>
          <div className="col-1-1 text--center mt--">
            <Link to="forgot-password" className="link--obvious font--h8">
              Forgot password
            </Link>
          </div>
          <button
            className="btn--primary font--h7 col-1-1 mt-- pv-"
            type="submit"
            data-e2e="LoginPage-signInButton"
          >
            Sign in with email and password
          </button>
          {isSamlEnabled && (
            <div className="text--center mt">
              <a onClick={() => this.updateLoginMode('SAML')} className="link--obvious">
                Sign in with SAML
              </a>
            </div>
          )}
          {orgSlug && orgSlug !== 'app' ? (
            <div className="grid__item col-1-1 mt--- text--center">
              Don't have an account?{' '}
              <a href={`${config.FRONTEND_HOST_URL}/signup`} className="link--obvious">
                Sign up here
              </a>
              .
            </div>
          ) : (
            <div className="grid__item col-1-1 mt--- text--center">
              Don't have an account?{' '}
              <Link to="/signup" className="link--obvious">
                Sign up here
              </Link>
              .
            </div>
          )}
        </form>
      </div>
    );

    const singleSignOnForm = (
      <div className="grid__item col-1-1">
        <div className="grid">
          <div className="grid__item col-1-1 text--center">
            <div className="font--h6">Your organization requires Single Sign-On</div>
          </div>
          <div className="grid__item col-1-1 mt-">
            <form action={`${config.API_URL}/signin/dispatch-to-idp`} method="POST" name="SAMLForm">
              <div className="grid grid--full">
                <div className="grid__item col-1-1">
                  <input
                    autoComplete={'username email'}
                    type="email"
                    className="font--h6 control--text ph- col-1-1"
                    name="email"
                    autoFocus
                    placeholder="Single-sign-on email"
                    required={true}
                    value={samlEmailValue}
                    onChange={e => this.updateSamlEmailValue(e)}
                  />
                  {orgSlug && <input type="hidden" name="org" value={orgSlug} />}
                </div>
                <div className="grid__item col-1-1 flex flex--justify-content--end">
                  <button className="btn--primary font--h5 col-1-1 mt-">
                    Sign in with your identity provider
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="grid__item col-1-1 text--center mt-">
            <div className="">
              If you're an organization owner,{' '}
              <a className="link--obvious" onClick={() => this.updateLoginMode('PASSWORD')}>
                sign in here
              </a>{' '}
              with your username and password.
            </div>
          </div>
        </div>
      </div>
    );
    const orgPage = (
      <Fragment>
        <div className="grid__item col-1-1">
          <h3 className="text--center mb" data-e2e="LoginPage-signInTitle">
            Sign in to your organization
          </h3>
        </div>
        <LoaderWrapper
          isLoaderShowing={isCheckingOrgDomain}
          className="col-1-1 flex justify-content--center align-items--center text--center"
        >
          {!isSamlEnabled || loginMode === 'PASSWORD' ? signUpForm : singleSignOnForm}
        </LoaderWrapper>
      </Fragment>
    );
    return (
      <div className="grid grid--center col-3-7">
        {isFetchingToken ? (
          { signingInDisplay }
        ) : (
          <div className="grid__item col-1-1 flex justify-content--center align-items--center height--75vh">
            <div className="grid grid--center mb++">
              {autoLogout && (
                <div className="grid__item col-1-1">
                  <div className="panel panel--danger">
                    <div className="panel__heading font--h7">Inactivity Notice</div>
                    <div className="panel__body">
                      You were automatically signed out after being inactive for 30 minutes.
                    </div>
                  </div>
                </div>
              )}
              {errorMessage}
              {!isOrgDomainAvailable ? orgPage : <NoOrgPage />}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginState: state.loginState as App.LoginState,
    userStatus: state.userStatus,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
