import { combineReducers } from 'redux';
import * as actions from '~/actions/orgUserInvite';

import { USER_ROLES } from '~/constants/ModelConstants';

const orgUserInviteState = () => {
  const inviteStage = (state = 'EMAIL_INPUT', action) => {
    switch (action.type) {
      case actions.UPDATE_INVITE_STAGE:
        return action.inviteStage;
      case actions.RESET_INVITE_MODAL:
        return 'EMAIL_INPUT';
      default:
        return state;
    }
  };

  const activeModalTab = (state = '', action) => {
    switch (action.type) {
      case actions.UPDATE_ACTIVE_INVITE_MODAL_TAB:
        return action.tab;
      case actions.RESET_INVITE_MODAL:
        return '';
      default:
        return state;
    }
  };

  const rawEmailsInput = (state = '', action) => {
    switch (action.type) {
      case actions.UPDATE_RAW_EMAILS_INPUT:
        return action.emails;
      case actions.RESET_INVITE_MODAL:
        return '';
      default:
        return state;
    }
  };

  const emailsInput = (state = [], action) => {
    switch (action.type) {
      case actions.UPDATE_EMAILS_INPUT:
        return action.emails;
      case actions.RESET_INVITE_MODAL:
        return [];
      default:
        return state;
    }
  };

  const emailsInputError = (state = [], action) => {
    switch (action.type) {
      case actions.UPDATE_EMAILS_INPUT:
        return '';
      case actions.UPDATE_EMAILS_INPUT_ERROR:
        return action.error;
      case actions.RESET_INVITE_MODAL:
        return [];
      default:
        return state;
    }
  };

  const sendInviteError = (state = '', action) => {
    // eslint-disable-line no-unused-vars
    switch (action.type) {
      case actions.UPDATE_SEND_INVITE_ERROR:
        return action.message;
      default:
        return state;
    }
  };

  const invitees = (state = [], action) => {
    switch (action.type) {
      case actions.UPDATE_INVITEES: {
        const { emails = [] } = action;

        return emails.map(email => {
          return {
            email,
            orgRole: USER_ROLES.USER, // default
            groupAdmin: false, // default (optional unless groupId is present)
          };
        });
      }
      case actions.UPDATE_INVITEE_ROLE: {
        const newInvitees = state.map(invitee => {
          const { email } = invitee;
          if (email === action.email) {
            invitee.orgRole = action.role;
          }

          return invitee;
        });

        return newInvitees;
      }
      case actions.TOGGLE_INVITEE_GROUP_ADMIN:
        return state.map(invitee => {
          if (invitee.email === action.email) {
            invitee.groupAdmin = !invitee.groupAdmin;
          }
          return invitee;
        });
      case actions.RESET_INVITE_MODAL:
        return [];
      default:
        return state;
    }
  };

  return combineReducers({
    inviteStage,
    activeModalTab,
    rawEmailsInput,
    emailsInput,
    emailsInputError,
    invitees,
    sendInviteError,
  });
};

export default orgUserInviteState();
