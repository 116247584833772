import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';

export const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN';
export const UPDATE_COMPANY_NEWS = 'UPDATE_COMPANY_NEWS';
export const UPDATE_NEWSLETTER = 'UPDATE_NEWSLETTER';
export const UPDATE_MARKETING_NEWS = 'UPDATE_MARKETING_NEWS';
export const REQUEST_SUBMIT_FORM = 'REQUEST_SUBMIT_FORM';
export const RECEIVE_SUBMIT_FORM_SUCCESS = 'RECEIVE_SUBMIT_FORM_SUCCESS';
export const RECEIVE_SUBMIT_FORM_FAILURE = 'RECEIVE_SUBMIT_FORM_FAILURE';

export const updateUserToken = token => {
  return {
    type: UPDATE_USER_TOKEN,
    token,
  };
};

export const updateCompanyNews = bool => {
  return {
    type: UPDATE_COMPANY_NEWS,
    bool,
  };
};

export const updateNewsletter = bool => {
  return {
    type: UPDATE_NEWSLETTER,
    bool,
  };
};

export const updateMarketingNews = bool => {
  return {
    type: UPDATE_MARKETING_NEWS,
    bool,
  };
};

export const requestSubmitForm = () => {
  return {
    type: REQUEST_SUBMIT_FORM,
    meta: {
      snowplow: true,
    },
  };
};

export const receiveSubmitFormSuccess = () => {
  return {
    type: RECEIVE_SUBMIT_FORM_SUCCESS,
    meta: {
      snowplow: true,
    },
  };
};

export const receiveSubmitFormFailure = () => {
  return {
    type: RECEIVE_SUBMIT_FORM_FAILURE,
    meta: {
      snowplow: true,
    },
  };
};

export const submitForm = () => {
  return function (dispatch, getState) {
    const { companyNews, newsletter, marketingNews, userToken } = getState().unsubscribeState;

    dispatch(requestSubmitForm());
    const payload = {
      companyNews,
      newsletter,
      marketingNews,
      userToken,
    };

    ApiService.post('/newsletter/unsubscribe', { data: payload })
      .then(() => {
        dispatch(receiveSubmitFormSuccess());
      })
      .catch(err => {
        dispatch(receiveSubmitFormFailure());
        ErrorService.capture('Unsubscribe unsuccessful', { payload, err });
      });
  };
};
