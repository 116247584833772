import React, { Fragment } from 'react';
import Helpers from '~/utils/Helpers';
import {
  ISSUE_EVENT_SOURCE,
  ISSUE_EVENT_TYPE,
  ISSUE_STATUS,
  ISSUE_EVENT_AFFECTED_SOURCE,
} from '~/constants/ModelConstants';
import { RepoIssueEvent } from '~/types/SCA';

const IssueHelper = {
  /**
   * An event is a passive event when source === 'SOURCECLEAR'.
   */

  isPassivelyCreatedEvent: (event: any = {}) =>
    event.type === ISSUE_EVENT_TYPE.CREATED && event.source === ISSUE_EVENT_SOURCE.SOURCECLEAR,

  isPassivelyResolvedEvent: (event: any = {}) =>
    event.type === ISSUE_EVENT_TYPE.STATUS && event.source === ISSUE_EVENT_SOURCE.SOURCECLEAR,

  /**
   * Format into a 'View all comments' tooltip
   * @param  {function} onViewCommentClicked if present, allow user to view all comments
   */
  formatViewAllCommentsLink: onViewCommentClicked => {
    return (
      <div>
        {onViewCommentClicked && (
          <div className="cursor--pointer" onClick={onViewCommentClicked}>
            <u>View all comments</u>
          </div>
        )}
      </div>
    );
  },

  /**
   * param: event(object)
   *
   * returns an element containing the event message
   * i.e. <span>Issue created due to vulnerability data changing on Feb 6, 2018 11:30:21</span>
   */
  buildIssueEventMessage: (event: RepoIssueEvent) => {
    if (!event) {
      return null;
    }
    const { type = '', source = '', data = {}, date = '', user, comment } = event;
    const { new: newValue = '', affectedSource = '', old: oldValue = '' } = data;
    const suppressedUntilEpochSeconds = data.suppressedUntil;
    /*
      subtract 1s to go from 12:00AM to 11:59PM (more user-friendly)
      convert from s to ms
     */
    const suppressedUntilString = suppressedUntilEpochSeconds
      ? Helpers.formatTimestamp((suppressedUntilEpochSeconds - 1) * 1000, 'D MMM YYYY', false)
      : '';
    const formattedDateTime = Helpers.formatTimestamp(date);

    const eventMessage = {
      //CREATED event
      [ISSUE_EVENT_TYPE.CREATED]: () => {
        const msg =
          affectedSource === ISSUE_EVENT_AFFECTED_SOURCE.VULN_RELEASED
            ? `due to new vulnerability release on ${formattedDateTime}`
            : `due to vulnerability data changing on ${formattedDateTime}`;

        return {
          [ISSUE_EVENT_SOURCE.SOURCECLEAR]: <span>Issue created {msg}</span>,
          [ISSUE_EVENT_SOURCE.SCAN]: <span>Issue created upon scan</span>,
        }[source];
      },

      //STATUS event
      [ISSUE_EVENT_TYPE.STATUS]: () => {
        const msg =
          newValue === ISSUE_STATUS.FIXED ? (
            <span>Issue resolved</span>
          ) : (
            `Issue status changed to ${newValue}`
          );

        return {
          [ISSUE_EVENT_SOURCE.SOURCECLEAR]: (
            <span>
              {msg} due to vulnerability data changing on {formattedDateTime}
            </span>
          ),
          [ISSUE_EVENT_SOURCE.SCAN]: <span>{msg} on project scan</span>,
        }[source];
      },

      //SUPPRESSED event
      [ISSUE_EVENT_TYPE.SUPPRESSED]: () => {
        return {
          [ISSUE_EVENT_SOURCE.USER]: (
            <span>
              <div>
                <strong>Action:</strong> {newValue ? 'ignored' : 'unignored'}
              </div>
              {user ? (
                <Fragment>
                  <div>
                    <strong>Name:</strong> {user?.name}{' '}
                  </div>
                  <div>
                    <strong>Email:</strong> {user?.email}{' '}
                  </div>
                </Fragment>
              ) : (
                <div>User removed</div>
              )}
              {suppressedUntilString && (
                <div>
                  <strong>Ignored Until:</strong> {suppressedUntilString}{' '}
                </div>
              )}
              {comment && (
                <div>
                  <strong>Comment:</strong> {comment}{' '}
                </div>
              )}
            </span>
          ),
        }[source];
      },

      //SEVERITY event
      [ISSUE_EVENT_TYPE.SEVERITY]: () => {
        const msg = `Issue severity updated from ${oldValue} to ${newValue}`;

        return {
          [ISSUE_EVENT_SOURCE.SOURCECLEAR]: (
            <span>
              {msg} due to vulnerability data changing on {formattedDateTime}
            </span>
          ),
          [ISSUE_EVENT_SOURCE.SCAN]: <span>{msg} on project scan</span>,
        }[source];
      },

      //COMMENTED event
      [ISSUE_EVENT_TYPE.COMMENT]: () => {
        return {
          [ISSUE_EVENT_SOURCE.USER]: (
            <span>
              {user ? (
                <Fragment>
                  <div>
                    <strong>Name:</strong> {user.name}{' '}
                  </div>
                  <div>
                    <strong>Email:</strong> {user.email}{' '}
                  </div>
                </Fragment>
              ) : (
                <div>User removed</div>
              )}
              <div>
                <strong>Comment:</strong> {comment}{' '}
              </div>
            </span>
          ),
        }[source];
      },

      default: () => {
        return null;
      },
    };

    if (eventMessage[type]) {
      return eventMessage[type]();
    } else {
      return eventMessage['default']();
    }
  },

  getScanData: issue => {
    const { status } = issue;

    // Initialize xxxScan's. Default to empty object as xxxScan's are nullables
    const lastScan = issue.lastScan || {};
    const openedScan = issue.openedScan || {};
    const fixedScan = issue.fixedScan || {};

    return {
      status,
      scanId: lastScan.id || '',
      scanDate: lastScan.date || '',
      scanTag: lastScan.tag || '',
      scanBranch: lastScan.branch || '',
      openedScanId: openedScan.id || '',
      openedScanDate: openedScan.date || '',
      openedScanTag: openedScan.tag || '',
      openedScanBranch: openedScan.branch || '',
      openedScanCommit: openedScan.commit || '',
      fixedScanId: fixedScan.id || '',
      fixedScanDate: fixedScan.date || '',
      fixedScanCommit: fixedScan.commit || '',
      fixedScanTag: fixedScan.tag || '',
      fixedScanBranch: fixedScan.branch || '',
    };
  },
};

export default IssueHelper;
