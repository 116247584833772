import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Select from 'react-select';
import CopyToClipboard from '~/containers/CopyToClipboard';
import { agentWorkspaceSelectionInstructions } from '~/constants/ModelConstants';

import * as agentActions from '~/actions/agents';

interface AgentWorkspaceSelectionProps {
  agentState?: object;
  teamState?: object;
  agentType?: string;
  agentActions: object;
}
class AgentWorkspaceSelection extends React.Component<AgentWorkspaceSelectionProps, {}> {
  updateSelectedWorkspace(workspace) {
    this.props.agentActions.updateSelectedWorkspace(workspace);
  }

  render() {
    const { agentState, teamState, agentType } = this.props;
    const { selectedWorkspace = {} } = agentState;
    const { teams = [] } = teamState;
    const teamOptions = teams.map(team => {
      return { value: team.id, label: team.name };
    });

    const { [agentType]: instructions = {} } = agentWorkspaceSelectionInstructions;

    const header = instructions && instructions.header;
    const body = instructions && instructions.body;

    /* eslint-disable react/no-danger */
    const bodyHtml = <p dangerouslySetInnerHTML={{ __html: body }} />;
    /* eslint-disable react/no-danger */

    return (
      <div>
        <div className="grid mb">
          <div className="grid__item col-1-1 mb-">
            <div className="font--h6 mt mb--">{header}</div>
            <p>Select the workspace that should receive the scan and copy the workspace "slug":</p>
          </div>
          <div className="grid__item col-1-2">Target Workspace:</div>
          <div className="grid__item col-1-2">Workspace Slug:</div>
          <div className="grid__item col-1-2 mt---">
            <Select
              name="workspace-selector"
              className="policy col-1-1 srcclr-react-select-container"
              classNamePrefix={'srcclr-react-select'}
              value={teamOptions.find(option => option.value === selectedWorkspace)}
              options={teamOptions}
              onChange={workspace => this.updateSelectedWorkspace(workspace)}
              isClearable={false}
            />
          </div>
          <div className="grid__item col-2-5 mt---">
            <CopyToClipboard
              id="selected-workspace"
              inputWidth="col-3-4"
              value={selectedWorkspace.value || ''}
            />
          </div>
          <div className=" grid__item mt-">{bodyHtml}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentState: state.agentState,
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    agentActions: bindActionCreators(agentActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentWorkspaceSelection);
