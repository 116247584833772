/*
 * This component is responsible for setting local state when a route is not found
 * This state will be set for every route that isn't matched, top level and nested
 * see https://github.com/ReactTraining/react-router/issues/4698#issuecomment-314419439 for more details
 */

import React from 'react';
import { Redirect } from 'react-router-dom';

function RouteNotFound() {
  return <Redirect to={{ state: { notFoundError: true } }} />;
}

export default RouteNotFound;
