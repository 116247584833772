import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import * as MODEL from '~/constants/ModelConstants';
import SourceClearModal from '~/components/SourceClearModal';
import EnterpriseContactForm from '~/components/EnterpriseContactForm';
import Tooltip from '~/components/Tooltip';
import BlockingPage from '~/containers/BlockingPage';
import * as billingActions from '~/actions/billing';
import * as orgActions from '~/actions/org';
import * as paymentActions from '~/actions/payment';
import * as meActions from '~/actions/me';
import * as toastrActions from '~/actions/toastr';

interface TrialEndedPageProps extends RouteComponentProps {
  billingActions: object;
  meActions: object;
  orgActions: object;
  paymentActions: object;
  toastrActions: object;
  orgState: App.OrgState;
}

class TrialEndedPage extends React.Component<TrialEndedPageProps, {}> {
  showModal(modalType) {
    this.props.orgActions.showModal(modalType);
  }

  confirmDowngrade() {
    const { orgState, history } = this.props;
    const { org = {} } = orgState;
    const { id: orgId } = org;

    this.props.billingActions
      .submitUpdatePlan(orgId, 1001, undefined, 'TRIAL_EXPIRED_DOWNGRADE')
      .then(res => {
        if (res.success) {
          this.showModal();
          this.props.meActions.fetchMe().then(res => {
            const { organization = {} } = res;
            const { license = {} } = organization;
            const { status } = license;

            if (status !== 'TRIAL_EXPIRED') {
              history.push('/');
            }
          });
        }
      });
  }

  submitEnterpriseContactForm(params) {
    const { orgState, history } = this.props;
    const { org = {} } = orgState;

    this.props.paymentActions.requestPurchaseOrder(params, org.id).then(res => {
      // launch toast & redirect to app
      if (res.success) {
        const { extended } = res;

        this.props.toastrActions.addToastr({
          id: 'trial-extended',
          title: 'Message successfully sent',
          message: extended
            ? 'Your request has been sent and your account will be active for three days while the enterprise details are configured. Our sales team will be in touch shortly.'
            : 'Your request has been sent and our sales team will be in touch shortly.',
          level: 'info',
        });

        if (extended) {
          this.props.meActions.fetchMe().then(res => {
            const { organization = {} } = res;
            const { license = {} } = organization;
            const { status } = license;
            if (status !== 'TRIAL_EXPIRED') {
              history.push('/');
            }
          });
        } else {
          this.showModal();
        }
      }
    });
  }

  render() {
    const { orgState } = this.props;
    const { showModal, org = {} } = orgState;
    const {
      subscriptions = {},
      permissions = {},
      license: { status },
    } = org;
    const { billing } = permissions;
    const activeSubscription = subscriptions.ACTIVE || {};
    const { activeUntil } = activeSubscription;
    const isActiveExpired = activeUntil && moment().isAfter(moment(activeUntil));

    const isTrialExpired = status === 'TRIAL_EXPIRED';

    const trialEndedPage = (
      <div className="container grid grid--center pb++">
        <div className="grid__item col-2-3">
          {billing ? (
            <div className="grid">
              <div className="grid__item col-1-1 mt- text--center">
                <div className="font--h3">
                  {isActiveExpired ? 'SourceClear plans have been updated' : 'Your trial has ended'}
                </div>
                <div className="mt-">
                  To continue using SourceClear, choose one of the options below.
                </div>
              </div>
              <div className="grid__item col-1-1 mt-">
                <div className="bo--1 pv-- border-color--primary-dark bg-color--info flex">
                  <div className="flex align-items--center mh-">
                    <i className="fas fa-info-circle color--primary-dark" />
                  </div>
                  <div className="color--primary-dark">
                    Review the plan options carefully to find what works best for you. We will
                    adjust your account accordingly.{' '}
                    {isActiveExpired
                      ? 'If your users, workspaces, or projects exceed the new plan limits, reduce them after selecting a plan to re-enable scanning.'
                      : ''}
                  </div>
                </div>
              </div>
              <div className="grid__item col-1-1 mt">
                <div className="grid grid--full">
                  <div className="grid__item col-1-4" />
                  <div className="grid__item col-1-4">
                    <div className="pv-- bg-color--primary-lightest color--white font--h5 text--center">
                      PERSONAL
                    </div>
                  </div>
                  <div className="grid__item col-1-4">
                    <div className="pv-- bg-color--primary-lighter color--white font--h5 text--center">
                      BUSINESS
                    </div>
                  </div>
                  <div className="grid__item col-1-4">
                    <div className="pv-- bg-color--primary color--white font--h5 text--center">
                      ENTERPRISE
                    </div>
                  </div>
                </div>
                {MODEL.planOptions.map((option, index) => {
                  const { title, type, hobby, business, enterprise, tooltip } = option;

                  return (
                    <div key={`plan-option-${index}`} className="grid grid--full">
                      <div className="grid__item col-1-4 pv-- bo-t--1 border-color--muted font--h8">
                        {tooltip ? (
                          <Tooltip
                            id={`plan-details-tooltip-${index}`}
                            content={tooltip}
                            maxWidthClass="max-width--300"
                            place="top"
                          >
                            <div className="">
                              {title}
                              <i className="fas fa-info-circle color--primary pl-" />
                            </div>
                          </Tooltip>
                        ) : (
                          <div>{title}</div>
                        )}
                      </div>
                      <div className="grid__item col-1-4 pv-- ph bo-tl--1 border-color--muted text--center">
                        {type === 'BOOLEAN' && !!hobby && <i className="sci sci__check" />}
                        {type === 'TEXT' && <div className="font--h8">{hobby}</div>}
                      </div>
                      <div className="grid__item col-1-4 pv-- ph bo-tl--1 border-color--muted text--center">
                        {type === 'BOOLEAN' && !!business && <i className="sci sci__check" />}
                        {type === 'TEXT' && <div className="font--h8">{business}</div>}
                      </div>
                      <div className="grid__item col-1-4 pv-- ph bo-tl--1 border-color--muted text--center">
                        {type === 'BOOLEAN' && !!enterprise && <i className="sci sci__check" />}
                        {type === 'TEXT' && <div className="font--h8">{enterprise}</div>}
                      </div>
                    </div>
                  );
                })}
                <div className="grid grid--full bo-t--1 border-color--muted">
                  <div className="grid__item col-1-4" />
                  <div className="grid__item col-1-4 flex justify-content--center align-items--end pt+ bo-l--1 border-color--muted">
                    <button
                      className="col-5-6 btn--success bo-rad--0 pv-"
                      onClick={() => this.showModal('DOWNGRADE')}
                    >
                      SELECT
                    </button>
                  </div>
                  <div className="grid__item col-1-4 flex justify-content--center align-items--end pt+ bo-l--1 border-color--muted">
                    <Link
                      className="col-5-6 btn--success bo-rad--0 pv-"
                      to={`/trial-expired/payment`}
                    >
                      SUBSCRIBE
                    </Link>
                  </div>
                  <div className="grid__item col-1-4 flex justify-content--center align-items--end pt+ bo-l--1 border-color--muted">
                    <button
                      className="col-5-6 btn--success bo-rad--0 pv-"
                      onClick={() => this.showModal('ENTERPRISE_CONTACT')}
                    >
                      CONTACT US
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid grid--center mt++">
              <div className="grid__item col-2-12">
                <i className="sci font--100 sci__src-symbol--bracket color--primary-light" />
              </div>
              <div className="grid__item col-4-12">
                <div className="font--h4 mt">
                  <strong>Your trial has ended</strong>
                </div>
                <div className="mt-">
                  Please ask your administrator to log in and subscribe to a plan
                </div>
              </div>
            </div>
          )}
        </div>
        <SourceClearModal
          isOpen={showModal === 'DOWNGRADE'}
          title="Downgrade to Personal"
          onClose={() => this.showModal()}
          width={400}
        >
          <div className="mt-">
            You are choosing to continue using SourceClear with the Personal plan.
          </div>
          {isActiveExpired ? (
            <div className="mt-">
              You will have fewer workspaces, projects, users, and scans per month than before.
            </div>
          ) : (
            <div>
              <div className="mt-">
                You will lose access to premium features, such scan history and vulnerable methods
                analysis.
              </div>
              <div className="mt-">
                If you'd like to keep these features,{' '}
                <a href="mailto:sales@sourceclear.com" className="link--obvious">
                  let us help you
                </a>{' '}
                before you change plans.
              </div>
            </div>
          )}
          <div className="mt-">Do you still want to switch to the Personal plan?</div>
          <div className="flex flex--justify-content--end mt">
            <button className="pv- col-1-5 mr-" onClick={() => this.showModal()}>
              Cancel
            </button>
            <button
              className="btn--primary-clear pv- col-1-4 mr-"
              onClick={() => this.confirmDowngrade()}
            >
              Yes, switch
            </button>
          </div>
        </SourceClearModal>
        <SourceClearModal
          isOpen={showModal === 'ENTERPRISE_CONTACT'}
          title="Enter contact information"
          onClose={() => this.showModal()}
          width={400}
        >
          <EnterpriseContactForm
            submitEnterpriseContactForm={params => this.submitEnterpriseContactForm(params)}
            showModal={modalType => this.showModal(modalType)}
          />
        </SourceClearModal>
      </div>
    );

    return (
      <div>
        {isTrialExpired ? <BlockingPage isTrialExpired={isTrialExpired} /> : trialEndedPage}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    billingActions: bindActionCreators(billingActions, dispatch),
    meActions: bindActionCreators(meActions, dispatch),
    orgActions: bindActionCreators(orgActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
    toastrActions: bindActionCreators(toastrActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialEndedPage);
