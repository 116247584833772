export const INIT_PROJECT_FILTER_DEFAULTS = 'INIT_PROJECT_FILTER_DEFAULTS';
export const RESET_REPORT_FILTERS = 'RESET_REPORT_FILTERS';
export const UPDATE_FILTER_VALUE = 'UPDATE_FILTER_VALUE';
export const UPDATE_FILTER_VALUE_BY_PROJECT = 'UPDATE_FILTER_VALUE_BY_PROJECT';
export const UPDATE_FILTER_ARRAY = 'UPDATE_FILTER_ARRAY';
export const UPDATE_FILTER_ARRAY_BY_PROJECT = 'UPDATE_FILTER_ARRAY_BY_PROJECT';

export const updateFilterValue = (reportType, field, value, projectId) => dispatch => {
  if (projectId) {
    dispatch({
      type: UPDATE_FILTER_VALUE_BY_PROJECT,
      reportType,
      field,
      value,
      projectId,
    });
  } else {
    dispatch({
      type: UPDATE_FILTER_VALUE,
      reportType,
      field,
      value,
    });
  }
};

export const updateFilterArray = (reportType, field, value, projectId) => dispatch => {
  if (projectId) {
    dispatch({
      type: UPDATE_FILTER_ARRAY_BY_PROJECT,
      reportType,
      field,
      value,
      projectId,
    });
  } else {
    dispatch({
      type: UPDATE_FILTER_ARRAY,
      reportType,
      field,
      value,
    });
  }
};

export const resetReportFilters = () => ({
  type: RESET_REPORT_FILTERS,
});

export const initProjectFilterDefaults = projectId => ({
  type: INIT_PROJECT_FILTER_DEFAULTS,
  projectId,
});
