import React from 'react';
import { Link } from 'react-router-dom';
import SourceClearLoader from '~/components/SourceClearLoader';
import Tooltip from '~/components/Tooltip';
import { tooltipContent } from '~/constants/ModelConstants';

interface WorkspaceGroupsQuickViewProps {
  data?: any[];
  shouldShowVeracodePage?: boolean;
}

const WorkspaceGroupsQuickView: React.FunctionComponent<WorkspaceGroupsQuickViewProps> = props => {
  const { data: groups = [], shouldShowVeracodePage = false } = props;
  const columnWidths = {
    name: 'col-3-7',
    description: '',
    admin: 'col-1-7',
    filler: 'col-1-7',
  };

  if (!groups.length) {
    return (
      <div className="">
        <SourceClearLoader />
      </div>
    );
  }

  return (
    <div className="bo-v--1 border-color--muted mv-- pb-">
      <div className="col-1-1 bg-color--muted-light pv-- font--h7 pl- color--muted-dark">
        <strong>TEAMS</strong>
      </div>
      <div className="grid mt-">
        <div className={`grid__item ${columnWidths.name}`}>
          <strong>Team Name</strong>
        </div>
        {!shouldShowVeracodePage && (
          <div className={`grid__item ${columnWidths.description}`}>
            <strong>Description</strong>
          </div>
        )}
        {!shouldShowVeracodePage && (
          <div className={`grid__item text--center ${columnWidths.admin}`}>
            <Tooltip
              id="group-workspace-admins"
              content={tooltipContent.WORKSPACE_ADMIN}
              maxWidthClass="max-width--300"
              place="top"
            >
              <strong className="bo-b--1 bo--dashed">Workspace Admin</strong>
            </Tooltip>
          </div>
        )}
        <div className={`grid__item ${columnWidths.filler}`} />
      </div>

      <div className="overflow--auto max-height--300">
        {groups.map(groupObj => {
          const { group = {}, roles = [], admin } = groupObj;
          const { id: groupId, name, description } = group;

          return (
            <div className="grid mt--" key={`workspace-group-${groupId}`}>
              <div className={`grid__item ${columnWidths.name}`}>
                {admin ? (
                  <strong>
                    <Link to={`/org/settings/teams/${groupId}`} className="link--obvious">
                      {name}
                    </Link>
                  </strong>
                ) : (
                  <div className="">{name}</div>
                )}
              </div>
              {!shouldShowVeracodePage && (
                <div className={`grid__item ${columnWidths.description}`}>
                  {description || '--'}
                </div>
              )}
              {!shouldShowVeracodePage && (
                <div className={`grid__item text--center ${columnWidths.admin}`}>
                  {roles.includes('WORKSPACE_ADMIN') ? 'Yes' : '--'}
                </div>
              )}
              <div className={`grid__item ${columnWidths.filler}`} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkspaceGroupsQuickView;
