import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';
export const FETCHING_ORG_AGENTS = 'FETCHING_ORG_AGENTS';
export const UPDATE_ORG_AGENTS_PAGE = 'UPDATE_ORG_AGENTS_PAGE';
export const UPDATE_ORG_AGENT_FILTER_INPUT = 'UPDATE_ORG_AGENT_FILTER_INPUT';
export const UPDATE_ORG_AGENTS = 'UPDATE_ORG_AGENTS';

export const fetchOrgAgents = orgId => dispatch => {
  dispatch(fetchingOrgAgents());
  return ApiService.get(`${ApiConstants.fetchOrgAgentsURL(orgId)}`)
    .then(res => {
      dispatch(updateOrgAgents(res));
      return { success: true };
    })
    .catch(error => {
      ErrorService.capture(`Error fetching org agents`, error);
      dispatch(updateOrgAgents([]));
      return {};
    });
};

export const fetchingOrgAgents = () => ({
  type: FETCHING_ORG_AGENTS,
});

export const updateOrgAgents = orgAgents => ({
  type: UPDATE_ORG_AGENTS,
  orgAgents,
});

export const updateStringFilter = value => ({
  type: UPDATE_ORG_AGENT_FILTER_INPUT,
  value,
});

export const updateCurrentPage = currentPage => ({
  type: UPDATE_ORG_AGENTS_PAGE,
  currentPage,
});
