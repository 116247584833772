import React from 'react';

const ReportDetailsPageTabContent: React.FunctionComponent<{}> = props => {
  const { children } = props;
  return (
    <div className="grid__item col-1-1 mb p bo--1 border-color--white-dark bg-color--white-medium">
      {children}
    </div>
  );
};

export default ReportDetailsPageTabContent;
