import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _ from 'lodash';

import BlockingPage from '~/containers/BlockingPage';

import * as billingActions from '~/actions/billing';
import { RouteComponentProps } from 'react-router-dom';

interface SubscriptionUpdatePageProps extends RouteComponentProps {
  billingActions: object;
  billingState: object;
  orgState: App.OrgState;
}

class SubscriptionUpdatePage extends React.Component<SubscriptionUpdatePageProps, {}> {
  componentDidMount() {
    const { billingState, orgState, history } = this.props;
    const { org } = orgState;
    const { license, permissions = {} } = org;
    const { billing } = permissions;
    const { planType } = license;
    const { plan = {} } = billingState;
    if (!billing) {
      history.replace('/org/settings/no-owner');
    }

    if (planType === 'ENTERPRISE') {
      history.push(`/org/settings`);
      return;
    }

    if (_.isEmpty(plan)) {
      this.props.billingActions.fetchBillingDashboard(org.id);
    }
  }

  showModal(modalType) {
    this.props.billingActions.showModal(modalType);
  }

  confirmDowngrade() {
    const { orgState, history } = this.props;
    const { org = {} } = orgState;

    this.props.billingActions.submitUpdatePlan(org.id, 1001, undefined, 'HOBBY').then(res => {
      if (res.success) {
        history.push('/org/settings/subscription');
      }
    });
  }

  cancelPendingChange() {
    const { billingState, orgState, history } = this.props;
    const { org = {} } = orgState;
    const { plan = {} } = billingState;

    this.props.billingActions.submitUpdatePlan(org.id, plan.id, undefined, 'CANCEL').then(res => {
      if (res.success) {
        history.push('/org/settings/subscription');
      }
    });
  }

  render() {
    const { orgState } = this.props;

    const { org } = orgState;
    const { planType: subscriptionPlanType, limits } = org.license;

    const { headline, details } = blockingPageTextForSubscriptionUpdatePage(
      subscriptionPlanType,
      limits
    );

    return (
      <BlockingPage
        hasSidebar={true}
        renderPreheadline={() => {
          return <span>{headline}</span>;
        }}
        renderEnterpriseDetails={() => {
          return <span>{details}</span>;
        }}
      />
    );
  }
}

function blockingPageTextForSubscriptionUpdatePage(planType, limits) {
  // old pro plans do not have repos, teams, and users limits metadata
  const nullLimits = _.pickBy(limits, l => l === null);
  if (planType === 'PRO') {
    if ('repos' in nullLimits && 'teams' in nullLimits && 'users' in nullLimits) {
      return {
        headline: 'You are on the Pro plan',
        details:
          'Keep using Enterprise features like Custom Rules and Advanced User Management. Get access to new vulnerability notifications and additional support.',
      };
    } else {
      return {
        headline: 'You are on the Business plan',
        details:
          'Keep using Enterprise features like Custom Rules and get access to Advanced User Management, new vulnerability notifications, and additional support.',
      };
    }
  }
}

function mapStateToProps(state) {
  return {
    billingState: state.billingState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    billingActions: bindActionCreators(billingActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionUpdatePage);
