import * as actions from '~/actions/insights';
import produce from 'immer';

const defaultState = {
  ISSUES: {},
  PROJECT: {
    total: 0,
    scannedInLastWeek: 0,
    withVulnerabilities: 0,
    riskScore: 0,
    issues: {},
    issuesBySeverity: {
      HIGH: 0,
      LOW: 0,
      MEDIUM: 0,
    },
    vulnsBySeverity: {
      LOW: 0,
      HIGH: 0,
      MEDIUM: 0,
    },
  },
  VULNERABILITIES: {},
  isFetchingInsights: false,
};

const insightsByType = (state = defaultState, action) => {
  return produce(state, draft => {
    const typeOfInsight = action.issueType;
    switch (action.type) {
      case actions.UPDATE_INSIGHTS:
        // Handle null/undefined for project type insights
        if (typeOfInsight === 'PROJECT') {
          draft[typeOfInsight].total = action.insights.total;
          draft[typeOfInsight].scannedInLastWeek = action.insights.scannedInLastWeek;
          draft[typeOfInsight].withVulnerabilities = action.insights.withVulnerabilities;
          draft[typeOfInsight].riskScore = action.insights.riskScore;
          draft[typeOfInsight].issues = action.insights.issues;
          draft[typeOfInsight].issuesBySeverity.HIGH = action.insights?.issuesBySeverity?.HIGH
            ? action.insights.issuesBySeverity.HIGH
            : 0;
          draft[typeOfInsight].issuesBySeverity.MEDIUM = action.insights?.issuesBySeverity?.MEDIUM
            ? action.insights.issuesBySeverity.MEDIUM
            : 0;
          draft[typeOfInsight].issuesBySeverity.LOW = action.insights?.issuesBySeverity?.LOW
            ? action.insights.issuesBySeverity.LOW
            : 0;
          draft[typeOfInsight].vulnsBySeverity.HIGH = action.insights?.vulnsBySeverity?.HIGH
            ? action.insights.vulnsBySeverity.HIGH
            : 0;
          draft[typeOfInsight].vulnsBySeverity.MEDIUM = action.insights?.vulnsBySeverity?.MEDIUM
            ? action.insights.vulnsBySeverity.MEDIUM
            : 0;
          draft[typeOfInsight].vulnsBySeverity.LOW = action.insights?.vulnsBySeverity?.LOW
            ? action.insights.vulnsBySeverity.LOW
            : 0;
        } else {
          draft[typeOfInsight] = action.insights;
        }
        draft.isFetchingInsights = false;
        break;
      case actions.FETCHING_INSIGHTS:
        draft.isFetchingInsights = true;
    }
  });
};

export default insightsByType;
