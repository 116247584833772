import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'fluorine-ui';

function VCAgentBasedScanSettingsButton() {
  return (
    <div data-automation-id="AgentBasedScanSettings-Button">
      <Button variant="tertiary" as={Link} to="/settings/notifications">
        Agent-Based Scan Settings
      </Button>
    </div>
  );
}

export default VCAgentBasedScanSettingsButton;
