import * as actions from '~/actions/invoice';

export const defaultState = {};

const invoicesById = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_INVOICE_DATA:
      return {
        ...state,
        [action.invoice.id]: action.invoice,
      };
    default:
      return state;
  }
};

export default invoicesById;
