import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DropdownMenu from 'react-dd-menu';
import ReportStringFilter from '~/containers/ReportStringFilter';
import _ from 'lodash';

import * as dropdownMenuActions from '~/actions/dropdownMenu';

interface ContextBasedSearchProps {
  name: string;
  onChange: (...args: any[]) => any;
  value?: string;
  selectedContext?: string;
  className?: string;
  search?: string;
  disabled?: boolean;
  contextOptions?: any[];
  dropdownMenuState?: object;
  dropdownMenuActions?: object;
}
class ContextBasedSearch extends React.Component<ContextBasedSearchProps, {}> {
  constructor(props, context) {
    super(props, context);
    this.delayCloseDropdownMenu = _.debounce(this.closeDropdownMenu, 50);
  }

  closeDropdownMenu(name) {
    this.props.dropdownMenuActions.closeDropdownMenu(name);
  }

  onChange(field, value) {
    this.props.onChange(field, value);
  }

  render() {
    const {
      contextOptions = [],
      search = '',
      name = '',
      dropdownMenuState,
      selectedContext = '',
    } = this.props;
    const { openedDropdown = {} } = dropdownMenuState;

    const isOptionsListOpen = !!openedDropdown[name];

    const selectedContextObj =
      contextOptions.find(option => option.value === selectedContext) || {};
    const activeStringFilterPlaceholder = `Search ${
      (selectedContextObj.label && selectedContextObj.label.toLowerCase().replace(/cve/g, 'CVE')) ||
      ''
    }`;

    return (
      <div className="flex align-items--center bg-color--white bo--1 border-color--white-dark">
        <DropdownMenu
          animate={true}
          size={'sm'}
          textAlign="left"
          align="left"
          isOpen={isOptionsListOpen}
          close={() => this.delayCloseDropdownMenu(name)}
          toggle={
            <div
              className="bg-color--white p- bo-r--1 border-color--white-dark bg-color--hover-white-dark cursor--pointer"
              onClick={() => this.props.dropdownMenuActions.openDropdownMenu(name)}
            >
              <i className="fas fa-angle-down" />
            </div>
          }
        >
          <div className="pl pv-- text--bold">Search for:</div>
          {contextOptions.map((option, i) => (
            <li key={`${i}-${option.value}`}>
              <div
                className="grid grid--full cursor--pointer"
                onClick={() => this.onChange('context', option.value)}
              >
                <div className="grid__item col-1-6 pull--left">
                  {selectedContext === option.value && <i className="fas fa-check" />}
                </div>
                <div className="ml text--wrap">{option.label}</div>
              </div>
            </li>
          ))}
        </DropdownMenu>
        <div className="width--240">
          <ReportStringFilter
            inputClassName="bo--0"
            label={activeStringFilterPlaceholder}
            field={'search'}
            value={search}
            onChange={(field, value) => this.onChange(field, value)}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextBasedSearch);
