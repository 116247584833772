/**
 * processOnOrgLoad
 *
 * This performs checks on the availability of features
 * and dispatches action or series of actions based on enabled features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const processOnOrgLoad = () => (dispatch, getState) => {
  // Add actions here
};
