import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CopyToClipboard from '~/containers/CopyToClipboard';
import * as agentActions from '~/actions/agents';

interface TokenControlProps {
  agentActions: object;
  agentState: object;
  teamId?: string;
  integrationType: string;
  prependText?: string;
  appendText?: string;
}

class TokenControl extends React.Component<TokenControlProps, {}> {
  generateToken = () => {
    const { teamId, integrationType } = this.props;

    this.props.agentActions.generatePluginToken(teamId, integrationType);
  };

  render() {
    const { agentState, prependText = '', appendText = '' } = this.props;
    const { pluginToken = {} } = agentState;
    const tokenValue =
      pluginToken.accessToken && pluginToken.accessToken.access_token
        ? `${prependText}${
            (pluginToken.accessToken && pluginToken.accessToken.access_token) || ''
          }${appendText}`
        : '';

    return (
      <div className="grid grid--full">
        <button
          className="btn btn--primary pv-- mv--"
          onClick={() => this.generateToken()}
          data-automation-id="TokenControl-createAgentBtn"
        >
          Create Agent & Generate Token
        </button>

        <div className="grid__item col-11-12">
          <CopyToClipboard id="generateToken" value={tokenValue} placeholder="Generate new token" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentState: state.agentState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    agentActions: bindActionCreators(agentActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenControl);
