import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as orgUserInviteActions from '~/actions/orgUserInvite';

import { USER_ROLES } from '~/constants/ModelConstants';

interface ChooseUserRolesProps {
  orgUserInviteActions: object;
  myState: App.MyState;
  orgUserInviteState: object;
  orgState: App.OrgState;
  closeWhenClickOutside?: boolean;
}
class ChooseUserRoles extends React.Component<ChooseUserRolesProps, {}> {
  updateInviteeRole(email, role) {
    this.props.orgUserInviteActions.updateInviteeRole(email, role);
  }

  render() {
    const { myState, orgUserInviteState } = this.props;
    const { me = {} } = myState;
    const { orgRoles = [] } = me;
    const disableOrgOwnerOption = !orgRoles.includes('OWNER');
    const { invitees } = orgUserInviteState;

    const columnWidth = {
      email: 'col-1-2',
      user: 'col-1-8',
    };

    return (
      <div className="col-1-1">
        <div>
          By default, all invitees are users. Org Admins can administer the SourceClear application
          except for billing, subscriptions, usage, and organization profile. Org Owners can
          administer the entire SourceClear application.
        </div>
        <div className="bo--1 mt p-- border-color--muted-dark">
          <div className="grid grid__narrower">
            <div className={`grid__item ${columnWidth.email}`}>
              <strong>Email</strong>
            </div>
            <div className={`grid__item ${columnWidth.user} text--center`}>
              <strong>User</strong>
            </div>
            <div className={`grid__item ${columnWidth.orgAdmin} text--center`}>
              <strong>Org Admin</strong>
            </div>
            <div
              className={`grid__item ${columnWidth.owner} ${
                disableOrgOwnerOption && 'color--muted'
              } text--center`}
            >
              <strong>Org Owner</strong>
            </div>
          </div>
          {invitees.map((invitee, index) => {
            const { email, orgRole } = invitee;
            return (
              <div className="grid grid__narrower mt-" key={email}>
                <div className={`grid__item ${columnWidth.email} text--overflow`}>{email}</div>
                <div className={`grid__item ${columnWidth.user} flex justify-content--center`}>
                  <label htmlFor="user-role" className="label--checkbox mb0">
                    <div className="flex align-items--center justify-content--center">
                      <input
                        type="radio"
                        id={`${index}-user-role`}
                        className="flex zIndex-9--overlay"
                        checked={orgRole === USER_ROLES.USER}
                        onChange={() => this.updateInviteeRole(email, USER_ROLES.USER)}
                      />
                      <span className="react--radio mr0" />
                    </div>
                  </label>
                </div>
                <div className={`grid__item ${columnWidth.orgAdmin} flex justify-content--center`}>
                  <label htmlFor="org-admin-role" className="label--checkbox mb0">
                    <div className="flex align-items--center justify-content--center">
                      <input
                        type="radio"
                        id={`${index}-org-admin-role`}
                        className="flex zIndex-9--overlay"
                        checked={orgRole === USER_ROLES.ADMIN}
                        onChange={() => this.updateInviteeRole(email, USER_ROLES.ADMIN)}
                      />
                      <span className="react--radio mr0" />
                    </div>
                  </label>
                </div>
                <div className={`grid__item ${columnWidth.owner} flex justify-content--center`}>
                  <label htmlFor="owner-role" className="label--checkbox mb0">
                    <div className="flex align-items--center justify-content--center">
                      <input
                        type="radio"
                        id={`${index}-owner-role`}
                        className="flex zIndex-9--overlay"
                        checked={orgRole === USER_ROLES.OWNER}
                        disabled={disableOrgOwnerOption}
                        title={
                          disableOrgOwnerOption
                            ? 'Only org owners can invite new users to be org owners.'
                            : undefined
                        }
                        onChange={() => this.updateInviteeRole(email, USER_ROLES.OWNER)}
                      />
                      <span className="react--radio mr0" />
                    </div>
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myState: state.myState,
    orgUserInviteState: state.orgUserInviteState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orgUserInviteActions: bindActionCreators(orgUserInviteActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseUserRoles);
