import React from 'react';

function ReportDetailsPageFixLinkHtml(props) {
  const { fixLinks }: { fixLinks: Report.FixLink[] } = props;
  const linkElements = fixLinks
    .filter(fix => fix.link && fix.description)
    .map((link, index) => {
      return (
        <li key={`${link}-${index}`}>
          <a href={link.link} className="link--primary" target="_blank">
            {link.description}
          </a>{' '}
          ({link.type.toLowerCase()})
        </li>
      );
    });

  if (linkElements.length) {
    return (
      <div>
        <div className="col-1-1 pt">More references:</div>
        <ul className="col-1-1 pt-">{linkElements}</ul>
      </div>
    );
  } else {
    // render nothing
    return null;
  }
}

export default ReportDetailsPageFixLinkHtml;
