import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';

interface PackagistProps {
  packageInfo: object;
  filePath: string;
}

const Packagist: React.FunctionComponent<PackagistProps> = props => {
  const { filePath = 'composer.json', packageInfo = {} } = props;
  const { coord1, version } = packageInfo;
  return (
    <div>
      <div className="grid bo--1 border-color--white-dark mt lh+">
        <CodeDiffHeader text={filePath} />
        <CodeDiffLine type="normal" text={'\u0022require\u0022: {'} />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine
          type="highlight"
          text={'\u2003\u0022' + coord1 + '\u0022: \u0022' + version + '\u0022'}
        />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine type="normal" text="}" />
      </div>
    </div>
  );
};

export default Packagist;
