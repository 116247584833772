import * as actions from '~/actions/datePicker';

const defaultState = {};

const reportFilterState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_DATE_PICKER_VALUE: {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    default:
      return state;
  }
};

export default reportFilterState;
