import * as actions from '~/actions/unsubscribe';

const defaultState = {
  companyNews: false,
  newsletter: false,
  marketingNews: false,
  userToken: '',
  isSubmitting: false,
  submitSuccess: false,
  submitFailure: false,
};

const unsubscribeState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_USER_TOKEN:
      return Object.assign({}, state, {
        userToken: action.token,
      });
    case actions.UPDATE_COMPANY_NEWS:
      return Object.assign({}, state, {
        companyNews: action.bool,
      });
    case actions.UPDATE_NEWSLETTER:
      return Object.assign({}, state, {
        newsletter: action.bool,
      });
    case actions.UPDATE_MARKETING_NEWS:
      return Object.assign({}, state, {
        marketingNews: action.bool,
      });
    case actions.REQUEST_SUBMIT_FORM:
      return Object.assign({}, state, {
        isSubmitting: true,
        submitSuccess: false,
        submitFailure: false,
      });
    case actions.RECEIVE_SUBMIT_FORM_SUCCESS:
      return Object.assign({}, state, {
        submitSuccess: true,
        isSubmitting: false,
        submitFailure: false,
      });
    case actions.RECEIVE_SUBMIT_FORM_FAILURE:
      return Object.assign({}, state, {
        submitFailure: true,
        submitSuccess: false,
        isSubmitting: false,
      });
    default:
      return state;
  }
};

export default unsubscribeState;
