import { combineReducers } from 'redux';

import * as actions from '~/actions/projectScanHistory';

const projectScanHistory = () => {
  const isFetching = (state = false, action) => {
    switch (action.type) {
      case actions.FETCH_PROJECT_SCANS_REQUEST:
        return true;
      case actions.FETCH_PROJECT_SCANS_SUCCESS:
      case actions.FETCH_PROJECT_SCANS_FAILURE:
      case actions.RESET_PROJECT_SCANS_DATA:
        return false;
      default:
        return state;
    }
  };

  const isFetchingLatestScan = (state = false, action) => {
    switch (action.type) {
      case actions.FETCH_PROJECT_LATEST_SCAN_REQUEST:
        return true;
      case actions.UPDATE_LATEST_SCAN_BY_DEFAULT_BRANCH:
      case actions.FETCH_PROJECT_LATEST_SCAN_FAILURE:
      case actions.RESET_PROJECT_SCANS_DATA:
        return false;
      default:
        return state;
    }
  };

  const isFetchingScan = (state = false, action) => {
    switch (action.type) {
      case actions.FETCH_PROJECT_SCAN_BY_ID_REQUEST:
        return true;
      case actions.UPDATE_SELECTED_PROJECT_SCAN:
      case actions.FETCH_PROJECT_SCAN_BY_ID_FAILURE:
      case actions.RESET_PROJECT_SCANS_DATA:
        return false;
      default:
        return state;
    }
  };

  const data = (state = {}, action) => {
    switch (action.type) {
      case actions.FETCH_PROJECT_SCANS_SUCCESS:
        return action.res;
      case actions.RESET_PROJECT_SCANS_DATA:
        return {};
      default:
        return state;
    }
  };

  const errorMessage = (state = null, action) => {
    switch (action.type) {
      case actions.FETCH_PROJECT_SCANS_FAILURE:
      case actions.FETCH_PROJECT_LATEST_SCAN_FAILURE:
      case action.FETCH_PROJECT_SCAN_BY_ID_FAILURE:
        return action.message;
      case actions.UPDATE_LATEST_SCAN_BY_DEFAULT_BRANCH:
      case actions.UPDATE_SELECTED_PROJECT_SCAN:
      case actions.FETCH_PROJECT_SCANS_SUCCESS:
      case actions.RESET_PROJECT_SCANS_DATA:
        return null;
      default:
        return state;
    }
  };

  const selectedScan = (state = {}, action) => {
    switch (action.type) {
      case actions.UPDATE_SELECTED_PROJECT_SCAN:
        return action.data;
      case actions.RESET_PROJECT_SCANS_DATA:
        return {};
      default:
        return state;
    }
  };

  const latestScanByDefaultBranch = (state = {}, action) => {
    switch (action.type) {
      case actions.UPDATE_LATEST_SCAN_BY_DEFAULT_BRANCH:
        return action.scan;
      case actions.RESET_PROJECT_SCANS_DATA:
        return {};
      default:
        return state;
    }
  };

  /**
   * latestScanByBranchOrTag
   *
   * This state assumes the structure that differentiates latest scans
   * based on either branch or tag.
   *
   * latestScanByBranchOrTag = {
   *  branch: {
   *    develop: {}
   *    master: {}
   *  },
   *  tag: {
   *    v1: {}
   *    v2: {}
   *  }
   * }
   */
  const latestScanByBranchOrTag = (state = {}, action) => {
    switch (action.type) {
      case actions.UPDATE_LATEST_SCAN_BY_BRANCH_OR_TAG:
        return {
          ...state,
          [action.refType]: {
            ...state[action.refType],
            [action.ref]: action.data,
          },
        };
      case actions.RESET_PROJECT_SCANS_DATA:
        return {};
      default:
        return state;
    }
  };

  const filtersDefault = {
    scanFindings: ['WITH_ISSUES'],
  };

  /*
   * filters
   *
   * filters.refs is the state that tracks value of
   * the filter control(dropdown) in projectScanHistory panel.
   * `refs` is an object structured as follows:
   *
   * refs = {
   *   ref: <branch or tag value>,
   *   refType: <'branch' or 'tag'>
   * }
   *
   * Differentiating between refTypes is especially used when constructing repoScope = {commit, branch, tag}
   * */

  const filters = (state = filtersDefault, action) => {
    switch (action.type) {
      case actions.UPDATE_PROJECT_SCAN_FILTER: {
        let value;

        if (typeof action.value === 'object') {
          value = action.value;
        } else {
          value = action.value && action.value.length ? action.value.split(',') : [];
        }

        return {
          ...state.filters,
          [action.field]: value,
        };
      }
      case actions.RESET_PROJECT_SCAN_FILTERS:
        return {
          ...filtersDefault,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    isFetching,
    isFetchingLatestScan,
    isFetchingScan,
    latestScanByBranchOrTag,
    errorMessage,
    selectedScan,
    latestScanByDefaultBranch,
    data,
    filters,
  });
};

export default projectScanHistory();

export const getIsFetching = state => state.isFetching;
