import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Helmet from 'react-helmet';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { agentTokenOptions } from '~/constants/ModelConstants';

import AgentOSOptions from '~/components/AgentOSOptions';
import SourceClearModal from '~/components/SourceClearModal';

import * as agentActions from '~/actions/agents';
import * as meActions from '~/actions/me';
import * as welcomeActions from '~/actions/welcome';

import { AgentState } from '~/reducers/agentState.types';

interface WorkspaceWelcomeAgentPageProps extends RouteComponentProps {
  agentActions: object;
  meActions: object;
  welcomeActions: object;
  agentState: AgentState;
  messageState: object;
  welcomeState: object;
}

class WorkspaceWelcomeAgentPage extends Component<
  WorkspaceWelcomeAgentPageProps & ReturnType<typeof mapDispatchToProps>
> {
  componentDidMount() {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    const options = agentTokenOptions.cli || {};
    options.teamId = teamId;

    this.props.agentActions.createActivationToken(options);
    this.props.welcomeActions.selectOS(undefined);
  }

  selectOS(OS) {
    this.props.welcomeActions.selectOS(OS);
    this.props.agentActions.updateOperatingSystem(OS);
  }

  skipWelcomeConfirm() {
    const { match, history } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    this.showModal();
    this.props.welcomeActions.skipWelcome().then(res => {
      if (res.success) {
        this.props.meActions.fetchMe().then(res => {
          if (res.id) {
            history.push(`/workspaces/${teamId}/agents/new`);
          }
        });
      }
    });
  }

  showModal(modalType) {
    this.props.welcomeActions.showModal(modalType);
  }

  render() {
    const { welcomeState, match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    const { showModal } = welcomeState;

    return (
      <div className="grid col-1-1 mb+">
        <Helmet>
          <title>Welcome - Set Up Scanner</title>
        </Helmet>
        <div className="grid__item col-1-6" />
        <div className="grid__item col-2-3">
          <div className="mt">Choose your operating system to set up your first agent.</div>
          <AgentOSOptions teamId={teamId} welcomeMode={true} selectOS={os => this.selectOS(os)} />
          <div className="flex col-1-1 mt justify-content--end">
            <div
              className="color--muted cursor--pointer flex align-items--baseline"
              onClick={() => this.showModal('SKIP_WELCOME')}
            >
              Skip and go to advanced setup <i className="sci sci__caret--right font--10 ml---" />
            </div>
          </div>
        </div>

        <SourceClearModal
          isOpen={showModal === 'SKIP_WELCOME'}
          width={400}
          onClose={() => this.showModal('')}
          title="Skip Welcome Flow?"
        >
          <div className="mt">
            <p>
              You haven't completed your first scan yet. Do you want to skip the welcome flow to
              view other installation options?
            </p>
            <div className="flex justify-content--end mt mb-">
              <button className="pv- ph mr-" onClick={() => this.showModal('')}>
                Stay in Welcome Flow
              </button>
              <button className="btn--success pv- ph" onClick={() => this.skipWelcomeConfirm()}>
                Skip Welcome Flow
              </button>
            </div>
          </div>
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentState: state.agentState,
    messageState: state.messageState,
    welcomeState: state.welcomeState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    agentActions: bindActionCreators(agentActions, dispatch),
    meActions: bindActionCreators(meActions as any, dispatch),
    welcomeActions: bindActionCreators(welcomeActions as any, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspaceWelcomeAgentPage));
