import React from 'react';
import Tooltip from '~/components/Tooltip';
import ProIcon from '~/components/ProIcon';
import LoaderWrapper from '~/components/LoaderWrapper';

interface InsightNumberProps {
  title: string;
  field?: string;
  content?: string | number | object;
  contentClassName?: string;
  isFetchingInsights?: boolean;
  contentUnit?: string;
  handleClick?: (...args: any[]) => any;
  isClickable?: boolean;
  isSelected?: boolean;
  tooltip?: string;
  showUpgradeModalIfClicked?: boolean;
  callToAction?: (...args: any[]) => any;
  automationId?: string;
}

const InsightNumber: React.FunctionComponent<InsightNumberProps> = props => {
  const {
    title,
    content,
    isClickable,
    field,
    isSelected,
    contentClassName = '',
    contentUnit = '',
    isFetchingInsights,
    tooltip,
    showUpgradeModalIfClicked = false,
    automationId,
  } = props;

  function handleClick() {
    if (showUpgradeModalIfClicked && isClickable) {
      props.callToAction();
    } else if (isClickable) {
      props.handleClick(field, !isSelected);
    }
  }

  const customLoader = (
    <div className="col-1-1 flex flex--flex-direction--column bo-v--1 border-color--muted-light ph- pv--">
      <div className="col-1-1">{title}</div>
      <div className="col-1-1 ph flex align-items--center justify-content--center flex-grow--1">
        <i className="fas fa-spin fa-spinner font--h4 color--muted-dark" />
      </div>
    </div>
  );

  return (
    <LoaderWrapper
      className="grid__item col-1-5 flex"
      isLoaderShowing={isFetchingInsights}
      customLoader={customLoader}
    >
      <div
        className={`col-1-1 p- flex flex--flex-direction--column border-color--muted-light ${
          isClickable ? 'insight--clickable bo--1' : 'bo-v--1'
        } ${isSelected ? 'insight--selected' : ''}`}
        onClick={() => handleClick()}
        data-automation-id={automationId}
      >
        <div className="font--h8 flex flex--justify-content--space-between align-items--center">
          <div>
            {showUpgradeModalIfClicked && <ProIcon />}
            {title}
          </div>
          {isSelected && <i className="sci sci__check--box color--success" />}
          {tooltip && (
            <Tooltip id="insight-tooltip" maxWidthClass="max-width--300" content={tooltip}>
              <div className="font--h8">
                <i className="sci sci__detail-information color--primary" />
              </div>
            </Tooltip>
          )}
        </div>
        <div className="flex justify-content--center align-items--center flex-grow--1">
          <div className={`font--h2 font-family--roboto-medium ${contentClassName}`}>{content}</div>
        </div>
        <div className="flex justify-content--center">{contentUnit}</div>
      </div>
    </LoaderWrapper>
  );
};

export default InsightNumber;
