import React from 'react';
import Select from 'react-select';

interface AddSelectedUserToGroupsProps {
  selectableGroups: any[];
  selectedGroups: any[];
  buttons: React.ReactNode;
  updateSelectedGroup: (...args: any[]) => any;
  toggleAdminField: (...args: any[]) => any;
}

const AddSelectedUserToGroups: React.FunctionComponent<AddSelectedUserToGroupsProps> = props => {
  const { selectableGroups, selectedGroups } = props;

  function addSelectedGroup(group) {
    group && props.updateSelectedGroup(group.value);
  }

  function removeSelectedGroup(groupId) {
    props.updateSelectedGroup(groupId);
  }

  function toggleAdminField(groupId) {
    props.toggleAdminField(groupId);
  }

  return (
    <div className="grid">
      <div className="grid__item col-1-1">
        <div>
          <strong className="font--h6">Choose Teams</strong>
        </div>
      </div>
      <div className="grid__item col-1-1 mt-" data-automation-id="AddSelectedUserToGroups-chooseTeamsSelect">
        <Select
          value={''}
          options={selectableGroups}
          name="groups"
          onChange={group => addSelectedGroup(group)}
          className={'srcclr-react-select-container'}
          classNamePrefix={'srcclr-react-select'}
        />
      </div>
      <div className="grid__item col-1-1 mt">
        <div>
          <strong className="font--h6">Select Team Administration</strong> (optional)
        </div>
        <div className="mt-">
          Users can be members or administrators of a team. Team administrators can invite or add
          users, remove users, and make other users team administrators.
        </div>
      </div>
      <div className="grid__item col-1-1 mt-">
        <div className="bo--1 border-color--black-light pv-">
          {selectedGroups.length ? (
            <div>
              <div className="grid grid--full">
                <div className="grid__item col-1-2 pl-">
                  <strong>Team Name</strong>
                </div>
                <div className="grid__item col-5-12 text--center">
                  <strong>User Can Administer Team</strong>
                </div>
                <div className="grid__item col-1-12" />
              </div>
              <div className="max-height--150 overflow--auto">
                {selectedGroups.map(group => {
                  const { name = '', id: groupId, stagedAdminStatus = false } = group;
                  return (
                    <div className="grid grid--full pv-- hover-row" key={`selected-${groupId}`}>
                      <div className="grid__item col-1-2 pl-">{name}</div>
                      <div className="grid__item col-5-12 flex align-items--center justify-content--center">
                        <label className="label--checkbox flex justify-content--center mb0">
                          <input
                            type="checkbox"
                            checked={stagedAdminStatus}
                            onChange={() => toggleAdminField(groupId)}
                          />
                          <span className="react--checkbox mr0" />
                        </label>
                      </div>
                      <div className="grid__item col-1-12 flex justify-content--center">
                        <button
                          className="p--- flex align-items--center"
                          onClick={() => removeSelectedGroup(groupId)}
                        >
                          <i className="sci sci__trash color--danger-hover-row" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="color--muted-dark pl-">Select team above</div>
          )}
        </div>
      </div>

      {props.buttons}
    </div>
  );
};

export default AddSelectedUserToGroups;
