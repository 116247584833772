import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';

interface RubyGemsProps {
  packageInfo: object;
  filePath: string;
}

const RubyGems: React.FunctionComponent<RubyGemsProps> = props => {
  const { name, version } = props.packageInfo;
  const { filePath = 'Gemfile.lock' } = props;

  //split package.json#L5
  const splittedSelectedFilePath = filePath.split('#L');
  const file = splittedSelectedFilePath[0];
  const lineNumber = splittedSelectedFilePath[1] || '';

  let lineDiff;
  if (file === 'Gemfile') {
    lineDiff = (
      <CodeDiffLine
        type="highlight"
        lineNumber={lineNumber}
        text={'gem \u0027' + name + '\u0027, \u0027' + version + '\u0027'}
      />
    );
  } else if (file === 'Gemfile.lock') {
    lineDiff = (
      <CodeDiffLine type="highlight" lineNumber={lineNumber} text={name + ' (' + version + ')'} />
    );
  }

  return (
    <div>
      <div className="grid bo--1 border-color--white-dark mt lh+">
        <CodeDiffHeader text={file} />
        <CodeDiffLine type="normal" text={'\u00B7\u00B7\u00B7'} />
        {lineDiff}
        <CodeDiffLine type="normal" text={'\u00B7\u00B7\u00B7'} />
      </div>
      <div className="pt">
        <span className="text--bold"> {name} </span> may have been declared as a range instead of{' '}
        <span className="text--bold"> {version} </span> in your{' '}
        <span className="text--bold"> {file} </span>
      </div>
    </div>
  );
};

export default RubyGems;
