import React from 'react';
import CopyToClipboard from '~/containers/CopyToClipboard';

interface PackageManagerYarnProps {
  component: object;
  range?: object;
  safeVersion?: string;
  transitive: boolean;
  unsafeVersion?: string;
}

const PackageManagerYarn: React.FunctionComponent<PackageManagerYarnProps> = props => {
  const { component, safeVersion, transitive } = props;
  const { name } = component;

  return (
    <div className="pt-">
      <div className="grid pt">
        <span className="bg-color--white-dark pv- ph- text--bold">1</span>
        <span className="pv- pl--">
          Type the following to install{' '}
          <span className="font-family--code bg-color--white-medium p---">
            {name} {safeVersion}
          </span>
        </span>
      </div>
      <div className="pt-- -ml mt- bg-color--white">
        <CopyToClipboard
          value={`yarn ${transitive ? 'add' : 'upgrade'} ${name}@${safeVersion}`}
          id="PackageManagerYarn1"
        />
      </div>

      <div>
        <div className="grid pt">
          <span className="bg-color--white-dark pv- ph- text--bold">2</span>
          <span className="pv- pl--">
            Run the following and choose the latest version when prompted
          </span>
        </div>
        <div className="pt-- -ml mt- bg-color--white">
          <CopyToClipboard value={`yarn install --flat`} id="PackageManagerYarn2" />
        </div>
      </div>
    </div>
  );
};

export default PackageManagerYarn;
