import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';
import CopyToClipboard from '~/containers/CopyToClipboard';

interface PackageManagerRubyGemsProps {
  component: object;
  safeVersion: string;
  transitive: boolean;
}

const PackageManagerRubyGems: React.FunctionComponent<PackageManagerRubyGemsProps> = props => {
  const { component, safeVersion, transitive = false } = props;
  const { name } = component;

  let diff = null;

  if (!transitive) {
    diff = (
      <div>
        <div className="grid bo--1 border-color--white-dark mt-- lh+ bg-color--white">
          <CodeDiffHeader text={'Gemfile'} />
          <CodeDiffLine type="normal" text="source 'https://rubygems.org'" />
          <CodeDiffLine type="normal" text={'\u00B7\u00B7\u00B7'} />
          <CodeDiffLine type="delete" text={'gem \u0027' + name} />
          <CodeDiffLine
            type="add"
            text={'gem \u0027' + name + '\u0027, \u0027' + safeVersion + '\u0027'}
          />
          <CodeDiffLine type="normal" text={'\u00B7\u00B7\u00B7'} />
        </div>
      </div>
    );
  } else {
    diff = (
      <div className="grid bo--1 border-color--white-dark mt-- lh+ bg-color--white">
        <CodeDiffHeader text="Gemfile" />
        <CodeDiffLine type="add" text="source 'https://rubygems.org'" />
        <CodeDiffLine type="add" text={'\u00B7\u00B7\u00B7'} />
        <CodeDiffLine
          type="add"
          text={'gem \u0027' + name + '\u0027, \u0027' + safeVersion + '\u0027'}
        />
        <CodeDiffLine type="add" text={'\u00B7\u00B7\u00B7'} />
      </div>
    );
  }

  return (
    <div className="pt-">
      <div className="grid pt">
        <span className="bg-color--white-dark pv- ph- text--bold">1</span>
        {!transitive && <span className="pl-- pv-"> Update your</span>}
        {transitive && <span className="pl-- pv-"> Include {name} to your </span>}
        <span className="text--bold inline-block ph-- pv-"> Gemfile </span>
        <span className="pv-">as shown below:</span>
      </div>
      {diff}
      <div className="grid pt">
        <span className="bg-color--white-dark pv- ph- text--bold">2</span>
        <span className="pv- pl--">
          After updating the Gemfile, type the following to update your dependency.
        </span>
      </div>
      <div className="pt-- -ml">
        <CopyToClipboard value={`bundle update ${name}`} id="PackageManagerRubyGems" />
      </div>
      <div className="-ml text--italic pt-- pb">
        Note: There might be a chance that you get the error of bundler not finding compatible
        version for gem {name}. You might want to try and delete Gemfile.lock and run bundle
        install.
      </div>
    </div>
  );
};

export default PackageManagerRubyGems;
