import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import Helpers from '~/utils/Helpers';

import * as toastrActions from '~/actions/toastr';
import * as projectSettingsActions from '~/actions/projectSettings';

import {
  REPO_EVENTS,
  TRIGGER_EVENTS_OPTIONS,
  NOTIFICATION_TYPES,
} from '~/constants/ModelConstants';

export const UPDATE_NOTIFICATIONS_SETTINGS_FIELD_VALUE =
  'UPDATE_NOTIFICATIONS_SETTINGS_FIELD_VALUE';
export const FETCH_NOTIFICATIONS_SETTINGS_REQUEST = 'FETCH_NOTIFICATIONS_SETTINGS_REQUEST';
export const FETCH_NOTIFICATIONS_SETTINGS_SUCCESS = 'FETCH_NOTIFICATIONS_SETTINGS_SUCCESS';
export const FETCH_NOTIFICATIONS_SETTINGS_FAILURE = 'FETCH_NOTIFICATIONS_SETTINGS_FAILURE';
export const SAVE_NOTIFICATIONS_SETTINGS_REQUEST = 'SAVE_NOTIFICATIONS_SETTINGS_REQUEST';
export const SAVE_NOTIFICATIONS_SETTINGS_SUCCESS = 'SAVE_NOTIFICATIONS_SETTINGS_SUCCESS';
export const SAVE_NOTIFICATIONS_SETTINGS_FAILURE = 'SAVE_NOTIFICATIONS_SETTINGS_FAILURE';
export const RESET_NOTIFICATIONS_SETTINGS_CONTROLS = 'RESET_NOTIFICATIONS_SETTINGS_CONTROLS';
export const UPDATE_NOTIFICATIONS_SETTINGS_CONTROLS = 'UPDATE_NOTIFICATIONS_SETTINGS_CONTROLS';
export const UPDATE_NOTIFICATIONS_SETTINGS_SELECTED_ROWS =
  'UPDATE_NOTIFICATIONS_SETTINGS_SELECTED_ROWS';
export const CLEAR_NOTIFICATIONS_SETTINGS_SELECTED_ROWS =
  'CLEAR_NOTIFICATIONS_SETTINGS_SELECTED_ROWS';
export const DELETE_NOTIFICATIONS_SETTINGS_REQUEST = 'DELETE_NOTIFICATIONS_SETTINGS_REQUEST';
export const DELETE_NOTIFICATIONS_SETTINGS_SUCCESS = 'DELETE_NOTIFICATIONS_SETTINGS_SUCCESS';
export const DELETE_NOTIFICATIONS_SETTINGS_FAILURE = 'DELETE_NOTIFICATIONS_SETTINGS_FAILURE';

const { JUST_SCAN_EVENT, ALL_EVENTS } = TRIGGER_EVENTS_OPTIONS;

export const updateSelectedRows = (notificationType, id) => ({
  type: UPDATE_NOTIFICATIONS_SETTINGS_SELECTED_ROWS,
  notificationType,
  id,
});

export const clearSelectedRows = notificationType => ({
  type: CLEAR_NOTIFICATIONS_SETTINGS_SELECTED_ROWS,
  notificationType,
});

export const updateNotificationsSettingsFieldValue = (notificationType, field, value) => ({
  type: UPDATE_NOTIFICATIONS_SETTINGS_FIELD_VALUE,
  notificationType,
  field,
  value,
});

export const fetchNotificationsSettingsRequest = notificationType => ({
  type: FETCH_NOTIFICATIONS_SETTINGS_REQUEST,
  notificationType,
});

export const fetchNotificationsSettingsSuccess = (notificationType, data) => ({
  type: FETCH_NOTIFICATIONS_SETTINGS_SUCCESS,
  notificationType,
  data,
});

export const fetchNotificationsSettingsFailure = error => ({
  type: FETCH_NOTIFICATIONS_SETTINGS_FAILURE,
  message: error.message,
});

export const updateNotificationsSettingsControls = (notificationType, event) => ({
  type: UPDATE_NOTIFICATIONS_SETTINGS_CONTROLS,
  notificationType,
  event,
});

export const fetchNotificationsSettingsByType = (notificationType, projectId) => dispatch => {
  dispatch(fetchNotificationsSettingsRequest(notificationType));

  const path = notificationType === NOTIFICATION_TYPES.WEBHOOK ? 'webhooks' : ''; //TODO: to be updated/refined with email notifications
  const endpoint = `/repos/${projectId}/${path}`;

  return ApiService.get(endpoint)
    .then(data => {
      dispatch(fetchNotificationsSettingsSuccess(notificationType, data));
    })
    .catch(error => {
      dispatch(fetchNotificationsSettingsFailure(error));
      ErrorService.capture('Error fetching notifications settings', error);
      dispatch(
        toastrActions.addToastr({
          id: `FETCH_FAILED_${notificationType}`,
          level: 'error',
          title: 'Error fetching notification settings',
          message:
            Helpers.getFriendlyErrorMsg(error) ||
            'There was an error while fetching your notification settings. Please try again.',
        })
      );
    });
};

export const saveNotificationsSettingsRequest = () => ({
  type: SAVE_NOTIFICATIONS_SETTINGS_REQUEST,
});

export const saveNotificationsSettingsSuccess = () => ({
  type: SAVE_NOTIFICATIONS_SETTINGS_SUCCESS,
});

export const saveNotificationsSettingsFailure = error => ({
  type: SAVE_NOTIFICATIONS_SETTINGS_FAILURE,
  message: error.message,
});

export const resetNotificationsSettingsControls = notificationType => ({
  type: RESET_NOTIFICATIONS_SETTINGS_CONTROLS,
  notificationType,
});

export const saveWebhookNotificationsSettings = (projectId, webhookId) => (dispatch, getState) => {
  dispatch(toastrActions.clearToastrs());
  dispatch(saveNotificationsSettingsRequest());

  const { projectNotificationsSettingsState } = getState();
  const {
    [NOTIFICATION_TYPES.WEBHOOK]: notificationSettings = {},
  } = projectNotificationsSettingsState;
  const { controls = {} } = notificationSettings;
  const { url = '', contentType = '', triggerEventsOption = '', individualEvents = [] } = controls;

  const maybeAllEvents =
    triggerEventsOption === ALL_EVENTS
      ? [
          REPO_EVENTS.SCAN_SUCCESS,
          REPO_EVENTS.VULN_ISSUES_DISCOVERED_AFTER_SCAN,
          REPO_EVENTS.VULN_ISSUES_CHANGED_AFTER_SCAN,
        ]
      : individualEvents;
  const events =
    triggerEventsOption === JUST_SCAN_EVENT ? [REPO_EVENTS.SCAN_SUCCESS] : maybeAllEvents;

  const options = {
    url,
    contentType,
    events,
  };

  const endpoint = webhookId
    ? `/repos/${projectId}/webhooks/${webhookId}`
    : `/repos/${projectId}/webhooks`;
  const apiCall = webhookId
    ? ApiService.put(endpoint, { data: options })
    : ApiService.post(endpoint, { data: options });

  return apiCall
    .then(() => {
      dispatch(projectSettingsActions.updateProjectSettingsStage(''));
      dispatch(resetNotificationsSettingsControls(NOTIFICATION_TYPES.WEBHOOK));
      dispatch(saveNotificationsSettingsSuccess());
      dispatch(
        toastrActions.addToastr({
          id: 'SAVE_WEBHOOK_NOTF_SUCCESS',
          level: 'success',
          title: 'Settings successfully saved',
          message: 'Changes to your webhook notification settings have been successfully saved.',
        })
      );
    })
    .catch(error => {
      dispatch(saveNotificationsSettingsFailure(error));
      ErrorService.capture('Error saving notifications', error);
      dispatch(
        toastrActions.addToastr({
          id: 'SAVE_WEBHOOK_NOTF_ERROR',
          level: 'error',
          title: 'Error saving notification settings',
          message:
            Helpers.getFriendlyErrorMsg(error) ||
            'There was an error while saving changes to your notification settings. Please check that all information is accurate and try again.',
        })
      );
    });
};

export const deleteNotificationsSettingsRequest = notificationType => ({
  type: DELETE_NOTIFICATIONS_SETTINGS_REQUEST,
  notificationType,
});

export const deleteNotificationsSettingsSuccess = notificationType => ({
  type: DELETE_NOTIFICATIONS_SETTINGS_SUCCESS,
  notificationType,
});

export const deleteNotificationsSettingsFailure = (notificationType, error) => ({
  type: DELETE_NOTIFICATIONS_SETTINGS_FAILURE,
  notificationType,
  message: error.message,
});

export const deleteWebhookNotifications = (projectId, webhookIds) => dispatch => {
  if (!webhookIds || !webhookIds.length) {
    return;
  }

  const endpoint = `/repos/${projectId}/webhooks`;
  const delRequestPromises = webhookIds.map(webhookId =>
    ApiService.del(`${endpoint}/${webhookId}`)
  );
  dispatch(deleteNotificationsSettingsRequest(NOTIFICATION_TYPES.WEBHOOK));

  return Promise.all(delRequestPromises)
    .then(() => {
      dispatch(fetchNotificationsSettingsByType(NOTIFICATION_TYPES.WEBHOOK, projectId));
      dispatch(clearSelectedRows(NOTIFICATION_TYPES.WEBHOOK));
      dispatch(deleteNotificationsSettingsSuccess(NOTIFICATION_TYPES.WEBHOOK));
    })
    .catch(error => {
      dispatch(deleteNotificationsSettingsFailure(NOTIFICATION_TYPES.WEBHOOK, error));
      ErrorService.capture('Error deleting selected notifications', error);
      dispatch(
        toastrActions.addToastr({
          id: 'DEL_WEBHOOK_NOTF_ERROR',
          level: 'error',
          title: 'Error deleting selected notifications',
          message:
            Helpers.getFriendlyErrorMsg(error) ||
            'There was an error while deleting selected webhook notifications Please try again.',
        })
      );
    });
};
