import { combineReducers } from 'redux';
import * as actions from '~/actions/reports';

const reportsByTypeCSV = () => {
  const csvData = (state = {}, action) => {
    switch (action.type) {
      case actions.FETCH_REPORT_CSV_SUCCESS: {
        const isReportDataPresent = !!state[action.reportType];
        const reportState = isReportDataPresent ? state[action.reportType] : [];
        return {
          ...state,
          [action.reportType]: [...reportState, action.response],
        };
      }
      case actions.FETCH_REPORT_CSV_REQUEST:
      case actions.RESET_REPORT_STATE:
        return {};
      default:
        return state;
    }
  };

  const isFetching = (state = {}, action) => {
    switch (action.type) {
      case actions.FETCH_REPORT_CSV_REQUEST:
        return {
          ...state,
          [action.reportType]: true,
        };
      case actions.FETCH_REPORT_CSV_COMPLETED:
      case actions.FETCH_REPORT_CSV_FAILURE:
        return {
          ...state,
          [action.reportType]: false,
        };
      case actions.RESET_REPORT_STATE:
        return {};
      default:
        return state;
    }
  };

  const errorMessage = (state = {}, action) => {
    switch (action.type) {
      case actions.FETCH_REPORT_CSV_FAILURE:
        return {
          ...state,
          [action.reportType]: action.message,
        };
      case actions.FETCH_REPORT_CSV_COMPLETED:
      case actions.FETCH_REPORT_CSV_REQUEST:
        return {
          ...state,
          [action.reportType]: null,
        };
      case actions.RESET_REPORT_STATE:
        return {};
      default:
        return state;
    }
  };

  return combineReducers({
    csvData,
    isFetching,
    errorMessage,
  });
};

export default reportsByTypeCSV();
