const SortHelper = function () {
  const getVersionSortValue = val => {
    // convert version string to an int. Examples:
    // '4.1.5' =>  415000
    // '14.0.1' => 1401000
    // '1.8.3' => 183000

    if (val === '--') {
      return -2;
    } else if (val === 'up-to-date' || !val) {
      return -1;
    }

    const versionParts = val.split('.');
    let compareVal = 0;
    let versionPartInt = 0;
    let largestMag = 10;

    versionParts.forEach(function (versionPart, i) {
      versionPart = versionPart.replace('-SNAPSHOT', '');
      const charMatch = versionPart.match(/[^-0-9]/);

      // if not first part of version then ignore any part that is non-int like: 1.0.2v20150114
      if (charMatch && i > 0) {
        versionPart = '0';
      }

      if (versionPart.length > 4) {
        // handle when date is part of the version string like 1.0.20150114
        versionPart = versionPart.substring(0, 2);
      }

      versionPart = versionPart.replace(/[^0-9-]+/, '');
      versionPartInt = parseInt(versionPart, 10);
      compareVal += Math.pow(10, largestMag - i * 2) * versionPartInt;
    });

    return compareVal;
  };

  const getVulnSortValue = component => {
    if (!component.id) {
      return -1;
    }

    return component.vulnTotalCount;
  };

  const getSortValue = (component, sortField) => {
    const val = component[sortField];
    const calcFields = ['displayVulnBox', 'displayLanguage'];

    if (val === null && calcFields.indexOf(sortField) === -1) {
      return 0;
    }

    if (sortField === 'displayVersion' || sortField === 'displayLatestRelease') {
      return getVersionSortValue(val);
    } else if (sortField === 'displayVulnBox') {
      return getVulnSortValue(component);
    } else if (sortField === 'displayName') {
      const plus = component.displayProjectName ? component.displayProjectName : '';
      return (
        component.displayName.toLowerCase() +
        '-' +
        component.displayVersion +
        '-' +
        component.id +
        '-' +
        plus
      );
    } else if (sortField === 'displayAge') {
      return !component.releaseDate ? 0 : Date.now() - new Date(component.releaseDate).getTime();
    }

    // nothing special about this field so just normalize the string.
    if (typeof val === 'string') {
      return val.toLowerCase();
    }

    return val;
  };

  return {
    getVersionSortValue,
    getVulnSortValue,
    getSortValue,
  };
};

export default new SortHelper();
