import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';

interface GoDepProps {
  packageInfo: object;
  filePath: string;
}

const GoDep: React.FunctionComponent<GoDepProps> = props => {
  const { name, commitHash } = props.packageInfo;
  const { filePath = 'Godeps.json' } = props;
  //split Podfile#L5
  const splittedSelectedFilePath = filePath.split('#L');
  const file = splittedSelectedFilePath[0];
  const lineNumber = splittedSelectedFilePath[1] || '';
  return (
    <div>
      <div className="grid bo--1 border-color--white-dark mt lh+">
        <CodeDiffHeader text={file} />
        <CodeDiffLine type="normal" text={'\u00B7\u00B7\u00B7'} />
        <CodeDiffLine type="highlight" lineNumber={lineNumber} text={`"ImportPath": "${name}"`} />
        <CodeDiffLine type="highlight" text={`"Rev": " ${commitHash}`} />
        <CodeDiffLine type="normal" text={'\u00B7\u00B7\u00B7'} />
      </div>
      <div className="pt">
        <span className="text--bold">
          {' '}
          There might be a "Comment" about the version between "ImportPath" and "Rev"
        </span>
      </div>
    </div>
  );
};

export default GoDep;
