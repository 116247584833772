import React from 'react';
import Helpers from '~/utils/Helpers';
import ReportDetailsPageFixLinkHtml from '~/components/ReportComponents/ReportDetailsPageFixLinkHtml';
import { PROJECT_TYPES } from '~/constants/ModelConstants';
import ContainerFixInfo from '~/components/ReportPage/QuickviewFixInfo/ContainerFixInfo';
import PackageManagerNPM from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerNPM';
import PackageManagerYarn from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerYarn';
import PackageManagerRubyGems from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerRubyGems';
import PackageManagerMaven from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerMaven';
import PackageManagerGradle from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerGradle';
import PackageManagerPyPI from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerPyPI';
import PackageManagerBower from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerBower';
import PackageManagerCocoaPods from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerCocoaPods';
import PackageManagerSbt from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerSbt';
import PackageManagerPackagist from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerPackagist';
// GO package manager
import PackageManagerGlide from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerGlide';
import PackageManagerGoVendor from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerGoVendor';
import PackageManagerTrash from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerTrash';
import PackageManagerGoDep from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerGoDep';
import PackageManagerGoGet from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerGoGet';
// End of GO package manager
import Tooltip from '~/components/Tooltip';
import RegistryLink from '~/components/RegistryLink';

interface ReportDetailsPageTransitiveContentProps {
  component: object;
  fixText?: string;
  fixVersion?: string;
  languageType: string;
  filePath: string;
  safeVersion?: string;
  unsafeVersion?: string;
  unsafeCommitHash?: string;
  safeCommitHash?: string;
  projectType?: string;
  fixLinks?: Report.FixLink[];
  registryLibraryUrl?: string;
}

const ReportDetailsPageTransitiveContent: React.FunctionComponent<ReportDetailsPageTransitiveContentProps> = props => {
  const {
    component = {},
    fixText = '',
    fixVersion = '',
    languageType,
    filePath,
    safeVersion = '',
    unsafeVersion,
    unsafeCommitHash,
    safeCommitHash,
    fixLinks,
    projectType = '',
    registryLibraryUrl,
  } = props;
  const fixInSafeVersions = safeVersion === fixVersion;
  let showPackageManagers,
    updateText,
    validateText,
    transitiveDependencyText,
    upgradeInstructionsHtml;

  const packageFile = filePath.split('#')[0];
  const fixValidationText = `Be sure to build, test, and re-scan your project to ensure this fix has been successful.`;

  let fixTextElement = null;

  if (fixText) {
    let fixTextLines = fixText.split('\n'),
      lineElements = [];

    fixTextLines.forEach((line, index) => {
      lineElements.push(
        <div key={index} className="font--12">
          {line}
        </div>
      );
    });

    fixTextElement = (
      <div className="col-1-1 font-family--code pt--">
        <div className="border-color--muted-light bo--1 p- word-wrap--break-word">
          {lineElements}
        </div>
      </div>
    );
  }

  const hasSafeVersion = safeVersion || safeCommitHash;
  const commitHashOrVersionText = Helpers.isCommitHash(fixVersion) ? 'commit' : 'version';
  const safeVersionOrCommit = safeCommitHash ? safeCommitHash : safeVersion;

  let defaultTransitiveText =
    'This vulnerability is in a transitive dependency, it can be fixed by overriding and adding a new direct dependency of the library in your project.';
  let noFixDependencyText = (
    <span>
      This vulnerability is in a transitive dependency. Refer to the dependency graph above to see
      which direct dependency are using vulnerable library
      <span className="text--bold"> {component.name} </span>
    </span>
  );

  if (fixVersion && hasSafeVersion && !fixInSafeVersions) {
    transitiveDependencyText = defaultTransitiveText;
    updateText = (
      <div>
        <p>
          <strong>Add</strong>
        </p>
        <p>
          This issue was fixed in {commitHashOrVersionText}{' '}
          <span className="font-family--code bg-color--white-medium p---">{fixVersion}</span>.
          However, that {commitHashOrVersionText} is itself subject to{' '}
          <RegistryLink href={registryLibraryUrl} className="link--obvious">
            other vulnerabilities
          </RegistryLink>
          , we suggest that you upgrade to{' '}
          <span className="font-family--code bg-color--white-medium p---">
            {safeVersionOrCommit}
          </span>
          , which is considered safe.
        </p>
      </div>
    );
    validateText = (
      <div>
        <p>{fixValidationText}</p>
      </div>
    );
    showPackageManagers = true;
  } else if (fixVersion && hasSafeVersion && fixInSafeVersions) {
    // Fix version is present and at least a safe version is present and fix version is in safe versions
    transitiveDependencyText = defaultTransitiveText;
    updateText = (
      <div>
        <p>
          <strong>Update</strong>
        </p>
        <p>
          This issue was fixed in {commitHashOrVersionText}{' '}
          <span className="font-family--code bg-color--white-medium p---">{fixVersion}</span> of{' '}
          <span className="font-family--code bg-color--white-medium p---">{component.name}</span>.
          That {commitHashOrVersionText} is currently considered safe, we suggest that you upgrade
          to the fixed {commitHashOrVersionText}.
        </p>
      </div>
    );
    validateText = (
      <div>
        <p>{fixValidationText}</p>
      </div>
    );
    showPackageManagers = true;
  } else if (fixVersion && !hasSafeVersion) {
    // Fix version is present but no safe version. Do not show the Maven/Gradle/sbt section on the right.
    transitiveDependencyText = defaultTransitiveText;
    updateText = (
      <div>
        <p>
          <strong>Update</strong>
        </p>
        <p>
          This issue was fixed in {commitHashOrVersionText}{' '}
          <span className="font-family--code bg-color--white-medium p---">{fixVersion}</span>.
          However, that {commitHashOrVersionText} is itself subject to{' '}
          <RegistryLink href={registryLibraryUrl} className="link--obvious">
            other vulnerabilities
          </RegistryLink>
          , there are no safe {commitHashOrVersionText} to upgrade to.
        </p>
      </div>
    );
    validateText = <div />;
    showPackageManagers = false;
  } else if (!fixVersion && hasSafeVersion) {
    // Fix versions is missing but there is at least a safe version
    transitiveDependencyText = defaultTransitiveText;
    updateText = (
      <div>
        <p>
          <strong>Update</strong>
        </p>
        <p>
          We do not have a confirmed fix for this issue yet. However, newer versions of the library
          have been released. We suggest that you upgrade to{' '}
          <span className="font-family--code bg-color--white-medium p---">
            {safeVersionOrCommit}
          </span>
          , which is considered safe.
        </p>
      </div>
    );
    validateText = (
      <div>
        <p>{fixValidationText}</p>
      </div>
    );
    showPackageManagers = true;
  } else if (!fixVersion && !hasSafeVersion && fixText) {
    // Fix version is missing (and there is no safe version) but Fix text is present: Do not show the Maven/Gradle/sbt section on the right.
    transitiveDependencyText = noFixDependencyText;
    updateText = (
      <div>
        <p className="pt-">
          <strong>Add</strong>
        </p>
        <p>
          This issue has not yet been fixed in a released version of the library. Please use the
          following steps to mitigate the issue:
        </p>
        {fixTextElement}
        <ReportDetailsPageFixLinkHtml fixLinks={fixLinks} />
      </div>
    );
    validateText = (
      <div>
        <p>{fixValidationText}</p>
      </div>
    );
    showPackageManagers = false;
  } else {
    // Safe/Fix version and Fix text both are missing:
    transitiveDependencyText = noFixDependencyText;
    updateText = (
      <div>
        <p>
          This issue has not yet been fixed in a released version of the library. Moreover, there
          are no known mitigation steps.
        </p>
      </div>
    );
    validateText = <div />;
    showPackageManagers = false;
  }

  if (projectType.toUpperCase() === PROJECT_TYPES.CONTAINER) {
    upgradeInstructionsHtml = <ContainerFixInfo component={component} safeVersion={safeVersion} />;
  } else if (showPackageManagers) {
    if (languageType === 'RUBY') {
      upgradeInstructionsHtml = (
        <PackageManagerRubyGems
          component={component}
          safeVersion={safeVersion}
          unsafeVersion={unsafeVersion}
          transitive={true}
        />
      );
    } else if (languageType === 'PYTHON') {
      upgradeInstructionsHtml = (
        <PackageManagerPyPI
          component={component}
          safeVersion={safeVersion}
          unsafeVersion={unsafeVersion}
          transitive={true}
        />
      );
    } else if (languageType === 'JS' || languageType === 'JAVASCRIPT') {
      if (packageFile.includes('bower.json')) {
        upgradeInstructionsHtml = (
          <PackageManagerBower
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={true}
          />
        );
      } else if (packageFile.includes('yarn.lock')) {
        upgradeInstructionsHtml = (
          <PackageManagerYarn
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={true}
          />
        );
      } else {
        upgradeInstructionsHtml = (
          <PackageManagerNPM
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={true}
          />
        );
      }
    } else if (languageType === 'JAVA') {
      if (packageFile.indexOf('pom.xml') > -1) {
        upgradeInstructionsHtml = (
          <PackageManagerMaven
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={true}
          />
        );
      } else if (packageFile.indexOf('build.gradle') > -1) {
        upgradeInstructionsHtml = (
          <PackageManagerGradle
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={true}
          />
        );
      } else if (packageFile.indexOf('build.sbt') > -1) {
        upgradeInstructionsHtml = (
          <PackageManagerSbt
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={true}
          />
        );
      }
    } else if (languageType === 'OBJECTIVEC') {
      upgradeInstructionsHtml = (
        <PackageManagerCocoaPods
          component={component}
          safeVersion={safeVersion}
          unsafeVersion={unsafeVersion}
          transitive={true}
        />
      );
    } else if (languageType === 'GO') {
      if (packageFile.includes('glide.lock')) {
        upgradeInstructionsHtml = (
          <PackageManagerGlide
            packageFile={packageFile}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={true}
            safeCommitHash={safeCommitHash}
            unsafeCommitHash={unsafeCommitHash}
          />
        );
      } else if (packageFile.includes('vendor.json')) {
        upgradeInstructionsHtml = (
          <PackageManagerGoVendor
            packageFile={packageFile}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={true}
            safeCommitHash={safeCommitHash}
            unsafeCommitHash={unsafeCommitHash}
          />
        );
      } else if (packageFile.includes('vendor.conf') || packageFile.includes('trash.yaml')) {
        upgradeInstructionsHtml = (
          <PackageManagerTrash
            packageFile={packageFile}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={true}
            safeCommitHash={safeCommitHash}
            unsafeCommitHash={unsafeCommitHash}
          />
        );
      } else if (packageFile.includes('Godeps.json')) {
        upgradeInstructionsHtml = (
          <PackageManagerGoDep
            packageFile={packageFile}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={true}
            safeCommitHash={safeCommitHash}
            unsafeCommitHash={unsafeCommitHash}
          />
        );
      } else if (packageFile === '') {
        // If user is not using package manager then the package file will be empty
        upgradeInstructionsHtml = (
          <PackageManagerGoGet
            packageFile={packageFile}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={true}
            safeCommitHash={safeCommitHash}
            unsafeCommitHash={unsafeCommitHash}
          />
        );
      }
    } else if (languageType === 'PHP') {
      upgradeInstructionsHtml = (
        <PackageManagerPackagist
          component={component}
          safeVersion={safeVersion}
          unsafeVersion={unsafeVersion}
          transitive={true}
        />
      );
    }
  }

  return (
    <div className="grid">
      {projectType.toUpperCase() !== PROJECT_TYPES.CONTAINER && (
        <div className="grid mt--- mb- bo--1 ml p bo-rad--3 border-color--white-dark bg-color--white">
          <div className="grid__item bo-r--1 border-color--white-dark m0 pl0 pr- col-1-5">
            <Tooltip
              width="width--200"
              place="top"
              content={
                <div>
                  A transitive dependency is a library that is included in your project indirectly
                  through another dependency
                </div>
              }
              id={'transitive-dependency-text'}
            >
              <span className="text--bold">Transitive Dependency</span>{' '}
              <i className="sci sci__detail-information color--primary" />
            </Tooltip>
          </div>
          <div className="grid__item border-color--white-dark m0 col-4-5 pl lh+">
            {transitiveDependencyText}
          </div>
        </div>
      )}

      {updateText && (
        <div className="grid__item col-1-1 pr+ ml bo-l--2 border-color--white-dark text--italic">
          {updateText}
        </div>
      )}

      <div className="grid__item col-1-1 pl+">{upgradeInstructionsHtml}</div>
      <div className="grid__item col-1-1 pr+ pt-">{validateText}</div>
    </div>
  );
};

export default ReportDetailsPageTransitiveContent;
