import React from 'react';
import Sticky from 'react-sticky-el';

const ReportActionsWrapper: React.FunctionComponent<{}> = props => {
  const { children } = props;

  //We have to do transform: '' as we are using zIndex instead of transform translateZ which Sticky is using
  return (
    <Sticky className="zIndex-7--nav">
      <div
        className="bo--1 border-color--white-dark bg-color--white min-height--55 flex"
        data-automation-id="ReportActionsWrapper"
      >
        <div className="grid grid--middle grid--narrow ml0 col-1-1">{children}</div>
      </div>
    </Sticky>
  );
};

export default ReportActionsWrapper;
