import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';

interface PackageManagerSbtProps {
  component: object;
  range?: object;
  safeVersion: string;
  transitive: boolean;
  unsafeVersion?: string;
  packageFile?: string;
  lineNumber?: string;
}

const PackageManagerSbt: React.FunctionComponent<PackageManagerSbtProps> = props => {
  const {
    component,
    range = {},
    safeVersion,
    transitive = false,
    packageFile = 'build.sbt',
    lineNumber = '',
  } = props;
  const { coordinate1, coordinate2 } = component;
  const unsafeVersion =
    props.unsafeVersion ||
    (range.versionRange && range.versionRange.indexOf('-') !== -1
      ? range.versionRange.split('-')[0]
      : '');

  if (!transitive) {
    return (
      <div className="grid bo--1 border-color--white-dark bg-color--white mt lh+">
        <CodeDiffHeader text={packageFile} />
        <CodeDiffLine
          lineNumber={lineNumber}
          type="delete"
          text={`libraryDependencies += "${coordinate1}" % "${coordinate2}" % "${unsafeVersion}"`}
        />
        <CodeDiffLine
          lineNumber={lineNumber}
          type="add"
          text={`libraryDependencies += "${coordinate1}" % "${coordinate2}" % "${safeVersion}"`}
        />
      </div>
    );
  } else {
    return (
      <div className="grid bo--1 border-color--white-dark bg-color--white mt lh+">
        <CodeDiffHeader text={packageFile} />
        <CodeDiffLine
          type="add"
          text={`dependencyOverrides += "${coordinate1}" % "${coordinate2}" % ${safeVersion}`}
        />
      </div>
    );
  }
};

export default PackageManagerSbt;
