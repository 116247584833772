import ApiService from '~/utils/ApiService';
import AuthService from '~/utils/AuthService';
import ErrorService from '~/utils/ErrorService';
import _ from 'lodash';
import config from '~/config';
import * as toastrActions from '~/actions/toastr';

export const FIND_MY_ORGS_EMAIL_SUCCESS = 'FIND_MY_ORGS_EMAIL_SUCCESS';
export const UPDATE_FIND_MY_ORGS_EMAIL_VALUE = 'UPDATE_FIND_MY_ORGS_EMAIL_VALUE';
export const INVALID_RESET_TOKEN = 'INVALID_RESET_TOKEN';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const MISSING_RESET_TOKEN = 'MISSING_RESET_TOKEN';
export const MSA_AGREE_NOT_CHECKED = 'MSA_AGREE_NOT_CHECKED';
export const RESET_STATE = 'RESET_STATE';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const SEND_PASSWORD_RESET_EMAIL_FAILURE = 'SEND_PASSWORD_RESET_EMAIL_FAILURE';
export const SEND_PASSWORD_RESET_EMAIL_SUCCESS = 'SEND_PASSWORD_RESET_EMAIL_SUCCESS';
export const SHOW_MISMATCHING_PASSWORD_ERROR = 'SHOW_MISMATCHING_PASSWORD_ERROR';
export const SIGNING_UP = 'SIGNING_UP';
export const STORE_RESET_TOKEN = 'STORE_RESET_TOKEN';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const TOGGLE_MSA_AGREE = 'TOGGLE_MSA_AGREE';
export const TRACK_SIGNUP_SUBMIT = 'TRACK_SIGNUP_SUBMIT';
export const UPDATE_AUTO_LOGOUT = 'UPDATE_AUTO_LOGOUT';
export const UPDATE_EMAIL_INPUT = 'UPDATE_EMAIL_INPUT';
export const UPDATE_EMAIL_VALIDITY = 'UPDATE_EMAIL_VALIDITY';
export const UPDATE_IS_FETCHING_TOKEN = 'UPDATE_IS_FETCHING_TOKEN';
export const UPDATE_ORG_DOMAIN_REDIRECT_ERROR = 'UPDATE_ORG_DOMAIN_REDIRECT_ERROR';
export const UPDATE_GITHUB_LOGGING_IN = 'UPDATE_GITHUB_LOGGING_IN';
export const UPDATE_LOGIN_QUERY = 'UPDATE_LOGIN_QUERY';
export const UPDATE_IS_SAML_ENABLED = 'UPDATE_IS_SAML_ENABLED';
export const UPDATE_IS_ORG_DOMAIN_AVAILABLE = 'UPDATE_IS_ORG_DOMAIN_AVAILABLE';
export const UPDATE_SAML_EMAIL_VALUE = 'UPDATE_SAML_EMAIL_VALUE';
export const UPDATE_VANITY_DOMAIN = 'UPDATE_VANITY_DOMAIN';
export const UPDATE_WIDGET_ID = 'UPDATE_WIDGET_ID';
export const CLEAR_INPUT_ERRORS = 'CLEAR_INPUT_ERRORS';
export const CHECK_ORG_DOMAIN_REQUEST = 'CHECK_ORG_DOMAIN_REQUEST';
export const CHECK_ORG_DOMAIN_SUCCESS = 'CHECK_ORG_DOMAIN_SUCCESS';
export const UPDATE_LOGIN_MODE = 'UPDATE_LOGIN_MODE';
export const USER_IS_A_ROBOT = 'USER_IS_A_ROBOT';
export const UPDATE_SIGNUP_ERROR = 'UPDATE_SIGNUP_ERROR';
export const CHECK_ORG_MIGRATED = 'CHECK_ORG_MIGRATED';

export const resetState = () => ({
  type: RESET_STATE,
});

export const updateAutoLogout = bool => ({
  type: UPDATE_AUTO_LOGOUT,
  bool,
});

export const updateGithubLoggingIn = bool => ({
  type: UPDATE_GITHUB_LOGGING_IN,
  bool,
});

export const updateIsSamlEnabled = bool => ({
  type: UPDATE_IS_SAML_ENABLED,
  bool,
});

export const checkOrgDomainRequest = () => ({
  type: CHECK_ORG_DOMAIN_REQUEST,
});

export const checkOrgDomainSuccess = () => ({
  type: CHECK_ORG_DOMAIN_SUCCESS,
});

export const checkOrgMigrated = (status: App.OrgMigrationStatusStrings) => ({
  type: CHECK_ORG_MIGRATED,
  orgMigrationStatus: status,
});

export const updateIsFetchingToken = bool => ({
  type: UPDATE_IS_FETCHING_TOKEN,
  bool,
});

export const updateLoginQuery = query => ({
  type: UPDATE_LOGIN_QUERY,
  query,
});

export const updateSamlEmailValue = (value, isValidEmail) => ({
  type: UPDATE_SAML_EMAIL_VALUE,
  value,
  isValidEmail,
});

export const userIsARobot = () => ({
  type: USER_IS_A_ROBOT,
});

export const trackSignUpSubmit = () => ({
  type: TRACK_SIGNUP_SUBMIT,
  meta: {
    snowplow: true,
  },
});

export const submitSignUpForm = params => dispatch => {
  dispatch(trackSignUpSubmit());
  ApiService.post('/signup', { data: params })
    .then(
      res => {
        const { _links = {} } = res;
        const { html = {} } = _links;
        const { href } = html;

        if (res.authToken) {
          AuthService.handleNewAccountCookie('true');
          window.location.href = `${href}/login#session=${res.authToken}`;
        }
      },
      error => {
        ErrorService.capture('Error signing in:', error);
        if (error.error === 'SAML_REQUIRED') {
          window.location.href = '/saml?error=social-auth';
        } else {
          dispatch(signupFailed(error));
        }
      }
    )
    .catch(error => {
      dispatch(signupFailed(error));
      ErrorService.capture('Error signing in:', error);
    });
};

export const submitPasswordResetForm = params => dispatch => {
  return ApiService.post('/forgot-password/reset', { data: params })
    .then(
      () => {
        return { success: true };
      },
      error => {
        if (error.status === 404) {
          dispatch(invalidResetToken());
        } else {
          dispatch(resetPasswordFailed());
        }
        ErrorService.capture('Error signing in:', error);
        return {};
      }
    )
    .catch(error => {
      if (error.status === 404) {
        dispatch(invalidResetToken());
      } else {
        dispatch(resetPasswordFailed());
      }
      ErrorService.capture('Error signing in:', error);
      return {};
    });
};

export const invalidResetToken = () => ({
  type: INVALID_RESET_TOKEN,
});

export const resetPasswordFailed = () => ({
  type: RESET_PASSWORD_FAILED,
});

export const signupFailed = error => ({
  type: SIGNUP_FAILED,
  error,
});

export const loginWithUsername = (params, rememberComputerToken) => dispatch => {
  const endpoint = `/login${rememberComputerToken}`;

  let form_data = new FormData();

  for (let key in params) {
    form_data.append(key, params[key]);
  }

  ApiService.post(endpoint, { data: form_data, headers: { 'Content-Type': 'multipart/form-data' } })
    .then(
      res => {
        if (res.authToken) {
          if (window && window.location && window.location.search) {
            window.location.href = `/login#session=${res.authToken}`;
          } else {
            window.location.href = `/login#session=${res.authToken}`;
            window.location.reload();
          }
        }
      },
      error => {
        dispatch(loginFailed(error));
        ErrorService.capture('Error logging in:', error);
      }
    )
    .catch(error => {
      ErrorService.capture('Error logging in:', error);
    });
};

export const showMismatchingPasswordsError = () => ({
  type: SHOW_MISMATCHING_PASSWORD_ERROR,
});

export const loginFailed = (error = {}) => ({
  type: LOGIN_FAILED,
  error,
  meta: {
    snowplow: true,
  },
});

export const sendResetPasswordEmail = params => dispatch => {
  ApiService.post('/forgot-password', { data: params })
    .then(
      () => {
        dispatch(sendPasswordResetEmailSuccess());
      },
      () => {
        dispatch(sendPasswordResetEmailFailure());
      }
    )
    .catch(() => {
      dispatch(sendPasswordResetEmailFailure());
    });
};

export const sendPasswordResetEmailFailure = () => ({
  type: SEND_PASSWORD_RESET_EMAIL_FAILURE,
});

export const sendPasswordResetEmailSuccess = () => ({
  type: SEND_PASSWORD_RESET_EMAIL_SUCCESS,
  meta: {
    snowplow: true,
  },
});

export const storeResetToken = token => ({
  type: STORE_RESET_TOKEN,
  token,
});

export const missingResetToken = () => ({
  type: MISSING_RESET_TOKEN,
});

export const clearInputErrors = () => ({ type: CLEAR_INPUT_ERRORS });

export const toggleMsaAgree = () => ({
  type: TOGGLE_MSA_AGREE,
  meta: {
    snowplow: true,
  },
});

export const msaAgreeNotChecked = () => ({
  type: MSA_AGREE_NOT_CHECKED,
});

export const updateSignupError = (error = {}) => ({
  type: UPDATE_SIGNUP_ERROR,
  error,
});

export const updateEmailInput = value => ({
  type: UPDATE_EMAIL_INPUT,
  value,
});

export const updateWidgetId = widgetId => ({
  type: UPDATE_WIDGET_ID,
  widgetId,
});

export const signingUp = bool => ({
  type: SIGNING_UP,
  bool,
});

export const updateVanityDomain = value => ({
  type: UPDATE_VANITY_DOMAIN,
  value,
});

export const findOrgsByEmail = email => dispatch => {
  ApiService.post('/orgs/search', { data: { email } })
    .then(
      () => {
        dispatch(findMyOrgsEmailSuccess(email));
      },
      err => {
        dispatch(
          toastrActions.addToastr({
            id: `EMAIL_ERROR`,
            level: 'error',
            title: 'Error sending email',
            message:
              err.message ||
              `We ran into an error sending an email to ${email}. Please double check the address and try again.`,
            disableTimeout: true,
          })
        );
      }
    )
    .catch(error => {
      dispatch(
        toastrActions.addToastr({
          id: `EMAIL_ERROR`,
          level: 'error',
          title: 'Error sending email',
          message:
            error.message ||
            `We ran into an error sending an email to ${email}. Please double check the address and try again.`,
          disableTimeout: true,
        })
      );
    });
};

export const findMyOrgsEmailSuccess = email => ({
  type: FIND_MY_ORGS_EMAIL_SUCCESS,
  email,
});

export const updateFindMyOrgsEmailValue = value => ({
  type: UPDATE_FIND_MY_ORGS_EMAIL_VALUE,
  value,
});

export const updateLoginMode = mode => ({
  type: UPDATE_LOGIN_MODE,
  mode,
});

export const checkOrgDomain = domain => dispatch => {
  if (!domain) {
    dispatch(updateOrgDomainRedirectError());
    return Promise.resolve();
  }

  const options = {
    slug: domain,
  };

  dispatch(checkOrgDomainRequest());
  return ApiService.post('/orgs/check', { data: options })
    .then(
      res => {
        if (res && res.available) {
          dispatch(updateIsOrgDomainAvailable(true));
          dispatch(updateOrgDomainRedirectError());
        }

        if (res && !!res.migrationStatus) {
          dispatch(checkOrgMigrated(res.migrationStatus));
        }

        dispatch(checkOrgDomainSuccess());

        return res;
      },
      () => {
        // if veracode user reaches srcclr login, direct to veracode login domain
        if (_.includes(config.VERACODE_DOMAINS, window.location.hostname)) {
          window.location.href = `${config.VERACODE_LOGIN_HOST}`;
        }
        dispatch(updateOrgDomainRedirectError());
        return { available: true }; // not really, but we don't want the app to redirect so we're cheating a bit.
      }
    )
    .catch(() => {
      dispatch(updateOrgDomainRedirectError());
      return { available: true }; // not really, but we don't want the app to redirect so we're cheating a bit.
    });
};

export const updateOrgDomainRedirectError = () => ({
  type: UPDATE_ORG_DOMAIN_REDIRECT_ERROR,
});

export const updateIsOrgDomainAvailable = isAvailable => ({
  type: UPDATE_IS_ORG_DOMAIN_AVAILABLE,
  isAvailable,
});
