import * as actions from '~/actions/payment';

export const defaultState = {
  complete: false,
  errorMessage: undefined,
  isSubmittingPayment: false,
  payingWithPurchaseOrder: false,
  isRequestingPurchaseOrder: false,
  successMessagePurchaseOrder: false,
  purchaseOrderErrorMessage: undefined,
  showPurchaseOrderSuccessModal: false,
};

const paymentState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_PAYMENT_ERROR:
      return {
        ...state,
        errorMessage: action.message,
        isSubmittingPayment: false,
      };
    case actions.UPDATE_PAYMENT_STATUS:
      return {
        ...state,
        errorMessage: action.error,
        complete: action.complete,
      };
    case actions.IS_SUBMITTING_PAYMENT:
      return {
        ...state,
        isSubmittingPayment: action.bool,
      };
    case actions.PAYMENT_SUCCESS:
      return {
        ...state,
        isSubmittingPayment: false,
      };
    case actions.TOGGLE_PURCHASE_ORDER:
      return {
        ...state,
        payingWithPurchaseOrder: !state.payingWithPurchaseOrder,
      };
    case actions.PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        successMessagePurchaseOrder: 'Our sales representative will be in touch shortly.',
        isRequestingPurchaseOrder: false,
      };
    case actions.PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        isRequestingPurchaseOrder: true,
      };
    case actions.PURCHASE_ORDER_ERROR:
      return {
        ...state,
        purchaseOrderErrorMessage: action.message,
        isRequestingPurchaseOrder: false,
      };
    case actions.SHOW_PURCHASE_ORDER_SUCCESS_MODAL:
      return {
        ...state,
        showPurchaseOrderSuccessModal: action.bool,
      };
    default:
      return state;
  }
};

export default paymentState;
