import * as actions from '~/actions/apiKeyCredentials';

const apiKeyCredentials = (state = {}, action) => {
  switch (action.type) {
    case actions.FETCH_API_KEY_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.SET_API_KEY_CREDENTIALS:
      return {
        ...state,
        id: 'API Credentials exist. Click Generate for new credentials',
        secretKey: 'API Credentials exist. Click Generate for new credentials',
        apiKeyCreationDate: action.apiKeyCredentials
          ? action.apiKeyCredentials.apiKeyCreationDate
          : undefined,
        apiKeyExpirationDate: action.apiKeyCredentials
          ? action.apiKeyCredentials.apiKeyExpirationDate
          : undefined,
        isFetching: false,
        message: '',
      };
    case actions.FETCH_API_KEY_CREDENTIALS_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
      };
    case actions.GENERATE_NEW_CREDENTIALS_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case actions.GENERATE_NEW_CREDENTIALS_SET:
      return {
        ...state,
        isUpdating: false,
        id: action.apiKeyCredentials.apiKeyId,
        secretKey: action.apiKeyCredentials.apiKeySecret,
        apiKeyCreationDate: action.apiKeyCredentials.apiKeyCreationDate,
        apiKeyExpirationDate: action.apiKeyCredentials.apiKeyExpirationDate,
      };
    case actions.GENERATE_NEW_CREDENTIALS_FAILURE:
      return {
        ...state,
        isUpdating: false,
        message: action.message,
      };

    case actions.REVOKE_CREDENTIALS_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case actions.REVOKE_CREDENTIALS_SET:
      return {
        ...state,
        isDeleting: false,
        id: '',
        secretKey: '',
        apiKeyCreationDate: '',
        apiKeyExpirationDate: '',
      };
    case actions.REVOKE_CREDENTIALS_FAILURE:
      return {
        ...state,
        isDeleting: false,
        message: action.message,
      };
    default:
      return state;
  }
};

export default apiKeyCredentials;
