import produce from 'immer';
import * as agentModalActions from '~/actions/agentModal';
import { AgentModalState } from '~/reducers/vcAppState/vcAppStateTypes/types';

const defaultState: AgentModalState = {
  showModal: undefined,
  orgLevelSelected: false,
  workspaceLevelSelected: false,
  selectedWorkspace: null,
};

const agentModalState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case agentModalActions.SHOW_CREATE_AGENT_MODAL:
        draft.showModal = action.modalType;
        break;
      case agentModalActions.UPDATE_WORKSPACE_LEVEL_SELECTED:
        draft.orgLevelSelected = false;
        draft.workspaceLevelSelected = true;
        break;
      case agentModalActions.UPDATE_ORG_LEVEL_SELECTED:
        draft.orgLevelSelected = true;
        draft.workspaceLevelSelected = false;
        break;
      case agentModalActions.UPDATE_SELECTED_WORKSPACE:
        draft.selectedWorkspace = action.workspace;
        break;
      case agentModalActions.RESET_AGENT_MODAL_STATE:
        draft.orgLevelSelected = false;
        draft.workspaceLevelSelected = false;
        draft.selectedWorkspace = null;
    }
  });
};

export default agentModalState;
