import React from 'react';
import Helpers from '~/utils/Helpers';
import classNames from 'classnames';
import ParentLicenseNotice from '~/containers/ParentLicenseNotice';
import LicenseRiskIcon, { Rating } from '~/components/Icons/LicenseRiskIcon';
import { licenseRiskSeverityOptions } from '~/constants/ModelConstants';

interface LicensesQuickviewItemRowProps {
  license: object;
  isLast: boolean;
  isHiding: boolean;
  toggleExpandedLicense: (...args: any[]) => any;
  expandedLicense: string;
  index: number;
  expandable?: boolean;
  risk?: string;
}

const LicensesQuickviewItemRow: React.FunctionComponent<LicensesQuickviewItemRowProps> = props => {
  const {
    license: licenseData,
    expandedLicense,
    isLast,
    index,
    expandable,
    risk = 'UNRECOGNIZED',
  } = props;
  const { fullText, isParent, name } = licenseData;

  const isLicenseRiskFeatureEnable: boolean = Helpers.hasPolicyRiskEnabled();

  let riskFormatted;
  if (risk) {
    riskFormatted = licenseRiskSeverityOptions.find(s => s.value === risk).value;
  }
  const isExpanded = expandedLicense === name;
  const isGpl = Helpers.isGplString(name);

  const fullTextClasses = classNames({
    'is-hiding': !isExpanded,
    'isShowing--scroll': isExpanded,
    ph: true,
    'pv--': isExpanded,
  });

  const licenseNameClasses = classNames({
    'cursor-on-hover--pointer': expandable,
    'font--16': true,
    'color--danger': isGpl,
  });

  const caretClasses = classNames({
    'sci sci--sm': true,
    'sci__caret--right': !isExpanded && expandable,
    'sci__caret--down': isExpanded && expandable,
    'pr-': expandable,
    pr: !expandable,
  });

  let licenseParentLink;
  let sanitizedPrettyName = name === 'UNKNOWN' ? 'Unknown' : name;
  sanitizedPrettyName = name === 'LICENSE' ? 'License' : name;

  if (isParent) {
    licenseParentLink = <ParentLicenseNotice id={`license-${index}`} />;
  }

  function toggleExpandedLicense() {
    props.toggleExpandedLicense(name);
  }

  return (
    <div className="grid">
      <div className={`grid__item col-1-1`}>
        <div className={licenseNameClasses} onClick={toggleExpandedLicense}>
          <i className={caretClasses} />
          {sanitizedPrettyName} <span>{licenseParentLink}</span>
        </div>
        {risk && isLicenseRiskFeatureEnable && (
          <div className="col-1-2 flex m">
            <div className="mr-">License Risk :</div>
            <LicenseRiskIcon id={name} rating={Rating[riskFormatted]} />
            <div className="severityRangeText">{riskFormatted}</div>
          </div>
        )}
        <p className={fullTextClasses}>{fullText}</p>
        {!isLast ? <hr /> : null}
      </div>
    </div>
  );
};

export default LicensesQuickviewItemRow;
