import * as actions from '~/actions/billing';

export const defaultState = {
  invoices: [],
  account: {},
  paymentMethod: {},
  plan: {},
  pendingPlan: {},
  planLevels: [],
  showModal: undefined,
  selectedPlan: {},
  scanUsage: [],
  changePlanDetails: {},
  allowOverages: false,
  warnPercentScan: false,
  warnScanOverages: false,
  warnScanLimit: false,
  billingError: undefined,
  isFetchingDashboard: false,
};

const billingState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_BILLING_DATA: {
      const {
        account,
        invoices,
        'payment-method': paymentMethod,
        pendingPlan,
        scanUsage,
        allowOverages,
        warnPercentScan,
        warnScanOverages,
        warnScanLimit,
      } = action.billingData;
      const plan = action.billingData.plan || {};

      return {
        ...state,
        invoices,
        account,
        paymentMethod,
        plan,
        pendingPlan,
        scanUsage,
        allowOverages,
        warnPercentScan,
        warnScanOverages,
        warnScanLimit,
        isFetchingDashboard: false,
      };
    }
    case actions.FETCH_BILLING_DASHBOARD_REQUEST:
      return {
        ...state,
        isFetchingDashboard: true,
      };
    case actions.UPDATE_PLAN_LEVELS:
      return {
        ...state,
        planLevels: action.plans.products,
      };
    case actions.SHOW_MODAL:
      return {
        ...state,
        showModal: action.modalType,
      };
    case actions.SAVE_BILLING_INFO_COPIES:
      return {
        ...state,
        account: {
          ...state.account,
          nameWas: state.account.name || '',
          phoneNumberWas: state.account.phoneNumber || '',
          emailWas: state.account.email || '',
        },
      };
    case actions.RESET_BILLING_INFO:
      return {
        ...state,
        account: {
          name: state.account.nameWas,
          phoneNumber: state.account.phoneNumberWas,
          email: state.account.emailWas,
          nameWas: undefined,
          phoneNumberWas: undefined,
          emailWas: undefined,
        },
      };
    case actions.CLEAR_BILLING_INFO_COPIES:
      return {
        ...state,
        account: {
          ...state.account,
          nameWas: undefined,
          phoneNumberWas: undefined,
          emailWas: undefined,
        },
      };
    case actions.UPDATE_BILLING_FIELD:
      return {
        ...state,
        account: {
          ...state.account,
          [action.field]: action.value,
        },
      };
    case actions.UPDATE_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.plan,
      };
    case actions.UPDATE_CHANGE_PLAN_DETAILS:
      return {
        ...state,
        changePlanDetails: action.details,
      };
    case actions.UPDATE_ALLOW_OVERAGES:
      return {
        ...state,
        allowOverages: action.allowOverages,
      };
    case actions.UPDATE_BILLING_ERROR:
      return {
        ...state,
        billingError: action.message,
      };
    default:
      return state;
  }
};

export default billingState;
