import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Helmet from 'react-helmet';
import _ from 'lodash';

import ReportContentWrapper from '~/containers/ReportContentWrapper';
import IssueProjectDetails from '~/components/ReportComponents/IssueProjectDetails';
import SourceClearLoader from '~/components/SourceClearLoader';
import Tooltip from '~/components/Tooltip';
import IssueDetailCreateIssueBtn from '~/containers/IssueDetailCreateIssueBtn';
import LicensesQuickviewItemRow from '~/components/ReportPage/LicensesQuickviewItemRow';
import ReportFetchErrorMessage from '~/components/ReportFetchErrorMessage';
import CommentIssueModal from '~/containers/CommentIssueModal';

import Helpers from '~/utils/Helpers';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import JiraHelper from '~/utils/JiraHelper';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import IssueHelper from '~/utils/IssueHelper';
import Spinner from '~/components/Spinner';
import RegistryLink from '~/components/RegistryLink';
import IssueSeverityFlag, {
  IssueSeverityFlagDisplayStyle,
} from '~/components/ReportComponents/IssueSeverityFlag';
import IssueDetailComment from '~/components/IssueDetailComment';
import SidebarWrapper from '~/containers/SidebarWrapper';
import IssueHistory from '~/containers/IssueHistory';
import ProIcon from '~/components/ProIcon';

import * as reportDetailsPageActions from '~/actions/reportDetailsPage';
import * as reportIssuesActions from '~/actions/reportIssues';
import * as upgradeModalActions from '~/actions/upgradeModal';
import * as teamActions from '~/actions/team';
import * as modalActions from '~/actions/modal';
import * as sidebarActions from '~/actions/sidebar';

import * as MODEL from '~/constants/ModelConstants';
import { IssuesDetailsPageProps } from '~/containers/IssuesDetailsPage.types';

const REPORT_TYPE = 'ISSUE_LICENSES';
const SIDEBAR_ID = 'ISSUE_HISTORY';

class IssuesLicensesDetailsPage extends Component<
  IssuesDetailsPageProps & ReturnType<typeof mapDispatchToProps>
> {
  constructor(props) {
    super(props);

    (this as any).ignoreIssueCheckboxRef = React.createRef();
    this.handleIgnoreIssue = this.handleIgnoreIssue.bind(this);
  }

  async componentDidMount() {
    const { match } = this.props;
    const { params = {} as any } = match;
    const { issueId, teamId } = params;

    this.props.reportDetailsPageActions.resetReport(REPORT_TYPE);

    this.props.teamActions.fetchIssueData(teamId);
    const {
      lastScan: { id: scanId },
    } = await this.props.reportDetailsPageActions.fetchDetailsPage(REPORT_TYPE, issueId);

    this.props.reportIssuesActions.getIntegrationIssuesCreated(issueId, scanId);
  }

  fetchIssueEventsAndDetails = () => {
    const { match } = this.props;
    const { params = {} as any } = match;
    const { issueId } = params;
    this.props.reportDetailsPageActions.fetchIssuesEvents(REPORT_TYPE, issueId);
    this.props.reportDetailsPageActions.fetchIssuesDetails(REPORT_TYPE, issueId);
  };

  toggleLicense = licenseName => {
    this.props.reportDetailsPageActions.toggleLicense(REPORT_TYPE, licenseName);
  };

  licensesContentDidMount = licenses => {
    if (licenses) {
      this.props.reportDetailsPageActions.fetchLicensesContentData(REPORT_TYPE, licenses);
    }
  };

  handleTabClick = field => {
    this.props.reportDetailsPageActions.updateDetailsPageActiveTab(REPORT_TYPE, field);
  };

  getCoordAndVersionString = component => {
    return `${component.coord1}${component.coord2 ? ` ${component.coord2}` : ``} ${
      component.libraryVersion
    }`;
  };

  toggleCtaCreateIssueModalOpen = () => {
    const { match } = this.props;
    const { params = {} as any } = match;
    const { teamId } = params;
    this.props.upgradeModalActions.showUpgradeModal(MODEL.UPGRADE_MODAL_CREATE_ISSUE, teamId);
    this.props.reportIssuesActions.createIssueButtonClickedSnowplow(teamId, MODEL.NON_PAID_TEAM);
  };

  toggleSidebar = () => {
    const { sidebarState = {} } = this.props;
    const isSidebarOpen = sidebarState[SIDEBAR_ID];

    if (isSidebarOpen) {
      this.props.sidebarActions.closeSidebar(SIDEBAR_ID);
    } else {
      this.props.sidebarActions.openSidebar(SIDEBAR_ID);
    }
  };

  getCurrentIssue = () => {
    const { reportDetailsPageState } = this.props;
    const { [REPORT_TYPE]: detailsState } = reportDetailsPageState;
    const { details } = detailsState;
    const { content = {} } = details;
    const { issue } = content;

    return issue;
  };

  ignoreIssue = (comment?: string) => {
    const issue = this.getCurrentIssue();

    const suppress = (this as any).ignoreIssueCheckboxRef.checked;
    this.props.reportIssuesActions
      .maybeSuppressIssue(issue, REPORT_TYPE, suppress, comment)
      .then(() => {
        this.fetchIssueEventsAndDetails();
        this.props.modalActions.closeModal(MODEL.IGNORE_COMMENT_MODAL);
      });
  };

  isIssueCommentEnabled = () => {
    const { match, teamState } = this.props;
    const { params = {} as any } = match;
    const { teamId } = params;
    const { teams = [] } = teamState;
    const activeTeam = Helpers.getActiveTeamById({ teams, teamId });
    return activeTeam?.permissions?.issueComments || false;
  };

  isIgnoreIssueChangesEnabled = () => {
    const { match, teamState } = this.props;
    const { params = {} as any } = match;
    const { teamId } = params;
    const { teams = [] } = teamState;
    const activeTeam = Helpers.getActiveTeamById({ teams, teamId });
    return activeTeam.permissions.ignoreIssueChanges || false;
  };

  commentIssueOnConfirm = (comment: string) => {
    const { modalState = {} } = this.props;
    const { openedModal = {} } = modalState;

    if (openedModal[MODEL.IGNORE_COMMENT_MODAL]) {
      this.ignoreIssue(comment);
    } else if (openedModal[MODEL.COMMENT_ONLY_MODAL]) {
      this.commentIssue(comment);
    }
  };

  commentIssueOnCancel = () => {
    const issue = this.getCurrentIssue();

    const { suppressed } = issue;

    (this as any).ignoreIssueCheckboxRef.checked = suppressed;
  };

  handleIgnoreIssue = () => {
    this.isIssueCommentEnabled()
      ? this.props.modalActions.openModal(MODEL.IGNORE_COMMENT_MODAL)
      : this.ignoreIssue();
  };

  commentIssue = (comment: string) => {
    const issue = this.getCurrentIssue();
    this.props.modalActions.closeModal(MODEL.COMMENT_ONLY_MODAL);
    this.props.reportIssuesActions.commentIssue(issue, comment).then(() => {
      this.fetchIssueEventsAndDetails();
    });
  };

  render() {
    const {
      reportDetailsPageState,
      match,
      orgState,
      reportIssuesState,
      teamState,
      sidebarState,
    } = this.props;
    const { params = {} as any } = match;
    const { teamId } = params;
    const { org } = orgState;
    const { [REPORT_TYPE]: detailsState = {} } = reportDetailsPageState;
    const {
      details = {},
      isFetching,
      licenseContentData = [],
      isLicenseContentDataFetching,
      activeLicense = '',
      licenseContentDataFetchFailure = '',
    } = detailsState;
    const { content = {} } = details;
    const isSidebarOpen = sidebarState[SIDEBAR_ID];
    const { issue = {} } = content;
    const {
      id,
      hasComments,
      libraryName,
      license,
      coord1,
      coord2,
      coordinateType,
      libraryVersion,
      libraryReleaseDate,
      latestVersion,
      latestReleaseDate,
      dependencyMode,
      _links = {},
      issueSeverity = 0,
      suppressed,
      status,
      licenseCount,
      licenses,
      projectType = '',
    } = issue;

    const {
      isSuppressingIssue,
      isSuppressingIssueSuccess,
      isSuppressingIssueFailure,
      integrations,
    } = reportIssuesState;
    const formattedLibraryReleaseDate = Helpers.formatDate(libraryReleaseDate);
    const formattedLatestReleaseDate = Helpers.formatDate(latestReleaseDate);

    const scanData = IssueHelper.getScanData(issue);
    const { scanId, fixedScanId, fixedScanDate } = scanData;

    const { teams = [] } = teamState;
    const activeTeam = Helpers.getActiveTeamById({ teams, teamId });

    const isPoliciesEnabled =
      FeatureFlagHelper.isFeatureEnabledForOrgAndTeam(
        MODEL.FEATURE_SLUG_MAP.POLICIES,
        org,
        activeTeam
      ) || OrgPaidStatus.isOrgEnterpriseOrTrial(org);

    const sidebarTitleHtml = (
      <div className="flex align-items--center font--h6 text--bold">
        <i className="fas fa-clock mr- font--h4 color--primary" />
        Issue Event History
      </div>
    );

    // Issue severity
    const issueSeverityVal = issueSeverity || 0; //handling null case

    // Linked issue related
    const { [issue.id]: issueIntegrations = {} } = integrations;
    const { data: linkedTickets = [] } = issueIntegrations;

    const linkedJiraTicket = linkedTickets.filter(ticket => ticket.issueType === 'JIRA');

    const jiraHref =
      linkedJiraTicket.length === 0
        ? null
        : JiraHelper.getJiraLink(
            linkedJiraTicket[0].integrationServiceUrl,
            linkedJiraTicket[0].jiraKey
          );

    const registryLibHref = (_links && _links.library && _links.library.href) || '';

    const libraryNameText = libraryName ? libraryName : 'Name not available';

    let titleText: string;

    if (license) {
      titleText = license;
    } else if (licenseCount === 0) {
      titleText = 'Library has no license';
    } else {
      titleText = 'Library uses multiple licenses';
    }

    const dependencyModeText =
      dependencyMode && dependencyMode.toUpperCase() === 'BOTH'
        ? 'Direct & Transitive'
        : dependencyMode;

    let coord1Text = coord1;
    let coordContent = '';

    if (coordinateType === 'GEM') {
      coord1Text = coord1Text && `gem '${coord1Text}'`;
    }

    if (coord1Text) {
      coordContent = `${coord1Text}${coord2 ? ' ' + coord2 : ''}`.trim();
    }

    /* eslint-disable react/no-danger */

    const libraryNameHighlightHtml = (
      <RegistryLink
        href={registryLibHref.split('?')[0]}
        className="link--obvious"
        dangerouslySetInnerHTML={Helpers.highlightItems(libraryNameText, [])}
      />
    );

    const titleHighlightHtml = (
      <span dangerouslySetInnerHTML={Helpers.highlightItems(titleText, [], 100)} />
    );

    const versionInUseHtml = (
      <RegistryLink
        className="link--obvious"
        href={`${registryLibHref.split('=')[0]}=${libraryVersion}`}
        dangerouslySetInnerHTML={Helpers.formatBreakOnDot(libraryVersion)}
      />
    );

    const latestVersionHtml = (
      <RegistryLink
        className="link--obvious"
        href={`${registryLibHref.split('=')[0]}=${latestVersion}`}
        dangerouslySetInnerHTML={Helpers.formatBreakOnDot(latestVersion)}
      />
    );

    if (isFetching) {
      return (
        <div>
          <SourceClearLoader />
        </div>
      );
    }

    const formattedStatus = status === 'FIXED' ? 'Resolved' : Helpers.capFirst(status);

    //Check Org paid/free status
    const isPaidOrTrialing = OrgPaidStatus.isOrgPaidOrTrial(org);
    const isFixed = status === 'FIXED' && fixedScanId && fixedScanDate;

    const isContainerProject = projectType.toUpperCase() === MODEL.PROJECT_TYPES.CONTAINER;

    const licenseRows = licenseContentData.map((license, idx) => {
      const licenseData = licenses.filter(lic => lic.license === license.name)[0];
      const licenseRisk = licenseData ? licenseData.risk : '';
      return (
        <LicensesQuickviewItemRow
          key={`license-${idx}`}
          index={idx}
          license={license}
          isLast={idx === licenseContentData.length - 1}
          isHiding={licenseContentData.length !== 1}
          toggleExpandedLicense={this.toggleLicense}
          expandedLicense={licenseContentData.length === 1 ? license.name : activeLicense}
          expandable={licenseContentData.length !== 1}
          risk={licenseRisk}
        />
      );
    });

    const isLicenseRiskFeatureEnable: boolean = Helpers.hasPolicyRiskEnabled();

    const unrecognizedLicenseRiskTooltipText =
      'Unrecognized indicates that no license was found for the component. However, this does not indicate that there is no risk associated with the license.';

    return (
      <div className="col-1-1 mt-">
        <Helmet>
          <title>License Issue Details</title>
        </Helmet>
        <div className="">
          {/*Library detail section*/}
          <div className="grid mb-">
            <div className="grid__item pl">
              <div
                className="flex flex--align-items--center"
                data-automation-id="IssueLicenseDetailsPage-Title"
              >
                <span className="font--h3"> Issue </span>
                <span className="font--h7 color--black-dark pl- pt-">License </span>
              </div>
            </div>
            <div className="flex grid__item flex--justify-content--end align-item--center col-2-5 pt--">
              {this.isIssueCommentEnabled() && (
                <div
                  className="link--obvious link--no-underline font--16 pb--- pt-- flex flex--align-items--center mr"
                  data-automation-id="IssueLicenseDetailsPage-IssueComment"
                >
                  <i className="sci sci__chat" />{' '}
                  <span
                    className="pl--"
                    onClick={() => this.props.modalActions.openModal(MODEL.COMMENT_ONLY_MODAL)}
                  >
                    {' '}
                    Comment{' '}
                  </span>
                  <IssueDetailComment
                    toggleSidebar={() => this.toggleSidebar()}
                    hasComments={hasComments}
                    id={`${id}-${scanId}`}
                  />
                </div>
              )}

              <div
                className="link--obvious link--no-underline font--16 pb--- pt-- flex flex--align-items--center"
                onClick={this.toggleSidebar}
              >
                {isPaidOrTrialing ? <i className="fas fa-clock" /> : <ProIcon />}{' '}
                <span className="pl--"> Show History </span>
              </div>
            </div>
          </div>
          <div className="grid grid--narrower bo--b-1 bg-color--black-light mb+ color--white">
            <div className="grid__item col-3-4 p">
              <h4 className="pb--">{titleHighlightHtml}</h4>
            </div>
            <div className="grid__item col-1-4 p">
              <div className="grid">
                <div className="grid__item col-1-1">
                  <div className="pb-">
                    {isPoliciesEnabled && (
                      <IssueSeverityFlag
                        severity={issueSeverityVal}
                        displayStyle={IssueSeverityFlagDisplayStyle.NUMBER_WITH_TEXT}
                      />
                    )}
                  </div>
                  <div>
                    <span className="text--bold">Issue ID:</span> {id}
                  </div>
                  {isPaidOrTrialing && (
                    <div className="pt--">
                      <span className="text--bold">Linked Issue:&nbsp;</span>
                      {jiraHref ? (
                        <a href={jiraHref} className="link--obvious" target="_blank">
                          {linkedJiraTicket[0].jiraKey}
                        </a>
                      ) : (
                        <span>(None) </span>
                      )}
                    </div>
                  )}
                  {isPaidOrTrialing && (
                    <div className="pt--">
                      <span className="text--bold">Status: </span>
                      {formattedStatus}
                    </div>
                  )}
                  {this.isIgnoreIssueChangesEnabled() && (
                    <div className="pt--" data-automation-id="IssueLicenseDetailsPage-ignoreIssue">
                      <span className="text--bold">Ignore Issue: &nbsp;</span>
                      <label className="position--relative">
                        <input
                          ref={input => ((this as any).ignoreIssueCheckboxRef = input)}
                          type="checkbox"
                          name={`ignore-issue`}
                          value={`ignore`}
                          defaultChecked={suppressed}
                          onChange={this.handleIgnoreIssue}
                          disabled={isSuppressingIssue}
                        />
                        <span className="control--checkbox" />
                      </label>
                      <div>
                        {isSuppressingIssue && (
                          <span>
                            <Spinner
                              size={12}
                              strokeWidth={4}
                              backgroundColor="bg-color--black-light"
                              polygonClassName="fill--black-light stroke--white"
                            />{' '}
                            Loading...
                          </span>
                        )}
                        {isSuppressingIssueSuccess && <span> Updated Successfully</span>}
                        {isSuppressingIssueFailure && <span> {isSuppressingIssueFailure}</span>}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*End of Vulnerability detail and data source details section*/}

          {/*Repo and Library details section*/}

          <div className="grid grid--narrower pb+">
            <div className="grid__item col-1-2 pl0 pr-">
              <IssueProjectDetails
                teamId={teamId}
                issue={issue}
                isPaidOrTrialing={isPaidOrTrialing}
                isContainerProject={isContainerProject}
              />
            </div>

            <div className="grid__item col-1-2 pl-">
              <div className="grid">
                <div className="grid__item col-1-1">
                  <div className="font--18 bo--b-1 border-color--muted-light mb-">
                    LIBRARY DETAILS
                  </div>
                </div>
                <div className="grid__item col-1-2">
                  <div>
                    <span className="text--bold">Affected Library:&nbsp;</span>
                    {libraryNameHighlightHtml}
                    {coordinateType && <span>, {coordinateType}</span>}
                    {coordContent && <span>, {coordContent}</span>}
                  </div>
                  <div className="pt-">
                    <span className="text--bold">Type:</span> {Helpers.capFirst(dependencyModeText)}{' '}
                    dependency
                  </div>
                </div>

                <div className="grid__item col-1-2">
                  <div className="pl--">
                    <div>
                      <span className="text--bold">Version In Use:</span> {versionInUseHtml}
                    </div>
                    <div className="pt---">
                      <span className="text--bold">Released On:</span> {formattedLibraryReleaseDate}
                    </div>
                  </div>

                  <div className="pl-- pt-">
                    <div>
                      <span className="text--bold">Latest Version:</span> {latestVersionHtml}
                    </div>
                    <div className="pt---">
                      <span className="text--bold">Released On:</span> {formattedLatestReleaseDate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*End of Repo and Library details section*/}

          {/*License Content */}
          <div className="grid grid--narrower pb+">
            <div className="grid__item col-1-1 p0">
              <div className="font--18 bo--b-1 border-color--muted-light pb- mb-">
                LICENSE CONTENT
              </div>
              <div className="grid grid--space-between">
                {licenseCount > 0 ? (
                  <div className="ml pt- pb- pl- mb bo-rad--2 bo--1 color--primary border-color--primary col-4-5">
                    {' '}
                    The content below is the publicly accessible license indicated by this library,
                    which may vary from the actual content packaged in the library.{' '}
                  </div>
                ) : (
                  <>
                    {isLicenseRiskFeatureEnable ? (
                      <div className="ml pt- pb- mb col-4-5 flex">
                        <span className="text--bold mr-">License Risk:</span>
                        <Tooltip
                          id="unrecognizedLicenseRisk"
                          maxWidthClass="max-width--300"
                          content={unrecognizedLicenseRiskTooltipText}
                          place="bottom"
                        >
                          --
                        </Tooltip>
                        <span className="ml--">Unrecognized</span>
                      </div>
                    ) : (
                      <div className="ml pt- pb- pl- mb bo-rad--2 bo--1 color--primary border-color--primary col-4-5">
                        {' '}
                        This library has no license. To fix the issue, remove or replace the library
                        with one that does have an acceptable license.{' '}
                      </div>
                    )}
                  </>
                )}
                {!isFixed && (
                  <div className="col-1-9">
                    <IssueDetailCreateIssueBtn
                      isPaidOrTrialing={isPaidOrTrialing}
                      issue={issue}
                      reportType={REPORT_TYPE}
                      teamId={teamId}
                      linkedIssueCreated={!_.isEmpty(jiraHref)}
                    />
                  </div>
                )}
              </div>

              <ReportContentWrapper
                link={licenses}
                onComponentDidMount={this.licensesContentDidMount}
              >
                {isLicenseContentDataFetching && <SourceClearLoader />}
                {!isLicenseContentDataFetching && licenseRows}
                {!isLicenseContentDataFetching && licenseContentDataFetchFailure.length > 0 && (
                  <ReportFetchErrorMessage type="licenses" />
                )}
              </ReportContentWrapper>
            </div>
          </div>
          {/*End of Vuln Methods and Dependency Graph section*/}
        </div>

        <SidebarWrapper
          id={SIDEBAR_ID}
          title={sidebarTitleHtml}
          pullRight={true}
          sidebarClassName={`bg-color--white-medium width--400`}
          shadow={true}
        >
          {isSidebarOpen && <IssueHistory reportType={REPORT_TYPE} />}
        </SidebarWrapper>
        {this.isIssueCommentEnabled() && (
          <CommentIssueModal
            onConfirm={(comment: string) => this.commentIssueOnConfirm(comment)}
            onCancel={() => this.commentIssueOnCancel()}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    modalState: state.modalState,
    reportDetailsPageState: state.reportDetailsPageState,
    reportIssuesState: state.reportIssuesState,
    teamState: state.teamState,
    orgState: state.orgState,
    sidebarState: state.sidebarState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    modalActions: bindActionCreators(modalActions as any, dispatch),
    reportDetailsPageActions: bindActionCreators(reportDetailsPageActions as any, dispatch),
    teamActions: bindActionCreators(teamActions as any, dispatch),
    reportIssuesActions: bindActionCreators(reportIssuesActions as any, dispatch),
    upgradeModalActions: bindActionCreators(upgradeModalActions as any, dispatch),
    sidebarActions: bindActionCreators(sidebarActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssuesLicensesDetailsPage);
