import { IssueTypePretty, reduxApiMap } from '~/constants/ModelConstants';
import Helpers from '~/utils/Helpers';
import moment from 'moment';

const statusPrettyMap = { FIXED: 'Resolved', OPEN: 'Open' };
const prettifyCVEStatus = status => {
  return status === 'FINAL' ? 'Public Disclosure' : 'Veracode Customer Access';
};
const ReportResponseHelper = {
  getTotalElement: response => {
    const { page = {} } = response || {};
    return page.totalElements || 0;
  },
  getEmbeddedObject: (response, reportType) => {
    const { _embedded = {} } = response;
    const key = reduxApiMap[reportType].contentKey;
    return _embedded[key];
  },
  formatIssues: (data, isPoliciesEnabled, isPolicyRiskEnabled) => {
    return data.map(data => {
      data.type = data.type ? IssueTypePretty[data.type] : '';
      data.status = data.status ? statusPrettyMap[data.status] : '';
      data.libraryReleaseDate = data.libraryReleaseDate
        ? Helpers.formatDate(data.libraryReleaseDate)
        : '';
      data.latestReleaseDate = data.latestReleaseDate
        ? Helpers.formatDate(data.latestReleaseDate)
        : '';
      data.openedScanDate = data.openedScanDate ? Helpers.formatDate(data.openedScanDate) : '';
      data.dependencyMode = Helpers.capFirst(data.dependencyMode);
      data.scanDate = data.scanDate ? Helpers.formatDate(data.scanDate) : '';
      data.cveStatus = data.cveStatus ? prettifyCVEStatus(data.cveStatus) : '';
      data.disclosureDate = data.disclosureDate ? Helpers.formatDate(data.disclosureDate) : '';
      data.updatedReleaseDate = data.updatedReleaseDate
        ? Helpers.formatDate(data.updatedReleaseDate)
        : '';
      data.deltaSeverity = Helpers.capFirst(data.deltaSeverity);
      data.deltaStatus = Helpers.capFirst(data.deltaStatus);
      data.releaseDate = data.releaseDate ? Helpers.formatDate(data.releaseDate) : '';

      if (isPoliciesEnabled) {
        data.severity = Helpers.getSeverityText(data.issueSeverity);
      } else {
        // Legacy issues
        data.severity = Helpers.capFirst(data.severity);
      }
      if (isPolicyRiskEnabled) {
        data.risk = data.risk ? Helpers.capFirst(data.risk) : '';
      }
      return data;
    });
  },
  formatSecurity: data => {
    return data.map(data => {
      data.severity = Helpers.capFirst(data.severity);
      data.disclosureDate = data.disclosureDate ? Helpers.formatDate(data.disclosureDate) : '';
      data.cve = data.cve ? data.cve : '';
      return data;
    });
  },
  formatProjects: data => {
    return data.map(data => {
      data.language = Helpers.sanitizeLanguageType(data.language);
      data.thirdPartyPercent = (data.thirdPartyPercent * 100).toFixed(0);
      data.lastScanDate = data.lastScanDate ? Helpers.formatDate(data.lastScanDate) : '';
      return data;
    });
  },
  formatLibraries: (data, isPolicyRiskEnabled) => {
    return data.map(data => {
      data.coordinateType = Helpers.sanitizePackageManagerType(data.coordinateType);
      data.languageType = Helpers.sanitizeLanguageType(data.languageType);
      data.coordinate = Helpers.sanitizePackageManagerType(data.coordinate);
      data.dependencyMode = Helpers.capFirst(data.dependencyMode);
      const dataLicenses = data.licenses;
      data.licenses = data.licenses ? Object.keys(data.licenses) : '';
      if (isPolicyRiskEnabled) {
        data.risk = dataLicenses ? Helpers.getLicenseRisksFromLicenses(dataLicenses) : '';
      }
      return data;
    });
  },
  formatLicenses: data => {
    return data.map(data => {
      data.risk = data.risk ? Helpers.capFirst(data.risk) : '';
      return data;
    });
  },
  formatWorkspaces: data => {
    return data.map(data => {
      data.lastScanDate = data.lastScanDate
        ? moment(data.lastScanDate).format('MMM D YYYY, h:mma')
        : '';
      return data;
    });
  },
};

export default ReportResponseHelper;
