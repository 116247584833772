import React from 'react';

interface TabContentProps {
  isActive?: boolean;
  className?: string;
}

const TabContent: React.FunctionComponent<TabContentProps> = props => {
  const { children, isActive, className } = props;

  if (!isActive) return null;

  return (
    <div className={`bg-color--white animation--fade-in--4s-ease ${className}`}>{children}</div>
  );
};

export default TabContent;
