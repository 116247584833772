import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import Helpers from '~/utils/Helpers';

import SourceClearModal from '~/components/SourceClearModal';
import UpdatePaymentStripeForm from '~/containers/UpdatePaymentStripeForm';

import * as billingActions from '~/actions/billing';
import * as meActions from '~/actions/me';

import { RouteComponentProps } from 'react-router-dom';

interface UpdatePaymentPageProps extends RouteComponentProps {
  billingActions: object;
  meActions: object;
  billingState: object;
  orgState: App.OrgState;
}
class UpdatePaymentPage extends React.Component<UpdatePaymentPageProps, {}> {
  updatePaymentCallback() {
    const { history } = this.props;

    this.props.meActions.fetchMe().then(() => {
      history.push('/');
    });
  }

  confirmDowngrade() {
    const { orgState, history } = this.props;
    const { org = {} } = orgState;

    this.props.billingActions.submitUpdatePlan(org.id, 1001).then(res => {
      if (res.success) {
        history.push('/org/settings/subscription');
      }
    });
  }

  showModal(modalType) {
    this.props.billingActions.showModal(modalType);
  }

  render() {
    const { billingState } = this.props;
    const { plan = {}, invoices = [], showModal } = billingState;
    const paymentMethod = billingState.paymentMethod || {};
    const { scanLimit, priceInCents } = plan;
    const mostRecentInvoice = invoices[0] || {};
    const { totalInCents = 0, date } = mostRecentInvoice;
    const { cardType = '', lastFour } = paymentMethod;
    const endOfMonth = moment().add(1, 'months').date(0).format('MMMM D, YYYY');

    return (
      <div className="container">
        <div className="grid mt+ mb++">
          <div className="grid__item col-1-1 text--center font--h3">
            Action Required to Reactivate Your Account
          </div>
          <div className="grid__item col-1-1 mt flex justify-content--center">
            <div className="bo--1 border-color--danger p- flex align-items--center color--danger">
              <div className="pr- pl---">
                <i className="sci sci__vulnerability--new font--h6" />
              </div>
              Your last invoice could not be paid with this card and your account is suspended.
              Please update your payment method to restore service, or downgrade to the Personal
              plan.
            </div>
          </div>
          <div className="grid__item col-1-2 mt">
            <div className="bo-b--1 border-color--muted pb---">
              <div className="font--h4">Plan Details</div>
            </div>
            <div className="mt- font--h5">
              Business - {scanLimit && scanLimit.toLocaleString()} scans/month,{' '}
              {Helpers.formatCost(priceInCents)}
              /month
            </div>
            <div className="mt-">
              <div className="link--obvious" onClick={() => this.showModal('DOWNGRADE')}>
                Downgrade to Personal plan
              </div>
              <div className="mt---">
                Questions?{' '}
                <a href="mailto:sales@sourceclear.com" className="link--obvious">
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
          <div className="grid__item col-1-2 mt">
            <div className="bo-b--1 border-color--muted pb---">
              <div className="font--h4">Payment Details</div>
            </div>
            <div className="mt- grid">
              <div className="grid__item">
                <div className="">Last Payment</div>
                <div className="mt--- font--h6">{Helpers.formatCost(totalInCents)}</div>
                <div className="mt---">Received {moment(date).format('MMM D, YYYY')}</div>
              </div>
              <div className="grid__item">
                <div className="">Payment Method</div>
                <div className="mt--- font--h6">
                  {cardType.toUpperCase()} **** {lastFour}
                </div>
                <div className="mt--- link--obvious" onClick={() => this.showModal('PAYMENT')}>
                  Update payment method
                </div>
              </div>
            </div>
          </div>
        </div>
        <SourceClearModal
          isOpen={showModal === 'PAYMENT'}
          title="Update Payment Method"
          onClose={() => this.showModal()}
          width={400}
        >
          <UpdatePaymentStripeForm
            onClose={() => this.showModal()}
            successCallback={res => this.updatePaymentCallback(res)}
          />
        </SourceClearModal>
        <SourceClearModal
          isOpen={showModal === 'DOWNGRADE'}
          title="Update Plan"
          onClose={() => this.showModal()}
          width={400}
        >
          <div className="">You are requesting to switch to the Personal plan.</div>
          <div className="mt-">
            If you downgrade, your plan benefits will expire at the end of your current billing
            cycle on <strong>{endOfMonth}</strong> and you will lose valuable insights into your
            code's vulnerabilities. If you need assistance,{' '}
            <a
              href="mailto:support@veracode.com?subject=Request For SourceClear Support"
              className="link--obvious"
            >
              let us help you
            </a>{' '}
            before you leave.
          </div>
          <div className="mt-">Do you still want to switch to the Personal plan?</div>
          <div className="flex flex--justify-content--end mt">
            <button className="pv- col-1-5 mr-" onClick={() => this.showModal()}>
              Cancel
            </button>
            <button
              className="btn--primary-clear pv- col-1-4 mr-"
              onClick={() => this.confirmDowngrade()}
            >
              Yes, switch
            </button>
          </div>
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    billingState: state.billingState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    billingActions: bindActionCreators(billingActions, dispatch),
    meActions: bindActionCreators(meActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentPage);
