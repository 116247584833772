import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  PROJECT_TYPES,
  PROJECT_TYPES_CLEAN_NAMES,
  languagePrettifiedNames,
  repoNameMaxLength,
} from '~/constants/ModelConstants';
import containerIcon from '~/images/icon_container_40x40.svg';
import databaseIcon from '~/images/icon_database_40x40.svg';
import { FavoriteRepoIcon } from '~/components/Icons';
import ReportExpandedScanErrorView from '~/components/ReportComponents/ReportExpandedScanErrorView';
import SuccessOrValueBox from '~/components/ReportComponents/SuccessOrValueBox';
import Tooltip from '~/components/Tooltip';
import Helpers from '~/utils/Helpers';

import * as quickviewActions from '~/actions/quickview';
import * as repoActions from '~/actions/repo';

import { ReportColumnWidths } from '~/components/ReportComponents/Report.types';

interface ProjectRowProps {
  quickviewState: object;
  repo: object;
  columnWidths: ReportColumnWidths;
  rowId: string;
  teamId: string;
}

class ProjectRow extends PureComponent<ProjectRowProps & ReturnType<typeof mapDispatchToProps>> {
  getQuickviewLinks(links) {
    if (!links) return;

    const { errors = {} } = links;
    return {
      errors: errors.href,
    };
  }

  toggleQuickview(quickviewId, isExpanded, quickviewLink) {
    if (isExpanded) {
      this.props.quickviewActions.loadQuickview(quickviewId, quickviewLink);
      this.props.quickviewActions.setCurrentOpenRow(quickviewId);
    } else {
      this.props.quickviewActions.setCurrentOpenRow('');
    }
  }

  onFavoriteClick = () => {
    const { repo } = this.props;
    const { id, favorite, _links } = repo;
    const { favorite: linkData = {} } = _links;
    const { href = '' } = linkData;

    if (favorite) {
      this.props.repoActions.unfavoriteRepo({ id, link: href });
    } else {
      this.props.repoActions.favoriteRepo({ id, link: href });
    }
  };

  onQuickviewPageClick(quickviewId, link, page) {
    this.props.quickviewActions.loadQuickview(quickviewId, link, page);
  }

  render() {
    const { repo, rowId, columnWidths = {}, teamId, quickviewState } = this.props;
    const {
      id,
      name,
      language,
      languages,
      type: projectType = '',
      vulnIssues = 0,
      libraryIssues = 0,
      licenseIssues = 0,
      lastScanDate,
      lastScanStatus,
      favorite,
      _links = {},
    } = repo;
    const repoLink = `/workspaces/${teamId}/projects/${id}/issues`;
    const { currentOpenRowId = '', dataByRowId = {}, isLoading = true } = quickviewState;
    const scanErrorQuickviewId = `${rowId}-scan-error`;
    const { [scanErrorQuickviewId]: scanErrorQuickviewData = {} } = dataByRowId;
    const { errors: scanErrorQuickviewLink } = this.getQuickviewLinks(_links);
    const repoNameText: string = _.startsWith(name, 'http')
      ? Helpers.leftCut(name, repoNameMaxLength)
      : Helpers.leftCut(Helpers.formatProjectName(name), repoNameMaxLength);
    const isProjectNameCut = Helpers.isProjectNameCut(repoNameText);
    const totalIssues = vulnIssues + libraryIssues + licenseIssues;

    /* eslint-disable react/no-danger */
    const projNameTooltipContent = Helpers.isProjectNameCut(repoNameText) ? (
      <div dangerouslySetInnerHTML={Helpers.formatBreakOnSlash(name)} />
    ) : (
      ''
    );

    const repoNameHtml = <p dangerouslySetInnerHTML={Helpers.formatBreakOnSlash(repoNameText)} />;
    /* eslint-enable react/no-danger */

    const projectIcon =
      projectType.toUpperCase() === PROJECT_TYPES.CONTAINER ? containerIcon : databaseIcon;
    const projectAltText =
      projectType.toUpperCase() === PROJECT_TYPES.CONTAINER ? 'Container' : 'Repo';

    //Make sure the display is always in the alphabetical order
    languages.sort();
    const prettifiedNames = languages.map(l => languagePrettifiedNames[l]);
    let prettifiedLanguageName = prettifiedNames.join(', ');

    const langTooltip = PROJECT_TYPES_CLEAN_NAMES[projectType.toUpperCase()] + ': ' + prettifiedLanguageName;

    if (prettifiedLanguageName.length > 14) {
      //Modify the language display
      prettifiedLanguageName = prettifiedLanguageName.substring(0,11) + "...";
    }

    return (
      <div className="bo--1 border-color--white-dark pl- pv-- mv---" data-e2e="report-row">
        <div className="grid grid--narrower grid--middle mv--- min-height--25">
          <div
            className={
              'grid__item font--h7 flex flex--align-items--center flex--justify-content--center ' +
              columnWidths.star
            }
            data-automation-id="RowCell"
          >
            <div className="-ml--">
              <FavoriteRepoIcon onClick={this.onFavoriteClick} isFavorite={favorite} />
            </div>
          </div>
          <div
            className={`grid__item  ${!isProjectNameCut && 'text--overflow'} ${columnWidths.name}`}
            data-automation-id="RowCell"
          >
            {isProjectNameCut && (
              <Tooltip content={projNameTooltipContent} id={`${rowId}-repoRow-repoName`}>
                <Link className={`link--obvious text--bold`} to={repoLink}>
                  {repoNameHtml}
                </Link>
              </Tooltip>
            )}
            {!isProjectNameCut && (
              <Link className={`link--obvious text--bold`} to={repoLink}>
                {repoNameHtml}
              </Link>
            )}
          </div>

          <div
            className={'grid__item color--muted-dark text--overflow ' + columnWidths.language}
            data-automation-id="RowCell"
          >
            {(prettifiedLanguageName && (
              <Tooltip
                id={`${rowId}-repoRow-LanguageName`}
                content={langTooltip}
                maxWidthClass="max-width--300"
                place="top"
              >
                <div className="flex align-items--center" id={rowId +"-languageText"}>
                  <div className="width--20 text--center flex align-items--center">
                    <img src={projectIcon} alt={projectAltText} className={`width--16 mr--`} />
                  </div>
                  {prettifiedLanguageName}
                </div>
              </Tooltip>
            )) ||
              '--'}
          </div>

          <div
            className={`grid__item flex justify-content--center ${columnWidths.totalIssues}`}
            data-automation-id="RowCell"
          >
            {lastScanStatus === 'ERROR' ? <div>--</div> : <SuccessOrValueBox num={totalIssues} />}
          </div>
          <div
            className={`grid__item flex justify-content--center ${columnWidths.vulnIssues}`}
            data-automation-id="RowCell"
          >
            {lastScanStatus === 'ERROR' ? <div>--</div> : <SuccessOrValueBox num={vulnIssues} />}
          </div>
          <div
            className={`grid__item flex justify-content--center ${columnWidths.libraryIssues}`}
            data-automation-id="RowCell"
          >
            {lastScanStatus === 'ERROR' ? <div>--</div> : <SuccessOrValueBox num={libraryIssues} />}
          </div>
          <div
            className={`grid__item flex justify-content--center ${columnWidths.licenseIssues}`}
            data-automation-id="RowCell"
          >
            {lastScanStatus === 'ERROR' ? <div>--</div> : <SuccessOrValueBox num={licenseIssues} />}
          </div>
          <div
            className={'grid__item color--muted-dark text--overflow ' + columnWidths.lastScanDate}
            data-automation-id="RowCell"
          >
            {lastScanStatus === 'ERROR' ? (
              <div className="flex flex--justify-content--space-between flex--align-items--center pr-">
                <div>{`${Helpers.formatDate(lastScanDate)}`}</div>
                <div
                  className="cursor--pointer"
                  onClick={() =>
                    this.toggleQuickview(
                      scanErrorQuickviewId,
                      currentOpenRowId !== scanErrorQuickviewId,
                      scanErrorQuickviewLink
                    )
                  }
                >
                  <i className="sci sci__alerts color--danger" />
                </div>
              </div>
            ) : (
              <div>{`${Helpers.formatDate(lastScanDate)}`}</div>
            )}
          </div>
        </div>

        {currentOpenRowId === scanErrorQuickviewId && (
          <div className="p- pb0">
            {_.isEmpty(scanErrorQuickviewData) && isLoading ? (
              <div className="mt">Loading...</div>
            ) : (
              <ReportExpandedScanErrorView
                data={scanErrorQuickviewData}
                onPageClick={(quickviewId, link, page) =>
                  this.onQuickviewPageClick(quickviewId, link, page)
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    quickviewState: state.quickviewState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    quickviewActions: bindActionCreators(quickviewActions as any, dispatch),
    repoActions: bindActionCreators(repoActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectRow);
