import { ScanUsage } from '~/reducers/usageState.types';

export const REQUEST_COMMITTERS = 'REQUEST_COMMITTERS';
export const RESET_USAGE_STATE = 'RESET_USAGE_STATE';
export const UPDATE_COMMITTERS_BY_ORG_ID = 'UPDATE_COMMITTERS_BY_ORG_ID';
export const UPDATE_COMMITTERS_BY_TEAM_ID = 'UPDATE_COMMITTERS_BY_TEAM_ID';
export const UPDATE_USAGE_FILTER = 'UPDATE_USAGE_FILTER';
export const UPDATE_USAGE_SORT_ASCENDING = 'UPDATE_USAGE_SORT_ASCENDING';
export const UPDATE_USAGE_SORT_COLUMN = 'UPDATE_USAGE_SORT_COLUMN';
export const FETCH_SCAN_USAGE_REQUEST = 'FETCH_SCAN_USAGE_REQUEST';
export const FETCH_SCAN_USAGE_SUCCESS = 'FETCH_SCAN_USAGE_SUCCESS';
export const FETCH_SCAN_USAGE_FAILURE = 'FETCH_SCAN_USAGE_FAILURE';

interface FetchScanUsageRequestAction {
  type: typeof FETCH_SCAN_USAGE_REQUEST;
}
interface FetchScanUsageSuccessAction {
  type: typeof FETCH_SCAN_USAGE_SUCCESS;
  scanUsage: Array<ScanUsage>;
}
interface FetchScanUsageFailureAction {
  type: typeof FETCH_SCAN_USAGE_FAILURE;
}

interface UpdateCommittersByOrgIdAction {
  type: typeof UPDATE_COMMITTERS_BY_ORG_ID;
  committers: any;
  orgId: string;
}

interface UpdateCommittersByTeamIdAction {
  type: typeof UPDATE_COMMITTERS_BY_TEAM_ID;
  committers: any;
  teamId: string;
}

interface UpdateUsageSortColumnAction {
  type: typeof UPDATE_USAGE_SORT_COLUMN;
  column: any;
}

interface UpdateUsageSortAscendingAction {
  type: typeof UPDATE_USAGE_SORT_ASCENDING;
  bool: boolean;
}
interface UpdateUsageFilterAction {
  type: typeof UPDATE_USAGE_FILTER;
  filter: any;
}

interface RequestCommittersAction {
  type: typeof REQUEST_COMMITTERS;
}

interface ResetUsageStateAction {
  type: typeof RESET_USAGE_STATE;
}

export type FetchScanUsageResponse = {
  _links: {
    [key: string]: any;
  };
  scanUsage: Array<ScanUsage>;
};

export type UsageActionTypes =
  | UpdateCommittersByOrgIdAction
  | UpdateCommittersByTeamIdAction
  | UpdateUsageSortColumnAction
  | UpdateUsageSortAscendingAction
  | UpdateUsageFilterAction
  | RequestCommittersAction
  | ResetUsageStateAction
  | FetchScanUsageRequestAction
  | FetchScanUsageSuccessAction
  | FetchScanUsageFailureAction;
