import React from 'react';
import Helpers from '~/utils/Helpers';

interface ScanMeterProps {
  plan: object;
  scanUsage: any[];
  isFetchingDashboard?: boolean;
}

const ScanMeter: React.FunctionComponent<ScanMeterProps> = props => {
  const { scanUsage = [], plan = {}, isFetchingDashboard } = props;
  const { scanLimit = 0 } = plan;
  const totalScans = Helpers.calculateScansUsed(scanUsage);
  const scanPercentage = isFetchingDashboard ? 0 : (totalScans / scanLimit) * 100;
  let meterColor = 'bg-color--success';
  if (scanPercentage >= 100) {
    meterColor = 'bg-color--danger';
  } else if (scanPercentage >= 80) {
    meterColor = 'bg-color--warning-light';
  }

  return (
    <div className="col-1-1 bg-color--white-dark position--relative height--25 flex align-items--center">
      <div className={`height--100p ${meterColor}`} style={{ width: `${scanPercentage}%` }} />
      <div className="position--absolute pl- text-shadow--1px">
        Monthly Scans: {totalScans && totalScans.toLocaleString()} /{' '}
        {scanLimit && scanLimit.toLocaleString()}
      </div>
    </div>
  );
};

export default ScanMeter;
