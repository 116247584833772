export const UPDATE_WORKSPACE_SCAN_DATE_VALUE = 'UPDATE_WORKSPACE_SCAN_DATE';
export const UPDATE_WORKSPACE_NEXT_SCAN_DATE_VALUE = 'UPDATE_WORKSPACE_NEXT_SCAN_DATE_VALUE';
export const TOGGLE_SCOPE_SCANS_TO_ALL_DATES = 'TOGGLE_SCOPE_SCANS_TO_ALL_DATES';

export const updateWorkspaceNextScanDateValue = value => ({
  type: UPDATE_WORKSPACE_NEXT_SCAN_DATE_VALUE,
  value,
});

export const updateWorkspaceScanDateValue = value => ({
  type: UPDATE_WORKSPACE_SCAN_DATE_VALUE,
  value,
});

export const toggleScopeScansToAllDates = toggle => ({
  type: TOGGLE_SCOPE_SCANS_TO_ALL_DATES,
  toggle,
});
