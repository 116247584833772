export enum VCAlertBoxLevels {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  SECURITY = 'SECURITY',
}

export interface VCAlertBoxProps {
  type: VCAlertBoxLevels;
  message: string;
  // indicates whether a close icon appears in the upper right portion of the alert box
  closeable?: boolean;
}
