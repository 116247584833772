import { policiesMap } from '~/constants/ModelConstants';

const PoliciesHelper = function () {
  const formatError = error => {
    const { field: fieldPath, type, description } = error;
    const fieldMeta = getFieldMetaByPath(fieldPath);
    const { name: fieldName } = fieldMeta;

    let formattedError;

    if (fieldName === 'parameters' && type === 'required') {
      formattedError = 'Parameters are required';
    } else {
      formattedError = description.replace(fieldName, policiesMap.FIELD[fieldName]);
     
      /* If it's a includes, ignore default JSON schema validation error and return a single error message for both 'minItems' / 'required' types */
      if (fieldName === 'includes' && (type ==='minItems' || type === 'required')) {
        formattedError = 'Please select at least one license name.';
      }

      // If it's a matcher, further replace likely occurrences of should_not_contain / should_be with their respective clean names.
      if (fieldName === 'matcher') {
        formattedError = formattedError.replace(
          'should_not_contain',
          `"${policiesMap.VALUE['should_not_contain']}"`
        );
        formattedError = formattedError.replace('should_be', `"${policiesMap.VALUE['should_be']}"`);
      }
    }

    return formattedError;
  };

  /**
   * getFieldMetaByPath accepts the field path in the form of 'a.b.c' to retrieve the field name, field parent and the original path.
   * This can be used to distinguish multiple fields of the same name by looking at the parent.
   * For instance, controls.0.condition.descriptor.parameters.severity (vulnerability) and controls.0.severity (issue).
   *
   * @param {string} fieldPath
   * 'condition.descriptor.parameters.severity'
   *
   * @returns {object}
   * {
   *   name: 'severity',
   *   parent: 'parameters',
   *   path: 'condition.descriptor.parameters.severity'
   * }
   */
  const getFieldMetaByPath = (fieldPath = '') => {
    if (!fieldPath) {
      return;
    }

    const fieldPathArr = fieldPath.split('.');
    const fieldMeta = {
      name: fieldPathArr[fieldPathArr.length - 1],
      parent: fieldPathArr.length > 1 ? fieldPathArr[fieldPathArr.length - 2] : '',
      path: fieldPath,
    };
    return fieldMeta;
  };

  return {
    formatError,
  };
};

export default new PoliciesHelper();
