import * as actions from '~/actions/stats';

const statsByProjectId = (state = {}, action) => {
  switch (action.type) {
    case actions.RECEIVE_FETCH_PROJECT_STATS_SUCCESS:
      return {
        ...state,
        [action.projectId]: {
          isFetching: false,
          hasError: false,
          data: action.stats,
        },
      };
    case actions.REQUEST_FETCH_PROJECT_STATS:
      return {
        ...state,
        [action.projectId]: {
          isFetching: true,
          hasError: false,
          data: (state[action.projectId] && state[action.projectId].data) || {},
        },
      };
    case actions.RECEIVE_FETCH_PROJECT_STATS_ERROR:
      return {
        ...state,
        [action.projectId]: {
          isFetching: false,
          hasError: true,
          data: (state[action.projectId] && state[action.projectId].data) || {},
        },
      };
    case actions.RESET_STATS_DATA:
      return {};
    default:
      return state;
  }
};

export default statsByProjectId;
