import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as orgActions from '~/actions/org';
import * as teamActions from '~/actions/team';
import * as startScanModalActions from '~/actions/startScanModal';
import * as vcApplicationActions from '~/actions/vcAppActions/vcApplicationActions';
import {
  StartScanModalState,
  VCMyState,
  VCNavState,
  VCApplicationsState,
} from '~/reducers/vcAppState/vcAppStateTypes/types';
import VCAlertBox from '~/views/components/veracode/VCAlertBox';
import { VCAlertBoxLevels } from '~/types/VCAlertBoxLevels.d.ts';
import ApplicationTable from "~/containers/ApplicationTable";

interface StartScanModalProps {
  orgActions: object;
  orgState: App.OrgState;
  startScanModalActions: object;
  startScanModalState: StartScanModalState;
  teamActions: object;
  vcApplicationLinkActions: object;
  vcApplicationState: VCApplicationsState;
  vcMyState: VCMyState;
  vcNavState: VCNavState;
}

class StartScanModal extends React.Component<StartScanModalProps, {}> {
  componentDidMount() {
    const { vcApplicationLinkActions, teamActions, vcMyState } = this.props;

    vcApplicationLinkActions.fetchVeracodeApplications();

    const { applications = [] } = vcMyState;

    if (!applications.length) {
      teamActions.fetchTeams();
    }
  }

  componentWillUnmount() {
    this.props.startScanModalActions.resetStartScanModalState();
  }

  render() {
    const {
      vcApplicationState: {
        applicationsState: { applications: options },
      },
      startScanModalState: { isSelectFromExisting, selectedApplication },
      startScanModalActions,
      vcMyState: { permissions },
    } = this.props;

    //checking that has applications or not
    const hasApplication = options.length > 1;

    const { scaCreateApplication } = permissions;
    const disableApplications =
      scaCreateApplication || !hasApplication ? !isSelectFromExisting : false;

    return (
      <div>
      <div className="grid">
        <div className="grid__item col-1-1">
          <div className="grid">
            {!hasApplication && !scaCreateApplication && (
              <div className="grid__item col-1-1 mb--">
                <VCAlertBox
                  type={VCAlertBoxLevels.WARNING}
                  message={
                    'There are no applications in your account available for scanning. Contact a user with the Administrator, Security Lead, or Creator role to assign an application to your team.'
                  }
                />
              </div>
            )}
            {scaCreateApplication && (
            <div className="grid__item col-1-1" id="applicationSelectOptions">
              <div className="grid__item col-1-1">Select an application to scan.</div>
              <div className="grid__item col-1-1 mt- flex ">
                <div className="grid__item col-1-3 ml+">
                  Application:
                  <span className="color--danger">*</span>
                </div>
                <div className="pl--- col-1-2 border-color--muted-light">
                    <div>
                      <label className="label--checkbox flex pv-- mb0">
                        <div className="flex flex--align-items--center">
                          <input
                            type="radio"
                            name="radio_start"
                            className="flex"
                            disabled={!scaCreateApplication}
                            onChange={() => {
                              // This could be done using local state.
                              startScanModalActions.createNewApplication();
                            }}
                            value="create"
                            defaultChecked={false}
                          />

                          <span className="react--radio" />
                        </div>

                        <div className="pl---">Create new</div>
                      </label>

                      <label className="label--checkbox flex pv-- mb0">
                        <div className="flex flex--align-items--center">
                          <input
                            type="radio"
                            name="radio_start"
                            className="flex"
                            onChange={() => {
                              startScanModalActions.selectFromExisting();
                            }}
                            value="select"
                            defaultChecked={false}
                            disabled={!hasApplication}
                          />

                          <span className="react--radio" />
                        </div>
                        <div className="pl---">Select from existing</div>
                      </label>
                    </div>

                </div>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
        {!disableApplications  && (
          <ApplicationTable />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgState: state.orgState,
    startScanModalState: state.startScanModalState,
    vcApplicationState: state.vcApplicationState,
    vcMyState: state.vcMyState,
    vcNavState: state.vcNavState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orgActions: bindActionCreators(orgActions, dispatch),
    startScanModalActions: bindActionCreators(startScanModalActions, dispatch),
    vcApplicationLinkActions: bindActionCreators(vcApplicationActions, dispatch),
    teamActions: bindActionCreators(teamActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StartScanModal);
