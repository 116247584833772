import ApiService from '~/utils/ApiService';
import ApiConstants from '~/constants/ApiConstants';
import ErrorService from '~/utils/ErrorService';
import Helpers from '~/utils/Helpers';
import { LicenseModel, LicenseResponse } from '~/types/license';

export const UPDATE_LICENSES = 'UPPDATE_LICENSES';
export const FETCH_LICENSES_REQUEST = 'FETCH_LICENSES_REQUEST';
export const FETCH_LICENSES_REQUEST_ERROR = 'FETCH_LICENSES_REQUEST_ERROR';

export const UPDATE_LICENSES_BY_RISK = 'UPDATE_LICENSES_BY_RISK';
export const FETCH_LICENSES_BY_RISK = 'FETCH_LICENSES_BY_RISK';
export const FETCH_LICENSES_BY_RISK_ERROR = 'FETCH_LICENSES_BY_RISK_ERROR';

/*
 It seems that this action does not need reducer as we fetch and use localstorage.
 Example:
   licenseAction.getLicense('APACHE20').then((license) => { });
   licenseAction.getLicenses(['APACHE20']).then((licenses) => { });
*/

export const fetchLicensesRequest = () => ({
  type: FETCH_LICENSES_REQUEST,
});

export const fetchLicenseFailure = error => ({
  type: FETCH_LICENSES_REQUEST_ERROR,
  message: error.message,
});

export const updatelicenses = licenses => ({
  type: UPDATE_LICENSES,
  licenses,
});

export const fetchLicenses = () => dispatch => {
  dispatch(fetchLicensesRequest());

  return ApiService.getV2<LicenseResponse>(ApiConstants.licenseURL)
    .then(licenseResponse => {
      const { licenses } =
        licenseResponse._embedded != undefined ? licenseResponse._embedded : { licenses: [] };
      const licenseList = licenses.map(license => {
        return {
          id: license.id,
          name: license.name,
          spdxId: license.spdxId,
        };
      });
      dispatch(updatelicenses(licenseList));
    })
    .catch(error => {
      dispatch(fetchLicenseFailure(error));
      ErrorService.capture('Error fetching licenses', error);
    });
};

export const fetchLicensesByRisk = riskType => dispatch => {
  dispatch(fetchingLicensesByRisk());

  const risk = Helpers.getRiskByKind(riskType);
  return ApiService.getV2<LicenseResponse>(`${ApiConstants.licenseURL}?risk=${risk}`)
    .then(licenseResponse => {
      const { licenses } =
        licenseResponse._embedded != undefined ? licenseResponse._embedded : { licenses: [] };
      const licenseList = licenses.map(license => {
        return {
          id: license.id,
          name: license.name,
          spdxId: license.spdxId,
        };
      });
      dispatch(updateLicensesByRisk(licenseList));
    })
    .catch(error => {
      dispatch(fetchLicenseByRiskFailure(error));
      ErrorService.capture('Error fetching licenses by risk', error);
    });
};

export const fetchingLicensesByRisk = () => ({
  type: FETCH_LICENSES_BY_RISK,
});

export const updateLicensesByRisk = licensesByRisk => ({
  type: UPDATE_LICENSES_BY_RISK,
  licensesByRisk,
});

export const fetchLicenseByRiskFailure = error => ({
  type: FETCH_LICENSES_BY_RISK_ERROR,
  message: error.message,
});

export interface LicenseMap {
  [license: string]: string;
}

export const getLicenses = (licenses: Array<string | undefined>) => {
  let promises = licenses.map(license => getLicense(license));
  return Promise.all(promises);
};

const getLicense = (license: string | undefined): Promise<LicenseMap> => {
  const key = 'license-' + license;
  const toLicenseMap = (key: string, value: string) => ({ [key]: value });
  if (!license) {
    return Promise.resolve(toLicenseMap(license, 'unknown license'));
  }
  return localStorage.getItem(key) != null
    ? Promise.resolve(toLicenseMap(license, localStorage.getItem(key)))
    : loadLicense(license).then(response => {
        localStorage.setItem(key, response.fullText);
        return toLicenseMap(license, response.fullText);
      });
};

export const loadLicense = (license: string) => {
  return ApiService.getV2<LicenseModel>('/licenses/' + license);
};
