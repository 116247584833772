import {
  vulnMethodsSupportedCoordTypes as supportedCoordTypes,
} from '~/constants/ModelConstants';

const VulnMethodSupportStatus = {
  isCoordTypeSupported: coordType => {
    const normalizedCoordType = coordType && coordType.toUpperCase();
    return supportedCoordTypes.includes(normalizedCoordType);
  },
};

export default VulnMethodSupportStatus;