export const ADD_MESSAGE = 'ADD_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export const addMessage = message => {
  return {
    type: ADD_MESSAGE,
    message,
  };
};

export const addMessageWithTimeout = (message, timeoutValue = 5000) => {
  return function (dispatch) {
    dispatch(addMessage(message));
    setTimeout(() => {
      dispatch(removeMessage(message.type));
    }, timeoutValue);
  };
};

export const removeMessage = messageType => {
  return {
    type: REMOVE_MESSAGE,
    messageType,
  };
};

export const clearMessages = () => {
  return {
    type: CLEAR_MESSAGES,
  };
};
