import produce from 'immer';
import { DEFAULT_WORKSPACE_SCAN_DATE } from '~/constants/ModelConstants';
import * as actions from '~/actions/workspaceScanDate';

interface WorkspaceScanDateState {
  scanDate: string;
  nextScanDate: 'last_7_days' | 'more_than_7_days' | null;
  scopeScansToAllDates: boolean;
}

const defaultState: WorkspaceScanDateState = {
  scanDate: DEFAULT_WORKSPACE_SCAN_DATE,
  nextScanDate: null,
  scopeScansToAllDates: false,
};

const workspaceScanDate = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.UPDATE_WORKSPACE_SCAN_DATE_VALUE:
        draft.scanDate = action.value;
        break;
      case actions.UPDATE_WORKSPACE_NEXT_SCAN_DATE_VALUE:
        draft.nextScanDate = action.value;
        break;
      case actions.TOGGLE_SCOPE_SCANS_TO_ALL_DATES:
        draft.scopeScansToAllDates = action.toggle;
    }
  });
};

export default workspaceScanDate;
