import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';
import queryString from 'query-string';
import classNames from 'classnames';

import ReportPopover from '~/containers/ReportPopover';
import ConfirmPasswordInputs from '~/containers/ConfirmPasswordInputs';
import Tooltip from '~/components/Tooltip';

import * as myActions from '~/actions/me';
import * as loginActions from '~/actions/login';
import * as passwordActions from '~/actions/password';
import { RouteComponentProps } from 'react-router-dom';

interface PersonalSettingsPageProps extends RouteComponentProps {
  myActions: object;
  loginActions: object;
  passwordActions: object;
  loginState: object;
  myState: App.MyState;
  orgState: App.OrgState;
  passwordInputs: object;
}

class PersonalSettingsPage extends React.Component<PersonalSettingsPageProps, {}> {
  componentDidMount() {
    const { location } = this.props;
    const { search } = location;
    const query = queryString.parse(search) || {};

    if (query['connect-error']) {
      this.props.myActions.toggleGithubConnectError();
    }
  }

  componentWillUnmount() {
    const { myState } = this.props;
    const { isFormDirty } = myState;

    if (isFormDirty) {
      this.props.myActions.fetchMe();
    }

    this.props.myActions.resetDeleteMeState();
  }

  submitUpdateUserForm(e) {
    e.preventDefault();

    const { myState, orgState } = this.props;
    const { org = {} } = orgState;
    const isSaml = org.samlDomains && !!org.samlDomains.length;
    const { me = {} } = myState;
    const { firstName = '', lastName = '', email } = me;
    const params = {
      firstName,
      lastName,
    };

    if (!isSaml) {
      params.email = email;
    }

    this.props.myActions.updateUser(params);
  }

  updateFirstName(e) {
    this.props.myActions.updateFirstName(e.target.value);
  }

  updateLastName(e) {
    this.props.myActions.updateLastName(e.target.value);
  }

  updateEmail(e) {
    this.props.myActions.updateEmail(e.target.value);
  }

  handleConfirmDeleteAccount = () => {
    const { history } = this.props;

    this.props.myActions.deleteMe().then(res => {
      if (res.success) {
        history.push('/login');
      }
    });
  };

  toggleChangePassword() {
    const { myState } = this.props;
    const { expandChangePassword } = myState;

    if (expandChangePassword) {
      this.props.myActions.updateCurrentPasswordInput(undefined);
      this.props.passwordActions.resetPasswordState();
    }
    this.props.myActions.toggleChangePassword();
  }

  submitChangePasswordForm() {
    const { passwordInputs, myState } = this.props;
    const { password = '', confirmPassword = '', passwordStrength = {} } = passwordInputs;
    const { score } = passwordStrength;
    const { currentPassword = '' } = myState;

    if (password.length < 8) {
      this.props.myActions.passwordError('Passwords must be at least 8 characters long.');
    } else if (score < 3) {
      this.props.myActions.passwordError(
        "The password you typed needs to be 'Strong' or 'Excellent'."
      );
    } else if (!password.length || !confirmPassword.length || !currentPassword.length) {
      this.props.myActions.passwordError('All fields are required.');
    } else if (password !== confirmPassword) {
      this.props.myActions.passwordError('The passwords you typed do not match.');
    } else {
      this.props.myActions.submitChangePasswordForm({
        currentPassword,
        password,
        confirmPassword,
      });
    }
  }

  clearInputErrors() {
    this.props.myActions.passwordError(undefined);
  }

  updateCurrentPasswordInput(e) {
    this.props.myActions.updateCurrentPasswordInput(e.target.value);
  }

  disconnectGithub() {
    this.props.myActions.disconnectGithub();
  }

  toggleAddPasswordLogin() {
    this.props.myActions.toggleAddPasswordLogin();
  }

  sendResetPasswordEmail(email) {
    this.props.loginActions.sendResetPasswordEmail(email);
  }

  render() {
    const { myState, orgState, loginState } = this.props;
    const { sendPasswordResetEmail } = loginState;
    const {
      me = {},
      updatingUser,
      updateError = {},
      expandChangePassword,
      passwordError,
      currentPassword = '',
      changingPassword,
      passwordChangeStatus = {},
      expandAddPasswordLogin,
    } = myState;
    const { org = {} } = orgState;
    const isSaml = org.samlDomains && !!org.samlDomains.length;
    const { firstName = '', lastName = '', email = '', passwordChangeDate } = me;
    const { status, error } = updateError;
    const { message } = passwordChangeStatus;
    const hasDeleteMeError = myState.deleteMeErrorMessage !== null;
    let errorToShow = undefined;

    if (passwordError) {
      errorToShow = <div className="color--danger mt-">{passwordError}</div>;
    } else if (message && message !== 'Password successfully changed.') {
      errorToShow = <div className="color--danger mt-">{message}</div>;
    }

    return (
      <section className="mb++">
        <Helmet>
          <title>Personal Profile</title>
        </Helmet>
        <div className="grid ml- mt">
          <div className="grid__item col-3-5">
            <form
              name="updateProfileForm"
              autoComplete="off"
              acceptCharset="UTF-8"
              onSubmit={e => this.submitUpdateUserForm(e)}
            >
              <div className="grid">
                <label htmlFor="firstName" className="grid__item col-1-4 pt-">
                  First name
                </label>
                <div className="grid__item col-3-4">
                  <input
                    id="firstName"
                    name="firstName"
                    className="col-1-1"
                    placeholder="First name"
                    type="text"
                    pattern=".{1,50}"
                    title="Names must be less than 50 characters"
                    value={firstName}
                    onChange={e => this.updateFirstName(e)}
                  />
                </div>
              </div>

              <div className="grid mt-">
                <label htmlFor="lastName" className="grid__item col-1-4 pt-">
                  Last name
                </label>
                <div className="grid__item col-3-4">
                  <input
                    id="lastName"
                    name="lastName"
                    className="col-1-1"
                    type="text"
                    placeholder="Last name"
                    pattern=".{1,50}"
                    title="Names must be less than 50 characters"
                    value={lastName}
                    onChange={e => this.updateLastName(e)}
                  />
                </div>
              </div>

              <div className="grid mt-">
                <label className="grid__item col-1-4 pt-" htmlFor="email">
                  Email
                </label>
                <div className="grid__item col-3-4">
                  <Tooltip
                    id="saml-email-tooltip"
                    active={isSaml}
                    content="SAML users cannot update their email addresses."
                    place="top"
                  >
                    <input
                      id="email"
                      type="email"
                      className="col-1-1"
                      required
                      value={email}
                      onChange={e => this.updateEmail(e)}
                      disabled={isSaml}
                    />
                  </Tooltip>
                </div>
              </div>

              <div
                className={`col-1-1 color--danger text--right ${
                  status === 409 ? 'is-showing-50' : 'is-hiding'
                }`}
              >
                <div className="mt--">That email is already associated with a user account.</div>
              </div>

              <div
                className={`col-1-1 color--danger text--right ${
                  error && error === 'SAML_REQUIRED' ? 'is-showing-50' : 'is-hiding'
                }`}
              >
                <div className="mt--">Sorry, that domain requires SAML.</div>
              </div>

              <div className="grid">
                <div className="grid__item col-1-1 text--right mt-">
                  <button className="btn btn--success col-1-4 pv- ph">
                    {updatingUser ? 'Updating profile' : 'Update profile'}
                  </button>
                </div>
              </div>
            </form>

            {!passwordChangeDate && !isSaml && (
              <div className="grid grid--middle mt- bo-t--1 border-color--white-dark">
                <div className="grid grid--full grid--middle mt- col-1-1 grid__item">
                  <div className="grid__item col-3-4">
                    <span className="font--h7">Add password login to your account</span>
                  </div>
                  <div className="grid__item col-1-4">
                    <button
                      className="col-1-1 pv- btn--default"
                      onClick={() => this.toggleAddPasswordLogin()}
                    >
                      {expandAddPasswordLogin ? 'Cancel' : 'Add password'}
                    </button>
                  </div>
                  <div
                    className={`grid__item col-1-1 text--right ${
                      expandAddPasswordLogin ? 'is-showing-200' : 'is-hiding'
                    }`}
                  >
                    <button
                      className="btn--primary p- mt-"
                      onClick={() => this.sendResetPasswordEmail(email)}
                    >
                      Send add password link to {email}
                    </button>
                  </div>
                  {sendPasswordResetEmail === 'SUCCESS' && (
                    <div className="grid__item mt- col-1-1 text--right">
                      Success! Please follow the link in your email.
                    </div>
                  )}
                  {sendPasswordResetEmail === 'FAILURE' && (
                    <div className="grid__item mt- col-1-1 text--danger flex flex--justify-content--end">
                      <div className="col-4-5">
                        Oops, something went wrong. Check your email address and try again. If you
                        need additional help, please{' '}
                        <a
                          href="mailto:support@veracode.com?subject=Request For SourceClear Support"
                          className="link--obvious"
                        >
                          contact support
                        </a>
                        .
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {passwordChangeDate && (
              <div className="grid mt- bo-t--1 border-color--white-dark">
                <div className="grid__item col-1-1 mt- grid grid--full">
                  <div className="grid__item col-3-4 pr- flex flex--align-items--center flex--justify-content--space-between">
                    <div className="font--h7">Change password</div>
                    {message === 'Password successfully changed.' && (
                      <div className="color--success font--h7">
                        Password changed successfully <i className="sci sci__check" />
                      </div>
                    )}
                  </div>
                  <div className="grid__item col-1-4">
                    <button
                      className="col-1-1 btn--default pv-"
                      onClick={() => this.toggleChangePassword()}
                    >
                      {expandChangePassword ? 'Cancel' : 'Change password'}
                    </button>
                  </div>

                  <div
                    className={`grid__item col-1-1 ${
                      expandChangePassword ? 'is-showing-200' : 'is-hiding'
                    }`}
                  >
                    <div className="grid mt-">
                      <div className="grid__item col-1-2">
                        <input
                          type="password"
                          className="col-1-1 control--text ph-"
                          name="currentPassword"
                          placeholder="Current password"
                          value={currentPassword}
                          onChange={e => this.updateCurrentPasswordInput(e)}
                          required
                        />
                        <ConfirmPasswordInputs
                          clearInputErrors={() => this.clearInputErrors()}
                          errorToShow={errorToShow}
                          passwordPlaceholderText="New password"
                        />
                      </div>
                      <div className="grid__item col-1-2 flex flex--justify-content--end flex--align-items--flex-end">
                        <button
                          className="col-1-2 pv- btn--primary"
                          onClick={() => this.submitChangePasswordForm()}
                        >
                          {changingPassword ? 'Saving...' : 'Save'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="grid mt- bo-t--1 pt- border-color--white-dark">
              <div className="grid grid--full grid__item col-1-1">
                <div className="grid__item col-3-4 flex flex--align-items--center font--h7">
                  Delete account
                </div>
                <div className="grid__item col-1-4">
                  <ReportPopover
                    id={`delete-account`}
                    buttonText={`Delete Account`}
                    buttonClass={`${
                      myState.isDeletingMe ? 'disabled cursor--not-allowed' : 'btn--default col-1-1'
                    }`}
                    confirmButtonText={`${myState.isDeletingMe ? 'Processing' : 'Confirm Delete'}`}
                    isButtonDisabled={myState.isDeletingMe ? true : false}
                    preferPlace="left"
                    place="left"
                    cancelButtonText={`${hasDeleteMeError ? 'OK' : 'Cancel'}`}
                    cancelButtonClass={`${myState.isDeletingMe ? 'invisible' : 'block'}`}
                    onConfirmClick={this.handleConfirmDeleteAccount}
                    closeOnConfirm={false}
                    confirmButtonClass={classNames({
                      'disabled cursor--not-allowed': myState.isDeletingMe,
                      invisible: hasDeleteMeError,
                      'btn--danger': !myState.isDeletingMe && !hasDeleteMeError,
                    })}
                    isConfirmButtonDisabled={myState.isDeletingMe ? true : false}
                    widthClass="max-width--500"
                  >
                    <section className="ph pt">
                      {!hasDeleteMeError && (
                        <div>
                          <h6>Delete Account</h6>
                          <p>
                            Are you sure you want to delete your account? If you delete your
                            account, the data from all your scans is also destroyed. If you belong
                            to a team with multiple users, no one will have access to scan results
                            from agents you created. Please contact the admin of your SourceClear
                            team or{' '}
                            <a
                              href="mailto:support@veracode.com?subject=Request For SourceClear Support"
                              className="link--obvious"
                            >
                              SourceClear support
                            </a>{' '}
                            before deleting your account if you are not sure.
                          </p>
                        </div>
                      )}
                      {hasDeleteMeError && (
                        <div>
                          <h6>Delete Account Failed</h6>
                          {myState.deleteMeErrorMessage && (
                            // but force it to a String, as React dislikes object-y things in elements
                            <b>{`${myState.deleteMeErrorMessage}`}</b>
                          )}
                          <p>Unable to delete your account, please contact support.</p>
                        </div>
                      )}
                    </section>
                  </ReportPopover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginState: state.loginState,
    myState: state.myState,
    orgState: state.orgState,
    passwordInputs: state.passwordInputs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    myActions: bindActionCreators(myActions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
    passwordActions: bindActionCreators(passwordActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalSettingsPage);
