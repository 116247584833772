import * as actions from '~/actions/dropdownMenu';

const defaultState = {
  openedDropdown: {},
};

const dropdownMenuState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.OPEN_DROPDOWN_MENU:
      return {
        openedDropdown: {
          ...state.openedDropdown,
          [action.dropdownId]: true,
        },
      };
    case actions.CLOSE_DROPDOWN_MENU:
      return {
        openedDropdown: {
          ...state.openedDropdown,
          [action.dropdownId]: false,
        },
      };
    case actions.RESET_DROPDOWN_MENU_STATE:
      return defaultState;
    default:
      return state;
  }
};

export default dropdownMenuState;
