import React from 'react';

interface TextInputProps {
  field: string;
  name: string;
  onChange: (...args: any[]) => any;
  value?: string;
  className?: string;
  disabled?: boolean;
}

const TextInput: React.FunctionComponent<TextInputProps> = props => {
  function onChange(e) {
    const { field } = props;
    const { target = {} } = e;
    const { value = '' } = target;

    props.onChange(field, value);
  }

  const { value, name, className, disabled } = props;

  return (
    <span>
      <input
        value={value}
        data-e2e={`${name}-input`}
        type="text"
        name={name}
        className={`flex flex-grow--1 ${className}`}
        onChange={onChange}
        disabled={disabled}
      />
    </span>
  );
};

export default TextInput;
