import React from 'react';
import classNames from 'classnames';

interface VCLogoutProps {
  onClick: () => void;
  url: string;
  label: string;
  disabled: boolean;
}

export function VCLogout(props: VCLogoutProps) {
  const { url, disabled, label, onClick } = props;
  return (
    <li>
      <a href={url} className={classNames({ disabled })} onClick={() => onClick()}>
        {label}
      </a>
    </li>
  );
}
