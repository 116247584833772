export const OPEN_POPOVER = 'OPEN_POPOVER';
export const CLOSE_POPOVER = 'CLOSE_POPOVER';

export const openPopover = id => {
  return {
    type: OPEN_POPOVER,
    id,
  };
};
export const closePopover = id => {
  return {
    type: CLOSE_POPOVER,
    id,
  };
};
