import React from 'react';
import Select from 'react-select';

interface AddInviteesToGroupProps {
  groupData?: object;
  selectableGroups: any[];
  selectedGroup?: boolean | object;
  invitees: any[];
  updateSelectedGroup: (...args: any[]) => any;
  toggleAdminField: (...args: any[]) => any;
  hideGroupSelection?: boolean;
}
const AddInviteesToGroup: React.FunctionComponent<AddInviteesToGroupProps> = props => {
  const { selectableGroups = [], selectedGroup, invitees = [], hideGroupSelection } = props;

  function updateSelectedGroup(group) {
    if (selectedGroup && selectedGroup.value) {
      props.updateSelectedGroup(selectedGroup.value);
    }

    if (group) {
      props.updateSelectedGroup(group.value);
    }
  }

  function toggleAdminField(userId) {
    props.toggleAdminField(userId);
  }

  return (
    <div className="grid">
      {!hideGroupSelection && (
        <div className="mb">
          <div className="grid__item col-1-1 flex justify-content--end align-items--end mb-">
            Add invitees to a team to give them access to workspaces linked to the team. Org admins
            can access everything and will not be added to the team.
          </div>
          <div className="grid__item col-1-1 mt-">
            <Select
              options={selectableGroups}
              placeholder="Type to choose team"
              name="group"
              value={selectableGroups.find(option => option.value === selectedGroup)}
              isClearable={true}
              onChange={group => updateSelectedGroup(group)}
              className={'srcclr-react-select-container'}
              classNamePrefix={'srcclr-react-select'}
            />
          </div>
        </div>
      )}
      <div className="grid__item col-1-1">
        <div>
          <strong className="font--h7 color--muted-dark">
            Optional: Choose Team Administrators
          </strong>
        </div>
        <div className="mt-">
          Users can be members or administrators of a team. Team administrators can invite or add
          users remove users, and make other users team administrators.
        </div>
      </div>
      <div className="grid__item col-1-1 mt-">
        <div className="bo--1 border-color--muted-dark pv-">
          <div className="grid grid--full">
            <div className="grid__item col-1-2 pl-">
              <strong>Email</strong>
            </div>
            <div className="grid__item col-1-2 text--center">
              <strong>Team Administrator</strong>
            </div>
          </div>
          <div className="max-height--150 overflow--auto">
            {invitees.length === 0 ? (
              <div className="ml- mt--">No users available</div>
            ) : (
              invitees.map((invitee, index) => {
                const { email, groupAdmin, stagedAdminStatus } = invitee;

                return (
                  <div className="grid grid--full pv-- hover-row" key={`selected-${index}`}>
                    <div className="grid__item col-1-2 pl-">{email}</div>
                    <div className="grid__item col-1-2 flex align-items--center justify-content--center">
                      <label className="label--checkbox justify-content--center flex mb0">
                        <input
                          type="checkbox"
                          checked={groupAdmin || stagedAdminStatus || false}
                          onChange={() => toggleAdminField(email)}
                        />
                        <span className="react--checkbox mr0" />
                      </label>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInviteesToGroup;
