import React from 'react';
import CopyToClipboard from '~/containers/CopyToClipboard';

interface PackageManagerGoVendorProps {
  component: object;
  safeCommitHash?: string;
}

const PackageManagerGoVendor: React.FunctionComponent<PackageManagerGoVendorProps> = props => {
  const { component, safeCommitHash } = props;

  return (
    <div className="pt-">
      <div className="grid pt">
        <span className="grid__item max-width--30 bg-color--white-dark pv- ph- text--bold">1.</span>
        <span className="grid__item pv- pl--">Type the following to install the fix commit</span>
      </div>
      <div className="pt-- -ml mt-">
        <CopyToClipboard
          value={`govendor fetch ${component.name}@${safeCommitHash}`}
          id="PackageManagerGoVendor"
        />
      </div>
    </div>
  );
};

export default PackageManagerGoVendor;
