import React from 'react';

interface ZeroIssuesPanelProps {
  repos: number;
}

const ZeroIssuesPanel: React.FunctionComponent<ZeroIssuesPanelProps> = props => {
  const { repos } = props;

  return (
    <div className="bo--1 border-color--muted bg-color--white-light p">
      <div className="grid">
        <div className="grid__item col-1-1 text--center">
          <h3 className="font--h3">Nice work 🎉</h3>
        </div>
        <div className="grid__item col-1-1 text--center mt--">
          <div className="font--h6">
            {repos !== 1 ? (
              <span>
                {' '}
                You have zero Issues across {repos} project
                {repos !== 1 && 's'}.
              </span>
            ) : (
              <span> You have zero Issues in this project.</span>
            )}
          </div>
        </div>
        <div className="grid__item col-1-1 text--center mt--">
          <div className="font--h6">Issues will appear here as projects are scanned.</div>
        </div>
      </div>
    </div>
  );
};

export default ZeroIssuesPanel;
