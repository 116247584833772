import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as teamActions from '~/actions/team';

import CreateWorkspaceModal from '~/containers/CreateWorkspaceModal';
import SourceClearModal from '~/components/SourceClearModal';

interface ZeroWorkspacesPageProps {
  orgState: App.OrgState;
  teamState: object;
  teamActions: {
    showModal: (modalType: string) => void;
  };
}
class ZeroWorkspacesPage extends React.Component<ZeroWorkspacesPageProps, {}> {
  showModal(modalType: string) {
    this.props.teamActions.showModal(modalType);
  }

  render() {
    const { orgState, teamState } = this.props;
    const { org } = orgState;
    const { permissions = {} } = org;
    const { createTeams } = permissions;
    const { showModal } = teamState;

    return (
      <div className="col-1-1">
        <div className="grid grid--center mt++">
          <div className="grid__item col-2-5 flex align-items--center">
            {createTeams ? (
              <div className="ml">
                <div className="font--h5">
                  This organization does not have any
                  <br />
                  workspaces.
                </div>
                <div className="mt-">
                  <button
                    className="btn--primary p-"
                    onClick={() => this.showModal('CREATE_WORKSPACE')}
                  >
                    Create Workspace
                  </button>
                </div>
              </div>
            ) : (
              <div className="font--h5 ml">
                You are not a member of any workspaces. Contact your administrator to be added.
              </div>
            )}
          </div>
          <SourceClearModal
            isOpen={showModal === 'CREATE_WORKSPACE'}
            onClose={showModal => this.showModal(showModal)}
            title="Create Workspace"
            width={500}
          >
            <CreateWorkspaceModal
              showModal={modalType => this.showModal(modalType)}
              showGroups={false}
            />
          </SourceClearModal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgState: state.orgState,
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    teamActions: bindActionCreators(teamActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ZeroWorkspacesPage);
