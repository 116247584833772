import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, AnyAction, Dispatch } from 'redux';
import { Link } from 'react-router-dom';

import config from '~/config';
import * as loginActions from '~/actions/login';

interface OrgLoginPageProps {
  loginActions: object;
  loginState: App.LoginState;
}

class OrgLoginPage extends React.Component<OrgLoginPageProps> {
  updateVanityDomain(e) {
    const { value } = e.target;

    this.props.loginActions.updateVanityDomain(value);
  }

  continueToOrgDomain(e) {
    e.preventDefault();
    const { loginState } = this.props;
    const { vanityDomain } = loginState;

    this.props.loginActions.checkOrgDomain(vanityDomain).then(res => {
      if (res && !res.available) {
        const redirectUrl = `${
          config.FRONTEND_HOST_URL
            ? config.FRONTEND_HOST_URL.replace('/app.', `/${vanityDomain}.`)
            : ''
        }/login`;
        window.location.href = redirectUrl;
      }
    });
  }

  render() {
    const { loginState } = this.props;
    const { vanityDomain = '', orgDomainRedirectError } = loginState;

    return (
      <div className="grid mv++">
        <div className="grid__item col-3-10 flex align-items--center justify-content--end">
          <i className="sci sci__circle--check color--primary-light font--150" />
        </div>
        <div className="grid__item col-4-10">
          <form className="grid" onSubmit={e => this.continueToOrgDomain(e)}>
            <div className="grid__item col-1-1">
              <h3 className="font-family--simple">Sign In</h3>
            </div>

            <div className="grid__item col-1-1 mt-">
              <div className="mt-- font--h7">Enter your personalized URL:</div>
            </div>

            <div className="grid__item col-1-1 mt-- flex">
              <div className="col-3-4 flex align-items--center">
                <input
                  type="text"
                  className="col-1-1 font--h7 control--text ph-- text--right"
                  name="vanityDomain"
                  value={vanityDomain}
                  autoFocus
                  onChange={e => this.updateVanityDomain(e)}
                  placeholder="company"
                  data-e2e={'OrgLoginPage-orgNameInput'}
                />
              </div>
              <div className="flex align-items--center font--h7 ph--- text--bold">
                .{config.FRONTEND_HOST_URL && config.FRONTEND_HOST_URL.split('/app.')[1]}
              </div>
            </div>
            <div
              className={`grid__item col-1-1 ${
                orgDomainRedirectError ? 'is-showing-50' : 'is-hiding'
              }`}
            >
              <div className="mt-- color--danger">
                <strong>We couldn't find your organization.</strong> If you can't remember your
                organization's address, we can{' '}
                <Link to="/find-my-org" className="link--obvious">
                  send you a reminder
                </Link>
                .
              </div>
            </div>
            <div className="grid__item col-1-1 mt-">
              <button
                className="btn--primary pv- col-1-1 flex justify-content--center align-items--center"
                type="submit"
                data-e2e={'OrgLoginPage-continueButton'}
              >
                <div className="font--h7 pr">Continue</div>
                <i className="sci sci__arrow--right" />
              </button>
            </div>
            <div className="grid__item col-1-1 mt- flex">
              <div className="font--h7 pr--">Don't know your organization's URL?</div>
              <Link className="link--obvious font--h7" to="/find-my-org">
                Get a reminder
              </Link>
              .
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginState: state.loginState as App.LoginState,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgLoginPage);
