import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helpers from '~/utils/Helpers';
import * as navigationActions from '~/actions/navigation';
import SidebarNavOption from '~/components/SidebarNavOption';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import { FEATURE_SLUG_MAP } from '~/constants/ModelConstants';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import { RouteComponentProps } from 'react-router-dom';

interface VCSettingsNavOptionsProps extends RouteComponentProps {
  navigationActions: object;
  navigationState: object;
  myState: App.MyState;
  orgState: App.OrgState;
}

const VCSettingsNavOptions: React.FunctionComponent<VCSettingsNavOptionsProps> = props => {
  const { location, orgState, myState } = props;
  const { org } = orgState;
  const { permissions: orgPermissions } = org;
  const { pathname = '' } = location;
  const navOptions = [];

  const { integrations, agents, usage: orgUsage } = orgPermissions;

  const showAgents = FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.ORG_AGENTS, org);
  const isOrgEnterpriseOrTrial = OrgPaidStatus.isOrgEnterpriseOrTrial(org);

  navOptions.push({
    title: 'My Notifications',
    toLocation: '/settings/notifications',
    isActive: pathname.includes(`settings/notifications`),
  });

  if (agents && (isOrgEnterpriseOrTrial || showAgents)) {
    navOptions.push({
      title: 'Agents',
      toLocation: '/org/settings/agents',
      isActive: pathname.includes('/agents'),
      separator: true,
    });
  }

  if (orgUsage) {
    navOptions.push({
      title: 'Usage',
      toLocation: '/org/settings/usage',
      isActive: pathname.includes('/usage'),
    });
  }
  if (Helpers.hasEnforceOrgLevelRulesPermissions(myState))
    navOptions.push({
      title: 'Rules',
      toLocation: '/org/settings/rules',
      isActive: pathname.includes('/rules'),
    });

  if (integrations) {
    navOptions.push({
      title: 'Integrations',
      toLocation: `/org/settings/integrations`,
      isActive: pathname.includes(`/integrations`),
    });
  }

  if (Helpers.hasLibraryCatalogEnabled()) {
    navOptions.push({
      title: 'Commercial Licenses',
      toLocation: `/org/settings/library-catalog`,
      isActive: pathname.includes(`/library-catalog`),
    });
  }

  return (
    <div className="flex flex--flex-direction--column" data-automation-id="OrganizationNav">
      <div className="mt pl- pb- font--h6 pr-" data-automation-id="OrganizationNav-Heading">
        Agent-Based Scan Settings
      </div>
      <div className="col-1-1 pl- pv-- pr-">
        <div className="bo-b--1 border-color--muted" />
      </div>
      {navOptions.map(option => (
        <SidebarNavOption key={option.title} option={option} fontSize="font--h7" />
      ))}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    navigationState: state.navigationState,
    myState: state.myState,
    orgState: state.orgState,
    vcMyState: state.vcMyState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigationActions: bindActionCreators(navigationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VCSettingsNavOptions);
