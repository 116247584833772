import React, { FunctionComponent } from 'react';
import Tooltip from '~/components/Tooltip';

import { ReportRepoBranchTagDisplayProps } from '~/components/ReportComponents/ReportIssue.types';

const ReportRepoBranchTagDisplay: FunctionComponent<ReportRepoBranchTagDisplayProps> = props => {
  const {
    rowId,
    branch,
    tag,
    className = '',
    scmHref = '',
    iconClassName = '',
    contentClassName = '',
  } = props;

  const branchTagClass = (branch && 'fa-code-fork') || (tag && 'fa-tag font--11 top--3') || '';
  let branchTagLabel = branch || tag || '--';
  let branchTagContent = '--';

  if (scmHref) {
    if (branch || tag) {
      branchTagContent = `${scmHref}/tree/${branch || tag}`;
    }
  }

  const maybeTagTitle = tag ? 'Tag' : '';
  const iconTitle = branch ? 'Branch' : maybeTagTitle;

  return (
    <div className={`position--relative max-width--130 ${className}`}>
      <i className={`fa ${branchTagClass} ${iconClassName} position--absolute`} title={iconTitle} />
      <Tooltip
        fullWidth={true}
        id={`${rowId}-issueRow`}
        place="top"
        content={branchTagContent}
        className="break-word"
      >
        <span className={`${(branch || tag) && 'pl-'} block text--overflow ${contentClassName}`}>
          {branchTagLabel}
        </span>
      </Tooltip>
    </div>
  );
};

export default ReportRepoBranchTagDisplay;
