import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';

import * as toastrActions from '~/actions/toastr';
import SourceClearLoader from '~/components/SourceClearLoader';
import ApiService from '~/utils/ApiService';
import ApiConstants from '~/constants/ApiConstants';

interface JiraIntegrationSetupCallbackProps extends RouteComponentProps {
  toastrActions: object;
}
class JiraIntegrationSetupCallback extends React.Component<JiraIntegrationSetupCallbackProps, {}> {
  componentDidMount() {
    const { match = {}, location = {}, history } = this.props;
    const { params = {} } = match;
    const { setupId = '' } = params;

    const { search = '' } = location;
    const { oauth_token = '', oauth_verifier = '' } = queryString.parse(search);

    ApiService.post(
      ApiConstants.jiraIntegrationSetupCallbackURL(setupId, oauth_token, oauth_verifier)
    )
      .then(res => {
        history.push(`/org/settings/integrations/jira/${res.id}?openTicketTemplate=true`);
        this.props.toastrActions.clearToastrs();
        this.props.toastrActions.addToastr({
          id: 'JIRA-VERIFIED',
          level: 'success',
          title: 'Integration Successfully Created',
          message:
            'Next, you can create an issue template which defines how fields are ' +
            'displayed to users when they create an issue.',
          position: 'topCenter',
        });
      })
      .catch(() => {
        history.push('/org/settings/integrations');
        this.props.toastrActions.clearToastrs();
        this.props.toastrActions.addToastr({
          id: 'JIRA-UNVERIFIED',
          level: 'error',
          title: 'Integration Canceled',
          message: 'Your integration setup was not completed.',
          position: 'topCenter',
        });
      });
  }

  render() {
    return (
      <div className="flex height--75vh">
        <SourceClearLoader />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toastrActions: bindActionCreators(toastrActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JiraIntegrationSetupCallback);
