import * as actions from '~/actions/reports';

const reportsPDF = (state = {}, action) => {
  switch (action.type) {
    case actions.FETCH_REPORT_PDF_REQUEST:
      return {
        ...state,
        [action.teamId]: {
          isFetching: true,
          error: '',
        },
      };
    case actions.FETCH_REPORT_PDF_SUCCESS:
      return {
        ...state,
        [action.teamId]: {
          isFetching: false,
          error: '',
        },
      };
    case actions.FETCH_REPORT_PDF_FAILURE:
      return {
        ...state,
        [action.teamId]: {
          isFetching: false,
          error: action.message,
        },
      };
    default:
      return state;
  }
};

export default reportsPDF;
