import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import { isMSAAccepted, isTrialExpiredPlanChanged } from '~/utils/NavHelpers';
import Helpers from '~/utils/Helpers';
import config from '~/config';

export const ADD_TEAM_TO_NAV = 'ADD_TEAM_TO_NAV';
export const COLLAPSE_TEAM_MENUS = 'COLLAPSE_TEAM_MENUS';
export const CLOSE_WORKSPACE_DROPDOWN = 'CLOSE_WORKSPACE_DROPDOWN';
export const OPEN_WORKSPACE_DROPDOWN = 'OPEN_WORKSPACE_DROPDOWN';
export const EXPAND_SEARCH_BAR = 'EXPAND_SEARCH_BAR';
export const TOGGLE_WORKSPACE_MANAGEMENT = 'TOGGLE_WORKSPACE_MANAGEMENT';
export const TOGGLE_SETTINGS_DROPDOWN = 'TOGGLE_SETTINGS_DROPDOWN';
export const TOGGLE_SUB_OPTION = 'TOGGLE_SUB_OPTION';
export const UPDATE_ACTIVE_STATE_AND_PARAMS = 'UPDATE_ACTIVE_STATE_AND_PARAMS';
export const UPDATE_ACTIVE_TEAM = 'UPDATE_ACTIVE_TEAM';
export const UPDATE_ACTIVE_TEAM_PARENT = 'UPDATE_ACTIVE_TEAM_PARENT';
export const UPDATE_TEAM_REPORTS = 'UPDATE_TEAM_REPORTS';
export const UPDATE_USER_AUTH = 'UPDATE_USER_AUTH';
export const UPDATE_ACTIVE_TOUR_TAB = 'UPDATE_ACTIVE_TOUR_TAB';
export const TOGGLE_RESOURCES_DROPDOWN = 'TOGGLE_RESOURCES_DROPDOWN';
export const CLOSE_RESOURCES_DROPDOWN = 'CLOSE_RESOURCES_DROPDOWN';
export const UPDATE_NEWSLETTER_VALIDITY = 'UPDATE_NEWSLETTER_VALIDITY';
export const REQUEST_NEWSLETTER_SIGNUP = 'REQUEST_NEWSLETTER_SIGNUP';
export const RECEIVE_NEWSLETTER_SIGNUP_SUCCESS = 'RECEIVE_NEWSLETTER_SIGNUP_SUCCESS';
export const UPDATE_NEWSLETTER_SIGNUP_ERROR = 'UPDATE_NEWSLETTER_SIGNUP_ERROR';
export const UPDATE_FULL_WIDTH_STATUS = 'UPDATE_FULL_WIDTH_STATUS';
export const UPDATE_SIDEBAR_STATUS = 'UPDATE_SIDEBAR_STATUS';
export const RESET_NEWSLETTER_FORM = 'RESET_NEWSLETTER_FORM';
export const UPDATE_ACTIVE_REPORT_TYPE = 'UPDATE_ACTIVE_REPORT_TYPE';
export const UPDATE_PREVIOUS_STATE = 'UPDATE_PREVIOUS_STATE';
export const UPDATE_PERSONAL_SETTINGS_STATUS = 'UPDATE_PERSONAL_SETTINGS_STATUS';

export const updatePreviousState = prevState => ({
  type: UPDATE_PREVIOUS_STATE,
  prevState,
});

export const updateActiveReportType = (reportType?: string) => {
  return {
    type: UPDATE_ACTIVE_REPORT_TYPE,
    reportType,
  };
};

export const updateActiveTourTab = tab => {
  return {
    type: UPDATE_ACTIVE_TOUR_TAB,
    tab,
  };
};

export const updateSidebarStatus = (status = {}) => ({
  type: UPDATE_SIDEBAR_STATUS,
  status,
});

export const updateFullWidthStatus = (status = false) => ({
  type: UPDATE_FULL_WIDTH_STATUS,
  status,
});

export const updatePersonalSettingsStatus = status => ({
  type: UPDATE_PERSONAL_SETTINGS_STATUS,
  status,
});

export const expandSearchBar = bool => {
  return {
    type: EXPAND_SEARCH_BAR,
    bool,
  };
};

export const collapseTeamMenus = () => {
  return {
    type: COLLAPSE_TEAM_MENUS,
  };
};

export const toggleActiveTeam = team => {
  return {
    type: UPDATE_ACTIVE_TEAM,
    team: team,
  };
};

export const toggleSubOption = (teamId, option) => {
  return {
    type: TOGGLE_SUB_OPTION,
    teamId,
    option,
    meta: {
      snowplow: true,
    },
  };
};

export const updateActiveStateAndParams = (activeState, activeParams) => {
  return {
    type: UPDATE_ACTIVE_STATE_AND_PARAMS,
    activeState,
    activeParams,
  };
};

export const updateActiveTeamParent = teamId => {
  return {
    type: UPDATE_ACTIVE_TEAM_PARENT,
    teamId,
    meta: {
      snowplow: true,
    },
  };
};

export const createNewTeam = name => {
  return function (dispatch) {
    return ApiService.post('/teams', name).then(function (res) {
      return dispatch(addTeamToNav(res));
    });
  };
};

export const addTeamToNav = team => {
  return {
    type: ADD_TEAM_TO_NAV,
    team,
  };
};

export const checkUserAuth = () => {
  return function (dispatch, getState) {
    const isLoggedIn = getState().myState.isLoggedIn;
    return {
      type: UPDATE_USER_AUTH,
      isLoggedIn,
    };
  };
};

export const toggleSettingsDropdown = () => {
  return {
    type: TOGGLE_SETTINGS_DROPDOWN,
  };
};

export const toggleResourcesDropdown = () => {
  return {
    type: TOGGLE_RESOURCES_DROPDOWN,
  };
};

export const closeResourcesDropdown = () => {
  return {
    type: CLOSE_RESOURCES_DROPDOWN,
  };
};

export const updateNewsletterValidity = bool => {
  return {
    type: UPDATE_NEWSLETTER_VALIDITY,
    bool,
  };
};

export const requestNewsletterSignup = () => {
  return {
    type: REQUEST_NEWSLETTER_SIGNUP,
  };
};

export const receiveNewsletterSignupSuccess = () => {
  return {
    type: RECEIVE_NEWSLETTER_SIGNUP_SUCCESS,
    meta: {
      snowplow: true,
    },
  };
};

export const receiveNewsletterSignupError = error => {
  return {
    type: UPDATE_NEWSLETTER_SIGNUP_ERROR,
    error,
    meta: {
      snowplow: true,
    },
  };
};

export const submitNewsletterSignup = email => {
  return function (dispatch) {
    dispatch(requestNewsletterSignup());

    ApiService.post('/newsletter/signup', { data: { email: email } })
      .then(() => {
        dispatch(receiveNewsletterSignupSuccess());
      })
      .catch(err => {
        dispatch(receiveNewsletterSignupError(err.message));
      });
  };
};

export const resetNewsletterForm = () => {
  return {
    type: RESET_NEWSLETTER_FORM,
  };
};

export const openWorkspaceDropdown = () => ({
  type: OPEN_WORKSPACE_DROPDOWN,
});

export const closeWorkspaceDropdown = () => ({
  type: CLOSE_WORKSPACE_DROPDOWN,
});

export const toggleWorkspaceManagement = bool => ({
  type: TOGGLE_WORKSPACE_MANAGEMENT,
  bool,
});

export const proceedToKibanaDashboard = () => () => {
  return ApiService.post(`/dashboard-token`)
    .then(res => {
      window.location = `${config.API_URL}/dashboard-token/${res.token}`;
    })
    .catch(err => {
      ErrorService.capture('Error in retrieving dashboard token', err);
    });
};

export const handleLocationChange = (location, history) => (dispatch, getState) => {
  const { pathname } = location;
  const { orgState, myState, teamState } = getState();
  const { teams = [] } = teamState;
  const { me = {} } = myState;
  const eula = me.eula || {};
  const { org = {}, isOrgSlugInvalid } = orgState;
  const { welcomeMode, permissions = {} } = org;
  const { billing } = permissions;
  const msaAccepted = isMSAAccepted(eula);

  if (isOrgSlugInvalid && pathname !== '/404') {
    history.replace('/404');
  }

  if (me.id && !pathname.includes('terms') && eula.currentVersion && !msaAccepted) {
    history.replace('/terms');
  } else if (me.id && msaAccepted) {
    if (!pathname.includes('trial-expired') && isTrialExpiredPlanChanged(org)) {
      history.replace('/trial-expired');
    } else if (!pathname.includes('account-locked') && status && status === 'PAYMENT_REQUIRED') {
      history.replace(billing ? '/org/settings/account-locked' : '/account-locked');
    } else if (
      !pathname.includes('welcome') &&
      (pathname.includes('/teams/') || pathname.includes('/workspaces/')) &&
      welcomeMode &&
      teams.length &&
      Helpers.hasManageAgentPermissions({ teams: teams[0] })
    ) {
      // When in welcome mode, send user to /welcome when the first of workspaces is administered by the user
      history.replace('/portfolio');
    } else if (pathname.startsWith('/vcintegration')) {
      return;
    } else if (pathname.startsWith('/start-scan')) {
      return;
    } else if (
      !pathname.includes('no-workspaces') &&
      !pathname.includes('settings') &&
      !teams.length
    ) {
      history.replace(`/no-workspaces`);
    }
  }
};
