import React from 'react';

interface ReportDetailsPageUpdateAdvisorAlertProps {
  data?: object;
}
const ReportDetailsPageUpdateAdvisorAlert: React.FunctionComponent<ReportDetailsPageUpdateAdvisorAlertProps> = props => {
  const { data } = props;
  //known status
  const status = {
    ERROR: 'ERROR',
    PENDING: 'PENDING',
    COMPLETE: 'COMPLETE',
  };

  //Handle null/undefined - ie. Case of no link/href provided, this means it is not applicable
  if (!data) {
    return <span>Not Applicable</span>;
  }

  const { deltaScore, deltaSeverity, deltaStatus } = data;

  //Handle ERROR or status other than known status
  if (
    deltaStatus === status.ERROR ||
    (deltaStatus !== status.PENDING && deltaStatus !== status.COMPLETE)
  ) {
    return <span />;
  }

  //Handle PENDING
  if (deltaStatus === status.PENDING) {
    return <span>Pending</span>;
  }

  //Handle COMPLETE
  let severityClass = '';
  let severityText = '';
  let severityTextColor = '';

  if (!deltaSeverity) {
    severityText = '--';
  } else {
    const normalizedSeverity = deltaSeverity.toLowerCase();

    if (normalizedSeverity === 'high') {
      severityClass = 'bg-color--danger-high border-color--danger';
      severityText = 'High Risk';
      severityTextColor = 'color--danger-high';
    } else if (normalizedSeverity === 'medium') {
      severityClass = 'bg-color--warning border-color--warning';
      severityText = 'Medium Risk';
      severityTextColor = 'color--warning';
    } else if (normalizedSeverity === 'low') {
      severityClass = 'bg-color--warning-light border-color--warning-light';
      severityText = 'Low Risk';
      severityTextColor = 'color--warning-light';
    }
  }

  return (
    <div className={`bo-rad--3 text--center text--bold color--white bo--1 ${severityClass}`}>
      <div className="p--">Update Advisor</div>
      <div className={`bg-color--white p- text--bold text--center ${severityTextColor}`}>
        <i className="sci sci__detail-information font--14" /> {severityText}
      </div>
      {deltaScore && <div className="pv-- text--center text--bold">Delta score: {deltaScore}</div>}
    </div>
  );
};

export default ReportDetailsPageUpdateAdvisorAlert;
