import * as actions from '~/actions/reportFilters';

const defaultState = {
  ISSUES: {
    vulnMethodsOnly: false,
    vulnDirectOnly: false,
    licenseSingleOnly: false,
    statuses: ['OPEN'],
    search: '',
    types: ['VULN'],
    severities: [],
  },
  LIBRARIES: {
    languageType: 'All languages',
    vulnsOnly: false,
    outOfDateOnly: false,
    directOnly: false,
    commLicenseOnly: false,
    search: '',
    inCatalog: '',
    licenseRisks: [],
    unlicensed: false,
  },
  VULNERABILITIES: {
    vulnMethodsOnly: false,
    highSeverityOnly: false,
    search: '',
  },
  LICENSES: {
    search: '',
    directOnly: false,
    outOfDateOnly: false,
    inCatalog: '',
    licenseRisks: [],
  },
  REPOSITORIES: {
    vulnsOnly: false,
    vulnMethodsOnly: false,
    scannedInPastWeek: null,
    search: '',
  },
  WORKSPACES: {
    search: '',
    context: 'WORKSPACE',
  },
};

const reportFilterState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_FILTER_VALUE:
      return {
        ...state,
        [action.reportType]: {
          ...state[action.reportType],
          [action.field]: action.value,
        },
      };
    case actions.UPDATE_FILTER_VALUE_BY_PROJECT:
      if (state[action.projectId]) {
        return {
          ...state,
          [action.projectId]: {
            ...state[action.projectId],
            [action.reportType]: {
              ...state[action.projectId][action.reportType],
              [action.field]: action.value,
            },
          },
        };
      } else {
        // the else shouldn't ever happen because we're initializing the defaults
        // but it's a good gaurd to have...
        return {
          ...state,
          [action.projectId]: {
            ...defaultState,
            [action.reportType]: {
              ...defaultState[action.reportType],
              [action.field]: action.value,
            },
          },
        };
      }
    case actions.UPDATE_FILTER_ARRAY:
      return {
        ...state,
        [action.reportType]: {
          ...state[action.reportType],
          [action.field]: action.value.length ? action.value.split(',') : [],
        },
      };
    case actions.UPDATE_FILTER_ARRAY_BY_PROJECT:
      if (state[action.projectId]) {
        return {
          ...state,
          [action.projectId]: {
            ...state[action.projectId],
            [action.reportType]: {
              ...state[action.projectId][action.reportType],
              [action.field]: action.value.length ? action.value.split(',') : [],
            },
          },
        };
      } else {
        // the else shouldn't ever happen because we're initializing the defaults
        // but it's a good gaurd to have...
        return {
          ...state,
          [action.projectId]: {
            ...defaultState,
            [action.reportType]: {
              ...defaultState[action.reportType],
              [action.field]: action.value.length ? action.value.split(',') : [],
            },
          },
        };
      }
    case actions.INIT_PROJECT_FILTER_DEFAULTS:
      if (state[action.projectId]) {
        return state;
      }
      return {
        ...state,
        [action.projectId]: defaultState,
      };
    case actions.RESET_REPORT_FILTERS:
      return defaultState;
    default:
      return state;
  }
};

export default reportFilterState;
