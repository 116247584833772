import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import { renderRoutesInPath } from '~/utils/RouterUtils';

import * as welcomeActions from '~/actions/welcome';

interface WelcomeFlowWrapperProps extends RouteComponentProps {
  routes: any[];
  welcomeActions: object;
  welcomeState: object;
}

class WelcomeFlowWrapper extends React.Component<WelcomeFlowWrapperProps, {}> {
  selectOS() {
    this.props.welcomeActions.selectOS();
  }

  render() {
    const { location, match, routes, welcomeState } = this.props;
    const { params = {} } = match;
    const { selectedOS } = welcomeState;
    const { pathname = '' } = location;
    const { teamId } = params;
    const isAgent = pathname.includes('agent');
    const isScanHelp = pathname.includes('scan-help');
    const isScan = !isScanHelp && pathname.includes('scan');
    const prettifyOSName = {
      OSX: 'OS X',
      LINUX: 'Linux',
      WINDOWS: 'Windows',
    };
    if (location.pathname && location.pathname.startsWith('/teams/')) {
      const to = {
        pathname: location.pathname.replace('/teams', '/workspaces'),
        state: { status: 301 },
      };
      return <Redirect to={to} />;
    }

    return (
      <div className="col-1-1">
        {(isAgent || isScan || isScanHelp) && (
          <div className="grid col-1-1 mt">
            <div className="grid__item col-1-6" />
            <div className="grid__item col-5-6">
              <div className="flex align-items--center">
                <Link
                  to={`/workspaces/${teamId}/welcome`}
                  className="link--obvious link--no-underline font--h3"
                >
                  Welcome
                </Link>
                <div className="mh-- font--h3">&rsaquo;</div>
                {isAgent && (
                  <div className="flex align-items--center">
                    {selectedOS ? (
                      <div className="flex align-items--center">
                        <Link
                          to={`/workspaces/${teamId}/welcome/agent`}
                          onClick={() => this.selectOS()}
                          className="link--obvious link--no-underline font--h3"
                        >
                          Set Up Scanner
                        </Link>
                        <div className="mh-- font--h3">&rsaquo;</div>
                        <div className="font--h3">{prettifyOSName[selectedOS]}</div>
                      </div>
                    ) : (
                      <div className="font--h3">Set Up Scanner</div>
                    )}
                  </div>
                )}
                {isScan && (
                  <div className="flex align-items--center">
                    <div className="font--h3">Scan Your Code</div>
                  </div>
                )}
                {isScanHelp && (
                  <div className="flex align-items--center">
                    <div className="font--h3">Complete Scan to View Results</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div>
          <Switch children={renderRoutesInPath(routes, match.path)} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    welcomeState: state.welcomeState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    welcomeActions: bindActionCreators(welcomeActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeFlowWrapper);
