import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import config from '~/config';
import Helpers from '~/utils/Helpers';

import * as MODEL from '~/constants/ModelConstants';
import CopyToClipboard from '~/containers/CopyToClipboard';
import SourceClearModal from '~/components/SourceClearModal';
import SourceClearLoader from '~/components/SourceClearLoader';
import * as integrationModalActions from '~/actions/integrationModal';
import ReportOptionsFilter from '~/components/ReportComponents/ReportOptionsFilter';

const activeEnvironment = config.ENVIRONMENT || 'DEV';
interface ProxyStep2ModalProps {
  integrationModalActions: object;
  integrationModalState: object;
  orgState: App.OrgState;
  closeWhenClickOutside?: boolean;
}
class ProxyStep2Modal extends React.Component<ProxyStep2ModalProps, {}> {
  onClose = () => {
    this.props.integrationModalActions.closeIntegrationModal();
  };

  continueStep3Modal() {
    this.props.integrationModalActions.proceedToProxyStep3(this.getProxyUrl());
  }

  getProxyUrl() {
    const { integrationModalState } = this.props;
    const { fields = {} } = integrationModalState;
    return fields.proxyServerUrl || '';
  }

  checkIfProxyClientSetup() {
    const { orgState, integrationModalState } = this.props;
    const { org } = orgState;
    const { jiraIntegration } = integrationModalState;
    const { [MODEL.CREATE_PROXY_CLIENT_STEP_1]: createProxyClientRequest = {} } = jiraIntegration;
    const { data: createProxyClientResponse } = createProxyClientRequest;
    const { uuid = '' } = createProxyClientResponse;

    this.props.integrationModalActions.checkIfProxyClientSetup(org.id, uuid);
  }

  updateFormInput(fieldType, e) {
    this.props.integrationModalActions.updateIntegrationModalField(fieldType, e);
  }

  returnToProxyStep1() {
    this.props.integrationModalActions.openIntegrationModal(MODEL.CREATE_PROXY_CLIENT_STEP_1);
  }

  render() {
    const { integrationModalState, closeWhenClickOutside = false } = this.props;

    const { fields = {}, jiraIntegration } = integrationModalState;
    const { proxyServerUrl = '', SEP_INSTALLATION_OPTION: installOption = 'command' } = fields;
    const {
      [MODEL.CREATE_PROXY_CLIENT_STEP_1]: createProxyClientRequest = {},
      [MODEL.CHECK_PROXY_CLIENT_REQUEST]: checkProxyClientConnectedRequest = {},
    } = jiraIntegration;
    const { data: createProxyClientResponse } = createProxyClientRequest;
    const { apiKey = '' } = createProxyClientResponse;
    const {
      data: checkProxyConnectedResponse = {},
      isFetching: isCheckingProxyConnectedRequest = false,
    } = checkProxyClientConnectedRequest;
    const { lastConnected } = checkProxyConnectedResponse;
    const connected = Helpers.isProxyConnected(lastConnected);

    const isProdEnv = activeEnvironment === 'PROD';

    const lineBreak = (
      <div className="pv">
        <div className="bo-b--1 border-color--muted-light -mh+" />
      </div>
    );

    return (
      <SourceClearModal
        isOpen={true}
        title={`Create ${MODEL.INTEGRATION_NAME_MAP.JIRA_ONPREM} Integration (Step 2 of 5)`}
        onClose={this.onClose}
        width={600}
        closeWhenClickOutside={closeWhenClickOutside}
      >
        <div className="pv min-height--232">
          <div className="grid">
            <div className="grid__item col-1-1">
              In this step, you will run the proxy-client locally (quick start) or on your ops
              environment. You can run it locally to check everything is set-up before proceeding to
              set up at your ops environment.
            </div>
            <div className="grid__item col-1-1">
              <span className="color--warning">
                {' '}
                Please ensure that you have saved the generated API key.{' '}
              </span>
              There is no way to retrieve the API key after.
            </div>
          </div>

          {lineBreak}

          <div className="flex flex--align-items--center flex--justify-content--center">
            <div className="col-1-1">
              1. Ensure you have docker installed or you are able to run docker image
            </div>
          </div>

          {lineBreak}

          <div className="flex flex--align-items--center flex--justify-content--center grid pl">
            <div className="col-1-1">
              <div className="flex flex--align-items--center flex--justify-content--center">
                <div className="col-1-3">2. Proxy client install option: </div>
                <div className="col-2-3">
                  <ReportOptionsFilter
                    value={installOption}
                    options={MODEL.SEP_CLIENT_INSTALL_OPTIONS}
                    onChange={(field, value) =>
                      this.updateFormInput('SEP_INSTALLATION_OPTION', value)
                    }
                    isMulti={false}
                    field="types"
                    placeholder="Issue Type"
                  />
                </div>
              </div>
            </div>

            {installOption === 'command' && (
              <div className="col-1-1 pt-">
                Type the following command to run the client locally:
                <div className="flex flex--align-items--center flex--justify-content--center pv-">
                  <div className="col-1-1">
                    <CopyToClipboard
                      rowCount={6}
                      value={`docker run -e SEP_API_KEY=${apiKey} -e SEP_JIRA_URL=${proxyServerUrl}${
                        isProdEnv ? '' : ` -e SEP_SERVER_IP=${config.SEP_SERVER_IP}`
                      } -e SEP_SERVER_PORT=443 ${config.SEPC_DOCKER_URL}`}
                      id="dockerCommand"
                    />
                  </div>
                </div>
              </div>
            )}

            {installOption === 'image' && (
              <div className="col-1-1 pt-">
                a) Create a dockerfile:
                <div className="flex flex--align-items--center flex--justify-content--center pv-">
                  <div className="col-1-1">
                    <CopyToClipboard
                      rowCount={7}
                      value={`FROM ${config.SEPC_DOCKER_URL}
                  \nENV SEP_JIRA_URL=${proxyServerUrl}
                  ${isProdEnv ? `` : `\nENV SEP_SERVER_IP=${config.SEP_SERVER_IP}`}
                  \nENV SEP_SERVER_PORT=443`}
                      id="dockerFile"
                    />
                  </div>
                </div>
                b) Build your docker image:
                <div className="flex flex--align-items--center flex--justify-content--center pv-">
                  <div className="col-1-1">
                    {' '}
                    <CopyToClipboard
                      value={`docker build -t YOUR_DOCKER_IMAGE_TAG -f YOUR_DOCKER_FILE_NAME .`}
                      id="dockerBuild"
                    />{' '}
                  </div>
                </div>
                c) Run your docker image:
                <div className="flex flex--align-items--center flex--justify-content--center pv-">
                  <div className="col-1-1">
                    {' '}
                    <CopyToClipboard
                      rowCount={4}
                      value={`docker run -e SEP_API_KEY=${apiKey} YOUR_DOCKER_IMAGE_TAG`}
                      id="dockerRun"
                    />{' '}
                  </div>
                </div>
              </div>
            )}
          </div>

          {lineBreak}

          <div className="grid flex flex--align-items--center flex--justify-content--center pb-">
            {isCheckingProxyConnectedRequest ? (
              <div>
                <div className="text--center font--h6"> Checking if connection is set-up... </div>
                <div className="pr">
                  {' '}
                  <SourceClearLoader />{' '}
                </div>
              </div>
            ) : (
              !!connected === false && (
                <button
                  className="pv- mr- btn--primary-clear"
                  onClick={() => this.checkIfProxyClientSetup()}
                >
                  {' '}
                  Test if connection set-up{' '}
                </button>
              )
            )}
          </div>

          <div className="grid flex flex--align-items--center flex--justify-content--center">
            {connected && !isCheckingProxyConnectedRequest && (
              <div>
                <i className="sci sci__check color--success" /> Your client is connected!
              </div>
            )}
            {connected === false && !isCheckingProxyConnectedRequest && (
              <div>
                <i className="sci sci__close color--danger" /> We couldn't detect a client
                connection yet.
              </div>
            )}
          </div>
        </div>

        <div className="pt pb text--right">
          <button className="col-1-5 pv- mr-" onClick={() => this.onClose()}>
            {' '}
            Cancel{' '}
          </button>
          <button
            className="col-1-5 pv- mr- btn--primary-clear"
            onClick={() => this.returnToProxyStep1()}
          >
            Back
          </button>
          {connected && (
            <button
              className="col-1-5 pv- mr- btn--primary"
              onClick={() => this.continueStep3Modal()}
            >
              {' '}
              Next{' '}
            </button>
          )}
        </div>
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationModalState: state.integrationModalState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProxyStep2Modal);
