import { combineReducers } from 'redux';
import * as actions from '~/actions/integrationModal';
import _ from 'lodash';

const integrationModalState = () => {
  const isModalJiraPrem = (state = false, action) => {
    switch (action.type) {
      case actions.SET_MODAL_TYPE_JIRA_PREM:
        return true;
      case actions.CLOSE_INTEGRATION_MODAL:
        return false;
      default:
        return state;
    }
  };

  const openedModal = (state = {}, action) => {
    switch (action.type) {
      case actions.OPEN_INTEGRATION_MODAL:
        return {
          [action.integrationType]: true,
        };
      case actions.CLOSE_INTEGRATION_MODAL:
        return {};
      default:
        return state;
    }
  };

  const fields = (state = {}, action) => {
    switch (action.type) {
      case actions.UPDATE_INTEGRATION_MODAL_FIELD:
        return {
          ...state,
          [action.fieldType]: action.fieldVal,
        };
      case actions.CLEAR_MODAL_JIRA_FIELD: {
        const newState = {};
        for (let key in state) {
          if (!key.startsWith('jiraField')) {
            newState[key] = state[key];
          }
        }
        return newState;
      }
      case actions.CLOSE_INTEGRATION_MODAL:
        return {};
      default:
        return state;
    }
  };

  const jiraIntegration = (state = {}, action) => {
    switch (action.type) {
      case actions.JIRA_INTEGRATION_REQUEST:
        return {
          ...state,
          [action.integrationStep]: {
            ...state[action.integrationStep],
            isFetching: true,
            err: null,
          },
        };
      case actions.JIRA_INTEGRATION_SUCCESS:
        return {
          ...state,
          [action.integrationStep]: {
            ...state[action.integrationStep],
            isFetching: false,
            data: action.res,
            err: null,
          },
        };
      case actions.JIRA_INTEGRATION_FAILURE:
        return {
          ...state,
          [action.integrationStep]: {
            ...state[action.integrationStep],
            isFetching: false,
            err: action.err,
          },
        };
      case actions.CLOSE_INTEGRATION_MODAL:
        return {};
      default:
        return state;
    }
  };

  const selectedTemplate = (state = {}, action) => {
    switch (action.type) {
      case actions.SET_SELECTED_TICKET_TEMPLATE:
        return action.template;
      case actions.CLOSE_INTEGRATION_MODAL:
        return {};
      default:
        return state;
    }
  };

  const currentEditTemplate = (state = {}, action) => {
    switch (action.type) {
      case actions.SET_CURRENT_EDIT_TEMPLATE:
        return action.template;
      case actions.CLOSE_INTEGRATION_MODAL:
        return {};
      default:
        return state;
    }
  };

  const selectedWorkspaceTeam = (state = {}, action) => {
    switch (action.type) {
      case actions.SET_SELECTED_WORKSPACE_TEAM:
        return action.team;
      case actions.CLOSE_INTEGRATION_MODAL:
        return {};
      default:
        return state;
    }
  };

  const defaultIssueSettings = {
    projects: [{ projectId: undefined, projectName: undefined, issueTypeId: undefined }],
    urlData: {},
    url: null,
  };
  const currentIssueSettings = (state = defaultIssueSettings, action) => {
    switch (action.type) {
      case actions.MODAL_UPDATE_JIRA_URL:
        return {
          ...state,
          url: action.url,
        };
      case actions.MODAL_UPDATE_JIRA_PROJECT_FORM: {
        const newState = _.cloneDeep(state);
        newState.projects[action.projectIndex][action.contentKey] = action.contentValue;
        return newState;
      }
      case actions.MODAL_REMOVE_JIRA_PROJECT:
        return {
          ...action.issueSettingsData,
        };
      case actions.MODAL_ADD_JIRA_PROJECT:
        return {
          ...state,
          projects: [...state.projects, _.cloneDeep(defaultIssueSettings.projects[0])],
        };
      case actions.MODAL_UPDATE_JIRA_PROJECT_VALIDATIONS:
        return {
          ...action.activeIssueSettingsData,
        };
      case actions.MODAL_SAVE_ATTEMPTED: {
        const newState = _.cloneDeep(state);

        newState.projects.forEach(project => {
          project.saveAttempted = true;
        });
        newState.urlData.saveAttempted = true;
        return newState;
      }
      case actions.MODAL_SET_ISSUE_SETTINGS_TO_EDIT:
        return {
          ...action.issueSettingsData,
        };
      case actions.CLOSE_INTEGRATION_MODAL:
      case actions.SET_SELECTED_WORKSPACE_TEAM:
        return defaultIssueSettings;
      default:
        return state;
    }
  };

  return combineReducers({
    isModalJiraPrem,
    openedModal,
    fields,
    jiraIntegration,
    selectedTemplate,
    currentEditTemplate,
    selectedWorkspaceTeam,
    currentIssueSettings,
  });
};

export default integrationModalState();
