import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import Helpers from '~/utils/Helpers';
import ApiConstants from '~/constants/ApiConstants';
import SunburstHelper from '~/utils/SunburstHelper';

export const UPDATE_DETAILS_PAGE_ACTIVE_TAB = 'UPDATE_DETAILS_PAGE_ACTIVE_TAB';
export const FETCH_DETAILS_PAGE_REQUEST = 'FETCH_DETAILS_PAGE_REQUEST';
export const FETCH_DETAILS_PAGE_SUCCESS = 'FETCH_DETAILS_PAGE_SUCCESS';
export const FETCH_DETAILS_PAGE_FAILURE = 'FETCH_DETAILS_PAGE_FAILURE';
export const FETCH_ISSUE_EVENT_REQUEST = 'FETCH_ISSUE_EVENT_REQUEST';
export const FETCH_ISSUE_EVENT_SUCCESS = 'FETCH_ISSUE_EVENT_SUCCESS';
export const FETCH_ISSUE_EVENT_FAILURE = 'FETCH_ISSUE_EVENT_FAILURE';

export const UPDATE_ACTIVE_TAB_DATA_BY_REPORT_FIELD = 'UPDATE_ACTIVE_TAB_DATA_BY_REPORT_FIELD';
export const UPDATE_ACTIVE_TAB_STATE_BY_REPORT_FIELD = 'UPDATE_ACTIVE_TAB_STATE_BY_REPORT_FIELD';
export const SET_IS_LOADING_ACTIVE_TAB = 'SET_IS_LOADING_ACTIVE_TAB';

export const UPDATE_REPORT_EXPANDED_VULN_METHOD_CHAIN = 'UPDATE_REPORT_EXPANDED_VULN_METHOD_CHAIN';

export const FETCH_UPDATE_ADVISOR_DATA_REQUEST = 'FETCH_UPDATE_ADVISOR_DATA_REQUEST';
export const FETCH_UPDATE_ADVISOR_DATA_SUCCESS = 'FETCH_UPDATE_ADVISOR_DATA_SUCCESS';
export const FETCH_LICENSE_CONTENT_DATA_REQUEST = 'FETCH_LICENSE_CONTENT_DATA_REQUEST';
export const FETCH_LICENSE_CONTENT_DATA_SUCCESS = 'FETCH_LICENSE_CONTENT_DATA_SUCCESS';
export const FETCH_LICENSE_CONTENT_DATA_FAILURE = 'FETCH_LICENSE_CONTENT_DATA_FAILURE';
export const TOGGLE_LICENSE = 'TOGGLE_LICENSE';

export const RESET_REPORT = 'RESET_REPORT';

export const updateDetailsPageActiveTab = (reportType, value) => ({
  type: UPDATE_DETAILS_PAGE_ACTIVE_TAB,
  reportType,
  value,
});

export const fetchDetailsPageRequest = reportType => ({
  type: FETCH_DETAILS_PAGE_REQUEST,
  reportType,
  isFetching: true,
});

export const fetchDetailsPageSuccess = (response, reportType) => ({
  type: FETCH_DETAILS_PAGE_SUCCESS,
  reportType,
  response,
});

export const fetchDetailsPageFailure = reportType => ({
  type: FETCH_DETAILS_PAGE_FAILURE,
  reportType,
});

export const fetchUpdateAdvisorDataRequest = reportType => ({
  type: FETCH_UPDATE_ADVISOR_DATA_REQUEST,
  reportType,
  isFetching: true,
});

export const fetchUpdateAdvisorDataSuccess = (response, reportType) => ({
  type: FETCH_UPDATE_ADVISOR_DATA_SUCCESS,
  reportType,
  response,
});

export const fetchLicenseContentDataRequest = reportType => ({
  type: FETCH_LICENSE_CONTENT_DATA_REQUEST,
  reportType,
  isFetching: true,
});

export const fetchLicenseContentDataSuccess = (response, reportType) => ({
  type: FETCH_LICENSE_CONTENT_DATA_SUCCESS,
  reportType,
  response,
});

export const fetchLicenseContentDataFailure = (err, reportType) => ({
  type: FETCH_LICENSE_CONTENT_DATA_FAILURE,
  reportType,
  err: err.message || 'Something went wrong. Please try again.',
});

export const fetchIssueEventsRequest = reportType => ({
  type: FETCH_ISSUE_EVENT_REQUEST,
  reportType,
});

export const fetchIssueEventsSuccess = (events, reportType) => ({
  type: FETCH_ISSUE_EVENT_SUCCESS,
  events,
  reportType,
});

export const fetchIssueEventsFailure = reportType => ({
  type: FETCH_ISSUE_EVENT_FAILURE,
  reportType,
});

export const fetchIssuesEvents = (reportType, issueId) => dispatch => {
  dispatch(fetchIssueEventsRequest(reportType));
  return ApiService.get(ApiConstants.getIssuesEvents(issueId))
    .then(res => {
      const {
        _embedded: { repoIssueEventModelList },
      } = res;
      dispatch(fetchIssueEventsSuccess(repoIssueEventModelList, reportType));
    })
    .catch(err => {
      ErrorService.capture('Error fetching issue events', err);
      dispatch(fetchIssueEventsFailure(reportType));
    });
};
// only fetch repo issues details from backend without triggering state updates that cause
// unnecessary re-renders when calling fetchDetailsPage.
export const fetchIssuesDetails = (reportType, issueId) => dispatch => {
  const endpoint = ApiConstants.getIssuesDetails(issueId);
  const scope = {
    id: 0,
    name: 'default',
    agents: '*',
    paths: '*',
    repos: '*',
    isDefault: true,
  };
  return ApiService.get(endpoint, { data: scope })
    .then(res => {
      dispatch(fetchDetailsPageSuccess(res, reportType));
    })
    .catch(error => {
      ErrorService.capture('Error fetching issue details', error);
    });
};

export const fetchDetailsPage = (reportType, issueId) => dispatch => {
  const endpoint = ApiConstants.getIssuesDetails(issueId);
  const scope = {
    id: 0,
    name: 'default',
    agents: '*',
    paths: '*',
    repos: '*',
    isDefault: true,
  };

  dispatch(fetchDetailsPageRequest(reportType));

  return ApiService.get(endpoint, { data: scope })
    .then(res => {
      if (res) {
        dispatch(fetchDetailsPageSuccess(res, reportType));
      }
      // return the issue {} from the response so
      // components have immediate access
      return Helpers.getIssueFromResponse(res);
    })
    .catch(error => {
      ErrorService.capture('Error fetching issue details', error);
      dispatch(fetchDetailsPageFailure(reportType));
    });
};

export const updateReportExpandedVulnMethodChain = (reportType, chain) => ({
  type: UPDATE_REPORT_EXPANDED_VULN_METHOD_CHAIN,
  reportType,
  chain,
});

export const updateActiveTabDataByReportField = (reportType, field, res) => ({
  type: UPDATE_ACTIVE_TAB_DATA_BY_REPORT_FIELD,
  reportType,
  field,
  res,
});

export const updateActiveTabStateByReportField = (reportType, field, val) => ({
  type: UPDATE_ACTIVE_TAB_STATE_BY_REPORT_FIELD,
  reportType,
  field,
  val,
});

export const setIsLoadingActiveTab = (reportType, isLoading = false) => ({
  type: SET_IS_LOADING_ACTIVE_TAB,
  reportType,
  isLoading,
});

export const loadDetailPageActiveTab = (reportType, field, link) => {
  return dispatch => {
    dispatch(setIsLoadingActiveTab(reportType, true));
    return ApiService.get(link).then(res => {
      if (res.graphs) {
        res.graphs.sunburstPaths = SunburstHelper.formatDepGraphRes(res.graphs);
      }
      dispatch(updateActiveTabDataByReportField(reportType, field, res));
      dispatch(setIsLoadingActiveTab(reportType, false));
    });
  };
};

export const fetchUpdateAdvisorData = (reportType, href) => dispatch => {
  dispatch(fetchUpdateAdvisorDataRequest(reportType));
  return ApiService.get(href).then(res => {
    if (res) {
      dispatch(fetchUpdateAdvisorDataSuccess(res, reportType));
    }
  });
};

export const fetchLicenseContentData = (reportType, href) => dispatch => {
  dispatch(fetchLicenseContentDataRequest(reportType));
  return ApiService.get(href).then(res => {
    if (res) {
      dispatch(fetchLicenseContentDataSuccess(res, reportType));
    }
  });
};

export const fetchLicensesContentData = (reportType, licenses) => dispatch => {
  dispatch(fetchLicenseContentDataRequest(reportType));

  const fetchLicensesPromises = licenses.map(license =>
    ApiService.get(`${license._links.self.href}`)
  );
  Promise.all(fetchLicensesPromises)
    .then(res => {
      dispatch(fetchLicenseContentDataSuccess(res, reportType));
    })
    .catch(err => {
      ErrorService.capture('Unable to fetch licenses :', err);
      dispatch(fetchLicenseContentDataFailure(err, reportType));
    });
};

export const toggleLicense = (reportType, licenseName) => ({
  type: TOGGLE_LICENSE,
  reportType,
  licenseName,
});

export const resetReport = reportType => ({
  type: RESET_REPORT,
  reportType,
});
