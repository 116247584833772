import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as reportsActions from '~/actions/reports';
import * as MODEL from '~/constants/ModelConstants';

import CSVDownloader from '~/containers/CSVDownloader';

const CSV_REPORT_DOWNLOADER_POPOVER = 'CSV_REPORT_DOWNLOADER_POPOVER';

interface CSVReportDownloaderProps {
  navigationState: object;
  reportsByTypeCSV: object;
  reportType: string;
  reportsActions: object;
  page: object;
  orgState: App.OrgState;
  noElementText: string;
  projectId?: string;
}
const CSVReportDownloader: React.FunctionComponent<CSVReportDownloaderProps> = props => {
  const { navigationState, reportType, page = {}, noElementText, projectId, orgState } = props;
  const { activeTeamParent: teamId } = navigationState;
  const { org } = orgState;
  const { id: orgId } = org;
  const popOverId = `${CSV_REPORT_DOWNLOADER_POPOVER}_${reportType}`;
  const tooltipId = `${reportType}-noResultCsv`;
  const onUpgradeCTA = () =>
    props.reportsActions.fetchReportsForCsvAttemptSnowplow(teamId, MODEL.NON_PAID_TEAM);
  const onDownload = () => {
    if (MODEL.orgLevelReports.includes(reportType)) {
      props.reportsActions.fetchOrgReportsForCSV(orgId, reportType);
    } else {
      props.reportsActions.fetchTeamReportsForCSV(teamId, projectId, reportType);
      props.reportsActions.fetchReportsForCsvAttemptSnowplow(teamId, MODEL.PRO_TEAM);
    }
  };
  const isFetching = props.reportsByTypeCSV.isFetching[reportType];
  const errorMessage = props.reportsByTypeCSV.errorMessage[reportType];

  return (
    <CSVDownloader
      page={page}
      noElementText={noElementText}
      popOverId={popOverId}
      tooltipId={tooltipId}
      onUpgradeCTA={onUpgradeCTA}
      onDownload={onDownload}
      isFetching={isFetching}
      errorMessage={errorMessage}
    />
  );
};

function mapStateToProps(state) {
  return {
    navigationState: state.navigationState,
    orgState: state.orgState,
    reportsByTypeCSV: state.reportsByTypeCSV,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportsActions: bindActionCreators(reportsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CSVReportDownloader);
