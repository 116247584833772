import React from 'react';
import { Link } from 'react-router-dom';
import { integrationPrettyNameMap } from '~/constants/ModelConstants';

interface AgentSetupBreadcrumbsProps {
  teamId?: string;
  option?: string;
}

const AgentSetupBreadcrumbs: React.FunctionComponent<AgentSetupBreadcrumbsProps> = props => {
  const { teamId, option } = props;

  return (
    <div className="flex">
      {option ? (
        <Link
          to={teamId ? `/workspaces/${teamId}/agents/new` : `/org/settings/agents/new`}
          className="link--obvious link--no-underline font--h3"
        >
          Set Up Scanner
        </Link>
      ) : (
        <div className="font--h3" data-automation-id="AgentSetupBreadcrumbs-Title">
          Set Up Scanner
        </div>
      )}
      {option && <div className="ml- font--h3">&rsaquo;</div>}
      {option && <div className="ml- font--h3">{integrationPrettyNameMap[option]}</div>}
    </div>
  );
};

export default AgentSetupBreadcrumbs;
