import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DropdownMenu from 'react-dd-menu';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as groupActions from '~/actions/group';
import * as orgUserActions from '~/actions/orgUser';

import Tooltip from '~/components/Tooltip';
import SourceClearModal from '~/components/SourceClearModal';
import AddSelectedUserToGroupsModal from '~/containers/AddSelectedUserToGroupsModal';

interface UserGroupsListProps {
  userId: string;
  teamId?: string;
  dropdownMenuActions: object;
  groupActions: object;
  orgUserActions: object;
  dropdownMenuState: object;
  groupState: object;
  orgUserState: object;
}
class UserGroupsList extends React.Component<UserGroupsListProps> {
  componentWillUnmount() {
    this.props.orgUserActions.resetUserSort();
    this.props.dropdownMenuActions.closeDropdownMenu('user-groups-actions');
  }

  removeFromGroups() {
    const { orgUserState, userId } = this.props;
    const { selectedUserGroups = {}, userGroups = [] } = orgUserState;
    const membershipIds = [];

    Object.keys(selectedUserGroups)
      .filter(groupId => selectedUserGroups[groupId])
      .forEach(groupId => {
        const foundGroupMembership =
          userGroups.find(groupObj => {
            const { group = {} } = groupObj;
            return group.id === parseInt(groupId);
          }) || {};

        membershipIds.push(foundGroupMembership.id);
      });

    this.props.groupActions.removeMemberships(membershipIds, 'users').then(res => {
      if (res.success) {
        this.props.orgUserActions.fetchOrgUsers(userId);
      }
    });
  }

  delayCloseDropdown = _.debounce(() => this.closeDropdown('user-groups-actions'), 50);

  updateGroupAdminStatus(toAdmin = false) {
    const { orgUserState, userId } = this.props;
    const { selectedUserGroups = {} } = orgUserState;
    const groupData = Object.keys(selectedUserGroups)
      .filter(groupId => selectedUserGroups[groupId])
      .map(groupId => {
        return {
          groupId: parseInt(groupId),
          users: [{ userId: parseInt(userId), admin: toAdmin }],
        };
      });

    this.props.groupActions.groupMultiUpdate(groupData).then(res => {
      if (res.id) {
        this.props.orgUserActions.fetchOrgUsers(userId);
        this.props.orgUserActions.resetSelectedUserGroups();
      }
    });
  }

  handleFilterInput(e) {
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.orgUserActions.filterUserGroups(value);
  }

  closeDropdown(menuId) {
    this.props.dropdownMenuActions.closeDropdownMenu(menuId);
  }

  openDropdown(menuId) {
    this.props.dropdownMenuActions.openDropdownMenu(menuId);
  }

  handleGroupSelection(groupId) {
    this.props.orgUserActions.updateSelectedUserGroups(groupId);
  }

  updateUserGroupSort(column) {
    this.props.orgUserActions.updateUserGroupSort(column);
  }

  showModal(modalType) {
    this.props.orgUserActions.showModal(modalType);
  }

  render() {
    const { orgUserState, userId, teamId, dropdownMenuState } = this.props;
    const { openedDropdown = {} } = dropdownMenuState;
    const {
      userGroups = [],
      filteredUserGroups = [],
      selectedUserGroups = {},
      showModal,
      userGroupsSortColumn: sortColumn,
      sortAscending,
    } = orgUserState;
    const columnWidths = {
      name: 'col-1-6',
      description: 'col-1-3',
      select: 'col-1-18',
      admin: '',
    };

    const renderUserGroups = () => {
      if (!userGroups.length) {
        return (
          <div className="mt- font--h3 color--muted-dark">
            This user does not belong to any teams.
          </div>
        );
      } else if (!filteredUserGroups.length) {
        return <div className="mt- font--h3 color--muted-dark">No teams found.</div>;
      }

      return filteredUserGroups.map(groupObj => {
        const { group = {}, admin } = groupObj;
        const { id: groupId, name = '', description = '' } = group;
        const isSelected = selectedUserGroups[groupId] || false;

        return (
          <div className="grid grid--narrower mt-" key={groupId}>
            <div className={`grid__item ${columnWidths.name}`}>
              <Link
                to={
                  teamId
                    ? `/workspaces/${teamId}/user-management/teams/${groupId}`
                    : `/org/settings/teams/${groupId}`
                }
                className="link--obvious"
              >
                <strong>{name}</strong>
              </Link>
            </div>
            <div className={`grid__item ${columnWidths.description}`}>{description || '--'}</div>
            <div className={`grid__item text--center ${columnWidths.admin}`}>
              {admin ? 'Yes' : '--'}
            </div>
            <div
              className={`grid__item ${columnWidths.select} flex align-items--center justify-content--center`}
            >
              <label className="label--checkbox flex justify-content--center mb0">
                <input
                  type="checkbox"
                  name="select"
                  checked={isSelected}
                  onChange={() => this.handleGroupSelection(groupId)}
                />
                <span className="react--checkbox mr0" />
              </label>
            </div>
          </div>
        );
      });
    };

    return (
      <div className="grid">
        <div className="grid__item col-1-1">
          <div className="bo--1 border-color--muted-dark flex justify-content--space-between align-items--center p-">
            <div className="flex col-3-4">
              <div className="col-1-2">
                <input
                  type="text"
                  className="control--text col-1-1"
                  placeholder="Filter by name or description"
                  onChange={e => this.handleFilterInput(e)}
                />
              </div>
            </div>
            <div>
              <DropdownMenu
                isOpen={!!openedDropdown['user-groups-actions']}
                close={() => this.delayCloseDropdown()}
                menuAlign="right"
                textAlign="left"
                toggle={
                  <button
                    className="btn--success p-"
                    onClick={() => this.openDropdown('user-groups-actions')}
                  >
                    Actions
                  </button>
                }
              >
                <li className="min-width--200 dropdown-menu--li">
                  <button className="pv--" onClick={() => this.showModal('ADD_GROUPS')}>
                    Add to Teams
                  </button>
                </li>
                <li className="min-width--200 dropdown-menu--li">
                  <button
                    className={`pv-- ${!_.some(selectedUserGroups) && 'disabled'}`}
                    disabled={!_.some(selectedUserGroups)}
                    onClick={() => this.removeFromGroups()}
                  >
                    Remove from Teams
                  </button>
                </li>
                <li className="separator mh-" />
                <li className="min-width--200 dropdown-menu--li">
                  <button
                    className={`pv-- ${!_.some(selectedUserGroups) && 'disabled'}`}
                    disabled={!_.some(selectedUserGroups)}
                    onClick={() => this.updateGroupAdminStatus(true)}
                  >
                    Change to Team Administrator
                  </button>
                </li>
                <li className="min-width--200 dropdown-menu--li">
                  <button
                    className={`pv-- ${!_.some(selectedUserGroups) && 'disabled'}`}
                    disabled={!_.some(selectedUserGroups)}
                    onClick={() => this.updateGroupAdminStatus()}
                  >
                    Change to Team Member
                  </button>
                </li>
              </DropdownMenu>
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1 mt">
          <div className="grid grid--narrower">
            <div
              className={`grid__item flex align-items--center justify-content--space-between cursor--pointer ${columnWidths.name}`}
              onClick={() => this.updateUserGroupSort('name')}
            >
              <strong>Team Name</strong>
              {sortColumn === 'name' ? (
                <i
                  className={`sci ${
                    sortAscending ? 'sci__arrow--closed-up' : 'sci__arrow--closed-down'
                  }`}
                />
              ) : (
                <i className={`sci sci__arrow--closed-scroll`} />
              )}
            </div>
            <div className={`grid__item ${columnWidths.description}`}>
              <strong>Description</strong>
            </div>
            <div className={`grid__item ${columnWidths.admin} text--center`}>
              <Tooltip
                id="user-groups-admin"
                content={`Team administrators can invite or add new users, remove users, and make other users team administrators.`}
                maxWidthClass="max-width--300"
                place="top"
              >
                <strong className="bo-b--1 bo--dashed">Team Administrator</strong>
              </Tooltip>
            </div>
            <div className={`grid__item ${columnWidths.select} text--center`}>
              <strong>Select</strong>
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1">{renderUserGroups()}</div>
        <SourceClearModal
          isOpen={showModal === 'ADD_GROUPS'}
          onClose={() => this.showModal('')}
          title="Add User to Teams"
          width={500}
        >
          <AddSelectedUserToGroupsModal userId={userId} />
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    groupState: state.groupState,
    orgUserState: state.orgUserState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    groupActions: bindActionCreators(groupActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupsList);
