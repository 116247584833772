import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import * as quickviewActions from '~/actions/quickview';

import { QuickviewClosedIcon, QuickviewOpenIcon } from '~/components/Icons';
import LicenseReportLibrariesQuickview from '~/components/ReportComponents/LicenseReportLibrariesQuickview';
import LicenseRiskIcon, { Rating } from '~/components/Icons/LicenseRiskIcon';
import LoaderWrapper from '~/components/LoaderWrapper';

import 'velocity-animate';
import 'velocity-animate/velocity.ui';
import { VelocityTransitionGroup } from 'velocity-react';
import Animations from '~/utils/Animations';

import { licenseRiskSeverityOptions } from '~/constants/ModelConstants';

interface LicensesRowProps {
  data: object;
  rowId: string;
  quickviewActions: object;
  quickviewState: object;
  navigationState: object;
  isSingleRepoScope?: boolean;
}

class LicensesRow extends React.Component<LicensesRowProps, {}> {
  getQuickviewLinks = links => {
    if (!links) return;
    const { libs = {} } = links;
    return {
      libraries: libs.href,
    };
  };

  getQuickviewId = ({ rowField, rowId }) => {
    return `${rowId}-${rowField}`;
  };

  toggleQuickview = (quickviewId, quickviewLink) => {
    const { currentOpenRowId = '' } = this.props.quickviewState;
    if (currentOpenRowId !== quickviewId) {
      this.props.quickviewActions.loadQuickview(quickviewId, quickviewLink);
      this.props.quickviewActions.setCurrentOpenRow(quickviewId);
    } else {
      this.props.quickviewActions.setCurrentOpenRow('');
    }
  };

  onQuickviewPageClick = (quickviewId, link, page) => {
    this.props.quickviewActions.loadQuickview(quickviewId, link, page);
  };

  render() {
    const { data, rowId, quickviewState, navigationState, isSingleRepoScope } = this.props;
    const { activeTeamParent } = navigationState;
    const { libraries, repos, name, risk, _links } = data;
    const { currentOpenRowId = '', dataByRowId = {}, isLoading = true } = quickviewState;
    const librariesQuickviewId = this.getQuickviewId({
      rowId,
      rowField: 'libraries',
    });
    const { libraries: librariesQuickviewLink } = this.getQuickviewLinks(_links);
    const { [librariesQuickviewId]: librariesQuickviewData = {} } = dataByRowId;

    const severity = licenseRiskSeverityOptions.find(s => s.value === risk);
    const { value: riskFormatted } = severity;

    return (
      <div
        className="bo--1 border-color--white-dark color--muted-dark pl- pv-- mv---"
        data-e2e="report-row"
      >
        <div className="grid grid--middle grid--narrower min-height--25 flex--align-items--stretch">
          <div
            className="grid__item flex flex--align-items--center col-3-5"
            data-automation-id="RowCell"
          >
            {name}
          </div>
          <div className="grid__item col-1-12" data-automation-id="RowCell">
            <div className="pad-left--30">
              <LicenseRiskIcon rating={Rating[riskFormatted]} id={rowId} />
            </div>
          </div>
          <div
            className="grid__item flex flex--align-items--center flex--justify-content--end"
            data-automation-id="RowCell"
          >
            <div
              className={`flex ${libraries && 'cursor--pointer'}`}
              onClick={() => this.toggleQuickview(librariesQuickviewId, librariesQuickviewLink)}
            >
              <div className="flex">
                {`Found in ${libraries} librar${libraries > 1 ? 'ies' : 'y'} ${
                  !isSingleRepoScope ? `in ${repos} project${repos > 1 ? 's' : ''}` : ``
                } `}
              </div>
              {libraries && (
                <span className="flex">
                  {currentOpenRowId === librariesQuickviewId ? (
                    <QuickviewOpenIcon className="cursor--pointer color--primary ph-" />
                  ) : (
                    <QuickviewClosedIcon className="cursor--pointer color--primary ph-" />
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
        <VelocityTransitionGroup
          enter={Animations.slideDown_quickView}
          leave={Animations.slideUp_default}
        >
          {currentOpenRowId === librariesQuickviewId && (
            <div>
              <LoaderWrapper isLoaderShowing={_.isEmpty(librariesQuickviewData) && isLoading}>
                {!_.isEmpty(librariesQuickviewData) && (
                  <LicenseReportLibrariesQuickview
                    data={librariesQuickviewData}
                    onPageClick={this.onQuickviewPageClick}
                    teamId={activeTeamParent}
                    quickviewId={librariesQuickviewId}
                    quickviewLink={librariesQuickviewLink}
                  />
                )}
              </LoaderWrapper>
            </div>
          )}
        </VelocityTransitionGroup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    quickviewState: state.quickviewState,
    navigationState: state.navigationState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    quickviewActions: bindActionCreators(quickviewActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LicensesRow);
