import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import * as reportLibraryActions from '~/actions/reportLibrary';
import * as quickviewAction from '~/actions/quickview';

import LicensesQuickviewItemRow from '~/components/ReportPage/LicensesQuickviewItemRow';

interface ReportExpandedLicenseProps {
  quickviewState: object;
  quickviewAction: object;
  rowId: string;
}
class ReportExpandedLicense extends React.Component<ReportExpandedLicenseProps, {}> {
  getOpenedLicenseName = () => {
    const { stateByRowId = {} } = this.props.quickviewState;
    const { [this.props.rowId]: licenseState = {} } = stateByRowId;
    return licenseState.openLicenses || '';
  };

  toggleLicense = name => {
    const isCurrentLicenseOpen = this.getOpenedLicenseName() === name;
    if (isCurrentLicenseOpen) {
      //close the current license
      this.props.quickviewAction.updateStateByRowId(this.props.rowId, 'openLicenses', '');
    } else {
      this.props.quickviewAction.updateStateByRowId(this.props.rowId, 'openLicenses', name);
    }
  };

  render() {
    const { rowId, quickviewState } = this.props;
    const { dataByRowId } = quickviewState;
    const { [rowId]: licenses = [] } = dataByRowId;

    const openedLicensesName = this.getOpenedLicenseName();

    let licenseRows = licenses.map((license, idx) => {
      return (
        <LicensesQuickviewItemRow
          key={`license-${idx}`}
          index={idx}
          license={license}
          isLast={idx === licenses.length - 1}
          isHiding={licenses.length !== 1}
          toggleExpandedLicense={this.toggleLicense}
          expandedLicense={licenses.length === 1 ? license.name : openedLicensesName}
          expandable={licenses.length === 1 ? false : true}
          risk={license.risk}
        />
      );
    });

    if (licenseRows.length === 0) {
      licenseRows = (
        <div>
          <div className="pv++ text--center">
            <div className="font--h5">
              <i className="sci sci__alerts mb-" />
              <p className="font--h5">No license information available</p>
            </div>
          </div>
        </div>
      );
    }

    return <div className="mt- mb-">{licenseRows}</div>;
  }
}

function mapStateToProps(state) {
  return {
    quickviewState: state.quickviewState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    quickviewAction: bindActionCreators(quickviewAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportExpandedLicense);
