export enum Severity {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Critical = 'critical'
}

export interface IssueInsightSeverity {
  id: Severity.Critical | Severity.High | Severity.Medium | Severity.Low;
  name: string;
  count: number | null;
}
