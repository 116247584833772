import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import Select from 'react-select';
import Helpers from '~/utils/Helpers';

import { isArray } from 'lodash';

import ReportHeaderRow from '~/components/ReportComponents/ReportHeaderRow';
import ReportHeaderItem from '~/components/ReportComponents/ReportHeaderItem';
import ReportLibrariesRow from '~/containers/ReportLibrariesRow';
import ReportHeader from '~/components/ReportComponents/ReportHeader';
import DropdownList from '~/components/DropdownList';
import ReportUnmatchedLibrariesRow from '~/containers/ReportUnmatchedLibrariesRow';
import LicenseRiskOptionsFilter from '~/containers/LicenseRiskOptionsFilter';

import ReportBooleanFilter from '~/components/ReportComponents/ReportBooleanFilter';
import ReportFetchErrorMessage from '~/components/ReportFetchErrorMessage';

import OrgPaidStatus from '~/utils/OrgPaidStatus';
import CSVReportDownloader from '~/containers/CSVReportDownloader';
import LoaderWrapper from '~/components/LoaderWrapper';

import * as navigationActions from '~/actions/navigation';
import * as reportActions from '~/actions/reports';
import * as reportFilterActions from '~/actions/reportFilters';
import * as sortByReportTypeActions from '~/actions/sortByReportType';
import * as reportSelectedRowsActions from '~/actions/reportSelectedRows';
import * as unmatchedLibActions from '~/actions/unmatchedLibraries';
import useLanguageTypeSelect from '~/hooks/useLanguageTypeSelect';
import * as MODEL from '~/constants/ModelConstants';

import Tooltip from '~/components/Tooltip';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';

const MATCHED_REPORT_TYPE = 'LIBRARIES';
const UNMATCHED_REPORT_TYPE = 'UNMATCHED_LIBRARIES';

interface ReportLibrariesPageProps extends RouteComponentProps {
  libraryCatalogState: {
    isAddingToCatalog: boolean;
    isAddingToCatalogSuccess: boolean;
    isAddingToCatalogFailure: boolean;
  };
  repoDataById: object;
  reportSelectedRows: any[];
  myState: App.MyState;
  orgState: App.OrgState;
  reportFilterState: object;
  sortByReportType: object;

  reports: {
    isFetching: boolean;
    errorMessage: string;
  };
  reportScope?: {
    repos: {
      [key: string]: any | Array<any>;
    };
  };
  unmatchedLibState?: {
    matched_unmatched_views: {
      label: string;
      value: string;
    };
  };
  vcPageState: VCPageState;
  reportsByType: {
    LIBRARIES: { librariesData: { [key: string]: any } };
    UNMATCHED_LIBRARIES: { unmatchedLibraryData: { [key: string]: any } };
  };
}

function ReportLibrariesPage() {
  const {
    unmatchedLibState,
    reportFilterState,
    reportsByType,
    reportSelectedRows = [],
    reports,
    sortByReportType,
    reportScope,
    orgState,
    vcPageState: { shouldShowVeracodePage },
    libraryCatalogState,
  } = useSelector(state => state as ReportLibrariesPageProps);
  const { projectId, teamId } = useParams();
  const dispatch = useDispatch();
  const { options: languages, open: languageTypeSelectIsOpen, onToggle } = useLanguageTypeSelect();

  // extract data from state
  const { repos: currentRepoListScope } = reportScope;
  const isSingleRepoScope = isArray(currentRepoListScope) && currentRepoListScope.length == 1;
  const { isFetching, errorMessage } = reports;
  const {
    [MATCHED_REPORT_TYPE]: librariesData = {} as { [key: string]: any },
    [UNMATCHED_REPORT_TYPE]: unmatchedLibraryData = {} as { [key: string]: any },
  } = reportsByType;
  const { _embedded = {}, page = {} } = librariesData;
  const { libraries = [] } = _embedded;

  const librariesFilter =
    projectId && reportFilterState[projectId]
      ? reportFilterState[projectId][MATCHED_REPORT_TYPE] || {}
      : reportFilterState[MATCHED_REPORT_TYPE] || {};
  const {
    vulnsOnly = false,
    directOnly = false,
    outOfDateOnly = false,
    commLicenseOnly = false,
    languageType,
    licenseRisks = [],
  } = librariesFilter;
  const currentSortMatchedLib =
    projectId && sortByReportType[projectId]
      ? sortByReportType[projectId][MATCHED_REPORT_TYPE] || {}
      : sortByReportType[MATCHED_REPORT_TYPE] || {};
  const currentSortUnmatchedLib =
    projectId && sortByReportType[projectId]
      ? sortByReportType[projectId][UNMATCHED_REPORT_TYPE] || {}
      : sortByReportType[UNMATCHED_REPORT_TYPE] || {};
  const isLibraryDetailsPage = !!projectId;

  const { isAddingToCatalogSuccess, isAddingToCatalogFailure } = libraryCatalogState;
  const { org } = orgState;

  const isPaidOrTrialing = OrgPaidStatus.isOrgPaidOrTrial(org);
  const { matched_unmatched_views: matchedView } = unmatchedLibState;
  const { _embedded: unmatched_embedded = {}, page: unmatched_page = {} } = unmatchedLibraryData;
  const { libraries: unmatched_libraries = [] } = unmatched_embedded;
  const isMatchedView = matchedView.label == 'Matched Libraries' && matchedView.value == 'MATCHED';

  const showLibraryCatalog = Helpers.hasLibraryCatalogEnabled();

  // hooks and functions

  useEffect(
    () => {
      const { matched_unmatched_views: matchedView } = unmatchedLibState;

      const isMatchedLib =
        matchedView.label == 'Matched Libraries' && matchedView.value == 'MATCHED';
      if (isMatchedLib) {
        dispatch(reportActions.fetchReport(teamId, MATCHED_REPORT_TYPE));
      } else {
        dispatch(reportActions.fetchReport(teamId, UNMATCHED_REPORT_TYPE));
      }

      if (projectId) {
        dispatch(reportFilterActions.initProjectFilterDefaults(projectId));
        dispatch(sortByReportTypeActions.initProjectSortDefaults(projectId));
      }

      dispatch(navigationActions.updateActiveReportType(MATCHED_REPORT_TYPE));
      return () => {
        dispatch(reportSelectedRowsActions.clearSelectedRows());
        dispatch(navigationActions.updateActiveReportType());
        // close dropdown when component unmounts
        onToggle(false);
      };
    },
    // disable exhaustive-deps lint warning because unresolved issues when referencing functions declared outside useEffect, see: https://github.com/facebook/react/issues/15865#issuecomment-527297171
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, teamId, unmatchedLibState]
  );

  const refreshReportData = (reportType: string) =>
    dispatch(reportActions.fetchReport(teamId, reportType));

  function onLanguageTypeSelect(item) {
    dispatch(
      reportFilterActions.updateFilterValue(MATCHED_REPORT_TYPE, 'languageType', item, projectId)
    );
    refreshReportData(MATCHED_REPORT_TYPE);
  }

  function updateSortField(reportType: string, field: string) {
    dispatch(sortByReportTypeActions.updateSortField(reportType, field, projectId));
    refreshReportData(reportType);
  }

  function updateMatchedUnmatchedDropdown(matchedView: { label: string; value: string }) {
    const isMatchedLib = matchedView.label == 'Matched Libraries' && matchedView.value == 'MATCHED';
    dispatch(unmatchedLibActions.updateFilterValue(matchedView));
    if (isMatchedLib) {
      refreshReportData(MATCHED_REPORT_TYPE);
    } else {
      refreshReportData(UNMATCHED_REPORT_TYPE);
    }
  }

  const onRowSelectChange = (
    library // instanceId is the unique identifier for the rows in this page.
  ) => dispatch(reportSelectedRowsActions.updateSelectedRows(library, 'instanceRef'));

  function toggleBooleanFilter(field: string, isChecked: boolean) {
    dispatch(
      reportFilterActions.updateFilterValue(MATCHED_REPORT_TYPE, field, isChecked, projectId)
    );
    refreshReportData(MATCHED_REPORT_TYPE);
  }

  // column widths & JSX
  const matchedLibColumnWidths = {
    name: showLibraryCatalog ? 'col-1-6' : 'col-1-4',
    notInCatalog: 'col-1-14',
    version: 'col-1-9',
    latestVersion: 'col-1-9',
    vulns: 'col-1-7',
    dependencyPath: 'col-1-7',
    license: 'col-1-8',
    commLicense: 'col-1-9',
    select: 'col-1-20',
  };

  const unmatchedLibColumnWidths = {
    coord1: 'col-1-5',
    coord2: 'col-1-6',
    libraryVersion: 'col-1-6',
    filename: 'col-1-5',
    filePath: '',
  };

  const matchedLibraryRows = libraries.map((library, index) => {
    const isRowSelected = reportSelectedRows.some(row => row.instanceRef === library.instanceRef);
    const rowId = `${index}-${library.coordinateType}-${library.coord1}-${library.coord2}-${library.version}`;
    return (
      <ReportLibrariesRow
        rowValues={library}
        key={index}
        rowId={rowId}
        isSingleRepoScope={isSingleRepoScope}
        columnWidths={matchedLibColumnWidths}
        isSelected={isRowSelected}
        onRowSelectChange={library => onRowSelectChange(library)}
        isPaidOrTrialing={isPaidOrTrialing}
        shouldShowVeracodePage={shouldShowVeracodePage}
      />
    );
  });

  const unmatchedLibraryRows = unmatched_libraries.map((library, index) => {
    const rowId = `${index}-${library.coordinateType}-${library.coord1}-${library.coord2}-${library.libraryVersion}`;
    return (
      <ReportUnmatchedLibrariesRow
        rowValues={library}
        key={index}
        rowId={rowId}
        columnWidths={unmatchedLibColumnWidths}
      />
    );
  });

  const matchedReportFilters = (
    <Fragment>
      <div className="pl- pr- width--200">
        <LicenseRiskOptionsFilter
          licenseRisks={licenseRisks}
          reportType={MATCHED_REPORT_TYPE}
          projectId={projectId}
          teamId={teamId}
        />
      </div>
      <div className="pl- flex align-items--center">
        <Tooltip content="Only show direct dependencies" id="direct-only-filter">
          <ReportBooleanFilter
            label={'Direct only'}
            field={'directOnly'}
            isChecked={directOnly}
            onClick={toggleBooleanFilter}
            automationId="ReportBooleanFilter-DirectLibrariesOnly"
          />
        </Tooltip>
      </div>
      <div className="pl- flex align-items--center">
        <Tooltip content="Only show out-of-date libraries" id="out-of-date-only-filter">
          <ReportBooleanFilter
            label={'Out-of-date'}
            field={'outOfDateOnly'}
            isChecked={outOfDateOnly}
            onClick={toggleBooleanFilter}
            automationId="ReportBooleanFilter-OutOfDateOnly"
          />
        </Tooltip>
      </div>
      <div className="pl- flex align-items--center">
        <Tooltip content="Only show libraries with vulnerabilities" id="vulns-only-filter">
          <ReportBooleanFilter
            label={'Has vulnerabilities'}
            field={'vulnsOnly'}
            isChecked={vulnsOnly}
            onClick={toggleBooleanFilter}
            automationId="ReportBooleanFilter-VulnsOnly"
          />
        </Tooltip>
      </div>
      {showLibraryCatalog && (
        <div className="pl- flex align-items--center">
          <Tooltip
            content="Only show libraries with commercial licenses"
            id="comm-license-only-filter"
          >
            <ReportBooleanFilter
              label={'Commercial License'}
              field={'commLicenseOnly'}
              isChecked={commLicenseOnly}
              onClick={toggleBooleanFilter}
              automationId="ReportBooleanFilter-CommLicenseOnly"
            />
          </Tooltip>
        </div>
      )}
    </Fragment>
  );

  const reportHeaderDropdown = (
    <div>
      {isAddingToCatalogSuccess && (
        <i className="fa sci--sm sci sci__check mh- color--primary font--14 ph0" />
      )}
      {isAddingToCatalogFailure && (
        <div className="inline-block pr--">
          <Tooltip id={`lib-catalog-fail-add`} content={`An error has occurred. Please try again.`}>
            <i className="sci__alerts sci color--warning font--16" />
          </Tooltip>
        </div>
      )}
    </div>
  );

  const matchedReportHeaderRow = (
    <ReportHeaderRow>
      <ReportHeaderItem
        label="Library"
        field="name"
        isSortable={true}
        onClick={field => updateSortField(MATCHED_REPORT_TYPE, field)}
        currentSort={currentSortMatchedLib}
        widthClass={matchedLibColumnWidths.name}
      />
      <ReportHeaderItem
        label="Version or (Commit) in use"
        field="version"
        widthClass={matchedLibColumnWidths.version}
      />
      <ReportHeaderItem
        label="Latest available"
        field="latestVersion"
        widthClass={matchedLibColumnWidths.latestVersion}
      />
      <Tooltip content="CVSS v3" id="severity-tool-tip">
        <ReportHeaderItem
          label="Vulnerabilities"
          field="vulns"
          isSortable={true}
          onClick={field => updateSortField(MATCHED_REPORT_TYPE, field)}
          currentSort={currentSortMatchedLib}
          widthClass={matchedLibColumnWidths.vulns}
        />
      </Tooltip>
      <ReportHeaderItem
        label="Dependency Graphs"
        field="dependencyPath"
        widthClass={matchedLibColumnWidths.dependencyPath}
      />
      <ReportHeaderItem
        label={`${showLibraryCatalog ? 'OSS' : ''} License`}
        field="license"
        widthClass={matchedLibColumnWidths.license}
      />
      <ReportHeaderItem
        label={`${showLibraryCatalog ? 'OSS' : ''} License Risk`}
        field="licenseRisk"
        widthClass="col-1-12"
        isSortable={true}
        onClick={field => updateSortField(MATCHED_REPORT_TYPE, field)}
        currentSort={currentSortMatchedLib}
        tooltip="The license information Veracode provides is not intended to be legal advice. Please consult your legal advisor for the specific details of the license, your use case, and associated risks and obligations."
      />
      {showLibraryCatalog && (
        <ReportHeaderItem
          label="Commercial License"
          field="commercialLicense"
          widthClass={matchedLibColumnWidths.commLicense}
        />
      )}
    </ReportHeaderRow>
  );

  const unmatchedRreportHeaderRow = (
    <ReportHeaderRow>
      <ReportHeaderItem
        label="Coordinate 1"
        tooltip="Either the name of the library or the Java Group ID"
        field="coord1"
        isSortable={true}
        onClick={field => updateSortField(UNMATCHED_REPORT_TYPE, field)}
        currentSort={currentSortUnmatchedLib}
        widthClass={unmatchedLibColumnWidths.coord1}
      />
      <ReportHeaderItem
        label="Coordinate 2"
        tooltip="The Java Artifact ID"
        field="coord2"
        widthClass={unmatchedLibColumnWidths.coord2}
      />
      <ReportHeaderItem
        label="Version"
        field="libraryVersion"
        widthClass={unmatchedLibColumnWidths.libraryVersion}
      />
      <ReportHeaderItem
        label="File Name"
        field="filename"
        widthClass={unmatchedLibColumnWidths.filename}
      />
      <ReportHeaderItem
        label="File Path"
        field="filename"
        widthClass={unmatchedLibColumnWidths.filePath}
      />
    </ReportHeaderRow>
  );

  const reportHeader = isMatchedView ? (
    <ReportHeader
      reportType={MATCHED_REPORT_TYPE}
      stringFilterPlaceholder={'Search libraries'}
      renderReportHeaderItems={() => (
        <div className="grid__item col-1-1 flex flex--justify-content--space-between p--">
          <div className="flex">
            <div>
              <DropdownList
                onToggle={() => onToggle(!languageTypeSelectIsOpen)}
                isOpen={languageTypeSelectIsOpen}
                items={languages}
                value={languageType}
                onSelect={onLanguageTypeSelect}
                automationId="ReportDropdownListFilter-LanguageType"
              />
            </div>
            {matchedReportFilters}
          </div>
          <div className="flex align-items--center">
            {reportHeaderDropdown}
            <CSVReportDownloader
              reportType={MATCHED_REPORT_TYPE}
              page={page}
              projectId={projectId}
              noElementText="No library to download"
            />
          </div>
        </div>
      )}
      renderSearchResultsMetadata={() => (
        <span>{`${page.totalElements || 0}  librar${page.totalElements === 1 ? 'y' : 'ies'}`}</span>
      )}
      renderReportHeaderRow={() => <Fragment>{matchedReportHeaderRow}</Fragment>}
    />
  ) : (
    <ReportHeader
      reportType={UNMATCHED_REPORT_TYPE}
      stringFilterPlaceholder={'Search libraries'}
      renderReportHeaderItems={() => (
        <div className="grid__item col-1-1 flex justify-content--end p--">
          <div className="flex align-items--center height--40">
            <CSVReportDownloader
              reportType={UNMATCHED_REPORT_TYPE}
              page={unmatched_page}
              projectId={projectId}
              noElementText="No library to download"
            />
          </div>
        </div>
      )}
      renderSearchResultsMetadata={() => (
        <span>{`${unmatched_page.totalElements || 0}  unmatched librar${
          unmatched_page.totalElements === 1 ? 'y' : 'ies'
        }`}</span>
      )}
      renderReportHeaderRow={() => <Fragment>{unmatchedRreportHeaderRow}</Fragment>}
    />
  );

  if (errorMessage) {
    return (
      <div className="grid grid--center">
        {reportHeader}
        <div className="grid__item col-3-5 mt">
          <ReportFetchErrorMessage />
        </div>
      </div>
    );
  }

  return (
    <div className="grid">
      {isLibraryDetailsPage ? (
        <Helmet>
          <title>Project Libraries</title>
        </Helmet>
      ) : (
        <Helmet>
          <title>Libraries</title>
        </Helmet>
      )}
      {isLibraryDetailsPage ? (
        <div className="grid__item col-1-1">
          <div className="bg-color--black-light color--white pl- pv- flex">
            <div
              className="width--200 mr-- zIndex-9--overlay"
              data-automation-id="LibrariesPage-UnmatchedLibrariesFilter"
            >
              <Select
                value={matchedView}
                options={MODEL.matchedLibOptions}
                onChange={(val: { label: string; value: string }) =>
                  updateMatchedUnmatchedDropdown(val)
                }
                isMulti={false}
                isClearable={false}
                className={'srcclr-react-select-container'}
                classNamePrefix={'srcclr-react-select'}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid__item col-1-1 flex mb- flex align-items--center">
          <div className="font--h6 mb--" data-automation-id="LibrariesPage-Title">
            LIBRARY LIST
          </div>
          <div
            className="width--200 zIndex-8--panel ml- align-items--center"
            data-automation-id="LibrariesPage-UnmatchedLibrariesFilter"
          >
            <Select
              value={matchedView}
              options={MODEL.matchedLibOptions}
              onChange={(val: { label: string; value: string }) =>
                updateMatchedUnmatchedDropdown(val)
              }
              isMulti={false}
              isClearable={false}
              className={'srcclr-react-select-container'}
              classNamePrefix={'srcclr-react-select'}
            />
          </div>
        </div>
      )}
      {reportHeader}
      {isMatchedView ? (
        <div className="grid__item col-1-1">
          <LoaderWrapper isLoaderShowing={isFetching && !page.totalPages}>
            <div data-automation-id="ReportRows">{matchedLibraryRows}</div>
            {libraries.length === 0 && (
              <h3 className="ml color--muted mt+"> No libraries found. </h3>
            )}
          </LoaderWrapper>
        </div>
      ) : (
        <div className="grid__item col-1-1">
          <LoaderWrapper isLoaderShowing={isFetching && !unmatched_page.totalPages}>
            <div data-automation-id="ReportRows">{unmatchedLibraryRows}</div>
            {unmatched_libraries.length === 0 && (
              <h3 className="ml color--muted mt+"> No libraries found. </h3>
            )}
          </LoaderWrapper>
        </div>
      )}
    </div>
  );
}

export default ReportLibrariesPage;
