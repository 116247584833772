export const UPDATE_REPORT_SELECTED_ROWS = 'UPDATE_REPORT_SELECTED_ROWS';
export const CLEAR_REPORT_SELECTED_ROWS = 'CLEAR_SELECTED_ROWS';

/*
  There's a chance your object does not have a .id attribute, in this case
  specify the key to use as the id
*/
export const updateSelectedRows = (value, idKey = 'id') => ({
  type: UPDATE_REPORT_SELECTED_ROWS,
  value,
  idKey,
});

export const clearSelectedRows = () => ({
  type: CLEAR_REPORT_SELECTED_ROWS,
});
