import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Helpers from '~/utils/Helpers';

interface InvoiceRowProps {
  invoice: object;
}

const InvoiceRow: React.FunctionComponent<InvoiceRowProps> = props => {
  const { invoice } = props;
  const { id, date, total, status, subscription = {}, items = [] } = invoice;
  const { plan = {} } = subscription;
  const { amount } = plan;
  const itemToDisplay =
    items.find(item => item.amountInCents >= 0 && !item.description.includes('additional scans')) ||
    items[0] ||
    {};
  const { description } = itemToDisplay;

  return (
    <div className="grid pv--">
      <div className="grid__item flex align-items--center">
        <Link className="link--obvious text--bold" to={`/org/settings/billing/invoice/${id}`}>
          {moment(date).format('MMM D, YYYY')}
        </Link>
      </div>
      <div className="grid__item col-1-3 flex align-items--center">
        {description || `Subscription to monthly ${amount ? ` - ${amount}` : ''}`}
      </div>
      <div className="grid__item flex align-items--center">{Helpers.formatCost(total)}</div>
      <div className="grid__item flex align-items--center">
        {status === 'PAID' ? 'Completed' : 'Open'}
      </div>
    </div>
  );
};

export default InvoiceRow;
