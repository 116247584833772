export const SHOW_UPGRADE_MODAL = 'SHOW_UPGRADE_MODAL';
export const CLOSE_UPGRADE_MODAL = 'CLOSE_UPGRADE_MODAL';
export const UPGRADE_MODAL_BTN_CLICKED = 'UPGRADE_MODAL_BTN_CLICKED';

export const showUpgradeModal = (inputId, teamId, location) => ({
  type: SHOW_UPGRADE_MODAL,
  inputId,
  teamId,
  location,
  meta: {
    snowplow: true,
    string1: inputId,
    string2: location,
  },
});

export const snowplowUpgradeModalBtnClicked = (inputId, teamId, btnClicked, location) => ({
  type: UPGRADE_MODAL_BTN_CLICKED,
  inputId,
  teamId,
  section: btnClicked,
  location,
  meta: {
    snowplow: true,
    string1: inputId,
    string2: location,
    string3: btnClicked,
  },
});

export const closeUpgradeModal = () => ({
  type: CLOSE_UPGRADE_MODAL,
});
