const LAST_SECOND_BEFORE_MIDNIGHT = '23:59:59.000Z';

/**
 * Converts a date to 23:59:59Z of the day provided
 *
 * @param {Date} localTime - The date to be converted
 * @returns {Date} Returns the converted date
 *
 * @example
 * // 2023/5/15 05:45:32:312 in users local timezone (ex: -05:00)
 * const localTime = new Date(2023, 5, 15, 5, 45, 32, 312)
 * // returns Date equivalent to 2023/5/15 23:59:59.000Z
 * toLastSecondOfDayUTC(localTime)
 */
export const toLastSecondOfDayUTC = (localTime: Date): Date => {
  if (localTime == null) {
    return localTime;
  }
  const todayDate = localTime.toISOString().substring(0, 10);
  return new Date(`${todayDate}T${LAST_SECOND_BEFORE_MIDNIGHT}`);
};
