import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as MODEL from '~/constants/ModelConstants';

import Helpers from '~/utils/Helpers';
import JiraHelper from '~/utils/JiraHelper';

import SourceClearLoader from '~/components/SourceClearLoader';
import Tooltip from '~/components/Tooltip';

import FieldDropDown from '~/containers/FieldDropDown';
import MultiFieldDropDown from '~/containers/MultiFieldDropDown';

import * as modalActions from '~/actions/modal';
import * as integrationActions from '~/actions/integration';
import * as integrationModalActions from '~/actions/integrationModal';

import IssueCreator from '~/utils/IssueCreator';
import { JiraCloudTicketFormProps } from './JiraCloudTicketForm.types';

class JiraCloudTicketForm extends Component<JiraCloudTicketFormProps> {
  componentDidMount() {
    this.prePopulateField();
  }

  componentDidUpdate(prevProps) {
    const { selectedTemplate, selectedIntegration } = this.props;

    // If template changes, re-populate field
    if (prevProps.selectedTemplate.id !== selectedTemplate.id) {
      this.prePopulateField();
    }

    // After jira project information is fetched, get the fields, epics, and users
    const { [MODEL.JIRA_PROJECTS]: prevJiraProjectResponse = {} } =
      prevProps.integrationState.jiraInfo || {};
    const { [MODEL.JIRA_PROJECTS]: jiraProjectResponse = {} } =
      this.props.integrationState.jiraInfo || {};
    if (_.isEmpty(prevJiraProjectResponse.data) && !_.isEmpty(jiraProjectResponse.data)) {
      const { selectedProject = {}, selectedIssueType = {} } = this.getSelectedJiraInfo();
      const { fields = [] } = selectedTemplate;

      // Get the pre-set asignee and reporter val
      const assigneeValField = fields.filter(field => field.jiraFieldName === 'assignee');
      const assigneeVal = assigneeValField.length > 0 ? assigneeValField[0].preDefinedValue : '';
      const reporterValField = fields.filter(field => field.jiraFieldName === 'reporter');
      const reporterVal = reporterValField.length > 0 ? reporterValField[0].preDefinedValue : '';

      // make request for issue types
      this.props.integrationActions
        .getJiraProjectIssueTypes(
          selectedIntegration?.id,
          selectedProject?.key,
          selectedIssueType.name
        )
        .then(issueType => {
          const {
            _links: {
              fields: { href: fieldsPath },
            },
          } = issueType;

          this.props.integrationActions.getJiraFields(fieldsPath);
          this.props.integrationActions.getJiraEpics(selectedIntegration?.id, selectedProject?.key);

          this.props.integrationActions.asyncGetJiraAssignees(
            selectedIntegration?.id,
            selectedProject?.key,
            assigneeVal
          );
          this.props.integrationActions.asyncGetJiraReporters(
            selectedIntegration?.id,
            selectedProject?.key,
            reporterVal
          );
          this.props.integrationActions.getJiraLabels(selectedIntegration.id, '');
        });
    }
  }

  componentWillUnmount() {
    this.props.integrationModalActions.clearModalJiraField();
  }

  getSelectedIssueTypesData(issueTypeName) {
    const {
      integrationState: { jiraInfo },
    } = this.props;
    const { [MODEL.JIRA_ISSUE_TYPES]: jiraIssueTypesResponse = {} } = jiraInfo;
    const { data: issueTypesData } = jiraIssueTypesResponse;
    return issueTypesData?.filter(issue => issue.name === issueTypeName)[0] || {};
  }

  prePopulateField = () => {
    const { selectedTemplate, reportIssuesState = {} } = this.props;
    const { issueFixData = {} } = reportIssuesState;
    const issueSummary = IssueCreator.getIssueSummary(issueFixData, 'jira');
    const issueDescription = IssueCreator.getIssueDescription({
      issueLink: Helpers.getIssueDataLinkFromReportIssuesState(reportIssuesState),
      data: issueFixData,
      integrationType: 'jira',
    });
    // Pre-populate state with all the fields pre-defined value
    selectedTemplate.fields.forEach(field => {
      if (!['description', 'summary'].includes(field.jiraFieldName)) {
        this.props.integrationModalActions.updateIntegrationModalField(
          `jiraField-${field.jiraFieldName}`,
          field.preDefinedValue
        );
      } else {
        if (['SourceClear Title', 'SourceClear Description'].includes(field.preDefinedValue)) {
          this.props.integrationModalActions.updateIntegrationModalField(
            `jiraField-${field.jiraFieldName}`,
            field.preDefinedValue === 'SourceClear Title' ? issueSummary : issueDescription
          );
        } else {
          this.props.integrationModalActions.updateIntegrationModalField(
            `jiraField-${field.jiraFieldName}`,
            null
          );
        }
      }
    });
  };

  onClose = () => {
    this.props.modalActions.closeModal(MODEL.CREATE_ISSUE_JIRA_CLOUD_MODAL);
  };

  createTicket = () => {
    if (this.isTicketRequiredFieldEmpty()) {
      return;
    }

    const {
      integrationModalState,
      selectedIntegration,
      selectedIssue,
      selectedTemplate,
    } = this.props;
    const { fields = {} } = integrationModalState;
    const {
      selectedIssueType = {},
      selectedProject = {},
      fields: modalFields,
    } = this.getSelectedJiraInfo();

    this.props.integrationActions.createJiraTicket(
      selectedIntegration,
      selectedProject,
      selectedIssueType.id,
      modalFields,
      fields,
      selectedIssue,
      selectedTemplate
    );
  };

  isTicketRequiredFieldEmpty() {
    const { integrationModalState } = this.props;
    const { fields = {} } = integrationModalState;

    for (let key in fields) {
      const fieldVal = fields[key];
      const jiraKey = key.split('-')[1];
      if (fields && fields[jiraKey] && fields[jiraKey].required && _.isEmpty(fieldVal)) {
        return true;
      }
    }

    return false;
  }

  getJiraReporters = () => {
    const { integrationState } = this.props;
    return JiraHelper.getJiraReportersFormatWithMetaData(integrationState);
  };

  getJiraAsignees = () => {
    const { integrationState } = this.props;
    return JiraHelper.getJiraAsigneesFormatWithMetaData(integrationState);
  };

  getJiraEpics = () => {
    const { integrationState } = this.props;
    return JiraHelper.getJiraEpicsFormatWithMetaData(integrationState);
  };

  getAllJiraLabels() {
    const { integrationState, integrationModalState } = this.props;
    const labelsInTemplate = JiraHelper.getJiraLabelFromTemplate(integrationModalState);
    const {
      val: labelsInInstance,
      isFetching = false,
    } = JiraHelper.getJiraLabelsFromInstanceWithMetaData(integrationState);

    return {
      val: _.union(labelsInInstance, labelsInTemplate),
      isFetching,
    };
  }

  getSelectedJiraInfo = () => {
    const { integrationState, selectedTemplate } = this.props;
    const { jiraInfo = {} } = integrationState;
    const {
      [MODEL.JIRA_PROJECTS]: jiraProjectResponse = {},
      [MODEL.JIRA_FIELDS]: fieldsResponse = {},
    } = jiraInfo;
    const {
      data: projectList = [],
      isFetching: jiraProjectIsFetching = false,
      err: jiraProjectErr,
    } = jiraProjectResponse;

    const { data: fields = {}, isFetching: isFetchingFields } = fieldsResponse;

    let selectedProject = {};

    if (projectList.length > 0) {
      selectedProject = projectList.filter(
        project => project.id === selectedTemplate.jiraProjectId.toString()
      )[0];
    }

    return {
      selectedProject,
      selectedIssueType: {
        id: selectedTemplate.jiraIssueTypeId.toString(),
        name: selectedTemplate.jiraIssueTypeName,
      },
      fields,
      isFetchingFields,
      jiraProjectIsFetching,
      jiraProjectErr,
    };
  };

  updateFormInput(fieldType, e) {
    this.props.integrationModalActions.updateIntegrationModalField(fieldType, e.target.value);
  }

  getJiraPriority() {
    const { integrationState } = this.props;

    const { jiraInfo = {} } = integrationState;
    const { [MODEL.JIRA_FIELDS]: fieldsResponse = {} } = jiraInfo;
    const { data: fieldsData } = fieldsResponse;

    return fieldsData?.priority;
  }

  getFixVersionsDropdownVal() {
    const { integrationState } = this.props;

    const { jiraInfo = {} } = integrationState;
    const { [MODEL.JIRA_FIELDS]: fieldsResponse = {} } = jiraInfo;
    const { data: fieldsData } = fieldsResponse;
    return fieldsData?.fixVersions;
  }

  isLoadingDropdownVal() {
    return (
      this.getJiraEpics().isFetching ||
      this.getJiraReporters().isFetching ||
      this.getJiraAsignees().isFetching
    );
  }

  render() {
    const {
      integrationModalState,
      integrationState,
      reportIssuesState,
      selectedTemplate,
      selectedIntegration,
    } = this.props;
    const { fields: modalFields = {} } = integrationModalState;
    const {
      selectedIssueType = {},
      jiraProjectIsFetching = false,
      jiraProjectErr = '',
      selectedProject = {},
      fields = [],
      isFetchingFields,
    } = this.getSelectedJiraInfo();

    const priorityDropdownVal = this.getJiraPriority();
    const fixVersionsDropdownVal = this.getFixVersionsDropdownVal();

    const {
      val: reportersDropdownVal = [],
      isFetching: isFetchingReporters = false,
    } = this.getJiraReporters();
    const {
      val: assigneesDropdownVal = [],
      isFetching: isFetchingAssignees = false,
    } = this.getJiraAsignees();

    const { val: epicDropDownVal, isFetching: isFetchingEpics = false } = this.getJiraEpics();

    const integrationId = selectedIntegration.id;
    const projectKey = selectedProject.key;

    // Get the selected issue and issueFixData
    const { isFixInfoFetching = {}, issueFixData = {} } = reportIssuesState;
    const { jiraInfo = {} } = integrationState;
    const {
      [MODEL.CREATE_JIRA_TICKET]: createTicketResonse = {},
      [MODEL.JIRA_LABELS]: labelsResponse = {},
    } = jiraInfo;
    const { isFetching: isCreatingTicket = false } = createTicketResonse;
    const { data: labelsData, isFetching: isFetchingLabels } = labelsResponse;
    const formattedLabels = labelsData?.map(label => label.value);

    let filteredTemplateFields: IssueTemplateFields[] = [];
    let sortedTemplateFields: IssueTemplateFields[] = [];

    if (selectedTemplate.fields && fields) {
      // Hidden fields in Jira won't be in the Jira Project data `fields` object, so we filter them out
      // this prevents accessing properties (e.g. `.required`) on non-existent fields in `fields`
      filteredTemplateFields = _.filter(selectedTemplate.fields, element => {
        return element.jiraFieldName in fields;
      });
      // Sort by required field first followed by alphabetical name order
      sortedTemplateFields = _.sortBy(filteredTemplateFields, [
        element => !fields[element.jiraFieldName].required,
        element => fields[element.jiraFieldName].name.toUpperCase(),
      ]);
    }

    const fieldsInput = sortedTemplateFields.map(field => {
      if (_.isEmpty(selectedIssueType) || _.isEmpty(issueFixData)) {
        return;
      }

      // If user can't edit and no predefined value, dont bother showing it.
      if (!field.canEdit && _.isEmpty(field.preDefinedValue)) {
        return;
      }

      const label = (
        <div className="grid__item col-1-5">
          {fields[field.jiraFieldName].name}{' '}
          {fields[field.jiraFieldName].required && <span className="color--danger">* </span>}
        </div>
      );

      if (['summary', 'description'].includes(field.jiraFieldName)) {
        const inputField = (
          <input
            type="text"
            className="col-1-1 control--text"
            onChange={e => this.updateFormInput(`jiraField-${field.jiraFieldName}`, e)}
            value={modalFields[`jiraField-${field.jiraFieldName}`] || ''}
          />
        );
        const textAreaField = (
          <textarea
            rows="15"
            type="text"
            className="col-1-1 control--text ta-resize--none"
            onChange={e => this.updateFormInput(`jiraField-${field.jiraFieldName}`, e)}
            value={modalFields[`jiraField-${field.jiraFieldName}`] || ''}
          />
        );

        return (
          <div
            className="grid flex flex--align-items--center flex--justify-content--center pt-"
            key={field.id}
          >
            {label}
            {field.canEdit && (
              <div className="grid__item col-4-5">
                {field.preDefinedValue === 'SourceClear Title' && inputField}
                {field.preDefinedValue === 'SourceClear Description' && textAreaField}
                {field.jiraFieldName === 'description' &&
                  !['SourceClear Title', 'SourceClear Description'].includes(
                    field.preDefinedValue
                  ) &&
                  textAreaField}
                {field.jiraFieldName === 'summary' &&
                  !['SourceClear Title', 'SourceClear Description'].includes(
                    field.preDefinedValue
                  ) &&
                  inputField}
              </div>
            )}

            {!field.canEdit && (
              <div className="grid__item col-4-5">
                {' '}
                {modalFields[`jiraField-${field.jiraFieldName}`]}{' '}
              </div>
            )}
          </div>
        );
      } else if (['assignee'].includes(field.jiraFieldName)) {
        const items = JiraHelper.formatUserDropdownList(assigneesDropdownVal);
        return (
          <div
            className="grid flex flex--align-items--center flex--justify-content--center pt-"
            key={field.id}
          >
            {label}
            <div className="grid__item col-4-5">
              {field.canEdit ? (
                <FieldDropDown
                  fieldName={field.jiraFieldName}
                  value={modalFields[`jiraField-${field.jiraFieldName}`] || ''}
                  items={items}
                  async={true}
                  isLoading={isFetchingAssignees}
                  asyncGetOptions={input =>
                    this.props.integrationActions.asyncGetJiraAssignees(
                      integrationId,
                      projectKey,
                      input
                    )
                  }
                />
              ) : (
                <span> {field.preDefinedValue}</span>
              )}
            </div>
          </div>
        );
      } else if (['reporter'].includes(field.jiraFieldName)) {
        const items = JiraHelper.formatUserDropdownList(reportersDropdownVal);
        return (
          <div
            className="grid flex flex--align-items--center flex--justify-content--center pt-"
            key={field.id}
          >
            {label}
            <div className="grid__item col-4-5">
              {field.canEdit ? (
                <FieldDropDown
                  fieldName={field.jiraFieldName}
                  value={modalFields[`jiraField-${field.jiraFieldName}`]}
                  items={items}
                  async={true}
                  isLoading={isFetchingReporters}
                  asyncGetOptions={input =>
                    this.props.integrationActions.asyncGetJiraReporters(
                      integrationId,
                      projectKey,
                      input
                    )
                  }
                />
              ) : (
                <span> {field.preDefinedValue}</span>
              )}
            </div>
          </div>
        );
      } else if (['priority'].includes(field.jiraFieldName)) {
        const items = priorityDropdownVal.allowedValues
          ? JiraHelper.formatPriorityDropdownList(priorityDropdownVal.allowedValues)
          : [];
        return (
          <div
            className="grid flex flex--align-items--center flex--justify-content--center pt-"
            key={field.id}
          >
            {label}
            <div className="grid__item col-4-5">
              {field.canEdit ? (
                <FieldDropDown
                  fieldName={`${field.jiraFieldName}`}
                  value={modalFields[`jiraField-${field.jiraFieldName}`]}
                  items={items}
                />
              ) : (
                <span> {field.preDefinedValue}</span>
              )}
            </div>
          </div>
        );
      } else if (['labels'].includes(field.jiraFieldName)) {
        return (
          <div
            className="grid flex flex--align-items--center flex--justify-content--center pt-"
            key={field.id}
          >
            {label}
            <div className="grid__item col-4-5">
              {field.canEdit ? (
                <MultiFieldDropDown
                  promptTextCreator={'Create label'}
                  fieldName={`${field.jiraFieldName}`}
                  stringItems={formattedLabels}
                  createable={true}
                  onNewOptionClick={option =>
                    this.props.integrationActions.addJiraLabels(option.value)
                  }
                  async={true}
                  isLoading={isFetchingLabels}
                  asyncGetOptions={input =>
                    this.props.integrationActions.getJiraLabels(integrationId, input)
                  }
                />
              ) : (
                <span> {field.preDefinedValue}</span>
              )}
            </div>
          </div>
        );
      } else if (['fixVersions'].includes(field.jiraFieldName)) {
        const items = fixVersionsDropdownVal.allowedValues
          ? JiraHelper.formatPriorityDropdownList(fixVersionsDropdownVal.allowedValues)
          : [];

        return (
          <div
            className="grid flex flex--align-items--center flex--justify-content--center pt-"
            key={field.id}
          >
            {label}
            <div className="grid__item col-4-5">
              {field.canEdit ? (
                <MultiFieldDropDown fieldName={`${field.jiraFieldName}`} items={items} />
              ) : (
                <span> {field.preDefinedValue}</span>
              )}
            </div>
          </div>
        );
      } else if (['Epic Link'].includes(fields[field.jiraFieldName].name)) {
        const items = JiraHelper.formatEpicDropdownList(epicDropDownVal) || [];
        return (
          <div
            className="grid flex flex--align-items--center flex--justify-content--center pt-"
            key={field.id}
          >
            {label}
            <div className="grid__item col-4-5">
              {field.canEdit ? (
                <FieldDropDown
                  fieldName={`${field.jiraFieldName}`}
                  value={modalFields[`jiraField-${field.jiraFieldName}`]}
                  items={items}
                  isLoading={isFetchingEpics}
                  noOptionsMessage={() => 'No epics found'}
                />
              ) : (
                <span> {field.preDefinedValue}</span>
              )}
            </div>
          </div>
        );
      } else if (fields[field.jiraFieldName].schema.type === 'string') {
        const isTextArea =
          fields[field.jiraFieldName].schema.custom === MODEL.JIRA_SCHEMA.TEXT_AREA;

        const fieldInput = isTextArea ? (
          <textarea
            rows={5}
            className="col-1-1 control--text ta-resize--none"
            onChange={e => this.updateFormInput(`jiraField-${field.jiraFieldName}`, e)}
            value={modalFields[`jiraField-${field.jiraFieldName}`] || ''}
          />
        ) : (
          <input
            type="text"
            className="col-1-1 control--text"
            onChange={e => this.updateFormInput(`jiraField-${field.jiraFieldName}`, e)}
            value={modalFields[`jiraField-${field.jiraFieldName}`] || ''}
          />
        );

        return (
          <div
            className="grid flex flex--align-items--center flex--justify-content--center pt-"
            key={field.id}
          >
            {label}
            <div className="grid__item col-4-5">
              {field.canEdit ? fieldInput : <span> {field.preDefinedValue}</span>}
            </div>
          </div>
        );
      } else if (fields[field.jiraFieldName].schema.type === 'number') {
        return (
          <div
            className="grid flex flex--align-items--center flex--justify-content--center pt-"
            key={field.id}
          >
            {label}
            <div className="grid__item col-4-5">
              {field.canEdit ? (
                <input
                  type="number"
                  className="col-1-1 control--text"
                  onChange={e => this.updateFormInput(`jiraField-${field.jiraFieldName}`, e)}
                  value={modalFields[`jiraField-${field.jiraFieldName}`] || ''}
                />
              ) : (
                <span> {field.preDefinedValue}</span>
              )}
            </div>
          </div>
        );
      } else if (fields[field.jiraFieldName].schema.custom === MODEL.JIRA_SCHEMA.MULTI_SELECT) {
        const items = JiraHelper.formatMultiSelectDropdownList(
          fields[field.jiraFieldName].allowedValues
        );

        return (
          <div
            className="grid flex flex--align-items--center flex--justify-content--center pt-"
            key={field.id}
          >
            {label}
            <div className="grid__item col-4-5">
              {field.canEdit ? (
                <MultiFieldDropDown fieldName={`${field.jiraFieldName}`} items={items} />
              ) : (
                <span> {field.preDefinedValue}</span>
              )}
            </div>
          </div>
        );
      }
    });

    if (jiraProjectErr) {
      return (
        <div className="color--danger pt-">
          <span className="sci sci__alerts"> </span>
          <span className="pl-">{jiraProjectErr}</span>
        </div>
      );
    }

    return (
      <div>
        {isFixInfoFetching || jiraProjectIsFetching ? (
          <div>
            <SourceClearLoader />
            <div className="font--h6 text--center"> Fetching issue info... </div>
          </div>
        ) : (
          <div>
            <div className="overflow-y--scroll height--500 pr pb">
              <div className="grid flex flex--align-items--center flex--justify-content--center pt pb-">
                <div className="grid__item col-1-5">Project</div>
                <div className="grid__item col-4-5">{selectedTemplate.jiraProjectName}</div>
              </div>

              <div className="grid flex flex--align-items--center flex--justify-content--center pv-">
                <div className="grid__item col-1-5">Issue Type</div>
                <div className="grid__item col-4-5">{selectedTemplate.jiraIssueTypeName}</div>
              </div>

              {selectedTemplate.resolvedStatus && (
                <div className="grid flex flex--align-items--center flex--justify-content--center pv-">
                  <div className="grid__item col-1-5">Resolved Status</div>
                  <div className="grid__item">
                    {selectedTemplate.resolvedStatus}
                    <div className="inline-block">
                      <Tooltip
                        id={`resolved-status`}
                        maxWidthClass="max-width--300"
                        content={`The status to which the corresponding Jira issue will be set when a SourceClear issue is resolved.`}
                      >
                        <i className="sci sci__detail-information pl-" />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              )}

              {isFetchingFields ? (
                <div>
                  <SourceClearLoader />
                  <div className="font--h6 text--center"> Loading fields... </div>
                </div>
              ) : (
                fieldsInput
              )}
            </div>
            <div className="pt-">
              <span className="color--danger">*</span> Required{' '}
            </div>
            <div className={`pt pb text--right ${isCreatingTicket ? 'invisible' : ''}`}>
              <button className="ph mr" onClick={() => this.onClose()}>
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => this.createTicket()}
                className={`btn--primary-clear ph ${
                  this.isTicketRequiredFieldEmpty() || this.isLoadingDropdownVal() ? 'disabled' : ''
                }`}
              >
                {' '}
                Create{' '}
              </button>
            </div>

            <div className={isCreatingTicket ? '' : 'invisible'}>
              <SourceClearLoader />
              <div className="font--h6 text--center"> Creating issue... </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationState: state.integrationState,
    integrationModalState: state.integrationModalState,
    reportIssuesState: state.reportIssuesState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
    integrationActions: bindActionCreators(integrationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JiraCloudTicketForm);
