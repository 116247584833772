import React from 'react';
import Select from 'react-select';
import Helpers from '~/utils/Helpers';

interface AddUsersToGroupProps {
  isOptional?: boolean;
  selectableUsers: any[];
  selectedUsers: any[];
  updateSelectedUsers: (...args: any[]) => any;
  toggleAdminField: (...args: any[]) => any;
  buttons: React.ReactNode;
}

const AddUsersToGroup: React.FunctionComponent<AddUsersToGroupProps> = props => {
  const { selectableUsers, selectedUsers, isOptional = false } = props;

  function addSelectedUser(user) {
    user && props.updateSelectedUsers(user.value);
  }

  function removeSelectedUser(userId) {
    props.updateSelectedUsers(userId);
  }

  function toggleAdminField(userId) {
    props.toggleAdminField(userId);
  }

  return (
    <div className="grid">
      <div className="grid__item col-1-1 mt-">
        <div>
          <strong className="font--h6">Choose Users</strong>
          {isOptional && ` (optional)`}
        </div>
      </div>
      <div className="grid__item col-1-1 mt-">
        <Select
          value={''}
          options={selectableUsers}
          name="group-users"
          onChange={user => addSelectedUser(user)}
          noOptionsMessage={() => 'No results found'}
          className={'srcclr-react-select-container'}
          classNamePrefix={'srcclr-react-select'}
          maxMenuHeight={120}
        />
      </div>
      <div className="grid__item col-1-1 mt">
        <div>
          <strong className="font--h6">Choose Team Administrators</strong>
          {isOptional && ` (optional)`}
        </div>
        <div className="mt-">
          Users can be members or administrators of a team. Team administrators can change the team
          name and description, invite new users to the organization, add users to a team, remove
          users, and make other members team administrators.
        </div>
      </div>
      <div className="grid__item col-1-1 mt-">
        <div className="bo--1 border-color--black-light pv-">
          {selectedUsers.length ? (
            <div>
              <div className="grid grid--full">
                <div className="grid__item col-1-2 pl-">
                  <strong>Name</strong>
                </div>
                <div className="grid__item col-5-12 text--center">
                  <strong>User Can Administer Team</strong>
                </div>
                <div className="grid__item col-1-12" />
              </div>
              {/* container of list - scrollable with max height */}
              <div className="max-height--150 overflow--auto">
                {selectedUsers.map(user => {
                  return (
                    <div className="grid grid--full pv-- hover-row" key={`selected-${user.id}`}>
                      <div className="grid__item col-1-2 pl-">{Helpers.formatUserName(user)}</div>
                      <div className="grid__item col-5-12 flex align-items--center justify-content--center">
                        <label className="label--checkbox flex justify-content--center mb0">
                          <input
                            type="checkbox"
                            checked={user.admin}
                            onChange={() => toggleAdminField(user.id)}
                          />
                          <span className="react--checkbox mr0" />
                        </label>
                      </div>
                      <div className="grid__item col-1-12 flex justify-content--center">
                        <button
                          className="p--- flex align-items--center"
                          onClick={() => removeSelectedUser(user.id)}
                        >
                          <i className="sci sci__trash color--danger-hover-row" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="color--muted-dark pl-">Select user above</div>
          )}
        </div>
      </div>

      {props.buttons}
    </div>
  );
};

export default AddUsersToGroup;
