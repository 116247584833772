export const CLOSE_DROPDOWN_MENU = 'CLOSE_DROPDOWN_MENU';
export const OPEN_DROPDOWN_MENU = 'OPEN_DROPDOWN_MENU';
export const RESET_DROPDOWN_MENU_STATE = 'RESET_DROPDOWN_MENU_STATE';

export const openDropdownMenu = dropdownId => ({
  type: OPEN_DROPDOWN_MENU,
  dropdownId,
});

export const closeDropdownMenu = dropdownId => ({
  type: CLOSE_DROPDOWN_MENU,
  dropdownId,
});

export const resetDropdownMenuState = () => ({
  type: RESET_DROPDOWN_MENU_STATE,
});
