import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import * as upgradeModalActions from '~/actions/upgradeModal';

interface UpgradeLinkProps {
  title: string;
  section?: string;
  to?: string;
  className?: string;
  location?: string;
  orgState: App.OrgState;
  upgradeModalActions: object;
}
class UpgradeLink extends React.Component<UpgradeLinkProps, {}> {
  handleClick = () => {
    const { title, orgState, section = 'BUY_SOURCECLEAR_PRO', location } = this.props;
    const { org } = orgState;
    const { id } = org;
    this.props.upgradeModalActions.snowplowUpgradeModalBtnClicked(title, id, section, location);
  };

  render() {
    const { to = '/org/settings/subscribe', className = '', children } = this.props;
    return (
      <Link onClick={this.handleClick} to={to} className={className}>
        {children}
      </Link>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    upgradeModalActions: bindActionCreators(upgradeModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeLink);
