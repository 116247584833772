import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as groupActions from '~/actions/group';
import * as teamActions from '~/actions/team';

import AddWorkspacesToGroup from '~/components/AddWorkspacesToGroup';

interface AddWorkspacesToGroupModalProps {
  groupId: string;
  groupActions: object;
  teamActions: object;
  groupState: object;
  teamState: object;
  showModal: (...args: any[]) => any;
}
class AddWorkspacesToGroupModal extends React.Component<AddWorkspacesToGroupModalProps, {}> {
  componentDidMount() {
    this.props.teamActions.resetSelectedWorkspaces();
  }

  addWorkspacesToGroupModal() {
    const { teamState, groupId } = this.props;
    const { selectedOrgWorkspaces = [] } = teamState;

    this.props.groupActions.groupMultiUpdate([
      {
        groupId: parseInt(groupId),
        teams: selectedOrgWorkspaces,
      },
    ]);
  }

  showModal(modalType) {
    this.props.showModal(modalType);
  }

  updateSelectedWorkspaces(workspaceId) {
    this.props.teamActions.updateSelectedWorkspaces(workspaceId);
  }

  toggleAdminField(workspaceId) {
    this.props.teamActions.toggleSelectedWorkspaceAdminField(workspaceId);
  }

  render() {
    const { teamState, groupState, groupId } = this.props;
    const { teams = [], selectedOrgWorkspaces = [] } = teamState;
    const { groups = [] } = groupState;
    const activeGroup = groups.find(group => group.id === parseInt(groupId)) || {};
    const { teams: groupTeams = [] } = activeGroup;
    const activeGroupTeamIds = {};
    const selectedWorkspaces = [];
    const selectableWorkspaces = [];

    groupTeams.forEach(groupTeam => {
      const { team = {} } = groupTeam;
      activeGroupTeamIds[team.id] = true;
    });

    const filteredTeams =
      teams.filter(team => {
        return !activeGroupTeamIds[team.id] && !team.userId;
      }) || [];

    if (!filteredTeams.length) {
      return (
        <div className="grid">
          <div className="grid__item col-1-1 mt-">
            <div className="">Every available workspace is already linked to this team.</div>
          </div>
        </div>
      );
    }

    filteredTeams.forEach(team => {
      const foundTeam = selectedOrgWorkspaces.find(
        selectedWorkspace => selectedWorkspace.teamId === team.id
      );

      if (foundTeam) {
        selectedWorkspaces.push({
          ...team,
          role: foundTeam.role,
        });
      } else {
        selectableWorkspaces.push({
          label: team.name,
          value: team.id,
        });
      }
    });

    const buttons = (
      <div className="grid__item col-1-1 flex justify-content--end mt+">
        <button className="col-1-5 pv- mr-" onClick={() => this.showModal()}>
          Cancel
        </button>
        <button
          className="col-1-5 pv- btn--success"
          onClick={() => this.addWorkspacesToGroupModal()}
        >
          Save
        </button>
      </div>
    );

    return (
      <div className="">
        <AddWorkspacesToGroup
          selectableWorkspaces={selectableWorkspaces}
          selectedWorkspaces={selectedWorkspaces}
          buttons={buttons}
          updateSelectedWorkspaces={workspaceId => this.updateSelectedWorkspaces(workspaceId)}
          toggleAdminField={workspaceId => this.toggleAdminField(workspaceId)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupState: state.groupState,
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkspacesToGroupModal);
