import produce from 'immer';
import * as actions from '~/actions/modal';

const defaultState = {
  openedModal: {},
};

const modalState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.SHOW_MODAL:
        draft.openedModal[action.modalId] = true;
        break;
      case actions.CLOSE_MODAL:
        draft.openedModal[action.modalId] = false;
        break;
      case actions.CLOSE_ALL_MODALS:
        draft.openedModal = {};
    }
  });
};

export default modalState;
