import React from 'react';
import Helpers from '~/utils/Helpers';
import ReportDetailsPageFixLinkHtml from '~/components/ReportComponents/ReportDetailsPageFixLinkHtml';
import { PROJECT_TYPES } from '~/constants/ModelConstants';
import ContainerFixInfo from '~/components/ReportPage/QuickviewFixInfo/ContainerFixInfo';
import PackageManagerNPM from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerNPM';
import PackageManagerYarn from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerYarn';
import PackageManagerRubyGems from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerRubyGems';
import PackageManagerMaven from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerMaven';
import PackageManagerGradle from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerGradle';
import PackageManagerPyPI from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerPyPI';
import PackageManagerBower from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerBower';
import PackageManagerShrinkwrap from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerShrinkwrap';
import PackageManagerCocoaPods from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerCocoaPods';
import PackageManagerSbt from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerSbt';
import PackageManagerPackagist from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerPackagist';
import JarFile from '~/components/ReportPage/QuickviewFixInfo/FileFixInfo/JarFile';
// GO package manager
import PackageManagerGlide from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerGlide';
import PackageManagerGoVendor from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerGoVendor';
import PackageManagerTrash from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerTrash';
import PackageManagerGoDep from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerGoDep';
import PackageManagerGoGet from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerGoGet';
// End of GO package manager
import CopyToClipboard from '~/containers/CopyToClipboard';
import Tooltip from '~/components/Tooltip';
import RegistryLink from '~/components/RegistryLink';

interface ReportDetailsPageDirectContentProps {
  component: object;
  fixText: string | null;
  fixVersion: string | null;
  languageType: string;
  filePath: string;
  safeVersion?: string;
  unsafeVersion?: string;
  isBoth: boolean;
  unsafeCommitHash?: string;
  safeCommitHash?: string;
  projectType?: string;
  fixLinks?: Report.FixLink[];
  registryLibraryUrl?: string;
}

const ReportDetailsPageDirectContent: React.FunctionComponent<ReportDetailsPageDirectContentProps> = props => {
  const {
    component,
    fixText,
    fixVersion = '',
    languageType,
    filePath,
    safeVersion,
    unsafeVersion,
    isBoth = false,
    unsafeCommitHash,
    safeCommitHash,
    fixLinks,
    projectType = '',
    registryLibraryUrl,
  } = props;
  const fixInSafeVersions = safeVersion === fixVersion;
  let showPackageManagers, updateText, validateText, directDependencyText, upgradeInstructionsHtml;

  const packageFile = filePath.length > 0 ? filePath.split('#L')[0] : '';
  const lineNumber = filePath.length > 0 ? filePath.split('#L')[1] : null;

  const fixValidationText = `Be sure to build, test, and re-scan your project to ensure this fix has been successful.`;

  let fixTextElement = null;

  const hasSafeVersion = safeVersion || safeCommitHash;
  const commitHashOrVersionText = Helpers.isCommitHash(fixVersion) ? 'commit' : 'version';

  if (fixText) {
    let fixTextLines = fixText.split('\n');

    const lineElements = fixTextLines.map((line, index) => {
      return (
        <div key={index} className="font--12">
          {line}
        </div>
      );
    });

    fixTextElement = (
      <div className="col-1-1 font-family--code pt--">
        <div className="border-color--muted-light bo--1 p- word-wrap--break-word">
          {lineElements}
        </div>
      </div>
    );
  }

  let noFixDependencyText = (
    <span>
      This vulnerability is in a direct dependency. Vulnerable library{' '}
      <span className="text--bold">{component.name}</span>
      {packageFile.length > 0 ? (
        <span>
          {' '}
          was found in <span className="text--bold"> {packageFile} </span>{' '}
          {lineNumber ? ' at line ' + lineNumber + '. ' : ' '}
        </span>
      ) : (
        <span>. </span>
      )}
    </span>
  );
  let defaultDependencyText = (
    <span>
      {noFixDependencyText}
      It can be fixed by updating the version of the library in your project and rebuilding it.
    </span>
  );

  if (fixVersion && hasSafeVersion && !fixInSafeVersions) {
    directDependencyText = defaultDependencyText;

    updateText = (
      <div>
        <p>
          <strong>Update</strong>
        </p>
        <p>
          This issue was fixed in {commitHashOrVersionText}{' '}
          <span className="font-family--code bg-color--white-medium p---">{fixVersion}</span>.
          However, that {commitHashOrVersionText} is itself subject to{' '}
          <RegistryLink href={registryLibraryUrl} className="link--obvious">
            other vulnerabilities
          </RegistryLink>
          , we suggest that you upgrade to{' '}
          <span className="font-family--code bg-color--white-medium p---">
            {safeCommitHash ? safeCommitHash : safeVersion}
          </span>
          , which is considered safe.
        </p>
      </div>
    );

    validateText = (
      <div>
        <p>{fixValidationText}</p>
      </div>
    );

    showPackageManagers = true;
  } else if (fixVersion && hasSafeVersion && fixInSafeVersions) {
    // Fix version is present and at least a safe version is present and fix version is in safe versions
    directDependencyText = defaultDependencyText;
    updateText = (
      <div>
        <p>
          <strong>Update</strong>
        </p>
        <p>
          This issue was fixed in version{' '}
          <span className="font-family--code bg-color--white-medium p---">{fixVersion}</span>. That
          version is currently considered safe, we suggest that you upgrade to the fixed version.
        </p>
      </div>
    );

    validateText = (
      <div>
        <p>{fixValidationText}</p>
      </div>
    );

    showPackageManagers = true;
  } else if (fixVersion && !hasSafeVersion) {
    // Fix version is present but no safe version Do not show the Maven/Gradle/sbt section on the right.
    directDependencyText = defaultDependencyText;
    updateText = (
      <div>
        <p>
          <strong>Update</strong>
        </p>
        <p>
          This issue was fixed in version{' '}
          <span className="font-family--code bg-color--white-medium p---">{fixVersion}</span>.
          However, that version is itself subject to{' '}
          <RegistryLink href={registryLibraryUrl} className="link--obvious">
            other vulnerabilities
          </RegistryLink>
          , and there are no safe versions to upgrade to.
        </p>
      </div>
    );

    validateText = <div />;
    showPackageManagers = false;
  } else if (!fixVersion && hasSafeVersion) {
    // Fix versions is missing but there is at least a safe version
    directDependencyText = defaultDependencyText;
    updateText = (
      <div>
        <p>
          <strong>Update</strong>
        </p>
        <p>
          We do not have a confirmed fix for this issue yet. However, newer versions of the library
          have been released. We suggest that you upgrade to one of the safe versions.
        </p>
      </div>
    );

    validateText = (
      <div>
        <p>{fixValidationText}</p>
      </div>
    );

    showPackageManagers = true;
  } else if (!fixVersion && !hasSafeVersion && fixText) {
    // Fix version is missing (and there is no safe version) but Fix text is present: Do not show the Maven/Gradle/sbt section on the right.
    directDependencyText = noFixDependencyText;
    updateText = (
      <div>
        <p>
          <strong>Update</strong>
        </p>
        <p>
          This issue has not yet been fixed in a released version of the library. Please use the
          following steps to mitigate the issue:
        </p>
        {fixTextElement}
        <ReportDetailsPageFixLinkHtml fixLinks={fixLinks} />
      </div>
    );

    validateText = (
      <div>
        <p>{fixValidationText}</p>
      </div>
    );

    showPackageManagers = false;
  } else {
    // Safe/Fix version and Fix text both are missing:
    directDependencyText = noFixDependencyText;
    updateText = (
      <div>
        <p>
          <strong>Update</strong>
        </p>
        <p>
          This issue has not yet been fixed in a released version of the library. Moreover, there
          are no known mitigation steps.
        </p>
      </div>
    );

    validateText = <div />;
    showPackageManagers = false;
  }

  if (projectType.toUpperCase() === PROJECT_TYPES.CONTAINER) {
    upgradeInstructionsHtml = <ContainerFixInfo component={component} safeVersion={safeVersion} />;
  } else if (showPackageManagers) {
    if (languageType === 'RUBY') {
      upgradeInstructionsHtml = (
        <PackageManagerRubyGems
          packageFile={packageFile}
          lineNumber={lineNumber}
          component={component}
          safeVersion={safeVersion}
          unsafeVersion={unsafeVersion}
          transitive={false}
        />
      );
    } else if (languageType === 'PYTHON') {
      upgradeInstructionsHtml = (
        <PackageManagerPyPI
          packageFile={packageFile}
          lineNumber={lineNumber}
          component={component}
          safeVersion={safeVersion}
          unsafeVersion={unsafeVersion}
          transitive={false}
        />
      );
    } else if (languageType === 'JS' || languageType === 'JAVASCRIPT') {
      if (packageFile.includes('bower.json')) {
        upgradeInstructionsHtml = (
          <PackageManagerBower
            packageFile={packageFile}
            lineNumber={lineNumber}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={false}
          />
        );
      } else if (packageFile.includes('shrinkwrap')) {
        upgradeInstructionsHtml = (
          <PackageManagerShrinkwrap
            packageFile={packageFile}
            lineNumber={lineNumber}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={false}
          />
        );
      } else if (packageFile.includes('yarn.lock')) {
        upgradeInstructionsHtml = (
          <PackageManagerYarn
            packageFile={packageFile}
            lineNumber={lineNumber}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={false}
          />
        );
      } else {
        upgradeInstructionsHtml = (
          <PackageManagerNPM
            packageFile={packageFile}
            lineNumber={lineNumber}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={false}
          />
        );
      }
    } else if (languageType === 'JAVA') {
      if (packageFile.indexOf('pom.xml') > -1) {
        upgradeInstructionsHtml = (
          <PackageManagerMaven
            packageFile={packageFile}
            lineNumber={lineNumber}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={false}
          />
        );
      } else if (packageFile.indexOf('build.gradle') > -1) {
        upgradeInstructionsHtml = (
          <PackageManagerGradle
            packageFile={packageFile}
            lineNumber={lineNumber}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={false}
            isBoth={isBoth}
          />
        );
      } else if (packageFile.indexOf('build.sbt') > -1) {
        upgradeInstructionsHtml = (
          <PackageManagerSbt
            packageFile={packageFile}
            lineNumber={lineNumber}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={false}
          />
        );
      } else {
        upgradeInstructionsHtml = <JarFile component={component} safeVersion={safeVersion} />;
      }
    } else if (languageType === 'OBJECTIVEC') {
      upgradeInstructionsHtml = (
        <PackageManagerCocoaPods
          packageFile={packageFile}
          lineNumber={lineNumber}
          component={component}
          safeVersion={safeVersion}
          unsafeVersion={unsafeVersion}
          transitive={false}
        />
      );
    } else if (languageType === 'GO') {
      if (packageFile.includes('glide.lock')) {
        upgradeInstructionsHtml = (
          <PackageManagerGlide
            packageFile={packageFile}
            lineNumber={lineNumber}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={false}
            safeCommitHash={safeCommitHash}
            unsafeCommitHash={unsafeCommitHash}
          />
        );
      } else if (packageFile.includes('vendor.json')) {
        upgradeInstructionsHtml = (
          <PackageManagerGoVendor
            packageFile={packageFile}
            lineNumber={lineNumber}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={false}
            safeCommitHash={safeCommitHash}
            unsafeCommitHash={unsafeCommitHash}
          />
        );
      } else if (packageFile.includes('vendor.conf') || packageFile.includes('trash.yaml')) {
        upgradeInstructionsHtml = (
          <PackageManagerTrash
            packageFile={packageFile}
            lineNumber={lineNumber}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={false}
            safeCommitHash={safeCommitHash}
            unsafeCommitHash={unsafeCommitHash}
          />
        );
      } else if (packageFile.includes('Godeps.json')) {
        upgradeInstructionsHtml = (
          <PackageManagerGoDep
            packageFile={packageFile}
            lineNumber={lineNumber}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={false}
            safeCommitHash={safeCommitHash}
            unsafeCommitHash={unsafeCommitHash}
          />
        );
      } else if (packageFile === '') {
        // If user is not using package manager then the package file will be empty
        upgradeInstructionsHtml = (
          <PackageManagerGoGet
            packageFile={packageFile}
            lineNumber={lineNumber}
            component={component}
            safeVersion={safeVersion}
            unsafeVersion={unsafeVersion}
            transitive={false}
            safeCommitHash={safeCommitHash}
            unsafeCommitHash={unsafeCommitHash}
          />
        );
      }
    } else if (languageType === 'PHP') {
      upgradeInstructionsHtml = (
        <PackageManagerPackagist
          packageFile={packageFile}
          lineNumber={lineNumber}
          component={component}
          safeVersion={safeVersion}
          unsafeVersion={unsafeVersion}
          transitive={false}
        />
      );
    }
  }

  return (
    <div className="grid">
      {projectType.toUpperCase() !== PROJECT_TYPES.CONTAINER && (
        <div className="grid grid__item mt--- mb- bo--1 ml p bo-rad--3 border-color--white-dark bg-color--white">
          <div className="grid__item bo-r--1 border-color--white-dark m0 pl0 pr- col-1-5">
            <Tooltip
              place="top"
              width="width--200"
              content={
                <div>
                  A direct dependency is a library that is specified in your project’s{' '}
                  <span className="text--bold">{packageFile}</span>
                </div>
              }
              id={`direct-dependecy-text`}
            >
              <span className="text--bold">Direct Dependency</span>{' '}
              <i className="sci sci__detail-information color--primary" />
            </Tooltip>
          </div>

          <div className="grid__item border-color--white-dark m0 col-4-5 pl lh+">
            {directDependencyText}
          </div>
        </div>
      )}

      {updateText && (
        <div className="grid__item col-1-1 pr+ ml bo-l--2 border-color--white-dark">
          {updateText}
        </div>
      )}

      {(packageFile.includes('shrinkwrap') || packageFile.includes('package.json')) && safeVersion && (
        <div className="grid__item col-1-1 mt-">
          <div className="mb-">To update your {packageFile} file, run the following command</div>
          <CopyToClipboard
            id="shrinkwrap-update"
            value={`npm install ${component.name}@${safeVersion} --save`}
          />
        </div>
      )}

      <div className="grid__item col-1-1 pl+">{upgradeInstructionsHtml}</div>

      <div className="grid__item col-1-1 pr+ pt-">{validateText}</div>
    </div>
  );
};

export default ReportDetailsPageDirectContent;
