import React from 'react';

import ProjectSettingsDefaultBranchSection from '~/containers/ProjectSettingsDefaultBranchSection';
import Helpers from '~/utils/Helpers';

interface ProjectSettingsBranchesPageProps {
  projectId: string;
}
const ProjectSettingsBranchesPage: React.FunctionComponent<ProjectSettingsBranchesPageProps> = props => {
  const { projectId } = props;
  const canUseLatestScan: boolean = Helpers.hasLatestScanEnabled();

  return (
    <div>
      <ProjectSettingsDefaultBranchSection projectId={projectId} useLastScannedEnabled={canUseLatestScan}/>
    </div>
  );
};

export default ProjectSettingsBranchesPage;
