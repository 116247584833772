import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import * as billingActions from '~/actions/billing';
import * as paymentActions from '~/actions/payment';
import * as meActions from '~/actions/me';

import UpdatePaymentStripeForm from '~/containers/UpdatePaymentStripeForm';
import UpdateBillingInformation from '~/containers/UpdateBillingInformation';
import SourceClearModal from '~/components/SourceClearModal';
import InvoiceRow from '~/components/InvoiceRow';

interface OrgBillingPageProps extends RouteComponentProps {
  billingActions: object;
  paymentActions: object;
  meActions: object;
  billingState: object;
  orgState: App.OrgState;
}

class OrgBillingPage extends React.Component<OrgBillingPageProps, {}> {
  componentDidMount() {
    const { orgState, history } = this.props;
    const { org } = orgState;
    const { permissions } = org;
    const { billing } = permissions;

    if (!billing) {
      history.replace('/org/settings/no-owner');
    }

    this.props.billingActions.fetchBillingDashboard(org.id);
  }

  toggleModal(modalType = undefined) {
    this.props.billingActions.showModal(modalType);

    if (!modalType) {
      this.props.paymentActions.updatePaymentError(undefined);
      this.props.billingActions.resetBillingInfo();
    }
  }

  resendValidationLink() {
    const { orgState, billingState } = this.props;
    const { org } = orgState;
    const { account = {} } = billingState;
    const { email } = account;

    this.props.meActions.resendEmailValidationLink(org.id, email);
  }

  render() {
    const { billingState, orgState } = this.props;
    const { org } = orgState;
    const { license } = org;
    const { planType } = license;
    const { invoices = [], showModal } = billingState;
    const account = billingState.account || {};
    const paymentMethod = billingState.paymentMethod || {};
    const { name, nameWas, phoneNumber, phoneNumberWas, email, emailWas, emailVerified } = account;
    const { cardType = '', lastFour, expMonth, expYear, hasPaymentFailure } = paymentMethod;

    return (
      <div className="grid mt mb++">
        <div className="grid__item col-1-1 text--center">
          <div className="font--h3">Billing and Payment</div>
        </div>
        {planType !== 'OPEN' && (
          <div className="grid__item col-1-2 mt">
            <div className="bo-b--1 border-color--muted pb---">
              <div className="font--h4">Payment Method</div>
            </div>
            {hasPaymentFailure && (
              <div className="bo--1 border-color--danger mt- flex align-items--center color--danger pv--">
                <i className="sci sci__vulnerability--new font--h5 ph-" />
                <div className="">
                  Your last invoice could not be paid with this card. Please update your payment
                  method to resume recurring payments.
                </div>
              </div>
            )}
            <div className="mt-">
              <div>
                <strong>Card</strong>: {cardType.toUpperCase()} **** {lastFour}
              </div>
              <div className="mt--">
                <strong>Expires</strong>: {expMonth}/{expYear}
              </div>
              <div className="link--obvious mt--" onClick={() => this.toggleModal('PAYMENT')}>
                Update payment method
              </div>
            </div>
          </div>
        )}
        {planType !== 'OPEN' && (
          <div className="grid__item col-1-2 mt">
            <div className="bo-b--1 border-color--muted pb---">
              <div className="font--h4">Billing Information</div>
            </div>
            <div className="mt-">
              <div className="">
                <strong>Name</strong>: {typeof nameWas === 'string' ? nameWas : name || 'n/a'}
              </div>
              <div className="mt--">
                <strong>Phone</strong>:{' '}
                {typeof phoneNumberWas === 'string' ? phoneNumberWas : phoneNumber || 'n/a'}
              </div>
              <div className="mt-- flex align-items--center">
                <div>
                  <strong>Email</strong>: {typeof emailWas === 'string' ? emailWas : email || 'n/a'}
                </div>
                {!emailVerified && (
                  <div className="pl-">
                    <button
                      className="btn--default-clear pv---"
                      onClick={() => this.resendValidationLink()}
                    >
                      <div className="font--h8">Resend verification link</div>
                    </button>
                  </div>
                )}
              </div>
              <div className="link--obvious mt---" onClick={() => this.toggleModal('BILLING')}>
                Update billing information
              </div>
            </div>
          </div>
        )}
        <div className="grid__item col-1-1 mt">
          <div className="bo-b--1 border-color--muted pb---">
            <div className="font--h4">Payment History</div>
          </div>
        </div>
        <div className="grid__item col-1-1 mt-">
          {/* List header */}
          <div className="grid">
            <div className="grid__item">
              <strong>Date</strong>
            </div>
            <div className="grid__item col-1-3">
              <strong>Subject</strong>
            </div>
            <div className="grid__item">
              <strong>Amount</strong>
            </div>
            <div className="grid__item">
              <strong>Status</strong>
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1 mt-">
          {/* List rows */}
          {invoices.map(invoice => {
            return <InvoiceRow key={invoice.id} invoice={invoice} />;
          })}
        </div>
        <SourceClearModal
          isOpen={showModal === 'PAYMENT'}
          title="Update Payment Method"
          onClose={() => this.toggleModal()}
          width={400}
        >
          <UpdatePaymentStripeForm onClose={() => this.toggleModal()} />
        </SourceClearModal>
        <SourceClearModal
          isOpen={showModal === 'BILLING'}
          title="Update Billing Information"
          onClose={() => this.toggleModal()}
          width={400}
        >
          <UpdateBillingInformation onClose={() => this.toggleModal()} />
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    billingState: state.billingState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    billingActions: bindActionCreators(billingActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
    meActions: bindActionCreators(meActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgBillingPage);
