import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Tooltip from '~/components/Tooltip';
import LoaderWrapper from '~/components/LoaderWrapper';
import SourceClearModal from '~/components/SourceClearModal';
import PolicyControlAddControlButton from './PolicyControlAddControlButton';

export enum ModalType {
  UseDefaultPolicy = 'USE_DEFAULT_POLICY',
  UseCustomPolicy = 'USE_CUSTOM_POLICY',
  UseOrganizationPolicy = 'USE_ORGANIZATION_POLICY',
  ResetToDefaultModal = 'RESET_TO_DEFAULT_MODAL',
  ResetToOrgRulesModal = 'RESET_TO_ORG_RULES_MODAL',
  ToggleDefaultMode = 'TOGGLE_DEFAULT_MODE',
}

export enum PolicyStatusMode {
  Default = 'DEFAULT',
  Inherit = 'INHERIT',
  Custom = 'CUSTOM',
}

interface PolicyControlToggleProps {
  editRulesMetaData: {
    editMode: boolean;
    status: Partial<PolicyStatusMode>;
    isSaving: boolean;
    isValidating: boolean;
    isPolicyDirty: boolean;
  };
  shouldEnableToggleButton?: boolean;
  togglePolicyStatusMode: (mode: PolicyStatusMode) => void;
  toggleEditMode: (bool?) => void;
  saveChanges: () => void;
  showModal: (string?) => void;
  modalType: string;
  isWorkspace?: boolean;
  updatePolicy?: boolean;
  shouldSeePolicyToggle?: boolean;
  renderPolicyControlCardList: () => JSX.Element;
  addControl: () => void;
  resetToDefault: () => void;
  resetToOrgPolicy?: () => void;
  isSandbox?: boolean;
}

function PolicyControlToggle(props: PolicyControlToggleProps) {
  const {
    updatePolicy,
    editRulesMetaData: { editMode, status, isSaving, isValidating, isPolicyDirty },
    shouldEnableToggleButton,
    showModal,
    modalType,
    resetToDefault,
    resetToOrgPolicy,
    saveChanges,
    toggleEditMode,
    togglePolicyStatusMode,
    isWorkspace = true,
    shouldSeePolicyToggle,
    renderPolicyControlCardList,
    addControl,
    isSandbox,
  } = props;

  const organizationRulesTooltipText = shouldEnableToggleButton
    ? 'Create issues based on rules controls that have been set by an administrator in your organization.'
    : 'This workspace uses the following rules set by your administrator. To edit rules for this workspace, contact your administrator to request an exception.';

  const policyControlToggleClassNames = classNames({ 'grid__item col-1-1 mt': isWorkspace });

  const isCustomMode = status === PolicyStatusMode.Custom;

  const toggleStatusModeButtonClassnames = (status: PolicyStatusMode, mode: PolicyStatusMode) =>
    classNames({
      'default-clear': status !== mode,
      'primary btn--primary--border': status === mode,
      'policy-toggles': !shouldEnableToggleButton && isWorkspace,
    });

  const shouldDisableWorkspaceToggle = !shouldEnableToggleButton && isWorkspace && !isSandbox;

  const vcPolicyControlToggleButtons = (
    <>
      {' '}
      <div className="flex" data-automation-id="PolicyControlToggle">
        {!shouldDisableWorkspaceToggle ? (
          <Tooltip
            id="use-veracode-defaults-button"
            content="Use the default system rules, which create issues for vulnerabilities in direct and transitive libraries, high-risk licenses in direct libraries, and outdated direct libraries."
            maxWidthClass="max-width--300"
            place="top"
          >
            <button
              className={`ph pv- bo-rad--0--right btn--${toggleStatusModeButtonClassnames(
                status,
                PolicyStatusMode.Default
              )}`}
              onClick={() => togglePolicyStatusMode(PolicyStatusMode.Default)}
              data-automation-id="PolicyControlToggle-Defaults"
            >
              Veracode Defaults
            </button>
          </Tooltip>
        ) : (
          <button
            className={`ph pv- bo-rad--0--right btn--${toggleStatusModeButtonClassnames(
              status,
              PolicyStatusMode.Default
            )}`}
            onClick={() => togglePolicyStatusMode(PolicyStatusMode.Default)}
            disabled={shouldDisableWorkspaceToggle}
            data-automation-id="PolicyControlToggle-Defaults"
          >
            Veracode Defaults
          </button>
        )}
        {/* Only show Org-Rules toggle if viewing from a workspace */}
        {isWorkspace && (
          <Tooltip
            id="use-organization-rules-button"
            content={organizationRulesTooltipText}
            maxWidthClass="max-width--300"
            place="top"
          >
            <button
              className={`ph pv- bo-rad--0--left bo-rad--0--right btn--${toggleStatusModeButtonClassnames(
                status,
                PolicyStatusMode.Inherit
              )}`}
              onClick={() => togglePolicyStatusMode(PolicyStatusMode.Inherit)}
              disabled={shouldDisableWorkspaceToggle || isSandbox}
              data-automation-id="PolicyControlToggle-Organization"
            >
              Organization Rules
            </button>
          </Tooltip>
        )}
        {!shouldDisableWorkspaceToggle ? (
          <Tooltip
            id="use-custom-rules-button"
            content="Create issues based on rule controls that fit your business. Pass or fail your build when vulnerabilities, outdated libraries, or libraries with unapproved licenses or license risk ratings are found."
            maxWidthClass="max-width--300"
            place="top"
          >
            <button
              className={`ph pv- bo-rad--0--left btn--${toggleStatusModeButtonClassnames(
                status,
                PolicyStatusMode.Custom
              )}`}
              onClick={() => togglePolicyStatusMode(PolicyStatusMode.Custom)}
              data-automation-id="PolicyControlToggle-Custom"
            >
              Custom Rules
            </button>
          </Tooltip>
        ) : (
          <button
            className={`ph pv- bo-rad--0--left btn--${toggleStatusModeButtonClassnames(
              status,
              PolicyStatusMode.Custom
            )}`}
            onClick={() => togglePolicyStatusMode(PolicyStatusMode.Custom)}
            disabled={shouldDisableWorkspaceToggle}
            data-automation-id="PolicyControlToggle-Custom"
          >
            Custom Rules
          </button>
        )}
      </div>
      {updatePolicy && isCustomMode && (
        <div>
          {editMode ? (
            <div className="flex">
              <button
                className="pv- ml-- ph"
                type="button"
                onClick={() => toggleEditMode(true)}
                data-automation-id="CustomPolicy-Button-Cancel"
              >
                Cancel
              </button>
              <button
                className="btn--success pv- ph ml--"
                type="button"
                onClick={() => saveChanges()}
                disabled={!isPolicyDirty}
                data-automation-id="CustomPolicy-Button-Save"
              >
                <LoaderWrapper isLoaderShowing={isSaving || isValidating} loaderType="SPINNER">
                  Save
                </LoaderWrapper>
              </button>
            </div>
          ) : (
            <div>
              <button
                className="btn--success pv- ph"
                type="button"
                onClick={() => toggleEditMode()}
                data-automation-id="CustomPolicy-Button-Edit"
              >
                Edit
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );

  const selectedPolicy = (type => {
    switch (type) {
      case ModalType.UseCustomPolicy:
        return 'the custom';
      case ModalType.UseOrganizationPolicy:
        return 'your organization';
      default:
        return 'the default';
    }
  })(modalType);

  return (
    <div className={policyControlToggleClassNames}>
      {/* Toggle buttons */}
      {shouldSeePolicyToggle && (
        <div className="flex justify-content--space-between">{vcPolicyControlToggleButtons}</div>
      )}
      {/* Reset policy links */}
      <div className="mt">
        {editMode && (
          <div className="flex align-items--baseline">
            <p className="text--bold">Start from:</p>
            <a
              className="ml link--obvious"
              onClick={() => showModal(ModalType.ResetToDefaultModal)}
              data-automation-id="ResetToDefaultRules-Button"
            >
              <strong>Veracode Defaults</strong>
            </a>
            {isWorkspace && !isSandbox && (
              <a
                className="ml link--obvious"
                onClick={() => showModal(ModalType.ResetToOrgRulesModal)}
                data-automation-id="ResetToOrgRules-Button"
              >
                <strong>Organization Rules</strong>
              </a>
            )}
          </div>
        )}
      </div>
      {/* Control Card list */}
      {renderPolicyControlCardList()}
      {/* Add new control button */}
      <PolicyControlAddControlButton addControl={() => addControl()} editMode={editMode} />
      {/* Policy control related modals */}
      <SourceClearModal
        isOpen={modalType === ModalType.ResetToDefaultModal}
        title="Replace Rule with Veracode Default Rule"
        onClose={() => showModal()}
        width={450}
      >
        <div className="pv">
          Are you sure you want to reset your custom rules to the system default rules? Your
          customizations will be discarded.
        </div>
        <div className="flex justify-content--end pt-">
          <button
            className="pv- ph mr--"
            onClick={() => showModal()}
            data-automation-id="ResetRulesConfirmationModal-Button-Cancel"
          >
            Cancel
          </button>
          <button
            className="btn btn--success pv- ph"
            onClick={() => resetToDefault()}
            data-automation-id="ResetRulesConfirmationModal-Button-Confirm"
          >
            <LoaderWrapper isLoaderShowing={isSaving} loaderType="SPINNER">
              Reset rules
            </LoaderWrapper>
          </button>
        </div>
      </SourceClearModal>
      <SourceClearModal
        isOpen={modalType === ModalType.ResetToOrgRulesModal}
        title="Replace Rule with Veracode Organization Rules"
        onClose={() => showModal()}
        width={450}
      >
        <div className="pv">
          Are you sure you want to reset your {status?.toLowerCase()} rules to organization rules?
          Your customizations will be discarded.
        </div>
        <div className="flex justify-content--end pt-">
          <button
            className="pv- ph mr--"
            onClick={() => showModal()}
            data-automation-id="ResetRulesConfirmationModal-Button-Cancel"
          >
            Cancel
          </button>
          <button
            className="btn btn--success pv- ph"
            onClick={() => resetToOrgPolicy()}
            data-automation-id="ResetRulesConfirmationModal-Button-Confirm"
          >
            <LoaderWrapper isLoaderShowing={isSaving} loaderType="SPINNER">
              Reset rules
            </LoaderWrapper>
          </button>
        </div>
      </SourceClearModal>
      <SourceClearModal
        isOpen={
          modalType === ModalType.UseCustomPolicy ||
          modalType === ModalType.UseOrganizationPolicy ||
          modalType === ModalType.UseDefaultPolicy
        }
        title={`Changing ${isWorkspace ? 'Workspace' : 'Organization'} Rules`}
        onClose={() => showModal()}
        width={450}
      >
        <div className="pv">{`You've switched the rules for this workspace to ${selectedPolicy} rules. These rules will be used in subsequent scans.`}</div>
        <div className="flex justify-content--end pt-">
          <button className="btn btn--success pv- ph+" onClick={() => showModal()}>
            Okay
          </button>
        </div>
      </SourceClearModal>
      <SourceClearModal
        isOpen={modalType === ModalType.ToggleDefaultMode}
        title="Discard Unsaved Changes?"
        onClose={() => showModal()}
        width={450}
      >
        <div className="pv">
          You have unsaved changes in your custom rules. If you switch to using the default rules,
          these changes will be lost. Do you want to continue?
        </div>
        <div className="flex justify-content--end pt-">
          <button className="pv- ph mr--" onClick={() => showModal()}>
            Stay on this page
          </button>
          <button className="btn btn--success pv- ph" onClick={() => toggleEditMode(true)}>
            Yes, discard changes
          </button>
        </div>
      </SourceClearModal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    policiesState: state.policiesState,
    modalState: state.modalState,
    teamState: state.teamState,
    policyValidationState: state.policyValidationState,
  };
}

export default connect(mapStateToProps)(PolicyControlToggle);
