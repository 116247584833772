import React from 'react';
import Select from 'react-select';
import { Button } from 'fluorine-ui';

interface VCLinkWorkspaceToTeamsProps {
  selectableGroups: any[];
  selectedGroups: any[];
  buttons?: React.ReactNode;
  updateSelectedGroups: (id: number) => void;
}

const VCLinkWorkspaceToTeams: React.FunctionComponent<VCLinkWorkspaceToTeamsProps> = props => {
  const { selectableGroups = [], selectedGroups = [] } = props;

  function removeSelectedGroup(groupId) {
    props.updateSelectedGroups(groupId);
  }

  function addSelectedGroup(group) {
    group && props.updateSelectedGroups(group.value);
  }

  return (
    <div className="grid">
      <div className="grid__item col-1-1 flex">
        <div className="col-1-4 mt- text--right">Available Teams:</div>
        <div className="col-1-4 ml+">
          <Select
            value={''}
            options={selectableGroups}
            name="workspace-group"
            onChange={group => addSelectedGroup(group)}
            className={'srcclr-react-select-container'}
            classNamePrefix={'srcclr-react-select'}
            noOptionsMessage={() => 'No results found'}
          />
        </div>
      </div>
      <div className="grid__item col-1-1 mt- flex">
        <div className={`col-1-4 mt- text--right`}>Teams Added:</div>
        <div className={`col-1-2 mt- ml+`}>
          <div className="bo--1 border-color--white-dark pv-">
            {selectedGroups.length ? (
              <div>
                <div className="max-height--150 overflow--auto col-1-1">
                  {selectedGroups.map(group => {
                    const { name = '', id: groupId } = group;

                    return (
                      <div
                        className="grid pv-- ml- hover-row flex"
                        key={`workspace-group-${groupId}`}
                      >
                        <div className="grid__item col-1-4 pl-">{name}</div>
                        <div className="grid__item col-1-12 align-items--center justify-content--center"></div>
                        <div className="grid__item col-1-12 flex align-items--center justify-content--center">
                          <Button
                            className="p--- flex align-items--center"
                            onClick={() => removeSelectedGroup(groupId)}
                          >
                            <i className="sci sci__trash color--danger-hover-row" />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="pl- color--muted-dark">
                <em>Select teams above.</em>
              </div>
            )}
          </div>
        </div>
      </div>

      {props.buttons}
    </div>
  );
};

export default VCLinkWorkspaceToTeams;
