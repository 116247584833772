export const CREATE_NEW_APPLICATION = 'CREATE_NEW_APPLICATION';
export const SELECT_FROM_EXISTING = 'SELECT_FROM_EXISTING';
export const UPDATE_SELECTED_APPLICATION = 'UPDATE_SELECTED_APPLICATION';
export const RESET_START_SCAN_MODAL_STATE = 'RESET_START_SCAN_MODAL_STATE';
export const SHOW_START_SCAN_MODAL = 'SHOW_START_SCAN_MODAL';

export const showModal = (modalType: string) => ({
  type: SHOW_START_SCAN_MODAL,
  modalType,
});

export const resetStartScanModalState = () => ({
  type: RESET_START_SCAN_MODAL_STATE,
});

export const selectFromExisting = () => ({
  type: SELECT_FROM_EXISTING,
});

export const createNewApplication = () => ({
  type: CREATE_NEW_APPLICATION,
});

export const updateApplicationName = (application: any) => ({
  type: UPDATE_SELECTED_APPLICATION,
  application: application,
});
