import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import SourceClearModal from '~/components/SourceClearModal';

import * as upgradeModalActions from '~/actions/upgradeModal';
import * as MODEL from '~/constants/ModelConstants';

interface UpgradeCtaModalProps {
  orgState: App.OrgState;
  upgradeModalActions: object;
  upgradeModalState: object;
}
class UpgradeCtaModal extends React.Component<UpgradeCtaModalProps, {}> {
  getOrgId = () => {
    const { orgState } = this.props;
    const { org = {} } = orgState;
    const { id } = org;
    return id;
  };

  openedModal = () => {
    const { upgradeModalState = {} } = this.props;
    return upgradeModalState.openedModal;
  };

  onClose = () => {
    this.props.upgradeModalActions.closeUpgradeModal();
  };

  learnMoreClicked = () => {
    const orgId = this.getOrgId();
    const openedModal = this.openedModal();
    this.props.upgradeModalActions.snowplowUpgradeModalBtnClicked(openedModal, orgId, 'LEARN_MORE');
  };

  buyClicked = () => {
    const orgId = this.getOrgId();
    const { upgradeModalState = {} } = this.props;
    const { openedModal, location } = upgradeModalState;
    this.props.upgradeModalActions.snowplowUpgradeModalBtnClicked(
      openedModal,
      orgId,
      'BUY_SOURCECLEAR_PRO',
      location
    );
    this.onClose();
  };

  contactUs = () => {
    window.location.href = 'mailto:sales@sourceclear.com';
    this.onClose();
  };

  render() {
    const openedModal = this.openedModal();

    const isEnterprise =
      openedModal && MODEL.UPGRADE_MODAL_CONTENT[openedModal].planType === 'ENTERPRISE';
    const modalHeader = isEnterprise ? 'Enterprise Feature' : 'Premium Feature';
    const upgradeTitle = openedModal ? MODEL.UPGRADE_MODAL_CONTENT[openedModal].title : '';
    const upgradeParagraph = openedModal ? MODEL.UPGRADE_MODAL_CONTENT[openedModal].paragraph : '';
    const upgradeSubParagraph = openedModal
      ? MODEL.UPGRADE_MODAL_CONTENT[openedModal].subParagraph
      : '';
    const upgradeBullet = openedModal ? MODEL.UPGRADE_MODAL_CONTENT[openedModal].bullet : [];
    const button = isEnterprise ? (
      <a
        onClick={this.contactUs}
        className="bo-rad--0 btn--primary-clear font-family--body-light font--12 pv- ph+ ml"
      >
        Contact Us
      </a>
    ) : (
      <Link onClick={this.buyClicked} to={`/org/settings/subscribe`} className="pl">
        <button className="bo-rad--0 btn--primary-clear font-family--body-light font--12 pv- ph+">
          Upgrade
        </button>
      </Link>
    );

    return (
      <SourceClearModal
        isOpen={!!openedModal}
        title={
          <span>
            <i className="sci sci__crown font--36 color--premium pr-" />
            {modalHeader}
          </span>
        }
        onClose={this.onClose}
        width={500}
      >
        <div className="grid">
          <div className="grid__item col-1-1">
            <div className="pb- ph">
              <div className="font--h5 pt pb-"> {upgradeTitle} </div>
              <div className="font--14 lh+ text--bold">{upgradeParagraph}</div>
              {upgradeSubParagraph && (
                <div className="font--14 pt- lh+"> {upgradeSubParagraph} </div>
              )}
            </div>
            <div className="ph">
              <ul>
                {upgradeBullet.map((bullet, i) => (
                  <li key={i} className="font--14 pt--">
                    {bullet}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt pt-- pb ph text--right">
              <div>
                <a onClick={this.onClose} target="_blank" className="font--12">
                  No, thanks
                </a>
                {button}
              </div>
            </div>
          </div>
        </div>
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    upgradeModalState: state.upgradeModalState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    upgradeModalActions: bindActionCreators(upgradeModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeCtaModal);
