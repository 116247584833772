import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

interface NoWorkspacePageProps {
  myState: object;
  teamState: {
    teams: object[];
  };
}
const NoWorkspacePage: React.FunctionComponent<NoWorkspacePageProps> = props => {
  const { teamState, myState } = props;
  const { me = {} } = myState;
  const { teams = [] } = teamState;
  const myScansTeam = teams.find(team => team.userId) || {};
  const organization = me.organization || {};

  return (
    <div className="grid grid--center mt++">
      <div className="grid__item col-2-5">
        <div className="font--h6">
          You are a member of the {organization.name} organization, however you are not a member of
          any of the workspaces. Contact your organization admin to be added to workspaces.
        </div>
        <div className="mt- font--h7">
          Click here to{' '}
          <Link to={`/workspaces/${myScansTeam.id}/issues`} className="link--obvious">
            go to {myScansTeam.name}
          </Link>
          .
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    myState: state.myState,
    teamState: state.teamState,
  };
}

export default connect(mapStateToProps)(NoWorkspacePage);
