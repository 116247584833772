import React from 'react';
import { Link } from 'react-router-dom';

interface ListTabProps {
  label: string;
  link: string;
  isActive?: boolean;
  className?: string;
  automationId?: string;
}

const ListTab: React.FunctionComponent<ListTabProps> = props => {
  const { link, label, isActive, className = 'bg-color--white-dark', automationId } = props;

  return (
    <div className={`${className}`} data-automation-id={automationId}>
      <Link
        to={link}
        className={`block text--center pv- link--no-underline  bg-color--transition-100ms-ease-out box-shadow--hover-neg-v3-muted box-shadow--transition-100ms-ease-out bo-rad--top-3 ${
          isActive && 'bg-color--black-light color--white link--plain link--white hover--no-shadow'
        }`}
      >
        {label}
      </Link>
    </div>
  );
};

export default ListTab;
