import React from 'react';

interface GithubIssuesCheckboxProps {
  toggleDisableGithubIssues: (...args: any[]) => any;
  disableGithubIssues?: boolean;
  teamId: string;
  saveSuccess: boolean;
  disabled: boolean;
  customText?: string;
}

const GithubIssuesCheckbox: React.FunctionComponent<GithubIssuesCheckboxProps> = props => {
  const { disableGithubIssues, saveSuccess, teamId, disabled, customText = '' } = props;
  function handleClick() {
    if (disabled) {
      return;
    }
    props.toggleDisableGithubIssues(!disableGithubIssues, teamId);
  }

  return (
    <label
      className={`label--checkbox grid grid--middle grid--full mt- ${disabled ? ' disabled' : ''}`}
      key={'github-issues'}
    >
      <div className="">
        {customText.length > 0 ? customText : `I use GitHub Issues to track my issues`}
      </div>
      <div className="ml-">
        <input
          type="checkbox"
          className="flex"
          name={'github-issues'}
          value={'github-issues'}
          onChange={handleClick}
          checked={!disableGithubIssues}
          disabled={disabled}
        />
        <span className="react--checkbox" />
      </div>
      {/* toggle github successfully */}
      {saveSuccess && (
        <i className="sci sci--fw sci__check color--success inline-block va-middle" />
      )}
    </label>
  );
};

export default GithubIssuesCheckbox;
