import React from 'react';
import classNames from 'classnames';

interface CodeDiffLineProps {
  text: string;
  type: string;
  lineNumber?: string;
}

const CodeDiffLine: React.FunctionComponent<CodeDiffLineProps> = props => {
  const { text, type, lineNumber = '' } = props;
  const colorClasses = classNames({
    'col-1-1 ph pv---': true,
    'font-family--code': true,
    'color--muted': type === 'comment',
    'bg-color--deleted': type === 'delete',
    'bg-color--added': type === 'add',
    'bg-color--primary-light': type === 'highlight',
  });
  let icon;

  if (type === 'delete') {
    icon =
      lineNumber !== '' ? (
        <div className="color--muted pl0 -ml-">
          {'\u2212'} {lineNumber}
        </div>
      ) : (
        <div>{'\u2212'}</div>
      );
  } else if (type === 'add') {
    icon =
      lineNumber !== '' ? (
        <div className="color--muted pl0 -ml-">
          {'\u002B'} {lineNumber}
        </div>
      ) : (
        <div>{'\u002B'}</div>
      );
  } else if (type === 'highlight' && lineNumber !== '') {
    icon = <div className="color--muted font-family--code"> {lineNumber} </div>;
  }

  return (
    <div className={colorClasses}>
      <div className="grid grid--middle">
        <div
          className={
            'grid__item font-family--code ' + (lineNumber !== '' ? 'col-1-12' : 'col-1-12')
          }
        >
          {icon}
        </div>
        <div
          className={
            'grid__item font-family--code ' + (lineNumber !== '' ? 'col-11-12' : 'col-11-12')
          }
        >
          {text}
        </div>
      </div>
    </div>
  );
};

export default CodeDiffLine;
