import React from 'react';

const UploadScan = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="upload">
        <circle cx="50" cy="50" r="50" fill="#F5F5F5" />
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="icon" fill="#00B3E6" transform=" translate(25 25)" fillRule="nonzero">
            <path
              d="M41.625,29.25 C34.803,29.25 29.25,34.803 29.25,41.625 C29.25,48.447 34.803,54 41.625,54 C48.447,54 54,48.447 54,41.625 C54,34.803 48.447,29.25 41.625,29.25 Z M41.625,51.75 C36.04275,51.75 31.5,47.20725 31.5,41.625 C31.5,36.04275 36.04275,31.5 41.625,31.5 C47.20725,31.5 51.75,36.04275 51.75,41.625 C51.75,47.20725 47.20725,51.75 41.625,51.75 Z"
              id="Shape"
            />
            <path
              d="M25.875,47.25 L5.625,47.25 C3.76425,47.25 2.25,45.73575 2.25,43.875 L2.25,11.25 L47.25,11.25 L47.25,27 C47.25,27.621 47.754,28.125 48.375,28.125 C48.996,28.125 49.5,27.621 49.5,27 L49.5,5.625 C49.5,2.52225 46.97775,0 43.875,0 L5.625,0 C2.52225,0 0,2.52225 0,5.625 L0,43.875 C0,46.97775 2.52225,49.5 5.625,49.5 L25.875,49.5 C26.496,49.5 27,48.996 27,48.375 C27,47.754 26.496,47.25 25.875,47.25 Z M5.625,2.25 L43.875,2.25 C45.73575,2.25 47.25,3.76425 47.25,5.625 L47.25,9 L2.25,9 L2.25,5.625 C2.25,3.76425 3.76425,2.25 5.625,2.25 Z"
              id="Shape"
            />
            <path
              d="M42.4215,34.0785 C42.372,34.029 42.309,34.0065 42.255,33.96825 C42.18975,33.92325 42.1335,33.86925 42.05925,33.83775 C41.7825,33.723 41.472,33.723 41.19525,33.83775 C41.0715,33.8895 40.96125,33.966 40.8645,34.05825 C40.8555,34.06725 40.842,34.0695 40.833,34.08075 L36.333,38.58075 C35.89425,39.0195 35.89425,39.73275 36.333,40.1715 C36.77175,40.61025 37.485,40.61025 37.92375,40.1715 L40.5,37.59075 L40.5,48.375 C40.5,48.996 41.004,49.5 41.625,49.5 C42.246,49.5 42.75,48.996 42.75,48.375 L42.75,37.59075 L45.3285,40.1715 C45.549,40.38975 45.837,40.5 46.125,40.5 C46.413,40.5 46.701,40.38975 46.9215,40.16925 C47.36025,39.7305 47.36025,39.01725 46.9215,38.5785 L42.4215,34.0785 Z"
              id="Shape"
            />
            <circle id="Oval" cx="5.625" cy="5.625" r="1.125" />
            <circle id="Oval" cx="10.125" cy="5.625" r="1.125" />
            <circle id="Oval" cx="14.625" cy="5.625" r="1.125" />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default UploadScan;
