import React from 'react';
import { Link } from 'react-router-dom';

const BillingEmailConfirmPage: React.FunctionComponent<{}> = () => {
  return (
    <div className="container">
      <div className="grid grid--center mt+">
        <div className="grid__item col-4-5 flex align--items--center">
          <div>
            <i className="sci sci__check color--primary-light font--200" />
          </div>
          <div className="pl mt pt-">
            <h1>Thanks for confirming your billing email.</h1>
            <div className="mt">
              <Link to="/login" className="btn--primary font--h5 col-1-4 pv-">
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingEmailConfirmPage;
