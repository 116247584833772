import React from 'react';
import { Link } from 'react-router-dom';
import WorkspaceTeamUsersQuickView from '~/components/workspaces/teams/WorkspaceTeamUsersQuickView';
import UserManagementQuickViewWrapper from '~/containers/UserManagementQuickViewWrapper';
import Tooltip from '~/components/Tooltip';
import { tooltipContent } from '~/constants/ModelConstants';

interface TeamsListProps {
  teamId: string;
  groups: any[];
  filteredGroups: any[];
  teamPermissions: object;
  selectedGroups: object;
  quickViewsByGroupId: object;
  toggleWorkspaceGroupUsersQuickView: (...args: any[]) => any;
  updateSelectedGroups: (...args: any[]) => any;
  shouldShowVeracodePage?: boolean;
}

const TeamsList: React.FunctionComponent<TeamsListProps> = props => {
  const {
    groups = [],
    filteredGroups = [],
    teamPermissions,
    selectedGroups = {},
    teamId,
    quickViewsByGroupId,
    shouldShowVeracodePage = false,
  } = props;
  const { updateUsers, manageGroups } = teamPermissions;
  const columnWidth = shouldShowVeracodePage
    ? {
        name: 'col-1-3',
        users: 'col-1-4',
        select: 'col-1-18',
      }
    : {
        name: 'col-1-5',
        users: 'col-1-5',
        teams: 'col-1-5',
        select: 'col-1-18',
        description: '',
      };

  const renderTeams = () => {
    if (!groups.length) {
      return <div className="mt- font--h3 color--muted-dark">No teams in workspace.</div>;
    } else if (!filteredGroups.length) {
      return <div className="mt- font--h3 color--muted-dark">No teams found.</div>;
    }

    return filteredGroups.map(groupObj => {
      const { group = {}, roles = [], numUsers, _links = {} } = groupObj;
      const { id: groupId, name = '', description = '', permissions = {} } = group;
      const { update } = permissions;
      const { users = {} } = _links;
      const isTeamAdmin = roles.includes('WORKSPACE_ADMIN');
      const quickView = quickViewsByGroupId[groupId];
      const isSelected = selectedGroups[groupId] || false;

      return (
        <div className="grid grid--narrower mt-" key={`group-${groupId}`}>
          <div className={`grid__item flex align-items--center ${columnWidth.name}`}>
            {!shouldShowVeracodePage && update ? (
              <Link
                to={`/workspaces/${teamId}/user-management/teams/${groupId}`}
                className="link--obvious"
              >
                <strong>{name}</strong>
              </Link>
            ) : (
              <div className="col-1-1 text--overflow">{name}</div>
            )}
          </div>
          {!shouldShowVeracodePage && (
            <div className={`grid__item flex align-items--center ${columnWidth.description}`}>
              {description || '--'}
            </div>
          )}
          {!shouldShowVeracodePage && (
            <div className={`grid__item flex align-items--center ${columnWidth.users}`}>
              <div
                className={`flex justify-content--space-between col-1-2 ${
                  numUsers && 'cursor--pointer'
                }`}
                onClick={
                  numUsers
                    ? () => props.toggleWorkspaceGroupUsersQuickView(groupId, 'USERS')
                    : undefined
                }
              >
                {numUsers} user
                {numUsers === 1 ? '' : 's'}
                <i
                  className={`sci sci__caret--${quickView === 'USERS' ? 'down' : 'right'} color--${
                    numUsers ? 'primary' : 'muted-light'
                  }`}
                />
              </div>
            </div>
          )}
          {!shouldShowVeracodePage && (
            <div
              className={`grid__item flex align-items--center justify-content--center ${columnWidth.teams}`}
            >
              {isTeamAdmin ? 'Yes' : '--'}
            </div>
          )}
          {manageGroups && (
            <div
              className={`grid__item flex align-items--center justify-content--center ${columnWidth.select}`}
            >
              <label className="label--checkbox flex justify-content--center mb0">
                <input
                  type="checkbox"
                  name="select-team-group"
                  checked={isSelected}
                  onChange={() => props.updateSelectedGroups(groupId)}
                />
                <span className="react--checkbox mr0" />
              </label>
            </div>
          )}

          <div
            className={`grid__item col-1-1 ${
              quickView === 'USERS' ? 'is-showing-400' : 'is-hiding'
            }`}
          >
            {quickView && (
              <UserManagementQuickViewWrapper dataType="users" href={users.href} teamId={teamId}>
                <WorkspaceTeamUsersQuickView
                  showDetailsLink={updateUsers}
                  shouldShowVeracodePage={shouldShowVeracodePage}
                />
              </UserManagementQuickViewWrapper>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="grid">
      <div className="grid__item col-1-1 mt">
        <div className="grid grid--narrower">
          <div className={`grid__item ${columnWidth.name}`}>
            <strong>Team Name</strong>
          </div>
          {!shouldShowVeracodePage && (
            <div className={`grid__item ${columnWidth.description}`}>
              <strong>Description</strong>
            </div>
          )}
          {!shouldShowVeracodePage && (
            <div className={`grid__item ${columnWidth.users}`}>
              <strong>Users</strong>
            </div>
          )}
          {!shouldShowVeracodePage && (
            <div className={`grid__item text--center ${columnWidth.teams}`}>
              <Tooltip
                id="group-workspace-admins"
                content={tooltipContent.WORKSPACE_ADMIN}
                maxWidthClass="max-width--300"
                place="top"
              >
                <strong className="bo-b--1 bo--dashed">Workspace Administrator</strong>
              </Tooltip>
            </div>
          )}
          {manageGroups && (
            <div className={`grid__item ${columnWidth.select} text--center`}>
              <strong>Select</strong>
            </div>
          )}
        </div>
      </div>
      <div className="grid__item col-1-1">{renderTeams()}</div>
    </div>
  );
};

export default TeamsList;
