import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as MODEL from '~/constants/ModelConstants';
import SourceClearModal from '~/components/SourceClearModal';
import * as integrationModalActions from '~/actions/integrationModal';
import SourceClearLoader from '~/components/SourceClearLoader';

const { JIRA_CLOUD, JIRA_ONPREM } = MODEL.INTEGRATION_NAME_MAP;

interface ProxyStep1ModalProps {
  integrationModalActions: object;
  integrationModalState: object;
  orgState: App.OrgState;
  closeWhenClickOutside?: boolean;
}
class ProxyStep1Modal extends React.Component<ProxyStep1ModalProps, {}> {
  onClose = () => {
    this.props.integrationModalActions.closeIntegrationModal();
  };

  checkIfNeedJiraPrem() {
    const { integrationModalState, orgState } = this.props;
    const { fields = {} } = integrationModalState;
    const { proxyServerUrl = '' } = fields;
    const { org = {} } = orgState;
    this.props.integrationModalActions.checkIfProxyUrlAccessible(org.id, proxyServerUrl);
  }

  continueStep2Modal() {
    const { orgState } = this.props;
    const { org = {} } = orgState;
    this.props.integrationModalActions.proceedToProxyStep2(org.id);
  }

  updateFormInput(fieldType, e) {
    this.props.integrationModalActions.updateIntegrationModalField(fieldType, e.target.value);
  }

  getProxyUrl() {
    const { integrationModalState } = this.props;
    const { fields = {} } = integrationModalState;
    return fields.proxyServerUrl || '';
  }

  validProxyServerUrl() {
    return this.getProxyUrl().startsWith('https://');
  }

  render() {
    const { integrationModalState, closeWhenClickOutside = false } = this.props;
    const { fields = {}, jiraIntegration } = integrationModalState;
    const { proxyServerUrl = '' } = fields;

    const { [MODEL.CHECK_PROXY_URL_REQUEST]: checkUrlState = {} } = jiraIntegration;
    const { data = {}, isFetching: proxyUrlIsFetching = false } = checkUrlState;
    const { url_accessible } = data;

    const { [MODEL.CREATE_PROXY_CLIENT_STEP_1]: createProxyClientRequest = {} } = jiraIntegration;
    const { isFetching: creatingProxyClient = false } = createProxyClientRequest;

    return (
      <SourceClearModal
        isOpen={true}
        title={`Create ${JIRA_ONPREM} Integration (Step 1 of 5)`}
        onClose={this.onClose}
        width={600}
        closeWhenClickOutside={closeWhenClickOutside}
      >
        <div className="pv min-height--232">
          <div className="grid grid__item pl+ --mt">
            <div className="h5 pb-">
              <strong> Do I need {JIRA_ONPREM} integration? </strong>{' '}
            </div>
            <div>
              You only need <strong> {JIRA_ONPREM} </strong> integration if your Jira instance is
              behind your VPN or firewall. Enter your Jira URL below, we will help you to check if
              you need a {JIRA_ONPREM} integration.
            </div>
          </div>
          <div className="grid flex flex--align-items--center flex--justify-content--center pt">
            <div className="grid__item col-1-4 pl+">Server URL</div>
            <div className="grid__item col-3-4">
              <input
                type="text"
                className="col-1-1 control--text"
                placeholder="Your internal JIRA URL, write the entire URL including https://"
                onChange={e => this.updateFormInput('proxyServerUrl', e)}
                value={proxyServerUrl}
              />
            </div>
          </div>

          {proxyServerUrl.length > 7 && !this.validProxyServerUrl() && (
            <div className="push--col-1-4 bubble--error  pt--">
              Please ensure that the url starts with https://
            </div>
          )}

          {proxyUrlIsFetching && (
            <div className="pt">
              <div className="text--center font--h6"> Checking if the url is accessible... </div>
              <div className="pr">
                {' '}
                <SourceClearLoader />{' '}
              </div>
            </div>
          )}

          {url_accessible === false && (
            <div className="push--col-1-4 pt col-3-4">
              <i className="sci sci__check color--success" /> You will need {JIRA_ONPREM}{' '}
              integration
            </div>
          )}
          {url_accessible && (
            <div className="push--col-1-4 pt  col-3-4">
              <i className="sci sci__close color--danger" /> Your Jira URL is accessible from the
              internet, please set up {JIRA_CLOUD} integration instead.
            </div>
          )}
        </div>
        <div className="pt pb text--right">
          <button className="col-1-5 pv- mr-" onClick={() => this.onClose()}>
            {' '}
            Cancel{' '}
          </button>
          {url_accessible === undefined && !proxyUrlIsFetching && (
            <button
              className="pv- mr- btn--primary-clear"
              onClick={() => this.checkIfNeedJiraPrem()}
              disabled={proxyServerUrl.length == 0 || !this.validProxyServerUrl()}
            >
              Check If I need {JIRA_ONPREM} Integration
            </button>
          )}
          {url_accessible === false && (
            <button
              className="col-1-5 btn--primary-clear pv- mr- width--150"
              onClick={() => this.continueStep2Modal()}
            >
              {creatingProxyClient ? (
                <span>
                  <i className="fas fa-spin fa-spinner font--h4" />{' '}
                </span>
              ) : (
                <span> Next </span>
              )}
            </button>
          )}
        </div>
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationModalState: state.integrationModalState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProxyStep1Modal);
