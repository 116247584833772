import React from 'react';

interface QuickviewClosedIconProps {
  className?: string;
  sizeClass?: string;
}

const QuickviewClosedIcon: React.FunctionComponent<QuickviewClosedIconProps> = props => {
  return (
    <span className={`${props.className} ${props.sizeClass}`}>
      <i className="sci sci--sm sci__caret--right" />
    </span>
  );
};

export default QuickviewClosedIcon;
