import React from 'react';

interface SuccessOrValueBoxProps {
  num: number;
}

const SuccessOrValueBox: React.FunctionComponent<SuccessOrValueBoxProps> = props => {
  const { num = 0 } = props;
  return (
    <div
      className={`font-family--body text--bold color--white width--50 flex justify-content--center col-1-1 pv-- ${
        num > 0 ? 'bg-color-imp--danger' : 'bg-color-imp--success'
      }`}
    >
      {num > 0 ? (
        <div>{num > 10000 ? '10,000+' : num.toLocaleString()}</div>
      ) : (
        <i className="sci sci__check" />
      )}
    </div>
  );
};

export default SuccessOrValueBox;
