/**
  Used by package manager fix info in the case where we have to do special things like checking HEAD is vulnerable or not.
**/

import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';

export const CHECK_COMPONENT_HEAD_SAFE_REQUEST = 'CHECK_COMPONENT_HEAD_SAFE_REQUEST';
export const CHECK_COMPONENT_HEAD_SAFE_SUCCESS = 'CHECK_COMPONENT_HEAD_SAFE_SUCCESS';
export const CHECK_COMPONENT_HEAD_SAFE_FAILURE = 'CHECK_COMPONENT_HEAD_SAFE_FAILURE';

export const checkComponentHeadSafeRequest = componentId => ({
  type: CHECK_COMPONENT_HEAD_SAFE_REQUEST,
  componentId,
});

export const checkComponentHeadSafeSuccess = (componentId, res) => ({
  type: CHECK_COMPONENT_HEAD_SAFE_SUCCESS,
  componentId,
  res,
});

export const checkComponentHeadSafeFailure = (componentId, error) => ({
  type: CHECK_COMPONENT_HEAD_SAFE_REQUEST,
  componentId,
  message: error.message || 'Something went wrong',
});

export const checkIfComponentHeadSafe = componentId => dispatch => {
  dispatch(checkComponentHeadSafeRequest(componentId));

  return ApiService.get(
    `/catalog/components/${componentId}/commits:checkHashOrHeadIsSafe?hashOrHead=HEAD`
  )
    .then(res => {
      dispatch(checkComponentHeadSafeSuccess(componentId, res));
    })
    .catch(err => {
      ErrorService.capture('Unable to check if component head safe:', err);
      dispatch(checkComponentHeadSafeFailure(componentId, err));
    });
};
