import React from 'react';
import Helmet from 'react-helmet';

const PolicyMigrationRulesPage = () => {
  return (
    <div className="mt">
      <Helmet>
        <title>Rules</title>
      </Helmet>
      <h3 data-automation-id="OrgPoliciesPage-Title">Rules</h3>
      <div className="grid grid--center grid--direction--column text--center min-height--300">
        <span className="text--bold mb font--18">
          {`Rules setting is currently unavailable due to policy migration.`}
        </span>
        <span className="font--18">Please try again later.</span>
      </div>
    </div>
  );
};

export default PolicyMigrationRulesPage;
