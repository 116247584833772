import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash';

import Tooltip from '~/components/Tooltip';
import * as projectBranchActions from '~/actions/projectBranch';
import SelectDropDown from '~/containers/SelectDropDown';
import { ProjectBranchState } from '~/reducers/projectBranchState.types';
import { USE_LAST_SCANNED } from '~/constants/ModelConstants';
import Helpers from '~/utils/Helpers';

interface ProjectDefaultBranchProps {
  projectBranchState: ProjectBranchState;
  saveOnChange?: boolean;
  projectId: string;
  repoDataById: object;
}

class ProjectDefaultBranch extends Component<
  ProjectDefaultBranchProps & ReturnType<typeof mapDispatchToProps>
> {
  componentDidMount() {
    const { projectId, repoDataById, projectBranchState } = this.props;
    const { branchState } = projectBranchState;
    const { [projectId]: projectData } = repoDataById;
    this.props.projectBranchActions.resetUpdateDefaultBranch();

    if (!branchState.branchesById[projectId]) {
      this.props.projectBranchActions.getProjectBranches(projectId).then(() => {
        const defaultBranch = projectData?.defaultBranch;
        const selectedDefaultBranch =
          Helpers.hasLatestScanEnabled() && (defaultBranch === '' || defaultBranch === null)
            ? USE_LAST_SCANNED
            : defaultBranch;
        this.props.projectBranchActions.updateSelectedDefaultBranch(selectedDefaultBranch);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { projectId, repoDataById, projectBranchState } = this.props;
    const {
      projectBranchState: prevProjectBranchState,
    }: { projectBranchState: ProjectBranchState } = prevProps;
    const { [projectId]: projectData } = repoDataById;
    const defaultBranch = projectData?.defaultBranch;

    if (
      !_.isEqual(
        prevProjectBranchState.searchBranchState.branchesById[projectId],
        projectBranchState.searchBranchState.branchesById[projectId]
      )
    ) {
      this.props.projectBranchActions.addSearchResultsToBranchList(
        projectId,
        projectBranchState.searchBranchState.branchesById[projectId]
      );
    }

    if (defaultBranch) {
      if (!projectBranchState?.branchState?.branchesById[projectId]?.includes(defaultBranch)) {
        this.props.projectBranchActions.searchProjectBranches(projectId, defaultBranch);
      }
    }
  }

  handleDefaultBranchSettingsChange = (fieldName, field) => {
    const { projectId, saveOnChange = false } = this.props;

    if (saveOnChange) {
      this.props.projectBranchActions.updateDefaultBranch(projectId, field);
    } else {
      this.props.projectBranchActions.updateSelectedDefaultBranch(field);
    }
  };

  getErrorTooltip = (tooltipId: string, errorMessage: string) => {
    return (
      <div className="inline pl--">
        <Tooltip id={`${tooltipId}`} content={errorMessage}>
          <i className="sci__alerts sci color--warning font--16" />
        </Tooltip>
      </div>
    );
  };

  render() {
    const { projectId, projectBranchState, saveOnChange = true } = this.props;
    const {
      branchState,
      updateBranchState,
      searchBranchState,
      selectedDefaultBranch,
    } = projectBranchState;
    const { branchesById, isFetchingBranches, fetchBranchErr } = branchState;
    const { [projectId]: branchesForProject = [] } = branchesById;
    const {
      isFetchingBranches: isSearchingBranches,
      fetchBranchErr: searchBranchErr,
    } = searchBranchState;
    const { isUpdating, updateSuccess, updateError } = updateBranchState;

    return (
      <div className="col-1-1">
        <div className="flex align-items--center">
          <SelectDropDown
            withGroupedOptions={false}
            className={`${saveOnChange ? 'col-1-4' : 'col-1-1'} inline-block`}
            isClearable={false}
            fieldName={`defaultBranch`}
            value={selectedDefaultBranch}
            onChange={this.handleDefaultBranchSettingsChange}
            items={branchesForProject.map(item => ({ label: item, value: item }))}
            async={true}
            isLoading={isFetchingBranches || isSearchingBranches}
            defaultOptions={branchesForProject.map(item => ({ label: item, value: item }))}
            asyncGetOptions={(input: string) => {
              if (input.length >= 1) {
                this.props.projectBranchActions.searchProjectBranches(projectId, input);
              }
            }}
          />

          {saveOnChange && (
            <div>
              {isUpdating && (
                <i className="fa sci--sm fa-spin fa-spinner mh- color--primary font--14 ph0" />
              )}
              {updateSuccess && (
                <i className="fa sci--sm sci sci__check mh- color--primary font--14 ph0" />
              )}
              {updateError && this.getErrorTooltip(`update-default-branch-err`, updateError)}
              {fetchBranchErr && this.getErrorTooltip(`fetch-branch-err`, fetchBranchErr)}
              {searchBranchErr && this.getErrorTooltip(`fetch-branch-err`, searchBranchErr)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projectBranchState: state.projectBranchState,
    repoDataById: state.repoDataById,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    projectBranchActions: bindActionCreators(projectBranchActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDefaultBranch);
