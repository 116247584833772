import React from 'react';
import classNames from 'classnames';

interface TabItemProps {
  isActive?: boolean;
  onClick?: (...args: any[]) => any;
  field?: string;
  label?: string;
  className?: string;
}

const TabItem: React.FunctionComponent<TabItemProps> = props => {
  const { children, field, isActive = true, className } = props;

  function handleClick() {
    props.onClick(field);
  }
  const classNameState = classNames({
    'bg-color--muted-dark color--white': isActive,
    'bg-color--white-dark color--muted color--hover-muted-dark': !isActive,
  });

  return (
    <div
      className={`${classNameState} ${className} bg-color--transition-200ms-ease-out cursor--pointer inline-block ph pv-- text--bold font--11`}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

export default TabItem;
