import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import AuthService from '~/utils/AuthService';

export const TRACK_SIGNUP_STEP = 'TRACK_SIGNUP_STEP';

// single signup flow actions
export const IS_SIGNING_UP = 'IS_SIGNING_UP';
export const MSA_AGREE_NOT_CHECKED = 'MSA_AGREE_NOT_CHECKED';
export const TRACK_SIGNUP_EMAIL = 'TRACK_SIGNUP_EMAIL';
export const SINGLE_SIGNUP_TOGGLE_MSA = 'SINGLE_SIGNUP_TOGGLE_MSA';
export const SINGLE_SIGNUP_UPDATE_WIDGET_ID = 'SINGLE_SIGNUP_UPDATE_WIDGET_ID';
export const UPDATE_SIGNUP_ERROR = 'UPDATE_SIGNUP_ERROR';
export const UPDATE_SIGNUP_FLOW = 'UPDATE_SIGNUP_FLOW';
export const UPDATE_SINGLE_SIGNUP_INPUT = 'UPDATE_SINGLE_SIGNUP_INPUT';

// normal signup flow actions
export const RESET_SIGNUP_STATE = 'RESET_SIGNUP_STATE';
export const UPDATE_LOCATION_SELECTION = 'UPDATE_LOCATION_SELECTION';
export const UPDATE_INVITE_TOKEN = 'UPDATE_INVITE_TOKEN';
export const UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME';
export const UPDATE_LAST_NAME = 'UPDATE_LAST_NAME';
export const UPDATE_COMPANY_NAME = 'UPDATE_COMPANY_NAME';
export const UPDATE_ORG_NAME = 'UPDATE_ORG_NAME';
export const UPDATE_ORG_NAME_ERROR = 'UPDATE_ORG_NAME_ERROR';
export const UPDATE_SAML_REQUIRED = 'UPDATE_SAML_REQUIRED';
export const UPDATE_SIGNUP_FLOW_STAGE = 'UPDATE_SIGNUP_FLOW_STAGE';
export const UPDATE_VANITY_ERROR = 'UPDATE_VANITY_ERROR';
export const UPDATE_VANITY_NAME = 'UPDATE_VANITY_NAME';

export const updateVanityName = value => ({
  type: UPDATE_VANITY_NAME,
  value,
});

export const submitVanityName = value => dispatch => {
  const options = {
    slug: value,
  };

  return ApiService.post('/orgs/check', { data: options })
    .then(
      res => {
        dispatch(
          updateVanityError(
            !res.available ? 'Sorry, that domain has been taken. Please choose another.' : undefined
          )
        );
        return res;
      },
      err => {
        dispatch(
          updateVanityError(
            err.message || 'Sorry, that domain has been taken. Please choose another.'
          )
        );
        return {};
      }
    )
    .catch(error => {
      dispatch(
        updateVanityError(
          error.message ||
            'Sorry, there was an error validating your domain. Please try again later.'
        )
      );
      return {};
    });
};

export const submitOrgName = value => (dispatch, getState) => {
  const { signupState } = getState();
  const { vanityName } = signupState;
  const options = {
    slug: vanityName,
    name: value,
  };

  return ApiService.post('/orgs/check', { data: options })
    .then(
      res => {
        if (!res.available) {
          dispatch(updateOrgNameError(true));
        }
        return res;
      },
      () => {
        dispatch(updateOrgNameError(true));
        return {};
      }
    )
    .catch(() => {
      dispatch(updateOrgNameError(true));
      return {};
    });
};

export const updateOrgNameError = bool => ({
  type: UPDATE_ORG_NAME_ERROR,
  bool,
});

export const updateSignupFlowStage = stage => ({
  type: UPDATE_SIGNUP_FLOW_STAGE,
  stage,
});

export const updateVanityError = message => ({
  type: UPDATE_VANITY_ERROR,
  message,
});

export const updateLastName = value => ({
  type: UPDATE_LAST_NAME,
  value,
});

export const updateFirstName = value => ({
  type: UPDATE_FIRST_NAME,
  value,
});

export const updateCompanyName = value => ({
  type: UPDATE_COMPANY_NAME,
  value,
});

export const updateOrgName = value => ({
  type: UPDATE_ORG_NAME,
  value,
});

export const updateInviteToken = token => ({
  type: UPDATE_INVITE_TOKEN,
  token,
});

export const resetSignupState = () => ({
  type: RESET_SIGNUP_STATE,
});

export const updateSingleSignupInput = (field, value) => ({
  type: UPDATE_SINGLE_SIGNUP_INPUT,
  field,
  value,
});

export const singleSignupToggleMSA = () => ({
  type: SINGLE_SIGNUP_TOGGLE_MSA,
});

export const msaAgreeNotChecked = () => ({
  type: MSA_AGREE_NOT_CHECKED,
});

export const updateSignupError = (error = {}) => ({
  type: UPDATE_SIGNUP_ERROR,
  error,
});

export const updateWidgetId = widgetId => ({
  type: SINGLE_SIGNUP_UPDATE_WIDGET_ID,
  widgetId,
});

export const isSigningUp = bool => ({
  type: IS_SIGNING_UP,
  bool,
  meta: bool && {
    snowplow: true,
  },
});

export const trackSignupEmail = email => ({
  type: TRACK_SIGNUP_EMAIL,
  meta: {
    snowplow: true,
    string1: email,
  },
});

export const trackSignupStep = (step, options = {}) => ({
  type: TRACK_SIGNUP_STEP,
  meta: {
    snowplow: true,
    string1: step,
    ...options,
  },
});

export const updateLocationSelection = (selection, area) => ({
  type: UPDATE_LOCATION_SELECTION,
  selection,
  area,
});

export const submitSignupForm = params => dispatch => {
  dispatch(isSigningUp(true));
  ApiService.post(`/signup`, { data: params })
    .then(res => {
      const { _links = {}, authToken } = res;
      const { html = {} } = _links;
      const { href } = html;

      if (authToken) {
        AuthService.handleNewAccountCookie('true');
        window.location = `${href}/login#session=${authToken}`;
      }
    })
    .catch(error => {
      dispatch(updateSignupError(error || { status: 400 }));
      ErrorService.capture('Error during account creation', error);
    });
};
