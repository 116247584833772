import * as actions from '~/actions/twofactor';

const defaultState = {
  isDone: false,
  isTwoFactorVerifying: false,
  isTokenInvalid: false,
  isSMSing: false,
  sentSMS: false,
  smsError: false,
  token: '',
  rememberComputer: false,
  twoFactorStatus: {},
  enablingTwoFactor: false,
  sendingCode: false,
  verifyingCode: false,
  phoneDropdownVal: 'United States of America (+1)',
};

const twoFactorState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.REQUEST_VERIFY_TOKEN:
      return Object.assign({}, state, {
        isTwoFactorVerifying: true,
      });
    case actions.RECEIVE_VERIFY_TOKEN:
      return Object.assign({}, state, {
        isTwoFactorVerifying: false,
      });
    case actions.UPDATE_IS_TOKEN_INVALID:
      return Object.assign({}, state, {
        isTokenInvalid: action.bool,
      });
    case actions.UPDATE_SENT_SMS:
      return Object.assign({}, state, {
        sentSMS: action.bool,
      });
    case actions.UPDATE_IS_SMSING:
      return Object.assign({}, state, {
        isSMSing: action.bool,
      });
    case actions.UPDATE_REMEMBER_COMPUTER:
      return Object.assign({}, state, {
        rememberComputer: action.bool,
      });
    case actions.UPDATE_SMS_ERROR:
      return Object.assign({}, state, {
        smsError: action.bool,
      });
    case actions.UPDATE_TWO_FACTOR:
      return {
        ...state,
        twoFactorStatus: action.res,
      };
    case actions.UPDATE_TWO_FACTOR_STATUS:
      return {
        ...state,
        twoFactorStatus: {
          ...state.twoFactorStatus,
          status: action.status,
        },
      };
    case actions.ENABLING_TWO_FACTOR:
      return {
        ...state,
        enablingTwoFactor: action.bool,
      };
    case actions.SENDING_TWO_FACTOR_CODE:
      return {
        ...state,
        sendingCode: action.bool,
      };
    case actions.VERIFYING_TWO_FACTOR:
      return {
        ...state,
        verifyingCode: action.bool,
      };
    case actions.UPDATE_PHONE_DROPDOWN_VAL:
      return {
        ...state,
        phoneDropdownVal: action.val,
      };
    default:
      return state;
  }
};

export default twoFactorState;
