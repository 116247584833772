import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DropdownMenu from 'react-dd-menu';
import Helmet from 'react-helmet';
import _ from 'lodash';

import OrgPaidStatus from '~/utils/OrgPaidStatus';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import { FEATURE_SLUG_MAP } from '~/constants/ModelConstants';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as teamUsersActions from '~/actions/teamUsers';
import TeamUsersList from '~/components/TeamUsersList';
import WorkspacePendingInvitesList from '~/containers/WorkspacePendingInvitesList';
import { RouteComponentProps } from 'react-router-dom';

interface UsersPageProps extends RouteComponentProps {
  dropdownMenuActions: object;
  teamUsersActions: object;
  dropdownMenuState: object;
  myState: App.MyState;
  orgState: App.OrgState;
  teamState: object;
  teamUsersState: object;
  showHeader?: boolean;
  showPendingInvites?: boolean;
}

class UsersPage extends React.Component<UsersPageProps, {}> {
  constructor(props, context) {
    super(props, context);

    this.delayCloseDropdown = _.debounce(id => this.closeDropdown(id), 50);
  }

  componentDidMount() {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    this.props.teamUsersActions.fetchTeamUsers(teamId);
  }

  componentWillUnmount() {
    this.props.teamUsersActions.resetTeamUserQuickViews();
    this.props.dropdownMenuActions.resetDropdownMenuState();
    this.props.teamUsersActions.resetSelectedTeamUsers();
  }

  closeDropdown(menuId) {
    this.props.dropdownMenuActions.closeDropdownMenu(menuId);
  }

  openDropdown(menuId) {
    this.props.dropdownMenuActions.openDropdownMenu(menuId);
  }

  toggleUserQuickView(userId, column) {
    this.props.teamUsersActions.toggleUserQuickView(userId, column);
  }

  handleUserSelection(userId) {
    this.props.teamUsersActions.updateSelectedUsers(userId);
  }

  handleFilterInput(e) {
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.teamUsersActions.filterTeamUsers(value);
  }

  removeUsers() {
    const { teamUsersState, match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    const { selectedUsers = [] } = teamUsersState;

    this.props.teamUsersActions.removeUsers(selectedUsers, teamId);
  }

  toggleWorkspaceAdminRole(role) {
    const { teamUsersState, match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    const { selectedUsers = [], teamUsers = [] } = teamUsersState;
    const usersWithRole = selectedUsers.map(userId => {
      const foundUser = teamUsers.find(teamUser => teamUser.user.id === userId) || {};
      const { email = '' } = foundUser.user;

      return {
        email,
        role,
      };
    });

    this.props.teamUsersActions
      .updateTeamMembers({ groups: [], users: usersWithRole }, teamId)
      .then(res => {
        if (res.success) {
          // toast notif with summary of successful events
        }
      });
  }

  render() {
    const {
      teamUsersState,
      teamState,
      orgState,
      dropdownMenuState,
      match,
      showHeader = true,
      showPendingInvites = true,
    } = this.props;
    const { openedDropdown = {} } = dropdownMenuState;
    const { params = {} } = match;
    const { teamId } = params;
    const { org } = orgState;
    const showGroups =
      FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org) ||
      OrgPaidStatus.isOrgEnterpriseOrTrial(org);
    const { permissions: orgPermissions, sso } = org;
    const samlDomains = org.samlDomains || [];
    const isSaml = !!samlDomains.length;
    const { updateUsers } = orgPermissions;
    const {
      teamUsers = [],
      filteredTeamUsers = [],
      quickViewsByUserId = {},
      quickViewsByInviteId = {},
      selectedUsers = [],
      isFetching = false,
    } = teamUsersState;
    const { teams = [] } = teamState;
    const activeTeam = teams.find(team => team.id === teamId) || {};
    const { permissions = {} } = activeTeam;
    const { manageUsers, inviteUsers } = permissions;
    const showDirectMembership = !isSaml && !sso;

    return (
      <div className="grid mt mb++">
        <Helmet>
          <title>Workspace Users</title>
        </Helmet>
        {showHeader && (
          <div className="grid__item col-1-1">
            <h3 data-e2e="UsersPage-Title">Users</h3>
          </div>
        )}
        {inviteUsers && showPendingInvites && (
          <div className="mb">
            <WorkspacePendingInvitesList
              teamId={teamId}
              showGroups={showGroups}
              quickViewsByInviteId={quickViewsByInviteId}
            />
          </div>
        )}
        <div className="grid__item col-1-1">
          <h6 className="mt-">ACTIVE USERS</h6>
          <div className="bo--1 border-color--muted-dark flex justify-content--space-between p- align-items--center">
            <div className="flex col-3-4">
              <div className="col-1-2">
                <input
                  type="text"
                  className="control-text col-1-1"
                  placeholder="Filter by name or email"
                  onChange={e => this.handleFilterInput(e)}
                />
              </div>
            </div>
            <div>
              {manageUsers && (
                <DropdownMenu
                  isOpen={!!openedDropdown['workspace-users-actions']}
                  close={() => this.delayCloseDropdown('workspace-users-actions')}
                  menuAlign="right"
                  textAlign="left"
                  toggle={
                    <button
                      className="btn--success p-"
                      onClick={() => this.openDropdown('workspace-users-actions')}
                    >
                      Actions
                    </button>
                  }
                >
                  <li className="pr+ dropdown-menu--li">
                    <button
                      className={`pv-- ${!selectedUsers.length && 'disabled'}`}
                      disabled={!selectedUsers.length}
                      onClick={() => this.removeUsers()}
                    >
                      Remove direct assignment
                    </button>
                  </li>
                  <li className="pr+ dropdown-menu--li">
                    <button
                      className={`pv-- ${!selectedUsers.length && 'disabled'}`}
                      disabled={!selectedUsers.length}
                      onClick={() => this.toggleWorkspaceAdminRole('WORKSPACE_ADMIN')}
                    >
                      Change to workspace administrator
                    </button>
                  </li>
                  <li className="pr+ dropdown-menu--li">
                    <button
                      className={`pv-- ${!selectedUsers.length && 'disabled'}`}
                      disabled={!selectedUsers.length}
                      onClick={() => this.toggleWorkspaceAdminRole('USER')}
                    >
                      Change to workspace user
                    </button>
                  </li>
                </DropdownMenu>
              )}
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1">
          <TeamUsersList
            users={teamUsers}
            filteredUsers={filteredTeamUsers}
            selectedUsers={selectedUsers}
            manageUsers={manageUsers}
            updateUsers={updateUsers}
            showGroups={showGroups}
            teamId={teamId}
            isFetchingTeamUsers={isFetching}
            quickViewsByUserId={quickViewsByUserId}
            showDirectMembership={showDirectMembership}
            toggleUserQuickView={(userId, column) => this.toggleUserQuickView(userId, column)}
            handleUserSelection={userId => this.handleUserSelection(userId)}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    myState: state.myState,
    orgState: state.orgState,
    teamState: state.teamState,
    teamUsersState: state.teamUsersState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    teamUsersActions: bindActionCreators(teamUsersActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
