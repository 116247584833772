import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';

export const FILTER_TEAM_GROUPS = 'FILTER_TEAM_GROUPS';
export const FILTER_TEAMS_BY_INPUT = 'FILTER_TEAMS_BY_INPUT';
export const RESET_TEAM_DETAILS = 'RESET_TEAM_DETAILS';
export const RESET_TEAM_SORT = 'RESET_TEAM_SORT';
export const RESET_WORKSPACE_QUICK_VIEWS = 'RESET_WORKSPACE_QUICK_VIEWS';
export const SET_TEAM_DETAILS = 'SET_TEAM_DETAILS';
export const SHOW_WORKSPACE_MODAL = 'SHOW_WORKSPACE_MODAL';
export const TOGGLE_WORKSPACE_GROUPS_VIEW = 'TOGGLE_WORKSPACE_GROUPS_VIEW';
export const UPDATE_IS_FETCHING_TEAM_GROUPS = 'UPDATE_IS_FETCHING_TEAM_GROUPS';
export const UPDATE_ORG_TEAM_GROUPS_SORT = 'UPDATE_ORG_TEAM_GROUPS_SORT';
export const UPDATE_ORG_TEAM_SORT = 'UPDATE_ORG_TEAM_SORT';
export const UPDATE_ORG_TEAMS = 'UPDATE_ORG_TEAMS';
export const UPDATE_SELECTED_ORG_TEAMS = 'UPDATE_SELECTED_ORG_TEAMS';
export const UPDATE_SELECTED_WORKSPACE_GROUPS = 'UPDATE_SELECTED_WORKSPACE_GROUPS';
export const UPDATE_ORG_WORKSPACE_ACTIVE_TAB = 'UPDATE_ORG_WORKSPACE_ACTIVE_TAB';

export const updateActiveTab = tab => ({
  type: UPDATE_ORG_WORKSPACE_ACTIVE_TAB,
  tab,
});

export const fetchOrgTeams = activeTeamId => (dispatch, getState) => {
  const { orgState } = getState();
  const { org = {} } = orgState;

  if (!org.id) {
    return [];
  }

  return ApiService.get(ApiConstants.orgTeamsURL(org))
    .then(
      res => {
        dispatch(updateOrgTeams(res));

        if (activeTeamId) {
          const activeTeam = res.find(team => team.id === activeTeamId);

          if (activeTeam) {
            dispatch(fetchTeamGroups(activeTeam));
          }
        }

        return res;
      },
      () => {
        dispatch(updateOrgTeams([]));
        return [];
      }
    )
    .catch(err => {
      ErrorService.capture('Error fetching org teams', err);
      return [];
    });
};

export const fetchTeamGroups = team => dispatch => {
  dispatch(updateIsFetchingTeamGroups(true));
  const { numGroups, _links = {} } = team;
  const { groups = {} } = _links;
  const { href } = groups;

  if (!numGroups) {
    dispatch(setTeamDetails({ groups: [] }));
  } else {
    ApiService.get(href).then(res => {
      dispatch(setTeamDetails({ groups: res }));
    });
  }
};

export const updateIsFetchingTeamGroups = bool => ({
  type: UPDATE_IS_FETCHING_TEAM_GROUPS,
  bool,
});

export const updateOrgTeams = teams => ({
  type: UPDATE_ORG_TEAMS,
  teams,
});

export const updateSelectedOrgTeams = teamId => ({
  type: UPDATE_SELECTED_ORG_TEAMS,
  teamId,
});

export const showModal = modalType => ({
  type: SHOW_WORKSPACE_MODAL,
  modalType,
});

export const updateSelectedWorkspaceGroups = groupId => ({
  type: UPDATE_SELECTED_WORKSPACE_GROUPS,
  groupId,
});

export const filterTeamsByInput = value => ({
  type: FILTER_TEAMS_BY_INPUT,
  value,
});

export const setTeamDetails = ({ groups = [] }) => ({
  type: SET_TEAM_DETAILS,
  groups,
});

export const resetTeamDetails = () => ({
  type: RESET_TEAM_DETAILS,
});

export const filterTeamGroupsByInput = value => ({
  type: FILTER_TEAM_GROUPS,
  value,
});

export const updateSort = column => ({
  type: UPDATE_ORG_TEAM_SORT,
  column,
});

export const updateTeamGroupSort = column => ({
  type: UPDATE_ORG_TEAM_GROUPS_SORT,
  column,
});

export const resetTeamSort = () => ({
  type: RESET_TEAM_SORT,
});

export const toggleWorkspaceQuickView = (workspaceId, column) => ({
  type: TOGGLE_WORKSPACE_GROUPS_VIEW,
  workspaceId,
  column,
});

export const resetWorkspaceQuickViews = () => ({
  type: RESET_WORKSPACE_QUICK_VIEWS,
});
