import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';

import * as welcomeActions from '~/actions/welcome';

import CopyToClipboard from '~/containers/CopyToClipboard';
import ScanTips from '~/components/ScanTips';

interface WorkspaceWelcomeScanPageProps extends RouteComponentProps {
  welcomeActions: object;
  welcomeState: object;
}

class WorkspaceWelcomeScanPage extends React.Component<WorkspaceWelcomeScanPageProps, {}> {
  updateScanStatus(bool) {
    this.props.welcomeActions.updateScanStatus(bool);
    this.props.welcomeActions.trackAgentSetup('SCAN_DONE');
  }

  updateScanTips(scanTip) {
    this.props.welcomeActions.updateScanTips(scanTip);
  }

  render() {
    const { match, welcomeState } = this.props;
    const { params = {} } = match;
    const { scanTip } = welcomeState;
    const { teamId } = params;

    return (
      <div className="">
        <Helmet>
          <title>Welcome - Scan Your Code</title>
        </Helmet>
        <div className="grid mt mb+">
          <div className="grid__item col-1-6" />
          <div className="grid__item col-7-12">
            <div className="col-3-4">
              <div className="font--h4">Scan</div>
              <p className="">
                In order to accurately identify libraries and vulnerabilities in your project, the
                scanner needs to build your code. Make sure your build environment is configured
                correctly and that your project can build.
              </p>
              <p className="mb---">
                In a terminal navigate to your project's source code directory and enter this
                command:
              </p>
            </div>
            <div className="col-3-4">
              <CopyToClipboard value={`srcclr scan`} id="srcclr-scan" />
            </div>
            <div className="mt-- ">
              <em>Your scan may take a few minutes to complete.</em>
            </div>
            <div className="mt-">
              <ScanTips scanTip={scanTip} updateScanTips={tip => this.updateScanTips(tip)} />
            </div>
            <div className="mt">
              <Link
                className="btn--success pv- ph"
                to={`/workspaces/${teamId}/welcome`}
                onClick={() => this.updateScanStatus(true)}
              >
                Done
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    welcomeState: state.welcomeState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    welcomeActions: bindActionCreators(welcomeActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceWelcomeScanPage);
