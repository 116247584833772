import React from 'react';
import { useSelector } from 'react-redux';
import SourceClearModal from '~/components/SourceClearModal';
import ReportActionsWrapper from '~/components/ReportComponents/ReportActionsWrapper';
import DropdownList from '~/components/DropdownList';
import ReportStringFilter from '~/containers/ReportStringFilter';
import Pagination from '~/components/Pagination';
import ReportHeaderRow from '~/components/ReportComponents/ReportHeaderRow';
import ReportHeaderItem from '~/components/ReportComponents/ReportHeaderItem';
import useLanguageTypeSelect from '~/hooks/useLanguageTypeSelect';
import { LibraryCatalogState } from '~/types/LibraryCatalog';

interface AddNewLibraryModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddNewLibraryModal: React.FunctionComponent<AddNewLibraryModalProps> = props => {
  const { isOpen, onClose } = props;
  const { libraryCatalogState } = useSelector(state => state as { [key: string]: any });
  const { options, open, onToggle } = useLanguageTypeSelect(false, false);

  const { libraries, newLibraryModal } = libraryCatalogState as LibraryCatalogState;

  const columnWidths = {
    libraryName: 'col-4-12',
    ossLicense: '',
    ossLicenseRisk: '',
  };

  // const dispatch = useDispatch();

  function onLanguageTypeSelect() {}

  /* pagination, filter, and sort */
  function handlePageClick(page) {
    //TODO : implement fetchLibrary actions
    // dispatch(libraryCatalogActions.fetchCatalog(filter, page));
    return page;
  }

  function updateSortField(field) {
    //TODO : implement refreshLibrary actions and sortLibraryBy
    // dispatch(libraryCatalogActions.sortBy(field));
    // refreshCatalog();
    return field;
  }

  return (
    <SourceClearModal
      isOpen={isOpen}
      title="Add New Library to Commercial Licenses"
      onClose={() => onClose()}
      width={800}
      minHeight={600}
    >
      {/* Search form */}
      <p>Select language and enter search criteria to add new library.</p>
      <ReportActionsWrapper>
        <div className="add-library--wrapper">
          <div className="p-">
            <div className="flex flex-grow--1">
              <label>
                <div className="flex">
                  <div className="p-" data-automation-id="AddNewLibraryModal-LanguageLabel">
                    Language: <span className="color--danger">*</span>
                  </div>
                  <div>
                    <DropdownList
                      onToggle={() => onToggle(!isOpen)}
                      isOpen={open}
                      items={options}
                      value={newLibraryModal.addLibraryLanguageType}
                      onSelect={onLanguageTypeSelect}
                      automationId="AddNewLibraryModal-DropdownListFilter-LanguageType"
                    />
                  </div>
                </div>
              </label>
              <div className="add-library--input-end">
                <label className="flex flex--align-items--center flex--justify-content--end">
                  <div className="flex">
                    <div className="p-" data-automation-id="AddNewLibraryModal-LicenseLabel">
                      License:
                    </div>
                    <input
                      className="add-library--input"
                      name="license"
                      data-automation-id="AddNewLibraryModal-LicenseInput"
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="flex flex-grow--1">
              <div className="add-library--input-end">
                <label>
                  <div className="flex">
                    <div
                      className="p- ml"
                      data-automation-id="AddNewLibraryModal-LibraryLabel"
                    >
                      Library:<span className="color--danger">*</span>
                    </div>
                    <input
                      type="text"
                      className="add-library--input"
                      name="library"
                      data-automation-id="AddNewLibraryModal-libraryInput"
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="flex flex--align-items--center flex--justify-content--end">
              <button className="btn--primary-clear">Search</button>
            </div>
          </div>
          <div className="grid__item col-1-1 p-- flex flex--justify-content--space-between bg-color--white-medium">
            <div className="flex">
              <div className="width--240 flex" data-automation-id="LibraryCatalogPage-Search">
                <ReportStringFilter
                  label="Search libraries"
                  field="text"
                  value={newLibraryModal.libraries}
                  onChange={() => {}}
                />
              </div>
              <div
                className="pl- flex align-items--center"
                data-automation-id="LibraryCatalog-Total"
              >
                {libraries.totalElements} librar
                {libraries.totalElements === 1 ? 'y' : 'ies'}
              </div>
            </div>
            <div className="flex">
              <Pagination
                currentPage={libraries.number}
                totalPages={libraries.totalPages || 1}
                handlePageClick={page => handlePageClick(page)}
              />
            </div>
          </div>
        </div>
      </ReportActionsWrapper>
      {/* libraries table */}
      <div className="flex flex--flex-direction--column add-library--results-container">
        <ReportHeaderRow>
          <ReportHeaderItem
            label="Library"
            field="name"
            isSortable={true}
            onClick={updateSortField}
            currentSort={newLibraryModal.librarySort}
            widthClass={columnWidths.libraryName}
            automationId="ReportHeaderItem-AddLibrary-Library"
          />
          <ReportHeaderItem
            label="License"
            field="license"
            onClick={updateSortField}
            widthClass={columnWidths.ossLicense}
            automationId="ReportHeaderItem-AddLibrary-License"
          />
          <ReportHeaderItem
            label="Coordinates"
            field="coordinates"
            widthClass={columnWidths.ossLicenseRisk}
            automationId="ReportHeaderItem-AddLibrary-Coordinates"
          />
        </ReportHeaderRow>
        {/* library rows */}
        <h3 className="ml color--muted mt+">No Libraries found</h3>
        <div className="add-library--form-buttons text--right">
          <button className="pv- col-1-12 mr-" onClick={() => onClose()}>
            Cancel
          </button>
          <button className="pv- col-1-12 btn--success">Add</button>
        </div>
      </div>
    </SourceClearModal>
  );
};
export default AddNewLibraryModal;
