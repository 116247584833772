import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as welcomeActions from '~/actions/welcome';

import projectDetails from '~/images/projectDetails.png';

interface WorkspaceWelcomeScanHelpPageProps extends RouteComponentProps {
  welcomeActions: object;
  welcomeState: object;
}

class WorkspaceWelcomeScanHelpPage extends React.Component<WorkspaceWelcomeScanHelpPageProps, {}> {
  componentDidMount() {
    this.props.welcomeActions.fetchAgentEvents();
  }

  trackWelcomeFlowStep(target) {
    this.props.welcomeActions.trackWelcomeFlowStep(target);
  }

  render() {
    const { welcomeState, match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    const { isScanPersisted } = welcomeState;

    return (
      <div>
        <div className="container">
          <div className="grid grid--full height--100">
            <div className="grid__item col-1-6" />
            <div className="grid__item col-1-2 mt">
              {isScanPersisted ? (
                <div className="pr+">
                  <p>
                    <strong>Congratulations! Your scan is complete.</strong> It's time to view your
                    results.
                  </p>
                </div>
              ) : (
                <div className="pr+">
                  <div className="mb--">
                    <strong>
                      You're so close to knowing about any vulnerabilities in your project!
                    </strong>
                  </div>
                  <div>
                    If you're seeing this page and you've started a scan, please wait as it finishes
                    up.
                  </div>
                  <div>
                    If you haven't scanned,{' '}
                    <Link to={`/workspaces/${teamId}/welcome/scan`} className="link--obvious">
                      see our easy instructions
                    </Link>{' '}
                    and come back to view your results.
                  </div>
                </div>
              )}
            </div>
            <div className="grid__item col-1-6 mt text--right">
              {isScanPersisted ? (
                <Link
                  to={`/workspaces/${teamId}/projects`}
                  onClick={() => this.trackWelcomeFlowStep('VIEW')}
                  className="btn--primary pv- ph bo-rad--0 font-family--space-mono-bold"
                >
                  VIEW SCAN RESULTS
                </Link>
              ) : (
                <button
                  disabled
                  className="btn--success-clear bo--0 pv- ph bo-rad--0 font-family--space-mono-bold disabled"
                >
                  SCAN NOT COMPLETE
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="container bg-color--black-dark mt">
          <div className="grid grid--full">
            <div className="grid__item col-1-6" />
            <div className="grid__item col-2-3">
              <div className="col-1-1 flex justify-content--center">
                <div className="downward-triangle" />
              </div>
              <div className="color--white col-1-1 text--center mt pb--">
                <strong>
                  When you scan a project, SourceClear generates a report that tells you about
                  libraries and vulnerabilities in your code. Here's a sample:
                </strong>
              </div>
              <div className="col-1-1">
                <img src={projectDetails} className="col-1-1" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    welcomeState: state.welcomeState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    welcomeActions: bindActionCreators(welcomeActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceWelcomeScanHelpPage);
