import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import { getScopeForRequest } from '~/actions/reports';

import { Dispatch } from 'redux';
import Helpers from '~/utils/Helpers';

export const RECEIVE_FETCH_STATS_SUCCESS = 'RECEIVE_FETCH_STATS_SUCCESS';
export const REQUEST_FETCH_STATS = 'REQUEST_FETCH_STATS';
export const RECEIVE_FETCH_STATS_ERROR = 'RECEIVE_FETCH_STATS_ERROR';
export const RECEIVE_FETCH_PROJECT_STATS_SUCCESS = 'RECEIVE_FETCH_PROJECT_STATS_SUCCESS';
export const REQUEST_FETCH_PROJECT_STATS = 'REQUEST_FETCH_PROJECT_STATS';
export const RECEIVE_FETCH_PROJECT_STATS_ERROR = 'RECEIVE_FETCH_PROJECT_STATS_ERROR';
export const RECEIVE_FETCH_WORKSPACE_STATS_SUCCESS = 'RECEIVE_FETCH_WORKSPACE_STATS_SUCCESS';
export const REQUEST_FETCH_WORKSPACE_STATS = 'REQUEST_FETCH_WORKSPACE_STATS';
export const RECEIVE_FETCH_WORKSPACE_STATS_ERROR = 'RECEIVE_FETCH_WORKSPACE_STATS_ERROR';
export const RESET_STATS_DATA = 'RESET_STATS_DATA';

export const requestFetchStats = () => ({
  type: REQUEST_FETCH_STATS,
});

export const receiveFetchStatsSuccess = stats => ({
  type: RECEIVE_FETCH_STATS_SUCCESS,
  stats,
});

export const receiveFetchStatsError = () => ({
  type: RECEIVE_FETCH_STATS_ERROR,
});

export const resetStatsData = () => ({
  type: RESET_STATS_DATA,
});

export const fetchWorkspaceStats = (teamId: string) => (dispatch: Dispatch, getState) => {
  const {
    reportScope: { scanAfterDate, scanBeforeDate },
  } = getState();
  dispatch(requestFetchWorkspaceStats(teamId));
  const scopeForRequest: Report.Scopes = {
    agents: '*',
    paths: '*',
    repos: '*',
    favoriteReposOnly: false,
  };
  if (Helpers.hasScanDateEnabled()) {
    scopeForRequest.scanAfterDate = scanAfterDate;
    scopeForRequest.scanBeforeDate = scanBeforeDate;
  }

  return ApiService.post(`/teams/${teamId}/reports/stats`, { data: scopeForRequest })
    .then(res => {
      dispatch(receiveFetchWorkspaceStatsSuccess(res, teamId));
    })
    .catch(err => {
      dispatch(receiveFetchWorkspaceStatsError(teamId));
      ErrorService.capture('Failed to grab team stats', { err, teamId, scopeForRequest });
    });
};

export const fetchProjectStats = (teamId, projectId) => (dispatch: Dispatch, getState) => {
  dispatch(requestFetchProjectStats(projectId));
  const scopeForRequest = getScopeForRequest(getState());

  return ApiService.post(`/teams/${teamId}/reports/stats`, { data: scopeForRequest })
    .then(res => {
      dispatch(receiveFetchProjectStatsSuccess(res, projectId));
    })
    .catch(err => {
      dispatch(receiveFetchProjectStatsError(projectId));
      ErrorService.capture('Failed to grab team stats', { err, teamId, scopeForRequest });
    });
};

export const fetchStats = teamId => (dispatch: Dispatch, getState) => {
  dispatch(requestFetchStats());
  const scopeForRequest = getScopeForRequest(getState());

  return ApiService.post(`/teams/${teamId}/reports/stats`, { data: scopeForRequest })
    .then(res => {
      dispatch(receiveFetchStatsSuccess(res));
    })
    .catch(err => {
      dispatch(receiveFetchStatsError());
      ErrorService.capture('Failed to grab team stats', { err, teamId, scopeForRequest });
    });
};

export const requestFetchProjectStats = projectId => ({
  type: REQUEST_FETCH_PROJECT_STATS,
  projectId,
});

export const receiveFetchProjectStatsSuccess = (stats, projectId) => ({
  type: RECEIVE_FETCH_PROJECT_STATS_SUCCESS,
  stats,
  projectId,
});

export const receiveFetchProjectStatsError = projectId => ({
  type: RECEIVE_FETCH_PROJECT_STATS_ERROR,
  projectId,
});

export const requestFetchWorkspaceStats = workspaceId => ({
  type: REQUEST_FETCH_WORKSPACE_STATS,
  workspaceId,
});

export const receiveFetchWorkspaceStatsSuccess = (stats, workspaceId) => ({
  type: RECEIVE_FETCH_WORKSPACE_STATS_SUCCESS,
  stats,
  workspaceId,
});

export const receiveFetchWorkspaceStatsError = workspaceId => ({
  type: RECEIVE_FETCH_WORKSPACE_STATS_ERROR,
  workspaceId,
});
