import _ from 'lodash';
import { AxiosError } from 'axios';
import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import { veracodeApplicationsService, vcAppLinkingErrorMessage } from '~/constants/ModelConstants';
import config from '~/config';
import * as toastrActions from '~/actions/toastr';
import {
  FETCH_VC_APPLICATIONS_REQUEST,
  FETCH_VC_APPLICATIONS_SUCCESS,
  FETCH_VC_APPLICATIONS_FAILURE,
  LINK_VC_APPLICATION_REQUEST,
  LINK_VC_APPLICATION_SUCCESS,
  LINK_VC_APPLICATION_FAILURE,
  UNLINK_VC_APPLICATION_REQUEST,
  UNLINK_VC_APPLICATION_SUCCESS,
  UNLINK_VC_APPLICATION_FAILURE,
  FETCH_LINKED_VC_APPLICATION_REQUEST,
  FETCH_LINKED_VC_APPLICATION_SUCCESS,
  FETCH_LINKED_VC_APPLICATION_FAILURE,
  UPDATE_SELECTED_DEFAULT_APPLICATION,
  RESET_SELECTED_DEFAULT_APPLICATION_VALUE,
  RESET_UPDATE_LINK_STATES,
  RESET_VC_APPLICATIONS,
  ADD_LINKED_APPLICATION,
  VCApplicationsResponse,
  LinkedApplicationResponse,
} from '~/actions/vcAppActions/vcAppActionsTypes/types';
import { LinkedApplication } from '~/reducers/vcAppState/vcAppStateTypes/types';

const UPDATE_LINK_STATES_TIMEOUT = 900;

export const fetchVeracodeApplicationsRequest = () => ({
  type: FETCH_VC_APPLICATIONS_REQUEST,
});

export const fetchVeracodeApplicationsSuccess = (applications: any) => {
  const sortedApplications = _.sortBy(applications, application => application.name);
  return {
    type: FETCH_VC_APPLICATIONS_SUCCESS,
    applications: sortedApplications,
  };
};

export const fetchVeracodeApplicationsFailure = (message: string) => ({
  type: FETCH_VC_APPLICATIONS_FAILURE,
  message,
});

export const linkApplicationRequest = () => ({
  type: LINK_VC_APPLICATION_REQUEST,
});

export const linkApplicationSuccess = (appId: string, projectId: string) => ({
  type: LINK_VC_APPLICATION_SUCCESS,
  linkedApp: { appId, projectId },
});

export const linkApplicationFailure = (message: string) => ({
  type: LINK_VC_APPLICATION_FAILURE,
  message,
});

export const unlinkApplicationRequest = () => ({
  type: UNLINK_VC_APPLICATION_REQUEST,
});

export const unlinkApplicationSuccess = () => ({
  type: UNLINK_VC_APPLICATION_SUCCESS,
});

export const unlinkApplicationFailure = (message: string) => ({
  type: UNLINK_VC_APPLICATION_FAILURE,
  message,
});

export const fetchLinkedVeracodeApplicationRequest = () => ({
  type: FETCH_LINKED_VC_APPLICATION_REQUEST,
});

export const fetchLinkedVeracodeApplicationSuccess = (linkedApp: LinkedApplication) => ({
  type: FETCH_LINKED_VC_APPLICATION_SUCCESS,
  linkedApp,
});

export const fetchLinkedVeracodeApplicationFailure = (message: string) => ({
  type: FETCH_LINKED_VC_APPLICATION_FAILURE,
  message,
});

export const resetUpdateLinkStates = () => ({
  type: RESET_UPDATE_LINK_STATES,
});

export const updateSelectedDefaultApplication = (selectedApplication: LinkedApplication) => ({
  type: UPDATE_SELECTED_DEFAULT_APPLICATION,
  selectedApplication,
});

export const resetVCApplications = () => ({
  type: RESET_VC_APPLICATIONS,
});

export const resetSelectedDefaultApplicationValue = () => ({
  type: RESET_SELECTED_DEFAULT_APPLICATION_VALUE,
});

export const updateSelectedDefaultApplicationValue = (field: string) => (
  dispatch: any,
  getState: any
) => {
  const {
    vcApplicationState: {
      applicationsState: { applications },
    },
  } = getState();

  const selectedItem = applications.find(
    (application: LinkedApplication) => application.value === field
  );

  dispatch(updateSelectedDefaultApplication(selectedItem));
};

export const fetchLinkedVeracodeApplication = (projectId: string) => (dispatch: any, getState) => {
  const {
    vcApplicationState: {
      applicationsState: { applications: options },
    },
  } = getState();

  return (ApiService as any)
    .get(`/repos/${projectId}?with=refs`)
    .then(async (response: LinkedApplicationResponse) => {
      const { appId, id: projectId } = response;

      const application = appId
        ? await dispatch(fetchLinkedVeracodeApplicationByLegacyId(appId, projectId))
        : null;

      let linkedApplication;
      if (application) {
        const {
          id,
          profile: { name },
        } = application;
        linkedApplication = {
          projectId,
          appId: id,
          name,
          label: name,
          value: name,
        };
      } else {
        // default to 'No link' if no linked application is found by appId
        linkedApplication = {
          appId: 'no-link',
          projectId,
          label: 'no-link',
          name: 'no-link',
          value: 'No link',
        };
      }

      // determine if linked app is in initial application data (currently limited to 50 applications)
      const linkedAppExistsInInitialApplications = options.some(
        option => option.appId === linkedApplication.appId
      );
      // if linked app is not in the initial data, add linked app
      if (!linkedAppExistsInInitialApplications) {
        dispatch(addLinkedAppToApplications(linkedApplication));
      }

      dispatch(fetchLinkedVeracodeApplicationSuccess(linkedApplication));
    })
    .catch((err: AxiosError) => {
      dispatch(
        toastrActions.addToastr({
          id: 'FETCH_LINKED_VERACODE_APPLICATION_FAILURE',
          title: 'Error fetching linked application',
          level: 'error',
          message: `${formattedErrorMessage(err, vcAppLinkingErrorMessage.fetchLinkedApp)}`,
        })
      );
      dispatch(
        fetchLinkedVeracodeApplicationFailure(
          formattedErrorMessage(err, vcAppLinkingErrorMessage.unlinkApp)
        )
      );
      ErrorService.capture(vcAppLinkingErrorMessage.fetchLinkedApp, err);
    });
};
export const addLinkedAppToApplications = linkedApplication => ({
  type: ADD_LINKED_APPLICATION,
  application: linkedApplication,
});

export const updateLink = (projectId: string) => (dispatch: any, getState: any) => {
  const {
    vcApplicationState: {
      updateApplicationState: {
        selectedDefaultApplication: { appId: selectedDefaultApplicationId },
      },
    },
  } = getState();

  if (selectedDefaultApplicationId === 'no-link') {
    dispatch(unlinkApp(projectId));
  } else {
    dispatch(linkApp(selectedDefaultApplicationId, projectId));
  }
};

export const linkApp = (appId: string, projectId: string) => (dispatch: any) => {
  dispatch(linkApplicationRequest());
  (ApiService as any)
    .put(`/apps/${appId}/projects/${projectId}`)
    .then((response: LinkedApplicationResponse) => {
      const { appId, id: projectId } = response;
      dispatch(linkApplicationSuccess(appId, projectId));
      setTimeout(() => dispatch(resetUpdateLinkStates()), UPDATE_LINK_STATES_TIMEOUT);
    })
    .catch((err: AxiosError) => {
      dispatch(
        toastrActions.addToastr({
          id: 'LINK_VERACODE_APPLICATION_FAILURE',
          title: 'Error linking application',
          level: 'error',
          message: `${formattedErrorMessage(err, vcAppLinkingErrorMessage.linkApp)}`,
        })
      );
      dispatch(
        linkApplicationFailure(formattedErrorMessage(err, vcAppLinkingErrorMessage.unlinkApp))
      );
      ErrorService.capture(vcAppLinkingErrorMessage.linkApp, err);
    });
};

export const unlinkApp = (projectId: string) => (dispatch: any, getState: any) => {
  const {
    vcApplicationState: {
      updateApplicationState: {
        linkedApplication: { appId },
      },
    },
  } = getState();

  dispatch(unlinkApplicationRequest());
  (ApiService as any)
    .del(`/apps/${appId}/projects/${projectId}`)
    .then(() => {
      dispatch(unlinkApplicationSuccess());
      setTimeout(() => dispatch(resetUpdateLinkStates()), UPDATE_LINK_STATES_TIMEOUT);
    })
    .catch((err: AxiosError) => {
      dispatch(
        toastrActions.addToastr({
          id: 'UNLINK_VERACODE_APPLICATION_FAILURE',
          title: 'Error unlinking application',
          level: 'error',
          message: `${formattedErrorMessage(err, vcAppLinkingErrorMessage.unlinkApp)}`,
        })
      );
      dispatch(
        unlinkApplicationFailure(formattedErrorMessage(err, vcAppLinkingErrorMessage.unlinkApp))
      );
      ErrorService.capture(vcAppLinkingErrorMessage.unlinkApp, err);
    });
};

export const fetchLinkedVeracodeApplicationByLegacyId = (legacyId: string, projectId: string) => async (dispatch: any, getState) => {
  const vcApplicationByLegacyIdUrl = `${config.VERACODE_UIGATEWAY_HOST}${veracodeApplicationsService}?legacy_id=${legacyId}`;
  const { _embedded : embedded } = await (ApiService as any).get(`${vcApplicationByLegacyIdUrl}`);
  if (embedded) {
    const { applications } = embedded;
    return applications[0];
  } else {
    // unlink application if application is deleted
    dispatch(unlinkAppWhenAppIsDeleted(legacyId, projectId));
    return null;
  }
};

// Separate unlink method, since this is not a user generated action (No toasts)
export const unlinkAppWhenAppIsDeleted = (appId: string, projectId: string) => (dispatch: any) => {
  dispatch(unlinkApplicationRequest());
  (ApiService as any)
    .del(`/apps/${appId}/projects/${projectId}`)
    .then(() => {
      dispatch(unlinkApplicationSuccess());
      setTimeout(() => dispatch(resetUpdateLinkStates()), UPDATE_LINK_STATES_TIMEOUT);
    })
    .catch((err: AxiosError) => {
      dispatch(unlinkApplicationFailure(formattedErrorMessage(err, vcAppLinkingErrorMessage.unlinkApp)));
      ErrorService.capture(vcAppLinkingErrorMessage.unlinkApp, err);
    });
};

export const fetchVeracodeApplications = (name = '') => async (dispatch: any) => {
  const vcApplicationsBaseUrl = `${config.VERACODE_UIGATEWAY_HOST}${veracodeApplicationsService}`;
  dispatch(fetchVeracodeApplicationsRequest());

  return (
    (ApiService as any)
      .get(`${vcApplicationsBaseUrl}?name=${name}`)
      .then((response: VCApplicationsResponse) => {
        const applications = response._embedded != undefined ? response._embedded.applications : [];
        // process the list of applications so it can easily be consumed by the dropdown
        const processedApplications = applications.map((application: any) => {
          const {
            id,
            profile: { name },
          } = application;

          return {
            appId: id,
            name,
            label: name,
            value: name,
          };
        });

        dispatch(fetchVeracodeApplicationsSuccess(processedApplications));
      })
      // Catch any errors from API request
      .catch((err: AxiosError) => {
        dispatch(
          toastrActions.addToastr({
            id: 'LINK_VERACODE_APPLICATION_FAILURE',
            title: 'Error fetching applications data',
            level: 'error',
            message: `${formattedErrorMessage(err, vcAppLinkingErrorMessage.fetchApplications)}`,
          })
        );
        dispatch(
          fetchVeracodeApplicationsFailure(
            formattedErrorMessage(err, vcAppLinkingErrorMessage.fetchApplications)
          )
        );
        ErrorService.capture(vcAppLinkingErrorMessage.fetchApplications, err);
      })
  );
};

// helper function to create a user friendly formatted error message

const formattedErrorMessage = (err: AxiosError, detailedMessage: string): string =>
  `${err.message ? `${err.message}:` : ''}  ${detailedMessage} `;
