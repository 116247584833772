import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';

export const FETCH_LIBRARY_CATALOG_IN_PROGRESS = 'FETCH_LIBRARY_CATALOG_IN_PROGRESS';

export const FETCH_LIBRARY_CATALOG_SUCCESS = 'FETCH_LIBRARY_CATALOG_SUCCESS';
export const FETCH_LIBRARY_CATALOG_FAILURE = 'FETCH_LIBRARY_CATALOG_FAILURE';

export const SWITCH_CATALOG_TYPE = 'SWITCH_CATALOG_TYPE';
export const SEARCH_CATALOG = 'SEARCH_CATALOG';
export const FILTER_CATALOG = 'FILTER_CATALOG';
export const SELECT_INSTANCE = 'SELECT_INSTANCE';
export const DESELECT_INSTANCE = 'DESELECT_INSTANCE';
export const CLEAR_SELECTED_INSTANCES = 'CLEAR_SELECTED_INSTANCES';
export const SORT_BY = 'SORT_BY';
export const RESET_FILTERS = 'RESET_FILTERS';
export const ADD_TO_CATALOG_REQUEST = 'ADD_TO_CATALOG_REQUEST';
export const ADD_TO_CATALOG_SUCCESS = 'ADD_TO_CATALOG_SUCCESS';
export const ADD_TO_CATALOG_FAILURE = 'ADD_TO_CATALOG_FAILURE';
export const ADD_TO_CATALOG_RESET = 'ADD_TO_CATALOG_RESET';
export const UPDATE_IN_CATALOG_STATUS = 'UPDATE_IN_CATALOG_STATUS';

export const FETCH_CSV_IN_PROGRESS = 'FETCH_CSV_IN_PROGRESS';
export const FETCH_CSV_SUCCESS = 'FETCH_CSV_SUCCESS';
export const FETCH_CSV_FAILURE = 'FETCH_CSV_FAILURE';
export const TOGGLE_LANGUAGE_TYPE_DROPDOWN = 'TOGGLE_LANGUAGE_TYPE_DROPDOWN';

import CsvHelper from '~/utils/CsvHelper';

export const fetchCatalog = (orgId, filter, page = 0, concat = false) => (dispatch, getState) => {
  const state = getState();
  const filter = state.libraryCatalogState.filter;
  const sort = state.libraryCatalogState.sort;

  dispatch(fetchCatalogInProgress);

  const pageQuery = ['page=' + page, 'size=20'];
  const sortQuery = Object.keys(sort).map(k => 'sort=' + k + ',' + sort[k]);

  const queryParams = pageQuery.concat(sortQuery).join('&');

  return ApiService.post(`/org/${orgId}/library-catalog:filter?${queryParams}`, {
    data: filter,
  }).then(
    result => {
      dispatch(fetchCatalogSuccess(result, concat));
    },
    error => {
      dispatch(fetchCatalogFailure(error));
    }
  );
};

export const downloadCSV = (type, id) => (dispatch, getState) => {
  const state = getState();
  const { [type]: catalog = {} } = state.libraryCatalogState;
  const totalElements = catalog.totalElements;

  const filter = state.libraryCatalogState.filter;
  const sort = state.libraryCatalogState.sort;

  dispatch(fetchCSVInProgress);

  const pageQuery = ['page=0', 'size=' + totalElements];
  const sortQuery = Object.keys(sort).map(k => `sort=${k},${sort[k]}`);

  const queryParams = pageQuery.concat(sortQuery).join('&');

  const url =
    type === 'personal'
      ? `/user/library-catalog:filter?${queryParams}`
      : `/orgs/${id}/library-catalog:filter?${queryParams}`;
  const fields = [
    {
      label: 'Library',
      value: 'instanceCommit.instance.name',
    },
    {
      label: 'Version',
      value: 'instanceCommit.instance.version',
    },
    {
      label: 'Updates Since Version',
      value: 'updatesSinceVersion',
    },
  ];

  return ApiService.post(url, { data: filter }).then(
    result => {
      dispatch(fetchCSVSuccess);
      CsvHelper.startCSVDownload('library-catalog', fields, result.content);
    },
    () => {
      dispatch(fetchCSVFailure);
    }
  );
};

export const removeInstances = (id, instances) => dispatch => {
  const url = `/orgs/${id}/library-catalog`;
  return ApiService.del(url, { data: instances }).then(() => {
    dispatch(clearSelectedInstances(instances));
  });
};

export const addToCatalog = (type, id, instance, reportType = 'LIBRARIES') => dispatch => {
  const instancePayload = typeof instance === 'object' ? instance : [instance];
  const url = type === 'personal' ? '/user/library-catalog' : `/orgs/${id}/library-catalog`;

  dispatch(addToCatalogRequest());

  return ApiService.post(url, { data: instancePayload })
    .then(() => {
      dispatch(addToCatalogSuccess());
      if (type === 'org') {
        dispatch(updateInCatalogStatus(reportType, instance));
      }
      setTimeout(() => {
        dispatch(addToCatalogReset());
      }, 2500);
    })
    .catch(err => {
      let errorMessage;
      if (err && err.status !== 500 && err.hasOwnProperty('message')) {
        errorMessage = err.message;
      } else {
        errorMessage = 'An error has occurred while processing your request.';
      }
      dispatch(addToCatalogFailure(errorMessage));
      ErrorService.capture('Failed to add catalog', { err });
    });
};

export const updateInCatalogStatus = (reportType, instance) => ({
  type: UPDATE_IN_CATALOG_STATUS,
  reportType,
  instance,
});

export const fetchCatalogInProgress = {
  type: FETCH_LIBRARY_CATALOG_IN_PROGRESS,
};

export const fetchCatalogSuccess = (result, concat) => ({
  type: FETCH_LIBRARY_CATALOG_SUCCESS,
  result,
  concat,
});

export const fetchCatalogFailure = err => ({
  type: FETCH_LIBRARY_CATALOG_FAILURE,
  err: err,
});

export const addToCatalogRequest = () => ({
  type: ADD_TO_CATALOG_REQUEST,
});

export const addToCatalogSuccess = () => ({
  type: ADD_TO_CATALOG_SUCCESS,
});

export const addToCatalogFailure = errorMessage => ({
  type: ADD_TO_CATALOG_FAILURE,
  errorMessage,
});

export const addToCatalogReset = () => ({
  type: ADD_TO_CATALOG_RESET,
});

export const switchCatalogType = t => ({
  type: SWITCH_CATALOG_TYPE,
  catalogType: t,
});

export const searchCatalog = s => ({
  type: SEARCH_CATALOG,
  searchFilter: s,
});

export const filterCatalog = (field, value) => ({
  type: FILTER_CATALOG,
  field,
  value,
});

export const toggleLanguageTypeDropdown = () => ({
  type: TOGGLE_LANGUAGE_TYPE_DROPDOWN,
});

export const selectInstance = value => ({
  type: SELECT_INSTANCE,
  value,
});

export const deselectInstance = value => ({
  type: DESELECT_INSTANCE,
  value,
});

export const clearSelectedInstances = instances => ({
  type: CLEAR_SELECTED_INSTANCES,
  instances,
});

export const sortBy = field => ({
  type: SORT_BY,
  field,
});

export const fetchCSVInProgress = {
  type: FETCH_CSV_IN_PROGRESS,
};

export const fetchCSVSuccess = {
  type: FETCH_CSV_SUCCESS,
};

export const fetchCSVFailure = {
  type: FETCH_CSV_FAILURE,
};

export const resetFilters = () => ({
  type: RESET_FILTERS,
});
