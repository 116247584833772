import React from 'react';
import ReportRowWrapper from '~/components/ReportComponents/ReportRowWrapper';

interface OrgIntegrationJiraRowProps {
  ticketTemplate: object;
  isSelected?: boolean;
  onRowSelectChange: (...args: any[]) => any;
  columnWidths: {
    [key: string]: string;
  };
  onTemplateNameSelect: (...args: any[]) => any;
}

const OrgIntegrationJiraRow: React.FunctionComponent<OrgIntegrationJiraRowProps> = props => {
  const { ticketTemplate, isSelected, columnWidths } = props;
  const { name, jiraProjectName, jiraIssueTypeName, id } = ticketTemplate;

  function handleChange(ticketTemplate) {
    props.onRowSelectChange(ticketTemplate);
  }

  function nameSelect(ticketTemplate) {
    props.onTemplateNameSelect(ticketTemplate);
  }

  return (
    <ReportRowWrapper data-e2e="report-row">
      <div
        className={`grid__item link--obvious ${columnWidths.name}`}
        onClick={() => nameSelect(ticketTemplate)}
      >
        {name}
      </div>
      <div className={`grid__item ${columnWidths.project}`}>{jiraProjectName}</div>
      <div className={`grid__item ${columnWidths.issue}`}>{jiraIssueTypeName}</div>
      <div className="grid__item">
        <div className="flex flex--align-items--center flex--justify-content--center">
          <label className="label--checkbox flex mb0">
            <div className="flex">
              <input
                type="checkbox"
                name={`ticketTemplate-${id}`}
                id={id}
                onChange={() => handleChange(ticketTemplate)}
                checked={isSelected}
              />
              <span className={`react--checkbox mr0`} />
            </div>
          </label>
        </div>
      </div>
    </ReportRowWrapper>
  );
};

export default OrgIntegrationJiraRow;
