import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import LoggedInHeader from '~/containers/LoggedInHeader';
import LoggedOutHeader from '~/components/LoggedOutHeader';
import Footer from '~/components/Footer';
import ErrorBoundary from '~/components/ErrorBoundary';
import scFavicon from '~/favicon.ico';
import { RootState } from '~/reducers';

interface SCPageProps {
  myState: App.MyState;
  orgState: App.OrgState;
  render: () => {};
}

class SCPage extends Component<SCPageProps> {
  render() {
    const {
      myState: {
        me: { id, organization },
      },
      orgState: { isOrgSlugInvalid },
      render,
    } = this.props;

    const scPageHeader = id ? (
      <LoggedInHeader />
    ) : (
      <LoggedOutHeader isOrgSlugInvalid={isOrgSlugInvalid} />
    );
    return (
      <Fragment>
        <Helmet titleTemplate="%s : SourceClear" defaultTitle="SourceClear">
          <link rel="shortcut icon" href={scFavicon} />
          <title>SourceClear</title>
        </Helmet>
        <div className="flex--header">{scPageHeader}</div>
        <div className="container flex flex--content flex--justify-content--center">
          <ErrorBoundary>{render()}</ErrorBoundary>
        </div>
        <div className="flex-footer">
          <Footer organization={organization} />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    myState: state.myState,
    orgState: state.orgState,
  };
}

export default connect(mapStateToProps)(SCPage);
