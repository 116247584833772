import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoaderWrapper from '~/components/LoaderWrapper';
import Helpers from '~/utils/Helpers';
import IssueHelper from '~/utils/IssueHelper';
import { RootState } from '~/reducers';
import { ReportType } from '~/reducers/reportDetailsPageState.types';
import { fetchIssuesEvents } from '~/actions/reportDetailsPage';

interface IssueHistoryProps {
  reportType: ReportType;
}

const IssueHistory = ({ reportType }: IssueHistoryProps) => {
  const dispatch = useDispatch();
  const { issueId } = useParams();
  useEffect(() => {
    dispatch(fetchIssuesEvents(reportType, issueId));
  }, []);
  const { isFetchingEvents, events } = useSelector(
    (state: RootState) => state.reportDetailsPageState[reportType]
  );
  return (
    <LoaderWrapper isLoaderShowing={isFetchingEvents}>
      <div className="mh">
        {events.map((event, index) => (
          <IssueEvent
            key={`${index}-${event.date}`}
            date={event.date}
            content={IssueHelper.buildIssueEventMessage(event)}
          />
        ))}
      </div>
    </LoaderWrapper>
  );
};

interface IssueEventProps {
  date: Date;
  content: JSX.Element;
}
const IssueEvent = ({ date, content }: IssueEventProps) => (
  <div className={'pr'}>
    <div className="grid">
      <div className="grid__item text--right position--relative pr col-1-3 bo-r--1 border-style--dashed border-color--muted-light pb pt color--black-light">
        <div className="font--12 text--bold">{Helpers.formatDate(date)}</div>
        <div className="font--11 text--bold">{Helpers.formatTime(date)}</div>
        <div className="bo-b--2 pb pl- position--absolute top--13 right--0 border-color--muted-light" />
      </div>
      <div className="grid__item col-2-3 pt pb bg-color--white">{content}</div>
    </div>
  </div>
);

export default IssueHistory;
