import produce from 'immer';
import * as actions from '~/actions/me';

export const defaultState: App.MyState = {
  me: {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    eula: {
      acceptedVersion: '',
      currentVersion: '',
    },
    twoFactorAuthEnabled: false,
  },
  fetchMeResolved: false,
  fetchMeFailed: false,
  isLoggedIn: false,
  eulaChecked: false,
  eulaAccepted: false,
  orgInvite: {
    email: '',
    expirationDate: '',
    organization: {},
  },
  teamInvite: {},
  isOnPaidTeam: false,
  notificationSettings: {
    newIssuesReportEnabled: false,
    weeklySummaryReportEnabled: false,
  },
  isDeletingMe: false,
  deleteMeErrorMessage: null,
  isFormDirty: false,
  updatingUser: false,
  updateError: {},
  expandChangePassword: false,
  expandAddPasswordLogin: false,
  passwordError: undefined,
  currentPassword: undefined,
  changingPassword: false,
  passwordChangeStatus: {},
  githubConnectError: false,
  emailValidationError: {},
  validatingEmail: false,
  validationEmailStatus: undefined,
  isFetchingMe: false,
};

const myState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.UPDATE_ME:
        draft.me = action.me;
        draft.isLoggedIn = action.isLoggedIn;
        draft.isFormDirty = false;
        draft.updatingUser = false;
        draft.updateError = {};
        draft.fetchMeResolved = true;
        draft.isFetchingMe = false;
        break;
      case actions.FETCH_ME_FAILURE:
        draft.fetchMeResolved = true;
        draft.isFetchingMe = false;
        draft.updatingUser = false;
        draft.fetchMeFailed = true;
        break;
      case actions.UPDATING_USER:
        draft.updatingUser = action.bool;
        draft.updateError = {};
        break;
      case actions.UPDATING_USER_FAILURE:
        draft.updatingUser = false;
        draft.updateError = action.error;
        break;
      case actions.TOGGLE_ACCEPT_EULA:
        draft.eulaChecked = !state.eulaChecked;
        break;
      case actions.EULA_ACCEPTED:
        draft.eulaAccepted = true;
        draft.me.eula.acceptedVersion = state.me.eula.currentVersion;
        break;
      case actions.UPDATE_ME_AUTH:
        draft.me.twoFactorAuthEnabled = action.bool;
        break;
      case actions.FETCH_ME_REQUEST:
        draft.isFetchingMe = true;
        break;
      case actions.UPDATE_TEAM_INVITE:
        draft.teamInvite = action.teamInvite;
        break;
      case actions.UPDATE_ORG_INVITE:
        draft.orgInvite = action.orgInvite;
        break;
      case actions.UPDATE_IS_ON_PAID_TEAM:
        draft.isOnPaidTeam = action.bool;
        break;
      case actions.UPDATE_NOTIFICATION_SETTINGS:
        draft.notificationSettings = action.notificationSettings;
        break;
      case actions.IS_DELETING_ME:
        draft.isDeletingMe = action.bool;
        break;
      case actions.DELETE_ME_FAILED:
        draft.deleteMeErrorMessage = action.message;
        draft.isDeletingMe = false;
        break;
      case actions.RESET_DELETE_ME_STATE:
        draft.deleteMeErrorMessage = null;
        draft.isDeletingMe = false;
        break;
      case actions.UPDATE_FIRST_NAME:
        draft.me.firstName = action.value;
        draft.isFormDirty = true;
        break;
      case actions.UPDATE_LAST_NAME:
        draft.me.lastName = action.value;
        draft.isFormDirty = true;
        break;
      case actions.UPDATE_EMAIL:
        draft.me.email = action.value;
        draft.isFormDirty = true;
        break;
      case actions.TOGGLE_CHANGE_PASSWORD:
        draft.expandChangePassword = !state.expandChangePassword;
        break;
      case actions.PASSWORD_ERROR:
        draft.passwordError = action.error;
        draft.passwordChangeStatus = {};
        break;
      case actions.UPDATE_CURRENT_PASSWORD_INPUT:
        draft.currentPassword = action.value;
        draft.passwordChangeStatus = {};
        break;
      case actions.CHANGING_PASSWORD:
        draft.changingPassword = action.bool;
        draft.passwordChangeStatus = {};
        break;
      case actions.PASSWORD_CHANGE_ERROR:
        draft.passwordChangeStatus = action.error;
        draft.changingPassword = false;
        break;
      case actions.PASSWORD_CHANGE_SUCCESS:
        draft.passwordChangeStatus = action.res;
        draft.changingPassword = false;
        draft.currentPassword = undefined;
        draft.expandChangePassword = false;
        break;
      case actions.TOGGLE_ADD_PASSWORD_LOGIN:
        draft.expandAddPasswordLogin = !state.expandAddPasswordLogin;
        break;
      case actions.TOGGLE_GITHUB_CONNECT_ERROR:
        draft.githubConnectError = !state.githubConnectError;
        break;
      case actions.EMAIL_VALIDATION_FAILURE:
        draft.emailValidationError = action.error;
        draft.validatingEmail = false;
        break;
      case actions.EMAIL_VALIDATION_SUCCESS:
        draft.emailValidationError = {};
        break;
      case actions.VALIDATING_EMAIL:
        draft.validatingEmail = true;
        break;
      case actions.VALIDATION_EMAIL_STATUS:
        draft.validationEmailStatus = action.status;
    }
  });
};

export default myState;
