import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Helpers from '~/utils/Helpers';

import * as groupActions from '~/actions/group';
import * as orgUserActions from '~/actions/orgUser';

import AddUsersToGroup from '~/components/AddUsersToGroup';

interface AddUsersToGroupModalProps {
  groupId: string;
  groupActions: object;
  orgUserActions: object;
  groupState: object;
  orgUserState: object;
  showModal: (...args: any[]) => any;
}
class AddUsersToGroupModal extends React.Component<AddUsersToGroupModalProps, {}> {
  componentDidMount() {
    const { orgUserState } = this.props;
    const { orgUsers = [] } = orgUserState;

    if (!orgUsers.length) {
      this.props.orgUserActions.fetchOrgUsers();
    }

    this.props.orgUserActions.resetSelectedUsers();
  }

  addUsersToGroup() {
    const { orgUserState, groupId } = this.props;
    const { selectedOrgUsers = [] } = orgUserState;

    this.props.groupActions.groupMultiUpdate([
      {
        groupId: parseInt(groupId),
        users: selectedOrgUsers,
      },
    ]);
  }

  updateSelectedUsers(userId) {
    this.props.orgUserActions.updateSelectedUsers(userId);
  }

  toggleAdminField(userId) {
    this.props.orgUserActions.toggleSelectedUserAdminField(userId);
  }

  showModal(modalType) {
    this.props.showModal(modalType);
  }

  render() {
    const { orgUserState, groupId, groupState } = this.props;
    const { orgUsers = [], selectedOrgUsers = [] } = orgUserState;
    const { groups = [] } = groupState;
    const activeGroup = groups.find(group => group.id === parseInt(groupId)) || {};
    const { users: groupUsers = [] } = activeGroup;
    const activeGroupUserIds = {};
    const selectedUsers = [];
    const selectableUsers = [];

    groupUsers.forEach(groupUser => {
      const { user = {} } = groupUser;
      activeGroupUserIds[user.id] = true;
    });

    const filteredOrgUsers =
      orgUsers.filter(userObj => {
        const { user = {} } = userObj;
        return !activeGroupUserIds[user.id];
      }) || [];

    if (!filteredOrgUsers.length) {
      return (
        <div className="grid">
          <div className="grid__item col-1-1 mt-">
            <div className="">Every available organization user already belongs to this team.</div>
          </div>
        </div>
      );
    }

    filteredOrgUsers.forEach(userObj => {
      const { user = {} } = userObj;
      const foundUser = selectedOrgUsers.find(selectedUser => selectedUser.userId === user.id);

      if (foundUser) {
        selectedUsers.push({
          ...user,
          admin: foundUser.admin,
        });
      } else {
        let formattedUserName = Helpers.formatUserName(user);

        selectableUsers.push({
          label: formattedUserName,
          value: user.id,
        });
      }
    });

    const buttons = (
      <div className="grid__item col-1-1 flex justify-content--end mt+">
        <button className="col-1-5 pv- mr-" onClick={() => this.showModal()}>
          Cancel
        </button>
        <button className="col-1-5 pv- btn--primary" onClick={() => this.addUsersToGroup()}>
          Save
        </button>
      </div>
    );
    return (
      <div className="">
        <AddUsersToGroup
          selectableUsers={selectableUsers}
          selectedUsers={selectedUsers}
          buttons={buttons}
          updateSelectedUsers={userId => this.updateSelectedUsers(userId)}
          toggleAdminField={userId => this.toggleAdminField(userId)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupState: state.groupState,
    orgUserState: state.orgUserState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUsersToGroupModal);
