import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import * as agentActions from '~/actions/agents';
import * as agentModalActions from '~/actions/agentModal';
import { AgentModalState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import { TeamState } from '~/reducers/teamState';

interface CreateWorkspaceAgentModalProps extends RouteComponentProps {
  agentModalState: AgentModalState;
  agentModalActions: object;
  teamState?: TeamState;
}

class CreateWorkspaceAgentModal extends React.Component<CreateWorkspaceAgentModalProps, {}> {
  componentDidMount() {
    const {
      agentModalState: { selectedWorkspace, workspaceLevelSelected },
    } = this.props;
    if (selectedWorkspace) {
      this.props.agentModalActions.updateWorkspace(selectedWorkspace);
    }
    if (workspaceLevelSelected) {
      this.props.agentModalActions.workspaceLevelSelected();
    }
  }

  componentWillUnmount() {
    this.props.agentModalActions.resetAgentModalState();
  }

  showModal(modalType) {
    this.props.showModal(modalType);
  }

  updateWorkspace(workspace) {
    const teamId = workspace.value.id;
    this.props.agentActions.fetchTeamAgents(teamId);

    this.props.agentModalActions.updateWorkspace(workspace);
    this.props.agentModalActions.workspaceLevelSelected();
  }

  render() {
    const {
      agentModalState: { selectedWorkspace },
      teamState,
    } = this.props;

    const { teams = [] } = teamState;
    const workspaceList = teams.map(team => {
      return {
        label: team.name,
        value: team,
      };
    });

    const currentSelectedWsVal = selectedWorkspace ? selectedWorkspace.value : '';

    return (
      <div className="grid">
        <div className="grid__item col-1-1">
          <div className="grid">
            <div className="grid__item col-1-1">
              <p>Select a workspace for this agent.</p>
            </div>
            <div className="grid__item col-1-1 mt flex align-items--center mb+">
              <div className="grid__item col-1-5 ml mr+">
                Workspace:
                <span className="color--danger">*</span>
              </div>
              <div className="pl---">
                <div className="width--200">
                  <Select
                    name="group-workspaces"
                    options={workspaceList}
                    onChange={workspace => this.updateWorkspace(workspace)}
                    value={workspaceList.find(option => option.value === currentSelectedWsVal)}
                    className={'srcclr-react-select-container'}
                    classNamePrefix={'srcclr-react-select'}
                    placeholder="Select..."
                    maxMenuHeight={170}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentModalState: state.agentModalState,
    teamState: state.teamState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    agentActions: bindActionCreators(agentActions, dispatch),
    agentModalActions: bindActionCreators(agentModalActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateWorkspaceAgentModal));
