import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ProjectSettingsLinkProjectToApplicationProps } from '~/containers/ProjectSettingsLinkProjectToApplicationTypes/types.ts';
import SelectDropDown from '~/containers/SelectDropDown';
import Tooltip from '~/components/Tooltip';
import VCAlertBox from '~/views/components/veracode/VCAlertBox';
import { VCAlertBoxLevels } from '~/types/VCAlertBoxLevels.d.ts';
import * as vcApplicationActions from '~/actions/vcAppActions/vcApplicationActions';

class ProjectSettingsLinkProjectToApplication extends Component<
  ProjectSettingsLinkProjectToApplicationProps
> {
  componentDidMount() {
    const { projectId, vcApplicationLinkActions } = this.props;
    vcApplicationLinkActions.fetchVeracodeApplications();
    vcApplicationLinkActions.fetchLinkedVeracodeApplication(projectId);
    vcApplicationLinkActions.resetUpdateLinkStates();
  }

  componentDidUpdate(prevProps) {
    const { projectId, vcApplicationLinkActions, vcApplicationState } = this.props;

    if (
      prevProps.vcApplicationState.applicationsState.applications.length !==
      vcApplicationState.applicationsState.applications.length
    ) {
      vcApplicationLinkActions.fetchLinkedVeracodeApplication(projectId);
    }
  }

  componentWillUnmount() {
    const { vcApplicationLinkActions } = this.props;
    vcApplicationLinkActions.resetSelectedDefaultApplicationValue();
  }
  getApplicationsByName(name: string) {
    const { vcApplicationLinkActions } = this.props;
    vcApplicationLinkActions.fetchVeracodeApplications(name);
  }
  saveChanges() {
    const { projectId, vcApplicationLinkActions } = this.props;
    vcApplicationLinkActions.updateLink(projectId);
  }
  handleLinkToApplicationsChange(field: string) {
    this.props.vcApplicationLinkActions.updateSelectedDefaultApplicationValue(field);
  }
  getErrorTooltip(tooltipId: string, errorMessage: string) {
    return (
      <div className="inline pl--">
        <Tooltip id={`${tooltipId}`} content={errorMessage}>
          <i className="sci__alerts sci color--warning font--16" />
        </Tooltip>
      </div>
    );
  }

  render() {
    const {
      vcApplicationState: {
        applicationsState: {
          applications: options,
          fetchApplicationsStates: { isFetching },
        },
        updateApplicationState: {
          selectedDefaultApplication,
          linkedApplication,
          updateApplicationStates: { isUpdating, updateSuccess, updateFailure },
          fetchLinkedApplicationErrorMessage,
        },
      },
      updateActivePage,
      projectBranchState: { selectedDefaultBranch },
    } = this.props;
    const { appId: linkedApplicationId } = linkedApplication;

    const noLinkGroup = options.slice(0, 1);
    const applicationsGroup = options.slice(1);

    const groupedOptions = [
      { label: '', options: noLinkGroup },
      { label: '', options: applicationsGroup },
    ];

    const saveButtonIsDisabled =
      selectedDefaultApplication.appId === linkedApplicationId ||
      !selectedDefaultBranch ||
      isFetching;

    return (
      <div>
        <h6 data-automation-id="ProjectSettingsLinkProjectToApplication-Title" className="text--uppercase bo-b--1 border-color--muted-light pb---">
          Link Project to Application
        </h6>
        {!selectedDefaultBranch && (
          <VCAlertBox
            type={VCAlertBoxLevels.INFO}
            message={
              'To enable this feature, first go to Project Settings and set the default branch on the Branches Page.'
            }
          />
        )}
        <p>
          Link this project to an application to see findings from agent scans together with
          findings from binary scans. To enable this feature, you must have a{' '}
          <span className="link--obvious" onClick={() => updateActivePage()}>
            default branch
          </span>{' '}
          set.
        </p>

        <div className="flex flex--align-items--center">
          <div className="col-1-3 inline-block">
            <SelectDropDown
              async={true}
              withGroupedOptions={true}
              className="vcAppLinkingNoLinkOption"
              isClearable={false}
              fieldName="linkToApplications"
              items={groupedOptions}
              value={selectedDefaultApplication.value}
              onChange={(_: unknown, field: string) => this.handleLinkToApplicationsChange(field)}
              noOptionsMessage={() => 'No options'}
              isLoading={isFetching}
              isDisabled={!selectedDefaultBranch}
              asyncGetOptions={(input: string) => this.getApplicationsByName(input)}
            />
          </div>
          <button
            className={`btn btn--success ml-- inline-block p-`}
            type="submit"
            onClick={() => this.saveChanges()}
            disabled={saveButtonIsDisabled}
          >
            Save
          </button>
          {isUpdating && (
            <i className="fa sci--sm fa-spin fa-spinner mh- color--primary font--14 ph0" />
          )}
          {updateSuccess && !isFetching && (
            <i className="fa sci--sm sci sci__check mh- color--primary font--14 ph0" />
          )}
          {updateFailure &&
            !isFetching &&
            this.getErrorTooltip(`update-app-linking-err`, fetchLinkedApplicationErrorMessage)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: ProjectSettingsLinkProjectToApplicationProps) {
  return {
    vcApplicationState: state.vcApplicationState,
    projectBranchState: state.projectBranchState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    vcApplicationLinkActions: bindActionCreators(vcApplicationActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSettingsLinkProjectToApplication);
