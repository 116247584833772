import * as actions from '~/actions/stats';

const statsByWorkspaceId = (state = {}, action) => {
  switch (action.type) {
    case actions.RECEIVE_FETCH_WORKSPACE_STATS_SUCCESS:
      return {
        ...state,
        [action.workspaceId]: {
          isFetching: false,
          hasError: false,
          data: action.stats,
        },
      };
    case actions.REQUEST_FETCH_WORKSPACE_STATS:
      return {
        ...state,
        [action.workspaceId]: {
          isFetching: true,
          hasError: false,
          data: (state[action.workspaceId] && state[action.workspaceId].data) || {},
        },
      };
    case actions.RECEIVE_FETCH_WORKSPACE_STATS_ERROR:
      return {
        ...state,
        [action.workspaceId]: {
          isFetching: false,
          hasError: true,
          data: (state[action.workspaceId] && state[action.workspaceId].data) || {},
        },
      };
    case actions.RESET_STATS_DATA:
      return {};
    default:
      return state;
  }
};

export default statsByWorkspaceId;
