import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import osxSrc from '~/images/apple-white.png';
import linuxSrc from '~/images/linux-large.svg';
import windowsSrc from '~/images/windows-white.png';

import { AgentOSOptionsProps } from '~/components/AgentOSOptions.types';

const AgentOSOptions: FunctionComponent<AgentOSOptionsProps> = props => {
  const { teamId, welcomeMode } = props;
  const urlBase = teamId
    ? `/workspaces/${teamId}/${welcomeMode ? 'welcome/agent' : 'agents/new'}`
    : `/org/settings/agents/new`;

  function selectOS(args) {
    props.selectOS(args);
  }

  return (
    <div className="grid mt-">
      <div className="grid__item col-1-3 flex">
        <Link
          to={`${urlBase}/${welcomeMode ? 'osx' : 'cli'}`}
          className="btn--primary p-- col-1-1 flex flex-direction--column align-items--center color--muted button--image"
          onClick={() => selectOS('OSX')}
          data-automation-id="AgentOSOptions-OSX"
        >
          <div className="font--h6">OS X</div>
          <div className="height--150 mt-">
            <img className="height--inherit" src={osxSrc} alt="" />
          </div>
          <div className="mt-">OS X 10.8 (Mountain Lion)</div>
          <div>and later</div>
        </Link>
      </div>
      <div className="grid__item col-1-3 flex">
        <Link
          to={`${urlBase}/${welcomeMode ? 'linux' : 'cli'}`}
          className="btn--primary p-- col-1-1 flex flex-direction--column align-items--center color--muted button--image"
          onClick={() => selectOS('LINUX')}
          data-automation-id="AgentOSOptions-Linux"
        >
          <div className="font--h6">LINUX</div>
          <div className="height--150 mt-">
            <img className="height--inherit" src={linuxSrc} alt="" />
          </div>
          <div className="mt-">Recent versions of 64-bit Linux</div>
          <div className="">supported</div>
        </Link>
      </div>
      <div className="grid__item col-1-3 flex">
        <Link
          to={`${urlBase}/windows`}
          className="btn--primary p-- col-1-1 flex flex-direction--column align-items--center color--muted button--image"
          onClick={() => selectOS('WINDOWS')}
          data-automation-id="AgentOSOptions-Windows"
        >
          <div className="font--h6">WINDOWS</div>
          <div className="height--150 mt-">
            <img className="height--inherit" src={windowsSrc} alt="" />
          </div>
          <div className="mt-">Windows 7 and later, PowerShell 3</div>
          <div>and later</div>
        </Link>
      </div>
    </div>
  );
};

export default AgentOSOptions;
