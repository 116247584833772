import React from 'react';
import SourceClearLoader from '~/components/SourceClearLoader';

interface LoaderWrapperProps {
  isLoaderShowing?: boolean;
  className?: string;
  size?: string;
  loaderType?: string;
  customLoader?: object;
}

const LoaderWrapper: React.FunctionComponent<LoaderWrapperProps> = props => {
  const {
    isLoaderShowing,
    className,
    size = 'font--h4',
    loaderType = 'DEFAULT',
    customLoader = null,
  } = props;

  const LOADER_TYPES = {
    DEFAULT: <SourceClearLoader />,
    SPINNER: <i className={`fas fa-spin fa-spinner color--muted-dark ${size}`} />,
  };

  const loaderHtml = customLoader ? customLoader : LOADER_TYPES[loaderType];

  return <div className={className}>{isLoaderShowing ? loaderHtml : props.children}</div>;
};

export default LoaderWrapper;
