export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

export const openSidebar = id => {
  return {
    type: OPEN_SIDEBAR,
    id,
  };
};
export const closeSidebar = id => {
  return {
    type: CLOSE_SIDEBAR,
    id,
  };
};
