import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Helmet from 'react-helmet';
import Sticky from 'react-sticky-el';

import _ from 'lodash';

import ReportHeaderRow from '~/components/ReportComponents/ReportHeaderRow';
import LibraryCatalogRow from '~/containers/LibraryCatalogRow';
import ReportHeaderItem from '~/components/ReportComponents/ReportHeaderItem';
import LoaderWrapper from '~/components/LoaderWrapper';
import ReportActionsWrapper from '~/components/ReportComponents/ReportActionsWrapper';
import ReportStringFilter from '~/containers/ReportStringFilter';
import LibraryCatalogFetchErrorMessage from '~/components/LibraryCatalogFetchErrorMessage';
import DropdownList from '~/components/DropdownList';
import Pagination from '~/components/Pagination';
import useLanguageTypeSelect from '~/hooks/useLanguageTypeSelect';
import * as libraryCatalogActions from '~/actions/libraryCatalog';
import RemoveLibraryModal from '~/containers/RemoveLibraryModal';
import AddNewLibraryModal from '~/containers/AddNewLibraryModal';

import Tooltip from '~/components/Tooltip';
import { LibraryCatalogState } from '~/types/LibraryCatalog';

function getRowId(libCatalogInstance) {
  const { instanceCommit = {} } = libCatalogInstance;
  const { instance = {} } = instanceCommit;
  // Holds library unique look-up value eg. npm:abab::1.0.3:
  return instance.id;
}

function LibraryCatalogPage() {
  const [addNewLibraryModalIsOpen, setAddNewLibraryModalIsOpen] = useState(false);
  const [removeLibraryModalIsOpen, setRemoveLibraryModalIsOpen] = useState(false);
  const { libraryCatalogState, orgState } = useSelector(state => state as { [key: string]: any });
  const { options: languages, open: languageTypeSelectIsOpen, onToggle } = useLanguageTypeSelect();
  const {
    catalog,
    isFetching,
    errorFetching,
    filter,
    selected,
    sort,
  } = libraryCatalogState as LibraryCatalogState;
  const orgId = orgState.org.id;
  const dispatch = useDispatch();
  useEffect(() => {
    // fetch catalog
    return () => {
      // reset filters
    };
  }, [orgId]);

  const delayedRefreshCatalog = () => _.debounce(refreshCatalog, 300);
  function refreshCatalog() {
    dispatch(libraryCatalogActions.fetchCatalog(orgId, filter));
  }
  /* select and remove */
  // TODO: implement remove
  // function removeInstances() {
  //  dispatch(libraryCatalogActions.removeInstances(orgId, selected));
  // }

  function onSelectionChanged(rowId, checked) {
    if (checked) {
      dispatch(libraryCatalogActions.selectInstance(rowId));
    } else {
      dispatch(libraryCatalogActions.deselectInstance(rowId));
    }
  }

  /* pagination, filter, and sort */
  function handlePageClick(page) {
    dispatch(libraryCatalogActions.fetchCatalog(filter, page));
  }

  function updateSearchFilter(_, value) {
    dispatch(libraryCatalogActions.searchCatalog(value));
    delayedRefreshCatalog();
  }
  function updateSortField(field) {
    dispatch(libraryCatalogActions.sortBy(field));
    refreshCatalog();
  }

  function onLanguageTypeSelect() {}

  /* JSX */
  const loadedFirstTime = !!catalog.totalPages;
  const libraryCatalogResults = catalog.content || [];
  // const librarySearchResults = libraries.content || [];
  const columnWidths = {
    libraryName: 'col-4-12',
    ossLicense: '',
    ossLicenseRisk: '',
    dateAdded: '',
    select: 'col-1-12',
  };

  const rows =
    libraryCatalogResults.length === 0 ? (
      <h3 className="ml color--muted mt+" data-automation-id="LibraryCatalogPage-noLibrariesFound">
        {' '}
        No libraries found.{' '}
      </h3>
    ) : (
      libraryCatalogResults.map(instance => {
        const rowId = getRowId(instance);
        return (
          <LibraryCatalogRow
            rowValues={instance}
            key={rowId}
            rowId={rowId}
            onChecked={onSelectionChanged}
            checked={selected.indexOf(rowId) >= 0}
            columnWidths={columnWidths}
          />
        );
      })
    );

  const header = (
    <div className="grid grid--full grid__item col-1-1">
      <Helmet>
        <title>Commercial Licenses</title>
      </Helmet>
      <div className="grid__item col-1-1">
        <h3 data-automation-id="LibraryCatalogPage-Title">Commercial Licenses</h3>
        <div className="mb">
          If your organization has purchased a commercial license for an open source library, add it
          here to block the creation of license issues for that library.
          <a
            href="https://help.veracode.com"
            target="_blank"
            rel="noopener noreferrer"
            className="link--obvious help--link pl--"
          >
            Help
            <i className="fas fa-external-link pl-- " />
          </a>
        </div>
        <Sticky>
          <ReportActionsWrapper>
            <div className="grid__item col-1-4 pl--">
              <DropdownList
                onToggle={() => onToggle(!languageTypeSelectIsOpen)}
                isOpen={languageTypeSelectIsOpen}
                items={languages}
                value={filter.languageType}
                onSelect={onLanguageTypeSelect}
                automationId="LibraryCatalogPage-DropdownListFilter-LanguageType"
              />
            </div>
            <div className="grid__item flex flex--align-items--center flex--justify-content--end p--">
              <div className="pl-">
                <Tooltip content="Add libraries to the catalog" id="add-to-catalog">
                  <button
                    className={'col-1-1 text--right pv- ph btn--success'}
                    data-automation-id="LibraryCatalogPage-AddLibraryButton"
                    onClick={() => setAddNewLibraryModalIsOpen(true)}
                  >
                    Add New Library
                  </button>
                </Tooltip>
              </div>
              <div className="pl-">
                <Tooltip content="Remove libraries from catalog" id="remove-from-catalog">
                  <button
                    className={'col-1-1 text--right pv- ph btn--success-clear'}
                    data-automation-id="LibraryCatalogPage-RemoveLibraryButton"
                    onClick={() => setRemoveLibraryModalIsOpen(true)}
                    disabled={selected.length === 0 ? true : false}
                  >
                    Remove Library
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className="grid__item col-1-1 p-- flex flex--justify-content--space-between bg-color--white-medium">
              <div className="flex">
                <div className="width--240 flex" data-automation-id="LibraryCatalogPage-Search">
                  <ReportStringFilter
                    label="Search libraries"
                    field="text"
                    value={filter.text}
                    onChange={updateSearchFilter}
                  />
                </div>
                <div
                  className="pl- flex align-items--center"
                  data-automation-id="LibraryCatalog-Total"
                >
                  {catalog.totalElements} librar
                  {catalog.totalElements === 1 ? 'y' : 'ies'}
                </div>
              </div>
              <div className="flex">
                <Pagination
                  currentPage={catalog.number}
                  totalPages={catalog.totalPages || 1}
                  handlePageClick={page => handlePageClick(page)}
                />
              </div>
            </div>
          </ReportActionsWrapper>
        </Sticky>
      </div>

      <div className="grid__item col-1-1 mt--">
        <ReportHeaderRow>
          <ReportHeaderItem
            label="Library"
            field="name"
            isSortable={true}
            onClick={updateSortField}
            currentSort={sort}
            widthClass={columnWidths.libraryName}
            automationId="ReportHeaderItem-Library"
          />
          <ReportHeaderItem
            label="OSS License"
            field="license"
            onClick={updateSortField}
            widthClass={columnWidths.ossLicense}
            automationId="ReportHeaderItem-OSSLicense"
          />
          <ReportHeaderItem
            label="OSS License Risk"
            field="ossLicenseRisk"
            widthClass={columnWidths.ossLicenseRisk}
            automationId="ReportHeaderItem-OSSLicenseRisk"
          />
          <ReportHeaderItem
            label="Date Added"
            field="dateAdded"
            isSortable={true}
            widthClass={columnWidths.dateAdded}
            automationId="ReportHeaderItem-dateAdded"
          />
          <ReportHeaderItem
            label="Select"
            field="select"
            widthClass={columnWidths.select}
            data-automation-id="ReportHeaderItem-Select"
          />
        </ReportHeaderRow>
      </div>
    </div>
  );

  if (errorFetching) {
    return (
      <div className="grid grid--center pt">
        {header}
        <div
          className="grid__item col-3-5 mt"
          data-automation-id="LibraryCatalogPage-LibraryCatalogFetchErrorMessage"
        >
          <LibraryCatalogFetchErrorMessage />
        </div>
      </div>
    );
  }

  return (
    <div className="grid pt">
      {header}
      <div className="grid__item col-1-1">
        <LoaderWrapper isLoaderShowing={isFetching && !loadedFirstTime}>{rows}</LoaderWrapper>
      </div>
      <AddNewLibraryModal
        isOpen={addNewLibraryModalIsOpen}
        onClose={() => setAddNewLibraryModalIsOpen(false)}
      />
      <RemoveLibraryModal
        isOpen={removeLibraryModalIsOpen}
        onClose={() => setRemoveLibraryModalIsOpen(false)}
        selected={selected}
      />
    </div>
  );
}
export default LibraryCatalogPage;
