import produce from 'immer';
import * as actions from '~/actions/userStatus';

const defaultState = {
  roles: [],
  userStatusHasResolved: false,
  fetchUserStatusFailed: false,
};

const userStatus = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.FETCH_USER_STATUS_SUCCESS:
        draft.roles = action.roles;
        draft.userStatusHasResolved = true;
        break;

      case actions.FETCH_USER_STATUS_FAILURE:
        draft.fetchUserStatusFailed = true;
        break;

      case actions.RESET_USER_STATUS:
        return defaultState;
    }
  });
};

export default userStatus;
