import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';

export const FILTER_USER_GROUPS = 'FILTER_USER_GROUPS';
export const FILTER_USER_WORKSPACES = 'FILTER_USER_WORKSPACES';
export const FILTER_USERS_BY_INPUT = 'FILTER_USERS_BY_INPUT';
export const FILTER_PENDING_INVITES_BY_INPUT = 'FILTER_PENDING_INVITES_BY_INPUT';
export const RESET_SELECTED_PENDING_INVITES = 'RESET_SELECTED_PENDING_INVITES';
export const RESET_SELECTED_USER_GROUPS = 'RESET_SELECTED_USER_GROUPS';
export const RESET_SELECTED_USERS = 'RESET_SELECTED_USERS';
export const RESET_USER_DETAILS = 'RESET_USER_DETAILS';
export const RESET_USER_QUICK_VIEWS = 'RESET_USER_QUICK_VIEWS';
export const RESET_USER_WORKSPACES_QUICK_VIEWS = 'RESET_USER_WORKSPACES_QUICK_VIEWS';
export const RESET_USER_SORT = 'RESET_USER_SORT';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_USER_WORKSPACES_DETAILS = 'SET_USER_WORKSPACES_DETAILS';
export const SHOW_USER_MODAL = 'SHOW_USER_MODAL';
export const TOGGLE_CREATE_NEW_GROUP = 'TOGGLE_CREATE_NEW_GROUP';
export const TOGGLE_INVITE_QUICK_VIEW = 'TOGGLE_INVITE_QUICK_VIEW';
export const TOGGLE_SELECTED_INVITE_ADMIN_FIELD = 'TOGGLE_SELECTED_INVITE_ADMIN_FIELD';
export const TOGGLE_SELECTED_USER_ADMIN_FIELD = 'TOGGLE_SELECTED_USER_ADMIN_FIELD';
export const TOGGLE_USER_GROUP_ADMIN = 'TOGGLE_USER_GROUP_ADMIN';
export const TOGGLE_USER_QUICK_VIEW = 'TOGGLE_USER_QUICK_VIEW';
export const TOGGLE_USER_WORKSPACES_QUICK_VIEW = 'TOGGLE_USER_WORKSPACES_QUICK_VIEW';
export const UPDATE_IS_FETCHING_ORG_GROUPS = 'UPDATE_IS_FETCHING_ORG_GROUPS';
export const UPDATE_ORG_USER_SORT = 'UPDATE_ORG_USER_SORT';
export const UPDATE_ORG_USER_GROUPS_SORT = 'UPDATE_ORG_USER_GROUPS_SORT';
export const UPDATE_SELECTED_USER_GROUPS = 'UPDATE_SELECTED_USER_GROUPS';
export const UPDATE_SELECTED_USERS = 'UPDATE_SELECTED_USERS';
export const UPDATE_SELECTED_PENDING_INVITES = 'UPDATE_SELECTED_PENDING_INVITES';
export const UPDATE_ORG_USERS = 'UPDATE_ORG_USERS';
export const UPDATE_PENDING_INVITES = 'UPDATE_PENDING_INVITES';
export const SELECT_ALL_PENDING_INVITES = 'SELECT_ALL_PENDING_INVITES';
export const UPDATE_ORG_USERS_ACTIVE_TAB = 'UPDATE_ORG_USERS_ACTIVE_TAB';
export const UPDATE_IS_FETCHING_ORG_USER_WORKSPACES = 'UPDATE_IS_FETCHING_ORG_USER_WORKSPACES';

export const updateActiveTab = tab => ({
  type: UPDATE_ORG_USERS_ACTIVE_TAB,
  tab,
});

export const fetchOrgUsers = (userId, withGroups) => (dispatch, getState) => {
  const {
    orgState,
    vcPageState: { shouldShowVeracodePage },
  } = getState();
  // '/users' endpoint is standalone specific, do not make request as a VC user
  if (!shouldShowVeracodePage) {
    const { org = {} } = orgState;
    ApiService.get(ApiConstants.fetchOrgUsersURL(org, withGroups))
      .then(
        res => {
          dispatch(updateOrgUsers(res));

          if (userId) {
            const activeUser = res.find(user => user.user.id === parseInt(userId));

            dispatch(fetchUserGroups(activeUser));
          }
        },
        () => {
          dispatch(updateOrgUsers([]));
        }
      )
      .catch(err => {
        ErrorService.capture('Error fetching org users', err);
      });
  }
};

export const fetchPendingInvites = () => (dispatch, getState) => {
  const { orgState } = getState();
  const { org = {} } = orgState;

  ApiService.get(ApiConstants.pendingInvitesURL(org))
    .then(
      res => {
        dispatch(updatePendingInvites(res));
      },
      () => {
        dispatch(updatePendingInvites([]));
      }
    )
    .catch(err => {
      ErrorService.capture('Error fetching org pending invites', err);
    });
};

export const revokeInvitations = inviteIds => dispatch => {
  return ApiService.post(`/invites:revoke`, { data: inviteIds })
    .then(() => {
      dispatch(resetSelectedPendingInvites());
      dispatch(fetchPendingInvites());
      return { success: true };
    })
    .catch(error => {
      ErrorService.capture('Error revoking invitations', error);
      return {};
    });
};

export const resendInvitations = inviteIds => dispatch => {
  return ApiService.post(`/invites:resend`, { data: inviteIds })
    .then(() => {
      dispatch(resetSelectedPendingInvites());
      dispatch(fetchPendingInvites());
      return { success: true };
    })
    .catch(error => {
      ErrorService.capture('Error resending invitations', error);
      return {};
    });
};

export const fetchUserGroups = user => dispatch => {
  dispatch(updateIsFetchingOrgGroups(true));
  const { numGroups, _links = {} } = user;
  const { groups = {} } = _links;
  const { href } = groups;

  if (!numGroups) {
    dispatch(setUserDetails({ groups: [] }));
  } else {
    ApiService.get(href).then(res => {
      dispatch(setUserDetails({ groups: res }));
    });
  }
};

export const fetchUserWorkspaces = userId => (dispatch, getState) => {
  dispatch(updateIsFetchingOrgUserWorkspaces(true));
  const { orgState } = getState();
  const { org = {} } = orgState;

  const endpoint = `/orgs/${org.id}/users/${userId}/teams`;

  ApiService.get(endpoint).then(res => {
    dispatch(setUserWorkspacesDetails({ workspaces: res }));
  });
};

export const updateIsFetchingOrgGroups = bool => ({
  type: UPDATE_IS_FETCHING_ORG_GROUPS,
  bool,
});

export const updateIsFetchingOrgUserWorkspaces = bool => ({
  type: UPDATE_IS_FETCHING_ORG_USER_WORKSPACES,
  bool,
});

export const updateSelectedUsers = userId => ({
  type: UPDATE_SELECTED_USERS,
  userId,
});

export const updateSelectedPendingInvites = inviteId => ({
  type: UPDATE_SELECTED_PENDING_INVITES,
  inviteId,
});

export const resetSelectedUsers = () => ({
  type: RESET_SELECTED_USERS,
});

export const resetSelectedPendingInvites = () => ({
  type: RESET_SELECTED_PENDING_INVITES,
});

export const toggleSelectedUserAdminField = userId => ({
  type: TOGGLE_SELECTED_USER_ADMIN_FIELD,
  userId,
});

export const toggleSelectedInviteAdminField = email => ({
  type: TOGGLE_SELECTED_INVITE_ADMIN_FIELD,
  email,
});

export const updateOrgUsers = users => ({
  type: UPDATE_ORG_USERS,
  users,
});

export const updatePendingInvites = invites => ({
  type: UPDATE_PENDING_INVITES,
  invites,
});

export const showModal = modalType => ({
  type: SHOW_USER_MODAL,
  modalType,
});

export const filterUsersByInput = value => ({
  type: FILTER_USERS_BY_INPUT,
  value,
});

export const filterPendingInvitesByInput = value => ({
  type: FILTER_PENDING_INVITES_BY_INPUT,
  value,
});

export const toggleUserGroupAdmin = userId => ({
  type: TOGGLE_USER_GROUP_ADMIN,
  userId,
});

export const toggleCreateNewGroup = () => ({
  type: TOGGLE_CREATE_NEW_GROUP,
});

export const updateSelectedUserGroups = groupId => ({
  type: UPDATE_SELECTED_USER_GROUPS,
  groupId,
});

export const resetSelectedUserGroups = () => ({
  type: RESET_SELECTED_USER_GROUPS,
});

export const setUserDetails = ({ groups }) => ({
  type: SET_USER_DETAILS,
  groups,
});

export const setUserWorkspacesDetails = ({ workspaces }) => ({
  type: SET_USER_WORKSPACES_DETAILS,
  workspaces,
});

export const resetUserDetails = () => ({
  type: RESET_USER_DETAILS,
});

export const filterUserGroups = value => ({
  type: FILTER_USER_GROUPS,
  value,
});

export const filterUserWorkspaces = value => ({
  type: FILTER_USER_WORKSPACES,
  value,
});

export const updateSort = column => ({
  type: UPDATE_ORG_USER_SORT,
  column,
});

export const updateUserGroupSort = column => ({
  type: UPDATE_ORG_USER_GROUPS_SORT,
  column,
});

export const resetUserSort = () => ({
  type: RESET_USER_SORT,
});

export const toggleUserWorkspacesQuickView = (workspaceId, column) => ({
  type: TOGGLE_USER_WORKSPACES_QUICK_VIEW,
  workspaceId,
  column,
});

export const toggleUserQuickView = (userId, column) => ({
  type: TOGGLE_USER_QUICK_VIEW,
  userId,
  column,
});

export const toggleInviteQuickView = (inviteId, column) => ({
  type: TOGGLE_INVITE_QUICK_VIEW,
  inviteId,
  column,
});

export const resetUserQuickViews = () => ({
  type: RESET_USER_QUICK_VIEWS,
});

export const resetUserWorkspacesQuickViews = () => ({
  type: RESET_USER_WORKSPACES_QUICK_VIEWS,
});

export const selectAllPendingInvites = selectAll => ({
  type: SELECT_ALL_PENDING_INVITES,
  selectAll,
});
