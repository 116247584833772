import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';
import CopyToClipboard from '~/containers/CopyToClipboard';

interface PackageManagerPackagistProps {
  component: object;
  range?: object;
  safeVersion: string;
  transitive: boolean;
  unsafeVersion?: string;
  isBoth?: boolean;
}

const PackageManagerPackagist: React.FunctionComponent<PackageManagerPackagistProps> = props => {
  const { component, range = {}, safeVersion, transitive = false } = props;
  const { coordinate1 } = component;
  const unsafeVersion =
    props.unsafeVersion ||
    (range.versionRange && range.versionRange.indexOf('-') !== -1
      ? range.versionRange.split('-')[0]
      : '');
  const packageFile = 'composer.json';

  let diffBlock = null;

  if (!transitive) {
    // Direct
    diffBlock = (
      <div className="grid bo--1 border-color--white-dark mt bg-color--white lh+">
        <CodeDiffHeader text={packageFile} />
        <CodeDiffLine type="normal" text={'\u0022require\u0022: {'} />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine
          type="delete"
          text={'\u2003\u0022' + coordinate1 + '\u0022: \u0022' + unsafeVersion + '\u0022'}
        />
        <CodeDiffLine
          type="add"
          text={'\u2003\u0022' + coordinate1 + '\u0022: \u0022' + safeVersion + '\u0022'}
        />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine type="normal" text="}" />
      </div>
    );
  } else {
    diffBlock = (
      <div className="grid bo--1 border-color--white-dark mt bg-color--white lh+">
        <CodeDiffHeader text={packageFile} />
        <CodeDiffLine type="normal" text={'\u0022require\u0022: {'} />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine
          type="add"
          text={
            '\u2003\u0022' +
            coordinate1 +
            '\u0022: \u0022' +
            safeVersion +
            ' as ' +
            unsafeVersion +
            '\u0022'
          }
        />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine type="normal" text="}" />
      </div>
    );
  }

  return (
    <span>
      <div className="grid pt">
        <span className="grid__item max-width--30 bg-color--white-dark pv- ph- text--bold">1.</span>
        <span className="grid__item pl--">
          Update your <span className="text--bold inline-block ph-- pv-">composer.json</span> as
          shown below:
        </span>
      </div>
      {diffBlock}
      <div className="grid pt">
        <span className="bg-color--white-dark pv- ph- text--bold">2.</span>
        <span className="pv- pl--">Execute following to update your dependency.</span>
      </div>
      <div className="pt-- -ml mt-">
        <CopyToClipboard
          value={`php composer.phar update ${coordinate1}`}
          id="PackageManagerGlide1"
        />
      </div>
    </span>
  );
};

export default PackageManagerPackagist;
