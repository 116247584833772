import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import * as toastrActions from '~/actions/toastr';
import * as myActions from '~/actions/me';
import SourceClearLoader from '~/components/SourceClearLoader';

interface EmailVerifyPageProps extends RouteComponentProps {
  toastrActions: object;
  myActions: object;
  myState: object;
}
class EmailVerifyPage extends React.Component<EmailVerifyPageProps, {}> {
  componentDidMount() {
    const { location, myState, history } = this.props;
    const { validatingEmail, me = {} } = myState;
    const { search, pathname } = location;
    const query = queryString.parse(search) || {};
    const { token } = query;

    if (token && !validatingEmail) {
      if (pathname.includes('/billing')) {
        this.props.myActions.validateBillingEmail(token).then(res => {
          if (res.success) {
            history.push(me.id ? '/' : '/billing-confirm');
          }
        });
      } else {
        this.props.myActions.validateEmail(token).then(res => {
          if (res.success) {
            history.push('/');
          }
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.myActions.validationEmailStatus(undefined);
  }

  resendEmailValidationLink() {
    this.props.myActions.resendEmailValidationLink();
  }

  render() {
    const { myState } = this.props;
    const { me = {}, emailValidationError = {}, validationEmailStatus } = myState;
    const { emailVerified, email } = me;

    if (emailValidationError.status) {
      return (
        <div className="mt++ grid grid--center">
          <div className="grid__item col-3-5">
            <div className="panel panel--danger mt">
              <div className="panel__heading font--h6">Something went wrong</div>
              <div className="panel__body">
                <p>
                  Email validation links last 24 hours.{' '}
                  <span className="link--obvious" onClick={() => this.resendEmailValidationLink()}>
                    Click here
                  </span>{' '}
                  to resend a new link to {email}. Not the right email? You can update your email in
                  your{' '}
                  <Link to="/settings/profile" className="link--obvious">
                    personal settings
                  </Link>
                  . If you need additional help please{' '}
                  <a
                    href="mailto:support@veracode.com?subject=Request For SourceClear Support"
                    className="link--obvious"
                  >
                    contact support
                  </a>
                  .
                </p>
                <div
                  className={`color--success ${
                    validationEmailStatus === 'SUCCESS' ? 'is-showing-50' : 'is-hiding'
                  }`}
                >
                  <i className="sci sci__check pr--" /> Email sent
                </div>
                <div
                  className={`color--danger ${
                    validationEmailStatus === 'FAILURE' ? 'is-showing-50' : 'is-hiding'
                  }`}
                >
                  <i className="sci sci__alerts pr--" /> Error sending email, try again later.
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <div className="mt++">{!emailVerified && <SourceClearLoader />}</div>;
  }
}

function mapStateToProps(state) {
  return {
    myState: state.myState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toastrActions: bindActionCreators(toastrActions, dispatch),
    myActions: bindActionCreators(myActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerifyPage);
