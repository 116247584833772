import React from 'react';

interface ReportExpandedScanErrorViewProps {
  data: object;
  onPageClick: (...args: any[]) => any;
}

const ReportExpandedScanErrorView: React.FunctionComponent<
  ReportExpandedScanErrorViewProps
> = props => {
  const { message, errorLog } = props.data;
  let errorLogElement = null;

  if (errorLog) {
    let logLines = errorLog.split('\n'),
      lineElements = [];

    logLines.forEach(line => {
      lineElements.push(<div className="font--12">{line}</div>);
    });

    errorLogElement = (
      <div className="grid__item col-1-1 font-family--code pt--">
        <div className="border-color--muted-light bo--1 p- word-wrap--break-word">
          {lineElements}
        </div>
      </div>
    );
  }

  return (
    <div className="bo-t--1 border-color--muted-light pt pb- pl-">
      <div className="grid pb-">
        <div className="grid__item text--bold">Scan Error</div>
        <div className="grid__item col-1-1 color--danger">{message}</div>
        {errorLogElement}
      </div>
    </div>
  );
};

export default ReportExpandedScanErrorView;
