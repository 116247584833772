import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';
export const UPDATE_INVOICE_DATA = 'UPDATE_INVOICE_DATA';

export const updateInvoiceData = invoice => ({
  type: UPDATE_INVOICE_DATA,
  invoice,
});

export const fetchInvoice = (orgId, invoiceId) => dispatch => {
  ApiService.get(`${ApiConstants.fetchInvoiceURL(orgId, invoiceId)}`)
    .then(res => {
      dispatch(updateInvoiceData(res));
    })
    .catch(error => {
      dispatch(updateInvoiceData({}));
      ErrorService.capture('Error fetching invoice', error);
    });
};
