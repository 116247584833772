import randomColor from 'randomcolor';

const ColorHelper = function () {
  // Given a string return a color
  const getBrightColor = str => {
    //console.log(randomColor);
    return randomColor({
      seed: str,
      luminosity: 'bright',
    });
  };

  return {
    getBrightColor,
  };
};

export default new ColorHelper();
