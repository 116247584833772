import React from 'react';

import 'velocity-animate';
import 'velocity-animate/velocity.ui';
import Tooltip from '~/components/Tooltip';

interface ReportUnmatchedLibrariesRowProps {
  columnWidths: {
    [key: string]: string;
  };
  rowValues: object;
  rowId: string;
}
const ReportUnmatchedLibrariesRow: React.FunctionComponent<ReportUnmatchedLibrariesRowProps> = props => {
  const { rowValues, columnWidths, rowId } = props;
  const { coord1, coord2, libraryVersion, filePath, filename } = rowValues;

  const visibleLength = {
    coord1: 23,
    coord2: 18,
    libraryVersion: 18,
    filename: 23,
    filePath: 30,
  };

  const renderCoord = (coord: string, visibleLength: number) => {
    if (!coord) {
      return '--';
    } else if (coord.length < visibleLength) {
      return coord;
    } else {
      return (
        <Tooltip
          fullWidth={true}
          place="top"
          id={`${rowId}-${coord}`}
          content={coord}
          className="width--300 break-word"
        >
          {`${coord.substr(0, visibleLength)}...`}
        </Tooltip>
      );
    }
  };

  const renderLibraryVersion = (libraryVersion, visibleLength) => {
    const {
      rowValues: { commitHash, coordinateType },
    } = props;
    // When tag not used on a Go repo, we append the commitHash
    // to distinguish multiple occurences of same library
    if (libraryVersion && commitHash && coordinateType === 'GO') {
      libraryVersion = `${libraryVersion}-${commitHash}`;
    }
    if (!libraryVersion) {
      return '--';
    } else if (libraryVersion.length < visibleLength) {
      return libraryVersion;
    } else {
      return (
        <Tooltip
          fullWidth={true}
          place="top"
          id={`${rowId}-libraryVersion`}
          content={libraryVersion}
          className="width--300 break-word"
        >
          {`${libraryVersion.substr(0, visibleLength)}...`}
        </Tooltip>
      );
    }
  };

  const renderFilename = (filename, visibleLength) => {
    if (!filename) {
      return '--';
    } else if (filename.length < visibleLength) {
      return filename;
    } else {
      return (
        <Tooltip
          fullWidth={true}
          place="top"
          id={`${rowId}-filename`}
          content={filename}
          className="width--300 break-word"
        >
          {`${filename.substr(0, visibleLength)}...`}
        </Tooltip>
      );
    }
  };

  const renderFilePath = (filePath, visibleLength) => {
    if (!filePath) {
      return '--';
    } else if (filePath.length < visibleLength) {
      return filePath;
    } else {
      return (
        <Tooltip
          fullWidth={true}
          place="top"
          id={`${rowId}-filePath`}
          content={filePath}
          className="width--300 break-word"
        >
          {`...${filePath.substr(filePath.length - visibleLength)}`}
        </Tooltip>
      );
    }
  };

  return (
    <div className="bo--1 border-color--white-dark pl- pv-- mv---">
      <div className="grid grid--narrower grid--middle min-height--25 flex--align-items--stretch">
        <div
          className={`grid__item flex flex--align-items--center ${columnWidths.coord1}`}
          data-automation-id="RowCell"
        >
          {renderCoord(coord1, visibleLength.coord1)}
        </div>

        <div
          className={`grid__item flex flex--align-items--center ${columnWidths.coord2}`}
          data-automation-id="RowCell"
        >
          {renderCoord(coord2, visibleLength.coord2)}
        </div>

        <div
          className={`grid__item flex flex--align-items--center ${columnWidths.libraryVersion}`}
          data-automation-id="RowCell"
        >
          {renderLibraryVersion(libraryVersion, visibleLength.libraryVersion)}
        </div>

        <div
          className={`grid__item flex flex--align-items--center ${columnWidths.filename}`}
          data-automation-id="RowCell"
        >
          {renderFilename(filename, visibleLength.filename)}
        </div>

        <div
          className={`grid__item flex flex--align-items--center ${columnWidths.filePath}`}
          data-automation-id="RowCell"
        >
          {renderFilePath(filePath, visibleLength.filePath)}
        </div>
      </div>
    </div>
  );
};

export default ReportUnmatchedLibrariesRow;
