import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as teamActions from '~/actions/team';
import * as orgTeamActions from '~/actions/orgTeam';
import { errorMessageTextMap } from '~/constants/ModelConstants';

interface RenameWorkspaceModalProps {
  teamId: string;
  orgTeamActions: object;
  teamActions: object;
  activeTeam: object;
  teamState: object;
  showModal: (...args: any[]) => any;
  validWorkspaceName?: boolean;
}
class RenameWorkspaceModal extends React.Component<RenameWorkspaceModalProps, {}> {
  componentDidMount() {
    const { activeTeam } = this.props;
    const { name = '' } = activeTeam;

    this.props.teamActions.updateWorkspaceName(name);
  }

  saveTeamName(e) {
    e.preventDefault();
    const { teamState, teamId } = this.props;
    const { nameWillBe = '' } = teamState;

    this.props.teamActions.saveTeamName(teamId, nameWillBe).then(res => {
      if (res.success) {
        this.props.orgTeamActions.fetchOrgTeams();
        this.showModal();
      }
    });
  }

  updateWorkspaceName(e) {
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.teamActions.updateWorkspaceName(value);
  }

  showModal(modalType) {
    this.props.showModal(modalType);
  }

  render() {
    const { teamState } = this.props;
    const { nameWillBe = '', validWorkspaceName = true } = teamState;
    const workspaceNameError = !validWorkspaceName ? errorMessageTextMap.workspaceNameError : '';
    const shouldDisableButton =
      nameWillBe.length < 3 || nameWillBe.length > 20 || !!workspaceNameError;

    return (
      <form className="grid mt" onSubmit={e => this.saveTeamName(e)}>
        <div className="grid__item col-1-3 flex align-items--center">Workspace Name</div>
        <div className="grid__item col-2-3 flex align-items--center">
          <input
            type="text"
            className="control--text col-1-1"
            placeholder="Workspace Name (required)"
            autoFocus
            value={nameWillBe}
            onChange={e => this.updateWorkspaceName(e)}
          />
        </div>
        <div className={`grid__item col-1-1 height--50 mb-`}>
          <div className={`${workspaceNameError ? 'is-showing-50' : 'is-hiding'}`}>
            <div className="mt- color--danger">{workspaceNameError}</div>
          </div>
        </div>
        <div className="grid__item mt flex justify-content--end">
          <div className="col-1-5">
            <button className="col-1-1 pv-" type="button" onClick={() => this.showModal()}>
              Cancel
            </button>
          </div>
          <div className="col-1-5 ml-">
            <button
              className="btn--primary-clear col-1-1 pv-"
              type="submit"
              disabled={shouldDisableButton}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orgTeamActions: bindActionCreators(orgTeamActions, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RenameWorkspaceModal);
