import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';
import _ from 'lodash';

import ReportHeaderRow from '~/components/ReportComponents/ReportHeaderRow';
import ReportHeaderItem from '~/components/ReportComponents/ReportHeaderItem';
import ReportHeader from '~/components/ReportComponents/ReportHeader';
import LicensesRow from '~/containers/LicensesRow';

import ReportBooleanFilter from '~/components/ReportComponents/ReportBooleanFilter';
import ReportFetchErrorMessage from '~/components/ReportFetchErrorMessage';
import CSVReportDownloader from '~/containers/CSVReportDownloader';
import LoaderWrapper from '~/components/LoaderWrapper';
import LicenseRiskOptionsFilter from '~/containers/LicenseRiskOptionsFilter';

import * as navigationActions from '~/actions/navigation';
import * as reportActions from '~/actions/reports';
import * as reportFilterActions from '~/actions/reportFilters';
import * as sortByReportTypeActions from '~/actions/sortByReportType';
import * as licenseActions from '~/actions/license';

import Tooltip from '~/components/Tooltip';

import { RouteComponentProps } from 'react-router-dom';

const REPORT_TYPE = 'LICENSES';

interface LicensesPageProps extends RouteComponentProps {
  licenseActions: object;
  navigationActions: object;
  orgState: App.OrgState;
  repoDataById: object;
  reportActions: object;
  reportFilterActions: object;
  reportFilterState: object;
  reportsByType: object;
  reports: object;
  reportScope: object;
  sortByReportTypeActions: object;
  sortByReportType: object;
}

class LicensesPage extends React.Component<LicensesPageProps, {}> {
  componentDidMount() {
    const { match } = this.props;
    const { params = {} } = match;
    const { projectId } = params;
    this.refreshReportData();

    if (projectId) {
      this.props.reportFilterActions.initProjectFilterDefaults(projectId);
    }

    this.props.navigationActions.updateActiveReportType(REPORT_TYPE);
  }

  componentWillUnmount() {
    this.props.navigationActions.updateActiveReportType();
  }

  updateSortField(reportType, field) {
    const { match } = this.props;
    const { params = {} } = match;
    const { projectId } = params;

    this.props.sortByReportTypeActions.updateSortField(reportType, field, projectId);
    this.refreshReportData();
  }

  refreshReportData() {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    this.props.reportActions.fetchReport(teamId, REPORT_TYPE);
  }

  toggleBooleanFilter(field, isChecked) {
    const { match } = this.props;
    const { params = {} } = match;
    const { projectId } = params;
    this.props.reportFilterActions.updateFilterValue(REPORT_TYPE, field, isChecked, projectId);
    this.refreshReportData();
  }

  render() {
    const {
      reportsByType,
      reports,
      reportFilterState,
      reportScope,
      match,
      sortByReportType,
    } = this.props;
    const { params = {} } = match;
    const { projectId, teamId } = params;
    const { repos: currentRepoListScope } = reportScope;
    const isSingleRepoScope = _.isArray(currentRepoListScope) && currentRepoListScope.length == 1;
    const { isFetching, errorMessage } = reports;
    const licenseFilters =
      projectId && reportFilterState[projectId]
        ? reportFilterState[projectId][REPORT_TYPE] || {}
        : reportFilterState[REPORT_TYPE] || {};
    const { directOnly = false, licenseRisks = [] } = licenseFilters;
    const { [REPORT_TYPE]: reportData = {} } = reportsByType;
    const { _embedded = {}, page = {} } = reportData;
    const { licenses = [] } = _embedded;
    const currentSort =
      projectId && sortByReportType[projectId]
        ? sortByReportType[projectId][REPORT_TYPE] || {}
        : sortByReportType[REPORT_TYPE] || {};

    const licenseRows = licenses.map((license, index) => {
      const rowId = `${index}-${license.name.replace(/ /g, '-')}`;
      return (
        <LicensesRow
          data={license}
          key={rowId}
          rowId={rowId}
          isSingleRepoScope={isSingleRepoScope}
        />
      );
    });
    const reportHeader = (
      <ReportHeader
        match={match}
        reportType={REPORT_TYPE}
        stringFilterPlaceholder={'Search licenses'}
        renderReportHeaderItems={() => (
          <div className="grid__item col-1-1 p-- flex flex--justify-content--space-between">
            <div className="flex">
              <div className="width--200">
                <LicenseRiskOptionsFilter
                  licenseRisks={licenseRisks}
                  projectId={projectId}
                  teamId={teamId}
                  reportType={REPORT_TYPE}
                />
              </div>

              <div className="flex align-items--center pl-">
                <Tooltip
                  content="Only show licenses from direct dependencies"
                  id="direct-only-filter"
                >
                  <ReportBooleanFilter
                    automationId="ReportBooleanFilter--directOnly"
                    label="Direct only"
                    field="directOnly"
                    isChecked={directOnly}
                    onClick={(field, isChecked) => this.toggleBooleanFilter(field, isChecked)}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="flex align-items--center">
              <CSVReportDownloader
                reportType={REPORT_TYPE}
                page={page}
                projectId={projectId}
                noElementText="No license to download"
              />
            </div>
          </div>
        )}
        renderSearchResultsMetadata={() => (
          <span>
            {page.totalElements} license
            {page.totalElements === 1 ? '' : 's'}{' '}
          </span>
        )}
        renderReportHeaderRow={() => (
          <ReportHeaderRow>
            <ReportHeaderItem
              label="Name"
              field="name"
              widthClass="col-3-5"
              isSortable={true}
              onClick={field => this.updateSortField(REPORT_TYPE, field)}
              currentSort={currentSort}
            />
            <ReportHeaderItem
              label="License Risk"
              field="risk"
              widthClass="col-1-12"
              isSortable={true}
              onClick={field => this.updateSortField(REPORT_TYPE, field)}
              currentSort={currentSort}
              tooltip="The license information Veracode provides is not intended to be legal advice. Please consult your legal advisor for the specific details of the license, your use case, and associated risks and obligations."
            />
          </ReportHeaderRow>
        )}
      />
    );

    if (errorMessage) {
      return (
        <div className="grid pt grid--center">
          {reportHeader}
          <div className="grid__item col-3-5 mt">
            <ReportFetchErrorMessage />
          </div>
        </div>
      );
    }

    return (
      <div className="grid">
        {projectId ? (
          <Helmet>
            <title>Project Licenses</title>
          </Helmet>
        ) : (
          <Helmet>
            <title>Licenses</title>
          </Helmet>
        )}
        {!projectId && (
          <div className="grid__item col-1-1">
            <div className="font--h6 mb--" data-automation-id="LicensesPage-Title">
              LICENSE LIST
            </div>
          </div>
        )}
        {reportHeader}
        <div className="grid__item col-1-1">
          <LoaderWrapper isLoaderShowing={isFetching && !page.totalPages}>
            <div data-automation-id="ReportRows">{licenseRows}</div>
            {licenses.length === 0 && <h3 className="ml color--muted mt+">No licenses found.</h3>}
          </LoaderWrapper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    repoDataById: state.repoDataById,
    reportsByType: state.reportsByType,
    reportFilterState: state.reportFilterState,
    reports: state.reports,
    reportScope: state.reportScope,
    orgState: state.orgState,
    sortByReportType: state.sortByReportType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigationActions: bindActionCreators(navigationActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch),
    reportFilterActions: bindActionCreators(reportFilterActions, dispatch),
    sortByReportTypeActions: bindActionCreators(sortByReportTypeActions, dispatch),
    licenseActions: bindActionCreators(licenseActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LicensesPage);
