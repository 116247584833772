import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import * as navigationActions from '~/actions/navigation';
import * as teamActions from '~/actions/team';
import * as reportSelectedRowsActions from '~/actions/reportSelectedRows';
import { FEATURE_SLUG_MAP } from '~/constants/ModelConstants';

import OrgPaidStatus from '~/utils/OrgPaidStatus';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';

import SourceClearModal from '~/components/SourceClearModal';
import Tooltip from '~/components/Tooltip';
import CreateWorkspaceModal from '~/containers/CreateWorkspaceModal';

interface WorkspaceSwitcherProps {
  navigationActions: object;
  teamActions: object;
  expandedWorkspace: object;
  workspaces: any[];
  navigationState: object;
  orgState: App.OrgState;
  teamState: object;
  workspaceId: string;
  reportSelectedRowsActions: {
    clearSelectedRows: () => void;
  };
}
class WorkspaceSwitcher extends Component<WorkspaceSwitcherProps, {}> {
  componentDidMount() {
    document.addEventListener('click', this.handleDropdownClicks);
  }
  componentDidUpdate(prevProps) {
    const { workspaceId: prevWorkspaceId } = prevProps;
    // clear selected issues when workspace changes
    if (prevWorkspaceId !== this.props.workspaceId) {
      this.props.reportSelectedRowsActions.clearSelectedRows();
    }
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleDropdownClicks);
  }

  handleDropdownClicks = e => {
    const { navigationState } = this.props;
    const { isWorkspaceDropdownExpanded } = navigationState;

    if (isWorkspaceDropdownExpanded && !e.target.closest('#workspace-dropdown')) {
      this.closeWorkspaceDropdown();
    }
  };

  toggleWorkspaceDropdown = () => {
    const { navigationState } = this.props;
    const { isWorkspaceDropdownExpanded } = navigationState;

    if (isWorkspaceDropdownExpanded) {
      this.closeWorkspaceDropdown();
    } else {
      this.openWorkspaceDropdown();
    }
  };

  openWorkspaceDropdown = () => {
    this.props.navigationActions.openWorkspaceDropdown();
  };

  closeWorkspaceDropdown = () => {
    this.props.navigationActions.closeWorkspaceDropdown();
  };

  showModal(modalType) {
    this.props.teamActions.showModal(modalType);
  }

  render() {
    const { navigationState, workspaces, orgState, expandedWorkspace = {}, teamState } = this.props;
    const { showModal } = teamState;
    const { org } = orgState;
    const { license, permissions } = org;
    const { createTeams } = permissions;
    const plan = org.plan || {};
    const { usage } = license;
    const { teams } = usage;
    const { id, teamLimit } = plan;
    const { isWorkspaceDropdownExpanded } = navigationState;
    const showGroups =
      FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org) ||
      OrgPaidStatus.isOrgEnterpriseOrTrial(org);
    const orgCannotCreateWorkspaces = id === 1001;
    const orgHasReachedLimit = teamLimit && teamLimit <= teams;
    const showCreateWorkspace = createTeams && !orgCannotCreateWorkspaces;

    return (
      <div
        className="bo--1 border-color--muted-light p-- mt- col-23-24"
        id="workspace-dropdown"
        onClick={this.toggleWorkspaceDropdown}
      >
        <div className="col-1-1 flex justify-content--space-between align-items--center mb--">
          <div className="flex">
            <div className="color--muted-dark">Workspaces</div>
            {teamLimit && !orgCannotCreateWorkspaces && (
              <div className="ml-">
                ({teams} of {teamLimit})
              </div>
            )}
          </div>
          {showCreateWorkspace && (
            <button
              data-e2e="create-new-team-btn"
              className={`btn--primary-clear bo--0 cursor--pointer flex justify-content--center align-items--center ph-- pv-- ${
                orgHasReachedLimit && 'disabled'
              }`}
              disabled={orgHasReachedLimit}
              onClick={orgHasReachedLimit ? undefined : () => this.showModal('CREATE_WORKSPACE')}
            >
              {orgHasReachedLimit ? (
                <Tooltip
                  id="workspace-limit"
                  content="You have reached the maximum number of workspaces for your organization. To create another workspace, delete an existing one or contact sales@sourceclear.com."
                  maxWidthClass="max-width--300"
                  place="right"
                >
                  <i className="sci sci__circle--add" />
                </Tooltip>
              ) : (
                <i className="sci sci__circle--add" />
              )}
            </button>
          )}
        </div>
        <div className="position--relative col-1-1">
          <div
            className="flex cursor--pointer justify-content--space-between bg-color--muted bg-color--hover-muted-dark"
            onClick={() => this.toggleWorkspaceDropdown()}
          >
            <div
              title={expandedWorkspace.name}
              className="flex flex-grow--1 align-items--center font--h6 font-weight--400 bo--1 bo-r--0 border-color--muted cursor--pointer pl-- pv- color--white font-family--roboto-medium expanded-workspace-name-text"
            >
              <span className="expanded-workspace-name-text">{expandedWorkspace.name}</span>
            </div>
            <div className="bo--1 bo-l--0 border-color--muted flex align-items--center pr-- color--white">
              <i className="sci sci__caret--down font--h7" />
            </div>
          </div>
          <ul
            className={`list--group right--0 col-1-1 position--absolute zIndex-9--overlay bg-color--white cursor--pointer border-color--muted ${
              isWorkspaceDropdownExpanded ? 'is-showing-400--scroll bo-b--1' : 'is-hiding'
            }`}
          >
            <div className="bo-h--1 border-color--muted">
              {workspaces.map(team => {
                const { repoCount, permissions = {} } = team;
                const { reports } = permissions;

                let workspaceUrl;

                if (reports && repoCount) {
                  workspaceUrl = `/workspaces/${team.id}/issues`;
                } else if (repoCount) {
                  workspaceUrl = `/workspaces/${team.id}/agents`;
                } else {
                  workspaceUrl = `/workspaces/${team.id}/agents/new`;
                }

                return (
                  <div
                    className="hover-background--white-light"
                    key={`workspace-switcher-${team.id}`}
                  >
                    <Link
                      className="cursor--pointer link--no-underline"
                      to={workspaceUrl}
                      onClick={() => this.closeWorkspaceDropdown()}
                    >
                      <div
                        className={`pl-- col-1-1 font--h7 pv- ${
                          expandedWorkspace.id === team.id ? 'bg-color--muted-light text--bold' : ''
                        }`}
                      >
                        {team.name}
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </ul>
        </div>
        <SourceClearModal
          isOpen={showModal === 'CREATE_WORKSPACE'}
          onClose={() => this.showModal()}
          width={500}
          title="Create Workspace"
        >
          <CreateWorkspaceModal
            showModal={modalType => this.showModal(modalType)}
            showGroups={showGroups}
          />
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    navigationState: state.navigationState,
    orgState: state.orgState,
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigationActions: bindActionCreators(navigationActions as any, dispatch),
    teamActions: bindActionCreators(teamActions as any, dispatch),
    reportSelectedRowsActions: bindActionCreators(reportSelectedRowsActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSwitcher);
