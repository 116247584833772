import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';
import CopyToClipboard from '~/containers/CopyToClipboard';

interface PackageManagerTrashProps {
  component: object;
  lineNumber?: string;
  packageFile?: string;
  safeCommitHash?: string;
  transitive: boolean;
  unsafeCommitHash?: string;
}

const PackageManagerTrash: React.FunctionComponent<PackageManagerTrashProps> = props => {
  const {
    component,
    transitive = false,
    safeCommitHash,
    unsafeCommitHash,
    packageFile,
    lineNumber,
  } = props;
  const { name } = component;

  const diff = (
    <div className="grid bo--1 border-color--white-dark mt lh+ bg-color--white">
      <CodeDiffHeader text={packageFile} />
      <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
      <CodeDiffLine
        lineNumber={lineNumber}
        type="delete"
        text={`${component.coordinate1} ${unsafeCommitHash}`}
      />
      <CodeDiffLine
        lineNumber={lineNumber}
        type="add"
        text={`${component.coordinate1} ${safeCommitHash}`}
      />
      <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
    </div>
  );

  return (
    <div className="pt-">
      <div className="grid pt">
        <span className="bg-color--white-dark pv- ph- text--bold">1</span>
        {!transitive && (
          <span className="ph-- pv-">
            {' '}
            Update your <span className="text--bold"> {packageFile} </span>
          </span>
        )}
        {transitive && (
          <span className="ph-- pv-">
            {' '}
            Include {name} to your <span className="text--bold">glide.yaml</span>
          </span>
        )}
        <span className="pv-">as shown below:</span>
      </div>
      {diff}
      <div className="grid pt">
        <span className="grid__item max-width--30 bg-color--white-dark pv- ph- text--bold">2</span>
        <span className="grid__item pv- pl--">
          After updating {packageFile}, type the following to perform update. This will also update
          other libraries in {packageFile}
        </span>
      </div>
      <div className="pt-- -ml mt-">
        <CopyToClipboard value={`trash`} id="PackageManagerTrash" />
      </div>
    </div>
  );
};

export default PackageManagerTrash;
