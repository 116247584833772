import React from 'react';
import _ from 'lodash';
import RegistryAuthHelper from '~/utils/RegistryAuthHelper';

interface RegistryLinkProps {
  href?: string;
  className?: string;
  dangerouslySetInnerHTML?: any;
}

const RegistryLink: React.FunctionComponent<RegistryLinkProps> = props => {
  const { href = '', className = '', dangerouslySetInnerHTML = {} } = props;
  function onClick(e) {
    e.preventDefault();
    RegistryAuthHelper.proceedToRegistry(href);
  }

  /* eslint-disable react/no-danger */
  if (!_.isEmpty(dangerouslySetInnerHTML)) {
    return (
      <a
        href={encodeURI(href)}
        className={className}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        target="_blank"
        onClick={onClick}
      />
    );
  }
  /* eslint-disable react/no-danger */

  return (
    <a href={encodeURI(href)} className={className} target="_blank" onClick={onClick}>
      {props.children}
    </a>
  );
};

export default RegistryLink;
