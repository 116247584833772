import _ from 'lodash';
import * as actions from '~/actions/quickview';

const defaultState = {
  dataByRowId: {},
  currentOpenRowId: '',
  hasError: false,
  isLoading: false,
  isOpen: false,
  /*
    It contains state of the quick view. Eg. in libraries dependency graph
    quickview, we need to track currently selected repo.
    stateByRowId[rowId].dependenciesRepoSelectedItem
  */
  stateByRowId: {},
};

const quickviewState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_CURRENT_OPEN_ROW:
      return Object.assign({}, state, {
        currentOpenRowId: action.rowId,
      });
    case actions.SET_HAS_ERROR:
      return Object.assign({}, state, {
        hasError: action.hasError,
      });
    case actions.SET_IS_OPEN:
      return Object.assign({}, state, {
        isOpen: action.isOpen,
      });
    case actions.SET_IS_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading,
      });
    case actions.UPDATE_DATA_BY_ROWID: {
      const newDataByRowId = _.cloneDeep(state.dataByRowId);
      newDataByRowId[action.rowId] = action.data;
      return Object.assign({}, state, {
        dataByRowId: newDataByRowId,
      });
    }
    case actions.UPDATE_STATE_BY_ROWID: {
      const newStateByRowId = _.cloneDeep(state.stateByRowId);
      const { [action.rowId]: rowIdState = {} } = newStateByRowId;
      rowIdState[action.field] = action.val;
      newStateByRowId[action.rowId] = rowIdState;
      return Object.assign({}, state, {
        stateByRowId: newStateByRowId,
      });
    }
    default:
      return state;
  }
};

export default quickviewState;
