import React from 'react';
import { Link } from 'react-router-dom';
import SourceClearLoader from '~/components/SourceClearLoader';
import Tooltip from '~/components/Tooltip';
import { tooltipContent } from '~/constants/ModelConstants';

interface TeamWorkspacesQuickViewProps {
  data?: any[];
  showDetailsLink?: boolean;
}

const TeamWorkspacesQuickView: React.FunctionComponent<TeamWorkspacesQuickViewProps> = props => {
  const { data: workspaces = [], showDetailsLink } = props;
  const columnWidths = {
    name: 'col-1-2',
    filler: 'col-1-4',
    admin: '',
  };

  if (!workspaces) {
    return (
      <div>
        <SourceClearLoader />
      </div>
    );
  }

  return (
    <div className="bo-v--1 border-color--muted mv-- pb-">
      <div className="col-1-1 bg-color--muted-light pv-- font--h7 pl- color--muted-dark">
        <strong>WORKSPACES</strong>
      </div>
      <div className="grid mt-">
        <div className={`grid__item ${columnWidths.name}`}>
          <strong>Workspace Name</strong>
        </div>
        <div className={`grid__item text--center ${columnWidths.admin}`}>
          <Tooltip
            id="group-workspace-admins"
            content={tooltipContent.WORKSPACE_ADMIN}
            maxWidthClass="max-width--300"
            place="top"
          >
            <strong className="bo-b--1 bo--dashed">Workspace Admin</strong>
          </Tooltip>
        </div>
        <div className={`grid__item ${columnWidths.filler}`} />
      </div>

      <div className="overflow--auto max-height--300">
        {workspaces.map(workspaceObj => {
          const { team: workspace = {}, roles = [] } = workspaceObj;
          const { name, id: workspaceId } = workspace;

          return (
            <div className="grid mt--" key={`group-workspace-${workspaceId}`}>
              <div className={`grid__item ${columnWidths.name}`}>
                {showDetailsLink ? (
                  <strong>
                    <Link to={`/org/settings/workspaces/${workspaceId}`} className="link--obvious">
                      {name}
                    </Link>
                  </strong>
                ) : (
                  <div>{name}</div>
                )}
              </div>
              <div className={`grid__item text--center ${columnWidths.admin}`}>
                {roles.includes('WORKSPACE_ADMIN') ? 'Yes' : '--'}
              </div>
              <div className={`grid__item ${columnWidths.filler}`} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TeamWorkspacesQuickView;
