import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as groupActions from '~/actions/group';
import * as orgUserActions from '~/actions/orgUser';

import AddSelectedUserToGroups from '~/components/AddSelectedUserToGroups';

interface AddSelectedUserToGroupsModalProps {
  groupActions: object;
  orgUserActions: object;
  userId: string;
  groupState: object;
  orgUserState: object;
}
class AddSelectedUserToGroupsModal extends React.Component<AddSelectedUserToGroupsModalProps, {}> {
  componentDidMount() {
    this.props.groupActions.resetSelectedGroups();
  }

  toggleAdminField(groupId) {
    this.props.groupActions.toggleGroupAdminField(groupId);
  }

  updateSelectedGroup(groupId) {
    this.props.groupActions.updateSelectedGroups(groupId);
  }

  addUserToGroups() {
    const { groupState, userId } = this.props;
    const { selectedGroups = {}, groups = [] } = groupState;

    const groupData = Object.keys(selectedGroups)
      .filter(groupId => selectedGroups[groupId])
      .map(groupId => {
        const foundGroup = groups.find(group => group.id === parseInt(groupId)) || {};
        return {
          groupId: parseInt(groupId),
          users: [
            {
              userId: parseInt(userId),
              admin: foundGroup.stagedAdminStatus || false,
            },
          ],
        };
      });

    this.props.groupActions.groupMultiUpdate(groupData).then(() => {
      this.showModal();
      this.props.orgUserActions.fetchOrgUsers(userId);
    });
  }

  showModal(modalType) {
    this.props.orgUserActions.showModal(modalType);
  }

  render() {
    const { orgUserState, groupState, userId } = this.props;
    const { orgUsers = [] } = orgUserState;
    const activeUser = orgUsers.find(userObj => userObj.user.id === parseInt(userId)) || {};
    const { groups: userGroups = [] } = activeUser;
    const { groups = [], selectedGroups: selectedGroupIds = {} } = groupState;
    const activeUserGroupIds = {};
    const selectableGroups = [];
    const selectedGroups = [];

    userGroups.forEach(userGroup => {
      activeUserGroupIds[userGroup.id] = true;
    });

    const filteredGroups = groups.filter(group => !activeUserGroupIds[group.id]) || [];

    filteredGroups.forEach(group => {
      if (selectedGroupIds[group.id]) {
        selectedGroups.push(group);
      } else {
        selectableGroups.push({
          label: group.name,
          value: group.id,
        });
      }
    });

    const buttons = (
      <div className="grid__item col-1-1 flex justify-content--end mt">
        <button className="col-1-5 pv- mr-" onClick={() => this.showModal()}>
          Cancel
        </button>

        <button
          className="col-1-5 pv- btn--success"
          disabled={!selectedGroups.length}
          onClick={() => this.addUserToGroups()}
        >
          Save
        </button>
      </div>
    );

    return (
      <div className="">
        <AddSelectedUserToGroups
          selectableGroups={selectableGroups}
          selectedGroups={selectedGroups}
          updateSelectedGroup={groupId => this.updateSelectedGroup(groupId)}
          toggleAdminField={(field, value) => this.toggleAdminField(field, value)}
          buttons={buttons}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupState: state.groupState,
    orgUserState: state.orgUserState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSelectedUserToGroupsModal);
