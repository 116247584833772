import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';

import { RouteComponentProps } from 'react-router-dom';
import AgentsList from '~/containers/AgentsList';
import SourceClearLoader from '~/components/SourceClearLoader';

import * as agentActions from '~/actions/agents';

interface AgentSummaryPageProps extends RouteComponentProps {
  agentActions: object;
  agentState: object;
}

class AgentSummaryPage extends React.Component<AgentSummaryPageProps, {}> {
  componentDidMount() {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    this.props.agentActions.fetchTeamAgents(teamId);
  }

  handlePaginationClick(page) {
    // client side pagination
    this.props.agentActions.updateCurrentPage(page);
  }

  updateStringFilter(field, value) {
    // client side filtering
    this.props.agentActions.filterAgentListByString(value);
  }

  render() {
    const { agentState, match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    const {
      search = '',
      agents = [],
      agentListPagination = {},
      filteredAgents = [],
      sortAgentList = {},
      isFetching = false,
    } = agentState;

    return (
      <div className="mt">
        <Helmet>
          <title>Workspace Agents</title>
        </Helmet>
        <h3 data-automation-id="AgentSummaryPage-Title">Agents</h3>
        {!isFetching ? (
          <AgentsList
            agents={filteredAgents}
            pagination={agentListPagination}
            sortAgentList={sortAgentList}
            search={search}
            zeroAgents={!agents.length}
            teamId={teamId}
            handlePaginationClick={page => this.handlePaginationClick(page)}
            updateStringFilter={(field, value) => this.updateStringFilter(field, value)}
          />
        ) : (
          <SourceClearLoader />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentState: state.agentState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    agentActions: bindActionCreators(agentActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentSummaryPage);
