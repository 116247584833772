import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import DropdownMenu from 'react-dd-menu';
import _ from 'lodash';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as groupActions from '~/actions/group';
import * as orgUserInviteActions from '~/actions/orgUserInvite';

import { inviteModalTabs } from '~/constants/ModelConstants';
import SourceClearModal from '~/components/SourceClearModal';
import Tooltip from '~/components/Tooltip';
import AddUsersToGroupModal from '~/containers/AddUsersToGroupModal';
import OrgUserInviteModal from '~/containers/OrgUserInviteModal';

interface TeamUsersListProps {
  dropdownMenuActions: object;
  orgUserInviteActions: object;
  groupActions: object;
  groupId: string;
  teamId?: string;
  updateUsers: boolean;
  dropdownMenuState: object;
  groupState: object;
}
class TeamUsersList extends React.Component<TeamUsersListProps, {}> {
  constructor(props, context) {
    super(props, context);

    this.delayCloseDropdown = _.debounce(() => this.closeDropdown('group-users-actions'), 50);
  }

  componentWillUnmount() {
    this.props.groupActions.resetGroupSort();
    this.props.dropdownMenuActions.closeDropdownMenu('group-users-actions');
    this.props.orgUserInviteActions.resetInviteModal();
  }

  handleUserSelection(userId) {
    this.props.groupActions.updateSelectedGroupUsers(userId);
  }

  handleFilterInput(e) {
    const { groupId } = this.props;
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.groupActions.filterGroupUsersByInput(value, groupId);
    this.props.groupActions.updateGroupUsersFilter(value);
  }

  toggleGroupUsersAdminFilter() {
    const { groupId } = this.props;

    this.props.groupActions.toggleGroupUsersAdminFilter(groupId);
  }

  closeDropdown(menuId) {
    this.props.dropdownMenuActions.closeDropdownMenu(menuId);
  }

  openDropdown(menuId) {
    this.props.dropdownMenuActions.openDropdownMenu(menuId);
  }

  removeUsers() {
    const { groupState, groupId } = this.props;
    const { selectedGroupUsers = {}, groupUsers = [] } = groupState;
    const selectedGroupUserIds = Object.keys(selectedGroupUsers).filter(
      userId => selectedGroupUsers[userId]
    );
    const membershipIds = selectedGroupUserIds.map(userId => {
      const foundMembership =
        groupUsers.find(groupUser => groupUser.user.id === parseInt(userId)) || {};
      return foundMembership.id;
    });

    this.props.groupActions.removeMemberships(membershipIds, 'users', groupId);
  }

  updateGroupUserSort(column) {
    this.props.groupActions.updateGroupUserSort(column);
  }

  updateGroupAdminStatus(toAdmin = false) {
    const { groupState, groupId } = this.props;
    const { selectedGroupUsers = {} } = groupState;
    const formattedUsers = [];

    for (let userId in selectedGroupUsers) {
      formattedUsers.push({ userId, admin: toAdmin });
    }

    this.props.groupActions.groupMultiUpdate([
      {
        groupId: parseInt(groupId),
        users: formattedUsers,
      },
    ]);
  }

  showModal(modalType) {
    this.props.groupActions.showModal(modalType);
  }

  showOrgUserInviteModal(modalType) {
    this.props.orgUserInviteActions.openUserInviteModal(modalType);
  }

  render() {
    const { groupState, dropdownMenuState, groupId, teamId, updateUsers } = this.props;
    const {
      filteredGroupUsers = [],
      selectedGroupUsers = {},
      groupUsersFilter = '',
      onlyAdminGroupUsers,
      showModal,
      groups = [],
      groupUserSortColumn: sortColumn,
      sortAscending,
    } = groupState;
    const activeGroup = groups.find(group => group.id === parseInt(groupId)) || {};
    const { permissions = {} } = activeGroup;
    const { manageUsers = false } = permissions;
    const { numUsers } = activeGroup;
    const { openedDropdown = {} } = dropdownMenuState;
    const columnWidths = {
      email: 'col-3-10',
      admin: 'col-1-7',
      filler: 'col-1-18',
      select: 'col-1-18',
      firstName: '',
      lastName: '',
    };
    const someUsersSelected = _.some(selectedGroupUsers);

    const teamUsers = () => {
      if (!numUsers) {
        return <div className="mt- font--h3 color--muted-dark">No users belong to this team.</div>;
      } else if (!filteredGroupUsers.length) {
        return <div className="mt- font--h3 color--muted-dark">No users found.</div>;
      }
      return filteredGroupUsers.map(userObj => {
        const { user = {}, admin } = userObj;
        const { id: userId, email = '', firstName = '', lastName = '' } = user;
        const isSelected = selectedGroupUsers[userId] || false;

        return (
          <div className="grid grid--narrower mt-" key={userId}>
            <div className={`grid__item ${columnWidths.email}`}>
              {updateUsers ? (
                <Link
                  to={
                    teamId
                      ? `/workspaces/${teamId}/user-management/users/${userId}`
                      : `/org/settings/users/${userId}`
                  }
                  className="link--obvious"
                >
                  <strong>{email}</strong>
                </Link>
              ) : (
                <div className="">{email}</div>
              )}
            </div>
            <div className={`grid__item ${columnWidths.firstName}`}>{firstName || '--'}</div>
            <div className={`grid__item ${columnWidths.lastName}`}>{lastName || '--'}</div>
            <div className={`grid__item text--center ${columnWidths.admin}`}>
              {admin ? 'Yes' : '--'}
            </div>
            {manageUsers && (
              <React.Fragment>
                <div className={`grid__item ${columnWidths.filler}`} />
                <div
                  className={`grid__item flex align-items--center justify-content--center ${columnWidths.select}`}
                >
                  <label className="label--checkbox flex justify-content--center mb0">
                    <input
                      type="checkbox"
                      name="select"
                      checked={isSelected}
                      onChange={() => this.handleUserSelection(userId)}
                    />
                    <span className="react--checkbox mr0" />
                  </label>
                </div>
              </React.Fragment>
            )}
          </div>
        );
      });
    };

    return (
      <div className="grid">
        <div className="grid__item col-1-1">
          <div className="bo--1 border-color--muted-dark flex justify-content--space-between align-items--center p-">
            <div className="flex col-3-4">
              <div className="col-1-2">
                <input
                  type="text"
                  className="control--text col-1-1"
                  placeholder="Filter by name or email"
                  value={groupUsersFilter}
                  onChange={e => this.handleFilterInput(e)}
                />
              </div>
              {manageUsers && (
                <div className="flex align-items--center ml">
                  <label
                    htmlFor="only-admins"
                    className="label--checkbox flex align-items--center mb0"
                  >
                    <input
                      type="checkbox"
                      id="only-admins"
                      checked={onlyAdminGroupUsers}
                      onChange={() => this.toggleGroupUsersAdminFilter()}
                    />
                    <span className="react--checkbox mr0" />
                    <div className="ml-">Only Admins</div>
                  </label>
                </div>
              )}
            </div>
            {manageUsers && (
              <div>
                <DropdownMenu
                  isOpen={!!openedDropdown['group-users-actions']}
                  close={() => this.delayCloseDropdown()}
                  menuAlign="right"
                  textAlign="left"
                  toggle={
                    <button
                      className="btn--success p-"
                      onClick={() => this.openDropdown('group-users-actions')}
                    >
                      Actions
                    </button>
                  }
                >
                  <li className="min-width--200 dropdown-menu--li">
                    <button
                      className="pv--"
                      onClick={() => this.showModal('INVITE_USERS_TO_GROUP')}
                    >
                      Invite New Users
                    </button>
                  </li>
                  <li className="separator mh-" />
                  <li className="min-width--200 dropdown-menu--li">
                    <button className="pv--" onClick={() => this.showModal('ADD_USERS')}>
                      Add Users
                    </button>
                  </li>
                  <li className="min-width--200 dropdown-menu--li">
                    <button
                      className={`pv-- ${!someUsersSelected && 'disabled'}`}
                      disabled={!someUsersSelected}
                      onClick={() => this.removeUsers()}
                    >
                      Remove Users
                    </button>
                  </li>
                  <li className="separator mh-" />
                  <li className="min-width--200 dropdown-menu--li">
                    <button
                      className={`pv-- ${!someUsersSelected && 'disabled'}`}
                      disabled={!someUsersSelected}
                      onClick={() => this.updateGroupAdminStatus(true)}
                    >
                      Change to Team Administrator
                    </button>
                  </li>
                  <li className="min-width--200 dropdown-menu--li">
                    <button
                      className={`pv-- ${!someUsersSelected && 'disabled'}`}
                      disabled={!someUsersSelected}
                      onClick={() => this.updateGroupAdminStatus()}
                    >
                      Change to Team Member
                    </button>
                  </li>
                </DropdownMenu>
              </div>
            )}
          </div>
        </div>
        <div className="grid__item col-1-1 mt">
          {/* team users list header */}
          <div className="grid grid--narrower">
            <div
              className={`grid__item flex align-items--end justify-content--space-between cursor--pointer ${columnWidths.email}`}
              onClick={() => this.updateGroupUserSort('email')}
            >
              <strong>Email</strong>
              {sortColumn === 'email' ? (
                <i
                  className={`sci ${
                    sortAscending ? 'sci__arrow--closed-up' : 'sci__arrow--closed-down'
                  }`}
                />
              ) : (
                <i className={`sci sci__arrow--closed-scroll`} />
              )}
            </div>
            <div className={`grid__item flex align-items--end ${columnWidths.firstName}`}>
              <strong>First Name</strong>
            </div>
            <div className={`grid__item flex align-items--end ${columnWidths.lastName}`}>
              <strong>Last Name</strong>
            </div>
            <div
              className={`grid__item flex align-items--end justify-content--center ${columnWidths.admin}`}
            >
              <Tooltip
                id="group-admins"
                content="Team administrators can change the team name and description, invite new users to the organization, add users to a team, remove users, and make other members team administrators"
                maxWidthClass="max-width--300"
                place="top"
              >
                <strong className="bo-b--1 bo--dashed">Team Administrator</strong>
              </Tooltip>
            </div>
            {manageUsers && (
              <React.Fragment>
                <div className={`grid__item ${columnWidths.filler}`} />
                <div
                  className={`grid__item flex align-items--end justify-content--center ${columnWidths.select}`}
                >
                  <strong>Select</strong>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="grid__item col-1-1">{teamUsers()}</div>
        <SourceClearModal
          isOpen={showModal === 'ADD_USERS'}
          onClose={() => this.showModal('')}
          title="Add Users to Team"
          width={500}
        >
          <AddUsersToGroupModal
            groupId={groupId}
            showModal={modalType => this.showModal(modalType)}
          />
        </SourceClearModal>
        <SourceClearModal
          isOpen={showModal === 'INVITE_USERS_TO_GROUP'}
          onClose={() => this.showModal('')}
          title="Add Users to Team"
          width={500}
        >
          <OrgUserInviteModal
            hideMoreOptionsTab={true}
            defaultMoreOptionsModal={inviteModalTabs.ADD_USERS_TO_GROUP}
            hideAddInviteesToGroupGroupSelection={true}
            groupId={groupId}
            showModal={modalType => this.showModal(modalType)}
          />
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    groupState: state.groupState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    groupActions: bindActionCreators(groupActions, dispatch),
    orgUserInviteActions: bindActionCreators(orgUserInviteActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamUsersList);
