import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PopoverWithEscKey from '~/components/PopoverWithEscKey';

import * as popoverActions from '~/actions/popover';
import * as repoScopeSwitcherActions from '~/actions/repoScopeSwitcher';
import * as repoScopeActions from '~/actions/repoScope';
import RepoScopeOptionsList from '~/components/RepoScopeOptionsList';

interface RepoScopePopoverProps extends RouteComponentProps {
  popoverActions: object;
  repoScopeActions: object;
  repoScopeSwitcherActions: object;
  popoverState: object;
  repoScopeSwitcher: object;
  id: string;
  branches: any[];
  tags: any[];
  updateReportData: (...args: any[]) => any;
  buttonClassName?: string;
}
class RepoScopePopover extends React.Component<RepoScopePopoverProps, {}> {
  componentWillUnmount() {
    this.toggleClosed();
  }

  toggleClosed = () => {
    this.props.popoverActions.closePopover(this.props.id);
  };

  toggleOpen = () => {
    this.props.popoverActions.openPopover(this.props.id);
  };

  handleActiveRefType(type) {
    this.props.repoScopeSwitcherActions.updateActiveRefType(type);
  }

  updateSpecificOption(option, refType) {
    this.props.repoScopeSwitcherActions.updateSpecificOption(option, refType);
  }

  updateReportData(activeSpecificOption, activeRefType) {
    const { history } = this.props;
    if (activeRefType !== 'scan' && (!activeSpecificOption || !activeRefType)) {
      this.toggleClosed();
      return;
    }

    if (activeRefType === 'scan') {
      // default is lastest scan so clear repo scope object
      this.props.repoScopeActions.resetRepoScope();
    } else {
      // update repo scope, branch/tag option selection and url query parameter ie ?branch=<branchSelected>
      this.props.repoScopeActions.performRepoScopeChange(
        { [activeRefType]: activeSpecificOption },
        history
      );
    }

    // refresh all report data
    this.props.updateReportData();

    // close the popover
    this.toggleClosed();
  }

  validateCommit(value) {
    const { id } = this.props;
    this.props.repoScopeSwitcherActions.validateCommit(value, id);
  }

  render() {
    const { popoverState, id, repoScopeSwitcher, branches, tags, buttonClassName } = this.props;
    const { activeRefType, specificOptionByRefType = {} } = repoScopeSwitcher;
    const activeSpecificOption = specificOptionByRefType[activeRefType] || null;
    const isOpen = popoverState[id] || false;
    const enableUpdateData = activeRefType === 'scan' || !!activeSpecificOption;

    let branchOptions;
    let tagOptions;

    if (branches && branches.length) {
      branchOptions = (
        <div>
          <div
            className={activeRefType === 'branch' ? 'max-height--150 overflow--auto' : 'invisible'}
          >
            <RepoScopeOptionsList
              options={branches}
              updateSpecificOption={(option, refType) => this.updateSpecificOption(option, refType)}
              refType={'branch'}
              activeSpecificOption={activeSpecificOption}
            />
          </div>
        </div>
      );
    }

    if (tags && tags.length) {
      tagOptions = (
        <div>
          <div className={activeRefType === 'tag' ? 'max-height--150 overflow--auto' : 'invisible'}>
            <RepoScopeOptionsList
              options={tags}
              updateSpecificOption={(option, refType) => this.updateSpecificOption(option, refType)}
              refType={'tag'}
              activeSpecificOption={activeSpecificOption}
            />
          </div>
        </div>
      );
    }

    const content = (
      <div className="grid width--300">
        <div className="grid__item col-1-1 flex justify-content--space-between">
          <div className="font--h7 text--bold">Switch Branch or Tag</div>
          <div className="cursor--pointer" onClick={() => this.toggleClosed()}>
            <i className="sci sci__close" />
          </div>
        </div>
        <div className="grid__item col-1-1 mt-">
          <div className="bo-b--2 border-color--muted col-1-1 flex mb--">
            <div
              className={`pv-- ph ${
                branches && branches.length ? 'cursor--pointer' : 'tab--disabled'
              } ${
                activeRefType === 'branch'
                  ? 'bg-color--muted color--white'
                  : 'bg-color--muted-light'
              }`}
              onClick={
                branches && branches.length ? () => this.handleActiveRefType('branch') : undefined
              }
            >
              Branches
            </div>
            <div
              className={`pv-- ph ${tags && tags.length ? 'cursor--pointer' : 'tab--disabled'} ${
                activeRefType === 'tag' ? 'bg-color--muted color--white' : 'bg-color--muted-light'
              }`}
              onClick={tags && tags.length ? () => this.handleActiveRefType('tag') : undefined}
            >
              Tags
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1">
          {branchOptions}
          {tagOptions}
        </div>

        <div className="grid__item col-1-1 flex flex--justify-content--end mt-">
          <button
            className={`btn--primary pv-- ${!enableUpdateData && 'disabled'}`}
            disabled={!enableUpdateData}
            onClick={() => this.updateReportData(activeSpecificOption, activeRefType)}
          >
            Update
          </button>
        </div>
      </div>
    );
    return (
      <PopoverWithEscKey
        isOpen={isOpen}
        body={content}
        className="zIndex-9--dialog"
        preferPlace="bottom"
        place="bottom"
        onOuterAction={() => this.toggleClosed()}
      >
        <button
          className={`flex font--h8 btn--primary ${buttonClassName}`}
          onClick={isOpen ? this.toggleClosed : this.toggleOpen}
        >
          Change
        </button>
      </PopoverWithEscKey>
    );
  }
}

function mapStateToProps(state) {
  return {
    popoverState: state.popoverState,
    repoScopeSwitcher: state.repoScopeSwitcher,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    popoverActions: bindActionCreators(popoverActions, dispatch),
    repoScopeActions: bindActionCreators(repoScopeActions, dispatch),
    repoScopeSwitcherActions: bindActionCreators(repoScopeSwitcherActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RepoScopePopover));
