import React from 'react';
import { Link } from 'react-router-dom';
import { agentTypeImageMap } from '~/constants/ModelConstants';

interface IntegrationOptionProps {
  buttonText: string;
  route: string;
  imageEnum?: string;
  title?: string;
  description?: string;
  handleOnClick?: (...args: any[]) => any;
}

const IntegrationOption: React.FunctionComponent<IntegrationOptionProps> = props => {
  const { title, description, buttonText, route, imageEnum } = props;

  function handleOnClick() {
    if (props.handleOnClick) {
      props.handleOnClick();
    }
  }

  return (
    <div className={'col-1-1'} data-automation-id={`IntegrationOption-${buttonText}`}>
      <div className="mt-">
        <Link
          to={route}
          className={
            'btn col-1-1 grid grid--full grid--middle font-family--body font--h7 color--black bo--1 border-color--muted'
          }
          onClick={handleOnClick}
        >
          {imageEnum && (
            <div className="grid__item flex flex--align-items--center col-1-6">
              <img className="col-1-1 p--" src={agentTypeImageMap[imageEnum]} alt={imageEnum} />
            </div>
          )}
          <div className="grid__item ph-- flex flex--justify-content--space-between flex--align-items--center">
            {buttonText ? buttonText : title}
            <i className="sci sci__caret--right" />
          </div>
        </Link>
      </div>
      {description && <div className="mt--">{description}</div>}
    </div>
  );
};

export default IntegrationOption;
