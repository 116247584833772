import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as navigationActions from '~/actions/navigation';
import SidebarNavOption from '~/components/SidebarNavOption';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import { RouteComponentProps } from 'react-router-dom';

interface SettingsNavOptionsProps extends RouteComponentProps {
  navigationActions: object;
  navigationState: object;
  myState: App.MyState;
  orgState: App.OrgState;
}

const SettingsNavOptions: React.FunctionComponent<SettingsNavOptionsProps> = props => {
  const { location, orgState, myState } = props;
  const { me = {} } = myState;
  const { permissions = {} } = me;
  const { update, twoFactor } = permissions;
  const { org } = orgState;
  const samlDomains = org.samlDomains || [];
  const { pathname = '' } = location;
  const navOptions = [];

  if (update) {
    navOptions.push({
      title: 'Profile',
      toLocation: `/settings/profile`,
      isActive: pathname.includes(`settings/profile`),
    });
  }
  if (OrgPaidStatus.isOrgEnterpriseOrTrial(org)) {
    navOptions.push({
      title: 'API Credentials',
      toLocation: '/settings/credentials',
      isActive: pathname.includes('settings/credentials'),
    });
  }

  navOptions.push({
    title: 'Notifications',
    toLocation: `/settings/notifications`,
    isActive: pathname.includes(`settings/notifications`),
  });

  if (!samlDomains.length && twoFactor) {
    navOptions.push({
      title: 'Two-factor authentication',
      toLocation: `/settings/two-factor`,
      isActive: pathname.includes(`settings/two-factor`),
    });
  }

  return (
    <div className="flex flex--flex-direction--column">
      <div className="mt pl- pb- font--h6 pr-" data-e2e={'SettingsNavOptions-Title'}>
        Personal Settings
      </div>
      <div className="col-1-1 pl- pv-- pr-">
        <div className="bo-b--1 border-color--muted" />
      </div>
      {navOptions.map(option => (
        <SidebarNavOption key={option.title} option={option} fontSize="font--h7" />
      ))}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    navigationState: state.navigationState,
    myState: state.myState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigationActions: bindActionCreators(navigationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsNavOptions);
