import React from 'react';

interface ProIconProps {
  className?: string;
  greenProIcon?: boolean;
  crownProIcon?: boolean;
}

const ProIcon: React.FunctionComponent<ProIconProps> = props => {
  const { className, crownProIcon = true, greenProIcon = false } = props;
  const buttonColorClass = greenProIcon
    ? 'bg-color--white color--success'
    : 'bg-color--primary color--white';

  return (
    <span>
      {crownProIcon ? (
        <i className="sci sci__crown color--premium font--18 pr--" />
      ) : (
        <span
          className={`${className} ${buttonColorClass} bo-rad--3  p--- text--bold font--11 mr--`}
        >
          PRO
        </span>
      )}
    </span>
  );
};

export default ProIcon;
