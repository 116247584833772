import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import _ from 'lodash';

import * as groupActions from '~/actions/group';
import * as orgActions from '~/actions/org';
import * as orgUserActions from '~/actions/orgUser';
import * as workspaceInviteActions from '~/actions/workspaceInvite';
import * as toastrActions from '~/actions/toastr';

import LinkWorkspaceToTeams from '~/components/workspaces/teams/LinkWorkspaceToTeams';
import InviteOrAddUsers from '~/components/InviteOrAddUsers';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';

interface CreateWorkspaceModalProps extends RouteComponentProps {
  groupActions: object;
  orgActions: object;
  orgUserActions: object;
  workspaceInviteActions: object;
  groupState: object;
  orgState: App.OrgState;
  workspaceInviteState: object;
  showModal: (...args: any[]) => any;
  showGroups: boolean;
  vcPageState: VCPageState;
  toastrActions: object;
}
class CreateWorkspaceModal extends React.Component<CreateWorkspaceModalProps, {}> {
  componentDidMount() {
    const { groupState } = this.props;
    const { groups = [] } = groupState;

    if (!groups.length) {
      this.props.groupActions.fetchGroups();
    }

    this.props.orgActions.resetNewWorkspaceFlow();
    this.props.groupActions.resetSelectedGroups();
    this.props.orgUserActions.fetchOrgUsers();
    this.props.orgActions.updateNewWorkspaceName('');
  }

  componentWillUnmount() {
    this.props.workspaceInviteActions.resetWorkspaceInviteState();
  }

  updateNewWorkspaceName(e) {
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.orgActions.updateNewWorkspaceName(value);
  }

  updateNewWorkspaceStep(currentStep) {
    this.props.orgActions.updateNewWorkspaceStep(currentStep === 1 ? 2 : 1);
  }

  createWorkspace() {
    const { groupState, workspaceInviteState, orgState, history } = this.props;
    const { newWorkspaceName = '' } = orgState;
    const { validEmails = [] } = workspaceInviteState;
    const { selectedGroups = {}, groups = [] } = groupState;
    const groupKeys = Object.keys(selectedGroups).filter(key => selectedGroups[key]);
    const memberships = {};
    const teamData = {
      name: newWorkspaceName,
    };

    if (groupKeys.length) {
      memberships.groups = groupKeys.map(groupId => {
        const foundGroup = groups.find(group => group.id === parseInt(groupId)) || {};

        return {
          groupId,
          role: foundGroup.stagedAdminStatus ? 'WORKSPACE_ADMIN' : 'USER',
        };
      });

      memberships.users = [];
    }

    if (validEmails.length) {
      memberships.users = validEmails;

      memberships.groups = memberships.groups || [];
    }

    if (!_.isEmpty(memberships)) {
      teamData.memberships = memberships;
    }

    this.props.orgActions.createWorkspaceWithGroups(teamData).then(res => {
      if (res.id) {
        let canViewWorkspace = res.permissions && res.permissions.read;
        let canViewAgents = res.permissions && res.permissions.manageAgents;
        this.props.showModal();
        if (canViewWorkspace) {
          if (canViewAgents) {
            history.push(`/workspaces/${res.id}/agents/new`);
          } else {
            history.push(`/workspaces/${res.id}/settings`);
          }
        } else {
          this.props.toastrActions.addToastr({
            id: 'CREATE_NEW_WORKSPACE_ERROR_PERMISSION',
            level: 'success',
            title: 'Workspace Successfully Created',
            message: `Your workspace ${res.name}
               was successfully created. It is not visible because it is not linked to a team that you belong to.`,
            disableTimeout: true,
          });
        }
      }
    });
  }

  handleEmailsInput(value) {
    this.props.workspaceInviteActions.handleEmailsInput(value);
  }

  addEmails() {
    const { workspaceInviteState } = this.props;
    const { rawEmailsValue = '' } = workspaceInviteState;

    this.props.workspaceInviteActions.addEmails(rawEmailsValue);
  }

  toggleWorkspaceAdmin(email) {
    this.props.workspaceInviteActions.toggleWorkspaceAdmin(email);
  }

  removeUser(email) {
    this.props.workspaceInviteActions.removeUser(email);
  }

  updateSelectedGroups(groupId) {
    this.props.groupActions.updateSelectedGroups(groupId);
  }

  toggleAdminField(groupId) {
    this.props.groupActions.toggleGroupAdminField(groupId);
  }

  updateNewWorkspaceTab(tab) {
    this.props.orgActions.updateNewWorkspaceTab(tab);
  }

  showModal(modalType) {
    this.props.showModal(modalType);
  }

  render() {
    const {
      orgState,
      groupState,
      workspaceInviteState,
      showGroups,
      vcPageState: { shouldShowVeracodePage },
    } = this.props;
    const { rawEmailsValue = '', validEmails = [], invalidEmails = [] } = workspaceInviteState;
    const {
      newWorkspaceName = '',
      newWorkspaceStep,
      org = {},
    } = orgState;
    const { permissions = {} } = org;
    const { inviteUsers = false, listGroups = false } = permissions;
    const { groups = [], selectedGroups: selectedGroupIds = {} } = groupState;
    const selectedGroups = [];
    const selectableGroups = [];
    const displayMoreButton =
      (shouldShowVeracodePage && listGroups) /* for VC integration */ ||
      inviteUsers; /* for SrcClr standalone */
    const newWorkspaceTab = !shouldShowVeracodePage ? orgState.newWorkspaceTab : 'ADD_GROUPS';

    const shouldDisableButton = newWorkspaceName.trim().length < 1 ;

    groups.forEach(group => {
      if (selectedGroupIds[group.id]) {
        selectedGroups.push(group);
      } else {
        selectableGroups.push({
          label: group.name,
          value: group.id,
        });
      }
    });

    return (
      <div className="grid">
        {newWorkspaceStep === 1 ? (
          <div className="grid__item col-1-1">
            <div className="grid">
              <div className="grid__item col-1-1 mt-">
                <strong className="font--h6">Enter Workspace Name</strong>
              </div>
              <div className="grid__item col-1-1 mt- flex align-items--center">
                <input
                  type="text"
                  className="control--text col-1-1"
                  placeholder="Workspace Name (required)"
                  value={newWorkspaceName}
                  onChange={e => this.updateNewWorkspaceName(e)}
                  required
                  autoComplete="off"
                  name="workspace"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="grid__item col-1-1 mb+">
            {!shouldShowVeracodePage && (
              <div className="grid">
                <div className="grid__item col-1-2">
                  <div
                    className={`font--h6 bo-b--3 col-1-2 pb--- border-color--${
                      newWorkspaceTab === 'ADD_PEOPLE' ? 'primary' : 'muted'
                    } cursor--pointer`}
                    onClick={() => this.updateNewWorkspaceTab('ADD_PEOPLE')}
                  >
                    Add People
                  </div>
                </div>
                {showGroups && (
                  <div className="grid__item col-1-2">
                    <div
                      className={`font--h6 bo-b--3 col-1-2 pb--- border-color--${
                        newWorkspaceTab === 'ADD_GROUPS' ? 'primary' : 'muted'
                      } cursor--pointer`}
                      onClick={() => this.updateNewWorkspaceTab('ADD_GROUPS')}
                    >
                      Add Teams
                    </div>
                  </div>
                )}
              </div>
            )}
            {newWorkspaceTab === 'ADD_PEOPLE' && (
              <InviteOrAddUsers
                rawEmailsValue={rawEmailsValue}
                validEmails={validEmails}
                invalidEmails={invalidEmails}
                removeUser={email => this.removeUser(email)}
                toggleWorkspaceAdmin={email => this.toggleWorkspaceAdmin(email)}
                addEmails={() => this.addEmails()}
                handleEmailsInput={value => this.handleEmailsInput(value)}
              />
            )}
            {newWorkspaceTab === 'ADD_GROUPS' && (
              <LinkWorkspaceToTeams
                selectableGroups={selectableGroups}
                selectedGroups={selectedGroups}
                updateSelectedGroups={groupId => this.updateSelectedGroups(groupId)}
                toggleAdminField={groupId => this.toggleAdminField(groupId)}
                shouldShowVeracodePage={shouldShowVeracodePage}
              />
            )}
          </div>
        )}
        <div className="grid__item col-1-1 flex justify-content--end align-items--center">
          <button className="col-1-5 mr- pv-" onClick={() => this.showModal()}>
            Cancel
          </button>
          {displayMoreButton && (
            <button
              className={`${newWorkspaceStep === 1 ? 'ph-' : 'col-1-5'} pv- btn--primary-clear mr-`}
              onClick={() => this.updateNewWorkspaceStep(newWorkspaceStep)}
            >
              {newWorkspaceStep === 1 ? 'More Options' : 'Back'}
            </button>
          )}
          <button
            className="col-1-5 pv- btn--primary-clear"
            onClick={() => this.createWorkspace()}
            disabled={shouldDisableButton}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupState: state.groupState,
    orgState: state.orgState,
    workspaceInviteState: state.workspaceInviteState,
    vcPageState: state.vcPageState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions, dispatch),
    orgActions: bindActionCreators(orgActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
    workspaceInviteActions: bindActionCreators(workspaceInviteActions, dispatch),
    toastrActions: bindActionCreators(toastrActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateWorkspaceModal));
