import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Helpers from '~/utils/Helpers';
import Pagination from '~/components/Pagination';
import SourceClearLoader from '~/components/SourceClearLoader';

import * as agentScanHistoryActions from '~/actions/agentScanHistory';
import * as reportScopeActions from '~/actions/reportScope';

interface AgentActivitySidebarProps {
  agentScanHistoryActions: object;
  reportScopeActions: object;
  agentId: string;
  teamId?: string;
  sidebarId: string;
  agentScanHistoryState: object;
  sidebarState: object;
}
class AgentActivitySidebar extends React.Component<AgentActivitySidebarProps, {}> {
  componentDidMount() {
    const { agentId } = this.props;
    this.props.reportScopeActions.updateReportScope('agents', [agentId]);
  }

  componentDidUpdate(prevProps) {
    const { sidebarState, sidebarId, teamId } = this.props;
    const { sidebarState: prevSidebarState } = prevProps;

    if (!prevSidebarState[sidebarId] && sidebarState[sidebarId]) {
      this.props.agentScanHistoryActions.fetchAgentScans(teamId, 0);
    }
  }

  componentWillUnmount() {
    this.props.reportScopeActions.updateReportScope('agents', '*');
  }

  handlePageClick(page) {
    const { teamId } = this.props;

    this.props.agentScanHistoryActions.fetchAgentScans(teamId, page);
  }

  render() {
    const { agentScanHistoryState } = this.props;
    const { scansData, isFetching } = agentScanHistoryState;
    const { _embedded = {}, page = {} } = scansData;
    const { repoScans = [] } = _embedded;
    const { number, totalPages } = page;

    const renderRepoScans = () => {
      if (isFetching) {
        return <SourceClearLoader />;
      } else if (!repoScans.length) {
        return (
          <div className="mt+ ph+ mb++ font--h7">
            This agent has no recorded activity. For detailed instructions on how to use this token
            in your specific setup, please{' '}
            <a
              href="https://help.veracode.com/go/c_sc_agent_management"
              target="_blank"
              className="link--obvious"
            >
              refer to the documentation
            </a>
            .
          </div>
        );
      }

      return (
        <Fragment>
          {repoScans.map(scan => {
            const { id, repoName, scanDate, branch } = scan;

            return (
              <div className="grid" key={`event-id-${id}`}>
                <div className="grid__item col-3-10 bo-r--1 border-color--muted-light bo--dashed flex">
                  <div className="grid grid--full col-1-1">
                    <div className="grid__item pr- flex align-items--center">
                      <div className="col-1-1">
                        <div className="text--right">
                          <strong>{moment(scanDate).format('D MMM YYYY')}</strong>
                        </div>
                        <div className="text--right font--h8 pt---">
                          <strong>{`${moment(scanDate).format(
                            'HH:mm:ssA'
                          )} ${Helpers.getTimezoneAbbr()}`}</strong>
                        </div>
                      </div>
                    </div>
                    <div className="grid__item col-1-12 flex align-items--center">
                      <div className="col-1-1 bo-t--1 border-color--muted-light" />
                    </div>
                  </div>
                </div>
                <div className="grid__item col-7-10 flex">
                  <div className="pv-">
                    <div className="flex">
                      <strong className="pr-">Repo:</strong> {repoName || '--'}
                    </div>
                    <div className="flex">
                      <strong className="pr-">Branch:</strong> {branch}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Fragment>
      );
    };

    return (
      <div className="grid">
        <div className="grid__item col-1-1">
          <div className="col-1-1 flex justify-content--center pv---">
            <div className="col-1-2">
              <Pagination
                currentPage={number}
                totalPages={totalPages || 1}
                handlePageClick={page => this.handlePageClick(page)}
              />
            </div>
          </div>
          <div className="col-1-1 bo-t--1 border-color--muted-light" />
          {renderRepoScans()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentScanHistoryState: state.agentScanHistoryState,
    sidebarState: state.sidebarState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    agentScanHistoryActions: bindActionCreators(agentScanHistoryActions, dispatch),
    reportScopeActions: bindActionCreators(reportScopeActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentActivitySidebar);
