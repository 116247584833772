import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as MODEL from '~/constants/ModelConstants';
import * as meActions from '~/actions/me';
import { RouteComponentProps } from 'react-router-dom';
interface MasterServicesAgreementPageProps extends RouteComponentProps {
  meActions: object;
  myState: object;
}
class MasterServicesAgreementPage extends React.Component<MasterServicesAgreementPageProps, {}> {
  submitEula = () => {
    const { myState, history } = this.props;
    const { eulaChecked } = myState;
    const redirectUrl = localStorage && localStorage.getItem(MODEL.REDIRECT_URL);
    if (eulaChecked) {
      this.props.meActions.acceptEula().then(res => {
        if (res.success) {
          history.push(redirectUrl && !redirectUrl.includes('terms') ? redirectUrl : '/');
        }
      });
    }
  };

  toggleAcceptEula = () => {
    this.props.meActions.toggleAcceptEula();
  };

  render() {
    const { myState } = this.props;
    const { me = {}, eulaChecked } = myState;
    const { eula = {} } = me;
    const { acceptedVersion } = eula;

    return (
      <section className="container flex align-items--center justify-content--center">
        <div className="grid">
          <div className="grid grid--center">
            <div className="grid__item col-5-6">
              <div className="grid grid--center">
                <div className="grid__item col-1-1 mt+">
                  <div className="font--h6">
                    Please review our {acceptedVersion ? 'updated' : ''} Terms of Service.
                  </div>
                </div>
                <div className="grid__item col-1-1 mt">
                  <section>
                    <div className="bo--1 pb+ ph border-color--muted is-showing-300--scroll">
                      <p>Effective date: April 9, 2018</p>
                      <p>Prior update: April 9, 2018</p>
                      <p>Last update: May 25, 2018</p>
                      <strong>
                        <h5 className="color--primary text--center mb-">Terms of Service</h5>
                      </strong>
                      <p>
                        <strong>VERACODE END USER ASSESSMENT AGREEMENT</strong>
                      </p>
                      <p>
                        <strong>Vers. 4.3</strong>
                      </p>

                      <p>
                        THIS AGREEMENT (THE “AGREEMENT”) IS ENTERED INTO BETWEEN THE CUSTOMER ENTITY
                        IDENTIFIED ON AN ORDER FORM REFERENCING THIS AGREEMENT (“CUSTOMER”) AND
                        VERACODE, INC., A DELAWARE CORPORATION WITH OFFICES AT 65 NETWORK DRIVE,
                        BURLINGTON, MASSACHUSETTS 01803 (“VERACODE”) RELATING TO THE VERACODE
                        SOLUTION. BY CUSTOMER CLICKING THROUGH THIS AGREEMENT ELECTRONICALLY OR THE
                        PARTIES EXECUTING A WRITTEN ORDER FORM REFERENCING THIS AGREEMENT, CUSTOMER
                        AND VERACODE MUTUALLY AGREE TO BE BOUND BY THE TERMS AND CONDITIONS HEREOF.
                        EACH ORDER FORM SHALL BE EITHER AN ORDER FORM OR STATEMENT OF WORK IN A FORM
                        PROVIDED BY VERACODE AND MUTUALLY AGREED TO AND ENTERED INTO BY CUSTOMER AND
                        VERACODE (EITHER ELECTRONICALLY OR IN WRITING AS PROVIDED ABOVE) PROVIDED
                        THAT IF CUSTOMER PURCHASES THE SOLUTION THROUGH A VERACODE AUTHORIZED
                        PARTNER, THE ORDER FORM SHALL BE THE ORDER FORM ENTERED INTO BETWEEN
                        VERACODE AND THE AUTHORIZED PARTNER FOR CUSTOMER’S USE (EACH AN “ORDER
                        FORM”). FOR PURPOSES OF CLARITY, CUSTOMER’S SELF-SERVICE ACCESS OF THE
                        SOLUTION THROUGH THE PLATFORM SHALL CONSTITUTE AN ORDER FORM FOR THE
                        DESIGNATED SOLUTION AND TERM. If Customer wishes to order Solution(s), the
                        parties shall enter into one or more Order Forms, each of which shall be
                        deemed to be incorporated herein by reference. Each Order Form shall
                        specify, as applicable, the particular Solution(s) ordered (including a
                        description thereof), the quantity of Solution(s) ordered, the Licensed
                        Entity, the fees for the Solution(s) and the term of the Order Form. A
                        Customer Affiliate may enter into an Order Form pursuant to this Agreement
                        and, in such case, by entering into the Order Form, the Affiliate agrees to
                        be bound by the terms and conditions of this Agreement with respect to such
                        Order Form and such Affiliate shall be considered to be the Customer, as
                        such term is used herein, with respect to such Order Form. Customer shall be
                        responsible for the compliance of its Affiliates with the terms and
                        conditions of this Agreement.
                      </p>

                      <p>
                        <strong>1. Definitions.</strong>
                        The following terms shall have the meaning specified below: “Affiliate”
                        shall mean any entity controlled by, controlling, or under common control
                        with a party to this Agreement during the period such control exists. For
                        the purposes hereof “control” means the power to direct the operation,
                        policies and management of an entity through the ownership of more than
                        fifty percent (50%) of the voting securities of such entity, by contract, or
                        otherwise. “Application(s)” shall mean a supported software application
                        (including a web enabled application or mobile application), which depending
                        on the type of Solution purchased, shall be either a Customer Application or
                        Third Party Application, as set forth in the applicable Order Form, and
                        defined as follows: “Customer Application(s)” shall mean (i) an Application
                        owned by Customer and/or its Affiliates (provided that the Application may
                        contain third party software components licensed by Customer and/or its
                        Affiliates) or (ii) a web Application owned by a Third Party but licensed by
                        Customer for internal use and installed on a Customer owned or controlled
                        system (“Customer System”) which is made available to Veracode for
                        Assessment by Customer on such Customer System. “Third Party Application(s)”
                        mean an Application owned and developed by a Third Party which is (i)
                        licensed by Customer for internal use or being evaluated by Customer for
                        potential licensing; and (ii) is not a Customer Application. “Assessment”,
                        “Assess”, “Assesses” or “Assessed” shall mean the analysis performed by
                        Veracode on an Application as part of a Veracode Solution. “Authorized
                        Recipient” shall have the meaning set forth in Section 2.2 hereof. “Customer
                        Data” shall mean any data, information or content (excluding the
                        Applications and any Assessment results thereof) provided by Customer to
                        Veracode in connection with its use of a Solution. “Confidential
                        Information” shall have the meaning set forth in Section 4 hereof. “Licensed
                        Entity” shall mean the legal entity(ies) of Customer and/or its Affiliates
                        authorized to use the Solution as specified in the applicable Order Form,
                        and unless otherwise stated in the Order Form, the Licensed Entity shall be
                        Customer and its Affiliates. “Solution(s)” shall mean the particular
                        Veracode’s security related solution(s) to be provided by Veracode to
                        Customer as described in an Order Form (including in the case of any
                        Solution provided on a software as a service basis, the Veracode Solution
                        Platform and any Veracode provided content provided as a part thereof); any
                        software incidental to the Customer’s use of Solutions which is provided by
                        Veracode to Customer to be installed at a customer site (“On-site
                        Software”), including agents, APIs, virtual appliances and e-Learning
                        content and other course content; any Solution documentation provided by
                        Veracode in connection therewith (“Documentation”); and any updates to the
                        particular Solution made available by Veracode as part of such Solution from
                        time to time, in its sole discretion. As used herein, the term Solution
                        specifically excludes all Applications. “Solution Output” shall mean the
                        following: (i) “Report(s)” meaning any report (or any portion of a report)
                        accessible through Veracode’s Solution Platform (or provided by such other
                        means as mutually agreed by the parties), that provides the results of an
                        Assessment either relating to (a) a Customer Application (“Customer
                        Report(s)”) or (b) a Third Party Application (“Third Party Report(s)”);
                        and/or (ii) “Document Output” meaning any type of document or report, other
                        than a Report, to be provided or made available by Veracode, through the
                        Solution Platform or otherwise, as described in an Order Form. “Solution
                        Platform” shall mean any Veracode owned or licensed software, data,
                        information, content or systems which form a part of the platform owned or
                        controlled by Veracode which is used by Veracode to provide automated
                        software as a service Solutions. The Solution Platform specifically excludes
                        all Applications. “Term” shall have the meaning set forth in Section 9
                        hereof. “Third Party” shall mean a third party software provider that owns a
                        Customer Application or Third Party Application to be Assessed by Veracode
                        and with respect to which Customer has the right to access the Report
                        resulting from such Assessment as expressly described herein. As used
                        herein, Third Party shall include, but not be limited to, other Veracode
                        customers who elect to publish results of an Assessment of their own
                        Application to Customer via the Solution Platform. "Users" shall have the
                        meaning set forth in Section 2.1.1 hereof. “Veracode Property” shall mean
                        any technical information, e-Learning or other course content, techniques,
                        ideas, methods, processes, software, interfaces, utilities, data, documents,
                        directories, designs, user interfaces, know-how, intellectual property,
                        information or materials of any kind (regardless of form) which has been or
                        is acquired, created, developed or licensed by Veracode prior to or outside
                        the scope of this Agreement and any improvement, modification or other
                        derivative works thereof and all intellectual property rights therein; and
                        expressly includes, without limitation, the Solution and Solution Output
                        templates.{' '}
                      </p>

                      <p>
                        <strong>2. Solution.</strong>
                      </p>
                      <p>
                        <strong>2.1 Solution Description and Obligations of the Parties.</strong>
                      </p>
                      <p>
                        <strong>2.1.1 Solution Description and User Access.</strong> Veracode will
                        provide the Solution and Solution Output as described in an Order Form.
                        Customer will identify a primary administrative username and password that
                        will be used to set up Customer’s account. Customer may use the
                        administrative user name and password to create subaccounts for its
                        employee, contractor and consultant users (“Users”), each of which shall
                        have unique login IDs and passwords. Customer shall be responsible for the
                        acts or omissions of any of its Users who access the Solution using
                        passwords or access procedures provided to or created by Customer. Veracode
                        reserves the right to refuse registration of, or to cancel, login IDs of any
                        Users who violate the terms of this Agreement. Customer agrees to notify
                        Veracode promptly upon learning of any unauthorized use of Customer’s
                        accounts or any other breach of security related to the Solution(s) and
                        Veracode may temporarily suspend such accounts to mitigate the effects of
                        any security event.
                      </p>
                      <p>
                        <strong>2.1.2 Applications – Access.</strong> Customer shall be responsible
                        for making available each Customer Application for which Customer requests
                        an Assessment. Each Third Party Application for which Customer requests an
                        Assessment, shall be provided by the Third Party pursuant to a separate
                        agreement entered into between Veracode and such Third Party as provided for
                        in Section 3.2 hereof. Customer shall not submit any Third Party
                        Applications to Veracode directly. Each Application shall be made available
                        in executable object code form (unless the Application is deployed in
                        source, in which case source will be provided) in accordance with Veracode’s
                        specifications or, in the case of a web Application, by providing the URL or
                        other information as mutually agreed to between the parties to enable the
                        Assessment. Customer agrees not to provide any health, payment card or
                        similarly sensitive personal information that imposes specific data security
                        obligations for the processing of such data part unless it is a supported
                        feature in the Documentation of the applicable Solution.
                      </p>
                      <p>
                        <strong>2.1.3 Other Obligations.</strong> Veracode is responsible for
                        maintaining back-ups of the Solution Output which is stored on the Solution
                        Platform. Customer is responsible for providing the systems, servers,
                        software and network and communications necessary to connect to and utilize
                        the Solution Platform. Veracode has no obligation to back up Customer’s
                        systems or any data processed or stored on the Customer’s systems. Each
                        party will comply with such other obligations and requirements relating to
                        the performance of the Solution as mutually agreed to by the parties in an
                        Order Form.
                      </p>
                      <p>
                        <strong>2.2 Solution License.</strong> Veracode grants Customer a
                        non-exclusive, non-transferable right and license, during the subscription
                        term and in the quantities specified in an Order Form, to (i) access and use
                        the particular Solution(s) as described in an Order Form (and, if
                        applicable, the Solution Platform), subject to the terms hereof, solely for
                        Customer’s internal use; (ii) have the permitted classifications of
                        Applications Assessed to the extent Assessments are included in an Order
                        Form; (iii) access and use each Report and/or the Document Output made
                        available to Customer by Veracode subject to the terms of Section 3.3
                        hereof; and (iv) at Customer’s option, make available (by accepting an
                        electronic prompt provided via the Solution Platform) the summary results of
                        an Assessment of a Customer Application to one or more other specified
                        Veracode customers selected by Customer (each an “Authorized Recipient”). If
                        Customer elects to make available the summary results of an Assessment to an
                        Authorized Recipient as provided for in this Section 2.2, then Customer
                        grants Veracode the right to transmit and disclose such summary Report to
                        the Authorized Recipient. Customer also grants Veracode the right to provide
                        Authorized Recipients with high level status updates regarding the status of
                        the Assessment and the availability of the Report. Notwithstanding anything
                        herein to the contrary, Customer’s use of the Solution pursuant to a
                        particular Order Form shall be limited to use by the Licensed Entity
                        specified in such Order Form and, to the extent applicable, Customer shall
                        have the right to grant the Licensed Entity(ies) a sublicense to use the
                        Solution as provided herein. With regard to any On-Site Software, Customer
                        shall use the On-Site Software solely at a Customer owned or controlled
                        site. Customer shall not (i) remove or alter any proprietary notices
                        included on the Solutions; or (ii) modify or attempt to expose the source
                        code of or attempt to recreate any software which forms a part of the
                        Solution. Except as provided herein, Customer shall not have the right to
                        make the Solution available to, use the Solution on behalf of, or for the
                        benefit of any third party. Except for the rights expressly licensed to
                        Customer hereunder, Veracode and its licensors reserve and retain all right,
                        title and interest to the Veracode Property.
                      </p>
                      <p>
                        <strong>
                          2.3 Solution Add-Ons, Other Veracode Offerings and/or Pre-release or
                          Early-Access Solutions.
                        </strong>{' '}
                        Unless specifically agreed in writing by the parties, this Agreement shall
                        govern with respect to all Solutions made available to Customer, whether
                        through an Order Form referencing this Agreement or otherwise. The parties
                        understand and agree that, from time to time, Veracode may make available
                        functionality, add-ons or offerings of Solutions which are not contemplated
                        by this Agreement or which have different or additional terms than the terms
                        and conditions of this Agreement. In the event that the parties mutually
                        agree to add such an offering to the scope of this Agreement, Customer and
                        Veracode may agree to additional or different terms and conditions
                        applicable to such offering either in an Order Form or by entering into an
                        addendum or an amendment to this Agreement. In the event that any Solutions
                        to be provided by Veracode are identified as pre-release, early access,
                        “alpha”, “beta” or similar, then notwithstanding anything to the contrary
                        contained herein, such Solutions are provided “as is” and without any
                        warranty or service commitment of any kind.
                      </p>
                      <p>
                        <strong>
                          3. Applications and Solution Output – Intellectual Property Rights.
                        </strong>
                      </p>
                      <p>
                        <strong>3.1 Customer Applications.</strong>
                        Customer grants Veracode a limited, non-exclusive right and license to (i)
                        during the subscription term specified in an Order Form, use, access,
                        reproduce, and store each Customer Application solely to the extent
                        necessary to provide the Solution under this Agreement; (ii) during the
                        subscription term specified in an Order Form, create, reproduce, store, make
                        available and transfer Reports as specified in Section 3.3 of this
                        Agreement; and (iii) during the term of this Agreement, use the results of
                        the Assessments to create high level, generic, anonymous, statistical data
                        relating to such Assessments (“Statistical Data”) that is aggregated with
                        assessment results of other parties (the “Aggregated Data”) and, for a
                        perpetual license term, use, reproduce, store, publish, license and transmit
                        the Statistical Data included within the Aggregated Data in a format that
                        does not reveal or allow to be inferred the identity of the Customer, the
                        identity of any Third Party or a particular Application or link Customer or
                        a Third Party to an Application (such as, for example, statistical data
                        pertaining to an industry or application type). Veracode will not expose or
                        attempt to derive the source code of any Customer Application. Except as
                        expressly licensed herein, Customer and its Affiliates (and/or their
                        licensors) shall retain all right, title and/or interest to the Customer
                        Applications and Customer Data and all intellectual property rights therein,
                        and except as expressly licensed herein, Veracode shall obtain no right or
                        license thereto.
                      </p>
                      <p>
                        <strong>3.2 Third Party Applications.</strong>
                        Customer understands and agrees that with respect to each Third Party
                        Application, each Third Party must enter into a separate agreement with
                        Veracode in a form mutually agreed to by Veracode and the Third Party before
                        Veracode will either Assess a Third Party Application or provide a Third
                        Party Report to Customer, pursuant to which (i) the Third Party shall grant
                        Veracode permission to Assess the applicable Third Party Application; and
                        (ii) The Third Party shall grant Veracode permission to provide Customer
                        with a summary version of the Third Party Report relating to such Third
                        Party Application. Customer grants Veracode the limited right to use
                        Customer’s name in Veracode’s communications to Third Parties and/or
                        Customer’s vendors who are identified by Customer for such purpose solely in
                        connection with Veracode’s performance of the Solution.
                      </p>
                      <p>
                        <strong>3.3 Solution Output.</strong>
                      </p>
                      <p>
                        <strong>3.3.1 Customer Reports.</strong>
                        For each Customer Application Assessed by Veracode as part of the Solution,
                        Veracode will make available to Customer a Customer Report containing the
                        results of the Assessment as may be further described in an Order Form.
                        Subject to Veracode’s ownership of any Veracode Property contained therein,
                        Customer will own all right, title and interest to each Customer Report.
                        Veracode grants Customer a non-exclusive, non-transferable, perpetual,
                        worldwide license to access, use and reproduce the Veracode Property
                        (including the Veracode trademarks) included in each Customer Report as
                        incorporated into the Customer Report solely for Customer’s and its
                        Affiliates’ internal use. Notwithstanding the foregoing, Customer shall have
                        the right to disclose the Veracode Property (including the Veracode
                        trademarks) solely as incorporated into the Customer Report (and without any
                        modifications thereto) to (i) other Veracode customers via the Solution
                        Platform as provided for herein and (ii) any other third parties subject
                        only to a confidentiality obligation at least as protective as the
                        confidentiality obligations contained herein.
                      </p>
                      <p>
                        <strong>3.3.2 Third Party Reports.</strong> For each Third Party Application
                        Assessed by Veracode for Customer as part of the Solution or for which the
                        Third Party elects to publish the results of a Report regarding its Third
                        Party Application to Customer as provided for herein, Veracode shall make
                        available to Customer a summary version of a Third Party Report containing
                        the results of the Assessment as further described in an Order Form and the
                        Third Party will have access to a detailed Third Party Report relating to
                        the Third Party Application as further described in the Order Form. Customer
                        understands and agrees that, pursuant to Veracode’s separate agreement with
                        the Third Party, the Third Party Report shall be deemed to be owned by, and
                        considered to be the Confidential Information of, the Third Party. Customer
                        is hereby granted a non-exclusive, non-transferable, perpetual, worldwide
                        license to access, use and reproduce the summary version of the Third Party
                        Report and any Veracode Property contained therein in the form provided by
                        Veracode solely for Customer’s and its Affiliates’ internal use.
                      </p>
                      <p>
                        <strong>3.3.3 Document Output.</strong> Veracode will provide Document
                        Output to the extent specified in an Order Form. Subject to Veracode’s
                        ownership of any Veracode Property contained therein, Customer shall own all
                        right, title and interest to the Document Output. Veracode hereby grants
                        Customer a non-exclusive, non-transferable, perpetual, worldwide license to
                        access, use and reproduce any Veracode Property contained in the Document
                        Output solely for Customer’s and its Affiliates’ use in connection with
                        their use of the Document Output.
                      </p>
                      <p>
                        <strong>4. Confidentiality and Customer Data.</strong>
                      </p>
                      <p>
                        <strong>4.1 Confidentiality.</strong>
                        During the term of this Agreement and continuing after termination of this
                        Agreement, each party shall retain in confidence, and not use except for the
                        purposes described in this Agreement, the confidential and proprietary
                        information of the other party disclosed by the other party or its
                        Affiliates or made available in connection with this Agreement, whether
                        disclosed in written, oral, electronic or visual form, which is identified
                        as confidential at the time of disclosure or should reasonably be understood
                        to be confidential given the nature of the information and the circumstances
                        surrounding the disclosure, including without limitation business,
                        operations, finances, technologies, products and services, pricing,
                        personnel, customer and suppliers and (i) with regard to Customer, Customer
                        Data, the Customer Applications, debug builds and information regarding the
                        specific security vulnerabilities of the Customer Applications and, subject
                        to Veracode’s ownership of the Veracode Property, the Customer Reports and
                        Document Output, and (ii) with regard to Veracode, the Veracode Property
                        (“Confidential Information”). The receiving party will use the same degree
                        of care and discretion (but not less than reasonable care) to avoid
                        disclosure, publication or dissemination of the disclosing party’s
                        Confidential Information as it uses with its own information of a similar
                        nature. Except as authorized in this Agreement or an Order Form, the
                        receiving party will not disclose the Confidential Information of the
                        disclosing party to a third party other than to its or its Affiliates’
                        employees, contractors, agents or advisors in connection with its
                        performance of this Agreement and the receiving party shall be liable to the
                        disclosing party for any violation of this Agreement by such persons.
                        Confidential Information shall not include information that (a) is publicly
                        known at the time of disclosure, (b) is lawfully received from a third party
                        not bound in a confidential relationship with the disclosing party, (c) is
                        published or otherwise made known to the public by the disclosing party, or
                        (d) was or is generated independently without use of the disclosing party’s
                        Confidential Information. The receiving party may disclose Confidential
                        Information as required to comply with orders of governmental entities that
                        have jurisdiction over it or as otherwise required by law, provided that the
                        receiving party (i) gives the disclosing party reasonable advance written
                        notice to allow the disclosing party to seek a protective order or other
                        appropriate remedy (except to the extent that compliance with the foregoing
                        would cause it to violate an order of the governmental entity or other legal
                        requirement), (ii) discloses only that portion of the Confidential
                        Information as is required, and (iii) uses commercially reasonable efforts
                        to obtain confidential treatment for any Confidential Information so
                        disclosed. Notwithstanding anything herein to the contrary, provided that
                        Veracode does not use or disclose Customer Confidential Information,
                        Veracode shall be free to use, exploit and disclose its general skills,
                        concepts, ideas, know-how, and expertise gained or learned during the course
                        of this Agreement, and Veracode shall not be restricted from creating output
                        for other customers which is similar to that provided to Customer.
                      </p>
                      <p>
                        <strong>4.2 Customer Data.</strong> Customer shall ensure that it has the
                        right to disclose Customer Data, if any, that may be provided by Customer to
                        Veracode for the purpose of enabling Veracode to perform its obligations
                        under this Agreement. Customer grants Veracode the right to use the Customer
                        Data solely on Customer’s behalf and solely for the purpose of providing the
                        Solution and performing its obligations under this Agreement.{' '}
                      </p>
                      <p>
                        <strong>4.3 Security.</strong> In addition to the confidentiality
                        obligations set forth herein, Veracode shall maintain, use and process any
                        Customer Confidential Information in compliance with any applicable data
                        protection and privacy laws to protect the confidentiality, integrity and
                        availably of Customer Confidential Information consistent with such laws.
                        Veracode shall establish and maintain administrative, physical and technical
                        safeguards designed to guard against the destruction, loss, or alteration of
                        Customer Confidential Information in accordance with Veracode’s Information
                        Security Policy (VISP). Without limiting the foregoing, Veracode shall at
                        all times in connection with this Agreement: (i) maintain and enforce an
                        information security program including administrative, physical and
                        technical security policies and procedures with respect to its processing of
                        Customer Data and Customer Confidential Information consistent with
                        commercially reasonable industry practices and standards; (ii) provide
                        technical and organizational safeguards designed to protect against
                        accidental, unlawful or unauthorized access to or use, destruction, loss,
                        alteration, disclosure, transfer, commingling or processing of such
                        information and ensure a level of security appropriate to the risks
                        presented by the processing of such information and the nature of such
                        information, consistent with commercially reasonable industry practice and
                        standards; (iii) take commercially reasonable measures to secure the
                        Solution Platform against "hackers" and others who may seek, without
                        authorization, to disrupt, damage, modify, access or otherwise use the
                        Solution Platform or the information found therein; (iv) take commercially
                        reasonable measures to logically separate Customer Confidential Information
                        from that of other customers. Additionally, Veracode shall periodically test
                        and continuously monitor its systems for potential areas where security
                        could be breached and shall also periodically conduct security testing,
                        including penetration testing. Veracode shall be solely responsible for its
                        information technology infrastructure, including all computers, software,
                        databases, electronic systems and networks that are owned or controlled by
                        Veracode that may be used by Veracode to access Customer’s systems or
                        otherwise in connection with the Solutions. To the extent that Veracode
                        utilizes service providers or subcontractors in connection with the
                        performance of the Solutions, Veracode acknowledges that this provision
                        applies equally to any such service provider or subcontractor, such service
                        provider or subcontractor will possess a level of security and data
                        protection equal to Veracode and Veracode shall be responsible for such
                        service providers and subcontractors in accordance with the terms of this
                        Agreement.
                      </p>
                      <p>
                        <strong>4.4 Security Overview and Independent Attestation.</strong> At
                        Customer's request Veracode will, on an annual basis, furnish to Customer
                        (i) Veracode’s Information Security Exhibit (VISE) and (ii) its then current
                        independent SSAE16 SOC 2 attestation report.{' '}
                      </p>

                      <p>
                        <strong>
                          5. Veracode's Solution Representations and Warranties; Disclaimer.
                        </strong>{' '}
                        Veracode represents and warrants that the Solution provided to Customer will
                        be provided as described in the applicable Order Form, by qualified
                        personnel in a professional manner, and will comply in all material respects
                        with the applicable Documentation. In order to state a claim for breach of
                        the foregoing warranty, Customer must provide notice of such non-compliance
                        within the thirty (30) day period following such non-compliance (such as,
                        for example, within thirty (30) days from date of performance of the part a
                        particular Assessment or delivery of a Report with respect to an Assessment)
                        specifying the details of such noncompliance. If Customer timely provides
                        Veracode with the required notice, as Customer’s sole and exclusive remedy
                        and Veracode’s sole and exclusive liability for breach of warranty, Veracode
                        shall re-perform such portion of the Solution or otherwise use commercially
                        reasonable efforts to correct any such non-compliance, at its expense,
                        within thirty (30) days of its receipt of such notice. EXCEPT FOR THE
                        EXPRESS REPRESENTATIONS AND WARRANTIES STATED ABOVE, VERACODE DISCLAIMS ALL
                        OTHER REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE
                        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                        NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, VERACODE DOES NOT
                        GUARANTEE THAT IT WILL FIND ALL SECURITY VULNERABILITIES, RISKY CAPABILITIES
                        OR MALICIOUS CODE.
                      </p>
                      <p>
                        <strong>6. Intellectual Property Matters and Indemnity.</strong>
                      </p>
                      <p>
                        <strong>6.1 Veracode Indemnity.</strong>
                        Veracode shall defend, indemnify and hold Customer and its Affiliates and
                        their officers, directors and employees harmless from and against any and
                        all claims, suits, actual damages, costs and expenses, including reasonable
                        attorneys' fees, brought against or suffered by such Customer indemnified
                        parties arising out of a third party claim that (i) the Solution infringes
                        or violates any patent, copyright or trade secret or (ii) Veracode is not
                        the owner or licensee of any Veracode Property, including without limitation
                        the Solution and/or does not have the right, title and/or interest to grant
                        the license rights provided for herein and make available the Solution;
                        provided that, in each case, Veracode shall not be responsible for any claim
                        to the extent arising from or relating to solely (a) Customer’s unauthorized
                        use of the Solution; or (b) any Applications or any Customer Data used in
                        combination with the Solution if the claim would not have arisen but for
                        such combination.
                      </p>
                      <p>
                        <strong>6.2 Customer Indemnity.</strong>
                        Customer shall defend, indemnify and hold Veracode and its Affiliates and
                        their officers, directors and employees harmless from and against any and
                        all claims, suits, actual damages, costs and expenses, including reasonable
                        attorneys' fees, brought against or suffered by such Veracode indemnified
                        parties arising out of any third party claim that Customer and/or its
                        Affiliates (i) are not the owner or licensee of each Customer Application
                        and any Customer Data or (ii) do not have the right, title and/or interest
                        to grant the license rights provided for herein and to submit and make
                        available to Veracode each Customer Application and any Customer Data for
                        the purposes of allowing Veracode to provide the Solution and produce the
                        Solution Output.
                      </p>
                      <p>
                        <strong>6.3 Indemnity Process.</strong>
                        The indemnifying party shall conduct and control the defense and settlement
                        of any such claim; provided that the indemnified party shall have the right
                        to provide for its separate defense at its own expense. The indemnified
                        party shall give prompt notice of any claim for which indemnity is sought
                        and shall cooperate in defending against such claims at the indemnifying
                        party’s expense. The rights and remedies set forth in this Section 6 state
                        each party’s exclusive liability and exclusive rights and remedies with
                        regard to claims made by a third party for intellectual property
                        infringement or violation of a third party’s intellectual property rights.
                      </p>

                      <p>
                        <strong>7. Limitations and Exclusions of Liability and Damages.</strong>
                        EXCEPT FOR LIABILITY ARISING FROM (A) A PARTY’S BREACH OF ITS
                        CONFIDENTIALITY OBLIGATIONS PURSUANT TO SECTION 4 OF THIS AGREEMENT OR A
                        VIOLATION OF THE OTHER PARTY’S INTELLECTUAL PROPERTY RIGHTS OR (B) A PARTY’S
                        INDEMNIFICATION OBLIGATIONS PURSUANT TO SECTION 6 OF THIS AGREEMENT, TO THE
                        FULLEST EXTENT PERMITTED BY LAW, (I) NEITHER PARTY SHALL BE LIABLE TO THE
                        OTHER PARTY FOR ANY PUNITIVE, SPECIAL, CONSEQUENTIAL, INCIDENTAL OR OTHER
                        INDIRECT DAMAGES INCLUDING WITHOUT LIMITATION, LOST PROFITS OR LOSS OF OR
                        DAMAGE TO DATA OR SOFTWARE, EVEN IF THE PARTY HAS BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES AND (II) EACH PARTY’S AGGREGATE LIABILITY TO THE
                        OTHER FOR ALL CLAIMS ARISING OUT OF THIS AGREEMENT, WHETHER IN CONTRACT,
                        TORT OR OTHERWISE, SHALL NOT EXCEED THE TOTAL OF THE AMOUNTS PAID BY
                        CUSTOMER TO VERACODE PURSUANT TO THE APPLICABLE ORDER FORM UNDER WHICH THE
                        CLAIM AROSE. Without limiting the foregoing, except to the extent arising
                        from Veracode’s negligence or intentional misconduct, Veracode shall not
                        have any liability for losses, claims or damages for any harm or disruption
                        of Customer’s systems or applications arising out of the penetration tests
                        or simulated attacks which are provided by Veracode as part of the Solution
                        in accordance with the terms of this Agreement.
                      </p>

                      <p>
                        <strong>8. Use of Trademarks and Publicity.</strong>
                        Neither party shall have the right to use the other party’s name, logo,
                        trademark or other proprietary marks in connection with this Agreement,
                        except as expressly authorized in writing by the other party.
                      </p>
                      <p>
                        <strong>9. Term and Termination.</strong>
                      </p>
                      <p>
                        <strong>9.1 Term and Termination of Agreement.</strong>
                        This Agreement shall continue in effect until the later of (i) such time as
                        a party provides thirty (30) days prior written notice of termination if
                        there is no Order Form(s) then in effect, or (ii) the expiration of the last
                        to expire of any existing Order Forms in effect at the time of such notice
                        of termination (the “Term”).
                      </p>
                      <p>
                        <strong>9.2 Term and Termination of Order Form for Breach.</strong>
                        Each Order Form shall remain in effect for the term of the Order Form as
                        specified in the Order Form, and the Solution will be provided by Veracode
                        only during the term specified in the Order Form. An individual Order Form
                        shall not be subject to termination, except that an Order Form may be
                        terminated (in whole but not in part) by a party solely if the other party
                        fails to cure a material breach of such Order Form, or of this Agreement as
                        it relates to such Order Form, within thirty (30) days after receiving
                        written notice of the breach from the non-breaching party.
                      </p>
                      <p>
                        <strong>9.3 Effect of Termination and Destruction of Data.</strong>
                        Except for the Statistical Data, Veracode shall destroy using industry
                        standard methods (i) all copies of each Customer Application within sixty
                        (60) days following the availability of the Report related thereto or
                        earlier if requested by Customer and (ii) all copies of the results of the
                        Assessments of each Customer Application (excluding the Statistical Data)
                        and all associated documentation and related materials provided by Customer
                        within sixty (60) days following any termination or expiration of this
                        Agreement or earlier if requested by Customer; and upon request, Veracode
                        shall confirm such destruction in writing. Upon the expiration or
                        termination of any Order Form granting Customer access to On-Site Software,
                        Customer shall promptly destroy such On-Site Software. Upon any termination
                        or expiration of this Agreement, any provision which, by its nature, would
                        survive termination or expiration of this Agreement will survive and this
                        Agreement shall continue to apply to any pending Order Form(s).
                      </p>

                      <p>
                        <strong>10. Miscellaneous.</strong>
                      </p>
                      <p>
                        <strong>10.1 Insurance.</strong> Veracode shall maintain, at its expense, at
                        all times during this Agreement, insurance of such type and level as is
                        reasonable and prudent in the circumstances. Such insurance shall be carried
                        with responsible insurance companies of recognized standing which are
                        authorized to do business in the state in which the Solution is rendered and
                        are rated A- or better by A.M. Best. Upon Customer’s request, Veracode shall
                        furnish Customer with a certificate of insurance providing evidence of its
                        insurance coverages.
                      </p>
                      <p>
                        <strong>10.2 Governing Law.</strong> This Agreement will be governed by, and
                        construed in accordance with, the internal laws of Massachusetts, without
                        regard to its conflict of laws principles. The United Nations Convention on
                        Contracts for the International Sale of Goods does not apply to the
                        transactions contemplated by this Agreement. The Uniform Computer
                        Information Transactions Act (“UCITA”) will not apply to this Agreement
                        regardless of when and howsoever adopted, enacted and further amended under
                        the governing state laws.
                      </p>
                      <p>
                        <strong>10.3 Compliance with laws.</strong>
                        Each party shall comply with all applicable, laws and regulations in
                        connection with the performance of its obligations and the exercise of its
                        rights under this Agreement.{' '}
                      </p>
                      <p>
                        <strong>10.4 Force Majeure.</strong>
                        If the performance of any obligation hereunder is interfered with by reason
                        of any circumstances beyond a party’s reasonable control, including but not
                        limited to acts of God, labor strikes and other labor disturbances, power
                        surges or failures, or the act or omission of any third party, the party
                        shall be excused from such performance to the extent necessary, provided the
                        party shall use reasonable efforts to remove such causes of nonperformance.
                      </p>
                      <p>
                        <strong>10.5 Assignment and Related Matters.</strong>
                        Neither party may assign this Agreement, or any of its rights or obligations
                        hereunder (in whole or in part) except with the prior written consent of the
                        other party; provided, however that either party may assign this Agreement,
                        without the other party’s consent, in whole (but not in part) to a successor
                        in interest to the business of such party in connection with a merger, sale
                        of substantially all of its assets, change of control or by operation of
                        law, or to an Affiliate, provided that (i) the assignee agrees to assume the
                        obligations under this Agreement in writing and has adequate resources to
                        meet its obligations hereunder; (ii) the assignment shall not change the
                        scope of work to be performed under any Order Form then in effect ; and
                        (iii) the assignee is not a competitor of the non-assigning party. The terms
                        of this Agreement shall be binding upon the permitted successors and assigns
                        of each party. Veracode may use subcontractors in connection with the
                        performance of the Solution provided that Veracode shall be responsible for
                        the acts and omissions of its subcontractors to the same extent as Veracode
                        would be responsible hereunder for its own acts and omissions.
                      </p>
                      <p>
                        <strong>10.6 General.</strong>
                        The terms and conditions of this Agreement supersede all previous
                        agreements, proposals or representations related to the subject matter
                        hereof. This Agreement shall govern with respect to Customer’s use, access
                        and license of the Solution and any transactions relating to the Solution,
                        whether such licenses are purchased directly from Veracode or indirectly
                        through an authorized Veracode partner (including without limitation any
                        follow-on purchases or renewals) and shall apply to all Order Forms and
                        forms of purchases, whether submitted through electronic transmissions or
                        otherwise, unless otherwise agreed by both parties in writing. Unless the
                        Order Form expressly amends this Agreement and except as otherwise expressly
                        provided herein, the terms and conditions of this Agreement shall take
                        precedence over any conflicting terms in the Order Form. Any waiver,
                        amendment, or modification of any right or remedy, in whole or in part under
                        this Agreement, or any additional or different terms in purchase orders,
                        acknowledgments or other documents other than the Order Form, will not be
                        effective unless expressly agreed to by both parties in writing or
                        electronic form. Notices shall be submitted in writing to the address set
                        forth in the Order Form. The exchange of a signature by electronic means
                        shall be sufficient to bind the parties to this Agreement or to any Order
                        Form.
                      </p>
                    </div>
                  </section>
                </div>
                <div className="grid__item col-11-12 mt-">
                  <div className="grid">
                    <div className="grid__item mt-">
                      <label className="label--checkbox col-1-1 mb-- flex flex--align-items--center">
                        <input
                          type="checkbox"
                          name="acceptEula"
                          onChange={this.toggleAcceptEula}
                          checked={eulaChecked}
                        />
                        <span className="react--checkbox" />
                        <span className="font--h7">
                          I have read and agree to the Terms of Service.
                        </span>
                      </label>
                    </div>
                    <div className="grid__item mt- col-1-1 text--center">
                      <button
                        className="btn--primary pv- col-1-3"
                        disabled={!eulaChecked}
                        onClick={this.submitEula}
                      >
                        <span className="font--h7">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    myState: state.myState,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    meActions: bindActionCreators(meActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MasterServicesAgreementPage);
