import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import validator from 'validator';
import queryString from 'query-string';
import Select from 'react-select';

import config from '~/config';
import Helpers from '~/utils/Helpers';
import { COUNTRIES, PROVINCES, STATES, errorMessageTextMap } from '~/constants/ModelConstants';
import AcquisitionBanner from '~/components/AcquisitionBanner';
import ConfirmPasswordInputs from '~/containers/ConfirmPasswordInputs';

import * as signupActions from '~/actions/signup';
import * as loginActions from '~/actions/login';

interface OrgSignupPageProps extends RouteComponentProps {
  signupActions: object;
  loginActions: object;
  loginState: object;
  myState: object;
  passwordInputs: object;
  signupState: object;
  teamState: object;
  validOrgName?: boolean;
}

class OrgSignupPage extends React.Component<OrgSignupPageProps, any> {
  componentDidMount() {
    const { location, signupState, loginState, history } = this.props;
    const { signupFlowStage } = signupState;
    const { emailInput } = loginState;
    const { search } = location;
    const query = queryString.parse(search) || {};

    if (query.email) {
      const decodedURI = decodeURIComponent(query.email);
      if (query.saml) {
        this.updateSamlEmailValue({ target: { value: decodedURI } });
      } else {
        this.updateEmailInput({ target: { value: decodedURI } });
      }
    }

    if (query.step && query.step.toUpperCase() !== signupFlowStage) {
      if (!emailInput && !query.email) {
        history.push('/signup');
      } else {
        this.props.signupActions.updateSignupFlowStage(query.step.toUpperCase());
      }
    } else if (signupFlowStage && !query.step) {
      this.props.signupActions.updateSignupFlowStage(undefined);
    }
  }

  componentDidUpdate(nextProps) {
    const { loginState, location, signupState, myState, teamState, history } = nextProps;
    const { me = {} } = myState;
    const { teams = [] } = teamState;
    const { signupFlowStage } = signupState;
    const { search } = location;
    const query = queryString.parse(search) || {};
    const { emailInput } = loginState;

    if (me.id) {
      history.replace(teams.length ? '/portfolio' : '/no-workspaces');
    }

    if (query.step && query.step.toUpperCase() !== signupFlowStage) {
      if (!emailInput && !query.email) {
        history.push('/signup');
      } else {
        this.props.signupActions.updateSignupFlowStage(query.step.toUpperCase());
      }
    } else if (signupFlowStage && !query.step) {
      this.props.signupActions.updateSignupFlowStage(undefined);
    }
  }

  componentWillUnmount() {
    this.props.loginActions.resetState();
    this.props.signupActions.resetSignupState();
  }

  clearInputErrors() {
    this.props.loginActions.clearInputErrors();
  }

  submitSignUpForm(e) {
    e && e.preventDefault();
    const { loginState, signupState, myState, passwordInputs } = this.props;
    const {
      firstName,
      lastName,
      companyName,
      orgName,
      vanityName,
      inviteToken,
      location = {},
    } = signupState;
    const { msaAgree, emailInput } = loginState;
    const { orgInvite = {} } = myState;
    const orgSlug = Helpers.getOrgSlug();
    const { password, confirmPassword, passwordStrength = {} } = passwordInputs;
    const { score } = passwordStrength;

    const params = {
      email: emailInput,
      firstName,
      lastName,
      password,
      confirmPassword,
      msaAgree,
      inviteToken,
      location,
    };

    if (orgInvite.id) {
      params.inviteType = 'ORG';
    }

    if (orgSlug === 'app') {
      params.org = {
        companyName,
        slug: vanityName,
        name: orgName,
      };
    }

    if (!params.msaAgree) {
      this.props.loginActions.msaAgreeNotChecked();
      this.props.loginActions.signingUp(false);
    } else if (password.length < 8) {
      this.props.loginActions.updateSignupError({
        message: 'Passwords must be at least 8 characters long.',
      });
      this.props.loginActions.signingUp(false);
    } else if (score < 3) {
      this.props.loginActions.updateSignupError({
        message: "The password you typed needs to be 'Strong' or 'Excellent'.",
      });
      this.props.loginActions.signingUp(false);
    } else if (params.password !== params.confirmPassword) {
      this.props.loginActions.showMismatchingPasswordsError();
      this.props.loginActions.signingUp(false);
    } else {
      this.props.loginActions.signingUp(true);
      this.props.loginActions.submitSignUpForm(params);
    }
  }

  updateVanityName(e) {
    const { signupState } = this.props;
    const { vanityError } = signupState;
    const { value } = e.target;

    if (vanityError && !value.startsWith('-') && !value.endsWith('-')) {
      this.props.signupActions.updateVanityError(undefined);
    }

    this.props.signupActions.updateVanityName(value);
  }

  stringifyQuery(query = {}) {
    return Object.keys(query)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
      .join('&');
  }

  submitVanityName(e) {
    e.preventDefault();
    const { location, history } = this.props;
    const { search } = location;
    const query = queryString.parse(search) || {};
    const { value } = e.target.vanityName;

    if (value.startsWith('-') || value.endsWith('-')) {
      this.props.signupActions.updateVanityError('Domain cannot start or end with a dash.');
    } else {
      this.props.signupActions.submitVanityName(value).then(res => {
        if (res.available) {
          query.step = 'orgname';
          this.trackSignupStep('domain');
          history.push(`/signup?${this.stringifyQuery(query)}`);
        }
      });
    }
  }

  updateEmailInput(e) {
    const { value } = e.target;

    this.props.loginActions.updateEmailInput(value);
  }

  updateFirstName(e) {
    const { value } = e.target;

    this.props.signupActions.updateFirstName(value);
  }

  updateLastName(e) {
    const { value } = e.target;

    this.props.signupActions.updateLastName(value);
  }

  updateCompanyName(e) {
    const { value } = e.target;
    const { signupState } = this.props;
    const { orgName = '' } = signupState;

    this.props.signupActions.updateCompanyName(value);

    if (
      orgName.length === 0 ||
      orgName === value.slice(0, value.length - 1) ||
      value === orgName.slice(0, orgName.length - 1)
    ) {
      this.updateOrgName(e);
    }
  }

  updateOrgName(e) {
    const { value } = e.target;

    if (value.length <= 20) {
      this.props.signupActions.updateOrgName(value);
    }
  }

  submitSamlForm(e) {
    e.preventDefault();

    e.target.email.disabled = false;

    e.target.submit();
  }

  submitOrgName(e) {
    e.preventDefault();
    const { location, history } = this.props;
    const { search } = location;
    const query = queryString.parse(search) || {};
    const { value } = e.target.orgName;

    this.props.signupActions.submitOrgName(value).then(res => {
      if (res.available) {
        query.step = 'account';
        this.trackSignupStep('orgname');
        history.push(`/signup?${this.stringifyQuery(query)}`);
      }
    });
  }

  toggleMsaAgree() {
    this.props.loginActions.toggleMsaAgree();
  }

  signUpWithGithub(e) {
    e.preventDefault();

    const githubSignupForm = e.target;
    githubSignupForm.setAttribute('action', `${config.API_URL}/signin/github`);

    githubSignupForm.submit();
  }

  updateSamlEmailValue(e) {
    const { target = {} } = e;
    const { value } = target;
    const isValidEmail = validator.isEmail(value);

    this.props.loginActions.updateSamlEmailValue(value, isValidEmail);
  }

  submitEmailAddress(e) {
    e.preventDefault();
    const { location, history } = this.props;
    const { search } = location;
    const query = queryString.parse(search) || {};

    query.step = 'domain';
    this.trackSignupStep('email');
    history.push(`/signup?${this.stringifyQuery(query)}`);
  }

  trackSignupStep(step) {
    const { loginState, signupState } = this.props;
    const { companyName, orgName, vanityName } = signupState;
    const { emailInput } = loginState;
    const options = {
      ...(emailInput && { string2: emailInput }),
      ...(vanityName && { string3: vanityName }),
      ...(orgName && { string4: orgName }),
      ...(companyName && { string5: companyName }),
    };

    this.props.signupActions.trackSignupStep(step, options);
  }

  updateLocationSelection(selection, area) {
    this.props.signupActions.updateLocationSelection(selection, area);
  }

  render() {
    const { signupState, loginState, location } = this.props;
    const {
      firstName,
      lastName,
      vanityName = '',
      vanityError,
      signupFlowStage,
      companyName = '',
      orgName = '',
      orgNameError,
      inviteToken,
      location: locationSelection = {},
      validOrgName = true,
    } = signupState;
    const {
      emailInput = '',
      signupError = {},
      passwordMismatch,
      msaAgree,
      isSigningUp,
      samlEmailValue,
    } = loginState;
    const { status, message } = signupError;
    const { search } = location;
    const query = queryString.parse(search) || {};
    const { email, saml } = query;
    let errorToShow;
    const { country, region } = locationSelection;
    const orgNameValidationError =
      !validOrgName || orgNameError ? errorMessageTextMap.orgNameError : '';
    const shouldDisableButton =
      orgName.length < 3 || orgName.length > 20 || !!orgNameValidationError;

    if (status) {
      if (status === 409) {
        errorToShow = (
          <div className="color--danger mt-">
            The email address you entered is already associated with an account.{' '}
            <Link to="login" className="link--obvious">
              Sign in here
            </Link>
            .
          </div>
        );
      } else if (message === 'You must accept the Terms of Use.') {
        errorToShow = <div className="color--danger mt-">{message}</div>;
      } else if (message === 'Invalid Country/Region selection') {
        errorToShow = <div className="color--danger mt-">{message}</div>;
      } else {
        errorToShow = (
          <div className="color--danger mt-">
            Oops, something went wrong. Please try again in a moment.
          </div>
        );
      }
    } else if (passwordMismatch) {
      errorToShow = <div className="color--danger mt-">The passwords you typed do not match.</div>;
    } else if (message) {
      errorToShow = <div className="color--danger mt-">{message}</div>;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Sign Up</title>
        </Helmet>
        {/** SCAF-333 Showing Acquisition Banner on first page of the signup flow */}
        {!signupFlowStage && <AcquisitionBanner />}
        <div className="container flex align-items--center justify-content--center mt+">
          <div className="col-1-1 mb++">
            {!inviteToken && !signupFlowStage && (
              <div className="grid">
                <div className="grid__item col-3-10 flex align-items--center flex--justify-content--end">
                  <i className="sci sci__lock font--200 color--primary-light" />
                </div>
                <div className="grid__item col-2-5">
                  <div className="grid">
                    <div className="grid__item col-1-1">
                      <h1>Welcome!</h1>
                    </div>
                    <div className="grid__item col-1-1 mt- font--h7">
                      <span className="text--bold block">
                        Start your Veracode SourceClear free trial today!
                      </span>
                      Confidently use open source components without introducing unnecessary risk.
                      Sign-up for a 30-day free trial.
                    </div>
                    <div className="grid__item col--1-1 mt-">
                      <form className="grid" onSubmit={e => this.submitEmailAddress(e)}>
                        <div className="grid__item col-1-1">
                          <div className="flex position--relative align-items--center">
                            <input
                              type="email"
                              name="email"
                              required
                              className="col-1-1 control--text"
                              placeholder="Email address"
                              autoFocus
                              value={emailInput}
                              onChange={e => this.updateEmailInput(e)}
                            />
                            <div className="flex">
                              <i className="sci sci__check color--success position--relative left--neg-35 font--h7" />
                            </div>
                          </div>
                        </div>
                        <div className="grid__item col-1-1 mt--">
                          <button className="btn--primary col-1-3 pv-- font--h7">
                            Get started
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="grid__item col-1-1 mt--">
                      Already using SourceClear?{' '}
                      <Link to="/login" className="link--obvious">
                        Sign in
                      </Link>{' '}
                      or{' '}
                      <Link to="/find-my-org" className="link--obvious">
                        find your organization
                      </Link>
                      .
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!inviteToken && signupFlowStage === 'DOMAIN' && (
              <div className="grid">
                <div className="grid__item col-3-10 flex align-items--center justify-content--end">
                  <i className="sci sci__link font--150 color--primary-light" />
                </div>
                <div className="grid__item col-2-5 mt">
                  <div className="grid">
                    <div className="grid__item col-1-1">
                      <h3 className="font-family--simple">Choose your organization's URL</h3>
                    </div>
                    <div className="grid__item col-1-1 mt">
                      <div className="font--h7">Your organization will login at this address.</div>
                    </div>
                    <div className="grid__item col-1-1 mt-">
                      <form
                        className="grid grid--full"
                        name="vanityName"
                        onSubmit={e => this.submitVanityName(e)}
                      >
                        <div className="grid__item col-1-1 flex">
                          <div className="col-3-5 flex align-items--center">
                            <input
                              type="text"
                              name="vanityName"
                              className="col-1-1 font--h7 control--text ph-- text--right"
                              value={vanityName || ''}
                              onChange={e => this.updateVanityName(e)}
                              autoComplete="off"
                              autoFocus
                              required
                              placeholder="company"
                              pattern="[a-zA-Z0-9-]{2,19}"
                              title={errorMessageTextMap.orgNameError}
                            />
                          </div>
                          <div className="flex align-items--center font--h7 ph--- text--bold">
                            .
                            {config.FRONTEND_HOST_URL && config.FRONTEND_HOST_URL.split('/app.')[1]}
                          </div>
                        </div>
                        {vanityError && (
                          <div className="grid__item col-1-1 mt-- text--danger">{vanityError}</div>
                        )}
                        <div className="grid__item col-1-1 mt--">
                          Letters, numbers, and dashes only.
                        </div>
                        <div className="grid__item col-1-1 mt-">
                          <button className="btn--primary pv- ph col-1-4 flex align-items--center justify-content--center">
                            <div className="font--h7 pr-">Next</div>
                            <i className="sci sci__arrow--right" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!inviteToken && signupFlowStage === 'ORGNAME' && (
              <div className="grid">
                <div className="grid__item col-3-10 flex align-items--center justify-content--end">
                  <i className="sci sci__user--profile font--150 color--primary-light" />
                </div>
                <div className="grid__item col-2-5 mt">
                  <div className="grid">
                    <div className="grid__item col-1-1">
                      <h3 className="font-family--simple">Name your organization</h3>
                    </div>

                    <div className="grid__item col-1-1 mt-">
                      <form
                        className="grid grid--full"
                        name="orgName"
                        onSubmit={e => this.submitOrgName(e)}
                      >
                        <div className="grid__item col-1-1 mv--">
                          <div className="font--h7">Where do you work?</div>
                        </div>

                        <div className="grid__item col-1-1 mb">
                          <input
                            type="text"
                            className="col-1-1 control--text ph- font--h7"
                            name="companyName"
                            placeholder="Company name"
                            value={companyName || ''}
                            onChange={e => this.updateCompanyName(e)}
                            autoComplete="off"
                            autoFocus
                          />
                        </div>

                        <div className="grid__item col-1-1 mv-">
                          <div className="font--h7">
                            Choose an organization name that will appear throughout SourceClear. You
                            can always change it later.
                          </div>
                        </div>

                        <div className="grid__item col-1-1">
                          <input
                            type="text"
                            className="col-1-1 control--text ph- font--h7"
                            name="orgName"
                            placeholder="Organization name"
                            value={orgName || ''}
                            onChange={e => this.updateOrgName(e)}
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div
                          className={`grid__item col-1-1 mt-- ${
                            orgNameValidationError && 'text--bold color--danger'
                          }`}
                        >
                          {orgNameValidationError}
                        </div>
                        <div className="grid__item col-1-1 mt-">
                          <button
                            className="btn--primary pv- ph col-1-4 flex align-items--center justify-content--center"
                            disabled={shouldDisableButton}
                          >
                            <div className="font--h7 pr-">Next</div>
                            <i className="sci sci__arrow--right" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={`grid ${signupFlowStage !== 'ACCOUNT' && !inviteToken && 'hidden'}`}>
              <div className="grid__item col-3-10 flex align-items--center justify-content--end">
                <i className="sci sci__key font--150 color--primary-light" />
              </div>
              <div className="grid__item col-2-5">
                <div className={`grid ${saml && 'invisible'}`}>
                  <div className="grid__item col-1-1">
                    <h3 className="font-family--simple">Complete signup</h3>
                  </div>
                  <div className="grid__item col-1-1 mt">
                    <form
                      method="POST"
                      name="signup"
                      onSubmit={e => this.submitSignUpForm(e)}
                      className="grid"
                    >
                      {inviteToken && (
                        <div className="grid__item col-1-1">
                          <div className="grid">
                            <div className="grid__item col-1-1 flex position--relative align-items--center mt-- mb-">
                              <input
                                type="email"
                                className="col-1-1 font--h7 control--text ph-"
                                name="email"
                                placeholder="Email"
                                required
                                autoComplete="off"
                                value={emailInput || ''}
                                disabled={email && !saml}
                                readOnly={email && !saml}
                                onChange={e => this.updateEmailInput(e)}
                              />
                              <div className="flex">
                                <i className="sci sci__check color--success position--relative left--neg-35 font--h7" />
                              </div>
                              {email && !saml && (
                                <div className="flex">
                                  <i className="sci sci__lock color--muted position--relative left--neg-35 font--h7" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="grid__item col-1-1">
                        {signupFlowStage === 'ACCOUNT' && (
                          <div>
                            <input
                              type="text"
                              className="col-1-1 control--text ph- font--h7 mb-"
                              name="firstName"
                              placeholder="First name"
                              value={firstName || ''}
                              onChange={e => this.updateFirstName(e)}
                              autoComplete="off"
                              required
                              autoFocus
                            />
                            <input
                              type="text"
                              className="col-1-1 control--text ph- font--h7"
                              name="lastName"
                              placeholder="Last name"
                              value={lastName || ''}
                              onChange={e => this.updateLastName(e)}
                              autoComplete="off"
                              required
                            />
                            <ConfirmPasswordInputs
                              clearInputErrors={() => this.clearInputErrors()}
                              fontSizeClass="font--h7"
                            />
                            <div className="grid grid--narrow mt-">
                              <div className="grid__item col-1-2">
                                <Select
                                  className="regions srcclr-react-select-container"
                                  classNamePrefix={'srcclr-react-select'}
                                  options={COUNTRIES}
                                  placeholder="Select a country"
                                  isClearable={false}
                                  required
                                  value={COUNTRIES.find(option => option.value === country)}
                                  onChange={selection =>
                                    this.updateLocationSelection(selection.value, 'country')
                                  }
                                />
                              </div>
                              <div className="grid__item col-1-2">
                                <Select
                                  className="regions srcclr-react-select-container"
                                  classNamePrefix={'srcclr-react-select'}
                                  options={country === 'US' ? STATES : PROVINCES}
                                  placeholder="Region..."
                                  isDisabled={country !== 'US' && country !== 'CA'}
                                  required={country === 'US' || country === 'CA'}
                                  value={
                                    country === 'US'
                                      ? STATES.find(option => option.value === region)
                                      : PROVINCES.find(option => option.value === region)
                                  }
                                  isClearable={false}
                                  onChange={selection =>
                                    this.updateLocationSelection(selection.value, 'region')
                                  }
                                />
                              </div>
                              <div
                                className={`grid__item col-1-1 ${
                                  errorToShow ? 'is-showing-50' : 'is-hiding'
                                }`}
                              >
                                {errorToShow}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="grid__item col-1-1">
                        <label
                          htmlFor="msaAgree"
                          className="label--checkbox col-1-1 grid grid--middle grid--center grid--full mt-"
                        >
                          <div className="grid__item col-1-12 flex align-items--center justify-content--center">
                            <input
                              type="checkbox"
                              className="flex zIndex-9--overlay"
                              onChange={() => this.toggleMsaAgree()}
                              checked={msaAgree}
                              id="msaAgree"
                            />

                            <span className="react--checkbox" />
                          </div>
                          <div className="grid__item col-3-4 font--h8">
                            By creating an account I agree to the{' '}
                            <a
                              href="https://www.sourceclear.com/legal/terms-of-service"
                              target="_blank"
                              className="link--obvious"
                            >
                              Terms of Service
                            </a>
                            .
                          </div>
                        </label>
                      </div>
                      <div className="grid__item col-1-1">
                        <button
                          className="btn--primary font--h7 col-1-1 mt- pv-"
                          type="submit"
                          disabled={isSigningUp}
                        >
                          {isSigningUp ? 'Creating account...' : 'Sign Up'}
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="grid__item col-1-1 font--h8 mt color--muted">
                    Veracode, Inc. (“Veracode”) does not provide legal advice. Please be aware that
                    your use of the Veracode solution does not serve as a substitute for your
                    compliance with any applicable laws (including but not limited to any act,
                    statute, regulation, rule, directive, standard, policy, administrative order, or
                    executive order (collectively, (“Laws”)) or any contractual obligations with any
                    third parties. You are responsible for consulting an independent legal counsel
                    regarding any such Laws or contractual obligations. Use of the Veracode solution
                    does not serve as a substitute for your own assessment of business risks
                    associated with the software licenses identified by Veracode.
                  </div>
                </div>

                {saml && (
                  <div className="grid">
                    <div className="grid__item col-1-1 mt">
                      <h4 className="font-family--simple">Create a new account</h4>
                    </div>
                    <div className="grid__item col-1-1 mt-">
                      <form
                        onSubmit={e => this.submitSamlForm(e)}
                        action={`${config.API_URL}/signin/dispatch-to-idp`}
                        method="POST"
                        name="SAMLForm"
                      >
                        <div className="grid grid--full mt--">
                          <div className="grid__item col-1-1 flex position--relative align-items--center">
                            <input
                              type="email"
                              className="font--h7 control--text col-1-1"
                              name="email"
                              placeholder="Single-sign-on email"
                              required="true"
                              autoComplete="off"
                              disabled={email && saml}
                              readOnly={email && saml}
                              value={samlEmailValue || ''}
                              onChange={e => this.updateSamlEmailValue(e)}
                            />
                            {email && saml && (
                              <div className="flex">
                                <i className="sci sci__lock color--muted position--relative left--neg-35 font--h7" />
                              </div>
                            )}
                          </div>
                          <div className="grid__item col-1-1 mt--">
                            <button className="btn--primary font--h7 pv- col-1-1">
                              Sign up with SAML
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginState: state.loginState,
    myState: state.myState,
    passwordInputs: state.passwordInputs,
    signupState: state.signupState,
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
    signupActions: bindActionCreators(signupActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrgSignupPage));
