import React from 'react';
import Helmet from 'react-helmet';

import Helpers from '~/utils/Helpers';
import LoginPage from '~/containers/LoginPage';
import OrgLoginPage from '~/containers/OrgLoginPage';
import { RouteComponentProps } from 'react-router-dom';

interface LoginFlowForkProps extends RouteComponentProps {}

const LoginFlowFork: React.FunctionComponent<LoginFlowForkProps> = props => {
  const { location } = props;
  const orgSlug = Helpers.getOrgSlug();

  return (
    <div className="container flex align-items--center justify-content--center mt+">
      <Helmet>
        <title>Login</title>
      </Helmet>
      {orgSlug !== 'app' ? <LoginPage location={location} /> : <OrgLoginPage location={location} />}
    </div>
  );
};

export default LoginFlowFork;
