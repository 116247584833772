import React from 'react';

interface JiraProjectFormProps {
  project: object;
  handleInputChange: (...args: any[]) => any;
  index: number;
  removeProject: (...args: any[]) => any;
  handleFocusLost: (...args: any[]) => any;
  disabled: boolean;
}

const JiraProjectForm: React.FunctionComponent<JiraProjectFormProps> = props => {
  const { project, handleInputChange, index, removeProject, handleFocusLost, disabled } = props;
  let errors = [];

  if (project.errors && project.errors.length && project.saveAttempted) {
    errors = project.errors;
  }

  return (
    <div className="grid grid--narrow">
      <div className="grid__item col-1-1 mt-">
        <div className="bo-t--1 border-color--muted-light col-1-1" />
      </div>
      <div className="grid__item col-1-2">
        <label htmlFor="" className="mt-">
          Project Name
        </label>
      </div>
      <div className="grid__item">
        <label htmlFor="" className="mt-">
          Project ID
        </label>
      </div>
      <div className="grid__item">
        <label htmlFor="" className="mt-">
          IssueType ID
        </label>
      </div>
      <div className="grid__item col-1-18" />
      <div className="grid__item col-1-2">
        <input
          type="text"
          name="projectName"
          value={project.projectName || ''}
          onChange={handleInputChange.bind(null, index)}
          onBlur={handleFocusLost}
          className={
            'control--text col-1-1 ph--' +
            (errors.indexOf('projectName') >= 0 ? ' border-color--danger' : '')
          }
          disabled={disabled}
          placeholder="Project Name"
        />
      </div>
      <div className="grid__item">
        <input
          type="number"
          name="projectId"
          value={project.projectId || ''}
          onChange={handleInputChange.bind(null, index)}
          onBlur={handleFocusLost}
          className={
            'control--text col-1-1 ph--' +
            (errors.indexOf('projectId') >= 0 ? ' border-color--danger' : '')
          }
          disabled={disabled}
          placeholder="Project ID"
        />
      </div>
      <div className="grid__item">
        <input
          type="number"
          name="issueTypeId"
          value={project.issueTypeId || ''}
          onChange={handleInputChange.bind(null, index)}
          onBlur={handleFocusLost}
          className={
            'control--text col-1-1 ph--' +
            (errors.indexOf('issueTypeId') >= 0 ? ' border-color--danger' : '')
          }
          disabled={disabled}
          placeholder="IssueType ID"
        />
      </div>
      <div className="grid__item col-1-18 flex flex--align-items--center flex--justify-content--center">
        <div
          className={disabled ? 'color--muted' : 'color--danger cursor--pointer'}
          onClick={!disabled && removeProject.bind(null, index)}
        >
          <i className="sci sci__circle--subtract" />
        </div>
      </div>
      <div
        className={
          'grid__item col-1-1 color--danger' + (errors.length ? ' is-showing-50' : ' is-hiding')
        }
      >
        <p className="mt--">
          All fields are required.{' '}
          {(errors.indexOf('projectId') >= 0 || errors.indexOf('issueTypeId') >= 0) &&
            'Project ID and IssueType ID must be numbers.'}
        </p>
      </div>
    </div>
  );
};

export default JiraProjectForm;
