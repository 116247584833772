import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as expandableSectionActions from '~/actions/expandableSection';

interface ExpandableSectionProps {
  actions: object;
  expandableSectionState: object;
  hideWhenCollapsed?: boolean;
  showDefaultToggleControls?: boolean;
  className?: string;
  minHeightClass?: string;
  collapsedHeightClass?: string;
  isExpandedByDefault?: boolean;
  id?: string;
}
class ExpandableSection extends React.Component<ExpandableSectionProps, {}> {
  componentDidMount() {
    const { id: section, isExpandedByDefault = true } = this.props;
    this.props.actions.toggleSection(section, isExpandedByDefault);
  }

  toggleSection = section => {
    const { expandableSectionState = {}, isExpandedByDefault = true } = this.props;
    const { [section]: isExpanded = isExpandedByDefault } = expandableSectionState;

    this.props.actions.toggleSection(section, !isExpanded);
  };

  render() {
    const {
      expandableSectionState = {},
      id,
      children,
      className,
      isExpandedByDefault = true,
      minHeightClass = 'min-height--225',
      collapsedHeightClass = 'height--300',
      showDefaultToggleControls = true,
      hideWhenCollapsed = false,
    } = this.props;
    const { [id]: isExpanded = isExpandedByDefault } = expandableSectionState;

    return (
      <section className={className}>
        <div
          className={
            hideWhenCollapsed && !isExpanded ? 'is-hiding' : 'is-showing-400 overflow-y--scroll'
          }
        >
          <div
            className={
              isExpanded ? `${minHeightClass}` : `${collapsedHeightClass} overflow-y--hidden`
            }
          >
            {children}
          </div>
        </div>
        {showDefaultToggleControls && (
          <div
            className={`${
              isExpanded ? 'mt' : ''
            } bg-color--white-dark color--primary text--center text--bold p- cursor--pointer`}
            onClick={() => this.toggleSection(id)}
          >
            {isExpanded ? 'Read less' : 'Read more'}
          </div>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    expandableSectionState: state.expandableSectionState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(expandableSectionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableSection);
