import { useQuery } from 'react-query';
import config from '~/config';
import ApiService from '~/utils/ApiService';
import { veracodeApplicationsService } from '~/constants/ModelConstants';
import {ApplicationListPageParam} from "~/reducers/vcAppState/vcAppStateTypes/types";

export function useGetApplicationList(pageInfo : ApplicationListPageParam) {
  return useQuery(['applicationList', pageInfo], () =>
    (ApiService as any).get(
      `${config.VERACODE_UIGATEWAY_HOST}${veracodeApplicationsService}?size=${pageInfo.size}&page=${pageInfo.pageNumber}&name=${pageInfo.name}&sort=${pageInfo.sort}`
    ), {keepPreviousData: true}
  );
}
