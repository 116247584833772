import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';
import { Link, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import ConfirmPasswordInputs from '~/containers/ConfirmPasswordInputs';
import * as loginActions from '~/actions/login';

interface PasswordResetPageProps extends RouteComponentProps {
  loginActions: object;
  loginState: object;
  passwordInputs: object;
}

class PasswordResetPage extends React.Component<PasswordResetPageProps, any> {
  componentDidMount() {
    const { location = {}, history } = this.props;
    const { search } = location;
    const query = queryString.parse(search) || {};
    const { token } = query;

    if (token) {
      this.props.loginActions.storeResetToken(token);
      history.replace('/password-reset');
    } else {
      this.props.loginActions.missingResetToken();
    }
  }

  componentWillUnmount() {
    this.props.loginActions.resetState();
  }

  submitPasswordResetForm(e) {
    e.preventDefault();
    const { target = {} } = e;
    const { password, confirmPassword } = target;
    const { loginState, history, passwordInputs } = this.props;
    const { resetPasswordToken: token = '' } = loginState;
    const params = {
      password: password.value,
      confirmPassword: confirmPassword.value,
      token,
    };
    const { passwordStrength = {} } = passwordInputs;
    const { score } = passwordStrength;

    if (params.password !== params.confirmPassword) {
      this.props.loginActions.showMismatchingPasswordsError();
    } else if (password.length < 8) {
      this.props.loginActions.updateSignupError({
        message: 'Passwords must be at least 8 characters long.',
      });
    } else if (score < 3) {
      this.props.loginActions.updateSignupError({
        message: "The password you typed needs to be 'Strong' or 'Excellent'.",
      });
    } else {
      this.props.loginActions.submitPasswordResetForm(params).then(res => {
        if (res.success) {
          history.push('/login');
        }
      });
    }
  }

  clearInputErrors() {
    this.props.loginActions.clearInputErrors();
  }

  render() {
    const { loginState } = this.props;
    const {
      resetPasswordToken,
      passwordMismatch,
      invalidResetToken,
      resetPasswordFailed,
      signupError = {},
    } = loginState;
    const { message } = signupError;

    let errorToShow;

    if (passwordMismatch) {
      errorToShow = <div className="mt- color--danger">The passwords you typed do not match.</div>;
    } else if (message) {
      errorToShow = <div className="mt- color--danger">{message}</div>;
    }

    return (
      <div className="container flex align-items--center justify-content--center height--75vh">
        <Helmet>
          <title>Password Reset</title>
        </Helmet>
        <div className="grid grid--center col-2-5 mb++">
          {!resetPasswordToken && (
            <div className="grid__item col-1-1">
              <div className="panel panel--danger mt">
                <div className="panel__heading font--h6">Missing reset token</div>
                <div className="panel__body">
                  <p>
                    To reset your password, please follow the link in the reset password email. The
                    link is valid for 24 hours.{' '}
                    <Link className="link--obvious" to="forgot-password">
                      Need another email?
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          )}
          {invalidResetToken && (
            <div className="grid__item col-1-1">
              <div className="panel panel--danger mt">
                <div className="panel__heading font--h6">Invalid reset link</div>
                <div className="panel__body">
                  <p>
                    To reset your password, please follow the link in the reset password email. The
                    link is valid for 24 hours.{' '}
                    <Link className="link--obvious" to="forgot-password">
                      Need another link?
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          )}
          {resetPasswordFailed && (
            <div className="grid__item col-1-1">
              <div className="panel panel--danger mt">
                <div className="panel__heading font--h6">Reset password failed</div>
                <div className="panel__body">
                  <p>
                    Oops, something went wrong. To reset your password, please follow the link in
                    the reset password email. The link is valid for 24 hours.{' '}
                    <Link className="link--obvious" to="forgot-password">
                      Need another email?
                    </Link>{' '}
                    If you need additional help, please{' '}
                    <a
                      href="mailto:support@veracode.com?subject=Request For SourceClear Support"
                      className="link--obvious"
                    >
                      contact support
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="grid__item col-1-1 text--center">
            <h3>Security for open-source code.</h3>
          </div>
          <div className="grid__item col-1-1 mt">
            <form
              method="POST"
              name="passwordReset"
              onSubmit={e => this.submitPasswordResetForm(e)}
            >
              <div className="col-1-1 font--h7">Enter a new password</div>
              <ConfirmPasswordInputs
                errorToShow={errorToShow}
                clearInputErrors={() => this.clearInputErrors()}
                fontSizeClass="font--h6"
                autoFocus={true}
                passwordPlaceholderText="New password"
              />
              <button
                className="btn--primary font--h5 col-1-1 mt- pv-"
                type="submit"
                disabled={!resetPasswordToken}
              >
                Reset my password
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginState: state.loginState,
    passwordInputs: state.passwordInputs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);
