import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IGNORE_ISSUE_MODAL, IssueTypePretty } from '~/constants/ModelConstants';
import SourceClearModal from '~/components/SourceClearModal';
import { closeModal } from '~/actions/modal';
import Helpers from '~/utils/Helpers';
import { RootState } from '~/reducers';
import { Issue } from '~/components/ReportComponents/ReportIssue.types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toLastSecondOfDayUTC } from '~/utils/toLastSecondOfDayUTC';

const columnWidths = {
  id: 'col-1-8',
  type: 'col-1-7',
  libraryName: 'col-1-6',
  latestVersion: 'col-1-8',
  repoName: 'col-1-4',
};

interface IgnoreIssueModalProps {
  selectedIssues: Issue[];
  isIgnoring: boolean;
  onCancel?: () => void;
  onConfirm: (comment: string, suppressUntil: Date | undefined) => void;
}

const todayPlusDays = (daysToAdd: number): Date => {
  const newDate = new Date();
  newDate.setDate(newDate.getDate() + daysToAdd);
  return newDate;
};

const MAX_COMMENT_LENGTH = 250;

const IgnoreIssueModal = ({
  isIgnoring,
  selectedIssues,
  onCancel,
  onConfirm,
}: IgnoreIssueModalProps) => {
  const { teamState, modalState } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [ignoreDuration, setIgnoreDuration] = useState<Date>(null);
  const [ignoreOption, setIgnoreOption] = useState(IgnoreOption.FOREVER);
  const teams = teamState?.teams;
  const openedModal = modalState?.openedModal;
  const params: { teamId: string } = useParams();
  const teamId = params?.teamId;
  const issueCommentEnabled =
    Helpers.getActiveTeamById({ teams, teamId })?.permissions?.issueComments || false;
  const commentFormControlsStyle = issueCommentEnabled
    ? `ignore-issue-comment-form-controls`
    : `ignore-issue-form-controls ${isIgnoring ? '' : 'mt-25'}`;
  const isValid = () => {
    let valid = true;
    if (issueCommentEnabled && (comment.length === 0 || comment.length > MAX_COMMENT_LENGTH)) {
      valid = false;
    }
    if (ignoreOption === IgnoreOption.TEMPORARY && ignoreDuration == null) {
      valid = false;
    }
    return valid;
  };
  const handleCancelModal = () => {
    dispatch(closeModal(IGNORE_ISSUE_MODAL));
    // Don't call onCancel if parent didn't provide a callback
    if (onCancel != null) {
      onCancel();
    }
    resetState();
  };
  const handleConfirmIssues = () => {
    dispatch(closeModal(IGNORE_ISSUE_MODAL));
    const suppressUntil =
      ignoreOption === IgnoreOption.FOREVER ? undefined : toLastSecondOfDayUTC(ignoreDuration);
    onConfirm(comment, suppressUntil);
    resetState();
  };
  const resetState = () => {
    setComment('');
    setIgnoreOption(IgnoreOption.FOREVER);
    setIgnoreDuration(null);
  };
  useEffect(() => {
    if (ignoreOption === IgnoreOption.FOREVER) {
      setIgnoreDuration(null);
    }
  }, [ignoreOption]);
  return (
    <SourceClearModal
      isOpen={!!openedModal[IGNORE_ISSUE_MODAL]}
      title={isIgnoring ? 'Ignore Issues' : 'Unignore Issues'}
      onClose={() => handleCancelModal()}
      closeWhenClickOutside={false}
      width={700}
    >
      <div className="ignore-issues-modal">
        <h6 className="pt">
          You are {!isIgnoring && 'un'}
          ignoring the following issue(s):
        </h6>
        <h4 className="bo-b--1 border-color--white-dark pb-" />
        <IssueTable selectedIssues={selectedIssues} />
        {isIgnoring && (
          <IgnoreDurationControl
            ignoreDuration={ignoreDuration}
            ignoreOption={ignoreOption}
            handleIgnoreDuration={setIgnoreDuration}
            handleIgnoreOption={setIgnoreOption}
          />
        )}
        {issueCommentEnabled && <div className={`mb-5 ${isIgnoring ? '' : 'mt-25'}`}>Comment</div>}
        <div className={commentFormControlsStyle}>
          {issueCommentEnabled && (
            <div className="ignore-issue-comments">
              <textarea
                className="control--text border-color--muted ta-resize--none"
                name="comment"
                rows={5}
                placeholder="250 characters maximum"
                value={comment}
                onChange={event => setComment(event.target.value)}
              />
            </div>
          )}
          <div className="ignore-issue-controls">
            <button className="ph mr--" onClick={() => handleCancelModal()}>
              {' '}
              Cancel{' '}
            </button>
            <button
              className="btn--primary-clear ph"
              disabled={!isValid()}
              onClick={() => handleConfirmIssues()}
            >
              Confirm {isIgnoring ? 'ignore issue' : 'unignore issue'}
            </button>
          </div>
        </div>
        {issueCommentEnabled && comment?.length > MAX_COMMENT_LENGTH && (
          <div className="color--danger mt-">
            Comments cannot exceed {MAX_COMMENT_LENGTH} characters
          </div>
        )}
      </div>
    </SourceClearModal>
  );
};

interface IssueTableProps {
  selectedIssues: Issue[];
}

const IssueTable = ({ selectedIssues }: IssueTableProps) => {
  return (
    <>
      <div className="grid pv- col-1-1 overflow--auto ml0">
        <div className={`grid__item pl- text--bold ${columnWidths.id}`}>Issue ID</div>
        <div className={`grid__item text--bold ${columnWidths.type}`}>Issue Type</div>
        <div className={`grid__item text--bold ${columnWidths.libraryName}`}>Library Name</div>
        <div className={`grid__item text--bold ${columnWidths.latestVersion}`}>Latest Version</div>
        <div className={`grid__item text--bold ${columnWidths.repoName}`}>Repo Name</div>
        <div className=" grid__item text--bold">Branch/Tag</div>
      </div>

      <h4 className="bo-b--1 border-color--white-dark" />
      <div className="col-1-1 max-height--150 overflow-y--scroll">
        <IssueTableContent selectedIssues={selectedIssues} />
      </div>
    </>
  );
};

interface IssueTableContentProps {
  selectedIssues: Issue[];
}
const IssueTableContent = ({ selectedIssues }: IssueTableContentProps) => {
  return (
    <>
      {selectedIssues
        .filter(selectedIssue => selectedIssue)
        .map((selectedIssue, index) => (
          <div
            className="grid bo--b-1 border-color--white-dark pv- col-1-1 ml0"
            key={`${index}-${selectedIssue.id}`}
          >
            <div className={`grid__item pl- ${columnWidths.id}`}>{selectedIssue.id}</div>
            <div className={`grid__item  ${columnWidths.type}`}>
              {IssueTypePretty[selectedIssue.type]}
            </div>
            <div className={`grid__item  word-wrap--break-word ${columnWidths.libraryName}`}>
              {selectedIssue.libraryName}
            </div>
            <div className={`grid__item  word-wrap--break-word ${columnWidths.latestVersion}`}>
              {selectedIssue.libraryVersion}
            </div>
            <div className={`grid__item  word-wrap--break-word ${columnWidths.repoName}`}>
              {selectedIssue.repoName}
            </div>
            <div className=" grid__item  word-wrap--break-word">
              {selectedIssue.branch || selectedIssue.tag}
            </div>
          </div>
        ))}
    </>
  );
};

enum IgnoreOption {
  FOREVER,
  TEMPORARY,
}

interface IgnoreDurationControlProps {
  ignoreOption: IgnoreOption;
  handleIgnoreOption: (option: IgnoreOption) => void;
  ignoreDuration: Date;
  handleIgnoreDuration: (ignoreDuration: Date) => void;
}

const dateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const IgnoreDurationControl = (props: IgnoreDurationControlProps) => {
  const isForever = props.ignoreOption === IgnoreOption.FOREVER;
  const foreverRadioRef = useRef(null);
  const isTemporary = !isForever;
  const quickSelectOptions = [14, 30, 60, 90];
  const tomorrow = todayPlusDays(1);
  const handleQuickSelect = (days: number) => {
    props.handleIgnoreDuration(todayPlusDays(days));
  };
  let dateText = props.ignoreDuration?.toLocaleDateString(undefined, dateOptions);
  useEffect(() => {
    /*
    A hacky solution to fix a quirk with our design system
    Normally React doesn't interact with "checked" and instead controls it via state
    Because we have a default value of Ignore Forever, "checked" gets added to the DOM
    Our design system causes a visually checked radio to appear causing the radio to always appear
    checked even when it isn't. This operation removes "checked" from the element, so it works
    properly
     */
    foreverRadioRef.current.removeAttribute('checked');
  }, []);
  return (
    <div>
      <h6 className="pt">Ignore Duration:</h6>
      <label htmlFor="ignore-forever">
        <input
          type="radio"
          id="ignore-forever"
          name="ignore-duration"
          value={IgnoreOption.FOREVER}
          ref={foreverRadioRef}
          onChange={() => props.handleIgnoreOption(IgnoreOption.FOREVER)}
          checked={isForever}
        />
        <span className="control--radio" />
        Ignore Forever
      </label>
      <div className="ignore-issue-temporarily">
        <label htmlFor="ignore-temporarily">
          <input
            type="radio"
            id="ignore-temporarily"
            name="ignore-duration"
            value={IgnoreOption.TEMPORARY}
            onChange={() => props.handleIgnoreOption(IgnoreOption.TEMPORARY)}
            checked={isTemporary}
          />
          <span className="control--radio" />
          Ignore Temporarily
        </label>
        <DatePicker
          className="date-picker"
          selected={props.ignoreDuration}
          onChange={date => props.handleIgnoreDuration(date)}
          minDate={tomorrow}
          disabled={isForever}
          placeholderText="Select Date"
          dateFormat="MMMM d, yyyy"
        />
        <div className="quick-select">
          <span>Quick select days to ignore:</span>
          {quickSelectOptions.map(quickSelectOption => (
            <span
              key={quickSelectOption}
              className={isTemporary ? 'link--obvious' : 'disabled'}
              onClick={() => handleQuickSelect(quickSelectOption)}
            >{`${quickSelectOption} days`}</span>
          ))}
        </div>
      </div>
      <div className="min-height--20 mb-20" data-testid="ignore-until-text">
        {isTemporary && props.ignoreDuration != null && (
          <>The issue(s) will be ignored until {dateText} at 11:59PM UTC</>
        )}
      </div>
    </div>
  );
};

export default IgnoreIssueModal;
