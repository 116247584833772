import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ActiveStateTriangle from '~/components/ActiveStateTriangle';
import LoaderWrapper from '~/components/LoaderWrapper';
import { LOADER_TYPES } from '~/constants/ModelConstants';

interface SidebarNavOptionProps {
  option: object;
  fontSize?: string;
  verticalPaddingClass?: string;
  isStatsFetching?: boolean;
  separator?: boolean;
}

const SidebarNavOption: React.FunctionComponent<SidebarNavOptionProps> = props => {
  const { option, fontSize, verticalPaddingClass = 'pv-', isStatsFetching } = props;
  const { toLocation, title, count, isActive, separator = false } = option;
  const showCount = count || count === 0;

  return (
    <Fragment>
      {separator && <span className="left-nav-separator" />}
      <Link
        className={`col-1-1 link--no-underline hover-background--white-light flex align-items--end position--relative ${verticalPaddingClass} ${
          isActive && 'text--bold active-workspace'
        }`}
        to={toLocation}
      >
        <div className={`ph- ${fontSize}`} data-automation-id={`SidebarNavOption-${title}`}>
          {title}
        </div>
        <LoaderWrapper
          isLoaderShowing={isStatsFetching}
          loaderType={LOADER_TYPES.SPINNER}
          size="font--12"
        >
          {showCount && (
            <div className="text--muted-inactive font-family--roboto-medium">{count}</div>
          )}
        </LoaderWrapper>
        {isActive && <ActiveStateTriangle />}
      </Link>
    </Fragment>
  );
};

export default SidebarNavOption;
