import * as actions from '~/actions/teamGroups';
import produce from 'immer';
import { TeamGroupState } from '~/types/Groups';

const defaultState: TeamGroupState = {
  teamGroups: [],
  filteredTeamGroups: [],
  selectedGroups: {},
  showModal: undefined,
  quickViewsByGroupId: {},
};

const teamGroupsState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.UPDATE_TEAM_GROUPS:
        draft.teamGroups = action.groups;
        draft.filteredTeamGroups = action.groups;
        break;
      case actions.UPDATE_TEAM_GROUP_FILTER:
        draft.filteredTeamGroups = filterTeamGroups(state.teamGroups, action.value);
        break;
      case actions.UPDATE_SELECTED_GROUPS_IN_WORKSPACE:
        draft.selectedGroups[action.groupId] = !state.selectedGroups[action.groupId];
        break;
      case actions.RESET_SELECTED_GROUPS_IN_WORKSPACE:
        draft.selectedGroups = {};
        break;
      case actions.SHOW_TEAM_GROUP_MODAL:
        draft.showModal = action.modalType;
        break;
      case actions.TOGGLE_WORKSPACE_GROUP_USERS_QUICK_VIEW:
        draft.quickViewsByGroupId[action.groupId] =
          state.quickViewsByGroupId[action.groupId] === action.column ? 'OFF' : action.column;
        break;
      case actions.RESET_TEAM_GROUP_QUICK_VIEWS:
        draft.quickViewsByGroupId = {};
    }
  });
};
export default teamGroupsState;

function filterTeamGroups(groups, value) {
  const trimmedValue = value.trim().toLowerCase();
  return groups.filter(groupObj => {
    const { group = {} } = groupObj;
    const { name = '' } = group;
    const description = group.description || '';

    return (
      name.toLowerCase().includes(trimmedValue) || description.toLowerCase().includes(trimmedValue)
    );
  });
}
