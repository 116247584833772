import _ from 'lodash';
import PromiseThrottle from 'promise-throttle';
import React from 'react';

import config from '~/config';
import ApiService from '~/utils/ApiService';
import CsvHelper from '~/utils/CsvHelper';
import ReportResponseHelper from '~/utils/ReportResponseHelper';
import ApiConstants from '~/constants/ApiConstants';
import { reduxApiMap, PROJECT_TYPES } from '~/constants/ModelConstants';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import PdfHelper from '~/utils/PdfHelper';
import ErrorService from '~/utils/ErrorService';
import Helpers from '~/utils/Helpers';
import * as toastrActions from '~/actions/toastr';
import { Dispatch } from 'redux';

export const TOGGLE_ACTIVE_OR_SUPPRESSED = 'TOGGLE_ACTIVE_OR_SUPPRESSED';
export const REPORT_TOGGLE_DROPDOWN = 'REPORT_TOGGLE_DROPDOWN';
export const UPDATE_EXPANDED_QV_GENERIC_COLLAPSE = 'UPDATE_EXPANDED_QV_GENERIC_COLLAPSE';
export const DECREMENT_PAGE_TOTAL_ELEMENTS = 'DECREMENT_PAGE_TOTAL_ELEMENTS';

export const FETCH_REPORT_REQUEST = 'FETCH_REPORT_REQUEST';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';
export const REMOVE_REPORT_ISSUE_ITEM = 'REMOVE_REPORT_ISSUE_ITEM';
export const CLOSE_DROPDOWN = 'CLOSE_DROPDOWN';
export const RESET_REPORT_STATE = 'RESET_REPORT_STATE';

export const FETCH_REPORT_CSV_REQUEST = 'FETCH_REPORT_CSV_REQUEST';
export const FETCH_REPORT_CSV_SUCCESS = 'FETCH_REPORT_CSV_SUCCESS';
export const FETCH_REPORT_CSV_FAILURE = 'FETCH_REPORT_CSV_FAILURE';
export const FETCH_REPORT_CSV_COMPLETED = 'FETCH_REPORT_CSV_COMPLETED';
export const FETCH_REPORT_CSV_ATTEMPT = 'CSV_DOWNLOAD_ATTEMPT';

export const FETCH_REPORT_PDF_REQUEST = 'FETCH_REPORT_PDF_REQUEST';
export const FETCH_REPORT_PDF_SUCCESS = 'FETCH_REPORT_PDF_SUCCESS';
export const FETCH_REPORT_PDF_FAILURE = 'FETCH_REPORT_PDF_FAILURE';

export const toggleReportDropdown = dropdown => {
  return {
    type: REPORT_TOGGLE_DROPDOWN,
    toggleDropdown: dropdown,
  };
};

export const updateExpandedQVGenericCollapse = identifier => {
  return {
    type: UPDATE_EXPANDED_QV_GENERIC_COLLAPSE,
    identifier,
  };
};

export const resetReportState = () => ({
  type: RESET_REPORT_STATE,
});

export const createReportEndpoint = ({
  orgId,
  teamId,
  reportType,
  pageNum = 0,
  pageSize,
  isRequestingSuppressed,
  endpoint = '',
}: {
  orgId?: string;
  teamId?: string;
  reportType: string;
  pageNum?: number;
  pageSize: number;
  isRequestingSuppressed?: boolean;
  endpoint?: string;
}) => {
  let apiPath = reduxApiMap[reportType] && reduxApiMap[reportType].apiPath;

  if (teamId) {
    if (isRequestingSuppressed) {
      apiPath += '/suppressed';
    }

    return `/teams/${teamId}/reports/${apiPath}?page=${pageNum}&size=${pageSize}`;
  } else if (orgId) {
    return `${endpoint}?page=${pageNum}&size=${pageSize}`;
  }
};

export const buildSortQueryString = sorts => {
  let sortQuery = '';
  if (_.isEmpty(sorts)) {
    return sortQuery;
  }

  for (const prop of Object.keys(sorts)) {
    const value = sorts[prop];
    if (value) {
      sortQuery += `&sort=${prop},${value}`;
    }
  }

  return sortQuery;
};

export const scrubFilters = filters => {
  const scrubbedFilters = _.cloneDeep(filters);
  const { licenseRisks = [] } = scrubbedFilters;
  // LOW, MEDIUM, HIGH, and UNKNOWN are supported under the licenseRisks filter
  // but UNRECOGNIZED risk type requires a separate 'unlicensed' filter
  // we check to see if UNRECOGNIZED is selected
  // if so, we toggle 'unlicensed' filter to true
  // and then return all non-UNRECOGNIZED items from licenseRisks as payload for POST request
  const licenseRisksContainUnrecognized = licenseRisks.includes('UNRECOGNIZED');
  if (licenseRisksContainUnrecognized) {
    scrubbedFilters.unlicensed = true;
    scrubbedFilters.licenseRisks = licenseRisks.filter(risk => risk !== 'UNRECOGNIZED');
  }
  // TODO: refactor to remove this special case that is libraries page specific
  if (scrubbedFilters.languageType) {
    if (scrubbedFilters.languageType !== 'All languages') {
      if (scrubbedFilters.languageType.toUpperCase() === 'JAVASCRIPT') {
        scrubbedFilters.languageType = 'JS';
      } else {
        scrubbedFilters.languageType = scrubbedFilters.languageType.toUpperCase();
      }
    } else {
      delete scrubbedFilters.languageType;
    }
  } else if (scrubbedFilters.search) {
    // A hack that removes instances of CVE- prefix in the CVEs in `search` filter across reports
    // because BE expects no CVE- prefix when querying CVE ids
    scrubbedFilters.search = scrubbedFilters.search.replace(/CVE-/gi, ''); // Finds CVE-(digits) and remove CVE-
  }

  // scannedInPastWeek filter functionality is rolled into scanAfterDate and scanBeforeDate scopes
  // so no need to return this filter
  if (Helpers.hasScanDateEnabled()) {
    delete scrubbedFilters.scannedInPastWeek;
  }
  return scrubbedFilters;
};

// Handles fetching of Org level reports
export const fetchOrgReport = (reportType, pageNum?) => (dispatch: Dispatch, getState) => {
  const state = getState();
  const { reportFilterState, sortByReportType, myState, orgState, pageSizeState } = state;
  const { org = {} } = orgState;
  const { pageSize } = pageSizeState;
  const { id: orgId } = org;
  const portfolioLink = Helpers.getPortfolioLinkFromMyState(myState);

  const endpoint = createReportEndpoint({
    orgId,
    reportType,
    pageNum,
    pageSize,
    endpoint: portfolioLink,
  });
  const scopeForRequest = getScopeForRequest(state);

  const filters = reportFilterState[reportType] || {};
  const sorts = sortByReportType[reportType] || {};
  const scrubbedFilters = scrubFilters(filters);
  const sortsQueryString = buildSortQueryString(sorts);

  const options = {
    scope: scopeForRequest,
    filter: scrubbedFilters,
  };

  dispatch(fetchReportRequest(reportType));

  return ApiService.post(`${endpoint}${sortsQueryString}`, { data: options })
    .then(
      res => {
        dispatch(fetchReportSuccess(res, reportType));
        return res;
      },
      error => {
        dispatch(fetchReportFailure(error, reportType));
        return {};
      }
    )
    .catch(err => {
      dispatch(fetchReportFailure(err, reportType));
      return {};
    });
};

export const fetchReport = (
  teamId,
  reportType,
  { pageNum, projectIdFromParams }: { pageNum?: number; projectIdFromParams?: string } = {}
) => (dispatch: Dispatch, getState) => {
  const state = getState();
  const {
    reportFilterState,
    sortByReportType,
    reportScope = {},
    reportState,
    pageSizeState,
  } = state;
  const { activeOrSuppressed } = reportState;
  const { pageSize } = pageSizeState;
  const { repos = [] } = reportScope;
  const endpoint = createReportEndpoint({
    teamId,
    reportType,
    pageNum,
    pageSize,
    isRequestingSuppressed: activeOrSuppressed === 'SUPPRESSED',
  });

  const scopeForRequest = getScopeForRequest(state, projectIdFromParams);

  //If repos is an array, we assume it's a report request by a singular projectId (apparently)
  const projectId = projectIdFromParams || Array.isArray(repos) ? repos[0] : null;
  const filters =
    projectId && reportFilterState[projectId]
      ? reportFilterState[projectId][reportType] || {}
      : reportFilterState[reportType] || {};
  const sorts =
    projectId && sortByReportType[projectId]
      ? sortByReportType[projectId][reportType] || {}
      : sortByReportType[reportType] || {};
  const scrubbedFilters = scrubFilters(filters);
  const sortsQueryString = buildSortQueryString(sorts);

  const options = {
    scope: scopeForRequest,
    filter: scrubbedFilters,
  };

  dispatch(fetchReportRequest(reportType));

  return ApiService.post(`${endpoint}${sortsQueryString}`, { data: options })
    .then(
      res => {
        dispatch(fetchReportSuccess(res, reportType));
        return res;
      },
      error => {
        dispatch(fetchReportFailure(error, reportType));
        return {};
      }
    )
    .catch(err => {
      dispatch(fetchReportFailure(err, reportType));
      return {};
    });
};

export const toggleActiveOrSuppressed = activeOrSuppressed => ({
  type: TOGGLE_ACTIVE_OR_SUPPRESSED,
  activeOrSuppressed,
});

export const getReportCsvEndPoint = ({
  orgId,
  teamId,
  reportType,
  totalElements,
  endpoint = '',
}: {
  orgId?: string;
  teamId?: string;
  reportType: string;
  totalElements: number;
  endpoint?: string;
}) => {
  const maxPageSize: number = config.CSV_REQUEST_PAGE_SIZE;
  const totalPage = _.floor(totalElements / maxPageSize);
  const endPoints = [];
  for (let currentPageNum = 0; currentPageNum <= totalPage; currentPageNum++) {
    let size = maxPageSize; // by default, size param needs to be consistent when there is more than 1 page

    if (totalPage === 0) {
      size = totalElements; // size param can be equal to total elements when there's only one page
    }

    endPoints.push(
      createReportEndpoint({
        orgId,
        teamId,
        reportType,
        pageNum: currentPageNum,
        endpoint,
        pageSize: size,
      })
    );
  }

  return endPoints;
};

export const fetchReportsForCSV = (endpoint, reportType, options, dispatch: Dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const { sortByReportType } = state;

    const sorts = sortByReportType[reportType] || {};
    const sortsQueryString = buildSortQueryString(sorts);

    return ApiService.post(`${endpoint}${sortsQueryString}`, { data: options })
      .then(
        res => {
          dispatch(fetchReportForCsvSuccess(res, reportType));
          resolve();
        },
        error => {
          dispatch(fetchReportForCsvFailure(error, reportType));
          reject(error);
        }
      )
      .catch(err => {
        dispatch(fetchReportForCsvFailure(err, reportType));
        reject(err);
      });
  });

export const fetchOrgReportsForCSV = (orgId, reportType) => (dispatch: Dispatch, getState) => {
  const state = getState();
  const { reportsByType, reportFilterState, myState } = state;
  const portfolioLink = Helpers.getPortfolioLinkFromMyState(myState);

  const totalElements = ReportResponseHelper.getTotalElement(reportsByType[reportType], reportType);
  const endpoints = getReportCsvEndPoint({
    orgId,
    reportType,
    totalElements,
    endpoint: portfolioLink,
  });

  const filters = reportFilterState[reportType] || {};
  const scrubbedFilters = scrubFilters(filters);

  const scopeForRequest = getScopeForRequest(state);

  const options = {
    scope: scopeForRequest,
    filter: scrubbedFilters,
  };

  dispatch(fetchReportForCsvRequest(reportType));

  let apiRequest = [];
  const promiseThrottle = new PromiseThrottle({
    requestsPerSecond: config.CSV_REQUEST_RATE, // up to 1 request per second
    promiseImplementation: Promise, // the Promise library you are using
  });

  endpoints.forEach(endpoint => {
    const throttledRequest = promiseThrottle.add(
      fetchReportsForCSV.bind(this, endpoint, reportType, options, dispatch, getState)
    );
    apiRequest.push(throttledRequest);
  });

  return Promise.all(apiRequest)
    .then(() => {
      dispatch(fetchReportForCsvComplete(reportType));
      dispatch(startCSVDownload(reportType, undefined));
    })
    .catch(() => {
      _.noop();
    });
};

export const fetchTeamReportsForCSV = (teamId, projectId, reportType) => (
  dispatch: Dispatch,
  getState
) => {
  const state = getState();
  const { reportsByType, reportFilterState, orgState, teamState } = state;
  const { org = {} } = orgState;
  const { teams = [] } = teamState;
  const activeTeam = teams.find(team => team.id === teamId) || {};
  const totalElements = ReportResponseHelper.getTotalElement(reportsByType[reportType], reportType);
  const endpoints = getReportCsvEndPoint({ teamId, reportType, totalElements });

  const maybeProjectReportFilterState = projectId
    ? reportFilterState[projectId]
    : reportFilterState; // Get either project-specific report filters state or generic filters state
  const filters = maybeProjectReportFilterState[reportType] || {};
  const scrubbedFilters = scrubFilters(filters);

  const scopeForRequest = getScopeForRequest(state);

  const options = {
    scope: scopeForRequest,
    filter: scrubbedFilters,
  };

  dispatch(fetchReportForCsvRequest(reportType));

  let apiRequest = [];
  const isPoliciesEnabled = FeatureFlagHelper.isFeatureEnabledForOrgAndTeam(
    'policies',
    org,
    activeTeam
  );
  const promiseThrottle = new PromiseThrottle({
    requestsPerSecond: config.CSV_REQUEST_RATE, // up to 3 request per second
    promiseImplementation: Promise, // the Promise library you are using
  });

  endpoints.forEach(endpoint => {
    const throttledRequest = promiseThrottle.add(
      fetchReportsForCSV.bind(this, endpoint, reportType, options, dispatch, getState)
    );

    apiRequest.push(throttledRequest);
  });

  return Promise.all(apiRequest)
    .then(() => {
      dispatch(fetchReportForCsvComplete(reportType));
      dispatch(startCSVDownload(reportType, isPoliciesEnabled));
    })
    .catch(() => {
      _.noop();
    });
};

export const startCSVDownload = (reportType, isPoliciesEnabled: boolean) => (
  dispatch,
  getState
) => {
  const state = getState();

  const { reportsByTypeCSV = {} } = state;
  const { csvData = {} } = reportsByTypeCSV;
  const data = csvData[reportType];
  CsvHelper.startCSVDownloadForReport(
    data,
    reportType,
    isPoliciesEnabled,
    Helpers.hasPolicyRiskEnabled()
  );
};

export const fetchReportRequest = reportType => ({
  type: FETCH_REPORT_REQUEST,
  reportType,
});

export const fetchReportSuccess = (response, reportType) => ({
  type: FETCH_REPORT_SUCCESS,
  response,
  reportType,
});

export const fetchReportFailure = (error, reportType) => ({
  type: FETCH_REPORT_FAILURE,
  reportType,
  message: error.message || 'Something went wrong. Please try again.',
});

export const fetchReportsForCsvAttemptSnowplow = (teamId, projectId, inputId) => ({
  type: FETCH_REPORT_CSV_ATTEMPT,
  teamId,
  projectId,
  inputId,
  meta: {
    snowplow: true,
    string1: inputId,
    string2: projectId,
    string3: teamId,
  },
});

export const fetchReportForCsvRequest = reportType => ({
  type: FETCH_REPORT_CSV_REQUEST,
  reportType,
});

export const fetchReportForCsvSuccess = (response, reportType) => ({
  type: FETCH_REPORT_CSV_SUCCESS,
  response,
  reportType,
});

export const fetchReportForCsvComplete = reportType => ({
  type: FETCH_REPORT_CSV_COMPLETED,
  reportType,
});

export const fetchReportForCsvFailure = (error, reportType) => ({
  type: FETCH_REPORT_CSV_FAILURE,
  message: error.message || 'Something went wrong. Please try again.',
  reportType,
});

export const removeReportIssueItem = (reportType, id) => {
  return {
    type: REMOVE_REPORT_ISSUE_ITEM,
    reportType,
    id,
  };
};

export const closeDropdown = dropdownId => ({
  type: CLOSE_DROPDOWN,
  dropdownId,
});

export const decrementPageTotalElements = (reportType, decrementBy = 1) => ({
  type: DECREMENT_PAGE_TOTAL_ELEMENTS,
  reportType,
  decrementBy,
});

/**
 * getScopeForRequest
 *
 * reportScope provides the agent, path, repos, isFavouriteRepo and defaultBranch context while
 * repoScope provide which branch/tag. Method combine them all into a single object as a scope request
 */
export const getScopeForRequest = (state, projectIdFromParams?: string) => {
  const {
    reportScope = {},
    repoScope = {},
    repoDataById = {},
    workspaceScanDateState: { scopeScansToAllDates },
  } = state;

  const scopeForRequest = _.cloneDeep(reportScope);

  // state does not change in time for shared pages to render correctly in time (see IssuesPage).
  // pull from params instead
  if (projectIdFromParams) {
    scopeForRequest.repos = [projectIdFromParams];
  }
  if (_.isArray(scopeForRequest.repos) && scopeForRequest.repos.length === 1) {
    const repoId = scopeForRequest.repos[0];
    const { [repoId]: repo } = repoDataById;

    if (repo?.type.toUpperCase() === PROJECT_TYPES.CONTAINER) {
      scopeForRequest.refs = {}; // For requests made by specific project(or repo) which type is CONTAINER, set refs to empty
    } else {
      scopeForRequest.refs = {
        [repoId]: repoScope,
      };
    }
  }

  // at project details level, we always want to fetch all issues for all dates
  // so we exclude scanBeforeDate and scanAfterDate scopes from the request
  // only return currently supported scopes if FF is not enabled
  if (!Helpers.hasScanDateEnabled() || (Helpers.hasScanDateEnabled() && scopeScansToAllDates)) {
    return _.pick(scopeForRequest, [
      'agents',
      'paths',
      'repos',
      'favoriteReposOnly',
      'repoScanId',
      'refs',
    ]);
  }
  return scopeForRequest;
};

/**
 * PDF Download actions
 */

export const fetchReportPDFRequest = teamId => ({
  type: FETCH_REPORT_PDF_REQUEST,
  teamId,
});

export const fetchReportPDFSuccess = teamId => ({
  type: FETCH_REPORT_PDF_SUCCESS,
  teamId,
});

export const fetchReportPDFFailure = (teamId, error) => ({
  type: FETCH_REPORT_PDF_FAILURE,
  teamId,
  message: error.message || 'Something went wrong',
});

export const startPdfDownload = (blobData, teamId) => (dispatch: Dispatch, getState) => {
  const state = getState();
  const { teamState = {} } = state;
  const { teams = [] } = teamState;
  const team = teams.find(team => team.id === teamId);
  const { name: teamName } = team;

  PdfHelper.startPdfDownload(blobData, teamName);
};

export const addPDFWarningToastr = ({ teamId, repoName, repoDefaultBranchCount, repoCount }) => {
  const numProjectsUsingLatestScan = repoCount - repoDefaultBranchCount;
  const projectGrammar = repoCount === 1 ? 'Project' : 'Projects';
  return toastrActions.addToastr({
    id: teamId.toUpperCase() + '_NO_REPO_DEFAULT_BRANCH',
    level: 'warning',
    title: `Report Uses Latest Scan For ${numProjectsUsingLatestScan} of ${repoCount} ${projectGrammar}`,
    message: (
      <div>
        The report you generated for workspace <strong>{repoName}</strong> uses the latest scan for
        projects which do not have a default branch set. To set a project’s default branch, go to
        project settings.
      </div>
    ),
    disableTimeout: true,
  });
};

export const downloadPDF = ({ teamId, repoName, repoCount, repoDefaultBranches }) => (
  dispatch: Dispatch
) => {
  dispatch(fetchReportPDFRequest(teamId));
  ApiService.get(ApiConstants.pdfReportURL(teamId))
    .then(res => {
      const repoDefaultBranchCount = Object.keys(repoDefaultBranches).length;
      if (repoDefaultBranchCount !== repoCount) {
        dispatch(
          addPDFWarningToastr({
            teamId,
            repoName,
            repoDefaultBranchCount,
            repoCount,
          })
        );
      }

      dispatch(fetchReportPDFSuccess(teamId));
      dispatch(startPdfDownload(res, teamId));
    })
    .catch(error => {
      // show toast when we get 400 error
      if (error.status === 400) {
        let fr = new FileReader();
        fr.readAsText(error);

        //FileReader runs asynchronously... so dispatching a toastr needs to be in an onload function.
        fr.onload = function (e) {
          const { target = {} } = e;
          const { result = '' } = target;
          if (result === "This workspace doesn't have any supported scans to report on.\r\n") {
            dispatch(
              toastrActions.addToastr({
                id: 'ERROR_DOWNLOADING_PDF',
                level: 'error',
                title: 'Error downloading PDF',
                message:
                  error.message ||
                  'To generate a report, first set the default branch for each project in this workspace. To set the default branch, navigate to a project and click on Settings.',
                disableTimeout: true,
              })
            );
          }
        };
      }
      ErrorService.capture('Error downloading PDF', error);
      dispatch(fetchReportPDFFailure(teamId, error));
    });
};
