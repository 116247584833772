export const UPDATE_PROJECT_SETTINGS_PAGE = 'UPDATE_PROJECT_SETTINGS_PAGE';
export const UPDATE_PROJECT_SETTINGS_STAGE = 'UPDATE_PROJECT_SETTINGS_STAGE';
export const SHOW_PROJECT_SETTINGS_MODAL = 'SHOW_PROJECT_SETTINGS_MODAL';
export const CLOSE_PROJECT_SETTINGS_MODAL = 'CLOSE_PROJECT_SETTINGS_MODAL';

export const updateProjectSettingsPage = page => ({
  type: UPDATE_PROJECT_SETTINGS_PAGE,
  page,
});

export const updateProjectSettingsStage = stage => ({
  type: UPDATE_PROJECT_SETTINGS_STAGE,
  stage,
});

export const showProjectSettingsModal = () => ({
  type: SHOW_PROJECT_SETTINGS_MODAL,
});

export const closeProjectSettingsModal = () => ({
  type: CLOSE_PROJECT_SETTINGS_MODAL,
});
