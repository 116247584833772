export const ADD_TO_CLIPBOARD_FAILURE = 'ADD_TO_CLIPBOARD_FAILURE';
export const ADD_TO_CLIPBOARD_SUCCESS = 'ADD_TO_CLIPBOARD_SUCCESS';
export const REMOVE_FROM_CLIPBOARD_FAILURE = 'REMOVE_FROM_CLIPBOARD_FAILURE';
export const REMOVE_FROM_CLIPBOARD_SUCCESS = 'REMOVE_FROM_CLIPBOARD_SUCCESS';

export const addToClipboardSuccess = inputId => ({
  type: ADD_TO_CLIPBOARD_SUCCESS,
  inputId,
  meta: {
    snowplow: true,
    string1: inputId,
  },
});

export const removeFromClipboardSuccess = inputId => ({
  type: REMOVE_FROM_CLIPBOARD_SUCCESS,
  inputId,
});

export const addToClipboardFailure = inputId => ({
  type: ADD_TO_CLIPBOARD_FAILURE,
  inputId,
  meta: {
    snowplow: true,
    string1: inputId,
  },
});

export const removeFromClipboardFailure = inputId => ({
  type: REMOVE_FROM_CLIPBOARD_FAILURE,
  inputId,
});

export const copySuccess = inputId => dispatch => {
  dispatch(addToClipboardSuccess(inputId));
  setTimeout(() => {
    dispatch(removeFromClipboardSuccess(inputId));
  }, 2500);
};

export const copyError = inputId => dispatch => {
  dispatch(addToClipboardFailure(inputId));
  setTimeout(() => {
    dispatch(removeFromClipboardFailure(inputId));
  }, 5000);
};
