import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as MODEL from '~/constants/ModelConstants';

import SourceClearModal from '~/components/SourceClearModal';
import SourceClearLoader from '~/components/SourceClearLoader';
import * as integrationActions from '~/actions/integration';
import * as integrationModalActions from '~/actions/integrationModal';
import * as teamActions from '~/actions/team';
import * as reportSelectedRowsActions from '~/actions/reportSelectedRows';
import { IntegrationState } from '~/reducers/integrationState.types';

interface ConfirmDeleteModalProps {
  integrationActions: object;
  integrationState: IntegrationState;
  integrationModalActions: object;
  integrationModalState: object;
  reportSelectedRowsActions: object;
  reportSelectedRows: any[];
  teamActions: object;
  orgState: App.OrgState;
}
class ConfirmDeleteModal extends React.Component<ConfirmDeleteModalProps, {}> {
  onClose = () => {
    this.props.integrationModalActions.closeIntegrationModal();
  };

  handleDeleteIntegration = () => {
    const { reportSelectedRows } = this.props;

    const jiraCloudRows = reportSelectedRows.filter(
      row => row.type === 'JIRA' || row.type === 'JIRA_PREM'
    );
    if (jiraCloudRows.length > 0) {
      this.props.integrationActions.deleteIntegration(jiraCloudRows);
    }

    const githubRows = reportSelectedRows.filter(row => row.type === 'Github');
    githubRows.forEach(row => this.props.teamActions.toggleDisableGithubIssues(true, row.team.id));

    const jiraEnterpriseRow = reportSelectedRows.filter(row => row.type === 'JIRA Enterprise');
    jiraEnterpriseRow.forEach(row => this.props.teamActions.deleteJiraEnterprise(row.team.id));

    this.props.reportSelectedRowsActions.clearSelectedRows();
    this.props.integrationModalActions.closeIntegrationModal();
  };

  render() {
    const { integrationModalState, reportSelectedRows, integrationState } = this.props;
    const { openedModal } = integrationModalState;
    const { deleteIntegrationRequest = {} } = integrationState;
    const { isDeleting = false } = deleteIntegrationRequest;

    const integrationToDeleteRow = reportSelectedRows.map(row => {
      const name = row.name === 'Github' ? 'GitHub' : row.name;
      return (
        <li className="font--h7 color--black-light" key={row.id}>
          {name} {row.serviceUrl && `- ${row.serviceUrl}`}{' '}
          {row.team && `for workspace "${row.team.name}"`}
        </li>
      );
    });

    return (
      <SourceClearModal
        isOpen={!!openedModal[MODEL.DELETE_INTEGRATION_MODAL]}
        title="Confirm Delete Integration"
        onClose={this.onClose}
        width={600}
      >
        <div className="grid color--black-light pt-">
          <div className="grid__item col-1-1 font--h7">
            <strong>
              {' '}
              Integration
              {reportSelectedRows.length === 1 ? '' : 's'} to be deleted:{' '}
            </strong>
            <ul className="pt--"> {integrationToDeleteRow} </ul>
          </div>

          {reportSelectedRows.filter(row => row.type === 'JIRA').length > 0 && (
            <div className="grid__item col-1-1 font--h7 pt">
              By deleting a {MODEL.INTEGRATION_NAME_MAP.JIRA_CLOUD} integration, it will delete all
              issue templates for the integration along with any JIRA links that SourceClear has
              stored, including links from SourcClear issues to JIRA issues
              <strong> It cannot be undone.</strong> Are you sure?
            </div>
          )}

          <div className="col-1-1 flex flex--justify-content--end align-items--center mt+">
            {isDeleting ? (
              <div className="text--center font--h7">
                Deleting...
                <SourceClearLoader />
              </div>
            ) : (
              <div className="col-1-2 text--right">
                <button className="ph mr-" onClick={() => this.onClose()}>
                  {' '}
                  Cancel{' '}
                </button>
                <button
                  onClick={() => this.handleDeleteIntegration()}
                  className="btn--danger-clear ph"
                >
                  Delete Integration
                </button>
              </div>
            )}
          </div>
        </div>
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationState: state.integrationState,
    integrationModalState: state.integrationModalState,
    reportSelectedRows: state.reportSelectedRows,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
    integrationActions: bindActionCreators(integrationActions, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
    reportSelectedRowsActions: bindActionCreators(reportSelectedRowsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteModal);
