import React from 'react';
import Select from 'react-select';

interface AddSelectedUsersToGroupProps {
  createNewGroupForUsers?: boolean;
  createGroups?: boolean;
  groupData?: object;
  buttons: React.ReactNode;
  selectableGroups: any[];
  selectedGroup?: boolean | object;
  selectedUsers: any[];
  updateSelectedGroup: (...args: any[]) => any;
  toggleAdminField: (...args: any[]) => any;
  toggleCreateNewGroup: (...args: any[]) => any;
  updateGroupData: (...args: any[]) => any;
}

const AddSelectedUsersToGroup: React.FunctionComponent<AddSelectedUsersToGroupProps> = props => {
  const {
    selectableGroups = [],
    selectedGroup,
    selectedUsers = [],
    createNewGroupForUsers = false,
    groupData = {},
    createGroups,
  } = props;
  const { name = '', description = '', error } = groupData;

  const addNewOrChooseTeam = createNewGroupForUsers ? 'Add to New Team' : 'Choose Team';
  const addExistingCreateNewTeam = createNewGroupForUsers
    ? 'Add to existing team'
    : 'Create new team';

  function updateSelectedGroup(group) {
    if (selectedGroup && selectedGroup.value) {
      props.updateSelectedGroup(selectedGroup.value);
    }

    if (group) {
      props.updateSelectedGroup(group.value);
    }
  }

  function toggleAdminField(userId) {
    props.toggleAdminField(userId);
  }

  function updateGroupData(field, e) {
    const { target = {} } = e;
    const { value = '' } = target;

    props.updateGroupData(field, value);
  }

  function toggleCreateNewGroup() {
    props.toggleCreateNewGroup();
  }

  return (
    <div className="grid">
      <div className="grid__item col-1-1 flex justify-content--space-between align-items--end">
        <div>
          <strong className="font--h6">{addNewOrChooseTeam}</strong>
        </div>
        {createGroups && (
          <div className="link--obvious" onClick={() => toggleCreateNewGroup()}>
            {addExistingCreateNewTeam}
          </div>
        )}
      </div>
      <div className="grid__item col-1-1 mt-">
        {createNewGroupForUsers ? (
          <div className="grid">
            <div className="grid__item col-1-3 flex align-items--center">Team Name</div>
            <div className="grid__item col-2-3">
              <input
                type="text"
                className="control--text col-1-1"
                placeholder="Name"
                value={name}
                onChange={e => updateGroupData('name', e)}
              />
            </div>
            <div
              className={`grid__item col-1-1 color--danger ${
                error ? 'is-showing-50' : 'is-hiding'
              }`}
            >
              <div className="mt--">This team name is already taken. Please choose another.</div>
            </div>
            <div className="grid__item col-1-3 mt- flex align-items--center">Description</div>
            <div className="grid__item col-2-3 mt-">
              <input
                type="text"
                className="control--text col-1-1"
                placeholder="Description (optional)"
                value={description}
                onChange={e => updateGroupData('description', e)}
              />
            </div>
          </div>
        ) : (
          <Select
            options={selectableGroups}
            name="group"
            value={selectableGroups.find(option => option.value === selectedGroup)}
            isClearable={true}
            onChange={group => updateSelectedGroup(group)}
            className={'srcclr-react-select-container'}
            classNamePrefix={'srcclr-react-select'}
          />
        )}
      </div>
      <div className="grid__item col-1-1 mt">
        <div>
          <strong className="font--h6">Choose Team Administrators</strong> (optional)
        </div>
        <div className="mt-">
          Users can be members or administrators of a team. Team administrators can change the team
          name and description, invite new users to the organization, add users to a team, remove
          users, and make other members team administrators.
        </div>
      </div>
      <div className="grid__item col-1-1 mt-">
        <div className="bo--1 border-color--black-light pv-">
          <div className="grid grid--full">
            <div className="grid__item col-1-2 pl-">
              <strong>User Name</strong>
            </div>
            <div className="grid__item col-1-2 text--center">
              <strong>User Can Administer Team</strong>
            </div>
          </div>
          <div className="max-height--150 overflow--auto">
            {selectedUsers.map(userObj => {
              const { user = {} } = userObj;
              return (
                <div className="grid grid--full pv-- hover-row" key={`selected-${user.id}`}>
                  <div className="grid__item col-1-2 pl-">{user.email}</div>
                  <div className="grid__item col-1-2 flex align-items--center justify-content--center">
                    <label className="label--checkbox justify-content--center flex mb0">
                      <input
                        type="checkbox"
                        checked={user.stagedAdminStatus || false}
                        onChange={() => toggleAdminField(user.id)}
                      />
                      <span className="react--checkbox mr0" />
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {props.buttons}
    </div>
  );
};

export default AddSelectedUsersToGroup;
