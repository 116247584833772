import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import UserManagementQuickViewWrapper from '~/containers/UserManagementQuickViewWrapper';
import UserGroupsQuickView from '~/components/UserGroupsQuickView';
import * as orgUserActions from '~/actions/orgUser';

interface UserWorkspacesListProps extends RouteComponentProps {
  hasAdminPerms: boolean;
  showGroups: boolean;
  orgUserActions: object;
  orgUserState: object;
}
class UserWorkspacesList extends React.Component<UserWorkspacesListProps, {}> {
  componentDidMount() {
    const { match } = this.props;
    const { params = {} } = match;
    const { userId } = params;

    this.props.orgUserActions.fetchUserWorkspaces(userId);
  }

  handleFilterInput = e => {
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.orgUserActions.filterUserWorkspaces(value);
  };

  toggleQuickView = (workspaceId, column) => {
    this.props.orgUserActions.toggleUserWorkspacesQuickView(workspaceId, column);
  };

  render() {
    const { hasAdminPerms, showGroups, orgUserState, match } = this.props;
    const { params = {} } = match;
    const { userId, teamId } = params;
    const columnWidths = {
      filler: 'col-1-4',
      select: 'col-1-12',
      name: 'col-1-3',
    };
    const {
      userWorkspaces = [],
      filteredUserWorkspaces = [],
      quickViewsByUserWorkspaceId = {},
      orgUsers = [],
    } = orgUserState;
    const orgUser = orgUsers.find(user => user.user.id === parseInt(userId)) || {};
    const { orgRoles = [] } = orgUser;

    const isUserOrgOwnerOrAdmin = orgRoles.includes('OWNER') || orgRoles.includes('ADMIN');

    const renderUserWorkspaces = () => {
      if (isUserOrgOwnerOrAdmin) {
        return (
          <div className="mt- font--h3 color--muted-dark">
            This user can administer all workspaces.
          </div>
        );
      } else if (!userWorkspaces.length) {
        return (
          <div className="mt- font--h3 color--muted-dark">
            This user does not belong to any workspaces.
          </div>
        );
      } else if (!filteredUserWorkspaces.length) {
        return <div className="mt- font--h3 color--muted-dark">No workspaces found.</div>;
      } else {
        return filteredUserWorkspaces.map(teamObj => {
          const { team = {} } = teamObj;
          const membership = teamObj.membership || {};
          const { roles = [], numGroups, direct } = membership;
          const { name, id: workspaceId, _links = {} } = team;
          const { self = {} } = _links;
          const quickView = quickViewsByUserWorkspaceId[workspaceId];
          return (
            <div className="grid mt--" key={`user-team-${workspaceId}`}>
              <div className={`grid__item ${columnWidths.name}`}>
                {teamId ? (
                  <div className="">{name}</div>
                ) : (
                  <strong>
                    <Link to={`/org/settings/workspaces/${workspaceId}`} className="link--obvious">
                      {name}
                    </Link>
                  </strong>
                )}
              </div>

              <div className={`grid__item text--center ${columnWidths.admin}`}>
                {roles.includes('WORKSPACE_ADMIN') ? 'Yes' : '--'}
              </div>

              {showGroups && (
                <div className={`grid__item flex justify-content--center ${columnWidths.groups}`}>
                  <div
                    className={`flex align-items--center col-3-10 ${
                      numGroups && 'cursor--pointer'
                    }`}
                    onClick={
                      numGroups ? () => this.toggleQuickView(workspaceId, 'GROUPS') : undefined
                    }
                  >
                    {numGroups}
                    <i
                      className={`sci sci__caret--${
                        quickView === 'GROUPS' ? 'down' : 'right'
                      } color--${numGroups ? 'primary' : 'muted-light'} pl--`}
                    />
                  </div>
                </div>
              )}

              {showGroups && (
                <div className={`grid__item text--center ${columnWidths.direct}`}>
                  {direct ? 'Yes' : '--'}
                </div>
              )}

              <div
                className={`grid__item col-1-1 ${
                  quickView === 'GROUPS' ? 'is-showing-400' : 'is-hiding'
                }`}
              >
                {showGroups && quickView === 'GROUPS' && (
                  <UserManagementQuickViewWrapper
                    dataType="groups"
                    href={`${self.href}/users/${userId}/groups`}
                  >
                    <UserGroupsQuickView showDetailsLink={hasAdminPerms} />
                  </UserManagementQuickViewWrapper>
                )}
              </div>
            </div>
          );
        });
      }
    };

    return (
      <div className="grid">
        <div className="grid__item col-1-1">
          <div className="bo--1 border-color--muted-dark flex justify-content--space-between align-items--center p-">
            <div className="flex col-3-4">
              <div className="col-1-2">
                <input
                  type="text"
                  className="control--text col-1-1"
                  placeholder="Filter by name"
                  onChange={e => this.handleFilterInput(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid__item col-1-1 mt-">
          <div className="grid">
            <div className={`grid__item ${columnWidths.name}`}>
              <strong>Name</strong>
            </div>
            <div className={`grid__item text--center ${columnWidths.admin}`}>
              <strong>Workspace Admin</strong>
            </div>
            {showGroups && (
              <div className={`grid__item text--center ${columnWidths.groups}`}>
                <strong>Access Through Team</strong>
              </div>
            )}
            {showGroups && (
              <div className={`grid__item text--center ${columnWidths.direct}`}>
                <strong>Direct Assignment</strong>
              </div>
            )}
          </div>
        </div>

        <div className="grid__item max-height--300 overflow--auto">{renderUserWorkspaces()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgUserState: state.orgUserState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserWorkspacesList));
