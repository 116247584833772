import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import * as orgUserActions from '~/actions/orgUser';
import * as orgUserInviteActions from '~/actions/orgUserInvite';
import * as groupActions from '~/actions/group';

import AddInviteesToGroup from '~/containers/AddInviteesToGroup';

interface AddInviteesToGroupModalProps {
  groupActions: object;
  orgUserInviteActions: object;
  groupState: object;
  orgState: App.OrgState;
  orgUserState: object;
  orgUserInviteState: object;
  hideGroupSelection?: boolean;
}
class AddInviteesToGroupModal extends React.Component<AddInviteesToGroupModalProps, {}> {
  componentDidMount() {
    const { groupState, orgState } = this.props;
    const { groups = [] } = groupState;
    const { org } = orgState;

    if (!groups.length) {
      this.props.groupActions.fetchGroups(org.id);
    }
  }

  updateSelectedGroup(groupId) {
    this.props.groupActions.updateSelectedGroups(groupId);
  }

  toggleAdminField(email) {
    this.props.orgUserInviteActions.toggleInviteeGroupAdmin(email);
  }

  render() {
    const { groupState, orgUserInviteState, hideGroupSelection } = this.props;
    const { invitees } = orgUserInviteState;
    const { groups = [], selectedGroups = {} } = groupState;
    const selectableGroups = groups.map(group => {
      return {
        label: group.name,
        value: group.id,
      };
    });

    const selectedGroup =
      !_.isEmpty(selectedGroups) && selectableGroups.find(group => selectedGroups[group.value]);

    return (
      <div className="">
        <AddInviteesToGroup
          hideGroupSelection={hideGroupSelection}
          selectableGroups={selectableGroups}
          selectedGroup={selectedGroup}
          invitees={invitees}
          updateSelectedGroup={groupId => this.updateSelectedGroup(groupId)}
          toggleAdminField={email => this.toggleAdminField(email)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgState: state.orgState,
    groupState: state.groupState,
    orgUserInviteState: state.orgUserInviteState,
    orgUserState: state.orgUserState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
    orgUserInviteActions: bindActionCreators(orgUserInviteActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInviteesToGroupModal);
