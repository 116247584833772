import produce from 'immer';

import {
  ADD_AGENT_TO_TEAM,
  CLEAR_ACTIVATION_TOKEN,
  CLEAR_REGENERATED_TOKEN,
  CLEAR_PLUGIN_TOKENS,
  FILTER_AGENT_LIST_BY_STRING,
  RESET_AGENT_NAME,
  SHOW_AGENT_MODAL,
  UPDATE_ACTIVE_AGENT,
  UPDATE_AGENT_IN_SETUP,
  UPDATE_AGENT_NAME,
  UPDATE_AGENT_SCAN_EVENTS,
  UPDATE_UPGRADE_AGENT_TYPE,
  UPDATE_UPGRADE_ACTIVE_AGENT,
  UPDATE_AGENT_LIST_SORT,
  UPDATE_AGENT_RELEASES,
  UPDATE_AGENTS,
  UPDATE_CURRENT_PAGE,
  UPDATE_EVENT_PAGE,
  UPDATE_INSTALL_OPTION,
  UPDATE_INTEGRATION_TYPE,
  UPDATE_PLUGIN_TOKEN,
  UPDATE_REGENERATED_TOKEN,
  UPDATE_CURRENT_ACTIVE_AGENT,
  UPDATE_SELECTED_AGENT_WORKSPACE,
  UPDATE_TOKEN,
  REQUEST_AGENT_MESSAGES,
  RECEIVE_AGENT_MESSAGES_SUCCESS,
  RECEIVE_AGENT_MESSAGES_ERROR,
  STOP_POLLING,
  ALLOW_POLLING,
  UPDATE_OS,
  UPDATE_ACTIVE_SCRIPT_MODE,
  FETCHING_TEAM_AGENTS,
  AgentActionTypes,
} from '~/actions/agents.types';

import { AgentState, Agent, ActiveInstallOption } from '~/reducers/AgentState.types';

import Helpers from '~/utils/Helpers';

import { CLIENT_PAGINATION_LIMITS } from '~/constants/ModelConstants';

export const defaultOSCliMap: ActiveInstallOption = {
  LINUX: 'apt-get',
  WINDOWS: 'chocolatey',
};

const defaultState: AgentState = {
  agents: [],
  isFetching: false,
  validAgentName: true,
  filteredAgents: [],
  sortAgentList: {
    name: 'asc',
  },
  search: '',
  agentListPagination: {
    totalPages: 0,
    currentPage: 0,
  },
  activeInstallOption: 'homebrew',
  operatingSystem: 'OSX',
  integrationType: '',
  tokenObj: {},
  regeneratedTokenObj: {},
  activeAgent: undefined,
  editableAgentName: '',
  agentEvents: [],
  agentEventPagination: {
    totalPages: 0,
    currentPage: 0,
  },
  agentInSetup: {},
  agentReleases: {},
  isFetchingAgentMessages: false,
  isPollingAllowed: false,
  pluginToken: {},
  agentListUpgrade: {
    activeInstallOption: 'homebrew',
    agentType: '',
  },
  modalType: undefined,
  selectedWorkspace: {
    value: '',
    label: '',
  },
};

const agentState = (state = defaultState, action: AgentActionTypes) => {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_AGENTS:
        draft.agents = action.agents;
        draft.filteredAgents = action.agents;
        draft.agentListPagination = {
          totalPages: Math.ceil(action.agents.length / CLIENT_PAGINATION_LIMITS.agentList) || 20,
          currentPage: 0,
        };
        draft.isFetching = false;
        break;
      case UPDATE_CURRENT_PAGE:
        draft.agentListPagination.currentPage = action.currentPage;
        break;
      case FILTER_AGENT_LIST_BY_STRING: {
        const { sortAgentList } = draft;
        const { name } = sortAgentList;
        const filteredAgents = filterAgents(state.agents, action.value);
        const sortedAgents = sortAgents(filteredAgents, name);

        draft.search = action.value;
        draft.filteredAgents = name ? sortedAgents : filteredAgents;
        draft.agentListPagination = {
          totalPages: Math.ceil(filteredAgents.length / CLIENT_PAGINATION_LIMITS.agentList) || 20,
          currentPage: 0,
        };
        break;
      }
      case ADD_AGENT_TO_TEAM:
        draft.agents.push(action.agent);
        break;
      case UPDATE_ACTIVE_AGENT:
        draft.activeAgent = action.agent;
        draft.editableAgentName = action.agent.name;
        break;
      case UPDATE_AGENT_LIST_SORT: {
        const newSort = determineNewSort({ ...state.sortAgentList }, action.field);

        draft.sortAgentList = {
          [action.field]: newSort,
        };
        draft.filteredAgents = !newSort.length
          ? filterAgents(state.agents, state.search)
          : sortAgents(state.filteredAgents, newSort);
        break;
      }
      case SHOW_AGENT_MODAL:
        draft.modalType = action.modalType;
        break;
      case UPDATE_TOKEN:
        draft.tokenObj = action.tokenObj;
        draft.agentInSetup[action.tokenObj.agentId] = 'TOKEN_CREATED';
        break;
      case UPDATE_INTEGRATION_TYPE:
        draft.integrationType = action.intType;
        break;
      case UPDATE_INSTALL_OPTION:
        draft.activeInstallOption = action.installOption;
        break;
      case UPDATE_OS:
        draft.operatingSystem = action.operatingSystem;
        draft.activeInstallOption = defaultOSCliMap[action.operatingSystem] || 'homebrew';
        break;
      case UPDATE_ACTIVE_SCRIPT_MODE:
        draft.activeScriptMode = action.scriptMode;
        break;
      case UPDATE_AGENT_NAME:
        draft.editableAgentName = action.value;
        draft.validAgentName = Helpers.validateAgentName(action.value);
        break;
      case RESET_AGENT_NAME:
        draft.editableAgentName = state.activeAgent!.name;
        draft.validAgentName = true;
        break;
      case UPDATE_AGENT_SCAN_EVENTS:
        draft.agentEvents = action.events;
        draft.agentEventPagination = {
          currentPage: 0,
          totalPages:
            Math.ceil(action.events.length / CLIENT_PAGINATION_LIMITS.agentEventsList) || 20,
        };
        break;
      case UPDATE_EVENT_PAGE:
        draft.agentEventPagination.currentPage = action.currentPage;
        break;
      case REQUEST_AGENT_MESSAGES:
        draft.isFetchingAgentMessages = true;
        break;
      case RECEIVE_AGENT_MESSAGES_SUCCESS:
        draft.isFetchingAgentMessages = false;
        break;
      case RECEIVE_AGENT_MESSAGES_ERROR:
        draft.isFetchingAgentMessages = false;
        break;
      case STOP_POLLING:
        draft.isPollingAllowed = false;
        break;
      case ALLOW_POLLING:
        draft.isPollingAllowed = true;
        break;
      case UPDATE_AGENT_IN_SETUP:
        draft.agentInSetup[action.agentId] = action.setupPhase;
        break;
      case UPDATE_AGENT_RELEASES:
        draft.agentReleases = action.releases;
        break;
      case UPDATE_REGENERATED_TOKEN:
        draft.regeneratedTokenObj = action.tokenObj;
        break;
      case UPDATE_CURRENT_ACTIVE_AGENT:
        draft.currentActiveAgent = action.activeAgent;
        break;
      case UPDATE_PLUGIN_TOKEN:
        draft.pluginToken = action.tokenObj;
        break;
      case CLEAR_PLUGIN_TOKENS:
        draft.pluginToken = {};
        break;
      case CLEAR_ACTIVATION_TOKEN:
        draft.tokenObj = {};
        break;
      case CLEAR_REGENERATED_TOKEN:
        draft.regeneratedTokenObj = {};
        break;
      case UPDATE_UPGRADE_AGENT_TYPE:
        return {
          ...state,
          agentListUpgrade: {
            agentType: action.agentType || state.agentListUpgrade.agentType,
            activeInstallOption: state.agentListUpgrade.activeInstallOption,
          },
        };
      case UPDATE_UPGRADE_ACTIVE_AGENT:
        draft.agentListUpgrade.activeInstallOption =
          action.activeInstallOption || state.agentListUpgrade.activeInstallOption;
        break;
      case UPDATE_SELECTED_AGENT_WORKSPACE:
        draft.selectedWorkspace = action.workspace;
        break;
      case FETCHING_TEAM_AGENTS:
        draft.isFetching = action.value;
        break;
    }
  });
};

export default agentState;

export const determineNewSort = (currentSort: any, field: string) => {
  if (field in currentSort) {
    if (currentSort[field] === 'desc') {
      return '';
    } else if (currentSort[field] === '') {
      return 'asc';
    }
  }

  return 'desc';
};

export const filterAgents = (agents: Array<Agent>, value = '') => {
  const filterValue = value.toLowerCase();

  return agents.filter(agent => {
    const createdBy = agent.createdBy || {};
    const displayName = Helpers.formatUserName(createdBy);

    return (
      agent.name.toLowerCase().includes(filterValue) ||
      displayName.toLowerCase().includes(filterValue)
    );
  });
};

export const sortAgents = (agents: Array<Agent>, sort: string) => {
  return [...agents].sort((a: Agent, b: Agent) => {
    if (sort === 'asc') {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    } else {
      return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
    }
  });
};
