import * as actions from '~/actions/welcome';

export const defaultState = {
  selectedOS: undefined,
  isAgentSetup: false,
  isScanComplete: false,
  isScanPersisted: false,
  allowPolling: false,
  agentEvents: [],
  showModal: undefined,
  scanTip: '',
};

const welcomeState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.WELCOME_MODE_SELECT_OS:
      return {
        ...state,
        selectedOS: action.OS,
      };
    case actions.UPDATE_AGENT_SETUP:
      return {
        ...state,
        isAgentSetup: action.status,
      };
    case actions.UPDATE_SCAN_STATUS:
      return {
        ...state,
        isScanComplete: action.status,
      };
    case actions.UPDATE_SCAN_PERSISTED:
      return {
        ...state,
        isScanPersisted: action.status,
      };
    case actions.UPDATE_AGENT_EVENTS:
      return {
        ...state,
        agentEvents: action.events,
      };
    case actions.TOGGLE_ALLOW_POLLING:
      return {
        ...state,
        allowPolling: action.bool,
      };
    case actions.UPDATE_SCAN_TIP:
      return {
        ...state,
        scanTip: action.scanTip !== state.scanTip ? action.scanTip : '',
      };
    case actions.SHOW_WELCOME_FLOW_MODAL:
      return {
        ...state,
        showModal: action.modalType,
      };
    default:
      return state;
  }
};

export default welcomeState;
