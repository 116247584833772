import React from 'react';

interface ReportStringFilterProps {
  field: string;
  label: string;
  onChange?: (...args: any[]) => any;
  value: string;
  inputClassName?: string;
}
class ReportStringFilter extends React.Component<ReportStringFilterProps, {}> {
  toggleFilter = value => {
    const { field } = this.props;
    this.props.onChange(field, value);
  };

  onKeypress = e => {
    this.toggleFilter(e.target.value);
  };

  render() {
    const { label, field, value, inputClassName = '' } = this.props;
    return (
      <label
        className="label--checkbox col-1-1 grid grid--middle grid--full"
        key={label.split(' ').join('-') + '-filter'}
      >
        <div
          className="grid__item flex flex--align-items--stretch position--relative"
          data-automation-id="ReportStringFilter"
        >
          <input
            value={value}
            data-e2e={`${label}-input`}
            type="text"
            className={`flex flex-grow--1 ${inputClassName}`}
            ref={field}
            placeholder={label}
            onChange={e => this.onKeypress(e)}
          />
          <div className="position--absolute top--6 right--10">
            <i className="sci sci--fw sci__search color--muted" />
          </div>
        </div>
      </label>
    );
  }
}

export default ReportStringFilter;
