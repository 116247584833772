import * as repoScopeSwitcherActions from '~/actions/repoScopeSwitcher';
import Helpers from '~/utils/Helpers';

export const UPDATE_REPO_SCOPE = 'UPDATE_REPO_SCOPE';
export const RESET_REPO_SCOPE = 'RESET_REPO_SCOPE';

export const updateRepoScope = (field, value) => ({
  type: UPDATE_REPO_SCOPE,
  field,
  value,
});

export const resetRepoScope = () => ({
  type: RESET_REPO_SCOPE,
});

/**
 *
 * @param {object} data
 * @param {boolean} updateUrlQueryParam
 *
 * This performs the following sequence of actions when data contains a branch or tag:
 * 1. Update repo scope
 * 2. Updating option in the repo branch/tag selection component (ie RepoScopePopover)
 * 3. Updates URL query params with the given scope - default is true
 *
 * Otherwise, when data does not contain a branch or tag, this function clears the scope, resets branch/tag switcher
 * selection component and optionally removes the branch or tag URL param if there's any.
 */
export const performRepoScopeChange = (
  data,
  history = {},
  updateURLQueryParam = true
) => dispatch => {
  const { branch, tag } = data;
  const maybeTagRefType = tag ? 'tag' : '';
  const refType = branch ? 'branch' : maybeTagRefType;

  if (refType) {
    dispatch(updateRepoScope(refType, data[refType]));
    dispatch(repoScopeSwitcherActions.updateSpecificOption(data[refType], refType)); //updates repo branch/tag selection

    if (updateURLQueryParam) {
      Helpers.updateURLQueryParam(window.location, refType, data[refType], history); //updates URL to include query param
    }
  } else {
    dispatch(resetRepoScope());
    dispatch(repoScopeSwitcherActions.resetSpecificOptions());
    if (updateURLQueryParam) {
      Helpers.removeURLQueryParams(window.location, ['branch', 'tag'], history); //Remove branch and tag params from URL
    }
  }
};
