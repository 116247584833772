import * as actions from '~/actions/repo';
import produce from 'immer';
const defaultState = {
  isFetching: false,
  isSettingRepoScope: false,
  message: '',
  repoNameWillBe: '',
  isValidRepoName: false,
};
import Helpers from '~/utils/Helpers';

const repoDataById = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.UPDATE_REPO_DATA: {
        const { response = {} } = action;
        const { id = '' } = response;
        draft[id] = action.response;
        draft.repoNameWillBe = draft[id].name;
        draft.isFetching = false;
        draft.message = '';
        break;
      }
      case actions.UPDATE_REPO_NAME: {
        const { value } = action;
        draft.repoNameWillBe = value;
        draft.isValidRepoName = Helpers.validateRepoName(value);
        break;
      }
      case actions.FETCH_REPO_DATA_REQUEST: {
        draft.isFetching = true;
        draft.message = '';
        break;
      }
      case actions.FETCH_REPO_DATA_FAILURE: {
        draft.isFetching = false;
        draft.message = action.message;
        break;
      }
      case actions.UPDATE_IS_SETTING_REPO_SCOPE_REQUEST: {
        draft.isSettingRepoScope = true;
        break;
      }
      case actions.UPDATE_IS_SETTING_REPO_SCOPE_SUCCESS: {
        draft.isSettingRepoScope = false;
        draft.message = '';
        break;
      }
      case actions.UPDATE_IS_SETTING_REPO_SCOPE_FAILURE: {
        draft.isSettingRepoScope = false;
        draft.message = action.message;
        break;
      }
      case actions.UPDATE_IS_REFRESHING_PROJECT_DATA:
        draft.isRefreshingProjectData = action.isRefreshingProjectData;
    }
  });
};

export default repoDataById;
