import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DropdownMenu from 'react-dd-menu';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import _ from 'lodash';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as groupActions from '~/actions/group';
import * as orgTeamActions from '~/actions/orgTeam';

import * as teamGroupsActions from '~/actions/teamGroups';

import Tooltip from '~/components/Tooltip';
import SourceClearModal from '~/components/SourceClearModal';
import LinkWorkspaceToTeamsModal from '~/containers/LinkWorkspaceToTeamsModal';
import { tooltipContent } from '~/constants/ModelConstants';

interface WorkspaceGroupsListProps {
  teamId: string;
  dropdownMenuActions: object;
  orgTeamActions: object;
  groupActions: object;
  teamGroupsActions: object;
  dropdownMenuState: object;
  groupState: object;
  orgState: App.OrgState;
  orgTeamState: object;
  teamGroupsState: object;
  setTeamDetails: (...args: any[]) => any;
}
class WorkspaceGroupsList extends React.Component<WorkspaceGroupsListProps, {}> {
  constructor(props, context) {
    super(props, context);

    this.delayCloseDropdown = _.debounce(() => this.closeDropdown('workspace-groups-actions'), 50);
  }

  componentDidMount() {
    const { teamId } = this.props;

    this.props.teamGroupsActions.fetchTeamGroups(teamId);
    this.props.orgTeamActions.fetchOrgTeams();
    this.props.groupActions.fetchGroups();
  }

  closeDropdown(menuId) {
    this.props.dropdownMenuActions.closeDropdownMenu(menuId);
  }

  openDropdown(menuId) {
    this.props.dropdownMenuActions.openDropdownMenu(menuId);
  }

  handleLinkSuccess() {
    const { teamId } = this.props;

    this.props.teamGroupsActions.fetchTeamGroups(teamId);
  }

  unlinkGroups() {
    const { teamGroupsState, teamId } = this.props;
    const { teamGroups = [], selectedGroups = {} } = teamGroupsState;
    const filteredGroups = teamGroups
      .filter(groupObj => selectedGroups[groupObj.group.id])
      .map(groupObj => groupObj.id);

    this.props.groupActions.removeMemberships(filteredGroups, 'teams').then(res => {
      if (res.success) {
        this.props.teamGroupsActions.fetchTeamGroups(teamId);
      }
    });
  }

  toggleAgentAdmin(toAdmin = false) {
    const { teamGroupsState, teamId } = this.props;
    const { selectedGroups = {} } = teamGroupsState;
    const groupData = Object.keys(selectedGroups)
      .filter(groupId => selectedGroups[groupId])
      .map(groupId => {
        return {
          groupId: parseInt(groupId),
          teams: [{ teamId, role: toAdmin ? 'WORKSPACE_ADMIN' : 'USER' }],
        };
      });

    this.props.groupActions.groupMultiUpdate(groupData).then(res => {
      if (res.id) {
        this.props.teamGroupsActions.fetchTeamGroups(teamId);
        this.props.groupActions.fetchGroups();
      }
    });
  }

  handleGroupSelection(groupId) {
    this.props.teamGroupsActions.updateSelectedGroups(groupId);
  }

  handleFilterInput(e) {
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.teamGroupsActions.updateTeamGroupsFilterInput(value);
  }

  updateTeamGroupSort(column) {
    this.props.orgTeamActions.updateTeamGroupSort(column);
  }

  showModal(modalType) {
    this.props.orgTeamActions.showModal(modalType);
  }

  render() {
    const {
      orgTeamState,
      teamId,
      dropdownMenuState,
      groupState,
      teamGroupsState,
      orgState,
    } = this.props;
    const { org = {} } = orgState;
    const { permissions = {} } = org;
    const { updateGroups } = permissions;
    const { groups = [] } = groupState;
    const { openedDropdown = {} } = dropdownMenuState;
    const { filteredTeamGroups = [], teamGroups = [], selectedGroups = {} } = teamGroupsState;
    const { orgTeams = [], showModal } = orgTeamState;
    const activeTeam = orgTeams.find(team => team.id === teamId) || {};
    const columnWidths = {
      name: 'col-1-8',
      description: 'col-1-3',
      select: 'col-1-18',
    };
    const isGroupSelected = _.some(selectedGroups);

    const renderWorkspaceTeams = () => {
      if (!teamGroups.length) {
        return (
          <div className="mt- font--h3 color--muted-dark">
            No teams are linked to this workspace.
          </div>
        );
      } else if (!filteredTeamGroups.length) {
        return <div className="mt- font--h3 color--muted-dark">No teams found.</div>;
      } else {
        return filteredTeamGroups.map(teamGroupObj => {
          const { group: teamGroup = {}, roles = [] } = teamGroupObj;
          const group = groups.find(group => group.id === teamGroup.id) || {};
          const { id: groupId, name = '', description = '' } = group;
          const isSelected = selectedGroups[groupId] || false;
          const isAgentAdmin = roles.includes('WORKSPACE_ADMIN');

          return (
            <div className="grid grid--narrower mt-" key={`key-${teamGroup.id}`}>
              <div className={`grid__item ${columnWidths.name}`}>
                {updateGroups ? (
                  <Link to={`/org/settings/teams/${groupId}`} className="link--obvious">
                    <strong>{name}</strong>
                  </Link>
                ) : (
                  <div>{name}</div>
                )}
              </div>
              <div className={`grid__item ${columnWidths.description}`}>{description || '--'}</div>
              <div
                className={`grid__item flex align-items--center justify-content--center ${columnWidths.admin}`}
              >
                {isAgentAdmin ? 'Yes' : '--'}
              </div>

              <div
                className={`grid__item flex align-items--center justify-content--center ${columnWidths.select}`}
              >
                <label className="label--checkbox flex justify-content--center mb0">
                  <input
                    type="checkbox"
                    name="select"
                    checked={isSelected}
                    onChange={() => this.handleGroupSelection(groupId)}
                  />
                  <span className="react--checkbox mr0" />
                </label>
              </div>
            </div>
          );
        });
      }
    };

    return (
      <div className="grid">
        <Helmet>
          <title>Workspace Groups</title>
        </Helmet>
        <div className="grid__item col-1-1">
          <div className="bo--1 border-color--muted-dark flex justify-content--space-between align-items--center p-">
            <div className="flex col-3-4">
              <div className="col-1-2">
                <input
                  type="text"
                  className="control--text col-1-1"
                  placeholder="Filter by team name or description"
                  onChange={e => this.handleFilterInput(e)}
                />
              </div>
            </div>
            <div>
              <DropdownMenu
                isOpen={!!openedDropdown['workspace-groups-actions']}
                close={() => this.delayCloseDropdown()}
                menuAlign="right"
                textAlign="left"
                toggle={
                  <button
                    className="btn--success p-"
                    onClick={() => this.openDropdown('workspace-groups-actions')}
                  >
                    Actions
                  </button>
                }
              >
                <li className="min-width--200 dropdown-menu--li">
                  <button className="pv--" onClick={() => this.showModal('LINK_GROUPS')}>
                    Add Teams
                  </button>
                </li>
                <li className="min-width--200 dropdown-menu--li">
                  <button
                    className={`pv-- ${!isGroupSelected && 'disabled'}`}
                    disabled={!isGroupSelected}
                    onClick={() => this.unlinkGroups()}
                  >
                    Remove Teams
                  </button>
                </li>
                <li className="separator mh-" />
                <li className="min-width--200 dropdown-menu--li">
                  <button
                    className={`pv-- ${!isGroupSelected && 'disabled'}`}
                    disabled={!isGroupSelected}
                    onClick={() => this.toggleAgentAdmin(true)}
                  >
                    Change to Workspace Administrator
                  </button>
                </li>
                <li className="min-width--200 dropdown-menu--li">
                  <button
                    className={`pv-- ${!isGroupSelected && 'disabled'}`}
                    disabled={!isGroupSelected}
                    onClick={() => this.toggleAgentAdmin()}
                  >
                    Change to Workspace User
                  </button>
                </li>
              </DropdownMenu>
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1 mt">
          <div className="grid grid--narrower">
            <div
              className={`grid__item flex align-items--center justify-content--space-between cursor--pointer ${columnWidths.name}`}
              onClick={() => this.updateTeamGroupSort('name')}
            >
              <strong>Team Name</strong>
            </div>
            <div className={`grid__item ${columnWidths.description}`}>
              <strong>Description</strong>
            </div>
            <div className={`grid__item text--center ${columnWidths.admin}`}>
              <Tooltip
                id="group-workspace-admins"
                content={tooltipContent.WORKSPACE_ADMIN}
                maxWidthClass="max-width--300"
                place="top"
              >
                <strong className="bo-b--1 bo--dashed">Workspace Administrator</strong>
              </Tooltip>
            </div>
            <div className={`grid__item text--center ${columnWidths.select}`}>
              <strong>Select</strong>
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1">{renderWorkspaceTeams()}</div>
        <SourceClearModal
          isOpen={showModal === 'LINK_GROUPS'}
          onClose={() => this.showModal('')}
          title="Add Workspace to Teams"
          width={500}
        >
          <LinkWorkspaceToTeamsModal
            handleLinkSuccess={() => this.handleLinkSuccess()}
            activeTeam={activeTeam}
          />
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    groupState: state.groupState,
    orgState: state.orgState,
    orgTeamState: state.orgTeamState,
    teamGroupsState: state.teamGroupsState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    groupActions: bindActionCreators(groupActions, dispatch),
    orgTeamActions: bindActionCreators(orgTeamActions, dispatch),
    teamGroupsActions: bindActionCreators(teamGroupsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceGroupsList);
