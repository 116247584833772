import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';

import validator from 'validator';
import _ from 'lodash';

export const UPDATE_INVITE_STAGE = 'UPDATE_INVITE_STAGE';
export const UPDATE_ACTIVE_INVITE_MODAL_TAB = 'UPDATE_ACTIVE_INVITE_MODAL_TAB';
export const UPDATE_RAW_EMAILS_INPUT = 'UPDATE_RAW_EMAILS_INPUT';
export const UPDATE_EMAILS_INPUT = 'UPDATE_EMAILS_INPUT';
export const UPDATE_EMAILS_INPUT_ERROR = 'UPDATE_EMAILS_INPUT_ERROR';
export const UPDATE_INVITEES = 'UPDATE_INVITEES';
export const UPDATE_INVITEE_ROLE = 'UPDATE_INVITEE_ROLE';
export const UPDATE_SEND_INVITE_ERROR = 'UPDATE_SEND_INVITE_ERROR';
export const UPDATE_NEW_GROUP_DETAILS = 'UPDATE_NEW_GROUP_DETAILS';
export const UPDATE_INVITE_SELECTED_GROUP = 'UPDATE_INVITE_SELECTED_GROUP';
export const TOGGLE_INVITEE_GROUP_ADMIN = 'TOGGLE_INVITEE_GROUP_ADMIN';
export const RESET_INVITE_MODAL = 'RESET_INVITE_MODAL';

export const updateInviteStage = inviteStage => ({
  type: UPDATE_INVITE_STAGE,
  inviteStage,
});

export const updateActiveModalTab = tab => ({
  type: UPDATE_ACTIVE_INVITE_MODAL_TAB,
  tab,
});

export const updateRawEmailsInput = emails => ({
  type: UPDATE_RAW_EMAILS_INPUT,
  emails,
});

export const updateEmailsInput = emails => ({
  type: UPDATE_EMAILS_INPUT,
  emails,
});

export const updateEmailsInputError = error => ({
  type: UPDATE_EMAILS_INPUT_ERROR,
  error,
});

export const updateInvitees = emails => ({
  type: UPDATE_INVITEES,
  emails,
});

export const updateInviteeRole = (email, role) => ({
  type: UPDATE_INVITEE_ROLE,
  email,
  role,
});

export const toggleInviteeGroupAdmin = email => ({
  type: TOGGLE_INVITEE_GROUP_ADMIN,
  email,
});

export const resetInviteModal = () => ({
  type: RESET_INVITE_MODAL,
});

export const sendInvites = inviteData => (dispatch, getState) => {
  const { orgState } = getState();
  const { org = {} } = orgState;

  return ApiService.post(ApiConstants.pendingInvitesURL(org), { data: inviteData })
    .then((res = {}) => {
      res.success = true;

      dispatch(resetInviteModal());
      return res;
    })
    .catch(error => {
      ErrorService.capture('Error sending invites', error);

      dispatch(
        updateSendInviteError(
          error.message ||
            'There has been an error sending the invitations. Please check the addresses and try again.'
        )
      );
      return {};
    });
};

export const updateSendInviteError = message => ({
  type: UPDATE_SEND_INVITE_ERROR,
  message,
});

export const checkEmailValidity = emails => dispatch => {
  const formattedEmailsArr = getFormattedEmailsArr(emails);
  const invalidEmails = formattedEmailsArr.filter(email => !validator.isEmail(email));

  if (invalidEmails.length > 0) {
    const invalidEmailsText = invalidEmails.join(', ');
    dispatch(
      updateEmailsInputError(
        `The following invalid email addresses will be ignored: ${invalidEmailsText}`
      )
    );
  } else {
    dispatch(updateEmailsInputError(''));
  }
};

export const processEmailsInput = emails => dispatch => {
  dispatch(updateRawEmailsInput(emails));

  const formattedEmailsArr = getFormattedEmailsArr(emails);
  const validEmails = formattedEmailsArr.filter(email => validator.isEmail(email));

  //Place valid emails into emailsInput state and
  //Create an array of invitee object in invitees state
  dispatch(updateEmailsInput(validEmails));
  dispatch(updateInvitees(validEmails));
};

//Replaces delimiters(spaces and newlines) with comma and returns an array of email inputs excluding empty values.
export const getFormattedEmailsArr = emails => {
  const formattedEmails = emails.replace(/\s|\n/g, ',');
  const formattedEmailsArr = formattedEmails.split(',');
  const distinctEmails = _.uniq(formattedEmailsArr);
  return distinctEmails.filter(email => email.length > 0);
};
/****** END USER INVITE MODAL RELATED ACTIONS  ******/
