import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import _ from 'lodash';

import * as orgUserActions from '~/actions/orgUser';
import * as groupActions from '~/actions/group';

import AddSelectedUsersToGroup from '~/components/AddSelectedUsersToGroup';

interface AddSelectedUsersToGroupModalProps extends RouteComponentProps {
  groupActions: object;
  orgUserActions: object;
  groupState: object;
  orgState: App.OrgState;
  orgUserState: object;
}

class AddSelectedUsersToGroupModal extends React.Component<AddSelectedUsersToGroupModalProps, {}> {
  componentDidMount() {
    const { groupState, orgState } = this.props;
    const { groups = [] } = groupState;
    const { org } = orgState;

    if (!groups.length) {
      this.props.groupActions.fetchGroups(org.id);
    }

    this.props.groupActions.resetSelectedGroups();
  }

  addUsersToGroup() {
    const { groupState, orgUserState } = this.props;
    const { selectedGroups = {} } = groupState;
    const { selectedOrgUsers = [] } = orgUserState;

    this.props.groupActions
      .groupMultiUpdate([
        {
          groupId: Object.keys(selectedGroups)[0],
          users: selectedOrgUsers,
        },
      ])
      .then(res => {
        if (res.id) {
          this.showModal();
          this.props.orgUserActions.fetchOrgUsers();
        }
      });
  }

  addUsersToNewGroup() {
    const { groupState, orgUserState, orgState, history } = this.props;
    const { groupData = {}, groups = [] } = groupState;
    const { name = '', description = '' } = groupData;
    const { selectedOrgUsers = [] } = orgUserState;
    const { org = {} } = orgState;

    if (name && groups.find(group => group.name.toLowerCase() === name.toLowerCase())) {
      this.props.groupActions.updateGroupData('error', true);
    } else {
      this.props.groupActions
        .createGroups(
          {
            name,
            description,
            users: selectedOrgUsers,
          },
          org.id
        )
        .then(res => {
          if (res.id) {
            this.showModal();
            history.push(`/org/settings/teams/${res.id}`);
          }
        });
    }
  }

  toggleCreateNewGroup() {
    this.props.orgUserActions.toggleCreateNewGroup();
  }

  updateSelectedGroup(groupId) {
    this.props.groupActions.updateSelectedGroups(groupId);
  }

  toggleAdminField(userId) {
    this.props.orgUserActions.toggleUserGroupAdmin(userId);
  }

  updateGroupData(field, value) {
    this.props.groupActions.updateGroupData(field, value);
  }

  showModal(modalType) {
    this.props.orgUserActions.showModal(modalType);
  }

  render() {
    const { orgUserState, orgState, groupState } = this.props;
    const { org = {} } = orgState;
    const { permissions = {} } = org;
    const { createGroups } = permissions;
    const { selectedOrgUsers = [], orgUsers = [], createNewGroupForUsers } = orgUserState;
    const { groups = [], selectedGroups = {}, groupData = {} } = groupState;
    const selectableGroups = groups.map(group => {
      return {
        label: group.name,
        value: group.id,
      };
    });
    const selectedUserMap = {};
    selectedOrgUsers.forEach(user => (selectedUserMap[user.userId] = true));
    const selectedOrgUsersWithData = orgUsers.filter(userObj => {
      const { user = {} } = userObj;
      return selectedUserMap[user.id];
    });
    const selectedGroup =
      !_.isEmpty(selectedGroups) && selectableGroups.find(group => selectedGroups[group.value]);

    const buttons = (
      <div className="grid__item col-1-1 flex justify-content--end mt">
        <button className="col-1-5 pv- mr-" onClick={() => this.showModal()}>
          Cancel
        </button>

        {createNewGroupForUsers ? (
          <button
            className="col-1-5 pv- btn--success"
            disabled={!groupData.name || !groupData.name.length}
            onClick={() => this.addUsersToNewGroup()}
          >
            Save
          </button>
        ) : (
          <button
            className="col-1-5 pv- btn--success"
            disabled={!selectedGroup}
            onClick={() => this.addUsersToGroup()}
          >
            Save
          </button>
        )}
      </div>
    );

    return (
      <div className="">
        <AddSelectedUsersToGroup
          selectableGroups={selectableGroups}
          selectedGroup={selectedGroup}
          selectedUsers={selectedOrgUsersWithData}
          createGroups={createGroups}
          createNewGroupForUsers={createNewGroupForUsers}
          groupData={groupData}
          updateSelectedGroup={groupId => this.updateSelectedGroup(groupId)}
          toggleAdminField={userId => this.toggleAdminField(userId)}
          updateGroupData={(field, value) => this.updateGroupData(field, value)}
          toggleCreateNewGroup={() => this.toggleCreateNewGroup()}
          buttons={buttons}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupState: state.groupState,
    orgState: state.orgState,
    orgUserState: state.orgUserState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddSelectedUsersToGroupModal)
);
