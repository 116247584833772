import React from 'react';

const LibraryCatalogFetchErrorMessage: React.FunctionComponent<{}> = () => {
  return (
    <div className="bo--1 border-color--muted bg-color--white-light p">
      <div className="grid">
        <div className="grid__item col-1-1 text--center">
          <div className="font--h7">
            Something went wrong retrieving the library catalog. Please try your request again, and{' '}
            <a
              href="mailto:support@veracode.com?subject=Request For SourceClear Support"
              className="link--obvious"
            >
              contact support
            </a>{' '}
            if the issue persists.
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibraryCatalogFetchErrorMessage;
