import { ResponseError } from 'superagent';
import ErrorService from '~/utils/ErrorService';
import ApiService from '~/utils/ApiService';
import ApiConstants from '~/constants/ApiConstants';
import * as toastrActions from '~/actions/toastr';
import { ScanUsage } from '~/reducers/usageState.types';
import {
  UPDATE_COMMITTERS_BY_ORG_ID,
  UPDATE_COMMITTERS_BY_TEAM_ID,
  UPDATE_USAGE_FILTER,
  UPDATE_USAGE_SORT_ASCENDING,
  UPDATE_USAGE_SORT_COLUMN,
  REQUEST_COMMITTERS,
  RESET_USAGE_STATE,
  FETCH_SCAN_USAGE_REQUEST,
  FETCH_SCAN_USAGE_SUCCESS,
  FETCH_SCAN_USAGE_FAILURE,
  FetchScanUsageResponse,
} from '~/actions/usage.types';

export const fetchOrgCommitters = (orgId: string) => (dispatch: any) => {
  dispatch(requestCommitters());
  (ApiService as any)
    .get(ApiConstants.fetchOrgCommittersURL(orgId))
    .then(
      (res: any) => {
        dispatch(updateCommittersByOrgId(res, orgId));
      },
      (err: ResponseError) => {
        dispatch(updateCommittersByOrgId([], orgId));
        dispatch(
          toastrActions.addToastr({
            id: `FETCH_COMMITTERS_ERROR`,
            level: 'error',
            title: 'Error fetching commiters for this org',
            message:
              err.message ||
              'We ran into an error fetching the committers for this org. Please try again later',
            disableTimeout: true,
          })
        );
      }
    )
    .catch((error: ResponseError) => {
      dispatch(updateCommittersByOrgId([], orgId));
      dispatch(
        toastrActions.addToastr({
          id: `FETCH_COMMITTERS_ERROR`,
          level: 'error',
          title: 'Error fetching commiters for this org',
          message:
            error.message ||
            'We ran into an error fetching the committers for this org. Please try again later',
          disableTimeout: true,
        })
      );
    });
};

export const fetchTeamCommitters = (teamId: string) => (dispatch: any) => {
  dispatch(requestCommitters());
  (ApiService as any)
    .get(ApiConstants.fetchTeamCommittersURL(teamId))
    .then(
      (res: any) => {
        dispatch(updateCommittersByTeamId(res, teamId));
      },
      (err: ResponseError) => {
        dispatch(updateCommittersByTeamId([], teamId));
        dispatch(
          toastrActions.addToastr({
            id: `FETCH_COMMITTERS_ERROR`,
            level: 'error',
            title: 'Error fetching commiters for this team',
            message:
              err.message ||
              'We ran into an error fetching the committers for this team. Please try again later',
            disableTimeout: true,
          })
        );
      }
    )
    .catch((error: ResponseError) => {
      dispatch(updateCommittersByTeamId([], teamId));
      dispatch(
        toastrActions.addToastr({
          id: `FETCH_COMMITTERS_ERROR`,
          level: 'error',
          title: 'Error fetching commiters for this team',
          message:
            error.message ||
            'We ran into an error fetching the committers for this team. Please try again later',
          disableTimeout: true,
        })
      );
    });
};

export const fetchScanUsage = (orgId: string) => (dispatch: any) => {
  dispatch(fetchScanUsageRequest());
  (ApiService as any)
    .get(ApiConstants.fetchScanUsageUrl(orgId))
    .then((response: FetchScanUsageResponse) => {
      const { scanUsage } = response;

      dispatch(fetchScanUsageSuccess(scanUsage));
    })
    .catch((err: ResponseError) => {
      dispatch(
        toastrActions.addToastr({
          id: `FETCH_SCAN_USAGE_ERROR`,
          level: 'error',
          title: 'Error fetching scan usage for this team',
          message:
            err.message ||
            'We ran into an error fetching the scan usage for this team. Please try again later',
          disableTimeout: true,
        })
      );
      dispatch(fetchScanUsageFailure(err));
      ErrorService.capture('Error fetching scan usage', err);
    });
};

export const updateCommittersByOrgId = (committers: any, orgId: string) => ({
  type: UPDATE_COMMITTERS_BY_ORG_ID,
  committers,
  orgId,
});

export const updateUsageSortColumn = (column: any) => ({
  type: UPDATE_USAGE_SORT_COLUMN,
  column,
});

export const updateUsageSortAscending = (bool: boolean) => ({
  type: UPDATE_USAGE_SORT_ASCENDING,
  bool,
});

export const updateUsageFilter = (filter: any) => ({
  type: UPDATE_USAGE_FILTER,
  filter,
});

export const updateCommittersByTeamId = (committers: any, teamId: string) => ({
  type: UPDATE_COMMITTERS_BY_TEAM_ID,
  committers,
  teamId,
});

export const requestCommitters = () => ({
  type: REQUEST_COMMITTERS,
});

export const resetUsageState = () => ({
  type: RESET_USAGE_STATE,
});

export const fetchScanUsageRequest = () => ({
  type: FETCH_SCAN_USAGE_REQUEST,
});
export const fetchScanUsageSuccess = (scanUsage: Array<ScanUsage>) => ({
  type: FETCH_SCAN_USAGE_SUCCESS,
  scanUsage,
});
export const fetchScanUsageFailure = (err: ResponseError) => ({
  type: FETCH_SCAN_USAGE_FAILURE,
  err: err.message || 'Error fetching scan usage',
});
