import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import { getScopeForRequest } from '~/actions/reports';
import * as MODEL from '~/constants/ModelConstants';

export const FETCH_PROJECT_SCANS_REQUEST = 'FETCH_PROJECT_SCANS_REQUEST';
export const FETCH_PROJECT_SCANS_SUCCESS = 'FETCH_PROJECT_SCANS_SUCCESS';
export const FETCH_PROJECT_SCANS_FAILURE = 'FETCH_PROJECT_SCANS_FAILURE';
export const FETCH_PROJECT_LATEST_SCAN_REQUEST = 'FETCH_PROJECT_LATEST_SCAN_REQUEST';
export const FETCH_PROJECT_LATEST_SCAN_FAILURE = 'FETCH_PROJECT_LATEST_SCAN_FAILURE';
export const FETCH_PROJECT_SCAN_BY_ID_REQUEST = 'FETCH_PROJECT_SCAN_BY_ID_REQUEST';
export const FETCH_PROJECT_SCAN_BY_ID_FAILURE = 'FETCH_PROJECT_SCAN_BY_ID_FAILURE';
export const UPDATE_SELECTED_PROJECT_SCAN = 'UPDATE_SELECTED_PROJECT_SCAN';
export const UPDATE_PROJECT_SCAN_FILTER = 'UPDATE_PROJECT_SCAN_FILTER';
export const RESET_PROJECT_SCAN_FILTERS = 'RESET_PROJECT_SCAN_FILTERS';
export const UPDATE_LATEST_SCAN_BY_DEFAULT_BRANCH = 'UPDATE_LATEST_SCAN_BY_DEFAULT_BRANCH';
export const UPDATE_LATEST_SCAN_BY_BRANCH_OR_TAG = 'UPDATE_LATEST_SCAN_BY_BRANCH_OR_TAG';
export const RESET_PROJECT_SCANS_DATA = 'RESET_PROJECT_SCANS_DATA';

export const resetProjectScansData = () => ({
  type: RESET_PROJECT_SCANS_DATA,
});

export const createScansEndpoint = (teamId, pageNum = 0, pageSize = 50) => {
  const projectScansApiData = MODEL.reduxApiMap['PROJECT_SCANS'];
  const apiPath = projectScansApiData && projectScansApiData.apiPath;

  return `/teams/${teamId}/reports/${apiPath}?page=${pageNum}&size=${pageSize}&sort=date,desc`;
};

export const fetchProjectScansRequest = () => ({
  type: FETCH_PROJECT_SCANS_REQUEST,
});

export const fetchProjectScansSuccess = res => ({
  type: FETCH_PROJECT_SCANS_SUCCESS,
  res,
});

export const fetchProjectScansFailure = error => ({
  type: FETCH_PROJECT_SCANS_FAILURE,
  message: error.message || 'Something went wrong. Please try again.',
});

export const fetchProjectLatestScanFailure = error => ({
  type: FETCH_PROJECT_LATEST_SCAN_FAILURE,
  message: error.message || 'Something went wrong. Please try again.',
});

export const fetchProjectLatestScanRequest = () => ({
  type: FETCH_PROJECT_LATEST_SCAN_REQUEST,
});

export const fetchProjectScanByIdFailure = error => ({
  type: FETCH_PROJECT_SCAN_BY_ID_FAILURE,
  message: error.message || 'Something went wrong. Please try again.',
});

export const fetchProjectScanByIdRequest = () => ({
  type: FETCH_PROJECT_SCAN_BY_ID_REQUEST,
});

export const updateSelectedProjectScan = data => ({
  type: UPDATE_SELECTED_PROJECT_SCAN,
  data,
});

export const updateLatestScanByDefaultBranch = scan => ({
  type: UPDATE_LATEST_SCAN_BY_DEFAULT_BRANCH,
  scan,
});

export const updateProjectScanFilter = (field, value) => ({
  type: UPDATE_PROJECT_SCAN_FILTER,
  field,
  value,
});

export const resetProjectScanFilters = () => ({
  type: RESET_PROJECT_SCAN_FILTERS,
});

export const updateLatestScanByBranchOrTag = (ref, refType, data) => ({
  type: UPDATE_LATEST_SCAN_BY_BRANCH_OR_TAG,
  ref,
  refType,
  data,
});

export const fetchLatestProjectScanByDefaultBranch = (teamId, projectId) => (
  dispatch,
  getState
) => {
  const state = getState();
  const endpoint = createScansEndpoint(teamId, 0, 1); // Retrieves a size of 1 to get the latest
  const scopeForRequest = getScopeForRequest(state);
  const { repoDataById = {} } = state;
  const repo = repoDataById[projectId] || {};

  scopeForRequest.refs = {
    [scopeForRequest.repos[0]]: { commit: null, branch: repo.defaultBranch || null, tag: null }, // Removes scope to get all scans across branches
  };

  // Don't filter scan list by repoScanId because we want the unfiltered list
  const options = {
    ...scopeForRequest,
    repoScanId: '',
  };

  dispatch(fetchProjectLatestScanRequest());

  return ApiService.post(endpoint, { data: options })
    .then(
      res => {
        const { _embedded = {} } = res;
        const { repoScans = [] } = _embedded;
        dispatch(updateLatestScanByDefaultBranch(repoScans[0]));
        return res;
      },
      error => {
        dispatch(fetchProjectLatestScanFailure(error));
        ErrorService.capture('Error fetching latest scan', error);
        return {};
      }
    )
    .catch(error => {
      dispatch(fetchProjectLatestScanFailure(error));
      ErrorService.capture('Error fetching latest scan', error);
      return {};
    });
};

export const fetchLatestProjectScanByRefs = (teamId, refs) => (dispatch, getState) => {
  const state = getState();
  const endpoint = createScansEndpoint(teamId, 0, 1); // Retrieves a size of 1 to get the latest
  const scopeForRequest = getScopeForRequest(state);
  const { branch = '', tag = '' } = refs;

  const refType = (ref => {
    if (ref.branch) {
      return 'branch';
    }
    if (ref.tag) {
      return 'tag';
    }
  })(refs);

  scopeForRequest.refs = {
    [scopeForRequest.repos[0]]: refs,
  };

  // Remove repoScanId filters to get all scans
  const options = {
    ...scopeForRequest,
    repoScanId: '',
  };

  return ApiService.post(endpoint, { data: options })
    .then(
      res => {
        const { _embedded = {} } = res;
        const { repoScans = [] } = _embedded;
        dispatch(updateLatestScanByBranchOrTag(branch || tag, refType, repoScans[0]));
        return res;
      },
      error => {
        ErrorService.capture('Error fetching project scan by branch or tag', error);
        return {};
      }
    )
    .catch(error => {
      ErrorService.capture('Error fetching project scan by branch or tag', error);
      return {};
    });
};

export const fetchProjectScanById = (teamId, scanId) => (dispatch, getState) => {
  const state = getState();
  const endpoint = createScansEndpoint(teamId, 0, 1); // Retrieves a size of 1 to get the latest
  const scopeForRequest = getScopeForRequest(state);

  scopeForRequest.refs = {
    [scopeForRequest.repos[0]]: { commit: null, branch: null, tag: null }, // Removes scope to get all scans across branches
  };

  const options = {
    ...scopeForRequest,
    repoScanId: scanId,
  };

  dispatch(fetchProjectScanByIdRequest());

  return ApiService.post(endpoint, { data: options })
    .then(
      res => {
        const { _embedded = {} } = res;
        const { repoScans = [] } = _embedded;
        dispatch(updateSelectedProjectScan(repoScans[0]));
        return res;
      },
      error => {
        dispatch(fetchProjectScanByIdFailure(error));
        ErrorService.capture('Error fetching project scan by id', error);
        return {};
      }
    )
    .catch(error => {
      dispatch(fetchProjectScanByIdFailure(error));
      ErrorService.capture('Error fetching project scan by id', error);
      return {};
    });
};
/**
 * fetchProjectScans
 *
 * Fetches project scans based on the scope derived from the projectScanHistory state called `filters`.
 * Filters are set in repo#refreshProjectData and ProjectScanHistory#updateOptionsFilter
 */
export const fetchProjectScans = (teamId, pageNum?: number) => (dispatch, getState) => {
  const state = getState();
  const { projectScanHistory } = state;
  const { filters = {} } = projectScanHistory;
  const endpoint = createScansEndpoint(teamId, pageNum);
  const scopeForRequest = getScopeForRequest(state);
  const { scanFindings, refs: refsFilter = {} } = filters;
  const { ref, refType } = refsFilter;
  const scopeOptions =
    ref && refType
      ? {
          [refType]: ref,
        }
      : {
          branch: null,
          tag: null,
        };
  scopeForRequest.refs = {
    [scopeForRequest.repos[0]]: { commit: null, ...scopeOptions },
  };
  const options = {
    ...scopeForRequest,
    repoScanId: '', //For Scan History, repoScanId need not be specified
    scanFindings,
  };

  dispatch(fetchProjectScansRequest());

  return ApiService.post(endpoint, { data: options })
    .then(
      res => {
        dispatch(fetchProjectScansSuccess(res));
        return res;
      },
      error => {
        dispatch(fetchProjectScansFailure(error));
        ErrorService.capture('Error fetching project scans', error);
        return {};
      }
    )
    .catch(error => {
      dispatch(fetchProjectScansFailure(error));
      ErrorService.capture('Error fetching project scans', error);
      return {};
    });
};
