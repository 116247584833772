import React from 'react';

const InsightsWrapper: React.FunctionComponent = props => {
  const { children } = props;
  return (
    <div className="grid height--120 insights-wrapper" data-automation-id="InsightsWrapper">
      {children}
    </div>
  );
};

export default InsightsWrapper;
