import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as integrationModalActions from '~/actions/integrationModal';

interface UserEditCheckboxProps {
  integrationModalActions: object;
  integrationModalState: object;
  fieldName: string;
}
class UserEditCheckbox extends React.Component<UserEditCheckboxProps, {}> {
  constructor(props, context) {
    super(props, context);
    this.handleCbxChang = this.handleCbxChange.bind(this);
  }

  handleCbxChange(fieldType) {
    const { integrationModalState } = this.props;
    const { fields = {} } = integrationModalState;

    const currentChckedState = fields[fieldType];
    this.props.integrationModalActions.updateIntegrationModalField(fieldType, !currentChckedState);
  }

  render() {
    const { integrationModalState, fieldName = '' } = this.props;
    const { fields = {} } = integrationModalState;
    const key = fieldName;

    return (
      <div className="flex flex--align-items--center flex--justify-content--center">
        <label className="label--checkbox flex mb0">
          <div className="flex">
            <input
              type="checkbox"
              name={`ticketTemplate-${key}`}
              id={key}
              onChange={() => this.handleCbxChange(`jiraField-${key}-userEdit`)}
              checked={fields[`jiraField-${key}-userEdit`] || false}
            />
            <span className={`react--checkbox mr0`} />
          </div>
        </label>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationModalState: state.integrationModalState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditCheckbox);
