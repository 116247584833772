import React from 'react';

interface QuickviewOpenIconProps {
  className?: string;
  sizeClass?: string;
}

const QuickviewOpenIcon: React.FunctionComponent<QuickviewOpenIconProps> = props => {
  return (
    <span className={`${props.className} ${props.sizeClass}`}>
      <i className="sci sci--sm sci__caret--down" />
    </span>
  );
};

export default QuickviewOpenIcon;
