import React from 'react';

interface TabWrapperProps {
  className?: string;
}

const TabWrapper: React.FunctionComponent<TabWrapperProps> = props => {
  const { children, className } = props;

  return <div className={className}>{children}</div>;
};

export default TabWrapper;
