import React from 'react';
import _ from 'lodash';

export const FETCH_INTEGRATIONS_REQUEST = 'FETCH_INTEGRATIONS_REQUEST';
export const FETCH_INTEGRATIONS_SUCCESS = 'FETCH_INTEGRATIONS_SUCCESS';
export const FETCH_INTEGRATIONS_FAILURE = 'FETCH_INTEGRATIONS_FAILURE';
export const JIRA_INFO_REQUEST = 'JIRA_INFO_REQUEST';
export const JIRA_INFO_SUCCESS = 'JIRA_INFO_SUCCESS';
export const JIRA_INFO_FAILURE = 'JIRA_INFO_FAILURE';
export const DELETE_INTEGRATIONS_REQUEST = 'DELETE_INTEGRATIONS_REQUEST';
export const DELETE_INTEGRATIONS_SUCCESS = 'DELETE_INTEGRATIONS_SUCCESS';
export const DELETE_INTEGRATIONS_FAILURE = 'DELETE_INTEGRATIONS_FAILURE';

const jiraIntegrationAccessErrorMessage = 'Sorry, we are unable to access your Jira instance';

import * as MODEL from '~/constants/ModelConstants';
import ApiConstants from '~/constants/ApiConstants';
import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import * as toastrActions from '~/actions/toastr';
import * as integrationModalActions from '~/actions/integrationModal';
import * as integrationByIdActions from '~/actions/integrationById';
import * as modalActions from '~/actions/modal';
import * as reportIssuesActions from '~/actions/reportIssues';

export const fetchIntegrationRequest = () => ({
  type: FETCH_INTEGRATIONS_REQUEST,
});

export const fetchIntegrationSuccess = (response, concat) => ({
  type: FETCH_INTEGRATIONS_SUCCESS,
  response,
  concat,
});

export const fetchIntegrationFailure = error => ({
  type: FETCH_INTEGRATIONS_FAILURE,
  message: error.message || 'Something went wrong. Please try again.',
});

export const fetchIntegrations = (pageNum: string = '0', concat?: boolean) => dispatch => {
  dispatch(fetchIntegrationRequest());

  return ApiService.get(ApiConstants.integrationsURL(pageNum))
    .then((res: JiraIntegration.JiraIntegrationsResponse) => {
      dispatch(fetchIntegrationSuccess(res, concat));
    })
    .catch(err => {
      dispatch(fetchIntegrationFailure(err));
      ErrorService.capture('Unable to load integrations :', err);
    });
};

export const deleteIntegrationRequest = () => ({
  type: DELETE_INTEGRATIONS_REQUEST,
});

export const deleteIntegrationSuccess = () => ({
  type: DELETE_INTEGRATIONS_SUCCESS,
});

export const deleteIntegrationFailure = error => ({
  type: DELETE_INTEGRATIONS_FAILURE,
  message: error.message || 'Something went wrong. Please try again.',
});

export const deleteIntegration = integrationList => dispatch => {
  dispatch(deleteIntegrationRequest());

  const deletePromises = integrationList.map(integration =>
    ApiService.del(ApiConstants.deleteIntegrationURL(integration.id))
  );

  Promise.all(deletePromises)
    .then(() => {
      dispatch(deleteIntegrationSuccess());
      dispatch(integrationModalActions.closeIntegrationModal());
      dispatch(fetchIntegrations());
    })
    .catch(err => {
      ErrorService.capture('Unable to delete integrations :', err);
      dispatch(deleteIntegrationFailure(err));
    });
};

export const jiraInfoRequest = (infoType: string) => ({
  type: JIRA_INFO_REQUEST,
  infoType,
});

export const jiraInfoSuccess = (infoType: string, response) => ({
  type: JIRA_INFO_SUCCESS,
  infoType,
  response,
});

export const jiraInfoFailure = (infoType: string, err) => ({
  type: JIRA_INFO_FAILURE,
  infoType,
  message: err.message || 'Something went wrong. Please try again.',
});

export const getJiraProjectDetail = integrationId => dispatch => {
  dispatch(jiraInfoRequest(MODEL.JIRA_PROJECTS));

  return ApiService.get(ApiConstants.getJiraProjectDetailURL(integrationId))
    .then((res: JiraIntegration.JiraProjectsResponse) => {
      const {
        _embedded: { projects = [] },
      } = res;
      dispatch(jiraInfoSuccess(MODEL.JIRA_PROJECTS, projects));
    })
    .catch(err => {
      ErrorService.capture('Unable to fetch Jira project :', err);
      dispatch(
        jiraInfoFailure(MODEL.JIRA_PROJECTS, {
          ...err,
          message: jiraIntegrationAccessErrorMessage,
        })
      );
    });
};

export const getJiraProjectIssueTypes = (
  integrationId: string,
  projectKey: string,
  preSelectedIssueType?
) => (dispatch, getState) => {
  const {
    integrationModalState: {
      fields: { issueTypeVal },
    },
  } = getState();
  dispatch(jiraInfoRequest(MODEL.JIRA_ISSUE_TYPES));
  return ApiService.get(ApiConstants.getJiraProjectIssueTypesURL(integrationId, projectKey))
    .then((res: JiraIntegration.JiraIssueTypesResponse) => {
      const {
        _embedded: { values = [] },
      } = res;

      dispatch(jiraInfoSuccess(MODEL.JIRA_ISSUE_TYPES, values));
      // make matching issue type immediately available when editing a template
      return values.filter(
        value => value.name === issueTypeVal || value.name === preSelectedIssueType
      )[0];
    })
    .catch(err => {
      ErrorService.capture('Unable to fetch Jira issue types :', err);
      dispatch(
        jiraInfoFailure(MODEL.JIRA_ISSUE_TYPES, {
          ...err,
          message: jiraIntegrationAccessErrorMessage,
        })
      );
    });
};

export const getJiraAssignees = (integrationId, projectKey) => dispatch => {
  dispatch(jiraInfoRequest(MODEL.JIRA_ASSIGNEES));
  return ApiService.get(ApiConstants.getJiraUsersURL(integrationId, projectKey))
    .then((res: JiraIntegration.JiraUsersResponse) => {
      const {
        _embedded: { values: valueList },
      } = res;

      dispatch(jiraInfoSuccess(MODEL.JIRA_ASSIGNEES, valueList));
    })
    .catch(err => {
      ErrorService.capture('Unable to fetch Jira user :', err);
      dispatch(
        jiraInfoFailure(MODEL.JIRA_ASSIGNEES, {
          ...err,
          message: jiraIntegrationAccessErrorMessage,
        })
      );
    });
};

export const asyncGetJiraAssignees = (integrationId, projectKey, id) => dispatch => {
  dispatch(jiraInfoRequest(MODEL.JIRA_ASSIGNEES));
  return ApiService.get(ApiConstants.getJiraUsersByIdURL(integrationId, projectKey, id))
    .then((res: JiraIntegration.JiraUsersResponse) => {
      const {
        _embedded: { values: valueList },
      } = res;

      dispatch(jiraInfoSuccess(MODEL.JIRA_ASSIGNEES, valueList));
    })
    .catch(err => {
      ErrorService.capture('Unable to fetch Jira user :', err);
      dispatch(
        jiraInfoFailure(MODEL.JIRA_ASSIGNEES, {
          ...err,
          message: jiraIntegrationAccessErrorMessage,
        })
      );
    });
};

export const asyncGetJiraReporters = (integrationId, projectKey, id) => dispatch => {
  dispatch(jiraInfoRequest(MODEL.JIRA_REPORTERS));
  return ApiService.get(ApiConstants.getJiraUsersByIdURL(integrationId, projectKey, id))
    .then((res: JiraIntegration.JiraUsersResponse) => {
      const {
        _embedded: { values: valueList },
      } = res;

      dispatch(jiraInfoSuccess(MODEL.JIRA_REPORTERS, valueList));
    })
    .catch(err => {
      ErrorService.capture('Unable to fetch Jira user :', err);
      dispatch(
        jiraInfoFailure(MODEL.JIRA_REPORTERS, {
          ...err,
          message: jiraIntegrationAccessErrorMessage,
        })
      );
    });
};

export const getJiraReporters = (integrationId, projectKey) => dispatch => {
  dispatch(jiraInfoRequest(MODEL.JIRA_REPORTERS));

  return ApiService.get(ApiConstants.getJiraUsersURL(integrationId, projectKey))
    .then((res: JiraIntegration.JiraUsersResponse) => {
      const {
        _embedded: { values: valueList },
      } = res;
      dispatch(jiraInfoSuccess(MODEL.JIRA_REPORTERS, valueList));
    })
    .catch(err => {
      ErrorService.capture('Unable to fetch Jira user :', err);
      dispatch(
        jiraInfoFailure(MODEL.JIRA_REPORTERS, {
          ...err,
          message: jiraIntegrationAccessErrorMessage,
        })
      );
    });
};

export const getJiraEpics = (integrationId, projectKey) => dispatch => {
  dispatch(jiraInfoRequest(MODEL.JIRA_EPICS));

  return ApiService.get(ApiConstants.getJiraEpicsURL(integrationId, projectKey))
    .then((res: JiraIntegration.JiraEpicsResponse) => {
      const {
        _embedded: { epicIssues: epicIssueList },
      } = res;
      dispatch(jiraInfoSuccess(MODEL.JIRA_EPICS, epicIssueList));
    })
    .catch(err => {
      ErrorService.capture('Unable to fetch Jira epics :', err);
      dispatch(
        jiraInfoFailure(MODEL.JIRA_EPICS, {
          ...err,
          message: jiraIntegrationAccessErrorMessage,
        })
      );
    });
};

export const getJiraLabels = (integrationId, searchKey) => dispatch => {
  dispatch(jiraInfoRequest(MODEL.JIRA_LABELS));

  return ApiService.get(ApiConstants.getJiraLabelsURL(integrationId, searchKey))
    .then((res: JiraIntegration.JiraLabelsResponse) => {
      const {
        _embedded: { values: valueList },
      } = res;
      dispatch(jiraInfoSuccess(MODEL.JIRA_LABELS, valueList));
    })
    .catch(err => {
      ErrorService.capture('Unable to fetch Jira labels :', err);
      dispatch(
        jiraInfoFailure(MODEL.JIRA_LABELS, {
          ...err,
          message: jiraIntegrationAccessErrorMessage,
        })
      );
    });
};

export const getJiraStatus = (path: string) => dispatch => {
  dispatch(jiraInfoRequest(MODEL.JIRA_STATUS));

  return ApiService.get(path)
    .then((res: JiraIntegration.JiraStatusesResponse) => {
      const {
        _embedded: { statuses: statusList },
      } = res;
      dispatch(jiraInfoSuccess(MODEL.JIRA_STATUS, statusList));
    })
    .catch(err => {
      ErrorService.capture('Unable to fetch Jira status :', err);
      dispatch(
        jiraInfoFailure(MODEL.JIRA_STATUS, {
          ...err,
          message: jiraIntegrationAccessErrorMessage,
        })
      );
    });
};

export const getJiraFields = (path: string) => dispatch => {
  dispatch(jiraInfoRequest(MODEL.JIRA_FIELDS));

  return ApiService.get(path)
    .then((res: JiraIntegration.JiraFieldsResponse) => {
      dispatch(jiraInfoSuccess(MODEL.JIRA_FIELDS, res));
    })
    .catch(err => {
      ErrorService.capture('Unable to fetch Jira fields :', err);
      dispatch(
        jiraInfoFailure(MODEL.JIRA_FIELDS, {
          ...err,
          message: jiraIntegrationAccessErrorMessage,
        })
      );
    });
};

export const addJiraLabels = labelToAdd => (dispatch, getState) => {
  const { jiraInfo = {} } = getState().integrationState;
  const { [MODEL.JIRA_LABELS]: labelResponse = {} } = jiraInfo;
  const { data: labels = [] } = labelResponse;

  labels.push({ label: labelToAdd, html: labelToAdd });
  dispatch(jiraInfoSuccess(MODEL.JIRA_LABELS, labels));
};

export const createTicketTemplate = (integrationId, options) => dispatch => {
  dispatch(jiraInfoRequest(MODEL.CREATE_JIRA_TICKET_TEMPLATE));
  return ApiService.post(ApiConstants.jiraTemplateURL(integrationId), {
    data: options,
  })
    .then(res => {
      dispatch(jiraInfoSuccess(MODEL.CREATE_JIRA_TICKET_TEMPLATE, res));
      dispatch(integrationModalActions.closeIntegrationModal());
      dispatch(integrationByIdActions.fetchTicketTemplate(integrationId));
    })
    .catch(err => {
      dispatch(jiraInfoFailure(MODEL.CREATE_JIRA_TICKET_TEMPLATE, err));
      ErrorService.capture('Unable to create issue template :', err);
    });
};

export const editTicketTemplate = (integrationId, template, options) => dispatch => {
  dispatch(jiraInfoRequest(MODEL.EDIT_TICKET_TEMPLATE_MODAL));
  return ApiService.put(ApiConstants.updateJiraTemplateURL(integrationId, template.id), {
    data: options,
  })
    .then(res => {
      dispatch(jiraInfoSuccess(MODEL.EDIT_TICKET_TEMPLATE_MODAL, res));
      dispatch(integrationModalActions.closeIntegrationModal());
      dispatch(integrationByIdActions.fetchTicketTemplate(integrationId));
    })
    .catch(err => {
      dispatch(jiraInfoFailure(MODEL.EDIT_TICKET_TEMPLATE_MODAL, err));
      ErrorService.capture('Unable to save issue template :', err);
    });
};

export const createJiraTicket = (
  integration,
  selectedProject,
  selectedIssueType,
  modalFields,
  fields,
  selectedIssue,
  selectedTemplate
) => (dispatch, getState) => {
  const options = {
    fields: {
      project: {
        id: selectedProject.id,
      },
      issuetype: {
        id: selectedIssueType,
      },
    },
    templateId: selectedTemplate.id,
    issueType: selectedIssue.type,
  };

  for (let key in fields) {
    const fieldName = key.split('-')[1];

    if (_.isEmpty(fields[key])) {
      continue;
    }

    // assignee and reporter fields
    if (modalFields[fieldName].schema.type === 'user') {
      const {
        integrationState: { jiraInfo },
        integrationModalState: { fields },
      } = getState();
      const userField = fields[`jiraField-${fieldName}`];
      const jiraUserInfo = `JIRA_${fieldName.toUpperCase()}S`;
      const { data } = jiraInfo[jiraUserInfo];

      const userByIdOrName = data.find(
        d => d.accountId === userField || d.name === userField || d.displayName === userField
      );

      // transform Jira API's accountId to SourceClear id
      const jiraUserInfoWithIds = _.mapKeys(userByIdOrName, (v, k) => {
        if (k === 'accountId') return 'id';
        return k;
      });

      options.fields[fieldName] = jiraUserInfoWithIds;
    } else if (modalFields[fieldName].schema.system === 'fixVersions') {
      options.fields[fieldName] = [];
      const { allowedValues } = modalFields[fieldName];

      fields[key].split(',').forEach(val => {
        allowedValues.map(value => {
          if (value.name === val) {
            options.fields[fieldName].push({ id: value.id });
          }
        });
      });
    } else if (modalFields[fieldName].schema.system === 'labels') {
      options.fields[fieldName] = fields[key].split(',').filter(x => x.length > 0);
    } else if (modalFields[fieldName].schema.type === 'priority') {
      options.fields[fieldName] = { id: fields[key].toString() };
    } else if (modalFields[fieldName].schema.type === 'string') {
      options.fields[fieldName] = fields[key];
    } else if (
      modalFields[fieldName].schema.custom === 'com.pyxis.greenhopper.jira:gh-epic-link' ||
      modalFields[fieldName].name === 'Epic Link'
    ) {
      if (!_.isEmpty(fields[key])) {
        options.fields[fieldName] = fields[key];
      }
    } else if (modalFields[fieldName].schema.type === 'number') {
      options.fields[fieldName] = Number(fields[key]);
    } else if (modalFields[fieldName].schema.custom === MODEL.JIRA_SCHEMA.MULTI_SELECT) {
      const values = fields[key]
        .split(',')
        .filter(val => val.length > 0)
        .map(val => ({ value: val }));
      if (values.length > 0) {
        options.fields[fieldName] = values;
      }
    }
  }

  dispatch(jiraInfoRequest(MODEL.CREATE_JIRA_TICKET));
  return ApiService.post(ApiConstants.createJiraTicketURL(integration.id, selectedIssue.id), {
    data: options,
  })
    .then(res => {
      dispatch(jiraInfoSuccess(MODEL.CREATE_JIRA_TICKET, res));
      dispatch(modalActions.closeModal(MODEL.CREATE_ISSUE_JIRA_CLOUD_MODAL));
      dispatch(
        toastrActions.addToastr({
          id: `JIRA-ISSUE-CREATED-${res.jiraKey}`,
          level: 'success',
          title: 'Jira issue successfully created',
          message: (
            <span>
              {' '}
              The new issue ID is{' '}
              <a
                className="link--obvious"
                href={`${res.integrationServiceUrl}/browse/${res.jiraKey}`}
                target="_blank"
              >
                {res.jiraKey}
              </a>
            </span>
          ),
          position: 'topCenter',
        })
      );
      dispatch(
        reportIssuesActions.getIntegrationIssuesCreated(selectedIssue.id, selectedIssue.lastScan.id)
      );
    })
    .catch(err => {
      ErrorService.capture('Unable to create Jira issue :', err);
      dispatch(jiraInfoFailure(MODEL.CREATE_JIRA_TICKET, err));
    });
};
