import React from 'react';
import _ from 'lodash';
import FullChainDisplay from '~/components/ReportPage/QuickviewVulnMethod/FullChainDisplay';
import VulnMethodDescription from '~/components/ReportPage/QuickviewVulnMethod/VulnMethodDescription';

interface VulnMethodItemProps {
  data: object;
  language: string;
  expandedVulnerableMethodChain: string;
  toggleFullCallChain: (...args: any[]) => any;
}

const VulnMethodItem: React.FunctionComponent<VulnMethodItemProps> = props => {
  function toggleFullCallChain(chain) {
    props.toggleFullCallChain(chain);
  }

  const { data, language, expandedVulnerableMethodChain } = props;
  const vulnerableMethods = data.vulnerableMethods || [];
  const vulnerableMethodElements = [];

  vulnerableMethods.forEach((vm, index) => {
    const callChains = vm.callChains || [];
    const callChainCount = callChains.length;
    const callChainDescriptions = [];
    const usedCallChains = [];

    callChains.forEach(chain => {
      // find the first instance of an internal caller, and then use the call just before that
      // that's the caller that's closest in the chain of invoking the vuln method that the user has control over
      // if no calls are internal, use the last caller in the chain
      const firstInternalCallIndex = _.findIndex(chain, chainItem => {
        return chainItem.internal;
      });

      let applicableIndex: number;

      if (firstInternalCallIndex === -1) {
        applicableIndex = chain.length - 1;
      } else if (firstInternalCallIndex > 0) {
        applicableIndex = firstInternalCallIndex - 1;
      } else {
        applicableIndex = 0;
      }

      const applicableChain = chain[applicableIndex];
      const caller = applicableChain.caller;
      const callee = applicableChain.callee;
      const lineNumber = applicableChain.lineNumber;
      const callChainIdentifier = `${caller.methodName}-${caller.className}-${lineNumber}`;
      const isChainExpanded = expandedVulnerableMethodChain === callChainIdentifier;

      if (usedCallChains.indexOf(callChainIdentifier) === -1) {
        callChainDescriptions.push(
          <div key={`call-chain--${usedCallChains.length + 1}`} className="grid">
            <div className="grid__item col-1-1">
              <div className="pt- pv--">
                <span className="text--bold">Caller #{usedCallChains.length + 1}:</span>
                <span
                  className="link--obvious cursor--pointer pl"
                  onClick={toggleFullCallChain.bind(null, callChainIdentifier)}
                >
                  {`${isChainExpanded ? 'Hide' : 'Show'} full call chain`}
                </span>
              </div>
              <FullChainDisplay
                callChain={chain}
                startIndex={applicableIndex}
                isShowing={isChainExpanded}
                toggleFullCallChain={toggleFullCallChain}
              />

              <VulnMethodDescription language={language} method={caller} lineNumber={lineNumber} callee={callee} />
            </div>
          </div>
        );
        usedCallChains.push(callChainIdentifier);
      }
    });

    let furtherDescriptionMessage =
      callChainCount === usedCallChains.length
        ? 'in your code:'
        : 'across ' +
          usedCallChains.length +
          ' common entry point' +
          (usedCallChains.length === 1 ? '' : 's') +
          ' into the vulnerable library code:';

    vulnerableMethodElements.push(
      <div key={`vuln-method--${index}`} className="grid">
        <p className="grid__item text--bold">Vulnerable method:</p>
        <div className="grid__item col-1-1">
          <VulnMethodDescription language={language} method={vm.method} />
        </div>
        <div className="grid__item col-1-1 pt-">
          We found {callChainCount} invocation
          {callChainCount !== 1 ? 's' : ''} of the above vulnerable method{' '}
          {furtherDescriptionMessage}
        </div>
        <div className="grid__item col-1-1 ">{callChainDescriptions}</div>
      </div>
    );
  });

  return (
    <div className="grid pt- pl">
      <div className="grid__item col-1-1">{vulnerableMethodElements}</div>
    </div>
  );
};

export default VulnMethodItem;
