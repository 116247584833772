const ArtifactHelper = function () {
  const isArtifactPremium = function (artifact) {
    const cveStatus = artifact && artifact.cveStatus && artifact.cveStatus.toUpperCase();
    return cveStatus !== 'FINAL';
  };

  const isArtifactPremiumByStatus = function (status) {
    const cveStatus = status && status.toUpperCase();
    return cveStatus !== 'FINAL';
  };

  return {
    isArtifactPremium,
    isArtifactPremiumByStatus,
  };
};

export default new ArtifactHelper();
