import * as actions from '~/actions/orgUser';

export const defaultState = {
  orgUsers: [],
  pendingInvites: [],
  selectedOrgUsers: [],
  selectedPendingInvites: [],
  filteredOrgUsers: [],
  filteredPendingInvites: [],
  userGroups: undefined,
  filteredUserGroups: undefined,
  selectedUserGroups: {},
  showModal: undefined,
  createNewGroupForUsers: false,
  sortAscending: true,
  sortColumn: 'email',
  userGroupsSortColumn: 'name',
  userWorkspacesSortColumn: 'name',
  quickViewsByUserId: {},
  quickViewsByInviteId: {},
  quickViewsByUserWorkspaceId: {},
  isFetchingUserGroups: false,
  isFetchingUserWorkspaces: false,
  userWorkspaces: undefined,
  filteredUserWorkspaces: undefined,
  activeTab: 'GROUPS',
};

const orgUserState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_ORG_USERS:
      return {
        ...state,
        orgUsers: action.users,
        filteredOrgUsers: sort(action.users, state.sortColumn, state.sortAscending, 'user'),
        selectedOrgUsers: [],
      };
    case actions.UPDATE_PENDING_INVITES:
      return {
        ...state,
        pendingInvites: action.invites,
        filteredPendingInvites: action.invites,
      };
    case actions.UPDATE_SELECTED_USERS: {
      const selectedMatch = state.selectedOrgUsers.some(user => user.userId === action.userId);

      return {
        ...state,
        selectedOrgUsers: selectedMatch
          ? state.selectedOrgUsers.filter(user => user.userId !== action.userId)
          : state.selectedOrgUsers.concat({ userId: action.userId, admin: false }),
      };
    }
    case actions.UPDATE_SELECTED_PENDING_INVITES: {
      const { selectedPendingInvites = [] } = state;
      const filteredPendingInvites = selectedPendingInvites.filter(
        user => user.inviteId !== action.inviteId
      );

      return {
        ...state,
        selectedPendingInvites:
          filteredPendingInvites.length !== selectedPendingInvites.length
            ? filteredPendingInvites
            : selectedPendingInvites.concat({ inviteId: action.inviteId }),
      };
    }
    case actions.TOGGLE_SELECTED_USER_ADMIN_FIELD:
      return {
        ...state,
        selectedOrgUsers: state.selectedOrgUsers.map(user => {
          if (user.userId === action.userId) {
            user.admin = !user.admin;
          }
          return user;
        }),
      };
    case actions.TOGGLE_SELECTED_INVITE_ADMIN_FIELD:
      return {
        ...state,
        pendingInvites: state.pendingInvites.map(invite => {
          if (invite.email === action.email) {
            invite.stagedAdminStatus = !invite.stagedAdminStatus;
          }
          return invite;
        }),
      };
    case actions.UPDATE_ORG_USERS_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab,
      };
    case actions.RESET_SELECTED_USERS:
      return {
        ...state,
        selectedOrgUsers: [],
      };
    case actions.RESET_SELECTED_PENDING_INVITES:
      return {
        ...state,
        selectedPendingInvites: [],
      };
    case actions.FILTER_USERS_BY_INPUT:
      return {
        ...state,
        filteredOrgUsers: sort(
          masterUsersFilter(state.orgUsers, action.value),
          state.sortColumn,
          state.sortAscending,
          'user'
        ),
      };
    case actions.FILTER_PENDING_INVITES_BY_INPUT:
      return {
        ...state,
        filteredPendingInvites: masterUsersFilter(state.pendingInvites, action.value),
      };
    case actions.SHOW_USER_MODAL:
      return {
        ...state,
        showModal: action.modalType,
      };
    case actions.TOGGLE_USER_GROUP_ADMIN:
      return {
        ...state,
        orgUsers: state.orgUsers.map(userObj => {
          if (userObj.user.id === action.userId) {
            userObj.user.stagedAdminStatus = !userObj.user.stagedAdminStatus;
          }
          return userObj;
        }),
        selectedOrgUsers: state.selectedOrgUsers.map(user => {
          return {
            ...user,
            admin: user.userId === action.userId ? !user.admin : user.admin,
          };
        }),
      };
    case actions.TOGGLE_CREATE_NEW_GROUP:
      return {
        ...state,
        createNewGroupForUsers: !state.createNewGroupForUsers,
      };
    case actions.UPDATE_SELECTED_USER_GROUPS:
      return {
        ...state,
        selectedUserGroups: {
          ...state.selectedUserGroups,
          [action.groupId]: !state.selectedUserGroups[action.groupId],
        },
      };
    case actions.RESET_SELECTED_USER_GROUPS:
      return {
        ...state,
        selectedUserGroups: {},
      };
    case actions.SELECT_ALL_PENDING_INVITES:
      return {
        ...state,
        selectedPendingInvites: action.selectAll
          ? state.pendingInvites.map(invitee => {
              return { inviteId: invitee.id };
            })
          : [],
      };
    case actions.SET_USER_DETAILS:
      return {
        ...state,
        userGroups: action.groups,
        filteredUserGroups: sort(action.groups, state.userGroupsSortColumn, state.sortAscending),
        isFetchingUserGroups: false,
      };
    case actions.SET_USER_WORKSPACES_DETAILS:
      return {
        ...state,
        userWorkspaces: action.workspaces,
        filteredUserWorkspaces: sort(
          action.workspaces,
          state.userWorkspacesSortColumn,
          state.sortAscending
        ),
        isFetchingUserWorkspaces: false,
      };
    case actions.RESET_USER_DETAILS:
      return {
        ...state,
        userGroups: undefined,
        filteredUserGroups: undefined,
        isFetchingUserGroups: false,
      };
    case actions.FILTER_USER_GROUPS:
      return {
        ...state,
        filteredUserGroups: sort(
          filterUserGroups(state.userGroups, action.value),
          state.userGroupsSortColumn,
          state.sortAscending
        ),
      };
    case actions.FILTER_USER_WORKSPACES:
      return {
        ...state,
        filteredUserWorkspaces: sort(
          filterUserWorkspaces(state.userWorkspaces, action.value),
          state.userWorkspacesSortColumn,
          state.sortAscending
        ),
      };
    case actions.UPDATE_ORG_USER_SORT: {
      const directionToSort = state.sortColumn === action.column ? !state.sortAscending : true;

      return {
        ...state,
        sortColumn: action.column,
        filteredOrgUsers: sort(state.filteredOrgUsers, action.column, directionToSort, 'user'),
        sortAscending: directionToSort,
      };
    }
    case actions.UPDATE_ORG_USER_GROUPS_SORT: {
      const directionToSort =
        state.userGroupsSortColumn === action.column ? !state.sortAscending : true;

      return {
        ...state,
        userGroupsSortColumn: action.column,
        filteredUserGroups: sort(state.filteredUserGroups, action.column, directionToSort),
        sortAscending: directionToSort,
      };
    }
    case actions.RESET_USER_SORT:
      return {
        ...state,
        sortColumn: 'email',
        sortAscending: true,
      };
    case actions.TOGGLE_USER_QUICK_VIEW:
      return {
        ...state,
        quickViewsByUserId: {
          [action.userId]:
            state.quickViewsByUserId[action.userId] === action.column ? 'OFF' : action.column,
        },
      };
    case actions.TOGGLE_INVITE_QUICK_VIEW:
      return {
        ...state,
        quickViewsByInviteId: {
          [action.inviteId]:
            state.quickViewsByInviteId[action.inviteId] === action.column ? 'OFF' : action.column,
        },
      };
    case actions.TOGGLE_USER_WORKSPACES_QUICK_VIEW:
      return {
        ...state,
        quickViewsByUserWorkspaceId: {
          [action.workspaceId]:
            state.quickViewsByUserWorkspaceId[action.workspaceId] === action.column
              ? 'OFF'
              : action.column,
        },
      };
    case actions.RESET_USER_QUICK_VIEWS:
      return {
        ...state,
        quickViewsByUserId: {},
      };
    case actions.RESET_USER_WORKSPACES_QUICK_VIEWS:
      return {
        ...state,
        quickViewsByUserWorkspaceId: {},
      };
    case actions.UPDATE_IS_FETCHING_ORG_GROUPS:
      return {
        ...state,
        isFetchingUserGroups: action.bool,
      };
    case actions.UPDATE_IS_FETCHING_ORG_USER_WORKSPACES:
      return {
        ...state,
        isFetchingUserWorkspaces: action.bool,
      };
    default:
      return state;
  }
};

export default orgUserState;

function sort(set, column, sortAscending, type?) {
  set.sort((a, b) => {
    const valueA = type && a[type] ? a[type][column] : a[column];
    const valueB = type && b[type] ? b[type][column] : b[column];
    if (!valueA || !valueB) return 0;

    const formattedValueA = valueA.toLowerCase ? valueA.trim().toLowerCase() : valueA;
    const formattedValueB = valueB.toLowerCase ? valueB.trim().toLowerCase() : valueB;

    if (formattedValueA > formattedValueB) {
      return sortAscending ? 1 : -1;
    }
    if (formattedValueA < formattedValueB) {
      return sortAscending ? -1 : 1;
    }
    return 0;
  });

  return set;
}

function masterUsersFilter(users, value) {
  return filterUsersByInput(users, value);
}

function filterUsersByInput(users, value) {
  const trimmedValue = value.trim().toLowerCase();
  return users.filter(userObj => {
    const user = userObj.user ? userObj.user : userObj;
    const { email = '', firstName = '', lastName = '' } = user;
    const groups = userObj.groups || [];
    const teams = userObj.teams || [];

    if (
      email.toLowerCase().includes(trimmedValue) ||
      firstName.toLowerCase().includes(trimmedValue) ||
      lastName.toLowerCase().includes(trimmedValue)
    ) {
      return true;
    }

    const groupMatch = groups.find(group => group.name.toLowerCase().includes(trimmedValue));

    if (groupMatch) {
      return true;
    }

    const teamMatch = teams.find(team => team.name.toLowerCase().includes(trimmedValue));

    if (teamMatch) {
      return true;
    }

    return false;
  });
}

function filterUserGroups(groups = [], value) {
  if (!groups.length) return groups;

  const trimmedValue = value.trim().toLowerCase();
  return groups.filter(groupObj => {
    const { group = {} } = groupObj;
    const { name = '' } = group;
    const description = group.description || '';

    if (
      name.toLowerCase().includes(trimmedValue) ||
      description.toLowerCase().includes(trimmedValue)
    ) {
      return true;
    }

    return false;
  });
}

function filterUserWorkspaces(workspaces = [], value = '') {
  if (!workspaces.length) return workspaces;

  const trimmedValue = value.trim().toLowerCase();
  return workspaces.filter(workspaceObj => {
    const { team = {} } = workspaceObj;
    const { name = '' } = team;

    if (name.toLowerCase().includes(trimmedValue)) {
      return true;
    }

    return false;
  });
}
