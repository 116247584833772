import React from 'react';
import TabHeader from '~/components/TabHeader';
import TabItem from '~/components/TabItem';

interface CIScriptModeTabHeaderProps {
  onScriptTabChange?: (...args: any[]) => any;
  activeScriptMode?: string;
  className?: string;
}

const CIScriptModeTabHeader: React.FunctionComponent<CIScriptModeTabHeaderProps> = props => {
  const { className = 'mb--', activeScriptMode } = props;

  function handleClick(field) {
    props.onScriptTabChange(field);
  }

  return (
    <TabHeader className={className}>
      <TabItem
        field={'curl'}
        onClick={handleClick}
        isActive={activeScriptMode === 'curl'}
        className={`bo-rad--left-3`}
      >
        curl
      </TabItem>
      <TabItem
        field={'powershell'}
        onClick={handleClick}
        isActive={activeScriptMode === 'powershell'}
        className={`bo-rad--right-3`}
      >
        PowerShell
      </TabItem>
    </TabHeader>
  );
};

export default CIScriptModeTabHeader;
