import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';

export const RESET_USER_STATUS = 'RESET_USER_STATUS';
export const FETCH_USER_STATUS_REQUEST = 'FETCH_USER_STATUS_REQUEST';
export const FETCH_USER_STATUS_SUCCESS = 'FETCH_USER_STATUS_SUCCESS';
export const FETCH_USER_STATUS_FAILURE = 'FETCH_USER_STATUS_FAILURE';

export const fetchUserStatus = () => dispatch => {
  dispatch(fetchUserStatusRequest());

  return ApiService.get('/user-status')
    .then((response = {}) => {
      const { roles = [] } = response;
      dispatch(fetchUserStatusSuccess(roles));
      return response;
    })
    .catch(error => {
      ErrorService.capture('Error fetching user-status', error);
      dispatch(fetchUserStatusFailure());
      return error;
    });
};

export const fetchUserStatusWithTokenSwap = sessionToken => dispatch => {
  dispatch(fetchUserStatusRequest());

  return ApiService.get('/user-status?upgrade=true', { headers: { 'x-auth-token': sessionToken } })
    .then((res = {}) => {
      const { roles = [] } = res;
      dispatch(fetchUserStatusSuccess(roles));
      return res;
    })
    .catch(error => {
      ErrorService.capture('Error fetching user-status', error);
      dispatch(fetchUserStatusFailure());
      return {};
    });
};

export const fetchUserStatusRequest = () => ({
  type: FETCH_USER_STATUS_REQUEST,
});

export const fetchUserStatusFailure = () => ({
  type: FETCH_USER_STATUS_FAILURE,
});

export const fetchUserStatusSuccess = roles => ({
  type: FETCH_USER_STATUS_SUCCESS,
  roles,
});

export const resetUserStatus = () => ({
  type: RESET_USER_STATUS,
});
