import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SAML_EMAIL } from '~/constants/ModelConstants';
import AuthService from '~/utils/AuthService';
import * as navActions from '~/actions/navigation';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import ApiService from '~/utils/ApiService';

const LogoutWrapper = WrappedComponent => {
  interface LogoutWrapperProps {
    navActions: object;
    url: string;
    myState: App.MyState;
    orgState: App.OrgState;
    vcPageState: VCPageState;
  }

  class LogoutWrapper extends React.Component<LogoutWrapperProps> {
    toggleSettingsDropdown() {
      this.props.navActions.toggleSettingsDropdown();
    }

    postLogout() {
      const { orgState } = this.props;
      const { org } = orgState;
      const isSaml = org.samlDomains && !!org.samlDomains.length;
      this.toggleSettingsDropdown();
      let url = isSaml ? '/saml' : '/login';
      window.location.href = url;
    }

    logout = () => {
      const {
        orgState,
        myState,
        url,
        vcPageState: { shouldShowVeracodePage },
      } = this.props;

      const { org } = orgState;
      const isSaml = org.samlDomains && !!org.samlDomains.length;
      const { me } = myState;
      const { email } = me;

      if (isSaml && email) {
        localStorage.setItem(SAML_EMAIL, email);
      }

      const tokenHeader = AuthService.getAuthToken();
      AuthService.logout(
        ApiService.post('/logout', { headers: { 'x-auth-token': tokenHeader } })
      ).then(() => {
        // if user is logging out of veracode, redirect user to login screen
        // otherwise, logout sourceclear user using standard logout
        if (shouldShowVeracodePage) {
          window.location.href = url;
        } else {
          this.postLogout();
        }
      });
    };

    render() {
      return <WrappedComponent {...this.props} onClick={this.logout} />;
    }
  }

  function mapStateToProps(state) {
    return {
      myState: state.myState,
      orgState: state.orgState,
      vcPageState: state.vcPageState,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      navActions: bindActionCreators(navActions, dispatch),
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(LogoutWrapper);
};

export default LogoutWrapper;
