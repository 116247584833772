/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

import { VCStartScanPageProps } from '~/containers/VCStartScanPageTypes/types';
import AgentScan from '~/components/SVGs/AgentScan';
import UploadScan from '~/components/SVGs/UploadScan';
import { Button, Header, Tooltip, ComplexModal, SimpleModal, Modal } from 'fluorine-ui';
import { veracodeCreateAppUrl, veracodeAppProfileUrl } from '~/constants/ModelConstants';

import VCCreateWorkspaceModal from '~/containers/VCCreateWorkspaceModal';
import CreateWorkspaceAgentModal from './CreateWorkspaceAgentModal';
import CreateNewAgentModal from '~/containers/CreateNewAgentModal';
import AgentExistsModal from '~/containers/AgentExistsModal';
import StartScanModal from './StartScanModal';

import { errorMessageTextMap } from '~/constants/ModelConstants';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import * as orgActions from '~/actions/org';
import * as startScanModalActions from '~/actions/startScanModal';
import * as agentModalActions from '~/actions/agentModal';
import * as toastrActions from '~/actions/toastr';

export enum ModalType {
  CreateWorkspace = 'CREATE_WORKSPACE',
  StartScan = 'START_SCAN',
  CreateNewAgent = 'CREATE_NEW_AGENT',
  CreateNewWorkspaceAgent = 'CREATE_NEW_WORKSPACE_AGENT',
  AgentExists = 'AGENT_EXISTS',
}
export class VCStartScanPage extends Component<
  VCStartScanPageProps & ReturnType<typeof mapDispatchToProps> & RouteComponentProps
> {
  componentDidUpdate(prevProps) {}

  getModelType(orgHasReachedTeamLimit, isMemberOfWorkspace, orgAgents) {
    if (!orgHasReachedTeamLimit && !isMemberOfWorkspace) {
      return ModalType.CreateWorkspace;
    } else {
      return orgAgents ? ModalType.CreateNewAgent : ModalType.CreateNewWorkspaceAgent;
    }
  }

  viewAgents(workspace, orgAgents, create: boolean) {
    const createNew = create ? '/new' : '';
    if (workspace) {
      const teamId = workspace.value.id;
      this.props.history.push(`/workspaces/${teamId}/agents${createNew}`);
    } else if (orgAgents) {
      this.props.history.push(`/org/settings/agents${createNew}`);
    }
    this.props.agentModalActions.showModal('');
  }

  createWorkspace() {
    const { groupState, workspaceInviteState, orgState, history } = this.props;
    const { newWorkspaceName = '' } = orgState;
    const { validEmails = [] } = workspaceInviteState;
    const { selectedGroups = {}, groups = [] } = groupState;
    const groupKeys = Object.keys(selectedGroups).filter(key => selectedGroups[key]);
    const memberships = {};
    const teamData = {
      name: newWorkspaceName,
    };

    if (groupKeys.length) {
      memberships.groups = groupKeys.map(groupId => {
        const foundGroup = groups.find(group => group.id === parseInt(groupId)) || {};

        return {
          groupId,
          role: foundGroup.stagedAdminStatus ? 'WORKSPACE_ADMIN' : 'USER',
        };
      });

      memberships.users = [];
    }

    if (validEmails.length) {
      memberships.users = validEmails;

      memberships.groups = memberships.groups || [];
    }

    if (!_.isEmpty(memberships)) {
      teamData.memberships = memberships;
    }

    this.props.orgActions.createWorkspaceWithGroups(teamData).then(res => {
      if (res.id) {
        let canViewWorkspace = res.permissions && res.permissions.read;
        let canViewAgents = res.permissions && res.permissions.manageAgents;
        this.props.agentModalActions.showModal();
        if (canViewWorkspace) {
          if (canViewAgents) {
            history.push(`/workspaces/${res.id}/agents/new`);
          } else {
            history.push(`/workspaces/${res.id}/settings`);
          }
        } else {
          this.props.toastrActions.addToastr({
            id: 'CREATE_NEW_WORKSPACE_ERROR_PERMISSION',
            level: 'success',
            title: 'Workspace Successfully Created',
            message: `Your workspace ${res.name}
               was successfully created. It is not visible because it is not linked to a team that you belong to.`,
            disableTimeout: true,
          });
        }
      }
    });
  }

  updateNewWorkspaceStep(currentStep) {
    this.props.orgActions.updateNewWorkspaceStep(currentStep === 1 ? 2 : 1);
  }

  createNewAgent() {
    const {
      history,
      agentModalState: { workspaceLevelSelected: workspaceLevel, selectedWorkspace },
      orgAgentState: { orgAgents = [] },
      agentState: { agents: workspaceAgents },
      agentModalActions,
    } = this.props;

    if (workspaceLevel && selectedWorkspace) {
      const teamId = selectedWorkspace.value.id;
      if (workspaceAgents.length) {
        agentModalActions.showModal(ModalType.AgentExists);
      } else {
        history.push(`/workspaces/${teamId}/agents/new`);
        agentModalActions.showModal('');
      }
    } else {
      if (orgAgents.length) {
        agentModalActions.showModal(ModalType.AgentExists);
      } else {
        history.push(`/org/settings/agents/new`);
        agentModalActions.showModal('');
      }
    }
  }

  startVCScan() {
    const {
      orgState: {
        org: { id },
      },
      vcNavState: {
        navigation: { platform: platformBaseUrl },
      },
      startScanModalState: { createNewApplication, selectedApplication },
    } = this.props;
    const orgId = id.substring(9);
    if (createNewApplication) {
      window.location.href = `${platformBaseUrl}${veracodeCreateAppUrl}`;
    } else if (selectedApplication) {
      window.location.href = `${platformBaseUrl}${veracodeAppProfileUrl}:${orgId}:${selectedApplication.appId}`;
    }
  }

  render() {
    const {
      agentModalState: {
        workspaceLevelSelected,
        selectedWorkspace,
        showModal: showAgentModal,
        orgLevelSelected: orgLevel,
      },
      orgAgentState: { isFetchingOrgAgents },
      startScanModalState: {
        showModal: showStartScanModal,
        createNewApplication,
        selectedApplication,
      },
      teamState: { teams },
      vcMyState: { permissions },
      agentState: { isFetching },
      vcPageState: { shouldShowVeracodePage },
      orgState,
      startScanModalActions,
      agentModalActions,
    } = this.props;

    const { newWorkspaceName = '', validWorkspaceName = true, org } = orgState;

    const noLevelSelected = !orgLevel && !workspaceLevelSelected;
    const noWorkspaceSelected = workspaceLevelSelected && selectedWorkspace == null;

    const { orgPermissions = {} } = org;
    const { inviteUsers = false, listGroups = false } = orgPermissions;

    const displayMoreButton =
      (shouldShowVeracodePage && listGroups) /* for VC integration */ ||
      inviteUsers; /* for SrcClr standalone */

    const showGroups = OrgPaidStatus.isOrgEnterpriseOrTrial(org);
    const plan = org.plan || {};
    const { teamLimit } = plan;
    const orgHasReachedTeamLimit = teamLimit && teams >= teamLimit;
    const { scaUpload, startVeracodeStaticScan, workspaceAgents, orgAgents } = permissions;
    const isMemberOfWorkspace = teams.length > 0;
    const canManageAgents = workspaceAgents || orgAgents;
    const canSubmitUploadScan = startVeracodeStaticScan && scaUpload;
    const canUploadAndManageAgents = canSubmitUploadScan || canManageAgents;
    const agentButtonDisabled = orgHasReachedTeamLimit || !canManageAgents;
    const modalToShow = this.getModelType(orgHasReachedTeamLimit, isMemberOfWorkspace, orgAgents);

    const workspaceNameError = !validWorkspaceName ? errorMessageTextMap.workspaceNameError : '';
    const shouldDisableButton =
      newWorkspaceName.trim().length < 3 || newWorkspaceName.length > 20 || !!workspaceNameError;

    const tooltipContent = !isMemberOfWorkspace
      ? 'To create an agent, you must belong to a team that has access to a workspace.'
      : 'Contact a Veracode Platform administrator or team administrator to request permission to create agents.';
    const tooltipStartScan =
      'Contact a Veracode Platform administrator or team administrator to request permission to start a scan.';
    const tooltipWorkspaceAgent = 'To continue, you must select a workspace.';

    const uploadScanCol = (
      <div className="vcTwoStateCol">
        <Header as="h2" mb={2}>
          <div className="height--120">
            <UploadScan />
          </div>
          Upload and Scan
        </Header>
        <p className="vcStartScanText">
          Upload your compiled code and third-party <br />
          components to identify vulnerabilities in a single <br />
          scan.{' '}
          <a
            href="https://help.veracode.com/go/t_sca_standalone_upload"
            className="vcStartScanHelpLink"
            target="_blank"
          >
            {' Help'}
            <i className="fas fa-external-link" />
          </a>
        </p>
        <Tooltip
          content={tooltipStartScan}
          disabled={canSubmitUploadScan}
          inverted
          position="top center"
          wide
          trigger={
            <div className="vcTooltip">
              <Button
                variant="primary"
                size="large"
                disabled={!canSubmitUploadScan}
                onClick={() => startScanModalActions.showModal(ModalType.StartScan)}
              >
                Start a Scan
              </Button>
            </div>
          }
        />
      </div>
    );
    const agentScanCol = (
      <div className="vcTwoStateCol">
        <Header as="h2" mb={2}>
          <div className="height--120">
            <AgentScan />
          </div>
          Agent-based Scan
        </Header>
        <>
          <p className="vcStartScanText">
            Deploy an agent to your CI/CD pipeline or local <br />
            desktop to identify vulnerabilities in your source <br />
            code.{' '}
            <a
              href="https://help.veracode.com/go/c_sc_what_is"
              className="vcStartScanHelpLink"
              target="_blank"
            >
              {' Help'}
              <i className="fas fa-external-link" />
            </a>
          </p>
          <Tooltip
            content={tooltipContent}
            disabled={!agentButtonDisabled}
            inverted
            position="top center"
            wide
            trigger={
              <div className="vcTooltip">
                <Button
                  variant="primary"
                  size="large"
                  disabled={agentButtonDisabled}
                  onClick={() => agentModalActions.showModal(modalToShow)}
                >
                  Create an Agent
                </Button>
              </div>
            }
          />
        </>
      </div>
    );

    return (
      <div className="vcStartScanContainer">
        <div className="vcStartScanHeader">
          <Header as="span" my={4}>
            {canUploadAndManageAgents && 'Select an SCA Scan Method'}
          </Header>
        </div>
        <div className="vcTwoStateContainer">
          {uploadScanCol}
          {agentScanCol}
        </div>
        <ComplexModal
          open={showAgentModal === ModalType.CreateWorkspace}
          onClose={() => agentModalActions.showModal()}
          closeIcon
        >
          <Modal.Header>Create Workspace</Modal.Header>
          <Modal.Content>
            <VCCreateWorkspaceModal showAlert={true} showGroups={showGroups} />
          </Modal.Content>
          <Modal.Actions>
            <Button className="mr-" type="button" onClick={() => agentModalActions.showModal()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => this.createWorkspace()}
              disabled={shouldDisableButton}
            >
              Create
            </Button>
          </Modal.Actions>
        </ComplexModal>
        <SimpleModal
          open={showAgentModal === ModalType.CreateNewAgent}
          onClose={() => agentModalActions.showModal()}
          closeIcon
        >
          <Modal.Header>Create New Agent</Modal.Header>
          <Modal.Content>
            <CreateNewAgentModal />
          </Modal.Content>
          <Modal.Actions>
            <Button className="mr-" type="button" onClick={() => agentModalActions.showModal()}>
              Cancel
            </Button>
            <Tooltip
              content={
                workspaceLevelSelected
                  ? 'To continue, you must select a workspace.'
                  : 'To continue, you must select an agent level.'
              }
              disabled={orgLevel || selectedWorkspace != null}
              inverted
              position="top center"
              className="max-width--150"
              trigger={
                <span>
                  <Button
                    variant="primary"
                    disabled={
                      noLevelSelected || noWorkspaceSelected || isFetchingOrgAgents || isFetching
                    }
                    onClick={() => this.createNewAgent()}
                  >
                    {isFetchingOrgAgents || isFetching ? (
                      <i className="fas fa-spin fa-spinner color--muted-dark" />
                    ) : (
                      'Next'
                    )}
                  </Button>
                </span>
              }
            />
          </Modal.Actions>
        </SimpleModal>
        <SimpleModal
          open={showAgentModal === ModalType.CreateNewWorkspaceAgent}
          onClose={() => agentModalActions.showModal()}
          closeIcon
        >
          <Modal.Header>Create New Agent</Modal.Header>
          <Modal.Content>
            <CreateWorkspaceAgentModal />
          </Modal.Content>
          <Modal.Actions>
            <Button className="mr-" type="button" onClick={() => agentModalActions.showModal()}>
              Cancel
            </Button>
            <Tooltip
              disabled={selectedWorkspace != null}
              content={tooltipWorkspaceAgent}
              position="top center"
              className="max-width--150"
              trigger={
                <span>
                  <Button
                    variant="primary"
                    disabled={!selectedWorkspace || isFetching}
                    onClick={() => this.createNewAgent()}
                  >
                    {isFetching ? (
                      <i className="fas fa-spin fa-spinner color--muted-dark" />
                    ) : (
                      'Next'
                    )}
                  </Button>
                </span>
              }
            />
          </Modal.Actions>
        </SimpleModal>
        <SimpleModal
          open={showAgentModal === ModalType.AgentExists}
          onClose={() => agentModalActions.showModal()}
          closeIcon
        >
          <Modal.Header>
            {`${workspaceLevelSelected ? 'Workspace Agent' : 'Organization Agent'} Already Exists`}
          </Modal.Header>
          <Modal.Content>
            <AgentExistsModal />
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="mr-"
              size="small"
              onClick={() =>
                agentModalActions.showModal(
                  !orgAgents ? ModalType.CreateNewWorkspaceAgent : ModalType.CreateNewAgent
                )
              }
            >
              Back
            </Button>
            <Button
              className="mr-"
              size="small"
              disabled={!(orgLevel || workspaceLevelSelected)}
              onClick={() => this.viewAgents(selectedWorkspace, orgAgents, true)}
            >
              Create New Agent
            </Button>
            <Button
              variant="primary"
              size="small"
              disabled={!(orgLevel || workspaceLevelSelected)}
              onClick={() => this.viewAgents(selectedWorkspace, orgAgents, false)}
            >
              View Existing Agents
            </Button>
          </Modal.Actions>
        </SimpleModal>
        <Modal
          open={showStartScanModal === ModalType.StartScan}
          onClose={() => startScanModalActions.showModal()}
          size={"large"}
          closeIcon
        >
          <Modal.Header>Start a Scan</Modal.Header>
          <Modal.Content>
            <StartScanModal />
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="mr-"
              type="button"
              onClick={() => startScanModalActions.showModal('')}
            >
              Cancel
            </Button>
            <Tooltip
              content={'To continue, select an application.'}
              disabled={createNewApplication || selectedApplication != null}
              position="top center"
              className="max-width--150"
              trigger={
                <span>
                  <Button
                    variant="primary"
                    disabled={!createNewApplication && !selectedApplication}
                    onClick={() => this.startVCScan()}
                  >
                    OK
                  </Button>
                </span>
              }
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    agentModalState: state.agentModalState,
    startScanModalState: state.startScanModalState,
    myState: state.myState,
    vcMyState: state.vcMyState,
    vcNavState: state.vcNavState,
    teamState: state.teamState,
    orgState: state.orgState,
    agentState: state.agentState,
    groupState: state.groupState,
    workspaceInviteState: state.workspaceInviteState,
    vcPageState: state.vcPageState,
    orgAgentState: state.orgAgentState,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    agentModalActions: bindActionCreators(agentModalActions, dispatch),
    orgActions: bindActionCreators(orgActions, dispatch),
    startScanModalActions: bindActionCreators(startScanModalActions, dispatch),
    toastrActions: bindActionCreators(toastrActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VCStartScanPage);
