import React from 'react';

type VCInfoBoxMap = {
  message: string;
};

function VCInfoBox(props: VCInfoBoxMap): JSX.Element {
  const { message } = props;

  return (
    <div className="vcInfoBoxContainer vcInfoBox__level--info">
      <p className="vcInfoBoxMessage">{message}</p>
    </div>
  );
}

export default VCInfoBox;
