import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import PaymentForm from '~/containers/PaymentForm';

import * as meActions from '~/actions/me';
import * as toastrActions from '~/actions/toastr';

interface TrialExpiredPaymentPageProps extends RouteComponentProps {
  meActions: object;
  toastrActions: object;
}

class TrialExpiredPaymentPage extends React.Component<TrialExpiredPaymentPageProps, {}> {
  requestPurchaseOrderSuccess(res) {
    const { history } = this.props;

    if (res.success) {
      const { extended } = res;

      this.props.toastrActions.addToastr({
        id: 'trial-extended',
        title: extended ? 'Purchase Order Pending' : 'Message successfully sent',
        message: extended
          ? 'Your request has been sent and your account will be active for three days while the purchase order is being set up.'
          : 'Your request has been sent and our sales team will be in touch shortly.',
        level: 'info',
      });

      if (extended) {
        this.props.meActions.fetchMe().then(res => {
          const { organization = {} } = res;
          const { license = {} } = organization;
          const { status } = license;
          if (status !== 'TRIAL_EXPIRED') {
            history.push('/');
          }
        });
      }
    }
  }

  render() {
    return (
      <div className="container grid grid--center mb++">
        <div className="grid__item col-2-5">
          <div className="mt font--h3 text--center">Subscribe to SourceClear Business</div>
          <div className="mt+">
            <PaymentForm
              trialExpired={true}
              requestPurchaseOrderSuccess={res => this.requestPurchaseOrderSuccess(res)}
            />
            <Link to={`/trial-expired`} className="btn col-1-1 pv- mt-">
              Cancel
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    meActions: bindActionCreators(meActions, dispatch),
    toastrActions: bindActionCreators(toastrActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialExpiredPaymentPage);
