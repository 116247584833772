import React from 'react';
import PolicyControlCard from '~/components/PolicyControlCard';
import { PolicyControl } from '~/types/Policy';
import Helpers from '~/utils/Helpers';
import { LicenseState } from '~/types/license';

interface PolicyControlContainerProps {
  controls: PolicyControl[];
  cardState: { [key: string]: any };
  licenseState?: LicenseState;
  validatedControls: { [key: string]: any };
  updateControl: (option: any, type: any, index: number) => void;
  fetchLicensesByRisk: (type: string) => void;
  removeControl: (index: number) => void;
  toggleControlExpand: (index: number) => void;
  moveControl: (index: number, toIndex: number) => void;
}

function PolicyControlCardList(props: PolicyControlContainerProps) {
  const {
    cardState,
    licenseState,
    controls,
    validatedControls,
    updateControl,
    removeControl,
    toggleControlExpand,
    fetchLicensesByRisk,
    moveControl,
  } = props;

  const { editMode, expandedControlsByIndex = {}, cardMoved = {} } = cardState;

  return (
    <div className="mt--" data-automation-id="PolicyControlCardList">
      {!controls.length ? (
        <div className="font--h5">No Controls Created</div>
      ) : (
        controls.map((control, index) => {
          return (
            <PolicyControlCard
              key={`control-${index}`}
              control={control}
              licenseState={licenseState}
              index={index}
              controlLength={controls.length}
              editMode={editMode}
              cardMoved={cardMoved[index] || false}
              isExpanded={expandedControlsByIndex[index] || false}
              updateControl={(option, type, index) => updateControl(option, type, index)}
              fetchLicensesByRisk={type => fetchLicensesByRisk(type)}
              removeControl={index => removeControl(index)}
              toggleControlExpand={index => toggleControlExpand(index)}
              moveControl={(index, toIndex) => moveControl(index, toIndex)}
              validation={validatedControls[index] || {}}
              showGplOrMulti={Helpers.existingGplMultiPolicies(control)}
            />
          );
        })
      )}
    </div>
  );
}

export default PolicyControlCardList;
