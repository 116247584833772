import produce from 'immer';
import * as actions from '~/actions/login';
import { TOGGLE_ADD_PASSWORD_LOGIN } from '~/actions/me';

const defaultState = {
  isFetchingToken: false,
  githubLoggingIn: false,
  autoLogout: false,
  query: {},
  samlEmailValue: '',
  samlEmailValueIsValid: false,
  passwordStrength: {},
  passwordMismatch: false,
  loginError: {},
  loginMode: 'PASSWORD',
  sendPasswordResetEmail: undefined,
  resetPasswordToken: undefined,
  newPasswordStrength: {},
  invalidResetToken: false,
  resetPasswordFailed: false,
  msaAgree: false,
  isSamlEnabled: false,
  isOrgDomainAvailable: false,
  isCheckingOrgDomain: false,
  signupError: {},
  userIsARobot: false,
  emailInput: '',
  emailValidity: {},
  widgetId: undefined,
  isSigningUp: false,
  vanityDomain: undefined,
  findMyOrgsEmailStatus: undefined,
  findMyOrgsEmail: undefined,
  orgDomainRedirectError: false,
  orgMigrationStatus: 'ACTIVE',
};

const loginState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.UPDATE_AUTO_LOGOUT:
        draft.autoLogout = action.bool;
        break;
      case actions.UPDATE_GITHUB_LOGGING_IN:
        draft.githubLoggingIn = action.bool;
        break;
      case actions.UPDATE_IS_FETCHING_TOKEN:
        draft.isFetchingToken = action.bool;
        break;
      case actions.UPDATE_LOGIN_QUERY:
        draft.query = action.query;
        draft.samlEmailValue = action.query.email || '';
        break;
      case actions.UPDATE_SAML_EMAIL_VALUE:
        draft.samlEmailValue = action.value;
        draft.samlEmailValueIsValid = action.isValidEmail;
        break;
      case actions.SHOW_MISMATCHING_PASSWORD_ERROR:
        draft.passwordMismatch = true;
        break;
      case actions.LOGIN_FAILED:
        draft.loginError = action.error;
        break;
      case actions.SEND_PASSWORD_RESET_EMAIL_SUCCESS:
        draft.sendPasswordResetEmail = 'SUCCESS';
        break;
      case actions.SEND_PASSWORD_RESET_EMAIL_FAILURE:
        draft.sendPasswordResetEmail = 'FAILURE';
        break;
      case TOGGLE_ADD_PASSWORD_LOGIN:
        draft.sendPasswordResetEmail = undefined;
        break;
      case actions.STORE_RESET_TOKEN:
        draft.resetPasswordToken = action.token;
        break;
      case actions.RESET_PASSWORD_FAILED:
        draft.resetPasswordFailed = true;
        break;
      case actions.INVALID_RESET_TOKEN:
        draft.invalidResetToken = true;
        break;
      case actions.SIGNUP_FAILED:
        draft.signupError = action.error;
        draft.isSigningUp = false;
        break;
      case actions.CLEAR_INPUT_ERRORS:
        draft.passwordMismatch = false;
        draft.userIsARobot = false;
        draft.signupError = {};
        break;
      case actions.TOGGLE_MSA_AGREE:
        draft.msaAgree = !state.msaAgree;
        draft.signupError = {};
        break;
      case actions.MSA_AGREE_NOT_CHECKED:
        draft.signupError = {
          status: 400,
          message: 'You must accept the Terms of Use.',
        };
        draft.isSigningUp = false;
        break;
      case actions.UPDATE_SIGNUP_ERROR:
        draft.signupError = action.error;
        break;
      case actions.USER_IS_A_ROBOT:
        draft.userIsARobot = true;
        break;
      case actions.UPDATE_LOGIN_MODE:
        draft.loginMode = action.mode;
        break;
      case actions.UPDATE_EMAIL_INPUT:
        draft.emailInput = action.value;
        break;
      case actions.UPDATE_EMAIL_VALIDITY:
        draft.emailValidity = action.response;
        break;
      case actions.UPDATE_WIDGET_ID:
        draft.widgetId = action.widgetId;
        break;
      case actions.CHECK_ORG_DOMAIN_REQUEST:
        draft.isCheckingOrgDomain = true;
        break;
      case actions.UPDATE_IS_ORG_DOMAIN_AVAILABLE:
        draft.isOrgDomainAvailable = action.isAvailable;
        break;
      case actions.CHECK_ORG_DOMAIN_SUCCESS:
        draft.isCheckingOrgDomain = false;
        break;
      case actions.UPDATE_IS_SAML_ENABLED:
        draft.isSamlEnabled = action.bool;
        break;
      case actions.SIGNING_UP:
        draft.isSigningUp = action.bool;
        break;
      case actions.UPDATE_VANITY_DOMAIN:
        draft.vanityDomain = action.value;
        draft.orgDomainRedirectError = false;
        break;
      case actions.UPDATE_ORG_DOMAIN_REDIRECT_ERROR:
        draft.orgDomainRedirectError = true;
        break;
      case actions.UPDATE_FIND_MY_ORGS_EMAIL_VALUE:
        draft.findMyOrgsEmail = action.value;
        draft.findMyOrgsEmailStatus = undefined;
        break;
      case actions.FIND_MY_ORGS_EMAIL_SUCCESS:
        draft.findMyOrgsEmailStatus = action.email;
        draft.findMyOrgsEmail = undefined;
        break;
      case actions.CHECK_ORG_MIGRATED:
        draft.orgMigrationStatus = action.orgMigrationStatus;
        break;
      case actions.RESET_STATE:
        return defaultState;
    }
  });
};

export default loginState;
