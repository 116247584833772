import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';

interface GradleProps {
  packageInfo: object;
  filePath: string;
}

const Gradle: React.FunctionComponent<GradleProps> = props => {
  const { coord1, coord2, version } = props.packageInfo;
  const { filePath = 'build.gradle' } = props;

  //split build.gradle#L5
  const splittedSelectedFilePath = filePath.split('#L');
  const file = splittedSelectedFilePath[0];
  const lineNumber = splittedSelectedFilePath[1] || '';

  return (
    <div>
      <div className="grid bo--1 border-color--white-dark mt lh+">
        <CodeDiffHeader text={file} />
        <CodeDiffLine type="normal" text="dependencies {" />
        <CodeDiffLine
          type="highlight"
          lineNumber={lineNumber}
          text={'\u2003compile \u0027' + coord1 + ':' + coord2 + ':' + version + '\u0027'}
        />
        <CodeDiffLine type="normal" text="}" />
      </div>
      <div className="pt">
        <span className="text--bold"> {coord1 + ':' + coord2} </span> may have been declared as a
        range instead of <span className="text--bold"> {version} </span> in your{' '}
        <span className="text--bold"> {file} </span>
      </div>
    </div>
  );
};

export default Gradle;
