import React from 'react';
import { vcmf } from '@veracode/micro-frontend';
import config from '~/config';

vcmf();

const ScaPlatformUI = () => {
  return (
    <vcmf-wrapper
      inline={false}
      jsFiles={JSON.stringify([`${config.VERACODE_SCA_PLATFORM_UI_JS_FILE}`])}
      cssFiles={JSON.stringify([`${config.VERACODE_SCA_PLATFORM_UI_CSS_FILE}`])}
      mountId={'platform-ui-root'}
      mountTag="div"
      nonInlineHideUntilLoaded
    />
  );
};

export default ScaPlatformUI;
