import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as MODEL from '~/constants/ModelConstants';

import SourceClearModal from '~/components/SourceClearModal';
import * as integrationModalActions from '~/actions/integrationModal';

const { JIRA_CLOUD, JIRA_ONPREM } = MODEL.INTEGRATION_NAME_MAP;

interface Step1ModalProps {
  integrationModalActions: object;
  integrationModalState: object;
  orgState: App.OrgState;
  closeWhenClickOutside?: boolean;
}
class Step1Modal extends React.Component<Step1ModalProps, {}> {
  onClose = () => {
    this.props.integrationModalActions.closeIntegrationModal();
  };

  updateFormInput(fieldType, e) {
    this.props.integrationModalActions.updateIntegrationModalField(fieldType, e.target.value);
  }

  createJiraIntegrationStep1(e) {
    e.preventDefault();
    const { integrationModalState } = this.props;
    const { fields = {}, isModalJiraPrem } = integrationModalState;
    const { integrationName = '', integrationServerUrl = '' } = fields;

    if (isModalJiraPrem) {
      const proxyClientUuid = this.getProxyClientUuid();
      this.props.integrationModalActions.createJiraPremIntegration(
        integrationName,
        integrationServerUrl,
        proxyClientUuid
      );
    } else {
      this.props.integrationModalActions.createJiraIntegration(
        integrationName,
        integrationServerUrl
      );
    }
  }

  getProxyClientUuid() {
    const { integrationModalState } = this.props;
    const { jiraIntegration } = integrationModalState;
    const { [MODEL.CREATE_PROXY_CLIENT_STEP_1]: createProxyClientRequest = {} } = jiraIntegration;
    const { data: createProxyClientResponse = {} } = createProxyClientRequest;
    return createProxyClientResponse.uuid || '';
  }

  validIntegrationServerUrl = () => {
    const { integrationModalState } = this.props;
    const { fields = {} } = integrationModalState;
    const { integrationServerUrl = '' } = fields;
    return integrationServerUrl.startsWith('https://');
  };

  render() {
    const { integrationModalState, closeWhenClickOutside = false } = this.props;
    const { fields = {}, isModalJiraPrem } = integrationModalState;
    const { integrationName = '' } = fields;
    let { integrationServerUrl = '' } = fields;
    const { jiraIntegration = {} } = integrationModalState;
    const { [MODEL.CREATE_JIRA_STEP_1]: step1Response = {} } = jiraIntegration;
    const { isFetching } = step1Response;

    return (
      <SourceClearModal
        isOpen={true}
        title={
          isModalJiraPrem
            ? `Create ${JIRA_ONPREM} Integration (Step 3 of 5)`
            : `Create ${JIRA_CLOUD} Integration (Step 1 of 3)`
        }
        onClose={this.onClose}
        width={600}
        closeWhenClickOutside={closeWhenClickOutside}
      >
        <form onSubmit={e => this.createJiraIntegrationStep1(e)}>
          <div className="pv+ min-height--232">
            <div className="grid flex flex--align-items--center flex--justify-content--center">
              <div className="grid__item pl+ col-1-4">Integration Name</div>
              <div className="grid__item">
                <input
                  type="text"
                  className="col-1-1 control--text"
                  placeholder="Provide a name for this integration"
                  onChange={e => this.updateFormInput('integrationName', e)}
                  value={integrationName}
                />
              </div>
            </div>
            <div className="grid flex flex--align-items--center flex--justify-content--center pv">
              <div className="grid__item col-1-4 pl+">Type</div>
              <div className="grid__item">{isModalJiraPrem ? JIRA_ONPREM : 'Jira'}</div>
            </div>
            <div className="grid flex flex--align-items--center flex--justify-content--center">
              <div className="grid__item col-1-4 pl+">Server URL</div>
              <div className="grid__item col-3-4">
                <input
                  type="text"
                  className="col-1-1 control--text"
                  placeholder="Your Jira URL, write the entire URL including https://"
                  disabled={isModalJiraPrem}
                  onChange={e => this.updateFormInput('integrationServerUrl', e)}
                  value={integrationServerUrl}
                />
              </div>
            </div>
            {integrationServerUrl.length > 5 &&
              !this.validIntegrationServerUrl() &&
              !isModalJiraPrem && (
                <div className="bubble--error text--center pt--">
                  Please ensure that the url starts with https://
                </div>
              )}
          </div>
          <div className="pt pb text--right">
            <button className="col-1-5 pv- mr-" onClick={() => this.onClose()}>
              {' '}
              Cancel{' '}
            </button>
            {isModalJiraPrem && (
              <button
                className="col-1-5 pv- mr- btn--primary-clear"
                onClick={() =>
                  this.props.integrationModalActions.openIntegrationModal(
                    MODEL.CREATE_PROXY_CLIENT_STEP_2
                  )
                }
              >
                Back
              </button>
            )}
            <button
              type="submit"
              className={'col-1-5 pv- btn--primary'}
              disabled={
                isModalJiraPrem
                  ? integrationName.length == 0
                  : integrationName.length == 0 ||
                    integrationServerUrl.length == 0 ||
                    !this.validIntegrationServerUrl()
              }
            >
              {isFetching ? (
                <span>
                  <i className="fas fa-spin fa-spinner font--h4" />{' '}
                </span>
              ) : (
                <span> Next </span>
              )}
            </button>
          </div>
        </form>
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationModalState: state.integrationModalState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Step1Modal);
