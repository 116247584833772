const PlatformUrlHelper = function () {
  const createProjectUrl = (teamId, projectId) => {
    return `/workspaces/${teamId}/projects/${projectId}/issues`;
  };

  return {
    createProjectUrl,
  };
};

export default new PlatformUrlHelper();
