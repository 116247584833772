import React from 'react';

import { IGNORE_COMMENT_MODAL } from '~/constants/ModelConstants';

import { useForm } from 'react-hook-form';

interface CommentIssueFormProps {
  handleSubmit: (comment: string) => any;
  handleCancel: (...args: any[]) => any;

  type: string;
}

function CommentIssueForm({
  handleSubmit: handleFormSubmit,
  handleCancel,
  type,
}: CommentIssueFormProps) {
  const { register, handleSubmit, errors } = useForm();
  const handleCommentIssueFormSubmit = ({ comment }: { comment: string }) => {
    handleFormSubmit(comment);
  };
  return (
    <form onSubmit={handleSubmit(handleCommentIssueFormSubmit)}>
      <div className="pb-">
        <div>
          <strong className="font--h6">Enter comment</strong>
        </div>
        {type === IGNORE_COMMENT_MODAL && (
          <div className="mt--">Add a comment when ignoring or unignoring.</div>
        )}

        <div className="mt-">
          <div className="flex flex-grow--1 flex--flex-direction--column">
            <textarea
              name="comment"
              rows={5}
              ref={register({ required: true, maxLength: 250 })}
              className="control--text border-color--muted ta-resize--none"
              placeholder="250 characters maximum"
            />
            {errors.comment?.type === 'required' && (
              <div className="color--danger mt-">Comment is required</div>
            )}
            {errors.comment?.type === 'maxLength' && (
              <div className="color--danger mt-">Comment cannot be longer than 250 characters</div>
            )}
          </div>
        </div>
      </div>
      <div className="mt- text--right">
        <button className="pv- col-1-5 mr-" onClick={handleCancel}>
          {' '}
          Cancel{' '}
        </button>
        <button disabled={errors.comment} type="submit" className="pv- col-1-5 btn--success-clear">
          Finish
        </button>
      </div>
    </form>
  );
}

export default CommentIssueForm;
