export const FETCH_TICKET_TEMPLATE_REQUEST = 'FETCH_TICKET_TEMPLATE_REQUEST';
export const FETCH_TICKET_TEMPLATE_SUCCESS = 'FETCH_TICKET_TEMPLATE_SUCCESS';
export const FETCH_TICKET_TEMPLATE_FAILURE = 'FETCH_TICKET_TEMPLATE_FAILURE';

export const FETCH_JIRA_PROJECT_REQUEST = 'FETCH_JIRA_PROJECT_REQUEST';
export const FETCH_JIRA_PROJECT_SUCCESS = 'FETCH_JIRA_PROJECT_SUCCESS';
export const FETCH_JIRA_PROJECT_FAILURE = 'FETCH_JIRA_PROJECT_FAILURE';

import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';

export const fetchTicketTemplateRequest = integrationId => ({
  type: FETCH_TICKET_TEMPLATE_REQUEST,
  integrationId,
});

export const fetchTicketTemplateSuccess = (integrationId, response, concat) => ({
  type: FETCH_TICKET_TEMPLATE_SUCCESS,
  integrationId,
  response,
  concat,
});

export const fetchTicketTemplateFailure = (integrationId, error) => ({
  type: FETCH_TICKET_TEMPLATE_FAILURE,
  integrationId,
  message: error.message || 'Something went wrong. Please try again.',
});

export const fetchTicketTemplate = (integrationId, pageNum = 0, concat?: boolean) => dispatch => {
  dispatch(fetchTicketTemplateRequest(integrationId));
  ApiService.get(ApiConstants.fetchTicketTemplateURL(integrationId, pageNum))
    .then((res: JiraIntegration.JiraTemplatesResponse) => {
      const {
        _embedded: { templates },
      } = res;

      dispatch(fetchTicketTemplateSuccess(integrationId, { content: templates }, concat));
    })
    .catch(err => {
      dispatch(fetchTicketTemplateFailure(integrationId, err));
      ErrorService.capture('Unable to load integrations :', err);
    });
};

export const deleteTicketTemplate = (integrationId, templateList) => dispatch => {
  const deletePromises = templateList.map(template =>
    ApiService.del(ApiConstants.updateJiraTemplateURL(integrationId, template.id))
  );

  Promise.all(deletePromises).then(() => {
    dispatch(fetchTicketTemplate(integrationId));
  });
};
