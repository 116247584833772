import { veracodeToastrBlockList } from '~/constants/ModelConstants';
export const ADD_TOASTR = 'ADD_TOASTR';
export const CLEAR_TOASTRS = 'CLEAR_TOASTRS';
export const EDIT_TOASTR = 'EDIT_TOASTR';
export const REMOVE_TOASTR = 'REMOVE_TOASTR';

export const addToastr = options => (dispatch, getState) => {
  const {
    vcPageState: { shouldShowVeracodePage },
  } = getState();

  // If we are a veracode user
  if (shouldShowVeracodePage) {
    // Check the block list for any Toasts we do not want to show Veracode users
    const blockedToast = veracodeToastrBlockList.find(item => item.id === options.id);
    if (!blockedToast) {
      dispatch({
        type: ADD_TOASTR,
        options,
      });
    }
  } else {
    dispatch({
      type: ADD_TOASTR,
      options,
    });
  }

  // if disable remove toast is NOT set to true
  if (!options.disableRemoveToastr && options.allowTimeout) {
    setTimeout(() => {
      dispatch(removeToastr(options));
    }, options.dismissTimeout || 10000);
    // use the provided timeout value or 10 seconds as default
  }
};

export const removeToastr = options => ({
  type: REMOVE_TOASTR,
  options,
});

export const clearToastrs = () => ({
  type: CLEAR_TOASTRS,
});

export const editToastr = (id, options) => ({
  type: EDIT_TOASTR,
  id,
  options,
});
