import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as projectScanHistoryActions from '~/actions/projectScanHistory';
import * as repoActions from '~/actions/repo';
import * as reportsActions from '~/actions/reports';
import * as repoScopeActions from '~/actions/repoScope';
import * as reportScopeActions from '~/actions/reportScope';
import * as repoScopeSwitcherActions from '~/actions/repoScopeSwitcher';

import LoaderWrapper from '~/components/LoaderWrapper';
import RepoScopePopover from '~/containers/RepoScopePopover';
import Helpers from '~/utils/Helpers';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import Tooltip from '~/components/Tooltip';

import { PROJECT_TYPES, PROJECT_TYPES_CLEAN_NAMES } from '~/constants/ModelConstants';

interface RepoScopeSwitcherProps {
  teamId: string;
  projectId: string;
  activeReportType: string;
  repoDataById: object;
  repoScope: object;
  projectScanHistoryState: object;
  orgState: App.OrgState;
  projectData?: object;
  projectType?: string;
}
class RepoScopeSwitcher extends Component<
  RepoScopeSwitcherProps & ReturnType<typeof mapDispatchToProps>
> {
  updateReportData() {
    const { teamId, projectId, activeReportType } = this.props;
    this.props.reportScopeActions.updateReportScope('repoScanId', '');

    this.props.reportsActions.fetchReport(teamId, activeReportType);
    this.props.repoActions.refreshProjectData(teamId, projectId);
  }

  render() {
    const {
      repoDataById,
      projectScanHistoryState,
      projectId,
      projectType = '',
      repoScope,
      orgState,
      projectData,
    } = this.props;
    const {
      isSettingRepoScope = false,
      isRefreshingProjectData = false,
      [projectId]: repoData = {},
    } = repoDataById;
    const { branches = [], tags = [] } = repoData;
    const { branch: scopeBranch, tag } = repoScope;
    const { selectedScan = {}, latestScanByBranchOrTag = {} } = projectScanHistoryState;
    const {
      commit: scanCommit,
      scanDate,
      branch: scanBranch,
      id: scanId,
      tag: scanTag,
    } = selectedScan;
    const ref = scopeBranch || tag || '';

    const refType = (ref => {
      if (ref.scopeBranch) {
        return 'branch';
      }
      if (ref.tag) {
        return 'tag';
      }
    })(repoScope);

    const { [refType]: latestScansRefType = {} } = latestScanByBranchOrTag;
    const { [ref]: latestScanData = {} } = latestScansRefType;
    const { id: latestScanId } = latestScanData;
    const { org } = orgState;
    const isPaidOrTrialing = OrgPaidStatus.isOrgPaidOrTrial(org);
    const scanDateText = (scanDate && Helpers.formatDate(scanDate)) || 'n/a';
    const uri = projectData.url ? projectData.url.replace(/^https?:\/\//, '') : 'n/a';
    const originalName = projectData.containerOriginalName
      ? projectData.containerOriginalName
      : 'n/a';

    const isContainerProject = projectType.toUpperCase() === PROJECT_TYPES.CONTAINER;

    const isLatestScan = scanId === latestScanId;
    const maybeLatestInTagText = tag ? 'latest in tag' : '';
    const latestInRefText = scopeBranch ? 'latest in branch' : maybeLatestInTagText;
    const maybeShowScopePopover =
      isPaidOrTrialing && !isContainerProject ? (
        <span className="pl- inline-block">
          <RepoScopePopover
            id={projectId}
            branches={branches}
            tags={tags}
            updateReportData={() => this.updateReportData()}
            buttonClassName="ph- pv---"
          />
        </span>
      ) : null;

    const renderScanBranchOrTag = () => {
      if (!isContainerProject && scanBranch) {
        return (
          <div className="mb-- mt--">
            <span className="text--bold">Selected Branch: </span>
            <span>{scopeBranch || scanBranch}</span>
          </div>
        );
      } else if (scanTag) {
        return (
          <div className="mb-- mt--">
            <span className="text--bold">Selected Tag: </span>
            <span>{scanTag}</span>
          </div>
        );
      }
      return (
        // When branch and tag are not available, show 'n/a'
        <div className="mb-- mt--">
          <span className="text--bold">Selected Branch: </span>
          <span>n/a</span>
        </div>
      );
    };

    let repoScopeLbl = (
      <div className="grid col-1-1">
        <div className="grid__item col-1-2">
          <div className="mb--- mt---">
            <span className="text--bold">Type:</span>{' '}
            {PROJECT_TYPES_CLEAN_NAMES[projectType.toUpperCase()]}
          </div>
          <div className="col-1-1 flex flex--align-items--center">
            Latest Scan <span className="inline-block ml--">{maybeShowScopePopover}</span>
          </div>
        </div>
      </div>
    );

    if (scanId && (scanCommit || scanBranch || scanTag)) {
      //If looking at a specific scan, show scan details including commit hash, branch or tag
      repoScopeLbl = (
        <div className="grid col-1-1">
          <div className="grid__item col-1-2">
            <div className="mb--- mt---">
              <span className="text--bold">Type:</span>{' '}
              {PROJECT_TYPES_CLEAN_NAMES[projectType.toUpperCase()]}
            </div>
            {!isContainerProject ? (
              <div className="mb-- mt-">
                <span className="text--bold">URI:</span>
                <span> {uri}</span>
              </div>
            ) : (
              <div className="mb-- mt-">
                <span className="text--bold">Original Name:</span>
                <span> {originalName}</span>
              </div>
            )}
            <div className="flex flex--align-items--center">
              {renderScanBranchOrTag()}
              {maybeShowScopePopover}
            </div>
          </div>
          <div className="grid__item col-1-2">
            <div className="mb--- mt---">
              {' '}
              <span className="text--bold">Scan ID:</span> {scanId}{' '}
              {isLatestScan && `(${latestInRefText})`}
            </div>
            <div className="mb-- mt-">
              {' '}
              <span className="text--bold">Scan Date:</span> {scanDateText}
            </div>
            <div className="flex flex--justify-content--start width--450">
              <div className="mb-- mt--">
                <Tooltip
                  place="top"
                  className="width--300"
                  content={
                    isContainerProject
                      ? `The digest of the Docker image scanned.`
                      : `The revision number of the code that was scanned. For git repositories, this is the commit hash. For SVN, this is the revision number. For Mercurial, this is the changeset ID, etc.`
                  }
                  id="full-commit-text"
                >
                  <span className="text--bold">
                    Selected {isContainerProject ? 'Digest' : 'Revision'}:
                  </span>{' '}
                  {scanCommit}
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="grid pl">
        <LoaderWrapper
          className="flex flex--align-items--center flex--justify-content--center col-1-1"
          isLoaderShowing={isSettingRepoScope || isRefreshingProjectData}
          customLoader={<i className={`fas fa-spin fa-spinner color--white font--h4`} />}
        >
          {repoScopeLbl}
        </LoaderWrapper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    repoDataById: state.repoDataById,
    repoScope: state.repoScope,
    projectScanHistoryState: state.projectScanHistory,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    projectScanHistoryActions: bindActionCreators(projectScanHistoryActions as any, dispatch),
    repoActions: bindActionCreators(repoActions as any, dispatch),
    reportsActions: bindActionCreators(reportsActions as any, dispatch),
    repoScopeActions: bindActionCreators(repoScopeActions as any, dispatch),
    reportScopeActions: bindActionCreators(reportScopeActions as any, dispatch),
    repoScopeSwitcherActions: bindActionCreators(repoScopeSwitcherActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RepoScopeSwitcher);
