import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import * as agentModalActions from '~/actions/agentModal';
import { AgentModalState } from '~/reducers/vcAppState/vcAppStateTypes/types';

interface AgentExistsModalProps extends RouteComponentProps {
  agentModalActions: object;
  agentModalState: AgentModalState;
  selectedWorkspace: object;
}
class AgentExistsModal extends React.Component<AgentExistsModalProps, {}> {
  componentDidMount() {
    const { agentModalState } = this.props;
    const { selectedWorkspace, orgLevelSelected, workspaceLevelSelected } = agentModalState;

    if (selectedWorkspace) {
      this.props.agentModalActions.updateWorkspace(selectedWorkspace);
    }
    if (orgLevelSelected) {
      this.props.agentModalActions.orgLevelSelected();
    } else if (workspaceLevelSelected) {
      this.props.agentModalActions.workspaceLevelSelected();
    }
  }

  componentWillUnmount() {
    this.props.agentModalActions.resetAgentModalState();
  }

  render() {
    const {
      agentModalState: { selectedWorkspace, orgLevelSelected: orgLevel },
    } = this.props;

    const selectedWorkspaceContent = selectedWorkspace ? selectedWorkspace.label : '';

    return (
      <div>
        {orgLevel ? (
          <p>
            An agent for your organization already exists. Veracode recommends you use an existing
            agent by regenerating the agent token.
          </p>
        ) : (
          <div className="mb">
            <p>
              An agent for this workspace already exists. Veracode recommends you use an existing
              agent by regenerating the agent token.
            </p>
            <p>Selected Workspace: {selectedWorkspaceContent}</p>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentModalState: state.agentModalState,
    selectedWorkspace: state.selectedWorkspace,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    agentModalActions: bindActionCreators(agentModalActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgentExistsModal));
