import produce from 'immer';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import {
  UPDATE_VC_PAGE_VISIBILITY,
  VCPageActionTypes,
} from '~/actions/vcAppActions/vcAppActionsTypes/types';
const defaultState: VCPageState = {
  shouldShowVeracodePage: false,
};

const vcPageState = (state = defaultState, action: VCPageActionTypes) => {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_VC_PAGE_VISIBILITY: {
        draft.shouldShowVeracodePage = action.isVisible;
      }
    }
  });
};

export default vcPageState;
