import { RepoIssueEvent } from '~/types/SCA';

export enum ReportType {
  ISSUE_VULNERABILITIES = 'ISSUE_VULNERABILITIES',
  ISSUE_LIBRARIES = 'ISSUE_LIBRARIES',
  ISSUE_LICENSES = 'ISSUE_LICENSES',
}

export interface ReportDetailsPageState {
  [ReportType.ISSUE_VULNERABILITIES]: IssueVulnerabilitiesState;
  [ReportType.ISSUE_LIBRARIES]: IssueLibrariesState;
  [ReportType.ISSUE_LICENSES]: IssueLicensesState;
}

interface ReportDetailsCommonState {
  isActiveTabLoading: boolean;
  isFetching: boolean;
  isFailure: boolean;
  tabData: any;
  tabSelected: string;
  tabState: any;
  details: any;
  isFetchingEvents: boolean;
  events: RepoIssueEvent[];
}

interface VulnerabilityLibraryCommonState extends ReportDetailsCommonState {
  isUpdateAdvisorDataFetching: boolean;
  updateAdvisorData: any;
}

interface IssueVulnerabilitiesState extends VulnerabilityLibraryCommonState {
  expandedVulnerableMethodChain: string;
}

interface IssueLibrariesState extends VulnerabilityLibraryCommonState {}

interface IssueLicensesState extends ReportDetailsCommonState {
  activeLicense: string;
  isLicenseContentDataFetching: boolean;
  licenseContentDataFetchFailure: string;
  licenseContentData: any[];
}
