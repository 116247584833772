import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as popoverActions from '~/actions/popover';
import PopoverWithEscKey, { placement } from '~/components/PopoverWithEscKey';

interface ReportPopoverProps {
  popoverActions: object;
  popoverState: object;
  id?: string;
  isButtonDisabled: boolean;
  onButtonClick?: (...args: any[]) => any;
  buttonText: string;
  buttonClass?: string;
  /**
   * Set false to hide both cancel and confirm button
   */
  showButton?: boolean;
  cancelButtonText?: string;
  cancelButtonClass?: string;
  preferPlace?: placement;
  place?: placement;
  widthClass?: string;
  confirmButtonText?: string;
  onConfirmClick?: (...args: any[]) => any;
  confirmButtonClass?: string;
  isConfirmButtonDisabled?: boolean;
  closeOnConfirm?: boolean;
}
class ReportPopover extends React.Component<ReportPopoverProps, {}> {
  componentWillUnmount() {
    this.toggleClosed();
  }

  toggleOpen = () => {
    this.props.popoverActions.openPopover(this.props.id);

    if (this.props.onButtonClick) {
      this.props.onButtonClick();
    }
  };

  toggleClosed = () => {
    this.props.popoverActions.closePopover(this.props.id);
  };

  handleConfirmClick = () => {
    this.props.onConfirmClick();

    const { closeOnConfirm = true } = this.props;

    if (closeOnConfirm) {
      this.toggleClosed();
    }
  };

  render() {
    const {
      popoverState,
      id,
      children,
      place,
      preferPlace = 'left',
      widthClass = 'max-width--675',
    } = this.props;
    const { cancelButtonText = 'Cancel', cancelButtonClass } = this.props;
    const {
      confirmButtonText,
      confirmButtonClass = 'btn--primary',
      isConfirmButtonDisabled = false,
    } = this.props;
    const { isButtonDisabled, buttonText, buttonClass, showButton = true } = this.props;

    const isOpen = popoverState[id] || false;
    const content = (
      <div className="grid">
        <div className="grid__item col-1-1 mb-">{children}</div>

        <div className={`grid__item col-1-1 mt- p- ${showButton ? '' : 'invisible'}`}>
          {confirmButtonText && (
            <button
              className={`pv- pl pr pull--right btn ${confirmButtonClass} font-family--body-light font--h7 ml--`}
              disabled={isConfirmButtonDisabled}
              onClick={this.handleConfirmClick}
            >
              {confirmButtonText}
            </button>
          )}
          <button
            className={`pv- pl pr pull--right btn btn--default font-family--body-light font--h7 ${cancelButtonClass}`}
            onClick={this.toggleClosed}
          >
            {cancelButtonText}
          </button>
        </div>
      </div>
    );

    let defaultBtnClass = 'btn--success';

    if (isButtonDisabled) {
      defaultBtnClass = 'disabled border-color--white-dark';
    } else if (buttonClass) {
      defaultBtnClass = buttonClass;
    }

    return (
      <PopoverWithEscKey
        isOpen={isOpen}
        body={content}
        className={`col-3-4 zIndex-9--dialog ${widthClass}`}
        preferPlace={preferPlace}
        place={place}
        onOuterAction={this.toggleClosed}
      >
        <button
          className={`col-1-1 btn text--right p- ${defaultBtnClass}`}
          data-e2e={`${buttonText}-btn`}
          disabled={isButtonDisabled}
          onClick={isOpen ? this.toggleClosed : this.toggleOpen}
        >
          {buttonText}
        </button>
      </PopoverWithEscKey>
    );
  }
}

function mapStateToProps(state) {
  return {
    popoverState: state.popoverState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    popoverActions: bindActionCreators(popoverActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPopover);
