import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import Helpers from '~/utils/Helpers';
import ApiConstants from '~/constants/ApiConstants';

import * as toastrActions from '~/actions/toastr';
import * as paymentActions from '~/actions/payment';

export const CLEAR_BILLING_INFO_COPIES = 'CLEAR_BILLING_INFO_COPIES';
export const FETCH_BILLING_DASHBOARD_REQUEST = 'FETCH_BILLING_DASHBOARD_REQUEST';
export const REQUEST_SWITCH = 'REQUEST_SWITCH';
export const RESET_BILLING_INFO = 'RESET_BILLING_INFO';
export const SAVE_BILLING_INFO_COPIES = 'SAVE_BILLING_INFO_COPIES';
export const SHOW_MODAL = 'SHOW_MODAL';
export const UPDATE_ALLOW_OVERAGES = 'UPDATE_ALLOW_OVERAGES';
export const UPDATE_BILLING_DATA = 'UPDATE_BILLING_DATA';
export const UPDATE_BILLING_ERROR = 'UPDATE_BILLING_ERROR';
export const UPDATE_BILLING_FIELD = 'UPDATE_BILLING_FIELD';
export const UPDATE_CHANGE_PLAN_DETAILS = 'UPDATE_CHANGE_PLAN_DETAILS';
export const UPDATE_PLAN_LEVELS = 'UPDATE_PLAN_LEVELS';
export const UPDATE_SELECTED_PLAN = 'UPDATE_SELECTED_PLAN';

export const fetchBillingDashboard = orgId => dispatch => {
  dispatch({ type: FETCH_BILLING_DASHBOARD_REQUEST });
  return ApiService.get(ApiConstants.billingDashboardURL(orgId))
    .then((res = {}) => {
      dispatch(updateBillingData(res.content));
      return res.content;
    })
    .catch(error => {
      dispatch(updateBillingData({ billingData: {} }));
      ErrorService.capture('Error fetching billing dashboard', error);
    });
};

export const handleBillingSuccess = (billingData, history) => dispatch => {
  const { scanUsage = [] } = billingData;
  const scansUsed = Helpers.calculateScansUsed(scanUsage);
  if (scansUsed >= 10) {
    dispatch(
      toastrActions.addToastr({
        id: 'SCANS_LIMIT',
        title: 'Monthly limit reached',
        level: 'error',
        message:
          'You have reached the scan limit for this month. No scans can be performed. Upgrade today to get more scans, premium vulnerability data, scan history, and more.',
        callbackText: 'Upgrade Now',
        callback: () => {
          dispatch(
            toastrActions.removeToastr({
              id: 'SCANS_LIMIT',
            })
          );
          history.push('/org/settings/subscription/update');
        },
      })
    );
  }
};

export const updateBillingData = billingData => ({
  type: UPDATE_BILLING_DATA,
  billingData,
});

export const showModal = modalType => ({
  type: SHOW_MODAL,
  modalType,
});

export const resetBillingInfo = () => ({
  type: RESET_BILLING_INFO,
});

export const saveBillingInfoCopies = () => ({
  type: SAVE_BILLING_INFO_COPIES,
});

export const updateBillingField = (field, value) => ({
  type: UPDATE_BILLING_FIELD,
  field,
  value,
});

export const fetchPlanLevels = () => dispatch => {
  ApiService.get(ApiConstants.billingCatalogURL)
    .then(res => {
      dispatch(updatePlanLevels(res));
    })
    .catch(error => {
      dispatch(updatePlanLevels([]));
      ErrorService.capture('Error fetching plan levels', error);
    });
};

export const updatePlanLevels = plans => ({
  type: UPDATE_PLAN_LEVELS,
  plans,
});

export const updateSelectedPlan = plan => ({
  type: UPDATE_SELECTED_PLAN,
  plan,
});

export const submitBillingInfo = (orgId, options) => dispatch => {
  ApiService.put(ApiConstants.billingAccountURL(orgId), { data: options })
    .then(() => {
      dispatch(showModal());
      dispatch(clearBillingInfoCopies());
    })
    .catch((error = {}) => {
      dispatch(
        updateBillingError(
          error.message || 'Error submitting form. Please check inputs and try again.'
        )
      );
      ErrorService.capture('Error submitting billing info', error);
    });
};

export const clearBillingInfoCopies = () => ({
  type: CLEAR_BILLING_INFO_COPIES,
});

export const updateBillingError = message => ({
  type: UPDATE_BILLING_ERROR,
  message,
});

export const submitPaymentMethod = (result, planId, orgId) => dispatch => {
  const { token = {} } = result;
  const subscriptionRequest = {
    chargeToken: token.id,
    billingEmail: token.email,
    productRatePlanId: planId,
  };

  return ApiService.put(ApiConstants.billingPaymentMethodURL(orgId), {
    data: subscriptionRequest,
  })
    .then(() => {
      dispatch(showModal());
      dispatch(fetchBillingDashboard(orgId));
      dispatch(
        toastrActions.addToastr({
          id: 'payment-update-succes',
          title: 'Payment Method Updated',
          message: 'Your payment method has successfully been updated',
          level: 'success',
        })
      );
      return { success: true };
    })
    .catch(error => {
      dispatch(
        paymentActions.updatePaymentError(
          error.message ||
            'There was an error processing the payment. Please fix any incorrect payment info and try again.'
        )
      );
      ErrorService.capture('Error submitting payment method', error);
      return {};
    });
};

export const fetchChangePlanDetails = orgId => dispatch => {
  ApiService.get(ApiConstants.billingChangePlanURL(orgId))
    .then(res => {
      dispatch(updateChangePlanDetails(res));
    })
    .catch(error => {
      ErrorService.capture('Error fetching change plan details', error);
    });
};

export const updateChangePlanDetails = details => ({
  type: UPDATE_CHANGE_PLAN_DETAILS,
  details,
});

export const submitUpdatePlan = (
  orgId,
  productPlanId,
  prorationDate,
  downgradeStatus
) => dispatch => {
  const successMessageMap = {
    DOWNGRADE:
      'We have successfully updated your plan level. The new plan level will take effect at the beginning of the next month.',
    HOBBY:
      'Your subscription has been canceled. You will return to the Personal plan at the end of the month.',
    TRIAL_EXPIRED_DOWNGRADE: 'Your subscription has been updated to the Personal plan.',
    CANCEL: 'Pending changes to your plan have been successfully canceled',
  };
  const options = {
    productPlanId,
  };
  if (prorationDate) {
    options.prorationDate = prorationDate;
  }

  return ApiService.post(ApiConstants.billingChangePlanURL(orgId), { data: options })
    .then(() => {
      dispatch(showModal());
      dispatch(fetchBillingDashboard(orgId));
      dispatch(toastrActions.removeToastr({ id: 'PLAN_CHANGE_SUCCESS' }));
      dispatch(
        toastrActions.addToastr({
          id: 'PLAN_CHANGE_SUCCESS',
          level: 'success',
          title:
            downgradeStatus === 'CANCEL' ? 'Plan change canceled' : 'Plan level update success',
          message:
            successMessageMap[downgradeStatus] || 'We have successfully updated your plan level.',
        })
      );

      return { success: true };
    })
    .catch(error => {
      dispatch(
        toastrActions.addToastr({
          id: 'PLAN_CHANGE_ERROR',
          level: 'error',
          title: 'Plan level update error',
          message: 'There was an error updating your plan level.',
        })
      );
      ErrorService.capture('Error updating plan level', error);
      return {};
    });
};

export const toggleAllowOverages = (orgId, allowOverages) => dispatch => {
  dispatch(updateOverages(allowOverages));
  ApiService.post(ApiConstants.billingOveragesURL(orgId), { data: { allowOverages } })
    .then(() => {
      // we optimistically updated the checkbox value above, so no need to do anything here
    })
    .catch(error => {
      dispatch(updateOverages(!allowOverages));
      ErrorService.capture('Error toggling overages', error);
    });
};

export const updateOverages = allowOverages => ({
  type: UPDATE_ALLOW_OVERAGES,
  allowOverages,
});

export const requestSwitch = () => ({
  type: REQUEST_SWITCH,
  meta: {
    snowplow: true,
  },
});
