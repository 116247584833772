import produce from 'immer';

import * as actions from '~/actions/repoScopeSwitcher';

const defaultState = {
  activeRefType: 'branch',
  specificOptionByRefType: {},
  isValidatingCommit: false,
  validCommit: false,
  invalidCommit: false,
};

const repoScopeSwitcher = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.UPDATE_ACTIVE_REF_TYPE:
        draft.activeRefType = action.refType;
        break;
      case actions.UPDATE_SPECIFIC_OPTION:
        draft.specificOptionByRefType[action.refType] = action.option;
        break;
      case actions.VALIDATE_COMMIT_REQUEST:
        draft.isValidatingCommit = true;
        draft.invalidCommit = false;
        draft.validCommit = false;
        break;
      case actions.COMMIT_VALIDATION_FAILURE:
        draft.isValidatingCommit = false;
        draft.invalidCommit = true;
        draft.validCommit = false;
        break;
      case actions.COMMIT_VALIDATION_SUCCESS:
        draft.isValidatingCommit = false;
        draft.invalidCommit = false;
        draft.validCommit = true;
        draft.specificOptionByRefType.commit = action.commit;
        break;
      case actions.RESET_SPECIFIC_OPTIONS:
        draft.specificOptionByRefType = {};
    }
  });
};

export default repoScopeSwitcher;
