import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getNavModel } from '~/utils/navServiceHelper';
import { VCNavItem } from '~/views/components/veracode/VCNavItem';
import * as vcNavActions from '~/actions/vcAppActions/vcNavActions';
import { VCNavState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import classnames from 'classnames';

interface VCNavProps {
  vcNavState: VCNavState;
  isScaPlatformUIOpen: boolean;
}

class VCNav extends Component<VCNavProps, {}> {
  render() {
    const { vcNavState, isScaPlatformUIOpen } = this.props;
    const { navigation } = vcNavState;
    const [navItemPrimary, navItemSecondary] = getNavModel(navigation);
    const start = navItemPrimary.navItems.length;
    return (
      <nav
        className={classnames('vcNav', isScaPlatformUIOpen ? 'nav-dark-style' : 'nav-vosp-style')}
        role="navigation"
      >
        <ul className="primary">
          {navItemPrimary.navItems.map((item, index) => (
            <VCNavItem id={index} key={index} item={item} />
          ))}
        </ul>
        <ul className="secondary">
          {navItemSecondary.navItems.map((item, index) => (
            <VCNavItem id={start + index} key={start + index} align="right" item={item} />
          ))}
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    vcNavState: state.vcNavState,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      fetchVeracodeNav: vcNavActions.fetchVeracodeNav,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VCNav);
