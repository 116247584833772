import React from 'react';
import { Link } from 'react-router-dom';
import SourceClearLoader from '~/components/SourceClearLoader';

interface WorkspaceUserGroupsQuickViewProps {
  teamId?: string;
  data?: any[];
  showDetailsLink?: boolean;
}

const WorkspaceUserGroupsQuickView: React.FunctionComponent<WorkspaceUserGroupsQuickViewProps> = props => {
  const { data: groups = [], teamId, showDetailsLink } = props;
  const columnWidths = {
    name: 'col-1-3',
    filler: 'col-1-6',
    description: '',
    admin: '',
  };

  if (!groups.length) {
    return (
      <div className="">
        <SourceClearLoader />
      </div>
    );
  }

  return (
    <div className="bo-v--1 border-color--muted mv-- pb-">
      <div className="col-1-1 bg-color--muted-light pv-- font--h7 pl- color--muted-dark">
        <strong>TEAMS</strong>
      </div>
      <div className="grid mt-">
        <div className={`grid__item ${columnWidths.name}`}>
          <strong>Team Name</strong>
        </div>
        <div className={`grid__item ${columnWidths.description}`}>
          <strong>Team Description</strong>
        </div>
        <div className={`grid__item text--center ${columnWidths.admin}`}>
          <strong>Workspace Admin</strong>
        </div>
        <div className={`grid__item ${columnWidths.filler}`} />
      </div>

      <div className="overflow--auto max-height--300">
        {groups.map(groupObj => {
          const { group = {}, roles = [] } = groupObj;
          const { id: groupId, name, description } = group;
          const isWorkspaceAdmin = roles.includes('WORKSPACE_ADMIN');

          return (
            <div className="grid mt--" key={`workspace-user-group-${groupId}`}>
              <div className={`grid__item ${columnWidths.name}`}>
                {showDetailsLink ? (
                  <strong>
                    <Link
                      to={`/workspaces/${teamId}/user-management/teams/${groupId}`}
                      className="link--obvious"
                    >
                      {name}
                    </Link>
                  </strong>
                ) : (
                  <div>{name}</div>
                )}
              </div>
              <div className={`grid__item ${columnWidths.description}`}>{description || '--'}</div>
              <div className={`grid__item text--center ${columnWidths.admin}`}>
                {isWorkspaceAdmin ? 'Yes' : '--'}
              </div>
              <div className={`grid__item ${columnWidths.filler}`} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkspaceUserGroupsQuickView;
