import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';

import { History } from 'history';

/* REDUCERS ORGANIZED ALPHABETICALLY */
import agentState from './agentState';
import agentScanHistoryState from './agentScanHistoryState';
import appLoadState from './appLoadState';
import agentModalState from './agentModalState';
import billingState from './billingState';
import clipboardState from './clipboardState';
import componentHeadSafeState from './componentHeadSafeState';
import datePickerState from './datePickerState';
import dropdownMenuState from './dropdownMenuState';
import expandableSectionState from './expandableSectionState';
import groupState from './groupState';
import insightsByType from './insightsByType';
import integrationByIdState from './integrationByIdState';
import integrationModalState from './integrationModalState';
import integrationState from './integrationState';
import invoicesById from './invoicesById';
import libraryCatalogState from './libraryCatalogState';
import licenseState from './licenseState';
import loginState from './loginState';
import messageState from './messageState';
import modalState from './modalState';
import myState from './myState';
import navigationState from './navigationState';
import orgAgentState from './orgAgentState';
import orgPoliciesState from './orgPoliciesState';
import orgState from './orgState';
import orgUserInviteState from './orgUserInviteState';
import orgUserState from './orgUserState';
import orgTeamState from './orgTeamState';
import pageSizeState from './pageSizeState';
import passwordInputs from './passwordInputs';
import paymentState from './paymentState';
import policiesState from './policiesState';
import policyValidationState from './policyValidationState';
import popoverState from './popoverState';
import projectScanHistory from './projectScanHistory';
import projectBranchState from './projectBranchState';
import projectStats from './projectStats';
import projectSettingsState from './projectSettingsState';
import projectNotificationsSettingsState from './projectNotificationsSettingsState';
import quickviewState from './quickviewState';
import repoDataById from './repoDataById';
import reportDetailsPageState from './reportDetailsPageState';
import reportFilterState from './reportFilterState';
import reportIssuesState from './reportIssuesState';
import reportScope from './reportScope';
import reportState from './reportState';
import reports, * as fromReports from './reports';
import reportsByType from './reportsByType';
import reportsByTypeCSV from './reportsByTypeCSV';
import reportsPDF from './reportsPDF';
import reportSelectedRows from './reportSelectedRows';
import repoScope from './repoScope';
import repoScopeSwitcher from './repoScopeSwitcher';
import sidebarState from './sidebarState';
import signupState from './signupState';
import singleSignupState from './singleSignupState';
import sortByReportType from './sortByReportType';
import startScanModalState from './startScanModalState';
import stats from './stats';
import statsByWorkspaceId from './statsByWorkspaceId';
import statsByProjectId from './statsByProjectId';
import teamGroupsState from './teamGroupsState';
import teamState from './teamState';
import teamUsersState from './teamUsersState';
import toastrState from './toastrState';
import twoFactorState from './twoFactorState';
import upgradeModalState from './upgradeModalState';
import unsubscribeState from './unsubscribeState';
import usageState from './usageState';
import userNotifications from './userNotifications';
import userStatus from './userStatus';
import userManagementQuickViewState from './userManagementQuickViewState';
import welcomeModalState from './welcomeModalState';
import welcomeState from './welcomeState';
import workspaceInviteState from './workspaceInviteState';
import workspaceNotificationsSettingsState from './workspaceNotificationsSettingsState';
import workspaceScanDateState from './workspaceScanDate';
import apiKeyCredentials from './apiKeyCredentials';
import vcDropdownState from './vcAppState/vcDropdownState';
import vcApplicationState from './vcAppState/vcApplicationState';
import vcPageState from './vcAppState/vcPageState';
import vcNavState from './vcAppState/vcNavState';
import vcMyState from './vcAppState/vcMyState';
import unmatchedLibState from './unmatchedLibState';

import { AgentState } from './agentState.types';
import {
  VCPageState,
  VCNavState,
  VCMyState,
  VCApplicationState,
  VCDropdownState,
} from './vcAppState/vcAppStateTypes/types';
import { UsageState } from './usageState.types';
import { ProjectBranchState } from './projectBranchState.types';
import { IntegrationState } from './integrationState.types';
import { ReportDetailsPageState } from '~/reducers/reportDetailsPageState.types';
import unifiedPoliciesState from '~/reducers/unifiedPoliciesState';
import { UnifiedPoliciesState } from '~/reducers/unifiedPoliciesState.types';

export interface RootState {
  router: RouterState;
  agentState: AgentState;
  agentScanHistoryState;
  appLoadState;
  agentModalState;
  billingState;
  clipboardState;
  componentHeadSafeState;
  datePickerState;
  dropdownMenuState;
  expandableSectionState;
  groupState;
  insightsByType;
  integrationByIdState;
  integrationModalState;
  integrationState: IntegrationState;
  invoicesById;
  libraryCatalogState;
  loginState;
  messageState;
  modalState;
  myState: App.MyState;
  navigationState;
  orgAgentState;
  orgPoliciesState;
  orgState: App.OrgState;
  orgUserState;
  orgTeamState;
  orgUserInviteState;
  passwordInputs;
  paymentState;
  policiesState;
  policyValidationState;
  popoverState;
  projectScanHistory;
  projectBranchState: ProjectBranchState;
  projectStats;
  projectNotificationsSettingsState;
  projectSettingsState;
  quickviewState;
  repoDataById;
  reportDetailsPageState: ReportDetailsPageState;
  reportFilterState;
  reportIssuesState;
  reportScope;
  reportState;
  reports;
  reportsByType;
  reportsByTypeCSV;
  reportsPDF;
  reportSelectedRows;
  repoScope;
  repoScopeSwitcher;
  sidebarState;
  signupState;
  singleSignupState;
  sortByReportType;
  startScanModalState;
  stats;
  statsByWorkspaceId;
  statsByProjectId;
  teamGroupsState;
  teamState: App.TeamState;
  teamUsersState;
  toastrState;
  twoFactorState;
  unifiedPoliciesState: UnifiedPoliciesState;
  upgradeModalState;
  unsubscribeState;
  usageState: UsageState;
  userNotifications;
  userStatus;
  userManagementQuickViewState;
  welcomeModalState;
  welcomeState;
  workspaceInviteState;
  workspaceNotificationsSettingsState;
  apiKeyCredentials;
  vcDropdownState: VCDropdownState;
  vcPageState: VCPageState;
  vcNavState: VCNavState;
  vcMyState: VCMyState;
  vcApplicationState: VCApplicationState;
  unmatchedLibState;
  licenseState;
  workspaceScanDateState;
  pageSizeState;
}

/* REDUCERS ORGANIZED ALPHABETICALLY */
const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    agentState,
    agentScanHistoryState,
    appLoadState,
    agentModalState,
    billingState,
    clipboardState,
    componentHeadSafeState,
    datePickerState,
    dropdownMenuState,
    expandableSectionState,
    groupState,
    insightsByType,
    integrationByIdState,
    integrationModalState,
    integrationState,
    invoicesById,
    libraryCatalogState,
    loginState,
    messageState,
    modalState,
    myState,
    navigationState,
    orgAgentState,
    orgPoliciesState,
    orgState,
    orgUserState,
    orgTeamState,
    orgUserInviteState,
    pageSizeState,
    passwordInputs,
    paymentState,
    policiesState,
    policyValidationState,
    popoverState,
    projectScanHistory,
    projectBranchState,
    projectStats,
    projectNotificationsSettingsState,
    projectSettingsState,
    quickviewState,
    repoDataById,
    reportDetailsPageState,
    reportFilterState,
    reportIssuesState,
    reportScope,
    reportState,
    reports,
    reportsByType,
    reportsByTypeCSV,
    reportsPDF,
    reportSelectedRows,
    repoScope,
    repoScopeSwitcher,
    sidebarState,
    signupState,
    singleSignupState,
    sortByReportType,
    startScanModalState,
    stats,
    statsByWorkspaceId,
    statsByProjectId,
    teamGroupsState,
    teamState,
    teamUsersState,
    toastrState,
    twoFactorState,
    unifiedPoliciesState,
    upgradeModalState,
    unsubscribeState,
    usageState,
    userNotifications,
    userStatus,
    userManagementQuickViewState,
    welcomeModalState,
    welcomeState,
    workspaceInviteState,
    workspaceNotificationsSettingsState,
    workspaceScanDateState,
    apiKeyCredentials,
    vcDropdownState,
    vcPageState,
    vcNavState,
    vcMyState,
    vcApplicationState,
    unmatchedLibState,
    licenseState,
  });

export default rootReducer;

export const getIsFetching = (state: RootState) => fromReports.getIsFetching(state.reports);
