import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash';
import ScanHistoryRow from '~/components/ProjectDetailsPage/ScanHistoryRow';
import Select from 'react-select';
import Spinner from '~/components/Spinner';
import Pagination from '~/components/Pagination';
import UpgradeLink from '~/containers/UpgradeLink';

import * as reportsActions from '~/actions/reports';
import * as reportScopeActions from '~/actions/reportScope';
import * as insightsActions from '~/actions/insights';
import * as statsActions from '~/actions/stats';
import * as projectScanHistoryActions from '~/actions/projectScanHistory';
import * as repoScopeActions from '~/actions/repoScope';
import * as workspaceScanDateActions from '~/actions/workspaceScanDate';

import OrgPaidStatus from '~/utils/OrgPaidStatus';

import Helpers from '~/utils/Helpers';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface ProjectScanHistoryProps extends RouteComponentProps {
  teamId: string;
  projectId: string;
  navigationState: object;
  reportFilterState: object;
  repoDataById: object;
  reportState: object;
  repoScope: object;
  orgState: App.OrgState;
  projectScanHistoryState: object;
}
class ProjectScanHistory extends Component<
  ProjectScanHistoryProps & ReturnType<typeof mapDispatchToProps>
> {
  componentWillUnmount() {
    this.props.reportScopeActions.updateReportScope('repoScanId', '');
    this.props.projectScanHistoryActions.resetProjectScanFilters();
    this.props.projectScanHistoryActions.resetProjectScansData();
  }

  refreshProjectScanData() {
    const { teamId } = this.props;

    this.props.projectScanHistoryActions.fetchProjectScans(teamId);
  }

  updateOptionsFilter = refs => {
    this.props.projectScanHistoryActions.updateProjectScanFilter('refs', refs.value); //BE handles Scan Findings filter by scope
    this.refreshProjectScanData();
  };

  handlePageClick = page => {
    const { teamId } = this.props;
    this.props.projectScanHistoryActions.fetchProjectScans(teamId, page);
  };

  handleScanItemClick = data => {
    const { id: scanId, branch = '', tag = '' } = data;

    const { teamId, projectId, navigationState, projectScanHistoryState, history } = this.props;
    const { activeReportType = '' } = navigationState;
    const { latestScanByBranchOrTag = {} } = projectScanHistoryState;
    const ref = branch || tag || '';

    // Check if the latest scan based on branch or tag is tracked in the state
    // If not, fetch it. Else, it already exists in the state and make no calls.
    if (ref && !latestScanByBranchOrTag.hasOwnProperty(ref)) {
      this.props.projectScanHistoryActions.fetchLatestProjectScanByRefs(teamId, { branch, tag });
    }

    this.props.repoScopeActions.performRepoScopeChange(data, history);

    this.props.projectScanHistoryActions.updateSelectedProjectScan(data);
    this.props.reportScopeActions.updateReportScope('repoScanId', parseInt(scanId));

    this.props.insightsActions.fetchProjectInsights(teamId, projectId);
    this.props.statsActions.fetchProjectStats(teamId, projectId);
    this.props.reportsActions.fetchReport(teamId, activeReportType);
  };

  render() {
    const {
      projectScanHistoryState = {},
      orgState,
      repoDataById = {},
      projectId = '',
    } = this.props;
    const { org } = orgState;
    const {
      isFetching = false,
      selectedScan = {},
      data: scansData = {},
      filters = {},
    } = projectScanHistoryState;
    const { [projectId]: projectData = {} } = repoDataById;
    const { refs = {} } = filters;
    const branches = projectData.branches || [];
    const tags = projectData.tags || [];

    /*
     * See projectScanHistory reducer for notes on the filter value and to understand the structure of
     * option arrays that follow
     */
    const branchOptions = branches.map(branch => {
      return {
        value: {
          ref: branch,
          refType: 'branch',
        },
        label: (
          <div className="flex flex--align-items--center">
            <i className="fas fa-code-fork mr--" /> {branch}
          </div>
        ),
      };
    });
    const tagOptions = tags.map(tag => {
      return {
        value: {
          ref: tag,
          refType: 'tag',
        },
        label: (
          <div className="flex flex--align-items--center">
            <i className="fas fa-tag font--11 top--3 mr---" /> {tag}
          </div>
        ),
      };
    });

    const defaultFilterOption = { value: {}, label: 'All' };
    const refFieldOptions = [defaultFilterOption, ...branchOptions, ...tagOptions];
    const selectedRefValue =
      refFieldOptions.find(
        option =>
          option &&
          option.value &&
          option.value.ref === refs.ref &&
          option.value.refType === refs.refType
      ) || defaultFilterOption;

    const { id: selectedScanId } = selectedScan;
    const isOrgPaidOrTrial = OrgPaidStatus.isOrgPaidOrTrial(org);

    if (!isOrgPaidOrTrial) {
      return (
        <div className="grid mb">
          <div className="grid__item col-1-1 bg-color--white-medium">
            <div className="p+ font--20">
              <p className="font--18 lh+ mb">
                View a history of your scans across all branches, including the number of issues
                found and project snapshots.
              </p>
              <p className="font--18 lh+">
                To unlock scan history, upgrade today! You'll also get more scans, premium library
                and vulnerability data, JIRA and GitHub integrations, and more!
              </p>
              <p className="pt--">
                <UpgradeLink
                  title="UPGRADE_MODAL_SCAN_HISTORY"
                  location="SCAN_HISTORY_SIDEBAR"
                  className="block bo-rad--3 col-1-1 bg-color--success-dark text--center color--white text--bold p- mt+"
                >
                  Upgrade Now
                </UpgradeLink>
              </p>
            </div>
          </div>
        </div>
      );
    }

    //Load scan data
    const { _embedded = {}, page = {} } = scansData;
    const { repoScans = [] } = _embedded;
    //End load scan data

    return (
      <div className="grid mb">
        <div className="grid__item col-1-1 bg-color--white">
          <div className="grid">
            <div className="grid__item">
              <div className="pl pt pb- pr" data-automation-id="BranchOrTagSelector">
                <Select
                  name={`ref-filter`}
                  value={refFieldOptions.find(option => option.value === selectedRefValue)}
                  options={refFieldOptions}
                  onChange={this.updateOptionsFilter}
                  isMulti={false}
                  placeholder={'Select branch or tag'}
                  isClearable={false}
                  className={'srcclr-react-select-container'}
                  classNamePrefix={'srcclr-react-select'}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid__item col-1-1">
          <div className="text--right flex align-items--center justify-content--center">
            <div className="col-1-2">
              <Pagination
                currentPage={page.number}
                totalPages={page.totalPages || 1}
                handlePageClick={page => this.handlePageClick(page)}
              />
            </div>
          </div>
          <div className="grid__item col-1-1 pl0 bg-color--white-medium" data-automation-id="ScanHistoryRows">
            <div className={`overflow--auto`}>
              {!isFetching && (
                <div className="width--400">
                  {repoScans.map((scan, index) => {
                    if (!scan) {
                      return;
                    }

                    const formattedDate = Helpers.formatDate(scan.scanDate);
                    const issuesCount =
                      (scan.vulnIssues || 0) +
                      (scan.libraryIssues || 0) +
                      (scan.licenseIssues || 0);
                    const maxSeverity =
                      _.max([
                        scan.vulnMaxSeverity,
                        scan.libraryMaxSeverity,
                        scan.licenseMaxSeverity,
                      ]) || 0;
                    const isSelected = selectedScanId === scan.id;

                    return (
                      <ScanHistoryRow
                        key={`${index}-${scan.id}`}
                        id={scan.id}
                        date={formattedDate}
                        issuesCount={issuesCount}
                        commit={scan.commit}
                        branch={scan.branch}
                        tag={scan.tag}
                        type={scan.type}
                        name={scan.repoName}
                        onClick={!isSelected ? this.handleScanItemClick : () => {}}
                        isSelected={isSelected}
                        maxSeverity={maxSeverity}
                      />
                    );
                  })}
                </div>
              )}
              {isFetching && (
                <div className="text--center mt">
                  <Spinner
                    size={24}
                    strokeWidth={4}
                    backgroundColor="bg-color--white-medium"
                    polygonClassName="fill--white-medium stroke--gray-dark"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    navigationState: state.navigationState,
    projectScanHistoryState: state.projectScanHistory,
    reportFilterState: state.reportFilterState,
    reportState: state.reportState,
    repoDataById: state.repoDataById,
    repoScope: state.repoScope,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    insightsActions: bindActionCreators(insightsActions as any, dispatch),
    repoScopeActions: bindActionCreators(repoScopeActions as any, dispatch),
    reportsActions: bindActionCreators(reportsActions as any, dispatch),
    reportScopeActions: bindActionCreators(reportScopeActions as any, dispatch),
    projectScanHistoryActions: bindActionCreators(projectScanHistoryActions as any, dispatch),
    statsActions: bindActionCreators(statsActions as any, dispatch),
    workspaceScanDateActions: bindActionCreators(workspaceScanDateActions as any, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectScanHistory));
