import React from 'react';
import CopyToClipboard from '~/containers/CopyToClipboard';
interface AgentTokenControlProps {
  token?: string;
  isRegenerate?: boolean;
  handleTokenGeneration: (...args: any[]) => any;
}

const AgentTokenControl: React.FunctionComponent<AgentTokenControlProps> = props => {
  const { token, isRegenerate } = props;

  function handleTokenGeneration() {
    props.handleTokenGeneration();
  }

  return (
    <div className="grid">
      <div className="grid__item col-3-4">
        <CopyToClipboard
          id="agent-token"
          value={token || ''}
          placeholder={isRegenerate ? 'Regenerate token' : 'Generate new token'}
        />
      </div>
      <div className="grid__item col-1-4 flex">
        <button className="btn btn--primary col-1-1" onClick={() => handleTokenGeneration()}>
          {isRegenerate ? 'Regenerate' : 'Generate'} Token
        </button>
      </div>
    </div>
  );
};

export default AgentTokenControl;
