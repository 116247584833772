import React from 'react';
import CircleScreenShot from '~/images/circle-screenshot.png';
import TokenControl from '~/containers/TokenControl';
import PowerShellExecutionPolicyStep from '~/components/PowerShellExecutionPolicyStep';
import CIScriptModeTabHeader from '~/components/CIScriptModeTabHeader';
import TabWrapper from '~/components/TabWrapper';
import TabContent from '~/components/TabContent';
import RegionHelpers from '~/utils/RegionHelpers';
import config from '~/config';

interface CircleConfigurationProps {
  teamId?: string;
  integrationType: string;
  onScriptTabChange?: (...args: any[]) => any;
  activeScriptMode?: string;
  renderAgentWorkspaceSelection?: (...args: any[]) => any;
}

const CircleConfiguration: React.FunctionComponent<CircleConfigurationProps> = props => {
  const { teamId, integrationType, activeScriptMode = 'curl' } = props;

  function handleTabChange(field) {
    props.onScriptTabChange(field);
  }

  return (
    <div className="mb+">
      <div className="font--h6">Step one: Set environment variable</div>
      <p className="mt-">
        Select the repository you wish to scan from your Circle-CI environment {'>'} "Project
        Settings" {'>'} "Environment Variables" (Under "Build Settings"), then set the{' '}
        <code>SRCCLR_API_TOKEN</code> environment variable to the authentication token below{RegionHelpers.getRegionEnvVarInstruction(config.REGION)}.
      </p>
      <div className="mt-">
        <TokenControl teamId={teamId} integrationType={integrationType} />
      </div>
      <img className="col-1-1 mt-" src={CircleScreenShot} alt="Circle CI setup screenshot" />
      {props.renderAgentWorkspaceSelection()}

      <div className="font--h6 mt-">Step two: Configure Circle CI</div>

      <TabWrapper className={`mb mt-`}>
        <CIScriptModeTabHeader
          onScriptTabChange={handleTabChange}
          activeScriptMode={activeScriptMode}
        />
        <TabContent field={'curl'} isActive={activeScriptMode === 'curl'}>
          <p>
            Add the following to the <code>post</code> step in the <code>test</code> within your{' '}
            <code>circle.yml</code> file:
          </p>
          <pre>
            test:
            <br />
            {'  '}
            post:
            <br />
            {'  '}
            {'  '}- {`curl -sSL https://download.sourceclear.com/ci.sh | sh`}
          </pre>
        </TabContent>
        <TabContent field={'powershell'} isActive={activeScriptMode === 'powershell'}>
          <p>
            Add the following to the <code>post</code> step in the <code>test</code> within your{' '}
            <code>circle.yml</code> file:
          </p>
          <pre className="overflow-x--scroll">
            test:
            <br />
            {'  '}
            post:
            <br />
            {'  '}
            {'  '}-{' '}
            {`iex ((New-Object
            System.Net.WebClient).DownloadString('https://download.srcclr.com/ci.ps1')); srcclr scan`}
          </pre>
          <div className="mb">
            <PowerShellExecutionPolicyStep />
          </div>
        </TabContent>
      </TabWrapper>

      <div className="mt">
        For detailed instructions
        <a
          className="link--obvious link--icon-hover"
          href="https://help.veracode.com/go/t_sc_ci_token"
          target="_blank"
        >
          {' visit the docs.'}
          <i className="sci sci__arrow--external-link pl-- va-default" />
        </a>
      </div>
    </div>
  );
};

export default CircleConfiguration;
