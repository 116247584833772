import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';

import * as messageActions from '~/actions/message';
import * as integrationModalActions from '~/actions/integrationModal';

import { INTEGRATION_NAME_MAP } from '~/constants/ModelConstants';

export const JIRA_SAVE_SUCCESS = 'JIRA_SAVE_SUCCESS';
export const REMOVE_TEAM_FROM_ISSUE_SETTINGS_DATA = 'REMOVE_TEAM_FROM_ISSUE_SETTINGS_DATA';
export const RESET_SELECTED_WORKSPACES = 'RESET_SELECTED_WORKSPACES';
export const SAVING_JIRA = 'SAVING_JIRA';
export const UPDATE_WORKSPACE_NAME = 'UPDATE_WORKSPACE_NAME';
export const SHOW_TEAM_MODAL = 'SHOW_TEAM_MODAL';
export const TOGGLE_SELECTED_WORKSPACE_ADMIN_FIELD = 'TOGGLE_SELECTED_WORKSPACE_ADMIN_FIELD';
export const UPDATE_ISSUE_SETTINGS_DATA = 'UPDATE_ISSUE_SETTINGS_DATA';
export const UPDATE_SELECTED_WORKSPACES = 'UPDATE_SELECTED_WORKSPACES';
export const UPDATE_TEAMS = 'UPDATE_TEAMS';
export const UPDATE_TEAM_NAME = 'UPDATE_TEAM_NAME';

export const updateSelectedWorkspaces = teamId => ({
  type: UPDATE_SELECTED_WORKSPACES,
  teamId,
});

export const resetSelectedWorkspaces = () => ({
  type: RESET_SELECTED_WORKSPACES,
});

export const toggleSelectedWorkspaceAdminField = workspaceId => ({
  type: TOGGLE_SELECTED_WORKSPACE_ADMIN_FIELD,
  workspaceId,
});

export const savingJira = () => ({
  type: SAVING_JIRA,
});

export const jiraSaveSuccess = () => ({
  type: JIRA_SAVE_SUCCESS,
});

export const updateIssueSettingsData = (teamId, issueSettingsData) => ({
  type: UPDATE_ISSUE_SETTINGS_DATA,
  teamId,
  issueSettingsData,
});

export const removeTeamFromIssueSettingsData = teamId => ({
  type: REMOVE_TEAM_FROM_ISSUE_SETTINGS_DATA,
  teamId,
});

export const fetchAllTeamsIntegrations = () => (dispatch, getState) => {
  const { teams = [] } = getState().teamState;
  const teamPromises = teams.map(team => dispatch(fetchIssueData(`${team.id}`)));

  return Promise.all(teamPromises);
};

export const fetchIssueData = teamId => dispatch => {
  const endpoint = `/issues/settings/${teamId}`;

  return ApiService.get(endpoint)
    .then(
      res => {
        dispatch(updateIssueSettingsData(teamId, res));
      },
      error => {
        ErrorService.capture('Error fetching issues settings data', error);
        dispatch(updateIssueSettingsData(teamId, {}));
      }
    )
    .catch(error => {
      ErrorService.capture('Error fetching issues settings data', error);
      dispatch(updateIssueSettingsData(teamId, {}));
    });
};

export const updateTeams = teams => ({
  type: UPDATE_TEAMS,
  teams,
});

export const saveIssueSettingsData = (issueSettingsData, teamId) => dispatch => {
  dispatch(savingJira());
  const issueSettingsDataToSave = scrubIssueSettingsData(issueSettingsData);

  ApiService.post(`/issues/settings/${teamId}`, { data: issueSettingsDataToSave })
    .then(() => {
      dispatch(fetchIssueData(teamId));
      dispatch(jiraSaveSuccess());
      dispatch(messageActions.addMessageWithTimeout({ type: 'jiraSaveSuccess' }));
      dispatch(integrationModalActions.closeIntegrationModal());
    })
    .catch(() => {});
};

export const toggleDisableGithubIssues = (disableGithubIssues, teamId) => (dispatch, getState) => {
  const { teamState } = getState();
  const { issueSettingsDataByTeamId = {} } = teamState;
  const issueSettingsData = issueSettingsDataByTeamId[teamId] || {};
  issueSettingsData.disableGithubIssues = disableGithubIssues;

  const issueSettingsDataToSave = scrubIssueSettingsData(issueSettingsData);

  ApiService.post(`/issues/settings/${teamId}`, { data: issueSettingsDataToSave })
    .then(() => {
      dispatch(messageActions.addMessageWithTimeout({ type: 'githubSaveSuccess' }, 1000));
      dispatch(fetchIssueData(teamId));
    })
    .catch(() => {});
};

export const deleteJiraEnterprise = teamId => (dispatch, getState) => {
  const { teamState } = getState();
  const { issueSettingsDataByTeamId = {} } = teamState;
  const issueSettingsData = issueSettingsDataByTeamId[teamId] || {};
  issueSettingsData.projects = [];
  issueSettingsData.url = null;

  const issueSettingsDataToSave = scrubIssueSettingsData(issueSettingsData);

  ApiService.post(`/issues/settings/${teamId}`, { data: issueSettingsDataToSave })
    .then(() => {
      dispatch(fetchIssueData(teamId));
    })
    .catch(error => {
      ErrorService.capture(`Error deleting ${INTEGRATION_NAME_MAP.JIRA_LEGACY}`, { error });
    });
};

export const scrubIssueSettingsData = issueSettingsData => {
  const scrubbedData = {
    id: issueSettingsData.id,
    settings: {
      url: issueSettingsData.url,
      disableGithubIssues: issueSettingsData.disableGithubIssues,
      projects: [],
    },
  };
  if (issueSettingsData.projects && issueSettingsData.projects.length) {
    issueSettingsData.projects.forEach(project => {
      scrubbedData.settings.projects.push({
        projectId: project.projectId,
        projectName: project.projectName,
        issueTypeId: project.issueTypeId,
      });
    });
  }

  return scrubbedData;
};

/*
 * ACTION HELPER FUNCTIONS
 */

export const saveTeamName = (teamId, name) => (dispatch, getState) => {
  const teamState = getState();
  const { teams = [] } = teamState;
  const activeTeam = teams.find(team => team.id === teamId) || {};
  const updatedTeam = { ...activeTeam, name };

  return ApiService.put(`/teams/${teamId}`, { data: updatedTeam })
    .then(() => {
      dispatch(fetchTeams());
      return { success: true };
    })
    .catch(error => {
      ErrorService.capture('Error saving team name', error);
      return {};
    });
};

export const deleteTeam = teamIdToDelete => () => {
  return ApiService.del('/teams/' + teamIdToDelete)
    .then(() => {
      return { success: true };
    })
    .catch(error => {
      ErrorService.capture('Error deleting team', error);
      return {};
    });
};

export const fetchTeams = config => dispatch => {
  return ApiService.get('/me/teams?with=repoCounts', config)
    .then(
      res => {
        dispatch(updateTeams(res));
        return res;
      },
      () => {
        dispatch(updateTeams([]));
      }
    )
    .catch(error => {
      ErrorService.capture('Error fetching teams', error);
    });
};

export const showModal = (modalType: string) => ({
  type: SHOW_TEAM_MODAL,
  modalType,
});

export const updateWorkspaceName = name => ({
  type: UPDATE_WORKSPACE_NAME,
  name,
});
