import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as groupActions from '~/actions/group';
import * as teamGroupsActions from '~/actions/teamGroups';
import * as orgTeamActions from '~/actions/orgTeam';
import LoaderWrapper from '~/components/LoaderWrapper';
import LinkWorkspaceToTeams from '~/components/workspaces/teams/LinkWorkspaceToTeams';

import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import { GroupState, TeamGroupState } from '~/types/Groups';

interface StateProps {
  groupState: GroupState;
  teamGroupsState: TeamGroupState;
  teamState: object;
  vcPageState: VCPageState;
}

interface DispatchProps {
  groupActions: {
    fetchGroups: () => void;
    groupMultiUpdate: (data: any) => { id: number };
    resetSelectedGroups: () => void;
    toggleGroupAdminField: (id: number) => void;
    updateSelectedGroups: (id: number) => void;
  };
  orgTeamActions: {
    fetchOrgTeams: () => any[];
    showModal: (type: string) => void;
  };
  teamGroupsActions: {
    fetchTeamGroups(number);
  };
}

interface LinkWorkspaceToTeamsModalProps {
  activeTeam: { id: number };
  handleLinkSuccess: (...args: any[]) => any;
  showModal?: (...args: any[]) => any;
}

type Props = StateProps & DispatchProps & LinkWorkspaceToTeamsModalProps;

class LinkWorkspaceToTeamsModal extends React.Component<Props, {}> {
  componentDidMount() {
    const { activeTeam } = this.props;
    this.props.teamGroupsActions.fetchTeamGroups(activeTeam.id);
    this.props.groupActions.fetchGroups();
  }

  updateSelectedGroups(groupId) {
    this.props.groupActions.updateSelectedGroups(groupId);
  }

  toggleAdminField(groupId) {
    this.props.groupActions.toggleGroupAdminField(groupId);
  }

  showModal(modalType?) {
    if (this.props.showModal) {
      this.props.showModal(modalType);
    } else {
      this.props.orgTeamActions.showModal(modalType);
    }
  }

  async linkWorkspaceToTeams() {
    const { groupState, activeTeam } = this.props;
    const { selectedGroups, groups } = groupState;
    const groupsToLink = groups.filter(group => selectedGroups[group.id]) || [];
    const groupData = groupsToLink.map(group => {
      return {
        groupId: group.id,
        teams: [
          {
            teamId: activeTeam.id,
            role: group.stagedAdminStatus ? 'WORKSPACE_ADMIN' : 'USER',
          },
        ],
      };
    });
    const { id } = await this.props.groupActions.groupMultiUpdate(groupData);

    if (id) {
      this.props.groupActions.resetSelectedGroups();
      this.showModal();
      const result = await this.props.orgTeamActions.fetchOrgTeams();
      if (result.length) {
        this.props.handleLinkSuccess();
      }
    }
  }

  render() {
    const {
      groupState,
      teamGroupsState,
      vcPageState: { shouldShowVeracodePage },
    } = this.props;
    const { groups = [], selectedGroups: selectedGroupIds = {}, isUpdatingGroups } = groupState;
    const { teamGroups = [] } = teamGroupsState;
    const workspaceGroupIds = {};
    teamGroups.forEach(groupObj => (workspaceGroupIds[groupObj.group.id] = true));
    const selectableGroups = [];
    const selectedGroups = [];
    const filteredGroups = groups.filter(group => !workspaceGroupIds[group.id]);

    filteredGroups.forEach(group => {
      if (selectedGroupIds[group.id]) {
        selectedGroups.push(group);
      } else {
        selectableGroups.push({
          label: group.name,
          value: group.id,
        });
      }
    });

    const buttons = (
      <div className="grid__item col-1-1 flex justify-content--end mt+">
        <button className="col-1-5 pv- mr-" onClick={() => this.showModal()}>
          Cancel
        </button>
        <button
          className="col-1-5 pv- btn--success"
          disabled={!selectedGroups.length}
          onClick={() => this.linkWorkspaceToTeams()}
        >
          <LoaderWrapper isLoaderShowing={isUpdatingGroups} loaderType="SPINNER">
            Save
          </LoaderWrapper>
        </button>
      </div>
    );

    return (
      <div>
        <LinkWorkspaceToTeams
          selectableGroups={selectableGroups}
          selectedGroups={selectedGroups}
          buttons={buttons}
          updateSelectedGroups={groupId => this.updateSelectedGroups(groupId)}
          toggleAdminField={groupId => this.toggleAdminField(groupId)}
          shouldShowVeracodePage={shouldShowVeracodePage}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupState: state.groupState,
    teamGroupsState: state.teamGroupsState,
    teamState: state.teamState,
    vcPageState: state.vcPageState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions as any, dispatch),
    orgTeamActions: bindActionCreators(orgTeamActions as any, dispatch),
    teamGroupsActions: bindActionCreators(teamGroupsActions as any, dispatch),
  };
}

export default connect<StateProps, DispatchProps, LinkWorkspaceToTeamsModalProps>(
  mapStateToProps,
  mapDispatchToProps
)(LinkWorkspaceToTeamsModal);
