import React from 'react';

interface DropdownListProps {
  items: any[];
  value: string;
  isOpen: boolean;
  automationId?: string;
  onToggle: (...args: any[]) => any;
  onSelect: (...args: any[]) => any;
}

const DropdownList: React.FunctionComponent<DropdownListProps> = props => {
  const { isOpen, items, value, automationId } = props;
  let dropdownItems = [];

  function toggleDropdown() {
    props.onToggle();
  }

  function onItemSelect(item, index) {
    props.onSelect(item, index);
    props.onToggle();
  }

  function getLanguagePrettyName(rawVal) {
    switch (rawVal.toUpperCase()) {
      case 'OBJECTIVEC':
        return 'Objective-C';
      case 'CSHARP':
        return 'C#';
      case 'CPP':
        return 'C/C++';
      default:
        return rawVal;
    }
  }

  items.forEach((item, index) => {
    const displayText = getLanguagePrettyName(item);

    dropdownItems.push(
      <li
        key={index}
        onClick={() => onItemSelect(item, index)}
        className="list--group__item grid grid--full p0 col-1-1"
      >
        <div className="p-">{displayText}</div>
      </li>
    );
  });

  const displayVal = getLanguagePrettyName(value);

  return (
    <div className="position--relative col-1-1" data-automation-id={automationId}>
      <div className="flex cursor--pointer" onClick={toggleDropdown}>
        <input
          type="text"
          className={
            'flex-grow--1 focus--border-color--muted bo--1 bo-r--0 border-color--muted cursor--pointer placeholder--black-dark'
          }
          placeholder={displayVal}
          readOnly
        />
        <div className="bo--1 bo-l--0 border-color--muted flex flex--ai--fe pb- pt- pr">
          <i className="sci sci__caret--down" />
        </div>
      </div>
      <ul
        className={
          'list--group overflow-y--scroll border-color--dropdown right--0 col-1-1 b--1 position--absolute zIndex-9--overlay cursor--pointer ' +
          (isOpen ? ' pb--' : ' is-hiding')
        }
      >
        <div>{dropdownItems}</div>
      </ul>
    </div>
  );
};

export default DropdownList;
