import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import OrgPaidStatus from '~/utils/OrgPaidStatus';

import * as toastrActions from '~/actions/toastr';

interface TrialNotificationsProps {
  toastrActions: object;
  orgState: App.OrgState;
  myState: App.MyState;
}
class TrialNotifications extends React.Component<TrialNotificationsProps, {}> {
  componentDidMount() {
    const { orgState } = this.props;
    const { org } = orgState;
    const { welcomeMode, id } = org;

    if (id && welcomeMode === false) {
      this.checkForTrialMessaging(org);
    }
  }
  // check for trial message when the org changes
  componentDidUpdate(prevProps) {
    const { org: nextOrg } = this.props.orgState;
    const { org: prevOrg } = prevProps.orgState;
    if (nextOrg.id && prevOrg.id !== nextOrg.id && nextOrg.welcomeMode === false) {
      this.checkForTrialMessaging(nextOrg);
    }
  }

  checkForTrialMessaging(org: App.Org) {
    const { license } = org;
    const { planType } = license;
    const trialEndDate = license.trialEndDate && moment(license.trialEndDate);
    const todayDate = moment();

    if (!org.id) {
      return;
    }

    if (planType === 'ENTERPRISE' || (!trialEndDate && planType === 'PRO')) {
      // these are just normal paid plans
      return;
    }

    if (trialEndDate) {
      if (todayDate.isAfter(trialEndDate, 'day')) {
        this.launchTrialExpiredToastr(org);
      } else {
        const trialDaysLeft = OrgPaidStatus.calculateDaysLeft(trialEndDate);
        this.launchCurrentTrialStatusToastr(org, trialDaysLeft);
      }
    }
  }

  launchCurrentTrialStatusToastr(org: App.Org, daysLeft) {
    const { permissions } = org;
    const { billing } = permissions;
    let message;
    const options = {
      id: 'current-trial',
      level: 'success',
      title: `SourceClear trial: ${daysLeft} days remaining`,
      disableTimeout: true,
    };

    if (billing) {
      message = (
        <div>
          <Link
            to="/org/settings/subscribe"
            className="link--obvious"
            onClick={() => this.removeToastr(options)}
          >
            Upgrade
          </Link>{' '}
          to get the features that matter most to you.
        </div>
      );
    } else {
      message = (
        <div>
          To keep premium features, ask your administrator to upgrade. You can continue to use
          SourceClear on the Personal plan when the trial ends.
        </div>
      );
    }

    options.message = message;

    this.props.toastrActions.addToastr(options);
  }

  launchTrialExpiredToastr(org) {
    const { permissions = {} } = org;
    const { billing } = permissions;
    const { name } = org;
    const options = {
      id: 'trial-expired',
      level: 'error',
      title: 'Trial has expired',
      disableTimeout: true,
    };
    let message;

    if (billing) {
      message = (
        <div>
          The trial for the organization <strong>{name}</strong> has expired.{' '}
          <Link
            className="link--obvious"
            to="/org/settings/subscribe"
            onClick={() => this.removeToastr(options)}
          >
            Upgrade now
          </Link>{' '}
          to access premium features.
        </div>
      );
    } else {
      message = (
        <div>
          The trial for the organization <strong>{name}</strong> has expired. To access premium
          features, ask your administrator to upgrade.
        </div>
      );
    }

    options.message = message;

    this.props.toastrActions.addToastr(options);
  }

  removeToastr(options) {
    this.props.toastrActions.removeToastr(options);
  }

  render() {
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    orgState: state.orgState,
    myState: state.myState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toastrActions: bindActionCreators(toastrActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialNotifications);
