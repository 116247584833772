import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';
import CopyToClipboard from '~/containers/CopyToClipboard';
interface PackageManagerNPMTransitiveDependencyFixInfoProps {
  name: string;
  safeVersion: string;
  unsafeVersion: string;
}
function PackageManagerNPMTransitiveDependencyFixInfo(
  props: PackageManagerNPMTransitiveDependencyFixInfoProps
): JSX.Element {
  const { name, safeVersion, unsafeVersion } = props;
  const resolvedUrl = `https://registry.npmjs.org/${name}/-/${name}-${safeVersion}.tgz`;
  return (
    <div className="grid mt- pt--- bg-color--white pl-">
      <div>
        Fixing a vulnerability in a transitive library for NPM can be more tricky than fixing a
        direct vulnerability because NPM projects allow for multiple versions of the same library
        which means you cannot override a vulnerable library by adding the appropriate version
        directly to the configuration file.
      </div>
      <div className="packageManagerNPMFixInfoListInstructions pt pb">
        After running a Veracode SCA agent-based scan or running a <code>npm install</code> to install
        dependencies, which is indicated by the existence of a <code>node_modules</code> folder and
        <code>package-lock.json</code> file:
        <ol className="pt">
          <li>
            Add this <code>resolutions</code> section to your <code>package.json</code> file:
            <div className="bo--1 border-color--white-dark mt bg-color--white lh+ overflow-x--auto">
              <CodeDiffHeader text="package.json" />
              <CodeDiffLine type="normal" text='"resolutions": {' />
              <CodeDiffLine type="normal" text={'\u2003\u2003\u2003' + `"${name}": "${safeVersion}"`} />
              <CodeDiffLine type="normal" text={`}`} />
            </div>
            <br/>
            If a <code>resolutions</code> section already exists, add
            <code>"{name}": "{safeVersion}"</code> to it.
          </li>
          <li>
            Add this <code>scripts</code> section to your <code>package.json</code> file:
            <div className="bo--1 border-color--white-dark mt bg-color--white lh+ overflow-x--auto">
              <CodeDiffHeader text="package.json" />
              <CodeDiffLine type="normal" text='"scripts": {' />
              <CodeDiffLine type="normal" text={'\u2003\u2003\u2003' + `"preinstall": "npx npm-force-resolutions"`} />
              <CodeDiffLine type="normal" text={`}`} />
            </div>
            <br/>
            If a <code>scripts</code> section already exists, add <code>"preinstall": "npx npm-force-resolutions"</code>
            this change makes the <code>npm install</code> command force the version resolution of the
            <code>{name}</code> library to {safeVersion} according to the <code>resolutions</code> section using the
            {' '}
            <a href="https://www.npmjs.com/package/npm-force-resolutions" className="link--obvious" target="_blank">
              NPM Force Resolutions
            </a>
            {' '}
            package.
          </li>
          <li>
            Delete the <code>node_modules</code> folder.
          </li>
          <li>
            Run the <code>npm install</code> command to download the updated dependency and ensure the updated
            version works with your project.
          </li>
        </ol>
        If you encounter problems using the NPM Force Resolutions package as in the above example, you can use this
        alternative method that
        {' '}
        <a href="https://github.com/npm/npm/pull/13214" className="link--obvious" target="_blank">
          requires NPM version 3.10.4 or later
        </a>
        . After running a Veracode SCA agent-based scan or running <code>npm install</code> to install dependencies,
        which is indicated by the <code>node_modules</code> folder:
        <ol className="pt">
          <li>
            Run the <code>npm shrinkwrap</code> command in the same directory as your <code>package.json</code> file.
            This generates a <code>npm-shrinkwrap.json</code> file with all of the dependencies currently in use.
          </li>
          <li>
            Find the <code>{name}</code> library with the version specified in the issue details viewed previously.
            In this example, version {unsafeVersion} is vulnerable and the recommended version to update is
            {safeVersion}.
          </li>
          <li>
            Edit the <code>npm-shrinkwrap.json</code> file to update the <code>{name}</code> library:
            <div className="bo--1 border-color--white-dark mt bg-color--white lh+ overflow-x--auto">
              <CodeDiffHeader text="npm-shrinkwrap.json" />
              <CodeDiffLine type="normal" text='"dependencies": {' />
              <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
              <CodeDiffLine type="normal" text={'\u2003\u2003\u2003' + `"${name}": {`} />
              <CodeDiffLine
                type="normal"
                text={'\u2003\u2003\u2003\u2003\u2003\u2003' + `"version": "${safeVersion}",`}
              />
              <CodeDiffLine
                type="normal"
                text={'\u2003\u2003\u2003\u2003\u2003\u2003' + `"resolved": "${resolvedUrl}"`}
              />
              <CodeDiffLine type="normal" text={'\u2003\u2003\u2003' + `}`} />
              <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
              <CodeDiffLine type="normal" text={`}`} />
            </div>
          </li>
          <li>
            Delete the <code>node_modules</code> folder, then run the <code>npm install</code> command to
            download the updated dependency and ensure the updated version works with your project.
          </li>
        </ol>
      </div>
    </div>
  );
}
export default PackageManagerNPMTransitiveDependencyFixInfo;
