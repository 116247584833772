import * as actions from '~/actions/agentScanHistory';

const defaultState = {
  scansData: {},
  isFetching: true,
};

const agentScanHistoryState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_AGENT_SCANS:
      return {
        ...state,
        isFetching: false,
        scansData: action.scansData,
      };
    case actions.UPDATE_IS_FETCHING_AGENT_SCANS:
      return {
        ...state,
        isFetching: action.bool,
      };
    default:
      return state;
  }
};

export default agentScanHistoryState;
