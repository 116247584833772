const NavigationService = function () {
  const getPreviousLocationPathname = navigationState => {
    const { prevState = {} } = navigationState;
    const { location = {} } = prevState;
    const { pathname = '' } = location;
    return pathname;
  };

  return {
    getPreviousLocationPathname,
  };
};

export default new NavigationService();
