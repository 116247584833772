import React from 'react';
import { planOptions } from '~/constants/ModelConstants';
import Tooltip from '~/components/Tooltip';

interface PlanDetailsListProps {
  tier: string;
}

const PlanDetailsList: React.FunctionComponent<PlanDetailsListProps> = props => {
  const { tier } = props;
  const filteredPlanDetails = planOptions.filter(detail => {
    if (detail.hideFromPlanDetails || detail.title === 'Vulnerable library analysis') {
      return false;
    }

    return detail[tier];
  });

  return (
    <div className="color--muted-dark columns features">
      {filteredPlanDetails.map((detail, index) => {
        const { title, tooltip, type } = detail;

        return (
          <div key={`detail-${tier}-${index}`}>
            {tooltip ? (
              <Tooltip
                id={`plan-details-tooltip-${index}`}
                content={tooltip}
                maxWidthClass="max-width--300"
                place="top"
              >
                <div className="flex mt- align-items--center justify-content--center">
                  <div className="font--h8">
                    {type === 'BOOLEAN' ? title : `${title}: ${detail[tier]}`}
                  </div>
                  <i className="fas fa-info-circle color--primary pl--" />
                </div>
              </Tooltip>
            ) : (
              <div className="pt- font--h8">
                {type === 'BOOLEAN' ? title : `${title}: ${detail[tier]}`}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PlanDetailsList;
