import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from '~/reducers';
import AutoLogout from '~/utils/middlewares/AutoLogout';
import createHistory from 'history/createBrowserHistory';

export const history = createHistory();

const isQa = window.location.hostname.indexOf('ops2.srcclr.io') >= 0;

export default function configureStore(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [thunk, AutoLogout, reactRouterMiddleware];

  if (isQa) {
    middlewares.push(createLogger({ collapsed: true, timestamp: false }));
  }

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
