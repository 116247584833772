import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Helpers from '~/utils/Helpers';

import StripeForm from '~/containers/StripeForm';

import * as billingActions from '~/actions/billing';
import * as paymentActions from '~/actions/payment';

interface PaymentFormProps {
  billingActions: object;
  paymentActions: object;
  billingState: object;
  orgState: App.OrgState;
  paymentState: object;
  trialExpired?: boolean;
  requestPurchaseOrderSuccess?: (...args: any[]) => any;
}

class PaymentForm extends Component<PaymentFormProps, {}> {
  componentDidMount() {
    const { orgState } = this.props;
    const { org = {} } = orgState;

    this.props.billingActions.fetchBillingDashboard(org.id);
    this.props.billingActions.fetchPlanLevels();
  }

  componentDidUpdate(prevProps) {
    const { billingState } = prevProps;
    const { selectedPlan = {}, planLevels = [] } = billingState;
    const defaultPlan = planLevels[0] || {};

    if (!selectedPlan.id && defaultPlan.id) {
      this.updateSelectedPlan(defaultPlan);
    }
  }

  closePurchaseOrderModal() {
    this.props.paymentActions.showPurchaseOrderSuccessModal(false);
  }

  togglePurchaseOrderOption(bool) {
    const { paymentState } = this.props;
    const { payingWithPurchaseOrder } = paymentState;

    if (payingWithPurchaseOrder !== bool) {
      this.props.paymentActions.togglePurchaseOrderOption();
    }
  }

  updateSelectedPlan(plan) {
    this.props.billingActions.updateSelectedPlan(plan);
  }

  render() {
    const { billingState } = this.props;
    const { planLevels = [] } = billingState;
    const proPlan = planLevels[0] || {};

    return (
      <div>
        <div className="">
          <div className="font--h6">Plan details</div>
          <div className="mt--">
            Business Plan:{' '}
            <strong className="pr--">{Helpers.formatCost(proPlan.priceInCents)} / Month</strong>
          </div>
        </div>
        <div>
          <div className="mt font--h6">Enter payment details</div>
          <div className="mt-">
            <StripeForm />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    billingState: state.billingState,
    orgState: state.orgState,
    paymentState: state.paymentState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    billingActions: bindActionCreators(billingActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
