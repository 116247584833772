import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Helpers from '~/utils/Helpers';

import * as groupActions from '~/actions/group';
import * as orgUserActions from '~/actions/orgUser';

import AddUserToGroups from '~/components/AddUserToGroups';

interface AddUserToGroupsModalProps {
  groupActions: object;
  orgUserActions: object;
  groupState: object;
  orgUserState: object;
  showModal: (...args: any[]) => any;
}
class AddUserToGroupsModal extends React.Component<AddUserToGroupsModalProps, {}> {
  componentDidMount() {
    this.props.orgUserActions.resetSelectedUsers();
  }

  updateSelectedUsers(userId) {
    this.props.orgUserActions.updateSelectedUsers(userId);
  }

  toggleAdminField(groupId) {
    this.props.groupActions.toggleGroupAdminField(groupId);
  }

  addUserToGroups() {
    const { orgUserState, groupState } = this.props;
    const { selectedOrgUsers = [] } = orgUserState;
    const { selectedGroups = {}, groups = [] } = groupState;
    const groupsToAddUser = groups.filter(group => selectedGroups[group.id]) || [];
    const groupUserData = groupsToAddUser.map(group => {
      return {
        groupId: group.id,
        users: selectedOrgUsers.map(user => {
          return {
            ...user,
            admin: group.stagedAdminStatus || false,
          };
        }),
      };
    });

    this.props.groupActions.groupMultiUpdate(groupUserData);
  }

  showModal(modalType) {
    this.props.showModal(modalType);
  }

  render() {
    const { orgUserState, groupState } = this.props;
    const { orgUsers = [], selectedOrgUsers = [] } = orgUserState;
    const { selectedGroups = {}, groups = [] } = groupState;

    const selectableUsers = orgUsers.map(userObj => {
      const { user = {} } = userObj;
      let formattedUserName = Helpers.formatUserName(user);
      return {
        label: formattedUserName,
        value: user.id,
      };
    });

    const selectedUser =
      selectedOrgUsers[0] &&
      selectableUsers.find(user => user.value === selectedOrgUsers[0].userId);
    const groupsToAddUser = groups.filter(group => selectedGroups[group.id]) || [];

    const buttons = (
      <div className="grid__item col-1-1 flex justify-content--end mt+">
        <button className="col-1-5 pv- mr-" onClick={() => this.showModal()}>
          Cancel
        </button>
        <button
          className="col-1-5 pv- btn--success"
          disabled={!selectedUser}
          onClick={() => this.addUserToGroups()}
        >
          Save
        </button>
      </div>
    );

    return (
      <div className="">
        <AddUserToGroups
          selectableUsers={selectableUsers}
          selectedUser={selectedUser}
          selectedGroups={groupsToAddUser}
          buttons={buttons}
          updateSelectedUsers={userId => this.updateSelectedUsers(userId)}
          toggleAdminField={groupId => this.toggleAdminField(groupId)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgUserState: state.orgUserState,
    groupState: state.groupState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserToGroupsModal);
