import * as actions from '~/actions/expandableSection';

const defaultState = {};

const expandableSectionState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.TOGGLE_SECTION:
      return {
        ...state,
        [action.id]: action.toggleState,
      };
    default:
      return state;
  }
};

export default expandableSectionState;
