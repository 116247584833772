import ApiService from '~/utils/ApiService';
import AuthService from '~/utils/AuthService';
import ErrorService from '~/utils/ErrorService';

import * as teamActions from '~/actions/team';
import * as meActions from '~/actions/me';
import * as orgActions from '~/actions/org';
import * as MODEL from '~/constants/ModelConstants';

export const ENABLING_TWO_FACTOR = 'ENABLING_TWO_FACTOR';
export const REQUEST_VERIFY_TOKEN = 'REQUEST_VERIFY_TOKEN';
export const RECEIVE_VERIFY_TOKEN = 'RECEIVE_VERIFY_TOKEN';
export const SENDING_TWO_FACTOR_CODE = 'SENDING_TWO_FACTOR_CODE';
export const UPDATE_TWO_FACTOR_STATUS = 'UPDATE_TWO_FACTOR_STATUS';
export const UPDATE_TWO_FACTOR = 'UPDATE_TWO_FACTOR';
export const UPDATE_PHONE_DROPDOWN_VAL = 'UPDATE_PHONE_DROPDOWN_VAL';
export const UPDATE_IS_TOKEN_INVALID = 'UPDATE_IS_TOKEN_INVALID';
export const UPDATE_SENT_SMS = 'UPDATE_SENT_SMS';
export const UPDATE_IS_SMSING = 'UPDATE_IS_SMSING';
export const UPDATE_REMEMBER_COMPUTER = 'UPDATE_REMEMBER_COMPUTER';
export const UPDATE_SMS_ERROR = 'UPDATE_SMS_ERROR';
export const VERIFYING_TWO_FACTOR = 'VERIFYING_TWO_FACTOR';

export const updateRememberComputer = bool => ({
  type: UPDATE_REMEMBER_COMPUTER,
  bool,
});

export const requestVerifyToken = () => ({
  type: REQUEST_VERIFY_TOKEN,
});

export const receiveVerifyToken = () => ({
  type: RECEIVE_VERIFY_TOKEN,
});

export const updateIsTokenInvalid = bool => ({
  type: UPDATE_IS_TOKEN_INVALID,
  bool,
});

export const updateIsSMSing = bool => ({
  type: UPDATE_IS_SMSING,
  bool,
});

export const updateSentSMS = bool => ({
  type: UPDATE_SENT_SMS,
  bool,
});

export const updateSMSError = bool => ({
  type: UPDATE_SMS_ERROR,
  bool,
});

export const sendSMS = () => (dispatch, getState) => {
  dispatch(updateIsSMSing(true));
  if (getState().twoFactorState.smsError) {
    dispatch(updateSMSError(false));
  }
  const sessionToken = AuthService.getAuthToken();
  const config = { headers: { 'x-auth-token': sessionToken } };
  ApiService.post('/twofactor?action=sms', config).then(
    () => {
      dispatch(updateIsSMSing(false));
      dispatch(updateSentSMS(true));
    },
    () => {
      // failure
      dispatch(updateIsSMSing(false));
      dispatch(updateSMSError(true));
    }
  );
};

export const loadInitialData = () => {
  return Promise.all([ApiService.get('/me'), ApiService.get('/me/teams?with=repoCounts')]);
};

export const verifyTwoFactorToken = (token, history) => (dispatch, getState) => {
  // you typed in the token and submitted; time to check it
  dispatch(requestVerifyToken());
  const { twoFactorState } = getState();
  const { rememberComputer } = twoFactorState;
  const redirectUrl = localStorage && localStorage.getItem(MODEL.REDIRECT_URL);
  const sessionToken = AuthService.getAuthToken();
  let config = {
    headers: { 'x-auth-token': sessionToken },
    data: { token, rememberComputer },
  };

  return ApiService.post('/twofactor', config).then(data => {
    dispatch(receiveVerifyToken());
    if (data && data.status === 'INVALID_TOKEN') {
      // wrong token. display error and try again
      dispatch(updateIsTokenInvalid(true));
    } else if (data && data.status === 'TOKEN_ACCEPTED') {
      // right token. move forward with logging in
      AuthService.setSessionToken(null);

      // if you checked for us to remember the computer, set the cookie
      AuthService.setTwoFactor(data.rememberToken || null);
      const authToken = sessionToken;
      AuthService.setAuthToken(authToken);
      loadInitialData().then(data => {
        const [me, teams] = data;
        dispatch(meActions.updateMe(me));
        dispatch(teamActions.updateTeams(teams));

        if (me.organization) {
          dispatch(orgActions.updateOrg(me.organization));
        }

        // check to see if the user has accepted the MSA.
        // if not, direct them to /master to accept the msa
        if (!me.eula.acceptedVersion || me.eula.acceptedVersion !== me.eula.currentVersion) {
          history.push('/msa');
        }

        if (redirectUrl) {
          localStorage.removeItem(MODEL.REDIRECT_URL);
          history.push(redirectUrl);
          return;
        }

        history.push('/portfolio');
      });
    }
  });
};

export const fetchTwoFactorStatus = () => dispatch => {
  ApiService.get('/user/twofactor')
    .then(
      res => {
        dispatch(updateTwoFactor(res));
      },
      error => {
        ErrorService.capture('Error fetching two factor status', error);
      }
    )
    .catch(error => {
      ErrorService.capture('Error fetching two factor status', error);
    });
};

export const updateTwoFactor = res => ({
  type: UPDATE_TWO_FACTOR,
  res,
});

export const twoFactorEnroll = (phoneCode, phoneNumber) => dispatch => {
  dispatch(enablingTwoFactor(true));

  const utf = {
    countryCode: phoneCode,
    phoneNumber: phoneNumber.replace(/[^0-9]/g, ''),
  };

  ApiService.post('/user/twofactor', { data: utf }).then(res => {
    dispatch(updateTwoFactor(res));
    dispatch(enablingTwoFactor(false));
  });
};

export const enablingTwoFactor = bool => ({
  type: ENABLING_TWO_FACTOR,
  bool,
});

export const twoFactorDelete = () => dispatch => {
  ApiService.del('/user/twofactor').then(res => {
    dispatch(updateTwoFactor(res));
    dispatch(meActions.updateMeTwoFactorAuth(false));
  });
};

export const sendTwoFactorSMS = () => dispatch => {
  dispatch(sendingTwoFactorCode(true));
  ApiService.post('/user/twofactor?action=sms').then(() => {
    dispatch(sendingTwoFactorCode(false));
  });
};

export const sendingTwoFactorCode = bool => ({
  type: SENDING_TWO_FACTOR_CODE,
  bool,
});

export const verifyTwoFactor = token => dispatch => {
  dispatch(verifyingTwoFactor(true));

  const payload = {
    token: token,
  };

  ApiService.post('/user/twofactor?action=verify', { data: payload }).then(res => {
    dispatch(verifyingTwoFactor(false));
    dispatch(updateTwoFactorStatus(res));

    if (res && res.status && res.status === 'TOKEN_ACCEPTED') {
      dispatch(meActions.updateMeTwoFactorAuth(true));
    }
  });
};

export const updateTwoFactorStatus = (res = {}) => ({
  type: UPDATE_TWO_FACTOR_STATUS,
  status: res.status,
});

export const verifyingTwoFactor = bool => ({
  type: VERIFYING_TWO_FACTOR,
  bool,
});

export const updatePhoneDropdownVal = val => ({
  type: UPDATE_PHONE_DROPDOWN_VAL,
  val,
});
