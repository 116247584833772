import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FEATURE_SLUG_MAP } from '~/constants/ModelConstants';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import SidebarNavOption from '~/components/SidebarNavOption';
import LoaderWrapper from '~/components/LoaderWrapper';
import Helpers from '~/utils/Helpers';
import OrgPaidStatus from '~/utils/OrgPaidStatus';

interface OrgNavOptionsProps extends RouteComponentProps {
  billingState: object;
  orgState: App.OrgState;
  myState: App.MyState;
}

const OrgNavOptions: React.FunctionComponent<OrgNavOptionsProps> = props => {
  const {location, orgState, billingState, myState} = props;

  const {org} = orgState;
  const {permissions} = org;
  const {
    listGroups,
    listTeams,
    listUsers,
    integrations,
    agents,
    billing,
    update,
    usage: orgUsage,
  } = permissions;
  const {invoices = []} = billingState;
  const {license, purchaseOrder} = org;
  const {planType, status} = license;
  const isOrgTrial = status === 'TRIAL';
  const {pathname = ''} = location;
  const isOrgPro = planType === 'PRO';
  const isOrgHobby = planType === 'OPEN';
  const {license: orgLicense} = org;
  const plan = org.plan || {};
  const {usage} = orgLicense;
  const {users} = usage;
  const {teamLimit, userLimit} = plan;
  const showGroups = FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org);
  const showAgents = FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.ORG_AGENTS, org);
  const showLibraryCatalog = Helpers.hasLibraryCatalogEnabled();

  const isOrgEnterpriseOrTrial = OrgPaidStatus.isOrgEnterpriseOrTrial(org);

  // arrays of options, separated with `mt-` class
  const settingsAndAgents = [];
  const subscriptionAndPayment = [];
  const userManagement = [];
  const integrationsAndExtras = [];

  // initialize nav options with profile

  if (Helpers.hasEnforceOrgLevelRulesPermissions(myState)) {
    integrationsAndExtras.push({
      title: 'Rules',
      toLocation: '/org/settings/rules',
      isActive: pathname.includes('/rules'),
    });
  }

  if (integrations) {
    integrationsAndExtras.push({
      title: 'Integrations',
      toLocation: `/org/settings/integrations`,
      isActive: pathname.includes(`/integrations`),
    });
  }

  if (showLibraryCatalog) {
    integrationsAndExtras.push({
      title: 'Commercial Licenses',
      toLocation: `/org/settings/library-catalog`,
      isActive: pathname.includes(`/library-catalog`),
    });
  }

  if (update) {
    settingsAndAgents.push({
      title: 'Settings',
      toLocation: `/org/settings`,
      isActive: pathname.endsWith(`settings`),
    });
  }

  if (agents && (isOrgEnterpriseOrTrial || showAgents)) {
    settingsAndAgents.push({
      title: 'Agents',
      toLocation: `/org/settings/agents`,
      isActive: pathname.includes(`/agents`),
    });
  }

  if (billing) {
    if (isOrgPro && !isOrgTrial) {
      subscriptionAndPayment.push({
        title: 'Subscription',
        toLocation: `/org/settings/subscription`,
        isActive: pathname.includes(`/subscription`),
      });
    } else if (isOrgHobby || isOrgTrial) {
      subscriptionAndPayment.push({
        title: 'Subscribe',
        toLocation: `/org/settings/subscribe`,
        isActive: pathname.includes(`/subscribe`),
      });
    }

    if ((isOrgPro && !isOrgTrial && !purchaseOrder) || (isOrgHobby && invoices.length)) {
      subscriptionAndPayment.push({
        title: 'Billing and Payment',
        toLocation: `/org/settings/billing`,
        isActive: pathname.includes(`/billing`),
      });
    }
  }

  if (orgUsage) {
    subscriptionAndPayment.push({
      title: 'Usage',
      toLocation: `/org/settings/usage`,
      isActive: pathname.includes(`/usage`),
    });
  }

  // Personal account: not display Users
  if (!isOrgHobby && listUsers && (userLimit !== 1 || users > 1)) {
    userManagement.push({
      title: 'Users',
      toLocation: `/org/settings/users`,
      isActive: pathname.includes(`/users`),
    });
  }

  // Personal or Pro/Business: not display Users
  if (!isOrgHobby && !isOrgPro && (listGroups || showGroups)) {
    userManagement.push({
      title: 'Teams',
      toLocation: `/org/settings/teams`,
      isActive: pathname.includes(`/teams`),
    });
  }

  if (listTeams && teamLimit !== 1) {
    userManagement.push({
      title: 'Workspaces',
      toLocation: `/org/settings/workspaces`,
      isActive: pathname.includes(`/workspaces`),
    });
  }

  return (
    <LoaderWrapper
      customLoader={
        <div className="mt pl0 pb-">
          <i className={`fas fa-spin fa-spinner color--muted-dark font--h4`} />
        </div>
      }
    >
      <div className="flex flex--flex-direction--column">
        <div className="mt pl- pb- font--h6 pr-">Manage Organization</div>
        <div>
          {settingsAndAgents.map(option => (
            <SidebarNavOption
              key={option.title}
              option={option}
              fontSize="font--h7"
              verticalPaddingClass="pv--"
            />
          ))}
          <div className="col-1-1 mt-" />
          {subscriptionAndPayment.map(option => (
            <SidebarNavOption
              key={option.title}
              option={option}
              fontSize="font--h7"
              verticalPaddingClass="pv--"
            />
          ))}
          <div className="col-1-1 mt-" />
          {userManagement.map(option => (
            <SidebarNavOption
              key={option.title}
              option={option}
              fontSize="font--h7"
              verticalPaddingClass="pv--"
            />
          ))}
          <div className="col-1-1 mt-" />
          {integrationsAndExtras.map(option => (
            <SidebarNavOption
              key={option.title}
              option={option}
              fontSize="font--h7"
              verticalPaddingClass="pv--"
            />
          ))}
        </div>
      </div>
    </LoaderWrapper>
  );
};

function mapStateToProps(state) {
  return {
    billingState: state.billingState,
    orgState: state.orgState,
    myState: state.myState,
  };
}

export default connect(mapStateToProps)(OrgNavOptions);
