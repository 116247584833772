import { combineReducers } from 'redux';
import * as actions from '~/actions/componentHeadSafe';

const componentHeadSafeState = () => {
  const data = (state = {}, action) => {
    switch (action.type) {
      case actions.CHECK_COMPONENT_HEAD_SAFE_REQUEST:
      case actions.CHECK_COMPONENT_HEAD_SAFE_FAILURE:
        return {
          ...state,
          [action.componentId]: {},
        };
      case actions.CHECK_COMPONENT_HEAD_SAFE_SUCCESS:
        return {
          ...state,
          [action.componentId]: action.res,
        };
      default:
        return state;
    }
  };

  const isFetching = (state = {}, action) => {
    switch (action.type) {
      case actions.CHECK_COMPONENT_HEAD_SAFE_REQUEST:
        return {
          ...state,
          [action.componentId]: true,
        };
      case actions.CHECK_COMPONENT_HEAD_SAFE_FAILURE:
      case actions.CHECK_COMPONENT_HEAD_SAFE_SUCCESS:
        return {
          ...state,
          [action.componentId]: false,
        };
      default:
        return state;
    }
  };

  const errorMessage = (state = {}, action) => {
    switch (action.type) {
      case actions.CHECK_COMPONENT_HEAD_SAFE_REQUEST:
      case actions.CHECK_COMPONENT_HEAD_SAFE_SUCCESS:
        return {
          ...state,
          [action.componentId]: null,
        };
      case actions.CHECK_COMPONENT_HEAD_SAFE_FAILURE:
        return {
          ...state,
          [action.componentId]: action.message,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    data,
    isFetching,
    errorMessage,
  });
};

export default componentHeadSafeState();
