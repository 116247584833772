import React from 'react';
import { Link } from 'react-router-dom';
import Helpers from '~/utils/Helpers';
import { VC_USER_ROLES } from '~/constants/ModelConstants';
import LoaderWrapper from '~/components/LoaderWrapper';
import WorkspaceUserGroupsQuickView from '~/components/WorkspaceUserGroupsQuickView';
import UserManagementQuickViewWrapper from '~/containers/UserManagementQuickViewWrapper';

interface TeamUsersListProps {
  teamId: string;
  updateUsers?: boolean;
  manageUsers?: boolean;
  showDirectMembership: boolean;
  isWorkspaceAdmin?: boolean;
  isFetchingTeamUsers?: boolean;
  users: any[];
  quickViewsByUserId: object;
  filteredUsers: any[];
  selectedUsers: any[];
  toggleUserQuickView: (...args: any[]) => any;
  handleUserSelection: (...args: any[]) => any;
  showGroups: boolean;
}

const TeamUsersList: React.FunctionComponent<TeamUsersListProps> = props => {
  const {
    users = [],
    filteredUsers = [],
    updateUsers,
    manageUsers,
    teamId,
    showDirectMembership,
    quickViewsByUserId,
    selectedUsers = [],
    showGroups,
    isFetchingTeamUsers,
  } = props;
  const columnWidth = {
    email: 'col-1-4',
    select: 'col-1-18',
    admin: 'col-1-8',
    groups: 'col-1-7',
    direct: 'col-1-8',
    firstName: '',
    lastName: '',
    userRole: '',
  };

  const usersList = () => {
    if (!users.length) {
      return <div className="mt- font--h3 color--muted-dark">No users in workspace.</div>;
    } else if (!filteredUsers.length) {
      return <div className="mt- font--h3 color--muted-dark">No users found.</div>;
    }
    return filteredUsers.map(userObj => {
      const { user = {}, orgRoles = [], _links = {} } = userObj;
      const membership = userObj.membership || {};
      const { roles = [], direct, numGroups } = membership;
      const { id: userId, email = '', firstName = '', lastName = '' } = user;
      const { groups = {} } = _links;
      const quickView = quickViewsByUserId[userId];
      const isSelected = selectedUsers.includes(userId) || false;
      const userRole = orgRoles.find(role => role !== 'USER') || orgRoles[0] || ''; // orgRoles is always at least ['USER']
      const isOrgOwnerOrAdmin = userRole === 'OWNER' || userRole === 'ADMIN';

      return (
        <div className="grid grid--narrower mt-" key={`id-${user.id}`}>
          <div className={`grid__item flex align-items--center ${columnWidth.email}`}>
            {updateUsers ? (
              <Link
                to={`/workspaces/${teamId}/user-management/users/${userId}`}
                className="link--obvious col-1-1 text--overflow"
              >
                <strong>{email}</strong>
              </Link>
            ) : (
              <div className="col-1-1 text--overflow">{email}</div>
            )}
          </div>
          <div className={`grid__item flex align-items--center ${columnWidth.firstName}`}>
            {firstName || '--'}
          </div>
          <div className={`grid__item flex align-items--center ${columnWidth.lastName}`}>
            {lastName || '--'}
          </div>
          <div className={`grid__item flex align-items--center ${columnWidth.userRole}`}>
            {Helpers.capFirst(VC_USER_ROLES[userRole] || userRole)}
          </div>
          <div
            className={`grid__item flex align-items--center justify-content--center ${columnWidth.admin}`}
          >
            {roles.includes('WORKSPACE_ADMIN') || isOrgOwnerOrAdmin ? 'Yes' : '--'}
          </div>
          {showGroups && (
            <div
              className={`grid__item flex justify-content--center align-items--center ${columnWidth.groups}`}
            >
              {numGroups === undefined ? (
                <div>--</div>
              ) : (
                <div
                  className={`justify-content--space-between col-1-2 flex ${
                    numGroups && 'cursor--pointer'
                  }`}
                  onClick={
                    numGroups ? () => props.toggleUserQuickView(userId, 'GROUPS') : undefined
                  }
                >
                  {numGroups} team
                  {numGroups === 1 ? '' : 's'}
                  <i
                    className={`sci sci__caret--${
                      quickView === 'GROUPS' ? 'down' : 'right'
                    } color--${numGroups ? 'primary' : 'muted-light'}`}
                  />
                </div>
              )}
            </div>
          )}
          {showDirectMembership && (
            <div
              className={`grid__item flex align-items--center justify-content--center ${columnWidth.direct}`}
            >
              {direct ? 'Yes' : '--'}
            </div>
          )}
          {manageUsers && (
            <div
              className={`grid__item ${columnWidth.select} flex justify-content--center align-items--center`}
            >
              <label className="label--checkbox flex justify-content--center mb0">
                <input
                  type="checkbox"
                  name="users-option"
                  checked={isSelected}
                  onChange={() => props.handleUserSelection(userId)}
                  disabled={isOrgOwnerOrAdmin}
                />
                <span className="react--checkbox mr0" />
              </label>
            </div>
          )}
          <div
            className={`grid__item col-1-1 ${
              quickView === 'GROUPS' ? 'is-showing-400' : 'is-hiding'
            }`}
          >
            {quickView && (
              <UserManagementQuickViewWrapper dataType="groups" href={groups.href} teamId={teamId}>
                <WorkspaceUserGroupsQuickView showDetailsLink={updateUsers} />
              </UserManagementQuickViewWrapper>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <LoaderWrapper className="grid" isLoaderShowing={isFetchingTeamUsers}>
      <div className="grid__item col-1-1 mt">
        {/* users list header */}
        <div className="grid grid--narrower">
          <div className={`grid__item ${columnWidth.email}`}>
            <strong>Email</strong>
          </div>
          <div className={`grid__item ${columnWidth.firstName}`}>
            <strong>First Name</strong>
          </div>
          <div className={`grid__item ${columnWidth.lastName}`}>
            <strong>Last Name</strong>
          </div>
          <div className={`grid__item ${columnWidth.userRole}`}>
            <strong>User Role</strong>
          </div>
          <div className={`grid__item ${columnWidth.admin} text--center`}>
            <strong>Workspace Admin</strong>
          </div>
          {showGroups && (
            <div className={`grid__item text--center ${columnWidth.groups}`}>
              <strong>Teams</strong>
            </div>
          )}
          {showDirectMembership && (
            <div className={`grid__item ${columnWidth.direct} text--center`}>
              <strong>Direct Membership</strong>
            </div>
          )}
          {manageUsers && (
            <div className={`grid__item ${columnWidth.select} text--center`}>
              <strong>Select</strong>
            </div>
          )}
        </div>
      </div>
      <div className="grid__item col-1-1">{usersList()}</div>
    </LoaderWrapper>
  );
};

export default TeamUsersList;
