export const OPEN_INTEGRATION_MODAL = 'OPEN_INTEGRATION_MODAL';
export const CLOSE_INTEGRATION_MODAL = 'CLOSE_INTEGRATION_MODAL';
export const UPDATE_INTEGRATION_MODAL_FIELD = 'UPDATE_INTEGRATION_MODAL_FIELD';
export const CLEAR_MODAL_JIRA_FIELD = 'CLEAR_MODAL_JIRA_FIELD';
export const JIRA_INTEGRATION_REQUEST = 'JIRA_INTEGRATION_REQUEST';
export const JIRA_INTEGRATION_SUCCESS = 'JIRA_INTEGRATION_SUCCESS';
export const JIRA_INTEGRATION_FAILURE = 'JIRA_INTEGRATION_FAILURE';
export const SET_SELECTED_TICKET_TEMPLATE = 'SET_SELECTED_TICKET_TEMPLATE';
export const SET_CURRENT_EDIT_TEMPLATE = 'SET_CURRENT_EDIT_TEMPLATE';
export const SET_SELECTED_WORKSPACE_TEAM = 'SET_SELECTED_WORKSPACE_TEAM';
export const SET_MODAL_TYPE_JIRA_PREM = 'SET_MODAL_TYPE_JIRA_PREM';

import * as MODEL from '~/constants/ModelConstants';
import ApiService from '~/utils/ApiService';
import ApiConstants from '~/constants/ApiConstants';

export const setIntegrationModalJiraPrem = () => ({
  type: SET_MODAL_TYPE_JIRA_PREM,
});

export const openIntegrationModal = integrationType => (dispatch, getState) => {
  if (integrationType === MODEL.CREATE_PROXY_CLIENT_STEP_1) {
    dispatch(setIntegrationModalJiraPrem());
  }

  const { reportSelectedRows = [] } = getState();
  const deletedIntegrations =
    integrationType === MODEL.DELETE_INTEGRATION_MODAL
      ? reportSelectedRows
          .map(row => row.type)
          .reduce((acc, current) => acc + ' ' + current, '')
          .trim()
      : '';

  dispatch({
    type: OPEN_INTEGRATION_MODAL,
    integrationType,
    meta: {
      snowplow: true,
      string1: integrationType,
      string2: deletedIntegrations,
    },
  });
};

export const closeIntegrationModal = () => ({
  type: CLOSE_INTEGRATION_MODAL,
});

export const updateIntegrationModalField = (fieldType, fieldVal) => ({
  type: UPDATE_INTEGRATION_MODAL_FIELD,
  fieldType,
  fieldVal,
});

export const clearModalJiraField = () => ({
  type: CLEAR_MODAL_JIRA_FIELD,
});

export const jiraIntegrationRequest = integrationStep => ({
  type: JIRA_INTEGRATION_REQUEST,
  integrationStep,
});

export const jiraIntegrationSuccess = (integrationStep, res) => ({
  type: JIRA_INTEGRATION_SUCCESS,
  integrationStep,
  res,
});

export const jiraIntegrationFailure = (integrationStep, err) => ({
  type: JIRA_INTEGRATION_FAILURE,
  integrationStep,
  err,
});

export const setSelectedTicketTemplate = template => ({
  type: SET_SELECTED_TICKET_TEMPLATE,
  template,
});

export const setCurrentEditTemplate = template => ({
  type: SET_CURRENT_EDIT_TEMPLATE,
  template,
});

export const setSelectedWorkspaceTeam = team => ({
  type: SET_SELECTED_WORKSPACE_TEAM,
  team,
});

/*
 Step 1: JIRA OAuth creation: Passing the backend the service name and url.
 Expected to be returned an object of {
  consumerKey,
  applicationName,
  callbackUrl,
  publicKey,
  setupId
 }
*/
export const createJiraIntegration = (integrationName, integrationUrl) => dispatch => {
  dispatch(jiraIntegrationRequest(MODEL.CREATE_JIRA_STEP_1));

  const options = {
    serviceUrl: integrationUrl,
    name: integrationName,
  };

  ApiService.post(ApiConstants.createJiraIntegrationURL(), { data: options })
    .then(res => {
      dispatch(jiraIntegrationSuccess(MODEL.CREATE_JIRA_STEP_1, res));
      dispatch(openIntegrationModal(MODEL.CREATE_JIRA_STEP_2));
    })
    .catch(err => {
      dispatch(jiraIntegrationFailure(MODEL.CREATE_JIRA_STEP_1, err));
    });
};

/**
  Step 2: To be called after user went to their JIRA and have done the necessary set-up.
  Expected to return an oAuth url.
**/
export const getJiraOAuthLink = setupId => dispatch => {
  dispatch(jiraIntegrationRequest(MODEL.CREATE_JIRA_STEP_2));

  ApiService.post(ApiConstants.getJiraOAuthLinkURL(setupId))
    .then(res => {
      dispatch(jiraIntegrationSuccess(MODEL.CREATE_JIRA_STEP_2, res));
      dispatch(openIntegrationModal(MODEL.CREATE_JIRA_STEP_3));
    })
    .catch(err => {
      dispatch(jiraIntegrationFailure(MODEL.CREATE_JIRA_STEP_2, err));
    });
};

export const setEditTicketTemplate = ticketTemplate => dispatch => {
  dispatch(updateIntegrationModalField('targetProjectVal', ticketTemplate.jiraProjectName));
  dispatch(updateIntegrationModalField('issueTypeVal', ticketTemplate.jiraIssueTypeName));
  dispatch(updateIntegrationModalField('templateName', ticketTemplate.name));
  dispatch(updateIntegrationModalField('resolvedStatusVal', ticketTemplate.resolvedStatus));

  ticketTemplate.fields.forEach(field => {
    dispatch(
      updateIntegrationModalField(`jiraField-${field.jiraFieldName}-userEdit`, field.canEdit)
    );
    dispatch(
      updateIntegrationModalField(`jiraField-${field.jiraFieldName}`, field.preDefinedValue)
    );
  });

  dispatch(setCurrentEditTemplate(ticketTemplate));
};

/**
  Stage 0 of the proxy client setup. We need to verify if the jira url is indeed
  inaccessible by the platform. Otherwise user can just create a jira cloud integration.
**/
export const checkIfProxyUrlAccessible = (orgId, proxyUrl) => dispatch => {
  // API post the url
  // get the res and dispatch succes result
  const options = {
    url: proxyUrl,
  };

  dispatch(jiraIntegrationRequest(MODEL.CHECK_PROXY_URL_REQUEST));

  ApiService.post(`/orgs/${orgId}/proxy-client:required`, { data: options })
    .then(res => {
      dispatch(jiraIntegrationSuccess(MODEL.CHECK_PROXY_URL_REQUEST, res));
    })
    .catch(err => {
      dispatch(jiraIntegrationFailure(MODEL.CHECK_PROXY_URL_REQUEST, err));
    });
};

/**
  Stage 1 of the proxy client setup. This is the step where they would take
  after we've checked that they need jira on premise for their jira instance.
**/
export const proceedToProxyStep2 = orgId => dispatch => {
  dispatch(jiraIntegrationRequest(MODEL.CREATE_PROXY_CLIENT_STEP_1));

  ApiService.post(`/orgs/${orgId}/proxy-client`)
    .then(res => {
      dispatch(jiraIntegrationSuccess(MODEL.CREATE_PROXY_CLIENT_STEP_1, res));
      dispatch(openIntegrationModal(MODEL.CREATE_PROXY_CLIENT_STEP_2));
    })
    .catch(err => {
      dispatch(jiraIntegrationFailure(MODEL.CREATE_PROXY_CLIENT_STEP_1, err));
    });
};

/**
  Pre-stage 2. User will check if their proxy client is connected
**/
export const checkIfProxyClientSetup = (orgId, proxyClientUuid) => dispatch => {
  dispatch(jiraIntegrationRequest(MODEL.CHECK_PROXY_CLIENT_REQUEST));

  ApiService.post(`/orgs/${orgId}/proxy-client:connected?setupUuid=${proxyClientUuid}`)
    .then(res => {
      dispatch(jiraIntegrationSuccess(MODEL.CHECK_PROXY_CLIENT_REQUEST, res));
    })
    .catch(err => {
      dispatch(jiraIntegrationFailure(MODEL.CHECK_PROXY_CLIENT_REQUEST, err));
    });
};

/**
  Stage 2 after user have their client connected, we proceed to step 3.
  This can happen when we have a way in the future to seperate step 2 and step 3. So user can
  resume from step 3 so long we have the proxy url.
**/
export const proceedToProxyStep3 = proxyUrl => dispatch => {
  dispatch(updateIntegrationModalField('integrationServerUrl', proxyUrl));
  dispatch(openIntegrationModal(MODEL.CREATE_JIRA_STEP_1));
};

export const createJiraPremIntegration = (
  integrationName,
  integrationUrl,
  proxyClientUuid
) => dispatch => {
  dispatch(jiraIntegrationRequest(MODEL.CREATE_JIRA_STEP_1));

  const options = {
    serviceUrl: integrationUrl,
    name: integrationName,
    uuid: proxyClientUuid,
  };

  ApiService.post(ApiConstants.createJiraPremIntegration(), { data: options })
    .then(res => {
      dispatch(jiraIntegrationSuccess(MODEL.CREATE_JIRA_STEP_1, res));
      dispatch(openIntegrationModal(MODEL.CREATE_JIRA_STEP_2));
    })
    .catch(err => {
      dispatch(jiraIntegrationFailure(MODEL.CREATE_JIRA_STEP_1, err));
    });
};

/****** JIRA LEGACY MODAL RELATED ACTIONS  ******/

export const MODAL_UPDATE_JIRA_URL = 'MODAL_UPDATE_JIRA_URL';
export const MODAL_UPDATE_JIRA_PROJECT_FORM = 'MODAL_UPDATE_JIRA_PROJECT_FORM';
export const MODAL_REMOVE_JIRA_PROJECT = 'MODAL_REMOVE_JIRA_PROJECT';
export const MODAL_ADD_JIRA_PROJECT = 'MODAL_ADD_JIRA_PROJECT';
export const MODAL_UPDATE_JIRA_PROJECT_VALIDATIONS = 'MODAL_UPDATE_JIRA_PROJECT_VALIDATIONS';
export const MODAL_SAVE_ATTEMPTED = 'MODAL_SAVE_ATTEMPTED';

export const MODAL_SET_ISSUE_SETTINGS_TO_EDIT = 'MODAL_SET_ISSUE_SETTINGS_TO_EDIT';

export const setIssueSettingsToEdit = issueSettingsData => {
  return {
    type: MODAL_SET_ISSUE_SETTINGS_TO_EDIT,
    issueSettingsData,
  };
};

export const updateJiraUrl = url => {
  return {
    type: MODAL_UPDATE_JIRA_URL,
    url,
  };
};

export const updateJiraProjectForm = (projectIndex, contentKey, contentValue) => {
  return {
    type: MODAL_UPDATE_JIRA_PROJECT_FORM,
    projectIndex,
    contentKey,
    contentValue,
  };
};

export const removeJiraProject = issueSettingsData => {
  return {
    type: MODAL_REMOVE_JIRA_PROJECT,
    issueSettingsData,
    meta: {
      snowplow: true,
    },
  };
};

export const addJiraProject = () => {
  return {
    type: MODAL_ADD_JIRA_PROJECT,
    meta: {
      snowplow: true,
    },
  };
};

export const updateJiraProjectValidations = activeIssueSettingsData => {
  return {
    type: MODAL_UPDATE_JIRA_PROJECT_VALIDATIONS,
    activeIssueSettingsData,
  };
};

export const saveAttempted = () => {
  return {
    type: MODAL_SAVE_ATTEMPTED,
  };
};

/****** END JIRA LEGACY MODAL RELATED ACTIONS  ******/
