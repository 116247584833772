import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DropdownMenu from 'react-dd-menu';
import _ from 'lodash';
import Helmet from 'react-helmet';

import LoaderWrapper from '~/components/LoaderWrapper';
import Helpers from '~/utils/Helpers';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as workspaceNotificationsSettingsActions from '~/actions/workspaceNotificationsSettings';
import {
  NOTIFICATION_TYPES,
  REPO_EVENTS,
  REPO_EVENTS_CLEAN_NAMES,
} from '~/constants/ModelConstants';

const NOTIFICATION_TYPE = NOTIFICATION_TYPES.WEBHOOK;

interface WebhookNotificationsListSectionProps extends RouteComponentProps {
  teamId: string;
  dropdownMenuState: object;
  dropdownMenuActions: object;
  workspaceNotificationsSettingsActions: object;
  workspaceNotificationsSettingsState: object;
  teamState: object;
}
class WebhookNotificationsListSection extends React.Component<
  WebhookNotificationsListSectionProps,
  {}
> {
  constructor(props, context) {
    super(props, context);
    this.delayCloseDropdownMenu = _.debounce(this.closeDropdownMenu, 50);
  }

  componentDidMount() {
    const { teamId } = this.props;
    this.props.workspaceNotificationsSettingsActions.fetchWorkspaceNotificationsSettingsByType(
      NOTIFICATION_TYPE,
      teamId
    );
  }

  componentWillUnmount() {
    this.props.dropdownMenuActions.resetDropdownMenuState();
  }

  closeDropdownMenu = () => {
    this.props.dropdownMenuActions.closeDropdownMenu('workspace-webhook-notifications-action');
  };

  getCleanEventsName = triggerEvents => {
    if (!triggerEvents || !triggerEvents.length) {
      return '';
    }

    const repoEventsCount = Object.keys(REPO_EVENTS).length;

    if (triggerEvents.length === repoEventsCount) {
      return 'All events';
    } else if (triggerEvents.length === 1) {
      return REPO_EVENTS_CLEAN_NAMES[triggerEvents[0]];
    } else if (triggerEvents.length < repoEventsCount) {
      return 'Selected events';
    } else {
      return '';
    }
  };

  updateWebhookFormControls = event => {
    const { teamId, history } = this.props;

    // Set selected event into controls state before redirecting to /manage-webhook
    this.props.workspaceNotificationsSettingsActions.updateWorkspaceNotificationsSettingsControls(
      NOTIFICATION_TYPE,
      event
    );
    history.push(`/workspaces/${teamId}/notifications/manage-webhook`);
  };

  onRowSelectChange = id => {
    this.props.workspaceNotificationsSettingsActions.updateSelectedRows(NOTIFICATION_TYPE, id);
  };

  deleteSelectedWebhooks = () => {
    const { teamId, workspaceNotificationsSettingsState } = this.props;
    const { [NOTIFICATION_TYPE]: webhookNotifications = {} } = workspaceNotificationsSettingsState;
    const { selectedRows: webhookIds = [] } = webhookNotifications;
    this.props.workspaceNotificationsSettingsActions.deleteWebhookNotifications(teamId, webhookIds);
  };

  createNew = () => {
    const { teamId, history } = this.props;
    history.push(`/workspaces/${teamId}/notifications/manage-webhook`);
  };

  render() {
    const {
      dropdownMenuState,
      workspaceNotificationsSettingsState,
      teamState,
      teamId,
    } = this.props;
    const { [NOTIFICATION_TYPE]: webhookNotifications = {} } = workspaceNotificationsSettingsState;
    const { data = {}, selectedRows = [], isDeleting = false, isFetching } = webhookNotifications;
    const { content = [] } = data;
    const { openedDropdown = {} } = dropdownMenuState;
    const { teams = [] } = teamState;
    const canManageAgents = Helpers.hasManageAgentPermissions({
      teams,
      teamId,
    });
    const colWidths = {
      url: 'col-4-12',
      triggerEvents: 'col-4-12',
      select: 'col-4-12 flex flex--justify-content--end',
    };

    const rows = content.map((item, index) => {
      const { id = '', url = '' } = item;
      const isSelected = selectedRows.includes(id);
      return (
        <div key={`${index}-${item.id}`} className="grid pv--">
          <div className={`grid__item ${colWidths.url} text--wrap `}>
            <a
              className="link--primary text--bold"
              onClick={() => this.updateWebhookFormControls(item)}
            >
              {url}
            </a>
          </div>
          <div className={`grid__item ${colWidths.triggerEvents}`}>
            {this.getCleanEventsName(item.events)}
          </div>
          <div className={`grid__item ${colWidths.select}`}>
            <div className="flex flex--justify-content--center">
              <label className="label--checkbox flex mb0">
                <div className="flex pr-- mr--">
                  <input
                    type="checkbox"
                    name={`webhook-${id}`}
                    id={id}
                    onChange={() => this.onRowSelectChange(id)}
                    checked={isSelected}
                    disabled={!canManageAgents}
                  />
                  <span className={`react--checkbox mr0`} />
                </div>
              </label>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="grid__item">
        <Helmet>
          <title>Webhook Notifications</title>
        </Helmet>
        <p>
          Webhooks allow external services to be notified when certain events happen. When the
          specified events happen, we'll send a POST request to each of the URLs you provide, and
          the content type will be application/json.
        </p>
        <div>
          <div className="mb-- mt pb text--right bo-b--1 border-color--muted-light">
            <DropdownMenu
              animate={true}
              textAlign="left"
              align="right"
              isOpen={!!openedDropdown['workspace-webhook-notifications-action']}
              close={() => this.delayCloseDropdownMenu()}
              toggle={
                <button
                  className={`p- btn--success`}
                  disabled={!canManageAgents}
                  onClick={() =>
                    this.props.dropdownMenuActions.openDropdownMenu(
                      'workspace-webhook-notifications-action'
                    )
                  }
                >
                  Actions
                </button>
              }
            >
              <li>
                <button className="ph" onClick={this.createNew}>
                  Create
                </button>
              </li>
              <li>
                <button
                  className="ph"
                  onClick={this.deleteSelectedWebhooks}
                  disabled={isDeleting || selectedRows.length === 0}
                >
                  Delete
                </button>
              </li>
            </DropdownMenu>
          </div>
          <div className="grid pt">
            <div className={`grid__item ${colWidths.url}`}>
              <span className="text--bold">Payload URL</span>
            </div>
            <div className={`grid__item ${colWidths.triggerEvents}`}>
              <span className="text--bold">Trigger Events</span>
            </div>
            <div className={`grid__item ${colWidths.select}`}>
              <span className="text--bold">Select</span>
            </div>
          </div>
          <div className="height--300 overflow-y--scroll pt-">
            <LoaderWrapper isLoaderShowing={isFetching}>
              {!content.length ? (
                <span className="color--muted">No webhook notifications found.</span>
              ) : (
                rows
              )}
            </LoaderWrapper>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    projectSettingsState: state.projectSettingsState,
    workspaceNotificationsSettingsState: state.workspaceNotificationsSettingsState,
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    workspaceNotificationsSettingsActions: bindActionCreators(
      workspaceNotificationsSettingsActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WebhookNotificationsListSection)
);
