import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import NotFoundPage from '~/components/Errors/NotFoundPage';
import { Location } from 'history';

interface CaptureRouteNotFoundProps {
  location: Location;
}

const CaptureRouteNotFound: React.FunctionComponent<CaptureRouteNotFoundProps> = props => {
  const { location, children } = props;
  const { state = {} } = location;
  const { notFoundError } = state;
  /*
   * notFoundError will be set to true by <RouteNotFound /> in the case a route isn't found (nested or top-level)
   * see https://github.com/ReactTraining/react-router/issues/4698#issuecomment-314419439 for more details
   */

  return notFoundError ? (
    <div className="container flex flex--content">
      <div className="flex flex--content justify-content--center">
        <div className="col-1-1">
          <NotFoundPage />
        </div>
      </div>
    </div>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

export default withRouter(CaptureRouteNotFound);
