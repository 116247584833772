import cookie from 'react-cookie';
import moment from 'moment';
import ErrorService from '~/utils/ErrorService';

import config from '~/config';
import Helpers from '~/utils/Helpers';
import { IS_NEW_ACCOUNT } from '~/constants/ModelConstants';

const BEARER_TOKEN_KEY = 'bearer-token';
const SESSION_COOKIE_KEY = 'session-token';
const TWO_FACTOR_COOKIE_KEY = 'auth-2fa';
const TWO_FACTOR_EXPIRE_DAYS = 30;

const AuthService = {
  setSessionToken: sessionToken => {
    let isSecure = !!config.BEARER_DOMAINS.find(domain =>
      window.location.hostname.endsWith(domain)
    );

    const orgSlug = Helpers.getOrgSlug();
    config.BEARER_DOMAINS.forEach(domain => {
      const sessionKey = orgSlug ? `${orgSlug}.${SESSION_COOKIE_KEY}` : SESSION_COOKIE_KEY;
      cookie.save(sessionKey, sessionToken, {
        path: '/',
        domain: domain,
        secure: isSecure,
      });
    });
  },

  clearSessionToken: () => {
    const utcZero = new Date(0).toUTCString();
    const orgSlug = Helpers.getOrgSlug();
    config.BEARER_DOMAINS.forEach(domain => {
      // flush the old JWT if it still exists
      [BEARER_TOKEN_KEY, SESSION_COOKIE_KEY].forEach(cookieName => {
        const cookieKey = orgSlug ? `${orgSlug}.${cookieName}` : cookieName;
        cookie.remove(cookieKey, {
          path: '/',
          domain: domain,
          expires: utcZero,
          maxAge: 0, // says expire it RIGHT NOW
        });
      });
    });
  },

  /**
   * Sets bearer token for user with an expiry time.
   * @param {String} authToken the API token for the current session, or <tt>null</tt> to <b>CLEAR</b> the session.
   */
  setAuthToken: authToken => {
    return new Promise(resolve => {
      if (authToken) {
        AuthService.setSessionToken(authToken);
      } else {
        AuthService.clearSessionToken();
      }
      resolve();
    });
  },

  logout: request => {
    return request
      .then(
        () => {
          AuthService.setAuthToken(null);
        },
        error => {
          const msg = 'Error logging user out';

          ErrorService.capture(msg, error);
          AuthService.setAuthToken(null);
          throw new Error(msg);
        }
      )
      .catch(error => {
        const msg = 'Error logging user out';

        ErrorService.capture(msg, error);
        AuthService.setAuthToken(null);
        throw new Error(msg);
      });
  },

  getAuthToken: (): string => {
    const orgSlug = Helpers.getOrgSlug();
    const sessionKey = orgSlug ? `${orgSlug}.${SESSION_COOKIE_KEY}` : SESSION_COOKIE_KEY;
    return cookie.load(sessionKey);
  },

  setTwoFactor: value => {
    const orgSlug = Helpers.getOrgSlug();
    const twoFactorCookieKey = orgSlug
      ? `${orgSlug}.${TWO_FACTOR_COOKIE_KEY}`
      : TWO_FACTOR_COOKIE_KEY;
    if (value) {
      cookie.save(twoFactorCookieKey, value, {
        expires: moment().add(TWO_FACTOR_EXPIRE_DAYS, 'days').toDate(),
      });
    } else {
      cookie.remove(TWO_FACTOR_COOKIE_KEY);
    }
  },

  handleNewAccountCookie: value => {
    const domain = window.location.hostname.includes('sourceclear.io')
      ? '.sourceclear.io'
      : '.srcclr.io';
    if (value) {
      const isSecure = !!config.BEARER_DOMAINS.find(domain =>
        window.location.hostname.endsWith(domain)
      );
      cookie.save(IS_NEW_ACCOUNT, value, {
        path: '/',
        isSecure,
        domain,
      });
    } else {
      cookie.remove(IS_NEW_ACCOUNT, {
        path: '/',
        domain,
      });
    }
  },

  getTwoFactor: () => {
    const orgSlug = Helpers.getOrgSlug();
    const twoFactorCookieKey = orgSlug
      ? `${orgSlug}.${TWO_FACTOR_COOKIE_KEY}`
      : TWO_FACTOR_COOKIE_KEY;

    return cookie.load(twoFactorCookieKey);
  },
};

export default AuthService;
