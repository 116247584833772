import ApiService from '~/utils/ApiService';

import * as licenseActions from '~/actions/license';
import { getScopeForRequest } from '~/actions/reports';
import SunburstHelper from '~/utils/SunburstHelper';

export const SET_CURRENT_OPEN_ROW = 'SET_CURRENT_OPEN_ROW';
export const SET_HAS_ERROR = 'SET_HAS_ERROR';
export const SET_IS_OPEN = 'SET_IS_OPEN';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const UPDATE_DATA_BY_ROWID = 'UPDATE_DATA_BY_ROWID';

export const UPDATE_STATE_BY_ROWID = 'UPDATE_STATE_BY_ROWID';

export const setCurrentOpenRow = (rowId = '') => {
  return {
    type: SET_CURRENT_OPEN_ROW,
    rowId,
  };
};

export const setHasError = (hasError = false) => {
  return {
    type: SET_HAS_ERROR,
    hasError,
  };
};

export const setIsOpen = (isOpen = false) => {
  return {
    type: SET_IS_OPEN,
    isOpen,
  };
};

export const setIsLoading = (isLoading = false) => {
  return {
    type: SET_IS_LOADING,
    isLoading,
  };
};

export const updateDataByRowId = (rowId, data) => {
  return {
    type: UPDATE_DATA_BY_ROWID,
    rowId,
    data,
  };
};

export const loadQuickview = (rowId, link, pageNum = 0) => (dispatch, getState) => {
  dispatch(setIsLoading(true));

  const endpoint = `${link}${link.includes('?') ? '&' : '?'}size=10&page=${pageNum}`;
  const scopeForRequest = getScopeForRequest(getState());

  return ApiService.post(endpoint, { data: scopeForRequest }).then(res => {
    if (res.data) {
      res.data.graphs.sunburstPaths = SunburstHelper.formatDepGraphRes(res.data.graphs);
    }
    dispatch(updateDataByRowId(rowId, res));
    dispatch(setIsLoading(false));
  });
};

export const getQuickViewLicenses = (rowId, licenseList) => {
  return dispatch => {
    dispatch(setIsLoading(true));
    const licenseListKey = licenseList.map(license => license.key);
    return licenseActions.getLicenses(licenseListKey).then(fullTextLicenses => {
      licenseList.map(license => {
        fullTextLicenses.map(fullTextLicense => {
          license.fullText = fullTextLicense[license.key]
            ? fullTextLicense[license.key]
            : license.fullText;
        });
      });
      dispatch(updateDataByRowId(rowId, licenseList));
      dispatch(setIsLoading(false));
    });
  };
};

export const updateStateByRowId = (rowId, field, val) => {
  return {
    type: UPDATE_STATE_BY_ROWID,
    rowId,
    field,
    val,
  };
};

/*
  This is called when the user is in a repository scope view. Eg. http://localhost:3000/teams/Paai5v/projects/1428/libraries

  The response for dependency graph are:
  {
    repos: [] //list of repo for the graph of the library component
    data: {
      repo: // the repo being provided data for
      graph: {}
    }
  }

  However the repo might not be what the user has selected. Thus we might have to make
  2 ajax request. One after calling the default dependency graph, the other after finding
  the right repo's dependency graph.
*/
export const loadQuickviewForDependencyPathSelectedRepo = (rowId, link) => {
  return (dispatch, getState) => {
    const scopeForRequest = getScopeForRequest(getState());
    dispatch(setIsLoading(true));
    return ApiService.post(`${link}`, { data: scopeForRequest }).then(res => {
      //get the current selected repo
      const currentSelectRepoId = getState().reportScope.repos[0];

      // The data returned is already matching the repo
      if (res.data.repo.id.toString() === currentSelectRepoId) {
        res.data.graphs.sunburstPaths = SunburstHelper.formatDepGraphRes(res.data.graphs);
        dispatch(updateDataByRowId(rowId, res));
        dispatch(setIsLoading(false));
      } else {
        // Get the url for that repository dependency graph and pull the data
        const currentRepo = res.repos.find(repo => repo.id.toString() === currentSelectRepoId);
        if (currentRepo) {
          ApiService.post(`${currentRepo._links.graph.href}`).then(res => {
            res.data.graphs.sunburstPaths = SunburstHelper.formatDepGraphRes(res.data.graphs);
            dispatch(updateDataByRowId(rowId, res));
            dispatch(setIsLoading(false));
          });
        }
      }
    });
  };
};
