import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CopyToClipboard from '~/containers/CopyToClipboard';
import LoaderWrapper from '~/components/LoaderWrapper';
import * as componentHeadSafeActions from '~/actions/componentHeadSafe';

interface PackageManagerGoDepProps {
  component: object;
  range?: object;
  safeVersion?: string;
  transitive: boolean;
  unsafeVersion?: string;
  componentHeadSafeState: object;
  componentHeadSafeActions: object;
}

/**
  Understand that this is actually a container instead of component. However given that all the package manager are here,
  it will be better to locate it here.
**/
class PackageManagerGoDep extends React.Component<PackageManagerGoDepProps, {}> {
  componentDidMount() {
    const { component } = this.props;
    this.props.componentHeadSafeActions.checkIfComponentHeadSafe(component.id);
  }

  render() {
    const { component, componentHeadSafeState } = this.props;
    const { data, isFetching } = componentHeadSafeState;
    const { [component.id]: componentData = {} } = data;

    return (
      <LoaderWrapper isLoaderShowing={isFetching[component.id]}>
        <div>
          {componentData.hasVulns ? (
            <span>
              <div className="grid pt">
                <span className="grid__item pv- pl--">
                  Unfortunately <strong>go dep</strong> only supports updating a library to the
                  latest commit. The latest commit of the <strong>{component.coordinate1}</strong>{' '}
                  has vulnerabilities. Consider using a package manager that allows you to upgrade
                  to a particular commit such as <strong>glide</strong>
                </span>
              </div>
            </span>
          ) : (
            <span>
              <div className="grid pt">
                <span className="grid__item max-width--30 bg-color--white-dark pv- ph- text--bold">
                  1.
                </span>
                <span className="grid__item pv- pl--">
                  Unfortunately <strong>go dep</strong> only supports updating a library to the
                  latest commit. The latest commit of <strong>{component.coordinate1}</strong> does
                  not have vulnerabilities. Type this to upgrade your library
                </span>
              </div>
              <div className="pt-- -ml mt-">
                <CopyToClipboard value={`go get -u ${component.name}`} id="PackageManagerGoDep1" />
              </div>
              <div className="grid pt">
                <span className="grid__item max-width--30 bg-color--white-dark pv- ph- text--bold">
                  2.
                </span>
                <span className="grid__item pv- pl--">
                  Type this to save the updated dependency
                </span>
              </div>
              <div className="pt-- -ml mt-">
                <CopyToClipboard value={`godep save`} id="PackageManagerGoDep2" />
              </div>
            </span>
          )}
        </div>
      </LoaderWrapper>
    );
  }
}
function mapStateToProps(state) {
  return {
    componentHeadSafeState: state.componentHeadSafeState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    componentHeadSafeActions: bindActionCreators(componentHeadSafeActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageManagerGoDep);
