import * as actions from '~/actions/signup';

export const defaultState = {
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  companyName: undefined,
  vanityName: undefined,
  vanityError: undefined,
  widgetId: undefined,
  msaAgree: false,
  signupError: {},
  isSigningUp: false,
};

const singleSignupState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_SINGLE_SIGNUP_INPUT:
      return {
        ...state,
        [action.field]: action.value,
      };
    case actions.UPDATE_SIGNUP_ERROR:
      return {
        ...state,
        signupError: action.error,
        isSigningUp: false,
      };
    case actions.SINGLE_SIGNUP_TOGGLE_MSA:
      return {
        ...state,
        msaAgree: !state.msaAgree,
        signupError: {},
      };
    case actions.IS_SIGNING_UP:
      return {
        ...state,
        isSigningUp: action.bool || false,
      };
    case actions.SINGLE_SIGNUP_UPDATE_WIDGET_ID:
      return {
        ...state,
        widgetId: action.widgetId,
      };
    case actions.UPDATE_VANITY_ERROR:
      return {
        ...state,
        vanityError: action.message,
      };
    default:
      return state;
  }
};

export default singleSignupState;
