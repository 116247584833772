import React from 'react';

interface FavoriteRepoIconProps {
  onClick: (...args: any[]) => any;
  isFavorite?: boolean;
}

const FavoriteRepoIcon: React.FunctionComponent<FavoriteRepoIconProps> = props => {
  const { isFavorite } = props;
  return (
    <i
      onClick={props.onClick}
      className={`fa transition--3-10 cursor--pointer ${
        isFavorite ? 'fa-star color--warning' : 'far fa-star color--muted'
      }`}
    />
  );
};

export default FavoriteRepoIcon;
