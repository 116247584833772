import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import Helpers from '~/utils/Helpers';
import PolicyControlToggle, { PolicyStatusMode } from '~/components/PolicyControlToggle';
import PolicyControlCardList from './PolicyControlCardList';
import SourceClearModal from '~/components/SourceClearModal';
import WorkspacePicker from '~/components/WorkspacePicker';

import * as orgPolicyActions from '~/actions/orgPolicies';
import * as licenseActions from '~/actions/license';
import * as policyValidationActions from '~/actions/policyValidation';
import * as toastrActions from '~/actions/toastr';
import { EffectiveRevision } from '~/types/Policy';
import { OrgPoliciesState } from '~/reducers/orgPoliciesState';
import LoaderWrapper from '~/components/LoaderWrapper';

interface OrgPoliciesPageProps {}

const INVALID_POLICY = 'INVALID_POLICY';

const OrgPoliciesPage: React.FunctionComponent<OrgPoliciesPageProps> = () => {
  // Redux hooks
  const dispatch = useDispatch();
  // 'team' is workspace
  // 'group' is team
  const {
    groupId,
    groupState,
    myState,
    orgPoliciesState,
    orgState,
    licenseState,
    policyValidationState,
    teamState,
  } = useSelector(state => state as { [key: string]: any });
  // Retrieve validation state
  const { validationResults = {}, isFetching: isValidating } = policyValidationState;
  const { policy: validatedPolicy = {} } = validationResults;
  const { controls: validatedControls = [] } = validatedPolicy;

  const {
    policy: { effectiveRevision = {} as EffectiveRevision, status },
    editMode,
    isFetching,
    isSaving,
    isSavingPolicySettings,
    isResettingToDefault,
    policySettingsErrorMessage = '',
    isFetchingPolicySettings,
    isPolicyDirty,
    isPolicyScopeDirty,
    policySettings: { enforceRules, scope },
    showModal: showPolicyControlModal,
  } = orgPoliciesState as OrgPoliciesState;

  const { controls = [] } = effectiveRevision;

  // Local State Hooks
  const [didUpdateWorkspaces, setDidUpdateWorkspaces] = useState(false);
  // maintain in-memory list of selected workspace to facilitate add/removal of workspaces
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [selectableWorkspaces, setSelectableWorkspaces] = useState([]);

  const orgId = orgState.org.id;

  // the second parameter with an empty array means this will only be run once after the
  // first render, similar to componentDidMount
  useEffect(
    () => {
      fetchOrgPolicies();
      fetchLicenses();
      dispatch(orgPolicyActions.fetchOrgPolicySettings(orgId)).then(settings => {
        const { scopeTeams } = settings;

        // populate selectable workspaces dropdown

        const { teams: workspaces } = teamState;
        const { groups: teams = [] } = groupState;
        const activeTeam = teams.find(team => team.id === parseInt(groupId)) || {};
        const { teams: teamWorkspaces = [] } = activeTeam;
        const activeTeamWorkspaceIds = {};

        teamWorkspaces.forEach(teamWorkspace => {
          const { team: workspace } = teamWorkspace;

          activeTeamWorkspaceIds[workspace.id] = true;
        });

        const filteredWorkspaces =
          workspaces.filter(workspace => {
            return !activeTeamWorkspaceIds[workspace.id] && !workspace.userId;
          }) || [];

        // Construct an array of selectable objects first
        const selectableWorkspacesArr = filteredWorkspaces
          .filter(workspace => !scopeTeams.includes(workspace.id)) // Filter to exclude workspaces found in the scopeTeams
          .map(workspace => ({ ...workspace, label: workspace.name, value: workspace.id }));

        // Then, set selectable workspaces array
        setSelectableWorkspaces(selectableWorkspacesArr);

        // populate selected workspaces with scopeTeams
        setSelectedWorkspaces([
          ...selectedWorkspaces,
          ...mapScopeTeamsIdsToWorkspaces(scopeTeams, filteredWorkspaces),
        ]);
      });

      // returning a function is equivalent to componentWillUnmount
      return () => {
        setSelectedWorkspaces([]);
        setSelectableWorkspaces([]);
      };
    }, // disable exhaustive-deps lint warning because unresolved issues when referencing functions declared outside useEffect, see: https://github.com/facebook/react/issues/15865#issuecomment-527297171
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId]
  );

  function fetchOrgPolicies() {
    dispatch(orgPolicyActions.fetchOrgPolicies(orgId));
  }

  function fetchLicenses() {
    dispatch(licenseActions.fetchLicenses());
  }

  function fetchLicensesByRisk(riskType) {
    dispatch(licenseActions.fetchLicensesByRisk(riskType));
  }

  function removeWorkspaceFromSelectedWorkspaces(workspaceId) {
    setSelectedWorkspaces(
      selectedWorkspaces.filter(selectedWorkspace => selectedWorkspace.id !== workspaceId)
    );

    // add selected workspace back to selectable workspace list

    const workspaceToRemoveFromSelected = selectedWorkspaces.find(workspace => {
      return workspace.id === workspaceId;
    });

    setSelectableWorkspaces(selectableWorkspaces => [
      ...selectableWorkspaces,
      {
        ...workspaceToRemoveFromSelected,
        label: workspaceToRemoveFromSelected.name,
        value: workspaceToRemoveFromSelected.id,
      },
    ]);
    setDidUpdateWorkspaces(true);
  }

  function addWorkspaceToSelectedWorkspaces(workspaceId) {
    const workspaceToAdd = selectableWorkspaces.find(workspace => {
      return workspace.id === workspaceId || workspace.value === workspaceId;
    });

    setSelectedWorkspaces(selectedWorkspaces => [...selectedWorkspaces, workspaceToAdd]);

    // hide selected workspace from selectable workspaces dropdown
    setSelectableWorkspaces(
      selectableWorkspaces.filter(selectableWorkspace => selectableWorkspace.id !== workspaceId)
    );
    setDidUpdateWorkspaces(true);
  }

  // Policy Control Toggle functions
  function saveChanges() {
    dispatch(orgPolicyActions.validateAndSaveControlChanges(orgId));
  }

  function showModal(modalType) {
    dispatch(orgPolicyActions.showModal(modalType));
  }

  function togglePolicyStatusMode(mode: PolicyStatusMode) {
    const {
      policy: { status },
      isPolicyDirty,
    } = orgPoliciesState;

    // prevent user from attempting to toggle already selected status mode
    if (mode === status) return;

    if (isPolicyDirty) {
      showModal('TOGGLE_DEFAULT_MODE');
      return;
    }

    dispatch(orgPolicyActions.togglePolicyStatusMode(mode, orgId)).then(res => {
      if (res.success) {
        showModal(Helpers.getPolicyControlToggleModalId(mode));
      }
    });
  }
  function toggleEditMode(cancel: boolean) {
    if (cancel) {
      dispatch(toastrActions.removeToastr({ id: INVALID_POLICY }));
      dispatch(policyValidationActions.resetPolicyValidationResults());
      fetchOrgPolicies();
      showModal('');
    }

    dispatch(orgPolicyActions.toggleEditMode(cancel));
  }
  function resetToDefault() {
    dispatch(orgPolicyActions.resetToDefault());
  }
  // Policy Control Card List functions
  function updateControl(option: string, field: string, index: number) {
    dispatch(orgPolicyActions.updateControl(option, field, index));
  }
  function removeControl(index: number) {
    dispatch(orgPolicyActions.removeControl(index));
    dispatch(policyValidationActions.removeValidationResultItem(index));
  }
  function toggleControlExpand(index: number) {
    dispatch(orgPolicyActions.toggleControlExpand(index));
  }
  function moveControl(index: number, toIndex: number) {
    dispatch(orgPolicyActions.moveControl(index, toIndex));
    dispatch(policyValidationActions.updateValidationResultsOrder(index, toIndex));
  }
  // Policy Control Card Add New Control Button functions
  function addControl() {
    dispatch(orgPolicyActions.addControl());
    dispatch(policyValidationActions.addValidationResultItem());
  }

  const toggleEnforceRules = (enforce: boolean) => {
    if (enforce) {
      dispatch(orgPolicyActions.toggleEnforceRules(true));
    } else {
      dispatch(orgPolicyActions.toggleEnforceRules(false));
    }
  };

  function enforceOrgRules(e) {
    e.preventDefault();
    const scopeTeams = selectedWorkspaces.map(selectedWorkspace => selectedWorkspace.id);
    dispatch(orgPolicyActions.saveOrgPolicySettings(scopeTeams, orgId));
    showModal('');
    setDidUpdateWorkspaces(false);
  }

  const saveButtonClasses = classNames('pv- ph', {
    'btn--success': isPolicyScopeDirty || didUpdateWorkspaces,
    'btn--default disabled': !isPolicyScopeDirty && !didUpdateWorkspaces,
  });

  const shouldSeePolicyToggle = Helpers.hasEnforceOrgLevelRulesPermissions(myState);

  // Show 'Rules are currently unavailable' text when error is not caused by a controls validation error
  const errorHasOccurred = policySettingsErrorMessage.length > 0;

  return (
    <LoaderWrapper isLoaderShowing={isFetching || isResettingToDefault || isFetchingPolicySettings}>
      <div className="grid mt">
        <Helmet>
          <title>Rules</title>
        </Helmet>
        <form onSubmit={e => e.preventDefault()} className="grid__item col-1-1">
          {errorHasOccurred ? (
            <div className="grid">
              <div className="grid__item col-1-1 flex justify-content--center flex--align-items--center height--75vh">
                <div className="text--center width--400">
                  <p className="font--h5">
                    Rules are currently unavailable.
                    <br />
                    Please try again later.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid">
              <div className="grid__item col-1-1">
                <h3 data-automation-id="OrgPoliciesPage-Title">Rules</h3>
                <div className="orgItemDefinition">
                  Rules are a set of controls that your code should follow. If the rules are
                  violated, you can specify what actions should be taken. To learn more about how
                  organization rules are enforced across workspaces, visit the{' '}
                  <a
                    href="https://help.veracode.com/go/t_int_create_org_rules"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link--obvious"
                  >
                    {'Help Center'}
                    <i className="fas fa-external-link pl-- " />
                  </a>
                </div>
              </div>

              <div className="grid__item col-1-1 mt pl--">
                <div className="grid__item col-1-1">
                  <h4 data-automation-id="OrganizationRules-Heading-Enforce">
                    Enforce Organization Rules
                  </h4>
                  <div className="bo-b--1 mt-- border-color--muted-dark col-1-1" />
                </div>

                <div className="grid__item col-1-1 mt">
                  <div className="grid col-1-1">
                    <label className="grid__item col-1-4 grid--middle mt-- text--wrap">
                      Enforce Organization Rules Across Workspaces
                    </label>
                    <div className="grid__item grid--middle">
                      <button
                        className={classNames(['ph', 'pv--', 'bo-rad--0--right', 'btn--sm'], {
                          'btn--primary btn--primary--border': !enforceRules,
                          'btn--default-clear': enforceRules,
                        })}
                        data-automation-id="OrgPoliciesPage-enforceRulesOff"
                        onClick={() => toggleEnforceRules(false)}
                      >
                        No
                      </button>
                      <button
                        className={classNames(['ph', 'pv--', 'bo-rad--0--left', 'btn--sm'], {
                          'btn--primary btn--primary--border': enforceRules,
                          'btn--default-clear': !enforceRules,
                        })}
                        data-automation-id="OrgPoliciesPage-enforceRulesOn"
                        onClick={() => toggleEnforceRules(true)}
                      >
                        Yes
                      </button>
                      <input type="checkbox" name={'enforce-rules'} defaultChecked={enforceRules} />
                    </div>
                  </div>
                  <div className="grid col-1-1 mt+">
                    <label className="grid__item col-1-4 grid--middle mt--">Scope</label>
                    <div className="grid__item col-1-2 grid--middle">
                      <label htmlFor="enforce-scope-all-workspaces">
                        <input
                          type="radio"
                          name="enforce-scope"
                          value="all-workspaces"
                          id="enforce-scope-all-workspaces"
                          data-automation-id="OrgPoliciesPage-enforceScopeAllWorkspaces"
                          disabled={!enforceRules}
                          defaultChecked={scope === 'ALL_WORKSPACES'}
                          onChange={() => dispatch(orgPolicyActions.toggleScope('ALL_WORKSPACES'))}
                        />
                        <span className="control--radio" />
                        All Workspaces
                      </label>
                      <br />
                      <label htmlFor="enforce-scope-exempted-workspaces">
                        <input
                          type="radio"
                          name="enforce-scope"
                          value="exempted-workspaces"
                          id="enforce-scope-exempted-workspaces"
                          data-automation-id="OrgPoliciesPage-enforceScopeExemptedWorkspaces"
                          disabled={!enforceRules}
                          defaultChecked={scope === 'EXCLUDE'}
                          onChange={() => dispatch(orgPolicyActions.toggleScope('EXCLUDE'))}
                        />
                        <span className="control--radio" />
                        All Workspaces except the following
                      </label>
                      <div className="pl">
                        <WorkspacePicker
                          selectableWorkspaces={selectableWorkspaces}
                          selectedWorkspaces={selectedWorkspaces}
                          updateSelectedWorkspaces={() => {}}
                          isDisabled={!enforceRules || scope === 'ALL_WORKSPACES'}
                          handleRemove={workspaceId =>
                            removeWorkspaceFromSelectedWorkspaces(workspaceId)
                          }
                          handleAdd={workspaceId => addWorkspaceToSelectedWorkspaces(workspaceId)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid col-1-1 mt+">
                    <div className="grid__item col-1-4" />
                    <div className="grid__item">
                      <button
                        data-automation-id="OrgPoliciesPage-saveEnforceRules"
                        type="submit"
                        className={saveButtonClasses}
                        onClick={() => showModal('ENFORCERULES')}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>

                <div className="grid__item col-1-1 mt">
                  <h4 data-automation-id="OrganizationRules-Heading-Define">
                    Define Organization Rules
                  </h4>
                  <div className="bo-b--1 mt-- border-color--muted-dark col-1-1" />
                  <p className="mt">
                    Provide a set of rules here that will be made available across all workspaces in
                    your organization.
                  </p>

                  <PolicyControlToggle
                    editRulesMetaData={{
                      editMode,
                      status,
                      isSaving,
                      isValidating,
                      isPolicyDirty,
                    }}
                    isWorkspace={false}
                    saveChanges={() => saveChanges()}
                    showModal={modal => showModal(modal)}
                    resetToDefault={() => resetToDefault()}
                    modalType={showPolicyControlModal}
                    togglePolicyStatusMode={(mode: PolicyStatusMode) =>
                      togglePolicyStatusMode(mode)
                    }
                    toggleEditMode={toggled => toggleEditMode(toggled)}
                    updatePolicy={true}
                    shouldSeePolicyToggle={shouldSeePolicyToggle}
                    addControl={() => addControl()}
                    renderPolicyControlCardList={() => (
                      <PolicyControlCardList
                        controls={controls}
                        cardState={orgPoliciesState}
                        licenseState={licenseState}
                        validatedControls={validatedControls}
                        updateControl={(option, type, index) => updateControl(option, type, index)}
                        fetchLicensesByRisk={riskType => fetchLicensesByRisk(riskType)}
                        removeControl={index => removeControl(index)}
                        toggleControlExpand={index => toggleControlExpand(index)}
                        moveControl={(index, toIndex) => moveControl(index, toIndex)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </form>
        <SourceClearModal
          closeWhenClickOutside={false}
          isOpen={showPolicyControlModal === 'ENFORCERULES'}
          onClose={() => showModal('')}
          title="Enforce Organization Rules Across Workspaces"
          width={600}
        >
          <form className="grid" onSubmit={e => enforceOrgRules(e)}>
            <div className="grid__item col-1-1">
              Are you sure you want to enforce rules for the selected scope of workspaces? This will
              reset the active rules to those specified at the organization level.
            </div>
            <div className="grid__item col-1-1 flex justify-content--end mt">
              <button
                data-automation-id="OrgPoliciesPage-cancelEnforceRules"
                className="col-1-5 pv-"
                type="button"
                onClick={() => showModal('')}
              >
                Cancel
              </button>
              <button
                data-automation-id="OrgPoliciesPage-confirmEnforceRules"
                className="btn--success"
                type="submit"
              >
                <LoaderWrapper isLoaderShowing={isSavingPolicySettings} loaderType="SPINNER">
                  Enforce Rules
                </LoaderWrapper>
              </button>
            </div>
          </form>
        </SourceClearModal>
      </div>
    </LoaderWrapper>
  );
};

function mapScopeTeamsIdsToWorkspaces(scopeTeams: string[], workspaces) {
  return scopeTeams.map(scopeTeam => workspaces.find(workspace => workspace.id === scopeTeam));
}

export default OrgPoliciesPage;
