import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Select from 'react-select';

import * as MODEL from '~/constants/ModelConstants';
import * as modalActions from '~/actions/modal';
import * as integrationActions from '~/actions/integration';
import * as integrationModalActions from '~/actions/integrationModal';

import JiraCloudTicketForm from '~/containers/JiraCloudTicketForm';
import { IntegrationState } from '~/reducers/integrationState.types';

interface JiraCloudCreateTicketProps {
  selectedIssue: object;
  integrationState: IntegrationState;
  integrationActions: object;
  integrationModalActions: object;
  integrationModalState: object;
  modalActions: object;
  orgState: App.OrgState;
}
class JiraCloudCreateTicket extends React.Component<JiraCloudCreateTicketProps, {}> {
  componentDidMount() {
    // If only 1 ticket template, select that by default for user.
    const ticketTemplateList = this.getTicketTemplateList();
    if (ticketTemplateList.length === 1) {
      this.onTicketTemplateSelect(ticketTemplateList[0]);
    }
  }

  componentWillUnmount() {
    this.props.integrationModalActions.setSelectedTicketTemplate({});
  }

  onTicketTemplateSelect = template => {
    const integration = this.getIntegrationFromTemplate(template);
    this.props.integrationModalActions.setSelectedTicketTemplate(template);
    this.props.integrationActions.getJiraProjectDetail(integration.id);
  };

  getIntegrationFromTemplate(selectedTemplate) {
    const { data = {} } = this.props.integrationState;
    const { content = [] } = data;
    const filteredIntegration = content.filter(integration => {
      return (
        integration.jiraTemplates.filter(template => template.id === selectedTemplate.id).length > 0
      );
    });

    return filteredIntegration.length > 0 ? filteredIntegration[0] : null;
  }

  getTicketTemplateList = () => {
    const { integrationState } = this.props;
    const { data: integrationStateData = {} } = integrationState;
    const integrationList = integrationStateData.content || [];
    return _.flatten(integrationList.map(integration => integration.jiraTemplates));
  };

  getConfigureTemplateLink() {
    const { integrationState } = this.props;
    const { data: integrationStateData = {} } = integrationState;
    const integrationList = integrationStateData.content || [];
    if (integrationList.length > 1) {
      return `/org/settings/integrations`;
    } else {
      return `/org/settings/integrations/jira/${integrationList[0].id}`;
    }
  }

  onClose() {
    this.props.modalActions.closeModal(MODEL.CREATE_ISSUE_JIRA_CLOUD_MODAL);
  }

  render() {
    const { selectedIssue = {}, integrationModalState, orgState } = this.props;
    const { org = {} } = orgState;
    const { permissions = {} } = org;
    const { integrations } = permissions;
    const { selectedTemplate = {} } = integrationModalState;
    const ticketTemplateList = this.getTicketTemplateList();
    const ticketTemplates = ticketTemplateList.map(ticketTemplate => {
      return {
        label: ticketTemplate.name,
        value: ticketTemplate,
      };
    });

    return (
      <div className="color--black">
        <div>
          <div className="mb-">
            {' '}
            Select a pre-defined template to create a Jira issue without leaving SourceClear.
          </div>
          <div className="grid">
            <div className="grid__item col-1-5 pt-">Template</div>
            <div className="grid__item col-4-5">
              <Select
                isClearable={false}
                name="jira-cloud-template"
                value={ticketTemplates.find(option => option.value === selectedTemplate.name)}
                options={ticketTemplates}
                onChange={field => this.onTicketTemplateSelect(field.value)}
                placeholder={'Select a issue template'}
                noOptionsMessage={() => 'No issue template available, configure one'}
                className={'srcclr-react-select-container'}
                classNamePrefix={'srcclr-react-select'}
              />
              {integrations && (
                <div className="text--right pt--">
                  <Link to={this.getConfigureTemplateLink()} className="link--obvious">
                    Configure templates
                  </Link>
                </div>
              )}
              {!integrations && ticketTemplateList.length == 0 && (
                <div className="color--danger pt--">
                  No issue template available, please contact your administrator to create a Jira
                  issue template.
                </div>
              )}
            </div>
          </div>

          <div className="pt">
            <h5> ISSUE DETAILS </h5>
          </div>

          {_.isEmpty(selectedTemplate) ? (
            <div className="pt">
              <div className="text--center">
                {' '}
                To create a Jira issue, first choose a template above{' '}
              </div>

              <div className="p pt++">
                <div className="push--col-1-2 ml++ col-1-2">
                  <button className="ph" onClick={() => this.onClose()}>
                    Cancel
                  </button>
                  <button className="btn--primary-clear ph disabled"> Create </button>
                </div>
              </div>
            </div>
          ) : (
            <JiraCloudTicketForm
              selectedIntegration={this.getIntegrationFromTemplate(selectedTemplate)}
              selectedTemplate={selectedTemplate}
              selectedIssue={selectedIssue}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationState: state.integrationState,
    integrationModalState: state.integrationModalState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    integrationActions: bindActionCreators(integrationActions, dispatch),
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JiraCloudCreateTicket);
