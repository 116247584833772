import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import UpgradeLink from '~/containers/UpgradeLink';
import * as MODEL from '~/constants/ModelConstants';

interface NoAccessPremiumPageProps extends RouteComponentProps {
  teamState: object;
  orgState: App.OrgState;
  navigationState: object;
}

const NoAccessPremiumPage: React.FunctionComponent<NoAccessPremiumPageProps> = props => {
  const { navigationState, location, orgState } = props;
  const { org = {} } = orgState;
  const { permissions = {} } = org;
  const { billing } = permissions;
  const { activeTeamParent: teamId } = navigationState;
  const { search } = location;
  const query = queryString.parse(search) || {};
  const { projectId } = query;
  const page = 'GENERIC_PAYWALL';
  const upgradeTitle = MODEL.UPGRADE_MODAL_CONTENT[page].title || '';
  const upgradeParagraph = MODEL.UPGRADE_MODAL_CONTENT[page].paragraph || '';
  const upgradeBullet = MODEL.UPGRADE_MODAL_CONTENT[page].bullet || [];

  const backLink = projectId ? (
    <Link
      to={teamId ? `/workspaces/${teamId}/projects/${projectId}/issues` : '/'}
      className="link--obvious link--no-underline font--14"
    >
      Back to Workspace to view the most recent scan
    </Link>
  ) : (
    <Link
      to={teamId ? `/workspaces/${teamId}/issues` : '/'}
      className="link--obvious link--no-underline font--14"
    >
      No, thanks
    </Link>
  );

  return (
    <div className="grid mb flex--justify-content--center mt+">
      <div className="max-width--675 pt++">
        <div className="grid">
          <div className="grid__item col-1-5">
            <i className="sci sci__crown font--100 color--premium pt- pl-" />
          </div>
          <div className="grid__item col-4-5 pt ph+ font--20">
            <p className="font--h6 lh+ mb text--bold">{upgradeTitle}</p>
            <div className="font--14 lh+ text--bold">{upgradeParagraph}</div>
            <div className="">
              <ul>
                {upgradeBullet.map((bullet, i) => (
                  <li key={i} className="font--14 pt--">
                    {bullet}
                  </li>
                ))}
              </ul>
            </div>
            {billing ? (
              <div>
                <p className="pt---">
                  <UpgradeLink
                    title="UPGRADE_MODAL_SCAN_HISTORY"
                    className="block bo-rad--3 col-1-1 bg-color--success text--center color--white text--bold p- mt+"
                  >
                    Upgrade Now
                  </UpgradeLink>
                </p>
                <div className="text--center">{backLink}</div>
              </div>
            ) : (
              <div>
                <div className="text--center pt">{backLink}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    teamState: state.teamState,
    orgState: state.orgState,
    navigationState: state.navigationState,
  };
}

export default connect(mapStateToProps)(NoAccessPremiumPage);
