import React from 'react';

interface ReportRowWrapperProps {
  className?: string;
}

const ReportRowWrapper: React.FunctionComponent<ReportRowWrapperProps> = props => {
  const { children, className } = props;

  return (
    <div
      data-automation-id="report-row"
      className={`bo--1 border-color--white-dark pl- pv-- mv--- ${className}`}
    >
      <div className={`grid grid--middle grid--narrower min-height--25`}>{children}</div>
    </div>
  );
};

export default ReportRowWrapper;
