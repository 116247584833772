import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as repoActions from '~/actions/repo';
import { errorMessageTextMap } from '~/constants/ModelConstants';

interface UpdateProjectDetailsModalProps {
  closeModal: (...args: any[]) => any;
  repoActions: object;
  repoDataById: object;
  projectId: string;
  isValidRepoName?: boolean;
}
class UpdateProjectDetailsModal extends React.Component<UpdateProjectDetailsModalProps, {}> {
  componentDidMount() {
    const { projectId, repoDataById } = this.props;
    const { [projectId]: projectData = {} } = repoDataById;
    const { name } = projectData;
    this.props.repoActions.updateRepoName(name);
  }

  saveProjectName(e) {
    e.preventDefault();
    const { projectId, repoDataById } = this.props;
    const { repoNameWillBe } = repoDataById;
    this.props.repoActions.saveRepoName(projectId, repoNameWillBe).then(res => {
      if (res.success) {
        this.closeModal();
      }
    });
  }

  updateRepoName(value) {
    this.props.repoActions.updateRepoName(value);
  }

  closeModal() {
    this.props.closeModal();
  }

  render() {
    const {
      repoDataById: { repoNameWillBe, isValidRepoName },
    } = this.props;

    const repoNameError = !isValidRepoName ? errorMessageTextMap.repoNameError : '';
    const shouldDisableButton =
      repoNameWillBe.length < 3 || repoNameWillBe.length > 40 || !!repoNameError;

    return (
      <form className="grid mt" onSubmit={e => this.saveProjectName(e)}>
        <div className="grid__item col-1-3 flex align-items--center">Project Name</div>
        <div className="grid__item col-2-3 flex align-items--center" >
          <input
            type="text"
            className="control--text col-1-1"
            placeholder="Project Name (required)"
            required
            autoFocus
            value={repoNameWillBe}
            data-automation-id="Project-rename-edit"
            onChange={e => this.updateRepoName(e.target.value)}
          />
        </div>
        <div className={`grid__item col-1-1 height--50 mb-`}>
          <div className={`${repoNameError ? 'is-showing-50' : 'is-hiding'}`}>
            <div className="mt- color--danger" data-automation-id="Project-rename-error">{repoNameError}</div>
          </div>
        </div>
        <div className="grid__item mt flex justify-content--end">
          <div className="col-1-5">
            <button className="col-1-1 pv-" type="button" onClick={() => this.closeModal()}
                    data-automation-id="Project-rename-cancel">
              Cancel
            </button>
          </div>
          <div className="col-1-5 ml-">
            <button
              className="btn--primary-clear col-1-1 pv-"
              type="submit"
              disabled={shouldDisableButton}
              data-automation-id="Project-rename-save"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    repoDataById: state.repoDataById,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    repoActions: bindActionCreators(repoActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProjectDetailsModal);
