import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import { Response, ResponseError } from 'superagent';

import * as MODEL from '~/constants/ModelConstants';
import ApiConstants from '~/constants/ApiConstants';
import * as toastrActions from '~/actions/toastr';
import { Agent, AgentEvent } from '~/reducers/agentState.types';

import {
  ADD_AGENT_TO_TEAM,
  CLEAR_ACTIVATION_TOKEN,
  CLEAR_REGENERATED_TOKEN,
  CLEAR_PLUGIN_TOKENS,
  FILTER_AGENT_LIST_BY_STRING,
  RESET_AGENT_NAME,
  SHOW_AGENT_MODAL,
  UPDATE_ACTIVE_AGENT,
  UPDATE_AGENT_IN_SETUP,
  UPDATE_AGENT_NAME,
  UPDATE_AGENT_SCAN_EVENTS,
  UPDATE_UPGRADE_AGENT_TYPE,
  UPDATE_UPGRADE_ACTIVE_AGENT,
  UPDATE_AGENT_LIST_SORT,
  UPDATE_AGENT_RELEASES,
  UPDATE_AGENTS,
  UPDATE_CURRENT_PAGE,
  UPDATE_EVENT_PAGE,
  UPDATE_INSTALL_OPTION,
  UPDATE_INTEGRATION_TYPE,
  UPDATE_PLUGIN_TOKEN,
  UPDATE_REGENERATED_TOKEN,
  UPDATE_CURRENT_ACTIVE_AGENT,
  UPDATE_SELECTED_AGENT_WORKSPACE,
  UPDATE_TOKEN,
  REQUEST_AGENT_MESSAGES,
  RECEIVE_AGENT_MESSAGES_SUCCESS,
  RECEIVE_AGENT_MESSAGES_ERROR,
  STOP_POLLING,
  ALLOW_POLLING,
  UPDATE_OS,
  UPDATE_ACTIVE_SCRIPT_MODE,
  FETCHING_TEAM_AGENTS,
} from '~/actions/agents.types';

export const updateAgents = (agents: any, teamId: string) => ({
  type: UPDATE_AGENTS,
  agents,
  teamId,
});

export const updateCurrentPage = (currentPage: any) => ({
  type: UPDATE_CURRENT_PAGE,
  currentPage,
});

export const updateEventPage = (currentPage: any) => ({
  type: UPDATE_EVENT_PAGE,
  currentPage,
});

export const updateSelectedWorkspace = (workspace: any) => ({
  type: UPDATE_SELECTED_AGENT_WORKSPACE,
  workspace,
});

export const filterAgentListByString = (value: string) => ({
  type: FILTER_AGENT_LIST_BY_STRING,
  value,
});

export const updateAgentListSort = (field: string) => ({
  type: UPDATE_AGENT_LIST_SORT,
  field,
});

export const updateUpgradeAgentType = (agentType: string) => ({
  type: UPDATE_UPGRADE_AGENT_TYPE,
  agentType,
});

export const updateUpgradeActiveAgent = (activeInstallOption: string) => ({
  type: UPDATE_UPGRADE_ACTIVE_AGENT,
  activeInstallOption,
});

export const showModal = (modalType: string) => ({
  type: SHOW_AGENT_MODAL,
  modalType,
});

export const updateIntegrationType = (intType: string) => ({
  type: UPDATE_INTEGRATION_TYPE,
  intType,
});

export const updateInstallOption = (installOption: string) => ({
  type: UPDATE_INSTALL_OPTION,
  installOption,
  meta: {
    snowplow: true,
    string1: installOption,
  },
});

export const updateOperatingSystem = (operatingSystem: string) => ({
  type: UPDATE_OS,
  operatingSystem,
});

export const updateToken = (tokenObj: any) => ({
  type: UPDATE_TOKEN,
  tokenObj,
});

export const updateActiveAgentWithTeam = (agent: any, teamId: string) => ({
  type: UPDATE_ACTIVE_AGENT,
  agent,
  teamId,
});

export const resetAgentName = () => ({
  type: RESET_AGENT_NAME,
});

export const updateAgentName = (value: string) => ({
  type: UPDATE_AGENT_NAME,
  value,
});

export const updateAgentEvents = (events: Array<AgentEvent>) => ({
  type: UPDATE_AGENT_SCAN_EVENTS,
  events,
});

export const requestAgentMessages = () => ({
  type: REQUEST_AGENT_MESSAGES,
  ignoreTimeout: true,
});

export const updateAgentInSetup = (agentId: string, setupPhase: any) => ({
  type: UPDATE_AGENT_IN_SETUP,
  agentId,
  setupPhase,
});

export const stopPolling = () => ({
  type: STOP_POLLING,
});

export const allowPolling = () => ({
  type: ALLOW_POLLING,
});

export const updateAgentReleases = (releases: any) => ({
  type: UPDATE_AGENT_RELEASES,
  releases,
});

export const fetchAgentReleases = () => (dispatch: any) => {
  (ApiService as any)
    .get(ApiConstants.agentReleasesURL)
    .then((res: Response) => {
      dispatch(updateAgentReleases(res));
    })
    .catch((error: ResponseError) => {
      ErrorService.capture('Error fetching agent releases', error);
      dispatch(updateAgentReleases([]));
    });
};

export const addAgentToTeam = (agent: any, teamId: string) => ({
  type: ADD_AGENT_TO_TEAM,
  agent,
  teamId,
});

export const updatePluginToken = (tokenObj: any) => ({
  type: UPDATE_PLUGIN_TOKEN,
  tokenObj,
});

export const clearPluginTokens = () => ({
  type: CLEAR_PLUGIN_TOKENS,
});

export const clearActivationToken = () => ({
  type: CLEAR_ACTIVATION_TOKEN,
});

export const clearRegeneratedToken = () => ({
  type: CLEAR_REGENERATED_TOKEN,
});

/*
 * ACTION HELPERS
 */
export const createActivationToken = (options: any) => (dispatch: any) => {
  const endpoint = '/agents/activation';

  (ApiService as any).post(endpoint, { data: options }).then((res: Response) => {
    dispatch(updateToken(res));
  });
};

export const fetchAgentData = (agentId: string, teamId: string) => (dispatch: any) => {
  const endpoint = `/agents/${agentId}`;

  return (ApiService as any)
    .get(endpoint)
    .then((res: Response) => {
      dispatch(updateActiveAgentWithTeam(res, teamId));
      return res;
    })
    .catch((error: ResponseError) => {
      ErrorService.capture('Error fetching agent data', error);
      return {};
    });
};

export const generatePluginToken = (teamId: string, pluginType: any) => (dispatch: any) => {
  const endpoint = '/agents/activation/plugin';
  const options = (MODEL as any).agentTokenOptions[pluginType];

  if (options) {
    if (teamId) {
      // teamId is optional. if missing, the token will be assigned to the org
      options.teamId = teamId;
    }

    (ApiService as any).post(endpoint, { data: options }).then((res: Response) => {
      dispatch(updatePluginToken(res));
    });
  }
};

export const saveAgentName = (newName: string, agent: any, teamId: string) => (dispatch: any) => {
  const options = {
    name: newName,
    acceptPaths: agent.acceptPaths,
    acceptRepos: agent.acceptRepos,
  };
  const endpoint = agent._links.self.href || '';

  return (ApiService as any)
    .put(endpoint, { data: options })
    .then(() => {
      dispatch(fetchAgentData(agent.id, teamId));
      return { success: true };
    })
    .catch((error: ResponseError) => {
      ErrorService.capture('Error saving agent name', error);
      return {};
    });
};

export const handleEventsForAgentInSetup = (events: Array<AgentEvent> = [], teamId: string) => (
  dispatch: any,
  getState: any
) => {
  const { agentState } = getState();
  const { tokenObj = {}, currentActiveAgent = {}, activeAgent = {} } = agentState;
  const { agentId: onboardingAgentId } = tokenObj;

  let resultAvailable = false;
  events.forEach(event => {
    const { agentId, eventType } = event;

    if (agentId === onboardingAgentId) {
      if (eventType === 'ScanDataPersisted') {
        resultAvailable = true;
        dispatch(updateAgentInSetup(agentId, 'RESULTS_AVAILABLE'));
      } else if (eventType === 'AgentActivated' && !resultAvailable) {
        dispatch(updateAgentInSetup(agentId, 'AGENT_ACTIVATED'));
        dispatch(fetchAgentData(agentId, teamId)).then((res: any) => {
          if (res.id) {
            dispatch(addAgentToTeam(res, teamId));
          }
        });
        if (activeAgent.id !== currentActiveAgent.id) {
          dispatch(
            toastrActions.addToastr({
              id: 'agent-activation-succes',
              title: 'Activation Successful',
              disableTimeout: true,
              message:
                'The token was successfully activated. To complete your agent setup and view results, perform your first scan.',
              level: 'success',
            })
          );
          dispatch(updateCurrentActiveAgent(activeAgent));
        }
      }
    }
  });
};

export const fetchAgentMessages = (withLastEventId: boolean) => (dispatch: any, getState: any) => {
  dispatch(requestAgentMessages());
  const { agentState } = getState();
  const { activeAgentEvents = [] } = agentState;
  const mostRecentEventId = activeAgentEvents.length && activeAgentEvents[0].eventId;
  const endpoint =
    withLastEventId && mostRecentEventId
      ? `/user/scans/events?lastSeenId=${mostRecentEventId}`
      : `/user/scans/events`;

  (ApiService as any)
    .get(endpoint)
    .then((res: Array<AgentEvent>) => {
      dispatch(fetchAgentMessagesSuccess());
      dispatch(updateAgentEvents(res));
      dispatch(handleEventsForAgentInSetup(res, ''));

      if (agentState.isPollingAllowed) {
        setTimeout(() => dispatch(fetchAgentMessages(true)), 2000);
      }
    })
    .catch((error: ResponseError) => {
      ErrorService.capture('Error fetching agent messages', error);
      dispatch(fetchAgentMessagesError());
    });
};

export const fetchAgentMessagesSuccess = () => ({
  type: RECEIVE_AGENT_MESSAGES_SUCCESS,
  ignoreTimeout: true,
});

export const fetchAgentMessagesError = () => ({
  type: RECEIVE_AGENT_MESSAGES_ERROR,
  ignoreTimeout: true,
});

export const regenerateToken = (agentId: string) => (dispatch: any) => {
  const endpoint = '/agents/activation/renew/' + agentId;

  (ApiService as any).post(endpoint).then((res: Response) => {
    dispatch(updateRegeneratedToken(res));
  });
};

export const updateCurrentActiveAgent = (activeAgent: Agent) => ({
  type: UPDATE_CURRENT_ACTIVE_AGENT,
  activeAgent,
});

export const updateRegeneratedToken = (tokenObj: any) => ({
  type: UPDATE_REGENERATED_TOKEN,
  tokenObj,
});

export const confirmDelete = (agent: any, teamId: string) => (dispatch: any) => {
  const endpoint = agent._links.self.href;

  return (ApiService as any)
    .del(endpoint)
    .then(() => {
      if (teamId) {
        dispatch(fetchTeamAgents(teamId));
      }
      return { success: true };
    })
    .catch((error: ResponseError) => {
      ErrorService.capture('Error deleting agent', error);
      return {};
    });
};

export const fetchTeamAgents = (teamId: string) => (dispatch: any) => {
  dispatch(fetchingTeamAgents(true));
  const endpoint = `/teams/${teamId}/agents`;

  return (ApiService as any)
    .get(endpoint)
    .then((res: Response) => {
      dispatch(updateAgents(res, teamId));
      return res;
    })
    .catch((error: ResponseError) => {
      dispatch(fetchingTeamAgents(false));
      ErrorService.capture('Error fetching agents', error);
      return [];
    });
};

export const updateActiveScriptMode = (scriptMode: string) => ({
  type: UPDATE_ACTIVE_SCRIPT_MODE,
  scriptMode,
});

export const fetchingTeamAgents = (value: boolean) => ({
  type: FETCHING_TEAM_AGENTS,
  value,
});
