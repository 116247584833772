import React from 'react';
import UpgradeLink from '~/containers/UpgradeLink';

function ProjectSettingsNotificationsPaywallPage() {
  return (
    <div className="grid mb">
      <div className="grid__item col-1-1">
        <div className="p font--20">
          <p className="font--18 lh+ mb">
            Webhooks allow external services to be notified when certain events happen, such as scan
            success, or discovery of a new vulnerability in a library after a scan.
          </p>
          <p className="font--18 lh+">
            To unlock webhooks, upgrade today! You'll also get more scans, premium library and
            vulnerability data, JIRA and GitHub integrations, and more!
          </p>
          <p className="pt--">
            <UpgradeLink
              title="PROJECT_SETTINGS_NOTIFICATION"
              location="PROJECT_SETTINGS_NOTIFICATION"
              className="block bo-rad--3 col-1-1 bg-color--success text--center color--white text--bold p- mt+"
            >
              Upgrade Now
            </UpgradeLink>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProjectSettingsNotificationsPaywallPage;
