export const VALIDATE_POLICY_REQUEST = 'VALIDATE_POLICY_REQUEST';
export const VALIDATE_POLICY_SUCCESS = 'VALIDATE_POLICY_SUCCESS';
export const VALIDATE_POLICY_FAILURE = 'VALIDATE_POLICY_FAILURE';
export const UPDATE_POLICY_VALIDATION_RESULTS = 'UPDATE_POLICY_VALIDATION_RESULTS';
export const RESET_POLICY_VALIDATION_RESULTS = 'RESET_POLICY_VALIDATION_RESULTS';
export const REMOVE_VALIDATION_RESULT_ITEM = 'REMOVE_VALIDATION_RESULT_ITEM';
export const UPDATE_VALIDATION_RESULTS_ORDER = 'UPDATE_VALIDATION_RESULTS_ORDER';
export const ADD_VALIDATION_RESULT_ITEM = 'ADD_VALIDATION_RESULT_ITEM';

export const updatePolicyValidationResults = (validationResults, controlCount) => ({
  type: UPDATE_POLICY_VALIDATION_RESULTS,
  validationResults,
  controlCount,
});

export const validatePolicyRequest = () => ({
  type: VALIDATE_POLICY_REQUEST,
});

export const validatePolicyFailure = error => ({
  type: VALIDATE_POLICY_FAILURE,
  message: error.message,
});

export const resetPolicyValidationResults = () => ({
  type: RESET_POLICY_VALIDATION_RESULTS,
});

export const addValidationResultItem = () => ({
  type: ADD_VALIDATION_RESULT_ITEM,
});

export const removeValidationResultItem = index => ({
  type: REMOVE_VALIDATION_RESULT_ITEM,
  index,
});

export const updateValidationResultsOrder = (index, toIndex) => ({
  type: UPDATE_VALIDATION_RESULTS_ORDER,
  index,
  toIndex,
});
