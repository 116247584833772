import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';
import * as meActions from '~/actions/me';

export const WELCOME_MODE_SELECT_OS = 'WELCOME_MODE_SELECT_OS';
export const SHOW_WELCOME_FLOW_MODAL = 'SHOW_WELCOME_FLOW_MODAL';
export const TOGGLE_ALLOW_POLLING = 'TOGGLE_ALLOW_POLLING';
export const FINISH_WELCOME_FLOW_STEP = 'FINISH_WELCOME_FLOW_STEP';
export const ENTER_WELCOME_FLOW_STEP = 'ENTER_WELCOME_FLOW_STEP';
export const TRACK_WELCOME_FLOW_STEP = 'TRACK_WELCOME_FLOW_STEP';
export const UPDATE_AGENT_SETUP = 'UPDATE_AGENT_SETUP';
export const UPDATE_AGENT_EVENTS = 'UPDATE_AGENT_EVENTS';
export const UPDATE_SCAN_STATUS = 'UPDATE_SCAN_STATUS';
export const UPDATE_SCAN_TIP = 'UPDATE_SCAN_TIP';
export const UPDATE_SCAN_PERSISTED = 'UPDATE_SCAN_PERSISTED';

export const trackWelcomeFlowStep = target => ({
  type: ENTER_WELCOME_FLOW_STEP,
  meta: {
    snowplow: true,
    string1: target,
  },
});

export const selectOS = OS => ({
  type: WELCOME_MODE_SELECT_OS,
  OS,
  meta: {
    snowplow: !!OS,
    string1: OS,
  },
});

export const updateAgentSetup = (status = false) => ({
  type: UPDATE_AGENT_SETUP,
  status,
});

export const trackAgentSetup = stage => (dispatch, getState) => {
  const { agentState, welcomeState } = getState();
  const { activeInstallOption = '' } = agentState;
  const { selectedOS = '' } = welcomeState;

  dispatch({
    type: FINISH_WELCOME_FLOW_STEP,
    meta: {
      snowplow: true,
      string1: stage,
      string2: selectedOS,
      string3: activeInstallOption,
    },
  });
};

export const updateScanTips = scanTip => ({
  type: UPDATE_SCAN_TIP,
  scanTip,
});

export const updateScanStatus = (status = false) => ({
  type: UPDATE_SCAN_STATUS,
  status,
});

export const updateScanPersisted = (status = false) => ({
  type: UPDATE_SCAN_PERSISTED,
  status,
});

export const fetchAgentEvents = () => dispatch => {
  return ApiService.get(ApiConstants.fetchAgentEventsURL)
    .then(res => {
      dispatch(processAgentEvents(res));
      dispatch(updateAgentEvents(res));

      return res;
    })
    .catch(error => {
      ErrorService.capture('Error fetching agent events', error);
    });
};

export const updateAgentEvents = events => ({
  type: UPDATE_AGENT_EVENTS,
  events,
});

export const toggleAllowPolling = bool => ({
  type: TOGGLE_ALLOW_POLLING,
  bool,
});

export const showModal = modalType => ({
  type: SHOW_WELCOME_FLOW_MODAL,
  modalType,
});

export const processAgentEvents = (events = []) => (dispatch, getState) => {
  const { welcomeState } = getState();
  const { allowPolling, agentEvents = [] } = welcomeState;
  let isActivated = false;
  let isScanComplete = false;

  if (events.length === agentEvents.length) {
    // if there's not a new event, check if we're polling and re-fetch events in 3 seconds
    if (allowPolling) {
      setTimeout(() => {
        dispatch(fetchAgentEvents());
      }, 2000);
    }
  } else {
    // if there is a new event, loop through to see the status
    events.forEach((event = {}) => {
      const { eventType } = event;

      if (eventType === 'AgentActivated') {
        dispatch(updateAgentSetup(true));
        isActivated = true;
      } else if (eventType === 'ScanDataPersisted') {
        isScanComplete = true;
        dispatch(updateScanStatus(true));
        dispatch(updateScanPersisted(true));
      }
    });

    if (isActivated && !isScanComplete) {
      // anytime an agent has been activated but we don't have a scan, we want to poll (or keep polling)
      dispatch(toggleAllowPolling(true));
      setTimeout(() => {
        dispatch(fetchAgentEvents());
      }, 2000);
    } else if (isScanComplete) {
      // once a scan is complete, we don't care anymore and should turn off polling
      dispatch(toggleAllowPolling(false));
      // we need to re-fetch `me` in order to get the updated `welcomeMode` field on the org
      dispatch(meActions.fetchMe());
    }
  }
};

export const trackLeaveWelcomeMode = () => ({
  type: TRACK_WELCOME_FLOW_STEP,
  meta: {
    snowplow: true,
    string1: 'USER_OVERRIDE',
  },
});

export const skipWelcome = () => (dispatch, getState) => {
  const { orgState } = getState();
  const { org = {} } = orgState;

  dispatch(trackLeaveWelcomeMode());

  return ApiService.post(`/orgs/${org.id}/leave-welcome-mode`)
    .then(() => {
      return { success: true };
    })
    .catch(error => {
      ErrorService.capture('Error leaving welcome mode', error);
      return {};
    });
};
