import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DropdownMenu from 'react-dd-menu';
import _ from 'lodash';

import * as MODEL from '~/constants/ModelConstants';
import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as modalActions from '~/actions/modal';
import * as reportIssuesActions from '~/actions/reportIssues';
import * as upgradeModalActions from '~/actions/upgradeModal';

import ProIcon from '~/components/ProIcon';
import Tooltip from '~/components/Tooltip';
import CreateIssueModal from '~/containers/CreateIssueModal';
import { IntegrationState } from '~/reducers/integrationState.types';

const { JIRA_CLOUD, JIRA_ONPREM, JIRA_LEGACY, GITHUB } = MODEL.INTEGRATION_NAME_MAP;

interface IssueDetailCreateIssueBtnProps {
  dropdownMenuActions: object;
  dropdownMenuState: object;
  integrationState: IntegrationState;
  modalActions: object;
  reportIssuesActions: object;
  upgradeModalActions: object;
  teamId: string;
  issue: object;
  reportType: string;
  isPaidOrTrialing: boolean;
  linkedIssueCreated: boolean;
}
class IssueDetailCreateIssueBtn extends React.Component<IssueDetailCreateIssueBtnProps, {}> {
  constructor(props, context) {
    super(props, context);
    this.delayCloseDropdownMenu = _.debounce(this.closeDropdownMenu, 50);
  }

  componentWillUnmount() {
    this.closeDropdownMenu();
  }

  toggleCtaCreateIssueModalOpen() {
    const { teamId } = this.props;
    this.props.upgradeModalActions.showUpgradeModal(MODEL.UPGRADE_MODAL_CREATE_ISSUE, teamId);
    this.props.reportIssuesActions.createIssueButtonClickedSnowplow(teamId, MODEL.NON_PAID_TEAM);
  }

  handleCreateIssueClick(modal) {
    const { issue } = this.props;

    if (issue && issue._links && issue._links.issueData) {
      const href = issue._links.issueData.href;
      if (href) {
        this.props.reportIssuesActions.fetchFixInfo(href);
      }
    }

    this.props.modalActions.openModal(modal);
  }

  closeDropdownMenu() {
    const { reportType } = this.props;
    this.props.dropdownMenuActions.closeDropdownMenu(`${reportType}-create-issue`);
  }

  render() {
    const {
      dropdownMenuState,
      integrationState,
      issue,
      reportType,
      isPaidOrTrialing,
      linkedIssueCreated,
    } = this.props;
    const { openedDropdown = {} } = dropdownMenuState;

    const createJiraTxt = `${JIRA_CLOUD} / ${JIRA_ONPREM}`;

    const { data: integrationStateData = {} } = integrationState;
    const integrationList = integrationStateData.content || [];

    return (
      <span>
        {linkedIssueCreated ? (
          <button className="disabled border-color--white-dark" disabled>
            <Tooltip content="Issue has been created" id="issue-created-btn">
              <div className="p-"> Create Issue </div>
            </Tooltip>
          </button>
        ) : (
          <DropdownMenu
            animate={true}
            textAlign="left"
            align="right"
            isOpen={!!openedDropdown[`${reportType}-create-issue`]}
            close={() => this.delayCloseDropdownMenu()}
            toggle={
              <button
                className="text--right p- btn--success"
                onClick={() =>
                  this.props.dropdownMenuActions.openDropdownMenu(`${reportType}-create-issue`)
                }
              >
                Create Issue
              </button>
            }
          >
            {isPaidOrTrialing ? (
              <span>
                {integrationList.length > 0 && (
                  <li>
                    <button
                      onClick={() =>
                        this.handleCreateIssueClick(MODEL.CREATE_ISSUE_JIRA_CLOUD_MODAL)
                      }
                    >
                      {createJiraTxt}
                    </button>
                  </li>
                )}
                <li className="bo-b--1 border-color--white-dark" />
                <li>
                  <button
                    onClick={() =>
                      this.handleCreateIssueClick(MODEL.CREATE_ISSUE_JIRA_ENTERPRISE_MODAL)
                    }
                  >
                    {JIRA_LEGACY}
                  </button>
                </li>
                <li className="bo-b--1 border-color--white-dark" />
                <li>
                  <button
                    onClick={() => this.handleCreateIssueClick(MODEL.CREATE_ISSUE_GITHUB_MODAL)}
                  >
                    {GITHUB}
                  </button>
                </li>
              </span>
            ) : (
              <span>
                <li>
                  <button onClick={() => this.toggleCtaCreateIssueModalOpen()}>
                    <ProIcon />
                    {createJiraTxt}
                  </button>
                </li>
                <li className="bo-b--1 border-color--white-dark" />
                <li>
                  <button onClick={() => this.toggleCtaCreateIssueModalOpen()}>
                    <ProIcon /> {JIRA_LEGACY}
                  </button>
                </li>
                <li className="bo-b--1 border-color--white-dark" />
                <li>
                  <button onClick={() => this.toggleCtaCreateIssueModalOpen()}>
                    <ProIcon /> {GITHUB}
                  </button>
                </li>
              </span>
            )}
          </DropdownMenu>
        )}

        <CreateIssueModal selectedIssue={issue} />
      </span>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    integrationState: state.integrationState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    reportIssuesActions: bindActionCreators(reportIssuesActions, dispatch),
    upgradeModalActions: bindActionCreators(upgradeModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueDetailCreateIssueBtn);
