import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';

interface MavenProps {
  packageInfo: object;
  filePath: string;
}

const Maven: React.FunctionComponent<MavenProps> = props => {
  const { coord1, coord2, version } = props.packageInfo;
  const { filePath = 'pom.xml' } = props;

  //split package.json#L5
  const splittedSelectedFilePath = filePath.split('#L');
  const file = splittedSelectedFilePath[0];
  const lineNumber = splittedSelectedFilePath[1] || '';

  return (
    <div>
      <div className="grid bo--1 border-color--white-dark mt lh+">
        <CodeDiffHeader text={file} />
        <CodeDiffLine type="normal" text="<dependency>" />
        <CodeDiffLine
          type="normal"
          text={'\u2003\u003CgroupId\u003E' + coord1 + '\u003C/groupId\u003E'}
        />
        <CodeDiffLine
          type="normal"
          text={'\u2003\u003CartifactId\u003E' + coord2 + '\u003C/artifactId\u003E'}
        />
        <CodeDiffLine
          type="highlight"
          lineNumber={lineNumber}
          text={'\u2003\u003Cversion\u003E' + version + '\u003C/version\u003E'}
        />
        <CodeDiffLine type="normal" text="</dependency>" />
      </div>
      <div className="pt">
        <span className="text--bold"> {coord1 + ':' + coord2} </span> may have been declared as a
        range instead of <span className="text--bold"> {version} </span> in your{' '}
        <span className="text--bold"> {file} </span>
      </div>
    </div>
  );
};

export default Maven;
