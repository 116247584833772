import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import Helpers from '~/utils/Helpers';

import * as groupActions from '~/actions/group';
import * as orgUserActions from '~/actions/orgUser';

import AddUsersToGroup from '~/components/AddUsersToGroup';
import UpdateGroupDetails from '~/components/UpdateGroupDetails';

interface CreateGroupModalProps extends RouteComponentProps {
  workspaceId?: string;
  groupActions: object;
  orgUserActions: object;
  groupState: object;
  orgState: App.OrgState;
  orgUserState: object;
  showModal: (...args: any[]) => any;
}
class CreateGroupModal extends React.Component<CreateGroupModalProps, {}> {
  componentDidMount() {
    const { orgUserState } = this.props;
    const { orgUsers = [] } = orgUserState;

    if (!orgUsers.length) {
      this.props.orgUserActions.fetchOrgUsers();
    } else {
      this.props.orgUserActions.resetSelectedUsers();
    }

    this.props.groupActions.resetGroupData();
  }

  updateCreateGroupStage(stage) {
    this.props.groupActions.updateCreateGroupStage(stage);
  }

  createGroup() {
    const { orgState, orgUserState, groupState, workspaceId, history } = this.props;
    const { org } = orgState;
    const { selectedOrgUsers = [] } = orgUserState;
    const { groupData = {} } = groupState;
    const { name = '', description = '' } = groupData;
    const newGroupData = {
      name,
      description,
      users: selectedOrgUsers,
    };

    if (workspaceId) {
      newGroupData.teams = [{ teamId: workspaceId, role: 'USER' }];
    }

    this.props.groupActions.createGroup(newGroupData, org.id).then(res => {
      if (res.id) {
        this.props.showModal();

        history.push(
          workspaceId
            ? `/workspaces/${workspaceId}/user-management/teams/${res.id}`
            : `/org/settings/teams/${res.id}`
        );
      }
    });
  }

  updateSelectedUsers(userId) {
    this.props.orgUserActions.updateSelectedUsers(userId);
  }

  toggleSelectedUserAdminField(userId) {
    this.props.orgUserActions.toggleSelectedUserAdminField(userId);
  }

  updateGroupData(field, value) {
    this.props.groupActions.updateGroupData(field, value);
  }

  showModal(modalType) {
    this.props.showModal(modalType);
  }

  submitDetailsUpdate() {
    const { groupState } = this.props;
    const { createGroupStage, groups = [], groupData = {} } = groupState;
    const { name } = groupData;

    if (name && groups.find(group => group.name.toLowerCase() === name.toLowerCase())) {
      this.props.groupActions.updateGroupData('error', true);
    } else {
      this.updateCreateGroupStage(createGroupStage + 1);
    }
  }

  render() {
    const { groupState, orgUserState } = this.props;
    const { createGroupStage, groupData = {} } = groupState;
    const { selectedOrgUsers = [], orgUsers = [] } = orgUserState;
    let modalContent = '';
    const selectableUsers = [];
    const selectedUsers = [];

    if (createGroupStage < 1) {
      modalContent = (
        <UpdateGroupDetails
          groupData={groupData}
          createFlow={true}
          updateGroupData={(field, value) => this.updateGroupData(field, value)}
          showModal={modalType => this.showModal(modalType)}
          submitDetailsUpdate={() => this.submitDetailsUpdate()}
        />
      );
    } else {
      // Filter the users that have already been selected, then format a list for the select component
      orgUsers.forEach(userObj => {
        const { user = {} } = userObj;
        const foundUser = selectedOrgUsers.find(selectedUser => selectedUser.userId === user.id);

        if (foundUser) {
          selectedUsers.push({
            ...user,
            admin: foundUser.admin,
          });
        } else {
          let formattedUserName = Helpers.formatUserName(user);

          selectableUsers.push({
            label: formattedUserName,
            value: user.id,
          });
        }
      });

      const buttons = (
        <div className="grid__item col-1-1 flex justify-content--end mt+">
          <button className="col-1-5 pv- mr-" onClick={() => this.showModal()}>
            Cancel
          </button>

          <button
            className="col-1-5 pv- btn--primary-clear mr-"
            onClick={() => this.updateCreateGroupStage(createGroupStage - 1)}
          >
            Back
          </button>
          <button className="col-1-5 pv- btn--success" onClick={() => this.createGroup()}>
            Save
          </button>
        </div>
      );

      modalContent = (
        <AddUsersToGroup
          selectableUsers={selectableUsers}
          selectedUsers={selectedUsers}
          isOptional={true}
          buttons={buttons}
          updateSelectedUsers={userId => this.updateSelectedUsers(userId)}
          toggleAdminField={userId => this.toggleSelectedUserAdminField(userId)}
        />
      );
    }

    return <div className="">{modalContent}</div>;
  }
}

function mapStateToProps(state) {
  return {
    groupState: state.groupState,
    orgState: state.orgState,
    orgUserState: state.orgUserState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateGroupModal));
