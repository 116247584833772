import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Sticky from 'react-sticky-el';
import Helmet from 'react-helmet';
import DropdownMenu from 'react-dd-menu';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';

import * as MODEL from '~/constants/ModelConstants';
import { withOrgPermissionCheck } from '~/utils/Permissions';

import ReportHeaderRow from '~/components/ReportComponents/ReportHeaderRow';
import ReportHeaderItem from '~/components/ReportComponents/ReportHeaderItem';
import ReportActionsWrapper from '~/components/ReportComponents/ReportActionsWrapper';
import ReportStringFilter from '~/containers/ReportStringFilter';
import ReportBottom from '~/containers/ReportBottom';
import ReportFetchErrorMessage from '~/components/ReportFetchErrorMessage';
import SourceClearLoader from '~/components/SourceClearLoader';
import ConfirmDeleteTicketTemplateModal from '~/containers/ConfirmDeleteTicketTemplateModal';

import OrgIntegrationJiraRow from '~/components/OrgIntegrationJiraRow';
import OrgIntegrationTicketModal from '~/containers/OrgIntegrationTicketModal';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as integrationActions from '~/actions/integration';
import * as integrationByIdActions from '~/actions/integrationById';
import * as reportFilterActions from '~/actions/reportFilters';
import * as reportSelectedRowsActions from '~/actions/reportSelectedRows';
import * as integrationModalActions from '~/actions/integrationModal';

const REPORT_TYPE = 'INTEGRATION_JIRA';
const ACTION_DROPDOWN_ID = 'ORG_INTEGRATION_JIRA_ACTION';

interface OrgIntegrationJiraPageProps extends RouteComponentProps {
  dropdownMenuActions: object;
  dropdownMenuState: object;
  integrationByIdActions: object;
  integrationByIdState: object;
  reportSelectedRowsActions: object;
  reportSelectedRows: any[];
  integrationModalActions: object;
  filterActions: object;
  reportFilterState: object;
  integrationActions: object;
  orgState: App.OrgState;
}

class OrgIntegrationJiraPage extends React.Component<OrgIntegrationJiraPageProps, {}> {
  constructor(props, context) {
    super(props, context);
    this.delayCloseDropdownMenu = _.debounce(this.closeDropdownMenu, 50);
  }

  componentDidMount() {
    const { location = {}, match } = this.props;
    const { params = {} } = match;
    const { integrationId } = params;
    const { search } = location;
    const query = queryString.parse(search) || {};
    const { openTicketTemplate = false } = query;
    this.props.integrationByIdActions.fetchTicketTemplate(integrationId);
    if (openTicketTemplate) {
      this.openTicketTemplateModal();
    }
  }

  componentWillUnmount() {
    this.props.reportSelectedRowsActions.clearSelectedRows();
  }

  tryOpenDeleteTicketTemplateModal = () => {
    const { reportSelectedRows } = this.props;
    if (reportSelectedRows.length > 0) {
      this.props.integrationModalActions.openIntegrationModal(MODEL.DELETE_JIRA_TICKET_TEMPLATE);
    }
  };

  loadMoreData = () => {
    const { match, integrationByIdState } = this.props;

    const { params = {} } = match;
    const { integrationId } = params;

    const { ticketTemplateData } = integrationByIdState;
    const { number: pageNum, numberOfElements, size } = ticketTemplateData[integrationId] || {};

    if ((pageNum + 1) * size < numberOfElements) {
      this.props.integrationByIdActions.fetchTicketTemplate(integrationId, pageNum + 1, true);
    }
  };

  updateStringFilter = (field, value) => {
    this.props.filterActions.updateFilterValue(REPORT_TYPE, field, value);
    this.clearRowSelection();
  };

  clearRowSelection = () => {
    this.props.reportSelectedRowsActions.clearSelectedRows();
  };

  handleRowSelectChange = integration => {
    this.props.reportSelectedRowsActions.updateSelectedRows(integration);
  };

  openTicketTemplateModal = () => {
    const { match } = this.props;
    const { params = {} } = match;
    const { integrationId } = params;

    this.props.integrationActions.getJiraProjectDetail(integrationId);
    this.props.integrationModalActions.openIntegrationModal(MODEL.TICKET_TEMPLATE_MODAL);
  };

  handleEditTemplate = ticketTemplate => {
    const { match } = this.props;
    const { params = {} } = match;
    const { integrationId } = params;

    this.props.integrationActions.getJiraProjectDetail(integrationId);
    this.props.integrationModalActions.setEditTicketTemplate(ticketTemplate);
    this.props.integrationModalActions.openIntegrationModal(MODEL.EDIT_TICKET_TEMPLATE_MODAL);
  };

  closeDropdownMenu() {
    this.props.dropdownMenuActions.closeDropdownMenu(ACTION_DROPDOWN_ID);
  }

  toggleDropdown() {
    const { dropdownMenuState } = this.props;
    const { openedDropdown = {} } = dropdownMenuState;
    if (openedDropdown[ACTION_DROPDOWN_ID]) {
      this.closeDropdownMenu();
    } else {
      this.props.dropdownMenuActions.openDropdownMenu(ACTION_DROPDOWN_ID);
    }
  }

  render() {
    const {
      match,
      integrationByIdState,
      reportFilterState,
      reportSelectedRows,
      dropdownMenuState,
    } = this.props;
    const { openedDropdown = {} } = dropdownMenuState;
    const { params = {} } = match;
    const { integrationId } = params;

    const {
      ticketTemplateData,
      ticketTemplateErrorMessage,
      ticketTemplateIsFetching,
    } = integrationByIdState;
    const { content: ticketTemplates = [] } = ticketTemplateData[integrationId] || {};
    const errorMessage = ticketTemplateErrorMessage[integrationId] || null;
    const isFetching = ticketTemplateIsFetching[integrationId] || false;

    const { [REPORT_TYPE]: integrationTicketFilterState = {} } = reportFilterState;
    const { search = '' } = integrationTicketFilterState;

    const columnWidths = {
      name: 'col-1-3',
      project: 'col-1-3',
      issue: 'col-1-4',
    };

    let filteredTicketTemplate = ticketTemplates;
    if (search !== '') {
      filteredTicketTemplate = ticketTemplates.filter(ticketTemplate => {
        return (
          ticketTemplate.name.toUpperCase().indexOf(search.toUpperCase()) >= 0 ||
          ticketTemplate.jiraProjectName.toUpperCase().indexOf(search.toUpperCase()) >= 0 ||
          ticketTemplate.jiraIssueTypeName.toUpperCase().indexOf(search.toUpperCase()) >= 0
        );
      });
    }

    const orgIntegrationJiraRows = filteredTicketTemplate.map((ticketTemplate, index) => {
      const isRowSelected = reportSelectedRows.some(
        selectedRow => selectedRow.id === ticketTemplate.id
      );
      return (
        <OrgIntegrationJiraRow
          ticketTemplate={ticketTemplate}
          key={`${index}-${ticketTemplate.id}`}
          onRowSelectChange={this.handleRowSelectChange}
          isSelected={isRowSelected}
          columnWidths={columnWidths}
          onTemplateNameSelect={this.handleEditTemplate}
        />
      );
    });

    const header = (
      <div className="grid grid--full grid__item col-1-1 zIndex-1--screenForeground">
        <Helmet>
          <title>Jira Integration</title>
        </Helmet>
        {/* JI-48 <div className="col-1-1">
          <Link to="/org/settings/integrations" className="link--primary">
            <i className="sci sci__arrow--left" /> Back
          </Link>
        </div> */}
        <div className="pb font--h3">
          Integration <span className="font--h5"> External Jira </span>{' '}
        </div>
        <div className="grid__item col-1-1">
          <Sticky>
            <ReportActionsWrapper>
              <div className="grid__item col-1-4">
                <ReportStringFilter
                  label={'Search issue templates'}
                  field={'search'}
                  value={search}
                  onChange={this.updateStringFilter}
                />
              </div>
              <div className="grid__item col-2-3">
                {filteredTicketTemplate.length} issue templates
              </div>
              <div className="grid__item -mr">
                <DropdownMenu
                  animate={true}
                  textAlign="left"
                  align="right"
                  isOpen={!!openedDropdown[ACTION_DROPDOWN_ID]}
                  close={() => this.delayCloseDropdownMenu()}
                  toggle={
                    <button
                      className="text--right p- btn--success"
                      onClick={() => this.toggleDropdown()}
                    >
                      Actions
                    </button>
                  }
                >
                  <li>
                    <button className="ph-" onClick={() => this.openTicketTemplateModal()}>
                      Create Issue Template
                    </button>
                  </li>
                  <li className="bo-b--1 border-color--white-dark" />
                  <li
                    className={`${
                      reportSelectedRows.length === 0 ? 'color--muted' : 'color--danger'
                    }`}
                  >
                    <button
                      className="ph-"
                      disabled={reportSelectedRows.length === 0}
                      onClick={() => this.tryOpenDeleteTicketTemplateModal()}
                    >
                      Delete
                    </button>
                  </li>
                </DropdownMenu>
              </div>
            </ReportActionsWrapper>
          </Sticky>
        </div>
        <div className="grid__item col-1-1 mt--">
          <ReportHeaderRow>
            <ReportHeaderItem
              label="Issue Template Name"
              field={'name'}
              widthClass={columnWidths.name}
            />
            <ReportHeaderItem
              label="Jira Project"
              field={'project'}
              widthClass={columnWidths.project}
            />
            <ReportHeaderItem label="Issue Type" field={'issue'} widthClass={columnWidths.issue} />
            <ReportHeaderItem label="Select" alignment="CENTER" />
          </ReportHeaderRow>
        </div>
        <OrgIntegrationTicketModal />
        <ConfirmDeleteTicketTemplateModal />
      </div>
    );

    if (isFetching && !ticketTemplates.length) {
      return (
        <div className="grid pt+">
          {header}
          <div className="grid__item col1-1 pt">
            <SourceClearLoader />
          </div>
        </div>
      );
    }

    if (errorMessage) {
      return (
        <div className="grid grid--center pt+">
          {header}
          <div className="grid__item col-3-5 mt">
            <ReportFetchErrorMessage type="issue templates" />
          </div>
        </div>
      );
    }

    const renderOrgIntegrationJiraRows = () => {
      if (orgIntegrationJiraRows.length > 0) {
        return orgIntegrationJiraRows;
      } else if (search.length > 0) {
        return <h3 className="ml color--muted mt+">No issue templates found.</h3>;
      }

      return (
        <div className="bo--1 border-color--muted bg-color--white-light p">
          <div className="grid">
            <div className="grid__item col-1-1 text--center mt--">
              <div className="font--h6">You have no issue templates.</div>
            </div>
            <div className="grid__item col-1-1 text--center mt-- font--h7">
              <div>
                An issue template defines how fields are displayed when a user creates an issue.
              </div>
              <div>
                Click the <strong>Actions</strong> button above to get started.
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="grid pt+">
        {header}
        <div className="grid__item col-1-1">{renderOrgIntegrationJiraRows()}</div>
        <div className="grid__item col-1-1">
          <ReportBottom onVisible={this.loadMoreData} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    integrationByIdState: state.integrationByIdState,
    reportFilterState: state.reportFilterState,
    reportSelectedRows: state.reportSelectedRows,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    integrationActions: bindActionCreators(integrationActions, dispatch),
    integrationByIdActions: bindActionCreators(integrationByIdActions, dispatch),
    filterActions: bindActionCreators(reportFilterActions, dispatch),
    reportSelectedRowsActions: bindActionCreators(reportSelectedRowsActions, dispatch),
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOrgPermissionCheck(OrgIntegrationJiraPage, 'integrations'));
