import { useState } from 'react';
import * as _ from 'lodash';
function useLanguageTypeSelect(isOpen = false, showAllLanguages = true) {
  const [open, setOpen] = useState(isOpen);
  const languages = [
    'Java',
    'Ruby',
    'JavaScript',
    'Python',
    'ObjectiveC',
    'Swift',
    'CPP',
    'CSHARP',
    'GO',
    'PHP',
  ];

  return {
    options: showAllLanguages ? _.concat('All languages', languages) : languages,
    open,
    onToggle: (isOpen: boolean) => setOpen(isOpen),
  };
}

export default useLanguageTypeSelect;
