import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import KeyHandler from 'react-key-handler';
import _ from 'lodash';

export type Placement = 'top' | 'right' | 'bottom' | 'left';

interface PopoverWithEscKeyProps {
  body: JSX.Element;
  children: JSX.Element;
  className?: string;
  // If this property is enabled, rather than the popover content
  // repositioning on a boundary collision, the popover content container
  // will move beyond the window's bounds. see: https://github.com/alexkatz/react-tiny-popover#api
  disableReposition?: boolean;
  fullWidth?: boolean;
  isOpen: boolean;
  onOuterAction?: (...args: any[]) => any;
  popOverNoAnimation?: boolean;
  preferPlace?: Placement;
  place?: Placement;
  tipSize?: number;
  theme?: 'light' | 'dark';
  isVCPopover?: boolean;
}
function PopoverWithEscKey(props: PopoverWithEscKeyProps) {
  const {
    body,
    children,
    className,
    disableReposition = true,
    fullWidth,
    isOpen,
    isVCPopover = false,
    onOuterAction,
    place = 'bottom',
    preferPlace = 'bottom',
    theme = 'light',
    tipSize,
  } = props;
  // TODO: migrate hardcoded color values along with PopoverWithEscKey to Fluorine
  const darkPopoverArrow = isVCPopover ? '#3c3d3e' : 'hsl(216, 6%, 47%)';
  const popoverBodyClassName = isVCPopover ? 'popover--veracode' : `popover--${theme}`;
  return (
    <Popover
      containerClassName={className}
      disableReposition={disableReposition}
      isOpen={isOpen}
      position={place || preferPlace}
      content={({ position, popoverRect, targetRect }) => {
        return tipSize ? (
          <ArrowContainer
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor={theme === 'dark' && darkPopoverArrow}
            arrowSize={tipSize}
          >
            <div className={popoverBodyClassName} data-automation-id="PopoverWithEscKey-tooltip">
              {body}
            </div>
          </ArrowContainer>
        ) : (
          <div className={popoverBodyClassName} data-automation-id="PopoverWithEscKey-tooltip">
            {body}
          </div>
        );
      }}
      onClickOutside={onOuterAction}
    >
      <div className={fullWidth ? 'col-1-1' : ''} data-automation-id="PopoverWithEscKey-popover">
        <KeyHandler
          keyEventName="keydown"
          keyValue="Escape"
          onKeyHandle={() => (isOpen ? onOuterAction() : _.noop)}
        />
        {children}
      </div>
    </Popover>
  );
}

export default PopoverWithEscKey;
