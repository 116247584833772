import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BarChart, Bar, YAxis, XAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import Helmet from 'react-helmet';

import Helpers from '~/utils/Helpers';
import { withOrgPermissionCheck } from '~/utils/Permissions';
import { ScanUsage } from '~/reducers/usageState.types';
import * as billingActions from '~/actions/billing';
import * as usageActions from '~/actions/usage';

interface OrgUsagePageProps {
  history: { [key: string]: any };
  billingActions: {
    fetchBillingDashboard: (orgId: number) => {};
    toggleAllowOverages: (orgId: number, allowOverages: boolean) => {};
  };
  billingState: { [key: string]: any };
  usageActions: {
    fetchOrgCommitters: (orgId: number) => {};
    fetchScanUsage: (orgId: number) => {};
    updateUsageFilter: (value: string) => {};
    updateUsageSortAscending: (isAscending: boolean) => {};
    updateUsageSortColumn: (sortColumn: any) => {};
  };
  orgState: {
    org: {
      id: number;
      permissions: {
        usage: { [key: string]: any };
      };
    };
  };
  usageState: { [key: string]: any };
  vcPageState: {
    shouldShowVeracodePage: boolean;
  };
}

class OrgUsagePage extends React.Component<OrgUsagePageProps, {}> {
  componentDidMount() {
    const {
      orgState,
      history,
      vcPageState: { shouldShowVeracodePage },
    } = this.props;
    const { org } = orgState;
    const { permissions } = org;
    const { usage } = permissions;

    if (!usage) {
      history.replace('/org/settings/no-owner');
    }

    this.props.usageActions.fetchOrgCommitters(org.id);

    // only fetch billing dashboard for sourcclear users
    if (!shouldShowVeracodePage) {
      this.props.billingActions.fetchBillingDashboard(org.id);
    } else {
      // fetch scan usage directly from the endpoint instead of
      // billing if user is veracode user
      this.props.usageActions.fetchScanUsage(org.id);
    }
  }

  updateUsageFilter(value: string) {
    this.props.usageActions.updateUsageFilter(value);
  }

  updateUsageSortAscending(isAscending: boolean) {
    this.props.usageActions.updateUsageSortAscending(isAscending);
  }

  updateUsageSortColumn(sortColumn: any) {
    this.props.usageActions.updateUsageSortColumn(sortColumn);
  }

  toggleAllowOverages() {
    const { billingState, orgState } = this.props;
    const { allowOverages } = billingState;
    const { org } = orgState;

    this.props.billingActions.toggleAllowOverages(org.id, !allowOverages);
  }

  customTooltip(data: any) {
    const { payload = [] } = data;
    const scanDate = payload[0] || {};

    return (
      <div className="bg-color--white p- font--h7 bo--1 border-color--muted-light">
        {scanDate.value || 0} Scans
      </div>
    );
  }

  render() {
    const {
      billingState,
      usageState: { scanUsage },
      vcPageState: { shouldShowVeracodePage },
    } = this.props;
    const { scanUsage: scanUsageFromBilling = [] } = billingState;

    const [scanUsageChartData, scanUsageData] = shouldShowVeracodePage
      ? [Helpers.buildUsageChartData(scanUsage), scanUsage]
      : [Helpers.buildUsageChartData(scanUsageFromBilling), scanUsageFromBilling];

    return (
      <div className="grid mt mb++">
        <Helmet>
          <title>Usage</title>
        </Helmet>
        <div className="grid__item col-1-1 text--left">
          <div className="font--h3" data-automation-id="OrgUsagePage-Title">
            Usage
          </div>
        </div>
        <div className="grid__item col-1-1 mt mb">
          <ResponsiveContainer height={200} width="100%">
            <BarChart data={scanUsageChartData} margin={{ left: -10, bottom: -10 }}>
              <XAxis dataKey="dayOfMonth" />
              <YAxis />
              <Tooltip content={this.customTooltip} />
              <CartesianGrid strokeDasharray="1 1" />
              <Bar dataKey="scans" fill="#94a2aa" />
            </BarChart>
          </ResponsiveContainer>
          <div className="col-1-1 text--center">Day of Billing Cycle</div>
        </div>

        <div className="grid__item col-1-2 mt-">
          <div className="bo-b--1 border-color--muted pb---">
            <div className="font--h4">Your Usage This Month</div>
          </div>
          <div className="mt- flex justify-content--space-between">
            <div>
              <strong>Date</strong>
            </div>
            <div>
              <strong>Number of Scans</strong>
            </div>
          </div>
          {scanUsageData.map((scanDate: ScanUsage, index: number) => {
            const { month, dayOfMonth, year, scans } = scanDate;
            return (
              <div
                key={`${index}-${month}-${dayOfMonth}-${year}`}
                className="mt- flex justify-content--space-between"
              >
                <div>{`${month}/${dayOfMonth}/${year}`}</div>
                <div>{scans}</div>
              </div>
            );
          })}
          {!scanUsageData.length ? (
            <div className="mt- color--muted font--h6">No scans found.</div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: OrgUsagePageProps) {
  return {
    billingState: state.billingState,
    orgState: state.orgState,
    usageState: state.usageState,
    vcPageState: state.vcPageState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    billingActions: bindActionCreators(billingActions as any, dispatch),
    usageActions: bindActionCreators(usageActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOrgPermissionCheck(OrgUsagePage, 'usage'));
