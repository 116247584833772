import * as actions from '~/actions/workspaceNotificationsSettings';
import { TRIGGER_EVENTS_OPTIONS, REPO_EVENTS } from '~/constants/ModelConstants';

const defaultState = {
  selectedWebhookNotififications: [],
  selectedEmailNotifications: [],
};

const workspaceNotificationsSettingsState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS_FIELD_VALUE: {
      const controls = state[action.notificationType]
        ? state[action.notificationType].controls
        : {};

      return {
        ...state,
        [action.notificationType]: {
          ...state[action.notificationType],
          controls: {
            ...controls,
            [action.field]: action.value,
          },
        },
      };
    }
    case actions.FETCH_WORKSPACE_NOTIFICATIONS_SETTINGS_REQUEST:
      return {
        ...state,
        [action.notificationType]: {
          ...state[action.notificationType],
          isFetching: true,
        },
      };
    case actions.FETCH_WORKSPACE_NOTIFICATIONS_SETTINGS_SUCCESS:
      return {
        ...state,
        [action.notificationType]: {
          ...state[action.notificationType],
          data: action.data,
          isFetching: false,
        },
      };
    case actions.FETCH_WORKSPACE_NOTIFICATIONS_SETTINGS_FAILURE:
      return {
        ...state,
        message: action.message,
        [action.notificationType]: {
          ...state[action.notificationType],
          isFetching: false,
        },
      };
    case actions.SAVE_WORKSPACE_NOTIFICATIONS_SETTINGS_REQUEST:
      return {
        ...state,
        isSaving: true,
      };
    case actions.SAVE_WORKSPACE_NOTIFICATIONS_SETTINGS_SUCCESS:
      return {
        ...state,
        isSaving: false,
        message: '',
      };
    case actions.SAVE_WORKSPACE_NOTIFICATIONS_SETTINGS_FAILURE:
      return {
        ...state,
        isSaving: false,
        message: action.message,
      };
    case actions.RESET_WORKSPACE_NOTIFICATIONS_SETTINGS_CONTROLS:
      return {
        ...state,
        [action.notificationType]: {
          controls: {},
        },
      };
    case actions.UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS_CONTROLS: {
      let individualEvents = action.event.events || [];
      let triggerEventsOption = '';

      //These checks decide which radio button is selected.
      if (individualEvents.length === Object.keys(REPO_EVENTS).length) {
        triggerEventsOption = TRIGGER_EVENTS_OPTIONS.ALL_EVENTS; // Select the All events radio button
        individualEvents = []; // Clear the checkboxes
      } else if (
        individualEvents.length === 1 &&
        individualEvents[0] === REPO_EVENTS.SCAN_SUCCESS
      ) {
        triggerEventsOption = TRIGGER_EVENTS_OPTIONS.JUST_SCAN_EVENT; // Select the Just scan event radio button
        individualEvents = []; // Clear the checkboxes
      } else if (
        individualEvents.length > 1 ||
        (individualEvents.length === 1 && individualEvents[0] !== REPO_EVENTS.SCAN_SUCCESS)
      ) {
        // In this case where 1 < individualEvents.length < total REPO_EVENTS or options other than success event,
        // we select INDIVIDUAL_EVENTS radio button
        // and tick the individual events accordingly ased on the individualEvents
        triggerEventsOption = TRIGGER_EVENTS_OPTIONS.INDIVIDUAL_EVENTS;
      }

      return {
        ...state,
        [action.notificationType]: {
          ...state[action.notificationType],
          controls: {
            ...action.event,
            triggerEventsOption,
            individualEvents,
          },
        },
      };
    }
    case actions.UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS_SELECTED_ROWS: {
      const settings = state[action.notificationType];
      const currentSelectedRows = settings.selectedRows || [];
      const rowId = currentSelectedRows.find(id => id === action.id);
      let newSelectedRows = [];

      if (rowId) {
        newSelectedRows = currentSelectedRows.filter(id => id !== rowId);
      } else {
        newSelectedRows = [...currentSelectedRows, action.id];
      }

      return {
        ...state,
        [action.notificationType]: {
          ...state[action.notificationType],
          selectedRows: newSelectedRows,
        },
      };
    }
    case actions.CLEAR_WORKSPACE_NOTIFICATIONS_SETTINGS_SELECTED_ROWS:
      return {
        ...state,
        [action.notificationType]: {
          ...state[action.notificationType],
          selectedRows: [],
        },
      };
    case actions.DELETE_WORKSPACE_NOTIFICATIONS_SETTINGS_REQUEST:
      return {
        ...state,
        [action.notificationType]: {
          ...state[action.notificationType],
          isDeleting: true,
          message: '',
        },
      };
    case actions.DELETE_WORKSPACE_NOTIFICATIONS_SETTINGS_SUCCESS:
      return {
        ...state,
        [action.notificationType]: {
          ...state[action.notificationType],
          isDeleting: false,
          message: '',
        },
      };
    case actions.DELETE_WORKSPACE_NOTIFICATIONS_SETTINGS_FAILURE:
      return {
        ...state,
        [action.notificationType]: {
          ...state[action.notificationType],
          isDeleting: false,
          message: action.message,
        },
      };
    default:
      return state;
  }
};

export default workspaceNotificationsSettingsState;
