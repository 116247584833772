import ApiService from '~/utils/ApiService';
import AuthService from '~/utils/AuthService';
import ErrorService from '~/utils/ErrorService';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import { FEATURE_SLUG_MAP } from '~/constants/ModelConstants';

import * as teamActions from './team';
import * as orgActions from './org';
import * as messageActions from './message';
import * as userStatusActions from './userStatus';
import * as toastrActions from './toastr';
import * as vcPageActions from './vcAppActions/vcPageActions';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const CHANGING_PASSWORD = 'CHANGING_PASSWORD';
export const EMAIL_VALIDATION_FAILURE = 'EMAIL_VALIDATION_FAILURE';
export const EMAIL_VALIDATION_SUCCESS = 'EMAIL_VALIDATION_SUCCESS';
export const EULA_ACCEPTED = 'EULA_ACCEPTED';
export const PASSWORD_ERROR = 'PASSWORD_ERROR';
export const PASSWORD_CHANGE_ERROR = 'PASSWORD_CHANGE_ERROR';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const TOGGLE_ACCEPT_EULA = 'TOGGLE_ACCEPT_EULA';
export const TOGGLE_ADD_PASSWORD_LOGIN = 'TOGGLE_ADD_PASSWORD_LOGIN';
export const TOGGLE_CHANGE_PASSWORD = 'TOGGLE_CHANGE_PASSWORD';
export const TOGGLE_GITHUB_CONNECT_ERROR = 'TOGGLE_GITHUB_CONNECT_ERROR';
export const UPDATE_ME = 'UPDATE_ME';
export const FETCH_ME_FAILURE = 'FETCH_ME_FAILURE';
export const UPDATE_ME_AUTH = 'UPDATE_ME_AUTH';
export const SET_NOT_LOGGED_IN = 'SET_NOT_LOGGED_IN';
export const UPDATE_IS_ON_PAID_TEAM = 'UPDATE_IS_ON_PAID_TEAM';
export const UPDATE_CURRENT_PASSWORD_INPUT = 'UPDATE_CURRENT_PASSWORD_INPUT';
export const UPDATE_ORG_INVITE = 'UPDATE_ORG_INVITE';
export const UPDATE_TEAM_INVITE = 'UPDATE_TEAM_INVITE';
export const UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME';
export const UPDATE_LAST_NAME = 'UPDATE_LAST_NAME';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS';
export const UPDATING_USER = 'UPDATING_USER';
export const UPDATING_USER_FAILURE = 'UPDATING_USER_FAILURE';
export const VALIDATING_EMAIL = 'VALIDATING_EMAIL';
export const VALIDATION_EMAIL_STATUS = 'VALIDATION_EMAIL_STATUS';
export const IS_DELETING_ME = 'IS_DELETING_ME';
export const DELETE_ME_FAILED = 'DELETE_ME_FAILED';
export const RESET_DELETE_ME_STATE = 'RESET_DELETE_ME_STATE';
export const FETCH_ME_REQUEST = 'FETCH_ME_REQUEST';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';

export const updateNotificationSettings = notificationSettings => ({
  type: UPDATE_NOTIFICATION_SETTINGS,
  notificationSettings,
});

export const fetchNotificationSettings = () => {
  return function (dispatch) {
    ApiService.get('/user/settings/notifications').then(res => {
      dispatch(updateNotificationSettings(res));
    });
  };
};

export const toggleNotificationSetting = (changedField, newValue) => {
  return function (dispatch, getState) {
    const { myState } = getState();
    const { notificationSettings = {} } = myState;
    const { weeklySummaryReportEnabled, newIssuesReportEnabled } = notificationSettings;
    const notificationData = Object.assign(
      {},
      { weeklySummaryReportEnabled, newIssuesReportEnabled },
      {
        [changedField]: newValue,
      }
    );

    ApiService.put('/user/settings/notifications', { data: notificationData })
      .then(res => {
        dispatch(updateNotificationSettings(res));
        dispatch(messageActions.addMessageWithTimeout({ type: 'notificationSettingsSuccess' }));
      })
      .catch(() => {
        dispatch(messageActions.addMessageWithTimeout({ type: 'notificationSettingsError' }));
      });
  };
};

export const updateOrgInvite = orgInvite => ({
  type: UPDATE_ORG_INVITE,
  orgInvite,
});

export const updateTeamInvite = teamInvite => {
  return {
    type: UPDATE_TEAM_INVITE,
    teamInvite,
  };
};

export const updateIsOnPaidTeam = bool => {
  return {
    type: UPDATE_IS_ON_PAID_TEAM,
    bool,
  };
};

export const updateMe = (me = {} as App.Me) => {
  ErrorService.setUserContext({
    id: me.id,
    username: me.username,
    email: me.email,
  });
  if ((window as any).snowplow) {
    (window as any).snowplow('setUserId', me.id);
  }

  return {
    type: UPDATE_ME,
    me,
    isLoggedIn: true,
  };
};

export const fetchMeFailure = () => {
  return {
    type: FETCH_ME_FAILURE,
  };
};

export const clearMe = () => {
  ErrorService.setUserContext();
  return {
    type: UPDATE_ME,
    me: {},
    isLoggedIn: false,
  };
};

export const toggleAcceptEula = () => {
  return {
    type: TOGGLE_ACCEPT_EULA,
  };
};

export const eulaAccepted = () => {
  return {
    type: EULA_ACCEPTED,
  };
};

export const acceptEula = () => dispatch => {
  return ApiService.post('/me/eula')
    .then(() => {
      dispatch(eulaAccepted());
      return { success: true };
    })
    .catch(err => {
      ErrorService.capture('Error accepting MSA', { err });
      return {};
    });
};

export const updateMeTwoFactorAuth = bool => ({
  type: UPDATE_ME_AUTH,
  bool,
});

export const updateUser = params => (dispatch, getState) => {
  dispatch(updatingUser(true));
  const { orgState } = getState();
  const { org = {} } = orgState;
  const { welcomeMode } = org;

  ApiService.put('/user', { data: params })
    .then(
      res => {
        dispatch(updateMe(res));
        if (!res.emailVerified && welcomeMode === false) {
          dispatch(toastrActions.clearToastrs());
          dispatch(
            toastrActions.addToastr({
              id: 'UNVERIFIED-EMAIL',
              level: 'error',
              title: 'Please verify your email',
              message: `An email containing verification instructions was sent to ${res.email}.`,
              disableTimeout: true,
              callback: resendEmailValidationLink,
              callbackText: 'Resend verification link',
              position: 'topCenter',
            })
          );
        }
      },
      error => {
        dispatch(updatingUserFailure(error));
        ErrorService.capture('Error updating user', error);
      }
    )
    .catch(error => {
      dispatch(updatingUserFailure(error));
      ErrorService.capture('Error updating user', error);
    });
};

export const submitChangePasswordForm = params => dispatch => {
  dispatch(changingPassword(true));

  ApiService.post(`/user/password`, { data: params })
    .then(
      res => {
        dispatch(passwordChangeSuccess(res));
      },
      error => {
        dispatch(passwordChangeError(error));
        ErrorService.capture('Error changing password', error);
      }
    )
    .catch(error => {
      dispatch(passwordChangeError(error));
      ErrorService.capture('Error changing password', error);
    });
};

export const changingPassword = bool => ({
  type: CHANGING_PASSWORD,
  bool,
});

export const passwordChangeError = error => ({
  type: PASSWORD_CHANGE_ERROR,
  error,
});

export const passwordChangeSuccess = res => ({
  type: PASSWORD_CHANGE_SUCCESS,
  res,
});

export const updatingUser = bool => ({
  type: UPDATING_USER,
  bool,
});

export const updatingUserFailure = error => ({
  type: UPDATING_USER_FAILURE,
  error,
});

export const fetchMeRequest = () => ({
  type: FETCH_ME_REQUEST,
});

export const deleteMe = () => dispatch => {
  dispatch(isDeletingMe(true));
  /** @param {{status:?Number,message:String}} err */
  const errFn = err => {
    // don't show 500-class error messages, as they're almost always cryptic
    // but 400-class ones _usually_ carry english with them
    // even though 400-class responses don't carry .status, the !== does the right thing
    let userMessage;
    if (err && err.status !== 500 && err.hasOwnProperty('message')) {
      userMessage = err.message;
    } else {
      // don't set it to `null` as that is used to say a-ok downstream
      userMessage = '';
    }
    dispatch(deleteMeFailed(userMessage));
    ErrorService.capture('Failed to delete user', { err });
    return {};
  };

  return ApiService.del('/me')
    .then(() => {
      dispatch(isDeletingMe(false));
      dispatch(clearMe());
      dispatch(userStatusActions.resetUserStatus());
      dispatch(teamActions.updateTeams([]));
      AuthService.setAuthToken(null);
      return { success: true };
    }, errFn)
    .catch(errFn);
};

export const fetchMe = () => dispatch => {
  dispatch(fetchMeRequest());

  return ApiService.get('/me')
    .then(
      res => {
        dispatch(updateMe(res));
        if (res.emailVerified) {
          dispatch(
            toastrActions.removeToastr({
              id: 'UNVERIFIED-EMAIL',
            })
          );
        }

        if (res.organization) {
          dispatch(orgActions.updateOrg(res.organization));
          // Once the org is set, we currently determine whether or not
          // we show the VCPage based solely on the feature flag.
          // This will change in the future.
          dispatch(
            vcPageActions.updateVCPageVisibility(
              FeatureFlagHelper.isFeatureEnabledForOrg(
                FEATURE_SLUG_MAP.VERACODE_PORTFOLIO,
                res.organization
              )
            )
          );
        }

        return res;
      },
      error => {
        dispatch(fetchMeFailure());
        ErrorService.capture('Error fetching user', error);
        return {};
      }
    )
    .catch(error => {
      dispatch(fetchMeFailure());
      ErrorService.capture('Error fetching user', error);
      return {};
    });
};

export const isDeletingMe = bool => {
  return {
    type: IS_DELETING_ME,
    bool,
  };
};

export const deleteMeFailed = message => {
  return {
    type: DELETE_ME_FAILED,
    message,
  };
};

export const resetDeleteMeState = () => {
  return {
    type: RESET_DELETE_ME_STATE,
  };
};

export const updateFirstName = value => ({
  type: UPDATE_FIRST_NAME,
  value,
});

export const updateLastName = value => ({
  type: UPDATE_LAST_NAME,
  value,
});

export const updateEmail = value => ({
  type: UPDATE_EMAIL,
  value,
});

export const toggleChangePassword = () => ({
  type: TOGGLE_CHANGE_PASSWORD,
});

export const toggleAddPasswordLogin = () => ({
  type: TOGGLE_ADD_PASSWORD_LOGIN,
});

export const passwordError = error => ({
  type: PASSWORD_ERROR,
  error,
});

export const updateCurrentPasswordInput = value => ({
  type: UPDATE_CURRENT_PASSWORD_INPUT,
  value,
});

export const disconnectGithub = () => dispatch => {
  ApiService.del('/connect/github')
    .then(
      () => {
        dispatch(fetchMe());
      },
      () => {
        dispatch(fetchMe());
      }
    )
    .catch(error => {
      ErrorService.capture('Error disconnecting github', error);
    });
};

export const toggleGithubConnectError = () => ({
  type: TOGGLE_GITHUB_CONNECT_ERROR,
});

export const validateBillingEmail = token => (dispatch, getState) => {
  return ApiService.post('/billing/verify-email', { data: { token } })
    .then(() => {
      dispatch({ type: EMAIL_VALIDATION_SUCCESS });
      // clear any toastrs
      dispatch(toastrActions.clearToastrs());

      const { myState } = getState();
      const { isLoggedIn } = myState;

      // redirect user based on auth status
      if (isLoggedIn) {
        // fire success toastr
        dispatch(
          toastrActions.addToastr({
            id: 'BILLING-EMAIL-VERIFIED',
            level: 'success',
            title: 'Email successfully verified',
            message: 'Thanks for verifying your billing email address.',
            position: 'topCenter',
          })
        );
      }

      return { success: true };
    })
    .catch(error => {
      dispatch(emailValidationFailure(error));
      ErrorService.capture('Error validating billing email', error);
      return {};
    });
};

export const validateEmail = token => (dispatch, getState) => {
  dispatch({ type: VALIDATING_EMAIL });

  const { myState } = getState();
  const { me = {} } = myState;

  if (me.emailVerified) {
    return Promise.resolve({});
  }

  return ApiService.post('/user/verify-email', { data: { token } })
    .then(
      () => {
        dispatch({ type: EMAIL_VALIDATION_SUCCESS });
        // clear any toatrs that may be present
        dispatch(toastrActions.clearToastrs());
        // fire toastr success message
        dispatch(
          toastrActions.addToastr({
            id: 'EMAIL-VERIFIED',
            level: 'success',
            title: 'Email successfully verified',
            message: 'Thanks for verifying your email address.',
            position: 'topCenter',
          })
        );
        dispatch(fetchMe());
        // redirect user to "home"
        return { success: true };
      },
      error => {
        dispatch(emailValidationFailure(error));
        return {};
      }
    )
    .catch(error => {
      dispatch(emailValidationFailure(error));
      ErrorService.capture('Error validating email', error);
      return {};
    });
};

export const emailValidationFailure = error => ({
  type: EMAIL_VALIDATION_FAILURE,
  error,
});

export const resendEmailValidationLink = (orgId, email) => dispatch => {
  const endpoint = orgId
    ? `/orgs/${orgId}/billing/verify-email/resend`
    : `/user/verify-email/resend`;

  return ApiService.post(endpoint, { data: {} })
    .then(
      () => {
        dispatch(validationEmailStatus('SUCCESS'));
        setTimeout(() => {
          dispatch(validationEmailStatus(undefined));
        }, 5000);
        if (orgId) {
          dispatch(
            toastrActions.addToastr({
              id: 'BILLING_EMAIL_RESENT',
              level: 'success',
              title: 'Email sent',
              message: `We've sent a new link to ${email}.`,
            })
          );
        }
      },
      () => {
        dispatch(validationEmailStatus('FAILURE'));
      }
    )
    .catch(error => {
      dispatch(validationEmailStatus('FAILURE'));
      ErrorService.capture('Error sending validation email', error);
    });
};

export const validationEmailStatus = status => ({
  type: VALIDATION_EMAIL_STATUS,
  status,
});
