import React from 'react';
import CopyToClipboard from '~/containers/CopyToClipboard';
import TokenControl from '~/containers/TokenControl';
import RegionHelpers from '~/utils/RegionHelpers';
import config from '~/config';

interface MavenInstallStepsProps {
  teamId?: string;
  integrationType: string;
  renderAgentWorkspaceSelection?: (...args: any[]) => any;
}

const MavenInstallSteps: React.FunctionComponent<MavenInstallStepsProps> = props => {
  const { teamId, integrationType } = props;

  return (
    <div className="mb+">
      <div className="font--h6">Step one: Set environment variable</div>
      <p className="mt-" />
      <div className="mt- font--h7">
        Set the <code>SRCCLR_API_TOKEN</code> environment variable to the token generated below{RegionHelpers.getRegionEnvVarInstruction(config.REGION)}:
      </div>
      <div className="mt-">
        <TokenControl
          teamId={teamId}
          integrationType={integrationType}
          prependText={`export SRCCLR_API_TOKEN="`}
          appendText={`"`}
        />
      </div>
      <br />
      {props.renderAgentWorkspaceSelection()}
      <div className="font--h6">Step two: Create a post build step</div>
      <p className="mt-">For each project, create a post build step with the following command:</p>
      <div className="mt--">
        <CopyToClipboard value="mvn com.srcclr:srcclr-maven-plugin:scan" id="mavenScan" />
      </div>
      <div className="mt">
        For detailed instructions
        <a
          className="link--obvious link--icon-hover"
          href="https://help.veracode.com/go/t_sc_maven"
          target="_blank"
        >
          {' visit the docs.'}
          <i className="sci sci__arrow--external-link pl-- va-default" />
        </a>
      </div>
    </div>
  );
};

export default MavenInstallSteps;
