export const INIT_PROJECT_SORT_DEFAULTS = 'INIT_PROJECT_SORT_DEFAULTS';
export const UPDATE_SORT_FIELD = 'UPDATE_SORT_FIELD';
export const UPDATE_SORT_FIELD_BY_PROJECT = 'UPDATE_SORT_FIELD_BY_PROJECT';

export const updateSortField = (reportType, field, projectId) => dispatch => {
  if (projectId) {
    dispatch({
      type: UPDATE_SORT_FIELD_BY_PROJECT,
      reportType,
      field,
      projectId,
    });
  } else {
    dispatch({
      type: UPDATE_SORT_FIELD,
      reportType,
      field,
    });
  }
};

export const initProjectSortDefaults = projectId => ({
  type: INIT_PROJECT_SORT_DEFAULTS,
  projectId,
});
