import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import InstallStepsCLI from '~/components/InstallStepsCLI';
import WindowsConfiguration from '~/components/WindowsConfiguration';

import * as agentActions from '~/actions/agents';
import * as welcomeActions from '~/actions/welcome';

import { AgentState } from '~/reducers/agentState.types';

interface WorkspaceAgentSetupPageProps extends RouteComponentProps {
  agentState: AgentState;
  messageState: object;
  welcomeState: object;
}

class WorkspaceAgentSetupPage extends Component<
  WorkspaceAgentSetupPageProps & ReturnType<typeof mapDispatchToProps>
> {
  toggleDownloadOption(option) {
    this.props.agentActions.updateInstallOption(option);
  }

  updateAgentSetup(status) {
    this.props.welcomeActions.updateAgentSetup(status);
    this.props.welcomeActions.trackAgentSetup('AGENT_SETUP_DONE');
  }

  render() {
    const { match, messageState, agentState } = this.props;
    const { operatingSystem } = agentState;
    const { params = {} } = match;
    const { teamId } = params;

    return (
      <div className="col-1-1">
        <div className="grid mb++">
          <div className="grid__item col-1-6" />
          <div className={`grid__item mt col-1-2`}>
            {operatingSystem === 'WINDOWS' ? (
              <WindowsConfiguration
                teamId={teamId}
                integrationType={'windows'}
                toggleDownloadOption={option => this.toggleDownloadOption(option)}
                welcomeFlow={true}
              />
            ) : (
              <InstallStepsCLI
                integrationType="cli"
                agentState={agentState}
                messageState={messageState}
                teamId={teamId}
                welcomeFlow={true}
                toggleDownloadOption={option => this.toggleDownloadOption(option)}
              />
            )}
            <div className="mt">
              <Link
                className="btn--success pv- ph"
                to={`/workspaces/${teamId}/welcome`}
                onClick={() => this.updateAgentSetup(true)}
              >
                Done
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentState: state.agentState,
    messageState: state.messageState,
    welcomeState: state.welcomeState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    agentActions: bindActionCreators(agentActions, dispatch),
    welcomeActions: bindActionCreators(welcomeActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceAgentSetupPage);
