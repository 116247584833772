import ApiService from '~/utils/ApiService';
import config from '~/config';
import { veracodePoliciesService } from '~/constants/ModelConstants';
import { PolicyDetail, PolicyResponse, ScaPolicyResponse } from '~/types/UnifiedPolicy';
import { Dispatch } from 'redux';
import { UnifiedPoliciesState } from '~/reducers/unifiedPoliciesState.types';
import { RootState } from '~/reducers';

export const FETCH_UNIFIED_POLICIES_SUCCESS = 'FETCH_UNIFIED_POLICIES_SUCCESS';
export const FETCH_UNIFIED_POLICIES = 'FETCH_UNIFIED_POLICIES';
export const FETCH_UNIFIED_POLICIES_FAILURE = 'FETCH_UNIFIED_POLICIES_FAILURE';
export const FETCH_UNIFIED_ASSIGNED_POLICY = 'FETCH_UNIFIED_ASSIGNED_POLICY';
export const FETCH_UNIFIED_ASSIGNED_POLICY_SUCCESS = 'FETCH_UNIFIED_ASSIGNED_POLICY_SUCCESS';
export const FETCH_UNIFIED_ASSIGNED_POLICY_FAILURE = 'FETCH_UNIFIED_ASSIGNED_POLICY_FAILURE';
export const SAVE_UNIFIED_POLICY_ASSIGNMENT = 'SAVE_UNIFIED_POLICY_ASSIGNMENT';
export const SAVE_UNIFIED_POLICY_ASSIGNMENT_SUCCESS = 'SAVE_UNIFIED_POLICY_ASSIGNMENT_SUCCESS';
export const SAVE_UNIFIED_POLICY_ASSIGNMENT_FAILURE = 'SAVE_UNIFIED_POLICY_ASSIGNMENT_FAILURE';
export const CLEAR_SAVED_FLAG_UNIFIED_POLICY = 'CLEAR_SAVED_FLAG_UNIFIED_POLICY';
export const FETCH_UNIFIED_ASSIGNED_POLICY_SYNC_FAILURE =
  'FETCH_UNIFIED_ASSIGNED_POLICY_SYNC_FAILURE';

export const createFetchPoliciesSuccessAction = (policies: PolicyDetail[]) => ({
  type: FETCH_UNIFIED_POLICIES_SUCCESS,
  policies,
});

export const createUnifiedFetchAssignedPolicyAction = (teamId: string) => ({
  type: FETCH_UNIFIED_ASSIGNED_POLICY,
  teamId,
});

export const createUnifiedFetchAssignedPolicySuccessAction = (
  teamId: string,
  assignedPolicy: PolicyDetail
) => ({
  type: FETCH_UNIFIED_ASSIGNED_POLICY_SUCCESS,
  payload: {
    teamId,
    assignedPolicy,
  },
});

export const createUnifiedFetchAssignedPolicyFailureAction = (teamId: string) => ({
  type: FETCH_UNIFIED_ASSIGNED_POLICY_FAILURE,
  teamId,
});

export const createFetchPoliciesAction = () => ({
  type: FETCH_UNIFIED_POLICIES,
});

export const createFetchPoliciesFailureAction = () => ({
  type: FETCH_UNIFIED_POLICIES_FAILURE,
});

export const createSaveUnifiedPolicyAssignmentAction = (teamId: string) => ({
  type: SAVE_UNIFIED_POLICY_ASSIGNMENT,
  teamId,
});

export const createSaveUnifiedPolicyAssignmentSuccessAction = (
  teamId: string,
  assignedPolicy: PolicyDetail
) => ({
  type: SAVE_UNIFIED_POLICY_ASSIGNMENT_SUCCESS,
  payload: { teamId, assignedPolicy },
});

export const createSaveUnifiedPolicyAssignmentFailureAction = (teamId: string) => ({
  type: SAVE_UNIFIED_POLICY_ASSIGNMENT_FAILURE,
  teamId,
});

export const createClearSavedFlagUnifiedPolicyAction = (teamId: string) => ({
  type: CLEAR_SAVED_FLAG_UNIFIED_POLICY,
  teamId,
});

export const createFetchUnifiedPolicySyncFailureAction = (teamId: string) => ({
  type: FETCH_UNIFIED_ASSIGNED_POLICY_SYNC_FAILURE,
  teamId,
});

export type UnifiedPoliciesAction =
  | { type: typeof FETCH_UNIFIED_POLICIES }
  | { type: typeof FETCH_UNIFIED_POLICIES_SUCCESS; policies: PolicyDetail[] }
  | { type: typeof FETCH_UNIFIED_POLICIES_FAILURE }
  | { type: typeof FETCH_UNIFIED_ASSIGNED_POLICY; teamId: string }
  | {
      type: typeof FETCH_UNIFIED_ASSIGNED_POLICY_SUCCESS;
      payload: { teamId: string; assignedPolicy: PolicyDetail };
    }
  | { type: typeof FETCH_UNIFIED_ASSIGNED_POLICY_FAILURE; teamId: string }
  | { type: typeof SAVE_UNIFIED_POLICY_ASSIGNMENT; teamId: string }
  | {
      type: typeof SAVE_UNIFIED_POLICY_ASSIGNMENT_SUCCESS;
      payload: { teamId: string; assignedPolicy: PolicyDetail };
    }
  | { type: typeof SAVE_UNIFIED_POLICY_ASSIGNMENT_FAILURE; teamId: string }
  | { type: typeof CLEAR_SAVED_FLAG_UNIFIED_POLICY; teamId: string }
  | { type: typeof FETCH_UNIFIED_ASSIGNED_POLICY_SYNC_FAILURE; teamId: string };

/*
 * action creator that handles business logic of linking policy-backend assigned id to agora policies
 * if it matches: it creates a success action with the assigned policy
 * if it doesn't match: it creates a sync failure action
 * */
const attemptToLinkAssignedPolicyToPolicies = (
  teamId: string,
  assignedPolicyId: string,
  state: UnifiedPoliciesState
) => {
  const potentialAssignedPolicy = state.policies.find(policy => policy.guid === assignedPolicyId);
  return potentialAssignedPolicy != null
    ? createUnifiedFetchAssignedPolicySuccessAction(teamId, potentialAssignedPolicy)
    : createFetchUnifiedPolicySyncFailureAction(teamId);
};

export const fetchPolicies = () => {
  return async (dispatch: Dispatch) => {
    dispatch(createFetchPoliciesAction());
    await getPolicies()
      .then(policies => dispatch(createFetchPoliciesSuccessAction(policies)))
      .catch(() => dispatch(createFetchPoliciesFailureAction()));
  };
};

export const fetchAssignedPolicy = (teamId: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(createUnifiedFetchAssignedPolicyAction(teamId));
    const state = getState().unifiedPoliciesState;
    await getAssignedPolicy(teamId)
      .then(assignedPolicyId =>
        dispatch(attemptToLinkAssignedPolicyToPolicies(teamId, assignedPolicyId, state))
      )
      .catch(() => dispatch(createUnifiedFetchAssignedPolicyFailureAction(teamId)));
  };
};

export const saveUnifiedPolicyAssignment = (teamId: string, policy: PolicyDetail) => {
  return async (dispatch: Dispatch) => {
    dispatch(createSaveUnifiedPolicyAssignmentAction(teamId));
    try {
      await saveAssignedPolicy(teamId, policy.guid);
      dispatch(createSaveUnifiedPolicyAssignmentSuccessAction(teamId, policy));
    } catch (e) {
      dispatch(createSaveUnifiedPolicyAssignmentFailureAction(teamId));
    } finally {
      // clear success/fail flags after 3s
      setTimeout(() => {
        dispatch(createClearSavedFlagUnifiedPolicyAction(teamId));
      }, 3000);
    }
  };
};

const getPolicies = () =>
  ApiService.getV2<PolicyResponse>(
    `${config.VERACODE_UIGATEWAY_HOST}${veracodePoliciesService}?rule_processor_type=AGENT&size=5000&page=0`
  ).then(response => {
    const policies = response?._embedded?.policy_versions;
    return policies ? policies : [];
  });

const getAssignedPolicy = (teamId: string) =>
  ApiService.getV2<ScaPolicyResponse>(`/v1/teams/${teamId}/policy`).then(
    ({ policy_id }) => policy_id
  );

const saveAssignedPolicy = (teamId: string, policyId: string) =>
  ApiService.put(`/v1/teams/${teamId}/policy`, {
    data: { policy_id: policyId },
  });
