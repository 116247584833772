import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { BarChart, Bar, XAxis, Cell } from 'recharts';
import { IssueInsightSeverity } from '~/components/IssuesInsightsBar.types';

interface IssuesInsightsBarProps {
  title: string;
  field?: string;
  defaultColor?: string;
  content: IssueInsightSeverity[];
  contentUnit?: string;
  colorBarMap?: { [key: string]: string };
  handleClick?: (...args: any[]) => any;
  isClickable?: boolean;
  isFetchingInsights?: boolean;
  isAnimationActive?: boolean;
  isSelected?: boolean;
}

const IssuesInsightsBar: FunctionComponent<IssuesInsightsBarProps> = props => {
  const {
    title,
    content,
    isClickable,
    field,
    isSelected,
    colorBarMap = { critical: '#d92b85', high: '#e61f25', medium: '#fd7333', low: '#ffCC33' },
    defaultColor = '#8698A1',
    isFetchingInsights,
    isAnimationActive = false,
  } = props;

  console.log(content)

  function handleClick() {
    isClickable && props.handleClick(field, !isSelected);
  }

  const toggleClassNames = (isClickable: boolean, isSelected: boolean) =>
    classNames({
      'insight--clickable bo--1': isClickable,
      'bo-v--1': !isClickable,
      'insight--selected': isSelected,
    });

  if (isFetchingInsights) {
    return (
      <div className="grid__item col-1-5 flex">
        <div className="col-1-1 flex flex--flex-direction--column bo--1 border-color--muted-light p--">
          <div className="col-1-1 font--h8">{title}</div>
          <div className="col-1-1 ph flex align-items--center justify-content--center flex-grow--1">
            <i className="fas fa-spin fa-spinner font--h4 color--muted-dark" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid__item col-1-5 flex">
      <div
        className={`col-1-1 p- flex flex--flex-direction--column border-color--muted-light ${toggleClassNames(
          isClickable,
          isSelected
        )}`}
        onClick={() => handleClick()}
        data-automation-id="IssuesInsightsBar"
      >
        <div className="col-1-1">
          {title}
          {isSelected && <i className="sci sci__check--box color--success" />}
        </div>
        <div className="flex justify-content--center align-items--end flex-grow--1 bar-chart">
          <BarChart
            data={content}
            width={158}
            height={90}
            margin={{ top: 15, right: 0, left: 0, bottom: -5 }}
          >
            <XAxis dataKey="name" stroke={defaultColor} interval={0} height={30} />

            <Bar
              dataKey="count"
              barSize={20}
              isAnimationActive={isAnimationActive}
              label={{
                position: 'top',
                fill: '#000',
                fontFamily: 'Roboto Medium',
                fontSize: '10px',
              }}
            >
              {content.map((entry, index) => {
                return <Cell key={index} fill={colorBarMap[entry.id] || defaultColor} />;
              })}
            </Bar>
          </BarChart>
        </div>
      </div>
    </div>
  );
};

export default IssuesInsightsBar;
