import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as reportDetailsPageActions from '~/actions/reportDetailsPage';

interface ReportDetailsPageTabWrapperProps {
  defaultTab?: string;
  reportDetailsPageState: object;
  reportType?: string;
  reportDetailsPageActions: object;
}
class ReportDetailsPageTabWrapper extends React.Component<ReportDetailsPageTabWrapperProps, {}> {
  componentDidMount() {
    const { defaultTab, reportDetailsPageState, reportType } = this.props;
    const { [reportType]: detailsPageState } = reportDetailsPageState;
    const { tabSelected } = detailsPageState;

    //If specified, set the tab as indicated in the props
    if (defaultTab && defaultTab !== tabSelected) {
      this.props.reportDetailsPageActions.updateDetailsPageActiveTab(reportType, defaultTab);
    }
  }

  render() {
    const { children } = this.props;
    return <div className="grid grid--narrower">{children}</div>;
  }
}

function mapStateToProps(state) {
  return {
    reportDetailsPageState: state.reportDetailsPageState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportDetailsPageActions: bindActionCreators(reportDetailsPageActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetailsPageTabWrapper);
