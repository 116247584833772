import _ from 'lodash';
import { combineReducers } from 'redux';
import * as actions from '~/actions/integrationById';

const integrationByIdState = () => {
  const ticketTemplateData = (state = {}, action) => {
    switch (action.type) {
      case actions.FETCH_TICKET_TEMPLATE_SUCCESS: {
        const oldContent = _.cloneDeep(state[action.integrationId]);
        const newContent = action.response;

        if (oldContent && action.concat) {
          if (oldContent.number === newContent.numer) {
            return state;
          }
          newContent.content = [...oldContent.content, ...action.response.content];
        }

        return {
          ...state,
          [action.integrationId]: {
            ...newContent,
          },
        };
      }
      case actions.FETCH_TICKET_TEMPLATE_REQUEST:
        return {};
      default:
        return state;
    }
  };

  const ticketTemplateIsFetching = (state = {}, action) => {
    switch (action.type) {
      case actions.FETCH_TICKET_TEMPLATE_REQUEST:
        return {
          ...state,
          [action.integrationId]: true,
        };
      case actions.FETCH_TICKET_TEMPLATE_SUCCESS:
      case actions.FETCH_TICKET_TEMPLATE_FAILURE:
        return {
          ...state,
          [action.integrationId]: false,
        };
      default:
        return state;
    }
  };

  const ticketTemplateErrorMessage = (state = {}, action) => {
    switch (action.type) {
      case actions.FETCH_TICKET_TEMPLATE_FAILURE:
        return {
          ...state,
          [action.integrationId]: action.error,
        };
      case actions.FETCH_TICKET_TEMPLATE_REQUEST:
      case actions.FETCH_TICKET_TEMPLATE_SUCCESS:
        return {
          ...state,
          [action.integrationId]: null,
        };
      default:
        return state;
    }
  };

  return combineReducers({
    ticketTemplateData,
    ticketTemplateIsFetching,
    ticketTemplateErrorMessage,
  });
};

export default integrationByIdState();
