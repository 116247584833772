import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import config from '~/config';
import * as billingActions from '~/actions/billing';
import * as paymentActions from '~/actions/payment';

import SourceClearModal from '~/components/SourceClearModal';
import PaymentConfirmation from '~/containers/PaymentConfirmation';

interface StripeFormProps extends RouteComponentProps {
  billingActions: object;
  paymentActions: object;
  billingState: object;
  orgState: App.OrgState;
  paymentState: object;
}
class StripeForm extends Component<StripeFormProps, {}> {
  constructor(props) {
    super(props);
    this.stripe = Stripe(config.STRIPE_PUBLISHABLE_KEY);
    this.elements = this.stripe.elements();
    const stripeStyles = {
      base: {
        fontWeight: 300,
        fontSize: '16px',
        lineHeight: '24px',
        '::placeholder': {
          color: '#8698A1',
        },
      },
    };

    this.card = this.elements.create('card', { style: stripeStyles });

    this.card.addEventListener('change', ({ error = {}, complete }) => {
      props.paymentActions.updatePaymentStatus(error.message, complete);
    });
  }

  componentDidMount() {
    this.card.mount('#card-element');
  }
  stripe: any = null;
  elements: any = null;
  card: any = null;

  submitForm() {
    const { orgState, billingState, history } = this.props;
    const { planLevels = [] } = billingState;
    const selectedPlan = planLevels[0] || {};
    const { org } = orgState;
    const form = document.getElementById('payment-form') || {};
    const { name = {}, phone = {}, email = {} } = form;
    const extraDetails = {
      name: name.value,
      phone: phone.value,
      email: email.value,
    };

    if (!selectedPlan.id) {
      return;
    }

    this.stripe.createToken(this.card, extraDetails).then(result => {
      if (result.error) {
        this.props.paymentActions.updatePaymentError(result.error.message);
      } else {
        this.props.paymentActions
          .checkout(result, selectedPlan.id, org.id, extraDetails)
          .then(res => {
            this.showModal();
            if (res.success) {
              history.push('/org/settings/subscription');
            }
          });
      }
    });
  }

  showModal(modalType) {
    this.props.billingActions.showModal(modalType);
  }

  openModal(e, modalType) {
    e.preventDefault();
    const { paymentState } = this.props;
    const { complete } = paymentState;

    if (complete) {
      this.showModal(modalType);
    } else {
      this.props.paymentActions.updatePaymentError(
        'Please complete the payment details and fix any errors before subscribing.'
      );
    }
  }

  render() {
    const { paymentState, billingState } = this.props;
    const { errorMessage, isSubmittingPayment, complete } = paymentState;
    const { showModal } = billingState;

    return (
      <form className="grid" id="payment-form" onSubmit={e => this.openModal(e, 'PAYMENT')}>
        <div className="grid__item col-1-1">
          <div className="bo-b--1 border-color--muted-light">
            <div className="grid">
              <label className="grid__item col-1-8 mb0 flex align-items--center" htmlFor="name">
                Name
              </label>
              <div className="grid__item col-7-8">
                <input
                  type="text"
                  name="name"
                  className="col-1-1 bo--0"
                  placeholder="First Last"
                  id="name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1">
          <div className="bo-b--1 border-color--muted-light">
            <div className="grid">
              <label
                className="grid__item col-1-8 mt-- mb0 flex align-items--center"
                htmlFor="phone"
              >
                Phone
              </label>
              <div className="grid__item col-7-8 mt--">
                <input
                  type="text"
                  name="phone"
                  className="col-1-1 bo--0"
                  placeholder="(123) 456-7890"
                  id="phone"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1">
          <div className="bo-b--1 border-color--muted-light">
            <div className="grid">
              <label
                className="grid__item col-1-8 mt-- mb0 flex align-items--center"
                htmlFor="email"
              >
                Email
              </label>
              <div className="grid__item col-7-8 mt--">
                <input
                  type="email"
                  name="email"
                  className="col-1-1 bo--0"
                  placeholder="billing@example.com"
                  id="email"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <label htmlFor="card-element" className="grid__item col-1-1 mv-">
          Card or debit card
        </label>
        <div className="grid__item col-1-1">
          <div className="bo-b--1 border-color--muted-light" />
        </div>
        <div className="grid__item col-1-1 mt--">
          <div id="card-element" />
        </div>
        <div className="grid__item col-1-1">
          <div className={`mt- color--danger ${errorMessage ? 'is-showing-100' : 'is-hiding'}`}>
            {errorMessage}
          </div>
        </div>
        <div className="grid__item col-1-1 mt+">
          <button
            className={`btn--success col-1-1 font--h7 pv- ${!complete ? 'disabled' : ''}`}
            disabled={!complete}
            type="submit"
          >
            Subscribe to Plan
          </button>
        </div>
        <SourceClearModal
          isOpen={showModal === 'PAYMENT'}
          title="Confirm Subscription"
          onClose={() => this.showModal()}
          width={400}
        >
          <PaymentConfirmation
            onConfirm={() => this.submitForm()}
            showModal={modalType => this.showModal(modalType)}
            isSubmittingPayment={isSubmittingPayment}
          />
        </SourceClearModal>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    billingState: state.billingState,
    orgState: state.orgState,
    paymentState: state.paymentState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    billingActions: bindActionCreators(billingActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StripeForm));
