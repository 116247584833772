import React from 'react';

interface DownloadOptionsSwitcherProps {
  operatingSystem: string;
  activeInstallOption: string;
  toggleDownloadOption: (option: string) => void;
}

const DownloadOptionsSwitcher: React.FunctionComponent<DownloadOptionsSwitcherProps> = (
  props
): JSX.Element => {
  const toggleDownloadOption = (option: string) => {
    props.toggleDownloadOption(option);
  };

  const apk = (
    <div
      className={`mt-- bo-b--4 mr- pr- pb-- ${
        props.activeInstallOption === 'apk'
          ? ' border-color--primary color--primary active'
          : ' border-color--transparent color--muted cursor--pointer'
      }`}
      onClick={() => toggleDownloadOption('apk')}
    >
      apk
    </div>
  );

  const aptGet = (
    <div
      className={`mt-- bo-b--4 mr- pr- pb-- ${
        props.activeInstallOption === 'apt-get'
          ? ' border-color--primary color--primary active'
          : ' border-color--transparent color--muted cursor--pointer'
      }`}
      onClick={() => toggleDownloadOption('apt-get')}
    >
      apt-get
    </div>
  );

  const chocolatey = (
    <div
      key={'chocolatey'}
      className={
        'mt-- bo-b--4 mr- pr- pb-- ' +
        (props.activeInstallOption === 'chocolatey'
          ? ' border-color--primary color--primary active'
          : ' border-color--transparent color--muted cursor--pointer')
      }
      onClick={() => toggleDownloadOption('chocolatey')}
    >
      {'chocolatey'}
    </div>
  );

  const curl = (
    <div
      className={`mt-- bo-b--4 mr- pr- pb-- ${
        props.activeInstallOption === 'curl'
          ? ' border-color--primary color--primary active'
          : ' border-color--transparent color--muted cursor--pointer'
      }`}
      onClick={() => toggleDownloadOption('curl')}
    >
      curl
    </div>
  );

  const homebrew = (
    <div
      className={`mt-- bo-b--4 mr- pr- pb-- ${
        props.activeInstallOption === 'homebrew'
          ? ' border-color--primary color--primary active'
          : ' border-color--transparent color--muted cursor--pointer'
      }`}
      onClick={() => toggleDownloadOption('homebrew')}
    >
      homebrew
    </div>
  );

  const powershell = (
    <div
      key={'powershell'}
      className={
        'mt-- bo-b--4 mr- pr- pb-- ' +
        (props.activeInstallOption === 'powershell'
          ? ' border-color--primary color--primary active'
          : ' border-color--transparent color--muted cursor--pointer')
      }
      onClick={() => toggleDownloadOption('powershell')}
    >
      {'PowerShell'}
    </div>
  );

  if (props.operatingSystem === 'LINUX') {
    return (
      <>
        {aptGet}
        {apk}
        {curl}
      </>
    );
  }

  if (props.operatingSystem === 'WINDOWS') {
    return (
      <>
        {chocolatey}
        {powershell}
      </>
    );
  }

  // if it's neither LINUX or WINDOWS, then show download instructions for OS X
  return (
    <>
      {homebrew}
      {curl}
    </>
  );
};

export default DownloadOptionsSwitcher;
