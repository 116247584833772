import * as actions from '~/actions/reportSelectedRows';

const defaultState = [];

const reportSelectedRows = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_REPORT_SELECTED_ROWS: {
      const rowToRemove = state.find(row => row[action.idKey] === action.value[action.idKey]);

      if (rowToRemove) {
        return state.filter(row => row[action.idKey] !== rowToRemove[action.idKey]);
      } else if (action.value) {
        return [...state, action.value];
      }

      return state;
    }
    case actions.CLEAR_REPORT_SELECTED_ROWS:
      return defaultState;
    default:
      return state;
  }
};

export default reportSelectedRows;
