import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Helmet from 'react-helmet';
import _ from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ReportHeader from '~/components/ReportComponents/ReportHeader';
import ReportHeaderRow from '~/components/ReportComponents/ReportHeaderRow';
import ReportHeaderItem from '~/components/ReportComponents/ReportHeaderItem';
import LoaderWrapper from '~/components/LoaderWrapper';
import ReportFetchErrorMessage from '~/components/ReportFetchErrorMessage';
import CSVReportDownloader from '~/containers/CSVReportDownloader';
import InsightsWrapper from '~/components/InsightsWrapper';
import InsightNumber from '~/components/InsightNumber';
import ProjectTable from '~/views/components/ProjectTable';

import * as insightsActions from '~/actions/insights';
import * as navigationActions from '~/actions/navigation';
import * as reportActions from '~/actions/reports';
import * as reportFilterActions from '~/actions/reportFilters';
import * as sortByReportTypeActions from '~/actions/sortByReportType';
import { RootState } from '~/reducers';

const REPORT_TYPE = 'REPOSITORIES';

interface ProjectsProps extends RouteComponentProps {
  insightsByType: object;
  orgState: App.OrgState;
  reportFilterState: object;
  reports: object;
  reportsByType: object;
  sortByReportType: object;
}

class Projects extends Component<ProjectsProps & ReturnType<typeof mapDispatchToProps>> {
  constructor(props: ProjectsProps) {
    super(props);

    this.delayedRefreshReportData = _.debounce(this.refreshReportData, 300);
  }

  componentDidMount() {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    this.props.insightsActions.fetchProjectInsights(teamId);
    this.refreshReportData();
    this.props.navigationActions.updateActiveReportType(REPORT_TYPE);
  }

  componentWillUnmount() {
    this.props.navigationActions.updateActiveReportType();
  }

  refreshReportData() {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    this.props.reportActions.fetchReport(teamId, REPORT_TYPE);
  }

  updateStringFilter(field, value) {
    this.props.reportFilterActions.updateFilterValue(REPORT_TYPE, field, value);
    this.delayedRefreshReportData();
  }

  toggleBooleanFilter(field, isChecked) {
    this.props.reportFilterActions.updateFilterValue(REPORT_TYPE, field, isChecked);
    this.refreshReportData();
  }

  updateSortField(field) {
    this.props.sortActions.updateSortField(REPORT_TYPE, field);
    this.refreshReportData();
  }

  handleScannedInPastWeek(source, isSelected) {
    if (source === 'showLastWeek') {
      this.props.reportFilterActions.updateFilterValue(
        REPORT_TYPE,
        'scannedInPastWeek',
        isSelected === true || null
      );
    } else {
      this.props.reportFilterActions.updateFilterValue(
        REPORT_TYPE,
        'scannedInPastWeek',
        isSelected === true ? false : null
      );
    }
    this.refreshReportData();
  }

  render() {
    const {
      reportsByType,
      reports,
      reportFilterState,
      sortByReportType,
      match,
      insightsByType,
      orgState,
    } = this.props;
    const { org } = orgState;
    const plan = org.plan || {};
    const { repoLimit } = plan;
    const { PROJECT: projectInsights = {}, isFetchingInsights } = insightsByType;
    const { total, scannedInLastWeek, withVulnerabilities } = projectInsights;
    const { params = {} } = match;
    const { teamId } = params;
    const { isFetching, errorMessage } = reports;
    const { [REPORT_TYPE]: repoFilters = {} } = reportFilterState;
    const { vulnsOnly = false, scannedInPastWeek } = repoFilters;
    const { [REPORT_TYPE]: currentSort = {} } = sortByReportType;
    const { [REPORT_TYPE]: repoData = {} } = reportsByType;
    const { _embedded = {}, page = {} } = repoData;
    const { repos = [] } = _embedded;

    const columnWidths = {
      star: 'width--40 flex--none',
      name: 'col-5-18',
      language: 'col-1-10',
      totalIssues: 'col-1-9',
      vulnIssues: 'col-1-10',
      libraryIssues: 'col-1-9',
      licenseIssues: 'col-1-12',
      lastScanDate: '',
    };

    const reportHeader = (
      <ReportHeader
        reportType={REPORT_TYPE}
        stringFilterPlaceholder={'Search projects'}
        renderReportHeaderItems={() => (
          <div className="grid__item col-1-1 p-- flex flex--justify-content--end align-items--center height--40">
            <CSVReportDownloader
              reportType={REPORT_TYPE}
              page={page}
              noElementText="No project to download"
            />
          </div>
        )}
        renderSearchResultsMetadata={() => (
          <span>
            {page.totalElements} project
            {page.totalElements === 1 ? '' : 's'}{' '}
          </span>
        )}
        renderReportHeaderRow={() => (
          <ReportHeaderRow>
            <ReportHeaderItem label="" field="star" widthClass={columnWidths.star} />
            <ReportHeaderItem
              label="Project"
              field="name"
              widthClass={columnWidths.name}
              isSortable={true}
              onClick={field => this.updateSortField(field)}
              currentSort={currentSort}
            />
            <ReportHeaderItem
              label="Language/OS"
              field="language"
              widthClass={columnWidths.language}
            />
            <ReportHeaderItem
              label={
                <div className="text--center">
                  Total <div>Issues</div>
                </div>
              }
              field="totalIssues"
              alignment="CENTER"
              widthClass={columnWidths.totalIssues}
            />
            <ReportHeaderItem
              label={
                <div className="text--center">
                  Vulnerability <div>Issues</div>
                </div>
              }
              field="vulnIssues"
              alignment="CENTER"
              widthClass={columnWidths.vulnIssues}
            />
            <ReportHeaderItem
              label={
                <div className="text--center">
                  Outdated Library <div>Issues</div>
                </div>
              }
              field="libraryIssues"
              alignment="CENTER"
              widthClass={columnWidths.libraryIssues}
            />
            <ReportHeaderItem
              label={
                <div className="text--center">
                  License <div>Issues</div>
                </div>
              }
              field="licenseIssues"
              alignment="CENTER"
              widthClass={columnWidths.licenseIssues}
            />
            <ReportHeaderItem
              label="Last Scan"
              field="lastScanDate"
              widthClass={columnWidths.lastScanDate}
            />
          </ReportHeaderRow>
        )}
      />
    );

    if (errorMessage) {
      return (
        <div className="grid grid--center">
          {reportHeader}
          <div className="grid__item col-3-5 mt">
            <ReportFetchErrorMessage />
          </div>
        </div>
      );
    }

    return (
      <div className="grid mb+">
        <Helmet>
          <title>Projects</title>
        </Helmet>
        <div className="grid__item col-1-1">
          <div className="col-1-1 font--h6 mb--" data-automation-id="Projects-Title">
            INSIGHTS
          </div>
          <InsightsWrapper>
            <InsightNumber
              automationId="InsightNumber-showLastWeek"
              title="Scanned in Last 7 days"
              field="showLastWeek"
              content={scannedInLastWeek === null ? 'N/A' : `${scannedInLastWeek}`}
              isFetchingInsights={isFetchingInsights}
              isClickable={true}
              handleClick={(field, isChecked) => this.handleScannedInPastWeek(field, isChecked)}
              isSelected={scannedInPastWeek === true}
            />
            <InsightNumber
              automationId="InsightNumber-hideLastWeek"
              title="Not Scanned in Last 7 days"
              field="hideLastWeek"
              content={scannedInLastWeek === null ? 'N/A' : `${total - scannedInLastWeek}`}
              isFetchingInsights={isFetchingInsights}
              isClickable={true}
              handleClick={(field, isChecked) => this.handleScannedInPastWeek(field, isChecked)}
              isSelected={scannedInPastWeek === false}
            />
            <InsightNumber
              automationId="InsightNumber-vulnsOnly"
              title="With Vulnerabilities"
              field="vulnsOnly"
              content={withVulnerabilities === null ? 'N/A' : `${withVulnerabilities}`}
              isFetchingInsights={isFetchingInsights}
              isClickable={true}
              handleClick={(field, isChecked) => this.toggleBooleanFilter(field, isChecked)}
              isSelected={vulnsOnly}
            />
          </InsightsWrapper>
        </div>
        <div className="grid__item col-1-1 mt">
          <div className="flex mb-- align-items--baseline">
            <div className="font--h6" data-automation-id="Projects-Title">
              PROJECT LIST
            </div>
            {repoLimit && (
              <div className="font--h7 ml-">
                <strong>
                  (
                  {`${page.totalElements} of ${repoLimit} project${
                    repoLimit === 1 ? '' : 's'
                  } used`}
                  )
                </strong>
              </div>
            )}
          </div>
        </div>
        {reportHeader}

        <div className="grid__item col-1-1 mt--">
          <LoaderWrapper isLoaderShowing={isFetching && !page.totalPages}>
            <ProjectTable repos={repos} teamId={teamId} columnWidths={columnWidths} />
          </LoaderWrapper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    insightsByType: state.insightsByType,
    orgState: state.orgState,
    reportFilterState: state.reportFilterState,
    reports: state.reports,
    reportsByType: state.reportsByType,
    sortByReportType: state.sortByReportType,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    insightsActions: bindActionCreators(insightsActions as any, dispatch),
    navigationActions: bindActionCreators(navigationActions as any, dispatch),
    reportActions: bindActionCreators(reportActions as any, dispatch),
    reportFilterActions: bindActionCreators(reportFilterActions as any, dispatch),
    sortActions: bindActionCreators(sortByReportTypeActions as any, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Projects));
