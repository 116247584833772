import React from 'react';
import Tooltip from '~/components/Tooltip';
import lowRiskIcon from '~/images/license_risk_low.png';
import mediumRiskIcon from '~/images/license_risk_medium.png';
import highRiskIcon from '~/images/license_risk_high.png';
import unassessedRiskIcon from '~/images/license_risk_unassessed.png';

export enum Rating {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
  UNKNOWN = 'Non-OSS',
  UNRECOGNIZED = 'Unrecognized',
}

type LicenseRiskRatingsData = {
  rating: Rating;
  icon?: string;
  details: string;
};

const licenseRiskRatingsData: LicenseRiskRatingsData[] = [
  {
    rating: Rating.LOW,
    icon: lowRiskIcon,
    details:
      'Low-risk licenses are typically permissive licenses that require you to preserve the copyright and license notices, but allow distribution under different terms without disclosing source code.',
  },
  {
    rating: Rating.MEDIUM,
    icon: mediumRiskIcon,
    details:
      'Medium-risk licenses are typically weak copyleft licenses that require you to preserve the copyright and license notices, and require distributors to make the source code of the component and any modifications under the same terms. ',
  },
  {
    rating: Rating.HIGH,
    icon: highRiskIcon,
    details:
      'High-risk licenses are typically strong copyleft licenses that require you to preserve the copyright and license notices, and require distributors to make the source code of the component and any modifications under the same terms. ',
  },
  {
    rating: Rating.UNKNOWN,
    icon: unassessedRiskIcon,
    details:
      'Non-OSS indicates that this file could be subject to commercial license terms. If so, you should refer to your applicable license agreement with such vendor for additional information.',
  },
  {
    rating: Rating.UNRECOGNIZED,

    details:
      'Unrecognized indicates that no license was found for the component. However, this does not indicate that there is no risk associated with the license.',
  },
];

interface LicenseRiskIconProps {
  rating: Rating;
  id: string;
  tooltipPlace?: 'top' | 'right' | 'bottom' | 'left';
}

function LicenseRiskIcon(props: LicenseRiskIconProps) {
  const { rating, tooltipPlace = 'bottom', id = '1' } = props;
  const currentLicenseRiskRating = licenseRiskRatingsData.find(
    licenseRiskRatingItem => licenseRiskRatingItem.rating === rating
  );

  // The Unrecognized license risk does not have a dedicated icon
  // so we rely on an 'undefined' icon value to determine when we should render '--' (the Unrecognized license risk symbol)
  const licenseRiskIconToRender = !currentLicenseRiskRating.icon ? (
    <div className="ml--">--</div>
  ) : (
    <img
      src={currentLicenseRiskRating.icon}
      alt={`${currentLicenseRiskRating.rating} license risk rating`}
    />
  );

  return (
    <div className="flex align-items--center">
      <Tooltip
        maxWidthClass="max-width--300"
        place={tooltipPlace}
        content={currentLicenseRiskRating.details}
        id={`License_Risk_${currentLicenseRiskRating.rating}-tooltip-${id}`}
      >
        {licenseRiskIconToRender}
      </Tooltip>
    </div>
  );
}

export default LicenseRiskIcon;
