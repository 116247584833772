import React from 'react';
import Tooltip from '~/components/Tooltip';

interface VCNavItemWithTooltipProps {
  className?: string;
  tooltipId: string;
  place?: 'top' | 'right' | 'bottom' | 'left';
  tipSize?: number;
  tooltipText: string;
}

const VCNavItemWithTooltip: React.FunctionComponent<VCNavItemWithTooltipProps> = props => {
  const { tooltipText, tooltipId, tipSize = 5, className = '', place = 'bottom' } = props;
  return (
    <Tooltip
      content={tooltipText}
      place={place}
      className={className}
      id={tooltipId}
      tipSize={tipSize}
      isVCPopover={true}
    >
      {props.children}
    </Tooltip>
  );
};
export default VCNavItemWithTooltip;
