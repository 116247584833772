import React from 'react';
import * as d3 from 'd3';
import ColorHelper from '~/utils/ColorHelper';
import SunburstHelper from '~/utils/SunburstHelper';
import _ from 'lodash';

interface SunburstChartProps {
  /**
    data = [
      ["A", "B", "C", "D"],
      ["A', "B", "E"]
    ];
    **/
  data: Array<string>;
  /**
     Use this if you only want certain path to get colored.
     eg. if onlyColorIfPathContains = C. only A, B and C slice will be colored.
   **/
  onlyColorIfPathContains?: string;
  unselectedColor?: string;
  onPathSelected: (...args: any[]) => any;
  selectedPath: any[];
  width: number;
  height: number;
  innerCircleSize?: number;
  unselectedPathOpacity?: number;
  strokeColor?: string;
}

class SunburstChart extends React.Component<SunburstChartProps, {}> {
  shouldComponentUpdate(nextProps) {
    return !(
      _.isEqual(this.props.data, nextProps.data) &&
      _.isEqual(this.props.onlyColorIfPathContains, nextProps.onlyColorIfPathContains) &&
      _.isEqual(this.props.unselectedColor, nextProps.unselectedColor) &&
      _.isEqual(this.props.selectedPath, nextProps.selectedPath) &&
      _.isEqual(this.props.width, nextProps.width) &&
      _.isEqual(this.props.unselectedPathOpacity, nextProps.unselectedPathOpacity) &&
      _.isEqual(this.props.height, nextProps.height)
    );
  }

  onMouseOver = ev => {
    const parents = ev.target.getAttribute('data-parent').split('...');
    this.props.onPathSelected(parents);
  };

  onMouseLeave = () => {
    //this.props.onPathSelected([]);
  };

  isNodeSelected = node => {
    // Base case: at parent node
    if (node.depth === 0) {
      return true;
    }

    if (this.props.selectedPath[node.depth - 1] !== node.name) {
      return false;
    } else {
      return this.isNodeSelected(node.parent);
    }
  };

  isNodeInOnlyColorIfPathContains = node => {
    const { onlyColorIfPathContains = '' } = this.props;
    const sequenceArray = SunburstHelper.getChildren(node);
    return (
      node.name === onlyColorIfPathContains ||
      sequenceArray.filter(node => node.name === onlyColorIfPathContains).length > 0
    );
  };

  getStroke = node => {
    const { onlyColorIfPathContains, strokeColor = '#909090' } = this.props;
    if (!onlyColorIfPathContains) {
      //no stroke needed if there's color for all node
      return '';
    } else {
      return this.isNodeInOnlyColorIfPathContains(node) ? '' : strokeColor;
    }
  };

  getOpacity = node => {
    const { unselectedPathOpacity = 0.3 } = this.props;
    const opacity = this.isNodeSelected(node) ? 1 : unselectedPathOpacity;
    return opacity;
  };

  getFill = node => {
    const { onlyColorIfPathContains, unselectedColor = '#808080' } = this.props;

    // Give color by default unless path is set
    if (!onlyColorIfPathContains) {
      return ColorHelper.getBrightColor(node.name);
    } else if (onlyColorIfPathContains === 'unmapped_nuget') {
      // In case this is a Nuget library that we cannot map to a DLL, as there
      // could potentially be many of them, we use unselected color for
      // consistent look of the sunburst chart.
      return unselectedColor;
    } else {
      return this.isNodeInOnlyColorIfPathContains(node)
        ? ColorHelper.getBrightColor(node.name)
        : unselectedColor;
    }
  };

  render() {
    const {
      data,
      width,
      height,
      innerCircleSize = 35,
      onlyColorIfPathContains = '',
      unselectedColor = '#808080',
    } = this.props;
    const radius = Math.min(width, height) / 2 + innerCircleSize;

    const formattedData = SunburstHelper.buildHierarchy(data, onlyColorIfPathContains);

    // @ts-ignore error TS2339: Property 'layout' does not exist on type 'typeof import("/Users/jpoh/development/srcclr/platform-frontend/node_modules/@types/d3/index")'. Maybe fixed in newer version of d3
    const partition = d3.layout
      .partition()
      .size([2 * Math.PI, radius * radius])
      .value(d => d.size);

    const arc = d3.svg
      .arc()
      .startAngle(d => d.x)
      .endAngle(d => d.x + d.dx)
      .innerRadius(d => Math.sqrt(d.y) - innerCircleSize)
      .outerRadius(d => Math.sqrt(d.y + d.dy) - innerCircleSize);

    const nodes = partition.nodes(formattedData);
    // commented the below as there's a chance there's too many libraries and we will have a huge empty space.
    //.filter((d) => d.dx > 0.0005);

    let hasTransitiveColoredNode = false;
    let totalColoredSlice = 0;
    const paths = nodes.map((node, index) => {
      const fillColor = this.getFill(node);
      if (node.depth !== 0 && fillColor !== unselectedColor) {
        totalColoredSlice++;
        hasTransitiveColoredNode = node.depth > 1 ? true : hasTransitiveColoredNode;
      }
      return (
        <path
          key={index}
          display={node.depth ? null : 'none'}
          d={arc(node)}
          stroke={this.getStroke(node)}
          fillRule="evenodd"
          fill={fillColor}
          opacity={this.getOpacity(node)}
          data-parent={SunburstHelper.getAncestorsIncludeSelf(node)
            .map(sequence => sequence.name)
            .join('...')}
        />
      );
    });

    let description =
      onlyColorIfPathContains === '' ? null : (
        <div className="pb-">
          {' '}
          Colored
          {totalColoredSlice <= 1 && <span> area is </span>}
          {totalColoredSlice > 1 && <span> areas are </span>}
          <span className="text--bold"> {onlyColorIfPathContains} </span>
          {hasTransitiveColoredNode && (
            <span>
              or transitive dependencies to
              <span className="text--bold pl--">{onlyColorIfPathContains} </span>
            </span>
          )}
        </div>
      );

    return (
      <div>
        {description}
        <div>
          <svg width={width} height={height}>
            <g
              transform={`translate(${width / 2},${height / 2})`}
              onMouseOver={ev => this.onMouseOver(ev)}
              onMouseLeave={() => this.onMouseLeave()}
            >
              {paths}
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

export default SunburstChart;
