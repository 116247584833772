import React from 'react';

function AcquisitionBanner() {
  return (
    <section className="container--full color--white bg-color--black-dark zIndex-7--nav bg-color--ca-blue">
      <section className="container pv flex flex--justify-content--space-between flex--align-items--center phone--flex--flex-direction--column">
        <div className="font-family--arial phone--col-1-1">
          <h5 className="m0 font-family--arial font--22">
            Thoma Bravo completes acquisition of Veracode, creating independent AppSec company.
          </h5>
          <div className="font--14">SourceClear has joined Veracode</div>
        </div>
        <div className="phone--col-1-1 phone--mt">
          <a
            href="https://www.veracode.com/thoma-bravo-completes-acquisition-veracode-software"
            target="_blank"
            className="color--white bo--1 border-color--white ph pv- btn--light color--white-hover border-color--white font-family--arial"
          >
            LEARN MORE
          </a>
        </div>
      </section>
    </section>
  );
}

export default AcquisitionBanner;
