import React from 'react';

interface ToastrProps {
  options: object;
  removeToastr?: (...args: any[]) => any;
}

const Toastr: React.FunctionComponent<ToastrProps> = props => {
  const { options } = props;
  const {
    message,
    level,
    title,
    callback,
    callbackText,
    hideDefaultIcon,
    disableRemoveToastr,
  } = options;
  const iconMap = {
    error: 'sci__vulnerability--new',
    warning: 'sci__alerts',
    info: 'sci__detail-information',
    success: 'sci__circle--check',
  };

  return (
    <div className={`toastr toastr--${level}`}>
      <div
        className={`flex flex--justify-content--space-between align-items--center pv-- ph- toastr--bg-color--${level}`}
      >
        <div className="flex align-items--center">
          {!hideDefaultIcon && <i className={`sci mr-- ${iconMap[level]} font--h5`} />}
          <div className="font--h7 text--bold pr-">{title}</div>
        </div>
        {!disableRemoveToastr && (
          <i
            className="sci sci__close cursor--pointer"
            onClick={() => props.removeToastr(options)}
          />
        )}
      </div>
      <div className="pv-- ph-">
        {message}
        {callback && callbackText && (
          <div className="col-1-1 mt- text--right">
            <button className={`btn--default`} onClick={callback}>
              {callbackText}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Toastr;
