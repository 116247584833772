import React from 'react';

interface OptionsListProps {
  field: string;
  onChange: (...args: any[]) => any;
  value?: string;
  className?: string;
  activeOptions?: any[];
  activeOption?: string;
  type?: string;
  options?: any[];
  disabled?: boolean;
  name?: string;
}

const OptionsList: React.FunctionComponent<OptionsListProps> = props => {
  const {
    options = [],
    activeOptions = [],
    activeOption = '',
    className = '',
    type = 'checkbox',
    disabled = false,
    name = '',
  } = props;

  function onChange(e) {
    const { field } = props;
    const { target = {} } = e;
    const { value = '' } = target;

    let res = value;

    if (type === 'checkbox') {
      res = activeOptions.includes(value)
        ? activeOptions.filter(e => e !== value)
        : [...new Set([...activeOptions, value])]; // We use Set to dedupe
    }

    props.onChange(field, res);
  }

  const optionsHtml = options.map(option => {
    const { value, label } = option;
    const isActive = type === 'checkbox' ? activeOptions.includes(value) : activeOption === value;

    return (
      <div key={value}>
        <label className="label--checkbox flex pv-- mb0">
          <div className="flex flex--align-items--center">
            <input
              name={name}
              type={type}
              className="flex"
              onChange={onChange}
              value={value}
              checked={isActive}
              disabled={disabled}
            />
            <span className={`react--${type}`} />
          </div>
          <div className="pl---">{label}</div>
        </label>
      </div>
    );
  });
  return <div className={className}>{optionsHtml}</div>;
};

export default OptionsList;
