import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { Link, RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';

import Helpers from '~/utils/Helpers';
import TextInput from '~/components/TextInput';
import OptionsList from '~/components/OptionsList';
import * as workspaceNotificationsSettingsActions from '~/actions/workspaceNotificationsSettings';
import {
  NOTIFICATION_TYPES,
  PLAN_TYPE,
  REPO_EVENTS,
  TRIGGER_EVENTS_OPTIONS,
} from '~/constants/ModelConstants';
import OrgPaidStatus from '~/utils/OrgPaidStatus';

const NOTIFICATION_TYPE = NOTIFICATION_TYPES.WEBHOOK;
const { JUST_SCAN_EVENT, ALL_EVENTS, INDIVIDUAL_EVENTS } = TRIGGER_EVENTS_OPTIONS;

interface WebhookNotificationsManageSectionProps extends RouteComponentProps {
  orgState: App.OrgState;
  teamState: object;
  workspaceNotificationsSettingsState: object;
  workspaceNotificationsSettingsActions: object;
}

class WebhookNotificationsManageSection extends React.Component<
  WebhookNotificationsManageSectionProps,
  {}
> {
  componentDidMount() {
    this.setDefaults();
  }

  componentWillUnmount() {
    this.resetControls();
  }

  setDefaults() {
    this.props.workspaceNotificationsSettingsActions.updateWorkspaceNotificationsSettingsFieldValue(
      NOTIFICATION_TYPE,
      'contentType',
      'APPLICATION_JSON'
    );
  }

  saveSettings = webhookId => {
    const { history, match, orgState } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    const { org = {} } = orgState;
    const isOrgEnterprise = OrgPaidStatus.getOrgPlanState(org) === PLAN_TYPE.ENTERPRISE;

    if (!isOrgEnterprise) {
      this.props.workspaceNotificationsSettingsActions.updateWorkspaceNotificationsSettingsFieldValue(
        NOTIFICATION_TYPE,
        'triggerEventsOption',
        JUST_SCAN_EVENT
      );
    }
    this.props.workspaceNotificationsSettingsActions
      .saveWebhookWorkspaceNotificationsSettings(teamId, webhookId)
      .then(res => {
        const { success } = res;
        if (success) {
          history.push(`/workspaces/${teamId}/notifications`);
        }
      });
  };

  updateFormInput = (fieldType, value) => {
    this.props.workspaceNotificationsSettingsActions.updateWorkspaceNotificationsSettingsFieldValue(
      NOTIFICATION_TYPE,
      fieldType,
      value
    );
  };

  updateMultipleInputField = (fieldType, values) => {
    this.props.workspaceNotificationsSettingsActions.updateWorkspaceNotificationsSettingsFieldValue(
      NOTIFICATION_TYPE,
      fieldType,
      values
    );
  };

  resetControls = () => {
    this.props.workspaceNotificationsSettingsActions.resetWorkspaceNotificationsSettingsControls(
      NOTIFICATION_TYPE
    );
  };

  cancel = () => {
    const { history, match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    history.push(`/workspaces/${teamId}/notifications`);
  };

  render() {
    const { workspaceNotificationsSettingsState, orgState, teamState, match } = this.props;
    const {
      [NOTIFICATION_TYPE]: webhookNotificationSettings = {},
      isSaving = false,
    } = workspaceNotificationsSettingsState;
    const { controls = {} } = webhookNotificationSettings;
    const {
      individualEvents = [],
      triggerEventsOption = '',
      contentType,
      url = '',
      id: webhookId,
    } = controls;
    const buttonText = !webhookId ? 'Create' : 'Save';
    const { org } = orgState;
    const isOrgEnterprise = OrgPaidStatus.getOrgPlanState(org) === PLAN_TYPE.ENTERPRISE;

    const contentTypeOptions = [
      {
        value: 'APPLICATION_FORM_URLENCODED',
        label: 'application/x-www-form-urlencoded',
      },
      { value: 'APPLICATION_JSON', label: 'application/json' },
    ];

    const triggerEventsOptions = [{ value: JUST_SCAN_EVENT, label: 'Just the scan event.' }];

    const enterpriseTriggerEventsOptions = [
      { value: ALL_EVENTS, label: 'Send me everything.' },
      { value: INDIVIDUAL_EVENTS, label: 'Let me select individual events.' },
    ];

    const paywalledTriggerEventsOptions = isOrgEnterprise
      ? [...triggerEventsOptions, ...enterpriseTriggerEventsOptions]
      : triggerEventsOptions;

    const individualEventsOptions = [
      { value: REPO_EVENTS.SCAN_SUCCESS, label: 'Scan' },
      {
        value: REPO_EVENTS.VULN_ISSUES_DISCOVERED_AFTER_SCAN,
        label: 'Vulnerability issues discovered in project library after a scan',
      },
      {
        value: REPO_EVENTS.VULN_ISSUES_CHANGED_AFTER_SCAN,
        label: 'Vulnerability issues changed in project library after a scan',
      },
    ];

    // Events field is valid when a trigger event is selected and individual events is/are specified when Invidual Events option is selected
    const triggerEvent =
      triggerEventsOption &&
      (triggerEventsOption !== TRIGGER_EVENTS_OPTIONS.INDIVIDUAL_EVENTS ||
        (triggerEventsOption === TRIGGER_EVENTS_OPTIONS.INDIVIDUAL_EVENTS &&
          individualEvents &&
          individualEvents.length > 0));
    const isEventsFieldValid = this.isOrgEnterprise ? triggerEvent : true;

    const isFormValid = !!url && isEventsFieldValid;

    // Conditional hide form control
    const isContentTypeFieldShowing = false;

    const { params = {} } = match;
    const { teamId } = params;
    const { teams = [] } = teamState;

    const canManageAgents = Helpers.hasManageAgentPermissions({
      teams,
      teamId,
    });
    return (
      <div className="grid mt mb++">
        <Helmet>
          <title>Webhook Notification</title>
        </Helmet>
        <div className="grid__item col-1-1 mb">
          <h3> {webhookId ? `Update` : `Create New`} Webhook Notification</h3>
        </div>
        <div className="grid__item">
          <p>
            We'll send a POST request to the URL below with the details of any subscribed events.
            More information can be found in our developer documentation.
          </p>
          <div className="grid">
            <label
              className="grid__item col-1-5 mb- flex align-items--center"
              htmlFor="payload_url"
            >
              Payload URL
            </label>
            <div className="grid__item col-4-5 mb-">
              <TextInput
                type="text"
                name="payload_url"
                className="col-1-1"
                placeholder="http://example.com/postreceive"
                field="url"
                value={url}
                onChange={this.updateFormInput}
                disabled={!canManageAgents}
              />
            </div>
            {isContentTypeFieldShowing && (
              <div>
                <label
                  className="grid__item col-1-5 mb- flex align-items--center"
                  htmlFor="webhook_content_type"
                >
                  Content Type
                </label>
                <div className="grid__item col-4-5 mb-">
                  <Select
                    name={`webhook_content_type`}
                    value={contentTypeOptions.find(option => option.value === contentType)}
                    options={contentTypeOptions}
                    onChange={val => this.updateFormInput('contentType', val)}
                    isClearable={false}
                    className={'srcclr-react-select-container'}
                    classNamePrefix={'srcclr-react-select'}
                  />
                </div>
              </div>
            )}
            <label className="grid__item col-1-5 mb- flex pv--" htmlFor="payload_url">
              Trigger Events
            </label>
            <div className="grid__item col-4-5 mb-">
              {isOrgEnterprise ? (
                <React.Fragment>
                  <OptionsList
                    type="radio"
                    activeOption={triggerEventsOption}
                    field="triggerEventsOption"
                    options={paywalledTriggerEventsOptions}
                    onChange={this.updateFormInput}
                    className="col-1-1"
                    name="trigger_events"
                  />
                  <div className="pl">
                    <OptionsList
                      activeOptions={individualEvents}
                      field="individualEvents"
                      options={individualEventsOptions}
                      onChange={this.updateMultipleInputField}
                      className="col-1-1"
                      disabled={triggerEventsOption !== INDIVIDUAL_EVENTS}
                      name="individual_events"
                    />
                  </div>
                </React.Fragment>
              ) : (
                <div className="pt--">
                  Just the scan event.
                  <div className="mt- p- bo-rad--3 bo-l--1 border-color--white-dark bg-color--white-light">
                    Webhook notifications will be triggered by the scan event. For more event
                    options such as discovering new vulnerabilities after a scan,{' '}
                    <Link to="/org/settings/subscription" className="link--obvious">
                      upgrade to an Enterprise plan
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="grid__item col-1-1- mb- text--right">
              <button className="btn pv- ph mr--" onClick={this.cancel}>
                Cancel
              </button>
              <button
                className="btn btn--success pv- ph"
                onClick={() => this.saveSettings(webhookId)}
                disabled={isSaving || !isFormValid || !canManageAgents}
              >
                {isSaving ? (
                  <i className="fas fa-spin fa-spinner font--h5 color--muted-dark" />
                ) : (
                  buttonText
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    workspaceNotificationsSettingsState: state.workspaceNotificationsSettingsState,
    orgState: state.orgState,
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    workspaceNotificationsSettingsActions: bindActionCreators(
      workspaceNotificationsSettingsActions,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WebhookNotificationsManageSection);
