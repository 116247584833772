import React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import config from '~/config';
import { ENVIRONMENT_MAP } from '~/constants/ModelConstants';
import DownloadOptions from '~/components/DownloadOptions';
import ScanTips from '~/components/ScanTips';
import CopyToClipboard from '~/containers/CopyToClipboard';
import ScanCLI from '~/components/ScanCLI';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AgentState } from '~/reducers/agentState.types';
import Helpers from '~/utils/Helpers';

interface InstallStepsCLIProps extends RouteComponentProps {
  agentState: AgentState;
  messageState?: object;
  teamId?: string;
  integrationType: string;
  scanTip?: string;
  teams?: any[];
  toggleDownloadOption?: (...args: any[]) => any;
  fetchWorkspaceStats?: (...args: any[]) => any;
  updateSelectedWorkspace?: (...args: any[]) => any;
  updateScanTips?: (...args: any[]) => any;
  showDownloadOptions?: boolean;
  welcomeFlow?: boolean;
}

const InstallStepsCLI: React.FunctionComponent<InstallStepsCLIProps> = props => {
  const {
    history,
    integrationType,
    agentState,
    teamId,
    scanTip,
    welcomeFlow,
    teams = [],
    showDownloadOptions = true,
  } = props;

  const {
    activeInstallOption,
    agentInSetup = {},
    tokenObj = {},
    operatingSystem = '',
    selectedWorkspace = {},
  } = agentState;

  let agentId = '';

  if (!_.isEmpty(agentState.activeAgent)) {
    agentId = agentState.activeAgent.id.toString();
  } else if (tokenObj) {
    agentId = tokenObj.agentId;
  }

  const agentStatus = agentInSetup[agentId] || '';
  const resultsAvailable = agentStatus === 'RESULTS_AVAILABLE';
  const isCLI = integrationType === 'cli' || activeInstallOption === 'chocolatey'; // Windows' chocolatey option is of CLI type

  const teamOptions = teams.map(team => {
    return { value: team.id, label: team.name };
  });
  const activateTrack = {
    event: 'Activate Click',
    options: {
      integrationType: integrationType.toUpperCase(),
      item: 1,
    },
  };
  const tokenTrack = {
    event: 'Activate Click',
    options: {
      integrationType: integrationType.toUpperCase(),
      item: 2,
    },
  };

  // ONPREM-81: Agent activate will support --api flag, and by default if
  // no --api is set, it will be the prod's url.
   const activateCommand =
     config.ENVIRONMENT === ENVIRONMENT_MAP.PROD
       ? `srcclr activate ${Helpers.getRegionActivateCommand(config.REGION)}`
       : `srcclr activate --api ${config.API_URL}`;
      
  function goToResults() {
    props.fetchWorkspaceStats();
    history.push(`/workspaces/${teamId}/issues`);
  }

  function updateSelectedWorkspace(workspace) {
    workspace && props.updateSelectedWorkspace(workspace);
  }

  function updateScanTips(tip) {
    props.updateScanTips(tip);
  }

  return (
    <div>
      {showDownloadOptions && (
        <DownloadOptions
          operatingSystem={operatingSystem}
          activeInstallOption={activeInstallOption}
          toggleDownloadOption={props.toggleDownloadOption}
          integrationType={integrationType}
        />
      )}
      <div>
        <div className="mt-">Run this command in your terminal:</div>
        <div className="mt--">
          <CopyToClipboard
            value={activateCommand}
            id={'srcclrActivate' + integrationType}
            trackOptions={activateTrack}
          />
        </div>
        <div className="mt-">When prompted, enter this activation token:</div>
        <div className="mt--">
          <CopyToClipboard
            value={tokenObj.token || ''}
            id={'activateToken' + integrationType}
            placeholder="Loading activation token..."
            trackOptions={tokenTrack}
          />
        </div>
      </div>
      {!welcomeFlow &&
        isCLI &&
        (teamId ? (
          <ScanCLI />
        ) : (
          <div className="mt-">
            <div className="col-11-12">
              To accurately identify libraries and vulnerabilities in your project, the scanner
              needs to build your code. Make sure your build environment is configured and that your
              project can build.
            </div>
            <div className="flex col-11-12 mt-">
              <div className="flex align-items--center">
                Select the workspace that should receive the scan:
              </div>
              <div className="flex-1 ml-">
                <Select
                  name={`workspace-selector`}
                  className="policy col-1-1 srcclr-react-select-container"
                  classNamePrefix={'srcclr-react-select'}
                  value={teamOptions.find(option => option.value === selectedWorkspace)}
                  options={teamOptions}
                  onChange={workspace => updateSelectedWorkspace(workspace)}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="mt-">
              In a terminal, navigate to your project's source code directory and enter this
              command:
            </div>
            <div className="mt--">
              <CopyToClipboard
                id={`srcclr-scan-workspace`}
                value={`srcclr scan --ws=${selectedWorkspace.value || 'default'}`}
              />
            </div>
          </div>
        ))}
      {!welcomeFlow && (
        <div className={'grid grid--middle col-1-1 mt-'}>
          <div className="grid__item col-1-1">
            <ScanTips scanTip={scanTip} updateScanTips={tip => updateScanTips(tip)} />
          </div>
          {teamId ? (
            <div className="grid__item col-1-2 mt">
              <button
                className={
                  'pv-- col-1-1 font--h6' +
                  (resultsAvailable ? ' btn--success' : ' btn--success-clear disabled')
                }
                onClick={resultsAvailable ? () => goToResults() : null}
                title={resultsAvailable ? '' : 'Scan repos in order to view results'}
              >
                View Results
              </button>
            </div>
          ) : (
            <div className="grid__item col-1-1 mt">
              <strong className="font--h7">
                To view results, visit the URL shown in your terminal at the end of your scan.
              </strong>
            </div>
          )}
          <div className="grid__item col-1-2 mt font--h7">
            For detailed instructions{' '}
            <a
              className="link--obvious"
              href="https://help.veracode.com/go/t_sc_cli_agent"
              target="_blank"
            >
              visit the docs
            </a>
            .
          </div>
        </div>
      )}
    </div>
  );
};
export default withRouter(InstallStepsCLI);
