import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import * as toastrActions from '~/actions/toastr';

export const FETCH_API_KEY_REQUEST = 'FETCH_API_KEY_REQUEST';
export const SET_API_KEY_CREDENTIALS = 'SET_API_KEY_CREDENTIALS';
export const FETCH_API_KEY_CREDENTIALS_FAILURE = 'FETCH_API_KEY_CREDENTIALS_FAILURE';

export const GENERATE_NEW_CREDENTIALS_REQUEST = 'GENERATE_NEW_CREDENTIALS_REQUEST';
export const GENERATE_NEW_CREDENTIALS_SET = 'GENERATE_NEW_CREDENTIALS_SET';
export const GENERATE_NEW_CREDENTIALS_FAILURE = 'GENERATE_NEW_CREDENTIALS_FAILURE';

export const REVOKE_CREDENTIALS_REQUEST = 'REVOKE_CREDENTIALS_REQUEST';
export const REVOKE_CREDENTIALS_SET = 'REVOKE_CREDENTIALS_SET';
export const REVOKE_CREDENTIALS_FAILURE = 'REVOKE_CREDENTIALS_FAILURE';

export const apiCredentialsKeyRequest = type => ({
  type: type,
});

export const setApiKeyCredentials = (type, apiKeyCredentials) => ({
  type: type,
  apiKeyCredentials,
});

export const apiKeyCredentialsFailure = (type, err) => ({
  type: type,
  message: err.message,
});

export const fetchApiCredentials = () => dispatch => {
  dispatch(apiCredentialsKeyRequest(FETCH_API_KEY_REQUEST));
  return ApiService.get('/me/hmac')
    .then(res => dispatch(setApiKeyCredentials(SET_API_KEY_CREDENTIALS, res)))
    .catch(error => {
      dispatch(
        toastrActions.addToastr({
          id: 'FETCH_API_KEY_CREDENTIALS_ERROR',
          level: 'error',
          title: 'Error fetching api credentials',
          message:
            error.message ||
            'We ran into an error fetching your api credentials. Please try again.',
          disableTimeout: true,
        })
      );
      dispatch(apiKeyCredentialsFailure(FETCH_API_KEY_CREDENTIALS_FAILURE, error));
      ErrorService.capture('Error fetching api credentials', error);
    });
};

export const updateApiCredentials = () => dispatch => {
  dispatch(apiCredentialsKeyRequest(GENERATE_NEW_CREDENTIALS_REQUEST));
  return ApiService.post('/me/hmac')
    .then(res => dispatch(setApiKeyCredentials(GENERATE_NEW_CREDENTIALS_SET, res)))
    .catch(error => {
      dispatch(
        toastrActions.addToastr({
          id: 'GENERATE_NEW_CREDENTIALS_FAILURE',
          level: 'error',
          title: 'Error generating new api credentials',
          message:
            error.message ||
            'We ran into an error generating your api credentials. Please try again.',
          disableTimeout: true,
        })
      );
      dispatch(apiKeyCredentialsFailure(GENERATE_NEW_CREDENTIALS_FAILURE, error));
      ErrorService.capture('Error generating api credentials', error);
    });
};

export const revokeApiCredentials = () => dispatch => {
  dispatch(apiCredentialsKeyRequest(REVOKE_CREDENTIALS_REQUEST));
  return ApiService.del('/me/hmac')
    .then(() => dispatch(setApiKeyCredentials(REVOKE_CREDENTIALS_SET)))
    .catch(error => {
      dispatch(
        toastrActions.addToastr({
          id: 'REVOKE_CREDENTIALS_FAILURE',
          level: 'error',
          title: 'Error revoking your api credentials',
          message:
            error.message ||
            'We ran into an error while revoking your api credentials. Please try again.',
          disableTimeout: true,
        })
      );
      dispatch(apiKeyCredentialsFailure(REVOKE_CREDENTIALS_FAILURE, error));
      ErrorService.capture('Error deleting api credentials', error);
    });
};
