import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';

interface PyPIProps {
  packageInfo: object;
  filePath: string;
}

const PyPI: React.FunctionComponent<PyPIProps> = props => {
  const { name, version } = props.packageInfo;
  const { filePath = 'requirements.txt' } = props;

  //split package.json#L5
  const splittedSelectedFilePath = filePath.split('#L');
  const file = splittedSelectedFilePath[0];
  const lineNumber = splittedSelectedFilePath[1] || '';

  return (
    <div>
      <div className="grid bo--1 border-color--white-dark mt lh+">
        <CodeDiffHeader text={file} />
        <CodeDiffLine
          type="highlight"
          lineNumber={lineNumber}
          text={name + ' \u003D\u003D ' + version}
        />
      </div>
      <div className="pt">
        <span className="text--bold"> {name} </span> may have been declared as a range instead of{' '}
        <span className="text--bold"> {version} </span> in your{' '}
        <span className="text--bold"> {file} </span>
      </div>
    </div>
  );
};

export default PyPI;
