import React, { StrictMode } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import App from '~/containers/App';
import ErrorBoundary from '~/components/ErrorBoundary';
import { Store } from 'redux';
import { History } from 'history';

interface RootProps {
  store: Store;
  history: History;
}

const Root: React.FunctionComponent<RootProps> = props => {
  const { store, history } = props;
  const redirectMessage = 'Try Again';
  const redirectUrl = '/';

  return (
    <StrictMode>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ErrorBoundary redirectMessage={redirectMessage} redirectUrl={redirectUrl}>
            <App />
          </ErrorBoundary>
        </ConnectedRouter>
      </Provider>
    </StrictMode>
  );
};

export default Root;
