import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Select from 'react-select';

import SourceClearModal from '~/components/SourceClearModal';
import SourceClearLoader from '~/components/SourceClearLoader';

import * as modalActions from '~/actions/modal';
import * as reportIssuesActions from '~/actions/reportIssues';
import * as reportSelectedRowsActions from '~/actions/reportSelectedRows';
import * as MODEL from '~/constants/ModelConstants';
import JiraHelper from '~/utils/JiraHelper';

import JiraCloudCreateTicket from '~/containers/JiraCloudCreateTicket';

const { JIRA_CLOUD, JIRA_ONPREM, JIRA_LEGACY, GITHUB } = MODEL.INTEGRATION_NAME_MAP;

import { CreateIssueModalProps } from '~/containers/CreateIssueModal.types';

class CreateIssueModal extends React.Component<CreateIssueModalProps> {
  componentDidMount() {
    this.checkIfIssueCreated();
  }

  componentWillUnmount() {
    this.closeModal();
  }

  closeModal = () => {
    const viewType = this.getModalType();
    if (viewType !== '') {
      this.props.modalActions.closeModal(viewType);
    }
  };

  updateActiveProject = project => {
    this.props.reportIssuesActions.updateReportActiveJiraProject(project);
    this.props.reportIssuesActions.buildJiraUrlForProject();
    this.props.reportIssuesActions.buildGitHubUrlForRepo();
  };

  getTeamId = () => {
    return this.props.navigationState.activeTeamParent || '';
  };

  getCreateIssueData = () => {
    const { teamState, reportIssuesState, selectedIssue } = this.props;
    const { issueSettingsDataByTeamId } = teamState;
    const teamId = this.getTeamId();
    const issueSettingsData = issueSettingsDataByTeamId[teamId] || {};

    const {
      isFixInfoFetching,
      jiraFixUrl = '',
      githubFixUrl = '',
      activeJiraProject: activeProject,
    } = reportIssuesState;
    const { projects = [], disableGithubIssues } = issueSettingsData;

    let activeJiraProject = {};

    if (!_.isEmpty(activeProject)) {
      activeJiraProject = activeProject;
    } else if (issueSettingsData.projects && issueSettingsData.projects.length === 1) {
      activeJiraProject = issueSettingsData.projects[0];
    }

    const createIssueData = {
      projects,
      disableGithubIssues,
      jiraFixUrl,
      githubFixUrl,
      activeJiraProject,
      isFixInfoFetching,
      issue: selectedIssue,
      teamId,
    };

    return createIssueData;
  };

  createIssueLinkClicked(integrationType) {
    const { reportIssuesActions, orgState, selectedIssue } = this.props;
    const { org } = orgState;
    reportIssuesActions.createIssueButtonClickedSnowplow(org.id, integrationType, selectedIssue);
    this.closeModal();
  }

  checkIfIssueCreated = () => {
    const { selectedIssue } = this.props;
    const { id, lastScan } = selectedIssue;

    let scanId;
    if (lastScan) {
      scanId = lastScan.id;
    }

    if (!_.isEmpty(selectedIssue)) {
      this.props.reportIssuesActions.getIntegrationIssuesCreated(id, scanId);
    }
  };

  getModalType = () => {
    const { modalState = {} } = this.props;
    const { openedModal = {} } = modalState;

    if (openedModal[MODEL.CREATE_ISSUE_JIRA_CLOUD_MODAL]) {
      return MODEL.CREATE_ISSUE_JIRA_CLOUD_MODAL;
    }

    if (openedModal[MODEL.CREATE_ISSUE_JIRA_ENTERPRISE_MODAL]) {
      return MODEL.CREATE_ISSUE_JIRA_ENTERPRISE_MODAL;
    }

    if (openedModal[MODEL.CREATE_ISSUE_GITHUB_MODAL]) {
      return MODEL.CREATE_ISSUE_GITHUB_MODAL;
    }

    return '';
  };

  render() {
    const { selectedIssue, orgState } = this.props;
    const { org } = orgState;
    const { permissions } = org;
    const { integrations: integrationsPermission } = permissions;
    const viewType = this.getModalType();

    const {
      projects,
      disableGithubIssues,
      jiraFixUrl = '',
      githubFixUrl = '',
      activeJiraProject,
      isFixInfoFetching,
    } = this.getCreateIssueData();

    const showGithub = !disableGithubIssues && !!githubFixUrl;

    /*
     * Checking the state of the issue, if there's any jira issue created
     */
    const { reportIssuesState } = this.props;
    const { integrations = {} } = reportIssuesState;
    const { [selectedIssue.id]: fetchedIssue = {} } = integrations;
    const { isFetching: isCheckingIfIssueCreated = false, data: linkedIssues = [] } = fetchedIssue;
    const linkedJira = linkedIssues.filter(res => res.issueType === 'JIRA');

    const projectList = projects
      .filter(project => project.projectId)
      .map(project => {
        return {
          label: project.projectName,
          value: project,
        };
      });

    const jiraSectionHtml = (
      <div className="grid pb">
        <div className="grid__item col-1-1 mb">
          We will provide a link to create a Jira issue in your own Jira installation.
          {projectList.length === 0 && (
            <div className="color--danger">
              {integrationsPermission
                ? `You have not set up ${JIRA_LEGACY} integration for this workspace.`
                : `Please contact your administrator to enable ${JIRA_LEGACY} integration for this workspace.`}
            </div>
          )}
        </div>
        <div className="grid__item col-1-5 pt-">Jira Project</div>
        <div className="grid__item col-4-5">
          <Select
            isClearable={false}
            name="jira-project-filter"
            value={projectList.find(option => option.value === activeJiraProject.projectName)}
            options={projectList}
            onChange={field => this.updateActiveProject(field.value)}
            placeholder={'Select a Jira project'}
            className={'srcclr-react-select-container'}
            classNamePrefix={'srcclr-react-select'}
          />
          {integrationsPermission && (
            <div className="text--right pt--">
              <Link to={`/org/settings/integrations`} className="link--obvious text--right">
                Configure projects{' '}
              </Link>
            </div>
          )}
        </div>
        <div className="grid__item text--right mt+">
          <button className="p-" onClick={() => this.closeModal()}>
            {' '}
            Cancel{' '}
          </button>
          {projectList.length > 0 && (
            <a
              className="btn btn--primary-clear p-"
              href={jiraFixUrl || '#'}
              target={jiraFixUrl && '_blank'}
              onClick={() => this.createIssueLinkClicked('jira_legacy')}
            >
              Create
            </a>
          )}
        </div>
      </div>
    );

    const githubSectionHtml = (
      <div className="grid pt-">
        <div className="grid__item col-1-1">
          Create an issue to be tracked on Github.
          {!showGithub && (
            <div className="color--danger">
              {integrationsPermission
                ? `You have not set up ${GITHUB} integration for this workspace.`
                : `Please contact your administrator to enable ${GITHUB} integration for this workspace`}
            </div>
          )}
        </div>

        <div className="grid__item col-1-1 mt+ flex">
          <div className="grid__item col-1-2 pt- pl0">
            {integrationsPermission && (
              <Link to={`/org/settings/integrations`} className="link--obvious text--right">
                Configure {GITHUB} integration{' '}
              </Link>
            )}
          </div>
          <div className="grid__item text--right">
            <button className="p- mr" onClick={() => this.closeModal()}>
              {' '}
              Cancel{' '}
            </button>
            {showGithub && (
              <a
                className="btn btn--primary-clear p-"
                href={githubFixUrl || '#'}
                target={githubFixUrl && '_blank'}
                onClick={() => this.createIssueLinkClicked('github')}
              >
                Create
              </a>
            )}
          </div>
        </div>
      </div>
    );

    const newJiraSectionHtml = <JiraCloudCreateTicket selectedIssue={selectedIssue} />;

    const withIssueCheck = issueHtml => {
      if (isCheckingIfIssueCreated) {
        return (
          <div className="grid pb-">
            <span>
              {' '}
              Checking if a Jira issue has been created <SourceClearLoader />{' '}
            </span>
          </div>
        );
      }

      return (
        <div className="grid pb-">
          {linkedJira.length == 0 ? (
            <div className="col-1-1"> {issueHtml} </div>
          ) : (
            <div className="col-1-1">
              A Jira issue has already been created. See
              <a
                href={JiraHelper.getJiraLink(
                  linkedJira[0].integrationServiceUrl,
                  linkedJira[0].jiraKey
                )}
                target="_blank"
                className="link--obvious"
              >
                {' '}
                {linkedJira[0].jiraKey}
              </a>
            </div>
          )}
        </div>
      );
    };

    // Temporarily solution until Jira prem is no longer feature flagged
    const modalTitle =
      viewType !== MODEL.CREATE_ISSUE_JIRA_CLOUD_MODAL
        ? MODEL.MODAL_MAPPING[viewType]
        : `${JIRA_CLOUD} / ${JIRA_ONPREM}`;

    return (
      <SourceClearModal
        isOpen={viewType !== ''}
        title={modalTitle || ''}
        onClose={() => this.closeModal()}
        closeWhenClickOutside={false}
        width={viewType === MODEL.CREATE_ISSUE_JIRA_CLOUD_MODAL ? 670 : 600}
      >
        {isFixInfoFetching && <SourceClearLoader />}

        {!isFixInfoFetching && (
          <div className="pt pl">
            {viewType === MODEL.CREATE_ISSUE_JIRA_CLOUD_MODAL && withIssueCheck(newJiraSectionHtml)}
            {viewType === MODEL.CREATE_ISSUE_JIRA_ENTERPRISE_MODAL &&
              withIssueCheck(jiraSectionHtml)}
            {viewType === MODEL.CREATE_ISSUE_GITHUB_MODAL && withIssueCheck(githubSectionHtml)}
          </div>
        )}
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationState: state.integrationState,
    modalState: state.modalState,
    navigationState: state.navigationState,
    teamState: state.teamState,
    reportIssuesState: state.reportIssuesState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
    reportIssuesActions: bindActionCreators(reportIssuesActions, dispatch),
    reportSelectedRowsActions: bindActionCreators(reportSelectedRowsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateIssueModal);
