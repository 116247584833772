import React from 'react';

import ProjectSettingsWebhookNotificationsListSection from '~/containers/ProjectSettingsWebhookNotificationsListSection';
import ProjectSettingsWebhookNotificationsCreateSection from '~/containers/ProjectSettingsWebhookNotificationsCreateSection';

interface ProjectSettingsNotificationsPageProps {
  projectId: string;
  stage?: string;
}
const ProjectSettingsNotificationsPage: React.FunctionComponent<ProjectSettingsNotificationsPageProps> = props => {
  const { projectId, stage } = props;

  return stage === 'CREATE_WEBHOOK' || stage === 'UPDATE_WEBHOOK' ? (
    <ProjectSettingsWebhookNotificationsCreateSection projectId={projectId} />
  ) : (
    <ProjectSettingsWebhookNotificationsListSection projectId={projectId} />
  );
};

export default ProjectSettingsNotificationsPage;
