import * as actions from '~/actions/reportIssues';

const defaultState = {
  issueFixData: undefined,
  isFixInfoFetching: false,
  jiraFixUrl: undefined,
  githubFixUrl: undefined,
  activeJiraProject: undefined,
  integrations: {},
};

const reportIssuesState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_REPORT_ISSUE_FIX_DATA:
      return {
        ...state,
        issueFixData: action.issueFixData,
      };
    case actions.FETCH_FIXINFO_REQUEST:
      return {
        ...state,
        isFixInfoFetching: action.isFetching,
      };
    case actions.FETCH_FIXINFO_RECEIVED:
      return {
        ...state,
        isFixInfoFetching: false,
      };
    case actions.UPDATE_REPORT_JIRA_FIX_URL:
      return {
        ...state,
        jiraFixUrl: action.jiraHref,
      };
    case actions.CLEAR_REPORT_JIRA_FIX_URL:
      return {
        ...state,
        jiraFixUrl: undefined,
      };
    case actions.UPDATE_REPORT_GITHUB_FIX_URL:
      return {
        ...state,
        githubFixUrl: action.githubHref,
      };
    case actions.UPDATE_REPORT_ACTIVE_JIRA_PROJECT:
      return {
        ...state,
        activeJiraProject: action.jiraProject,
      };
    case actions.ISSUE_SUPPRESS_REQUEST:
      return {
        ...state,
        isSuppressingIssueSuccess: undefined,
        isSuppressingIssue: true,
        isSuppressingIssueFailure: undefined,
      };
    case actions.ISSUE_SUPPRESS_SUCCESS:
      return {
        ...state,
        isSuppressingIssueSuccess: true,
        isSuppressingIssue: false,
        isSuppressingIssueFailure: undefined,
      };
    case actions.ISSUE_SUPPRESS_FAILURE:
      return {
        ...state,
        isSuppressingIssueSuccess: false,
        isSuppressingIssue: false,
        isSuppressingIssueFailure: action.errorMessage,
      };
    case actions.ISSUE_SUPPRESS_STATE_RESET:
      return {
        ...state,
        isSuppressingIssueSuccess: undefined,
        isSuppressingIssue: false,
        isSuppressingIssueFailure: undefined,
      };
    case actions.ISSUE_INTEGRATION_REQUEST:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          [action.issueId]: {
            isFetching: true,
          },
        },
      };
    case actions.ISSUE_INTEGRATION_SUCCESS:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          [action.issueId]: {
            isFetching: false,
            data: action.res,
          },
        },
      };
    case actions.ISSUE_INTEGRATION_FAILURE:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          [action.issueId]: {
            isFetching: false,
            err: action.err,
          },
        },
      };
    default:
      return state;
  }
};

export default reportIssuesState;
