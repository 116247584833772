import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';

import ApiService from '~/utils/ApiService';

import * as myActions from '~/actions/me';
import * as signupActions from '~/actions/signup';

import EnvelopeExpired from '~/components/SVGs/EnvelopeExpired';
import EnvelopeInvalid from '~/components/SVGs/EnvelopeInvalid';

interface AcceptInvitePageProps extends RouteComponentProps {
  myState: object;
  teamState: object;
  myActions: object;
  signupActions: object;
}

class AcceptInvitePage extends React.Component<AcceptInvitePageProps, {}> {
  componentDidMount() {
    const { location, history } = this.props;
    const { search } = location;
    const query = queryString.parse(search);
    const { token } = query;

    if (token) {
      ApiService.post('/invites:lookup', { data: { token } })
        .then(res => {
          this.props.myActions.updateOrgInvite(res);
          const { email, organization = {}, expirationDate } = res;
          const samlDomains = organization.samlDomains || [];
          const isSaml = samlDomains.length;

          let url = isSaml ? '/saml' : '/signup';
          if (email) {
            url += `?email=${encodeURIComponent(email)}`;
          }

          if (!isSaml && organization.id) {
            this.props.signupActions.updateInviteToken(token);
            url += `&step=account`;
          }

          if (!expirationDate || moment().isBefore(moment(expirationDate))) {
            history.replace(url);
          }
        })
        .catch(() => {
          this.props.myActions.updateOrgInvite({});
          // no redirect
        });
    } else {
      // no redirect
    }
  }

  componentDidUpdate(prevProps) {
    const { myState, teamState, history } = prevProps;
    const { me = {} } = myState;
    const { teams = [] } = teamState;
    const isLoggedIn = !!me.id;
    if (isLoggedIn) {
      history.replace(teams.length ? `/portfolio` : '/no-workspaces');
    }
  }

  render() {
    const { myState } = this.props;
    const { orgInvite = {} } = myState;
    const { expirationDate } = orgInvite;
    let isInviteExpired = false;
    if (expirationDate && moment().isAfter(moment(expirationDate))) {
      isInviteExpired = true;
    }

    return (
      <div className="container flex align-items--center justify-content--center height--75vh">
        <div className="grid grid--middle col-2-5">
          {isInviteExpired ? (
            <div className="grid__item flex align-items--center">
              <EnvelopeExpired fillColor="#a2ddec" width="200" height="200" />
              <div className="ml+">
                <div className="font--h3">
                  <strong>This link is expired</strong>
                </div>
                <div className="font--h6 mv--">
                  Contact an organization administrator for a new invitation.
                </div>
                <Link to="/login" className="link--obvious mt-">
                  Take me to login
                </Link>
              </div>
            </div>
          ) : (
            <div className="grid__item flex align-items--center">
              <EnvelopeInvalid fillColor="#a2ddec" width="200" height="200" />
              <div className="ml+">
                <div className="font--h3">
                  <strong>This link is invalid</strong>
                </div>
                <div className="font--h6 mv--">
                  Please try again or contact an organization administrator.
                </div>
                <Link to="/login" className="link--obvious mt-">
                  Take me to login
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myState: state.myState,
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    myActions: bindActionCreators(myActions, dispatch),
    signupActions: bindActionCreators(signupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInvitePage);
