import * as actions from '~/actions/sidebar';

const defaultState = {};

const sidebarState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.OPEN_SIDEBAR:
      return Object.assign({}, state, {
        [action.id]: true,
      });
    case actions.CLOSE_SIDEBAR:
      return Object.assign({}, state, {
        [action.id]: false,
      });
    default:
      return state;
  }
};

export default sidebarState;
