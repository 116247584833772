import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';

interface GoDepProps {
  packageInfo: object;
  filePath: string;
}

const GoDep: React.FunctionComponent<GoDepProps> = props => {
  const { name, commitHash } = props.packageInfo;
  const { filePath = 'vendor.json' } = props;

  //split Podfile#L5
  const splittedSelectedFilePath = filePath.split('#L');
  const file = splittedSelectedFilePath[0];
  const lineNumber = splittedSelectedFilePath[1] || '';
  const PADDING_LINE = 2;
  const pathLineNumber = isNaN(Number(lineNumber)) ? '' : Number(lineNumber) + PADDING_LINE;

  return (
    <div>
      <div className="grid bo--1 border-color--white-dark mt lh+">
        <CodeDiffHeader text={file} />
        <CodeDiffLine type="normal" text={'\u00B7\u00B7\u00B7'} />
        <CodeDiffLine
          codeFont={true}
          type="highlight"
          lineNumber={`${pathLineNumber}`}
          text={`"path": "${name}"`}
        />
        <CodeDiffLine codeFont={true} type="highlight" text={`"revision": "${commitHash}"`} />
        <CodeDiffLine type="normal" text={'\u00B7\u00B7\u00B7'} />
      </div>
    </div>
  );
};

export default GoDep;
