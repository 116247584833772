import React from 'react';
import { Link } from 'react-router-dom';

const NoAccessPage: React.FunctionComponent<{}> = () => {
  return (
    <div className="grid grid--center mt++">
      <div className="grid__item col-2-5 text--center">
        <div className="font--h6">Workspace Error</div>
        <div className="mt- font--h7">
          This team either does not exist or you do not have permission to view it. Please ensure
          you are logged into the proper account.
        </div>
        <div className="mt- font--h7">
          <Link to="/portfolio" className="link--obvious">
            Take me to my portfolio
          </Link>
          .
        </div>
      </div>
    </div>
  );
};

export default NoAccessPage;
