import * as actions from '~/actions/sortByReportType';
import { SortByReportTypeState } from '~/reducers/sortByReportType.types';

const defaultState: SortByReportTypeState = {
  ISSUES: {
    // id, severity, repo
    severity: 'desc',
    id: 'desc',
  },
  LIBRARIES: {
    // name, vulns, licenseRisk
    name: 'asc',
  },
  VULNERABILITIES: {
    // name, severity
    severity: 'desc',
  },
  REPOSITORIES: {
    // name, libs, vulns
    name: 'asc',
  },
  WORKSPACES: {
    // name
    name: 'asc',
  },
  LICENSES: {
    // name, risk
    risk: 'desc',
  },
};

const sortByReportType = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_SORT_FIELD:
      return {
        ...state,
        [action.reportType]: {
          [action.field]: determineNewSortValue({ ...state[action.reportType] }, action.field),
        },
      };
    case actions.UPDATE_SORT_FIELD_BY_PROJECT:
      if (state[action.projectId]) {
        return {
          ...state,
          [action.projectId]: {
            ...state[action.projectId],
            [action.reportType]: {
              [action.field]: determineNewSortValue(
                { ...state[action.projectId][action.reportType] },
                action.field
              ),
            },
          },
        };
      } else {
        return {
          ...state,
          [action.projectId]: {
            ...defaultState,
            [action.reportType]: {
              [action.field]: 'desc',
            },
          },
        };
      }
    case actions.INIT_PROJECT_SORT_DEFAULTS:
      if (state[action.projectId]) {
        return state;
      }

      return {
        ...state,
        [action.projectId]: defaultState,
      };
    default:
      return state;
  }
};

const determineNewSortValue = (currentReportSort, field) => {
  if (field in currentReportSort) {
    // we clicked on the field that's currently sorted
    // move to the next order in the cycle
    if (currentReportSort[field] === 'desc') {
      return '';
    } else if (currentReportSort[field] === '') {
      return 'asc';
    }
  }

  // desc is the default for a new sort, as well as the last remaining option if the above if statements failed to be valid
  return 'desc';
};

export default sortByReportType;
