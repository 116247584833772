import React from 'react';
import CopyToClipboard from '~/containers/CopyToClipboard';

const ScanCLI: React.FunctionComponent<{}> = () => {
  const integrationType = 'cli';
  const scanTrack = {
    event: 'Scan Server Click',
    options: {
      integrationType: integrationType.toUpperCase(),
      item: 3,
    },
  };

  return (
    <div>
      <div className="mt-">Scan any public repositories:</div>
      <div className="mt--">
        <CopyToClipboard
          value={'srcclr scan --url https://github.com/veracode/example-ruby'}
          id="srcclrExampleRuby"
          trackOptions={scanTrack}
        />
      </div>
      <div className="mt-">Clone and scan your private repositories:</div>
      <div className="mt--">
        <CopyToClipboard
          value={'srcclr scan FILE_PATH'}
          id="personalLocalRepo"
          trackOptions={scanTrack}
        />
      </div>
    </div>
  );
};

export default ScanCLI;
