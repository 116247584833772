import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';

import * as policyValidationAction from '~/actions/policyValidation';
import * as toastrActions from '~/actions/toastr';
import * as orgPolicyActions from '~/actions/orgPolicies';
import _ from 'lodash';

import { EffectiveRevision } from '~/types/Policy';

export const ADD_CONTROL = 'ADD_CONTROL';
export const MOVE_CONTROL = 'MOVE_CONTROL';
export const REMOVE_CONTROL = 'REMOVE_CONTROL';
export const TOGGLE_CONTROL_EXPAND = 'TOGGLE_CONTROL_EXPAND';
export const TOGGLE_POLICY_STATUS_MODE = 'TOGGLE_POLICY_STATUS_MODE';
export const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';
export const TRIGGER_MOVE_EFFECT = 'TRIGGER_MOVE_EFFECT';
export const UPDATE_CONTROL = 'UPDATE_CONTROL';
export const UPDATE_POLICIES_MODAL = 'UPDATE_POLICIES_MODAL';
export const UPDATE_WORKSPACE_POLICIES = 'UPDATE_WORKSPACE_POLICIES';
export const UPDATE_WORKSPACE_POLICY = 'UPDATE_WORKSPACE_POLICY';
export const FETCH_WORKSPACE_POLICIES_REQUEST = 'FETCH_WORKSPACE_POLICIES_REQUEST';
export const FETCH_WORKSPACE_POLICIES_FAILURE = 'FETCH_WORKSPACE_POLICIES_FAILURE';
export const SAVE_CHANGES_REQUEST = 'SAVE_CHANGES_REQUEST';
export const SAVE_CHANGES_FAILURE = 'SAVE_CHANGES_FAILURE';
export const RESET_EXPANDED_CONTROLS = 'RESET_EXPANDED_CONTROLS';
export const RESET_MODES = 'RESET_MODES';
export const RESET_CUSTOM_POLICY_TO_DEFAULT = 'RESET_CUSTOM_POLICY_TO_DEFAULT';
export const RESET_TO_ORG_POLICY_REQUEST = 'RESET_TO_ORG_POLICY_REQUEST';
export const RESET_TO_ORG_POLICY_SUCCESS = 'RESET_TO_ORG_POLICY_SUCCESS';
export const RESET_TO_ORG_POLICY_FAILURE = 'RESET_TO_ORG_POLICY_FAILURE';
export const RESET_TO_DEFAULT_POLICY_REQUEST = 'RESET_TO_DEFAULT_POLICY_REQUEST';
export const UPDATE_DEFAULT_POLICY = 'UPDATE_DEFAULT_POLICY';

const INVALID_POLICY = 'INVALID_POLICY';

export const updateWorkspacePolicy = policy => ({
  type: UPDATE_WORKSPACE_POLICY,
  policy,
});

export const resetCustomPolicyToDefault = (effectiveRevision: EffectiveRevision) => ({
  type: RESET_CUSTOM_POLICY_TO_DEFAULT,
  effectiveRevision,
});

export const resetToOrgPolicyRequest = () => ({
  type: RESET_TO_ORG_POLICY_REQUEST,
});

export const resetToOrgPolicySuccess = (effectiveRevision: EffectiveRevision) => ({
  type: RESET_TO_ORG_POLICY_SUCCESS,
  effectiveRevision,
});

export const resetToOrgPolicyFailure = ({ message }: { message: string }) => ({
  type: RESET_TO_ORG_POLICY_FAILURE,
  message,
});

export const showModal = (modalType?) => ({
  type: UPDATE_POLICIES_MODAL,
  modalType,
});

export const toggleEditMode = cancel => ({
  type: TOGGLE_EDIT_MODE,
  cancel,
});

export const addControl = () => ({
  type: ADD_CONTROL,
});

export const removeControl = index => ({
  type: REMOVE_CONTROL,
  index,
});

export const toggleControlExpand = index => ({
  type: TOGGLE_CONTROL_EXPAND,
  index,
});

export const triggerMoveEffect = (index, bool) => ({
  type: TRIGGER_MOVE_EFFECT,
  index,
  bool,
});

export const fetchWorkspacePoliciesRequest = () => ({
  type: FETCH_WORKSPACE_POLICIES_REQUEST,
});

export const fetchWorkspacePoliciesFailure = error => ({
  type: FETCH_WORKSPACE_POLICIES_FAILURE,
  message: error.message,
});

export const saveChangesRequest = () => ({
  type: SAVE_CHANGES_REQUEST,
});

export const saveChangesFailure = error => ({
  type: SAVE_CHANGES_FAILURE,
  message: error.message,
});

export const resetExpandedControls = () => ({
  type: RESET_EXPANDED_CONTROLS,
});

export const resetToDefaultPolicyRequest = () => ({
  type: RESET_TO_DEFAULT_POLICY_REQUEST,
});

export const updateDefaultPolicy = policy => ({
  type: UPDATE_DEFAULT_POLICY,
  policy,
});

export const resetModes = () => ({
  type: RESET_MODES,
});

export const moveControl = (index, toIndex) => dispatch => {
  dispatch(triggerMoveEffect(toIndex, true));
  setTimeout(() => {
    dispatch(triggerMoveEffect(toIndex, false));
  }, 1000);

  dispatch({
    type: MOVE_CONTROL,
    index,
    toIndex,
  });
};

/**
  Setting null as option for parameter's field will clear the key
**/
export const updateControl = (option, field, index) => ({
  type: UPDATE_CONTROL,
  option,
  field,
  index,
});

export const resetToDefault = () => dispatch => {
  dispatch(resetToDefaultPolicyRequest());
  ApiService.get(ApiConstants.defaultPoliciesUrl())
    .then((res: EffectiveRevision) => {
      dispatch(resetCustomPolicyToDefault(res));
      dispatch(showModal()); //close modal
    })
    .catch(error => {
      ErrorService.capture('Error resetting policy to default', error);
      dispatch(fetchWorkspacePoliciesFailure(error));
    });
};

export const resetToOrgPolicy = () => (dispatch, getState) => {
  const {
    orgState: {
      org: { id: orgId },
    },
  } = getState();
  dispatch(resetToOrgPolicyRequest());
  // retrieve org-level controls
  ApiService.get(ApiConstants.orgPolicyURL(orgId))
    .then(res => {
      const { effectiveRevision } = res;
      dispatch(resetToOrgPolicySuccess(effectiveRevision));
      dispatch(showModal()); //close modal
    })
    .catch(error => {
      ErrorService.capture('Error resetting policy to organization rules', error);
      dispatch(resetToOrgPolicyFailure(error));
    });
};

/*
 * Format the policy before saving or validating
 * 1) Convert severity to float (must be string in order for input to work correctly)
 * 2) Remove severity if override is set
 */
export const formatSeverity = policy => {
  if (!policy || !policy.controls || !policy.controls.length) {
    return policy;
  }

  const formattedControls = policy.controls.map(control => {
    /*
      Convert severity to float. Delete severity instead of sending empty string to allow
      the correct validation message of severity is required.
    */

    if (
      isNaN(control.severity) ||
      (_.isString(control.severity) && control.severity.trim().length === 0)
    ) {
      delete control.severity;
    } else {
      control.severity = parseFloat(control.severity);
    }

    // Delete severity if severity_override is set
    if (
      control.condition &&
      control.condition.descriptor &&
      control.condition.descriptor.parameters
    ) {
      const { severity_override } = control.condition.descriptor.parameters;

      if (severity_override === 'cvss') {
        delete control.severity;
      }
    }

    return control;
  });

  return {
    ...policy,
    version: policy.version,
    controls: formattedControls,
  };
};

export const validateAndSaveChanges = (workspaceId: string) => (dispatch, getState) => {
  const {
    policiesState: {
      policy: { effectiveRevision },
    },
  } = getState();

  const data = formatSeverity(_.cloneDeep(effectiveRevision));
  const { controls } = data;

  // Not logging 400 status since that's the response to indicate the user input failed
  const ignoreStatusCode = 400;
  dispatch(policyValidationAction.validatePolicyRequest());
  dispatch(toastrActions.removeToastr({ id: INVALID_POLICY })); //Clear toastr if any before displaying any possible errors
  dispatch(saveChangesRequest());

  ApiService.post(ApiConstants.workspacePolicyRevisionsUrl(workspaceId), {
    data: { controls },
  })
    .then(res => {
      dispatch(updateWorkspacePolicy(res));
      dispatch(policyValidationAction.updatePolicyValidationResults({}, 0));
      dispatch(resetExpandedControls());
    })
    .catch(error => {
      const {
        response: { data: validationError },
      } = error;

      // if validation fails on the backend
      if (error.status === ignoreStatusCode) {
        dispatch(policyValidationAction.validatePolicyFailure(error));
        const { policyValidationState, policiesState } = getState();
        const { validationResults = {} } = policyValidationState;
        const { policy = {} } = validationResults;
        const { controls = [] } = policy;
        const {
          expandedControlsByIndex = {},
          policy: {
            effectiveRevision: { controls: policyControls },
          },
        } = policiesState;

        dispatch(
          policyValidationAction.updatePolicyValidationResults(
            validationError,
            policyControls.length
          )
        );
        // Expand the controls with errors
        for (let [index, control] of controls.entries()) {
          const isExpanded = expandedControlsByIndex[index];
          if (control && !isExpanded) {
            dispatch(toggleControlExpand(index));
          }
        }
        const options = {
          id: INVALID_POLICY,
          title: 'Sorry, we could not save your rules',
          level: 'error',
          message: 'Correct the errors below.',
        };

        dispatch(toastrActions.addToastr(options));
      } else {
        // log non-400 status errors
        ErrorService.capture('error updating policy', error);
      }
      dispatch(saveChangesFailure(error));
    });
};

export const fetchWorkspacePolicy = workspaceId => (dispatch, getState) => {
  const {
    orgState: {
      org: { id: orgId },
    },
  } = getState();
  dispatch(fetchWorkspacePoliciesRequest());

  return ApiService.get(ApiConstants.workspacePolicyURL(workspaceId))
    .then((res = {}) => {
      dispatch(updateWorkspacePolicy(res));
      if (!res.sandbox) {
        // workspace policy page depends on having access to the org policy and the org policy setting
        // we have to pre-fetch the data as it is likely the orgPoliciesState is not populated at this point
        dispatch(orgPolicyActions.fetchOrgPolicies(orgId));
        dispatch(orgPolicyActions.fetchOrgPolicySettings(orgId));
      }
    })
    .catch(error => {
      ErrorService.capture('Error fetching workspace policies', error);
      dispatch(fetchWorkspacePoliciesFailure(error));
    });
};

export const togglePolicyStatusMode = (mode, workspaceId) => dispatch => {
  /*
   * there is very little reason for this to fail, so we'll use some "optimistic rendering"
   * to show the switch has been successful before the api response has been resolved.
   * if the response has a problem, we'll switch it back and show an error message.
   */
  dispatch(updatePolicyStatusMode(mode));

  return ApiService.put(ApiConstants.workspacePolicyURL(workspaceId), {
    data: { status: mode },
  })
    .then(res => {
      // if successful, the saved policy will be returned

      dispatch(updateWorkspacePolicy(res));

      return { success: true };
    })
    .catch(error => {
      ErrorService.capture('Error updating policy mode', error);
      // TODO: fire error message explaining why we're switching the UI back...
      dispatch(
        toastrActions.addToastr({
          id: 'error-toggling-custom-policy',
          level: 'error',
          title: 'Error switching policy mode.',
          message:
            error.message ||
            "We're sorry, there has been an error switching your policy mode for this workspace. Please try again or contact support.",
        })
      );
      dispatch(updatePolicyStatusMode('DEFAULT'));
      return {};
    });
};

export const updatePolicyStatusMode = mode => ({
  type: TOGGLE_POLICY_STATUS_MODE,
  mode,
});
