import React from 'react';
import Select from 'react-select';
import { gatherDropdownOptions } from '~/utils/gatherDropdownOptions';
import _ from 'lodash';
import { StylesConfig } from 'react-select/src/styles';

interface SelectDropDownProps {
  fieldName: string;
  className?: string;
  /**
   * Expects an array of Objects of {label: "x", "value": y}
   */
  items?: any[];
  /**
   * Alternatively provide stringItems if label and value is the same
   */
  stringItems?: any[];
  name?: string;
  noOptionsMessage?: () => string | null;
  isClearable?: boolean;
  value?: any;
  /**
   * Async mode, when set to true, asyncGetOptions should supply a
   * function to retrieve the value given the input of the user
   */
  async?: boolean;
  asyncGetOptions?: (...args: any[]) => any;
  onChange?: (...args: any[]) => any;
  onInputChange?: (value: string) => void;
  withGroupedOptions: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  defaultOptions?: any[] | boolean;
  inputId: string;
  styles?: StylesConfig;
}
class SelectDropDown extends React.Component<SelectDropDownProps, {}> {
  constructor(props, context) {
    super(props, context);

    if (this.props.async) {
      this.delayedAsyncGetOptions = _.debounce(this.props.asyncGetOptions, 500);
    }
  }

  delayedAsyncGetOptions = undefined;

  handleOnChange(fieldType, field) {
    const value = field ? field.value : null;
    this.props.onChange(fieldType, value);
  }

  onInputChange = input => {
    const { async = false } = this.props;

    if (async) {
      this.delayedAsyncGetOptions(input);
    }

    return input;
  };
  render() {
    const {
      withGroupedOptions,
      fieldName = '',
      items,
      className = '',
      stringItems,
      name = '',
      value = '',
      noOptionsMessage = () => 'No results found',
      isClearable = true,
      isDisabled,
      isLoading = false,
      defaultOptions,
      inputId,
      styles,
    } = this.props;

    let options = stringItems ? stringItems.map(item => ({ value: item, label: item })) : items;
    // allow SelectDropDown to consume grouped options
    let groupedOptions = _.flatten(gatherDropdownOptions(options));
    const selectedDropdownValue = withGroupedOptions
      ? groupedOptions.find((option: { value: string }) => option.value === value)
      : options.find(option => option.value === value);
    return (
      <Select
        isDisabled={isDisabled}
        isClearable={isClearable}
        name={fieldName}
        value={selectedDropdownValue || ''}
        options={options}
        onChange={field => this.handleOnChange(fieldName, field)}
        placeholder={name.length > 0 ? `Select ${name.toLowerCase()}` : ''}
        noOptionsMessage={noOptionsMessage}
        onInputChange={this.onInputChange}
        defaultOptions={defaultOptions}
        isLoading={isLoading}
        className={className + ' srcclr-react-select-container'}
        classNamePrefix={'srcclr-react-select'}
        onSelectResetsInput={false}
        onBlurResetsInput={false}
        inputId={inputId}
        styles={styles}
      />
    );
  }
}

export default SelectDropDown;
