import * as actions from '~/actions/appLoad';

export const defaultState = {
  preAppLoadResolved: false,
};

const appLoadState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_PRE_APP_STATUS:
      return {
        ...state,
        preAppLoadResolved: action.status,
      };
    default:
      return state;
  }
};

export default appLoadState;
