import ApiService from '~/utils/ApiService';

export const UPDATE_QUICK_VIEW_DATA = 'UPDATE_QUICK_VIEW_DATA';
export const FETCH_QUICK_VIEW_DATA_REQUEST = 'FETCH_QUICK_VIEW_DATA_REQUEST';
export const fetchQuickViewData = (href, dataType) => dispatch => {
  dispatch(fetchQuickViewDataRequest());
  return ApiService.get(href).then(res => {
    dispatch(updateQuickViewData(res, dataType));
  });
};

export const updateQuickViewData = (data, dataType) => ({
  type: UPDATE_QUICK_VIEW_DATA,
  data,
  dataType,
});

export const fetchQuickViewDataRequest = () => ({
  type: FETCH_QUICK_VIEW_DATA_REQUEST,
});
