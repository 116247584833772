import React, { useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as reportsActions from '~/actions/reports';
import * as reportScopeActions from '~/actions/reportScope';
import * as reportFilterActions from '~/actions/reportFilters';
import * as workspaceScanDateActions from '~/actions/workspaceScanDate';
import * as insightsActions from '~/actions/insights';
import * as statsActions from '~/actions/stats';
import Helpers from '~/utils/Helpers';
import {
  scanDateOptions,
  scanDateOptionsForStandalone,
  WORKSPACE_SCAN_DATE_LAST_7_DAYS,
  WORKSPACE_SCAN_DATE_MORE_THAN_7_DAYS,
  DEFAULT_WORKSPACE_SCAN_DATE,
} from '~/constants/ModelConstants';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';

interface WorkspaceScanDateDropdownProps {
  navigationState: { activeReportType: string };
  reportFilterState: { [key: string]: any };
  vcPageState: VCPageState;
  workspaceScanDateState: { scanDate: string; nextScanDate: string };
}

function WorkspaceScanDateDropdown() {
  const { teamId } = useParams();
  const dispatch = useDispatch();
  const {
    navigationState: { activeReportType },
    reportFilterState,
    vcPageState,
    workspaceScanDateState: { scanDate, nextScanDate },
  } = useSelector((state: WorkspaceScanDateDropdownProps) => state);

  const { ['REPOSITORIES']: projectFilters } = reportFilterState;

  const { scannedInPastWeek } = projectFilters;

  const { shouldShowVeracodePage } = vcPageState;

  useEffect(() => {
    if (scannedInPastWeek) {
      updateSelectedScanDate(WORKSPACE_SCAN_DATE_LAST_7_DAYS);
      dispatch(
        workspaceScanDateActions.updateWorkspaceNextScanDateValue(WORKSPACE_SCAN_DATE_LAST_7_DAYS)
      );
    } else if (scannedInPastWeek === null) {
      // restore to previously selected scan date
      updateSelectedScanDate(scanDate);
      dispatch(workspaceScanDateActions.updateWorkspaceNextScanDateValue(null));
    } else {
      updateSelectedScanDate(WORKSPACE_SCAN_DATE_MORE_THAN_7_DAYS);
      dispatch(
        workspaceScanDateActions.updateWorkspaceNextScanDateValue(
          WORKSPACE_SCAN_DATE_MORE_THAN_7_DAYS
        )
      );
    }

    return () => {
      if (scannedInPastWeek !== null) {
        dispatch(reportFilterActions.updateFilterValue('REPOSITORIES', 'scannedInPastWeek', null));
      }

      dispatch(workspaceScanDateActions.updateWorkspaceScanDateValue(DEFAULT_WORKSPACE_SCAN_DATE));
      updateSelectedScanDate(DEFAULT_WORKSPACE_SCAN_DATE);
    };
  }, [scannedInPastWeek]);

  const updateSelectedScanDate = (scanDate: string) => {
    if (scanDate === WORKSPACE_SCAN_DATE_MORE_THAN_7_DAYS) {
      dispatch(
        reportScopeActions.updateReportScope(
          'scanBeforeDate',
          Helpers.getWorkspaceScanDate(scanDate)
        )
      );
      // reset scanAfterDate
      dispatch(reportScopeActions.updateReportScope('scanAfterDate', null));
    } else {
      dispatch(
        reportScopeActions.updateReportScope(
          'scanAfterDate',
          Helpers.getWorkspaceScanDate(scanDate)
        )
      );
      // reset scanBeforeDate
      dispatch(reportScopeActions.updateReportScope('scanBeforeDate', null));
    }
  };

  const fetchData = () => {
    // update appropriate insights
    if (activeReportType === 'ISSUES') {
      dispatch(insightsActions.fetchIssueInsights(teamId));
    } else if (activeReportType === 'REPOSITORIES') {
      dispatch(insightsActions.fetchProjectInsights(teamId));
    } else if (activeReportType === 'VULNERABILITIES') {
      dispatch(insightsActions.fetchVulnerabilityInsights(teamId));
    }
    // update report type counts
    dispatch(statsActions.fetchWorkspaceStats(teamId));
    dispatch(reportsActions.fetchReport(teamId, activeReportType));
  };

  const handleScanDateChange = (scanDateValue: string) => {
    // uncheck scan date insight if checked
    if (scannedInPastWeek !== null) {
      dispatch(reportFilterActions.updateFilterValue('REPOSITORIES', 'scannedInPastWeek', null));
    }

    dispatch(workspaceScanDateActions.updateWorkspaceScanDateValue(scanDateValue));
    updateSelectedScanDate(scanDateValue);
    fetchData();
  };

  const scanDateDropdownData = shouldShowVeracodePage
    ? scanDateOptions
    : scanDateOptionsForStandalone;
  return (
    <Select
      options={scanDateDropdownData}
      name="scanDate"
      value={scanDateDropdownData.find(option => {
        if (nextScanDate) {
          return option.value === nextScanDate;
        }
        return option.value === scanDate;
      })}
      isClearable={false}
      onChange={(scanDate: { value: string; label: string }) =>
        handleScanDateChange(scanDate.value)
      }
      className="srcclr-react-select-container zIndex-9--overlay"
      classNamePrefix="srcclr-react-select"
    />
  );
}

export default WorkspaceScanDateDropdown;
