import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DropdownMenu from 'react-dd-menu';
import _ from 'lodash';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as repoActions from '~/actions/repo';

const WATCH_DROPDOWN_MENU = 'watch-dropdown-menu';

interface WatchProjectProps {
  dropdownMenuActions: object;
  dropdownMenuState: object;
  projectData: object;
  repoActions: object;
  onChange?: (...args: any[]) => any;
  className?: string;
}
class WatchProject extends React.Component<WatchProjectProps, {}> {
  constructor(props, context) {
    super(props, context);
    this.delayCloseDropdownMenu = _.debounce(this.closeDropdownMenu, 50);
  }

  componentWillUnmount() {
    this.closeDropdownMenu();
  }

  closeDropdownMenu() {
    this.props.dropdownMenuActions.closeDropdownMenu(WATCH_DROPDOWN_MENU);
  }

  watchRepo(watching) {
    const { projectData = {} } = this.props;
    const { _links = {}, id } = projectData;
    const { watch = {} } = _links;
    const watchLink = watch.href || '';

    if (projectData.watch === watching) {
      return;
    }

    if (watching) {
      this.props.repoActions.watchRepo(id, watchLink);
    } else {
      this.props.repoActions.unwatchRepo(id, watchLink);
    }

    this.props.onChange(watching);
  }

  render() {
    const { projectData, dropdownMenuState, className = '' } = this.props;
    const { watch = false } = projectData;
    const { openedDropdown = {} } = dropdownMenuState;

    return (
      <DropdownMenu
        animate={true}
        size={'sm'}
        textAlign="left"
        align="right"
        isOpen={!!openedDropdown[WATCH_DROPDOWN_MENU]}
        close={() => this.delayCloseDropdownMenu()}
        toggle={
          <div
            className={className}
            onClick={() => this.props.dropdownMenuActions.openDropdownMenu(WATCH_DROPDOWN_MENU)}
            data-automation-id="ProjectDetailsPage-Actions-Watch"
          >
            <i className={`pb--- fas pr-- ${watch ? 'fa-eye' : 'fa-eye-slash'}`} />
            <span> {watch ? 'Watching' : 'Not watching'} </span>
          </div>
        }
      >
        <li>
          <div
            className="grid flex grid--full cursor--pointer"
            onClick={() => this.watchRepo(true)}
          >
            <div className="grid__item col-1-6 inline-block">
              {watch && <i className="fas fa-check"> </i>}
            </div>
            <span className="grid__item">Watch project</span>
          </div>
        </li>
        <li>
          <div
            className="grid flex grid--full cursor--pointer"
            onClick={() => this.watchRepo(false)}
          >
            <div className="grid__item col-1-6 inline-block">
              {!watch && <i className="fas fa-check"> </i>}
            </div>
            <span className="grid__item">Don't watch project</span>
          </div>
        </li>
      </DropdownMenu>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    repoActions: bindActionCreators(repoActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchProject);
