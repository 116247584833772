import React, {FunctionComponent} from "react";
import Pagination from "~/components/Pagination";
import PageSizeDropdown from "~/containers/PageSizeDropdown";

interface ModalPaginationProps {
  page : any;
  handlePageClick: (...args: any[]) => any;
  handlePageSizeChange: (...args: any[]) => any;
}

const ModalPagination : FunctionComponent<ModalPaginationProps> = props =>{

  const page = props.page;
  const itemsDisplayed =  (page.number +1 ) * page.size > page.total_elements ? page.total_elements : (page.number +1 ) * page.size;

  return(
    <div>
      <div className="col-1-1 " data-automation-id="ApplicationTable-report-top ">
        <div className="grid_item col-1-1 p-- flex flex--justify-content--space-between">
          <div className="flex align-items--center" >
            <p> {page.number * page.size + 1 } - { itemsDisplayed  } of { page.total_elements } </p>
          </div>
          <div className="flex">
            <Pagination
              currentPage={page.number}
              totalPages={page.total_pages || 1}
              handlePageClick={page => props.handlePageClick(page)}
            />
          </div>
          <div>
            <PageSizeDropdown
              onChange={props.handlePageSizeChange}
              value={5}
              isSmall={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPagination;
