import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import { ResponseError } from 'superagent';
import { pickBy } from 'lodash';
import {
  START_A_SCAN_STATE_REQUEST,
  START_A_SCAN_STATE_SUCCESS,
  START_A_SCAN_STATE_FAILURE,
} from '~/actions/vcAppActions/vcAppActionsTypes/types';
import { VCPermissions, StartAScanResponse } from '~/reducers/vcAppState/vcAppStateTypes/types';

export const startAScanStateRequest = () => ({
  type: START_A_SCAN_STATE_REQUEST,
});

export const startAScanStateSuccess = (permissions: VCPermissions) => ({
  type: START_A_SCAN_STATE_SUCCESS,
  permissions,
});

export const startAScanStateFailure = (error: ResponseError) => ({
  type: START_A_SCAN_STATE_FAILURE,
  message: error.message || 'Error fetching Start a Scan state.',
});

export const gatherVeracodeMePermissions = () => (dispatch: any, getState: any) => {
  const {
    myState: { me },
  } = getState();

  const {
    permissions: { scaUpload, startVeracodeStaticScan },
  } = me;

  dispatch(startAScanStateRequest());
  return (ApiService as any)
    .get('/me/start-scan-state')
    .then((response: StartAScanResponse) => {
      const {
        permissions: { orgAgents, workspaceAgents, scaCreateApplication },
      } = response;

      // Get all permissions that vcMe has access to
      const vcMeWithPermissions: VCPermissions = pickBy(
        {
          startVeracodeStaticScan,
          orgAgents,
          workspaceAgents,
          scaUpload,
          scaCreateApplication,
        },

        (value: boolean) => value
      );

      dispatch(startAScanStateSuccess(vcMeWithPermissions));
    })
    .catch((error: ResponseError) => {
      dispatch(startAScanStateFailure(error));
      ErrorService.capture('Error fetching Start a Scan state', error);
    });
};
