import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import config from '~/config';
import { FEATURE_SLUG_MAP, SOURCECLEAR_COMPONENTS } from '~/constants/ModelConstants';
import Helpers from '~/utils/Helpers';
import RegistryAuthHelper from '~/utils/RegistryAuthHelper';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import VeracodeLogo from '~/images/sca-veracode-logo-white.png';

import Tooltip from '~/components/Tooltip';
import TrialNotifications from '~/containers/TrialNotifications';
import RegistryLink from '~/components/RegistryLink';
import Logout from '~/components/Logout';
import LogoutWrapper from '~/components/LogoutWrapper';

import * as navActions from '~/actions/navigation';
import * as meActions from '~/actions/me';
import * as teamActions from '~/actions/team';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';

const LogoutButton = LogoutWrapper(Logout);
interface LoggedInHeaderProps extends RouteComponentProps {
  navActions: { [key: string]: any };
  teamActions: object;
  meActions: object;
  myState: App.MyState;
  navigationState: { [key: string]: any };
  orgState: App.OrgState;
  teamState: object;
  vcPageState: VCPageState;
}

class LoggedInHeader extends React.Component<LoggedInHeaderProps, {}> {
  constructor(props, context) {
    super(props, context);

    (this as any).nameToDisplay = 'My Account';
    (this as any).catalogSearchRef = React.createRef();
  }

  componentDidMount() {
    document.body.addEventListener('click', this.closeSettingsDropdown);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.closeSettingsDropdown);
  }

  focusSearchBar = () => {
    (this as any).catalogSearchRef.current.focus();
    this.expandSearchBar();
  };

  expandSearchBar = () => {
    this.props.navActions.expandSearchBar(true);
  };

  collapseSearchBar = () => {
    const self = this;
    setTimeout(function () {
      self.props.navActions.expandSearchBar(false);
    }, 200);
  };

  closeSettingsDropdown = e => {
    if (
      e.target.id !== 'settingsDropdown' &&
      e.target.parentElement.id !== 'settingsDropdown' &&
      this.props.navigationState.settingsDropdownExpanded
    ) {
      this.props.navActions.toggleSettingsDropdown();
    }
  };

  toggleSettingsDropdown = () => {
    this.props.navActions.toggleSettingsDropdown();
  };

  updateDisplayName() {
    const { myState } = this.props;
    const { me = {}, isFormDirty = false } = myState;

    if (!isFormDirty && me.firstName && me.lastName) {
      return (this.nameToDisplay = me.firstName + ' ' + me.lastName);
    } else if (isFormDirty && this.nameToDisplay) {
      return this.nameToDisplay;
    } else {
      return 'My Account';
    }
  }

  render() {
    const {
      myState,
      navigationState,
      location,
      teamState,
      orgState,
      vcPageState: { shouldShowVeracodePage },
    } = this.props;
    const { teams = [] } = teamState;
    const {
      searchBarExpand,
      settingsDropdownExpanded,
      activeTeamParent: navTeamId,
    } = navigationState;
    const { org = {} } = orgState;
    const { me = {} } = myState;
    const { navPermissions = {}, permissions: myPermissions = {} } = me;
    const { update: myUpdate } = myPermissions;
    const { orgSettings } = navPermissions;
    const { permissions = {} } = org;
    const { update } = permissions;
    const hasMeEula = !!me.eula && !!me.eula.acceptedVersion;
    const acceptedEula = !!me.eulaAcceptanceDate || hasMeEula;
    const nameToDisplay = this.updateDisplayName();
    const { pathname = '' } = location;
    const isHome = pathname.includes('/portfolio');
    const isOrgSettings = pathname.includes('/org/settings');
    const isPersonalSettings = pathname.startsWith('/settings');
    const VeracodePortfolio = FeatureFlagHelper.isFeatureEnabledForOrg(
      FEATURE_SLUG_MAP.VERACODE_PORTFOLIO,
      org
    );

    const personalSettingsUrl = myUpdate ? `/settings/profile` : `/settings/notifications`;
    const registryUrl = shouldShowVeracodePage ? config.VC_REGISTRY_URL : config.REGISTRY_URL;

    // If a user lands on a bogus team, the home button should not bring them back to the bogus team.
    const teamId =
      navTeamId && teams.some(team => team.id === navTeamId)
        ? navTeamId
        : teams.length && teams[0].id;

    return (
      <section className="container--full bg-color--black-dark height--68">
        <section className="container h--100">
          <div className="grid grid--middle grid--full h--100">
            <div className="grid__item pr- col-1-6 flex flex--align-items--center position--relative h--100">
              {acceptedEula && (
                <Link
                  className="font--h3 flex flex--align-items--center link--plain"
                  to={teamId ? `/portfolio` : '/no-workspaces'}
                >
                  <img src={VeracodeLogo} alt="Veracode" className="vc-logo" />
                </Link>
              )}
              {!acceptedEula && (
                <div className="font--h3 flex flex--align-items--center cursor--not-allowed">
                  <img src={VeracodeLogo} alt="Veracode" className="vc-logo" />
                </div>
              )}
              
            </div>
            <div className="grid__item flex flex--justify-content--space-between flex--align-items--center">
              <div className="col-1-5 top--nav" >
              </div>
              <div className="font--h4 col-4-5 flex flex--align-items--center flex--justify-content--end top--nav">
                <li className="color--white navbar__item">
                  <Link
                    to={teamId ? `/portfolio` : '/no-workspaces'}
                    className={`btn font--h6 ph- ${isHome ? 'selected' : ''}`}
                  >
                    Home
                  </Link>
                </li>
                <li className="color--white navbar__item">
                  <RegistryLink
                    className={'btn font--h6 ph- mr--'}
                    href={`${registryUrl}/${SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.slug}/search`}
                  >
                    {SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.name}
                  </RegistryLink>
                </li>
                z<div className="bo-l--1 border-color--muted height--20" />
                <ul className="inline-block m0 pl0">
                  <li className="color--white navbar__item inline">
                    <a
                      href="mailto:support@veracode.com?subject=Request For SourceClear Support"
                      className="btn ph-- text--center"
                    >
                      <Tooltip id={`contact-support`} content="Contact Support">
                        <i
                          className="sci sci__chat--help sci--fw font--h6 p0"
                          alt="Contact Support"
                        />
                      </Tooltip>
                    </a>
                  </li>
                  <li className="color--white navbar__item inline">
                    <a href="https://help.veracode.com" className="btn ph- text--center">
                      <Tooltip id={`documentation`} content="Documentation">
                        <i className="sci sci--fw sci__catalog font--h6 p0" alt="Documentation" />
                      </Tooltip>
                    </a>
                  </li>
                  {orgSettings && (
                    <li className="color--white navbar__item inline manageOrgLink">
                      <Link
                        to={update ? `/org/settings` : `/org/settings/users`}
                        className={`btn ph- text--center ${isOrgSettings ? 'selected' : ''}`}
                        data-e2e={'LoggedInHeader-OrgProfilePage-btn'}
                      >
                        <Tooltip id={`org-settings`} content="Manage Organization">
                          <i className="fas fa-cog font--h6" />
                        </Tooltip>
                      </Link>
                    </li>
                  )}
                </ul>
                <div className="bo-l--1 border-color--muted height--20" />
                <div className="navbar__item">
                  <div
                    id="settingsDropdown"
                    className={`position--relative pl- pv- font--h7 color--white cursor--pointer header-dropdown--light header-dropdown--light-hover ${
                      isPersonalSettings ? 'selected' : ''
                    }`}
                    onClick={this.toggleSettingsDropdown}
                    data-e2e={'LoggedInHeader-personalSettingsDropdown'}
                  >
                    <span className="font--h6">{Helpers.cut(nameToDisplay, 20)}</span>
                    <i className="sci sci__caret--down pl-" />
                    <div
                      className={
                        'list--group position--absolute right--0 width--200 zIndex-1--screenForeground' +
                        (settingsDropdownExpanded ? ' is-showing--100' : ' is-hiding')
                      }
                    >
                      <div className="list--group__item bg-color--white p0 color--black">
                        {acceptedEula && (
                          <Link
                            to={personalSettingsUrl}
                            className="link--no-underline grid grid--full grid--middle p-"
                            onClick={this.toggleSettingsDropdown}
                          >
                            <div className="grid__item col-1-6">
                              <i className="sci sci__settings" />
                            </div>
                            <div
                              className="grid__item"
                              data-e2e={'LoggedInHeader-personalSettings'}
                            >
                              Personal settings
                            </div>
                          </Link>
                        )}
                        {!acceptedEula && (
                          <div className="link--no-underline grid grid--full grid--middle p- cursor--not-allowed">
                            <div className="grid__item col-1-6">
                              <i className="sci sci__settings" />
                            </div>
                            <div
                              className="grid__item"
                              data-e2e={'LoggedInHeader-personalSettings'}
                            >
                              Personal settings
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="list--group__item bg-color--white p0 color--black cursor--pointer">
                        <LogoutButton {...this.props} />
                      </div>
                    </div>
                  </div>
                </div>
                {VeracodePortfolio && (
                  <div className="color--white navbar__item">
                    <Link to="/vcintegration/vcTopNav/vcworkspaces" className="btn font--h6">
                      Veracode
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          <TrialNotifications />
        </section>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    myState: state.myState,
    navigationState: state.navigationState,
    orgState: state.orgState,
    teamState: state.teamState,
    vcPageState: state.vcPageState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navActions: bindActionCreators(navActions as any, dispatch),
    teamActions: bindActionCreators(teamActions as any, dispatch),
    meActions: bindActionCreators(meActions as any, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoggedInHeader));
