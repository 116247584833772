import { Response } from 'superagent';
import { VCPermissions, LinkedApplication } from '~/reducers/vcAppState/vcAppStateTypes/types';
import { VCNavigation } from '~/utils/navServiceHelper.types';

export const VC_DROPDOWN_MENU_OPEN = 'VC_DROPDOWN_MENU_OPEN';
export const VC_DROPDOWN_MENU_CLOSE = 'VC_DROPDOWN_MENU_CLOSE';
export const FETCH_VC_NAV_REQUEST = 'FETCH_VC_NAV_REQUEST';
export const FETCH_VC_NAV_SUCCESS = 'FETCH_VC_NAV_SUCCESS';
export const FETCH_VC_NAV_FAILURE = 'FETCH_VC_NAV_FAILURE';
export const UPDATE_VC_PAGE_VISIBILITY = 'UPDATE_VC_PAGE_VISIBILITY';
export const FETCH_VC_APPLICATIONS_REQUEST = 'FETCH_VC_APPLICATIONS_REQUEST';
export const FETCH_VC_APPLICATIONS_SUCCESS = 'FETCH_VC_APPLICATIONS_SUCCESS';
export const FETCH_VC_APPLICATIONS_FAILURE = 'FETCH_VC_APPLICATIONS_FAILURE';
export const FETCH_LINKED_VC_APPLICATION_REQUEST = 'FETCH_LINKED_VC_APPLICATION_REQUEST';
export const FETCH_LINKED_VC_APPLICATION_SUCCESS = 'FETCH_LINKED_VC_APPLICATION_SUCCESS';
export const FETCH_LINKED_VC_APPLICATION_FAILURE = 'FETCH_LINKED_VC_APPLICATION_FAILURE';

export const LINK_VC_APPLICATION_REQUEST = 'LINK_VC_APPLICATION_REQUEST';
export const LINK_VC_APPLICATION_SUCCESS = 'LINK_VC_APPLICATION_SUCCESS';
export const LINK_VC_APPLICATION_FAILURE = 'LINK_VC_APPLICATION_FAILURE';
export const UNLINK_VC_APPLICATION_REQUEST = 'UNLINK_VC_APPLICATION_REQUEST';
export const UNLINK_VC_APPLICATION_SUCCESS = 'UNLINK_VC_APPLICATION_SUCCESS';
export const UNLINK_VC_APPLICATION_FAILURE = 'UNLINK_VC_APPLICATION_FAILURE';
export const RESET_UPDATE_LINK_STATES = 'RESET_UPDATE_LINK_STATES';
export const RESET_VC_APPLICATIONS = 'RESET_VC_APPLICATIONS';
export const UPDATE_SELECTED_DEFAULT_APPLICATION = 'UPDATE_SELECTED_DEFAULT_APPLICATION';
export const START_A_SCAN_STATE_REQUEST = 'START_A_SCAN_STATE_REQUEST';
export const START_A_SCAN_STATE_SUCCESS = 'START_A_SCAN_STATE_SUCCESS';
export const START_A_SCAN_STATE_FAILURE = 'START_A_SCAN_STATE_FAILURE';
export const RESET_SELECTED_DEFAULT_APPLICATION_VALUE = 'RESET_SELECTED_DEFAULT_APPLICATION_VALUE';
export const ADD_LINKED_APPLICATION = 'ADD_LINKED_APPLICATION';

interface VCDropdownMenuOpenAction {
  type: typeof VC_DROPDOWN_MENU_OPEN;
  id: number | null;
}

interface AddLinkedApplicationAction {
  type: typeof ADD_LINKED_APPLICATION;
  application;
}

interface VCDropdownMenuCloseAction {
  type: typeof VC_DROPDOWN_MENU_CLOSE;
}

interface FetchVeracodeNavRequestAction {
  type: typeof FETCH_VC_NAV_REQUEST;
}

interface FetchVeracodeNavSuccessAction {
  type: typeof FETCH_VC_NAV_SUCCESS;
  navigation: VCNavigation;
}
interface FetchVeracodeNavFailureAction {
  type: typeof FETCH_VC_NAV_FAILURE;
  message: string;
}

interface FetchVeracodeApplicationsRequestAction {
  type: typeof FETCH_VC_APPLICATIONS_REQUEST;
}
interface FetchVeracodeApplicationsSuccessAction {
  type: typeof FETCH_VC_APPLICATIONS_SUCCESS;
  applications: Array<LinkedApplication>;
}
interface FetchVeracodeApplicationsFailureAction {
  type: typeof FETCH_VC_APPLICATIONS_FAILURE;
  message: string;
}

interface FetchLinkedVeracodeApplicationRequestAction {
  type: typeof FETCH_LINKED_VC_APPLICATION_REQUEST;
}

interface FetchLinkedVeracodeApplicationSuccessAction {
  type: typeof FETCH_LINKED_VC_APPLICATION_SUCCESS;
  linkedApp: LinkedApplication;
}
interface FetchLinkedVeracodeApplicationFailureAction {
  type: typeof FETCH_LINKED_VC_APPLICATION_FAILURE;
  message: string;
}

interface LinkVcApplicationRequestAction {
  type: typeof LINK_VC_APPLICATION_REQUEST;
}

interface LinkVcApplicationSuccessAction {
  type: typeof LINK_VC_APPLICATION_SUCCESS;
  linkedApp: LinkedApplication;
}

interface LinkVcApplicationFailureAction {
  type: typeof LINK_VC_APPLICATION_FAILURE;
  message: string;
}

interface UnlinkVcApplicationRequestAction {
  type: typeof UNLINK_VC_APPLICATION_REQUEST;
}

interface UnlinkVcApplicationSuccessAction {
  type: typeof UNLINK_VC_APPLICATION_SUCCESS;
}

interface UnlinkVcApplicationFailureAction {
  type: typeof UNLINK_VC_APPLICATION_FAILURE;
  message: string;
}

interface ResetUpdateLinkStatesAction {
  type: typeof RESET_UPDATE_LINK_STATES;
}

interface ResetVCApplicationsAction {
  type: typeof RESET_VC_APPLICATIONS;
}
interface UpdateSelectedDefaultApplicationAction {
  type: typeof UPDATE_SELECTED_DEFAULT_APPLICATION;
  selectedApplication: LinkedApplication;
}

interface ResetSelectedDefaultApplicationAction {
  type: typeof RESET_SELECTED_DEFAULT_APPLICATION_VALUE;
}

interface StartAScanRequestAction {
  type: typeof START_A_SCAN_STATE_REQUEST;
}

interface StartAScanSuccessAction {
  type: typeof START_A_SCAN_STATE_SUCCESS;
  permissions: VCPermissions;
}

interface StartAScanFailureAction {
  type: typeof START_A_SCAN_STATE_FAILURE;
  message: string;
}

interface UpdateVCPageVisibility {
  type: typeof UPDATE_VC_PAGE_VISIBILITY;
  isVisible: boolean;
}

export interface VCApplicationsResponse {
  _embedded: {
    applications: Array<Response>;
  };
  _links: {
    [key: string]: {
      href: string;
    };
  };
  page: Page;
}

export type Page = {
  size: number;
  total_elements: number;
  total_pages: number;
  number: number;
};

export type LinkedApplicationResponse = {
  appId: string;
  id: string;
};

export type VCDropdownMenuActionTypes = VCDropdownMenuOpenAction | VCDropdownMenuCloseAction;

export type VCNavActionTypes =
  | FetchVeracodeNavRequestAction
  | FetchVeracodeNavSuccessAction
  | FetchVeracodeNavFailureAction;

export type VCPageActionTypes = UpdateVCPageVisibility;

export type VCMeActionTypes = StartAScanSuccessAction;

export type VCApplicationsActionTypes =
  | FetchVeracodeApplicationsRequestAction
  | FetchVeracodeApplicationsSuccessAction
  | FetchVeracodeApplicationsFailureAction
  | LinkVcApplicationRequestAction
  | LinkVcApplicationSuccessAction
  | LinkVcApplicationFailureAction
  | UnlinkVcApplicationRequestAction
  | UnlinkVcApplicationSuccessAction
  | UnlinkVcApplicationFailureAction
  | FetchLinkedVeracodeApplicationRequestAction
  | FetchLinkedVeracodeApplicationSuccessAction
  | ResetUpdateLinkStatesAction
  | UpdateSelectedDefaultApplicationAction
  | ResetVCApplicationsAction
  | FetchLinkedVeracodeApplicationFailureAction
  | StartAScanRequestAction
  | StartAScanSuccessAction
  | StartAScanFailureAction
  | AddLinkedApplicationAction
  | ResetSelectedDefaultApplicationAction;
