import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import SelectDropDown from '~/containers/SelectDropDown';
import { PolicyDetail } from '~/types/UnifiedPolicy';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/reducers';
import { addToastr } from '~/actions/toastr';
import {
  fetchAssignedPolicy,
  fetchPolicies,
  saveUnifiedPolicyAssignment,
} from '~/actions/unifiedPolicies';
import { TeamPolicyState } from '~/reducers/unifiedPoliciesState.types';
import { StylesConfig } from 'react-select/src/styles';

const PAGE_NAME = 'Policy Assignment';
const PAGE_DESCRIPTION =
  'To manage policies, navigate to the Policies page using the top navigation menu. If you do not have permissions to view policies, contact your Veracode administrator.';
const ASSIGN_POLICY_TITLE = 'Assign Policy';
const ASSIGN_POLICY_DESCRIPTION = 'Assign a policy to this workspace.';
const DEFAULT_POLICIES_LABEL = 'Default Policies';
const CUSTOM_POLICIES_LABEL = 'Custom Policies';

enum PolicyType {
  CUSTOMER = 'CUSTOMER',
  BUILTIN = 'BUILTIN',
}

const toGroupedOptions = (policies: PolicyDetail[]) => {
  // dummy invisible options to force the select dropdown to always show the groups and not 'no results found'
  // the hide boolean is used in the groupStyle below to show display: none for the option
  const invisibleOption = [{ label: undefined, value: undefined, hide: true }];
  const builtInPolicies = policies
    .filter(policy => policy.type === PolicyType.BUILTIN)
    .map(policy => ({ label: policy.name, value: policy }));
  const customPolicies = policies
    .filter(policy => policy.type === PolicyType.CUSTOMER)
    .map(policy => ({ label: policy.name, value: policy }));
  return [
    {
      label: DEFAULT_POLICIES_LABEL,
      options: builtInPolicies.length > 0 ? builtInPolicies : invisibleOption,
    },
    {
      label: CUSTOM_POLICIES_LABEL,
      options: customPolicies.length > 0 ? customPolicies : invisibleOption,
    },
  ];
};

const getSaveErrorToastConfig = () =>
  getErrorToastConfig(
    'UNIFIED_POLICY_SAVE_ERROR',
    'Error assigning policy',
    'We were unable to assign your policy. Please try again.'
  );

const getFetchErrorToastConfig = () =>
  getErrorToastConfig(
    'UNIFIED_POLICY_FETCH_ERROR',
    'Error retrieving policies',
    'We were unable to retrieve policies. Please try again.'
  );

const getSyncErrorToastConfig = () =>
  getErrorToastConfig(
    'UNIFIED_POLICY_SYNC_ERROR',
    'Error retrieving policies',
    'We were unable to retrieve the assigned policy. Please contact Veracode support.'
  );

const getErrorToastConfig = (id: string, title: string, message: string) => {
  return {
    id,
    level: 'error',
    title,
    message,
    allowTimeout: true,
  };
};

const forceGroupStyles: StylesConfig = {
  option: (styles, { data }) => ({
    ...styles,
    display: data.hide ? 'none' : 'inherit',
  }),
};

const getTeamState = (teamPolicies: TeamPolicyState | undefined): TeamPolicyState => {
  const assignedPolicy = teamPolicies?.assignedPolicy;
  const isFetchingAssignedPolicy = teamPolicies?.isFetchingAssignedPolicy;
  const isFetchAssignedPolicyFailed = teamPolicies?.isFetchAssignedPolicyFailed;
  const isSyncFailed = teamPolicies?.isSyncFailed;
  const isSaving = teamPolicies?.isSaving;
  const isSaveFailed = teamPolicies?.isSaveFailed;
  const isSaved = teamPolicies?.isSaved;
  return {
    assignedPolicy,
    isFetchingAssignedPolicy,
    isFetchAssignedPolicyFailed,
    isSyncFailed,
    isSaving,
    isSaveFailed,
    isSaved,
  };
};

const UnifiedWorkspacePoliciesPage = () => {
  let { teamId } = useParams();
  const { isFetchingPolicies, policies, teamPolicies, isFetchPoliciesFailed } = useSelector(
    (state: RootState) => state.unifiedPoliciesState
  );
  const {
    assignedPolicy,
    isFetchingAssignedPolicy,
    isFetchAssignedPolicyFailed,
    isSyncFailed,
    isSaving,
    isSaveFailed,
    isSaved,
  } = getTeamState(teamPolicies[teamId]);
  const isFetching = isFetchingPolicies || isFetchingAssignedPolicy;
  const isFetchFailed = isFetchPoliciesFailed || isFetchAssignedPolicyFailed;
  const dispatch = useDispatch();
  const [selectedPolicy, setSelectedPolicy] = useState<PolicyDetail>(null);
  useEffect(() => {
    if (policies.length === 0 || isFetchPoliciesFailed) {
      dispatch(fetchPolicies());
    }
  }, []);
  useEffect(() => {
    const canAttemptToSync = policies.length > 0 && !isSyncFailed;
    if (canAttemptToSync) {
      if (assignedPolicy == null || isFetchAssignedPolicyFailed) {
        dispatch(fetchAssignedPolicy(teamId));
      }
    }
  }, [policies]);
  // set the selected to the initialPolicy when it changes
  useEffect(() => {
    setSelectedPolicy(assignedPolicy);
  }, [assignedPolicy]);

  useEffect(() => {
    if (isSaveFailed) {
      dispatch(addToastr(getSaveErrorToastConfig()));
    } else if (isFetchFailed) {
      dispatch(addToastr(getFetchErrorToastConfig()));
    } else if (isSyncFailed) {
      dispatch(addToastr(getSyncErrorToastConfig()));
    }
  }, [isSaveFailed, isFetchFailed, isSyncFailed]);

  const groupedOptions = toGroupedOptions(policies);
  const handleSavePolicy = () => {
    dispatch(saveUnifiedPolicyAssignment(teamId, selectedPolicy));
  };
  const isSavable =
    !isFetchFailed &&
    !isFetching &&
    !isSaving &&
    selectedPolicy != null &&
    selectedPolicy !== assignedPolicy;
  return (
    <div className="mt unified-workspace-policy">
      <Helmet>
        <title>{PAGE_NAME}</title>
      </Helmet>
      <h3 data-automation-id="OrgPoliciesPage-Title">{PAGE_NAME}</h3>
      <span>{PAGE_DESCRIPTION}</span>
      <h4 className="mt-25">{ASSIGN_POLICY_TITLE}</h4>
      <hr className="policy-hr" />
      <div className="col-1-3 inline-block">
        <label htmlFor="policy">{ASSIGN_POLICY_DESCRIPTION}</label>
        <SelectDropDown
          fieldName="policies"
          name="a policy..."
          items={groupedOptions}
          value={selectedPolicy}
          onChange={(_: unknown, item: PolicyDetail) => setSelectedPolicy(item)}
          withGroupedOptions={true}
          isLoading={isFetching}
          isClearable={false}
          isDisabled={isFetchFailed}
          inputId={'policy'}
          styles={forceGroupStyles}
        />
      </div>

      <div className="button-container">
        <button
          type="submit"
          disabled={!isSavable}
          className="ph pv- btn btn--success font-family--body-light font--h7"
          onClick={handleSavePolicy}
        >
          Save
        </button>
        {isSaving && (
          <i
            className="fa sci--sm fa-spin fa-spinner mh- color--primary font--14 ph0"
            data-testid="spinner"
          />
        )}
        {isSaved && (
          <i
            className="fa sci--sm sci sci__check mh- color--primary font--14 ph0"
            data-testid="saveCheck"
          />
        )}
        {isSaveFailed && (
          <i
            className="fa sci--sm sci sci__close mh- color--danger font--14 ph0"
            data-testid="failX"
          />
        )}
      </div>
    </div>
  );
};

export default UnifiedWorkspacePoliciesPage;
