import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SourceClearModal from '~/components/SourceClearModal';

import * as modalActions from '~/actions/modal';

const MODAL_TYPE = 'OVERAGES_MODAL';

interface OveragesModalProps {
  modalActions: object;
  orgState: App.OrgState;
  modalState: object;
}
class OveragesModal extends React.Component<OveragesModalProps, {}> {
  onClose() {
    this.props.modalActions.closeModal(MODAL_TYPE);
  }

  render() {
    const { modalState, orgState } = this.props;
    const { org = {} } = orgState;
    const { license = {} } = org;
    const { usage = {} } = license;
    const limits = license.limits || {};
    const options = ['repos', 'teams', 'users'];
    const messages = [];
    const messageMap = {
      repos: 'Projects used',
      teams: 'Workspaces used',
      users: 'Seats assigned',
    };

    options.forEach(option => {
      if (limits[option] && usage[option] > limits[option]) {
        messages.push(`${messageMap[option]}: ${usage[option]} of ${limits[option]}`);
      }
    });

    const { openedModal } = modalState;
    const isOpen = !!openedModal[MODAL_TYPE];

    return (
      <SourceClearModal
        isOpen={isOpen}
        title="Plan limits exceeded"
        onClose={() => this.onClose()}
        width={660}
        closeWhenClickOutside={false}
      >
        <div className="p">
          <div className="">
            Your organization's service usage exceeds plan limits, so scanning is paused. To resume
            scanning, reduce the number of items to within allowed limits.
          </div>
          <ul>
            {messages.map((message, index) => {
              return (
                <li className="mt--" key={`overage-${index}`}>
                  {message}
                </li>
              );
            })}
          </ul>
          <div className="flex justify-content--end pt-">
            <button className="btn btn--success pv- ph+" onClick={() => this.onClose()}>
              Okay
            </button>
          </div>
        </div>
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgState: state.orgState,
    modalState: state.modalState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OveragesModal);
