import React from 'react';
import _ from 'lodash';

interface FullChainDisplayProps {
  callChain: any[];
  startIndex: number;
  isShowing: boolean;
  toggleFullCallChain: (...args: any[]) => any;
}

const FullChainDisplay: React.FunctionComponent<FullChainDisplayProps> = props => {
  const { callChain, startIndex, isShowing } = props;
  function closeVulnerableMethodChain() {
    props.toggleFullCallChain('');
  }

  let applicableCallChainItems = _.slice(_.cloneDeep(callChain), startIndex);
  let chainLines = [];
  let extraHideChainLink;

  applicableCallChainItems.forEach((chainItem, index) => {
    const isLast = index === applicableCallChainItems.length - 1;
    const { caller, callee, lineNumber = 0 } = chainItem;
    const { methodName = '', className = '', moduleName = '', descriptor = '' } = caller;
    const {
      methodName: calleeMethod = '',
      descriptor: calleeDescriptor = '',
      className: calleeClass = '',
      moduleName: calleeModule = '',
    } = callee;
    let fullDescription, calleeDescription;

    if (methodName && className && descriptor && lineNumber) {
      // java
      fullDescription =
        '' + className.replace(/\//g, '.') + '.' + methodName + descriptor + '#' + lineNumber;
    } else if (moduleName && lineNumber) {
      // python
      fullDescription = `${moduleName}${methodName ? '.' + methodName : ''}#${lineNumber}`;
    }

    if (fullDescription) {
      chainLines.push(
        <div
          className="font-family--code grid__item col-1-1 word-wrap"
          key={'chain-item--' + index}
        >
          {fullDescription}
        </div>
      );
      chainLines.push(
        <div key={'arrow--' + index} className="grid__item col-1-1 text--center">
          <i className="sci sci--fw sci--sm sci__arrow--down" />
        </div>
      );
    }

    if (isLast && calleeMethod && calleeDescriptor && calleeClass && lineNumber) {
      // java
      calleeDescription =
        '' + calleeClass.replace(/\//g, '.') + '.' + calleeMethod + calleeDescriptor;
      chainLines.push(
        <div
          className="font-family--code grid__item col-1-1 word-wrap color--danger-dark pb-"
          key={'chain-item-callee-' + index}
        >
          {calleeDescription}
        </div>
      );
    } else if (isLast && calleeModule && calleeMethod) {
      // python
      calleeDescription = `${calleeModule}.${calleeMethod}`;
      chainLines.push(
        <div
          className="font-family--code grid__item col-1-1 word-wrap color--danger-dark pb-"
          key={'chain-item-callee-' + index}
        >
          {calleeDescription}
        </div>
      );
    }
  });

  if (applicableCallChainItems.length > 5) {
    extraHideChainLink = (
      <div className="grid__item col-1-1">
        <div className="link--obvious" onClick={closeVulnerableMethodChain}>
          Hide full call chain
        </div>
      </div>
    );
  }

  return (
    <div>
      {!!chainLines.length && (
        <div className={isShowing ? 'is-showing-1000--scroll' : 'isHiding'}>
          <div className="grid pb- mb-- ">
            <div className="grid__item col-1-1">
              <div className="grid">{chainLines}</div>
            </div>
            {extraHideChainLink}
          </div>
        </div>
      )}
      {!chainLines.length && (
        <div className={isShowing ? 'is-showing-1000--scroll' : 'isHiding'}>
          We were not able to calculate the call chain
        </div>
      )}
    </div>
  );
};

export default FullChainDisplay;
