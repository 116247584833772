import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';

export const FILTER_TEAM_USERS = 'FILTER_TEAM_USERS';
export const RESET_SELECTED_TEAM_USERS = 'RESET_SELECTED_TEAM_USERS';
export const RESET_TEAM_USER_QUICK_VIEWS = 'RESET_TEAM_USER_QUICK_VIEWS';
export const TOGGLE_WORKSPACE_INVITE_QUICK_VIEW = 'TOGGLE_WORKSPACE_INVITE_QUICK_VIEW';
export const TOGGLE_WORKSPACE_USER_QUICK_VIEW = 'TOGGLE_WORKSPACE_USER_QUICK_VIEW';
export const UPDATE_SELECTED_TEAM_USERS = 'UPDATE_SELECTED_TEAM_USERS';
export const UPDATE_WORKSPACE_USERS = 'UPDATE_WORKSPACE_USERS';
export const FETCH_TEAM_USERS_REQUEST = 'FETCH_TEAM_USERS_REQUEST';
export const FETCH_TEAM_USERS_FAILURE = 'FETCH_TEAM_USERS_FAILURE';

export const fetchTeamUsers = teamId => dispatch => {
  dispatch(fetchTeamUsersRequest());
  return ApiService.get(ApiConstants.fetchTeamUsersURL(teamId))
    .then(res => {
      dispatch(updateTeamUsers(res));
      return res;
    })
    .catch(error => {
      dispatch(fetchTeamUsersFailure(error));
      ErrorService.capture('Error fetching team users', error);
      return [];
    });
};

export const fetchTeamUsersRequest = () => ({
  type: FETCH_TEAM_USERS_REQUEST,
});

export const fetchTeamUsersFailure = error => ({
  type: FETCH_TEAM_USERS_FAILURE,
  message: error.message || 'Something went wrong. Please try again.',
});

export const removeUsers = (users, teamId) => dispatch => {
  return ApiService.post(`/teams/${teamId}/remove-members`, { data: users })
    .then(res => {
      dispatch(fetchTeamUsers(teamId));
      return res;
    })
    .catch(error => {
      ErrorService.capture('Error removing users', error);
    });
};

export const updateTeamMembers = (data, teamId) => dispatch => {
  return ApiService.post(`/teams/${teamId}/update-members`, { data })
    .then(() => {
      dispatch(fetchTeamUsers(teamId));
      return { success: true };
    })
    .catch(error => {
      ErrorService.capture('Error updating team members', error);
    });
};

export const updateTeamUsers = users => ({
  type: UPDATE_WORKSPACE_USERS,
  users,
});

export const updateSelectedUsers = userId => ({
  type: UPDATE_SELECTED_TEAM_USERS,
  userId,
});

export const resetSelectedTeamUsers = () => ({
  type: RESET_SELECTED_TEAM_USERS,
});

export const filterTeamUsers = value => ({
  type: FILTER_TEAM_USERS,
  value,
});

export const toggleUserQuickView = (userId, column) => ({
  type: TOGGLE_WORKSPACE_USER_QUICK_VIEW,
  userId,
  column,
});

export const resetTeamUserQuickViews = () => ({
  type: RESET_TEAM_USER_QUICK_VIEWS,
});

export const toggleWorkspaceInviteQuickView = (inviteId, column) => ({
  type: TOGGLE_WORKSPACE_INVITE_QUICK_VIEW,
  inviteId,
  column,
});
