import React from 'react';
import { Link } from 'react-router-dom';
import SourceClearLoader from '~/components/SourceClearLoader';

interface UserTeamsQuickViewProps {
  data?: any[];
  showDetailsLink?: boolean;
  showGroups?: boolean;
}

const UserTeamsQuickView: React.FunctionComponent<UserTeamsQuickViewProps> = props => {
  const { data: teams = [], showDetailsLink, showGroups } = props;
  const columnWidths = {
    name: showGroups ? 'col-1-4' : 'col-1-2',
    admin: '',
    groups: '',
    direct: '',
  };

  if (!teams.length) {
    return (
      <div className="">
        <SourceClearLoader />
      </div>
    );
  }

  return (
    <div className="bo-v--1 mv-- pb- border-color--muted">
      <div className="col-1-1 bg-color--muted-light pv-- font--h7 pl- color--muted-dark">
        <strong>WORKSPACES</strong>
      </div>
      <div className="grid mt-">
        <div className={`grid__item ${columnWidths.name}`}>
          <strong>Name</strong>
        </div>
        <div className={`grid__item text--center ${columnWidths.admin}`}>
          <strong>Workspace Admin</strong>
        </div>
        {showGroups && (
          <div className={`grid__item text--center ${columnWidths.groups}`}>
            <strong>Access Through Team</strong>
          </div>
        )}
        {showGroups && (
          <div className={`grid__item text--center ${columnWidths.direct}`}>
            <strong>Direct Assignment</strong>
          </div>
        )}
      </div>

      <div className="max-height--300 overflow--auto">
        {teams.map(teamObj => {
          const { team = {} } = teamObj;
          const membership = teamObj.membership || {};
          const { roles = [], numGroups, direct } = membership;
          const { name, id: teamId } = team;

          return (
            <div className="grid mt--" key={`user-team-${teamId}`}>
              <div className={`grid__item ${columnWidths.name}`}>
                {showDetailsLink ? (
                  <strong>
                    <Link to={`/org/settings/workspaces/${teamId}`} className="link--obvious">
                      {name}
                    </Link>
                  </strong>
                ) : (
                  <div>{name}</div>
                )}
              </div>
              <div className={`grid__item text--center ${columnWidths.admin}`}>
                {roles.includes('WORKSPACE_ADMIN') ? 'Yes' : '--'}
              </div>
              {showGroups && (
                <div className={`grid__item text--center ${columnWidths.groups}`}>
                  {numGroups} team
                  {numGroups === 1 ? '' : 's'}
                </div>
              )}
              {showGroups && (
                <div className={`grid__item text--center ${columnWidths.direct}`}>
                  {direct ? 'Yes' : '--'}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserTeamsQuickView;
