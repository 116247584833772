import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { VCLogout } from '~/views/components/veracode/VCLogout';
import VCNavIcon from '~/views/components/veracode/VCNavIcon';
import LogoutWrapper from '~/components/LogoutWrapper';
import { veracodeLogout } from '~/constants/ModelConstants';
import config from '~/config';
import { VCDropdownState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import { VCIconComponentIcon } from '~/types/VCIconComponentIcon';
import { constructUrlFromNavServiceClickObj } from '~/utils/navServiceHelper';

const LogoutLink = LogoutWrapper(VCLogout);

interface VCDropdownProps {
  label?: string;
  id: number;
  vcDropdownState: VCDropdownState;
  toggleOpen: (...args: any[]) => void;
  toggleClose: (...args: any[]) => void;
  icon: VCIconComponentIcon;
  maxWidth?: number;
  dropdownMenuItems?: any[];
  align?: string;
  isActive: boolean;
  isAccountMenu: boolean;
}

export class VCDropdown extends PureComponent<VCDropdownProps, {}> {
  isDropdownOpen = () => {
    return this.props.id === this.props.vcDropdownState.vcDropdownId || false;
  };

  toggleOpen = () => {
    this.props.toggleOpen(this.props.id);
  };

  toggleClose = () => {
    this.props.toggleClose(this.props.id);
  };

  render() {
    const { maxWidth, label, align, dropdownMenuItems = [], icon } = this.props;
    return (
      <div
        className="vcDropdown"
        onMouseEnter={() => this.toggleOpen()}
        onMouseLeave={() => this.toggleClose()}
      >
        {icon && <VCNavIcon icon={icon} />}
        <span className="dropdown-label" style={{ maxWidth }}>
          {label}
        </span>

        <span className="fas fa-chevron-down caret" />

        {this.isDropdownOpen() && dropdownMenuItems.length > 0 && (
          <ul
            className={classNames('dropdown-menu', {
              'right-align': align === 'right',
            })}
          >
            {dropdownMenuItems.map((item, index) => {
              const { click, label, disabled } = item;
              let urlLink = constructUrlFromNavServiceClickObj(click);
              let menuItemClassNames = classNames({
                'section-header': item.isSectionHeader,
              });
              if (item.isSectionHeader) {
                return (
                  <li key={index} className={menuItemClassNames}>
                    {label}
                  </li>
                );
              }
              if (item.isLogoutLink) {
                return (
                  <LogoutLink
                    key={index}
                    url={`${config.VERACODE_LOGIN_HOST}${veracodeLogout}`}
                    label={label}
                    disabled={disabled}
                  />
                );
              } else {
                return (
                  <li key={index} className={menuItemClassNames}>
                    <a
                      href={urlLink}
                      className={classNames({
                        disabled,
                        'manual-scans': item.label === 'Manual Penetration Testing',
                      })}
                    >
                      {label}
                    </a>
                  </li>
                );
              }
            })}
          </ul>
        )}
      </div>
    );
  }
}
