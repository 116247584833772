import {
  withManageAgentCheck,
  withEnforceRulesCheck,
  withPermissionsCheck,
  withVeracodeUserCheck,
  withLibraryCatalogCheck,
  withOrgPermissionCheck,
  withStartScanPermissionCheck,
  withEnforceWorkspaceRulesCheck,
} from '~/utils/Permissions';

/* containers */
import AcceptInvitePage from '~/containers/AcceptInvitePage';
import AccountLockedPage from '~/containers/AccountLockedPage';
import PageWrapper from '~/containers/PageWrapper';
import AgentConfigPage from '~/containers/AgentConfigPage';
import AgentSetupPage from '~/containers/AgentSetupPage';
import AgentSummaryPage from '~/containers/AgentSummaryPage';
import BillingEmailConfirmPage from '~/containers/BillingEmailConfirmPage';
import EmailVerifyPage from '~/containers/EmailVerifyPage';
import FindMyOrgPage from '~/containers/FindMyOrgPage';
import ForgotPasswordPage from '~/containers/ForgotPasswordPage';
import OrgTeamDetailsPage from '~/containers/OrgTeamDetailsPage';
import OrgTeamsPage from '~/containers/OrgTeamsPage';
import HomePage from '~/containers/HomePage';
import InvoiceDetailsPage from '~/containers/InvoiceDetailsPage';
import IssuesPage from '~/containers/IssuesPage';
import IssuesLibrariesDetailsPage from '~/containers/IssuesLibrariesDetailsPage';
import IssuesLicensesDetailsPage from '~/containers/IssuesLicensesDetailsPage';
import IssuesVulnerabilitiesDetailsPage from '~/containers/IssuesVulnerabilitiesDetailsPage';
import LibraryCatalogPage from '~/containers/LibraryCatalogPage';
import LicensesPage from '~/containers/LicensesPage';
import LoginFlowFork from '~/containers/LoginFlowFork';
import MasterServicesAgreementPage from '~/containers/MasterServicesAgreementPage';
import NewAgentSummary from '~/containers/NewAgentSummary';
import NoAccessPage from '~/components/Errors/NoAccessPage';
import NoAccessPremiumPage from '~/containers/NoAccessPremiumPage';
import NoOrgOwnerPage from '~/components/Errors/NoOrgOwnerPage';
import NoOrgAdminPage from '~/components/Errors/NoOrgAdminPage';
import NotificationSettingsPage from '~/containers/NotificationSettingsPage';
import NotFoundPage from '~/components/Errors/NotFoundPage';
import NoWorkspacePage from '~/containers/NoWorkspacePage';
import NoWorkspaceAdminPage from '~/components/Errors/NoWorkspaceAdminPage';
import OrgAdminWrapper from '~/containers/OrgAdminWrapper';
import OrgAgentsPage from '~/containers/OrgAgentsPage';
import OrgBillingPage from '~/containers/OrgBillingPage';
import OrgIntegrationPage from '~/containers/OrgIntegrationPage';
import OrgIntegrationJiraPage from '~/containers/OrgIntegrationJiraPage';
import OrgProfilePage from '~/containers/OrgProfilePage';
import OrgUsagePage from '~/containers/OrgUsagePage';
import OrgUsersPage from '~/containers/OrgUsersPage';
import OrgUserDetailsPage from '~/containers/OrgUserDetailsPage';
import OrgUpgradePage from '~/containers/OrgUpgradePage';
import PasswordResetPage from '~/containers/PasswordResetPage';
import PaymentPage from '~/containers/PaymentPage';
import PersonalSettingsWrapper from '~/containers/PersonalSettingsWrapper';
import PersonalSettingsPage from '~/containers/PersonalSettingsPage';
import PortfolioPage from '~/containers/PortfolioPage';
import ProjectDetailsPage from '~/containers/ProjectDetailsPage';
import RedirectFromScan from '~/containers/RedirectFromScan';
import ReportLibrariesPage from '~/containers/ReportLibrariesPage';
import { Projects } from '~/views/pages/Projects';
import SamlLoginPage from '~/containers/SamlLoginPage';
import SettingsProfilePage from '~/containers/SettingsProfilePage';
import SignupSplitter from '~/containers/SignupSplitter';
import SubscriptionPage from '~/containers/SubscriptionPage';
import SubscriptionUpdatePage from '~/containers/SubscriptionUpdatePage';
import TeamsPage from '~/containers/TeamsPage';
import Workspace from '~/views/layouts/Workspace';
import TrialEndedPage from '~/containers/TrialEndedPage';
import TrialExpiredPaymentPage from '~/containers/TrialExpiredPaymentPage';
import OrgMigratedPage from '~/components/OrgMigratedPage';
import TwoFactorAuth from '~/containers/TwoFactorAuth';
import TwoFactorLoginPage from '~/containers/TwoFactorLoginPage';
import UnsubscribePage from '~/containers/UnsubscribePage';
import UpdatePaymentPage from '~/containers/UpdatePaymentPage';
import UserManagementWrapper from '~/containers/UserManagementWrapper';
import UsersPage from '~/containers/UsersPage';
import VulnerabilitiesPage from '~/containers/VulnerabilitiesPage';
import WelcomeFlowWrapper from '~/containers/WelcomeFlowWrapper';
import WorkspacesPage from '~/containers/WorkspacesPage';
import WorkspaceDetailsPage from '~/containers/WorkspaceDetailsPage';
import NotificationsPage from '~/containers/NotificationsPage';
import WebhookNotificationsManageSection from '~/containers/WebhookNotificationsManageSection';
import WorkspaceWelcomeAgentPage from '~/containers/WorkspaceWelcomeAgentPage';
import WorkspaceWelcomeAgentSetupPage from '~/containers/WorkspaceWelcomeAgentSetupPage';
import WorkspaceWelcomePage from '~/containers/WorkspaceWelcomePage';
import WorkspaceWelcomeScanPage from '~/containers/WorkspaceWelcomeScanPage';
import WorkspaceWelcomeScanHelpPage from '~/containers/WorkspaceWelcomeScanHelpPage';
import ZeroWorkspacesPage from '~/containers/ZeroWorkspacesPage';
import JiraIntegrationSetupCallback from '~/containers/JiraIntegrationSetupCallback';
import APICredentials from '~/containers/APICredentials';
import VCStartScanPage from '~/containers/VCStartScanPage';

/* components */
import RouteNotFound from '~/components/RouteNotFound'; // IMPORTANT!! {component: RouteNotFound} most be included in every sub-routes array

import SunsettedPlanPage from '~/components/Errors/SunsettedPlanPage';
import OrgPoliciesHub from '~/containers/OrgPoliciesHub';
import WorkspacePoliciesHub from '~/containers/WorkspacePoliciesHub';
import ScaPlatformUI from '~/containers/ScaPlatformUI';
import Helpers from '~/utils/Helpers';

/**
 * HOCs : Wrapped Components
 */
const AgentsWrapperWithManageAgentCheck = withManageAgentCheck(PageWrapper);
const PersonalSettingsWithPermissionsCheck = withPermissionsCheck(PersonalSettingsWrapper);

const routes = [
  { path: '/', component: HomePage, exact: true },
  /* public paths */
  { path: '/login', component: withVeracodeUserCheck(LoginFlowFork) },
  { path: '/forgot-password', component: withVeracodeUserCheck(ForgotPasswordPage) },
  { path: '/password-reset', component: withVeracodeUserCheck(PasswordResetPage) },
  { path: '/twofactor', component: withVeracodeUserCheck(TwoFactorLoginPage) },
  { path: '/find-my-org', component: withVeracodeUserCheck(FindMyOrgPage) },
  { path: '/saml', component: withVeracodeUserCheck(SamlLoginPage) },
  { path: '/signup', component: withVeracodeUserCheck(SignupSplitter) },
  { path: '/accept-invite', component: withVeracodeUserCheck(AcceptInvitePage) },
  { path: '/unsubscribe', component: withVeracodeUserCheck(UnsubscribePage) },
  { path: '/email-verify', component: withVeracodeUserCheck(EmailVerifyPage) },
  { path: '/billing/email-verify', component: withVeracodeUserCheck(EmailVerifyPage) },
  { path: '/billing-confirm', component: withVeracodeUserCheck(BillingEmailConfirmPage) },
  { path: '/org-moved', component: withVeracodeUserCheck(OrgMigratedPage) },
  { path: '/plan-unsupported', component: withVeracodeUserCheck(SunsettedPlanPage) },

  /* private paths */
  { path: '/terms', component: withVeracodeUserCheck(MasterServicesAgreementPage) },
  { path: '/not-found', component: NotFoundPage, centeredNoSidebar: true },
  { path: '/no-access', component: NoAccessPage, centeredNoSidebar: true },
  { path: '/no-access-premium', component: NoAccessPremiumPage, centeredNoSidebar: true },
  { path: '/no-workspaces', component: ZeroWorkspacesPage, centeredNoSidebar: true },
  { path: '/portfolio', component: PortfolioPage, centeredNoSidebar: true },
  { path: '/account-locked', component: AccountLockedPage, centeredNoSidebar: true },
  {
    path: '/trial-expired',
    component: withVeracodeUserCheck(TrialEndedPage),
    exact: true,
  },
  {
    path: '/trial-expired/payment',
    component: withVeracodeUserCheck(TrialExpiredPaymentPage),
    centeredNoSidebar: true,
  },

  {
    path: '/settings',
    component: PersonalSettingsWithPermissionsCheck,
    sidebar: 'PERSONAL',
    routes: [
      { path: '/', exact: true, to: '/settings/profile', redirect: true },
      { path: '/profile', component: withVeracodeUserCheck(PersonalSettingsPage) },
      { path: '/two-factor', component: withVeracodeUserCheck(TwoFactorAuth) },
      {
        path: '/notifications',
        component: NotificationSettingsPage,
      },
      { path: '/credentials', component: withVeracodeUserCheck(APICredentials) },
      { component: RouteNotFound },
    ],
  },
  /* team pages */
  {
    path: '/teams/:teamId/welcome',
    from: '/teams',
    to: '/workspaces',
    redirect: true,
  },
  /* workspace pages */
  {
    path: '/workspaces/:teamId/welcome',
    component: WelcomeFlowWrapper,
    centeredNoSidebar: true,
    routes: [
      { path: '/', exact: true, component: WorkspaceWelcomePage },
      { path: '/agent', exact: true, component: WorkspaceWelcomeAgentPage },
      { path: '/agent/:operatingSystem', component: WorkspaceWelcomeAgentSetupPage },
      { path: '/scan', component: WorkspaceWelcomeScanPage },
      { path: '/scan-help', component: WorkspaceWelcomeScanHelpPage },
    ],
  },
  {
    path: '/teams/:teamId',
    from: '/teams/',
    to: '/workspaces/',
    redirect: true,
  },
  {
    path: '/workspaces/:teamId',
    component: Workspace,
    sidebar: 'WORKSPACE',
    routes: [
      { path: '/issues', exact: true, component: IssuesPage },
      {
        path: '/issues/vulnerabilities/:issueId',
        component: IssuesVulnerabilitiesDetailsPage,
      },
      { path: '/issues/libraries/:issueId', component: IssuesLibrariesDetailsPage },
      { path: '/issues/licenses/:issueId', component: IssuesLicensesDetailsPage },
      { path: '/projects', exact: true, component: Projects },
      {
        path: '/projects/:projectId',
        component: ProjectDetailsPage,
        routes: [
          { path: '/issues', component: IssuesPage },
          { path: '/vulnerabilities', component: VulnerabilitiesPage },
          { path: '/libraries', component: ReportLibrariesPage },
          { path: '/licenses', component: LicensesPage },
        ],
      },
      { path: '/vulnerabilities', component: VulnerabilitiesPage },
      { path: '/libraries', component: ReportLibrariesPage },
      { path: '/licenses', component: LicensesPage },
      { path: '/settings', component: SettingsProfilePage },
      { path: '/scans/:scanId', exact: true, component: RedirectFromScan },
      { path: '/scans/:scanId/issues/:page', component: RedirectFromScan },
      {
        path: '/agents',
        component: AgentsWrapperWithManageAgentCheck,
        routes: [
          { path: '/', exact: true, component: AgentSummaryPage },
          { path: '/new', exact: true, component: NewAgentSummary },
          { path: '/new/:integrationType', exact: true, component: AgentSetupPage },
          { path: '/:agentId', exact: true, component: AgentConfigPage },
        ],
      },
      {
        path: '/user-management',
        component: UserManagementWrapper,
        routes: [
          { path: '/users', exact: true, component: withVeracodeUserCheck(UsersPage) },
          { path: '/users/:userId', component: withVeracodeUserCheck(OrgUserDetailsPage) },
          { path: '/groups', from: '/groups', to: '/teams', redirect: true },
          { path: '/teams', exact: true, component: TeamsPage },
          { path: '/teams/:groupId', component: withVeracodeUserCheck(OrgTeamDetailsPage) },
          { component: RouteNotFound },
        ],
      },
      { path: '/policy', exact: true, from: '/policy', to: '/rules', redirect: true },
      { path: '/rules', component: withEnforceWorkspaceRulesCheck(WorkspacePoliciesHub) },
      { path: '/notifications', exact: true, component: NotificationsPage },
      {
        path: '/notifications/manage-webhook',
        exact: true,
        component: WebhookNotificationsManageSection,
      },
      { component: RouteNotFound },
    ],
  },
  /* org pages */
  {
    path: '/org/settings/account-locked',
    exact: true,
    component: withVeracodeUserCheck(UpdatePaymentPage),
  },
  {
    path: '/org/settings/no-owner',
    exact: true,
    centeredNoSidebar: true,
    component: NoOrgOwnerPage,
  },
  {
    path: '/org/settings',
    component: OrgAdminWrapper,
    sidebar: 'ORG',
    routes: [
      { path: '/', exact: true, component: withVeracodeUserCheck(OrgProfilePage) },
      {
        path: '/subscription',
        exact: true,
        component: withVeracodeUserCheck(SubscriptionPage),
      },
      {
        path: '/subscription/update',
        component: withVeracodeUserCheck(SubscriptionUpdatePage),
        exact: true,
      },
      {
        path: '/subscribe',
        component: withVeracodeUserCheck(OrgUpgradePage),
        exact: true,
      },
      { path: '/upgrade', exact: true, from: '/upgrade', to: '/subscribe', redirect: true },
      { path: '/upgrade/payment', component: withVeracodeUserCheck(PaymentPage) },
      { path: '/billing', exact: true, component: withVeracodeUserCheck(OrgBillingPage) },
      { path: '/billing/invoice/:invoiceId', component: withVeracodeUserCheck(InvoiceDetailsPage) },
      { path: '/usage', component: OrgUsagePage },
      { path: '/rules', component: withEnforceRulesCheck(OrgPoliciesHub) },
      { path: '/users', exact: true, component: withVeracodeUserCheck(OrgUsersPage) },
      { path: '/users/:userId', component: withVeracodeUserCheck(OrgUserDetailsPage) },
      { path: '/groups', from: '/groups', to: '/teams', redirect: true },
      { path: '/teams', exact: true, component: withVeracodeUserCheck(OrgTeamsPage) },
      {
        path: '/agents',
        component: PageWrapper,
        routes: [
          { path: '/', exact: true, component: OrgAgentsPage },
          {
            path: '/new',
            exact: true,
            component: withOrgPermissionCheck(NewAgentSummary, 'agents'),
          },
          {
            path: '/new/:integrationType',
            exact: true,
            component: withOrgPermissionCheck(AgentSetupPage, 'agents'),
          },
          {
            path: '/:agentId',
            exact: true,
            component: withOrgPermissionCheck(AgentConfigPage, 'agents'),
          },
        ],
      },
      { path: '/groups/:groupId', from: '/groups', to: '/teams', redirect: true },
      { path: '/teams/:groupId', component: withVeracodeUserCheck(OrgTeamDetailsPage) },
      { path: '/workspaces', exact: true, component: withVeracodeUserCheck(WorkspacesPage) },
      { path: '/workspaces/:teamId', component: withVeracodeUserCheck(WorkspaceDetailsPage) },
      {
        path: '/library-catalog',
        component: withLibraryCatalogCheck(LibraryCatalogPage),
      },
      { path: '/integrations', exact: true, component: OrgIntegrationPage },
      { path: '/integrations/jira/:integrationId', component: OrgIntegrationJiraPage },
      { component: RouteNotFound },
    ],
  },
  {
    path: '/org/:orgId/jira/:setupId/callback',
    exact: true,
    centeredNoSidebar: true,
    component: JiraIntegrationSetupCallback,
  },
  { path: '/org/no-workspace', component: NoWorkspacePage },
  { path: '/no-workspace-admin', component: NoWorkspaceAdminPage, centeredNoSidebar: true },
  { path: '/org/no-admin', component: NoOrgAdminPage, centeredNoSidebar: true },
  { path: '/start-scan', component: withStartScanPermissionCheck(VCStartScanPage) },
  {
    path: '/sca-home',
    component: Helpers.isScaPlatformUIEnabled() ? ScaPlatformUI : RouteNotFound,
  },
  { component: RouteNotFound },
];

export default routes;
