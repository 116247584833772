import React from 'react';
import { errorMessageTextMap } from '~/constants/ModelConstants';

interface UpdateGroupDetailsProps {
  createFlow?: boolean;
  groupData: object;
  showModal: (...args: any[]) => any;
  updateGroupData: (...args: any[]) => any;
  submitDetailsUpdate: (...args: any[]) => any;
  validTeamName?: boolean;
}

const UpdateGroupDetails: React.FunctionComponent<UpdateGroupDetailsProps> = props => {
  const { groupData = {}, createFlow } = props;
  const { name = '', description = '', error = false, validTeamName = true } = groupData;

  const teamNameError = !validTeamName ? errorMessageTextMap.teamNameError : '';
  const shouldDisableButton = name.length < 3 || name.length > 20 || !!teamNameError;

  function submitDetailsUpdate(e) {
    e.preventDefault();

    props.submitDetailsUpdate();
  }

  function updateGroupData(field, e) {
    const { target = {} } = e;
    const { value = '' } = target;

    props.updateGroupData(field, value);
  }

  function showModal(modalType) {
    props.showModal(modalType);
  }

  return (
    <form className="grid" onSubmit={e => submitDetailsUpdate(e)}>
      {createFlow && (
        <div className="grid__item col-1-1 mt-">
          <div className="font--h6">
            <strong>Enter Team Information</strong>
          </div>
        </div>
      )}
      <div className="grid__item col-1-3 mt flex align-items--center">Team Name</div>
      <div className="grid__item col-2-3 mt">
        <input
          type="text"
          className="control--text col-1-1"
          placeholder="Name"
          required
          value={name}
          onChange={e => updateGroupData('name', e)}
        />
      </div>
      <div className={`grid__item col-1-1 color--danger ${error ? 'is-showing-50' : 'is-hiding'}`}>
        <div className="mt--">This team name is already taken. Please choose another.</div>
      </div>
      <div
        className={`grid__item col-1-1 color--danger ${
          teamNameError ? 'is-showing-50' : 'is-hiding'
        }`}
      >
        <div className="mt--">{teamNameError}</div>
      </div>
      <div className="grid__item col-1-3 mt- flex align-items--center">Description</div>
      <div className="grid__item col-2-3 mt-">
        <input
          type="text"
          className="control--text col-1-1"
          placeholder="Description (optional)"
          value={description}
          onChange={e => updateGroupData('description', e)}
        />
      </div>
      <div className="grid__item col-1-1 flex justify-content--end mt">
        <button className="col-1-5 pv- mr-" type="button" onClick={() => showModal()}>
          Cancel
        </button>
        <button className="col-1-5 pv- btn--primary" type="submit" disabled={shouldDisableButton}>
          {createFlow ? 'Next' : 'Save'}
        </button>
      </div>
    </form>
  );
};

export default UpdateGroupDetails;
