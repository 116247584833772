import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';

interface PackageManagerMavenProps {
  component: object;
  range?: object;
  safeVersion: string;
  transitive: boolean;
  unsafeVersion?: string;
}

const PackageManagerMaven: React.FunctionComponent<PackageManagerMavenProps> = props => {
  const { component, range = {}, safeVersion, transitive = false } = props;
  const { coordinate1, coordinate2 } = component;
  const unsafeVersion =
    props.unsafeVersion ||
    (range.versionRange && range.versionRange.indexOf('-') !== -1
      ? range.versionRange.split('-')[0]
      : '');

  if (!transitive) {
    return (
      <div className="grid bo--1 border-color--white-dark bg-color--white mt lh+">
        <CodeDiffHeader text="pom.xml" />
        <CodeDiffLine type="normal" text="<dependency>" />
        <CodeDiffLine
          type="normal"
          text={'\u2003\u003CgroupId\u003E' + coordinate1 + '\u003C/groupId\u003E'}
        />
        <CodeDiffLine
          type="normal"
          text={'\u2003\u003CartifactId\u003E' + coordinate2 + '\u003C/artifactId\u003E'}
        />
        <CodeDiffLine
          type="delete"
          text={'\u2003\u003Cversion\u003E' + unsafeVersion + '\u003C/version\u003E'}
        />
        <CodeDiffLine
          type="add"
          text={'\u2003\u003Cversion\u003E' + safeVersion + '\u003C/version\u003E'}
        />
        <CodeDiffLine type="normal" text="</dependency>" />
      </div>
    );
  } else {
    return (
      <div className="grid bo--1 border-color--white-dark bg-color--white mt lh+">
        <CodeDiffHeader text="pom.xml" />
        <CodeDiffLine type="add" text="<dependency>" />
        <CodeDiffLine
          type="add"
          text={'\u2003\u003CgroupId\u003E' + coordinate1 + '\u003C/groupId\u003E'}
        />
        <CodeDiffLine
          type="add"
          text={'\u2003\u003CartifactId\u003E' + coordinate2 + '\u003C/artifactId\u003E'}
        />
        <CodeDiffLine
          type="add"
          text={'\u2003\u003Cversion\u003E' + safeVersion + '\u003C/version\u003E'}
        />
        <CodeDiffLine type="add" text="</dependency>" />
      </div>
    );
  }
};

export default PackageManagerMaven;
