import React from 'react';
import classNames from 'classnames';

interface VulnBoxProps {
  num: number;
  level?: string;
}

const VulnBox: React.FunctionComponent<VulnBoxProps> = props => {
  const { num = 0, level } = props;

  const commonProperties = {
    'font-family--body text--bold inline-block': true,
    'color--white': true,
    'col-1-1 max-width--30': true,
    'pv-- bo--1': true,
  };

  let vulnBoxProperties;

  if (num > 0) {
    switch (level) {
      case 'CRITICAL':
        vulnBoxProperties = {
          ...commonProperties,
          'border-color--critical bo--1--hover-color-critical': true,
          'bg-color-imp--critical': true,
        };
        break;
      case 'HIGH':
        vulnBoxProperties = {
          ...commonProperties,
          'border-color--danger bo--1--hover-color-danger': true,
          'bg-color-imp--danger': true,
        };
        break;
      case 'MEDIUM':
        vulnBoxProperties = {
          ...commonProperties,
          'border-color--warning bo--1--hover-color-warning': true,
          'bg-color-imp--warning': true,
        };
        break;
      case 'LOW':
        vulnBoxProperties = {
          ...commonProperties,
          'border-color--warning-light bo--1--hover-color-warning-light': true,
          'bg-color-imp--warning-light': true,
        };
        break;
    }
  } else {
    vulnBoxProperties = {
      ...commonProperties,
      'bg-color--white-dark border-color--white-dark': true,
    };
  }

  const vulnBoxClass = classNames(vulnBoxProperties);

  return <span className={vulnBoxClass}>{num}</span>;
};

export default VulnBox;
