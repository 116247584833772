import React from 'react';

interface SpinnerProps {
  size?: number;
  strokeWidth?: number;
  backgroundColor?: string;
  polygonClassName?: string;
}

const Spinner: React.FunctionComponent<SpinnerProps> = props => {
  const {
    size = 40,
    strokeWidth = 6,
    backgroundColor = 'bg-color--white',
    polygonClassName = '',
  } = props;
  return (
    <span className={`${backgroundColor} zIndex-10--loadingPage`}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <polygon
          points={`0 0 0 ${size} ${size} ${size} ${size} 0`}
          className={`movedash-rectangle ${polygonClassName}`}
          style={{ strokeDasharray: size, strokeWidth: `${strokeWidth}px` }}
        />
      </svg>
    </span>
  );
};

export default Spinner;
