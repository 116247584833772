import React from 'react';
import _ from 'lodash';

import ApiService from '~/utils/ApiService';
import IssueCreator from '~/utils/IssueCreator';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';

import * as reportActions from '~/actions/reports';
import * as statsActions from '~/actions/stats';
import * as toastrActions from '~/actions/toastr';
import { RepoIssueSuppressModel } from '~/types/SCA';

export const UPDATE_REPORT_GITHUB_FIX_URL = 'UPDATE_REPORT_GITHUB_FIX_URL';
export const UPDATE_REPORT_JIRA_FIX_URL = 'UPDATE_REPORT_JIRA_FIX_URL';
export const CLEAR_REPORT_JIRA_FIX_URL = 'CLEAR_REPORT_JIRA_FIX_URL';
export const UPDATE_REPORT_ISSUE_FIX_DATA = 'UPDATE_REPORT_ISSUE_FIX_DATA';
export const FETCH_FIXINFO_REQUEST = 'FETCH_FIXINFO_REQUEST';
export const FETCH_FIXINFO_RECEIVED = 'FETCH_FIXINFO_RECEIVED';
export const UPDATE_REPORT_ACTIVE_JIRA_PROJECT = 'UPDATE_REPORT_ACTIVE_JIRA_PROJECT';
export const ISSUE_SUPPRESS_REQUEST = 'ISSUE_SUPPRESS_REQUEST';
export const ISSUE_SUPPRESS_SUCCESS = 'ISSUE_SUPPRESS_SUCCESS';
export const ISSUE_SUPPRESS_FAILURE = 'ISSUE_SUPPRESS_FAILURE';
export const ISSUE_SUPPRESS_STATE_RESET = 'ISSUE_SUPPRESS_STATE_RESET';
export const ISSUE_INTEGRATION_REQUEST = 'ISSUE_INTEGRATION_REQUEST';
export const ISSUE_INTEGRATION_SUCCESS = 'ISSUE_INTEGRATION_SUCCESS';
export const ISSUE_INTEGRATION_FAILURE = 'ISSUE_INTEGRATION_FAILURE';
export const ISSUE_COMMENT_REQUEST = 'ISSUE_COMMENT_REQUEST';
export const ISSUE_COMMENT_SUCCESS = 'ISSUE_COMMENT_SUCCESS';
export const ISSUE_COMMENT_FAILURE = 'ISSUE_COMMENT_FAILURE';

// Tracking user who uses the create issue functionality
export const CREATE_LEGACY_ISSUE = 'CREATE_LEGACY_ISSUE';

export const updateReportActiveJiraProject = jiraProject => {
  return {
    type: UPDATE_REPORT_ACTIVE_JIRA_PROJECT,
    jiraProject,
  };
};

export const updateReportGitHubFixUrl = githubHref => {
  return {
    type: UPDATE_REPORT_GITHUB_FIX_URL,
    githubHref,
  };
};

export const updateReportJiraFixUrl = jiraHref => {
  return {
    type: UPDATE_REPORT_JIRA_FIX_URL,
    jiraHref,
  };
};

export const clearReportJiraFixUrl = () => {
  return {
    type: CLEAR_REPORT_JIRA_FIX_URL,
  };
};

export const updateReportIssueFixData = issueFixData => {
  return {
    type: UPDATE_REPORT_ISSUE_FIX_DATA,
    issueFixData,
  };
};

export const fetchFixInfoRequest = () => {
  return {
    type: FETCH_FIXINFO_REQUEST,
    isFetching: true,
  };
};

export const fetchFixInfoReceived = () => {
  return {
    type: FETCH_FIXINFO_RECEIVED,
  };
};

export const issueSuppressRequest = () => {
  return {
    type: ISSUE_SUPPRESS_REQUEST,
  };
};

export const issueSuppressSuccess = () => {
  return {
    type: ISSUE_SUPPRESS_SUCCESS,
  };
};

export const issueSuppressFailure = (errorMessage: string) => {
  return {
    type: ISSUE_SUPPRESS_FAILURE,
    errorMessage,
  };
};

export const issueSuppressStateReset = () => {
  return {
    type: ISSUE_SUPPRESS_STATE_RESET,
  };
};

export const issueCommentRequest = () => ({
  type: ISSUE_COMMENT_REQUEST,
});

export const issueCommentSuccess = () => ({
  type: ISSUE_COMMENT_SUCCESS,
});

export const issueCommentFailure = errorMessage => ({
  type: ISSUE_COMMENT_FAILURE,
  errorMessage,
});

//WIP - Simplified version of the loadQuickView
export const fetchFixInfo = href => {
  return function (dispatch) {
    const scope = {
      id: 0,
      name: 'default',
      agents: '*',
      paths: '*',
      repos: '*',
      isDefault: true,
    };
    dispatch(fetchFixInfoRequest());
    return ApiService.get(href, { data: scope }).then(res => {
      if (res && res.content) {
        dispatch(updateReportIssueFixData(res.content));
        dispatch(buildJiraUrlForProject(res.content));
        dispatch(buildGitHubUrlForRepo(res.content));
        dispatch(fetchFixInfoReceived());
      }
    });
  };
};

export const buildJiraUrlForProject = initialIssueFixData => {
  return function (dispatch, getState) {
    const store = getState();
    const { reportIssuesState = {}, navigationState = {}, teamState = {} } = store;
    const issueFixData = initialIssueFixData ? initialIssueFixData : reportIssuesState.issueFixData;

    const { activeJiraProject = {} } = reportIssuesState;
    const { activeTeamParent } = navigationState;
    const { issueSettingsDataByTeamId } = teamState;
    const teamId = activeTeamParent;
    const issueSettingsData = issueSettingsDataByTeamId[teamId] || {};
    const jiraUrl = issueSettingsData.url || '';

    let jiraProject = {};

    if (!_.isEmpty(activeJiraProject)) {
      jiraProject = activeJiraProject;
    }

    if (issueSettingsData.projects && issueSettingsData.projects[0]) {
      jiraProject = issueSettingsData.projects[0];
    }

    const { projectId, issueTypeId } = jiraProject;

    const data = {
      ...issueFixData,
      jiraUrl: jiraUrl || '',
      pid: projectId,
      issueType: issueTypeId,
    };
    const jiraHref = IssueCreator.getJiraHref(data);
    dispatch(updateReportJiraFixUrl(jiraHref));
  };
};

export const buildGitHubUrlForRepo = initialIssueFixData => {
  return function (dispatch, getState) {
    const store = getState();
    const { reportIssuesState = {} } = store;
    const issueFixData = initialIssueFixData ? initialIssueFixData : reportIssuesState.issueFixData;

    const githubHref = IssueCreator.getGitHubHref(issueFixData);
    dispatch(updateReportGitHubFixUrl(githubHref));
  };
};

export const maybeSuppressIssue = (
  issue,
  reportType: string,
  suppress = true,
  comment: string,
  suppressedUntil?: Date
) => (dispatch, getState) => {
  const store = getState();
  const { navigationState = {} } = store;
  const teamId = navigationState.activeTeamParent;
  const { id: issueId, _links = {} } = issue;
  const { self = {} } = _links;
  const { href } = self;

  const data: RepoIssueSuppressModel = {
    suppressed: suppress,
    comment,
    suppressedUntil,
  };

  if (!data.comment) {
    ErrorService.capture(
      `maybeSuppressIssue called without any comment set for issue ID: ${issueId}`
    );
    return Promise.reject(
      `maybeSuppressIssue called without any comment set for issue ID: ${issueId}`
    );
  }

  if (!href) {
    return Promise.reject('response is missing HATEOAS links');
  }

  dispatch(issueSuppressRequest());
  return ApiService.post(`${href}:suppress`, { data })
    .then(() => {
      dispatch(reportActions.removeReportIssueItem(reportType, issueId));
      dispatch(reportActions.decrementPageTotalElements(reportType));
      dispatch(statsActions.fetchStats(teamId)); //Update stats upon suppression of an issue

      dispatch(issueSuppressSuccess());
      setTimeout(() => {
        dispatch(issueSuppressStateReset());
      }, 2500);
    })
    .catch(err => {
      let errorMessage = 'An error has occurred while processing your request.';
      if (err.status !== 500 && err.message) {
        errorMessage = err.message;
      }
      dispatch(issueSuppressFailure(errorMessage));
      ErrorService.capture('Failed to update issue suppress status', { err });
    });
};

export const commentIssue = (issue, comment) => dispatch => {
  const data = {
    comment,
  };

  dispatch(issueCommentRequest());
  return ApiService.post(`repo-issues/${issue.id}:comment`, { data: data })
    .then(() => {
      dispatch(issueCommentSuccess());
      dispatch(
        toastrActions.addToastr({
          id: `COMMENT_ISSUE_SUCCESS_${Date.now()}`,
          level: 'success',
          title: 'Success',
          message: (
            <div className="flex flex--flex-direction--column">
              <div>Your comment was successfully added.</div>
              <div>View comments in the History panel.</div>
            </div>
          ),
        })
      );
    })
    .catch(err => {
      let errorMessage;
      if (err && err.status !== 500 && err.hasOwnProperty('message')) {
        errorMessage = err.message;
      } else {
        errorMessage = 'An error has occurred while processing your request.';
      }
      dispatch(
        toastrActions.addToastr({
          id: `COMMENT_ISSUE_FAILURE`,
          level: 'error',
          title: 'Error commenting on issue',
          message: errorMessage || `We ran into an error commenting on your issue.`,
        })
      );
      dispatch(issueCommentFailure(errorMessage));
      ErrorService.capture('Failed to comment issue', { err });
    });
};

// Only for snowplow purpose
export const createIssueButtonClickedSnowplow = (orgId, integrationType, selectedIssue) => ({
  type: CREATE_LEGACY_ISSUE,
  meta: {
    snowplow: true,
    string1: orgId,
    string2: integrationType,
    string3: selectedIssue.type,
    integer1: selectedIssue.id,
  },
});

export const issueIntegrationRequest = issueId => ({
  type: ISSUE_INTEGRATION_REQUEST,
  issueId,
});

export const issueIntegrationSuccess = (issueId, res) => ({
  type: ISSUE_INTEGRATION_SUCCESS,
  issueId,
  res,
});

export const issueIntegrationFailure = (issueId, err) => ({
  type: ISSUE_INTEGRATION_FAILURE,
  issueId,
  err,
});

export const getIntegrationIssuesCreated = (issueId, repoScanId) => dispatch => {
  dispatch(issueIntegrationRequest(issueId));
  return ApiService.get(ApiConstants.getIntegrationIssuesCreatedURL(repoScanId, issueId))
    .then(res => {
      dispatch(issueIntegrationSuccess(issueId, res));
    })
    .catch(err => {
      dispatch(issueIntegrationFailure(issueId, err));
      ErrorService.capture('Failed to get integration for issue', err);
    });
};
