import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import workspaceSwitcher from '~/images/workspace-switcher.png';

import SourceClearModal from '~/components/SourceClearModal';

import * as welcomeModalActions from '~/actions/welcomeModal';

const MODAL_TYPE = 'WELCOME_MODAL';

interface WelcomeModalProps {
  welcomeModalActions: object;
  myState: object;
  navigationState: object;
  welcomeModalState: object;
}
class WelcomeModal extends React.Component<WelcomeModalProps, {}> {
  onClose() {
    this.props.welcomeModalActions.closeWelcomeModal();
  }

  handleButtonClick() {
    const { navigationState } = this.props;
    const { activeTeamParent: teamId } = navigationState;
    this.props.welcomeModalActions.snowplowWelcomeBtnClicked(MODAL_TYPE, teamId);
    this.onClose();
  }

  render() {
    const { welcomeModalState, myState } = this.props;
    const { openedModal } = welcomeModalState;
    const { me = {} } = myState;
    const { orgRoles = [] } = me;
    const isOrgAdminOrOwner = orgRoles.includes('OWNER') || orgRoles.includes('ADMIN');

    const isOpen = openedModal === MODAL_TYPE;

    if (!me.id) {
      return null;
    }

    return (
      <SourceClearModal
        isOpen={isOpen}
        title="Welcome to SourceClear"
        onClose={() => this.onClose()}
        width={660}
        showCloseButton={false}
        closeWhenClickOutside={false}
      >
        <div className="p">
          <div className="font--h4 mt-- text--bold">Thank you for signing up with SourceClear!</div>
          <div className="grid">
            <div className="grid__item col-2-3 mt">
              <div className="font--h7">
                <strong>TIP:</strong> scan results go into <strong>projects</strong>, and projects
                live in <strong>workspaces</strong>. Workspaces allow grouping by application,
                business unit, team, or however you want. Switch between workspaces using the
                dropdown at the top left.
              </div>
              <div className="mt-">
                Try out SourceClear with your personal workspace called "My Sandbox".{' '}
                <strong>This workspace is just for you and can't be shared</strong>.
              </div>
              {isOrgAdminOrOwner ? (
                <div className="mt-">
                  When you are ready to use SourceClear on real projects and collaborate with
                  others, <strong>create a workspace and invite users</strong>.
                </div>
              ) : (
                <div className="mt-">
                  When you are ready to use SourceClear on real projects and collaborate with
                  others,{' '}
                  <strong>
                    switch to a workspace. If you're not part of a workspace, ask an organization
                    owner or administrator.
                  </strong>
                </div>
              )}
            </div>
            <div className="grid__item col-1-3 mt">
              <img
                src={workspaceSwitcher}
                alt="Workspace switcher screenshot"
                className="col-1-1"
              />
            </div>
            <div className="grid__item col-1-1 mt pt- flex justify-content--center">
              <button
                className="btn--primary font--h4 pv ph+"
                onClick={() => this.handleButtonClick()}
              >
                Okay - got it!
              </button>
            </div>
          </div>
        </div>
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    myState: state.myState,
    navigationState: state.navigationState,
    welcomeModalState: state.welcomeModalState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    welcomeModalActions: bindActionCreators(welcomeModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeModal);
