import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import CustomizedAxisTick from '~/components/CustomizedAxisTick';

interface InsightHorizontalBarProps {
  title: string;
  field?: string;
  defaultColor?: string;
  content: any[];
  contentUnit?: string;
  handleClick?: (...args: any[]) => any;
  isClickable?: boolean;
  isFetchingInsights?: boolean;
  isAnimationActive?: boolean;
  isSelected?: boolean;
}

const InsightHorizontalBar: React.FunctionComponent<InsightHorizontalBarProps> = props => {
  const {
    title,
    content,
    isClickable,
    field,
    isSelected,
    defaultColor = '#8698A1',
    isFetchingInsights,
    isAnimationActive = false,
  } = props;

  function handleClick() {
    isClickable && props.handleClick(field, !isSelected);
  }

  if (isFetchingInsights) {
    return (
      <div className="grid__item col-1-5 flex">
        <div className="col-1-1 flex flex--flex-direction--column bo--1 border-color--muted-light p--">
          <div className="col-1-1 font--h8">{title}</div>
          <div className="col-1-1 ph flex align-items--center justify-content--center flex-grow--1">
            <i className="fas fa-spin fa-spinner font--h4 color--muted-dark" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid__item col-1-5 flex">
      <div
        className={`col-1-1 p-- flex flex--flex-direction--column border-color--muted-light ${
          isClickable ? 'insight--clickable bo--1' : 'bo-v--1'
        } ${isSelected ? 'insight--selected' : ''}`}
        onClick={() => handleClick()}
      >
        <div className="font--h8 flex flex--justify-content--space-between">
          {title}
          {isSelected && <i className="sci sci__check--box color--success" />}
        </div>
        <div className="flex justify-content--center align-items--end flex-grow--1 bar-chart">
          <BarChart
            data={content}
            width={170}
            height={85}
            margin={{ top: 0, right: 0, left: -30, bottom: 5 }}
          >
            <XAxis
              dataKey="name"
              stroke={defaultColor}
              interval={0}
              height={30}
              tick={<CustomizedAxisTick />}
            />
            <YAxis stroke={defaultColor} fontSize={'10px'} />
            <Tooltip
              itemStyle={{ fontSize: '10px' }}
              labelStyle={{ display: 'none' }}
              wrapperStyle={{ padding: '2px' }}
            />
            <Bar
              dataKey="count"
              barSize={15}
              fill={defaultColor}
              isAnimationActive={isAnimationActive}
            />
          </BarChart>
        </div>
      </div>
    </div>
  );
};

export default InsightHorizontalBar;
