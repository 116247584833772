import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LoaderWrapper from '~/components/LoaderWrapper';
import * as userManagementQuickViewActions from '~/actions/userManagementQuickView';

interface UserManagementQuickViewWrapperProps {
  userManagementQuickViewActions: object;
  teamId?: string;
  href: string;
  dataType: string;
  userManagementQuickViewState: object;
}
class UserManagementQuickViewWrapper extends React.Component<
  UserManagementQuickViewWrapperProps,
  {}
> {
  componentDidMount() {
    const { href, dataType } = this.props;
    this.props.userManagementQuickViewActions.fetchQuickViewData(href, dataType);
  }

  render() {
    const { userManagementQuickViewState, dataType, teamId } = this.props;
    const { [dataType]: data = [], isFetching } = userManagementQuickViewState;

    const customLoader = (
      <div className="text--center pv bo-v--1 mv-- pb border-color--muted">
        <i className={`fas fa-spin fa-spinner color--muted-dark font--h4`} />
      </div>
    );
    return (
      <LoaderWrapper isLoaderShowing={!data.length || isFetching} customLoader={customLoader}>
        {React.cloneElement(this.props.children, { data, teamId })}
      </LoaderWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    userManagementQuickViewState: state.userManagementQuickViewState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userManagementQuickViewActions: bindActionCreators(userManagementQuickViewActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementQuickViewWrapper);
