const FeatureFlagHelper = function () {
  const isFeatureEnabledForOrg = (featureHash, org = {}): boolean => {
    const { features = [] } = org;
    return features.some(feature => feature.toUpperCase() === featureHash.toUpperCase());
  };

  const isFeatureEnabledForTeam = (featureHash, team = {}): boolean => {
    const { features = [] } = team;
    return features.some(feature => feature.toUpperCase() === featureHash.toUpperCase());
  };

  const isFeatureEnabledForOrgAndTeam = (featureHash, org: App.Org, team): boolean => {
    return isFeatureEnabledForOrg(featureHash, org) && isFeatureEnabledForTeam(featureHash, team);
  };

  const isFeatureEnabledForOrgOrTeam = (featureHash, org: App.Org, team): boolean => {
    return isFeatureEnabledForOrg(featureHash, org) || isFeatureEnabledForTeam(featureHash, team);
  };

  return {
    isFeatureEnabledForOrg,
    isFeatureEnabledForTeam,
    isFeatureEnabledForOrgAndTeam,
    isFeatureEnabledForOrgOrTeam,
  };
};

export default FeatureFlagHelper();
