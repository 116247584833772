import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';

import * as orgTeamActions from '~/actions/orgTeam';

import SuccessOrValueBox from '~/components/ReportComponents/SuccessOrValueBox';
import UserManagementQuickViewWrapper from '~/containers/UserManagementQuickViewWrapper';
import WorkspaceUsersQuickView from '~/components/WorkspaceUsersQuickView';
import WorkspaceGroupsQuickView from '~/components/WorkspaceGroupsQuickView';
import PDFDownloadButton from '~/containers/PDFDownloadButton';
import Helpers from '~/utils/Helpers';

import { ReportColumnWidths } from '~/components/ReportComponents/Report.types';
import { RootState } from '~/reducers';

interface WorkspaceRowProps {
  workspace: object;
  columnWidths?: ReportColumnWidths;
  orgTeamState: object;
  orgTeamActions: object;
  rowId: string;
  updateUsers?: boolean;
  showGroups?: boolean;
  canShowPdfDownload?: boolean;
  userShowPdfDownload?: boolean;
  isPDFDownloading?: boolean;
  hasErrorOnPDFDownload?: boolean;
  onPDFDownload?: (...args: any[]) => any;
  showDirectMembership: boolean;
  shouldShowVeracodePage?: boolean;
}
class WorkspaceRow extends React.Component<
  WorkspaceRowProps & ReturnType<typeof mapDispatchToProps>
> {
  componentWillUnmount() {
    this.props.orgTeamActions.resetWorkspaceQuickViews();
  }

  getQuickviewLinks = links => {
    if (!links) return;

    const { errors = {} } = links;
    return {
      errors: errors.href,
    };
  };

  toggleWorkspaceQuickView = (workspaceId, column) => {
    this.props.orgTeamActions.toggleWorkspaceQuickView(workspaceId, column);
  };

  render() {
    const {
      workspace,
      columnWidths,
      orgTeamState,
      updateUsers,
      showGroups,
      canShowPdfDownload,
      userShowPdfDownload,
      isPDFDownloading,
      hasErrorOnPDFDownload,
      onPDFDownload,
      showDirectMembership,
      shouldShowVeracodePage = false,
    } = this.props;
    const {
      id: teamId,
      name,
      numUsers,
      numGroups,
      repoCount = 0,
      vulnIssues = 0,
      libraryIssues = 0,
      licenseIssues = 0,
      lastScanDate,
      repoDefaultBranches = {},
      _links = {},
      permissions = {},
    } = workspace;
    const { quickViewsByWorkspaceId = {} } = orgTeamState;
    const { users = {}, groups = {} } = _links;
    const { listGroups, listUsers, read } = permissions;
    const linkUrl = Helpers.getUrlByPermission(teamId, permissions, repoCount);

    const totalIssues = vulnIssues + libraryIssues + licenseIssues;
    const quickView = quickViewsByWorkspaceId[teamId];

    return (
      <div className="grid grid--narrower grid--middle bo--1 border-color--white-dark pl- pv-- ml0 mv--- min-height--25">
        <div className={`grid__item ${columnWidths.name}`} data-automation-id="RowCell">
          {!read ? (
            <div>{name}</div>
          ) : (
            <Link className={`link--obvious text--bold`} to={linkUrl}>
              <strong data-e2e={`WorkspaceRow-${name}`}>{name}</strong>
            </Link>
          )}
        </div>
        <div
          className={'grid__item flex justify-content--center ' + columnWidths.totalProjects}
          data-automation-id="RowCell"
        >
          <div>{repoCount}</div>
        </div>
        <div
          className={'grid__item flex justify-content--center ' + columnWidths.totalIssues}
          data-automation-id="RowCell"
        >
          <SuccessOrValueBox num={totalIssues} />
        </div>
        <div
          className={'grid__item flex justify-content--center ' + columnWidths.vulnIssues}
          data-automation-id="RowCell"
        >
          <SuccessOrValueBox num={vulnIssues} />
        </div>
        <div
          className={'grid__item flex justify-content--center ' + columnWidths.libraryIssues}
          data-automation-id="RowCell"
        >
          <SuccessOrValueBox num={libraryIssues} />
        </div>
        <div
          className={'grid__item flex justify-content--center ' + columnWidths.licenseIssues}
          data-automation-id="RowCell"
        >
          <SuccessOrValueBox num={licenseIssues} />
        </div>
        <div
          className={'grid__item text--overflow ' + columnWidths.lastScanDate}
          data-automation-id="RowCell"
        >
          {lastScanDate ? Helpers.formatDate(lastScanDate) : '--'}
        </div>
        {!shouldShowVeracodePage && (
          <div
            className={`grid__item flex align-items--center ${columnWidths.users}`}
            data-automation-id="RowCell"
          >
            {listUsers ? (
              <div
                className={`flex justify-content--space-between col-5-6 ${
                  numUsers && 'cursor--pointer'
                }`}
                onClick={
                  numUsers ? () => this.toggleWorkspaceQuickView(teamId, 'USERS') : undefined
                }
              >
                {numUsers} user
                {numUsers === 1 ? '' : 's'}
                <i
                  className={`sci sci__caret--${quickView === 'USERS' ? 'down' : 'right'} color--${
                    numUsers ? 'primary' : 'muted-light'
                  }`}
                />
              </div>
            ) : (
              <div className="">
                {numUsers} user
                {numUsers === 1 ? '' : 's'}
              </div>
            )}
          </div>
        )}
        {showGroups && (
          <div
            className={`grid__item flex align-items--center ${columnWidths.groups}`}
            data-automation-id="RowCell"
          >
            {listGroups ? (
              <div
                className={`flex justify-content--space-between col-5-6 ${
                  numGroups && 'cursor--pointer'
                }`}
                onClick={
                  numGroups ? () => this.toggleWorkspaceQuickView(teamId, 'GROUPS') : undefined
                }
              >
                View teams
                <i
                  className={`sci sci__caret--${quickView === 'GROUPS' ? 'down' : 'right'} color--${
                    numGroups ? 'primary' : 'muted-light'
                  }`}
                />
              </div>
            ) : (
              <div className="">View teams</div>
            )}
          </div>
        )}
        {canShowPdfDownload && (
          <div
            className={`grid__item flex align-items--center justify-content--center ${columnWidths.actions}`}
            data-automation-id="RowCell"
          >
            {userShowPdfDownload && (
              <PDFDownloadButton
                hasError={hasErrorOnPDFDownload}
                isDownloading={isPDFDownloading}
                teamId={teamId}
                repoName={name}
                repoCount={repoCount}
                repoDefaultBranches={repoDefaultBranches}
                onPDFDownload={onPDFDownload}
              />
            )}
          </div>
        )}
        <div
          className={`grid__item col-1-1 pr- ${
            quickView === 'USERS' ? 'is-showing-400' : 'is-hiding'
          }`}
          data-automation-id="RowCell"
        >
          {quickView && users.href && (
            <UserManagementQuickViewWrapper dataType="users" href={users.href}>
              <WorkspaceUsersQuickView
                showDetailsLink={updateUsers}
                showGroups={showGroups}
                showDirectMembership={showDirectMembership}
              />
            </UserManagementQuickViewWrapper>
          )}
        </div>
        <div
          className={`grid__item col-1-1 pr- ${
            quickView === 'GROUPS' ? 'is-showing-400' : 'is-hiding'
          }`}
          data-automation-id="RowCell"
        >
          {quickView && groups.href && (
            <UserManagementQuickViewWrapper dataType="groups" href={groups.href}>
              <WorkspaceGroupsQuickView shouldShowVeracodePage={shouldShowVeracodePage} />
            </UserManagementQuickViewWrapper>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    orgTeamState: state.orgTeamState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    orgTeamActions: bindActionCreators(orgTeamActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceRow);
