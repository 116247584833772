import produce from 'immer';
import * as actions from '~/actions/popover';

const defaultState = {};

const popoverState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.OPEN_POPOVER:
        draft[action.id] = true;
        break;
      case actions.CLOSE_POPOVER:
        draft[action.id] = false;
    }
  });
};

export default popoverState;
