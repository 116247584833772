import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _ from 'lodash';

import * as clipboardActions from '~/actions/clipboard';
import ClipboardFactory from '~/utils/ClipboardFactory';

interface CopyToClipboardProps {
  actions: object;
  clipboardState: object;
  id: string;
  value: string;
  placeholder?: string;
  trackOptions?: object;
  inputWidth?: string;
  rowCount?: number;
}
class CopyToClipboard extends React.Component<CopyToClipboardProps, {}> {
  componentDidMount() {
    this.clipBtn = ClipboardFactory.create(
      this.props.id,
      this.copySuccess,
      this.copyError,
      this.props.trackOptions
    );
  }

  componentWillUnmount() {
    this.clipBtn.destroy();
  }

  copySuccess = inputId => {
    const { value = '' } = this.props;
    value.length > 0 ? this.props.actions.copySuccess(inputId) : _.noop;
  };

  copyError = inputId => {
    this.props.actions.copyError(inputId);
  };

  selectOnClick(e) {
    if (!window.getSelection().toString()) {
      e.target.setSelectionRange(0, e.target.value.length);
    }
  }

  render() {
    const { clipboardState, id, value, rowCount = 1, inputWidth, placeholder } = this.props;
    const clipboardSuccess = clipboardState.clipboardSuccess;
    const clipboardFailure = clipboardState.clipboardFailure;
    let tooltipMessage;
    const showTooltip = clipboardFailure[id] || clipboardSuccess[id];

    if (clipboardSuccess[id]) {
      tooltipMessage = (
        <div>
          <i className="sci sci__circle--check va-default" /> Copied!
        </div>
      );
    } else if (clipboardFailure[id]) {
      tooltipMessage = <div>Cmd + C to copy</div>;
    }

    return (
      <div className="grid grid--full">
        <div className={'grid__item ' + (inputWidth || 'col-11-12')}>
          {rowCount == 1 && (
            <input
              id={id}
              readOnly
              className="code col-1-1"
              value={value}
              onClick={e => this.selectOnClick(e)}
              placeholder={placeholder || ''}
            />
          )}
          {rowCount > 1 && (
            <textarea
              id={id}
              rows={rowCount}
              readOnly
              className="code col-1-1"
              value={value}
              onClick={e => this.selectOnClick(e)}
              placeholder={placeholder || ''}
            />
          )}
        </div>
        <div className={`grid__item ${rowCount == 1 ? 'flex justify-content--center' : ''}`}>
          <button
            id={id + 'Button'}
            data-clipboard-target={'#' + id}
            disabled={value.length === 0}
            className={`position--relative flex ${
              value.length === 0 ? ' disabled color--muted' : ''
            }`}
          >
            <i className="sci sci__clipboard--add sci--md-sm" />
            <div
              className={
                'position--absolute copy-tooltip zIndex-9--overlay' +
                (showTooltip ? '' : ' invisible')
              }
            >
              {tooltipMessage}
              <div className="tooltip-triangle" />
            </div>
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clipboardState: state.clipboardState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(clipboardActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyToClipboard);
