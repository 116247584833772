import React from 'react';
import config from '~/config';
import GenericError from '~/components/Errors/GenericError';
import vcIconSrc from '~/images/vc-icon.svg';

const OrgMigratedPage = () => {
  const ctaLinkUrl = config.VERACODE_LOGIN_HOST;
  const errorMessage = 'Your login has moved';
  const contactInfo = 'Your organization has been moved to the Veracode platform.';
  const ctaLinkText = 'Take me to the Veracode login';

  return (
    <div className="container flex justify-content--center height--75vh">
      <GenericError
        errorMessage={errorMessage}
        ctaLinkUrl={ctaLinkUrl}
        ctaLinkText={ctaLinkText}
        renderIcon={() => <img className="height--100 mt-" src={vcIconSrc} alt="" />}
      >
        {contactInfo}
      </GenericError>
    </div>
  );
};

export default OrgMigratedPage;
