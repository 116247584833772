import React from 'react';
import Select from 'react-select';

interface AddWorkspacesToGroupProps {
  workspaceId?: string;
  isOptional?: boolean;
  selectedWorkspaces: any[];
  selectableWorkspaces: any[];
  updateSelectedWorkspaces: (...args: any[]) => any;
  toggleAdminField: (...args: any[]) => any;
  buttons: React.ReactNode;
}

const AddWorkspacesToGroup: React.FunctionComponent<AddWorkspacesToGroupProps> = props => {
  const {
    selectableWorkspaces = [],
    selectedWorkspaces = [],
    isOptional = false,
    workspaceId,
  } = props;

  function addSelectedWorkspace(workspace) {
    workspace && props.updateSelectedWorkspaces(workspace.value);
  }

  function removeSelectedWorkspace(workspaceId) {
    props.updateSelectedWorkspaces(workspaceId);
  }

  function toggleAdminField(workspaceId) {
    props.toggleAdminField(workspaceId);
  }

  return (
    <div className="grid">
      <div className="grid__item col-1-1 mt-">
        <div>
          <strong className="font--h6">Choose Workspaces</strong>
          {isOptional && ` (optional)`}
        </div>
      </div>
      <div className="grid__item col-1-1 mt-">
        <Select
          value={''}
          options={selectableWorkspaces}
          name="group-workspaces"
          onChange={workspace => addSelectedWorkspace(workspace)}
          className={'srcclr-react-select-container'}
          classNamePrefix={'srcclr-react-select'}
        />
      </div>
      <div className="grid__item col-1-1 mt">
        <div>
          <strong className="font--h6">Choose Workspace Administrators</strong>
          {isOptional && ` (optional)`}
        </div>
        <div className="mt-">
          Workspace administrators can rename a workspace, create and delete workspace agents and
          delete projects within a workspace. Choose the teams that should be Workspace
          Administrators for this workspace.
        </div>
      </div>
      <div className="grid__item col-1-1 mt-">
        <div className="bo--1 border-color--black-light pv-">
          {selectedWorkspaces.length ? (
            <div>
              <div className="grid grid--full">
                <div className="grid__item col-1-2 pl-">
                  <strong>Workspace Name</strong>
                </div>
                <div className="grid__item col-5-12 pl-">
                  <strong>Workspace Administrator</strong>
                </div>
                <div className="grid__item col-1-12" />
              </div>
              <div className="max-height--150 overflow--auto">
                {selectedWorkspaces.map(workspace => {
                  const { id: teamId, name = '', role } = workspace;

                  return (
                    <div className="grid grid--full pv-- hover-row" key={`selected-${teamId}`}>
                      <div className="grid__item col-1-2 pl-">{name}</div>
                      <div className="grid__item col-5-12 flex align-items--center justify-content--center">
                        <label className="label--checkbox flex justify-content--center mb0">
                          <input
                            type="checkbox"
                            checked={role === 'WORKSPACE_ADMIN'}
                            onChange={() => toggleAdminField(teamId)}
                          />
                          <span className="react--checkbox mr0" />
                        </label>
                      </div>
                      {teamId !== workspaceId && (
                        <div className="grid__item col-1-12 flex justify-content--center">
                          <button
                            className="p--- flex align-items--center"
                            onClick={() => removeSelectedWorkspace(teamId)}
                          >
                            <i className="sci sci__trash color--danger-hover-row" />
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="color--muted-dark pl-">Select workspace above</div>
          )}
        </div>
      </div>

      {props.buttons}
    </div>
  );
};

export default AddWorkspacesToGroup;
