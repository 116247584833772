import AuthService from '~/utils/AuthService';
import { LOGOUT_TIME, SAML_EMAIL, veracodeSessionTimeout } from '~/constants/ModelConstants';
import config from '~/config';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import ApiService from '~/utils/ApiService';

let timeoutRef = null;

const AutoLogout = store => next => action => {
  const { getState } = store;

  const logUserOut = () => {
    const {
      myState,
      orgState,
      vcPageState: { shouldShowVeracodePage },
    }: {
      myState: App.MyState;
      orgState: App.OrgState;
      vcPageState: VCPageState;
    } = getState();
    const { org } = orgState;
    const isSaml = org.samlDomains && !!org.samlDomains.length;
    const { me } = myState;
    const { email = undefined } = me;
    let url = isSaml ? '/saml?autoLogout=true' : '/login?autoLogout=true';

    if (localStorage && localStorage.setItem) {
      // if user is a saml user, set email into local storage for easier sign-in
      if (isSaml && email) {
        localStorage.setItem(SAML_EMAIL, email);
      }
    }

    const tokenHeader = AuthService.getAuthToken();
    AuthService.logout(
      ApiService.post('/logout', { headers: { 'x-auth-token': tokenHeader } })
    ).then(() => {
      // if session expires for Veracode user
      // redirect to approriate session timeout screen
      // otherwise, redirect sourceclear users to sourceclear login
      if (shouldShowVeracodePage) {
        window.location.href = `${config.VERACODE_LOGIN_HOST}${veracodeSessionTimeout}`;
      } else {
        // as long as we're using window.location, we don't need to clear the state
        window.location.href = url;
      }
    });
  };

  const reset = () => {
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }

    // check to see if redux has loaded yet
    if (getState) {
      const {
        myState,
      }: {
        myState: App.MyState;
      } = getState();
      const { me } = myState;
      // if the user isn't logged in, we don't care about them being idle
      if (me.id) {
        timeoutRef = setTimeout(logUserOut, LOGOUT_TIME);
      }
    }
  };

  if (!action.ignoreTimeout) {
    reset();
  }

  return next(action);
};

export default AutoLogout;
