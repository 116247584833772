import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';

interface WorkspacePickerProps {
  workspaceId?: string;
  isOptional?: boolean;
  isDisabled?: boolean;
  selectedWorkspaces: any[];
  selectableWorkspaces: any[];
  updateSelectedWorkspaces: (...args: any[]) => any;
  handleRemove?: (id: string) => void;
  handleAdd?: (id: string) => void;
}

const WorkspacePicker: React.FunctionComponent<WorkspacePickerProps> = props => {
  const {
    selectableWorkspaces = [],
    selectedWorkspaces = [],
    workspaceId,
    updateSelectedWorkspaces,
    handleRemove,
    handleAdd,
  } = props;

  function addSelectedWorkspace(workspace) {
    // use custom handleAdd callback if provided
    !handleAdd
      ? workspace && updateSelectedWorkspaces(workspace.value)
      : workspace && handleAdd(workspace.value);
  }

  function removeSelectedWorkspace(workspaceId: string) {
    // use custom handleRemove callback if provided
    !handleRemove ? updateSelectedWorkspaces(workspaceId) : handleRemove(workspaceId);
  }

  const selectedWorkspacesClassNames = classNames('bo--1', 'pv-', {
    'border-color--muted-light bg-color--muted-light color--muted-dark': props.isDisabled,
    'border-color--black-light': !props.isDisabled,
  });

  return (
    <div className="grid">
      <div
        className="grid__item col-1-1 mt-"
        data-automation-id="WorkspacePicker-selectableWorkspacesDropdown"
      >
        <Select
          value={''}
          options={selectableWorkspaces}
          name="group-workspaces"
          onChange={workspace => addSelectedWorkspace(workspace)}
          className="srcclr-react-select-container"
          classNamePrefix={'srcclr-react-select'}
          isDisabled={props.isDisabled}
        />
      </div>
      <div
        className="grid__item col-1-1 mt-"
        data-automation-id="WorkspacePicker-selectedWorkspacesContainer"
      >
        <div className={selectedWorkspacesClassNames}>
          {selectedWorkspaces.length ? (
            <div>
              <div className="grid grid--full">
                <div className="grid__item col-11-12 pl-">
                  <strong>Workspace Name</strong>
                </div>
                <div className="grid__item col-1-12" />
              </div>
              <div className="max-height--150 overflow--auto">
                {selectedWorkspaces.map(workspace => {
                  const { id: teamId, name = '' } = workspace;
                  return (
                    <div
                      data-automation-id={`WorkspacePicker-selectedWorkspace-${name}`}
                      className="grid grid--full pv-- hover-row"
                      key={`selected-${teamId}`}
                    >
                      <div
                        data-automation-id="WorkspacePicker-selectedWorkspaceName"
                        className="grid__item col-11-12 pl-"
                      >
                        {name}
                      </div>
                      {teamId !== workspaceId && (
                        <div className="grid__item col-1-12 flex justify-content--center">
                          <button
                            data-automation-id="WorkspacePicker-removeSelectedWorkspace"
                            className="p--- flex align-items--center"
                            onClick={() => removeSelectedWorkspace(teamId)}
                            disabled={props.isDisabled}
                          >
                            <i className="sci sci__trash color--danger-hover-row" />
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="color--muted-dark pl-">Select workspace above</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkspacePicker;
