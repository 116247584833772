import React from 'react';
import Tooltip from '~/components/Tooltip';

interface ReportHeaderItemProps {
  label?: string;
  currentSort?: object;
  isSortable?: boolean;
  onClick?: (...args: any[]) => any;
  field?: string;
  /** eg, 'col-1-10' */
  widthClass?: string;
  alignment?: 'LEFT' | 'CENTER' | 'RIGHT';
  verticalAlignClass?: string;
  tooltip?: string;
  automationId?: string;
}

const ReportHeaderItem: React.FunctionComponent<ReportHeaderItemProps> = props => {
  const {
    widthClass = '',
    alignment = 'LEFT',
    field = '',
    label,
    isSortable = false,
    currentSort = {},
    verticalAlignClass = '',
    tooltip,
    automationId,
  } = props;
  const isClickable = !!props.onClick;
  const sortOrder = currentSort[field] || '';
  let alignmentClass = '';
  let iconClass = 'sci__arrow--closed-scroll';

  if (sortOrder === 'desc') {
    iconClass = 'sci__arrow--closed-down';
  } else if (sortOrder === 'asc') {
    iconClass = 'sci__arrow--closed-up';
  }

  function handleClick() {
    !!props.onClick && props.onClick(props.field);
  }

  if (alignment === 'CENTER') {
    alignmentClass = 'flex--justify-content--center text--center';
  } else if (alignment === 'RIGHT') {
    alignmentClass = 'flex--justify-content--end';
  }

  let headerLabel = (
    <div
      className={`flex ${alignmentClass} text--bold bo-b--1 border-color--white ${verticalAlignClass}`}
    >
      {label}
    </div>
  );

  if (tooltip) {
    headerLabel = (
      <Tooltip
        id={`report-${label}Header`}
        content={tooltip}
        place="top"
        maxWidthClass="max-width--300"
      >
        {isSortable ? (
          <div className={`flex flex--justify-content--space-between ${verticalAlignClass}`}>
            <div className="flex text--bold bo-b--1 border-color--white bo-b--1--hover-black">
              {label}
            </div>
            <div className="flex flex--align-items--center">
              <i className={`sci ${iconClass}`} />
            </div>
          </div>
        ) : (
          <div
            className={`flex ${alignmentClass} text--bold bo-b--1 border-color--white ${verticalAlignClass}`}
          >
            {label}
          </div>
        )}
      </Tooltip>
    );
  } else if (isSortable) {
    headerLabel = (
      <div className={`flex flex--justify-content--space-between ${verticalAlignClass}`}>
        <div className="flex text--bold bo-b--1 border-color--white bo-b--1--hover-black">
          {label}
        </div>
        <div className="flex flex--align-items--center">
          <i className={`sci ${iconClass}`} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`grid__item ${widthClass} ${isClickable ? 'cursor--pointer' : ''}`}
      onClick={isClickable ? handleClick : null}
      data-automation-id={automationId}
    >
      {headerLabel}
    </div>
  );
};

export default ReportHeaderItem;
