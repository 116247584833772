import * as actions from '~/actions/navigation';
import _ from 'lodash';

export const defaultState = {
  expandedSubOption: null,
  sidebarStatus: {},
  fullWidthStatus: false,
  personalSettingsStatus: {},
  teamState: {},
  teams: [],
  activeState: {},
  activeParams: {},
  activeTeamParent: false,
  isLoggedIn: false,
  searchBarExpand: false,
  settingsDropdownExpanded: false,
  resourcesDropdownExpanded: false,
  activeTourTab: 'install',
  isNewsletterValid: false,
  isSubmittingNewsletterSignup: false,
  newsletterSignupSuccess: false,
  newsletterSignupError: '',
  activeReportType: undefined,
  prevState: {},
  isWorkspaceDropdownExpanded: false,
  isWorkspaceManagementExpanded: true,
};

const navigationState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_USER_AUTH:
      return Object.assign({}, state, {
        isLoggedIn: action.isLoggedIn,
      });
    case actions.TOGGLE_SUB_OPTION: {
      const newTeamState = _.cloneDeep(state.teamState);

      newTeamState[action.teamId].expanded =
        newTeamState[action.teamId].expanded === action.option ? false : action.option;

      return Object.assign({}, state, {
        teamState: newTeamState,
      });
    }
    case actions.UPDATE_ACTIVE_TEAM: {
      const newTeamState = _.cloneDeep(state.teamState);
      const newActiveParams = _.cloneDeep(state.activeParams);

      if (!newTeamState[action.team.id]) {
        newTeamState[action.team.id] = { expanded: false };
      }

      if (newActiveParams.teamId === action.team.id) {
        newActiveParams.teamId = false;
      } else {
        newActiveParams.teamId = action.team.id;
      }

      return Object.assign({}, state, {
        teamState: newTeamState,
        activeParams: newActiveParams,
      });
    }
    case actions.COLLAPSE_TEAM_MENUS: {
      const newActiveParams = _.cloneDeep(state.activeParams);

      newActiveParams.teamId = false;

      return Object.assign({}, state, {
        teamState: {},
        activeParams: newActiveParams,
      });
    }
    case actions.UPDATE_TEAM_REPORTS: {
      const newTeams = state.teams.map(team => {
        if (team.id === action.team.id) {
          team.reports = action.reports;
        }
        return team;
      });

      return Object.assign([], state, {
        teams: newTeams,
      });
    }
    case actions.ADD_TEAM_TO_NAV: {
      const newTeams = state.teams.concat(action.team);
      return Object.assign([], state, {
        teams: newTeams,
      });
    }
    case actions.UPDATE_ACTIVE_TEAM_PARENT:
      return Object.assign({}, state, {
        activeTeamParent: action.teamId,
      });
    case actions.UPDATE_ACTIVE_STATE_AND_PARAMS: {
      const teamId = action.activeParams.teamId;
      const option = action.activeState.navMenu;
      const newTeamState = {};

      newTeamState[teamId] = { expanded: option };

      return Object.assign({}, state, {
        activeParams: action.activeParams,
        activeState: action.activeState,
        teamState: newTeamState,
        expandedSubOption: option,
      });
    }
    case actions.EXPAND_SEARCH_BAR:
      return Object.assign({}, state, {
        searchBarExpand: action.bool,
      });
    case actions.TOGGLE_SETTINGS_DROPDOWN:
      return Object.assign({}, state, {
        settingsDropdownExpanded: !state.settingsDropdownExpanded,
      });
    case actions.UPDATE_ACTIVE_TOUR_TAB:
      return Object.assign({}, state, {
        activeTourTab: action.tab,
      });
    case actions.TOGGLE_RESOURCES_DROPDOWN:
      return Object.assign({}, state, {
        resourcesDropdownExpanded: !state.resourcesDropdownExpanded,
      });
    case actions.CLOSE_RESOURCES_DROPDOWN:
      return Object.assign({}, state, {
        resourcesDropdownExpanded: false,
      });
    case actions.UPDATE_NEWSLETTER_VALIDITY:
      return Object.assign({}, state, {
        isNewsletterValid: action.bool,
      });
    case actions.REQUEST_NEWSLETTER_SIGNUP:
      return Object.assign({}, state, {
        isSubmittingNewsletterSignup: true,
        newsletterSignupError: '',
        newsletterSignupSuccess: false,
      });
    case actions.RECEIVE_NEWSLETTER_SIGNUP_SUCCESS:
      return Object.assign({}, state, {
        isSubmittingNewsletterSignup: false,
        newsletterSignupSuccess: true,
      });
    case actions.UPDATE_NEWSLETTER_SIGNUP_ERROR:
      return Object.assign({}, state, {
        isSubmittingNewsletterSignup: false,
        newsletterSignupSuccess: false,
        newsletterSignupError: action.error,
      });
    case actions.RESET_NEWSLETTER_FORM:
      return Object.assign({}, state, {
        isSubmittingNewsletterSignup: false,
        newsletterSignupError: '',
        newsletterSignupSuccess: false,
      });
    case actions.UPDATE_SIDEBAR_STATUS:
      return Object.assign({}, state, {
        sidebarStatus: action.status,
      });
    case actions.UPDATE_FULL_WIDTH_STATUS:
      return {
        ...state,
        fullWidthStatus: action.status,
      };
    case actions.UPDATE_PERSONAL_SETTINGS_STATUS:
      return {
        ...state,
        personalSettingsStatus: action.status,
      };
    case actions.UPDATE_ACTIVE_REPORT_TYPE:
      return {
        ...state,
        activeReportType: action.reportType,
      };
    case actions.UPDATE_PREVIOUS_STATE:
      if (!_.isEmpty(action.prevState)) {
        return {
          ...state,
          prevState: action.prevState,
        };
      } else {
        return state;
      }
    case actions.OPEN_WORKSPACE_DROPDOWN:
      return {
        ...state,
        isWorkspaceDropdownExpanded: true,
      };
    case actions.CLOSE_WORKSPACE_DROPDOWN:
      return {
        ...state,
        isWorkspaceDropdownExpanded: false,
      };
    case actions.TOGGLE_WORKSPACE_MANAGEMENT:
      return {
        ...state,
        isWorkspaceManagementExpanded: action.bool,
      };
    default:
      return state;
  }
};

export default navigationState;
