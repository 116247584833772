import React from 'react';
import { Link } from 'react-router-dom';
import UserManagementQuickViewWrapper from '~/containers/UserManagementQuickViewWrapper';
import UserGroupsQuickView from '~/components/UserGroupsQuickView';
import UserTeamsQuickView from '~/components/UserTeamsQuickView';
import Helpers from '~/utils/Helpers';
import { VC_USER_ROLES } from '~/constants/ModelConstants';

interface OrgUsersListProps {
  isSaml: boolean;
  isSso: boolean;
  sortAscending: boolean;
  sortColumn: string;
  users: any[];
  filteredUsers: any[];
  selectedUsers: any[];
  quickViewsByUserId: object;
  handleUserSelection: (...args: any[]) => any;
  toggleQuickView: (...args: any[]) => any;
  updateSort: (...args: any[]) => any;
  showGroups: boolean;
  permissions: object;
}

const OrgUsersList: React.FunctionComponent<OrgUsersListProps> = props => {
  const {
    users = [],
    filteredUsers = [],
    selectedUsers = [],
    sortColumn,
    sortAscending,
    isSaml,
    isSso,
    quickViewsByUserId,
    showGroups,
    permissions,
  } = props;
  const columnWidth = {
    email: 'col-1-4',
    userRole: 'col-1-10',
    teams: 'col-1-10',
    select: 'col-1-18',
    firstName: '',
    lastName: '',
    groups: '',
    twoFactor: '',
  };
  const {
    manageGroupUsers,
    updateUsers,
    updateGroups,
    updateTeams,
    manageGroupTeams,
  } = permissions;
  const enableTeamsQuickView = updateTeams || manageGroupTeams;
  const show2fa = !isSaml && !isSso; // not saml and not sso

  function updateSort(column) {
    props.updateSort(column);
  }

  const usersList = () => {
    if (!users.length) {
      return <div className="mt- font--h3 color--muted-dark">No users in organization.</div>;
    } else if (!filteredUsers.length) {
      return <div className="mt- font--h3 color--muted-dark">No users found.</div>;
    }
    return filteredUsers.map(userObj => {
      const { user = {}, orgRoles = [], numGroups, numTeams, _links = {} } = userObj;
      const { groups = {}, teams = {} } = _links;
      const { id: userId, email = '', firstName = '', lastName = '', twoFactorAuthEnabled } = user;
      const isSelected =
        selectedUsers.find(selectedUser => selectedUser.userId === userId) || false;
      const quickView = quickViewsByUserId[userId];
      const isOrgOwner = orgRoles.includes('OWNER');
      const isOrgAdmin = orgRoles.includes('ADMIN');
      const userRole = orgRoles.find(role => role !== 'USER') || orgRoles[0] || ''; // orgRoles is always at least ['USER']

      return (
        <div className="grid grid--narrower mt-" key={userId}>
          <div className={`grid__item flex align-items--center ${columnWidth.email}`}>
            {updateUsers ? (
              <Link
                to={`/org/settings/users/${userId}`}
                className="link--obvious col-1-1 text--overflow"
              >
                <strong>{email}</strong>
              </Link>
            ) : (
              <div className="col-1-1 text--overflow">{email}</div>
            )}
          </div>
          <div className={`grid__item text--overflow ${columnWidth.firstName}`}>
            {firstName || '--'}
          </div>
          <div className={`grid__item text--overflow ${columnWidth.lastName}`}>
            {lastName || '--'}
          </div>
          <div className={`grid__item flex align-items--center ${columnWidth.userRole}`}>
            {Helpers.capFirst(VC_USER_ROLES[userRole] || userRole)}
          </div>
          {showGroups && (
            <div className={`grid__item flex justify-content--center ${columnWidth.groups}`}>
              {isOrgAdmin || isOrgOwner ? (
                <div>(All)</div>
              ) : (
                <div
                  className={`flex justify-content--space-between align-items--center col-3-10 ${
                    numGroups && 'cursor--pointer'
                  }`}
                  onClick={numGroups ? () => props.toggleQuickView(userId, 'GROUPS') : undefined}
                >
                  {numGroups}
                  <i
                    className={`sci sci__caret--${
                      quickView === 'GROUPS' ? 'down' : 'right'
                    } color--${numGroups ? 'primary' : 'muted-light'}`}
                  />
                </div>
              )}
            </div>
          )}
          <div className={`grid__item flex justify-content--center ${columnWidth.teams}`}>
            {isOrgAdmin || isOrgOwner ? (
              <div>(All)</div>
            ) : (
              <div
                className={`flex justify-content--space-between align-items--center col-3-10 ${
                  numTeams && enableTeamsQuickView && 'cursor--pointer'
                }`}
                onClick={
                  numTeams && enableTeamsQuickView
                    ? () => props.toggleQuickView(userId, 'TEAMS')
                    : undefined
                }
              >
                {numTeams}
                <i
                  className={`sci sci__caret--${quickView === 'TEAMS' ? 'down' : 'right'} color--${
                    numTeams && enableTeamsQuickView ? 'primary' : 'muted-light'
                  }`}
                />
              </div>
            )}
          </div>
          {show2fa && (
            <div
              className={`grid__item ${columnWidth.twoFactor} flex justify-content--center align-items--center`}
            >
              {twoFactorAuthEnabled ? 'Yes' : '--'}
            </div>
          )}
          {manageGroupUsers && (
            <div
              className={`grid__item ${columnWidth.select} flex justify-content--center align-items--center`}
            >
              <label className="label--checkbox flex justify-content--center mb0">
                <input
                  type="checkbox"
                  name="users-option"
                  checked={isSelected}
                  onChange={() => props.handleUserSelection(userId)}
                />
                <span className="react--checkbox mr0" />
              </label>
            </div>
          )}
          <div
            className={`grid__item col-1-1 ${
              quickView === 'GROUPS' ? 'is-showing-400' : 'is-hiding'
            }`}
          >
            {quickView === 'GROUPS' && (
              <UserManagementQuickViewWrapper dataType="groups" href={groups.href}>
                <UserGroupsQuickView showDetailsLink={updateGroups} />
              </UserManagementQuickViewWrapper>
            )}
          </div>
          <div
            className={`grid__item col-1-1 ${
              quickView === 'TEAMS' ? 'is-showing-400' : 'is-hiding'
            }`}
          >
            {quickView === 'TEAMS' && (
              <UserManagementQuickViewWrapper dataType="workspaces" href={teams.href}>
                <UserTeamsQuickView
                  showDetailsLink={enableTeamsQuickView}
                  showGroups={showGroups}
                />
              </UserManagementQuickViewWrapper>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="grid">
      <div className="grid__item col-1-1 mt">
        {/* users list header */}
        <div className="grid grid--narrower">
          <div
            className={`grid__item flex align-items--center justify-content--space-between ${columnWidth.email} cursor--pointer`}
            onClick={() => updateSort('email')}
          >
            <strong>Email</strong>
            {sortColumn === 'email' ? (
              <i
                className={`sci ${
                  sortAscending ? 'sci__arrow--closed-up' : 'sci__arrow--closed-down'
                }`}
              />
            ) : (
              <i className={`sci sci__arrow--closed-scroll`} />
            )}
          </div>
          <div className={`grid__item ${columnWidth.firstName}`}>
            <strong>First Name</strong>
          </div>
          <div className={`grid__item ${columnWidth.lastName}`}>
            <strong>Last Name</strong>
          </div>
          <div className={`grid__item ${columnWidth.userRole}`}>
            <strong>User Role</strong>
          </div>
          {showGroups && (
            <div className={`grid__item ${columnWidth.groups} text--center`}>
              <strong>Teams</strong>
            </div>
          )}
          <div className={`grid__item ${columnWidth.teams} text--center`}>
            <strong>Workspaces</strong>
          </div>
          {show2fa && (
            <div className={`grid__item ${columnWidth.twoFactor} text--center`}>
              <strong>2-Factor Authentication</strong>
            </div>
          )}
          {manageGroupUsers && (
            <div className={`grid__item ${columnWidth.select} text--center`}>
              <strong>Select</strong>
            </div>
          )}
        </div>
      </div>
      <div className="grid__item col-1-1">{usersList()}</div>
    </div>
  );
};

export default OrgUsersList;
