import * as actions from '~/actions/upgradeModal';

const defaultState = {
  openedModal: null,
  location: null,
};

const upgradeModalState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SHOW_UPGRADE_MODAL:
      return {
        ...state,
        openedModal: action.inputId,
        location: action.location,
      };
    case actions.CLOSE_UPGRADE_MODAL:
      return {
        ...state,
        openedModal: null,
        location: null,
      };
    default:
      return state;
  }
};

export default upgradeModalState;
