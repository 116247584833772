import _ from 'lodash';
import { reduxApiMap } from '~/constants/ModelConstants';

import * as actions from '~/actions/reports';
import { UPDATE_REPO_DATA } from '~/actions/repo';
import { UPDATE_IN_CATALOG_STATUS } from '~/actions/libraryCatalog';

const defaultState = {};

const reportsByType = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        [action.reportType]: action.response,
      };
    case actions.FETCH_REPORT_FAILURE:
      // if error occurs fetching a report of type ISSUES | REPOSITORIES | VULNERABILITIES, etc reset respective report type
      // to avoid stale issues list persisting in memory
      return {
        ...state,
        [action.reportType]: {},
      };
    case actions.REMOVE_REPORT_ISSUE_ITEM: {
      const contentKey =
        reduxApiMap[action.reportType] && reduxApiMap[action.reportType].contentKey;
      let nextResponseData = _.cloneDeep(state[action.reportType]);

      if (nextResponseData) {
        const previousEmbeddedContent = nextResponseData._embedded[contentKey] || [];
        const filteredEmbeddedContent = previousEmbeddedContent.filter(
          item => item.id !== action.id
        );

        nextResponseData._embedded[contentKey] = filteredEmbeddedContent;
      }

      return {
        ...state,
        [action.reportType]: nextResponseData,
      };
    }
    case UPDATE_REPO_DATA: {
      // this is an action that fires when a user favorites/unfavorites a repo
      // we need to update the repos list with the new 'favorite' value
      const { id, favorite } = action.response;
      const repoReportData = _.cloneDeep(state['REPOSITORIES']);

      // if you haven't loaded the repos list yet, we don't need to do anything
      if (_.isEmpty(repoReportData)) return state;

      const { _embedded = {} } = repoReportData;
      const { repos = [] } = _embedded;
      const newRepos = repos.map(repo => {
        if (repo.id === id) {
          repo.favorite = favorite;
        }
        return repo;
      });
      repoReportData._embedded.repos = newRepos;

      return {
        ...state,
        REPOSITORIES: repoReportData,
      };
    }
    case actions.DECREMENT_PAGE_TOTAL_ELEMENTS: {
      let nextResponseData = _.cloneDeep(state[action.reportType]);

      if (nextResponseData) {
        const previousPageData = nextResponseData.page;
        const newTotalElements =
          previousPageData && previousPageData.totalElements >= action.decrementBy
            ? previousPageData.totalElements - action.decrementBy
            : 0;

        nextResponseData.page = { ...previousPageData, totalElements: newTotalElements };
      }
      return {
        ...state,
        [action.reportType]: nextResponseData,
      };
    }
    case UPDATE_IN_CATALOG_STATUS: {
      const contentKey =
        reduxApiMap[action.reportType] && reduxApiMap[action.reportType].contentKey;
      let nextResponseData = _.cloneDeep(state[action.reportType]);

      if (nextResponseData) {
        const previousEmbeddedContent = nextResponseData._embedded[contentKey] || [];
        const filteredEmbeddedContent = previousEmbeddedContent.map(item => {
          if (action.instance && action.instance.includes(item.instanceRef)) {
            return {
              ...item,
              inCatalog: true,
            };
          }

          return item;
        });

        nextResponseData._embedded[contentKey] = filteredEmbeddedContent;
      }

      return {
        ...state,
        [action.reportType]: nextResponseData,
      };
    }
    case actions.RESET_REPORT_STATE:
      return defaultState;
    default:
      return state;
  }
};

export default reportsByType;
