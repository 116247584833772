import produce from 'immer';
import { VCDropdownState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import {
  VC_DROPDOWN_MENU_OPEN,
  VC_DROPDOWN_MENU_CLOSE,
  VCDropdownMenuActionTypes,
} from '~/actions/vcAppActions/vcAppActionsTypes/types';
const defaultState: VCDropdownState = {
  vcDropdownId: null,
};

const vcDropdownState = (state = defaultState, action: VCDropdownMenuActionTypes) => {
  return produce(state, draft => {
    switch (action.type) {
      case VC_DROPDOWN_MENU_OPEN: {
        draft.vcDropdownId = action.id;
        break;
      }
      case VC_DROPDOWN_MENU_CLOSE: {
        draft.vcDropdownId = null;
      }
    }
  });
};

export default vcDropdownState;
