import React from 'react';
import Select from 'react-select';

interface LinkWorkspaceToTeamsProps {
  selectableGroups: any[];
  selectedGroups: any[];
  buttons?: React.ReactNode;
  toggleAdminField: (...args: any[]) => any;
  updateSelectedGroups: (...args: any[]) => any;
  shouldShowVeracodePage: boolean;
}

const LinkWorkspaceToTeams: React.FunctionComponent<LinkWorkspaceToTeamsProps> = props => {
  const { selectableGroups = [], selectedGroups = [], shouldShowVeracodePage } = props;

  function toggleAdminField(groupId) {
    props.toggleAdminField(groupId);
  }

  function removeSelectedGroup(groupId) {
    props.updateSelectedGroups(groupId);
  }

  function addSelectedGroup(group) {
    group && props.updateSelectedGroups(group.value);
  }

  return (
    <div className="grid">
      <div className="grid__item col-1-1 mt-">
        <div>
          <strong className="font--h6">Choose Teams</strong>
        </div>
      </div>
      <div className="grid__item col-1-1 mt-" data-automation-id="LinkWorkspaceToTeams-chooseTeamsSelect">
        <Select
          value={''}
          options={selectableGroups}
          name="workspace-group"
          onChange={group => addSelectedGroup(group)}
          className={'srcclr-react-select-container'}
          classNamePrefix={'srcclr-react-select'}
          noOptionsMessage={() => 'No results found'}
        />
      </div>
      <div className="grid__item col-1-1 mt">
        <div>
          <strong className="font--h6">
            {shouldShowVeracodePage ? 'Selected Teams' : 'Choose Workspace Administrators'}
          </strong>
          {!shouldShowVeracodePage && ' (optional)'}
        </div>
        {!shouldShowVeracodePage && (
          <div className="mt-">
            Workspace administrators can rename a workspace, create and delete workspace agents, and
            delete projects within a workspace. Choose the teams that should be Workspace Admins for
            this workspace.
          </div>
        )}
      </div>
      <div className="grid__item col-1-1 mt-">
        <div className="bo--1 border-color--black-light pv-">
          {selectedGroups.length ? (
            <div>
              {!shouldShowVeracodePage && (
                <div className="grid grid--full">
                  <div className="grid__item col-1-2 pl-">
                    <strong>Team Name</strong>
                  </div>
                  <div className="grid__item col-5-12 text--center">
                    <strong>Workspace Administrator</strong>
                  </div>
                  <div className="grid__item col-1-12" />
                </div>
              )}
              <div className="max-height--150 overflow--auto">
                {selectedGroups.map(group => {
                  const { name = '', stagedAdminStatus = false, id: groupId } = group;

                  return (
                    <div
                      className="grid grid--full pv-- hover-row"
                      key={`workspace-group-${groupId}`}
                    >
                      <div className="grid__item col-1-2 pl-">{name}</div>
                      <div className="grid__item col-5-12 flex align-items--center justify-content--center">
                        {!shouldShowVeracodePage && (
                          <label className="label--checkbox flex justify-content--center mb0">
                            <input
                              type="checkbox"
                              checked={stagedAdminStatus}
                              onChange={() => toggleAdminField(groupId)}
                            />
                            <span className="react--checkbox mr0" />
                          </label>
                        )}
                      </div>
                      <div className="grid__item col-1-12 flex align-items--center justify-content--center">
                        <button
                          className="p--- flex align-items--center"
                          onClick={() => removeSelectedGroup(groupId)}
                        >
                          <i className="sci sci__trash color--danger-hover-row" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="pl- color--muted-dark">
              <em>Select teams above.</em>
            </div>
          )}
        </div>
      </div>

      {props.buttons}
    </div>
  );
};

export default LinkWorkspaceToTeams;
