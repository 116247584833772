import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import _ from 'lodash';

import * as integrationActions from '~/actions/integration';
import * as integrationModalActions from '~/actions/integrationModal';
interface FieldDropDownProps {
  integrationActions: object;
  integrationModalActions: object;
  integrationModalState: object;
  fieldName: string;
  /**
   * Expects an array of Objects of {label: "x", "value": y}
   */
  items?: any[];
  /**
   * Alternatively provide stringItems if label and value is the same
   */
  stringItems?: any[];
  name?: string;
  noOptionsMessage?: () => string;
  isClearable?: boolean;
  value?: string;
  /**
   * Async mode, when set to true, asyncGetOptions should supply a
   * function to retrieve the value given the input of the user
   */
  async?: boolean;
  asyncGetOptions?: (...args: any[]) => any;
  isLoading?: boolean;
}
class FieldDropDown extends React.Component<FieldDropDownProps, {}> {
  constructor(props, context) {
    super(props, context);

    if (this.props.async) {
      this.delayedAsyncGetOptions = _.debounce(this.props.asyncGetOptions, 500);
    }
  }

  onDropdownListToggle = fieldType => {
    const { integrationModalState } = this.props;
    const { fields = {} } = integrationModalState;
    const currentOpenState = fields[fieldType];
    this.props.integrationModalActions.updateIntegrationModalField(fieldType, !currentOpenState);
  };

  onDropdownListSelected(fieldType, field) {
    const value = field ? field.value : null;
    this.props.integrationModalActions.updateIntegrationModalField(fieldType, value);
  }

  onInputChange = input => {
    const { async = false } = this.props;

    if (async) {
      this.delayedAsyncGetOptions(input);
    }

    return input;
  };

  render() {
    const {
      fieldName = '',
      items,
      stringItems,
      name = '',
      value = '',
      noOptionsMessage = () => 'No results found',
      isClearable = true,
      isLoading = false,
    } = this.props;
    const key = fieldName;
    const options = stringItems ? stringItems.map(item => ({ value: item, label: item })) : items;
    const selectedValue = options.find(option => option.value === value || '');

    return (
      <Select
        isClearable={isClearable}
        name={`jiraField-${key}`}
        value={selectedValue}
        options={options}
        onChange={field => this.onDropdownListSelected(`jiraField-${key}`, field)}
        placeholder={name.length > 0 ? `Select ${name.toLowerCase()}` : ''}
        noOptionsMessage={noOptionsMessage}
        onInputChange={this.onInputChange}
        isLoading={isLoading}
        className={'srcclr-react-select-container'}
        classNamePrefix={'srcclr-react-select'}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationModalState: state.integrationModalState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
    integrationActions: bindActionCreators(integrationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldDropDown);
