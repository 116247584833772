import React from 'react';

interface CodeDiffHeaderProps {
  text: string;
}

const CodeDiffHeader: React.FunctionComponent<CodeDiffHeaderProps> = props => {
  const { text } = props;
  return (
    <div
      className={`col-1-1- pv--- ph font-family--code bg-color--white-medium bo-b--1 border-color--white-dark`}
    >
      {text}
    </div>
  );
};

export default CodeDiffHeader;
