export function gatherDropdownOptions(options: Array<any>) {
  let result: any = [];
  options.forEach(option => {
    if (option.options) result.push(option.options);
    if (Array.isArray(option.options)) {
      result = result.concat(gatherDropdownOptions(option.options));
    }
  });
  return result;
}
