import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~/reducers';
import WorkspacePoliciesPage from '~/containers/WorkspacePoliciesPage';
import UnifiedWorkspacePoliciesPage from '~/containers/UnifiedWorkspacePoliciesPage';
import PolicyMigrationRulesPage from '~/containers/PolicyMigrationRulesPage';

const WorkspacePoliciesHub = () => {
  const permissions = useSelector((state: RootState) => state?.myState?.me?.permissions);
  const isUnifiedPolicy = permissions?.unifiedPolicy;
  const isPolicyMigrationInProgress = permissions?.unifiedPolicyMigration;

  if (isPolicyMigrationInProgress) {
    return <PolicyMigrationRulesPage />;
  } else {
    return isUnifiedPolicy ? <UnifiedWorkspacePoliciesPage /> : <WorkspacePoliciesPage />;
  }
};

export default WorkspacePoliciesHub;
