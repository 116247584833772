import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';
import moment from 'moment';

import Helpers from '~/utils/Helpers';

import UpdatePaymentStripeForm from '~/containers/UpdatePaymentStripeForm';
import SourceClearModal from '~/components/SourceClearModal';
import PlanDetailsList from '~/components/PlanDetailsList';
import ScanMeter from '~/components/ScanMeter';

import * as billingActions from '~/actions/billing';

interface SubscriptionPageProps extends RouteComponentProps {
  billingActions: object;
  billingState: object;
  orgState: App.OrgState;
}

class SubscriptionPage extends React.Component<SubscriptionPageProps, {}> {
  componentDidMount() {
    const { orgState, history } = this.props;
    const { org = {} } = orgState;
    const { license = {}, permissions = {} } = org;
    const { billing } = permissions;
    const { planType, trialEndDate } = license;

    if (!billing) {
      history.replace('/org/settings/no-owner');
    }

    if (planType !== 'PRO' || trialEndDate) {
      history.push('/org/settings');
    } else {
      this.props.billingActions.fetchBillingDashboard(org.id);
    }
  }

  toggleModal(modalType) {
    this.props.billingActions.showModal(modalType);
  }

  toggleAllowOverages() {
    const { billingState, orgState } = this.props;
    const { allowOverages } = billingState;
    const { org = {} } = orgState;

    this.props.billingActions.toggleAllowOverages(org.id, !allowOverages);
  }

  requestSwitch() {
    this.props.billingActions.requestSwitch();
  }

  render() {
    const { billingState, orgState } = this.props;
    const { org = {} } = orgState;
    const { showModal, invoices = [], scanUsage, plan = {}, isFetchingDashboard } = billingState;
    const paymentMethod = billingState.paymentMethod || {};
    const { scanLimit, priceInCents, id } = plan;
    const { purchaseOrder } = org;

    const mostRecentInvoice = invoices[0] || {};
    const { totalInCents = 0, date } = mostRecentInvoice;
    const { cardType = '', lastFour } = paymentMethod;
    const isBusinessPlan = id === 2001;

    return (
      <div className="grid mt mb++">
        <Helmet>
          <title>Organization Subscription</title>
        </Helmet>
        <div className="grid__item col-1-1 text--center">
          <div className="font--h3">Your Subscription Plan</div>
        </div>
        <div className="grid__item col-1-2 mt">
          <div className="bo-b--1 border-color--muted pb---">
            <div className="font--h4">Plan Details</div>
          </div>
          <div>
            <div className="mt- font--h5">
              {purchaseOrder ? (
                <span>
                  {' '}
                  {isBusinessPlan ? 'Business' : 'Professional'} -{' '}
                  {scanLimit ? `${scanLimit.toLocaleString()} scans/month` : 'Unlimited scans'},
                  offline billing{' '}
                </span>
              ) : (
                <span>
                  {' '}
                  {isBusinessPlan ? 'Business' : 'Professional'} -{' '}
                  {scanLimit ? `${scanLimit.toLocaleString()} scans/month` : 'Unlimited scans'},{' '}
                  {Helpers.formatCost(priceInCents)}
                  /month{' '}
                </span>
              )}
            </div>
            <div className="grid mt---">
              <div className="grid__item col-1-2">
                {scanLimit && (
                  <ScanMeter
                    isFetchingDashboard={isFetchingDashboard}
                    scanUsage={scanUsage}
                    plan={plan}
                  />
                )}
              </div>
              <div className="grid__item col-1-2 mt-- font--h8">
                <a
                  href="mailto:support@veracode.com?Subject=Cancel%20SourceClear%20Plan%20Request"
                  className="link--obvious"
                >
                  Contact Support
                </a>
                &nbsp; to cancel plan
              </div>
            </div>
          </div>
        </div>
        {purchaseOrder ? (
          <div className="grid__item col-1-2" />
        ) : (
          <div className="grid__item col-1-2 mt">
            <div className="bo-b--1 border-color--muted pb---">
              <div className="font--h4">Payment Details</div>
            </div>
            <div className="mt- grid">
              <div className="grid__item">
                <div className="">Last Payment</div>
                <div className="font--h5 mt---">{Helpers.formatCost(totalInCents)}</div>
                <div className="mt---">Received {moment(date).format('MMM D, YYYY')}</div>
                <div className="mt---">
                  <Link to="/org/settings/billing" className="link--obvious">
                    View payment history
                  </Link>
                </div>
              </div>
              <div className="grid__item">
                <div className="">Payment Method</div>
                <div className="font--h5 mt---">
                  {cardType.toUpperCase()} **** {lastFour}
                </div>
                <div className="link--obvious mt---" onClick={() => this.toggleModal('PAYMENT')}>
                  Update payment method
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="grid__item col-1-2 mt++">
          <div className="bo-b--1 border-color--muted pb---">
            <div className="font--h4">Get to Know Your Plan</div>
          </div>
          <div className="grid">
            <div className="grid__item">
              <PlanDetailsList tier={isBusinessPlan ? 'business' : 'pro'} />
            </div>
          </div>
        </div>
        <SourceClearModal
          isOpen={showModal === 'PAYMENT'}
          title="Update Payment Method"
          onClose={() => this.toggleModal()}
          width={400}
        >
          <UpdatePaymentStripeForm onClose={() => this.toggleModal()} />
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    billingState: state.billingState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    billingActions: bindActionCreators(billingActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
