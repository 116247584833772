import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import * as orgUserActions from '~/actions/orgUser';
import * as orgUserInviteActions from '~/actions/orgUserInvite';
import * as groupActions from '~/actions/group';
import * as toastrActions from '~/actions/toastr';

import AddInviteesToGroup from '~/containers/AddInviteesToGroup';

interface AddSelectedInviteesToGroupModalProps {
  groupActions: object;
  orgUserActions: object;
  orgUserInviteActions: object;
  toastrActions: object;
  groupState: object;
  orgUserState: object;
  showModal: (...args: any[]) => any;
}
class AddSelectedInviteesToGroupModal extends React.Component<
  AddSelectedInviteesToGroupModalProps,
  {}
> {
  updateSelectedGroup(groupId) {
    this.props.groupActions.updateSelectedGroups(groupId);
  }

  toggleAdminField(email) {
    this.props.orgUserActions.toggleSelectedInviteAdminField(email);
  }

  addInviteesToGroup() {
    const { groupState, orgUserState } = this.props;
    const { selectedPendingInvites = [], pendingInvites = [] } = orgUserState;
    const { groups = [], selectedGroups = {} } = groupState;
    const selectedPendingInvitesMap = {};
    selectedPendingInvites.forEach(
      selectedInvite => (selectedPendingInvitesMap[selectedInvite.inviteId] = true)
    );

    const selectedInvites = pendingInvites.filter(invite => selectedPendingInvitesMap[invite.id]);
    const selectedGroup = groups.find(group => selectedGroups[group.id]);
    const selectedGroupId = Object.keys(selectedGroups)[0];

    const inviteData = {
      groupId: parseInt(selectedGroupId),
      users: selectedInvites.map(invite => {
        const { email, orgRole, stagedAdminStatus } = invite;

        return {
          email,
          orgRole,
          groupAdmin: stagedAdminStatus || false,
        };
      }),
    };

    this.props.orgUserInviteActions.sendInvites(inviteData).then((res = {}) => {
      const { invites = [], success } = res;

      if (invites.length) {
        const invitesAddedToGroup = [];
        const invitesAlreadyOwnersOrAdmins = [];
        invites.forEach(invite => {
          if (invite.orgRole === 'OWNER' || invite.orgRole === 'ADMIN') {
            invitesAlreadyOwnersOrAdmins.push(invite.email);
          } else {
            invitesAddedToGroup.push(invite.email);
          }
        });
        const successMessage = (
          <div className="mv-">
            {invitesAddedToGroup.length ? (
              <div className="">
                The following invites have been added to the team{' '}
                <strong>{selectedGroup.name}</strong>: {invitesAddedToGroup.join(', ')}
              </div>
            ) : (
              ''
            )}
            {invitesAlreadyOwnersOrAdmins.length ? (
              <div className={invitesAddedToGroup.length && 'mt-'}>
                The following invitations are Org Owners or Org Admins, and therefore have access to
                all groups: {invitesAlreadyOwnersOrAdmins.join(', ')}
              </div>
            ) : (
              ''
            )}
          </div>
        );

        this.props.toastrActions.addToastr({
          id: 'ADD_GROUP_SUCCESS',
          level: 'success',
          title: 'Invitations successfully added to team',
          message: successMessage,
        });
      }

      if (success) {
        this.props.orgUserActions.resetSelectedPendingInvites();
        this.props.orgUserActions.fetchPendingInvites();
        this.props.groupActions.resetSelectedGroups();
        this.showModal();
      }
    });
  }

  showModal(modalType) {
    this.props.showModal(modalType);
  }

  render() {
    const { groupState, orgUserState } = this.props;
    const { selectedPendingInvites = [], pendingInvites = [] } = orgUserState;
    const { groups = [], selectedGroups = {} } = groupState;
    const selectedPendingInvitesMap = {};
    const selectableGroups = groups.map(group => {
      return {
        label: group.name,
        value: group.id,
      };
    });
    selectedPendingInvites.forEach(
      selectedInvite => (selectedPendingInvitesMap[selectedInvite.inviteId] = true)
    );

    const selectedInvites = pendingInvites.filter(invite => selectedPendingInvitesMap[invite.id]);
    const selectedGroup =
      !_.isEmpty(selectableGroups) && selectableGroups.find(group => selectedGroups[group.value]);

    return (
      <div className="grid">
        <div className="grid__item col-1-1">
          <AddInviteesToGroup
            selectableGroups={selectableGroups}
            selectedGroup={selectedGroup}
            invitees={selectedInvites}
            updateSelectedGroup={groupId => this.updateSelectedGroup(groupId)}
            toggleAdminField={groupId => this.toggleAdminField(groupId)}
          />
        </div>
        <div className="grid__item col-1-1 mt flex justify-content--end">
          <button className="col-1-5 pv- mr-" onClick={() => this.showModal()}>
            Cancel
          </button>
          <button
            className={`col-1-5 btn--success pv- ${!selectedGroup && 'disabled'}`}
            disabled={!selectedGroup}
            onClick={() => this.addInviteesToGroup()}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupState: state.groupState,
    orgUserState: state.orgUserState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
    orgUserInviteActions: bindActionCreators(orgUserInviteActions, dispatch),
    toastrActions: bindActionCreators(toastrActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSelectedInviteesToGroupModal);
