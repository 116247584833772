import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';

import * as policyValidationAction from '~/actions/policyValidation';
import * as toastrActions from '~/actions/toastr';
import _ from 'lodash';

import { EffectiveRevision, PolicySettings } from '~/types/Policy';

export const ORG_ADD_CONTROL = 'ORG_ADD_CONTROL';
export const ORG_MOVE_CONTROL = 'ORG_MOVE_CONTROL';
export const ORG_REMOVE_CONTROL = 'ORG_REMOVE_CONTROL';
export const ORG_TOGGLE_CONTROL_EXPAND = 'ORG_TOGGLE_CONTROL_EXPAND';
export const ORG_TOGGLE_POLICY_STATUS_MODE = 'ORG_TOGGLE_POLICY_STATUS_MODE';
export const ORG_TOGGLE_EDIT_MODE = 'ORG_TOGGLE_EDIT_MODE';
export const ORG_TRIGGER_MOVE_EFFECT = 'ORG_TRIGGER_MOVE_EFFECT';
export const ORG_UPDATE_CONTROL = 'ORG_UPDATE_CONTROL';
export const UPDATE_ORGANIZATION_MODAL = 'UPDATE_ORGANIZATION_MODAL';
export const UPDATE_ORGANIZATION_POLICIES = 'UPDATE_ORGANIZATION_POLICIES';
export const UPDATE_ORGANIZATION_POLICY_SETTINGS = 'UPDATE_ORGANIZATION_POLICY_SETTINGS';
export const FETCH_ORGANIZATION_POLICIES_REQUEST = 'FETCH_ORGANIZATION_POLICIES_REQUEST';
export const FETCH_ORGANIZATION_POLICIES_FAILURE = 'FETCH_ORGANIZATION_POLICIES_FAILURE';
export const FETCH_ORGANIZATION_POLICY_SETTINGS_REQUEST =
  'FETCH_ORGANIZATION_POLICY_SETTINGS_REQUEST';
export const FETCH_ORGANIZATION_POLICY_SETTINGS_FAILURE =
  'FETCH_ORGANIZATION_POLICY_SETTINGS_FAILURE';
export const SAVE_ORGANIZATION_POLICY_SETTINGS_REQUEST =
  'SAVE_ORGANIZATION_POLICY_SETTINGS_REQUEST';
export const SAVE_ORGANIZATION_POLICY_SETTINGS_FAILURE =
  'SAVE_ORGANIZATION_POLICY_SETTINGS_FAILURE';
export const ORG_SAVE_CHANGES_REQUEST = 'ORG_SAVE_CHANGES_REQUEST';
export const ORG_SAVE_CHANGES_FAILURE = 'ORG_SAVE_CHANGES_FAILURE';
export const ORG_RESET_EXPANDED_CONTROLS = 'ORG_RESET_EXPANDED_CONTROLS';
export const ORG_RESET_MODES = 'ORG_RESET_MODES';
export const ORG_RESET_CUSTOM_POLICY_TO_DEFAULT = 'ORG_RESET_CUSTOM_POLICY_TO_DEFAULT';
export const ORG_RESET_TO_DEFAULT_POLICY_REQUEST = 'ORG_RESET_TO_DEFAULT_POLICY_REQUEST';
export const ORG_UPDATE_DEFAULT_POLICY = 'ORG_UPDATE_DEFAULT_POLICY';
export const TOGGLE_ENFORCE_RULES = 'TOGGLE_ENFORCE_RULES';
export const TOGGLE_SCOPE = 'TOGGLE_SCOPE';

const INVALID_POLICY = 'INVALID_POLICY';

export const toggleEnforceRules = (enforce: boolean) => ({
  type: TOGGLE_ENFORCE_RULES,
  enforce,
});

export const toggleScope = scope => ({
  type: TOGGLE_SCOPE,
  scope,
});

export const fetchOrgPoliciesRequest = () => ({
  type: FETCH_ORGANIZATION_POLICIES_REQUEST,
});

export const fetchOrgPoliciesFailure = error => ({
  type: FETCH_ORGANIZATION_POLICIES_FAILURE,
  message: error.message,
});

export const updateOrgPolicies = policy => ({
  type: UPDATE_ORGANIZATION_POLICIES,
  policy,
});

export const fetchOrgPolicySettingsRequest = () => ({
  type: FETCH_ORGANIZATION_POLICY_SETTINGS_REQUEST,
});

export const fetchOrgPolicySettingsFailure = error => ({
  type: FETCH_ORGANIZATION_POLICY_SETTINGS_FAILURE,
  message: error.message,
});

export const updateOrgPolicySettings = (policySettings: PolicySettings) => ({
  type: UPDATE_ORGANIZATION_POLICY_SETTINGS,
  policySettings,
});

export const saveChangesRequest = () => ({
  type: ORG_SAVE_CHANGES_REQUEST,
});

export const saveChangesFailure = error => ({
  type: ORG_SAVE_CHANGES_FAILURE,
  message: error.message,
});

export const saveOrgPolicySettingsRequest = () => ({
  type: SAVE_ORGANIZATION_POLICY_SETTINGS_REQUEST,
});

export const saveOrgPolicySettingsFailure = () => ({
  type: SAVE_ORGANIZATION_POLICY_SETTINGS_FAILURE,
});

export const resetExpandedControls = () => ({
  type: ORG_RESET_EXPANDED_CONTROLS,
});

export const toggleEditMode = cancel => ({
  type: ORG_TOGGLE_EDIT_MODE,
  cancel,
});

export const addControl = () => ({
  type: ORG_ADD_CONTROL,
});

export const removeControl = index => ({
  type: ORG_REMOVE_CONTROL,
  index,
});

export const toggleControlExpand = index => ({
  type: ORG_TOGGLE_CONTROL_EXPAND,
  index,
});

export const triggerMoveEffect = (index, bool) => ({
  type: ORG_TRIGGER_MOVE_EFFECT,
  index,
  bool,
});

export const resetCustomPolicyToDefault = (effectiveRevision: EffectiveRevision) => ({
  type: ORG_RESET_CUSTOM_POLICY_TO_DEFAULT,
  effectiveRevision,
});

export const resetToDefaultPolicyRequest = () => ({
  type: ORG_RESET_TO_DEFAULT_POLICY_REQUEST,
});

export const updateDefaultPolicy = policy => ({
  type: ORG_UPDATE_DEFAULT_POLICY,
  policy,
});

export const resetModes = () => ({
  type: ORG_RESET_MODES,
});

export const moveControl = (index, toIndex) => dispatch => {
  dispatch(triggerMoveEffect(toIndex, true));
  setTimeout(() => {
    dispatch(triggerMoveEffect(toIndex, false));
  }, 1000);

  dispatch({
    type: ORG_MOVE_CONTROL,
    index,
    toIndex,
  });
};

/**
 Setting null as option for parameter's field will clear the key
 **/
export const updateControl = (option, field, index) => ({
  type: ORG_UPDATE_CONTROL,
  option,
  field,
  index,
});

export const updatePolicyStatusMode = mode => ({
  type: ORG_TOGGLE_POLICY_STATUS_MODE,
  mode,
});

export const showModal = modalType => ({
  type: UPDATE_ORGANIZATION_MODAL,
  modalType,
});

/**
 * Format the policy before saving or validating
 * 1) Convert severity to float (must be string in order for input to work correctly)
 * 2) Remove severity if override is set
 **/
export const formatSeverity = policy => {
  if (!policy || !policy.controls || !policy.controls.length) {
    return policy;
  }

  const formattedControls = policy.controls.map(control => {
    /*
      Convert severity to float. Delete severity instead of sending empty string to allow
      the correct validation message of severity is required.
    */
    if (
      isNaN(control.severity) ||
      (_.isString(control.severity) && control.severity.trim().length === 0)
    ) {
      delete control.severity;
    } else {
      control.severity = parseFloat(control.severity);
    }

    // Delete severity if severity_override is set
    if (
      control.condition &&
      control.condition.descriptor &&
      control.condition.descriptor.parameters
    ) {
      const { severity_override } = control.condition.descriptor.parameters;

      if (severity_override === 'cvss') {
        delete control.severity;
      }
    }

    return control;
  });

  return {
    ...policy,
    version: policy.version,
    controls: formattedControls,
  };
};

/** Retrieve current org settings **/
export const fetchOrgPolicySettings = orgId => dispatch => {
  dispatch(fetchOrgPolicySettingsRequest());

  return ApiService.get(ApiConstants.orgPolicySettingsUrl(orgId))
    .then((res: PolicySettings) => {
      dispatch(updateOrgPolicySettings(res));
      // return the result so scopeTeams is immediately available for useEffect
      return res;
    })
    .catch(error => {
      ErrorService.capture('Error fetching organization settings', error);
      dispatch(fetchOrgPolicySettingsFailure(error));
    });
};

/** Save updated org settings **/
export const saveOrgPolicySettings = (scopeTeams, orgId) => (dispatch, getState) => {
  const {
    orgPoliciesState: {
      policySettings: { enforceRules, scope },
    },
  } = getState();
  dispatch(saveOrgPolicySettingsRequest());

  return ApiService.put(ApiConstants.orgPolicySettingsUrl(orgId), {
    data: {
      enforceRules,
      scope,
      scopeTeams,
    },
  })
    .then((res: PolicySettings) => {
      dispatch(updateOrgPolicySettings(res));
    })
    .catch(error => {
      ErrorService.capture('Error saving organization settings', error);
      dispatch(
        toastrActions.addToastr({
          id: 'error-saving-org-setting',
          level: 'error',
          title: 'Error saving organization settings.',
          message:
            "We're sorry, there has been an error saving the settings for this organization. Please try again or contact support.",
        })
      );
      dispatch(saveOrgPolicySettingsFailure(error));
    });
};

/** Retrieve the org-level policy and its current Effective Policy Controls **/
export const fetchOrgPolicies = orgId => dispatch => {
  dispatch(fetchOrgPoliciesRequest());

  return ApiService.get(ApiConstants.orgPolicyURL(orgId))
    .then((res = {}) => {
      dispatch(updateOrgPolicies(res));
    })
    .catch(error => {
      ErrorService.capture('Error fetching organization policies', error);
      dispatch(fetchOrgPoliciesFailure(error));
    });
};

/** Update the org-level policy status **/
export const togglePolicyStatusMode = (mode, orgId) => dispatch => {
  /*
   * there is very little reason for this to fail, so we'll use some "optimistic rendering"
   * to show the switch has been successful before the api response has been resolved.
   * if the response has a problem, we'll switch it back and show an error message.
   */

  dispatch(updatePolicyStatusMode(mode));

  return ApiService.put(ApiConstants.orgPolicyURL(orgId), {
    data: { status: mode },
  })
    .then(res => {
      // if successful, the saved policy will be returned
      dispatch(updateOrgPolicies(res));

      return { success: true };
    })
    .catch(error => {
      ErrorService.capture('Error updating policy mode', error);
      // TODO: fire error message explaining why we're switching the UI back...
      dispatch(
        toastrActions.addToastr({
          id: 'error-toggling-custom-policy',
          level: 'error',
          title: 'Error switching policy mode.',
          message:
            error.message ||
            "We're sorry, there has been an error switching your policy mode for this workspace. Please try again or contact support.",
        })
      );
      dispatch(updatePolicyStatusMode('DEFAULT'));
      return {};
    });
};

/** Returns the default controls **/
export const resetToDefault = () => dispatch => {
  dispatch(resetToDefaultPolicyRequest());

  ApiService.get(ApiConstants.defaultPoliciesUrl())
    .then((res: EffectiveRevision) => {
      dispatch(resetCustomPolicyToDefault(res));
      dispatch(showModal('')); //close modal
    })
    .catch(error => {
      ErrorService.capture('Error resetting policy to default', error);
      dispatch(fetchOrgPoliciesFailure(error));
    });
};

/** Save a new set of controls in the org-level policy **/
export const validateAndSaveControlChanges = (orgId: string) => (dispatch, getState) => {
  const {
    orgPoliciesState: {
      policy: { effectiveRevision },
    },
  } = getState();

  const data = formatSeverity(_.cloneDeep(effectiveRevision));
  const { controls } = data;

  // Not logging 400 status since that's the response to indicate the user input failed
  const ignoreStatusCode = 400;
  dispatch(policyValidationAction.validatePolicyRequest());
  dispatch(toastrActions.removeToastr({ id: INVALID_POLICY })); //Clear toastr if any before displaying any possible errors
  dispatch(saveChangesRequest());

  ApiService.post(ApiConstants.orgPolicyRevisionsUrl(orgId), {
    data: { controls },
  })
    .then(res => {
      dispatch(updateOrgPolicies(res));
      dispatch(policyValidationAction.updatePolicyValidationResults({}, 0));
      dispatch(resetExpandedControls());
    })
    .catch(error => {
      const {
        response: { data: validationError },
      } = error;
      // if validation fails on the backend
      if (error.status === ignoreStatusCode) {
        dispatch(policyValidationAction.validatePolicyFailure(error));
        const { policyValidationState, orgPoliciesState } = getState();
        const { validationResults = {} } = policyValidationState;
        const { policy = {} } = validationResults;
        const { controls = [] } = policy;
        const {
          expandedControlsByIndex = {},
          policy: {
            effectiveRevision: { controls: policyControls },
          },
        } = orgPoliciesState;

        dispatch(
          policyValidationAction.updatePolicyValidationResults(
            validationError,
            policyControls.length
          )
        );
        // Expand the controls with errors
        for (let [index, control] of controls.entries()) {
          const isExpanded = expandedControlsByIndex[index];
          if (control && !isExpanded) {
            dispatch(toggleControlExpand(index));
          }
        }
        const options = {
          id: INVALID_POLICY,
          title: 'Sorry, we could not save your rules',
          level: 'error',
          message: 'Correct the errors below.',
        };

        dispatch(toastrActions.addToastr(options));
      } else {
        // log non-400 status errors
        ErrorService.capture('error updating policy', error);
      }
      dispatch(saveChangesFailure(error));
    });
};
