import React from 'react';
import SourceClearModal from '~/components/SourceClearModal';

interface RemoveLibraryModalProps {
  isOpen: boolean;
  onClose: () => void;
  selected: object[];
}

const RemoveLibraryModal: React.FunctionComponent<RemoveLibraryModalProps> = props => {
  const { isOpen, onClose, selected } = props;

  return (
    <SourceClearModal isOpen={isOpen} title="Remove Library" onClose={() => onClose()} width={450}>
      <div className="pv">
        Are you sure you want to remove{' '}
        {selected.length > 1 ? `${selected.length} libraries` : 'this library'} from your commercial
        licenses? This action may result in the creation of new license issues the next time your
        organization scans a project that uses these libraries.
      </div>
      <div className="flex justify-content--end pt-">
        <button className="pv- ph mr--" onClick={() => onClose()}>
          Cancel
        </button>
        <button
          className="btn btn--success pv- ph"
          onClick={() => {
            //TODO: remove the selected libraries
            onClose();
          }}
        >
          Remove
        </button>
      </div>
    </SourceClearModal>
  );
};
export default RemoveLibraryModal;
