import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';

export const UPDATE_AGENT_SCANS = 'UPDATE_AGENT_SCANS';
export const UPDATE_IS_FETCHING_AGENT_SCANS = 'UPDATE_IS_FETCHING_AGENT_SCANS';

export const fetchAgentScans = (teamId, pageNum = 0) => (dispatch, getState) => {
  dispatch(updateIsFetchingAgentScans(true));
  const pageSize = 20;
  const { orgState, reportScope } = getState();
  const { org = {} } = orgState;
  const { id: orgId } = org;
  const requestParams = `?page=${pageNum}&size=${pageSize}&sort=date,desc`;
  const endpoint = teamId
    ? `/teams/${teamId}/reports/scans${requestParams}`
    : `/orgs/${orgId}/reports/scans${requestParams}`;

  return ApiService.post(endpoint, { data: reportScope })
    .then(res => {
      dispatch(updateAgentScans(res));
    })
    .catch(error => {
      ErrorService.capture('Error fetching agent scan data', error);
    });
};

export const updateAgentScans = scansData => ({
  type: UPDATE_AGENT_SCANS,
  scansData,
});

export const updateIsFetchingAgentScans = bool => ({
  type: UPDATE_IS_FETCHING_AGENT_SCANS,
  bool,
});
