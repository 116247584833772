export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';

export const openModal = modalId => ({
  type: SHOW_MODAL,
  modalId,
});

export const closeModal = modalId => ({
  type: CLOSE_MODAL,
  modalId,
});

export const closeAllModals = () => ({
  type: CLOSE_ALL_MODALS,
});
