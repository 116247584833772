import React from 'react';
import CopyToClipboard from '~/containers/CopyToClipboard';

function PowerShellExecutionPolicyStep() {
  return (
    <React.Fragment>
      <p>
        You may need to run the following command to set the execution policy, since PowerShell does
        not permit scripts to run by default. See{' '}
        <a
          href="https://docs.microsoft.com/en-us/powershell/module/microsoft.powershell.core/about/about_execution_policies?view=powershell-6#allsigned"
          className="link--primary"
          target="_blank"
        >
          About Execution Policies - AllSigned
        </a>{' '}
        for details on the AllSigned execution policy.{' '}
      </p>
      <CopyToClipboard
        value={`Set-ExecutionPolicy AllSigned -Scope Process -Force`}
        id={'allsigned-policy'}
      />
    </React.Fragment>
  );
}

export default PowerShellExecutionPolicyStep;
