import validator from 'validator';
import _ from 'lodash';

import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';

export const FILTER_PENDING_INVITES = 'FILTER_PENDING_INVITES';
export const REMOVE_USER_FROM_INVITE_LIST = 'REMOVE_USER_FROM_INVITE_LIST';
export const RESET_SELECTED_PENDING_INVITES = 'RESET_SELECTED_PENDING_INVITES';
export const RESET_WORKSPACE_INVITE_STATE = 'RESET_WORKSPACE_INVITE_STATE';
export const SELECT_ALL_WORKSPACE_PENDING_INVITES = 'SELECT_ALL_WORKSPACE_PENDING_INVITES';
export const TOGGLE_WORKSPACE_ADMIN = 'TOGGLE_WORKSPACE_ADMIN';
export const TOGGLE_WORKSPACE_INVITE_MODAL = 'TOGGLE_WORKSPACE_INVITE_MODAL';
export const UPDATE_INVALID_EMAILS = 'UPDATE_INVALID_EMAILS';
export const UPDATE_INVITE_EMAILS_INPUT = 'UPDATE_INVITE_EMAILS_INPUT';
export const UPDATE_PENDING_WORKSPACE_INVITES = 'UPDATE_PENDING_WORKSPACE_INVITES';
export const UPDATE_SELECTED_INVITES = 'UPDATE_SELECTED_INVITES';
export const UPDATE_VALID_EMAILS = 'UPDATE_VALID_EMAILS';
export const UPDATE_WORKSPACE_INVITE_ERROR = 'UPDATE_WORKSPACE_INVITE_ERROR';

export const fetchWorkspaceInvites = teamId => dispatch => {
  return ApiService.get(ApiConstants.fetchWorkspaceInvitesURL(teamId))
    .then(res => {
      dispatch(updateWorkspaceInvites(res));
    })
    .catch(error => {
      dispatch(updateWorkspaceInvites([]));
      ErrorService.capture('Error fetching workspace pending invites', error);
    });
};

export const resendInvitations = (inviteIds, teamId) => dispatch => {
  return ApiService.post(`/invites:resend`, { data: inviteIds })
    .then(() => {
      dispatch(resetSelectedPendingInvites());
      dispatch(fetchWorkspaceInvites(teamId));
      return { success: true };
    })
    .catch(error => {
      ErrorService.capture('Error resending workspace pending invites', error);
      return {};
    });
};

export const revokeInvitations = (inviteIds, teamId) => dispatch => {
  return ApiService.post(`/invites:revoke`, { data: inviteIds })
    .then(() => {
      dispatch(resetSelectedPendingInvites());
      dispatch(fetchWorkspaceInvites(teamId));
      return { success: true };
    })
    .catch(error => {
      ErrorService.capture('Error revoking workspace invites', error);
      return {};
    });
};

export const resetSelectedPendingInvites = () => ({
  type: RESET_SELECTED_PENDING_INVITES,
});

export const handleInviteSelection = inviteId => ({
  type: UPDATE_SELECTED_INVITES,
  inviteId,
});

export const selectAllPendingInvites = selectAll => ({
  type: SELECT_ALL_WORKSPACE_PENDING_INVITES,
  selectAll,
});

export const filterPendingInvites = value => ({
  type: FILTER_PENDING_INVITES,
  value,
});

export const updateWorkspaceInvites = pendingInvites => ({
  type: UPDATE_PENDING_WORKSPACE_INVITES,
  pendingInvites,
});

export const handleEmailsInput = value => dispatch => {
  dispatch(updateEmailsInput(value));
};

export const updateEmailsInput = value => ({
  type: UPDATE_INVITE_EMAILS_INPUT,
  value,
});

export const addEmails = value => (dispatch, getState) => {
  const { orgUserState } = getState();
  const { orgUsers = [] } = orgUserState;
  const invalidEmails = [];
  const validEmails = [];
  const formattedEmailsArr = getFormattedEmails(value);

  formattedEmailsArr.forEach(email => {
    if (validator.isEmail(email)) {
      const matchedUser = orgUsers.find(userObj => userObj.user.email === email) || {};

      validEmails.push(
        matchedUser.user ? { ...matchedUser.user, role: 'USER' } : { email, role: 'USER' }
      );
    } else {
      invalidEmails.push(email);
    }
  });

  dispatch(updateEmailsInput(invalidEmails.join(', ')));
  dispatch(updateInvalidEmails(invalidEmails));
  dispatch(updateValidEmails(validEmails));
};

export const getFormattedEmails = emailsInput => {
  const emailsArray = emailsInput.replace(/\s|\n/g, ',').split(',');
  return _.uniq(emailsArray).filter(email => email.length > 0);
};

export const updateValidEmails = newValidEmails => (dispatch, getState) => {
  const { workspaceInviteState } = getState();
  const { validEmails = [] } = workspaceInviteState;
  const dedupedValidEmails = _.uniqBy(validEmails.concat(newValidEmails), 'email');

  dispatch({
    type: UPDATE_VALID_EMAILS,
    validEmails: dedupedValidEmails,
  });
};

export const updateInvalidEmails = invalidEmails => ({
  type: UPDATE_INVALID_EMAILS,
  invalidEmails,
});

export const toggleWorkspaceAdmin = email => ({
  type: TOGGLE_WORKSPACE_ADMIN,
  email,
});

export const resetWorkspaceInviteState = () => ({
  type: RESET_WORKSPACE_INVITE_STATE,
});

export const sendInvites = (teamId, users = [], groups = []) => dispatch => {
  return ApiService.post(`/teams/${teamId}/update-members`, { data: { users, groups } })
    .then(() => {
      dispatch(fetchWorkspaceInvites(teamId));
      return { success: true };
    })
    .catch(error => {
      dispatch(
        updateWorkspaceInviteError(
          error.message ||
            'There has been an error sending your invites. Please check the information and then try again.'
        )
      );
      ErrorService.capture('Error sending team membership request', error);
      return {};
    });
};

export const removeUser = email => ({
  type: REMOVE_USER_FROM_INVITE_LIST,
  email,
});

export const showModal = modalType => ({
  type: TOGGLE_WORKSPACE_INVITE_MODAL,
  modalType,
});

export const updateWorkspaceInviteError = message => ({
  type: UPDATE_WORKSPACE_INVITE_ERROR,
  message,
});
