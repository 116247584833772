import React from 'react';
import CopyToClipboard from '~/containers/CopyToClipboard';

interface ScanTipsProps {
  scanTip?: string;
  updateScanTips: (...args: any[]) => any;
}

const ScanTips: React.FunctionComponent<ScanTipsProps> = props => {
  const { scanTip } = props;
  function updateScanTips(tip) {
    props.updateScanTips(tip);
  }
  return (
    <div>
      <div>
        <div onClick={() => updateScanTips('BASIC')} className="link--obvious font--h7">
          Trouble? Check out these tips.{' '}
          <i className={`sci sci--sm sci__caret--${scanTip === 'BASIC' ? 'down' : 'right'}`} />
        </div>
      </div>
      <div className={`col-3-4 ${scanTip === 'BASIC' ? 'is-showing-200' : 'is-hiding'}`}>
        <div className="mb- pl mt--">
          <p className="mb--">
            The most common reason that scans don't work is that the project cannot be built. Make
            sure you are able to build your project. There could be something missing in the build
            environment.
          </p>
          <p>
            Visit the <strong>Finding and Fixing Vulnerabilities</strong> section of{' '}
            <a
              target="_blank"
              href="https://help.veracode.com/go/c_sc_net"
              className="link--obvious"
            >
              the documentation
            </a>{' '}
            for more info.
          </p>
        </div>
      </div>
      <div onClick={() => updateScanTips('EXAMPLES')} className="mt-- font--h7 link--obvious">
        No project? Try one of our example projects.{' '}
        <i className={`sci sci--sm sci__caret--${scanTip === 'EXAMPLES' ? 'down' : 'right'}`} />
      </div>
      <div className={`col-1-1 ${scanTip === 'EXAMPLES' ? 'is-showing-400' : 'is-hiding'}`}>
        <p className="mt-- mb---">
          Clone any of our example projects from our{' '}
          <a
            target="_blank"
            href="https://github.com/veracode?q=example%20NOT%20test"
            className="link--obvious"
          >
            GitHub page
          </a>
          .
        </p>
        <p className="mt- mb---">
          For example, to scan our JavaScript example project enter these commands:
        </p>
        <div className="col-3-4">
          <CopyToClipboard
            value={`git clone https://github.com/veracode/example-javascript.git`}
            id="git-clone-js"
          />
        </div>
        <div className="col-3-4">
          <CopyToClipboard value={`cd example-javascript/`} id="cd-js" />
        </div>
        <div className="col-3-4">
          <CopyToClipboard value={`srcclr scan`} id="srcclr-scan-js" />
        </div>
        <p className="mt- mb---">Or if you prefer Java:</p>
        <div className="col-3-4">
          <CopyToClipboard
            value={`git clone https://github.com/veracode/example-java-maven.git`}
            id="git-clone-java"
          />
        </div>
        <div className="col-3-4">
          <CopyToClipboard value={`cd example-java-maven/`} id="cd-java" />
        </div>
        <div className="col-3-4">
          <CopyToClipboard value={`srcclr scan`} id="srcclr-scan-java" />
        </div>

        <p className="mt- mb---">Other example repositories:</p>
        <div className="grid col-3-4">
          <div className="grid__item col-3-10">
            <div>
              <a
                target="_blank"
                href="https://github.com/veracode/example-java-ant"
                className="link--obvious"
              >
                Java (Ant)
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://github.com/veracode/example-java-gradle"
                className="link--obvious"
              >
                Java (Gradle)
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://github.com/veracode/example-ruby"
                className="link--obvious"
              >
                Ruby
              </a>
            </div>
          </div>
          <div className="grid__item col-3-10">
            <div>
              <a
                target="_blank"
                href="https://github.com/veracode/example-javascript-bower"
                className="link--obvious"
              >
                JavaScript (Bower)
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://github.com/veracode/example-javascript-yarn"
                className="link--obvious"
              >
                JavaScript (Yarn)
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://github.com/veracode/example-python"
                className="link--obvious"
              >
                Python
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://github.com/veracode/example-go-glide"
                className="link--obvious"
              >
                Go Glide
              </a>
            </div>
          </div>
          <div className="grid__item col-2-5">
            <div>
              <a
                target="_blank"
                href="https://github.com/veracode/example-objc-cocoapods"
                className="link--obvious"
              >
                Objective-C (Cocoapods)
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://github.com/veracode/example-swift-cocoapods"
                className="link--obvious"
              >
                Swift
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://github.com/veracode/example-cpp-makefile"
                className="link--obvious"
              >
                C/C++
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanTips;
