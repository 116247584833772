import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';
import _ from 'lodash';

import * as twoFactorActions from '~/actions/twofactor';
import * as MODEL from '~/constants/ModelConstants';

interface TwoFactorAuthProps {
  twoFactorActions: object;
  myState: object;
  twoFactorState: object;
}
class TwoFactorAuth extends React.Component<TwoFactorAuthProps, {}> {
  constructor(props) {
    super(props);
    this.phoneCountryRef = React.createRef();
    this.phoneNumberRef = React.createRef();
    this.verifyCodeRef = React.createRef();
  }
  componentDidMount() {
    this.fetchTwoFactorStatus();
  }
  phoneCountryRef: React.RefObject<HTMLSelectElement>;
  phoneNumberRef: React.RefObject<HTMLInputElement>;
  verifyCodeRef: React.RefObject<HTMLInputElement>;

  fetchTwoFactorStatus() {
    this.props.twoFactorActions.fetchTwoFactorStatus();
  }

  handleDisableTwoFactor() {
    this.props.twoFactorActions.twoFactorDelete();
  }

  enableTwoFactor(e) {
    e.preventDefault();

    const phoneCode = _.find(
      MODEL.countryCodes,
      ele => ele.country === this.phoneCountryRef.current.value
    ).code;
    const phoneNumber = this.phoneNumberRef.current.value;

    this.props.twoFactorActions.twoFactorEnroll(phoneCode, phoneNumber);
  }

  sendSMSCode() {
    this.props.twoFactorActions.sendTwoFactorSMS();
  }

  changePhoneDropdownVal() {
    const phoneVal = this.phoneCountryRef.current.value;
    this.props.twoFactorActions.updatePhoneDropdownVal(phoneVal);
  }

  verifyCode(e) {
    e.preventDefault();
    const code = this.verifyCodeRef.current.value;

    this.props.twoFactorActions.verifyTwoFactor(code);
  }

  createPhoneOptions() {
    const sortedCountryCode = MODEL.countryCodes.sort((a, b) => {
      const textA = a.country.toUpperCase();
      const textB = b.country.toUpperCase();

      if (textA < textB) {
        return -1;
      } else if (textA > textB) {
        return 1;
      }

      return 0;
    });

    const groupOptions = sortedCountryCode.map((number, index) => {
      return (
        <option key={index} value={number.country}>
          {number.country}
        </option>
      );
    });
    return groupOptions;
  }

  render() {
    const { myState, twoFactorState } = this.props;
    const { me = {} } = myState;
    const {
      twoFactorStatus,
      enablingTwoFactor,
      phoneDropdownVal,
      sendingCode,
      verifyingCode,
    } = twoFactorState;
    const { twoFactorAuthEnabled } = me;
    const { status = '', countryCode = '', phone = '' } = twoFactorStatus;

    const phoneOptions = this.createPhoneOptions();
    let phoneDisplay = '';

    if (phone && phone.length === 4) {
      phoneDisplay = `${countryCode}-XXX-XXX-${phone}`;
    } else {
      phoneDisplay = `${countryCode}-${phone}`;
    }

    const helmetContent = (
      <Helmet>
        <title>Two-factor Settings</title>
      </Helmet>
    );

    if (twoFactorAuthEnabled && status !== 'NOT_VALIDATED') {
      return (
        <div className="grid">
          {helmetContent}
          <div className="grid__item col-1-2">
            <div className="pt font--h7">Two-factor authentication</div>
            <div className="grid pt">
              <div className="grid__item col-1-2 font--h7">Enabled ({phoneDisplay})</div>

              <div className="grid__item font--h7 text--right">
                <i
                  onClick={() => this.handleDisableTwoFactor()}
                  className="fas fa-toggle-on sci--md cursor--pointer"
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="grid">
          {helmetContent}
          <div className="grid__item col-1-2">
            <div className="pt font--h7">Two-factor authentication</div>
            <div className="grid pt">
              <div className="grid__item col-1-2 font--h7 color--muted">Disabled</div>

              <div className="grid__item font--h7 text--right color--muted">
                <i className="fas fa-toggle-off sci--md cursor--not-allowed" />
              </div>
            </div>

            <div className="pv">
              <select
                ref={this.phoneCountryRef}
                className="control--select"
                defaultValue={phoneDropdownVal}
                onChange={() => this.changePhoneDropdownVal()}
              >
                {phoneOptions}
              </select>
            </div>

            {!status || status === 'DISABLED' ? (
              <form name="twoFactorEnrollForm" onSubmit={e => this.enableTwoFactor(e)}>
                <div>
                  <div className="grid grid--middle">
                    <div className="grid__item col-2-3">
                      <input
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        required
                        className="col-1-1"
                        autoComplete="off"
                        ref={this.phoneNumberRef}
                      />
                    </div>

                    <div className="grid__item">
                      <button disabled={enablingTwoFactor} className="btn btn--primary ph">
                        {enablingTwoFactor ? 'Enabling...' : 'Enable'}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div>
                <div className="pv grid grid--middle">
                  <div className="grid__item col-2-3">{phoneDisplay}</div>

                  <div className="grid__item col-1-3">
                    <button
                      disabled={sendingCode}
                      className="btn btn--primary ph"
                      onClick={() => this.sendSMSCode()}
                    >
                      {sendingCode ? 'Sending...' : 'Send code'}
                    </button>
                  </div>
                </div>

                <form name="verifyCodeForm" onSubmit={e => this.verifyCode(e)}>
                  <div className="grid grid--middle">
                    <div className="grid__item col-2-3">
                      <input
                        type="text"
                        name="verifyCode"
                        placeholder="'Send Code' or via Authy app"
                        required
                        className="col-1-1"
                        autoComplete="off"
                        ref={this.verifyCodeRef}
                      />

                      {status === 'INVALID_TOKEN' && (
                        <div className={'color--danger '}>Invalid code</div>
                      )}
                    </div>

                    <div className="grid__item col-1-3">
                      <button disabled={verifyingCode} className="btn btn--primary ph">
                        {verifyingCode ? 'Verifying...' : 'Verify'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    myState: state.myState,
    twoFactorState: state.twoFactorState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    twoFactorActions: bindActionCreators(twoFactorActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuth);
