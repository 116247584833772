import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';

interface PackageManagerShrinkwrapProps {
  component: object;
  range?: object;
  safeVersion: string;
  transitive: boolean;
  unsafeVersion?: string;
  packageFile?: string;
  lineNumber?: string;
}

const PackageManagerShrinkwrap: React.FunctionComponent<PackageManagerShrinkwrapProps> = props => {
  const { component, range = {}, safeVersion, transitive = false, packageFile, lineNumber } = props;
  const { name } = component;
  const unsafeVersion =
    props.unsafeVersion ||
    (range.versionRange && range.versionRange.indexOf('-') !== -1
      ? range.versionRange.split('-')[0]
      : '');

  if (!transitive) {
    return (
      <div className="grid grid--full mt-">
        <div className="grid__item col-1-1 -ml">We found this dependency here:</div>
        <div className="grid__item grid bo--1 border-color--white-dark mt-- bg-color--white lh+">
          <CodeDiffHeader text={packageFile} />
          <CodeDiffLine type="normal" text='"dependencies": {' />
          <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
          <CodeDiffLine
            type="normal"
            lineNumber={lineNumber}
            text={'\u2003\u0022' + name + '\u0022: {'}
          />
          <CodeDiffLine
            type="normal"
            text={'\u2003\u2003\u2003\u0022version\u0022: \u0022' + unsafeVersion + '\u0022,'}
          />
          <CodeDiffLine type="normal" text={'\u2003\u2003\u2003\u00B7\u00B7\u00B7'} />
          <CodeDiffLine type="normal" text={'\u2003}'} />
          <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
          <CodeDiffLine type="normal" text={`}`} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="grid bo--1 border-color--white-dark mt bg-color--white lh+">
        <CodeDiffHeader text="package.json" />
        <CodeDiffLine type="add" text='"dependencies": {' />
        <CodeDiffLine type="add" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine
          type="add"
          text={'\u2003\u0022' + name + '\u0022: \u0022' + safeVersion + '\u0022,'}
        />
        <CodeDiffLine type="add" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine type="add" text={`}`} />
      </div>
    );
  }
};

export default PackageManagerShrinkwrap;
