import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import _ from 'lodash';

import Helpers from '~/utils/Helpers';

import * as invoiceActions from '~/actions/invoice';
import { RouteComponentProps } from 'react-router-dom';

interface InvoiceDetailsPageProps extends RouteComponentProps {
  invoiceActions: object;
  invoicesById: object;
  orgState: App.OrgState;
}

class InvoiceDetailsPage extends React.Component<InvoiceDetailsPageProps, {}> {
  componentDidMount() {
    const { match, orgState, history } = this.props;
    const { params = {} } = match;
    const { org } = orgState;
    const { permissions = {} } = org;
    const { billing } = permissions;
    const { invoiceId } = params;

    if (!billing) {
      history.replace('/org/settings/no-owner');
    }

    this.props.invoiceActions.fetchInvoice(org.id, invoiceId);
  }

  render() {
    const { invoicesById, match } = this.props;
    const { params = {} } = match;
    const { invoiceId } = params;
    const invoice = invoicesById[invoiceId] || {};
    const {
      receipt_number,
      date,
      paymentCard = {},
      subtotalInCents,
      totalInCents,
      status,
      items = [],
    } = invoice;
    const discount = invoice.discount || {}; // could be null, so deconstruction won't work
    const { cardType = '', lastFour } = paymentCard;
    const { coupon = {} } = discount;
    const { id: couponId, amount_off, percent_off } = coupon;

    return (
      <div className="grid col-1-1 pb++">
        <div className="grid__item col-1-1 mt">
          <div className="font--h4 text--center">Payment Details</div>
        </div>
        <div className="grid__item col-1-2 mt">
          <div className="p bg-color--white-dark color--muted-dark">
            {receipt_number && (
              <div className="mb--">
                <strong>Receipt Number</strong>: {receipt_number}
              </div>
            )}
            <div className="">
              <strong>Date</strong>: {moment(date).format('M/DD/YYYY')}
            </div>
            <div className="mt--">
              <strong>Payment Method</strong>: {cardType.toUpperCase()} **** {lastFour}
            </div>
          </div>
          <div className="mt+">
            <div className="bo-b--1 border-color--muted-light pb---">
              <div className="font--h5">Charges</div>
            </div>
          </div>
          <div className="mt">
            <div className="bo-b--1 border-color--muted-light pb">
              {items.map((item = {}, index) => {
                const { description, amountInCents } = item;

                return (
                  <div className="flex justify-content--space-between pv---" key={`item-${index}`}>
                    <div>{description}</div>
                    <div>{Helpers.formatCost(amountInCents)}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt flex flex--justify-content--end">
            <div className="col-2-3">
              <div className="flex justify-content--space-between pv---">
                <div>
                  <strong>Subtotal</strong>
                </div>
                <div>{Helpers.formatCost(subtotalInCents)}</div>
              </div>
              {!_.isEmpty(discount) && (
                <div>
                  <div className="flex justify-content--space-between pv---">
                    <div>
                      <strong>Coupons</strong>
                    </div>
                    <div>-{Helpers.formatCost(amount_off)}</div>
                  </div>
                  <div className="flex flex--justify-content--end pv---">
                    <div className="color--muted">
                      {couponId}({percent_off}% off)
                    </div>
                  </div>
                </div>
              )}
              <div className="flex justify-content--space-between pb- pt--- bo-b--1 border-color--muted-light mt-">
                <div>
                  <strong>Total</strong>
                </div>
                <div>{Helpers.formatCost(totalInCents)}</div>
              </div>
              <div className="flex justify-content--space-between mt-">
                <div>
                  <strong>Paid</strong>
                </div>
                <div>
                  {status === 'PAID' ? Helpers.formatCost(totalInCents) : Helpers.formatCost(0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    invoicesById: state.invoicesById,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    invoiceActions: bindActionCreators(invoiceActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetailsPage);
