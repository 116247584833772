import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import containerIcon from '~/images/icon_container_40x40.svg';
import databaseIcon from '~/images/icon_database_40x40.svg';
import ReportPagination from '~/components/ReportComponents/ReportPagination';
import Tooltip from '~/components/Tooltip';

import {
  languagePrettifiedNames,
  PROJECT_TYPES,
  PROJECT_TYPES_CLEAN_NAMES,
} from '~/constants/ModelConstants';
import Helpers from '~/utils/Helpers';

interface VulnReposQuickViewProps {
  params: object;
  data: object;
  onPageClick: (...args: any[]) => any;
  quickviewId: string;
  quickviewLink: string;
}

const VulnReposQuickView: FunctionComponent<VulnReposQuickViewProps> = props => {
  const { data, params } = props;
  const { _embedded = {}, page = {} } = data;
  const { repos = [] } = _embedded;
  const { teamId } = params;

  const reposList = repos.map(repo => {
    const { language, id: repoId, name, lastScanDate, type: projectType = '' } = repo;
    const prettifiedLanguageName = languagePrettifiedNames[language];
    const projectIcon =
      projectType.toUpperCase() === PROJECT_TYPES.CONTAINER ? containerIcon : databaseIcon;
    const projectAltText =
      projectType.toUpperCase() === PROJECT_TYPES.CONTAINER ? 'Container' : 'Repo';
    return (
      <div className="grid pv-- flex align-items--center" key={repoId}>
        <div className="grid__item col-1-2">
          <Link to={`/workspaces/${teamId}/projects/${repoId}/issues`} className="link--obvious">
            {name}
          </Link>
        </div>

        <div className={'grid__item color--muted-dark text--overflow'}>
          {(prettifiedLanguageName && (
            <Tooltip
              id={`${repoId}-repoRow`}
              content={PROJECT_TYPES_CLEAN_NAMES[projectType.toUpperCase()]}
              maxWidthClass="max-width--300"
              place="left"
            >
              <div className="flex align-items--center">
                <div className="width--20 text--center flex align-items--center">
                  <img src={projectIcon} alt={projectAltText} className={`width--16 mr--`} />
                </div>
                {prettifiedLanguageName}
              </div>
            </Tooltip>
          )) ||
            '--'}
        </div>

        <div className="grid__item">{Helpers.formatDate(lastScanDate)}</div>
      </div>
    );
  });

  return (
    <div className="bo-t--1 border-color--muted-light pt pb-">
      <div className="grid pb-">
        <div className="grid__item col-1-2">
          <strong>Project Name</strong>
        </div>
        <div className="grid__item">
          <strong>Language/OS</strong>
        </div>
        <div className="grid__item">
          <strong>Last Scan</strong>
        </div>
      </div>
      {reposList}

      <ReportPagination
        page={page}
        onPageClick={props.onPageClick}
        quickviewId={props.quickviewId}
        quickviewLink={props.quickviewLink}
      />
    </div>
  );
};

export default VulnReposQuickView;
