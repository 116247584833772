import React from 'react';
import classnames from 'classnames';
import enterprisePlanTypeLogo from '~/images/plan-type--enterprise-nologo.svg';

interface BlockingPageProps {
  hasSidebar?: boolean;
  isTrialExpired?: boolean;
  renderPreheadline: () => {};
  renderEnterpriseDetails: () => {};
}

const BlockingPage = (props: BlockingPageProps) => {
  const {
    isTrialExpired,
    hasSidebar = false,
    renderEnterpriseDetails = () => {
      // default with expired trial wording
      return 'Scale your program with Enterprise features like Advanced User Management, Custom Rules, new vulnerability notifications, and additional support. ';
    },
    renderPreheadline = () => {
      // default with expired trial wording
      return 'Your trial has ended';
    },
  } = props;

  const blockingPageContainerClasses = classnames({
    'blocking-page-with-sidebar': hasSidebar,
  });

  const enterprisePlanContainerClasses = classnames('flex', {
    container: !hasSidebar,
  });

  const enterprisePlanDescriptioncContainerClasses = classnames({
    'enterprise-plan-description-no-sidebar': !hasSidebar,
  });

  const enterpriseImageClasses = classnames('enterprise-plan-type-image', {
    'enterprise-image-margin-no-sidebar': !hasSidebar,
  });

  const enterprisePlanDescriptionTextClasses = classnames({
    'enterprise-plan-description-text-margin-with-sidebar': hasSidebar,
    'enterprise-plan-description-text-margin-no-sidebar': !hasSidebar,
  });
  const enterprisePlanDescriptionHeadlineClasses = classnames({
    'enterprise-plan-description-headline-with-sidebar': hasSidebar,
    'enterprise-plan-description-headline-no-sidebar': !hasSidebar,
  });
  const enterprisePlanDescriptionDetailsClasses = classnames({
    'enterprise-plan-description-details-with-sidebar': hasSidebar,
    'enterprise-plan-description-details-no-sidebar ': !hasSidebar,
  });

  const offeringsContainerNoSidebarClasses = classnames({
    'container blocking-page-no-sidebar': !hasSidebar,
  });

  const offeringsContentWrapperClasses = classnames({
    'offerings-content-wrapper-with-sidebar': hasSidebar,
    'offerings-content-wrapper-no-sidebar': !hasSidebar,
  });

  const offeringsContentTitleClasses = classnames({
    'font--h5': hasSidebar,
    'font--h4': !hasSidebar,
  });

  const offeringsContentDetailClasses = classnames('force-font--default', {
    'font--13': hasSidebar,
    'font--16': !hasSidebar,
  });

  const enterpriseOfferings = [
    {
      icon: 'sci sci__shield--urgent offerings-shield-icon',
      title: 'Vulnerable Methods',
      detail:
        'Find out which line of code is calling a vulnerable method from a vulnerable library.',
    },
    {
      icon: 'sci sci__crown offerings-crown-icon',
      title: 'Premium Security Data',
      detail:
        'Access unique, non-public vulnerability disclosures generated by our security research team.',
    },
    {
      icon: 'sci sci__language offerings-language-icon',
      title: 'Dev Tool Integration',
      detail: 'Create Jira or GitHub issues to collaborate with developers.',
    },
    {
      icon: 'sci sci__data--download color--success-dark',
      title: 'Data Exports',
      detail: 'Share data with your team and perform your own analysis.',
    },
  ];

  return (
    <div className={`${blockingPageContainerClasses}`}>
      <div className="container--full enterprise-plan-container">
        <div className={enterprisePlanContainerClasses}>
          <div
            className={`${enterprisePlanDescriptioncContainerClasses} enterprise-plan-description-container`}
          >
            <div
              className={`${enterprisePlanDescriptionTextClasses} font--h3 font-family--roboto-light`}
            >
              {renderPreheadline()}
            </div>
            <div
              className={`${enterprisePlanDescriptionHeadlineClasses} font--h3 roboto-bold line-height--36`}
            >
              {isTrialExpired
                ? 'Keep your open source software secure with an Enterprise plan'
                : 'Upgrade to Enterprise to make your open source software more secure'}
            </div>
            <div
              className={`${enterprisePlanDescriptionTextClasses} font--h4 font-family--roboto-light`}
            >
              More scans and workspaces. Unlimited projects and users.
            </div>

            <div
              className={`${enterprisePlanDescriptionDetailsClasses} font--h6 font-family--roboto-light`}
            >
              {renderEnterpriseDetails()}
            </div>
            <button
              className="font--h6 btn--success bo-rad--0 roboto-bold enterprise-contact-sales-button"
              onClick={() => window.open('mailto:sales@veracode.com')}
            >
              CONTACT SALES
            </button>
          </div>
          <img
            className={`${enterpriseImageClasses} enterprise-plan-type-image`}
            src={enterprisePlanTypeLogo}
            alt=""
          />
        </div>
      </div>
      <div className={`${offeringsContainerNoSidebarClasses}`}>
        <div className="font--h3 roboto-bold enterprise-bottom-title">
          Enterprise also includes...
        </div>

        <div className="enterprise-offerings-container">
          {enterpriseOfferings.map((offering, index) => {
            return (
              <div key={index} className="enterprise-offerings-item">
                <i className={offering.icon} />
                <div className={`${offeringsContentWrapperClasses}`}>
                  <div
                    className={`${offeringsContentTitleClasses} enterprise-offerings-item-title`}
                  >
                    {offering.title}
                  </div>
                  <div className={`${offeringsContentDetailClasses}`}>{offering.detail}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlockingPage;
