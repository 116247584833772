import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Helmet from 'react-helmet';
import _ from 'lodash';

import ReportDetailsPageTabItem from '~/components/ReportComponents/ReportDetailsPageTabItem';
import ReportDetailsPageTabWrapper from '~/containers/ReportDetailsPageTabWrapper';
import ReportDetailsPageTabContent from '~/components/ReportComponents/ReportDetailsPageTabContent';
import ReportDetailsPageUpdateAdvisorAlert from '~/components/ReportComponents/ReportDetailsPageUpdateAdvisorAlert';
import ReportDetailsPageUpdateAdvisorWrapper from '~/containers/ReportDetailsPageUpdateAdvisorWrapper';
import ReportDetailsPageTabSectionWrapper from '~/containers/ReportDetailsPageTabSectionWrapper';
import ReportDetailsPageFixContent from '~/components/ReportComponents/ReportDetailsPageFixContent';
import IssueProjectDetails from '~/components/ReportComponents/IssueProjectDetails';
import IssueHistory from '~/containers/IssueHistory';
import IssueSeverityFlag, {
  IssueSeverityFlagDisplayStyle,
} from '~/components/ReportComponents/IssueSeverityFlag';
import SeverityFlag from '~/components/ReportComponents/SeverityFlag';
import SidebarWrapper from '~/containers/SidebarWrapper';
import ReportDetailsPageVulnMethodView from '~/components/ReportComponents/ReportDetailsPageVulnMethodView';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import LoaderWrapper from '~/components/LoaderWrapper';
import IssueDetailCreateIssueBtn from '~/containers/IssueDetailCreateIssueBtn';
import ProIcon from '~/components/ProIcon';

import Helpers from '~/utils/Helpers';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import ArtifactHelper from '~/utils/ArtifactHelper';
import SunburstHelper from '~/utils/SunburstHelper';
import IssueHelper from '~/utils/IssueHelper';
import VulnMethodSupportStatus from '~/utils/VulnMethodSupportStatus';
import JiraHelper from '~/utils/JiraHelper';
import Spinner from '~/components/Spinner';
import RegistryLink from '~/components/RegistryLink';
import IssueDetailComment from '~/components/IssueDetailComment';

import * as reportDetailsPageActions from '~/actions/reportDetailsPage';
import * as reportIssuesActions from '~/actions/reportIssues';
import * as upgradeModalActions from '~/actions/upgradeModal';
import * as teamActions from '~/actions/team';
import * as sidebarActions from '~/actions/sidebar';
import * as modalActions from '~/actions/modal';

import SunburstChartHeader from '~/containers/SunburstChartHeader';
import SunburstChart from '~/containers/SunburstChart';
import CommentIssueModal from '~/containers/CommentIssueModal';

import * as MODEL from '~/constants/ModelConstants';
import {
  DetailLinks,
  DetailLink,
  IssuesDetailsPageProps,
} from '~/containers/IssuesDetailsPage.types';
import IgnoreIssueModal from '~/containers/IgnoreIssueModal';
const REPORT_TYPE = 'ISSUE_VULNERABILITIES';
const SIDEBAR_ID = 'ISSUE_HISTORY';

class IssuesVulnerabilitiesDetailsPage extends Component<
  IssuesDetailsPageProps & ReturnType<typeof mapDispatchToProps>
> {
  constructor(props) {
    super(props);

    (this as any).ignoreIssueCheckboxRef = React.createRef();
    this.handleIgnoreIssue = this.handleIgnoreIssue.bind(this);
  }

  async componentDidMount() {
    const { match } = this.props;
    const { params = {} } = match;
    const { issueId, teamId } = params;

    this.props.teamActions.fetchIssueData(teamId);
    this.props.reportDetailsPageActions.resetReport(REPORT_TYPE);
    // TODO: Centralize logic/markup shared between Issues[Vulnerabilities|Licenses|Libraries]DetailsPage components in a common component
    const {
      lastScan: { id: scanId },
    } = await this.props.reportDetailsPageActions.fetchDetailsPage(REPORT_TYPE, issueId);
    this.props.reportIssuesActions.getIntegrationIssuesCreated(issueId, scanId);
  }

  componentDidUpdate() {
    const { orgState, myState, reportDetailsPageState, history } = this.props;
    const { isFetchingMe = false } = myState;
    const { [REPORT_TYPE]: detailsState } = reportDetailsPageState;
    const { details, isFetching } = detailsState;
    const { content = {} } = details;
    const { issue = {} } = content;
    const { cveStatus } = issue;
    const { org } = orgState;
    const isPaidOrTrialing = OrgPaidStatus.isOrgPaidOrTrial(org);
    const showPremiumVulnPaywall =
      ArtifactHelper.isArtifactPremiumByStatus(cveStatus) && !isPaidOrTrialing;

    if (!isFetching && !isFetchingMe && showPremiumVulnPaywall) {
      history.replace(`/no-access-premium`);
    }
  }

  fetchIssueEventsAndDetails = () => {
    const { match } = this.props;
    const { params = {} } = match;
    const { issueId } = params;
    this.props.reportDetailsPageActions.fetchIssuesEvents(REPORT_TYPE, issueId);
    this.props.reportDetailsPageActions.fetchIssuesDetails(REPORT_TYPE, issueId);
  };

  updateAdvisorComponentDidMount = link => {
    if (link) {
      this.props.reportDetailsPageActions.fetchUpdateAdvisorData(REPORT_TYPE, link);
    }
  };

  toggleFullCallChain = chain => {
    this.props.reportDetailsPageActions.updateReportExpandedVulnMethodChain(REPORT_TYPE, chain);
  };

  getLinks = (_links = {} as DetailLinks) => {
    const { graph = {} as DetailLink, updates = {} as DetailLink } = _links;
    return {
      graphLink: graph.href,
      updatesLink: updates.href,
    };
  };

  handleTabClick = (field, link) => {
    if (field === 'dependencyGraph') {
      //reset the dependency path
      this.props.reportDetailsPageActions.updateActiveTabStateByReportField(
        REPORT_TYPE,
        'dependenciesSunburstSelectedPath',
        undefined
      );
    }
    this.props.reportDetailsPageActions.loadDetailPageActiveTab(REPORT_TYPE, field, link);
    this.props.reportDetailsPageActions.updateDetailsPageActiveTab(REPORT_TYPE, field);
  };

  getCurrentIssue = () => {
    const { reportDetailsPageState } = this.props;
    const { [REPORT_TYPE]: detailsState } = reportDetailsPageState;
    const { details } = detailsState;
    const { content = {} } = details;
    const { issue } = content;

    return issue;
  };

  ignoreIssue = (comment: string, suppressUntil: Date) => {
    const issue = this.getCurrentIssue();
    const suppress = (this as any).ignoreIssueCheckboxRef.checked;
    this.props.reportIssuesActions
      .maybeSuppressIssue(issue, REPORT_TYPE, suppress, comment, suppressUntil)
      .then(() => {
        this.fetchIssueEventsAndDetails();
        this.props.modalActions.closeModal(MODEL.IGNORE_COMMENT_MODAL);
      });
  };

  getCoordAndVersionString = component => {
    return `${component.coord1}${component.coord2 ? ` ${component.coord2}` : ``} ${
      component.libraryVersion
    }`;
  };

  onPathSelected = path => {
    this.props.reportDetailsPageActions.updateActiveTabStateByReportField(
      REPORT_TYPE,
      'dependenciesSunburstSelectedPath',
      path
    );
  };

  toggleCtaCreateIssueModalOpen = () => {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    this.props.upgradeModalActions.showUpgradeModal(MODEL.UPGRADE_MODAL_CREATE_ISSUE, teamId);
    this.props.reportIssuesActions.createIssueButtonClickedSnowplow(teamId, MODEL.NON_PAID_TEAM);
  };

  toggleCtaVulnModalOpen = () => {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    this.props.upgradeModalActions.showUpgradeModal(MODEL.UPGRADE_MODAL_VULN_METHODS, teamId);
  };

  toggleCtaPremiumVulnModalOpen = () => {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    this.props.upgradeModalActions.showUpgradeModal(MODEL.UPGRADE_MODAL_PREMIUM_VULN, teamId);
  };

  toggleSidebar = () => {
    const { sidebarState = {} } = this.props;
    const isSidebarOpen = sidebarState[SIDEBAR_ID];

    if (isSidebarOpen) {
      this.props.sidebarActions.closeSidebar(SIDEBAR_ID);
    } else {
      this.props.sidebarActions.openSidebar(SIDEBAR_ID);
    }
  };

  isIssueCommentEnabled = () => {
    const { match, teamState } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    const { teams = [] } = teamState;
    const activeTeam = Helpers.getActiveTeamById({ teams, teamId });
    return activeTeam?.permissions?.issueComments;
  };

  isIgnoreIssueChangesEnabled = () => {
    const { match, teamState } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    const { teams = [] } = teamState;
    const activeTeam = Helpers.getActiveTeamById({ teams, teamId });
    return activeTeam?.permissions?.ignoreIssueChanges;
  };

  handleIgnoreIssueCancel = () => {
    const issue = this.getCurrentIssue();
    const { suppressed } = issue;

    (this as any).ignoreIssueCheckboxRef.checked = suppressed;
  };

  handleIgnoreIssue = () => {
    this.props.modalActions.openModal(MODEL.IGNORE_ISSUE_MODAL);
  };

  commentIssue = (comment: string) => {
    const issue = this.getCurrentIssue();
    this.props.modalActions.closeModal(MODEL.IGNORE_ISSUE_MODAL);
    this.props.reportIssuesActions.commentIssue(issue, comment).then(() => {
      this.fetchIssueEventsAndDetails();
    });
  };

  render() {
    const {
      reportDetailsPageState,
      match,
      reportIssuesState,
      orgState,
      myState,
      teamState,
      sidebarState,
    } = this.props;
    const isSidebarOpen = sidebarState[SIDEBAR_ID];

    const { params = {} } = match;
    const { teamId } = params;
    const { org } = orgState;
    const { isFetchingMe = false } = myState;
    const { [REPORT_TYPE]: detailsState } = reportDetailsPageState;
    const {
      details,
      tabSelected,
      isFetching,
      expandedVulnerableMethodChain,
      updateAdvisorData,
      isActiveTabLoading,
      isFailure,
    } = detailsState;
    const { content = {}, _links: vulDetailLink = {} } = details;
    const { issue = {}, artifact = {}, fixInfo = {}, vulnMethods } = content;

    const { graphLink = '', updatesLink = '' } = this.getLinks(vulDetailLink);
    const { cveStatus } = artifact;
    const {
      id,
      repoName,
      projectType = '',
      libraryName,
      coord1,
      coord2,
      coordinateType,
      libraryVersion,
      dependencyMode = '',
      libraryReleaseDate,
      latestVersion,
      latestReleaseDate,
      cve,
      title,
      severity = '',
      issueSeverity = 0,
      _links,
      suppressed,
      status,
      commitHash,
      hasComments,
    } = issue;
    const scanData = IssueHelper.getScanData(issue);
    const { scanId, fixedScanId, fixedScanDate } = scanData;
    const { overview } = artifact;
    const {
      isSuppressingIssue,
      isSuppressingIssueSuccess,
      isSuppressingIssueFailure,
      integrations,
    } = reportIssuesState;

    const { teams = [] } = teamState;
    const activeTeam = Helpers.getActiveTeamById({ teams, teamId });

    const isPoliciesEnabled =
      FeatureFlagHelper.isFeatureEnabledForOrgAndTeam(
        MODEL.FEATURE_SLUG_MAP.POLICIES,
        org,
        activeTeam
      ) || OrgPaidStatus.isOrgEnterpriseOrTrial(org);

    const sidebarTitleHtml = (
      <div className="flex align-items--center font--h6 text--bold">
        <i className="fas fa-clock mr- font--h4 color--primary" />
        Issue Event History
      </div>
    );

    // Issue severity
    const issueSeverityVal = issueSeverity || 0; //handling null case

    // Linked issue related
    const { [issue.id]: issueIntegrations = {} } = integrations;
    const { data: linkedTickets = [] } = issueIntegrations;
    const linkedJiraTicket = linkedTickets.filter(ticket => ticket.issueType === 'JIRA');
    const jiraHref =
      linkedJiraTicket.length === 0
        ? null
        : JiraHelper.getJiraLink(
            linkedJiraTicket[0].integrationServiceUrl,
            linkedJiraTicket[0].jiraKey
          );

    //dependency graph related
    const formattedComponent = this.getCoordAndVersionString(issue);
    const { tabData = {}, tabState = {} } = detailsState;
    const { dependencyGraph = {} } = tabData;

    const { sunburstPaths: sunburst = [] } = dependencyGraph.graphs ? dependencyGraph.graphs : [];
    const defaultDependencyPath = SunburstHelper.getDefaultDependencypath(
      sunburst,
      formattedComponent
    );
    const colorIfPathContains = _.last(defaultDependencyPath);
    const { dependenciesSunburstSelectedPath = defaultDependencyPath || [] } = tabState;
    const showColorForHeader = SunburstHelper.isComponentInSelectedPath(
      sunburst,
      colorIfPathContains,
      dependenciesSunburstSelectedPath
    );
    // end dependency graph

    const formattedLibraryReleaseDate = Helpers.formatDate(libraryReleaseDate);
    const formattedLatestReleaseDate = Helpers.formatDate(latestReleaseDate);

    const registryVulnHref = _links && _links.vulnerability && _links.vulnerability.href;
    const registryLibHref = (_links && _links.library && _links.library.href) || '';

    const formattedStatus = status === 'FIXED' ? 'Resolved' : Helpers.capFirst(status);

    const repoNameText = Helpers.formatProjectName(repoName);

    const libraryNameText = libraryName ? libraryName : 'Name not available';
    const titleText = title ? title : 'Title not available';
    const dependencyModeText =
      dependencyMode.toUpperCase() === 'BOTH' ? 'Direct & Transitive' : dependencyMode;
    const overviewText = overview ? overview : 'Overview not available';

    let coord1Text = coord1;
    let coordContent = '';

    if (coordinateType === 'GEM') {
      coord1Text = coord1Text && `gem '${coord1Text}'`;
    }

    if (coord1Text) {
      coordContent = `${coord1Text}${coord2 ? ' ' + coord2 : ''}`.trim();
    }

    const libraryNameHighlightHtml = (
      <RegistryLink
        href={registryLibHref.split('?')[0]}
        className="link--obvious"
        dangerouslySetInnerHTML={Helpers.highlightItems(libraryNameText, [])}
      />
    );
    /* eslint-disable react/no-danger */

    const titleHighlightHtml = (
      <span dangerouslySetInnerHTML={Helpers.highlightItems(titleText, [], 100)} />
    );

    const overviewHighlightHtml = (
      <p dangerouslySetInnerHTML={Helpers.highlightItems(overviewText, [])} />
    );
    /* eslint-disable react/no-danger */

    let dangerousLibraryVersionUrl = Helpers.formatBreakOnDot(libraryVersion);
    if (commitHash && libraryVersion === 'HEAD') {
      dangerousLibraryVersionUrl.__html = `${
        dangerousLibraryVersionUrl.__html
      } (${Helpers.shortedCommitHash(commitHash)})`;
    }
    const versionInUseHtml =
      commitHash && libraryVersion === 'HEAD' ? (
        <RegistryLink
          className="link--obvious"
          href={`${registryLibHref.split('?version=HEAD')[0]}?commit_hash=${commitHash}`}
          dangerouslySetInnerHTML={dangerousLibraryVersionUrl}
        />
      ) : (
        <RegistryLink
          className="link--obvious"
          href={`${registryLibHref.split('=')[0]}=${libraryVersion}`}
          dangerouslySetInnerHTML={dangerousLibraryVersionUrl}
        />
      );

    const latestVersionHtml = (
      <RegistryLink
        className="link--obvious"
        href={`${registryLibHref.split('=')[0]}=${latestVersion}`}
        dangerouslySetInnerHTML={Helpers.formatBreakOnDot(latestVersion)}
      />
    );
    /* eslint-disable react/no-danger */

    //CVE Status and Text
    let cveText = cve;

    if (!cveText) {
      cveText = 'Premium Data';
    } else if (cveText.indexOf('CVE') === -1) {
      cveText = `CVE-${cveText}`;
    }

    let dataSource: string;

    if (cveStatus === 'FINAL') {
      dataSource = 'Public Disclosure';
    } else {
      dataSource = 'Veracode Customer Access';
    }

    //Check org paid/free status
    const isPaidOrTrialing = OrgPaidStatus.isOrgPaidOrTrial(org);
    const canTrial = OrgPaidStatus.isOrgAbleToTrial(org);
    const showPremiumVulnPaywall =
      ArtifactHelper.isArtifactPremiumByStatus(cveStatus) && !isPaidOrTrialing;

    //CoordinateType support check
    const isVulnMethodSupported = VulnMethodSupportStatus.isCoordTypeSupported(coordinateType);
    const isFixed = status === 'FIXED' && fixedScanId && fixedScanDate;

    const isContainerProject = projectType.toUpperCase() === MODEL.PROJECT_TYPES.CONTAINER;

    if (isFailure) {
      return (
        <div className="col-1-1 mt-">
          <Helmet>
            <title>Vulnerability Issue Details</title>
          </Helmet>
          <LoaderWrapper isLoaderShowing={isFetching || isFetchingMe}>
            <div className="grid grid--center mb-">
              <div className="grid__item col-1-1">
                <div className="flex align-items--baseline">
                  <div className="font--h3">Issue</div>
                  <div className="font--h4 color--black-light pl- pt-">
                    <strong>Vulnerability</strong>
                  </div>
                </div>
              </div>
              <div className="grid__item col-1-3 mt++">
                <div className="font--h5 text--center">
                  The information about this vulnerability has changed. Please rescan the project.
                </div>
              </div>
            </div>
          </LoaderWrapper>
        </div>
      );
    }

    return (
      <div className="col-1-1 mt-">
        <LoaderWrapper isLoaderShowing={isFetching || isFetchingMe}>
          <Helmet>
            <title>Vulnerability Issue Details</title>
          </Helmet>
          <div className="">
            {/*Vulnerability detail and data source details section*/}
            <div className="grid mb-">
              <div className="grid__item pl">
                <div
                  className="flex align-items--baseline"
                  data-automation-id="IssueVulnerabilitiesDetailsPage-Heading"
                >
                  <span className="font--h3"> Issue </span>
                  <span className="font--h4 color--black-light pl- pt-">
                    <strong>Vulnerability</strong>
                  </span>
                </div>
              </div>
              <div className="flex grid__item flex--justify-content--end align-item--center col-2-5 pt--">
                {this.isIssueCommentEnabled() && (
                  <div
                    className="link--obvious link--no-underline font--16 pb--- pt-- flex flex--align-items--center mr"
                    data-automation-id="IssueVulnerabilitiesDetailsPage-IssueComment"
                  >
                    <i className="sci sci__chat" />{' '}
                    <span
                      className="pl--"
                      onClick={() => this.props.modalActions.openModal(MODEL.COMMENT_ONLY_MODAL)}
                    >
                      {' '}
                      Comment{' '}
                    </span>
                    <IssueDetailComment
                      toggleSidebar={() => this.toggleSidebar()}
                      hasComments={hasComments}
                      id={`${id}-${scanId}`}
                    />
                  </div>
                )}

                <div
                  className="link--obvious link--no-underline font--16 pb--- pt-- flex flex--align-items--center"
                  onClick={this.toggleSidebar}
                >
                  {isPaidOrTrialing ? <i className="fas fa-clock" /> : <ProIcon />}{' '}
                  <span className="pl--"> Show History </span>
                </div>
              </div>
            </div>
            <div className="grid grid--narrower bo--b-1 bg-color--black-light mb+ color--white">
              <div className="grid__item col-3-4 p">
                <h4>{titleHighlightHtml}</h4>
                {overviewHighlightHtml}
                <p data-automation-id="IssueVulnerabilitiesDetailsPage-ViewMore">
                  <RegistryLink href={registryVulnHref} className="link--obvious">
                    View more details in the Veracode{' '}
                    {MODEL.SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.name}
                  </RegistryLink>
                </p>
              </div>
              <div className="grid__item col-1-4 p">
                <div className="grid">
                  <div className="grid__item col-1-1">
                    <LoaderWrapper loaderType="SPINNER">
                      {isPoliciesEnabled ? (
                        <IssueSeverityFlag
                          severity={issueSeverityVal}
                          displayStyle={IssueSeverityFlagDisplayStyle.NUMBER_WITH_TEXT}
                        />
                      ) : (
                        <SeverityFlag severity={severity} />
                      )}
                    </LoaderWrapper>
                    <div className="pt-">
                      <span className="text--bold">Issue ID:</span> {id}
                    </div>
                    <div className="pt--">
                      <span className="text--bold">Data Source:</span> {dataSource}
                    </div>
                    <div
                      className="pt--"
                      data-automation-id="IssueVulnerabilitiesDetailsPage-VulnerabilityId"
                    >
                      <span className="text--bold">Vulnerability ID:</span>{' '}
                      <RegistryLink href={registryVulnHref} className="link--obvious">
                        {cveText}
                      </RegistryLink>
                    </div>
                    {isPaidOrTrialing && (
                      <div className="pt--">
                        <span className="text--bold">Linked Issue:&nbsp;</span>
                        {jiraHref ? (
                          <a href={jiraHref} className="link--obvious" target="_blank">
                            {linkedJiraTicket[0].jiraKey}
                          </a>
                        ) : (
                          <span>(None) </span>
                        )}
                      </div>
                    )}
                    {isPaidOrTrialing && (
                      <div className="pt--">
                        <span className="text--bold">Status: </span>
                        {formattedStatus}
                      </div>
                    )}

                    {this.isIgnoreIssueChangesEnabled() && (
                      <div
                        className="pt--"
                        data-automation-id="IssueVulnerabilitiesDetailsPage-IgnoreIssue"
                      >
                        <span className="text--bold">Ignore Issue: &nbsp;</span>
                        <label className="position--relative">
                          <input
                            ref={input => ((this as any).ignoreIssueCheckboxRef = input)}
                            type="checkbox"
                            name={`ignore-issue`}
                            value={`ignore`}
                            defaultChecked={suppressed}
                            onChange={this.handleIgnoreIssue}
                            disabled={isSuppressingIssue}
                          />
                          <span className="control--checkbox" />
                        </label>
                        <div>
                          {isSuppressingIssue && (
                            <span>
                              <Spinner
                                size={12}
                                strokeWidth={4}
                                backgroundColor="bg-color--black-light"
                                polygonClassName="fill--black-light stroke--white"
                              />{' '}
                              Loading...
                            </span>
                          )}
                          {isSuppressingIssueSuccess && <span> Updated Successfully</span>}
                          {isSuppressingIssueFailure && <span> {isSuppressingIssueFailure}</span>}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*End of Vulnerability detail and data source details section*/}

            {/*Project and Library details section*/}
            <div className="grid grid--narrower pb+">
              <div className="grid__item col-1-2 pl0 pr-">
                <IssueProjectDetails
                  teamId={teamId}
                  issue={issue}
                  isPaidOrTrialing={isPaidOrTrialing}
                  isContainerProject={isContainerProject}
                />
              </div>
              <div className="grid__item col-1-2 pl-">
                <div className="grid grid--narrow">
                  <div className="grid__item col-1-1">
                    <div className="font--18 bo--b-1 border-color--muted-light mb-">
                      LIBRARY DETAILS
                    </div>
                  </div>

                  {showPremiumVulnPaywall ? (
                    <div className="grid__item col-1-2">
                      <div className="cursor--pointer" onClick={this.toggleCtaPremiumVulnModalOpen}>
                        <ProIcon />
                      </div>
                    </div>
                  ) : (
                    <div className="grid col-1-1 pl-">
                      <div className="grid__item col-1-2">
                        <div data-automation-id="IssueVulnerabilitiesDetailsPage-AffectedLibrary">
                          <span className="text--bold">Affected Library:&nbsp;</span>
                          {libraryNameHighlightHtml}
                          {coordinateType && <span>, {coordinateType}</span>}
                          {/* For GO component, their name is enough. coordContent is always the same as their name  */}
                          {coordContent && coordinateType !== 'GO' && <span>, {coordContent}</span>}
                        </div>
                        <div className="pt-">
                          <span className="text--bold">Type:</span>{' '}
                          {Helpers.capFirst(dependencyModeText)} dependency
                        </div>
                      </div>

                      <div className="grid__item col-1-2">
                        <div className="pl--">
                          <div>
                            <span className="text--bold">Version In Use:</span> {versionInUseHtml}
                          </div>
                          <div className="pt---">
                            <span className="text--bold">Released On:</span>{' '}
                            {formattedLibraryReleaseDate}
                          </div>
                        </div>

                        <div className="pl-- pt-">
                          <div>
                            <span className="text--bold">Latest Version:</span> {latestVersionHtml}
                          </div>
                          <div className="pt---">
                            <span className="text--bold">Released On:</span>{' '}
                            {formattedLatestReleaseDate}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/*End of Repo and Library details section*/}

            {/*The Fix, Vuln Methods and Dependency Graph section*/}
            <div className="pb- bo--b-1 border-color--white-dark mb">
              <ReportDetailsPageTabWrapper defaultTab={'theFix'} reportType={REPORT_TYPE}>
                {!isFixed && (
                  <ReportDetailsPageTabItem
                    label="The Fix"
                    field={`theFix`}
                    isActive={tabSelected === 'theFix'}
                    onClick={this.handleTabClick}
                  />
                )}
                {!isContainerProject && (
                  <React.Fragment>
                    {isPaidOrTrialing ? (
                      isVulnMethodSupported && (
                        <ReportDetailsPageTabItem
                          label="Vulnerable Methods"
                          field={`vulnMethods`}
                          showProIcon={!isPaidOrTrialing}
                          isActive={tabSelected === 'vulnMethods'}
                          onClick={this.handleTabClick}
                        />
                      )
                    ) : (
                      <div>
                        <ReportDetailsPageTabItem
                          label="Vulnerable Methods"
                          field={`vulnMethods`}
                          showProIcon={!isPaidOrTrialing}
                          isActive={tabSelected === 'vulnMethods'}
                          onClick={this.toggleCtaVulnModalOpen}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )}
                <ReportDetailsPageTabItem
                  label="Dependency Graph"
                  field={`dependencyGraph`}
                  link={graphLink}
                  isActive={tabSelected === 'dependencyGraph'}
                  onClick={this.handleTabClick}
                />
                <ReportDetailsPageTabContent>
                  {tabSelected === 'theFix' && (
                    <div className="grid grid--narrower">
                      <div className="grid__item col-2-3 pr pl0">
                        <ReportDetailsPageFixContent
                          data={fixInfo}
                          projectType={projectType}
                          dependencyMode={dependencyMode}
                          artifact={artifact}
                          registryLibraryUrl={registryLibHref.split('?')[0]}
                        />
                      </div>

                      <div className="grid__item col-1-3 pr+ pl">
                        <div className="grid">
                          <div>
                            <IssueDetailCreateIssueBtn
                              isPaidOrTrialing={isPaidOrTrialing}
                              issue={issue}
                              reportType={REPORT_TYPE}
                              teamId={teamId}
                              linkedIssueCreated={!_.isEmpty(jiraHref)}
                            />
                          </div>
                          {projectType.toUpperCase() !== MODEL.PROJECT_TYPES.CONTAINER && (
                            <div className="grid__item col-3-5">
                              <ReportDetailsPageUpdateAdvisorWrapper
                                link={updatesLink}
                                onComponentDidMount={this.updateAdvisorComponentDidMount}
                              >
                                <ReportDetailsPageUpdateAdvisorAlert data={updateAdvisorData} />
                              </ReportDetailsPageUpdateAdvisorWrapper>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {tabSelected === 'vulnMethods' &&
                    (isPaidOrTrialing ? (
                      <ReportDetailsPageVulnMethodView
                        data={vulnMethods}
                        language={coordinateType}
                        expandedVulnerableMethodChain={expandedVulnerableMethodChain}
                        toggleFullCallChain={this.toggleFullCallChain}
                      />
                    ) : (
                      <div className="grid">
                        <div className="grid__item col-1-18 flex flex--align-items--center">
                          <i className="sci font--h3 sci__lock color--muted-dark" />
                        </div>
                        <div className="grid__item col-2-3 flex">
                          <p>
                            Vulnerable Methods are available exclusively in SourceClear Premium. We
                            currently offer this feature for Java and Python, with support for other
                            languages coming soon.
                            <Link className="link--obvious" to={`/org/settings/subscribe`}>
                              {canTrial ? ' Start your free trial today' : ' Upgrade to Premium'}
                            </Link>
                            .
                          </p>
                        </div>
                      </div>
                    ))}

                  {tabSelected === 'dependencyGraph' && (
                    <ReportDetailsPageTabSectionWrapper
                      link={graphLink}
                      reportType={REPORT_TYPE}
                      field={`dependencyGraph`}
                      loadOnComponentMount={!(isVulnMethodSupported && isPaidOrTrialing)}
                    >
                      Dependency graph for{' '}
                      <span
                        className="text--bold"
                        dangerouslySetInnerHTML={Helpers.formatBreakOnSlash(repoNameText)}
                      />
                      .
                      <LoaderWrapper isLoaderShowing={isActiveTabLoading}>
                        {!isActiveTabLoading && (
                          <div>
                            <SunburstChart
                              onlyColorIfPathContains={colorIfPathContains}
                              data={sunburst}
                              selectedPath={dependenciesSunburstSelectedPath}
                              onPathSelected={path => this.onPathSelected(path)}
                              width={800}
                              height={450}
                            />

                            <div className="min-height--50">
                              <SunburstChartHeader
                                showColorForHeaders={showColorForHeader}
                                headers={dependenciesSunburstSelectedPath}
                                maxWidth={800}
                              />
                            </div>
                          </div>
                        )}
                      </LoaderWrapper>
                    </ReportDetailsPageTabSectionWrapper>
                  )}
                </ReportDetailsPageTabContent>
              </ReportDetailsPageTabWrapper>
            </div>

            {/*End of Vuln Methods and Dependency Graph section*/}

            {/*More Information details section*/}

            <div className="grid grid--narrower pb+">
              <div className="grid__item col-1-1 mb p0">
                <h5> More Information</h5>
                <RegistryLink href={registryVulnHref} className="link--obvious">
                  More on <span className="text--bold">{titleHighlightHtml}</span> at Veracode{' '}
                  {MODEL.SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.name}
                </RegistryLink>
              </div>
            </div>

            {/*End of More Information details section*/}
          </div>
        </LoaderWrapper>
        <SidebarWrapper
          id={SIDEBAR_ID}
          title={sidebarTitleHtml}
          pullRight={true}
          sidebarClassName={`bg-color--white-medium width--400`}
          shadow={true}
        >
          {isSidebarOpen && <IssueHistory reportType={REPORT_TYPE} />}
        </SidebarWrapper>
        <IgnoreIssueModal
          selectedIssues={[this.getCurrentIssue()]}
          isIgnoring={!suppressed}
          onCancel={() => this.handleIgnoreIssueCancel()}
          onConfirm={(comment: string, suppressUntil: Date) =>
            this.ignoreIssue(comment, suppressUntil)
          }
        />
        {this.isIssueCommentEnabled() && (
          <CommentIssueModal onConfirm={(comment: string) => this.commentIssue(comment)} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportDetailsPageState: state.reportDetailsPageState,
    reportIssuesState: state.reportIssuesState,
    teamState: state.teamState,
    orgState: state.orgState,
    modalState: state.modalState,
    myState: state.myState,
    sidebarState: state.sidebarState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    modalActions: bindActionCreators(modalActions as any, dispatch),
    reportDetailsPageActions: bindActionCreators(reportDetailsPageActions as any, dispatch),
    teamActions: bindActionCreators(teamActions as any, dispatch),
    reportIssuesActions: bindActionCreators(reportIssuesActions as any, dispatch),
    upgradeModalActions: bindActionCreators(upgradeModalActions as any, dispatch),
    sidebarActions: bindActionCreators(sidebarActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssuesVulnerabilitiesDetailsPage);
