import React from 'react';

interface PaginationProps {
  currentPage?: number;
  totalPages?: number;
  handlePageClick: (...args: any[]) => any;
}

const Pagination: React.FunctionComponent<PaginationProps> = props => {
  const { currentPage = 0, totalPages = 0 } = props;
  const hasPreviousPage = currentPage > 0;
  const hasNextPage = currentPage + 1 < totalPages;

  function loadFirstPage() {
    props.handlePageClick(0);
  }

  function loadPreviousPage() {
    props.handlePageClick(currentPage - 1);
  }

  function loadNextPage() {
    props.handlePageClick(currentPage + 1);
  }

  function loadLastPage() {
    props.handlePageClick(totalPages - 1);
  }

  return (
    <div className="grid grid--center grid--full pv-" data-automation-id="Pagination">
      <div className="grid__item flex justify-content--center ph--" title="First page">
        <div
          className={`flex color--primary align-items--center ${
            currentPage !== 0 ? 'cursor--pointer' : 'color--muted'
          }`}
          onClick={currentPage !== 0 ? loadFirstPage : null}
        >
          <i className={'sci sci--sm sci__caret--left'} />
          <i className={'sci sci--sm sci__caret--left -ml--'} />
        </div>
      </div>
      <div className="grid__item flex justify-content--center ph--" title="Previous page">
        <div
          className={`flex color--primary align-items--center ${
            hasPreviousPage ? 'cursor--pointer' : 'color--muted'
          }`}
          onClick={hasPreviousPage ? loadPreviousPage : null}
        >
          <i className={'sci sci--sm sci__caret--left'} />
        </div>
      </div>
      <div className="grid__item flex align-items--center justify-content--center ph-- position--relative">
        Page <span className="text--bold ph--">{currentPage + 1}</span> of{' '}
        <span className="text--bold pl--">{totalPages}</span>
      </div>
      <div className="grid__item flex justify-content--center ph--" title="Next page">
        <div
          className={`flex  color--primary align-items--center ${
            hasNextPage ? 'cursor--pointer' : 'color--muted'
          }`}
          onClick={hasNextPage ? loadNextPage : null}
        >
          <i className={'sci sci--sm sci__caret--right'} />
        </div>
      </div>
      <div className="grid__item flex justify-content--center ph--" title="Last page">
        <div
          className={`flex color--primary align-items--center ${
            currentPage === totalPages - 1 ? 'color--muted' : 'cursor--pointer'
          }`}
          onClick={currentPage !== totalPages - 1 ? loadLastPage : null}
        >
          <i className={'sci sci--sm sci__caret--right -mr--'} />{' '}
          <i className={'sci sci--sm sci__caret--right'} />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
