import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';
import PackageManagerNPMTransitiveDependencyFixInfo from '~/components/ReportPage/QuickviewFixInfo/PackageManagerFixInfo/PackageManagerNPMTransitiveDependencyFixInfo';

interface PackageManagerNPMProps {
  component: {
    name: string;
  };
  range?: {
    versionRange?: string;
  };
  safeVersion: string;
  transitive: boolean;
  unsafeVersion?: string;
  packageFile?: string;
  lineNumber?: string;
}

const PackageManagerNPM: React.FunctionComponent<PackageManagerNPMProps> = props => {
  const { component, range = {}, safeVersion, transitive = false, packageFile, lineNumber } = props;
  const { name } = component;
  const unsafeVersion =
    props.unsafeVersion ||
    (range.versionRange && range.versionRange.indexOf('-') !== -1
      ? range.versionRange.split('-')[0]
      : '');

  if (!transitive) {
    return (
      <div className="grid bo--1 border-color--white-dark mt bg-color--white lh+">
        <CodeDiffHeader text={packageFile} />
        <CodeDiffLine type="normal" text='"dependencies": {' />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine
          type="delete"
          lineNumber={lineNumber}
          text={'\u2003\u0022' + name + '\u0022: \u0022' + unsafeVersion + '\u0022,'}
        />
        <CodeDiffLine
          type="add"
          text={'\u2003\u0022' + name + '\u0022: \u0022' + safeVersion + '\u0022,'}
        />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine type="normal" text={`}`} />
      </div>
    );
  } else {
    // EXT-596: Transitive JS vulnerabilities are fixed by addition of safe version of transitive library in npm-shrinkwrap
    return (
      <div className="pt">
        <PackageManagerNPMTransitiveDependencyFixInfo
          name={name}
          safeVersion={safeVersion}
          unsafeVersion={unsafeVersion}
        />
      </div>
    );
  }
};

export default PackageManagerNPM;
