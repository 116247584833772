import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Helmet from 'react-helmet';

import * as userNotificationsActions from '~/actions/userNotifications';
import { ISSUE_NOTIFICATION_TYPES, PLAN_TYPE } from '~/constants/ModelConstants';
import Tooltip from '~/components/Tooltip';
import OrgPaidStatus from '~/utils/OrgPaidStatus';

import { NotificationSettingsPageProps } from '~/containers/NotificationSettingsPage.types';

class NotificationSettingsPage extends Component<
  NotificationSettingsPageProps & ReturnType<typeof mapDispatchToProps>
> {
  componentDidMount() {
    this.props.userNotificationsActions.fetchNotificationsSettings();
  }

  handleNotificationUpdate(type) {
    this.props.userNotificationsActions.updateNotificationSettings(type);
  }

  render() {
    const {
      userNotifications = {},
      vcPageState: { shouldShowVeracodePage },
      orgState,
    } = this.props;
    const {
      settings = [],
      isFetching,
      isUpdating = false,
      isUpdatingType = '',
    } = userNotifications;
    const isLoaderShowing = type => isUpdating && isUpdatingType === type;
    const sourceclearIssuesTooltipContent = (
      <div>
        <p>
          Issues can be created between scans when a new vulnerability is discovered in one of your
          libraries. Issues can be resolved when vulnerability data changes.
        </p>
        <p>
          Emails will only be sent for changes in a project&apos;s default branch. To set the
          default branch, go to project settings.
        </p>
      </div>
    );
    const title = shouldShowVeracodePage ? 'My Notifications' : 'Notifications';
    const { org } = orgState;
    const isOrgEnterprise = OrgPaidStatus.getOrgPlanState(org) === PLAN_TYPE.ENTERPRISE;

    return (
      <section>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <div className="pt font--h3" data-automation-id="OrgNotificationsPage-Title">
          {title}
          {(isUpdating || isFetching) && (
            <i className={`fas fa-spin fa-spinner color--muted-dark ml-- font--18`} />
          )}
        </div>

        <div className="grid">
          <div className="grid__item col-1-1">
            <label
              className={`label--checkbox grid grid--middle grid--full mt-`}
              key={'new-issues'}
            >
              <input
                type="checkbox"
                className="flex"
                name={'new-issues'}
                onChange={() => this.handleNotificationUpdate(ISSUE_NOTIFICATION_TYPES.SCAN_ISSUES)}
                checked={
                  settings.filter(x => x.notificationType === ISSUE_NOTIFICATION_TYPES.SCAN_ISSUES)
                    .length > 0
                }
              />
              <span className="react--checkbox" />
              <span
                className={`pv-- ${
                  isLoaderShowing(ISSUE_NOTIFICATION_TYPES.SCAN_ISSUES) ? 'color--muted-dark' : ''
                }`}
              >
                Email me about new issues found during scans in any of my projects
              </span>
            </label>
          </div>

          {isOrgEnterprise && (
            <div className="grid__item col-1-1">
              <label
                className={`label--checkbox grid grid--middle grid--full mt-`}
                key={'new-issues'}
              >
                <input
                  type="checkbox"
                  className="flex"
                  name={'new-issues'}
                  onChange={() =>
                    this.handleNotificationUpdate(ISSUE_NOTIFICATION_TYPES.SOURCECLEAR_ISSUES)
                  }
                  checked={
                    settings.filter(
                      x => x.notificationType === ISSUE_NOTIFICATION_TYPES.SOURCECLEAR_ISSUES
                    ).length > 0
                  }
                />
                <span className="react--checkbox" />
                <span
                  className={`pv-- ${
                    isLoaderShowing(ISSUE_NOTIFICATION_TYPES.SOURCECLEAR_ISSUES)
                      ? 'color--muted-dark'
                      : ''
                  }`}
                >
                  Email me about issues created or changed due to new vulnerability releases in any
                  of my projects that I am watching
                </span>
                <Tooltip
                  id={`SOURCECLEAR-ISSUES-TOOLTIP`}
                  content={sourceclearIssuesTooltipContent}
                  maxWidthClass="max-width--300"
                  place="top"
                >
                  <div className="flex align-items--center justify-content--center">
                    <i className="fas fa-info-circle pl--" />
                  </div>
                </Tooltip>
              </label>
            </div>
          )}
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    userNotifications: state.userNotifications,
    vcPageState: state.vcPageState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    userNotificationsActions: bindActionCreators(userNotificationsActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsPage);
