import * as actions from '~/actions/teamUsers';

const defaultState = {
  teamUsers: [],
  filteredTeamUsers: [],
  quickViewsByInviteId: {},
  quickViewsByUserId: {},
  selectedUsers: [],
  isFetching: false,
};

const teamUsersState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_WORKSPACE_USERS:
      return {
        ...state,
        teamUsers: action.users,
        filteredTeamUsers: action.users,
        selectedTeamUserIds: {},
        isFetching: false,
        message: '',
      };
    case actions.FETCH_TEAM_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.FETCH_TEAM_USERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message,
      };
    case actions.FILTER_TEAM_USERS:
      return {
        ...state,
        filteredTeamUsers: filterTeamUsers(state.teamUsers, action.value),
      };
    case actions.TOGGLE_WORKSPACE_USER_QUICK_VIEW:
      return {
        ...state,
        quickViewsByUserId: {
          [action.userId]:
            state.quickViewsByUserId[action.userId] === action.column ? 'OFF' : action.column,
        },
      };
    case actions.TOGGLE_WORKSPACE_INVITE_QUICK_VIEW:
      return {
        ...state,
        quickViewsByInviteId: {
          [action.inviteId]:
            state.quickViewsByInviteId[action.inviteId] === action.column ? 'OFF' : action.column,
        },
      };
    case actions.UPDATE_SELECTED_TEAM_USERS:
      return {
        ...state,
        selectedUsers: state.selectedUsers.includes(action.userId)
          ? state.selectedUsers.filter(userId => userId !== action.userId)
          : state.selectedUsers.concat(action.userId),
      };
    case actions.RESET_SELECTED_TEAM_USERS:
      return {
        ...state,
        selectedUsers: [],
      };
    case actions.RESET_TEAM_USER_QUICK_VIEWS:
      return {
        ...state,
        quickViewsByUserId: {},
      };
    default:
      return state;
  }
};

export default teamUsersState;

function filterTeamUsers(users, value) {
  const trimmedValue = value.trim().toLowerCase();
  return users.filter(userObj => {
    const { user = {} } = userObj;
    const { firstName = '', lastName = '', email = '' } = user;

    return (
      firstName.toLowerCase().includes(trimmedValue) ||
      lastName.toLowerCase().includes(trimmedValue) ||
      email.toLowerCase().includes(trimmedValue)
    );
  });
}
