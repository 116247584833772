import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';
import DropdownMenu from 'react-dd-menu';
import _ from 'lodash';

import * as groupActions from '~/actions/group';
import * as orgUserActions from '~/actions/orgUser';
import * as dropdownMenuActions from '~/actions/dropdownMenu';

import OrgPaidStatus from '~/utils/OrgPaidStatus';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import { FEATURE_SLUG_MAP } from '~/constants/ModelConstants';

import UserManagementQuickViewWrapper from '~/containers/UserManagementQuickViewWrapper';
import SourceClearModal from '~/components/SourceClearModal';
import TeamWorkspacesQuickView from '~/components/TeamWorkspacesQuickView';
import TeamUsersQuickView from '~/components/TeamUsersQuickView';
import CreateGroupModal from '~/containers/CreateGroupModal';
import AddUserToGroupsModal from '~/containers/AddUserToGroupsModal';

interface OrgTeamsPageProps extends RouteComponentProps {
  dropdownMenuActions: object;
  groupActions: object;
  orgUserActions: object;
  dropdownMenuState: object;
  groupState: object;
  orgState: App.OrgState;
}
class OrgTeamsPage extends React.Component<OrgTeamsPageProps> {
  componentDidMount() {
    const { orgState, history } = this.props;
    const { org } = orgState;
    const showGroups = FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org);

    if (org.id && !showGroups && !OrgPaidStatus.isOrgEnterpriseOrTrial(org)) {
      history.replace(`/org/settings/users`);
    }

    this.props.groupActions.fetchGroups(org.id);
    this.props.orgUserActions.fetchOrgUsers();
    this.props.groupActions.resetSelectedGroups();
  }

  componentWillUnmount() {
    this.props.groupActions.resetGroupSort();
    this.props.dropdownMenuActions.closeDropdownMenu('groups-actions');
    this.props.groupActions.resetGroupQuickViews();
  }

  delayCloseDropdown = _.debounce(() => this.closeDropdown('groups-actions'), 50);

  handleGroupSelection(groupId) {
    this.props.groupActions.updateSelectedGroups(groupId);
  }

  handleFilterInput(e) {
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.groupActions.filterGroupsByInput(value);
  }

  formatUserName(user = {}) {
    const { firstName, lastName, email } = user;

    if (!firstName && !lastName) {
      return email;
    }
    return `${firstName ? firstName : ''}${firstName && lastName ? ' ' : ''}${
      lastName ? lastName : ''
    }`;
  }

  confirmBulkDelete(groupIds) {
    this.props.groupActions.bulkGroupDelete(groupIds);
  }

  closeDropdown(menuId) {
    this.props.dropdownMenuActions.closeDropdownMenu(menuId);
  }

  openDropdown(menuId) {
    this.props.dropdownMenuActions.openDropdownMenu(menuId);
  }

  updateSort(column) {
    this.props.groupActions.updateSort(column);
  }

  toggleGroupQuickView(groupId, column) {
    this.props.groupActions.toggleGroupQuickView(groupId, column);
  }

  showModal(modalType) {
    this.props.groupActions.showModal(modalType);
  }

  render() {
    const { groupState, dropdownMenuState, orgState } = this.props;
    const { org } = orgState;
    const { permissions = {} } = org;
    const {
      createGroups,
      manageGroupUsers,
      deleteGroups,
      manageGroupTeams,
      updateTeams,
      updateUsers,
    } = permissions;
    const allowGroupSelect = createGroups || deleteGroups || manageGroupUsers;
    const {
      filteredGroups = [],
      selectedGroups = {},
      showModal,
      groups = [],
      sortColumn,
      sortAscending,
      quickViewsByGroupId,
    } = groupState;
    const { openedDropdown = {} } = dropdownMenuState;
    const selectedGroupNames = [];
    const selectedGroupIds = [];
    const columnWidth = {
      groupName: 'col-1-8',
      users: 'col-5-18',
      workspaces: 'col-1-4',
      select: 'col-1-18',
      description: '',
    };
    const someGroupsSelected = _.some(selectedGroups);
    groups.forEach(group => {
      if (selectedGroups[group.id]) {
        selectedGroupNames.push(group.name);
        selectedGroupIds.push({ groupId: group.id });
      }
    });

    const renderTeams = () => {
      if (!groups.length) {
        return <div className="mt- font--h3 color--muted-dark">No teams exist.</div>;
      } else if (!filteredGroups.length)
        return <div className="mt- font--h3 color--muted-dark">No teams found.</div>;
      return filteredGroups.map(group => {
        const {
          id: groupId,
          name = '',
          description = '',
          numUsers,
          numTeams,
          _links = {},
          permissions = {},
        } = group;
        const { update, manageUsers, manageWorkspaces } = permissions;
        const enableGroupLink = update || manageUsers || manageWorkspaces;
        const { users = {}, teams = {} } = _links;
        const isChecked = selectedGroups[groupId] || false;
        const quickView = quickViewsByGroupId[groupId];

        return (
          <div className="grid grid--narrower mt-" key={groupId}>
            <div className={`grid__item flex align-items--center ${columnWidth.groupName}`}>
              {enableGroupLink ? (
                <Link to={`/org/settings/teams/${groupId}`} className="link--obvious">
                  <strong>{name}</strong>
                </Link>
              ) : (
                <div>{name}</div>
              )}
            </div>
            <div
              className={`grid__item flex align-items--center text--overflow ${columnWidth.description}`}
            >
              {description || '--'}
            </div>
            <div className={`grid__item flex ${columnWidth.users}`}>
              <div
                className={`flex justify-content--space-between col-1-4 ${
                  numUsers && 'cursor--pointer'
                }`}
                onClick={numUsers ? () => this.toggleGroupQuickView(groupId, 'USERS') : undefined}
              >
                {numUsers}
                {numUsers === 1 ? 'user' : 'users'}
                <i
                  className={`sci sci__caret--${quickView === 'USERS' ? 'down' : 'right'} color--${
                    numUsers ? 'primary' : 'muted-light'
                  }`}
                />
              </div>
            </div>
            <div className={`grid__item flex ${columnWidth.workspaces}`}>
              <div
                className={`flex justify-content--space-between col-4-9 ${
                  numTeams && 'cursor--pointer'
                }`}
                onClick={
                  numTeams ? () => this.toggleGroupQuickView(groupId, 'WORKSPACES') : undefined
                }
              >
                {numTeams}
                {numTeams === 1 ? 'workspace' : 'workspaces'}
                <i
                  className={`sci sci__caret--${
                    quickView === 'WORKSPACES' ? 'down' : 'right'
                  } color--${numTeams ? 'primary' : 'muted-light'}`}
                />
              </div>
            </div>
            {allowGroupSelect && (
              <div
                className={`grid__item ${columnWidth.select} flex justify-content--center align-items--center`}
              >
                <label className="label--checkbox flex justify-content--center mb0">
                  <input
                    type="checkbox"
                    name="group"
                    className=""
                    checked={isChecked}
                    onChange={() => this.handleGroupSelection(groupId)}
                  />
                  <span className="react--checkbox mr0" />
                </label>
              </div>
            )}
            <div
              className={`grid__item col-1-1 ${
                quickView === 'WORKSPACES' ? 'is-showing-400' : 'is-hiding'
              }`}
            >
              {quickView === 'WORKSPACES' && (
                <UserManagementQuickViewWrapper dataType="workspaces" href={teams.href}>
                  <TeamWorkspacesQuickView showDetailsLink={updateTeams || manageGroupTeams} />
                </UserManagementQuickViewWrapper>
              )}
            </div>
            <div
              className={`grid__item col-1-1 ${
                quickView === 'USERS' ? 'is-showing-400' : 'is-hiding'
              }`}
            >
              {quickView === 'USERS' && (
                <UserManagementQuickViewWrapper dataType="users" href={users.href}>
                  <TeamUsersQuickView showDetailsLink={updateUsers} />
                </UserManagementQuickViewWrapper>
              )}
            </div>
          </div>
        );
      });
    };

    return (
      <div className="grid mt mb++">
        <Helmet>
          <title>Teams</title>
        </Helmet>
        <div className="grid__item col-1-1">
          <h3 data-e2e="OrgTeamsPage-Title">Teams</h3>
        </div>
        <div className="grid__item col-1-1 mt-">
          <div className="bo--1 border-color--muted-dark flex justify-content--space-between p- align-items--center">
            <div className="flex col-3-4">
              <div className="col-1-2">
                <input
                  type="text"
                  className="control--text col-1-1"
                  placeholder="Filter by name, description, user, or workspace"
                  onChange={e => this.handleFilterInput(e)}
                />
              </div>
            </div>
            <div>
              {allowGroupSelect && (
                <DropdownMenu
                  isOpen={!!openedDropdown['groups-actions']}
                  close={() => this.delayCloseDropdown()}
                  menuAlign="right"
                  textAlign="left"
                  toggle={
                    <button
                      className="btn--success p-"
                      onClick={() => this.openDropdown('groups-actions')}
                    >
                      Actions
                    </button>
                  }
                >
                  {createGroups && (
                    <li className="min-width--200">
                      <button className="pv--" onClick={() => this.showModal('CREATE')}>
                        Create
                      </button>
                    </li>
                  )}
                  {manageGroupUsers && (
                    <li className="min-width--200 dropdown-menu--li">
                      <button
                        className={`pv-- ${!someGroupsSelected && 'disabled'}`}
                        disabled={!someGroupsSelected}
                        onClick={() => this.showModal('ADD_USER')}
                      >
                        Add User to Teams
                      </button>
                    </li>
                  )}
                  {deleteGroups && (
                    <li className="min-width--200 dropdown-menu--li">
                      <button
                        className={`pv-- ${!someGroupsSelected && 'disabled'}`}
                        disabled={!someGroupsSelected}
                        onClick={() => this.showModal('DELETE_GROUPS')}
                      >
                        Delete
                      </button>
                    </li>
                  )}
                </DropdownMenu>
              )}
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1 mt">
          {/* groups list header */}
          <div className="grid grid--narrower">
            <div
              className={`grid__item flex align-items--center justify-content--space-between ${columnWidth.groupName} cursor--pointer`}
              onClick={() => this.updateSort('name')}
            >
              <strong>Team Name</strong>
              {sortColumn === 'name' ? (
                <i
                  className={`sci ${
                    sortAscending ? 'sci__arrow--closed-up' : 'sci__arrow--closed-down'
                  }`}
                />
              ) : (
                <i className={`sci sci__arrow--closed-scroll`} />
              )}
            </div>
            <div className={`grid__item ${columnWidth.description}`}>
              <strong className="">Description</strong>
            </div>
            <div className={`grid__item ${columnWidth.users}`}>
              <strong className="">Users</strong>
            </div>
            <div className={`grid__item ${columnWidth.workspaces}`}>
              <strong className="">Workspaces</strong>
            </div>
            {allowGroupSelect && (
              <div className={`grid__item ${columnWidth.select} text--center`}>
                <strong className="">Select</strong>
              </div>
            )}
          </div>
        </div>
        <div className="grid__item col-1-1">{renderTeams()}</div>
        <SourceClearModal
          isOpen={showModal === 'CREATE'}
          title="Create Team"
          onClose={() => this.showModal('')}
          width={500}
        >
          <CreateGroupModal showModal={modalType => this.showModal(modalType)} />
        </SourceClearModal>
        <SourceClearModal
          isOpen={showModal === 'ADD_USER'}
          title="Add User to Teams"
          onClose={() => this.showModal('')}
          width={500}
        >
          <AddUserToGroupsModal showModal={modalType => this.showModal(modalType)} />
        </SourceClearModal>
        <SourceClearModal
          isOpen={showModal === 'DELETE_GROUPS'}
          title={`Delete ${selectedGroupIds.length !== 1 ? 'Teams' : 'Team'}`}
          onClose={() => this.showModal('')}
          width={500}
        >
          <div className="grid">
            <div className="grid__item col-1-1 ">
              Are you sure you want to delete <strong>{selectedGroupNames.join(', ')}</strong>?
              Deleting teams is permanent and cannot be undone. When you delete a team, team members
              will lose access to any linked workspaces unless they belong to another team that
              gives them access.
            </div>
            <div className="grid__item col-1-1 flex justify-content--end mt">
              <button className="col-1-5 pv- mr-" onClick={() => this.showModal('')}>
                Cancel
              </button>
              <button
                className="p- btn--danger"
                onClick={() => this.confirmBulkDelete(selectedGroupIds)}
              >
                {selectedGroupIds.length !== 1 ? 'Delete Teams' : 'Delete Team'}
              </button>
            </div>
          </div>
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    groupState: state.groupState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    groupActions: bindActionCreators(groupActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgTeamsPage);
