import classNames from 'classnames';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import ErrorBoundary from '~/components/ErrorBoundary';
import WorkspaceTable from '~/containers/WorkspaceTable';
import VCAgentBasedScanSettingsButton from '~/components/VCWorkspaceSettingsButton';
import Helpers from '~/utils/Helpers';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import { RootState } from '~/reducers';

interface PortfolioPageProps {
  myState: App.MyState;
  orgState: App.OrgState;
  teamState: App.TeamState;
  vcPageState: VCPageState;
}

const PortfolioPage: React.FunctionComponent<PortfolioPageProps> = props => {
  const {
    myState,
    orgState: {
      org: { permissions: createTeams },
    },
    teamState,
    vcPageState: { shouldShowVeracodePage },
  } = props;

  const { me } = myState;
  const { teams = [] } = teamState;
  const { organization } = me;
  const { welcomeMode } = organization;
  if (welcomeMode) {
    if (teams.length && Helpers.isWorkspaceAdmin(teams[0])) {
      return (
        <Redirect
          to={{
            pathname: `/workspaces/${teams[0].id}/welcome`,
            state: { status: 301 },
          }}
        />
      );
    }
  }

  if (teams.length === 0 && createTeams && shouldShowVeracodePage) {
    return <Redirect to={{ pathname: '/no-workspaces' }} />;
  }

  const workspaceListTitle = shouldShowVeracodePage ? 'Workspace List' : 'Portfolio';
  const workspaceTableWidth = shouldShowVeracodePage ? 'col-1-1' : 'col-6-7';

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{workspaceListTitle}</title>
      </Helmet>
      <div className={`flex flex--flex-direction--column ${workspaceTableWidth}`}>
        {shouldShowVeracodePage && (
          <div className="flex flex--justify-content--end">
            <VCAgentBasedScanSettingsButton />
          </div>
        )}
        <div
          className={classNames(
            'flex mt',
            shouldShowVeracodePage ? 'font--h6 text--uppercase' : 'font--h3'
          )}
          data-e2e="WorkspaceList-Title"
        >
          {workspaceListTitle}
        </div>
        <WorkspaceTable />
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    myState: state.myState,
    teamState: state.teamState,
    vcPageState: state.vcPageState,
    orgState: state.orgState,
  };
};
export default connect(mapStateToProps)(PortfolioPage);
