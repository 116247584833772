import React from 'react';
import classNames from 'classnames';
import { VCIconComponentIcon } from '~/types/VCIconComponentIcon';

export interface VCIconProps {
  icon: VCIconComponentIcon | null;
  baseClass?: string;
}
function VCIcon(props: VCIconProps) {
  const {
    baseClass = '',
    icon: { icon_name: iconName, icon_type: iconType, stylePrefix = 'fal' },
  } = props;
  const iconClassNames =
    iconType === 'FONTAWESOME'
      ? classNames(baseClass, stylePrefix, `fa-${iconName}`)
      : classNames(baseClass, 'glyphicon', `glyphicon-${iconName}`);

  return props.icon && <span className={iconClassNames} />;
}

export default VCIcon;
