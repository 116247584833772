import React from 'react';

interface CustomizedAxisTickProps {
  x?: number;
  y?: number;
  payload?: object;
}

const CustomizedAxisTick: React.FunctionComponent<CustomizedAxisTickProps> = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        textAnchor="end"
        fill="#666"
        transform="rotate(-25)"
        fontSize="10px"
      >
        {payload.value}
      </text>
    </g>
  );
};

export default CustomizedAxisTick;
