import { combineReducers } from 'redux';

import * as actions from '~/actions/projectSettings';
import { PROJECT_SETTINGS_PAGES } from '~/constants/ModelConstants';

const projectSettingsState = () => {
  const page = (state = PROJECT_SETTINGS_PAGES.BRANCHES, action) => {
    switch (action.type) {
      case actions.UPDATE_PROJECT_SETTINGS_PAGE:
        return action.page;
      default:
        return state;
    }
  };

  const stage = (state = '', action) => {
    switch (action.type) {
      case actions.UPDATE_PROJECT_SETTINGS_STAGE:
        return action.stage;
      case actions.UPDATE_PROJECT_SETTINGS_PAGE:
        return '';
      default:
        return state;
    }
  };

  const isModalOpen = (state = false, action) => {
    switch (action.type) {
      case actions.SHOW_PROJECT_SETTINGS_MODAL:
        return true;
      case actions.CLOSE_PROJECT_SETTINGS_MODAL:
        return false;
      default:
        return state;
    }
  };

  return combineReducers({
    page,
    isModalOpen,
    stage,
  });
};

export default projectSettingsState();
