import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DropdownMenu from 'react-dd-menu';
import moment from 'moment-timezone';
import _ from 'lodash';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as groupActions from '~/actions/group';
import * as orgUserActions from '~/actions/orgUser';
import * as expandableSectionActions from '~/actions/expandableSection';
import * as orgUserInviteActions from '~/actions/orgUserInvite';
import * as toastrActions from '~/actions/toastr';

import ExpandableSection from '~/containers/ExpandableSection';
import UserManagementQuickViewWrapper from '~/containers/UserManagementQuickViewWrapper';
import InviteGroupsQuickView from '~/components/InviteGroupsQuickView';
import InviteWorkspacesQuickView from '~/components/InviteWorkspacesQuickView';
import Helpers from '~/utils/Helpers';

const expandableSections = {
  PENDING_INVITES_SECTION: 'pending-invites-section',
};

interface OrgUserPendingInvitesListProps {
  dropdownMenuActions: object;
  groupActions: object;
  orgUserActions: object;
  dropdownMenuState: object;
  expandableSectionState: object;
  expandableSectionActions: object;
  orgUserInviteActions: object;
  toastrActions: object;
  orgState: App.OrgState;
  orgUserState: object;
  quickViewsByInviteId: object;
  pendingInvites: any[];
  filteredPendingInvites: any[];
  selectedPendingInvites: any[];
  showModal: (...args: any[]) => any;
  toggleInviteQuickView: (...args: any[]) => any;
  showGroups: boolean;
}
class OrgUserPendingInvitesList extends React.Component<OrgUserPendingInvitesListProps, {}> {
  constructor(props, context) {
    super(props, context);

    this.delayCloseDropdown = _.debounce(id => this.closeDropdown(id), 50);
  }

  componentDidMount() {
    this.props.orgUserActions.fetchPendingInvites();
  }

  componentWillUnmount() {
    this.props.dropdownMenuActions.closeDropdownMenu('pending-invites-actions');
  }

  handleUserSelection(inviteId) {
    this.props.orgUserActions.updateSelectedPendingInvites(inviteId);
  }

  handleSelectAllPendingInvites(selectAll) {
    this.props.orgUserActions.selectAllPendingInvites(selectAll);
  }

  closeDropdown(menuId) {
    this.props.dropdownMenuActions.closeDropdownMenu(menuId);
  }

  openDropdown(menuId) {
    this.props.dropdownMenuActions.openDropdownMenu(menuId);
  }

  handleFilterInput(e) {
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.orgUserActions.filterPendingInvitesByInput(value);
  }

  resendInvitations() {
    const { selectedPendingInvites = [] } = this.props;
    const selectedInviteIds = selectedPendingInvites.map(invite => invite.inviteId);

    this.props.orgUserActions.resendInvitations(selectedInviteIds).then((res = {}) => {
      if (res.success) {
        this.props.toastrActions.addToastr({
          id: 'INVITE_RESEND_SUCCESS',
          level: 'success',
          title: `Invitation${selectedInviteIds.length !== 1 ? 's' : ''} successfully resent`,
          message: `${
            selectedInviteIds.length !== 1 ? 'Invitations have' : 'An invitation has'
          } been resent to the selected invitee${selectedInviteIds.length !== 1 ? 's' : ''}.`,
        });
      }
    });
  }

  showModal(modalType) {
    this.props.showModal(modalType);
  }

  toggleSection = section => {
    const { expandableSectionState = {} } = this.props;
    const { [section]: isExpanded = false } = expandableSectionState;

    this.props.expandableSectionActions.toggleSection(section, !isExpanded);
  };

  render() {
    const {
      dropdownMenuState,
      expandableSectionState,
      orgState,
      pendingInvites = [],
      filteredPendingInvites = [],
      selectedPendingInvites = [],
      quickViewsByInviteId = {},
      showGroups,
    } = this.props;
    const { openedDropdown = {} } = dropdownMenuState;
    const {
      [expandableSections.PENDING_INVITES_SECTION]: isExpanded = false,
    } = expandableSectionState;
    const { org = {} } = orgState;
    const samlDomains = org.samlDomains || [];
    const isSamlOrg = !!samlDomains.length;

    const isSelectAll =
      pendingInvites.length === selectedPendingInvites.length && pendingInvites.length;

    const columnWidth = {
      email: 'col-1-5',
      invitedAsAdmin: showGroups ? 'col-1-12' : 'col-1-7',
      invitedAsOwner: showGroups ? 'col-1-12' : 'col-1-7',
      groups: 'col-1-8',
      workspaces: 'col-1-8',
      status: 'col-1-10',
      select: 'col-1-18',
    };

    const samlColumnWidths = {
      email: 'col-1-3',
    };

    const renderPendingInvites = () => {
      if (!pendingInvites.length) {
        return (
          <div className="mt- font--h3 color--muted-dark">There are no pending invitations.</div>
        );
      } else if (!filteredPendingInvites.length) {
        return <div className="mt- font--h3 color--muted-dark">No invitations found.</div>;
      } else {
        return filteredPendingInvites.map(invite => {
          const {
            id: inviteId,
            email = '',
            orgRole = '',
            expirationDate,
            numGroups,
            numTeams,
            _links = {},
          } = invite;
          const { groups = {}, teams = {} } = _links;
          const isSelected =
            selectedPendingInvites.find(selectedInvite => selectedInvite.inviteId === inviteId) ||
            false;
          const quickView = quickViewsByInviteId[inviteId];

          const renderGroups = () => {
            if (orgRole === 'ADMIN' || orgRole === 'OWNER') {
              return <div>(All)</div>;
            } else if (numGroups) {
              return (
                <div
                  className="flex justify-content--space-between col-1-4 cursor--pointer"
                  onClick={() => this.props.toggleInviteQuickView(inviteId, 'GROUPS')}
                >
                  {numGroups}
                  <i
                    className={`sci sci__caret--${
                      quickView === 'GROUPS' ? 'down' : 'right'
                    } color--primary`}
                  />
                </div>
              );
            } else {
              return '(None)';
            }
          };

          const renderWorkspaces = () => {
            if (orgRole === 'ADMIN' || orgRole === 'OWNER') {
              return <div>(All)</div>;
            } else if (numTeams) {
              return (
                <div
                  className="flex justify-content--space-between col-1-4 cursor--pointer"
                  onClick={() => this.props.toggleInviteQuickView(inviteId, 'TEAMS')}
                >
                  {numTeams}
                  <i
                    className={`sci sci__caret--${
                      quickView === 'TEAMS' ? 'down' : 'right'
                    } color--primary`}
                  />
                </div>
              );
            } else {
              return '(None)';
            }
          };

          return (
            <div className="grid grid--narrower mt-" key={inviteId}>
              <div
                className={`grid__item flex align-items--center ${
                  isSamlOrg ? samlColumnWidths.email : columnWidth.email
                }`}
              >
                <div className="text--overflow">{email}</div>
              </div>
              <div
                className={`grid__item text--center ${
                  isSamlOrg ? samlColumnWidths.invitedAsOwner : columnWidth.invitedAsOwner
                }`}
              >
                {orgRole === 'OWNER' ? 'Yes' : '--'}
              </div>
              <div
                className={`grid__item text--center ${
                  isSamlOrg ? samlColumnWidths.invitedAsAdmin : columnWidth.invitedAsAdmin
                }`}
              >
                {orgRole === 'ADMIN' ? 'Yes' : '--'}
              </div>
              {showGroups && (
                <div
                  className={`grid__item flex align-items--center justify-content--center ${
                    isSamlOrg ? samlColumnWidths.groups : columnWidth.groups
                  }`}
                >
                  {renderGroups()}
                </div>
              )}
              <div
                className={`grid__item flex align-items--center justify-content--center ${
                  isSamlOrg ? samlColumnWidths.workspaces : columnWidth.workspaces
                }`}
              >
                {renderWorkspaces()}
              </div>
              {!isSamlOrg && (
                <div className={`grid__item text--center ${columnWidth.status}`}>
                  {moment().isAfter(moment(expirationDate)) ? 'Expired' : 'Pending'}
                </div>
              )}
              {!isSamlOrg && (
                <div className={`grid__item ${columnWidth.expires}`}>
                  {Helpers.formatDate(expirationDate)}
                </div>
              )}
              <div
                className={`grid__item ${columnWidth.select} flex justify-content--center align-items--center`}
              >
                <label className="label--checkbox flex justify-content--center mb0">
                  <input
                    type="checkbox"
                    name="pending-invites-option"
                    checked={isSelected}
                    onChange={() => this.handleUserSelection(inviteId)}
                  />
                  <span className="react--checkbox mr0" />
                </label>
              </div>
              <div
                className={`grid__item col-1-1 ${
                  quickView === 'GROUPS' ? 'is-showing--400' : 'is-hiding'
                }`}
              >
                {quickView === 'GROUPS' && (
                  <UserManagementQuickViewWrapper dataType="groups" href={groups.href}>
                    <InviteGroupsQuickView />
                  </UserManagementQuickViewWrapper>
                )}
              </div>
              <div
                className={`grid__item col-1-1 ${
                  quickView === 'TEAMS' ? 'is-showing--400' : 'is-hiding'
                }`}
              >
                {quickView === 'TEAMS' && (
                  <UserManagementQuickViewWrapper dataType="teams" href={teams.href}>
                    <InviteWorkspacesQuickView showGroups={showGroups} />
                  </UserManagementQuickViewWrapper>
                )}
              </div>
            </div>
          );
        });
      }
    };

    return (
      <div className="col-1-1">
        <div
          className="grid__item col-1-1 mt flex align-items--center cursor--pointer"
          onClick={() => this.toggleSection(expandableSections.PENDING_INVITES_SECTION)}
        >
          <div className="font--h6">PENDING INVITATIONS</div>
          <div className="ml-">
            <i
              className={`sci ${
                isExpanded ? 'sci__caret--down' : 'sci__caret--right'
              } color--primary`}
            />
          </div>
        </div>
        <ExpandableSection
          id={expandableSections.PENDING_INVITES_SECTION}
          isExpandedByDefault={false}
          showDefaultToggleControls={false}
          hideWhenCollapsed={true}
          className="col-1-1"
        >
          <div className="col-1-1 bo-v--1 pv mt- bg-color--white-light border-color--white-dark">
            <div className="grid__item col-1-1 mt-">
              <div className="bo--1 border-color--muted-dark flex justify-content--space-between p- align-items--center">
                <div className="flex col-3-4">
                  <div className="col-1-2">
                    <input
                      name="pending-invites-filter"
                      type="text"
                      className="control--text col-1-1"
                      placeholder="Filter by email or team"
                      onChange={e => this.handleFilterInput(e)}
                    />
                  </div>
                </div>
                <div className="">
                  <DropdownMenu
                    isOpen={!!openedDropdown['pending-invites-actions']}
                    close={() => this.delayCloseDropdown('pending-invites-actions')}
                    menuAlign="right"
                    textAlign="left"
                    toggle={
                      <button
                        className="btn--success p-"
                        onClick={() => this.openDropdown('pending-invites-actions')}
                      >
                        Actions
                      </button>
                    }
                  >
                    <li className="min-width--200 dropdown-menu--li">
                      <button className="pv--" onClick={() => this.showModal('INVITE_USERS')}>
                        Invite
                      </button>
                    </li>
                    <li className="separator mh-" />
                    {showGroups && (
                      <li className="min-width--200 dropdown-menu--li">
                        <button
                          className={`pv-- ${!selectedPendingInvites.length && 'disabled'}`}
                          disabled={!selectedPendingInvites.length}
                          onClick={() => this.showModal('ADD_INVITEES_TO_GROUP')}
                        >
                          Add to Team
                        </button>
                      </li>
                    )}
                    <li className="min-width--200 dropdown-menu--li">
                      <button
                        className={`pv-- ${!selectedPendingInvites.length && 'disabled'}`}
                        disabled={!selectedPendingInvites.length}
                        onClick={() => this.resendInvitations()}
                      >
                        Resend
                      </button>
                    </li>
                    <li className="min-width--200 dropdown-menu--li">
                      <button
                        className={`pv-- ${!selectedPendingInvites.length && 'disabled'}`}
                        disabled={!selectedPendingInvites.length}
                        onClick={() => this.showModal('REVOKE_INVITES')}
                      >
                        Revoke
                      </button>
                    </li>
                  </DropdownMenu>
                </div>
              </div>
            </div>
            <div className="grid__item col-1-1 mt">
              {/* pending invites list header */}
              <div className="grid grid--narrower bo--b-1 border-color--muted-light pb--">
                <div
                  className={`grid__item ${isSamlOrg ? samlColumnWidths.email : columnWidth.email}`}
                >
                  <strong>Email</strong>
                </div>
                <div
                  className={`grid__item text--center ${
                    isSamlOrg ? samlColumnWidths.invitedAsOwner : columnWidth.invitedAsOwner
                  }`}
                >
                  <strong>Invited as Org Owner</strong>
                </div>
                <div
                  className={`grid__item text--center ${
                    isSamlOrg ? samlColumnWidths.invitedAsAdmin : columnWidth.invitedAsAdmin
                  }`}
                >
                  <strong>Invited as Org Admin</strong>
                </div>
                {showGroups && (
                  <div
                    className={`grid__item text--center ${
                      isSamlOrg ? samlColumnWidths.groups : columnWidth.groups
                    }`}
                  >
                    <strong>Teams</strong>
                  </div>
                )}
                <div
                  className={`grid__item text--center ${
                    isSamlOrg ? samlColumnWidths.workspaces : columnWidth.workspaces
                  }`}
                >
                  <strong>Workspaces</strong>
                </div>
                {!isSamlOrg && (
                  <div className={`grid__item ${columnWidth.status} text--center`}>
                    <strong>Status</strong>
                  </div>
                )}
                {!isSamlOrg && (
                  <div className={`grid__item ${columnWidth.expires}`}>
                    <strong>Expires</strong>
                  </div>
                )}
                <div className={`grid__item ${columnWidth.select} text--center`}>
                  <label className="label--checkbox flex justify-content--center mb0">
                    <input
                      type="checkbox"
                      name="select-all-pending-invites"
                      checked={isSelectAll}
                      onChange={() => this.handleSelectAllPendingInvites(!isSelectAll)}
                      disabled={!pendingInvites.length}
                    />
                    <span className="react--checkbox mr0" />
                  </label>
                </div>
              </div>
            </div>
            <div className="grid__item col-1-1">{renderPendingInvites()}</div>
          </div>
        </ExpandableSection>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    expandableSectionState: state.expandableSectionState,
    orgState: state.orgState,
    orgUserState: state.orgUserState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    groupActions: bindActionCreators(groupActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
    orgUserInviteActions: bindActionCreators(orgUserInviteActions, dispatch),
    expandableSectionActions: bindActionCreators(expandableSectionActions, dispatch),
    toastrActions: bindActionCreators(toastrActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgUserPendingInvitesList);
