import produce from 'immer';
import * as actions from '~/actions/integration';
import { IntegrationState } from './integrationState.types';

const defaultState: IntegrationState = {
  data: {
    content: [],
    number: undefined,
    numberOfElements: undefined,
    size: undefined,
  },
  isFetching: false,
  errorMessage: null,
  deleteIntegrationRequest: {
    isDeleting: false,
    error: null,
  },
  jiraInfo: {},
};

const integrationState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.FETCH_INTEGRATIONS_REQUEST: {
        draft.isFetching = true;
        break;
      }

      case actions.FETCH_INTEGRATIONS_SUCCESS: {
        draft.data.number = action.response.page.number;
        draft.data.numberOfElements = action.response.page.totalElements;
        draft.data.size = action.response.page.size;

        // `_embedded` will only be in the response for Jira Cloud and OnPrem integrations.
        // Jira Legacy and Github integrations are handled differently
        // the `concat` payload is used when requesting subsequent pages from the `integrations` endpoint
        // and will append the response to the existing array in the store
        if (action.response._embedded?.integrations) {
          if (action.concat && state.data.number !== draft.data.number) {
            draft.data.content = [...state.data.content, ...action.response._embedded.integrations];
          } else {
            draft.data.content = action.response._embedded.integrations;
          }
        }

        draft.isFetching = false;
        draft.errorMessage = null;
        break;
      }

      case actions.FETCH_INTEGRATIONS_FAILURE: {
        draft.isFetching = false;
        draft.errorMessage = action.message;
        break;
      }

      case actions.DELETE_INTEGRATIONS_REQUEST: {
        draft.deleteIntegrationRequest.isDeleting = true;
        break;
      }

      case actions.DELETE_INTEGRATIONS_SUCCESS: {
        draft.deleteIntegrationRequest.isDeleting = false;
        break;
      }

      case actions.DELETE_INTEGRATIONS_FAILURE: {
        draft.deleteIntegrationRequest.isDeleting = false;
        draft.deleteIntegrationRequest.error = action.message;
        break;
      }

      case actions.JIRA_INFO_REQUEST: {
        draft.jiraInfo = {
          ...state.jiraInfo,
          [action.infoType]: {
            isFetching: true,
            err: null,
          },
        };
        break;
      }

      case actions.JIRA_INFO_SUCCESS: {
        draft.jiraInfo = {
          ...state.jiraInfo,
          [action.infoType]: {
            data: action.response,
            isFetching: false,
            err: null,
          },
        };
        break;
      }

      case actions.JIRA_INFO_FAILURE: {
        draft.jiraInfo = {
          ...state.jiraInfo,
          [action.infoType]: {
            isFetching: false,
            err: action.message,
          },
        };
        break;
      }
    }
  });
};

export default integrationState;
