import produce from 'immer';
import { VCNavState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import {
  FETCH_VC_NAV_REQUEST,
  FETCH_VC_NAV_SUCCESS,
  VCNavActionTypes,
} from '~/actions/vcAppActions/vcAppActionsTypes/types';

const defaultState: VCNavState = {
  isFetchingVcNav: false,
  navigation: {
    groups: [],
    footer: {
      activity_ip: '',
      activity_timestamp: '',
    },
    platform: '',
  },
};

const vcNavState = (state = defaultState, action: VCNavActionTypes) => {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_VC_NAV_REQUEST: {
        draft.isFetchingVcNav = true;
        break;
      }
      case FETCH_VC_NAV_SUCCESS: {
        draft.navigation = { ...action.navigation };
        draft.isFetchingVcNav = false;
      }
    }
  });
};

export default vcNavState;
