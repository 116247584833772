import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as groupActions from '~/actions/group';

import UpdateGroupDetails from '~/components/UpdateGroupDetails';

interface UpdateGroupDetailsModalProps {
  groupId: string;
  groupActions: object;
  groupState: object;
}
class UpdateGroupDetailsModal extends React.Component<UpdateGroupDetailsModalProps, {}> {
  componentDidMount() {
    const { groupState, groupId } = this.props;
    const { groups = [] } = groupState;
    const activeGroup = groups.find(group => group.id === parseInt(groupId)) || {};

    this.props.groupActions.setGroupDataToActiveGroup(activeGroup);
  }

  showModal(modalType) {
    this.props.groupActions.showModal(modalType);
  }

  updateGroupData(field, value) {
    this.props.groupActions.updateGroupData(field, value);
  }

  submitDetailsUpdate() {
    const { groupState, groupId } = this.props;
    const { groupData = {}, groups = [] } = groupState;
    const { name } = groupData;
    const isGroupNameConflict = groups.some(group => {
      return group.name.toLowerCase() === name.toLowerCase() && group.id !== parseInt(groupId);
    });

    if (name && isGroupNameConflict) {
      this.props.groupActions.updateGroupData('error', true);
    } else {
      this.props.groupActions.submitDetailsUpdate(groupData, groupId);
    }
  }

  render() {
    const { groupState } = this.props;
    const { groupData = {} } = groupState;

    return (
      <div>
        <UpdateGroupDetails
          groupData={groupData}
          showModal={modalType => this.showModal(modalType)}
          updateGroupData={(field, value) => this.updateGroupData(field, value)}
          submitDetailsUpdate={() => this.submitDetailsUpdate()}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupState: state.groupState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateGroupDetailsModal);
