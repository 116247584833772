import React from 'react';
import ColorHelper from '~/utils/ColorHelper';

interface SunburstChartHeaderProps {
  headers: any[];
  showColorForHeaders?: boolean;
  maxWidth?: number;
  minHeaderWidth?: number;
  headerHeight?: number;
  headerTipLength?: number;
  headerWidthPadding?: number;
  headerHeightPadding?: number;
  headerStrokeWidth?: number;
  headerFontSize?: number;
  unselectedColor?: string;
}
class SunburstChartHeader extends React.Component<SunburstChartHeaderProps, {}> {
  constructor(props, context) {
    super(props, context);

    const { headerFontSize = 11 } = this.props;

    this.canvasContext = document.createElement('canvas').getContext('2d');
    this.canvasContext.font = `${headerFontSize}px Roboto-light`;
  }

  getMaxTextWidth = headers => {
    if (headers.length == 0) {
      return 0;
    } else if (headers.length == 1) {
      return this.canvasContext.measureText(headers[0]).width;
    } else {
      return headers.reduce((prevVal, nextVal, currentIndex) => {
        const prevValWidth =
          currentIndex === 1 ? this.canvasContext.measureText(prevVal).width : prevVal;
        const nextValWidth = this.canvasContext.measureText(nextVal).width;
        return Math.max(prevValWidth, nextValWidth);
      });
    }
  };

  getColor = header => {
    const { showColorForHeaders = true, unselectedColor = '#808080' } = this.props;
    return showColorForHeaders ? ColorHelper.getBrightColor(header) : unselectedColor;
  };

  render() {
    const {
      headers = [],
      maxWidth = 750,
      minHeaderWidth = 100,
      headerTipLength = 10,
      headerHeight = 30,
      headerWidthPadding = 30,
      headerHeightPadding = 5,
      headerStrokeWidth = 2,
      headerFontSize = 11,
    } = this.props;

    const maxHeaderWidth = Math.max(
      this.getMaxTextWidth(headers) + headerWidthPadding + headerStrokeWidth,
      minHeaderWidth
    );
    const maxRowPerElement = Math.floor(maxWidth / (maxHeaderWidth + 5));
    const heightRequired =
      Math.ceil(headers.length / maxRowPerElement) * (headerHeight + headerHeightPadding);

    const headerList = headers.map((header, index) => {
      const headerXLocation = (index % maxRowPerElement) * (maxHeaderWidth + 5);
      const headerYLocation =
        Math.floor(index / maxRowPerElement) * (headerHeight + headerHeightPadding);

      return (
        <g transform={`translate(${headerXLocation},${headerYLocation})`} key={index}>
          <polygon
            style={{
              stroke: this.getColor(header),
              strokeWidth: `${headerStrokeWidth}px`,
              fill: 'white',
            }}
            points={`${headerStrokeWidth},
                    ${headerStrokeWidth} ${maxHeaderWidth},
                    ${headerStrokeWidth} ${maxHeaderWidth + headerTipLength - headerStrokeWidth},
                    ${headerHeight / 2} ${maxHeaderWidth},
                    ${headerHeight} ${headerStrokeWidth},
                    ${headerHeight}${index > 0 ? ` 10, 15` : ``}`}
          />

          <text
            style={{ fontSize: `${headerFontSize}px` }}
            x={`${(maxHeaderWidth + headerTipLength) / 2}`}
            y={`${headerHeight / 2}`}
            dy="0.35em"
            textAnchor="middle"
          >
            {header}
          </text>
        </g>
      );
    });

    return (
      <svg width={maxWidth} height={`${heightRequired}`}>
        {headerList}
      </svg>
    );
  }
}

export default SunburstChartHeader;
