import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Helpers from '~/utils/Helpers';

import LoaderWrapper from '~/components/LoaderWrapper';

import * as billingActions from '~/actions/billing';

interface PaymentConfirmationProps {
  billingActions: object;
  billingState: object;
  orgState: App.OrgState;
  onConfirm: (...args: any[]) => any;
  showModal: (...args: any[]) => any;
  isSubmittingPayment?: boolean;
}
class PaymentConfirmation extends React.Component<PaymentConfirmationProps, {}> {
  componentDidMount() {
    const { billingState, orgState } = this.props;
    const { selectedPlan = {} } = billingState;
    const { org = {} } = orgState;

    this.props.billingActions.fetchChangePlanDetails(org.id, selectedPlan.id);
  }

  showModal(modalType) {
    this.props.showModal(modalType);
  }

  confirmUpdate() {
    const { isSubmittingPayment } = this.props;

    if (!isSubmittingPayment) {
      this.props.onConfirm();
    }
  }

  render() {
    const { billingState, isSubmittingPayment } = this.props;
    const { selectedPlan = {}, changePlanDetails = {} } = billingState;
    const { scanLimit, priceInCents = 0 } = selectedPlan;
    const { amountInCents } = changePlanDetails;
    const customLoader = (
      <div className="flex align-items--center justify-content--center">
        <i className="fas fa-spinner fa-spin color--muted-dark mr-" />
        <div>Subscribing...</div>
      </div>
    );

    return (
      <div>
        <div className="">
          You are subscribing to the Business plan at{' '}
          <strong>
            {scanLimit ? scanLimit.toLocaleString() : 'unlimited'} scans / month for{' '}
            {Helpers.formatCost(priceInCents)} / Month
          </strong>
          . Changes will take effect immediately.
        </div>
        <div className="mt-">
          Billing cycles start on the first of each month. For this initial month, you will be
          charged a prorated amount. The amount due is{' '}
          <strong>{Helpers.formatCost(amountInCents)}</strong>.
        </div>
        <div className="mt-">Do you want to subscribe?</div>
        <div className="mt flex flex--justify-content--end">
          <button className="pv- col-1-5 mr-" type="button" onClick={() => this.showModal()}>
            Cancel
          </button>
          <button
            className="btn--primary-clear pv- col-2-5 mr-"
            type="button"
            disabled={isSubmittingPayment}
            onClick={() => this.confirmUpdate()}
          >
            <LoaderWrapper isLoaderShowing={isSubmittingPayment} customLoader={customLoader}>
              <div className="">Yes, subscribe</div>
            </LoaderWrapper>
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    billingState: state.billingState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    billingActions: bindActionCreators(billingActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentConfirmation);
