import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import _ from 'lodash';
import Select from 'react-select';

import * as MODEL from '~/constants/ModelConstants';

import JiraHelper from '~/utils/JiraHelper';

import SourceClearLoader from '~/components/SourceClearLoader';
import SourceClearModal from '~/components/SourceClearModal';
import Tooltip from '~/components/Tooltip';
import UserEditCheckbox from '~/containers/UserEditCheckbox';
import FieldDropDown from '~/containers/FieldDropDown';
import MultiFieldDropDown from '~/containers/MultiFieldDropDown';

import * as integrationActions from '~/actions/integration';
import * as integrationModalActions from '~/actions/integrationModal';
import { IntegrationState } from '~/reducers/integrationState.types';

const SUPPORTED_JIRA_KEYS = [
  'assignee',
  'reporter',
  'summary',
  'description',
  'priority',
  'fixVersions',
  'labels',
];

interface OrgIntegrationTicketModalProps extends RouteComponentProps {
  integrationActions: object;
  integrationState: IntegrationState;
  integrationModalActions: object;
  integrationModalState: object;
  navigationState: object;
  orgState: App.OrgState;
}
class OrgIntegrationTicketModal extends React.Component<OrgIntegrationTicketModalProps, {}> {
  componentDidUpdate(prevProps) {
    const { currentEditTemplate } = this.props.integrationModalState;
    const { [MODEL.JIRA_PROJECTS]: projectResponse = {} } = this.props.integrationState.jiraInfo;
    const { isFetching } = projectResponse;

    const { [MODEL.JIRA_PROJECTS]: prevProjectResponse = {} } = prevProps.integrationState.jiraInfo;
    const { isFetching: previsFetching } = prevProjectResponse;

    const projectKey = this.getSelectedProject().key;

    // fetch issue types, epics, statuses, fields, and users for edit ticket modal since project is already selected.
    if (previsFetching && !isFetching && !_.isEmpty(currentEditTemplate) && projectKey) {
      this.props.integrationActions
        .getJiraProjectIssueTypes(this.getIntegrationId(), projectKey)
        .then(issueType => {
          this.fetchOtherJIRAInfo(issueType);
        });
      this.props.integrationActions.getJiraEpics(this.getIntegrationId(), projectKey);
    }
  }

  onClose = () => {
    this.props.integrationModalActions.closeIntegrationModal();
  };

  getIntegrationId = () => {
    const { match = {} } = this.props;
    const { params = {} } = match;
    return params.integrationId;
  };

  getSelectedProject = () => {
    const { integrationState, integrationModalState } = this.props;
    const { fields } = integrationModalState;
    const { targetProjectVal = '' } = fields;
    const { jiraInfo = {} } = integrationState;
    const { [MODEL.JIRA_PROJECTS]: projectResponse = {} } = jiraInfo;

    const { data = [] } = projectResponse;

    return (
      data.filter(
        project => project.name === targetProjectVal || project.key === targetProjectVal
      )[0] || {}
    );
  };

  getSelectedProjectIssueType = (issueType?) => {
    const { integrationState, integrationModalState } = this.props;
    const { fields } = integrationModalState;

    const { jiraInfo = {} } = integrationState;
    const { [MODEL.JIRA_ISSUE_TYPES]: issueTypesResponse = {} } = jiraInfo;

    const { issueTypeVal } = fields;

    const { data = [] } = issueTypesResponse;

    return data.filter(issue => issue.name === issueTypeVal || issue.name === issueType)[0] || {};
  };

  isTicketRequiredFieldEmpty = () => {
    // Check if the target project, issue type and template name is given
    const { integrationModalState } = this.props;
    const { fields = {} } = integrationModalState;
    const { targetProjectVal, issueTypeVal, templateName } = fields;
    if (_.isEmpty(targetProjectVal) || _.isEmpty(issueTypeVal) || _.isEmpty(templateName)) {
      return true;
    }

    // Check to ensure that required fields's dropdown list have a value or at least user can edit
    const selectedIssueFields = this.getSelectedProjectIssueType().fields || {};
    for (let key in selectedIssueFields) {
      if (
        selectedIssueFields[key].required &&
        !['Issue Type', 'Project'].includes(selectedIssueFields[key].name)
      ) {
        if (_.isEmpty(fields[`jiraField-${key}`]) && !fields[`jiraField-${key}-userEdit`]) {
          return true;
        }
      }
    }
  };

  // Given fields with possible input, only care about field of jiraField-key and extract the key
  extractKeysFromFields = fields => {
    let fieldList = [];
    for (let key in fields) {
      const splittedKey = key.split('-');
      if (key.startsWith('jiraField-') && splittedKey.length === 2) {
        fieldList.push(splittedKey[1]);
      }
    }
    return fieldList;
  };

  updateFormInput = (fieldType, e) => {
    this.props.integrationModalActions.updateIntegrationModalField(fieldType, e.target.value);
  };

  onFormSubmit = e => {
    e.preventDefault();
    if (this.isTicketRequiredFieldEmpty()) {
      return;
    }

    const selectedProject = this.getSelectedProject();
    const selectedIssue = this.getSelectedProjectIssueType();

    const { integrationModalState } = this.props;
    const { fields = {}, openedModal, currentEditTemplate } = integrationModalState;
    const { resolvedStatusVal = '' } = fields;
    const fieldList = this.extractKeysFromFields(fields);
    const formattedField = fieldList.map(field => {
      return {
        canEdit: fields[`jiraField-${field}-userEdit`] || false,
        jiraFieldName: field,
        preDefinedValue: fields[`jiraField-${field}`],
      };
    });

    const options = {
      jiraProjectId: selectedProject.id,
      jiraProjectName: selectedProject.name,
      jiraIssueTypeId: selectedIssue.id,
      jiraIssueTypeName: selectedIssue.name,
      name: fields.templateName,
      resolvedStatus: resolvedStatusVal && resolvedStatusVal.length > 0 ? resolvedStatusVal : null,
      fields: formattedField,
    };
    if (openedModal[MODEL.TICKET_TEMPLATE_MODAL]) {
      this.props.integrationActions.createTicketTemplate(this.getIntegrationId(), options);
    } else {
      this.props.integrationActions.editTicketTemplate(
        this.getIntegrationId(),
        currentEditTemplate,
        options
      );
    }
  };

  isFieldSupported = (key, selectedIssueTypeField) => {
    return (
      selectedIssueTypeField[key].required ||
      SUPPORTED_JIRA_KEYS.includes(key) ||
      ['Epic Link'].includes(selectedIssueTypeField[key].name) ||
      selectedIssueTypeField[key].schema.custom === MODEL.JIRA_SCHEMA.MULTI_SELECT
    );
  };

  getSelectedJiraInfo() {
    const { integrationState, integrationModalState } = this.props;
    const {
      currentEditTemplate,
      fields: { issueTypeVal },
    } = integrationModalState;
    const { jiraInfo = {} } = integrationState;
    const {
      [MODEL.JIRA_PROJECTS]: jiraProjectResponse = {},
      [MODEL.JIRA_FIELDS]: fieldsResponse = {},
    } = jiraInfo;
    const {
      data: projectList = [],
      isFetching: jiraProjectIsFetching = false,
      err: jiraProjectErr,
    } = jiraProjectResponse;

    const { data: fields = {}, isFetching: isFetchingFields } = fieldsResponse;

    let selectedProject = {};

    if (projectList.length > 0) {
      selectedProject = projectList.filter(
        project => project.id === currentEditTemplate?.jiraProjectId?.toString()
      )[0];
    }

    return {
      selectedProject,
      selectedIssueType: currentEditTemplate?.jiraIssueTypeId?.toString() || issueTypeVal,
      fields,
      isFetchingFields,
      jiraProjectIsFetching,
      jiraProjectErr,
    };
  }

  // We are sending issueTypeVal at times because we need to call this function the moment user select issueType
  fetchOtherJIRAInfo = (selectedIssueType: object) => {
    const integrationId = this.getIntegrationId();
    const selectedProject = this.getSelectedProject();
    const {
      _links: {
        fields: { href: fieldsPath },
        statuses: { href: statusesPath },
      },
    } = selectedIssueType;

    const projectKey = selectedProject.key;

    if (!projectKey) {
      return;
    }

    const { integrationModalState = {} } = this.props;
    const { fields = {} } = integrationModalState;
    const {
      ['jiraField-assignee']: assigneeVal = '',
      ['jiraField-reporter']: reporterVal = '',
    } = fields;

    this.props.integrationActions.getJiraAssignees(integrationId, projectKey, assigneeVal);
    this.props.integrationActions.getJiraReporters(integrationId, projectKey, reporterVal);

    this.props.integrationActions.getJiraStatus(statusesPath);
    this.props.integrationActions.getJiraFields(fieldsPath);
  };

  getSelectedIssueTypesData(issueTypeName) {
    const {
      integrationState: { jiraInfo },
    } = this.props;
    const { [MODEL.JIRA_ISSUE_TYPES]: jiraIssueTypesResponse = {} } = jiraInfo;
    const { data: issueTypesData } = jiraIssueTypesResponse;
    return issueTypesData?.filter(issue => issue.name === issueTypeName)[0] || {};
  }

  onDropdownListSelected = (fieldType, item) => {
    this.props.integrationModalActions.updateIntegrationModalField(fieldType, item);
    // after project is selected, fetch issue types and epics
    if (fieldType === 'targetProjectVal') {
      this.props.integrationActions.getJiraProjectIssueTypes(this.getIntegrationId(), item);
      this.props.integrationActions.getJiraEpics(this.getIntegrationId(), item);
    }
    // after issue type is selected, fetch create issue metadata (initial 50 users (assignees/reporters), fields, statuses)
    if (fieldType === 'issueTypeVal') {
      // item at this point will be the issueType

      const selectedIssueType = this.getSelectedIssueTypesData(item);
      const { fields: selectedIssueTypeField = {} } = selectedIssueType;
      this.fetchOtherJIRAInfo(selectedIssueType);
      this.props.integrationModalActions.clearModalJiraField();

      // Set all value inside state with `jiraField-key` as null and can be edited by default
      for (let key in selectedIssueTypeField) {
        if (
          !['project', 'issuetype'].includes(key) &&
          this.isFieldSupported(key, selectedIssueTypeField)
        ) {
          this.props.integrationModalActions.updateIntegrationModalField(`jiraField-${key}`, null);
          this.props.integrationModalActions.updateIntegrationModalField(
            `jiraField-${key}-userEdit`,
            true
          );
        }

        if (['priority'].includes(key)) {
          const defaultPriorityVal = selectedIssueTypeField[key].defaultValue;
          if (Array.isArray(defaultPriorityVal) && defaultPriorityVal.length > 0)
            this.props.integrationModalActions.updateIntegrationModalField(
              `jiraField-${key}`,
              defaultPriorityVal[0].name
            );
        }
      }
    }
  };

  onDropdownListToggle = fieldType => {
    const { integrationModalState } = this.props;
    const { fields = {} } = integrationModalState;
    const currentOpenState = fields[fieldType];
    this.props.integrationModalActions.updateIntegrationModalField(fieldType, !currentOpenState);
  };

  getJiraReporters = () => {
    const { integrationState } = this.props;
    return JiraHelper.getJiraReportersFormatWithMetaData(integrationState);
  };

  getJiraAsignees = () => {
    const { integrationState } = this.props;
    return JiraHelper.getJiraAsigneesFormatWithMetaData(integrationState);
  };

  getJiraEpics = () => {
    const { integrationState } = this.props;
    return JiraHelper.getJiraEpicsFormatWithMetaData(integrationState);
  };

  getJiraStatus = () => {
    const { integrationState } = this.props;
    return JiraHelper.getJiraStatusFormatWithMetaData(integrationState);
  };

  // Return the list of labels available in user's JIRA instance
  getJiraLabels = () => {
    const { integrationState, integrationModalState } = this.props;
    const labelsInTemplate = JiraHelper.getJiraLabelFromTemplate(integrationModalState);
    const {
      val: labelsInInstance,
      isFetching = false,
    } = JiraHelper.getJiraLabelsFromInstanceWithMetaData(integrationState);

    return {
      val: _.union(labelsInInstance, labelsInTemplate),
      isFetching,
    };
  };

  getJiraPriority = () => {
    const { integrationState } = this.props;

    const { jiraInfo = {} } = integrationState;
    const { [MODEL.JIRA_FIELDS]: fieldsResponse = {} } = jiraInfo;
    const { data: fieldsData } = fieldsResponse;

    return fieldsData?.priority;
  };

  getFixVersionsDropdownVal = () => {
    const { integrationState } = this.props;

    const { jiraInfo = {} } = integrationState;
    const { [MODEL.JIRA_FIELDS]: fieldsResponse = {} } = jiraInfo;
    const { data: fieldsData } = fieldsResponse;
    return fieldsData?.fixVersions;
  };

  isLoadingDropdownVal = () => {
    return (
      this.getJiraEpics().isFetching ||
      this.getJiraReporters().isFetching ||
      this.getJiraAsignees().isFetching
    );
  };

  render() {
    const { integrationModalState, integrationState } = this.props;
    const { fields = {}, openedModal } = integrationModalState;
    const { jiraInfo = {} } = integrationState;
    const {
      [MODEL.JIRA_ISSUE_TYPES]: issueTypesResponse = {},
      [MODEL.JIRA_FIELDS]: fieldsResponse = {},
      [MODEL.JIRA_PROJECTS]: projectResponse = {},
      [MODEL.JIRA_LABELS]: labelResponse = {},
      [MODEL.CREATE_JIRA_TICKET_TEMPLATE]: createTemplateResponse = {},
      [MODEL.EDIT_TICKET_TEMPLATE_MODAL]: editTemplateResponse = {},
    } = jiraInfo;
    const { data = [], isFetching, err } = projectResponse;
    const { data: issueTypesData = [], isFetching: issueTypesAreFetching } = issueTypesResponse;
    const { data: fieldsData } = fieldsResponse;
    const { data: labelsData, isFetching: isFetchingLabels } = labelResponse;

    const formattedLabels = labelsData?.map(label => label.value);

    const { isFetching: isCreatingJiraTemplate = false } = createTemplateResponse;
    const { isFetching: isEditingJiraTemplate = false } = editTemplateResponse;

    // Dropdown val and open state for project list and issue type
    const {
      targetProjectVal = '',
      issueTypeVal = '',
      resolvedStatusVal = '',
      templateName = '',
    } = fields;

    const priorityDropdownVal = this.getJiraPriority();
    const fixVersionsDropdownVal = this.getFixVersionsDropdownVal();
    const { val: epicDropDownVal, isFetching: isFetchingEpics = false } = this.getJiraEpics();
    const {
      val: reportersDropdownVal,
      isFetching: isFetchingReporters = false,
    } = this.getJiraReporters();
    const {
      val: assigneesDropdownVal,
      isFetching: isFetchingAssignees = false,
    } = this.getJiraAsignees();

    const { val: statusDropdownVal, isFetching: isFetchingStatus = false } = this.getJiraStatus();

    const integrationId = this.getIntegrationId();

    const { selectedIssueType } = this.getSelectedJiraInfo();
    const projectKey = this.getSelectedProject().key;

    // Get selected issue
    const issueTypeForSelectedProject = issueTypesData;

    // Generating the field configurations fields
    let selectedIssueFields = [];
    if (fieldsData) {
      for (let key in fieldsData) {
        selectedIssueFields.push({ ...fieldsData[key], key });
      }
    }

    // Sort by required field first followed by alphabetical name order
    const sortedSelectedIssueFields = _.sortBy(selectedIssueFields, [
      o => !o.required,
      o => o.name.toUpperCase(),
    ]);

    const fieldsInput = sortedSelectedIssueFields
      .map(field => {
        if (!selectedIssueType) return;
        if (!['Issue Type', 'Project'].includes(field.name)) {
          const label = (
            <div className="grid__item col-1-5">
              {' '}
              {field.name || ''} {field.required && <span className="color--danger">** </span>}{' '}
            </div>
          );

          if (['assignee'].includes(field.key)) {
            const items = JiraHelper.formatUserDropdownList(assigneesDropdownVal);
            return (
              <div
                className="grid flex flex--align-items--center flex--justify-content--center pt-"
                key={field.key}
              >
                {label}
                <div className="grid__item">
                  <FieldDropDown
                    fieldName={field.key}
                    value={fields[`jiraField-${field.key}`]}
                    items={items}
                    name={field.name}
                    async={true}
                    isLoading={isFetchingAssignees}
                    asyncGetOptions={input =>
                      this.props.integrationActions.asyncGetJiraAssignees(
                        integrationId,
                        projectKey,
                        input
                      )
                    }
                  />
                </div>
                <div className="grid__item col-1-50" />
                <div className="grid__item col-1-5">
                  <UserEditCheckbox fieldName={field.key} />
                </div>
              </div>
            );
          } else if (['reporter'].includes(field.key)) {
            const items = JiraHelper.formatUserDropdownList(reportersDropdownVal);
            return (
              <div
                className="grid flex flex--align-items--center flex--justify-content--center pt-"
                key={field.key}
              >
                {label}
                <div className="grid__item">
                  <FieldDropDown
                    fieldName={field.key}
                    value={fields[`jiraField-${field.key}`]}
                    items={items}
                    name={field.name}
                    async={true}
                    isLoading={isFetchingReporters}
                    asyncGetOptions={input =>
                      this.props.integrationActions.asyncGetJiraReporters(
                        integrationId,
                        projectKey,
                        input
                      )
                    }
                  />
                </div>
                <div className="grid__item col-1-50" />
                <div className="grid__item col-1-5">
                  <UserEditCheckbox fieldName={field.key} />
                </div>
              </div>
            );
          } else if (['Epic Link'].includes(field.name)) {
            const items = JiraHelper.formatEpicDropdownList(epicDropDownVal);
            return (
              <div
                className="grid flex flex--align-items--center flex--justify-content--center pt-"
                key={field.key}
              >
                {label}
                <div className="grid__item">
                  <FieldDropDown
                    fieldName={field.key}
                    value={fields[`jiraField-${field.key}`]}
                    items={items}
                    name={field.name}
                    isLoading={isFetchingEpics}
                  />
                </div>
                <div className="grid__item col-1-50" />
                <div className="grid__item col-1-5">
                  <UserEditCheckbox fieldName={field.key} />
                </div>
              </div>
            );
          } else if (['labels'].includes(field.key)) {
            return (
              <div
                className="grid flex flex--align-items--center flex--justify-content--center pt-"
                key={field.key}
              >
                {label}
                <div className="grid__item">
                  <MultiFieldDropDown
                    promptTextCreator={'Create label'}
                    fieldName={field.key}
                    stringItems={formattedLabels}
                    name={field.name}
                    createable={true}
                    onNewOptionClick={option =>
                      this.props.integrationActions.addJiraLabels(option.value)
                    }
                    async={true}
                    isLoading={isFetchingLabels}
                    asyncGetOptions={input =>
                      this.props.integrationActions.getJiraLabels(integrationId, input)
                    }
                  />
                  {/* labels list will be empty at template creation time; populated from in-memory cache with selected labels at edit time */}
                  {labelsData?.length === 0 && 'Begin typing to find and create labels'}
                </div>
                <div className="grid__item col-1-50" />
                <div className="grid__item col-1-5">
                  <UserEditCheckbox fieldName={field.key} />
                </div>
              </div>
            );
          } else if (['summary'].includes(field.key)) {
            return (
              <div
                className="grid flex flex--align-items--center flex--justify-content--center pt-"
                key={field.key}
              >
                {label}
                <div className="grid__item">
                  <FieldDropDown
                    fieldName={field.key}
                    value={fields[`jiraField-${field.key}`]}
                    stringItems={['SourceClear Title']}
                    name={field.name}
                  />
                </div>
                <div className="grid__item col-1-50">
                  <Tooltip
                    id={`${field.key}-info`}
                    maxWidthClass="max-width--300"
                    content={`Select SourceClear Title to dynamically autopopulate this field upon issue creation`}
                  >
                    <i className="sci sci__detail-information" />
                  </Tooltip>
                </div>
                <div className="grid__item col-1-5">
                  <UserEditCheckbox fieldName={field.key} />
                </div>
              </div>
            );
          } else if (['description'].includes(field.key)) {
            return (
              <div
                className="grid flex flex--align-items--center flex--justify-content--center pt-"
                key={field.key}
              >
                {label}
                <div className="grid__item">
                  <FieldDropDown
                    fieldName={field.key}
                    value={fields[`jiraField-${field.key}`]}
                    stringItems={['SourceClear Description']}
                    name={field.name}
                  />
                </div>
                <div className="grid__item col-1-50">
                  <Tooltip
                    id={`${field.key}-info`}
                    maxWidthClass="max-width--300"
                    content={`Select SourceClear Description to dynamically autopopulate this field upon issue creation`}
                  >
                    <i className="sci sci__detail-information" />
                  </Tooltip>
                </div>
                <div className="grid__item col-1-5">
                  <UserEditCheckbox fieldName={field.key} />
                </div>
              </div>
            );
          } else if (['priority'].includes(field.key)) {
            const items = priorityDropdownVal.allowedValues
              ? JiraHelper.formatPriorityDropdownList(priorityDropdownVal.allowedValues)
              : [];
            return (
              <div
                className="grid flex flex--align-items--center flex--justify-content--center pt-"
                key={field.key}
              >
                {label}
                <div className="grid__item">
                  <FieldDropDown
                    fieldName={field.key}
                    value={fields[`jiraField-${field.key}`]}
                    items={items}
                    name={field.name}
                  />
                </div>
                <div className="grid__item col-1-50" />
                <div className="grid__item col-1-5">
                  <UserEditCheckbox fieldName={field.key} />
                </div>
              </div>
            );
          } else if (field.schema.type === 'string') {
            return (
              <div
                className="grid flex flex--align-items--center flex--justify-content--center pt-"
                key={field.key}
              >
                {label}
                <div className="grid__item">
                  {field.schema.custom && field.schema.custom === MODEL.JIRA_SCHEMA.TEXT_AREA ? (
                    <textarea
                      rows={5}
                      className="col-1-1 control--text ta-resize--none"
                      onChange={e =>
                        this.props.integrationModalActions.updateIntegrationModalField(
                          `jiraField-${field.key}`,
                          e.target.value
                        )
                      }
                      value={fields[`jiraField-${field.key}`]}
                    />
                  ) : (
                    <input
                      type="text"
                      className="col-1-1 control--text"
                      onChange={e =>
                        this.props.integrationModalActions.updateIntegrationModalField(
                          `jiraField-${field.key}`,
                          e.target.value
                        )
                      }
                      value={fields[`jiraField-${field.key}`]}
                    />
                  )}
                </div>
                <div className="grid__item col-1-50" />
                <div className="grid__item col-1-5">
                  <UserEditCheckbox fieldName={field.key} />
                </div>
              </div>
            );
          } else if (field.schema.type === 'number') {
            return (
              <div
                className="grid flex flex--align-items--center flex--justify-content--center pt-"
                key={field.key}
              >
                {label}
                <div className="grid__item">
                  <input
                    type="number"
                    className="col-1-1 control--text"
                    onChange={e =>
                      this.props.integrationModalActions.updateIntegrationModalField(
                        `jiraField-${field.key}`,
                        e.target.value
                      )
                    }
                    value={fields[`jiraField-${field.key}`]}
                  />
                </div>
                <div className="grid__item col-1-50" />
                <div className="grid__item col-1-5">
                  <UserEditCheckbox fieldName={field.key} />
                </div>
              </div>
            );
          } else if (field.schema.custom === MODEL.JIRA_SCHEMA.MULTI_SELECT) {
            const items = field.allowedValues
              ? JiraHelper.formatMultiSelectDropdownList(field.allowedValues)
              : [];

            return (
              <div
                className="grid flex flex--align-items--center flex--justify-content--center pt-"
                key={field.key}
              >
                {label}
                <div className="grid__item">
                  <MultiFieldDropDown fieldName={field.key} items={items} name={field.name} />
                </div>
                <div className="grid__item col-1-50" />
                <div className="grid__item col-1-5">
                  <UserEditCheckbox fieldName={field.key} />
                </div>
              </div>
            );
          } else if (['fixVersions'].includes(field.key)) {
            const items = fixVersionsDropdownVal.allowedValues
              ? JiraHelper.formatFixVersionDropdownList(fixVersionsDropdownVal.allowedValues)
              : [];

            return (
              <div
                className="grid flex flex--align-items--center flex--justify-content--center pt-"
                key={field.key}
              >
                {label}
                <div className="grid__item">
                  <MultiFieldDropDown fieldName={field.key} items={items} name={field.name} />
                </div>
                <div className="grid__item col-1-50" />
                <div className="grid__item col-1-5">
                  <UserEditCheckbox fieldName={field.key} />
                </div>
              </div>
            );
          } else if (field.required) {
            return (
              <div
                className="grid flex flex--align-items--center flex--justify-content--center pt-"
                key={field.key}
              >
                {label}
                <div className="grid__item col-4-5 color--danger">
                  We are sorry, we are not able to support this field. Please contact
                  support@veracode.com if you need this field support. In the meantime, please
                  select another issue type.
                </div>
              </div>
            );
          }
        }
      })
      .filter(item => item != undefined);

    const targetProjectList = data.map(project => ({
      value: project.key,
      label: project.name,
    }));

    const targetIssueList = issueTypeForSelectedProject
      .filter(issueType => ['epic', 'sub-task'].indexOf(issueType.name.toLowerCase()) < 0)
      .map(issueType => ({ value: issueType.name, label: issueType.name }));

    return (
      <SourceClearModal
        isOpen={
          !!openedModal[MODEL.TICKET_TEMPLATE_MODAL] ||
          !!openedModal[MODEL.EDIT_TICKET_TEMPLATE_MODAL]
        }
        title={
          openedModal[MODEL.TICKET_TEMPLATE_MODAL] ? 'Create Issue Template' : 'Edit Issue Template'
        }
        onClose={this.onClose}
        width={670}
      >
        {err && (
          <div className="color--danger">
            <span className="sci sci__alerts"> </span>
            <span className="pl-">{err}</span>
          </div>
        )}

        <div className={isFetching ? '' : 'invisible'}>
          <SourceClearLoader />
          <div className="font--h6 text--center"> Fetching your Jira project information... </div>
        </div>

        <form className={isFetching ? 'invisible' : ''} onSubmit={e => this.onFormSubmit(e)}>
          <div className="pv">
            Configure the fields that display when creating a new issue, and save them as a
            template. When a user creates a new issue, they will choose from the list of issue
            templates.
          </div>
          <div className="grid flex flex--align-items--center flex--justify-content--center">
            <div className="grid__item col-1-5">
              Target Project
              <span className="color--danger">*</span>
            </div>
            <div className="grid__item">
              <Select
                isClearable={false}
                name="jira-ticket-target-project"
                value={targetProjectList.find(
                  option => option.value === targetProjectVal || option.label === targetProjectVal
                )}
                options={targetProjectList}
                onChange={field => this.onDropdownListSelected('targetProjectVal', field.value)}
                placeholder={'Select a Jira project'}
                className={'srcclr-react-select-container'}
                classNamePrefix={'srcclr-react-select'}
              />
            </div>
          </div>
          {targetProjectVal && !issueTypesAreFetching && (
            <div className="grid flex flex--align-items--center flex--justify-content--center pt-">
              <div className="grid__item col-1-5">
                Issue Type
                <span className="color--danger">*</span>
              </div>
              <div className="grid__item">
                <Select
                  isClearable={false}
                  name="jira-ticket-issue-type"
                  value={targetIssueList.find(
                    option => option.value === issueTypeVal || option.label === issueTypeVal
                  )}
                  options={targetIssueList}
                  onChange={field => this.onDropdownListSelected('issueTypeVal', field.value)}
                  placeholder={'Select an issue type'}
                  className={'srcclr-react-select-container'}
                  classNamePrefix={'srcclr-react-select'}
                />
              </div>
            </div>
          )}
          {issueTypeVal && (
            <div className="grid flex flex--align-items--center flex--justify-content--center pt-">
              <div className="grid__item col-1-5"> Resolved Status </div>
              <div className="grid__item col-3-4">
                <Select
                  isClearable={true}
                  name="jira-ticket-resolved-status"
                  value={JiraHelper.formatStatusDropdownList(statusDropdownVal).find(
                    option => option.value === resolvedStatusVal
                  )}
                  options={JiraHelper.formatStatusDropdownList(statusDropdownVal)}
                  //clearable is true, so we have to do a null check
                  onChange={field =>
                    this.onDropdownListSelected('resolvedStatusVal', field ? field.value : '')
                  }
                  placeholder={'Select a status'}
                  isLoading={isFetchingStatus}
                  className={'srcclr-react-select-container'}
                  classNamePrefix={'srcclr-react-select'}
                />
              </div>
              <div className="grid__item min-width--col-2-50 -ml--">
                <Tooltip
                  id={`resolved-status`}
                  maxWidthClass="max-width--300"
                  content={`The status to which the corresponding Jira issue will be set when a SourceClear issue is resolved.`}
                >
                  <i className="sci sci__detail-information" />
                </Tooltip>
              </div>
              <div className="grid__item col-1-5" />
            </div>
          )}
          <div className="grid flex flex--align-items--center flex--justify-content--center pt- pb">
            <div className="grid__item col-1-5">
              Template Name
              <span className="color--danger">*</span>
            </div>
            <div className="grid__item">
              <input
                type="text"
                className="col-1-1 control--text"
                placeholder="Provide a name for this template"
                onChange={e => this.updateFormInput('templateName', e)}
                value={templateName}
              />
            </div>
          </div>

          <div className="grid flex flex--align-items--center flex--justify-content--center pb">
            <div className="grid__item col-1-1">
              <span className="color--danger">*</span> Required
            </div>
          </div>

          <div className="pt">
            <h5> FIELD CONFIGURATION </h5>
            {issueTypeVal && (
              <div>
                {' '}
                Some of your fields may not appear here. We currently support a limited set of Jira
                field types.
              </div>
            )}
            <div className="grid flex flex--align-items--center flex--justify-content--center pt-">
              <div className="grid__item col-1-5">
                <strong> Field </strong>{' '}
              </div>
              <div className="grid__item col-3-5 font--bold">
                <strong> Value </strong>{' '}
              </div>
              <div className="grid__item col-1-5 font--bold">
                <strong> User Can Edit </strong>{' '}
              </div>
            </div>
            <div className={`pt+ ${issueTypeVal ? 'invisible' : ''} min-height--300 text--center`}>
              To configure the fields for this issue template, first choose a project and then an
              issue type above.
            </div>
            <div className="overflow-y--scroll height--265 pb">{fieldsInput}</div>
            <div className={`pt ${issueTypeVal ? '' : 'invisible'}`}>
              <span className="color--danger">**</span> Required at the time of issue creation.
            </div>
          </div>

          {isCreatingJiraTemplate || isEditingJiraTemplate ? (
            <div className="pt ph text--center">
              <div> Saving issue template... </div>
              <SourceClearLoader />
            </div>
          ) : (
            <div className="pt+ pb text--right">
              <button className="ph" onClick={() => this.onClose()}>
                {' '}
                Cancel{' '}
              </button>
              <button
                type="submit"
                className={`btn--primary-clear ph ${
                  this.isTicketRequiredFieldEmpty() || this.isLoadingDropdownVal() ? 'disabled' : ''
                }`}
              >
                Save
              </button>
            </div>
          )}
        </form>
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationState: state.integrationState,
    integrationModalState: state.integrationModalState,
    navigationState: state.navigationState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
    integrationActions: bindActionCreators(integrationActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrgIntegrationTicketModal));
