import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import ApiConstants from '~/constants/ApiConstants';

export const RESET_SELECTED_GROUPS_IN_WORKSPACE = 'RESET_SELECTED_GROUPS_IN_WORKSPACE';
export const RESET_TEAM_GROUP_QUICK_VIEWS = 'RESET_TEAM_GROUP_QUICK_VIEWS';
export const SHOW_TEAM_GROUP_MODAL = 'SHOW_TEAM_GROUP_MODAL';
export const TOGGLE_WORKSPACE_GROUP_USERS_QUICK_VIEW = 'TOGGLE_WORKSPACE_GROUP_USERS_QUICK_VIEW';
export const UPDATE_SELECTED_GROUPS_IN_WORKSPACE = 'UPDATE_SELECTED_GROUPS_IN_WORKSPACE';
export const UPDATE_TEAM_GROUP_FILTER = 'UPDATE_TEAM_GROUP_FILTER';
export const UPDATE_TEAM_GROUPS = 'UPDATE_TEAM_GROUPS';

export const fetchTeamGroups = teamId => dispatch => {
  return ApiService.get(ApiConstants.fetchTeamGroupsURL(teamId))
    .then(res => {
      dispatch(updateTeamGroups(res));
      return res;
    })
    .catch(error => {
      ErrorService.capture('Error fetching team groups', error);
      return [];
    });
};

export const updateTeamGroups = groups => ({
  type: UPDATE_TEAM_GROUPS,
  groups,
});

export const showModal = modalType => ({
  type: SHOW_TEAM_GROUP_MODAL,
  modalType,
});

export const toggleWorkspaceGroupUsersQuickView = (groupId, column) => ({
  type: TOGGLE_WORKSPACE_GROUP_USERS_QUICK_VIEW,
  groupId,
  column,
});

export const resetTeamGroupQuickViews = () => ({
  type: RESET_TEAM_GROUP_QUICK_VIEWS,
});

export const updateTeamGroupsFilterInput = value => ({
  type: UPDATE_TEAM_GROUP_FILTER,
  value,
});

export const updateSelectedGroups = groupId => ({
  type: UPDATE_SELECTED_GROUPS_IN_WORKSPACE,
  groupId,
});

export const resetSelectedGroups = () => ({
  type: RESET_SELECTED_GROUPS_IN_WORKSPACE,
});
