import React, { useState } from 'react';
import Select from 'react-select';
import {PageSizeOptions, PageSizeSmallOptions} from '~/constants/ModelConstants';

interface PageSizeDropdownProps {
  onChange: (pageSizeValue: number) => void;
  value: number;
  isSmall?: boolean;
}

function PageSizeDropdown(props: PageSizeDropdownProps) {
  const [pageSize, setPageSize] = useState(props.value);

  const handleChange = (pageSize: { value: number; label: string }) => {
    setPageSize(pageSize.value);
    props.onChange(pageSize.value);
  };

  const pageSizeOptions = (props.isSmall ? PageSizeSmallOptions : PageSizeOptions);
  return (
    <div className="width--70 pl-">
      <Select
        options={pageSizeOptions}
        name="pageSize"
        value={pageSizeOptions.find(option => {
          return option.value === pageSize;
        })}
        isClearable={false}
        onChange={handleChange}
        className="srcclr-react-select-container zIndex-9--overlay"
        classNamePrefix="srcclr-react-select"
      />
    </div>
  );
}

export default PageSizeDropdown;
