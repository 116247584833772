import produce from 'immer';
import * as actions from '~/actions/toastr';

const defaultState = {
  toastrs: [],
};

const toastrState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.ADD_TOASTR: {
        draft.toastrs.push(action.options);
        break;
      }
      case actions.REMOVE_TOASTR: {
        const toastrIndex = state.toastrs.findIndex(toastr => toastr.id === action.options.id);
        draft.toastrs.splice(toastrIndex, 1);
        break;
      }
      case actions.EDIT_TOASTR: {
        draft.toastrs.forEach(toastr => {
          if (toastr.id === action.id) {
            for (const prop in action.options) {
              toastr[prop] = action.options[prop];
            }
          }
        });
        break;
      }
      case actions.CLEAR_TOASTRS:
        return defaultState;
    }
  });
};

export default toastrState;
