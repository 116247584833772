import React, { Fragment } from 'react';
import GenericError from '~/components/Errors/GenericError';

function SunsettedPlanPage() {
  const errorMessage = 'This link is invalid';
  const contactInfo =
    'Your plan is no longer supported. Contact Sales to upgrade to Enterprise and reactivate your account.';
  const customCtaMessage = (
    <Fragment>
      <a href="mailto:sales@veracode.com" className="link--obvious">
        Email Sales
      </a>{' '}
      or call 1-888-ZER-0DAY (937-0329)
    </Fragment>
  );

  return (
    <div className="container flex align-items--center justify-content--center height--75vh">
      <GenericError errorMessage={errorMessage} customCtaMessage={customCtaMessage}>
        {contactInfo}
      </GenericError>
    </div>
  );
}

export default SunsettedPlanPage;
