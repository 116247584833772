import React from 'react';
import { ENTERPRISE_UNLIMITED } from '~/constants/ModelConstants';

interface EnterpriseContactFormProps {
  showModal: (...args: any[]) => any;
  submitEnterpriseContactForm: (...args: any[]) => any;
}

const EnterpriseContactForm: React.FunctionComponent<EnterpriseContactFormProps> = props => {
  function submitEnterpriseContactForm(e) {
    e.preventDefault();
    const { firstName, lastName, email } = e.target;
    const params = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      productPlanId: ENTERPRISE_UNLIMITED,
    };

    props.submitEnterpriseContactForm(params);
  }

  return (
    <form className="grid" onSubmit={e => submitEnterpriseContactForm(e)}>
      <div className="grid__item col-1-1">
        To discuss enterprise plan options, please provide your contact information. A sales
        represetative will be in touch shortly.
      </div>
      <div className="grid__item col-1-1 mt-">
        <input
          type="text"
          className="col-1-1 control--text"
          name="firstName"
          placeholder="First name"
          required
        />
      </div>
      <div className="grid__item col-1-1 mt-">
        <input
          type="text"
          className="col-1-1 control--text"
          name="lastName"
          placeholder="Last name"
          required
        />
      </div>
      <div className="grid__item col-1-1 mt-">
        <input
          type="email"
          className="col-1-1 control--text"
          name="email"
          placeholder="Email"
          required
        />
      </div>
      <div className="grid__item col-1-1 mt flex justify-content--end">
        <button className="col-1-5 pv- mr-" type="button" onClick={() => props.showModal()}>
          Cancel
        </button>
        <button className="col-1-5 btn--success pv-" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default EnterpriseContactForm;
