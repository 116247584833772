import React from 'react';
import TokenControl from '~/containers/TokenControl';
import TravisScreenShot from '~/images/travis-screenshot.png';
import RegionHelpers from '~/utils/RegionHelpers';
import config from '~/config';

interface TravisConfigurationProps {
  teamId?: string;
  integrationType: string;
  renderAgentWorkspaceSelection?: (...args: any[]) => any;
}

const TravisConfiguration: React.FunctionComponent<TravisConfigurationProps> = props => {
  const { teamId, integrationType } = props;

  return (
    <div className="mb+">
      <div className="font--h6">Step one: Set environment variable</div>
      <p className="mt-">
        Select the repository you wish to scan from your Travis-CI environment {'>'} "More Options"{' '}
        {'>'}
        "Settings", then set the <code>SRCCLR_API_TOKEN</code> environment variable to the
        authentication token below{RegionHelpers.getRegionEnvVarInstruction(config.REGION)}.
      </p>
      <div className="mt-">
        <TokenControl teamId={teamId} integrationType={integrationType} />
      </div>
      <img className="col-1-1 mt-" src={TravisScreenShot} alt="Travis setup screenshot" />
      {props.renderAgentWorkspaceSelection()}

      <div className="font--h6 mt-">Step two: Configure Travis CI</div>
      <p>
        Add the following to your <code>.travis.yml</code> file:
      </p>
      <pre>
        addons:
        <br />
        {'  '}
        srcclr: true
      </pre>
      <div className="mt">
        For detailed instructions
        <a
          className="link--obvious link--icon-hover"
          href="https://help.veracode.com/go/t_sc_travis_token"
          target="_blank"
        >
          {' visit the docs.'}
          <i className="sci sci__arrow--external-link pl-- va-default" />
        </a>
      </div>
    </div>
  );
};

export default TravisConfiguration;
