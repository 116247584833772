import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as MODEL from '~/constants/ModelConstants';
import CopyToClipboard from '~/containers/CopyToClipboard';

import SourceClearModal from '~/components/SourceClearModal';
import SourceClearLoader from '~/components/SourceClearLoader';

import * as integrationModalActions from '~/actions/integrationModal';

interface Step2ModalProps {
  integrationModalActions: object;
  integrationModalState: object;
  orgState: App.OrgState;
  closeWhenClickOutside?: boolean;
}
class Step2Modal extends React.Component<Step2ModalProps, {}> {
  constructor(props, context) {
    super(props, context);
    this.createJiraIntegrationStep2.bind(this);
    this.isModalJiraPrem.bind(this);
  }

  onClose = () => {
    this.props.integrationModalActions.closeIntegrationModal();
  };

  returnToStep1 = () => {
    this.props.integrationModalActions.openIntegrationModal(MODEL.CREATE_JIRA_STEP_1);
  };

  isModalJiraPrem() {
    const { integrationModalState } = this.props;
    return integrationModalState.isModalJiraPrem;
  }

  createJiraIntegrationStep2() {
    const { integrationModalState } = this.props;

    const { jiraIntegration = {} } = integrationModalState;
    const { [MODEL.CREATE_JIRA_STEP_1]: step1Response = {} } = jiraIntegration;
    const { setupId } = step1Response.data || {};
    this.props.integrationModalActions.getJiraOAuthLink(setupId);
  }

  render() {
    const { orgState, integrationModalState, closeWhenClickOutside = false } = this.props;
    const { org = {} } = orgState;
    const { jiraIntegration = {} } = integrationModalState;
    const { [MODEL.CREATE_JIRA_STEP_1]: step1Response = {} } = jiraIntegration;

    const { consumerKey = '', publicKey = '', setupUrl = '', setupId = '' } = step1Response.data;
    const applicationUrl = `${window.location.protocol}//${window.location.host}`;
    const callbackUrl = `${window.location.protocol}//${window.location.host}/org/${org.id}/jira/${setupId}/callback`;

    const { [MODEL.CREATE_JIRA_STEP_2]: step2Response = {} } = jiraIntegration;
    const { isFetching = false } = step2Response;
    const { message: errMessage = '' } = step2Response.err || {};

    const lineBreak = (
      <div className="pv">
        <div className="bo-b--1 border-color--muted-light -mh+" />
      </div>
    );

    return (
      <SourceClearModal
        isOpen={true}
        title={
          this.isModalJiraPrem()
            ? `Create ${MODEL.INTEGRATION_NAME_MAP.JIRA_ONPREM} Integration (Step 4 of 5)`
            : 'Create Jira Integration (Step 2 of 3)'
        }
        onClose={this.onClose}
        width={600}
        minHeight={590}
        closeWhenClickOutside={closeWhenClickOutside}
      >
        <div className="pt-">
          <div className="flex flex--align-items--center flex--justify-content--center">
            <div className="col-1-5">1. Copy the URL</div>
            <div className="col-4-5">
              {' '}
              <CopyToClipboard value={applicationUrl} id="applicationUrl" />{' '}
            </div>
          </div>

          {lineBreak}

          <div className="flex flex--align-items--center flex--justify-content--center">
            <div className="col-1-1">
              2. Paste it in to the application link box in Jira by going to this page.
              <div className="mt pl--">
                <span className="pl--">
                  <a href={setupUrl} className="link--obvious" target="_blank">
                    {setupUrl}
                  </a>
                </span>
              </div>
            </div>
          </div>

          {lineBreak}

          <div className="flex flex--align-items--center flex--justify-content--center">
            <div className="col-1-1">
              3. In the <strong> Configure Application URL</strong> pop-up, click{' '}
              <strong> Continue</strong>. You may see an error saying "No response was received..."
              You can ignore this message if you see it.
            </div>
          </div>

          {lineBreak}

          <div className="flex flex--align-items--center flex--justify-content--center">
            <div className="col-1-1">
              4. In the <strong> Link applications</strong> pop-up, paste the following into{' '}
              <strong>Application Name</strong>. Leave the <strong>Application Type</strong> as{' '}
              <strong>Generic Application</strong> and leave the other fields blank. Click{' '}
              <strong>Continue</strong>.
              <div className="flex flex--align-items--center flex--justify-content--center pt-">
                <div className="col-1-5"> Application Name </div>
                <div className="col-4-5">
                  <CopyToClipboard value="SourceClear" id="jiraApplicationName" />{' '}
                </div>
              </div>
            </div>
          </div>

          {lineBreak}

          <div className="flex flex--align-items--center flex--justify-content--center">
            <div className="col-1-1">
              5. Edit the <strong>SourceClear</strong> application by clicking on{' '}
              <strong>Incoming Authentication</strong>.
            </div>
          </div>

          {lineBreak}

          <div className="flex flex--align-items--center flex--justify-content--center">
            <div className="col-1-1">
              6. Input the following items into their respective fields.
              <div className="flex flex--align-items--center flex--justify-content--center pt- pb--">
                <div className="col-1-5"> Consumer Key </div>
                <div className="col-4-5">
                  <CopyToClipboard value={consumerKey} id="jiraConsumerKey" />
                </div>
              </div>
              <div className="flex flex--align-items--center flex--justify-content--center pb--">
                <div className="col-1-5"> Consumer Name </div>
                <div className="col-4-5">
                  <CopyToClipboard value="SourceClear" id="jiraConsumerName" />
                </div>
              </div>
              <div className="flex flex--align-items--center flex--justify-content--center pb--">
                <div className="col-1-5"> Public Key </div>
                <div className="col-4-5">
                  <CopyToClipboard value={publicKey} rowCount={6} id="jiraPublicKey" />
                </div>
              </div>
              <div className="flex flex--align-items--center flex--justify-content--center pb--">
                <div className="col-1-5"> Callback URL </div>
                <div className="col-4-5">
                  <CopyToClipboard value={callbackUrl} id="jiraCallbackUrl" />
                </div>
              </div>
            </div>
          </div>

          {lineBreak}

          <div className="flex flex--align-items--center flex--justify-content--center">
            <div className="col-1-1">
              Click <strong> Next </strong> once you have completed these steps.
            </div>
          </div>
        </div>

        <div
          className={`bubble--inner bubble--error mv ${errMessage.length > 0 ? '' : 'invisible'}`}
        >
          Please check that all integration values are copied and set up correctly.
        </div>

        <div className="mt">
          {isFetching ? (
            <div>
              <div className="text--center font--h6"> Validating your Jira application link </div>
              <SourceClearLoader />
            </div>
          ) : (
            <div className="text--right">
              <button className="col-1-5 pv- mr-" onClick={() => this.onClose()}>
                Cancel
              </button>
              <button
                className="col-1-5 pv- mr- btn--primary-clear"
                onClick={() => this.returnToStep1()}
              >
                Back
              </button>
              <button
                className="col-1-5 pv- mr- btn--primary"
                onClick={() => this.createJiraIntegrationStep2()}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationModalState: state.integrationModalState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Step2Modal);
