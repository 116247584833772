import React from 'react';
import { Link } from 'react-router-dom';
import SourceClearLoader from '~/components/SourceClearLoader';

interface WorkspaceTeamUsersQuickViewProps {
  teamId?: string;
  data?: any[];
  showDetailsLink?: boolean;
  shouldShowVeracodePage?: boolean;
}

const WorkspaceTeamUsersQuickView: React.FunctionComponent<WorkspaceTeamUsersQuickViewProps> = props => {
  const { data: users = [], teamId, showDetailsLink, shouldShowVeracodePage = false } = props;
  const columnWidths = shouldShowVeracodePage
    ? {
        email: 'col-1-3',
        firstName: '',
        lastName: 'col-1-4',
        filler: 'col-1-18',
      }
    : {
        email: 'col-1-3',
        firstName: '',
        lastName: '',
        admin: 'col-1-8',
        filler: 'col-1-8',
      };
  // eslint-disable-next-line

  if (!users.length) {
    return (
      <div className="">
        <SourceClearLoader />
      </div>
    );
  }

  return (
    <div className="bo-v--1 border-color--muted mv-- pb-">
      <div className="col-1-1 bg-color--muted-light pv-- font--h7 pl- color--muted-dark">
        <strong>USERS</strong>
      </div>
      <div className="grid mt-">
        <div className={`grid__item ${columnWidths.email}`}>
          <strong>Email</strong>
        </div>
        <div className={`grid__item ${columnWidths.firstName}`}>
          <strong>First Name</strong>
        </div>
        <div className={`grid__item ${columnWidths.lastName}`}>
          <strong>Last Name</strong>
        </div>
        {!shouldShowVeracodePage && (
          <div className={`grid__item text--center ${columnWidths.admin}`}>
            <strong>Team Administrator</strong>
          </div>
        )}
        <div className={`grid__item ${columnWidths.filler}`} />
      </div>

      <div className="overflow--auto max-height--300">
        {users.map(userObj => {
          const { user = {}, admin } = userObj;
          const { id: userId, email, firstName, lastName } = user;
          return (
            <div className="grid mt--" key={`workspace-group-user-${userId}`}>
              <div className={`grid__item ${columnWidths.email}`}>
                {showDetailsLink ? (
                  <strong>
                    <Link
                      to={`/workspaces/${teamId}/user-management/users/${userId}`}
                      className="link--obvious"
                    >
                      {email}
                    </Link>
                  </strong>
                ) : (
                  <div>{email}</div>
                )}
              </div>
              <div className={`grid__item ${columnWidths.firstName}`}>{firstName || '--'}</div>
              <div className={`grid__item ${columnWidths.lastName}`}>{lastName || '--'}</div>
              {!shouldShowVeracodePage && (
                <div className={`grid__item text--center ${columnWidths.admin}`}>
                  {admin ? 'Yes' : '--'}
                </div>
              )}
              <div className={`grid__item ${columnWidths.filler}`} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkspaceTeamUsersQuickView;
