import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import Toastr from '~/components/Toastr';
import * as toastrActions from '~/actions/toastr';

interface SourceClearToastrSystemProps {
  toastrActions: object;
  toastrState: object;
}
class SourceClearToastrSystem extends React.Component<SourceClearToastrSystemProps, {}> {
  separateByPosition(toastrs) {
    const toastrsMap = {
      topLeft: [],
      topCenter: [],
      topRight: [],
      bottomLeft: [],
      bottomCenter: [],
      bottomRight: [],
    };

    toastrs.forEach(toastr => {
      if (toastr.position) {
        toastrsMap[toastr.position].push(toastr);
      } else {
        toastrsMap.topCenter.push(toastr);
      }
    });
    return toastrsMap;
  }

  removeToastr = options => {
    this.props.toastrActions.removeToastr(options);
  };

  render() {
    const { toastrState } = this.props;
    let { toastrs = [] } = toastrState;

    if (!toastrs.length) {
      return null;
    }

    toastrs = _.uniqBy(toastrs, 'id');
    const toastrsSeparatedByPosition = this.separateByPosition(toastrs);

    return (
      <div className="toastr--background">
        <div className="toastr__top--left">
          {toastrsSeparatedByPosition.topLeft.map(toastr => {
            return <Toastr key={toastr.id} options={toastr} removeToastr={this.removeToastr} />;
          })}
        </div>
        <div className="toastr__top--center">
          {toastrsSeparatedByPosition.topCenter.map(toastr => {
            return <Toastr key={toastr.id} options={toastr} removeToastr={this.removeToastr} />;
          })}
        </div>
        <div className="toastr__top--right">
          {toastrsSeparatedByPosition.topRight.map(toastr => {
            return <Toastr key={toastr.id} options={toastr} removeToastr={this.removeToastr} />;
          })}
        </div>
        <div className="toastr__bottom--left">
          {toastrsSeparatedByPosition.bottomLeft.map(toastr => {
            return <Toastr key={toastr.id} options={toastr} removeToastr={this.removeToastr} />;
          })}
        </div>
        <div className="toastr__bottom--center">
          {toastrsSeparatedByPosition.bottomCenter.map(toastr => {
            return <Toastr key={toastr.id} options={toastr} removeToastr={this.removeToastr} />;
          })}
        </div>
        <div className="toastr__bottom--right">
          {toastrsSeparatedByPosition.bottomRight.map(toastr => {
            return <Toastr key={toastr.id} options={toastr} removeToastr={this.removeToastr} />;
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    toastrState: state.toastrState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toastrActions: bindActionCreators(toastrActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceClearToastrSystem);
