import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';

import { getScopeForRequest } from '~/actions/reports';
import ApiConstants from '~/constants/ApiConstants';
import { Dispatch } from 'redux';
import Helpers from '~/utils/Helpers';

export const FETCHING_INSIGHTS = 'FETCHING_INSIGHTS';
export const UPDATE_INSIGHTS = 'UPDATE_INSIGHTS';

export const fetchIssueInsights = teamId => (dispatch: Dispatch, getState) => {
  const { scanAfterDate, scanBeforeDate } = getScopeForRequest(getState());
  dispatch(fetchingInsights());
  const options: Report.Scopes = {
    agents: '*',
    repos: '*',
    paths: '*',
  };

  if (Helpers.hasScanDateEnabled()) {
    options.scanAfterDate = scanAfterDate;
    options.scanBeforeDate = scanBeforeDate;
  }

  return ApiService.post(ApiConstants.insightsIssuesUrl(teamId), { data: options })
    .then(
      res => {
        dispatch(updateInsights(res, 'ISSUES'));
      },
      () => {
        dispatch(updateInsights({}, 'ISSUES'));
      }
    )
    .catch(error => {
      dispatch(updateInsights({}, 'ISSUES'));
      ErrorService.capture('Failed to fetch issue insights', error);
    });
};

export const fetchProjectInsights = (teamId, projectId) => (dispatch, getState) => {
  dispatch(fetchingInsights());
  // Get repo-specific scope. Otherwise use generic for all repos
  const options: Report.Scopes = projectId
    ? getScopeForRequest(getState())
    : {
        agents: '*',
        repos: '*',
        paths: '*',
      };

  return ApiService.post(ApiConstants.insightsProjectsUrl(teamId), { data: options })
    .then(
      res => {
        dispatch(updateInsights(res, 'PROJECT'));
      },
      () => {
        dispatch(updateInsights({}, 'PROJECT'));
      }
    )
    .catch(error => {
      dispatch(updateInsights({}, 'PROJECT'));
      ErrorService.capture('Failed to fetch issue insights', error);
    });
};

export const fetchVulnerabilityInsights = teamId => (dispatch, getState) => {
  const { scanAfterDate, scanBeforeDate } = getScopeForRequest(getState());
  dispatch(fetchingInsights());
  const options = {
    scope: {
      agents: '*',
      paths: '*',
      repos: '*',
      favoriteReposOnly: false,
      defaultBranchOnly: true,
    },
    filter: {
      vulnMethodsOnly: false,
      highSeverityOnly: false,
      search: '',
    },
  };

  if (Helpers.hasScanDateEnabled()) {
    options.scope.scanAfterDate = scanAfterDate;
    options.scope.scanBeforeDate = scanBeforeDate;
  }

  return ApiService.post(ApiConstants.insightsVulnerabilitiesUrl(teamId), { data: options })
    .then(
      res => {
        dispatch(updateInsights(res, 'VULNERABILITIES'));
      },
      () => {
        dispatch(updateInsights({}, 'VULNERABILITIES'));
      }
    )
    .catch(error => {
      dispatch(updateInsights({}, 'VULNERABILITIES'));
      ErrorService.capture('Failed to fetch vulnerability insights', error);
    });
};

export const updateInsights = (insights, issueType) => ({
  type: UPDATE_INSIGHTS,
  insights,
  issueType,
});

export const fetchingInsights = () => ({
  type: FETCHING_INSIGHTS,
});
