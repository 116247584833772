import React from 'react';

import CopyToClipboard from '~/containers/CopyToClipboard';
import TokenControl from '~/containers/TokenControl';
import { AgentState } from '~/reducers/agentState.types';

interface GradleInstallStepsProps {
  agentState: AgentState;
  teamId?: string;
  integrationType: string;
  renderAgentWorkspaceSelection?: (...args: any[]) => any;
}

const GradleInstallSteps: React.FunctionComponent<GradleInstallStepsProps> = props => {
  const { agentState, teamId, integrationType } = props;
  const { pluginToken = {}, agentReleases } = agentState;
  const { accessToken = {} } = pluginToken;
  const tokenIfItExists = accessToken.access_token || '<-- Copy Auth Token from above -->';
  const space = '  ';
  const gradlePluginVersion = agentReleases.GRADLE
    ? agentState.agentReleases.GRADLE.version
    : '<-- insert version -->';

  return (
    <div className="mb+">
      <div className="font--h6">Step one: Configure Gradle</div>
      <div className="mt-">
        <TokenControl teamId={teamId} integrationType={integrationType} />
      </div>
      <p className="mt-">
        Add the following to your <code>build.gradle</code> file using the token above:
      </p>
      <div className="col-1-1">
        <pre className="overflow-x--scroll">
          plugins &#123;
          <br />
          {space}
          id "com.srcclr.gradle" version "{gradlePluginVersion}"<br />
          &#125;
          <br />
          {space}
          <br />
          srcclr &#123;
          <br />
          {space}
          apiToken = "{tokenIfItExists}" // required field
          <br />
          &#125;
          <br />
        </pre>
      </div>
      {props.renderAgentWorkspaceSelection()}

      <div className="font--h6 mt-">Step two: Create a post build step</div>
      <p className="mt-">
        After saving your <code>build.gradle</code> file, add a post build step using the following
        command:
      </p>
      <div className="mt--">
        <CopyToClipboard value="./gradlew srcclr" id="gradlewSrcclrNew" />
      </div>
      <div className="mt">
        For detailed instructions or for help supporting Gradle versions 2.0.0 and earlier
        <a
          className="link--obvious link--icon-hover"
          href="https://help.veracode.com/go/t_sc_gradle"
          target="_blank"
        >
          {' visit the docs.'}
          <i className="sci sci__arrow--external-link pl-- va-default" />
        </a>
      </div>
    </div>
  );
};

export default GradleInstallSteps;
