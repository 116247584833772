import React from 'react';
import { Link } from 'react-router-dom';

interface UserGroupsQuickViewProps {
  data?: any[];
  showDetailsLink?: boolean;
}

const UserGroupsQuickView: React.FunctionComponent<UserGroupsQuickViewProps> = props => {
  const { data: groups = [], showDetailsLink } = props;
  const columnWidths = {
    name: 'col-1-4',
    description: 'col-2-5',
    admin: 'col-1-8',
  };

  return (
    <div className="bo-v--1 mv-- pb- border-color--muted">
      <div className="col-1-1 bg-color--muted-light pv-- font--h7 pl- color--muted-dark">
        <strong>TEAMS</strong>
      </div>
      <div className="grid mt-">
        <div className={`grid__item ${columnWidths.name}`}>
          <strong>Name</strong>
        </div>
        <div className={`grid__item ${columnWidths.description}`}>
          <strong>Description</strong>
        </div>
        <div className={`grid__item flex justify-content--center ${columnWidths.admin}`}>
          <strong>Team Administrator</strong>
        </div>
        <div className={`grid__item ${columnWidths.filler}`} />
      </div>

      <div className="max-height--300 overflow--auto">
        {groups.map(groupObj => {
          const { group = {}, admin } = groupObj;
          const { name, description, id: groupId } = group;

          return (
            <div className="grid mt--" key={`user-group-${groupId}`}>
              <div className={`grid__item ${columnWidths.name}`}>
                {showDetailsLink ? (
                  <strong>
                    <Link to={`/org/settings/teams/${groupId}`} className="link--obvious">
                      {name}
                    </Link>
                  </strong>
                ) : (
                  <div>{name}</div>
                )}
              </div>
              <div className={`grid__item ${columnWidths.description}`}>{description || '--'}</div>
              <div className={`grid__item flex justify-content--center ${columnWidths.admin}`}>
                {admin ? 'Yes' : '--'}
              </div>
              <div className={`grid__item ${columnWidths.filler}`} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserGroupsQuickView;
