import React from 'react';

import ReportDetailsPageTransitiveContent from '~/components/ReportComponents/ReportDetailsPageTransitiveContent';
import ReportDetailsPageDirectContent from '~/components/ReportComponents/ReportDetailsPageDirectContent';

interface ReportDetailsPageFixContentProps {
  data: object;
  dependencyMode: string;
  artifact: object;
  projectType?: string;
  registryLibraryUrl?: string;
}

const ReportDetailsPageFixContent: React.FunctionComponent<
  ReportDetailsPageFixContentProps
> = props => {
  const { data, dependencyMode, projectType, registryLibraryUrl } = props;
  const {
    component,
    fixText = '',
    fixVersion,
    fixLinks = [],
    languageType = '',
    safeVersion,
    componentId,
    coordinates,
    unsafeVersion,
    unsafeCommitHash,
    safeCommitHash,
  } = data;

  const filePath = data.filePath || '';

  const componentParts = component && component.length ? component.split(' ') : [];
  componentParts.pop();
  const componentName = componentParts.join(' ') || '';
  const coordParts = coordinates && coordinates.length ? coordinates.split(':') : [];
  const componentObj = {
    name: componentName,
    coordinate1: coordParts[0] || '',
    coordinate2: coordParts[1] || '',
    id: componentId,
  };

  const isTransitive = dependencyMode === 'TRANSITIVE';
  const isBoth = dependencyMode === 'BOTH';

  const howToFixContent = isTransitive ? (
    <ReportDetailsPageTransitiveContent
      component={componentObj}
      componentId={componentId}
      coordinates={coordinates}
      filePath={filePath}
      fixLinks={fixLinks}
      fixText={fixText}
      fixVersion={fixVersion}
      languageType={languageType}
      projectType={projectType}
      safeCommitHash={safeCommitHash}
      safeVersion={safeVersion}
      unsafeCommitHash={unsafeCommitHash}
      unsafeVersion={unsafeVersion}
      registryLibraryUrl={registryLibraryUrl}
    />
  ) : (
    <ReportDetailsPageDirectContent
      component={componentObj}
      componentId={componentId}
      coordinates={coordinates}
      filePath={filePath}
      fixLinks={fixLinks}
      fixText={fixText}
      fixVersion={fixVersion}
      isBoth={isBoth}
      languageType={languageType}
      projectType={projectType}
      safeCommitHash={safeCommitHash}
      safeVersion={safeVersion}
      unsafeCommitHash={unsafeCommitHash}
      unsafeVersion={unsafeVersion}
      registryLibraryUrl={registryLibraryUrl}
    />
  );

  return <div>{howToFixContent}</div>;
};

export default ReportDetailsPageFixContent;
