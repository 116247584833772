import produce from 'immer';
import Helpers from '~/utils/Helpers';
import { DEFAULT_WORKSPACE_SCAN_DATE } from '~/constants/ModelConstants';
import * as actions from '~/actions/reportScope';

const defaultState = {
  agents: '*',
  paths: '*',
  repos: '*',
  favoriteReposOnly: false,

  // scanAfterDate will build a range between that date, up to now()
  // scanBeforeDate will build a range between the beginning of time() and scanBeforeDate
  // both scanAfterDate & scanBeforeDate will make the proper range that you'd expect
  scanAfterDate: Helpers.getWorkspaceScanDate(DEFAULT_WORKSPACE_SCAN_DATE),
  scanBeforeDate: null,
};

const reportScope = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.UPDATE_REPORT_SCOPE:
        draft[action.field] = action.value;
    }
  });
};

export default reportScope;
