import * as actions from '~/actions/stats';

const defaultState = {
  isFetching: false,
  hasError: false,
  data: {},
};

const projectStats = (state = defaultState, action) => {
  switch (action.type) {
    case actions.RECEIVE_FETCH_PROJECT_STATS_SUCCESS:
      return {
        isFetching: false,
        hasError: false,
        data: action.stats,
      };
    case actions.REQUEST_FETCH_PROJECT_STATS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case actions.RECEIVE_FETCH_PROJECT_STATS_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true,
      };
    case actions.RESET_STATS_DATA:
      return defaultState;
    default:
      return state;
  }
};

export default projectStats;
