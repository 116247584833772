import _ from 'lodash';
import ngraph, { Graph, Node, NodeId } from 'ngraph.graph';
import { DependencyResponseGraph } from './UtilsTypes/types';

const SunburstHelper = function () {
  /*
    Given a node in a partition layout, return an array of all of its ancestor nodes, highest first, but excluding the root.
  */
  const getAncestorsIncludeSelf = node => {
    let path = [];
    let current = node;
    while (current.parent) {
      path.unshift(current);
      current = current.parent;
    }
    return path;
  };

  /*
    Given a node, iterate and get all the children
  */
  const getChildren = node => {
    // Base case, at leaf node
    if (!node.children) {
      return [];
    } else {
      return _.flatten(
        _.concat(
          node.children,
          node.children.map(child => getChildren(child))
        )
      );
    }
  };

  const doesNodePathExist = (nodeList, path) => {
    let doesPathExist = true,
      prevNode = nodeList.find(node => node.name === path[0]),
      currNode;

    if (prevNode) {
      for (let l = 1; l < path.length; l++) {
        currNode = nodeList.find(node => node.name === path[l]);

        if (!currNode || !prevNode.children.find(node => node.name === currNode.name)) {
          doesPathExist = false;
          break;
        }
        prevNode = currNode;
      }
    } else {
      doesPathExist = false;
    }

    return doesPathExist;
  };

  const buildHierarchy = (data, componentToHighlight) => {
    data = _.sortBy(data, [o => o.length]);

    let root = { name: 'root', children: [] };
    const nodes = [];

    //Set relevant area either 1/10 of the pie or 1.5 which is 50% bigger than the other slices.
    const highlightedSize = data.length > 10 ? data.length / 10 : 1.5;

    for (let i = 0; i < data.length; i++) {
      let parts = data[i];
      let currentNode = root;

      if (doesNodePathExist(nodes, parts)) {
        continue;
      }

      let sizeForPath = parts.indexOf(componentToHighlight) >= 0 ? highlightedSize : 1;

      for (let j = 0; j < parts.length; j++) {
        let children = currentNode['children'];
        let nodeName = parts[j];

        let childNode;

        if (j + 1 < parts.length) {
          // Not yet at the end of the sequence; move down the tree.
          let foundChild = false;
          for (let k = 0; k < children.length; k++) {
            if (children[k]['name'] == nodeName) {
              childNode = children[k];
              foundChild = true;
              break;
            }
          }
          // If we don't already have a child node for this branch, create it.
          if (!foundChild) {
            childNode = { name: nodeName, children: [] };
            nodes.push(childNode);
            children.push(childNode);
          }
          currentNode = childNode;
        } else {
          // Reached the end of the sequence; check if it already exist before adding
          let foundChild = false;
          for (let k = 0; k < children.length; k++) {
            if (children[k]['name'] == nodeName) {
              foundChild = true;
            }
          }
          if (!foundChild) {
            childNode = { name: nodeName, size: sizeForPath, children: [] };
            nodes.push(childNode);
            children.push(childNode);
          }
        }
      }
    }

    return root;
  };

  const formatFlatDepPathToArray = flatGraph => {
    return flatGraph.map(dependencyPath => {
      return dependencyPath.map(component => {
        return component.coordinate1 && component.version
          ? `${component.coordinate1}${component.coordinate2 ? ` ${component.coordinate2}` : ``} ${
              component.version
            }`
          : `${component.filePath}`;
      });
    });
  };

  const formatEdgeSetToArray = (artifacts, edgeSet): string[][] => {
    const graph = ngraph();
    // build the vertices of the graph
    artifacts.forEach(artifact => {
      graph.addNode(
        artifact.evidenceId,
        artifact.coord1 && artifact.version
          ? `${artifact.coord1}${artifact.coord2 ? ` ${artifact.coord2}` : ``} ${artifact.version}`
          : `${artifact.filePath}`
      );
    });
    // build the edges of the graph
    edgeSet.forEach(edge => {
      graph.addLink(edge.sourceId, edge.targetId);
    });
    const flats: string[][] = [];
    graph.forEachLinkedNode(
      findRootId(graph),
      node => {
        flats.push(...getAllPaths(graph, node));
      },
      true
    );
    // even though this is sorted again later, best to sort here because we don't actually want to sort meaningfully every time we render
    // and we render whenever the cursor moves in a general direction
    return flats.sort((here, that) => here.length - that.length);
  };

  const findRootId = (graph: Graph): NodeId => {
    const sourceIds: Set<NodeId> = new Set();
    const targetIds: Set<NodeId> = new Set();
    graph.forEachLink(edge => {
      sourceIds.add(edge.fromId);
      targetIds.add(edge.toId);
    });
    // there should only be 1 root
    return difference(sourceIds, targetIds).values().next().value;
  };

  function difference<T>(setA: Set<T>, setB: Set<T>): Set<T> {
    // copied from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set#Examples
    const _difference = new Set(setA);
    for (let elem of setB) {
      _difference.delete(elem);
    }
    return _difference;
  }

  const getAllPaths = (graph: Graph, direct: Node): string[][] => {
    return bfsPaths(graph, new Map([[direct.id, [direct.data]]]), new Set([direct.id]));
  };

  const bfsPaths = (
    graph: Graph,
    currentlayer: Map<NodeId, string[]>,
    seen: Set<NodeId>,
    donePaths: string[][] = []
  ): string[][] => {
    const nextLayer = new Map<NodeId, string[]>();
    currentlayer.forEach((path, parent) => {
      let hasChild = false;
      graph.forEachLinkedNode(
        parent,
        child => {
          // dedupe, do not repeat libraries
          if (!seen.has(child.id)) {
            hasChild = true;
            seen.add(child.id);
            // build the next layer for traversal
            nextLayer.set(child.id, [...path, child.data]);
          }
        },
        true
      );
      // childless nodes means end of path
      if (!hasChild) {
        donePaths.push(currentlayer.get(parent));
      }
    });
    // if there is an empty nextLayer, it means the traversal is done
    if (nextLayer.size === 0) {
      return donePaths;
    }
    return bfsPaths(graph, nextLayer, seen, donePaths);
  };

  const formatDepGraphRes = (graph: DependencyResponseGraph): string[][] => {
    if (graph.flat.length !== 0) {
      return formatFlatDepPathToArray(graph.flat);
    } else if (graph.edgeSet && graph.edgeSet.length !== 0) {
      return formatEdgeSetToArray(graph.full, graph.edgeSet);
    }
    return [];
  };

  /*
    Given a list of dependency path, find a path that has the component.
    Eg.
      flatArrayPath = [['A'], ['A','B','C']]
      component = 'B'
    returns ['A','B'];
  */
  const getDefaultDependencypath = (flatArrayPath, component) => {
    let componentToSearch = component;

    let hasDll = false;

    let path = flatArrayPath.find(graph => {
      const foundByFullPath = graph.indexOf(component) >= 0;

      if (!foundByFullPath) {
        if (doesAllItemComeFromJar(graph)) {
          // This happens when we do jar dependency and it could be libsrc/{coordinate2}.jar
          const compInfo = component.split(' ');
          const coordinate2 = compInfo[1];
          const version = compInfo[2];
          const foundComponent = graph.find(
          item => item.search(coordinate2) >= 0 && item.search(version) >= 0
          );
          componentToSearch = foundComponent !== undefined ? foundComponent : componentToSearch;
          return foundComponent !== undefined;
        } else if (doesAllItemComeFromDll(graph)) {
          // This happens when we do dll dependency and it could be subdir/*{coordinate1}*.dll
          hasDll = true;
          const compInfo = component.split(' ');
          const coordinate1 = compInfo[0];
          const foundComponent = graph.find(
            item => item.toLowerCase().search(coordinate1.toLowerCase()) >= 0
          );
          componentToSearch = foundComponent !== undefined ? foundComponent : componentToSearch;
          return foundComponent !== undefined;
        }
      }

      return foundByFullPath;
    });

    // if unable to find the exact graph path with version fallback to previous implementation
    if (!path) {
      path = flatArrayPath.find(graph => {
        const foundByFullPath = graph.indexOf(component) >= 0;

        // This happens when we do jar dependency and it could be libsrc/{coordinate2}.jar
        if (!foundByFullPath && doesAllItemComeFromJar(graph)) {
          const coordinate2 = component.split(' ')[1];
          const foundComponent = graph.find(item => item.search(coordinate2) >= 0);
          componentToSearch = foundComponent !== undefined ? foundComponent : componentToSearch;
          return foundComponent !== undefined;
        }

        return foundByFullPath;
      });
    }

    const ret = _.slice(path, 0, _.indexOf(path, componentToSearch) + 1);

    if (hasDll && ret.length === 0) {
      // We signal the failure to find a matching component in the graph by
      // showing a sunburst chart with all cells colored. DLLs hardly have
      // the same name as the Nuget it is matched with. So, if there were
      // some vulnerable DLLs, it is very likely the sunburst chart shows all
      // cells colored, which would be confusing. This returns a token to
      // indicate that we color no cell (all grey). Although even the DLL
      // itself will not be colored in the chart, this has a more consistent
      // look to the charts of non-DLL dependencies.
      return ['unmapped_nuget'];
    }

    return ret;
  };

  const doesAllItemComeFromExt = (graph, extension: string) => {
    let fromExt = true;
    graph.forEach(path => {
      if (!path.endsWith(extension)) {
        fromExt = false;
      }
    });
    return fromExt;
  };

  const doesAllItemComeFromJar = graph =>
    doesAllItemComeFromExt(graph, '.jar');

  const doesAllItemComeFromDll = graph =>
    doesAllItemComeFromExt(graph, '.dll');

  /*
    Checks if the selected path is a relevant path for the component.
  */
  const isComponentInSelectedPath = (flatArrayPath, component, selectedPath) => {
    return (
      flatArrayPath.filter(paths => {
        if (paths.includes(component)) {
          const pathUntilComponent = paths.slice(0, _.indexOf(paths, component) + 1);
          return _.intersection(pathUntilComponent, selectedPath).length === selectedPath.length;
        }
        return false;
      }).length > 0
    );
  };

  return {
    doesNodePathExist,
    buildHierarchy,
    getAncestorsIncludeSelf,
    getChildren,
    formatFlatDepPathToArray,
    formatEdgeSetToArray,
    formatDepGraphRes,
    getDefaultDependencypath,
    isComponentInSelectedPath,
  };
};

export default new SunburstHelper();
