import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import * as toastrActions from '~/actions/toastr';

export const SET_USER_NOTIFICATIONS_SETTINGS = 'SET_USER_NOTIFICATIONS_SETTINGS';
export const FETCH_NOTIFICATION_SETTINGS_REQUEST = 'FETCH_NOTIFICATION_SETTINGS_REQUEST';
export const FETCH_NOTIFICATION_SETTINGS_FAILURE = 'FETCH_NOTIFICATION_SETTINGS_FAILURE';
export const UPDATE_NOTIFICATION_SETTINGS_REQUEST = 'UPDATE_NOTIFICATION_SETTINGS_REQUEST';
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_NOTIFICATION_SETTINGS_SUCCESS';
export const UPDATE_NOTIFICATION_SETTINGS_FAILURE = 'UPDATE_NOTIFICATION_SETTINGS_FAILURE';

export const fetchNotificationSettingsRequest = () => ({
  type: FETCH_NOTIFICATION_SETTINGS_REQUEST,
});

export const fetchNotificationSettingsFailure = err => ({
  type: FETCH_NOTIFICATION_SETTINGS_FAILURE,
  message: err.message,
});

export const updateNotificationSettingsRequest = notificationType => ({
  type: UPDATE_NOTIFICATION_SETTINGS_REQUEST,
  notificationType,
});

export const updateNotificationSettingsSuccess = () => ({
  type: UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
});

export const updateNotificationSettingsFailure = err => ({
  type: UPDATE_NOTIFICATION_SETTINGS_FAILURE,
  message: err.message,
});

export const setUserNotificationSettings = settings => ({
  type: SET_USER_NOTIFICATIONS_SETTINGS,
  settings,
});

export const fetchNotificationsSettings = () => dispatch => {
  dispatch(fetchNotificationSettingsRequest());
  return ApiService.get('/v1/user/settings/notifications')
    .then(res => dispatch(setUserNotificationSettings(res)))
    .catch(error => {
      dispatch(
        toastrActions.addToastr({
          id: `FETCH_NOTIFICATION_SETTINGS_ERROR`,
          level: 'error',
          title: 'Error fetching settings',
          message:
            error.message ||
            `We ran into an error fetching your notification settings. Please try again.`,
          disableTimeout: true,
        })
      );
      dispatch(fetchNotificationSettingsFailure(error));
      ErrorService.capture('Error fetching notification settings', error);
    });
};

export const updateNotificationSettings = type => (dispatch, getState) => {
  dispatch(updateNotificationSettingsRequest(type));

  const { userNotifications = {} } = getState();
  const { settings = [] } = userNotifications;
  const toRemove = settings.filter(x => x.notificationType === type).length > 0;
  const apiCall = toRemove ? delNotificationSettings(type) : addNotificationSettings(type);

  apiCall
    .then(() => {
      dispatch(updateNotificationSettingsSuccess());
      dispatch(fetchNotificationsSettings());
    })
    .catch(error => {
      dispatch(
        toastrActions.addToastr({
          id: `UPDATE_NOTIFICATION_SETTINGS_ERROR`,
          level: 'error',
          title: 'Error updating settings',
          message:
            error.message ||
            `We ran into an error updating your notification settings. Please try again.`,
          disableTimeout: true,
        })
      );
      dispatch(updateNotificationSettingsFailure(error));
      ErrorService.capture('Error updating notification settings', error);
    });
};

export const addNotificationSettings = type => {
  return ApiService.post(`/v1/user/settings/notifications/${type.toUpperCase()}`);
};

export const delNotificationSettings = type => {
  return ApiService.del(`/v1/user/settings/notifications/${type.toUpperCase()}`);
};
