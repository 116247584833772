import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import ProjectSettingsBranchesPage from '~/containers/ProjectSettingsBranchesPage';
import ProjectSettingsNotificationsPage from '~/containers/ProjectSettingsNotificationsPage';
import ProjectSettingsNotificationsPaywallPage from '~/containers/ProjectSettingsNotificationsPaywallPage';
import ProjectSettingsLinkProjectToApplication from '~/containers/ProjectSettingsLinkProjectToApplication';
import ProjectSettingsOtherPage from '~/containers/ProjectSettingsOtherPage';
import ProIcon from '~/components/ProIcon';
import SourceClearModal from '~/components/SourceClearModal';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import Helpers from '~/utils/Helpers';

import * as projectSettingsActions from '~/actions/projectSettings';
import * as vcApplicationsActions from '~/actions/vcAppActions/vcApplicationActions';
import { PROJECT_SETTINGS_PAGES, PROJECT_TYPES } from '~/constants/ModelConstants';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';

const DEFAULT_PAGE = PROJECT_SETTINGS_PAGES.BRANCHES;

interface ProjectSettingsModalProps {
  projectId: string;
  projectData: object;
  orgState: App.OrgState;
  projectSettingsState: object;
  vcPageState: VCPageState;
  teamState: App.TeamState;
  teamId: string;
}
class ProjectSettingsModal extends Component<
  ProjectSettingsModalProps & ReturnType<typeof mapDispatchToProps>
> {
  componentWillUnmount() {
    this.resetModal();
  }

  onClose() {
    this.resetModal();
    this.props.vcApplicationsActions.resetVCApplications();
  }

  resetModal() {
    this.props.projectSettingsActions.closeProjectSettingsModal();
    this.props.projectSettingsActions.updateProjectSettingsPage(DEFAULT_PAGE);
  }

  updateProjectSettingsPage = page => {
    // if page selected is the AppLinking "page"
    //  we reset our current applications and update app link states
    // as we may want to rely on updated applications data each time
    // ProjectSettingsLinkProjectToApplication component is rendered inside this modal
    if (page === 'LINK_TO_APPLICATIONS') {
      this.props.vcApplicationsActions.resetUpdateLinkStates();
    }
    this.props.projectSettingsActions.updateProjectSettingsPage(page);
  };

  render() {
    const {
      projectSettingsState,
      projectId,
      orgState,
      projectData,
      vcPageState: { shouldShowVeracodePage },
      teamState,
      teamId,
    } = this.props;
    const { isModalOpen = false, page = '', stage } = projectSettingsState;
    const { org } = orgState;
    const { type: projectType = '' } = projectData;

    const isOrgPaidOrTrial = OrgPaidStatus.isOrgPaidOrTrial(org);
    const selectedLinkClass = 'text--bold ';

    const isContainerProject = projectType.toUpperCase() === PROJECT_TYPES.CONTAINER;
    const useLastScannedEnabled: boolean = Helpers.hasLatestScanEnabled();

    const activePage = !isContainerProject ? page : PROJECT_SETTINGS_PAGES.OTHER;

    const teams = teamState.teams.filter(team => team.id === teamId);
    const isMyWorkspace = teams.length > 0 ? !!teams[0].userId : false;

    const pageMap = {
      BRANCHES: (
        <div>
          <ProjectSettingsBranchesPage projectId={projectId} />
        </div>
      ),
      NOTIFICATIONS: (
        <div>
          <ProjectSettingsNotificationsPage projectId={projectId} stage={stage} />
        </div>
      ),
      NOTIFICATIONS_PAYWALL: <ProjectSettingsNotificationsPaywallPage />,
      LINK_TO_APPLICATIONS: (
        <div>
          <ProjectSettingsLinkProjectToApplication
            projectId={projectId}
            updateActivePage={() => this.updateProjectSettingsPage(PROJECT_SETTINGS_PAGES.BRANCHES)}
          />
        </div>
      ),
      OTHER: (
        <div>
          <ProjectSettingsOtherPage projectId={projectId} />
        </div>
      ),
    };

    return (
      <SourceClearModal
        isOpen={isModalOpen}
        title="Project Settings"
        onClose={() => this.onClose()}
        width={800}
        closeWhenClickOutside={false}
        minHeight={500}
      >
        <div className="grid">
          <div className="grid__item col-1-4">
            <div className="ph- pt" data-automation-id="ProjectSettingsModal-Nav">
              {!isContainerProject && (
                <div
                  data-automation-id="ProjectSettingsModal-Nav-Branch"
                  className={`${
                    page === PROJECT_SETTINGS_PAGES.BRANCHES ? selectedLinkClass : ''
                  }  cursor--pointer pb-`}
                  onClick={() => this.updateProjectSettingsPage(PROJECT_SETTINGS_PAGES.BRANCHES)}
                >
                  {useLastScannedEnabled ? 'Default Branch' : 'Branches'}
                </div>
              )}

              {!isContainerProject && !isOrgPaidOrTrial && (
                <div
                  data-automation-id="ProjectSettingsModal-Nav-Notifications"
                  className={`${
                    activePage === PROJECT_SETTINGS_PAGES.NOTIFICATIONS_PAYWALL
                      ? selectedLinkClass
                      : ''
                  } cursor--pointer pb-`}
                  onClick={() =>
                    this.updateProjectSettingsPage(PROJECT_SETTINGS_PAGES.NOTIFICATIONS_PAYWALL)
                  }
                >
                  <ProIcon /> Notifications
                </div>
              )}
              {!isContainerProject && isOrgPaidOrTrial && (
                <div
                  data-automation-id="ProjectSettingsModal-Nav-Notifications"
                  className={`${
                    activePage === PROJECT_SETTINGS_PAGES.NOTIFICATIONS ? selectedLinkClass : ''
                  } cursor--pointer pb-`}
                  onClick={() =>
                    this.updateProjectSettingsPage(PROJECT_SETTINGS_PAGES.NOTIFICATIONS)
                  }
                >
                  Notifications
                </div>
              )}
              {shouldShowVeracodePage && !isMyWorkspace && (
                <div
                  data-automation-id="ProjectSettingsModal-Nav-LinkToApp"
                  className={`${
                    activePage === PROJECT_SETTINGS_PAGES.LINK_TO_APPLICATIONS
                      ? selectedLinkClass
                      : ''
                  } cursor--pointer pb-`}
                  onClick={() =>
                    this.updateProjectSettingsPage(PROJECT_SETTINGS_PAGES.LINK_TO_APPLICATIONS)
                  }
                >
                  Link to Application
                </div>
              )}
              <div
                data-automation-id="ProjectSettingsModal-Nav-Other"
                className={`${
                  activePage === PROJECT_SETTINGS_PAGES.OTHER ? selectedLinkClass : ''
                } cursor--pointer pb-`}
                onClick={() => this.updateProjectSettingsPage(PROJECT_SETTINGS_PAGES.OTHER)}
              >
                Other
              </div>
            </div>
          </div>
          <div className="grid__item col-3-4">
            <div className="ph- pt" data-automation-id="ProjectSettingsModal-Content">
              {pageMap[activePage]}
            </div>
          </div>
        </div>
      </SourceClearModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgState: state.orgState,
    projectSettingsState: state.projectSettingsState,
    vcPageState: state.vcPageState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    projectSettingsActions: bindActionCreators(projectSettingsActions as any, dispatch),
    vcApplicationsActions: bindActionCreators(vcApplicationsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettingsModal);
