import React from 'react';
import { Link } from 'react-router-dom';
import ReportRowWrapper from '~/components/ReportComponents/ReportRowWrapper';
import { INTEGRATION_TYPE_MAPPING } from '~/constants/ModelConstants';

interface OrgIntegrationRowProps {
  integration: object;
  isSelected?: boolean;
  onRowSelectChange: (...args: any[]) => any;
  columnWidths: {
    [key: string]: string;
  };
  workspace: string;
  onGithubEditIntegrationSelect?: (...args: any[]) => any;
  onJIRAEnterpriseEditIntegrationSelect?: (...args: any[]) => any;
  isPaidOrTrialing?: boolean;
}

const OrgIntegrationRow: React.FunctionComponent<OrgIntegrationRowProps> = props => {
  const { integration, isSelected, columnWidths, workspace, isPaidOrTrialing } = props;
  const { name, type, id: integrationId, team = {} } = integration;
  function handleChange(integration) {
    props.onRowSelectChange(integration);
  }

  function onGithubEditIntegrationSelect(workspaceTeam) {
    props.onGithubEditIntegrationSelect(workspaceTeam);
  }

  function onJIRAEnterpriseEditIntegrationSelect(workspaceTeam) {
    props.onJIRAEnterpriseEditIntegrationSelect(workspaceTeam);
  }

  return (
    <ReportRowWrapper data-e2e="report-row">
      {isPaidOrTrialing ? (
        <div className={`grid__item ${columnWidths.name}`}>
          {(type === 'JIRA' || type === 'JIRA_PREM') && (
            <Link to={`/org/settings/integrations/jira/${integrationId}`} className="link--obvious">
              {name}
            </Link>
          )}
          {type === 'Github' && (
            <span className="link--obvious" onClick={() => onGithubEditIntegrationSelect(team)}>
              {' '}
              {name}{' '}
            </span>
          )}
          {type === 'JIRA Enterprise' && (
            <span
              className="link--obvious"
              onClick={() => onJIRAEnterpriseEditIntegrationSelect(team)}
            >
              {' '}
              {name}{' '}
            </span>
          )}
        </div>
      ) : (
        <div className={`grid__item ${columnWidths.name}`}>{name}</div>
      )}
      <div className={`grid__item ${columnWidths.type}`}>{INTEGRATION_TYPE_MAPPING[type]}</div>
      <div className={`grid__item ${columnWidths.workspace}`}>{workspace}</div>
      <div className="grid__item">
        <div className="flex flex--align-items--center flex--justify-content--center">
          <label className="label--checkbox flex mb0">
            <div className="flex">
              <input
                type="checkbox"
                name={`integration-${integrationId}`}
                id={integrationId}
                onChange={() => handleChange(integration)}
                checked={isSelected}
              />
              <span className={`react--checkbox mr0`} />
            </div>
          </label>
        </div>
      </div>
    </ReportRowWrapper>
  );
};

export default OrgIntegrationRow;
