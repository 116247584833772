import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';

interface PackageManagerPyPIProps {
  component: object;
  range?: object;
  safeVersion: string;
  transitive: boolean;
  unsafeVersion?: string;
  packageFile?: string;
  lineNumber?: string;
}

const PackageManagerPyPI: React.FunctionComponent<PackageManagerPyPIProps> = props => {
  const { component, range = {}, safeVersion, transitive = false, packageFile, lineNumber } = props;
  const { name } = component;
  const unsafeVersion =
    props.unsafeVersion ||
    (range.versionRange && range.versionRange.indexOf('-') !== -1
      ? range.versionRange.split('-')[0]
      : '');

  if (!transitive) {
    return (
      <div className="grid bo--1 border-color--white-dark mt bg-color--white lh+">
        <CodeDiffHeader text={packageFile} />
        <CodeDiffLine type="normal" text={'\u00B7\u00B7\u00B7'} />
        <CodeDiffLine
          type="delete"
          lineNumber={lineNumber}
          text={name + ' \u003D\u003D ' + unsafeVersion}
        />
        <CodeDiffLine type="add" text={name + ' \u003D\u003D ' + safeVersion} />
        <CodeDiffLine type="normal" text={'\u00B7\u00B7\u00B7'} />
      </div>
    );
  } else {
    return (
      <div className="grid bo--1 border-color--white-dark mt bg-color--white lh+">
        <CodeDiffHeader text="requirements.txt" />
        <CodeDiffLine type="add" text={'\u00B7\u00B7\u00B7'} />
        <CodeDiffLine type="add" text={name + ' \u003D\u003D ' + safeVersion} />
        <CodeDiffLine type="add" text={'\u00B7\u00B7\u00B7'} />
      </div>
    );
  }
};

export default PackageManagerPyPI;
