import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';

import * as MODEL from '~/constants/ModelConstants';
import * as signupActions from '~/actions/signup';
import OrgSignupPage from '~/containers/OrgSignupPage';
import SourceClearLoader from '~/components/SourceClearLoader';
import { RouteComponentProps } from 'react-router-dom';

interface SignupSplitterProps extends RouteComponentProps {
  signupActions: object;
  signupState: object;
}

class SignupSplitter extends React.Component<SignupSplitterProps, {}> {
  componentDidMount() {
    const { location } = this.props;
    const { search } = location;
    const query = queryString.parse(search) || {};
    const { email = '' } = query;

    if (email) {
      this.props.signupActions.trackSignupStep('landing', { string2: email });
    } else {
      window.location.assign(MODEL.veracodeScaDemoUrl);
    }
  }

  render() {
    const { location } = this.props;
    const { search } = location;
    const query = queryString.parse(search) || {};
    const { email = '' } = query;

    return email ? (
      <div className="col-1-1">
        <div className="container--full flex flex--content">
          <div className="flex flex--content justify-content--center">
            <div className="col-1-1">
              <OrgSignupPage location={location} />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <SourceClearLoader />
    );
  }
}

function mapStateToProps(state) {
  return {
    signupState: state.signupState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signupActions: bindActionCreators(signupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupSplitter);
