import React from 'react';
import Helmet from 'react-helmet';

const RULES_SET_BY_MESSAGE =
  "Rules are set by your organization's policy and can be viewed under the Policies menu.";
const CONTACT_ADMIN_MESSAGE =
  'Please contact your Veracode administrator if you do not have rights to this menu.';

const UnifiedOrgPoliciesPage = () => {
  return (
    <div className="mt">
      <Helmet>
        <title>Rules</title>
      </Helmet>
      <h3 data-automation-id="OrgPoliciesPage-Title">Rules</h3>
      <div className="grid grid--center grid--direction--column text--center min-height--300">
        <span className="text--bold mb font--18">{RULES_SET_BY_MESSAGE}</span>
        <span className="font--18">{CONTACT_ADMIN_MESSAGE}</span>
      </div>
    </div>
  );
};

export default UnifiedOrgPoliciesPage;
