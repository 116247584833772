import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';

interface NPMProps {
  packageInfo: object;
  isShrinkWrap?: boolean;
  filePath: string;
}

const NPM: React.FunctionComponent<NPMProps> = props => {
  const { name, version } = props.packageInfo;
  const { filePath = 'requirements.txt', isShrinkWrap = false } = props;

  //split package.json#L5
  const splittedSelectedFilePath = filePath.split('#L');
  const file = splittedSelectedFilePath[0];
  const lineNumber = splittedSelectedFilePath[1] || '';

  if (isShrinkWrap) {
    return (
      <div>
        <div className="grid bo--1 border-color--white-dark mt lh+">
          <CodeDiffHeader text={file} />
          <CodeDiffLine type="normal" text='"dependencies": {' />
          <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
          <CodeDiffLine type="normal" text={`\u2003\u2003"${name}": {`} />
          <CodeDiffLine
            type="highlight"
            lineNumber={lineNumber}
            text={`\u2003\u2003\u2003\u2003"version": "${version}"`}
          />
          <CodeDiffLine type="normal" text={`\u2003\u2003}`} />
          <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
          <CodeDiffLine type="normal" text={`}`} />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="grid bo--1 border-color--white-dark mt lh+">
          <CodeDiffHeader text={file} />
          <CodeDiffLine type="normal" text='"dependencies": {' />
          <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
          <CodeDiffLine
            type="highlight"
            lineNumber={lineNumber}
            text={'\u2003\u0022' + name + '\u0022: \u0022' + version + '\u0022,'}
          />
          <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
          <CodeDiffLine type="normal" text={`}`} />
        </div>
        <div className="pt">
          <span className="text--bold"> {name} </span> may have been declared as a range instead of{' '}
          <span className="text--bold"> {version} </span> in your{' '}
          <span className="text--bold"> {file} </span>
        </div>
      </div>
    );
  }
};

export default NPM;
