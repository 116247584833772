import React, { FunctionComponent } from 'react';
import ProjectRow from '~/views/containers/ProjectRow';

interface ProjectTableProps {
  repos: any[];
  teamId: string;
  columnWidths: {
    [key: string]: string;
  };
}

const ProjectTable: FunctionComponent<ProjectTableProps> = props => {
  const { repos, teamId, columnWidths } = props;

  const repoRows = repos.map((repo, index) => {
    const rowId = `${index}-${repo.name}-${repo.lastScanDate}`;
    return (
      <ProjectRow
        key={rowId}
        repo={repo}
        rowId={rowId}
        columnWidths={columnWidths}
        teamId={teamId}
      />
    );
  });

  return (
    <div>
      <div data-automation-id="ReportRows">{repoRows}</div>
      {repoRows.length === 0 && <h3 className="ml color--muted mt">No projects found.</h3>}
    </div>
  );
};

export default ProjectTable;
