import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Helpers from '~/utils/Helpers';

import * as orgUserInviteActions from '~/actions/orgUserInvite';
import * as orgUserActions from '~/actions/orgUser';
import * as toastrActions from '~/actions/toastr';
import * as groupActions from '~/actions/group';

import InviteUsersViaEmailModal from '~/containers/InviteUsersViaEmailModal';
import InviteMoreOptionsModal from '~/containers/InviteMoreOptionsModal';

interface OrgUserInviteModalProps {
  orgUserActions: object;
  orgUserInviteActions: object;
  toastrActions: object;
  groupActions: object;
  groupState: object;
  myState: object;
  orgUserInviteState: object;
  hideMoreOptionsTab?: boolean;
  groupId?: string;
  hideAddInviteesToGroupGroupSelection?: boolean;
  defaultMoreOptionsModal?: string;
  showModal: (...args: any[]) => any;
  showGroups?: boolean;
}
class OrgUserInviteModal extends React.Component<OrgUserInviteModalProps, {}> {
  sendInvites() {
    const { orgUserInviteState, groupState, groupId } = this.props;
    const { selectedGroups = {} } = groupState;
    const { invitees = [] } = orgUserInviteState;
    const selectedGroup = Object.keys(selectedGroups)[0];
    const inviteData = {
      users: invitees,
    };

    if (selectedGroup || groupId) {
      inviteData.groupId = parseInt(groupId || selectedGroup);
    }

    this.props.orgUserInviteActions.sendInvites(inviteData).then((res = {}) => {
      const { invites = [], users = [], success } = res;
      const inviteEmails = invites.map(invite => invite.email);
      const existingUsers = users.map(userObj => {
        // forward-compatibility for when the backend returns an array of flat user JSON instead of an array
        // of nested objects containing a "users" field. When the backend change goes live, this can be inlined
        // to simply the top-level user.
        const user = userObj.email ? userObj : userObj.user;
        return Helpers.formatUserName(user);
      });

      if (inviteEmails.length || existingUsers.length) {
        const successMessage = (
          <div className="mv-">
            {inviteEmails.length ? (
              <div>Invitations have successfully been sent to: {inviteEmails.join(', ')}</div>
            ) : (
              ''
            )}
            {existingUsers.length ? (
              <div className={inviteEmails.length && 'mt-'}>
                The following users already exist
                {selectedGroup ? ' and were added to the selected team' : ''}:{' '}
                {existingUsers.join(', ')}
              </div>
            ) : (
              ''
            )}
          </div>
        );
        this.props.toastrActions.addToastr({
          id: 'INVITE_SUCCESS',
          level: 'success',
          title: 'Invitations successfully sent',
          message: successMessage,
        });
      }

      if (success) {
        this.props.orgUserActions.fetchPendingInvites();
        this.props.groupActions.resetSelectedGroups();
        this.showModal();
      }
    });
  }

  showModal(modalType) {
    if (!modalType) {
      this.props.groupActions.resetSelectedGroups();
      this.props.orgUserInviteActions.resetInviteModal();
    }

    this.props.showModal(modalType);
  }

  render() {
    const {
      orgUserInviteState,
      hideMoreOptionsTab = false,
      defaultMoreOptionsModal,
      hideAddInviteesToGroupGroupSelection,
      showGroups,
      myState,
    } = this.props;
    const { me = {} } = myState;
    const { orgRoles = [] } = me;
    const isOrgAdminOrOwner = orgRoles.includes('OWNER') || orgRoles.includes('ADMIN');
    const { inviteStage } = orgUserInviteState;

    if (inviteStage === 'EMAIL_INPUT') {
      return (
        <InviteUsersViaEmailModal
          onClose={modalType => this.showModal(modalType)}
          sendInvites={() => this.sendInvites()}
        />
      );
    }

    if (inviteStage === 'MORE_OPTIONS') {
      return (
        <InviteMoreOptionsModal
          defaultModal={defaultMoreOptionsModal}
          hideTabs={hideMoreOptionsTab || !isOrgAdminOrOwner}
          hideAddInviteesToGroupGroupSelection={hideAddInviteesToGroupGroupSelection}
          onClose={modalType => this.showModal(modalType)}
          sendInvites={() => this.sendInvites()}
          showGroups={showGroups}
          isOrgAdminOrOwner={isOrgAdminOrOwner}
        />
      );
    }

    return <span />;
  }
}

function mapStateToProps(state) {
  return {
    groupState: state.groupState,
    myState: state.myState,
    orgUserInviteState: state.orgUserInviteState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
    orgUserInviteActions: bindActionCreators(orgUserInviteActions, dispatch),
    toastrActions: bindActionCreators(toastrActions, dispatch),
    groupActions: bindActionCreators(groupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgUserInviteModal);
