import produce from 'immer';
import {
  UPDATE_COMMITTERS_BY_ORG_ID,
  UPDATE_COMMITTERS_BY_TEAM_ID,
  UPDATE_USAGE_FILTER,
  UPDATE_USAGE_SORT_ASCENDING,
  UPDATE_USAGE_SORT_COLUMN,
  REQUEST_COMMITTERS,
  RESET_USAGE_STATE,
  FETCH_SCAN_USAGE_SUCCESS,
  UsageActionTypes,
} from '~/actions/usage.types';
import { UsageState } from '~/reducers/usageState.types';

export const defaultState: UsageState = {
  scanUsage: [],
  committersByOrgId: {},
  committersByTeamId: {},
  usageSortColumn: 'name',
  usageSortAscending: true,
  usageFilter: '',
  isLoadingCommitters: false,
};

const usageState = (state = defaultState, action: UsageActionTypes) => {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_COMMITTERS_BY_ORG_ID:
        draft.committersByOrgId[action.orgId] = action.committers;
        draft.isLoadingCommitters = false;
        break;
      case UPDATE_COMMITTERS_BY_TEAM_ID:
        draft.committersByTeamId[action.teamId] = action.committers;
        draft.isLoadingCommitters = false;
        break;
      case UPDATE_USAGE_SORT_COLUMN:
        draft.usageSortColumn = action.column;
        break;
      case UPDATE_USAGE_SORT_ASCENDING:
        draft.usageSortAscending = action.bool;
        break;
      case UPDATE_USAGE_FILTER:
        draft.usageFilter = action.filter;
        break;
      case REQUEST_COMMITTERS:
        draft.isLoadingCommitters = true;
        break;
      case RESET_USAGE_STATE:
        return defaultState;
      case FETCH_SCAN_USAGE_SUCCESS:
        draft.scanUsage = action.scanUsage;
    }
  });
};

export default usageState;
