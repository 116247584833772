import React from 'react';

interface GenericErrorProps {
  errorMessage: string;
  ctaLinkUrl?: string;
  ctaLinkText?: string;
  errorId?: string;
  customCtaMessage?: JSX.Element;
  renderIcon?: () => React.ReactNode;
}

const GenericError: React.FunctionComponent<GenericErrorProps> = props => {
  const {
    errorMessage,
    ctaLinkUrl,
    ctaLinkText,
    customCtaMessage,
    errorId,
    renderIcon = () => <i className="error-page--icon sci sci__alerts" />,
  } = props;

  const callToAction = customCtaMessage || (
    <a href={`${ctaLinkUrl}`} className="link--obvious">
      {ctaLinkText}
    </a>
  );

  return (
    <div className="grid grid--middle col-2-5">
      <div className="flex align-items--center">
        {renderIcon()}

        <div className="ml+">
          <div className="font--h3">
            <strong>{errorMessage}</strong>
          </div>
          <div className="font--h6 mv--">{props.children}</div>
          <pre>{errorId}</pre>
          {callToAction}
        </div>
      </div>
    </div>
  );
};

export default GenericError;
