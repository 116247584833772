import { connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import * as vcDropdownActions from '~/actions/vcAppActions/vcDropdownActions';
import { VCDropdown } from '~/views/components/veracode/VCDropdown';

function mapStateToProps(state: any) {
  return {
    vcDropdownState: state.vcDropdownState,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return bindActionCreators(
    {
      toggleOpen: vcDropdownActions.handleOpen,
      toggleClose: vcDropdownActions.handleClose,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VCDropdown);
