import * as actions from '~/actions/unmatchedLibraries';

const defaultState = { matched_unmatched_views: { value: 'MATCHED', label: 'Matched Libraries' } };

const reportFilterState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_MATCH_UNMATCH_DROPDOWN:
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

export default reportFilterState;
