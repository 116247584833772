import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import OrgPaidStatus from '~/utils/OrgPaidStatus';
import BrowserDetection from '~/utils/BrowserDetection';
import ProIcon from '~/components/ProIcon';
import PopoverWithEscKey from '~/components/PopoverWithEscKey';
import Tooltip from '~/components/Tooltip';

import * as popoverActions from '~/actions/popover';
import * as upgradeModalActions from '~/actions/upgradeModal';
import Helpers from '~/utils/Helpers';
import * as MODEL from '~/constants/ModelConstants';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';

interface CSVDownloaderProps {
  vcPageState: VCPageState;
  navigationState: object;
  popoverActions: object;
  popoverState: object;
  orgState: App.OrgState;
  page: object;
  noElementText: string;
  upgradeModalActions: object;
  popOverId: string;
  tooltipId: string;
  onUpgradeCTA: (...args: any[]) => any;
  onDownload: (...args: any[]) => any;
  isFetching?: boolean;
  errorMessage?: string;
}
class CSVDownloader extends React.Component<CSVDownloaderProps, {}> {
  componentWillUnmount() {
    this.toggleCSVClosed();
  }

  toggleCSVOpen = () => {
    const popOverId = this.props.popOverId;
    this.props.popoverActions.openPopover(popOverId);
  };

  toggleCSVClosed = () => {
    const popOverId = this.props.popOverId;
    const { popoverState } = this.props;
    const isOpen = popoverState[popOverId];
    isOpen ? this.props.popoverActions.closePopover(popOverId) : _.noop();
  };

  toggleCtaModalOpen = location => {
    const { navigationState } = this.props;
    const { activeTeamParent: teamId } = navigationState;
    this.props.upgradeModalActions.showUpgradeModal(
      MODEL.UPGRADE_MODAL_DOWNLOAD_CSV,
      teamId,
      location
    );
  };

  showUpgradeCTA = location => {
    this.toggleCSVClosed();
    this.toggleCtaModalOpen(location);
    this.props.onUpgradeCTA();
  };

  downloadCSV = () => {
    this.toggleCSVClosed();
    this.props.onDownload();
  };

  render() {
    const {
      popoverState,
      page = {},
      noElementText,
      tooltipId,
      popOverId: csvPopOverId,
      orgState,
      workspaceScanDateState: { scanDate },
      navigationState: { activeReportType },
      vcPageState,
    } = this.props;
    const { org } = orgState;

    const { shouldShowVeracodePage } = vcPageState;

    const isPaidOrTrialing = OrgPaidStatus.isOrgPaidOrTrial(org);

    const isCsvPopOverOpen = popoverState[csvPopOverId] || false;
    const isFetchingCSV = this.props.isFetching || false;
    const hasError = !!this.props.errorMessage || false;

    const content = (
      <div
        data-automation-id="CSVDownloader-Content"
        className="cursor--pointer text--center link--obvious hover--no-underline"
        onClick={
          isPaidOrTrialing ? this.downloadCSV : () => this.showUpgradeCTA(`${csvPopOverId}_BTN`)
        }
      >
        {!isPaidOrTrialing && <ProIcon />}
        <span className="pl---">
          {' '}
          Export to CSV{' '}
          {BrowserDetection.isSafari && isPaidOrTrialing ? (
            <span className="alert-warning p- bo-rad--3 text--left mt-- block">
              The data will load in this page.{' '}
              <span className="text--bold">Right-click &gt; Save page as</span>
            </span>
          ) : (
            ''
          )}
        </span>
      </div>
    );

    if (!(page.totalElements && page.totalElements > 0) && isPaidOrTrialing) {
      return (
        <div data-automation-id="CSVDownloader">
          <Tooltip id={tooltipId} content={noElementText}>
            <i className="sci sci__data--download ph- color--muted font--16" />
          </Tooltip>
        </div>
      );
    }
    // disable CSV downloader when Any Date or More than 7 days scanDate is selected, except
    // when the CSV downloader is displayed on the Projects list page
    if (Helpers.hasScanDateEnabled() && activeReportType !== 'REPOSITORIES' && !shouldShowVeracodePage) {
      if (
        scanDate === MODEL.WORKSPACE_SCAN_DATE_ALL_DATES ||
        scanDate === MODEL.WORKSPACE_SCAN_DATE_MORE_THAN_7_DAYS
      ) {
        return (
          <div data-automation-id="CSVDownloader">
            <Tooltip
              id={tooltipId}
              place="left"
              content={
                <div className="max-width--200">
                  Export is not available for this scan date range. The complete data may be
                  accessed via Analytics.
                </div>
              }
            >
              <i className="sci sci__data--download ph- color--muted font--16" />
            </Tooltip>
          </div>
        );
      }
    }

    if (isFetchingCSV) {
      return (
        <div data-automation-id="CSVDownloader">
          <i className="fa sci--sm fa-spin fa-spinner mh- color--primary font--16 ph0" />
        </div>
      );
    }

    return (
      <div data-automation-id="CSVDownloader">
        <PopoverWithEscKey
          className="zIndex-9--dialog width--150"
          isOpen={isCsvPopOverOpen}
          body={content}
          place="bottom"
          onOuterAction={this.toggleCSVClosed}
        >
          {hasError ? (
            <Tooltip
              content={`Error downloading csv. Please click to retry`}
              id="csv-download-error"
            >
              <i
                className="sci sci__alerts color--danger cursor--pointer ph- font--16"
                onClick={isCsvPopOverOpen ? this.toggleCSVClosed : this.toggleCSVOpen}
              />
            </Tooltip>
          ) : (
            <i
              className={
                'sci sci__data--download cursor--pointer ph- color-hover--primary font--16 ' +
                (isCsvPopOverOpen ? 'color--primary' : 'color--muted')
              }
              onClick={isCsvPopOverOpen ? this.toggleCSVClosed : this.toggleCSVOpen}
            />
          )}
        </PopoverWithEscKey>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    navigationState: state.navigationState,
    orgState: state.orgState,
    vcPageState: state.vcPageState,
    popoverState: state.popoverState,
    workspaceScanDateState: state.workspaceScanDateState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    popoverActions: bindActionCreators(popoverActions, dispatch),
    upgradeModalActions: bindActionCreators(upgradeModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CSVDownloader);
