import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';

import CopyToClipboard from '~/containers/CopyToClipboard';
import TokenControl from '~/containers/TokenControl';

import InstallStepsCLI from '~/components/InstallStepsCLI';
import DownloadOptions from '~/components/DownloadOptions';
import PowerShellExecutionPolicyStep from '~/components/PowerShellExecutionPolicyStep';
import ScanTips from '~/components/ScanTips';

import * as agentActions from '~/actions/agents';
import { agentTokenOptions } from '~/constants/ModelConstants';

import { WindowsConfigurationProps } from '~/components/WindowsConfiguration.types';

/**
 * WindowsConfiguration component
 *
 * This component renders either a CLI option or a CI option. CLI option is called 'chocolatey' and the CI option is named 'ci'
 * in the menu.
 *
 * By default, chocolatey(the CLI option) is active, hence activation token is generated using agentType 'cli'.
 * On the otherhand, with CI option, API token is generated using agentType 'WINDOWSCI'. (Please see agentTokenOptions in ModelConstants)
 */
class WindowsConfiguration extends Component<
  WindowsConfigurationProps & ReturnType<typeof mapDispatchToProps>
> {
  componentDidMount() {
    const { teamId, agentState } = this.props;
    const isFetchingAgentMessages = agentState.isFetchingAgentMessages;

    //By default, windows will create activation token with agentType 'CLI' for its 'chocolatey' download option.
    const defaultAgentType = 'cli';
    const options = agentTokenOptions[defaultAgentType];
    options.teamId = teamId;

    this.props.agentActions.updateOperatingSystem('WINDOWS'); // Initializing operating system so DownloadOptions renders correct navigation tabs
    this.props.agentActions.createActivationToken(options);

    if (teamId) {
      // polling for agent activity (only on team level - no polling for org agents)
      this.props.agentActions.allowPolling();
      if (!isFetchingAgentMessages) {
        this.props.agentActions.fetchAgentMessages(false);
      }
    }
  }

  updateScanTips(scanTip) {
    this.props.updateScanTips(scanTip);
  }

  updateSelectedWorkspace(workspace) {
    this.props.updateSelectedWorkspace(workspace);
  }

  render() {
    const {
      teamId,
      teamState,
      integrationType,
      messageState,
      agentState,
      welcomeFlow = false,
      scanTip,
    } = this.props;
    const { activeInstallOption, operatingSystem, selectedWorkspace } = agentState;
    const { teams = [] } = teamState;

    const teamOptions = teams.map(team => {
      return { value: team.id, label: team.name };
    });
    return (
      <div className="mb">
        <DownloadOptions
          operatingSystem={operatingSystem}
          activeInstallOption={activeInstallOption}
          toggleDownloadOption={this.props.toggleDownloadOption}
          integrationType={integrationType}
        />

        {activeInstallOption === 'chocolatey' ? (
          <InstallStepsCLI
            integrationType={integrationType}
            agentState={agentState}
            messageState={messageState}
            teamId={teamId}
            teams={teams}
            fetchWorkspaceStats={this.props.fetchWorkspaceStats}
            showDownloadOptions={false}
            welcomeFlow={welcomeFlow}
            updateScanTips={scanTip => this.updateScanTips(scanTip)}
            updateSelectedWorkspace={workspace => this.updateSelectedWorkspace(workspace)}
            scanTip={scanTip}
          />
        ) : (
          <div className="mt-">
            <div className="font--h6">Step one: Create agent and set environment variable</div>
            <p className="mt-">
              Create an agent and set the <code>$Env:SRCCLR_API_TOKEN</code> environment variable to
              the authentication token.
            </p>
            <div className="mt- mb">
              <TokenControl
                teamId={teamId}
                integrationType={integrationType}
                prependText={`$Env:SRCCLR_API_TOKEN="`}
                appendText={`"`}
              />
            </div>
            <div className="font--h6 mb-">
              Step two: In your PowerShell terminal, set execution policy to <code>AllSigned</code>
            </div>
            <div className="mb">
              <PowerShellExecutionPolicyStep />
            </div>
            <div className="font--h6 mb-">Step three: Download script</div>
            <div className="mb">
              <p>Execute the following to download the script.</p>
              <CopyToClipboard
                value={`iex ((New-Object System.Net.WebClient).DownloadString('https://download.srcclr.com/ci.ps1'))`}
                id={'download-scripts-' + integrationType}
              />
            </div>
            <div className="font--h6 mb-">Step four: Start scanning</div>

            {teamId ? (
              <div className="mb">
                <div className="mb-">
                  <p>Scan public repositories</p>
                  <CopyToClipboard
                    value={`srcclr scan --url https://github.com/veracode/example-ruby`}
                    id={'scan-public-repos-' + integrationType}
                  />
                </div>
                <div className="mb-">
                  <p>Or, clone and scan local repositories</p>
                  <CopyToClipboard
                    value={`srcclr scan FILE_PATH`}
                    id={'scan-local-repos-' + integrationType}
                  />
                </div>
              </div>
            ) : (
              <div className="mt-">
                <div className="col-11-12">
                  To accurately identify libraries and vulnerabilities in your project, the scanner
                  needs to build your code. Make sure your build environment is configured and that
                  your project can build.
                </div>
                <div className="flex col-11-12 mt-">
                  <div className="flex align-items--center">
                    Select the workspace that should receive the scan:
                  </div>
                  <div className="flex-1 ml-">
                    <Select
                      name={`workspace-selector`}
                      className="policy col-1-1 srcclr-react-select-container"
                      classNamePrefix={'srcclr-react-select'}
                      value={teamOptions.find(option => option.value === selectedWorkspace.value)}
                      options={teamOptions}
                      onChange={workspace => this.updateSelectedWorkspace(workspace)}
                      isClearable={false}
                    />
                  </div>
                </div>
                <div className="mt-">
                  In a terminal, navigate to your project's source code directory and enter this
                  command:
                </div>
                <div className="mt--">
                  <CopyToClipboard
                    id={`srcclr-scan-workspace`}
                    value={`srcclr scan --ws=${selectedWorkspace.value || 'default'}`}
                  />
                </div>
              </div>
            )}
            {!welcomeFlow && (
              <div className={'grid grid--middle col-1-1 mt-'}>
                <div className="grid__item col-1-1">
                  <ScanTips scanTip={scanTip} updateScanTips={tip => this.updateScanTips(tip)} />
                </div>

                <div className="grid__item col-1-1 mt">
                  <strong className="font--h7">
                    To view results, visit the URL shown in your terminal at the end of your scan.
                  </strong>
                </div>

                <div className="grid__item col-1-2 mt font--h7">
                  For detailed instructions{' '}
                  <a
                    className="link--obvious"
                    href="https://help.veracode.com/go/t_sc_cli_agent"
                    target="_blank"
                  >
                    visit the docs
                  </a>
                  .
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    agentState: state.agentState,
    messageState: state.messageState,
    teamState: state.teamState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    agentActions: bindActionCreators(agentActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WindowsConfiguration));
