import React from 'react';
import ProIcon from '~/components/ProIcon';

interface ReportBooleanFilterProps {
  isChecked: boolean;
  label: string;
  field: string;
  textJustification?: string;
  fontSize?: string;
  onClick: (...args: any[]) => any;
  showUpgradeModalIfClicked?: boolean;
  callToAction?: (...args: any[]) => any;
  automationId?: string;
}

const ReportBooleanFilter: React.FunctionComponent<ReportBooleanFilterProps> = props => {
  const {
    isChecked,
    label,
    field,
    textJustification,
    fontSize,
    showUpgradeModalIfClicked = false,
    automationId,
  } = props;

  function handleClick(e) {
    if (showUpgradeModalIfClicked) {
      props.callToAction();
    } else {
      props.onClick(field, e.target.checked);
    }
  }

  return (
    <div className="flex flex--align-items--center" data-automation-id={automationId}>
      {showUpgradeModalIfClicked && <ProIcon />}
      <label className="label--checkbox flex mb0" key={`${field}-filter`}>
        <div className={`flex pr- ${textJustification} ${fontSize}`} data-e2e={`${label}-lbl`}>
          {label}
        </div>
        <div className="flex flex--align-items--center">
          <input
            data-e2e={`${field}-cbx`}
            type="checkbox"
            checked={isChecked}
            name={`${field}-filter`}
            onChange={handleClick}
          />
          <span className="react--checkbox" />
        </div>
      </label>
    </div>
  );
};

export default ReportBooleanFilter;
