import React from 'react';
import { Link } from 'react-router-dom';

const NoOrgAdminPage: React.FunctionComponent<{}> = () => {
  return (
    <div className="grid grid--center">
      <div className="grid__item col-2-5">
        <div className="panel panel--danger mt++">
          <div className="panel__heading font--h7">
            You do not have permission to view this page
          </div>
          <div className="panel__body">
            <div>Contact an organization administrator to upgrade your permissions.</div>
            <div className="mt-">
              <Link to="/" className="link--obvious">
                Take me home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoOrgAdminPage;
