import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Tooltip from '~/components/Tooltip';
import IssueHelper from '~/utils/IssueHelper';

import { IssueDetailCommentProps } from '~/containers/IssuesDetailsPage.types';

class IssueDetailComment extends Component<IssueDetailCommentProps> {
  render() {
    const { hasComments, id, toggleSidebar } = this.props;
    const formattedIssueComment = IssueHelper.formatViewAllCommentsLink(toggleSidebar);
    return hasComments ? (
      <div className="pl--">
        <Tooltip content={formattedIssueComment} id={`${id}-tooltip`} closeOnMouseOut={false}>
          <i className="fas fa-circle color--danger" />
        </Tooltip>
      </div>
    ) : null;
  }
}

export default withRouter(IssueDetailComment);
