import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DownloadOptions from '~/components/DownloadOptions';

import * as agentActions from '~/actions/agents';
interface AgentUpgradeModalProps {
  agentActions: object;
  agentState: object;
  activeInstallOption: string;
  showModal: (...args: any[]) => any;
  toggleDownloadOption: (...args: any[]) => any;
  type: string;
}
class AgentUpgradeModal extends React.Component<AgentUpgradeModalProps, {}> {
  toggleDownloadOption(option) {
    this.props.toggleDownloadOption(option);
  }

  render() {
    const { agentState, type, activeInstallOption } = this.props;
    const { operatingSystem } = agentState;

    return (
      <div>
        {type === 'CLI' ? (
          <div>
            <div className="mb--">CHOOSE UPGRADE OPTIONS</div>
            <DownloadOptions
              activeInstallOption={activeInstallOption}
              operatingSystem={operatingSystem}
              toggleDownloadOption={option => this.toggleDownloadOption(option)}
              upgrade={true}
              integrationType="cli"
            />
          </div>
        ) : (
          <div className="mt-">
            You are scanning with SourceClear in a CI pipeline and may have turned off automatic
            updates. To get the latest version of the SourceClear agent, turn automatic updates back
            on in your CI script, and the latest version will be downloaded during your next scan.
          </div>
        )}
        <div className="mt col-1-1 flex justify-content--end">
          <button className="btn--success pv- col-1-5" onClick={() => this.props.showModal()}>
            Done
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentState: state.agentState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    agentActions: bindActionCreators(agentActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentUpgradeModal);
