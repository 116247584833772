import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';

import Helpers from '~/utils/Helpers';

import * as toastrActions from '~/actions/toastr';
import * as policiesActions from '~/actions/policies';
import * as licenseActions from '~/actions/license';

import * as policyValidationActions from '~/actions/policyValidation';

import LoaderWrapper from '~/components/LoaderWrapper';

import PolicyControlCardList from '~/containers/PolicyControlCardList';

import PolicyControlToggle, { PolicyStatusMode } from '~/components/PolicyControlToggle';

const INVALID_POLICY = 'INVALID_POLICY';

function WorkspacePoliciesPage() {
  const {
    orgPoliciesState,
    policiesState,
    licenseState,
    policyValidationState,
    teamState,
  } = useSelector(state => state as any);
  const dispatch = useDispatch();
  const { teamId } = useParams();
  useEffect(
    () => {
      fetchPolicies();
      fetchLicenses();

      return () => {
        // cleanup
        dispatch(toastrActions.removeToastr({ id: INVALID_POLICY }));
        dispatch(policyValidationActions.resetPolicyValidationResults());
        dispatch(policiesActions.resetModes());
      };
    }, // disable exhaustive-deps lint warning because unresolved issues when referencing functions declared outside useEffect, see: https://github.com/facebook/react/issues/15865#issuecomment-527297171
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function showModal(modalType) {
    dispatch(policiesActions.showModal(modalType));
  }

  function updateControl(option, field, index) {
    dispatch(policiesActions.updateControl(option, field, index));
  }

  function toggleEditMode(cancel) {
    if (cancel) {
      dispatch(toastrActions.removeToastr({ id: INVALID_POLICY }));
      dispatch(policyValidationActions.resetPolicyValidationResults());
      fetchPolicies();
      showModal('');
    }

    dispatch(policiesActions.toggleEditMode(cancel));
  }

  function fetchPolicies() {
    dispatch(policiesActions.fetchWorkspacePolicy(teamId));
  }

  function fetchLicenses() {
    dispatch(licenseActions.fetchLicenses());
  }

  function fetchLicensesByRisk(riskType) {
    dispatch(licenseActions.fetchLicensesByRisk(riskType));
  }

  function resetToDefault() {
    dispatch(policiesActions.resetToDefault());
  }

  function resetToOrgPolicy() {
    dispatch(policiesActions.resetToOrgPolicy());
  }

  function addControl() {
    dispatch(policiesActions.addControl());
    dispatch(policyValidationActions.addValidationResultItem());
  }

  function removeControl(index: number) {
    dispatch(policiesActions.removeControl(index));
    dispatch(policyValidationActions.removeValidationResultItem(index));
  }

  function toggleControlExpand(index) {
    dispatch(policiesActions.toggleControlExpand(index));
  }

  function moveControl(index, toIndex) {
    dispatch(policiesActions.moveControl(index, toIndex));
    dispatch(policyValidationActions.updateValidationResultsOrder(index, toIndex));
  }

  function saveChanges() {
    dispatch(policiesActions.validateAndSaveChanges(teamId));
  }

  /*
    params:
    mode: 'DEFAULT' | 'INHERIT' | 'CUSTOM'
  */
  function togglePolicyStatusMode(mode: PolicyStatusMode) {
    const {
      policy: { status },
      isPolicyDirty,
    } = policiesState;

    // prevent user from attempting to toggle already selected status mode
    if (mode === status) return;

    if (isPolicyDirty) {
      showModal('TOGGLE_DEFAULT_MODE');
      return;
    }

    dispatch(policiesActions.togglePolicyStatusMode(mode, teamId)).then(res => {
      if (res.success) {
        showModal(Helpers.getPolicyControlToggleModalId(mode));
      }
    });
  }

  const {
    policy: { effectiveRevision = {}, status },
    editMode,
    isFetching,
    isSaving,
    isPolicyDirty,
    showModal: showPolicyControlModal,
    isResettingToDefault,
    isResettingToOrgPolicy,
    isSandbox,
    errorMessage = '',
  } = policiesState;

  const {
    policySettings: { enforceRules, scope, scopeTeams },
  } = orgPoliciesState;

  const { teams = [] } = teamState;
  const activeTeam = teams.find(team => team.id === teamId) || {};
  const { permissions = {} } = activeTeam;
  const { updatePolicy } = permissions;
  const shouldSeePolicyToggle = permissions.updatePolicy;

  const { controls = [] } = effectiveRevision;

  // Retrieve validation state
  const {
    validationResults = {},
    isFetching: isValidating,
    errorMessage: policyValidationErrorMessage,
  } = policyValidationState;
  const { policy: validatedPolicy = {} } = validationResults;
  const { controls: validatedControls = [] } = validatedPolicy;

  // Show 'Rules are currently unavailable' text when error is not caused by a controls validation error
  const errorHasOccurred = errorMessage.length > 0 && policyValidationErrorMessage.length === 0;

  const shouldEnableToggleButton = Helpers.shouldEnableToggleButton(
    { enforceRules, scope, scopeTeams },
    teamId
  );

  return (
    <LoaderWrapper isLoaderShowing={isFetching || isResettingToDefault || isResettingToOrgPolicy}>
      <div className="grid mt mb++">
        <Helmet>
          <title>Rules</title>
        </Helmet>
        <form onSubmit={e => e.preventDefault()} className="grid__item col-1-1">
          {errorHasOccurred ? (
            <div className="grid">
              <div className="grid__item col-1-1 flex justify-content--center flex--align-items--center height--75vh">
                <div className="text--center width--400">
                  <p className="font--h5">
                    Rules are currently unavailable.
                    <br />
                    Please try again later.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid">
              <div className="grid__item col-1-1">
                <h3 data-automation-id="WorkspacePoliciesPage-Title">Rules</h3>
              </div>
              <div className="grid__item col-1-1 mt-">
                Rules are a set of controls that your code should follow. If the rules are violated,
                you can specify what actions should be taken.
              </div>
              <PolicyControlToggle
                editRulesMetaData={{ editMode, status, isSaving, isValidating, isPolicyDirty }}
                shouldEnableToggleButton={shouldEnableToggleButton}
                saveChanges={() => saveChanges()}
                showModal={modal => showModal(modal)}
                resetToDefault={() => resetToDefault()}
                resetToOrgPolicy={() => resetToOrgPolicy()}
                modalType={showPolicyControlModal}
                togglePolicyStatusMode={(mode: PolicyStatusMode) => togglePolicyStatusMode(mode)}
                toggleEditMode={toggled => toggleEditMode(toggled)}
                shouldSeePolicyToggle={shouldSeePolicyToggle}
                updatePolicy={updatePolicy}
                addControl={() => addControl()}
                isSandbox={isSandbox}
                renderPolicyControlCardList={() => (
                  <PolicyControlCardList
                    controls={controls}
                    cardState={policiesState}
                    licenseState={licenseState}
                    validatedControls={validatedControls}
                    updateControl={(option, type, index) => updateControl(option, type, index)}
                    fetchLicensesByRisk={riskType => fetchLicensesByRisk(riskType)}
                    removeControl={index => removeControl(index)}
                    toggleControlExpand={index => toggleControlExpand(index)}
                    moveControl={(index, toIndex) => moveControl(index, toIndex)}
                  />
                )}
              />
            </div>
          )}
        </form>
      </div>
    </LoaderWrapper>
  );
}

export default WorkspacePoliciesPage;
