import * as actions from '~/actions/orgTeam';

export const defaultState = {
  hasRequestedOrgTeams: false,
  orgTeams: [],
  filteredOrgTeams: [],
  selectedOrgTeams: {},
  selectedWorkspaceGroups: {},
  activeTab: 'USERS',
  teamGroups: undefined,
  filteredTeamGroups: undefined,
  showModal: undefined,
  sortColumn: 'name',
  sortAscending: true,
  quickViewsByWorkspaceId: {},
};

const orgTeamState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_ORG_TEAMS:
      return {
        ...state,
        hasRequestedOrgTeams: true,
        orgTeams: action.teams,
        filteredOrgTeams: sort(action.teams, state.sortColumn, state.sortAscending),
        selectedWorkspaceGroups: {},
        selectedOrgTeams: {},
      };
    case actions.UPDATE_SELECTED_ORG_TEAMS:
      return {
        ...state,
        selectedOrgTeams: {
          ...state.selectedOrgTeams,
          [action.teamId]: !state.selectedOrgTeams[action.teamId],
        },
      };
    case actions.SHOW_WORKSPACE_MODAL:
      return {
        ...state,
        showModal: action.modalType,
      };
    case actions.UPDATE_ORG_WORKSPACE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab,
      };
    case actions.UPDATE_SELECTED_WORKSPACE_GROUPS:
      return {
        ...state,
        selectedWorkspaceGroups: {
          ...state.selectedWorkspaceGroups,
          [action.groupId]: !state.selectedWorkspaceGroups[action.groupId],
        },
      };
    case actions.FILTER_TEAMS_BY_INPUT:
      return {
        ...state,
        filteredOrgTeams: sort(
          filterTeams(state.orgTeams, action.value),
          state.sortColumn,
          state.sortAscending
        ),
      };
    case actions.SET_TEAM_DETAILS:
      return {
        ...state,
        teamGroups: action.groups,
        filteredTeamGroups: sort(action.groups, state.sortColumn, state.sortAscending),
      };
    case actions.RESET_TEAM_DETAILS:
      return {
        ...state,
        teamGroups: undefined,
        filteredTeamGroups: undefined,
      };
    case actions.FILTER_TEAM_GROUPS:
      return {
        ...state,
        filteredTeamGroups: sort(
          filterTeamGroups(state.teamGroups, action.value),
          state.sortColumn,
          state.sortAscending
        ),
      };
    case actions.UPDATE_ORG_TEAM_SORT: {
      const directionToSort = state.sortColumn === action.column ? !state.sortAscending : true;

      return {
        ...state,
        sortColumn: action.column,
        sortAscending: directionToSort,
        filteredOrgTeams: sort(state.filteredOrgTeams, action.column, directionToSort),
      };
    }
    case actions.UPDATE_ORG_TEAM_GROUPS_SORT: {
      const directionToSort = state.sortColumn === action.column ? !state.sortAscending : true;

      return {
        ...state,
        sortColumn: action.column,
        sortAscending: directionToSort,
        filteredTeamGroups: sort(state.filteredTeamGroups, action.column, directionToSort),
      };
    }
    case actions.RESET_TEAM_SORT:
      return {
        ...state,
        sortColumn: 'name',
        sortAscending: true,
      };
    case actions.TOGGLE_WORKSPACE_GROUPS_VIEW:
      return {
        ...state,
        quickViewsByWorkspaceId: {
          [action.workspaceId]:
            state.quickViewsByWorkspaceId[action.workspaceId] === action.column
              ? 'OFF'
              : action.column,
        },
      };
    case actions.RESET_WORKSPACE_QUICK_VIEWS:
      return {
        ...state,
        quickViewsByWorkspaceId: {},
      };
    default:
      return state;
  }
};

export default orgTeamState;

function sort(set, column, sortAscending, type?) {
  set.sort((a, b) => {
    const valueA = type && a[type] ? a[type][column] : a[column];
    const valueB = type && b[type] ? b[type][column] : b[column];
    if (!valueA || !valueB) return 0;

    const formattedValueA = valueA.toLowerCase ? valueA.trim().toLowerCase() : valueA;
    const formattedValueB = valueB.toLowerCase ? valueB.trim().toLowerCase() : valueB;

    if (formattedValueA > formattedValueB) {
      return sortAscending ? 1 : -1;
    }
    if (formattedValueA < formattedValueB) {
      return sortAscending ? -1 : 1;
    }
    return 0;
  });

  return set;
}

function filterTeams(teams, value) {
  const trimmedValue = value.trim().toLowerCase();
  return teams.filter(team => {
    const { name = '' } = team;
    const groups = team.groups || [];
    const users = team.users || [];

    if (name.toLowerCase().includes(trimmedValue)) {
      return true;
    }

    const groupMatch = groups.some(group => group.name.toLowerCase().includes(trimmedValue));

    if (groupMatch) {
      return true;
    }

    const userMatch = users.some(user => {
      const { firstName = '', lastName = '', email = '' } = user;
      return (
        firstName.toLowerCase().includes(trimmedValue) ||
        lastName.toLowerCase().includes(trimmedValue) ||
        email.toLowerCase().includes(trimmedValue)
      );
    });

    if (userMatch) {
      return true;
    }

    return false;
  });
}

function filterTeamGroups(groups, value) {
  const trimmedValue = value.trim().toLowerCase();
  return groups.filter(groupObj => {
    const { group = {} } = groupObj;
    const { name = '' } = group;
    const description = group.description || '';

    return (
      name.toLowerCase().includes(trimmedValue) || description.toLowerCase().includes(trimmedValue)
    );
  });
}
