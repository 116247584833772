import React from 'react';

const NoOrgOwnerPage: React.FunctionComponent<{}> = () => {
  return (
    <div className="grid grid--center">
      <div className="grid__item col-2-5">
        <div className="panel panel--danger mt++">
          <div className="panel__heading font--h7">
            You do not have permission to view this page
          </div>
          <div className="panel__body">
            Contact an organization owner to upgrade you permissions.{' '}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoOrgOwnerPage;
