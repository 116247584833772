import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import SourceClearLoader from '~/components/SourceClearLoader';

interface ReportBottomProps {
  onVisible: (...args: any[]) => any;
  reportState: object;
}
class ReportBottom extends React.Component<ReportBottomProps, {}> {
  constructor(props, context) {
    super(props, context);

    this.delayedOnVisible = _.debounce(this.props.onVisible, 300);
  }

  componentDidMount() {
    this.attachListeners();
  }

  componentDidUpdate() {
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  }

  handleScroll = () => {
    const bounds = ReactDOM.findDOMNode(this).getBoundingClientRect();
    const scrollTop = window.pageYOffset;
    const top = bounds.top + scrollTop;
    const height = bounds.bottom - bounds.top;

    if (top < scrollTop + window.innerHeight && top + height > scrollTop) {
      this.delayedOnVisible();
    }
  };

  attachListeners = () => {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
    this.handleScroll();
  };

  render() {
    const { isFetching = false } = this.props.reportState;

    if (isFetching) {
      return <SourceClearLoader />;
    } else {
      return <div className="ml height--26" />;
    }
  }
}

function mapStateToProps(state) {
  return {
    reportState: state.reports,
  };
}

export default connect(mapStateToProps)(ReportBottom);
