import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DropdownMenu from 'react-dd-menu';
import _ from 'lodash';

import LoaderWrapper from '~/components/LoaderWrapper';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as projectSettingsActions from '~/actions/projectSettings';
import * as projectNotificationsSettingsActions from '~/actions/projectNotificationsSettings';
import {
  NOTIFICATION_TYPES,
  PROJECT_SETTINGS_STAGES,
  REPO_EVENTS,
  REPO_EVENTS_CLEAN_NAMES,
} from '~/constants/ModelConstants';

const NOTIFICATION_TYPE = NOTIFICATION_TYPES.WEBHOOK;

interface ProjectSettingsWebhookNotificationsListSectionProps {
  projectId: string;
  dropdownMenuState: object;
  dropdownMenuActions: object;
  projectSettingsActions: object;
  projectNotificationsSettingsActions: object;
  projectNotificationsSettingsState: object;
}
class ProjectSettingsWebhookNotificationsListSection extends React.Component<
  ProjectSettingsWebhookNotificationsListSectionProps,
  {}
> {
  constructor(props, context) {
    super(props, context);
    this.delayCloseDropdownMenu = _.debounce(this.closeDropdownMenu, 50);
  }

  componentDidMount() {
    const { projectId } = this.props;
    this.props.projectNotificationsSettingsActions.fetchNotificationsSettingsByType(
      NOTIFICATION_TYPE,
      projectId
    );
  }

  componentWillUnmount() {
    this.props.dropdownMenuActions.resetDropdownMenuState();
  }

  closeDropdownMenu() {
    this.props.dropdownMenuActions.closeDropdownMenu('webhook-notifications-action');
  }

  getCleanEventsName(triggerEvents) {
    if (!triggerEvents || !triggerEvents.length) {
      return '';
    }

    const repoEventsCount = Object.keys(REPO_EVENTS).length;

    if (triggerEvents.length === repoEventsCount) {
      return 'All events';
    } else if (triggerEvents.length === 1) {
      return REPO_EVENTS_CLEAN_NAMES[triggerEvents[0]];
    } else if (triggerEvents.length < repoEventsCount) {
      return 'Selected events';
    } else {
      return '';
    }
  }

  updateWebhookFormControls(event) {
    this.props.projectSettingsActions.updateProjectSettingsStage(
      PROJECT_SETTINGS_STAGES.UPDATE_WEBHOOK
    );
    this.props.projectNotificationsSettingsActions.updateNotificationsSettingsControls(
      NOTIFICATION_TYPE,
      event
    );
  }

  onRowSelectChange = id => {
    this.props.projectNotificationsSettingsActions.updateSelectedRows(NOTIFICATION_TYPE, id);
  };

  deleteSelectedWebhooks = () => {
    const { projectId, projectNotificationsSettingsState } = this.props;
    const { [NOTIFICATION_TYPE]: webhookNotifications = {} } = projectNotificationsSettingsState;
    const { selectedRows: webhookIds = [] } = webhookNotifications;
    this.props.projectNotificationsSettingsActions.deleteWebhookNotifications(
      projectId,
      webhookIds
    );
  };

  render() {
    const { dropdownMenuState, projectNotificationsSettingsState } = this.props;
    const { [NOTIFICATION_TYPE]: webhookNotifications = {} } = projectNotificationsSettingsState;
    const { data = {}, selectedRows = [], isDeleting = false, isFetching } = webhookNotifications;
    const { content = [] } = data;
    const { openedDropdown = {} } = dropdownMenuState;

    const colWidths = {
      url: `col-7-12`,
      triggerEvents: `col-3-12`,
      select: `col-2-12`,
    };

    const rows = content
      .sort((a, b) => {
        // Perform client sorting alphabetically by URL
        const aUrl = (a.url && a.url.toLowerCase()) || '';
        const bUrl = (b.url && b.url.toLowerCase()) || '';

        if (aUrl < bUrl) {
          return -1;
        } else if (aUrl > bUrl) {
          return 1;
        }

        return 0;
      })
      .map((item, index) => {
        const { id = '', url = '' } = item;
        const isSelected = selectedRows.includes(id);
        return (
          <div key={`${index}-${item.id}`} className="grid pv--">
            <div className={`grid__item ${colWidths.url} text--wrap `}>
              <a
                className="link--primary text--bold"
                onClick={() => this.updateWebhookFormControls(item)}
              >
                {url}
              </a>
            </div>
            <div className={`grid__item ${colWidths.triggerEvents}`}>
              {this.getCleanEventsName(item.events)}
            </div>
            <div className={`grid__item text--center ${colWidths.select}`}>
              <div className="flex flex--align-items--center flex--justify-content--center">
                <label className="label--checkbox flex mb0">
                  <div className="flex">
                    <input
                      type="checkbox"
                      name={`webhook-${id}`}
                      id={id}
                      onChange={() => this.onRowSelectChange(id)}
                      checked={isSelected}
                    />
                    <span className={`react--checkbox mr0`} />
                  </div>
                </label>
              </div>
            </div>
          </div>
        );
      });

    return (
      <div>
        <h6 data-automation-id="ProjectSettingsWebhookNotificationsListSection-Title" className="text--uppercase bo-b--1 border-color--muted-light pb---">
          Webhook Notifications
        </h6>
        <p>
          Webhooks allow external services to be notified when certain events happen. When the
          specified events happen, we'll send a POST request to each of the URLs you provide, and
          the content type will be application/json.
        </p>
        <div className="text--right mb- pr--">
          <DropdownMenu
            animate={true}
            textAlign="left"
            align="right"
            isOpen={!!openedDropdown['webhook-notifications-action']}
            close={() => this.delayCloseDropdownMenu()}
            toggle={
              <button
                className={`p- btn--success mr-`}
                disabled={false}
                onClick={() =>
                  this.props.dropdownMenuActions.openDropdownMenu('webhook-notifications-action')
                }
              >
                Actions
              </button>
            }
          >
            <li className="dropdown-menu--li">
              <button
                className="ph"
                onClick={() =>
                  this.props.projectSettingsActions.updateProjectSettingsStage(
                    PROJECT_SETTINGS_STAGES.CREATE_WEBHOOK
                  )
                }
              >
                Create
              </button>
            </li>
            <li className="dropdown-menu--li">
              <button
                className={`ph ${isDeleting || selectedRows.length === 0 ? 'disabled' : ''}`}
                onClick={this.deleteSelectedWebhooks}
                disabled={isDeleting || selectedRows.length === 0}
              >
                Delete
              </button>
            </li>
          </DropdownMenu>
        </div>
        <div className="grid">
          <div className={`grid__item ${colWidths.url}`}>
            <span className="text--bold">Payload URL</span>
          </div>
          <div className={`grid__item ${colWidths.triggerEvents}`}>
            <span className="text--bold">Trigger Events</span>
          </div>
          <div className={`grid__item text--center ${colWidths.select}`}>
            <span className="text--bold">Select</span>
          </div>
        </div>
        <div className="height--300 overflow-y--scroll pt-">
          <LoaderWrapper isLoaderShowing={isFetching}>
            {!content.length ? (
              <span className="color--muted">No webhook notifications found.</span>
            ) : (
              rows
            )}
          </LoaderWrapper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    projectSettingsState: state.projectSettingsState,
    projectNotificationsSettingsState: state.projectNotificationsSettingsState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    projectNotificationsSettingsActions: bindActionCreators(
      projectNotificationsSettingsActions,
      dispatch
    ),
    projectSettingsActions: bindActionCreators(projectSettingsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSettingsWebhookNotificationsListSection);
