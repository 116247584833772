import Clipboard from 'clipboard';

const ClipboardFactory = function () {
  const create = (inputId, successCallback, errorCallback) => {
    const newClipboardObj = new Clipboard('#' + inputId + 'Button');

    newClipboardObj.on('success', function (e) {
      e.clearSelection();
      successCallback(inputId);
    });

    newClipboardObj.on('error', function () {
      errorCallback(inputId);
    });

    return newClipboardObj;
  };

  return {
    create,
  };
};

export default new ClipboardFactory();
