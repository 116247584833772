import React from 'react';
import { Link } from 'react-router-dom';
import ReportPagination from '~/components/ReportComponents/ReportPagination';
import Helpers from '~/utils/Helpers';
import Tooltip from '~/components/Tooltip';
import RegistryLink from '~/components/RegistryLink';

interface LicenseReportLibrariesQuickviewProps {
  data: object;
  onPageClick: (...args: any[]) => any;
  teamId: string;
  quickviewId: string;
  quickviewLink: string;
}

const LicenseReportLibrariesQuickview: React.FunctionComponent<
  LicenseReportLibrariesQuickviewProps
> = props => {
  const { teamId } = props;
  const { _embedded = {}, page = {} } = props.data;
  const { libraries = [] } = _embedded;

  const librariesList = libraries.map((library, index) => {
    const { licenses = {}, name = '', version = '', repoDetails = [], _links = {} } = library;
    const { library: libraryLink = {} } = _links;
    const { href = '' } = libraryLink;

    const repoNames = repoDetails.map((repoData, idx) => {
      const mode =
        repoData.dependencyMode === 'BOTH'
          ? 'direct + transitive'
          : `${repoData.dependencyMode.toLowerCase()}`;
      const { repo = {} } = repoData;
      const { id, name: repoName } = repo;

      const repoNameText = Helpers.formatProjectName(repoName);
      const isProjectNameCut = Helpers.isProjectNameCut(repoNameText);
      const issuesVulnLink = `/workspaces/${teamId}/projects/${id}/issues`;
      /* eslint-disable react/no-danger */
      const projNameTooltipContent = Helpers.isProjectNameCut(repoNameText) ? (
        <div dangerouslySetInnerHTML={Helpers.formatBreakOnSlash(repoName)} />
      ) : (
        ''
      );
      /* eslint-enable react/no-danger */

      return (
        <div key={`repo-${idx}`}>
          {'- '}
          {isProjectNameCut && (
            <Tooltip
              content={projNameTooltipContent}
              place="left"
              id={`${id}-${idx}-${name}-${version}-licensesQuickview`}
            >
              <Link to={issuesVulnLink} className="link--obvious">
                {repoNameText}
              </Link>
            </Tooltip>
          )}
          {!isProjectNameCut && (
            <Link to={issuesVulnLink} className="link--obvious">
              {repoNameText}
            </Link>
          )}
          {` (${mode})`}
        </div>
      );
    });
    return (
      <div className="bo-b--1 border-color--muted-light" key={index}>
        <div className="grid pv-- flex flex--align-items--start">
          <div className="grid__item col-3-10">
            <RegistryLink href={href} className="link--obvious">
              {`${name}`}
            </RegistryLink>
          </div>
          <div className="grid__item">{`${version}`}</div>
          <div className="grid__item">
            {`${Object.keys(licenses).length > 1 ? 'Multi' : 'Single'}`}
          </div>
          <div className="grid__item col-2-5">{repoNames}</div>
        </div>
      </div>
    );
  });

  return (
    <div className="bo-t--1 border-color--muted-light pt pb-">
      <div className="grid pb-">
        <div className="grid__item col-3-10 text--bold">Library</div>
        <div className="grid__item text--bold">Version</div>
        <div className="grid__item text--bold">Single or Multi</div>
        <div className="grid__item col-2-5 text--bold">Projects</div>
      </div>
      {librariesList}

      <ReportPagination
        page={page}
        onPageClick={props.onPageClick}
        quickviewId={props.quickviewId}
        quickviewLink={props.quickviewLink}
      />
    </div>
  );
};

export default LicenseReportLibrariesQuickview;
