import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';
import DropdownMenu from 'react-dd-menu';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

import OrgPaidStatus from '~/utils/OrgPaidStatus';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import { FEATURE_SLUG_MAP } from '~/constants/ModelConstants';

import SourceClearModal from '~/components/SourceClearModal';
import CreateGroupModal from '~/containers/CreateGroupModal';
import LinkWorkspaceToTeamsModal from '~/containers/LinkWorkspaceToTeamsModal';
import TeamsList from '~/components/workspaces/teams/TeamsList';

import * as groupActions from '~/actions/group';
import * as teamGroupsActions from '~/actions/teamGroups';
import * as dropdownMenuActions from '~/actions/dropdownMenu';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';

interface TeamsPageProps extends RouteComponentProps {
  dropdownMenuActions: object;
  groupActions: object;
  teamGroupsActions: object;
  dropdownMenuState: object;
  groupState: object;
  myState: App.MyState;
  orgState: App.OrgState;
  teamGroupsState: object;
  orgTeamState: object;
  teamState: object;
  vcPageState: VCPageState;
}
export class TeamsPage extends React.Component<TeamsPageProps> {
  constructor(props) {
    super(props);

    this.delayCloseDropdown = _.debounce(id => this.closeDropdown(id), 50);
  }

  componentDidMount() {
    const { match, history, orgState } = this.props;
    const { org } = orgState;
    const { params = {} } = match;
    const { teamId } = params;
    const showGroups = FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org);

    if (org.id && !showGroups && !OrgPaidStatus.isOrgEnterpriseOrTrial(org)) {
      history.replace(`/workspaces/${teamId}/user-management/users`);
    }

    this.fetchTeamGroups(teamId);
  }

  componentWillUnmount() {
    this.props.teamGroupsActions.resetTeamGroupQuickViews();
    this.props.teamGroupsActions.resetSelectedGroups();
    this.props.dropdownMenuActions.closeDropdownMenu('team-groups-actions');
  }
  delayCloseDropdown = null;
  removeGroups() {
    const { teamGroupsState, match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;
    const { selectedGroups = {}, teamGroups = [] } = teamGroupsState;
    const filteredGroups = teamGroups
      .filter(groupObj => selectedGroups[groupObj.group.id])
      .map(groupObj => groupObj.id);

    this.props.groupActions.removeMemberships(filteredGroups, 'teams').then(res => {
      if (res.success) {
        this.fetchTeamGroups(teamId);
        this.props.teamGroupsActions.resetSelectedGroups();
      }
    });
  }

  fetchTeamGroups(teamId) {
    this.props.teamGroupsActions.fetchTeamGroups(teamId);
  }

  closeDropdown(menuId) {
    this.props.dropdownMenuActions.closeDropdownMenu(menuId);
  }

  openDropdown(menuId) {
    this.props.dropdownMenuActions.openDropdownMenu(menuId);
  }

  updateSelectedGroups(groupId) {
    this.props.teamGroupsActions.updateSelectedGroups(groupId);
  }

  toggleWorkspaceGroupUsersQuickView(groupId, column) {
    this.props.teamGroupsActions.toggleWorkspaceGroupUsersQuickView(groupId, column);
  }

  handleFilterInput(e) {
    this.props.teamGroupsActions.updateTeamGroupsFilterInput(e.target.value);
  }

  showModal(modalType?) {
    this.props.teamGroupsActions.showModal(modalType);
  }

  render() {
    const {
      dropdownMenuState,
      teamGroupsState,
      orgState,
      teamState,
      match,
      vcPageState: { shouldShowVeracodePage },
    } = this.props;
    const { params = {} } = match;
    const { org } = orgState;
    const { permissions } = org;
    const { createGroups } = permissions;
    const { teamId } = params;
    const { teams = [] } = teamState;
    const activeTeam = teams.find(team => team.id === teamId) || {};
    const { permissions: teamPermissions = {} } = activeTeam;
    const { manageGroups } = teamPermissions;
    const {
      filteredTeamGroups = [],
      teamGroups = [],
      showModal,
      quickViewsByGroupId = {},
      selectedGroups = {},
    } = teamGroupsState;
    const { openedDropdown = {} } = dropdownMenuState;
    const isAnyGroupSelected = Object.keys(selectedGroups).some(groupId => selectedGroups[groupId]);

    return (
      <div className="grid mt mb++">
        <Helmet>
          <title>Workspace Teams</title>
        </Helmet>
        <div className="grid__item col-1-1">
          <h3 data-automation-id="TeamsPage-Title">Teams</h3>
        </div>
        <div className="grid__item col-1-1 mt">
          <div className="bo--1 border-color--muted-dark flex justify-content--space-between p- align-items--center">
            <div className="flex col-3-4">
              <div className="col-1-2">
                <input
                  type="text"
                  className="control--text col-1-1"
                  placeholder="Filter by name or description"
                  onChange={e => this.handleFilterInput(e)}
                />
              </div>
            </div>
            <div className="" data-automation-id="TeamsPage-actionsDropdown">
              {(manageGroups || createGroups) && (
                <DropdownMenu
                  isOpen={!!openedDropdown['team-groups-actions']}
                  close={() => this.delayCloseDropdown('team-groups-actions')}
                  menuAlign="right"
                  textAlign="left"
                  toggle={
                    <button
                      className="btn--success p-"
                      onClick={() => this.openDropdown('team-groups-actions')}
                    >
                      Actions
                    </button>
                  }
                >
                  {!shouldShowVeracodePage && createGroups && (
                    <React.Fragment>
                      <li className="pr+ dropdown-menu--li">
                        <button className="pv--" onClick={() => this.showModal('CREATE_GROUP')}>
                          Create
                        </button>
                      </li>
                      <li className="separator mh-" />
                    </React.Fragment>
                  )}
                  {manageGroups && (
                    <React.Fragment>
                      <li className="pr+ dropdown-menu--li">
                        <button className="pv--" onClick={() => this.showModal('LINK_GROUPS')}>
                          Add Teams
                        </button>
                      </li>
                      <li className="pr+ dropdown-menu--li">
                        <button
                          className={`'pv--' ${!isAnyGroupSelected && 'disabled'}`}
                          disabled={!isAnyGroupSelected}
                          onClick={() => this.removeGroups()}
                        >
                          Remove Teams
                        </button>
                      </li>
                    </React.Fragment>
                  )}
                </DropdownMenu>
              )}
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1 mt-">This workspace is linked to the teams below.</div>
        <div className="grid__item col-1-1">
          <TeamsList
            groups={teamGroups}
            filteredGroups={filteredTeamGroups}
            teamPermissions={teamPermissions}
            selectedGroups={selectedGroups}
            updateSelectedGroups={groupId => this.updateSelectedGroups(groupId)}
            teamId={teamId}
            quickViewsByGroupId={quickViewsByGroupId}
            toggleWorkspaceGroupUsersQuickView={(groupId, column) =>
              this.toggleWorkspaceGroupUsersQuickView(groupId, column)
            }
            shouldShowVeracodePage={shouldShowVeracodePage}
          />
        </div>
        <SourceClearModal
          isOpen={showModal === 'CREATE_GROUP'}
          title="Create Team"
          onClose={() => this.showModal()}
          width={500}
        >
          <CreateGroupModal
            showModal={modalType => this.showModal(modalType)}
            workspaceId={teamId}
          />
        </SourceClearModal>
        <SourceClearModal
          isOpen={showModal === 'LINK_GROUPS'}
          title="Add Workspace to Teams"
          onClose={() => this.showModal()}
          width={500}
        >
          <LinkWorkspaceToTeamsModal
            showModal={modalType => this.showModal(modalType)}
            handleLinkSuccess={() => this.fetchTeamGroups(teamId)}
            activeTeam={activeTeam}
          />
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    groupState: state.groupState,
    myState: state.myState,
    orgState: state.orgState,
    teamGroupsState: state.teamGroupsState,
    orgTeamState: state.orgTeamState,
    teamState: state.teamState,
    vcPageState: state.vcPageState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions as any, dispatch),
    groupActions: bindActionCreators(groupActions as any, dispatch),
    teamGroupsActions: bindActionCreators(teamGroupsActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamsPage);
