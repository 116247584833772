import React, {useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {useGetApplicationList} from "~/hooks/applicationList";
import {ApplicationListPageParam, StartScanModalState} from "~/reducers/vcAppState/vcAppStateTypes/types";
import LoaderWrapper from "~/components/LoaderWrapper";
import * as startScanModalActions from '~/actions/startScanModal';
import {Button} from "fluorine-ui";
import ModalPagination from "~/components/ModalPagination";

interface ApplicationTableProps {
  startScanModalActions: object;
  startScanModalState: StartScanModalState;
}

const ApplicationTable: React.FunctionComponent<ApplicationTableProps> = props => {

  let applications = [];
  let page = {
    number: 0,
    size: 5,
    total_elements: 0,
    total_pages: 1
  };

  // Passing the values to get application, each time these values changes, the application will be fetched based on the new value.
  const [pageParam, setPageParam] =  useState<ApplicationListPageParam>({size: 5, pageNumber:0, name:'', sort:''});
  const [nameSearch, setNameSearch] = useState("");
  const [iconClass, setIconClass] = useState("sci__arrow--closed-scroll");

  //To Pass the selected application to the main page
  const selectedApplication = props.startScanModalState.selectedApplication;
  const { status: applicationStatus, data: applicationInfo } = useGetApplicationList(pageParam);

  let applicationLoading = true;
  if ( applicationStatus === 'success' ) {
    applicationLoading = false;
    applications = ( applicationInfo._embedded && applicationInfo._embedded.applications ? applicationInfo._embedded.applications : [] );
    page = applicationInfo.page;
  }

  function handlePaginationClick(pageNumber: number ) {
    setPageParam({size: pageParam.size, pageNumber: pageNumber, name: pageParam.name, sort: pageParam.sort});
  }

  function  handlePageSizeChange (pageSizeValue: number) {
    setPageParam({size: pageSizeValue, pageNumber: pageParam.pageNumber, name: pageParam.name, sort: pageParam.sort});
  }

  function handleSearch( field, e  ) {
    const { target = {} } = e;
    const { value = '' } = target;
    setNameSearch(value);
    setPageParam({size: pageParam.size, pageNumber: pageParam.pageNumber, name: value, sort: pageParam.sort});
    //Resetting the Selected application
    props.startScanModalActions.updateApplicationName(null);
  }

  function handleClearSearch(   ) {
    setNameSearch("");
    setPageParam({size: pageParam.size, pageNumber: pageParam.pageNumber, name: "", sort: pageParam.sort});
  }

  function sortApplication(){
    const sort = pageParam.sort;
    const sortOrder = ( sort === "" || sort.split(",")[1]  === 'desc' ) ? 'asc' : 'desc';
    setIconClass(sortOrder == "asc" ? 'sci__arrow--closed-up' : 'sci__arrow--closed-down');
    setPageParam({size: pageParam.size, pageNumber: pageParam.pageNumber, name: "", sort: "name," + sortOrder});
  }

  function handleApplicationSelection(e) {
    const application = JSON.parse(e.currentTarget.value);
    props.startScanModalActions.updateApplicationName(application);
  }


  return (

    <div className="grid pad-left--10 mt-">
      <div className="col-1-1 bo--1 border-color--white-dark bg-color--white">
      <div className=" pad-left--10 flex-content mt- mb- font--h6">
        Application
      </div>
      <LoaderWrapper isLoaderShowing={applicationLoading}>
      <div className={`flex flex-direction--column col-1-1`} id="application-list_div">
        <div className="bo--top-bottom--thin bo--top-bottom--thin border-color--white-dark bg-color--white ">
          <ModalPagination page={page} handlePageClick={handlePaginationClick} handlePageSizeChange={handlePageSizeChange}/>
          <div className="bo--top-bottom--thin bo--top-bottom--thin border-color--white-dark bg-color--white pb-- pt--"
                 data-automation-id="ApplicationTable-search">
            <div className={"grid__item flex position--relative width--240 flex"}>
              <input type="text" className="flex flex-grow--1 search-input-box" placeholder="Search Application"
                     value={nameSearch} onChange={e => handleSearch('name', e)}/>
              <Button className="ml search-input-box" type="button" onClick={handleClearSearch}>
                  Clear
              </Button>
            </div>
          </div>
        </div>
        {!applicationLoading && applications.length === 0 && (
          <div className="pt pb pl flex--align-items--center" data-automation-id="ApplicationTable-NoAppFound">
             No applications are found
          </div>
        )}
        { !applicationLoading && applications.length > 0 && (
          <div data-automation-id="ApplicationTables-Rows">
            <div className="bo--top-bottom--thin bo--top-bottom--thin border-color--white-dark bg-color--white pb-- pt-- " onClick={sortApplication}>
              <div className={`flex pad-left--30`}>
                <div className="flex flex--align-items--center">
                  <i className={`sci ${iconClass}`} />
                </div>
                <div className="flex text--bold bo-b--1 border-color--white bo-b--1--hover-black "  >
                  Application Name
                </div>
              </div>
            </div>
            {applications.map((application, index) => {
              //TODO see if we can use stringify to get the string value easily
              const appValue = ' {"appId": ' + application.id + ', "name": "' + application.profile.name +'"'  + '}';
              const bgColor = ( !(index % 2) ? 'bg-color--white-medium' : '');
              return (
                <div key={index} className={`pad-left--10 bo-b--1 border-color--muted-light ${bgColor}`} id={`appDiv_${index}`}>
                  <label className="label--checkbox flex pv-- mb0">
                    <div className="flex flex--align-items--center">
                      <input
                        type="radio"
                        className="flex"
                        name="applications"
                        onChange={handleApplicationSelection}
                        value={appValue}
                      />
                      <span className="react--radio" />
                    </div>
                    <div className="pl---">{application.profile.name}</div>
                  </label>
                </div>
              );
            }) }
            <ModalPagination page={page} handlePageClick={handlePaginationClick} handlePageSizeChange={handlePageSizeChange}/>
          </div>
          )}
      </div>
      </LoaderWrapper>
      </div>
      {  selectedApplication  && (
        <div className="pt- pl pb-" id="selectedApplication" data-automation-id="ApplicationTable-Selected-application">
          <span className="text--bold"> Selected Application:</span> {selectedApplication.name}
        </div>
      )}
    </div>
  );
};


function mapStateToProps(state) {
  return {
    startScanModalState: state.startScanModalState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startScanModalActions: bindActionCreators(startScanModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationTable);

