import {
  VC_DROPDOWN_MENU_OPEN,
  VC_DROPDOWN_MENU_CLOSE,
} from '~/actions/vcAppActions/vcAppActionsTypes/types';

export const handleOpen = (id: string) => ({
  type: VC_DROPDOWN_MENU_OPEN,
  id,
});

export const handleClose = () => ({
  type: VC_DROPDOWN_MENU_CLOSE,
});
