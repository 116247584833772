import React, { FunctionComponent, StrictMode } from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';
import { renderRoutesInPath } from '~/utils/RouterUtils';

interface OrgAdminWrapperProps extends RouteComponentProps {
  routes: App.Route[];
}

const OrgAdminWrapper: FunctionComponent<OrgAdminWrapperProps> = props => {
  const { routes = [], match } = props;
  return (
    <StrictMode>
      <div className="grid">
        <div className="grid__item col-1-1">
          <Switch children={renderRoutesInPath(routes, match.path)} />
        </div>
      </div>
    </StrictMode>
  );
};

export default OrgAdminWrapper;
