import React from 'react';
import PackageManagerUrlHelper from '~/utils/PackageManagerUrlHelper';

interface JarFileProps {
  component: object;
  safeVersion: string;
}

const JarFile: React.FunctionComponent<JarFileProps> = props => {
  const { component, safeVersion } = props;
  const { coordinate1, coordinate2 } = component;
  const path = PackageManagerUrlHelper.getMvnPathWithVersion(coordinate1, coordinate2, safeVersion);

  return (
    <div className="text--italic pt-">
      You can download the safe version
      <span className="font-family--code bg-color--white-medium ph--">{safeVersion}</span>
      of {component.name} from
      <a className="link--obvious" href={path} target="_blank">
        {' '}
        Maven Central{' '}
      </a>
    </div>
  );
};

export default JarFile;
