import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DropdownMenu from 'react-dd-menu';
import moment from 'moment';
import _ from 'lodash';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as expandableSectionActions from '~/actions/expandableSection';
import * as teamUsersActions from '~/actions/teamUsers';
import * as toastrActions from '~/actions/toastr';
import * as workspaceInviteActions from '~/actions/workspaceInvite';

import ExpandableSection from '~/containers/ExpandableSection';
import UserManagementQuickViewWrapper from '~/containers/UserManagementQuickViewWrapper';
import SourceClearModal from '~/components/SourceClearModal';
import InviteGroupsQuickView from '~/components/InviteGroupsQuickView';
import Helpers from '~/utils/Helpers';

interface WorkspacePendingInvitesListProps {
  quickViewsByInviteId: object;
  dropdownMenuActions: object;
  expandableSectionActions: object;
  teamUsersActions: object;
  toastrActions: object;
  workspaceInviteActions: object;
  dropdownMenuState: object;
  expandableSectionState: object;
  orgState: App.OrgState;
  workspaceInviteState: object;
  teamId: string;
  showGroups: boolean;
}
class WorkspacePendingInvitesList extends React.Component<WorkspacePendingInvitesListProps, {}> {
  constructor(props, context) {
    super(props, context);

    this.delayCloseDropdown = _.debounce(id => this.closeDropdown(id), 100);
  }

  componentDidMount() {
    const { teamId } = this.props;

    this.props.workspaceInviteActions.fetchWorkspaceInvites(teamId);
  }

  componentWillUnmount() {
    this.props.workspaceInviteActions.resetSelectedPendingInvites();
  }

  closeDropdown(menuId) {
    this.props.dropdownMenuActions.closeDropdownMenu(menuId);
  }

  openDropdown(menuId) {
    this.props.dropdownMenuActions.openDropdownMenu(menuId);
  }

  handleInviteSelection(inviteId) {
    this.props.workspaceInviteActions.handleInviteSelection(inviteId);
  }

  handleSelectAllPendingInvites(selectAll) {
    this.props.workspaceInviteActions.selectAllPendingInvites(selectAll);
  }

  resendInvitations() {
    const { workspaceInviteState, teamId } = this.props;
    const { selectedPendingInvites = [] } = workspaceInviteState;

    this.props.workspaceInviteActions
      .resendInvitations(selectedPendingInvites, teamId)
      .then(res => {
        if (res.success) {
          this.props.toastrActions.addToastr({
            id: 'INVITE_RESEND_SUCCESS',
            level: 'success',
            title: `Invitation${
              selectedPendingInvites.length !== 1 ? 's' : ''
            } successfully resent`,
            message: `${
              selectedPendingInvites.length !== 1 ? 'Invitations have' : 'An invitation has'
            } been resent to the selected invitee${
              selectedPendingInvites.length !== 1 ? 's' : ''
            }.`,
          });
        }
      });
  }

  revokeInvitations() {
    const { workspaceInviteState, teamId } = this.props;
    const { selectedPendingInvites = [] } = workspaceInviteState;

    this.props.workspaceInviteActions
      .revokeInvitations(selectedPendingInvites, teamId)
      .then(res => {
        if (res.success) {
          this.showModal();
        }
      });
  }

  toggleSection(section) {
    const { expandableSectionState } = this.props;
    const { [section]: isExpanded = false } = expandableSectionState;

    this.props.expandableSectionActions.toggleSection(section, !isExpanded);
  }

  handleFilterInput(e) {
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.workspaceInviteActions.filterPendingInvites(value);
  }

  showModal(modalType) {
    this.props.workspaceInviteActions.showModal(modalType);
  }

  toggleWorkspaceInviteQuickView(inviteId, column) {
    this.props.teamUsersActions.toggleWorkspaceInviteQuickView(inviteId, column);
  }

  render() {
    const {
      workspaceInviteState,
      expandableSectionState,
      dropdownMenuState,
      showGroups,
      quickViewsByInviteId = {},
      orgState,
    } = this.props;
    const { org = {} } = orgState;
    const samlDomains = org.samlDomains || [];
    const isSamlOrg = !!samlDomains.length;
    const { openedDropdown = {} } = dropdownMenuState;
    const { pendingWorkspaceInvites: isExpanded = false } = expandableSectionState;
    const {
      pendingInvites = [],
      filteredPendingInvites = [],
      selectedPendingInvites = [],
      activeModal,
    } = workspaceInviteState;
    const columnWidths = {
      email: isSamlOrg ? 'col-1-3' : 'col-1-4',
      direct: isSamlOrg ? '' : 'col-1-12',
      groups: isSamlOrg ? '' : 'col-1-12',
      expiry: 'col-1-6',
      status: 'col-1-12',
      select: 'col-1-18',
    };

    const isSelectAll =
      pendingInvites.length === selectedPendingInvites.length && pendingInvites.length;

    const renderPendingInvites = () => {
      if (!pendingInvites.length) {
        return (
          <div className="mt- font--h3 color--muted-dark">There are no pending invitations.</div>
        );
      } else if (!filteredPendingInvites.length) {
        return <div className="mt- font--h3 color--muted-dark">No invitations found.</div>;
      } else {
        return filteredPendingInvites.map(inviteObj => {
          const { invite = {}, membership = {}, _links = {} } = inviteObj;
          const { id: inviteId, email, orgRole, expirationDate } = invite;
          const { groups = {} } = _links;
          const { direct, numGroups, roles = [] } = membership;
          const isWorkspaceAdmin = roles.includes('WORKSPACE_ADMIN');
          const isSelected = selectedPendingInvites.some(selectedId => selectedId === inviteId);
          const quickview = quickViewsByInviteId[inviteId];

          const renderGroupInvite = () => {
            if (orgRole === 'ADMIN' || orgRole === 'OWNER') {
              return <div>(All)</div>;
            } else if (numGroups) {
              return (
                <div
                  className={`flex justify-content--space-between ${
                    isSamlOrg ? 'col-1-6' : 'col-1-2'
                  } cursor--pointer`}
                  onClick={() => this.toggleWorkspaceInviteQuickView(inviteId, 'GROUPS')}
                >
                  {numGroups}
                  <i
                    className={`sci sci__caret--${
                      quickview === 'GROUPS' ? 'down' : 'right'
                    } color--primary`}
                  />
                </div>
              );
            } else {
              return '(None)';
            }
          };

          return (
            <div className="grid grid--narrower mt-" key={`pending-invite-${inviteId}`}>
              <div className={`grid__item flex align-items--center ${columnWidths.email}`}>
                <div className="text--overflow">{email}</div>
              </div>
              <div
                className={`grid__item flex align-items--center justify-content--center ${columnWidths.admin}`}
              >
                {isWorkspaceAdmin ? 'Yes' : '--'}
              </div>
              {showGroups && (
                <div
                  className={`grid__item flex align-items--center justify-content--center ${columnWidths.groups}`}
                >
                  {renderGroupInvite()}
                </div>
              )}
              {showGroups && (
                <div
                  className={`grid__item flex align-items--center justify-content--center ${columnWidths.direct}`}
                >
                  {direct ? 'Yes' : '--'}
                </div>
              )}
              {!isSamlOrg && (
                <div
                  className={`grid__item flex align-items--center justify-content--center ${columnWidths.status}`}
                >
                  {moment().isAfter(moment(expirationDate)) ? 'Expired' : 'Pending'}
                </div>
              )}
              {!isSamlOrg && (
                <div className={`grid__item flex align-items--center ${columnWidths.expiry}`}>
                  {Helpers.formatDate(expirationDate)}
                </div>
              )}
              <div
                className={`grid__item flex justify-content--center align-items--center ${columnWidths.select}`}
              >
                <label className="label--checkbox flex justify-content--center mb0">
                  <input
                    type="checkbox"
                    name="pending-invite-option"
                    checked={isSelected}
                    onChange={() => this.handleInviteSelection(inviteId)}
                  />
                  <span className="react--checkbox mr0" />
                </label>
              </div>
              <div
                className={`grid__item col-1-1 ${
                  quickview === 'GROUPS' ? 'is-showing--400' : 'is-hiding'
                }`}
              >
                {quickview === 'GROUPS' && (
                  <UserManagementQuickViewWrapper dataType="groups" href={groups.href}>
                    <InviteGroupsQuickView />
                  </UserManagementQuickViewWrapper>
                )}
              </div>
            </div>
          );
        });
      }
    };

    return (
      <div className="col-1-1">
        <div
          className="grid__item col-1-1 mt flex align-items--center cursor--pointer"
          onClick={() => this.toggleSection('pendingWorkspaceInvites')}
        >
          <div className="font--h6">PENDING INVITATIONS</div>
          <div className="ml-">
            <i className={`sci sci__caret--${isExpanded ? 'down' : 'right'} color--primary`} />
          </div>
        </div>
        <ExpandableSection
          id="pendingWorkspaceInvites"
          isExpandedByDefault={false}
          showDefaultToggleControls={false}
          hideWhenCollapsed={true}
          className="col-1-1"
        >
          <div className="col-1-1 bo-b--1 pv mt- bg-color--white-light border-color--white-dark pl">
            <div className="grid">
              <div className="grid__item col-1-1">
                <div className="bo--1 border-color--muted-dark flex justify-content--space-between align-items--center p-">
                  <div className="flex col-3-4">
                    <div className="col-1-2">
                      <input
                        type="text"
                        className="control--text col-1-1"
                        placeholder="Filter by email"
                        onChange={e => this.handleFilterInput(e)}
                      />
                    </div>
                  </div>
                  <div className="">
                    <DropdownMenu
                      isOpen={!!openedDropdown.pendingWorkspaceInvites}
                      close={() => this.delayCloseDropdown('pendingWorkspaceInvites')}
                      menuAlign="right"
                      textAlign="left"
                      toggle={
                        <button
                          className="btn--success p-"
                          onClick={() => this.openDropdown('pendingWorkspaceInvites')}
                          disabled={!selectedPendingInvites.length}
                        >
                          Actions
                        </button>
                      }
                    >
                      <li className="min-width--200 dropdown-menu--li">
                        <button className="pv--" onClick={() => this.resendInvitations()}>
                          Resend
                        </button>
                      </li>
                      <li className="min-width--200 dropdown-menu--li">
                        <button
                          className="pv--"
                          onClick={() => this.showModal('REVOKE_WORKSPACE_INVITES')}
                        >
                          Revoke
                        </button>
                      </li>
                    </DropdownMenu>
                  </div>
                </div>
              </div>
              <div className="grid__item col-1-1 mt">
                <div className="grid grid--narrower bo-b--1 border-color--muted-light pb-">
                  <div className={`grid__item ${columnWidths.email}`}>
                    <strong>Email</strong>
                  </div>
                  <div className={`grid__item text--center ${columnWidths.admin}`}>
                    <strong>Invited as Workspace Admin</strong>
                  </div>
                  {showGroups && (
                    <div className={`grid__item text--center ${columnWidths.groups}`}>
                      <strong>Teams</strong>
                    </div>
                  )}
                  {showGroups && (
                    <div className={`grid__item text--center ${columnWidths.direct}`}>
                      <strong>Direct</strong>
                    </div>
                  )}
                  {!isSamlOrg && (
                    <div className={`grid__item text--center ${columnWidths.status}`}>
                      <strong>Status</strong>
                    </div>
                  )}
                  {!isSamlOrg && (
                    <div className={`grid__item ${columnWidths.expiry}`}>
                      <strong>Expires</strong>
                    </div>
                  )}
                  <div className={`grid__item text--center ${columnWidths.select}`}>
                    <label className="label--checkbox flex justify-content--center mb0">
                      <input
                        type="checkbox"
                        name="select-all-pending-invites"
                        checked={isSelectAll}
                        onChange={() => this.handleSelectAllPendingInvites(!isSelectAll)}
                        disabled={!pendingInvites.length}
                      />
                      <span className="react--checkbox mr0" />
                    </label>
                  </div>
                </div>
              </div>
              <div className="grid__item col-1-1">{renderPendingInvites()}</div>
            </div>
          </div>
        </ExpandableSection>
        <SourceClearModal
          isOpen={activeModal === 'REVOKE_WORKSPACE_INVITES'}
          title="Revoke Invitations"
          onClose={() => this.showModal('')}
          width={500}
        >
          <div className="grid">
            <div className="grid__item col-1-1 mt-">
              <strong>{`Invitation${
                selectedPendingInvites.length === 1 ? '' : 's'
              } to be revoked:`}</strong>
            </div>
            <ul className="grid__item col-1-1 m0 pl+">
              {selectedPendingInvites.map(selectedInviteId => {
                const foundInvite =
                  pendingInvites.find(inviteObj => inviteObj.invite.id === selectedInviteId) || {};

                return (
                  <li key={`invite-to-revoke-${foundInvite.invite.id}`} className="mt-">
                    {foundInvite.invite.email}
                  </li>
                );
              })}
            </ul>
            <div className="grid__item col-1-1 mt">
              <strong>Note:</strong> revoking an invitation will revoke all pending invitations for
              that user. If an administrator besides you invited the user to a different team or
              workspace, those administrators will not be notified when you revoke a user's
              invitation. Invitees will see an error page when clicking on the link in their emails.
              You can invite users again at any time.
            </div>
            <div className="grid__item col-1-1 mt flex justify-content--end">
              <button className="mr- col-1-5 pv-" onClick={() => this.showModal()}>
                Cancel
              </button>
              <button className="btn--primary-clear p-" onClick={() => this.revokeInvitations()}>
                {`Revoke Invitation${selectedPendingInvites.length === 1 ? '' : 's'}`}
              </button>
            </div>
          </div>
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    expandableSectionState: state.expandableSectionState,
    orgState: state.orgState,
    workspaceInviteState: state.workspaceInviteState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    expandableSectionActions: bindActionCreators(expandableSectionActions, dispatch),
    teamUsersActions: bindActionCreators(teamUsersActions, dispatch),
    toastrActions: bindActionCreators(toastrActions, dispatch),
    workspaceInviteActions: bindActionCreators(workspaceInviteActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspacePendingInvitesList);
