import React, { useEffect } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import ReportOptionsFilter from '~/components/ReportComponents/ReportOptionsFilter';
import * as reportActions from '~/actions/reports';
import * as reportFilterActions from '~/actions/reportFilters';
import * as MODEL from '~/constants/ModelConstants';

interface LicenseRiskOptionsFilterProps {
  licenseRisks: string[];
  projectId?: string;
  teamId?: string;
  reportType?: string;
}
function LicenseRiskOptionsFilter(props: LicenseRiskOptionsFilterProps) {
  const { reportType } = props;
  const dispatch = useDispatch();

  useEffect(
    () => {
      // cleanup when component is unmounted
      return () => {
        updateOptionsFilter('licenseRisks', []);
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function refreshReportData() {
    const { teamId } = props;

    dispatch(reportActions.fetchReport(teamId, reportType));
  }

  function updateOptionsFilter(field, value) {
    const { projectId } = props;

    dispatch(reportFilterActions.updateFilterValue(reportType, field, value, projectId));
    dispatch(reportFilterActions.updateFilterArray(reportType, field, value, projectId));
  }

  function updateOptionsFilterAndRefresh(field, value) {
    updateOptionsFilter(field, value);
    refreshReportData();
  }

  const { licenseRisks } = props;

  // it does not make sense to filter a license report by 'unrecognized' as 'unrecognized' means,
  // from a BE data perspective, we simply do not have any license data related to a library
  // so we hide that item in the select menu when viewed from the LicensesPage
  const unRecognizedRiskIndex = MODEL.licenseRiskSeverityOptions.findIndex(
    option => option.value === MODEL.UNRECOGNIZED_LICENSE_RISK
  );

  const options =
    reportType === 'LICENSES'
      ? produce(MODEL.licenseRiskSeverityOptions, draft => {
          draft.splice(unRecognizedRiskIndex, 1);
        })
      : MODEL.licenseRiskSeverityOptions;

  return (
    <ReportOptionsFilter
      value={licenseRisks}
      options={options}
      onChange={(field, val) => updateOptionsFilterAndRefresh(field, val)}
      isMulti={true}
      isClearable={true}
      field="licenseRisks"
      placeholder="License risk"
      automationId="ReportOptionsFilter-LicenseRisk"
    />
  );
}

export default LicenseRiskOptionsFilter;
