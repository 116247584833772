import * as actions from '~/actions/license';
import produce from 'immer';
import { LicenseState } from '~/types/license';

const defaultState: LicenseState = {
  isFetching: false,
  licenses: [],
  licensesByRisk: [],
  message: '',
};

const licenseState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.UPDATE_LICENSES:
        draft.isFetching = false;
        draft.licenses = action.licenses;
        break;
      case actions.FETCH_LICENSES_REQUEST:
        draft.isFetching = true;
        break;
      case actions.FETCH_LICENSES_REQUEST_ERROR:
        draft.isFetching = false;
        draft.message = action.message;
        break;
      case actions.UPDATE_LICENSES_BY_RISK:
        draft.isFetching = false;
        draft.licensesByRisk = action.licensesByRisk;
        break;
      case actions.FETCH_LICENSES_BY_RISK:
        draft.isFetching = true;
        break;
      case actions.FETCH_LICENSES_BY_RISK_ERROR:
        draft.isFetching = false;
        draft.message = action.message;
        break;
    }
  });
};

export default licenseState;
