import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import Helpers from '~/utils/Helpers';

import * as toastrActions from '~/actions/toastr';

import {
  REPO_EVENTS,
  TRIGGER_EVENTS_OPTIONS,
  NOTIFICATION_TYPES,
} from '~/constants/ModelConstants';

export const UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS_FIELD_VALUE =
  'UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS_FIELD_VALUE';
export const FETCH_WORKSPACE_NOTIFICATIONS_SETTINGS_REQUEST =
  'FETCH_WORKSPACE_NOTIFICATIONS_SETTINGS_REQUEST';
export const FETCH_WORKSPACE_NOTIFICATIONS_SETTINGS_SUCCESS =
  'FETCH_WORKSPACE_NOTIFICATIONS_SETTINGS_SUCCESS';
export const FETCH_WORKSPACE_NOTIFICATIONS_SETTINGS_FAILURE =
  'FETCH_WORKSPACE_NOTIFICATIONS_SETTINGS_FAILURE';
export const SAVE_WORKSPACE_NOTIFICATIONS_SETTINGS_REQUEST =
  'SAVE_WORKSPACE_NOTIFICATIONS_SETTINGS_REQUEST';
export const SAVE_WORKSPACE_NOTIFICATIONS_SETTINGS_SUCCESS =
  'SAVE_WORKSPACE_NOTIFICATIONS_SETTINGS_SUCCESS';
export const SAVE_WORKSPACE_NOTIFICATIONS_SETTINGS_FAILURE =
  'SAVE_WORKSPACE_NOTIFICATIONS_SETTINGS_FAILURE';
export const RESET_WORKSPACE_NOTIFICATIONS_SETTINGS_CONTROLS =
  'RESET_WORKSPACE_NOTIFICATIONS_SETTINGS_CONTROLS';
export const UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS_CONTROLS =
  'UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS_CONTROLS';
export const UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS_SELECTED_ROWS =
  'UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS_SELECTED_ROWS';
export const CLEAR_WORKSPACE_NOTIFICATIONS_SETTINGS_SELECTED_ROWS =
  'CLEAR_WORKSPACE_NOTIFICATIONS_SETTINGS_SELECTED_ROWS';
export const DELETE_WORKSPACE_NOTIFICATIONS_SETTINGS_REQUEST =
  'DELETE_WORKSPACE_NOTIFICATIONS_SETTINGS_REQUEST';
export const DELETE_WORKSPACE_NOTIFICATIONS_SETTINGS_SUCCESS =
  'DELETE_WORKSPACE_NOTIFICATIONS_SETTINGS_SUCCESS';
export const DELETE_WORKSPACE_NOTIFICATIONS_SETTINGS_FAILURE =
  'DELETE_WORKSPACE_NOTIFICATIONS_SETTINGS_FAILURE';

const { JUST_SCAN_EVENT, ALL_EVENTS } = TRIGGER_EVENTS_OPTIONS;

export const updateSelectedRows = (notificationType, id) => ({
  type: UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS_SELECTED_ROWS,
  notificationType,
  id,
});

export const clearSelectedRows = notificationType => ({
  type: CLEAR_WORKSPACE_NOTIFICATIONS_SETTINGS_SELECTED_ROWS,
  notificationType,
});

export const updateWorkspaceNotificationsSettingsFieldValue = (notificationType, field, value) => ({
  type: UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS_FIELD_VALUE,
  notificationType,
  field,
  value,
});

export const fetchWorkspaceNotificationsSettingsRequest = notificationType => ({
  type: FETCH_WORKSPACE_NOTIFICATIONS_SETTINGS_REQUEST,
  notificationType,
});

export const fetchWorkspaceNotificationsSettingsSuccess = (notificationType, data) => ({
  type: FETCH_WORKSPACE_NOTIFICATIONS_SETTINGS_SUCCESS,
  notificationType,
  data,
});

export const fetchWorkspaceNotificationsSettingsFailure = error => ({
  type: FETCH_WORKSPACE_NOTIFICATIONS_SETTINGS_FAILURE,
  message: error.message,
});

export const updateWorkspaceNotificationsSettingsControls = (notificationType, event) => ({
  type: UPDATE_WORKSPACE_NOTIFICATIONS_SETTINGS_CONTROLS,
  notificationType,
  event,
});

export const fetchWorkspaceNotificationsSettingsByType = (notificationType, teamId) => dispatch => {
  dispatch(fetchWorkspaceNotificationsSettingsRequest(notificationType));

  const path = notificationType === NOTIFICATION_TYPES.WEBHOOK ? 'webhooks' : ''; //TODO: to be updated/refined with email notifications
  const endpoint = `/teams/${teamId}/${path}`;

  return ApiService.get(endpoint)
    .then(data => {
      dispatch(fetchWorkspaceNotificationsSettingsSuccess(notificationType, data));
    })
    .catch(error => {
      dispatch(fetchWorkspaceNotificationsSettingsFailure(error));
      ErrorService.capture('Error fetching notifications settings', error);
      dispatch(
        toastrActions.addToastr({
          id: `FETCH_FAILED_${notificationType}`,
          level: 'error',
          title: 'Error fetching notification settings',
          message:
            Helpers.getFriendlyErrorMsg(error) ||
            'There was an error while fetching your notification settings. Please try again.',
        })
      );
    });
};

export const saveWorkspaceNotificationsSettingsRequest = () => ({
  type: SAVE_WORKSPACE_NOTIFICATIONS_SETTINGS_REQUEST,
});

export const saveWorkspaceNotificationsSettingsSuccess = () => ({
  type: SAVE_WORKSPACE_NOTIFICATIONS_SETTINGS_SUCCESS,
});

export const saveWorkspaceNotificationsSettingsFailure = error => ({
  type: SAVE_WORKSPACE_NOTIFICATIONS_SETTINGS_FAILURE,
  message: error.message,
});

export const resetWorkspaceNotificationsSettingsControls = notificationType => ({
  type: RESET_WORKSPACE_NOTIFICATIONS_SETTINGS_CONTROLS,
  notificationType,
});

export const saveWebhookWorkspaceNotificationsSettings = (teamId, webhookId) => (
  dispatch,
  getState
) => {
  dispatch(toastrActions.clearToastrs());
  dispatch(saveWorkspaceNotificationsSettingsRequest());

  const { workspaceNotificationsSettingsState } = getState();
  const {
    [NOTIFICATION_TYPES.WEBHOOK]: notificationSettings = {},
  } = workspaceNotificationsSettingsState;
  const { controls = {} } = notificationSettings;
  const { url = '', contentType = '', triggerEventsOption = '', individualEvents = [] } = controls;

  const maybeAllEvents =
    triggerEventsOption === ALL_EVENTS
      ? [
          REPO_EVENTS.SCAN_SUCCESS,
          REPO_EVENTS.VULN_ISSUES_DISCOVERED_AFTER_SCAN,
          REPO_EVENTS.VULN_ISSUES_CHANGED_AFTER_SCAN,
        ]
      : individualEvents;
  const events =
    triggerEventsOption === JUST_SCAN_EVENT ? [REPO_EVENTS.SCAN_SUCCESS] : maybeAllEvents;

  const options = {
    url,
    contentType,
    events,
  };

  const endpoint = webhookId
    ? `/teams/${teamId}/webhooks/${webhookId}`
    : `/teams/${teamId}/webhooks`;
  const apiCall = webhookId
    ? ApiService.put(endpoint, { data: options })
    : ApiService.post(endpoint, { data: options });

  return apiCall
    .then(() => {
      dispatch(resetWorkspaceNotificationsSettingsControls(NOTIFICATION_TYPES.WEBHOOK));
      dispatch(saveWorkspaceNotificationsSettingsSuccess());
      dispatch(
        toastrActions.addToastr({
          id: 'SAVE_WEBHOOK_NOTF_SUCCESS',
          level: 'success',
          title: 'Settings successfully saved',
          message: 'Changes to your webhook notification settings have been successfully saved.',
        })
      );
      return { success: true };
    })
    .catch(error => {
      dispatch(saveWorkspaceNotificationsSettingsFailure(error));

      ErrorService.capture('Error saving notifications', error);
      dispatch(
        toastrActions.addToastr({
          id: 'SAVE_WEBHOOK_NOTF_ERROR',
          level: 'error',
          title: 'Error saving notification settings',
          message:
            Helpers.getFriendlyErrorMsg(error) ||
            'There was an error while saving changes to your notification settings. Please check that all information is accurate and try again.',
        })
      );

      return {};
    });
};

export const deleteWorkspaceNotificationsSettingsRequest = notificationType => ({
  type: DELETE_WORKSPACE_NOTIFICATIONS_SETTINGS_REQUEST,
  notificationType,
});

export const deleteWorkspaceNotificationsSettingsSuccess = notificationType => ({
  type: DELETE_WORKSPACE_NOTIFICATIONS_SETTINGS_SUCCESS,
  notificationType,
});

export const deleteWorkspaceNotificationsSettingsFailure = (notificationType, error) => ({
  type: DELETE_WORKSPACE_NOTIFICATIONS_SETTINGS_FAILURE,
  notificationType,
  message: error.message,
});

export const deleteWebhookNotifications = (teamId, webhookIds) => dispatch => {
  if (!webhookIds || !webhookIds.length) {
    return;
  }

  const endpoint = `/teams/${teamId}/webhooks`;
  const delRequestPromises = webhookIds.map(webhookId =>
    ApiService.del(`${endpoint}/${webhookId}`)
  );
  dispatch(deleteWorkspaceNotificationsSettingsRequest(NOTIFICATION_TYPES.WEBHOOK));

  return Promise.all(delRequestPromises)
    .then(() => {
      dispatch(fetchWorkspaceNotificationsSettingsByType(NOTIFICATION_TYPES.WEBHOOK, teamId));
      dispatch(clearSelectedRows(NOTIFICATION_TYPES.WEBHOOK));
      dispatch(deleteWorkspaceNotificationsSettingsSuccess(NOTIFICATION_TYPES.WEBHOOK));
    })
    .catch(error => {
      dispatch(deleteWorkspaceNotificationsSettingsFailure(NOTIFICATION_TYPES.WEBHOOK, error));
      ErrorService.capture('Error deleting selected notifications', error);
      dispatch(
        toastrActions.addToastr({
          id: 'DEL_WORKSPACE_WEBHOOK_NOTF_ERROR',
          level: 'error',
          title: 'Error deleting selected notifications',
          message:
            Helpers.getFriendlyErrorMsg(error) ||
            'There was an error while deleting selected webhook notifications Please try again.',
        })
      );
    });
};
