import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import AuthService from '~/utils/AuthService';
import config from '~/config';
import _ from 'lodash';

import * as toastrActions from '~/actions/toastr';
import * as modalActions from '~/actions/modal';
import * as teamActions from '~/actions/team';
import * as meActions from '~/actions/me';
import * as orgUserActions from '~/actions/orgUser';

export const ORG_NAME_SAVE_SUCCESS = 'ORG_NAME_SAVE_SUCCESS';
export const ORG_SLUG_INVALID = 'ORG_SLUG_INVALID';
export const RESET_NEW_WORKSPACE_FLOW = 'RESET_NEW_WORKSPACE_FLOW';
export const SHOW_ORG_MODAL = 'SHOW_ORG_MODAL';
export const UPDATE_NEW_WORKSPACE_NAME = 'UPDATE_NEW_WORKSPACE_NAME';
export const UPDATE_NEW_WORKSPACE_STEP = 'UPDATE_NEW_WORKSPACE_STEP';
export const UPDATE_NEW_WORKSPACE_TAB = 'UPDATE_NEW_WORKSPACE_TAB';
export const UPDATE_ORG_NAME_VALUE = 'UPDATE_ORG_NAME_VALUE';
export const UPDATE_ORG = 'UPDATE_ORG';
export const UPDATING_USER_ROLE = 'UPDATING_USER_ROLE';

export const FETCH_SAML_SETUP_REQUEST = 'FETCH_SAML_SETUP_REQUEST';
export const FETCH_SAML_SETUP_SUCCESS = 'FETCH_SAML_SETUP_SUCCESS';
export const FETCH_SAML_SETUP_FAILURE = 'FETCH_SAML_SETUP_FAILURE';

export const UPDATE_SAML_DOMAINS_VALUE = 'UPDATE_SAML_DOMAINS_VALUE';
export const UPDATE_SAML_METADATA_VALUE = 'UPDATE_SAML_METADATA_VALUE';
export const UPDATE_SAML_GROUP_NAME_ATTR_VALUE = 'UPDATE_SAML_GROUP_NAME_ATTR_VALUE';
export const TOGGLE_SAML_ALLOW_PASSWORD_LOGIN = 'TOGGLE_SAML_ALLOW_PASSWORD_LOGIN';

export const SAVE_SAML_SETUP_REQUEST = 'SAVE_SAML_SETUP_REQUEST';
export const SAVE_SAML_SETUP_SUCCESS = 'SAVE_SAML_SETUP_SUCCESS';
export const SAVE_SAML_SETUP_FAILURE = 'SAVE_SAML_SETUP_FAILURE';

export const UPDATE_SAML_SETUP_MODE = 'UPDATE_SAML_SETUP_MODE';
export const CANCEL_SAML_SETUP_EDIT = 'CANCEL_SAML_SETUP_EDIT';

export const UPDATE_FIELD_VALIDATION = 'UPDATE_FIELD_VALIDATION';
export const RESET_ORG_VALIDATIONS = 'RESET_ORG_VALIDATIONS';

export const removeUserFromOrg = (orgId, userId) => dispatch => {
  const endpoint = `/orgs/${orgId}/users/${userId}`;

  return ApiService.del(endpoint)
    .then(
      () => {
        dispatch(orgUserActions.fetchOrgUsers(undefined, true));
        return { success: true };
      },
      err => {
        dispatch(
          toastrActions.addToastr({
            id: `REMOVE ${orgId} ERROR`,
            level: 'error',
            title: 'Error removing user from organization',
            message:
              err.message ||
              'We ran into an error removing the user from the organization. Please try again later',
            disableTimeout: true,
          })
        );
        return {};
      }
    )
    .catch(error => {
      dispatch(
        toastrActions.addToastr({
          id: `REMOVE ${orgId} ERROR`,
          level: 'error',
          title: 'Error removing user from organization',
          message:
            error.message ||
            'We ran into an error removing the user from the organization. Please try again later',
          disableTimeout: true,
        })
      );
      ErrorService.capture('Error removing user from org', error);
      return {};
    });
};

export const createWorkspace = (value, orgId) => dispatch => {
  const endpoint = `/orgs/${orgId}/teams`;

  return ApiService.post(endpoint, { data: { name: value } })
    .then(
      res => {
        return dispatch(teamActions.fetchTeams()).then(() => {
          return { success: true, teamId: res.id };
        });
      },
      err => {
        dispatch(
          toastrActions.addToastr({
            id: 'Create-workspace-error',
            level: 'error',
            title: 'Error creating a workspace',
            message:
              err.message || 'We ran into an error creating this workspace, please try again later',
            disableTimeout: true,
          })
        );
        return {};
      }
    )
    .catch(error => {
      dispatch(
        toastrActions.addToastr({
          id: 'Create-workspace-error',
          level: 'error',
          title: 'Error creating a workspace',
          message:
            error.message || 'We ran into an error creating this workspace, please try again later',
          disableTimeout: true,
        })
      );
      return {};
    });
};

/*
 * The Method above can be deleted once the groupsusers feature flag is no longer relevant
 */
export const createWorkspaceWithGroups = teamData => (dispatch, getState) => {
  const { orgState } = getState();
  const { org = {} } = orgState;

  return ApiService.post(`/orgs/${org.id}/teams`, { data: teamData })
    .then(
      res => {
        return dispatch(teamActions.fetchTeams()).then(() => {
          return res;
        });
      },
      err => {
        dispatch(
          toastrActions.addToastr({
            id: 'Create-workspace-error',
            level: 'error',
            title: 'Error creating a workspace',
            message:
              err.message || 'We ran into an error creating this workspace, please try again later',
            disableTimeout: true,
          })
        );
        return {};
      }
    )
    .catch(error => {
      dispatch(
        toastrActions.addToastr({
          id: 'Create-workspace-error',
          level: 'error',
          title: 'Error creating a workspace',
          message:
            error.message || 'We ran into an error creating this workspace, please try again later',
          disableTimeout: true,
        })
      );
      return {};
    });
};

export const updateNewWorkspaceName = value => ({
  type: UPDATE_NEW_WORKSPACE_NAME,
  value,
});

export const resetNewWorkspaceFlow = () => ({
  type: RESET_NEW_WORKSPACE_FLOW,
});

export const updateNewWorkspaceStep = (step = 1) => ({
  type: UPDATE_NEW_WORKSPACE_STEP,
  step,
});

export const updateOrgNameValue = value => ({
  type: UPDATE_ORG_NAME_VALUE,
  value,
});

export const fetchSamlSetupRequest = () => ({
  type: FETCH_SAML_SETUP_REQUEST,
});

export const fetchSamlSetupSuccess = samlSetup => ({
  type: FETCH_SAML_SETUP_SUCCESS,
  samlSetup,
});

export const fetchSamlSetupFailure = error => ({
  type: FETCH_SAML_SETUP_FAILURE,
  message: error.message,
});

export const saveSamlSetupRequest = () => ({
  type: SAVE_SAML_SETUP_REQUEST,
});

export const saveSamlSetupSuccess = () => ({
  type: SAVE_SAML_SETUP_SUCCESS,
});

export const saveSamlSetupFailure = error => ({
  type: SAVE_SAML_SETUP_FAILURE,
  message: error.message,
});

export const updateSamlSetupMode = mode => ({
  type: UPDATE_SAML_SETUP_MODE,
  mode,
});

export const cancelSamlSetupEdit = () => ({
  type: CANCEL_SAML_SETUP_EDIT,
});

export const updateSamlDomainsValue = value => ({
  type: UPDATE_SAML_DOMAINS_VALUE,
  value,
});

export const updateSamlMetadataValue = value => ({
  type: UPDATE_SAML_METADATA_VALUE,
  value,
});

export const updateSamlGroupNameAttr = value => ({
  type: UPDATE_SAML_GROUP_NAME_ATTR_VALUE,
  value,
});

export const toggleSamlAllowOrgOwnerPasswordLogin = value => ({
  type: TOGGLE_SAML_ALLOW_PASSWORD_LOGIN,
  value,
});

export const fetchSamlSetup = () => (dispatch, getState) => {
  const { orgState } = getState();
  const { org = {} } = orgState;
  const { id: orgId } = org;

  const endpoint = `/orgs/${orgId}/saml`;

  dispatch(fetchSamlSetupRequest());

  const badOutcome = err => {
    if (err && 404 !== err.status) {
      dispatch(
        toastrActions.addToastr({
          id: 'FETCH_SAML_SETUP_ERROR',
          level: 'error',
          title: 'Error fetching SAML setup',
          message: err.message || 'We ran into an error fetching SAML configurations.',
          disableTimeout: true,
        })
      );
    }
    dispatch(fetchSamlSetupFailure(err));
  };

  return ApiService.get(endpoint)
    .then(res => {
      dispatch(fetchSamlSetupSuccess(res));
    }, badOutcome)
    .catch(badOutcome);
};

export const saveSamlSetup = orgId => (dispatch, getState) => {
  const { orgState } = getState();
  const { samlInputRaw, saml } = orgState;
  const {
    /** @type String */ samlDomains = '',
    samlMetadata: metadataXml = '',
    /** @type String */ groupNameAttribute = '',
  } = samlInputRaw;
  const { samlDomains: currSamlDomains = [], samlMetadata: currSamlMetadata = '' } = saml;
  const hasExistingSamlSetup = currSamlDomains.length > 0 && currSamlMetadata;

  dispatch(saveSamlSetupRequest());

  const endpoint = `/orgs/${orgId}/saml`;
  const undedupedEmailDomains = samlDomains.split(/\s+|,/).filter(it => !!it);
  const emailDomains = _.uniq(undedupedEmailDomains);
  const trimmedGroupName = groupNameAttribute && groupNameAttribute.trim();
  // for groupNameAttribute, best if we don't send empty string, but rather an explicit null
  const groupNameData = trimmedGroupName.length === 0 ? null : trimmedGroupName;
  const data = {
    emailDomains,
    metadataXml,
    //when org owner is using password, don't include in saml login otherwise include.
    includingOrgOwner: false,
    groupNameAttribute: groupNameData,
  };
  const badOutcome = err => {
    let msg = err.message;
    const fieldErrs = err.fieldErrors;
    if (!msg && fieldErrs) {
      msg = _.keys(fieldErrs)
        .map(k => `field "${k}": ${fieldErrs[k]}`)
        .join(',');
    }
    if (!msg) {
      msg = 'We ran into an error saving your changes.';
    }
    dispatch(
      toastrActions.addToastr({
        id: 'SAML_SETUP_ERROR',
        level: 'error',
        title: 'Error saving SAML setup',
        message: msg,
        disableTimeout: true,
      })
    );
    dispatch(saveSamlSetupFailure(err));
    dispatch(showModal());
  };
  return ApiService.post(endpoint, { data })
    .then(() => {
      const toastrTitle = hasExistingSamlSetup
        ? 'SAML setup saved successfully'
        : 'SAML setup complete';
      const toastrMessage = hasExistingSamlSetup
        ? 'Changes to your SAML setup have been saved successfully. Your organization will now use this SAML configuration for single sign-on.'
        : 'Your organization is now set up to perform single sign-on through SAML.';
      dispatch(
        toastrActions.addToastr({
          id: 'SAML_SETUP_COMPLETE',
          level: 'success',
          title: toastrTitle,
          message: toastrMessage,
        })
      );
      dispatch(saveSamlSetupSuccess());
      dispatch(fetchSamlSetup());
      dispatch(updateSamlSetupMode('VIEW'));
      dispatch(showModal());
    }, badOutcome)
    .catch(badOutcome);
};

export const updateFieldValidation = (formGroup, field, isInvalid = false, error = []) => ({
  type: UPDATE_FIELD_VALIDATION,
  formGroup,
  field,
  isInvalid,
  error,
});

export const validateField = (formGroup, field) => (dispatch, getState) => {
  const { orgState } = getState();
  const { samlInputRaw = {} } = orgState;
  const { /** @type String */ samlDomains, samlMetadata } = samlInputRaw;

  if (field === 'samlMetadata') {
    /** @type String[] */
    const errors = [];

    /* eslint-disable no-control-regex */
    if (/[^\u0009-\u007F]/.test(samlMetadata)) {
      const badChars = samlMetadata.replace(/[\u0009-\u007F]/gi, '');
      const badCharsArr = badChars ? _.uniq(badChars.split('')) : [];
      const badCharsText = badCharsArr.join(', ');
      errors.push(`XML metadata must not contain non-ASCII characters: ${badCharsText}`);
    }
    /* eslint-enable no-control-regex */

    if (errors.length > 0) {
      //takes in form group, field, isInvalid, array of errors
      dispatch(updateFieldValidation(formGroup, field, true, errors));
      return;
    }
  } else if ('samlDomains' === field && samlDomains) {
    const samlDomainParts = samlDomains.split(/\s+|,/).filter(it => !!it);
    const suspiciousDomains = samlDomainParts
      .filter(it => it.indexOf('.') === -1)
      .map(it => `Domain "${it}" should contain at least one dot`);
    const notEmails = samlDomainParts
      .filter(it => it.indexOf('@') !== -1)
      .map(it => `Value "${it}" should only be the domain, not an email`);
    /** @type String[] */
    const errors = suspiciousDomains.concat(notEmails);
    if (errors.length > 0) {
      dispatch(updateFieldValidation(formGroup, field, true, errors));
      return;
    }
  }

  dispatch(updateFieldValidation(formGroup, field, false, [])); //clear validation errors
};

export const resetOrgValidations = () => ({
  type: RESET_ORG_VALIDATIONS,
});

export const saveOrgName = (value, orgId) => dispatch => {
  const endpoint = `/orgs/${orgId}`;
  const options = {
    name: value,
    slug: orgId,
  };

  return ApiService.put(endpoint, { data: options })
    .then(
      () => {
        dispatch(orgNameSaveSuccess());
      },
      err => {
        dispatch(meActions.fetchMe());
        dispatch(
          toastrActions.addToastr({
            id: 'update-org-error',
            level: 'error',
            title: 'Error editing org name',
            message: err.message || 'We ran into an error editing this org, please try again later',
            disableTimeout: true,
          })
        );
      }
    )
    .catch(error => {
      dispatch(meActions.fetchMe());
      dispatch(
        toastrActions.addToastr({
          id: 'update-org-error',
          level: 'error',
          title: 'Error editing org name',
          message: error.message || 'We ran into an error editing this org, please try again later',
          disableTimeout: true,
        })
      );
    });
};

export const orgNameSaveSuccess = () => ({
  type: ORG_NAME_SAVE_SUCCESS,
});

export const deleteOrg = orgId => dispatch => {
  ApiService.del(`/orgs/${orgId}`)
    .then(
      () => {
        // This is the grand-daddy of destructive actions
        // we need to destroy any session cookies and redirect to the app homepage
        AuthService.setAuthToken();
        window.location = config.FRONTEND_HOST_URL;
      },
      err => {
        dispatch(
          toastrActions.addToastr({
            id: 'delete-org-error',
            level: 'error',
            title: 'Error deleting organization',
            message:
              err.message || 'We ran into an error deleting this org, please try again later',
            disableTimeout: true,
          })
        );
      }
    )
    .catch(error => {
      dispatch(
        toastrActions.addToastr({
          id: 'delete-org-error',
          level: 'error',
          title: 'Error deleting organization',
          message:
            error.message || 'We ran into an error deleting this org, please try again later',
          disableTimeout: true,
        })
      );
      ErrorService.capture('Error deleting org', error);
    });
};

export const updatingUserRole = state => ({
  type: UPDATING_USER_ROLE,
  state,
});

export const updateUserStatus = (orgId, userId, status, withGroups) => (dispatch, getState) => {
  dispatch(updatingUserRole('SAVING'));
  return ApiService.put(`/orgs/${orgId}/users/${userId}`, { data: { role: status } })
    .then(
      () => {
        const { myState } = getState();
        const { me = {} } = myState;
        dispatch(updatingUserRole('SUCCESS'));
        setTimeout(() => {
          dispatch(updatingUserRole(undefined));
        }, 6000);

        dispatch(orgUserActions.fetchOrgUsers(undefined, withGroups));

        if (me.id === userId) {
          dispatch(meActions.fetchMe());
        }

        return { success: true };
      },
      err => {
        dispatch(updatingUserRole(undefined));
        dispatch(
          toastrActions.addToastr({
            id: 'user-status-update-error',
            level: 'error',
            title: "Error updating the user's status",
            message:
              err.message ||
              "There was an error changing the user's status, please try again later",
            disableTimeout: true,
          })
        );
        return {};
      }
    )
    .catch(error => {
      dispatch(updatingUserRole(undefined));
      dispatch(
        toastrActions.addToastr({
          id: 'user-status-update-error',
          level: 'error',
          title: "Error updating the user's status",
          message:
            error.message ||
            "There was an error changing the user's status, please try again later",
          disableTimeout: true,
        })
      );
      ErrorService.capture('Error deleting org', error);
      return {};
    });
};

export const updateOrg = org => dispatch => {
  dispatch({
    type: UPDATE_ORG,
    org,
  });
  dispatch(checkOrgForOverages(org));
};

export const checkOrgForOverages = org => dispatch => {
  const { license = {} } = org;
  const { usage = {} } = license;
  const limits = license.limits || {};
  const options = ['repos', 'teams', 'users'];

  const hasLimitExceeded = options.some(option => limits[option] && usage[option] > limits[option]);

  if (hasLimitExceeded) {
    dispatch(modalActions.openModal('OVERAGES_MODAL'));
  }
};

export const orgSlugInvalid = () => ({
  type: ORG_SLUG_INVALID,
});

export const showModal = modalType => ({
  type: SHOW_ORG_MODAL,
  modalType,
});

export const updateNewWorkspaceTab = tab => ({
  type: UPDATE_NEW_WORKSPACE_TAB,
  tab,
});
