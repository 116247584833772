import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash';

import * as quickviewActions from '~/actions/quickview';
import * as upgradeModalActions from '~/actions/upgradeModal';

import * as MODEL from '~/constants/ModelConstants';

import { QuickviewClosedIcon, QuickviewOpenIcon } from '~/components/Icons';
import VulnReposQuickview from '~/components/ReportComponents/VulnReposQuickview';
import SeverityFlag from '~/components/ReportComponents/SeverityFlag';
import ProIcon from '~/components/ProIcon';
import Tooltip from '~/components/Tooltip';
import Helpers from '~/utils/Helpers';
import ArtifactHelper from '~/utils/ArtifactHelper';
import RegistryLink from '~/components/RegistryLink';
import LoaderWrapper from '~/components/LoaderWrapper';

import 'velocity-animate';
import 'velocity-animate/velocity.ui';
import { VelocityTransitionGroup } from 'velocity-react';
import Animations from '~/utils/Animations';

import {
  VulnerabilitiesRowProps,
  VulnerabilitiesPageMatchParams,
} from '~/containers/VulnerabilitiesPage.types';

class VulnerabilitiesRow extends Component<
  VulnerabilitiesRowProps & ReturnType<typeof mapDispatchToProps>
> {
  toggleQuickview = (quickviewId, quickviewLink) => {
    const { currentOpenRowId = '' } = this.props.quickviewState;
    if (currentOpenRowId !== quickviewId) {
      this.props.quickviewActions.loadQuickview(quickviewId, quickviewLink);
      this.props.quickviewActions.setCurrentOpenRow(quickviewId);
    } else {
      this.props.quickviewActions.setCurrentOpenRow('');
    }
  };

  onQuickviewPageClick = (quickviewId, link, page) => {
    this.props.quickviewActions.loadQuickview(quickviewId, link, page);
  };

  toggleCtaVulnModalOpen = location => {
    const { params }: { params: VulnerabilitiesPageMatchParams } = this.props;
    const { teamId } = params;
    this.props.upgradeModalActions.showUpgradeModal(
      MODEL.UPGRADE_MODAL_VULN_METHODS,
      teamId,
      location
    );
  };

  toggleCtaPremiumVulnModalOpen = location => {
    const { params }: { params: VulnerabilitiesPageMatchParams } = this.props;
    const { teamId } = params;
    this.props.upgradeModalActions.showUpgradeModal(
      MODEL.UPGRADE_MODAL_PREMIUM_VULN,
      teamId,
      location
    );
  };

  render() {
    const {
      data,
      rowId,
      quickviewState,
      params,
      isSingleRepoScope,
      isPaidOrTrialing = false,
      columnWidths,
      isContainerProject = false,
    } = this.props;
    const {
      _links,
      libraryName,
      libraryVersion,
      repos,
      severity,
      title,
      vulnMethods,
      commitHash,
      cveStatus,
      cve,
    } = data;
    const { currentOpenRowId = '', dataByRowId = {}, isLoading = true } = quickviewState;
    const reposQuickviewId = `${rowId}-repos`;
    const { vulnerability = {}, repos: reposLink = {} } = _links;
    const vulnerabilityLink = vulnerability.href || '';
    const reposQuickviewLink = reposLink.href || '';

    const libraryRegistryLink = (_links && _links.library && _links.library.href) || '';

    const libraryRegistryCommitHashLink =
      libraryRegistryLink.includes('?version=HEAD') && commitHash
        ? `${libraryRegistryLink.split('?version=HEAD')[0]}?commit_hash=${commitHash}`
        : null;
    const { [reposQuickviewId]: reposQuickviewData = {} } = dataByRowId;

    const showPremiumVulnPaywall =
      ArtifactHelper.isArtifactPremiumByStatus(cveStatus) && !isPaidOrTrialing;

    const getLibraryVersionText = () => {
      if (commitHash) {
        return (
          <Tooltip
            fullWidth={true}
            place="right"
            id={`${rowId}-commitHash`}
            content={`Commit Hash: ${commitHash}`}
          >
            {libraryVersion} ({Helpers.shortedCommitHash(commitHash)})
          </Tooltip>
        );
      } else if (libraryVersion === 'HEAD') {
        return (
          <Tooltip
            fullWidth={true}
            place="right"
            id={`${rowId}-commitHash`}
            content={`Can't detect comission hash for this library`}
          >
            {libraryVersion}
          </Tooltip>
        );
      } else {
        return <span> {libraryVersion} </span>;
      }
    };

    const renderVulnMethods = () => {
      if (isPaidOrTrialing) {
        return vulnMethods ? (
          <Tooltip
            place="top"
            width="width-200"
            content="Call graph analysis shows a vulnerable method may be invoked"
            id={`${rowId}--vulnMethodCol`}
          >
            <i className="sci sci__shield--urgent font--h6 color--danger" />
          </Tooltip>
        ) : (
          <span>--</span>
        );
      } else {
        return (
          <div
            className="cursor--pointer"
            onClick={() => this.toggleCtaVulnModalOpen('VULN_LIST_VULN_METHODS_COLUMN')}
          >
            <ProIcon />
          </div>
        );
      }
    };

    return (
      <div className="bo--1 border-color--white-dark pl- pv-- mv---" data-e2e="report-row">
        <div className="grid grid--middle grid--narrower flex flex--align-items--stretch">
          <div
            className={`grid__item flex flex--align-items--center flex--justify-content--center ${columnWidths.severity}`}
            data-automation-id="RowCell"
          >
            <Tooltip id={`${rowId}-vulnRow`} content={Helpers.capFirst(severity)}>
              <SeverityFlag severity={severity} hideText={true} />
            </Tooltip>
          </div>
          <div
            className={`grid__item flex flex--align-items--center ${columnWidths.vulnId}`}
            data-automation-id="RowCell"
          >
            {showPremiumVulnPaywall || !cve ? 'No CVE' : `CVE-${cve}`}
          </div>
          <div
            className={`grid__item flex flex--align-items--center ${columnWidths.vulnerability}`}
            data-automation-id="RowCell"
          >
            <RegistryLink href={vulnerabilityLink} className="link--obvious">
              {title}
            </RegistryLink>
          </div>
          <div
            className={`grid__item flex flex--align-items--center ${columnWidths.library}`}
            data-automation-id="RowCell"
          >
            {showPremiumVulnPaywall ? (
              <div
                className="cursor--pointer"
                onClick={() => this.toggleCtaPremiumVulnModalOpen('VULN_LIST_LIBRARY_COLUMN')}
              >
                <ProIcon />
              </div>
            ) : (
              <RegistryLink
                href={libraryRegistryCommitHashLink || libraryRegistryLink}
                className="link--obvious"
              >
                {libraryName}
              </RegistryLink>
            )}
          </div>
          <div
            className={`grid__item flex flex--align-items--center ${columnWidths.version}`}
            data-automation-id="RowCell"
          >
            {showPremiumVulnPaywall ? (
              <div
                className="cursor--pointer"
                onClick={() => this.toggleCtaPremiumVulnModalOpen('VULN_LIST_VERSION_COLUMN')}
              >
                <ProIcon />
              </div>
            ) : (
              <RegistryLink
                href={libraryRegistryCommitHashLink || libraryRegistryLink}
                className="link--obvious"
              >
                {getLibraryVersionText()}
              </RegistryLink>
            )}
          </div>
          {!isContainerProject && (
            <div
              className="grid__item flex flex--align-items--center flex--justify-content--center"
              data-automation-id="RowCell"
            >
              {renderVulnMethods()}
            </div>
          )}
          {!isSingleRepoScope && (
            <div
              className={`grid__item color--muted-dark flex flex--align-items--center ${
                repos && 'cursor--pointer'
              }`}
              onClick={
                repos ? () => this.toggleQuickview(reposQuickviewId, reposQuickviewLink) : null
              }
              data-automation-id="RowCell"
            >
              <div className="flex flex--justify-content--center col-1-1">
                <div className="flex">{repos}</div>
                {repos && (
                  <span className="flex">
                    {currentOpenRowId === reposQuickviewId ? (
                      <QuickviewOpenIcon className="cursor--pointer color--primary pl-" />
                    ) : (
                      <QuickviewClosedIcon className="cursor--pointer color--primary pl-" />
                    )}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <VelocityTransitionGroup
          enter={Animations.slideDown_quickView}
          leave={Animations.slideUp_default}
        >
          {currentOpenRowId === reposQuickviewId && (
            <div className="p- pb0">
              <LoaderWrapper isLoaderShowing={_.isEmpty(reposQuickviewData) && isLoading}>
                {!_.isEmpty(reposQuickviewData) && (
                  <VulnReposQuickview
                    data={reposQuickviewData}
                    params={params}
                    onPageClick={this.onQuickviewPageClick}
                    quickviewId={reposQuickviewId}
                    quickviewLink={reposQuickviewLink}
                  />
                )}
              </LoaderWrapper>
            </div>
          )}
        </VelocityTransitionGroup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    quickviewState: state.quickviewState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    quickviewActions: bindActionCreators(quickviewActions as any, dispatch),
    upgradeModalActions: bindActionCreators(upgradeModalActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VulnerabilitiesRow);
