import React from 'react';

function ActiveStateTriangle() {
  return (
    <div className="arrow-bottom">
      <div className="arrow-top" />
    </div>
  );
}

export default ActiveStateTriangle;
