import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as billingActions from '~/actions/billing';

interface UpdateBillingInformationProps {
  billingActions: object;
  billingState: object;
  orgState: App.OrgState;
  onClose: (...args: any[]) => any;
}
class UpdateBillingInformation extends React.Component<UpdateBillingInformationProps, {}> {
  componentDidMount() {
    this.props.billingActions.saveBillingInfoCopies();
  }

  componentWillUnmount() {
    this.props.billingActions.updateBillingError(undefined);
  }

  closeModal() {
    this.props.onClose();
  }

  submitUpdateBillingForm(e) {
    e.preventDefault();
    const { orgState } = this.props;
    const { org = {} } = orgState;
    const { target = {} } = e;
    const { name, phoneNumber, email } = target;

    this.props.billingActions.submitBillingInfo(org.id, {
      name: name.value || '',
      phoneNumber: phoneNumber.value || '',
      billingEmail: email.value || '',
    });
  }

  updateBillingField(e, field) {
    const { value } = e.target;

    this.props.billingActions.updateBillingField(field, value);
  }

  render() {
    const { billingState } = this.props;
    const { account = {}, billingError } = billingState;
    const { name, phoneNumber, email } = account;

    return (
      <form className="grid" onSubmit={e => this.submitUpdateBillingForm(e)}>
        <label htmlFor="name" className="grid__item mt- mb0 flex align-items--center col-1-3">
          Name
        </label>
        <div className="grid__item col-2-3 mt- flex align-items--center">
          <input
            type="text"
            className="control--text col-1-1"
            id="name"
            value={name || ''}
            onChange={e => this.updateBillingField(e, 'name')}
            name="name"
            placeholder="Name"
          />
        </div>
        <label
          htmlFor="phoneNumber"
          className="grid__item mt- mb0 flex align-items--center col-1-3"
        >
          Phone
        </label>
        <div className="grid__item col-2-3 mt- flex align-items--center">
          <input
            type="text"
            className="control--text col-1-1"
            id="phoneNumber"
            value={phoneNumber || ''}
            onChange={e => this.updateBillingField(e, 'phoneNumber')}
            name="phoneNumber"
            placeholder="Phone number"
          />
        </div>
        <label htmlFor="email" className="grid__item mt- mb0 flex align-items--center col-1-3">
          Email
        </label>
        <div className="grid__item col-2-3 mt- flex align-items--center">
          <input
            type="email"
            className="control--text col-1-1"
            id="email"
            required
            value={email || ''}
            onChange={e => this.updateBillingField(e, 'email')}
            name="email"
            placeholder="Email"
          />
        </div>
        <div className={`grid__item col-1-1 ${billingError ? 'is-showing-100' : 'is-hiding'}`}>
          <div className="mt-- color--danger">{billingError}</div>
        </div>
        <div className="grid__item col-1-1 mt- flex flex--justify-content--end">
          <button className="pv- col-1-5 mr-" onClick={() => this.closeModal()} type="button">
            Cancel
          </button>
          <button className="btn--primary-clear pv- col-1-5" type="submit">
            Save
          </button>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    billingState: state.billingState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    billingActions: bindActionCreators(billingActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBillingInformation);
