import * as actions from '~/actions/workspaceInvite';

export const defaultState = {
  activeModal: undefined,
  rawEmailsValue: '',
  validEmails: [],
  invalidEmails: [],
  pendingInvites: [],
  filteredPendingInvites: [],
  selectedPendingInvites: [],
  sendInvitesError: undefined,
};

const workspaceInviteState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_INVITE_EMAILS_INPUT:
      return {
        ...state,
        rawEmailsValue: action.value,
        sendInvitesError: undefined,
      };
    case actions.UPDATE_VALID_EMAILS:
      return {
        ...state,
        validEmails: action.validEmails,
      };
    case actions.UPDATE_INVALID_EMAILS:
      return {
        ...state,
        invalidEmails: action.invalidEmails,
      };
    case actions.UPDATE_WORKSPACE_INVITE_ERROR:
      return {
        ...state,
        sendInvitesError: action.message,
      };
    case actions.TOGGLE_WORKSPACE_ADMIN:
      return {
        ...state,
        validEmails: state.validEmails.map(user => {
          if (user.email === action.email) {
            user.role = user.role === 'USER' ? 'WORKSPACE_ADMIN' : 'USER';
          }
          return user;
        }),
      };
    case actions.REMOVE_USER_FROM_INVITE_LIST:
      return {
        ...state,
        validEmails: state.validEmails.filter(user => user.email !== action.email),
        sendInvitesError: undefined,
      };
    case actions.RESET_WORKSPACE_INVITE_STATE:
      return {
        ...state,
        activeModal: undefined,
        rawEmailsValue: '',
        validEmails: [],
        invalidEmails: [],
        sendInvitesError: undefined,
      };
    case actions.UPDATE_PENDING_WORKSPACE_INVITES:
      return {
        ...state,
        pendingInvites: action.pendingInvites,
        filteredPendingInvites: action.pendingInvites,
      };
    case actions.FILTER_PENDING_INVITES:
      return {
        ...state,
        filteredPendingInvites: filterInvites(state.pendingInvites, action.value),
      };
    case actions.UPDATE_SELECTED_INVITES:
      return {
        ...state,
        selectedPendingInvites: state.selectedPendingInvites.find(
          inviteId => inviteId === action.inviteId
        )
          ? state.selectedPendingInvites.filter(inviteId => inviteId !== action.inviteId)
          : state.selectedPendingInvites.concat(action.inviteId),
      };
    case actions.SELECT_ALL_WORKSPACE_PENDING_INVITES:
      return {
        ...state,
        selectedPendingInvites: action.selectAll
          ? state.pendingInvites.map(inviteeObj => inviteeObj.invite.id)
          : [],
      };
    case actions.RESET_SELECTED_PENDING_INVITES:
      return {
        ...state,
        selectedPendingInvites: [],
      };
    case actions.TOGGLE_WORKSPACE_INVITE_MODAL:
      return {
        ...state,
        activeModal: action.modalType,
      };
    default:
      return state;
  }
};

export default workspaceInviteState;

function filterInvites(invitees, value) {
  const trimmedValue = value.trim().toLowerCase();

  return invitees.filter(inviteeObj => {
    const { invite = {} } = inviteeObj;
    const { email = '' } = invite;

    return email.toLowerCase().includes(trimmedValue);
  });
}
