import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';

import * as orgTeamActions from '~/actions/orgTeam';
import * as teamActions from '~/actions/team';

import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import { FEATURE_SLUG_MAP } from '~/constants/ModelConstants';

import WorkspaceGroupsList from '~/containers/WorkspaceGroupsList';
import RenameWorkspaceModal from '~/containers/RenameWorkspaceModal';
import SourceClearModal from '~/components/SourceClearModal';
import UsersPage from '~/containers/UsersPage';
import { RouteComponentProps } from 'react-router-dom';

interface WorkspaceDetailsPageProps extends RouteComponentProps {
  orgTeamActions: object;
  teamActions: object;
  orgTeamState: object;
  orgState: App.OrgState;
}

class WorkspaceDetailsPage extends React.Component<WorkspaceDetailsPageProps, {}> {
  componentDidMount() {
    const { orgTeamState } = this.props;
    const { orgTeams = [] } = orgTeamState;

    if (!orgTeams.length) {
      this.props.orgTeamActions.fetchOrgTeams();
    }
  }

  componentDidUpdate(prevProps) {
    const { match, orgTeamState } = prevProps;
    const { params = {} } = match;
    const { teamId } = params;
    const { orgTeams = [], teamGroups, isFetchingTeamGroups } = orgTeamState;
    if (orgTeams.length) {
      const activeTeam = orgTeams.find(team => team.id === teamId);

      if (activeTeam && !isFetchingTeamGroups && !teamGroups) {
        this.props.orgTeamActions.fetchTeamGroups(activeTeam);
      }
    }
  }

  componentWillUnmount() {
    this.props.orgTeamActions.resetTeamSort();
    this.props.orgTeamActions.resetTeamDetails();
    this.props.orgTeamActions.showModal();
  }

  setTeamDetails(activeTeam) {
    if (!activeTeam) {
      const { match, orgTeamState } = this.props;
      const { params = {} } = match;
      const { teamId } = params;
      const { orgTeams = [] } = orgTeamState;
      activeTeam = orgTeams.find(team => team.id === teamId) || {};
    }

    this.props.orgTeamActions.fetchTeamGroups(activeTeam);
  }

  showModal(modalType) {
    this.props.orgTeamActions.showModal(modalType);
  }

  toggleActiveTab(tab) {
    this.props.orgTeamActions.updateActiveTab(tab);
  }

  deleteWorkspace(e) {
    e.preventDefault();
    const { match, history } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    this.props.teamActions.deleteTeam(teamId).then(res => {
      if (res.success) {
        this.props.teamActions.fetchTeams();
        this.props.teamActions.removeTeamFromIssueSettingsData(teamId);
        this.props.orgTeamActions.fetchOrgTeams();
        history.replace(`/org/settings/workspaces`);
      }
    });
  }

  render() {
    const { match, orgTeamState, orgState } = this.props;
    const { params = {} } = match;
    const { orgTeams = [], showModal, activeTab } = orgTeamState;
    const { teamId } = params;
    const activeTeam = orgTeams.find(team => team.id === teamId) || {};
    const { name = '' } = activeTeam;
    const { org } = orgState;

    const showGroups =
      FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org) ||
      OrgPaidStatus.isOrgEnterpriseOrTrial(org);

    return (
      <div className="grid mt mb++">
        <Helmet>
          <title>Workspace</title>
        </Helmet>
        <div className="grid__item col-1-1 flex align-items--baseline">
          <div className="font--h3">Workspace</div>
          <div className="ml- font--h4 color--black-light">
            <strong>{name}</strong>
          </div>
          <div className="ml-- flex align-self--stretch align-items--end mb---">
            <button className="color--muted-dark" onClick={() => this.showModal('EDIT_WORKSPACE')}>
              <i className="sci sci__pencil" />
            </button>
          </div>
          <div className="ml--- flex align-self--stretch align-items--end mb---">
            <button
              className="color--muted-dark"
              onClick={() => this.showModal('DELETE_WORKSPACE')}
            >
              <i className="sci sci__trash" />
            </button>
          </div>
        </div>
        <div className="grid__item col-1-1 mt+">
          <div className="bo-b--3 border-color--black-light">
            <div className="grid">
              <div className={`grid__item col-1-6`}>
                <div
                  className={`flex justify-content--center align-items--center pv- ${
                    activeTab === 'USERS'
                      ? 'bg-color--black-light color--white'
                      : 'bg-color--white-dark cursor--pointer'
                  }`}
                  onClick={() => this.toggleActiveTab('USERS')}
                >
                  <strong>Users</strong>
                </div>
              </div>
              {showGroups && (
                <div className={`grid__item col-1-6`}>
                  <div
                    className={`flex justify-content--center align-items--center pv- ${
                      activeTab === 'GROUPS'
                        ? 'bg-color--black-light color--white'
                        : 'bg-color--white-dark cursor--pointer'
                    }`}
                    onClick={() => this.toggleActiveTab('GROUPS')}
                  >
                    <strong>Teams</strong>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showGroups && activeTab === 'GROUPS' && (
          <div className="col-1-1">
            <div className="grid__item col-1-1 mt">
              <WorkspaceGroupsList setTeamDetails={() => this.setTeamDetails()} teamId={teamId} />
            </div>
          </div>
        )}
        {activeTab === 'USERS' && (
          <div className="col-1-1">
            <div className="grid__item col-1-1">
              <UsersPage {...this.props} showHeader={false} showPendingInvites={false} />
            </div>
          </div>
        )}
        <SourceClearModal
          isOpen={showModal === 'EDIT_WORKSPACE'}
          onClose={() => this.showModal()}
          title="Rename Workspace"
          width={400}
        >
          <RenameWorkspaceModal
            teamId={teamId}
            activeTeam={activeTeam}
            showModal={modalType => this.showModal(modalType)}
          />
        </SourceClearModal>
        <SourceClearModal
          isOpen={showModal === 'DELETE_WORKSPACE'}
          onClose={() => this.showModal()}
          title="Delete Workspace"
          width={400}
        >
          <form className="grid" onSubmit={e => this.deleteWorkspace(e)}>
            <div className="grid__item col-1-1">
              This will permanently delete the workspace. Are you sure?
            </div>
            <div className="grid__item col-1-1 flex justify-content--end mt">
              <button className="col-1-5 pv-" type="button" onClick={() => this.showModal()}>
                Cancel
              </button>
              <button className="btn--danger ph pv- ml-" type="submit">
                Yes, Delete
              </button>
            </div>
          </form>
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgTeamState: state.orgTeamState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orgTeamActions: bindActionCreators(orgTeamActions, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceDetailsPage);
