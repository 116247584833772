import _ from 'lodash';
import produce from 'immer';

import * as actions from '~/actions/orgPolicies';
import { ControlsEntity, Policy, PolicySettings } from '~/types/Policy';
import Helpers from '~/utils/Helpers';
import { highMediumLowRisks, LICENSE_RISK_CONST_MAP } from '~/constants/ModelConstants';

export interface OrgPoliciesState {
  policy: Policy;
  policySettings: PolicySettings;
  masterPolicy: Policy;
  defaultPolicy: Policy;
  isPolicyDirty: boolean;
  isPolicyScopeDirty: boolean;
  editMode: boolean;
  showModal?: string;
  cardMoved: {
    [key: string]: any;
  };
  status: 'DEFAULT' | 'CUSTOM';
  expandedControlsByIndex: {
    [key: string]: any;
  };
  policyContolsErrorMessage: string;
  isFetching: boolean;
  isSaving: boolean;
  isResettingToDefault: boolean;
  isFetchingPolicySettings: boolean;
  isSavingPolicySettings: boolean;
  policySettingsErrorMessage: string;
}

export const defaultState: OrgPoliciesState = {
  policy: {} as Policy,
  policySettings: {
    enforceRules: false,
    scope: 'NA',
    scopeTeams: [],
  },
  masterPolicy: {} as Policy,
  defaultPolicy: {} as Policy,
  isPolicyDirty: false,
  isPolicyScopeDirty: false,
  editMode: false,
  showModal: undefined,
  cardMoved: {},
  status: 'DEFAULT',
  expandedControlsByIndex: {},
  policyContolsErrorMessage: '',
  isFetching: false,
  isSaving: false,
  isResettingToDefault: false,
  isFetchingPolicySettings: false,
  policySettingsErrorMessage: '',
  isSavingPolicySettings: false,
};

const orgPoliciesState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.FETCH_ORGANIZATION_POLICIES_REQUEST:
        draft.isFetching = true;
        draft.policyContolsErrorMessage = '';
        break;
      case actions.FETCH_ORGANIZATION_POLICIES_FAILURE:
        draft.isFetching = false;
        draft.policyContolsErrorMessage = action.message;
        break;
      case actions.FETCH_ORGANIZATION_POLICY_SETTINGS_REQUEST:
        draft.isFetchingPolicySettings = true;
        draft.policySettingsErrorMessage = '';
        break;
      case actions.FETCH_ORGANIZATION_POLICY_SETTINGS_FAILURE:
        draft.isFetchingPolicySettings = false;
        draft.policySettingsErrorMessage = action.message;
        break;
      case actions.UPDATE_ORGANIZATION_POLICY_SETTINGS:
        draft.isFetchingPolicySettings = false;
        draft.isSavingPolicySettings = false;
        draft.isPolicyScopeDirty = false;
        draft.policySettings = action.policySettings;
        draft.policySettingsErrorMessage = '';
        break;
      case actions.UPDATE_ORGANIZATION_POLICIES:
        draft.isFetching = false;
        draft.isSaving = false;
        draft.isResettingToDefault = false;
        draft.editMode = false;
        draft.isPolicyDirty = false;
        draft.policy = action.policy;
        draft.masterPolicy = action.policy;
        draft.policyContolsErrorMessage = '';
        break;
      case actions.ORG_UPDATE_DEFAULT_POLICY:
        draft.defaultPolicy = action.policy;
        break;
      case actions.ORG_RESET_CUSTOM_POLICY_TO_DEFAULT:
        draft.policy.effectiveRevision = action.effectiveRevision;
        draft.isResettingToDefault = false;
        draft.editMode = true;
        draft.isPolicyDirty = true;
        break;
      case actions.UPDATE_ORGANIZATION_MODAL:
        draft.showModal = action.modalType;
        break;
      case actions.ORG_RESET_TO_DEFAULT_POLICY_REQUEST:
        draft.isResettingToDefault = true;
        break;
      case actions.ORG_TOGGLE_POLICY_STATUS_MODE:
        draft.status = action.mode;
        break;
      case actions.ORG_UPDATE_CONTROL:
        draft.isPolicyDirty = true;
        draft.policy.effectiveRevision.controls = _.cloneDeep(state.policy.effectiveRevision.controls).map(
          (control, index) => {
            if (index === action.index) {
              return updateControl(control, action.option, action.field);
            }

            return control;
          }
        );
        break;
      case actions.ORG_ADD_CONTROL:
        draft.isPolicyDirty = true;
        draft.expandedControlsByIndex[state.policy.effectiveRevision.controls.length] = true;
        draft.policy.effectiveRevision.controls = state.policy.effectiveRevision.controls.concat(
          _.cloneDeep(emptyControl)
        );
        break;
      case actions.ORG_REMOVE_CONTROL: {
        const newExpandedControls = {};
        Object.keys(state.expandedControlsByIndex).forEach(expandedIndex => {
          if (parseInt(expandedIndex) !== action.index) {
            newExpandedControls[
              parseInt(expandedIndex) > action.index ? +expandedIndex - 1 : expandedIndex
            ] = state.expandedControlsByIndex[expandedIndex];
          }
        });

        draft.isPolicyDirty = true;
        draft.expandedControlsByIndex = newExpandedControls;
        draft.policy.effectiveRevision.controls = [
          ...state.policy.effectiveRevision.controls.slice(0, action.index),
          ...state.policy.effectiveRevision.controls.slice(action.index + 1),
        ];

        break;
      }
      case actions.ORG_TOGGLE_EDIT_MODE:
        draft.editMode = !state.editMode;
        draft.policy = !state.editMode || action.cancel ? state.masterPolicy : state.policy;
        draft.isFetching = false;
        draft.isSaving = false;
        break;
      case actions.ORG_SAVE_CHANGES_REQUEST:
        draft.isSaving = true;
        draft.policyContolsErrorMessage = '';
        break;
      case actions.ORG_SAVE_CHANGES_FAILURE:
        draft.isSaving = false;
        draft.policyContolsErrorMessage = action.message;
        break;
      case actions.SAVE_ORGANIZATION_POLICY_SETTINGS_REQUEST:
        draft.isSavingPolicySettings = true;
        draft.policySettingsErrorMessage = '';
        break;
      case actions.SAVE_ORGANIZATION_POLICY_SETTINGS_FAILURE:
        draft.isSavingPolicySettings = false;
        draft.policySettingsErrorMessage = action.message;
        break;
      case actions.ORG_RESET_EXPANDED_CONTROLS:
        draft.expandedControlsByIndex = {};
        break;
      case actions.ORG_RESET_MODES:
        draft.editMode = false;
        break;
      case actions.ORG_TOGGLE_CONTROL_EXPAND:
        draft.expandedControlsByIndex[action.index] = !state.expandedControlsByIndex[action.index];
        break;
      case actions.ORG_TRIGGER_MOVE_EFFECT:
        draft.cardMoved[action.index] = action.bool;
        break;
      case actions.ORG_MOVE_CONTROL: {
        const newControls = [...state.policy.effectiveRevision.controls];
        newControls.splice(action.toIndex, 0, newControls.splice(action.index, 1)[0]);
        const newExpandedControls = {};

        // loop through all the expanded items to see if they need to move or be moved as a result of the order being changed
        Object.keys(state.expandedControlsByIndex).forEach(expandedIndex => {
          const expandedIndexAsInt = parseInt(expandedIndex);
          if (expandedIndexAsInt === action.toIndex) {
            // expanded item must be moved to clear space for the item being moved
            newExpandedControls[action.toIndex] = state.expandedControlsByIndex[action.index];
            newExpandedControls[
              action.index > action.toIndex ? expandedIndexAsInt + 1 : expandedIndexAsInt - 1
            ] = state.expandedControlsByIndex[expandedIndexAsInt];
          } else if (expandedIndexAsInt === action.index) {
            // expanded item *IS* the item being moved
            newExpandedControls[action.toIndex] = state.expandedControlsByIndex[action.index];
            newExpandedControls[expandedIndexAsInt] =
              state.expandedControlsByIndex[
                action.index > action.toIndex ? expandedIndexAsInt - 1 : expandedIndexAsInt + 1
              ];
          } else {
            // expanded item is either above or below the item being moved. the expanded item doesn't move
            newExpandedControls[expandedIndexAsInt] =
              state.expandedControlsByIndex[expandedIndexAsInt];
          }
        });
        draft.isPolicyDirty = true;
        draft.expandedControlsByIndex = newExpandedControls;
        draft.policy.effectiveRevision.controls = newControls;
        break;
      }
      case actions.TOGGLE_ENFORCE_RULES:
        draft.policySettings.enforceRules = action.enforce;
        draft.isPolicyScopeDirty = true;
        break;
      case actions.TOGGLE_SCOPE:
        draft.policySettings.scope = action.scope;
        draft.isPolicyScopeDirty = true;
        break;
    }
  });
};

export default orgPoliciesState;

function updateControl(control, value, field) {
  switch (field) {
    case 'name':
      control.name = value;
      break;
    case 'action':
      control.action.provider.parameters.create_issue = value;
      break;
    case 'level':
      control.level = value;
      break;
    case 'control-severity':
      control.severity = value && !isNaN(value) ? value : '';
      break;
    case 'matcher':
      control.condition.matcher = value;
      break;
    case 'type':
      control.condition.descriptor.type = value;
      break;
    case 'dependency':
      control.condition.resource.parameters.dependency = value;
      break;
    default: {
      // Cases where the field needed to be cleared
      if (value === null) {
        delete control.condition.descriptor.parameters[field];
      } else {
        if (field === LICENSE_RISK_CONST_MAP.KIND && highMediumLowRisks.includes(value)) {
          control.condition.descriptor.parameters[LICENSE_RISK_CONST_MAP.KIND] =
            LICENSE_RISK_CONST_MAP.RISK;
          control.condition.descriptor.parameters[
            LICENSE_RISK_CONST_MAP.RISK
          ] = Helpers.getRiskByKind(value);
          break;
        } else if (
          field === LICENSE_RISK_CONST_MAP.KIND &&
          value === LICENSE_RISK_CONST_MAP.LICENSE_BY_NAME
        ) {
          control.condition.descriptor.parameters[LICENSE_RISK_CONST_MAP.KIND] =
            LICENSE_RISK_CONST_MAP.CUSTOM;
          break;
        }
        control.condition.descriptor.parameters[field] = value;
      }
    }
  }

  return control;
}

const emptyControl: ControlsEntity = {
  name: '',
  severity: undefined,
  level: undefined,
  condition: {
    resource: {
      type: 'library',
      parameters: {
        dependency: 'direct',
      },
    },
    matcher: undefined,
    descriptor: {
      type: undefined,
      parameters: {},
    },
  },
  action: {
    provider: {
      type: 'sourceclear_issue',
      parameters: {
        create_issue: true,
      },
    },
  },
};
