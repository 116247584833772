import * as actions from '~/actions/org';
import Helpers from '~/utils/Helpers';

export const defaultState: App.OrgState = {
  org: {
    id: '',
    orgId: '',
    name: '',
    license: {},
    welcomeMode: undefined,
    features: [],
    purchaseOrder: false,
  },
  newWorkspaceName: '',
  newWorkspaceTab: 'ADD_PEOPLE',
  newWorkspaceStep: 1,
  orgNameDirty: false,
  isOrgSlugInvalid: false,
  showModal: undefined,
  updatingUserRole: undefined,
  saml: {}, //existing saml data
  samlInputRaw: {}, //raw values of input controls
  samlSetupMode: 'VIEW',
  fieldValidations: {},
  validWorkspaceName: false,
};

const orgState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_ORG:
      return {
        ...state,
        org: action.org,
        orgNameDirty: false,
      };
    case actions.UPDATE_NEW_WORKSPACE_NAME:
      return {
        ...state,
        newWorkspaceName: action.value,
        validWorkspaceName: Helpers.validateWorkspaceName(action.value),
      };
    case actions.UPDATE_NEW_WORKSPACE_STEP:
      return {
        ...state,
        newWorkspaceStep: action.step,
      };
    case actions.RESET_NEW_WORKSPACE_FLOW:
      return {
        ...state,
        newWorkspaceName: '',
        newWorkspaceStep: 1,
        newWorkspaceTab: 'ADD_PEOPLE',
      };
    case actions.ORG_NAME_SAVE_SUCCESS:
      return {
        ...state,
        orgNameDirty: false,
      };
    case actions.UPDATE_ORG_NAME_VALUE:
      return {
        ...state,
        org: {
          ...state.org,
          name: action.value,
          validOrgName: Helpers.validateOrgName(action.value),
        },
        orgNameDirty: true,
      };
    case actions.UPDATE_FIELD_VALIDATION:
      return {
        ...state,
        fieldValidations: {
          ...state.fieldValidations,
          [action.formGroup]: {
            ...state.fieldValidations[action.formGroup],
            [action.field]: {
              isInvalid: action.isInvalid,
              errors: action.isInvalid ? action.error : '',
            },
          },
        },
      };
    case actions.UPDATE_SAML_SETUP_MODE:
      return {
        ...state,
        samlSetupMode: action.mode,
      };
    case actions.CANCEL_SAML_SETUP_EDIT: {
      const samlDomainsText = (state.saml.samlDomains && state.saml.samlDomains.join(',')) || '';

      return {
        ...state,
        samlInputRaw: {
          ...state.saml,
          samlDomains: samlDomainsText,
        },
        samlSetupMode: 'VIEW',
        fieldValidations: {
          samlInputRaw: {},
        },
      };
    }
    case actions.UPDATE_SAML_DOMAINS_VALUE:
      return {
        ...state,
        samlInputRaw: {
          ...state.samlInputRaw,
          samlDomains: action.value,
        },
      };
    case actions.UPDATE_SAML_METADATA_VALUE:
      return {
        ...state,
        samlInputRaw: {
          ...state.samlInputRaw,
          samlMetadata: action.value,
        },
      };
    case actions.UPDATE_SAML_GROUP_NAME_ATTR_VALUE:
      return {
        ...state,
        samlInputRaw: {
          ...state.samlInputRaw,
          groupNameAttribute: action.value,
        },
      };
    case actions.TOGGLE_SAML_ALLOW_PASSWORD_LOGIN:
      return {
        ...state,
        saml: {
          ...state.saml,
          includingOrgOwner: action.value,
        },
      };
    case actions.FETCH_SAML_SETUP_REQUEST:
      return {
        ...state,
        isSamlFetching: true,
        errorMessage: '',
      };
    case actions.FETCH_SAML_SETUP_SUCCESS: {
      const samlSetup = action.samlSetup || {};
      const samlData = {
        samlDomains: samlSetup.emailDomains,
        samlMetadata: samlSetup.metadataXml,
        includingOrgOwner: samlSetup.includingOrgOwner,
        // React does not tolerate null as an input.value
        groupNameAttribute: samlSetup.groupNameAttribute || '',
      };

      //Transforming array to string to be used in email domain input control
      const samlDomainsText = (samlSetup.emailDomains && samlSetup.emailDomains.join(',')) || '';

      return {
        ...state,
        isSamlFetching: false,
        saml: samlData,
        samlInputRaw: {
          ...samlData,
          samlDomains: samlDomainsText,
        },
        errorMessage: '',
      };
    }
    case actions.FETCH_SAML_SETUP_FAILURE:
      return {
        ...state,
        isSamlFetching: false,
        errorMessage: '',
      };
    case actions.SAVE_SAML_SETUP_REQUEST:
      return {
        ...state,
        isSamlSaving: true,
        errorMessage: '',
      };
    case actions.SAVE_SAML_SETUP_SUCCESS:
      return {
        ...state,
        isSamlSaving: false,
        errorMessage: '',
      };
    case actions.SAVE_SAML_SETUP_FAILURE:
      return {
        ...state,
        isSamlSaving: false,
        errorMessage: action.message,
      };
    case actions.RESET_ORG_VALIDATIONS:
      return {
        ...state,
        fieldValidations: {},
      };
    case actions.ORG_SLUG_INVALID:
      return {
        ...state,
        isOrgSlugInvalid: true,
      };
    case actions.SHOW_ORG_MODAL:
      return {
        ...state,
        showModal: action.modalType,
      };
    case actions.UPDATING_USER_ROLE:
      return {
        ...state,
        updatingUserRole: action.state,
      };
    case actions.UPDATE_NEW_WORKSPACE_TAB:
      return {
        ...state,
        newWorkspaceTab: action.tab,
      };
    default:
      return state;
  }
};

export default orgState;
