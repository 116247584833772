import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import TextInput from '~/components/TextInput';
import OptionsList from '~/components/OptionsList';
import * as projectSettingsActions from '~/actions/projectSettings';
import * as projectNotificationsSettingsActions from '~/actions/projectNotificationsSettings';
import {
  NOTIFICATION_TYPES,
  PLAN_TYPE,
  PROJECT_SETTINGS_STAGES,
  REPO_EVENTS,
  TRIGGER_EVENTS_OPTIONS,
} from '~/constants/ModelConstants';
import OrgPaidStatus from '~/utils/OrgPaidStatus';

const NOTIFICATION_TYPE = NOTIFICATION_TYPES.WEBHOOK;
const { JUST_SCAN_EVENT, ALL_EVENTS, INDIVIDUAL_EVENTS } = TRIGGER_EVENTS_OPTIONS;

interface ProjectSettingsWebhookNotificationsCreateSectionProps {
  orgState: App.OrgState;
  projectId: string;
  projectNotificationsSettingsState: object;
  projectNotificationsSettingsActions: object;
  projectSettingsActions: object;
  projectSettingsState: object;
}
class ProjectSettingsWebhookNotificationsCreateSection extends React.Component<
  ProjectSettingsWebhookNotificationsCreateSectionProps,
  {}
> {
  componentDidMount() {
    this.setDefaults();
  }

  componentWillUnmount() {
    this.resetControls();
  }

  setDefaults() {
    const { projectNotificationsSettingsState } = this.props;
    const {
      [NOTIFICATION_TYPE]: webhookNotificationSettings = {},
    } = projectNotificationsSettingsState;
    const { controls = {} } = webhookNotificationSettings;
    const { triggerEventsOption = '' } = controls;

    this.props.projectNotificationsSettingsActions.updateNotificationsSettingsFieldValue(
      NOTIFICATION_TYPE,
      'contentType',
      'APPLICATION_JSON'
    );

    // If there's no previously selected event, set it to JUST_SCAN_EVENT by default.
    if (!triggerEventsOption) {
      this.props.projectNotificationsSettingsActions.updateNotificationsSettingsFieldValue(
        NOTIFICATION_TYPE,
        'triggerEventsOption',
        JUST_SCAN_EVENT
      );
    }
  }

  saveSettings(projectId, webhookId) {
    this.props.projectNotificationsSettingsActions.saveWebhookNotificationsSettings(
      projectId,
      webhookId
    );
  }

  updateFormInput = (fieldType, value) => {
    this.props.projectNotificationsSettingsActions.updateNotificationsSettingsFieldValue(
      NOTIFICATION_TYPE,
      fieldType,
      value
    );
  };

  updateMultipleInputField = (fieldType, values) => {
    this.props.projectNotificationsSettingsActions.updateNotificationsSettingsFieldValue(
      NOTIFICATION_TYPE,
      fieldType,
      values
    );
  };

  resetControls = () => {
    this.props.projectSettingsActions.updateProjectSettingsStage('');
    this.props.projectNotificationsSettingsActions.resetNotificationsSettingsControls(
      NOTIFICATION_TYPE
    );
  };

  render() {
    const {
      projectNotificationsSettingsState,
      projectId,
      projectSettingsState,
      orgState,
    } = this.props;
    const {
      [NOTIFICATION_TYPE]: webhookNotificationSettings = {},
      isSaving = false,
    } = projectNotificationsSettingsState;
    const { controls = {} } = webhookNotificationSettings;
    const {
      individualEvents = [],
      triggerEventsOption = '',
      contentType,
      url = '',
      id: webhookId,
    } = controls;
    const { stage = '' } = projectSettingsState;

    const buttonText = stage === PROJECT_SETTINGS_STAGES.CREATE_WEBHOOK ? 'Create' : 'Save';

    const { org } = orgState;
    const isOrgEnterprise = OrgPaidStatus.getOrgPlanState(org) === PLAN_TYPE.ENTERPRISE;

    const contentTypeOptions = [
      {
        value: 'APPLICATION_FORM_URLENCODED',
        label: 'application/x-www-form-urlencoded',
      },
      { value: 'APPLICATION_JSON', label: 'application/json' },
    ];

    const triggerEventsOptions = [{ value: JUST_SCAN_EVENT, label: 'Just the scan event.' }];

    const enterpriseTriggerEventsOptions = [
      { value: ALL_EVENTS, label: 'Send me everything.' },
      { value: INDIVIDUAL_EVENTS, label: 'Let me select individual events.' },
    ];

    const paywalledTriggerEventsOptions = isOrgEnterprise
      ? [...triggerEventsOptions, ...enterpriseTriggerEventsOptions]
      : triggerEventsOptions;

    const individualEventsOptions = [
      { value: REPO_EVENTS.SCAN_SUCCESS, label: 'Scan' },
      {
        value: REPO_EVENTS.VULN_ISSUES_DISCOVERED_AFTER_SCAN,
        label: 'Vulnerability issues discovered in project library after a scan',
      },
      {
        value: REPO_EVENTS.VULN_ISSUES_CHANGED_AFTER_SCAN,
        label: 'Vulnerability issues changed in project library after a scan',
      },
    ];

    // Events field is valid when a trigger event is selected and individual events is/are specified when Invidual Events option is selected
    const isEventsFieldValid =
      triggerEventsOption &&
      (triggerEventsOption !== TRIGGER_EVENTS_OPTIONS.INDIVIDUAL_EVENTS ||
        (triggerEventsOption === TRIGGER_EVENTS_OPTIONS.INDIVIDUAL_EVENTS &&
          individualEvents &&
          individualEvents.length > 0));

    const isFormValid = !!url && isEventsFieldValid;

    // Conditionally hide form control
    const isContentTypeFieldShowing = false;

    return (
      <div>
        <h6 className="text--uppercase bo-b--1 border-color--muted-light pb---">
          <a className="text--bold text--capitalize link--primary" onClick={this.resetControls}>
            Notifications
          </a>{' '}
          / Webhook Notifications
        </h6>
        <p>
          We'll send a POST request to the URL below with the details of any subscribed events. More
          information can be found in our developer documentation.
        </p>
        <div className="grid">
          <label className="grid__item col-1-5 mb- flex align-items--center" htmlFor="payload_url">
            Payload URL
          </label>
          <div className="grid__item col-4-5 mb-">
            <TextInput
              type="text"
              name="payload_url"
              className="col-1-1"
              placeholder="http://example.com/postreceive"
              field="url"
              value={url}
              onChange={this.updateFormInput}
            />
          </div>
          {isContentTypeFieldShowing && (
            <div>
              <label
                className="grid__item col-1-5 mb- flex align-items--center"
                htmlFor="webhook_content_type"
              >
                Content Type
              </label>
              <div className="grid__item col-4-5 mb-">
                <Select
                  name={`webhook_content_type`}
                  value={contentTypeOptions.find(option => option.value === contentType)}
                  options={contentTypeOptions}
                  onChange={val => this.updateFormInput('contentType', val)}
                  isClearable={false}
                  className={'srcclr-react-select-container'}
                  classNamePrefix={'srcclr-react-select'}
                />
              </div>
            </div>
          )}
          <label className="grid__item col-1-5 mb- flex pv--" htmlFor="payload_url">
            Trigger Events
          </label>
          <div className="grid__item col-4-5 mb-">
            {isOrgEnterprise ? (
              <div>
                <OptionsList
                  type="radio"
                  activeOption={triggerEventsOption}
                  field="triggerEventsOption"
                  options={paywalledTriggerEventsOptions}
                  onChange={this.updateFormInput}
                  className="col-1-1"
                  name="trigger_events"
                />
                <div className="pl">
                  <OptionsList
                    activeOptions={individualEvents}
                    field="individualEvents"
                    options={individualEventsOptions}
                    onChange={this.updateMultipleInputField}
                    className="col-1-1"
                    disabled={triggerEventsOption !== INDIVIDUAL_EVENTS}
                    name="individual_events"
                  />
                </div>
              </div>
            ) : (
              <div className="pt--">
                Just the scan event.
                <div className="mt- p- bo-rad--3 bo-l--1 border-color--white-dark bg-color--white-light">
                  Webhook notifications will be triggered by the scan event. For more event options
                  such as discovering new vulnerabilities after a scan,{' '}
                  <Link to="/org/settings/subscription" className="link--obvious">
                    upgrade to an Enterprise plan
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="grid__item col-1-1- mb- text--right">
            <button className="btn pv- ph mr--" onClick={this.resetControls}>
              Cancel
            </button>
            <button
              className="btn btn--success pv- ph"
              onClick={() => this.saveSettings(projectId, webhookId)}
              disabled={isSaving || !isFormValid}
            >
              {isSaving ? (
                <i className="fas fa-spin fa-spinner font--h5 color--muted-dark" />
              ) : (
                buttonText
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projectNotificationsSettingsState: state.projectNotificationsSettingsState,
    projectSettingsState: state.projectSettingsState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    projectNotificationsSettingsActions: bindActionCreators(
      projectNotificationsSettingsActions,
      dispatch
    ),
    projectSettingsActions: bindActionCreators(projectSettingsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSettingsWebhookNotificationsCreateSection);
