import React from 'react';

interface ContainerFixInfoProps {
  component: object;
  safeVersion: string;
}

const ContainerFixInfo: React.FunctionComponent<ContainerFixInfoProps> = props => {
  const { component, safeVersion } = props;
  const { name } = component;

  return (
    <React.Fragment>
      {safeVersion && (
        <div className="grid mt- pt--- bg-color--white pl-">
          <div className="pt--">
            <div className="text--bold">
              You may try any of the following suggested upgrade actions:
            </div>
          </div>
          <div className="">
            <ul className="pv-">
              <li className="pl- lh+">
                Use a base image that includes version <code>{safeVersion}</code> of{' '}
                <code>{name}</code>
              </li>
              <li className="pl- lh+">
                Upgrade <code>{name}</code> using your system package manager (for example,
                <code>yum update</code>)
              </li>
            </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ContainerFixInfo;
