import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import * as orgUserInviteActions from '~/actions/orgUserInvite';

interface InviteUsersViaEmailModalProps {
  orgUserInviteActions: object;
  orgUserInviteState: object;
  orgState: App.OrgState;
  closeWhenClickOutside?: boolean;
  onClose: (...args: any[]) => any;
  sendInvites: (...args: any[]) => any;
}
class InviteUsersViaEmailModal extends React.Component<InviteUsersViaEmailModalProps, {}> {
  constructor(props, context) {
    super(props, context);
    this.delayedCheckEmailValidity = _.debounce(emails => this.checkEmailValidity(emails), 1000);
  }

  onClose() {
    this.props.onClose();
  }

  updateInviteStage(inviteStage) {
    this.props.orgUserInviteActions.updateInviteStage(inviteStage);
  }

  handleEmailsInputChange(e) {
    const { target } = e;
    const { value } = target;
    this.props.orgUserInviteActions.processEmailsInput(value);
    this.delayedCheckEmailValidity(value);
  }

  checkEmailValidity(emails) {
    this.props.orgUserInviteActions.checkEmailValidity(emails);
  }

  handleInvite() {
    const { orgUserInviteState, orgState } = this.props;
    const { org = {} } = orgState;
    const { license = {} } = org;
    const plan = org.plan || {};
    const { usage = {} } = license;
    const { users } = usage;
    const { userLimit } = plan;
    const { invitees = [] } = orgUserInviteState;
    const invitesRemaining = userLimit - users;
    const invitesToRemove = users + invitees.length - userLimit;

    if (userLimit && invitees.length > invitesRemaining) {
      this.props.orgUserInviteActions.updateSendInviteError(
        `You only have ${invitesRemaining} invite${
          invitesRemaining === 1 ? '' : 's'
        } remaining, and you have entered ${invitees.length} email${
          invitees.length === 1 ? '' : 's'
        }. Please remove ${invitesToRemove} email${invitesToRemove === 1 ? '' : 's'}.`
      );
    } else {
      this.props.sendInvites();
    }
  }

  render() {
    const { orgUserInviteState, orgState } = this.props;
    const {
      rawEmailsInput,
      emailsInputError,
      sendInviteError,
      emailsInput = [],
    } = orgUserInviteState;
    const { org = {} } = orgState;
    const { license = {} } = org;
    const plan = org.plan || {};
    const { usage = {} } = license;
    const { users } = usage;
    const { userLimit } = plan;
    const inviteDomains = org.inviteDomains || [];

    return (
      <div className="grid">
        <div className="grid__item col-1-1 flex align-items--baseline">
          <div>
            <strong className="font--h6">Enter Emails</strong>
          </div>
          {userLimit && (
            <div className="ml-">
              ({userLimit - users} invitation
              {userLimit - users === 1 ? '' : 's'} remaining)
            </div>
          )}
        </div>
        <div className="grid__item col-1-1">
          <div className="mt--">Send email to invite people to join your organization.</div>
          <textarea
            type="text"
            className="control--text col-1-1 mt- border-color--muted"
            placeholder="Enter email addresses separated by commas, spaces, or newlines."
            value={rawEmailsInput}
            onChange={e => this.handleEmailsInputChange(e)}
            rows={5}
          />
          {emailsInputError && <div className="col-1-1 pt- color--danger">{emailsInputError}</div>}
          {inviteDomains.length ? (
            <div className="bo--1 mt- p- bg-color--primary-extra-light border-color--primary-dark color--primary-dark flex flex--align-items--center">
              <div className="pr-">
                <i className="sci sci__detail-information" />
              </div>
              <div>
                <strong>Approved domains: {inviteDomains.join(', ')}</strong> Because your
                organization uses SAML, email invitations can only be sent to approved domains.
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        <div className={`grid__item col-1-1 ${sendInviteError ? 'is-showing-50' : 'is-hiding'}`}>
          <div className="color--danger mt-">{sendInviteError}</div>
        </div>

        <div className="mt- flex flex--justify-content--end col-1-1">
          <button className="pv- col-1-5 mr-" onClick={() => this.onClose()}>
            Cancel
          </button>
          <button
            className={`pv- col-1-5 btn--primary-clear mr- ${!emailsInput.length && 'disabled'}`}
            disabled={!emailsInput.length}
            title={
              !emailsInput.length ? `Enter at least one valid email in order to proceed` : undefined
            }
            onClick={() => this.updateInviteStage('MORE_OPTIONS')}
          >
            More Options
          </button>
          <button
            className={`pv- col-1-5 btn--success-clear ${!emailsInput.length && 'disabled'}`}
            disabled={!emailsInput.length}
            title={
              !emailsInput.length ? `Enter at least one valid email in order to proceed` : undefined
            }
            onClick={() => this.handleInvite()}
          >
            Invite Now
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgState: state.orgState,
    orgUserInviteState: state.orgUserInviteState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orgUserInviteActions: bindActionCreators(orgUserInviteActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteUsersViaEmailModal);
