import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as MODEL from '~/constants/ModelConstants';

import * as integrationModalActions from '~/actions/integrationModal';
import Step1Modal from '~/containers/Step1Modal';
import Step2Modal from '~/containers/Step2Modal';
import Step3Modal from '~/containers/Step3Modal';
import GithubIntegrationModal from '~/containers/GithubIntegrationModal';
import JiraEnterpriseIntegrationModal from '~/containers/JiraEnterpriseIntegrationModal';

import ProxyStep1Modal from '~/containers/ProxyStep1Modal';
import ProxyStep2Modal from '~/containers/ProxyStep2Modal';

interface OrgIntegrationModalProps {
  integrationModalActions: object;
  integrationModalState: object;
}
class OrgIntegrationModal extends React.Component<OrgIntegrationModalProps, {}> {
  onClose = () => {
    this.props.integrationModalActions.closeIntegrationModal();
  };

  render() {
    const { integrationModalState } = this.props;
    const { openedModal } = integrationModalState;

    if (openedModal[MODEL.CREATE_JIRA_STEP_1]) {
      return <Step1Modal />;
    } else if (openedModal[MODEL.CREATE_JIRA_STEP_2]) {
      return <Step2Modal />;
    } else if (openedModal[MODEL.CREATE_JIRA_STEP_3]) {
      return <Step3Modal />;
    } else if (
      openedModal[MODEL.CREATE_JIRA_ENTERPRISE_INTEGRATION_MODAL] ||
      openedModal[MODEL.EDIT_JIRA_ENTERPRISE_INTEGRATION_MODAL]
    ) {
      return <JiraEnterpriseIntegrationModal />;
    } else if (
      openedModal[MODEL.CREATE_GITHUB_INTEGRATION_MODAL] ||
      openedModal[MODEL.EDIT_GITHUB_INTEGRATION_MODAL]
    ) {
      return <GithubIntegrationModal />;
    } else if (openedModal[MODEL.CREATE_PROXY_CLIENT_STEP_1]) {
      return <ProxyStep1Modal />;
    } else if (openedModal[MODEL.CREATE_PROXY_CLIENT_STEP_2]) {
      return <ProxyStep2Modal />;
    }

    return <span> </span>;
  }
}

function mapStateToProps(state) {
  return {
    integrationModalState: state.integrationModalState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgIntegrationModal);
