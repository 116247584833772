import * as actions from '~/actions/';
import { LibraryCatalogState } from '~/types/LibraryCatalog';
const defaultState: LibraryCatalogState = {
  catalog: {
    number: 0, // the page number, not renamed for consistency
    totalPages: 0,
    totalElements: 0,
    content: [],
  },
  libraries: {
    number: 0, // the page number, not renamed for consistency
    totalPages: 0,
    totalElements: 0,
    content: [],
  },
  isAddingToCatalog: false,
  isAddingToCatalogSuccess: false,
  isAddingToCatalogFailure: undefined,
  isFetching: false,
  errorFetching: false,
  filter: {
    text: '',
    languageType: 'All languages',
    licenseRisks: [],
  },
  newLibraryModal: {
    addLibraryLanguageType: 'Select',
    libraries: '',
    coordinates: '',
    license: '',
    librarySort: {
      name: 'asc',
    },
  },
  isLanguageTypeOpen: false,
  selected: [],
  sort: {
    name: 'asc', // or 'desc'
  },
  csv: {
    isFetching: false,
    errorFetching: false,
  },
};

const libraryCatalogState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.TOGGLE_LANGUAGE_TYPE_DROPDOWN: {
      return {
        ...state,
        isLanguageTypeOpen: !state.isLanguageTypeOpen,
      };
    }

    case actions.FETCH_LIBRARY_CATALOG_SUCCESS: {
      let newCatalog = action.result;

      if (action.concat) {
        const currentCatalog = state[action.catalogType];
        newCatalog = {
          ...newCatalog,
          content: currentCatalog.content.concat(newCatalog.content),
        };
      }

      return {
        ...state,
        [action.catalogType]: newCatalog,
        isFetching: false,
        errorFetching: false,
      };
    }
    case actions.FETCH_LIBRARY_CATALOG_FAILURE:
      return {
        ...state,
        catalog: { content: [] },
        isFetching: false,
        errorFetching: true,
      };
    case actions.FETCH_LIBRARY_CATALOG_IN_PROGRESS:
      return {
        ...state,
        isFetching: true,
      };
    case actions.ADD_TO_CATALOG_REQUEST:
      return {
        ...state,
        isAddingToCatalog: true,
        isAddingToCatalogSuccess: false,
        isAddingToCatalogFailure: undefined,
      };
    case actions.ADD_TO_CATALOG_SUCCESS:
      return {
        ...state,
        isAddingToCatalog: false,
        isAddingToCatalogSuccess: true,
        isAddingToCatalogFailure: undefined,
      };
    case actions.ADD_TO_CATALOG_FAILURE:
      return {
        ...state,
        isAddingToCatalog: false,
        isAddingToCatalogSuccess: false,
        isAddingToCatalogFailure: action.errorMessage,
      };
    case actions.ADD_TO_CATALOG_RESET:
      return {
        ...state,
        isAddingToCatalog: false,
        isAddingToCatalogSuccess: false,
        isAddingToCatalogFailure: undefined,
      };
    case actions.SWITCH_CATALOG_TYPE:
      return {
        ...state,
        catalogType: action.catalogType,
      };
    case actions.SEARCH_CATALOG:
      return {
        ...state,
        filter: { ...state.filter, text: action.searchFilter },
      };
    case actions.FILTER_CATALOG:
      return {
        ...state,
        filter: { ...state.filter, [action.field]: action.value },
      };
    case actions.SELECT_INSTANCE:
      return {
        ...state,
        selected: state.selected.concat([action.value]),
      };
    case actions.DESELECT_INSTANCE:
      return {
        ...state,
        selected: state.selected.filter(i => i !== action.value),
      };
    case actions.CLEAR_SELECTED_INSTANCES: {
      const currentCatalog = state[action.catalogType];

      return {
        ...state,
        selected: [],
        [action.catalogType]: {
          ...currentCatalog,
          content: currentCatalog.content.filter(
            i => state.selected.indexOf(i.instanceCommit.instance.id) < 0
          ),
        },
      };
    }
    case actions.SORT_BY:
      return {
        ...state,
        sort: { ...state.sort, [action.field]: toggleSort(state.sort[action.field]) },
      };
    case actions.FETCH_CSV_IN_PROGRESS:
      return {
        ...state,
        csv: { ...state.csv, isFetching: true },
      };
    case actions.FETCH_CSV_SUCCESS:
      return {
        ...state,
        csv: { ...state.csv, isFetching: false, errorFetching: false },
      };
    case actions.FETCH_CSV_FAILURE:
      return {
        ...state,
        csv: { ...state.csv, isFetching: false, errorFetching: true },
      };
    case actions.RESET_FILTERS:
      return {
        ...state,
        filter: defaultState.filter,
        sort: defaultState.sort,
      };
    default:
      return state;
  }
};

function toggleSort(sort) {
  if (sort === 'asc') {
    return 'desc';
  }
  return 'asc';
}

export default libraryCatalogState;
