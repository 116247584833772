import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';
import CopyToClipboard from '~/containers/CopyToClipboard';

interface PackageManagerGlideProps {
  component: object;
  safeCommitHash?: string;
  transitive: boolean;
  unsafeCommitHash?: string;
}

const PackageManagerGlide: React.FunctionComponent<PackageManagerGlideProps> = props => {
  const { component, transitive = false, safeCommitHash, unsafeCommitHash } = props;
  const { name } = component;

  let diff = null;
  if (!transitive) {
    // Direct
    diff = (
      <div className="grid bo--1 border-color--white-dark mt lh+ bg-color--white">
        <CodeDiffHeader text="glide.yaml" />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine type="delete" text={`- package  ${component.coordinate1}`} />
        <CodeDiffLine type="delete" text={`\u00A0\u0020version  ${unsafeCommitHash}`} />
        <CodeDiffLine type="add" text={`- package  ${component.coordinate1}`} />
        <CodeDiffLine type="add" text={`\u00A0\u0020version  ${safeCommitHash}`} />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
      </div>
    );
  } else {
    diff = (
      <div className="grid bo--1 border-color--white-dark mt lh+ bg-color--white">
        <CodeDiffHeader text="glide.yaml" />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine type="add" text={`- package  ${component.coordinate1}`} />
        <CodeDiffLine type="add" text={`\u00A0\u0020version  ${safeCommitHash}`} />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
      </div>
    );
  }

  return (
    <div className="pt-">
      <div className="grid pt">
        <span className="bg-color--white-dark pv- ph- text--bold">1</span>
        {!transitive && (
          <span className="ph-- pv-">
            {' '}
            Update your <span className="text--bold"> glide.yaml </span>
          </span>
        )}
        {transitive && (
          <span className="ph-- pv-">
            {' '}
            Include {name} to your <span className="text--bold">glide.yaml</span>
          </span>
        )}
        <span className="pv-">as shown below:</span>
      </div>
      {diff}
      <div className="grid pt">
        <span className="grid__item max-width--30 bg-color--white-dark pv- ph- text--bold">2</span>
        <span className="grid__item pv- pl--">
          After updating glide.yaml, type the following to update. This will also update other
          libraries in glide.yaml
        </span>
      </div>
      <div className="pt-- -ml mt-">
        <CopyToClipboard value={`glide up`} id="PackageManagerGlide1" />
      </div>

      <div className="grid">
        <span className="grid__item pv- pl--">
          If you are using <strong>Trash</strong> package manager, type the following instead
        </span>
      </div>
      <div className="pt-- -ml">
        <CopyToClipboard value={`trash`} id="PackageManagerGlide2" />
      </div>
    </div>
  );
};

export default PackageManagerGlide;
