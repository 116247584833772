import moment from 'moment';

export const isTrialExpiredPlanChanged = org => {
  const { license = {}, subscriptions = {} } = org;
  const { status } = license;
  const activeSubscription = subscriptions.ACTIVE || {};
  const { activeUntil } = activeSubscription;
  const isActiveExpired = activeUntil && moment().isAfter(moment(activeUntil));

  return status === 'TRIAL_EXPIRED' || isActiveExpired;
};

export const isMSAAccepted = (eula = {}) => {
  // MSA is considered accepted when acceptedVersion exists and is current
  return eula.acceptedVersion && eula.acceptedVersion === eula.currentVersion;
};
