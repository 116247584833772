import React from 'react';
import Select from 'react-select';
import _ from 'lodash';

interface ReportOptionsFilterProps {
  isMulti?: boolean;
  isClearable?: boolean;
  field: string;
  value?: string | any[] | boolean;
  placeholder?: string;
  onChange: (...args: any[]) => any;
  options: any[];
  automationId?: string;
}

const ReportOptionsFilter: React.FunctionComponent<ReportOptionsFilterProps> = props => {
  const {
    value = [],
    field,
    placeholder = '',
    options,
    isMulti,
    isClearable,
    onChange,
    automationId,
  } = props;

  let adjustedValue = value;
  if (isMulti) {
    if (_.isArray(value)) {
      adjustedValue = options.filter(option => value.find(v => v === option.value));
    }
  } else {
    if ((typeof value === 'string' && value.length) || typeof value === 'boolean') {
      adjustedValue = options.find(option => option.value === value);
    } else if (_.isArray(value) && value.length) {
      adjustedValue = options.find(option => option.value === value[0]);
    } else if (_.isArray(value) && !value.length) {
      adjustedValue = options.find(option => option.value === '');
    }
  }

  function handleChange(val) {
    if (_.isArray(val)) {
      const adjustedValue = val.map(v => {
        return v.value;
      });
      onChange(field, adjustedValue.join(','));
    } else {
      onChange(field, val.value);
    }
  }

  return (
    <div data-automation-id={automationId}>
      <Select
        name={`${field}-filter`}
        defaultValue={adjustedValue}
        value={adjustedValue}
        delimiter=","
        options={options}
        onChange={handleChange}
        isMulti={isMulti}
        placeholder={placeholder}
        isClearable={isClearable}
        className={'srcclr-react-select-container'}
        classNamePrefix={'srcclr-react-select'}
        noOptionsMessage={() => 'No results found'}
      />
    </div>
  );
};

export default ReportOptionsFilter;
