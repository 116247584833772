import React from 'react';
import Select from 'react-select';
import PoliciesHelper from '~/utils/PoliciesHelper';
import Helpers from '~/utils/Helpers';
import Tooltip from '~/components/Tooltip';
import classNames from 'classnames';
import {
  ControlsEntity,
  Condition,
  Action,
  Resource,
  ResourceParameters,
  Descriptor,
  DescriptorParameters,
  Provider,
  ProviderParameters,
} from '~/types/Policy.d.ts';
import { LicenseState } from '~/types/license';
import {
  resourceTypeMap,
  exitLevelOptions,
  descriptorTypeOptions,
  descriptorSeverityOptions,
  descriptorLicenseOptions,
  matcherOptions,
  vulnerableMethodOptions,
  descriptorLibraryOptions,
  resourceDependencyOptions,
  highMediumLowRisks,
  licenseRisksMap,
  LICENSE_RISK_CONST_MAP,
} from '~/constants/ModelConstants';

interface PolicyControlCardProps {
  control: ControlsEntity;
  editMode: boolean;
  isExpanded: boolean;
  cardMoved: boolean;
  controlLength: number;
  index: number;
  updateControl: (...args: any[]) => any;
  fetchLicensesByRisk: (...args: any[]) => any;
  removeControl: (...args: any[]) => any;
  moveControl: (...args: any[]) => any;
  toggleControlExpand: (...args: any[]) => any;
  validation?: object;
  licenseState: LicenseState;
  showGplOrMulti: string;
}

const PolicyControlCard: React.FunctionComponent<PolicyControlCardProps> = props => {
  const {
    control,
    index,
    editMode,
    isExpanded,
    controlLength,
    cardMoved,
    validation,
    licenseState,
    showGplOrMulti,
  } = props;

  const {
    condition = {} as Partial<Condition>,
    level,
    severity = '',
    action = {} as Partial<Action>,
    name = '',
  } = control;
  const {
    resource = {} as Partial<Resource>,
    descriptor = {} as Partial<Descriptor>,
    matcher,
  } = condition;
  const {
    type: resourceType,
    parameters: resourceParameters = {} as Partial<ResourceParameters>,
  } = resource;
  const { dependency } = resourceParameters;
  const {
    type: descriptorType,
    parameters: descriptorParameters = {} as Partial<DescriptorParameters>,
  } = descriptor;
  const {
    severity: descriptorSeverity,
    vulnerable_method,
    kind: descriptorLicense,
    risk: licenseRiskType,
    severity_override = '',
    includes: includedLicensesItems = [],
    excludes: excludedLicensesItems = [],
  } = descriptorParameters;
  const { provider = {} as Partial<Provider> } = action;
  const { parameters: actionParams = {} as Partial<ProviderParameters> } = provider;
  const { create_issue } = actionParams;

  // This is to disable license risk feature untill all related implementations are completed.
  // After all features have been implemented this feature check should be removed.
  const isLicenseRiskFeatureEnable = Helpers.hasPolicyRiskEnabled();

  // Validation configuration
  const enableControlLevelHighlight = true; //Outlines individual offending controls eg. red border

  // Validation: Assignments and destructuring of validation-related state for top level controls - ie severity, level
  // policy.controls[<index>]
  const {
    invalid: invalidControl = false,
    severity: severityControl = {},
    level: levelControl = {},
    condition: conditionControlGroup = {},
    name: nameControl = {},
  } = validation;
  const { invalid: invalidSeverity = false, errors: severityErrors = [] } =
    (enableControlLevelHighlight && severityControl) || {};
  const { invalid: invalidLevel = false, errors: levelErrors = [] } =
    (enableControlLevelHighlight && levelControl) || {};
  const { invalid: invalidName = false, errors: nameErrors = [] } =
    (enableControlLevelHighlight && nameControl) || {};

  // Validation for `condition`: Destructuring of validation-related state under the `condition` control team
  // policy.controls[<index>].condition
  const {
    matcher: matcherControl = {},
    descriptor: descriptorControlGroup = {},
    resource: resourceControlGroup = {},
  } = (enableControlLevelHighlight && conditionControlGroup) || {};
  const { invalid: invalidMatcher = false, errors: matcherErrors = [] } = matcherControl;

  // Validation for `condition.descriptor` : Destructuring of validation-related state under the `condition.descriptor` control team
  // policy.controls[<index>].condition.descriptor
  const {
    type: typeControl = {},
    parameters: parametersControlGroup = {},
  } = descriptorControlGroup;
  const { invalid: invalidType = false, errors: typeErrors = [] } = typeControl;

  // Validation for `condition.descriptor.parameters` : Destructuring of validation-related state under the `condition.descriptor.parameters` control team
  // policy.controls[<index>].condition.descriptor.parameters
  const {
    severity: paramSeverityControl = {},
    vulnerable_method: vulnMethodControl = {},
    kind: kindControl = {} /*latest_version:latestVersionControl = {}*/,
    includes: includesControl = {},
  } = parametersControlGroup;
  const {
    invalid: invalidParamSeverity = false,
    errors: paramSeverityErrors = [],
  } = paramSeverityControl;
  const { invalid: invalidVulnMethod = false, errors: vulnMethodErrors = [] } = vulnMethodControl;
  const { invalid: invalidKind = false, errors: kindErrors = [] } = kindControl;
  const { invalid: invalidIncludes = false, errors: includesErrors = [] } = includesControl;
  //const { invalid:invalidLatestVersion = false } = latestVersionControl; //NOTE: uncomment when supporting other values
  const { parameters: resourceParametersControlGroup = {} } = resourceControlGroup;
  const { invalid: invalidResourceParam = false } = resourceParametersControlGroup;

  // Valdation: styles
  const invalidCardClassName = 'bo--2 border-color--danger';
  const validCardClassName = 'bo--1 bg-color--white-light border-color--muted-light';
  const invalidControlClassName = 'bo--2 border-color--danger';
  const invalidInputControlClassName = 'bg-color--white-light';
  const validInputControlClassName = 'control--text';

  const isTypeVulnerability = descriptorType === 'vulnerability';
  const isTypeLicense = descriptorType === LICENSE_RISK_CONST_MAP.LICENSE;
  const isTypeLibrary = descriptorType === LICENSE_RISK_CONST_MAP.LIBRARY;
  const isOverrideSeverity = severity_override.length > 0;
  const isLicenseByName = descriptorLicense === LICENSE_RISK_CONST_MAP.CUSTOM;
  const isLicenseByRisk = descriptorLicense === LICENSE_RISK_CONST_MAP.RISK;

  const licenseOptions = [{ value: 'unlicensed', label: 'unlicensed' }];

  const licenseOptionsForDescriptor = isLicenseRiskFeatureEnable
    ? descriptorLicenseOptions
    : licenseOptions;

  const gplAvailable = licenseOptionsForDescriptor.some(
    v => v.value === LICENSE_RISK_CONST_MAP.GPL
  );
  const multiAvailable = licenseOptionsForDescriptor.some(
    v => v.value === LICENSE_RISK_CONST_MAP.MULTI
  );

  const gplObj = { value: LICENSE_RISK_CONST_MAP.GPL, label: 'GPL' };
  const multiObj = { value: LICENSE_RISK_CONST_MAP.MULTI, label: 'multiple' };

  if (isLicenseRiskFeatureEnable) {
    if (showGplOrMulti === LICENSE_RISK_CONST_MAP.GPL && !gplAvailable) {
      licenseOptionsForDescriptor.push(gplObj);
    } else if (showGplOrMulti === LICENSE_RISK_CONST_MAP.MULTI && !multiAvailable) {
      licenseOptionsForDescriptor.push(multiObj);
    } else if (showGplOrMulti === 'none') {
      if (gplAvailable || multiAvailable) {
        licenseOptionsForDescriptor.pop();
      }
    }
  } else {
    licenseOptionsForDescriptor.push(gplObj);
    licenseOptionsForDescriptor.push(multiObj);
  }

  const { licenses: allLicenses = [], licensesByRisk: allLicensesByRisk = [] } = licenseState;
  const licenseIdtoLabelMap = new Map();
  const licenseList = allLicenses
    .filter(license => {
      licenseIdtoLabelMap.set(license.id, license.name);
      return includedLicensesItems.includes(license.id) === false;
    })
    .map(license => {
      let licenseName = license.name;
      licenseIdtoLabelMap.set(license.id, licenseName);
      return {
        label: licenseName,
        value: license,
      };
    });

  const licenseListByRisk = allLicensesByRisk
    .filter(license => excludedLicensesItems.includes(license.id) === false)
    .map(license => {
      const licenseName = license.name;
      return {
        label: licenseName,
        value: license,
      };
    });

  const selectedKind = isLicenseRiskFeatureEnable
    ? getSelectedKind(descriptorLicense)
    : descriptorLicense;

  const selectedLicensesClassNames = classNames('overflow--auto mt-- ', {
    'border-color--muted-light bg-color--muted-light color--muted-dark': !editMode,
    'license-multi-select': editMode,
  });

  const selectedIncludesLicensesClassNames = classNames(
    'policy col-1-1 srcclr-react-select-container license-option-wrap',
    {
      [invalidControlClassName]: invalidIncludes,
    }
  );
  //adds license
  function updateLicesnseParameter(option, type) {
    const newIncludedLicensesItems = [...includedLicensesItems];
    newIncludedLicensesItems.push(option.value.id);
    //licenseList.splice(includedLicensesItems.indexOf(option.value.id), 1);
    updateControl(newIncludedLicensesItems, type);
  }

  function updateExcludesParameter(option, type) {
    //licenseListByRisk.splice(excludedLicensesItems.indexOf(option.value.id), 1);
    const newExcludedLicensesItems = [...excludedLicensesItems];
    newExcludedLicensesItems.push(option.value.id);
    updateControl(newExcludedLicensesItems, type);
  }

  //removes license from multi-select area
  function removeSelectedlicense(license, type) {
    const newIncludedLicensesItems = [...includedLicensesItems];
    newIncludedLicensesItems.splice(includedLicensesItems.indexOf(license), 1);
    updateControl(newIncludedLicensesItems, type);
  }

  //removes risks from multi-select area
  function removeExcludedLicense(license, type) {
    const newExcludedLicensesItems = [...excludedLicensesItems];
    newExcludedLicensesItems.splice(excludedLicensesItems.indexOf(license), 1);
    updateControl(newExcludedLicensesItems, type);
  }

  function updateSeverityParameter(value, type) {
    updateControl(value, type);

    // Do not update severity override with cvss score field
    // since the checkbox is not checked
    if (!isOverrideSeverity) {
      return;
    }

    value.startsWith('cvss_v3')
      ? updateControl('cvss3', 'severity_override')
      : updateControl('cvss', 'severity_override');
  }

  function updateSeverityOverrideParameter(value, type) {
    if (value) {
      // previously checked, uncheck now
      updateControl(null, type);
      return;
    }

    descriptorSeverity.startsWith('cvss_v3')
      ? updateControl('cvss3', type)
      : updateControl('cvss', type);
  }

  function getSelectedKind(descriptorLicense) {
    if (isLicenseByRisk) {
      return licenseRisksMap[licenseRiskType].kind;
    } else if (isLicenseByName) {
      return LICENSE_RISK_CONST_MAP.LICENSE_BY_NAME;
    }
    return descriptorLicense;
  }

  function updateKindParameter(option, type) {
    //clear licenses parameter when kind is not license-by-name
    const { index } = props;
    if (option !== LICENSE_RISK_CONST_MAP.LICENSE_BY_NAME) {
      props.updateControl(null, LICENSE_RISK_CONST_MAP.INCLUDES, index);
    }
    props.updateControl(null, LICENSE_RISK_CONST_MAP.EXCLUDES, index);
    props.updateControl(null, LICENSE_RISK_CONST_MAP.RISK, index);
    if (highMediumLowRisks.includes(option)) {
      props.fetchLicensesByRisk(option);
    }
    updateControl(option, type);
  }

  function updateControl(option, type) {
    const { index } = props;
    props.updateControl(option, type, index);

    /*
     * If the user is changing the 'type' of control, we might need to alter some more fields for them
     * If not, we're done here
     */
    if (type !== 'type') {
      return;
    }

    /*
     * Sets the default parameters given a particular descriptor type
     */
    if (option === LICENSE_RISK_CONST_MAP.LICENSE) {
      if (isLicenseRiskFeatureEnable) {
        props.updateControl(
          LICENSE_RISK_CONST_MAP.LICENSE_BY_NAME,
          LICENSE_RISK_CONST_MAP.KIND,
          index
        ); // sets 'kind'to license-by-name by default
      } else {
        props.updateControl(LICENSE_RISK_CONST_MAP.GPL, LICENSE_RISK_CONST_MAP.KIND, index); // sets 'kind' to gpl by default
      }
    } else if (option === LICENSE_RISK_CONST_MAP.LIBRARY) {
      props.updateControl(true, 'latest_version', index); // sets 'latest_version' to true by default
    } else if (option === 'vulnerability') {
      props.updateControl(false, 'vulnerable_method', index); // sets 'vulnerable_method' to false by default
    }

    /*
     * Clean up the existing parameters that was for existing descriptor type
     */
    if ([LICENSE_RISK_CONST_MAP.LICENSE, LICENSE_RISK_CONST_MAP.LIBRARY].includes(option)) {
      // Clear vuln parameters
      props.updateControl(null, 'severity_override', index);
      props.updateControl(null, 'vulnerable_method', index);
      props.updateControl(null, 'severity', index);
    }
    if ([LICENSE_RISK_CONST_MAP.LICENSE, 'vulnerability'].includes(option)) {
      // Clear library parameters
      props.updateControl(null, 'latest_version', index);
    }
    if (['vulnerability', LICENSE_RISK_CONST_MAP.LIBRARY].includes(option)) {
      // Clear license parameters
      props.updateControl(null, LICENSE_RISK_CONST_MAP.KIND, index);
      if (isLicenseRiskFeatureEnable) {
        props.updateControl(null, LICENSE_RISK_CONST_MAP.INCLUDES, index);
        props.updateControl(null, LICENSE_RISK_CONST_MAP.EXCLUDES, index);
        props.updateControl(null, LICENSE_RISK_CONST_MAP.RISK, index);
      }
    }
  }

  function updateAction(option, type) {
    const { index } = props;

    props.updateControl(option, type, index);
  }

  function updateSeverity(option) {
    const { index } = props;
    const { target = {} } = option;
    const { value } = target;

    props.updateControl(value, 'control-severity', index);
  }

  function removeControl() {
    const { index } = props;

    props.removeControl(index);
  }

  function toggleControlExpand() {
    const { index } = props;
    if (!isExpanded && isLicenseRiskFeatureEnable && isLicenseByRisk) {
      props.fetchLicensesByRisk(licenseRisksMap[licenseRiskType].kind);
    }
    props.toggleControlExpand(index);
  }

  function moveControl(toIndex) {
    const { index } = props;

    props.moveControl(index, toIndex);
  }

  return (
    <div
      className={`${
        invalidControl ? invalidCardClassName : validCardClassName
      } card--stable ph- pv-- mv-- ${cardMoved && 'card--moved'} hover-row`}
      data-automation-id="PolicyControlCard"
    >
      <div className="grid">
        <div className="grid__item col-1-1">
          <div className="grid mb---">
            <div className="grid__item flex align-items--center col-1-3">
              <strong>Control Name</strong>
            </div>
            <div className="grid__item flex align-items--center justify-content--center col-1-12">
              <Tooltip
                id={`severity-${index}`}
                content={`The severity is a number from 0.1 (lowest) to 10.0 (highest) that lets you determine how serious a control violation is considered in these rules. Severities appear on lists of issues to make them easier to rank. Severity is different from a vulnerability risk (CVSS) score.`}
                maxWidthClass="max-width--300"
                place="top"
              >
                <strong>Severity</strong>
              </Tooltip>
            </div>
            <div className="grid__item flex align-items--center col-1-8">
              <Tooltip
                id={`level-${index}`}
                content={`A level of "error" will return an exit code equivalent to the highest control severity from all failed controls, which can be used to break a build. A level of "warning" will return an exit code of "0" which can be used to allow the build to continue.`}
                maxWidthClass="max-width--300"
                place="top"
              >
                <strong>Level</strong>
              </Tooltip>
            </div>
            <div className="grid__item flex align-items--center" />
            <div
              className="grid__item col-1-12 flex justify-content--end align-items--center"
              data-automation-id="PolicyControlCardList-Actions"
            >
              <button
                className={`p--- btn--transparent bo--0 ${
                  editMode ? 'color--danger-hover-row' : 'invisible'
                }`}
                disabled={!editMode}
                onClick={() => removeControl()}
                title="Delete control"
              >
                <i className="sci sci__trash" />
              </button>
              <button
                className={`p--- btn--transparent bo--0 ${!editMode ? 'invisible' : ''}`}
                disabled={!editMode || index <= 0}
                onClick={() => moveControl(index - 1)}
                title="Move up"
              >
                <i className="sci sci__arrow--up" />
              </button>
              <button
                className={`p--- btn--transparent bo--0 ${!editMode ? 'invisible' : ''}`}
                disabled={!editMode || index >= controlLength - 1}
                onClick={() => moveControl(index + 1)}
                title="Move down"
              >
                <i className="sci sci__arrow--down" />
              </button>
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1">
          <div className="grid">
            <div
              className="grid__item flex align-items--center col-1-3 policy"
              data-automation-id="PolicyControlCard-Control-Name"
            >
              <input
                type="text"
                className={`${
                  invalidName
                    ? `${invalidControlClassName} ${invalidInputControlClassName}`
                    : validInputControlClassName
                } col-1-1 text-center font--h8`}
                disabled={!editMode}
                onChange={e => updateControl(e.target.value, 'name')}
                value={name}
                placeholder="Example: Check for high risk vulns with vuln method"
              />
            </div>
            <div
              className="grid__item flex align-items--center col-1-12 policy"
              data-automation-id="PolicyControlCard-Control-Severity"
            >
              <Tooltip
                id={`severityfield-${index}`}
                content={`You cannot enter a value for Severity because it is being set to the vulnerability's CVSS score. To enter a manual Severity, first uncheck "Override Severity with CVSS Score"`}
                maxWidthClass="max-width--300"
                place="top"
                active={isOverrideSeverity}
              >
                <input
                  className={`${
                    invalidSeverity
                      ? `${invalidControlClassName} ${invalidInputControlClassName}`
                      : validInputControlClassName
                  } col-1-1 text--center ph0 font--h8`}
                  onChange={e => updateSeverity(e)}
                  placeholder={isOverrideSeverity ? '' : '0.1 - 10.0 '}
                  value={isOverrideSeverity ? '' : severity}
                  disabled={!editMode || isOverrideSeverity}
                  type="text"
                  pattern="^([0-9]|10)([\.][\d]{1})?$"
                  title="Must be between 0.1 and 10.0 and contain no more than one decimal place (0.1)"
                />
              </Tooltip>
            </div>
            <div
              className="grid__item flex align-items--center col-1-6 policy"
              data-automation-id="PolicyControlCard-Control-Level"
            >
              <Select
                options={exitLevelOptions}
                value={exitLevelOptions.find(option => option.value === level || '')}
                isDisabled={!editMode}
                isClearable={false}
                onChange={level => updateControl(level.value, 'level')}
                className={`${
                  invalidLevel ? invalidControlClassName : ''
                } col-1-1 srcclr-react-select-container`}
                classNamePrefix={'srcclr-react-select'}
              />
            </div>
            <div className="grid__item flex col-1-3 align-items--center" />
            <div
              className={`grid__item col-1-12 flex justify-content--end ${
                editMode ? 'align-items--end pb--' : ''
              }`}
            >
              <div
                className="link--obvious"
                onClick={() => toggleControlExpand()}
                title={`${isExpanded ? 'Collapse' : 'Expand'}`}
                data-automation-id="PolicyControlCard-Toggle-Expand"
              >
                <i className={`sci sci__caret--${!isExpanded ? 'right' : 'down'}`} />
              </div>
            </div>
            <div className="grid__item col-1-3">
              {invalidName && (
                <div className="col-1-1">
                  {nameErrors.map((error, index) => {
                    return (
                      <div
                        className="col-1-1 font--h8 mt-- color--danger-dark"
                        key={`matcher-error-${index}`}
                      >
                        {PoliciesHelper.formatError(error)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="grid__item col-1-12">
              {invalidSeverity && (
                <div className="col-1-1">
                  {severityErrors.map((error, index) => {
                    return (
                      <div
                        className="col-1-1 font--h8 mt-- color--danger-dark"
                        key={`matcher-error-${index}`}
                      >
                        {PoliciesHelper.formatError(error)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="grid__item col-1-6">
              {invalidLevel && (
                <div className="col-1-1">
                  {levelErrors.map((error, index) => {
                    return (
                      <div
                        className="col-1-1 font--h8 mt-- color--danger-dark"
                        key={`matcher-error-${index}`}
                      >
                        {PoliciesHelper.formatError(error)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`grid__item col-1-1 ${isExpanded ? 'is-showing-400--scroll' : 'is-hiding'}`}
        >
          <div className="bo-t--1 mt- mb border-color--muted-light col-1-1">
            <div className="font--h5 mt-- col-1-5">
              <Tooltip
                id={`condition-${index}`}
                content={`A rule that should be true, comprised of a Resource, Matcher, and Descriptor.`}
                maxWidthClass="max-width--300"
                place="top"
              >
                <span className="font--h5" data-automation-id="PolicyControlCard-Heading-Condition">
                  Condition
                </span>
              </Tooltip>
            </div>
            <div className="grid grid--narrower mt-">
              <div className="grid__item col-1-5">
                <div className="bg-color--blue-muted p- min-height--105">
                  <div className="mv--">
                    <Tooltip
                      id={`resource-${index}`}
                      content={`The resource is the entity which is being inspected for certain conditions. Currently, SourceClear can inspect libraries.`}
                      maxWidthClass="max-width--300"
                      place="top"
                    >
                      <strong>Resource</strong>
                    </Tooltip>
                  </div>
                  <div className="grid ">
                    <div
                      className="grid__item col-1-1"
                      data-automation-id="PolicyControlCard-ResourceType"
                    >
                      <input
                        type="text"
                        className="control--text font--h8 pl--"
                        value={resourceTypeMap[resourceType]}
                        readOnly
                      />
                    </div>
                    <div className="grid__item col-1-1 ">
                      <div className="font--h8 mt-">Parameters</div>
                      <div className="col-1-1 bo-t--1 mt--- border-color--muted" />
                    </div>
                    <div
                      className="grid__item col-1-1 mt-"
                      data-automation-id="PolicyControlCard-Resource-Dependency"
                    >
                      <Tooltip
                        id={`dependency-${index}`}
                        content={
                          <div>
                            <p>
                              <strong>"Any"</strong> means that libraries which are either direct or
                              transitive will be examined.
                            </p>
                            <p>
                              <strong>"Direct"</strong> means that only libraries which are
                              referenced in the repository's configuration file will be examined.
                            </p>
                            <p>
                              <strong>"Transitive"</strong> means that only libraries which are not
                              directly specified in the repository's configuration file will be
                              examined.
                            </p>
                            <p>
                              <strong>"Both"</strong> means that only libraries which are both
                              direct and transitive will be examined.
                            </p>
                          </div>
                        }
                        maxWidthClass="max-width--300"
                        place="top"
                      >
                        <div className="mb--">Dependency</div>
                      </Tooltip>
                      <Select
                        options={resourceDependencyOptions}
                        isClearable={false}
                        value={resourceDependencyOptions.find(
                          option => option.value === dependency
                        )}
                        isDisabled={!editMode}
                        onChange={option => updateControl(option.value, 'dependency')}
                        className={`col-1-1 policy srcclr-react-select-container ${
                          invalidResourceParam && invalidControlClassName
                        }`}
                        classNamePrefix={'srcclr-react-select'}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid__item col-1-5">
                <div className="bg-color--tan-muted p- min-height--105">
                  <div className="mv--">
                    <Tooltip
                      id={`matcher-${index}`}
                      content={`The matcher is a comparison operator that defines how the resource will be inspected.`}
                      maxWidthClass="max-width--300"
                      place="top"
                    >
                      <strong>Matcher</strong>
                    </Tooltip>
                  </div>
                  <div data-automation-id="PolicyControlCard-Matcher">
                    <Select
                      options={matcherOptions}
                      isClearable={false}
                      value={matcherOptions.find(option => option.value === matcher || '')}
                      isDisabled={!editMode}
                      onChange={option => updateControl(option.value, 'matcher')}
                      className={`${
                        invalidMatcher ? invalidControlClassName : ''
                      } col-1-1 policy srcclr-react-select-container`}
                      classNamePrefix={'srcclr-react-select'}
                    />
                  </div>
                  {invalidMatcher && (
                    <div className="col-1-1">
                      {matcherErrors.map((error, index) => {
                        return (
                          <div
                            className="col-1-1 font--h8 mt-- color--danger-dark"
                            key={`matcher-error-${index}`}
                          >
                            {PoliciesHelper.formatError(error)}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="grid__item col-3-5">
                <div className="bg-color--purple-muted p- min-height--105">
                  <div className="grid">
                    <div className="grid__item col-1-3">
                      <div className="mv--">
                        <Tooltip
                          id={`descriptor-${index}`}
                          content={`The descriptor and its attributes define the checks performed against the resource.`}
                          maxWidthClass="max-width--300"
                          place="top"
                        >
                          <strong>Descriptor</strong>
                        </Tooltip>
                      </div>
                      <div data-automation-id="PolicyControlCard-DescriptorType">
                        <Select
                          options={descriptorTypeOptions}
                          isClearable={false}
                          value={descriptorTypeOptions.find(
                            option => option.value === descriptorType || ''
                          )}
                          isDisabled={!editMode}
                          onChange={option => updateControl(option.value, 'type')}
                          className={`${
                            invalidType ? invalidControlClassName : ''
                          } col-1-1 policy srcclr-react-select-container`}
                          classNamePrefix={'srcclr-react-select'}
                        />
                      </div>
                      {invalidType && (
                        <div className="col-1-1">
                          {typeErrors.map((error, index) => {
                            return (
                              <div
                                className="col-1-1 font--h8 mt-- color--danger-dark"
                                key={`matcher-error-${index}`}
                              >
                                {PoliciesHelper.formatError(error)}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <div className="font--h8 mt-">Parameters</div>
                    </div>
                    <div className="grid__item col-2-3" />
                    <div className="grid__item col-1-1">
                      <div className="col-1-1 bo-t--1 mt--- border-color--muted" />
                    </div>
                    {isTypeVulnerability && (
                      <div className="grid__item col-1-1">
                        <div className="grid">
                          <div
                            className="grid__item col-3-10 mt-"
                            data-automation-id="PolicyControlCard-Descriptor-Vulns-Severity"
                          >
                            <div className="mb--">Severity</div>
                            <Select
                              options={descriptorSeverityOptions}
                              isClearable={false}
                              value={descriptorSeverityOptions.find(
                                option => option.value === descriptorSeverity || ''
                              )}
                              isDisabled={!editMode}
                              onChange={option => updateSeverityParameter(option.value, 'severity')}
                              className={`${
                                invalidParamSeverity ? invalidControlClassName : ''
                              } col-1-1 policy flex-grow--1 policy srcclr-react-select-container`}
                              classNamePrefix={'srcclr-react-select'}
                            />
                          </div>
                          <div
                            className="grid__item col-3-10 mt-"
                            data-automation-id="PolicyControlCard-Descriptor-Vulns-VulnerableMethod"
                          >
                            <div className="mb--">Vulnerable Method</div>
                            <Select
                              options={vulnerableMethodOptions}
                              isClearable={false}
                              value={vulnerableMethodOptions.find(
                                option => option.value === vulnerable_method
                              )}
                              isDisabled={!editMode}
                              onChange={option => updateControl(option.value, 'vulnerable_method')}
                              className={`${
                                invalidVulnMethod ? invalidControlClassName : ''
                              } policy col-1-1 srcclr-react-select-container`}
                              classNamePrefix={'srcclr-react-select'}
                            />
                          </div>
                          <div
                            className="grid__item col-4-10 mt-"
                            data-automation-id="PolicyControlCard-Descriptor-Vulns-CVSS"
                          >
                            <div className="mb-- font">
                              <Tooltip
                                id={`severity_override-${index}`}
                                content={`Select to use a vulnerability's CVSS score for the control severity instead of value entered in the Severity field above.`}
                                maxWidthClass="max-width--300"
                                place="top"
                              >
                                Override Severity with CVSS Score
                              </Tooltip>
                            </div>
                            <label className="label--checkbox flex justify-content--center align-items--center mb0 policy">
                              <input
                                type="checkbox"
                                name={`${index}-severity_override`}
                                id={`${index}-severity_override`}
                                onChange={() =>
                                  updateSeverityOverrideParameter(
                                    isOverrideSeverity,
                                    'severity_override'
                                  )
                                }
                                checked={isOverrideSeverity}
                                disabled={!editMode}
                              />
                              <span className="react--checkbox mr0" />
                            </label>
                          </div>
                          <div className="grid__item col-3-10">
                            {invalidParamSeverity && (
                              <div className="col-1-1">
                                {paramSeverityErrors.map((error, index) => {
                                  return (
                                    <div
                                      className="col-1-1 font--h8 mt-- color--danger-dark"
                                      key={`matcher-error-${index}`}
                                    >
                                      {PoliciesHelper.formatError(error)}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                          <div className="grid__item col-3-10">
                            {invalidVulnMethod && (
                              <div className="col-1-1">
                                {vulnMethodErrors.map((error, index) => {
                                  return (
                                    <div
                                      className="col-1-1 font--h8 mt-- color--danger-dark"
                                      key={`matcher-error-${index}`}
                                    >
                                      {PoliciesHelper.formatError(error)}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                          <div className="grid__item col-4-10" />
                        </div>
                      </div>
                    )}
                    {isTypeLicense && (
                      <div className="grid__item col-1-1 grid">
                        <div
                          className="grid__item col-1-3 mt-"
                          data-automation-id="PolicyControlCard-Descriptor-Licence-Risk"
                        >
                          <div className="mb--">Kind</div>
                          <Select
                            options={licenseOptionsForDescriptor}
                            isClearable={false}
                            value={licenseOptionsForDescriptor.find(
                              option => option.value === selectedKind || ''
                            )}
                            isDisabled={!editMode}
                            onChange={option =>
                              isLicenseRiskFeatureEnable
                                ? updateKindParameter(option.value, LICENSE_RISK_CONST_MAP.KIND)
                                : updateControl(option.value, LICENSE_RISK_CONST_MAP.KIND)
                            }
                            className={`${
                              invalidKind ? invalidControlClassName : ''
                            } flex-grow--1 policy srcclr-react-select-container`}
                            classNamePrefix={'srcclr-react-select'}
                          />
                          {invalidKind && (
                            <div className="col-1-1">
                              {kindErrors.map((error, index) => {
                                return (
                                  <div
                                    className="col-1-1 font--h8 mt-- color--danger-dark"
                                    key={`matcher-error-${index}`}
                                  >
                                    {PoliciesHelper.formatError(error)}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                        {isLicenseByName && isLicenseRiskFeatureEnable && (
                          <div
                            className="grid__item col-3-6 mt- ml"
                            data-automation-id="PolicyControlCard-Descriptor-License-Includes"
                          >
                            <div className="mb--">Including</div>
                            <Select
                              options={licenseList} // licenses
                              isClearable={false}
                              value={licenseList.find(option => option.label === '')}
                              isDisabled={!editMode}
                              onChange={option =>
                                updateLicesnseParameter(option, LICENSE_RISK_CONST_MAP.INCLUDES)
                              }
                              className={selectedIncludesLicensesClassNames}
                              classNamePrefix={'srcclr-react-select'}
                            />
                            {invalidIncludes && (
                              <div className="col-1-1">
                                {includesErrors.map((error, index) => {
                                  return (
                                    <div
                                      className="col-1-1 font--h8 mt-- color--danger-dark"
                                      key={`matcher-error-${index}`}
                                    >
                                      {PoliciesHelper.formatError(error)}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            <div>
                              <div className={selectedLicensesClassNames}>
                                {includedLicensesItems.map(license => {
                                  return (
                                    <div
                                      className="grid grid--full pv-- hover-row"
                                      key={`selected-${license}`}
                                    >
                                      <div className="grid__item col-11-12 pl-">
                                        {licenseIdtoLabelMap.get(license)}
                                      </div>
                                      <div className="grid__item col-1-12 flex justify-content--center">
                                        <button
                                          className="p--- flex align-items--center"
                                          onClick={() =>
                                            removeSelectedlicense(
                                              license,
                                              LICENSE_RISK_CONST_MAP.INCLUDES
                                            )
                                          }
                                          disabled={!editMode}
                                        >
                                          <Tooltip
                                            id={`license-${license}-delete`}
                                            content="Remove this license."
                                            place="top"
                                          >
                                            <i className="sci sci__trash" />
                                          </Tooltip>
                                        </button>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}

                        {isLicenseByRisk && isLicenseRiskFeatureEnable && (
                          <div className="grid__item col-3-6 mt- ml">
                            <div className="mb--">Excluding</div>
                            <Select
                              options={licenseListByRisk} // excludes
                              isClearable={false}
                              value={licenseListByRisk.find(option => option.label === '')}
                              isDisabled={!editMode}
                              onChange={option =>
                                updateExcludesParameter(option, LICENSE_RISK_CONST_MAP.EXCLUDES)
                              }
                              className={`policy col-1-1 srcclr-react-select-container license-option-wrap`}
                              classNamePrefix={'srcclr-react-select'}
                            />

                            <div>
                              <div className={selectedLicensesClassNames}>
                                {excludedLicensesItems.map(license => {
                                  return (
                                    <div
                                      className="grid grid--full pv-- hover-row"
                                      key={`selected-${license}`}
                                    >
                                      <div className="grid__item col-11-12 pl-">
                                        {licenseIdtoLabelMap.get(license)}
                                      </div>
                                      <div className="grid__item col-1-12 flex justify-content--center">
                                        <button
                                          className="p--- flex align-items--center"
                                          onClick={
                                            editMode
                                              ? () =>
                                                  removeExcludedLicense(
                                                    license,
                                                    LICENSE_RISK_CONST_MAP.EXCLUDES
                                                  )
                                              : undefined
                                          }
                                          disabled={!editMode}
                                        >
                                          <Tooltip
                                            id={`license-${license}-delete`}
                                            content="Remove this license."
                                            place="top"
                                          >
                                            <i className="sci sci__trash" />
                                          </Tooltip>
                                        </button>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {isTypeLibrary && (
                      <div className="grid__item col-1-1 grid">
                        <div
                          className="grid__item col-1-3 mt-"
                          data-automation-id="PolicyControlCard-Descriptor-Library-Version"
                        >
                          <div className="mb--">Version</div>
                          {/* NOTE: This is the dropdown to use and
                            will replace the readOnly input below once we support other version categories other than latest such as safe, updated last month
                          <Select
                            options={descriptorLibraryOptions}
                            isClearable={false}
                            value={descriptorLibraryOptions.find(option => option.value === descriptorVersion || true)}
                            isDisabled={!editMode}
                            onChange={(option) => updateControl(option.value, 'latest_version')}
                            className={`${invalidLatestVersion ? invalidControlClassName : ''} flex-grow--1 policy srcclr-react-select-container`}
                            classNamePrefix={'srcclr-react-select'}
                          /> */}

                          {/* NOTE: This is set to 'latest' for now until we support version categories */}
                          <input
                            type="text"
                            className="control--text font--h8"
                            value={descriptorLibraryOptions[0].label}
                            readOnly
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1-1 bo-t--1 border-color--muted-light mt-" />
            <div className="font--h5 mt-- col-1-5">
              <Tooltip
                id={`action-${index}`}
                content={`An action is triggered when the condition is violated and the control fails.`}
                maxWidthClass="max-width--300"
                place="top"
              >
                <span className="font--h5" data-automation-id="PolicyControlCard-Heading-Action">
                  Action
                </span>
              </Tooltip>
            </div>
            <div className="grid mt-">
              <div
                className="grid__item col-1-1 flex align-items--center"
                data-automation-id="PolicyControlCard-Action-CreateIssue    "
              >
                <div className="mr-" id="create-issue">
                  <strong>Create Issue</strong>
                </div>
                <label className="label--checkbox flex justify-content--center mb0 policy">
                  <input
                    type="checkbox"
                    checked={create_issue || false}
                    disabled={!editMode}
                    onChange={() => updateAction(!create_issue, 'action')}
                  />
                  <span className="react--checkbox mr0" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyControlCard;
