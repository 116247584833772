import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import DropdownMenu from 'react-dd-menu';
import _ from 'lodash';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as orgActions from '~/actions/org';
import * as orgTeamActions from '~/actions/orgTeam';

import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import { FEATURE_SLUG_MAP } from '~/constants/ModelConstants';

import SourceClearModal from '~/components/SourceClearModal';
import Tooltip from '~/components/Tooltip';
import WorkspaceGroupsQuickView from '~/components/WorkspaceGroupsQuickView';
import WorkspaceUsersQuickView from '~/components/WorkspaceUsersQuickView';
import CreateWorkspaceModal from '~/containers/CreateWorkspaceModal';
import UserManagementQuickViewWrapper from '~/containers/UserManagementQuickViewWrapper';

interface WorkspacesPageProps {
  dropdownMenuActions: object;
  orgActions: object;
  orgTeamActions: object;
  dropdownMenuState: object;
  myState: App.MyState;
  orgState: App.OrgState;
  orgTeamState: object;
  shouldShowVeracodePage?: boolean;
}
class WorkspacesPage extends React.Component<WorkspacesPageProps, {}> {
  constructor(props, context) {
    super(props, context);

    this.delayCloseDropdown = _.debounce(() => this.closeDropdown('workspaces-actions'), 50);
  }

  componentDidMount() {
    this.props.orgTeamActions.fetchOrgTeams();
  }

  componentWillUnmount() {
    this.props.orgTeamActions.resetTeamSort();
    this.props.orgTeamActions.resetWorkspaceQuickViews();
  }

  closeDropdown(menuId) {
    this.props.dropdownMenuActions.closeDropdownMenu(menuId);
  }

  openDropdown(menuId) {
    this.props.dropdownMenuActions.openDropdownMenu(menuId);
  }

  handleWorkspaceSelection(teamId) {
    this.props.orgTeamActions.updateSelectedOrgTeams(teamId);
  }

  handleFilterInput(e) {
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.orgTeamActions.filterTeamsByInput(value);
  }

  updateSort(column) {
    this.props.orgTeamActions.updateSort(column);
  }

  toggleWorkspaceQuickView(workspaceId, column) {
    this.props.orgTeamActions.toggleWorkspaceQuickView(workspaceId, column);
  }

  showModal(modalType) {
    this.props.orgActions.showModal(modalType);
  }

  render() {
    const {
      orgTeamState,
      orgState,
      dropdownMenuState,
      shouldShowVeracodePage = false,
    } = this.props;
    const { openedDropdown = {} } = dropdownMenuState;
    const {
      filteredOrgTeams = [],
      sortColumn,
      sortAscending,
      quickViewsByWorkspaceId = {},
    } = orgTeamState;
    const { showModal, org } = orgState;
    const showGroups =
      FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org) ||
      OrgPaidStatus.isOrgEnterpriseOrTrial(org);
    const showOrgAgents = FeatureFlagHelper.isFeatureEnabledForOrg(
      FEATURE_SLUG_MAP.ORG_AGENTS,
      org
    );
    const { license, permissions } = org;
    const { createTeams, updateUsers } = permissions;
    const plan = org.plan || {};
    const { usage = {} } = license;
    const { teams } = usage;
    const { id: planId, teamLimit } = plan;
    const filteredTeams = filteredOrgTeams.filter(team => !team.userId);
    const orgHasReachedTeamLimit = teamLimit && teams >= teamLimit;
    const columnWidths = {
      name: 'col-1-2',
      filler: 'col-1-24',
    };
    const orgCanCreateWorkspaces = planId !== 1001;
    const showCreateWorkspaces = createTeams && orgCanCreateWorkspaces;

    return (
      <div className="grid mt mb++">
        <Helmet>
          <title>Workspaces</title>
        </Helmet>
        <div className="grid__item col-1-1 flex align-items--baseline">
          <h3 data-e2e="WorkspacesPage-Title">Workspaces</h3>
          {teamLimit && (
            <div className="ml font--h4">
              <strong>
                ({teams} of {teamLimit} workspaces used)
              </strong>
            </div>
          )}
        </div>
        <div className="grid__item col-1-1 mt-">
          <div className="bo--1 border-color--muted-dark flex justify-content--space-between align-items--center p-">
            <div className="flex col-3-4">
              <div className="col-1-2">
                <input
                  type="text"
                  className="control--text col-1-1"
                  placeholder="Filter by name"
                  onChange={e => this.handleFilterInput(e)}
                />
              </div>
            </div>
            <div>
              {showCreateWorkspaces && (
                <DropdownMenu
                  isOpen={!!openedDropdown['workspaces-actions']}
                  close={() => this.delayCloseDropdown()}
                  menuAlign="right"
                  textAlign="left"
                  toggle={
                    <button
                      className="btn--success p-"
                      onClick={() => this.openDropdown('workspaces-actions')}
                    >
                      Actions
                    </button>
                  }
                >
                  <li className="pr+ dropdown-menu--li">
                    <button
                      className={`pv-- ${orgHasReachedTeamLimit && 'disabled'}`}
                      disabled={orgHasReachedTeamLimit}
                      onClick={
                        orgHasReachedTeamLimit
                          ? undefined
                          : () => this.showModal('CREATE_WORKSPACE')
                      }
                    >
                      {orgHasReachedTeamLimit ? (
                        <Tooltip
                          id="workspace-limit-reached"
                          content="You have reached the maximum number of workspaces for your organization. To create another workspace, delete an existing one or contact sales@sourceclear.com."
                          maxWidthClass="max-width--300"
                          place="left"
                        >
                          <div>Create</div>
                        </Tooltip>
                      ) : (
                        <div>Create</div>
                      )}
                    </button>
                  </li>
                </DropdownMenu>
              )}
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1 mt">
          <div className="grid">
            <div
              className={`grid__item flex align-items--center justify-content--space-between ${columnWidths.name} cursor--pointer`}
              onClick={() => this.updateSort('name')}
            >
              <strong>Workspace Name</strong>
              {sortColumn === 'name' ? (
                <i
                  className={`sci ${
                    sortAscending ? 'sci__arrow--closed-up' : 'sci__arrow--closed-down'
                  }`}
                />
              ) : (
                <i className={`sci sci__arrow--closed-scroll`} />
              )}
            </div>
            {showOrgAgents && (
              <div className={`grid__item ${columnWidths.slug}`}>
                <Tooltip
                  id="workspace-slug"
                  content="The workspace slug uniquely identifies a workspace. Use it to specify a target workspace when scanning with organization-level agents."
                  maxWidthClass="max-width--300"
                  place="top"
                >
                  <strong>Workspace Slug</strong> <i className="ml-- fas fa-info-circle" />
                </Tooltip>
              </div>
            )}
            <div className={`grid__item ${columnWidths.users}`}>
              <strong>Users</strong>
            </div>
            {showGroups && (
              <div className={`grid__item ${columnWidths.groups}`}>
                <strong>Teams</strong>
              </div>
            )}
            <div className={`grid__item ${columnWidths.filler}`} />
          </div>
        </div>
        <div className="grid__item col-1-1">
          {filteredTeams.map(teamObj => {
            const { team = {}, numGroups, numUsers, _links = {} } = teamObj;
            const { name, id: teamId } = team;
            const { groups = {}, users = {} } = _links;
            const quickView = quickViewsByWorkspaceId[teamId];

            return (
              <div className="grid mt-" key={teamId}>
                <div className={`grid__item ${columnWidths.name}`}>
                  <Link to={`/org/settings/workspaces/${teamId}`} className="link--obvious">
                    <strong>{name}</strong>
                  </Link>
                </div>
                {showOrgAgents && <div className={`grid__item ${columnWidths.slug}`}>{teamId}</div>}
                <div className={`grid__item flex align-items--center ${columnWidths.users}`}>
                  <div
                    className={`flex justify-content--space-between col-6-12 ${
                      numUsers && 'cursor--pointer'
                    }`}
                    onClick={
                      numUsers ? () => this.toggleWorkspaceQuickView(teamId, 'USERS') : undefined
                    }
                  >
                    {numUsers} user
                    {numUsers === 1 ? '' : 's'}
                    <i
                      className={`sci sci__caret--${
                        quickView === 'USERS' ? 'down' : 'right'
                      } color--${numUsers ? 'primary' : 'muted-light'}`}
                    />
                  </div>
                </div>
                {showGroups && (
                  <div className={`grid__item flex align-items--center ${columnWidths.groups}`}>
                    <div
                      className={`flex justify-content--space-between col-6-12 ${
                        numGroups && 'cursor--pointer'
                      }`}
                      onClick={
                        numGroups
                          ? () => this.toggleWorkspaceQuickView(teamId, 'GROUPS')
                          : undefined
                      }
                    >
                      {numGroups} team
                      {numGroups === 1 ? '' : 's'}
                      <i
                        className={`sci sci__caret--${
                          quickView === 'GROUPS' ? 'down' : 'right'
                        } color--${numGroups ? 'primary' : 'muted-light'}`}
                      />
                    </div>
                  </div>
                )}
                <div className={`grid__item ${columnWidths.filler}`} />
                <div
                  className={`grid__item col-1-1 ${
                    quickView === 'USERS' ? 'is-showing-400' : 'is-hiding'
                  }`}
                >
                  {quickView && (
                    <UserManagementQuickViewWrapper dataType="users" href={users.href}>
                      <WorkspaceUsersQuickView
                        showDetailsLink={updateUsers}
                        showGroups={showGroups}
                      />
                    </UserManagementQuickViewWrapper>
                  )}
                </div>
                <div
                  className={`grid__item col-1-1 ${
                    quickView === 'GROUPS' ? 'is-showing-400' : 'is-hiding'
                  }`}
                >
                  {quickView && (
                    <UserManagementQuickViewWrapper dataType="groups" href={groups.href}>
                      <WorkspaceGroupsQuickView shouldShowVeracodePage={shouldShowVeracodePage} />
                    </UserManagementQuickViewWrapper>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <SourceClearModal
          isOpen={showModal === 'CREATE_WORKSPACE'}
          onClose={() => this.showModal()}
          title="Create Workspace"
          width={500}
        >
          <CreateWorkspaceModal
            showModal={modalType => this.showModal(modalType)}
            showGroups={showGroups}
          />
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    myState: state.myState,
    orgState: state.orgState,
    orgTeamState: state.orgTeamState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    orgActions: bindActionCreators(orgActions, dispatch),
    orgTeamActions: bindActionCreators(orgTeamActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspacesPage);
