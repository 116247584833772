import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';
import CopyToClipboard from '~/containers/CopyToClipboard';

interface PackageManagerCocoaPodsProps {
  component: object;
  range?: object;
  safeVersion?: string;
  transitive: boolean;
  unsafeVersion?: string;
}

const PackageManagerCocoaPods: React.FunctionComponent<PackageManagerCocoaPodsProps> = props => {
  const { component, range = {}, safeVersion, transitive = false } = props;
  const { name } = component;
  const unsafeVersion =
    props.unsafeVersion ||
    (range.versionRange && range.versionRange.indexOf('-') !== -1
      ? range.versionRange.split('-')[0]
      : '');

  let diff = null;
  if (!transitive) {
    // Direct
    diff = (
      <div className="grid bo--1 border-color--white-dark mt lh+ bg-color--white">
        <CodeDiffHeader text="Podfile" />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine
          type="delete"
          text={"pod '" + component.coordinate1 + "'," + " '" + unsafeVersion + " '"}
        />
        <CodeDiffLine
          type="add"
          text={"pod '" + component.coordinate1 + "'," + " '" + safeVersion + " '"}
        />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
      </div>
    );
  } else {
    diff = (
      <div className="grid bo--1 border-color--white-dark mt lh+ bg-color--white">
        <CodeDiffHeader text="Podfile" />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine
          type="add"
          text={"pod '" + component.coordinate1 + "'," + " '" + safeVersion + " '"}
        />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
      </div>
    );
  }

  return (
    <div className="pt-">
      <div className="grid pt">
        <span className="bg-color--white-dark pv- ph- text--bold">1</span>
        {!transitive && (
          <span className="ph-- pv-">
            {' '}
            Update your <span className="text--bold">Podfile </span>
          </span>
        )}
        {transitive && (
          <span className="ph-- pv-">
            {' '}
            Include {name} to your <span className="text--bold">Podfile</span>
          </span>
        )}
        <span className="pv-">as shown below:</span>
      </div>
      {diff}
      <div className="grid pt">
        <span className="bg-color--white-dark pv- ph- text--bold">2</span>
        <span className="pv- pl--">
          After updating Podfile, type the following to install{' '}
          <span className="font-family--code bg-color--white-medium p---">
            {name} {safeVersion}
          </span>
        </span>
      </div>
      <div className="pt-- -ml mt-">
        <CopyToClipboard value={`pod install ${name}`} id="PackageManagerCocoaPods" />
      </div>
    </div>
  );
};

export default PackageManagerCocoaPods;
