import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PopoverWithEscKey from '~/components/PopoverWithEscKey';

import config from '~/config';
import * as popoverActions from '~/actions/popover';

interface ParentLicenseNoticeProps {
  actions: object;
  popoverState: object;
  id: string;
}
class ParentLicenseNotice extends React.Component<ParentLicenseNoticeProps, {}> {
  constructor(props, context) {
    super(props, context);

    this.isOpen = false;
  }

  toggleOpen = () => {
    this.props.actions.openPopover(this.props.id);
  };

  toggleClosed = () => {
    this.props.actions.closePopover(this.props.id);
  };

  render() {
    const { id, popoverState } = this.props;
    const isOpen = popoverState[id] || false;
    let licenseMessage = (
      <div>
        This license was generated from a parent file reference, so we can’t verify its validity.
      </div>
    );
    return (
      <PopoverWithEscKey isOpen={isOpen} body={licenseMessage} theme="dark" tipSize={7}>
        <span onMouseOver={this.toggleOpen} onMouseOut={this.toggleClosed}>
          <a href={config.HELP_URL + '/t/frequently-asked-questions'} target="_blank">
            <i className="sci sci__detail-information color--primary" />
          </a>
        </span>
      </PopoverWithEscKey>
    );
  }
}

function mapStateToProps(state) {
  return {
    popoverState: state.popoverState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(popoverActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParentLicenseNotice);
