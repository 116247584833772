import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';

import _ from 'lodash';

import * as integrationActions from '~/actions/integration';
import * as integrationModalActions from '~/actions/integrationModal';

interface MultiFieldDropdownProps {
  integrationActions: object;
  integrationModalActions: object;
  integrationModalState: object;
  fieldName: string;
  /**
   * Expects an array of Objects of {label: "x", "value": y}
   **/
  items?: any[];
  /**
   * Alternatively provide stringItems if label and value is the same, an array of string
   **/
  stringItems?: any[];
  name?: string;
  /**
   * Able to create new tag
   **/
  createable?: boolean;
  promptTextCreator?: string;
  onNewOptionClick?: (...args: any[]) => any;
  /**
   * Async mode, when set to true, asyncGetOptions should supply a
   function to retrieve the value given the input of the user
   */
  async?: boolean;
  asyncGetOptions?: (...args: any[]) => any;
  isLoading?: boolean;
}
class MultiFieldDropdown extends React.Component<MultiFieldDropdownProps, {}> {
  constructor(props, context) {
    super(props, context);

    if (this.props.async) {
      this.delayedAsyncGetOptions = _.debounce(this.props.asyncGetOptions, 500);
    }
  }

  onDropdownListSelected(fieldType, items) {
    // convert items labels into comma-separated string

    const itemsString = items.map(item => item.label).join(',');

    this.props.integrationModalActions.updateIntegrationModalField(fieldType, itemsString);

    if (this.props.async) {
      this.props.asyncGetOptions('');
    }
  }

  onInputChange = input => {
    const { async = false } = this.props;

    if (async) {
      this.delayedAsyncGetOptions(input);
    }

    return input;
  };

  render() {
    const {
      integrationModalState,
      fieldName = '',
      items,
      stringItems,
      name = '',
      createable = false,
      promptTextCreator = '',
      isLoading = false,
    } = this.props;
    const { fields = {} } = integrationModalState;
    const key = fieldName;

    const multiFieldOptions = stringItems
      ? stringItems.map(item => ({ value: item, label: item }))
      : items;

    let adjustedValue = fields[`jiraField-${key}`];
    if (_.isArray(adjustedValue)) {
      adjustedValue = adjustedValue.join(',');
    }
    // create label-value pairs in order to conform to
    // react-select V2 API changes
    const multipleFields = adjustedValue
      ? adjustedValue.split(',').map(value => ({ label: value, value: value }))
      : [];

    const selectProps = {
      name: `jiraField-${key}-filter`,
      value: multipleFields,
      delimiter: ',',
      isMulti: true,
      options: multiFieldOptions,
      placeholder: name.length > 0 ? `Select ${name.toLowerCase()}` : '',
      isClearable: true,
      className: 'srcclr-react-select-container',
      classNamePrefix: 'srcclr-react-select',
    };

    if (createable) {
      return (
        <Creatable
          {...selectProps}
          onChange={val => {
            this.onDropdownListSelected(`jiraField-${key}`, val);
          }}
          promptTextCreator={item => `${promptTextCreator} : ${item}`}
          onNewOptionClick={this.props.onNewOptionClick}
          onInputChange={this.onInputChange}
          isLoading={isLoading}
        />
      );
    }

    return (
      <Select
        {...selectProps}
        onChange={val => this.onDropdownListSelected(`jiraField-${key}`, val)}
        onInputChange={this.onInputChange}
        isLoading={isLoading}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    integrationModalState: state.integrationModalState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationModalActions: bindActionCreators(integrationModalActions, dispatch),
    integrationActions: bindActionCreators(integrationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiFieldDropdown);
