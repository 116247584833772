import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';

import * as groupActions from '~/actions/group';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import { FEATURE_SLUG_MAP } from '~/constants/ModelConstants';

import TeamUsersList from '~/containers/TeamUsersList';
import TeamWorkspacesList from '~/containers/TeamWorkspacesList';
import UpdateGroupDetailsModal from '~/containers/UpdateGroupDetailsModal';
import SourceClearModal from '~/components/SourceClearModal';

interface OrgTeamDetailsPageProps extends RouteComponentProps {
  groupActions: object;
  groupState: object;
  myState: App.MyState;
  orgState: App.OrgState;
}
class OrgTeamDetailsPage extends React.Component<OrgTeamDetailsPageProps, {}> {
  async componentDidMount() {
    const { match, history, groupState, orgState } = this.props;
    const { org } = orgState;
    const { params = {} } = match;
    const { groupId } = params;

    const showGroups = FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org);

    if (org.id && !showGroups && !OrgPaidStatus.isOrgEnterpriseOrTrial(org)) {
      history.replace(
        groupId ? `/workspaces/${groupId}/user-management/users` : `/org/settings/users`
      );
    }

    const groups = await this.props.groupActions.fetchGroups(org.id);

    if (groups.length) {
      const activeGroup = groups.find(group => group.id === parseInt(groupId));
      const { filteredGroupUsers, filteredGroupWorkspaces, isFetchingGroupDetails } = groupState;

      if (
        activeGroup &&
        !isFetchingGroupDetails &&
        (!filteredGroupUsers || !filteredGroupWorkspaces)
      ) {
        this.props.groupActions.fetchGroupDetails(activeGroup || {});
      }
    }
    this.props.groupActions.updateActiveTab('USERS');
  }

  componentWillUnmount() {
    this.props.groupActions.resetGroupDetails();
  }

  confirmDelete() {
    const { match, history } = this.props;
    const { params = {} } = match;
    const { groupId } = params;

    this.props.groupActions.deleteGroup(groupId).then(res => {
      if (res.success) {
        history.replace('/org/settings/teams');
      }
    });
  }

  toggleActiveTab(tab) {
    this.props.groupActions.updateActiveTab(tab);
  }

  showModal(modalType) {
    this.props.groupActions.showModal(modalType);
  }

  render() {
    const { match, groupState, orgState } = this.props;
    const { org } = orgState;
    const { permissions: orgPermissions = {} } = org;
    const { updateUsers, manageGroupTeams } = orgPermissions;
    const { params = {} } = match;
    const { groupId, teamId } = params;
    const { groups = [], activeTab = 'USERS', showModal } = groupState;
    const activeGroup = groups.find(group => group.id === parseInt(groupId)) || {};
    const { name = '', description = '', permissions = {} } = activeGroup;
    const { update, delete: deleteGroup } = permissions;

    return (
      <div className="grid mt mb++">
        <Helmet>
          <title>Team</title>
        </Helmet>
        <div className="grid__item col-1-1 flex align-items--baseline">
          <div className="font--h3">Team</div>
          <div className="ml- font--h4 color--black-light">
            <strong>{name}</strong>
          </div>
          {update && (
            <div className="ml-- flex align-self--stretch align-items--end mb---">
              <button
                className="color--muted-dark"
                onClick={() => this.showModal('UPDATE_GROUP_DETAILS')}
              >
                <i className="sci sci__pencil" />
              </button>
            </div>
          )}
          {deleteGroup && (
            <div className=" flex align-self--stretch align-items--end mb---">
              <button className="color--muted-dark" onClick={() => this.showModal('DELETE_GROUP')}>
                <i className="sci sci__trash" />
              </button>
            </div>
          )}
        </div>
        <div className="grid__item col-1-2 mt-">
          <div className="mt-">
            <strong>Description:</strong> {description || '--'}
          </div>
        </div>
        <div className="grid__item col-1-1 mt+">
          <div className="bo-b--3 border-color--black-light">
            <div className="grid">
              <div className={`grid__item col-1-6`}>
                <div
                  className={`flex justify-content--center align-items--center pv- ${
                    activeTab === 'USERS'
                      ? 'bg-color--black-light color--white'
                      : 'bg-color--white-dark cursor--pointer'
                  }`}
                  onClick={() => this.toggleActiveTab('USERS')}
                >
                  <strong>Users</strong>
                </div>
              </div>
              <div className={`grid__item col-1-6`}>
                <div
                  className={`flex justify-content--center align-items--center pv- ${
                    activeTab === 'WORKSPACES'
                      ? 'bg-color--black-light color--white'
                      : 'bg-color--white-dark cursor--pointer'
                  }`}
                  onClick={() => this.toggleActiveTab('WORKSPACES')}
                >
                  <strong>Workspaces</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1 mt">
          {activeTab === 'USERS' && (
            <TeamUsersList updateUsers={updateUsers} groupId={groupId} teamId={teamId} />
          )}
          {activeTab === 'WORKSPACES' && (
            <TeamWorkspacesList
              manageGroupTeams={manageGroupTeams}
              groupId={groupId}
              teamId={teamId}
            />
          )}
        </div>
        <SourceClearModal
          isOpen={showModal === 'UPDATE_GROUP_DETAILS'}
          onClose={() => this.showModal()}
          width={500}
          title="Update Team Details"
        >
          <UpdateGroupDetailsModal groupId={groupId} />
        </SourceClearModal>
        <SourceClearModal
          isOpen={showModal === 'DELETE_GROUP'}
          onClose={() => this.showModal()}
          width={500}
          title="Delete Team"
        >
          <div className="grid">
            <div className="grid__item col-1-1 mt-">
              Are you sure you would like to delete <strong>{name}</strong>? Deleting teams is
              permanent and cannot be undone. When you delete a team, team members will lose access
              to any linked workspaces unless they belong to another team that gives them access.
            </div>
            <div className="grid__item col-1-1 flex justify-content--end mt">
              <button className="col-1-5 pv- mr-" onClick={() => this.showModal()}>
                Cancel
              </button>
              <button className="p- btn--danger" onClick={() => this.confirmDelete()}>
                Delete Team
              </button>
            </div>
          </div>
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupState: state.groupState,
    myState: state.myState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    groupActions: bindActionCreators(groupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgTeamDetailsPage);
