export const RESET_PASSWORD_STATE = 'RESET_PASSWORD_STATE';
export const UPDATE_PASSWORD_INPUT = 'UPDATE_PASSWORD_INPUT';
export const UPDATE_CONFIRM_PASSWORD_INPUT = 'UPDATE_CONFIRM_PASSWORD_INPUT';

export const updatePasswordInput = value => ({
  type: UPDATE_PASSWORD_INPUT,
  value,
});

export const updateConfirmPasswordInput = value => ({
  type: UPDATE_CONFIRM_PASSWORD_INPUT,
  value,
});

export const resetPasswordState = () => ({
  type: RESET_PASSWORD_STATE,
});
