import AuthService from '~/utils/AuthService';
import Helpers from '~/utils/Helpers';

const RegistryAuthHelper = function () {
  /*
    Create a window and redirect user to registry. Listen for registry loaded event and send over
    the auth-token to allow paid user to be logged in at registry/
  */
  const proceedToRegistry = href => {
    const registryWindow = window.open(href);
    const authToken = AuthService.getAuthToken();

    const onRecieveRegistryLoad = evt => {
      if (Helpers.isRegistryFrontendUrl(evt.origin)) {
        registryWindow.postMessage({ authToken }, '*');
      }
      window.removeEventListener('message', onRecieveRegistryLoad);
    };

    window.addEventListener('message', onRecieveRegistryLoad);
  };

  return {
    proceedToRegistry,
  };
};

export default new RegistryAuthHelper();
