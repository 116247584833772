import _ from 'lodash';

import * as actions from '~/actions/policyValidation';

export const defaultState = {
  isFetching: false,
  errorMessage: '',
  validationResults: {},
};

const policyValidationState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.VALIDATE_POLICY_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actions.VALIDATE_POLICY_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.message,
      };
    case actions.UPDATE_POLICY_VALIDATION_RESULTS:
      return {
        ...state,
        isFetching: false,
        validationResults: getPolicyValidationResults(
          action.validationResults,
          action.controlCount
        ),
      };
    case actions.ADD_VALIDATION_RESULT_ITEM: {
      if (!state.validationResults.policy || !state.validationResults.policy.controls) {
        return state;
      }

      return {
        ...state,
        validationResults: {
          ...state.validationResults,
          policy: {
            ...state.validationResults.policy,
            controls: [...state.validationResults.policy.controls, undefined],
          },
        },
      };
    }
    case actions.REMOVE_VALIDATION_RESULT_ITEM: {
      if (!state.validationResults.policy || !state.validationResults.policy.controls) {
        return state;
      }

      return {
        ...state,
        validationResults: {
          ...state.validationResults,
          policy: {
            ...state.validationResults.policy,
            controls: [
              ...state.validationResults.policy.controls.slice(0, action.index),
              ...state.validationResults.policy.controls.slice(action.index + 1),
            ],
          },
        },
      };
    }
    case actions.UPDATE_VALIDATION_RESULTS_ORDER: {
      if (!state.validationResults.policy || !state.validationResults.policy.controls) {
        return state;
      }

      const newControls = [...state.validationResults.policy.controls];
      newControls.splice(action.toIndex, 0, newControls.splice(action.index, 1)[0]);

      return {
        ...state,
        validationResults: {
          ...state.validationResults,
          policy: {
            ...state.validationResults.policy,
            controls: newControls,
          },
        },
      };
    }
    case actions.RESET_POLICY_VALIDATION_RESULTS:
      return {
        ...state,
        isFetching: false,
        errorMessage: '',
        validationResults: {},
      };
    default:
      return state;
  }
};

export default policyValidationState;

/**
This function accepts validationResults object and returns a JSON with structure that resembles policiesState.policy.policy
but with injected values such as `invalid: true` and `errors: [<array of errors from errorDetails property below>]` for offending controls.

@param {object} validationResults
{
  "error": "violatesSchema",
  "errorType": "policy",
  "errorDetails": [
    {
      "field": "policy.controls.0.condition.descriptor.parameters",
      "type": "required",
      "description": "no parameters provided"
    },
    {
      "field": "policy.controls.0.condition.descriptor.parameters",
      "type": "invalid parameters",
      "description": "invalid parameters"
    }
  ]
}

@returns {object}
{
  policy: {
    controls: [
      0: {
        invalid: true,
        condition: {
          descriptor: {
            parameters: {
              invalid: true,
              errors: [{
                "field": "policy.controls.0.condition.descriptor.parameters",
                "type": "required",
                "description": "no parameters provided"
              },{
                "field": "policy.controls.0.condition.descriptor.parameters",
                "type": "invalid parameters",
                "description": "invalid parameters"
              }
            ]
          }
        }
      }
    ]
  }
}
*/

function getPolicyValidationResults(validationResults, controlCount = 0) {
  if (_.isEmpty(validationResults)) {
    return {};
  }

  const { errorDetails = [] } = validationResults;

  const policiesValidation = {
    policy: {
      //setting the size the same size as the policy control's count
      //so we can move controls up and down keeping the validation in sync.
      controls: new Array(controlCount),
    },
  };

  for (let error of errorDetails) {
    let controlPath = error.field.split('.');
    controlPath = controlPath && `${controlPath[0]}.${controlPath[1]}.${controlPath[2]}`;
    _.set(policiesValidation, `${controlPath}.invalid`, true);
    _.set(policiesValidation, `${error.field}.invalid`, true);

    const errors = _.get(policiesValidation, `${error.field}.errors`) || [];
    _.set(policiesValidation, `${error.field}.errors[${errors.length}]`, error);
  }

  return policiesValidation;
}
