import React, { Component } from 'react';
import VCTab from './VCTab';

interface VCTabsProps {
  children: any;
  history: { [key: string]: any };
  scaUrl?: string;
}

interface VCTabsState {
  activeTab: string;
}

class VCTabs extends Component<VCTabsProps, VCTabsState> {
  constructor(props: VCTabsProps) {
    super(props);
    // default to Agent-Based Scan tab
    const agentBasedScanTab = this.props.children.find(
      child => child.props.id === 'agent-based-scan'
    );
    this.state = { activeTab: agentBasedScanTab ? agentBasedScanTab.props.id : '' };
  }

  handleTabClick(tabId: string) {
    const { history, scaUrl = '' } = this.props;
    // clicking on Agent-Based Scan tab should bring user back to main Workspace List
    if (tabId === 'agent-based-scan') {
      history.push('/portfolio');
    } else if (tabId === 'upload-and-scan') {
      window.location.href = scaUrl;
    }
    this.setState({ activeTab: tabId });
  }

  render() {
    const { children } = this.props;
    const { activeTab } = this.state;

    return (
      <div className="vcTabs">
        <div className="vcTabList">
          {children.map(child => {
            const { id, label } = child.props;
            return (
              <VCTab
                activeTab={activeTab}
                key={label}
                id={id}
                label={label}
                onHandleTabClick={tab => this.handleTabClick(tab)}
              />
            );
          })}
        </div>
        <div className="vcTabContent">
          <div className="flex flex--content">
            {children.map(child => {
              if (child.props.id !== activeTab) return undefined;
              return child.props.children;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default VCTabs;
