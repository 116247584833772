import config from '~/config';
import queryString from 'query-string';
import { VCNavigation } from './navServiceHelper.types';

// TODO: add unit test for navServiceHelper
export const getNavFooter = (navigation: VCNavigation) => {
  const { footer } = navigation;
  return footer;
};

export const getNavModel = (navigation: VCNavigation) => {
  const { groups, landing: landingPageUrl } = navigation;
  return groups.map(group => {
    const { nav_items: navItems } = group;

    return {
      location: group.location,
      navItems: navItems.map(navItem => {
        const {
          click,
          disabled,
          icon,
          id,
          label,
          max_width: maxWidth,
          nav_menu_items: navMenuItems,
        } = navItem;

        const isActive = id === 'scans_and_analysis';
        const isDropdown = !!navMenuItems.length;
        const isLink = !!click;
        const clickParamsUrl = !!click && !!click.params && !!click.params.url;
        const useLandingPage = id === 'home' && clickParamsUrl && !!landingPageUrl;

        let isAccountMenu = false;
        let isHelpMenu = false;
        let linkUrl: string;
        let openInNewWindow = false;
        let tooltipText = '';

        if (icon) {
          const { icon_name: iconName } = icon;
          isAccountMenu = iconName === 'user';
        }

        if (clickParamsUrl) {
          linkUrl = click.params.url;
          openInNewWindow = click.action === 'openWindow';

          const query = queryString.extract(linkUrl);
          isHelpMenu = queryString.parse(query)['subsystem'] === 'helpcenter';
        }

        if (isHelpMenu) {
          tooltipText = 'Help Center';
        }

        if (isAccountMenu) {
          tooltipText = label;
        }

        let landingPageClick = null;

        if (useLandingPage) {
          landingPageClick = {
            ...click,
            params: {
              ...click.params,
              url: landingPageUrl,
            },
          };
        }

        return {
          click: landingPageClick || click,
          disabled,
          icon,
          id,
          isAccountMenu,
          isActive,
          isDropdown,
          isHelpMenu,
          isLink,
          label: label ? label.trim() : label,
          maxWidth,
          dropdownMenuItems: navMenuItems.map(menuItem => {
            const { click, disabled, label, type } = menuItem;

            return {
              click,
              isLogoutLink: click && click.action === 'logout',
              disabled,
              isSectionHeader: type === 'SECTION',
              label,
            };
          }),
          openInNewWindow,
          tooltipText,
        };
      }),
    };
  });
};

// construct URL link from Nav service output of VCNavigation json object
export function constructUrlFromNavServiceClickObj(click) {
  // not all click objects contain params with a url
  let urlLink;
  if (click) {
    const { params = {}, action } = click;
    const { url } = params;
    if (action === 'uiGatewayWindow') {
      urlLink = url ? config.VERACODE_UIGATEWAY_HOST + url : '#';
    } else {
      // if we do not have a url from the endpoint, default to '#'
      urlLink = url ? url : '#';
    }
  }
  return urlLink;
}
