import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import DropdownMenu from 'react-dd-menu';
import _ from 'lodash';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as groupActions from '~/actions/group';

import SourceClearModal from '~/components/SourceClearModal';
import Tooltip from '~/components/Tooltip';
import AddWorkspacesToGroupModal from '~/containers/AddWorkspacesToGroupModal';
import { tooltipContent } from '~/constants/ModelConstants';

interface TeamWorkspacesListProps {
  dropdownMenuActions: object;
  groupActions: object;
  groupId: string;
  teamId?: string;
  manageGroupTeams: boolean;
  dropdownMenuState: object;
  groupState: object;
}
class TeamWorkspacesList extends React.Component<TeamWorkspacesListProps, {}> {
  componentWillUnmount() {
    this.props.groupActions.resetGroupSort();
  }

  handleWorkspaceSelection(workspaceId) {
    this.props.groupActions.updateSelectedGroupWorkspaces(workspaceId);
  }

  handleFilterInput(e) {
    const { groupId } = this.props;
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.groupActions.filterGroupWorkspacesByInput(value, groupId);
  }

  unlinkWorkspaces() {
    const { groupState, groupId } = this.props;
    const { selectedGroupWorkspaces = {}, groupWorkspaces = [] } = groupState;
    const selectedGroupWorkspaceIds = Object.keys(selectedGroupWorkspaces).filter(
      teamId => selectedGroupWorkspaces[teamId]
    );
    const membershipIds = selectedGroupWorkspaceIds.map(teamId => {
      const foundMembership =
        groupWorkspaces.find(groupWorkspace => groupWorkspace.team.id === teamId) || {};
      return foundMembership.id;
    });

    this.props.groupActions.removeMemberships(membershipIds, 'teams', groupId);
  }

  updateAgentAdminStatus(toAdmin) {
    const { groupState, groupId } = this.props;
    const { selectedGroupWorkspaces = {} } = groupState;
    const formatedTeams = [];

    for (let teamId in selectedGroupWorkspaces) {
      formatedTeams.push({
        teamId,
        role: toAdmin ? 'WORKSPACE_ADMIN' : 'USER',
      });
    }

    this.props.groupActions.groupMultiUpdate([
      {
        groupId: parseInt(groupId),
        teams: formatedTeams,
      },
    ]);
  }

  closeDropdown(menuId) {
    this.props.dropdownMenuActions.closeDropdownMenu(menuId);
  }

  openDropdown(menuId) {
    this.props.dropdownMenuActions.openDropdownMenu(menuId);
  }

  updateGroupTeamSort(column) {
    this.props.groupActions.updateGroupTeamSort(column);
  }

  showModal(modalType) {
    this.props.groupActions.showModal(modalType);
  }

  render() {
    const { groupState, dropdownMenuState, groupId, manageGroupTeams, teamId } = this.props;
    const {
      filteredGroupWorkspaces = [],
      selectedGroupWorkspaces = {},
      showModal,
      groups = [],
      groupTeamSortColumn: sortColumn,
      sortAscending,
    } = groupState;
    const activeGroup = groups.find(group => group.id === parseInt(groupId)) || {};
    const { numTeams } = activeGroup;
    const { openedDropdown = {} } = dropdownMenuState;
    const columnWidths = {
      filler: 'col-1-4',
      select: 'col-1-12',
      name: 'col-1-3',
      admin: '',
    };
    const someWorkspacesSelected = _.some(selectedGroupWorkspaces);

    const renderTeamWorkspaces = () => {
      if (!numTeams) {
        return (
          <div className="mt- font--h3 color--muted-dark">
            No workspaces are linked to this team.
          </div>
        );
      } else if (!filteredGroupWorkspaces.length) {
        return <div className="mt- font--h3 color--muted-dark">No workspaces found.</div>;
      }

      return filteredGroupWorkspaces.map(workspaceObj => {
        const { team: workspace = {}, roles = [] } = workspaceObj;
        const { id: workspaceId, name = '' } = workspace;
        const isSelected = selectedGroupWorkspaces[workspaceId] || false;

        return (
          <div className="grid grid--narrower mt-" key={workspaceId}>
            <div className={`grid__item ${columnWidths.name}`}>
              {manageGroupTeams ? (
                <Link to={`/org/settings/workspaces/${workspaceId}`} className="link--obvious">
                  <strong>{name}</strong>
                </Link>
              ) : (
                <div className="">{name}</div>
              )}
            </div>
            <div className={`grid__item text--center ${columnWidths.admin}`}>
              {roles.includes('WORKSPACE_ADMIN') ? 'Yes' : '--'}
            </div>
            <div className={`grid__item flex justify-content--center ${columnWidths.filler}`} />
            {manageGroupTeams && (
              <div
                className={`grid__item flex align-items--center justify-content--center ${columnWidths.select}`}
              >
                <label className="label--checkbox flex justify-content--center mb0">
                  <input
                    type="checkbox"
                    name="select"
                    checked={isSelected}
                    onChange={() => this.handleWorkspaceSelection(workspaceId)}
                  />
                  <span className="react--checkbox mr0" />
                </label>
              </div>
            )}
          </div>
        );
      });
    };

    return (
      <div className="grid">
        <div className="grid__item col-1-1">
          <div className="bo--1 border-color--muted-dark flex justify-content--space-between align-items--center p-">
            <div className="flex col-3-4">
              <div className="col-1-2">
                <input
                  type="text"
                  className="control--text col-1-1"
                  placeholder="Filter by workspace name"
                  onChange={e => this.handleFilterInput(e)}
                />
              </div>
            </div>
            <div>
              {manageGroupTeams && (
                <DropdownMenu
                  isOpen={!!openedDropdown['group-workspace-actions']}
                  close={() => this.closeDropdown('group-workspace-actions')}
                  menuAlign="right"
                  textAlign="left"
                  toggle={
                    <button
                      className="p- btn--success"
                      onClick={() => this.openDropdown('group-workspace-actions')}
                    >
                      Actions
                    </button>
                  }
                >
                  {!teamId && (
                    <li className="min-width--200">
                      <button className="pv--" onClick={() => this.showModal('LINK_WORKSPACES')}>
                        Add Workspaces
                      </button>
                    </li>
                  )}
                  <li className="min-width--200 dropdown-menu--li">
                    <button
                      className={`pv-- ${!someWorkspacesSelected && 'disabled'}`}
                      disabled={!someWorkspacesSelected}
                      onClick={() => this.unlinkWorkspaces()}
                    >
                      Remove Workspaces
                    </button>
                  </li>
                  <li className="separator mh-" />
                  <li className="min-width--200 dropdown-menu--li">
                    <button
                      className={`pv-- ${!someWorkspacesSelected && 'disabled'}`}
                      disabled={!someWorkspacesSelected}
                      onClick={() => this.updateAgentAdminStatus(true)}
                    >
                      Change to Workspace Admin
                    </button>
                  </li>
                  <li className="min-width--200 dropdown-menu--li">
                    <button
                      className={`pv-- ${!someWorkspacesSelected && 'disabled'}`}
                      disabled={!someWorkspacesSelected}
                      onClick={() => this.updateAgentAdminStatus()}
                    >
                      Change to Workspace User
                    </button>
                  </li>
                </DropdownMenu>
              )}
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1 mt">
          {/* team workspacees list header */}
          <div className="grid grid--narrower">
            <div
              className={`grid__item flex align-items--center justify-content--space-between cursor--pointer ${columnWidths.name}`}
              onClick={() => this.updateGroupTeamSort('name')}
            >
              <strong>Workspace</strong>
              {sortColumn === 'name' ? (
                <i
                  className={`sci ${
                    sortAscending ? 'sci__arrow--closed-up' : 'sci__arrow--closed-down'
                  }`}
                />
              ) : (
                <i className={`sci sci__arrow--closed-scroll`} />
              )}
            </div>
            <div className={`grid__item flex justify-content--center ${columnWidths.admin}`}>
              <Tooltip
                id="group-workspace-admins"
                content={tooltipContent.WORKSPACE_ADMIN}
                maxWidthClass="max-width--300"
                place="top"
              >
                <strong className="bo-b--1 bo--dashed">Workspace Administrator</strong>
              </Tooltip>
            </div>
            <div className={`grid__item flex justify-content--center ${columnWidths.filler}`} />
            {manageGroupTeams && (
              <div className={`grid__item flex justify-content--center ${columnWidths.select}`}>
                <strong>Select</strong>
              </div>
            )}
          </div>
        </div>
        <div className="grid__item col-1-1">{renderTeamWorkspaces()}</div>
        <SourceClearModal
          isOpen={showModal === 'LINK_WORKSPACES'}
          onClose={() => this.showModal('')}
          title="Add Workspaces to Team"
          width={500}
        >
          <AddWorkspacesToGroupModal
            groupId={groupId}
            showModal={modalType => this.showModal(modalType)}
          />
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    groupState: state.groupState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    groupActions: bindActionCreators(groupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamWorkspacesList);
