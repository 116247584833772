import React from 'react';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import * as apiKeyCredentialsActions from '~/actions/apiKeyCredentials';
import CopyToClipboard from '~/containers/CopyToClipboard';
import SourceClearModal from '~/components/SourceClearModal';
import Helpers from '~/utils/Helpers';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';
/**
  Component to create a API Credentials for the user.
**/

interface APICredentialsProps {
  apiKeyCredentials: object;
  apiKeyCredentialsActions: object;
  vcPageState: VCPageState;
}
interface APICredentialsState {
  isModalOpen: boolean;
}
class APICredentials extends React.Component<APICredentialsProps, APICredentialsState> {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { shouldShowVeracodePage } = this.props.vcPageState;
    if (!shouldShowVeracodePage) {
      this.props.apiKeyCredentialsActions.fetchApiCredentials();
    }
  }

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };
  /* eslint-enable */

  handleApiCredentialsUpdate = () => {
    this.props.apiKeyCredentialsActions.updateApiCredentials();
  };

  handleApiCredentialsDelete = () => {
    this.closeModal();
    this.props.apiKeyCredentialsActions.revokeApiCredentials();
  };

  render() {
    const {
      apiKeyCredentials = {},
      vcPageState: { shouldShowVeracodePage },
    } = this.props;
    const doesCredentialsExist =
      apiKeyCredentials.apiKeyCreationDate && apiKeyCredentials.id === apiKeyCredentials.secretKey;

    const title = shouldShowVeracodePage ? 'My API Credentials' : 'API Credentials';
    return (
      <section>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="pt font--h3">{title}</div>
        <SourceClearModal
          isOpen={this.state.isModalOpen}
          onClose={this.closeModal}
          width={500}
          title="Revoke API Credentials"
        >
          <div className="mt-"> Are you sure you want to revoke API credentials? </div>
          <div className="flex flex-direction--row mt flex-flow--row-reverse height--40">
            <button
              className="btn btn--danger col-2-10 ml-"
              onClick={this.handleApiCredentialsDelete}
            >
              Revoke
            </button>
            <button className="col-2-10" onClick={this.closeModal}>
              Cancel
            </button>
          </div>
        </SourceClearModal>
        <div className="grid__item col-4-6 pt">
          <p>
            To use the SourceClear APIs, you must first generate the API credentials. Click
            <strong> Generate</strong> to obtain your ID and secret key. Copy these strings and keep
            them secure. You only see these credentials once. If you forget these credentials or
            believe your account was compromised, you must regenerate new ones.
          </p>
        </div>
        <div className="flex flex-direction--column">
          <div className="grid__item col-1-2 mt+">
            <button
              className="btn btn--primary btn--primary--border col-1-3 pv- ph"
              onClick={this.handleApiCredentialsUpdate}
            >
              {apiKeyCredentials.isUpdating ? 'Generating' : 'Generate'}
            </button>
            {apiKeyCredentials.apiKeyCreationDate ? (
              <button
                className="btn btn--primary-clear col-1-3 pv- ph ml-"
                onClick={this.openModal}
              >
                Revoke
              </button>
            ) : null}
          </div>
          {apiKeyCredentials.apiKeyCreationDate ? (
            <div className="mt grid__item col-4-6">
              <div className="flex flex-direction--row mt-">
                <div className="flex col-1-6 align-items--center">ID</div>
                <div className="col-5-6 mt-10">
                  <CopyToClipboard
                    id="srcclr-api-key-id"
                    value={!doesCredentialsExist ? apiKeyCredentials.id : ''}
                    placeholder={doesCredentialsExist ? apiKeyCredentials.id : null}
                  />
                </div>
              </div>
              <div className="flex flex-direction--row mt-">
                <div className="flex col-1-6 align-items--center">Secret Key</div>
                <div className="col-5-6 mt-10">
                  <CopyToClipboard
                    id="srcclr-api-key-secret"
                    value={!doesCredentialsExist ? apiKeyCredentials.secretKey : ''}
                    placeholder={doesCredentialsExist ? apiKeyCredentials.secretKey : null}
                  />
                </div>
              </div>
              <div className="flex flex-direction--row mt-">
                <div className="flex col-1-6 align-items--center">Created</div>
                <div className="col-5-6 mt-10">
                  {Helpers.formatTimestamp(apiKeyCredentials.apiKeyCreationDate)}
                </div>
              </div>
              <div className="flex flex-direction--row mt-">
                <div className="flex col-1-6 align-items--center">Expires</div>
                <div className="col-5-6 mt-10">
                  {Helpers.formatTimestamp(apiKeyCredentials.apiKeyExpirationDate)}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    apiKeyCredentials: state.apiKeyCredentials,
    vcPageState: state.vcPageState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    apiKeyCredentialsActions: bindActionCreators(apiKeyCredentialsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(APICredentials);
