import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';

import * as agentActions from '~/actions/agents';
import * as orgAgentActions from '~/actions/orgAgent';
import * as orgTeamActions from '~/actions/orgTeam';
import * as agentModalActions from '~/actions/agentModal';
import { AgentModalState } from '~/reducers/vcAppState/vcAppStateTypes/types';

interface CreateNewAgentModalProps extends RouteComponentProps {
  agentActions: object;
  agentModalActions: object;
  agentModalState: AgentModalState;
  orgAgentActions: object;
  orgTeamActions: object;
  orgState: App.OrgState;
  orgTeamState: object;
}
class CreateNewAgentModal extends React.Component<CreateNewAgentModalProps, {}> {
  componentDidMount() {
    const {
      agentModalState: { selectedWorkspace, workspaceLevelSelected, orgLevelSelected },
    } = this.props;

    if (selectedWorkspace) {
      this.props.agentModalActions.updateWorkspace(selectedWorkspace);
    }
    if (orgLevelSelected) {
      this.props.agentModalActions.orgLevelSelected();
    } else if (workspaceLevelSelected) {
      this.props.agentModalActions.workspaceLevelSelected();
    }
  }

  componentWillUnmount() {
    this.props.agentModalActions.resetAgentModalState();
  }

  showModal(modalType) {
    this.props.showModal(modalType);
  }

  updateWorkspace(workspace) {
    const teamId = workspace.value.id;
    this.props.agentActions.fetchTeamAgents(teamId);

    this.props.agentModalActions.updateWorkspace(workspace);
  }

  fetchedOnce = false;
  fetchOrgTeamsAndAgents() {
    const {
      orgState: { org },
      orgTeamActions,
      orgAgentActions,
    } = this.props;

    orgTeamActions.fetchOrgTeams();
    orgAgentActions.fetchOrgAgents(org.id);
  }

  updateOrgLevelSelected() {
    if (!this.fetchedOnce) {
      this.fetchOrgTeamsAndAgents();
      this.fetchedOnce = true;
    }
    this.props.agentModalActions.orgLevelSelected();
  }

  updateWorkSpaceLevelSelected() {
    if (!this.fetchedOnce) {
      this.fetchOrgTeamsAndAgents();
      this.fetchedOnce = true;
    }
    this.props.agentModalActions.workspaceLevelSelected();
  }

  render() {
    const {
      orgTeamState: { orgTeams = [] },
      agentModalState: { selectedWorkspace, orgLevelSelected, workspaceLevelSelected },
    } = this.props;

    const workspaceList = orgTeams.map(team => {
      return {
        label: team.name,
        value: team,
      };
    });

    const currentSelectedWsVal = selectedWorkspace ? selectedWorkspace.label : '';

    return (
      <div className="grid">
        <div className="grid__item col-1-1">
          <div className="grid">
            <div className="grid__item col-1-1">
              <p>
                You can create agents at the organization or workspace level. Organization agents
                are accessible in all of your workspaces. Workspace agents are limited to one
                workspace.{' '}
                <a
                  href="https://help.veracode.com/go/c_sc_agent_management"
                  className="vcStartScanHelpLink"
                  target="_blank"
                >
                  {'Help'}
                  <i className="fa fa-external-link" />
                </a>
              </p>
            </div>
            <div className="grid__item col-1-1 mt- flex align-items--center mt-">
              <div className="grid col-1-1 mt-">
                <label className="grid__item col-1-5 grid--middle mt-- ml++">
                  Agent Level
                  <span className="color--danger">*</span>
                </label>
                <div className="grid__item col-1-2 grid--middle">
                  <label className="label--checkbox flex pv-- mb0">
                    <input
                      type="radio"
                      name="radio-agent"
                      value="org"
                      checked={orgLevelSelected}
                      className="flex"
                      onChange={() => {
                        this.updateOrgLevelSelected();
                      }}
                    />
                    <span className="react--radio" />
                    Organization
                  </label>
                  <label className="label--checkbox flex pv-- mb0">
                    <input
                      type="radio"
                      name="radio-agent"
                      value="all-workspaces"
                      checked={workspaceLevelSelected}
                      className="flex"
                      onChange={() => {
                        this.updateWorkSpaceLevelSelected();
                      }}
                    />
                    <span className="react--radio" />
                    Individual Workspace
                  </label>
                  <div className="pl mb-">
                    <div className="col-1-1 width--200">
                      <Select
                        value={workspaceList.find(option => option.label === currentSelectedWsVal)}
                        options={workspaceList}
                        name="group-workspaces"
                        onChange={workspace => {
                          this.updateWorkspace(workspace);
                        }}
                        className="srcclr-react-select-container"
                        classNamePrefix={'srcclr-react-select'}
                        isDisabled={!workspaceLevelSelected}
                        placeholder="Select Workspace"
                        maxMenuHeight={170}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentModalState: state.agentModalState,
    orgState: state.orgState,
    orgTeamState: state.orgTeamState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    agentActions: bindActionCreators(agentActions, dispatch),
    agentModalActions: bindActionCreators(agentModalActions, dispatch),
    orgAgentActions: bindActionCreators(orgAgentActions, dispatch),
    orgTeamActions: bindActionCreators(orgTeamActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNewAgentModal));
