import React from 'react';

interface InviteGroupsQuickViewProps {
  data?: any[];
}

const InviteGroupsQuickView: React.FunctionComponent<InviteGroupsQuickViewProps> = props => {
  const { data: groups = [] } = props;
  const columnWidths = {
    name: 'col-1-4',
    filler: 'col-1-6',
  };

  return (
    <div className="bo-v--1 mv-- pb- border-color--muted">
      <div className="col-1-1 bg-color--muted-light pv-- font--h7 pl- color--muted-dark">
        <strong>TEAMS</strong>
      </div>
      <div className="grid mt-">
        <div className={`grid__item ${columnWidths.name}`}>
          <strong>Name</strong>
        </div>
        <div className={`grid__item ${columnWidths.description}`}>
          <strong>Description</strong>
        </div>
        <div className={`grid__item text--center ${columnWidths.admin}`}>
          <strong>Team Administrator</strong>
        </div>
        <div className={`grid__item text--center ${columnWidths.filler}`} />
      </div>

      <div className="max-height--300 overflow--auto">
        {groups.map(groupObj => {
          const { group = {}, admin } = groupObj;
          const { name, description, id: groupId } = group;

          return (
            <div className="grid mt--" key={`invite-group-${groupId}`}>
              <div className={`grid__item ${columnWidths.name}`}>{name}</div>
              <div className={`grid__item ${columnWidths.description}`}>{description || '--'}</div>
              <div className={`grid__item text--center ${columnWidths.admin}`}>
                {admin ? 'Yes' : '--'}
              </div>
              <div className={`grid__item text--center ${columnWidths.filler}`} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InviteGroupsQuickView;
