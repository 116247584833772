import pickBy from 'lodash/pickBy';
import Helpers from '~/utils/Helpers';
import { VCNavigation } from '~/utils/navServiceHelper.types';
type PendoInitializeOptions = {
  visitor: {
    id: string;
    sfAccountId?: string;
    userRoles?: string;
    userLastLoginDate?: string;
    userAccountCreateDate?: string;
    userAccountType?: string;
  };
  account: {
    id: string;
  };
};

export function initializePendo({
  me,
  navigation,
}: {
  me: { [key: string]: any };
  navigation: VCNavigation;
}) {
  const {
    id,
    organization: { orgId },
    permissions,
  } = me;

  const formattedUserId = Helpers.formattedVcId(id);
  const formattedOrgId = Helpers.formattedVcId(orgId);

  const options: PendoInitializeOptions = {
    visitor: {
      id: formattedUserId,
      sfAccountId: 'TBD',
      userRoles: JSON.stringify(pickBy(permissions, permission => permission)),
      userLastLoginDate: Helpers.getVcUserLastLoginFromNav(navigation),
      userAccountCreateDate: 'unavailable',
      userAccountType: 'unavailable',
    },
    account: {
      id: formattedOrgId,
    },
  };
  (window as any).pendo.initialize(options);
}
