import React from 'react';
import CodeDiffLine from '~/components/CodeDiffLine';
import CodeDiffHeader from '~/components/CodeDiffHeader';

interface PackageManagerGradleProps {
  component: object;
  range?: object;
  safeVersion: string;
  transitive: boolean;
  unsafeVersion?: string;
  isBoth?: boolean;
}

const PackageManagerGradle: React.FunctionComponent<PackageManagerGradleProps> = props => {
  const { component, range = {}, safeVersion, transitive = false, isBoth = false } = props;
  const { coordinate1, coordinate2 } = component;
  const unsafeVersion =
    props.unsafeVersion ||
    (range.versionRange && range.versionRange.indexOf('-') !== -1
      ? range.versionRange.split('-')[0]
      : '');

  if (isBoth) {
    return (
      <div className="grid bo--1 border-color--white-dark mt bg-color--white lh+">
        <CodeDiffHeader text="build.gradle" />
        <CodeDiffLine type="normal" text="dependencies {" />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine
          type="delete"
          text={
            '\u2003compile \u0027' +
            coordinate1 +
            ':' +
            coordinate2 +
            ':' +
            unsafeVersion +
            '\u0027'
          }
        />

        <CodeDiffLine
          type="add"
          text={
            '\u2003compile (\u0027' +
            coordinate1 +
            ':' +
            coordinate2 +
            ':' +
            safeVersion +
            '\u0027) {'
          }
        />
        <CodeDiffLine type="add" text={'\u2003\u2003\u2003force = true'} />
        <CodeDiffLine type="add" text={'\u2003}'} />

        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine type="normal" text="}" />
      </div>
    );
  } else if (!transitive) {
    // Direct
    return (
      <div className="grid bo--1 border-color--white-dark mt bg-color--white lh+">
        <CodeDiffHeader text="build.gradle" />
        <CodeDiffLine type="normal" text="dependencies {" />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine
          type="delete"
          text={
            '\u2003compile \u0027' +
            coordinate1 +
            ':' +
            coordinate2 +
            ':' +
            unsafeVersion +
            '\u0027'
          }
        />
        <CodeDiffLine
          type="add"
          text={
            '\u2003compile \u0027' + coordinate1 + ':' + coordinate2 + ':' + safeVersion + '\u0027'
          }
        />
        <CodeDiffLine type="normal" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine type="normal" text="}" />
      </div>
    );
  } else {
    return (
      <div className="grid bo--1 border-color--white-dark mt bg-color--white lh+">
        <CodeDiffHeader text="build.gradle" />
        <CodeDiffLine type="add" text="dependencies {" />
        <CodeDiffLine type="add" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine
          type="add"
          text={
            '\u2003compile (\u0027' +
            coordinate1 +
            ':' +
            coordinate2 +
            ':' +
            safeVersion +
            '\u0027) {'
          }
        />
        <CodeDiffLine type="add" text={'\u2003\u2003\u2003force = true'} />
        <CodeDiffLine type="add" text={'\u2003}'} />
        <CodeDiffLine type="add" text={'\u2003\u00B7\u00B7\u00B7'} />
        <CodeDiffLine type="add" text="}" />
      </div>
    );
  }
};

export default PackageManagerGradle;
