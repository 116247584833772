import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import AuthService from './AuthService';
import Helpers from './Helpers';
import ErrorService from '~/utils/ErrorService';
import cookie from 'react-cookie';
import { VERACODE_SESSION_TIMEOUT_COOKIE_KEY, xsrfCookieName } from '~/constants/ModelConstants';
import moment from 'moment';

const errorHandler = (err, request) => {
  if (err.response) {
    err.status = err.response.status;
  }

  if (err.status === 401 && (!request || !request.url.includes('/login'))) {
    // if user ever gets a 401, it means they're not logged in
    // and should be sent to /login with the message that their session has expired
    AuthService.setAuthToken(null).then(() => {
      window.location.href = '/login?autoLogout=true';
    });
  }

  ErrorService.capture(err, { err });
  return err;
};

interface InterceptorFunctions {
  onRequestFulfilled: (
    requestConfig: InternalAxiosRequestConfig
  ) => Promise<InternalAxiosRequestConfig>;
  onRequestRejected: (error: AxiosError) => Promise<never>;
  onResponseFulfilled: (res: AxiosResponse) => any;
  onResponseRejected: (error: AxiosError) => Promise<never>;
}

export const getInterceptorFunctions = (isVeracodeDomain: boolean): InterceptorFunctions => ({
  onRequestFulfilled: onRequestFulfilled(isVeracodeDomain),
  onRequestRejected: onRequestRejected,
  onResponseFulfilled: onResponseFulfilled,
  onResponseRejected: onResponseRejected(isVeracodeDomain),
});

const onRequestFulfilled = (isVeracodeDomain: boolean) => async (
  requestConfig: InternalAxiosRequestConfig
) => {
  const tokenHeader = 'x-auth-token';

  if (requestConfig.headers && !(tokenHeader in requestConfig.headers)) {
    const token = AuthService.getAuthToken();
    if (token) {
      requestConfig.headers[tokenHeader] = token;
    }
  }

  if (requestConfig.url.endsWith('type=pdf')) {
    requestConfig.headers['Accept'] = 'application/pdf';
    requestConfig.responseType = 'blob';
  } else {
    requestConfig.headers['Accept'] = 'application/json';
  }

  if (isVeracodeDomain) {
    requestConfig.headers['X-XSRF-TOKEN'] = cookie.load(xsrfCookieName);
    let sessionTimeout = cookie.load(VERACODE_SESSION_TIMEOUT_COOKIE_KEY);
    const validSession = moment().isBefore(sessionTimeout);

    if (!validSession) {
      // If the sessionTimeout cookie appears to have expired, make a request to uigateway
      // to check if there is still a valid session. It is possible for sessionTimeout
      // from the uigateway to be extended beyond what's in the cookie. For instance,
      // when we ping an identity endpoint GET /v2/principal, we shift sessionTimeout further.
      // This simply verifies if an extension has happened or timeout is the same as what's in the cookie.
      return await Helpers.checkVeracodeSessionTimeout(requestConfig);
    }
  }

  return requestConfig;
};

const onRequestRejected = (error: AxiosError) => {
  return Promise.reject(errorHandler(error, error.config));
};

const onResponseFulfilled = (res: AxiosResponse) => {
  let data = res.data;
  const path = res.config.url;

  if (path.includes('/signup') || path.includes('/login') || path.includes('/user-status')) {
    if (res.headers && res.headers['x-auth-token']) {
      // if the response contains an x-auth-token, we need to set (or reset) the session token cookie
      const token = res.headers['x-auth-token'];
      AuthService.setAuthToken(token);
    }

    // signup and login are special in that we want to redirect in some cases
    data = data || {};
    data.authToken = res.headers['x-auth-token'];
  }

  return data;
};

const onResponseRejected = (isVeracodeDomain: boolean) => async (error: AxiosError) => {
  if (isVeracodeDomain) {
    await Helpers.checkVeracodeSessionTimeout(error.config);
  }
  return Promise.reject(errorHandler(error, error.config));
};
