import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Helpers from '~/utils/Helpers';

import * as orgUserActions from '~/actions/orgUser';
import * as toastrActions from '~/actions/toastr';
import * as workspaceInviteActions from '~/actions/workspaceInvite';

import InviteOrAddUsers from '~/components/InviteOrAddUsers';

interface InviteToWorkspaceProps {
  teamId: string;
  orgUserActions: object;
  toastrActions: object;
  showModal: (...args: any[]) => any;
  workspaceInviteActions: object;
  orgState: App.OrgState;
  workspaceInviteState: object;
}
class InviteToWorkspace extends React.Component<InviteToWorkspaceProps, {}> {
  componentDidMount() {
    this.props.orgUserActions.fetchOrgUsers();
  }

  componentWillUnmount() {
    this.props.workspaceInviteActions.resetWorkspaceInviteState();
  }

  handleEmailsInput(value) {
    this.props.workspaceInviteActions.handleEmailsInput(value);
  }

  addEmails() {
    const { workspaceInviteState } = this.props;
    const { rawEmailsValue = '' } = workspaceInviteState;

    this.props.workspaceInviteActions.addEmails(rawEmailsValue);
  }

  removeUser(email) {
    this.props.workspaceInviteActions.removeUser(email);
  }

  toggleWorkspaceAdmin(email) {
    this.props.workspaceInviteActions.toggleWorkspaceAdmin(email);
  }

  sendInvites() {
    const { workspaceInviteState, teamId, orgState } = this.props;
    const { validEmails = [] } = workspaceInviteState;
    const newUserEmails = validEmails.filter(user => !user.id);
    const { org = {} } = orgState;
    const { license = {} } = org;
    const plan = org.plan || {};
    const { usage = {} } = license;
    const { users } = usage;
    const { userLimit } = plan;
    const invitesRemaining = userLimit - users;
    const invitesToRemove = users + newUserEmails.length - userLimit;

    if (userLimit && newUserEmails.length > invitesRemaining) {
      this.props.workspaceInviteActions.updateWorkspaceInviteError(
        `You only have ${invitesRemaining} invite${
          invitesRemaining === 1 ? '' : 's'
        } remaining, and you have entered ${newUserEmails.length} new user email${
          newUserEmails.length === 1 ? '' : 's'
        }. Please remove ${invitesToRemove} email${invitesToRemove === 1 ? '' : 's'}.`
      );
    } else {
      this.props.workspaceInviteActions.sendInvites(teamId, validEmails).then(res => {
        if (res.success) {
          this.handleInviteSuccess(validEmails);
          // fetch pending invites?
          this.showModal();
        }
      });
    }
  }

  handleInviteSuccess(users) {
    const existingUsers = [];
    const emailInvites = [];

    users.forEach(user => {
      if (user.id) {
        existingUsers.push(Helpers.formatUserName(user));
      } else {
        emailInvites.push(user.email);
      }
    });

    const message = (
      <div className="mv-">
        {emailInvites.length ? (
          <div>Invitations have successfully been sent to: {emailInvites.join(', ')}</div>
        ) : (
          ''
        )}
        {existingUsers.length ? (
          <div className={emailInvites.length && 'mt-'}>
            The following users already exist and have been added to the workspace:{' '}
            {existingUsers.join(', ')}
          </div>
        ) : (
          ''
        )}
      </div>
    );

    this.props.toastrActions.addToastr({
      id: 'INVITE_TO_WORKSPACE_SUCCESS',
      level: 'success',
      title: 'Invitations successfully sent',
      message,
    });
  }

  showModal(modalType) {
    this.props.showModal(modalType);
  }

  render() {
    const { workspaceInviteState, orgState } = this.props;
    const { org = {} } = orgState;
    const { license = {} } = org;
    const plan = org.plan || {};
    const { usage = {} } = license;
    const { users } = usage;
    const { userLimit } = plan;
    const {
      rawEmailsValue = '',
      validEmails = [],
      invalidEmails = [],
      sendInvitesError,
    } = workspaceInviteState;

    return (
      <div className="grid grid--narrow">
        <div className="grid__item col-1-1 flex align-items--baseline">
          <div>
            <strong className="font--h6">Enter Emails</strong>
          </div>
          {userLimit && (
            <div className="ml-">
              ({userLimit - users} invitation
              {userLimit - users === 1 ? '' : 's'} remaining)
            </div>
          )}
        </div>
        <InviteOrAddUsers
          rawEmailsValue={rawEmailsValue}
          validEmails={validEmails}
          invalidEmails={invalidEmails}
          removeUser={email => this.removeUser(email)}
          toggleWorkspaceAdmin={email => this.toggleWorkspaceAdmin(email)}
          addEmails={() => this.addEmails()}
          handleEmailsInput={value => this.handleEmailsInput(value)}
        />

        <div className={`grid__item col-1-1 ${sendInvitesError ? 'is-showing-50' : 'is-hiding'}`}>
          <div className="color--danger mt-">{sendInvitesError}</div>
        </div>

        <div className="grid__item col-1-1 flex justify-content--end mt">
          <button className="pv- col-1-5 mr-" onClick={() => this.showModal()}>
            Cancel
          </button>
          <button
            className={`pv- col-1-5 btn--primary-clear ${!validEmails.length && 'disabled'}`}
            disabled={!validEmails.length}
            onClick={validEmails.length ? () => this.sendInvites() : undefined}
          >
            Invite
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgState: state.orgState,
    workspaceInviteState: state.workspaceInviteState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
    toastrActions: bindActionCreators(toastrActions, dispatch),
    workspaceInviteActions: bindActionCreators(workspaceInviteActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteToWorkspace);
