import produce from 'immer';
import { VCMyState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import {
  START_A_SCAN_STATE_SUCCESS,
  VCMeActionTypes,
} from '~/actions/vcAppActions/vcAppActionsTypes/types';

const defaultState: VCMyState = {
  permissions: {
    startVeracodeStaticScan: false,
    orgAgents: false,
    workspaceAgents: false,
    // default to show two-tab UI
    scaUpload: true,
  },
};
const vcMyState = (state = defaultState, action: VCMeActionTypes) => {
  return produce(state, draft => {
    switch (action.type) {
      case START_A_SCAN_STATE_SUCCESS:
        draft.permissions = action.permissions;
    }
  });
};

export default vcMyState;
