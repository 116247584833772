import {
  Agent,
  SelectedWorkspace,
  AgentEvent,
  ActiveInstallOption,
} from '~/reducers/agentState.types';

export const ADD_AGENT_TO_TEAM = 'ADD_AGENT_TO_TEAM';
export const CLEAR_ACTIVATION_TOKEN = 'CLEAR_ACTIVATION_TOKEN';
export const CLEAR_REGENERATED_TOKEN = 'CLEAR_REGENERATED_TOKEN';
export const CLEAR_PLUGIN_TOKENS = 'CLEAR_PLUGIN_TOKENS';
export const FILTER_AGENT_LIST_BY_STRING = 'FILTER_AGENT_LIST_BY_STRING';
export const RESET_AGENT_NAME = 'RESET_AGENT_NAME';
export const SHOW_AGENT_MODAL = 'SHOW_AGENT_MODAL';
export const UPDATE_ACTIVE_AGENT = 'UPDATE_ACTIVE_AGENT';
export const UPDATE_AGENT_IN_SETUP = 'UPDATE_AGENT_IN_SETUP';
export const UPDATE_AGENT_NAME = 'UPDATE_AGENT_NAME';
export const UPDATE_AGENT_SCAN_EVENTS = 'UPDATE_AGENT_SCAN_EVENTS';
export const UPDATE_UPGRADE_AGENT_TYPE = 'UPDATE_UPGRADE_AGENT_TYPE';
export const UPDATE_UPGRADE_ACTIVE_AGENT = 'UPDATE_UPGRADE_ACTIVE_AGENT';
export const UPDATE_AGENT_LIST_SORT = 'UPDATE_AGENT_LIST_SORT';
export const UPDATE_AGENT_RELEASES = 'UPDATE_AGENT_RELEASES';
export const UPDATE_AGENTS = 'UPDATE_AGENTS';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const UPDATE_EVENT_PAGE = 'UPDATE_EVENT_PAGE';
export const UPDATE_INSTALL_OPTION = 'UPDATE_INSTALL_OPTION';
export const UPDATE_INTEGRATION_TYPE = 'UPDATE_INTEGRATION_TYPE';
export const UPDATE_PLUGIN_TOKEN = 'UPDATE_PLUGIN_TOKEN';
export const UPDATE_REGENERATED_TOKEN = 'UPDATE_REGENERATED_TOKEN';
export const UPDATE_CURRENT_ACTIVE_AGENT = 'UPDATE_CURRENT_ACTIVE_AGENT';
export const UPDATE_SELECTED_AGENT_WORKSPACE = 'UPDATE_SELECTED_AGENT_WORKSPACE';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const REQUEST_AGENT_MESSAGES = 'REQUEST_AGENT_MESSAGES';
export const RECEIVE_AGENT_MESSAGES_SUCCESS = 'RECEIVE_AGENT_MESSAGES_SUCCESS';
export const RECEIVE_AGENT_MESSAGES_ERROR = 'RECEIVE_AGENT_MESSAGES_ERROR';
export const STOP_POLLING = 'STOP_POLLING';
export const ALLOW_POLLING = 'ALLOW_POLLING';
export const UPDATE_OS = 'UPDATE_OS';
export const UPDATE_ACTIVE_SCRIPT_MODE = 'UPDATE_ACTIVE_SCRIPT_MODE';
export const FETCHING_TEAM_AGENTS = 'FETCHING_TEAM_AGENTS';

interface AddAgentToTeamRequestAction {
  type: typeof ADD_AGENT_TO_TEAM;
  agent: Agent;
  teamId: string;
}

interface ClearActivationTokenRequestAction {
  type: typeof CLEAR_ACTIVATION_TOKEN;
}

interface ClearRegeneratedTokenRequestAction {
  type: typeof CLEAR_REGENERATED_TOKEN;
}

interface ClearPluginTokensRequestAction {
  type: typeof CLEAR_PLUGIN_TOKENS;
}

interface FilterAgentLlistByStringRequestAction {
  type: typeof FILTER_AGENT_LIST_BY_STRING;
  value: string;
}

interface ResetAgentNameRequestAction {
  type: typeof RESET_AGENT_NAME;
}

interface ShowAgentModalRequestAction {
  type: typeof SHOW_AGENT_MODAL;
  modalType: string;
}

interface UpdateActiveAgentRequestAction {
  type: typeof UPDATE_ACTIVE_AGENT;
  agent: Agent;
  teamId: string;
}

interface UpdateAgentInSetupRequestAction {
  type: typeof UPDATE_AGENT_IN_SETUP;
  agentId: string;
  setupPhase: any;
}

interface UpdateAgentNameRequestAction {
  type: typeof UPDATE_AGENT_NAME;
  value: string;
}

interface UpdateAgentScanEventsRequestAction {
  type: typeof UPDATE_AGENT_SCAN_EVENTS;
  events: Array<AgentEvent>;
}

interface UpdateUpgradeAgentTypeRequestAction {
  type: typeof UPDATE_UPGRADE_AGENT_TYPE;
  agentType: string;
}

interface UpdateUpgradeActiveAgentRequestAction {
  type: typeof UPDATE_UPGRADE_ACTIVE_AGENT;
  activeInstallOption: string;
}

interface UpdateAgentListSortRequestAction {
  type: typeof UPDATE_AGENT_LIST_SORT;
  field: string;
}

interface UpdateAgentReleasesRequestAction {
  type: typeof UPDATE_AGENT_RELEASES;
  releases: any;
}

interface UpdateAgentsRequestAction {
  type: typeof UPDATE_AGENTS;
  agents: Array<Agent>;
  teamId: string;
}

interface UpdateCurrentPageRequestAction {
  type: typeof UPDATE_CURRENT_PAGE;
  currentPage: number;
}

interface UpdateEventPageRequestAction {
  type: typeof UPDATE_EVENT_PAGE;
  currentPage: number;
}

interface UpdateInstallOptionRequestAction {
  type: typeof UPDATE_INSTALL_OPTION;
  installOption: string;
  meta: {
    snowplow: boolean;
    string1: string;
  };
}

interface UpdateIntegrationTypeRequestAction {
  type: typeof UPDATE_INTEGRATION_TYPE;
  intType: string;
}

interface UpdatePluginTokenRequestAction {
  type: typeof UPDATE_PLUGIN_TOKEN;
  tokenObj: any;
}

interface UpdateRegeneratedTokenRequestAction {
  type: typeof UPDATE_REGENERATED_TOKEN;
  tokenObj: any;
}

interface UpdateCurrentActiveAgentRequestAction {
  type: typeof UPDATE_CURRENT_ACTIVE_AGENT;
  activeAgent: Agent;
}

interface UpdateSelectedAgentWorkspaceRequestAction {
  type: typeof UPDATE_SELECTED_AGENT_WORKSPACE;
  workspace: SelectedWorkspace;
}

interface UpdateTokenRequestAction {
  type: typeof UPDATE_TOKEN;
  tokenObj: {
    agentId: string;
  };
}

interface RequestAgentMessagesRequestAction {
  type: typeof REQUEST_AGENT_MESSAGES;
  ignoreTimeout: boolean;
}

interface ReceiveAgentMessagesSUCCESSRequestAction {
  type: typeof RECEIVE_AGENT_MESSAGES_SUCCESS;
  ignoreTimeout: boolean;
}

interface ReceiveAgentMessagesErrorRequestAction {
  type: typeof RECEIVE_AGENT_MESSAGES_ERROR;
  ignoreTimeout: boolean;
}

interface StopPollingRequestAction {
  type: typeof STOP_POLLING;
}

interface AllowPollingRequestAction {
  type: typeof ALLOW_POLLING;
}

interface UpdateOSRequestAction {
  type: typeof UPDATE_OS;
  operatingSystem: keyof ActiveInstallOption;
}

interface UpdateActiveScriptModeRequestAction {
  type: typeof UPDATE_ACTIVE_SCRIPT_MODE;
  scriptMode: string;
}

interface FetchingTeamAgentsRequestAction {
  type: typeof FETCHING_TEAM_AGENTS;
  value: boolean;
}

export type AgentActionTypes =
  | AddAgentToTeamRequestAction
  | ClearActivationTokenRequestAction
  | ClearRegeneratedTokenRequestAction
  | ClearPluginTokensRequestAction
  | FilterAgentLlistByStringRequestAction
  | ResetAgentNameRequestAction
  | ShowAgentModalRequestAction
  | UpdateActiveAgentRequestAction
  | UpdateAgentInSetupRequestAction
  | UpdateAgentNameRequestAction
  | UpdateAgentScanEventsRequestAction
  | UpdateUpgradeAgentTypeRequestAction
  | UpdateUpgradeActiveAgentRequestAction
  | UpdateAgentListSortRequestAction
  | UpdateAgentReleasesRequestAction
  | UpdateAgentsRequestAction
  | UpdateCurrentPageRequestAction
  | UpdateEventPageRequestAction
  | UpdateInstallOptionRequestAction
  | UpdateIntegrationTypeRequestAction
  | UpdatePluginTokenRequestAction
  | UpdateRegeneratedTokenRequestAction
  | UpdateCurrentActiveAgentRequestAction
  | UpdateSelectedAgentWorkspaceRequestAction
  | UpdateTokenRequestAction
  | RequestAgentMessagesRequestAction
  | ReceiveAgentMessagesSUCCESSRequestAction
  | ReceiveAgentMessagesErrorRequestAction
  | StopPollingRequestAction
  | AllowPollingRequestAction
  | UpdateOSRequestAction
  | UpdateActiveScriptModeRequestAction
  | FetchingTeamAgentsRequestAction;
