/* eslint-disable */

import React from 'react';
import { Button } from 'fluorine-ui';

interface VCStartScanFooterProps {
  handleCancel: () => void;
}

function VCStartScanFooter(props: VCStartScanFooterProps) {
  const { handleCancel } = props;
  return (
    <footer className="vcStartScanFooter">
      <div className="footerContentWrapper">
        <a
          href="mailto:support@veracode.com?Subject=New%20Agent%2DBased%20Scan%20Support%20Request"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Support
        </a>
      </div>

      <div className="footerButtonContainer">
        <Button variant="urgentSecondary" size="medium" onClick={() => handleCancel()}>
          Cancel
        </Button>
      </div>
      <div className="footerContentWrapper" />
    </footer>
  );
}

export default VCStartScanFooter;
