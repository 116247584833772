import React from 'react';
import Modal from 'react-modal';
import KeyHandler from 'react-key-handler';

interface SourceClearModalProps {
  isOpen: boolean;
  onClose: (...args: any[]) => any;
  title: string | object;
  width?: number;
  minHeight?: number;
  closeWhenClickOutside?: boolean;
  showCloseButton?: boolean;
}

/*
  Default Modal for SourceClear.
  Usage:

   <SourceClearModal
      isOpen={true}
      title="Upgrade now"
      onClose={handleClose}
      width={400} >

      <div>
        Content for your modal here
      </div>

    </SourceClearModal>

  - Title can either be an element or string.
  - onClose is fired when esc key is pressed or the cross button is clicked on the top
  - width is the modal's width. By default it's 200 unless specified.

*/
const SourceClearModal: React.FunctionComponent<SourceClearModalProps> = props => {
  const {
    children,
    isOpen = true,
    title = '',
    width = 200,
    minHeight = 0,
    closeWhenClickOutside = true,
    showCloseButton = true,
  } = props;

  function closeModal() {
    if (isOpen) {
      props.onClose();
    }
  }

  function checkBeforeClose() {
    if (closeWhenClickOutside) {
      props.onClose();
    }
  }

  const customWidth = {
    content: {
      width: `${width}px`,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={checkBeforeClose}
      overlayClassName="sourceClearModalOverlay zIndex-9--overlay"
      className="sourceClearModal min-width--200"
      style={customWidth}
      ariaHideApp={false}
    >
      <KeyHandler keyEventName="keydown" keyValue="Escape" onKeyHandle={closeModal} />
      <div className="-mt -mh bg-color--black grid" data-automation-id="SourceClearModal-Header">
        <div className="grid__item color--white font--h6 flex flex--justify-content--space-between flex--align-items--center text--bold">
          <span className="pv--">{title}</span>

          {showCloseButton && (
            <span className="pr pv-">
              <i className="sci sci--xs sci__close cursor--pointer font--16" onClick={closeModal} data-testid={"close-button"} />
            </span>
          )}
        </div>
      </div>

      <div
        className="pt-"
        style={{ minHeight: `${minHeight}px` }}
        data-automation-id="SourceClearModal-Content"
      >
        {children}
      </div>
    </Modal>
  );
};

export default SourceClearModal;
