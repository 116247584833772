import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Helmet from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';

import { integrationOptions } from '~/constants/ModelConstants';

import AgentOSOptions from '~/components/AgentOSOptions';
import AgentSetupBreadcrumbs from '~/components/AgentSetupBreadcrumbs';
import IntegrationOption from '~/components/IntegrationOption';

import * as agentActions from '~/actions/agents';
import * as welcomeActions from '~/actions/welcome';

import { AgentState } from '~/reducers/agentState.types';

interface NewAgentSummaryProps extends RouteComponentProps {
  agentState: AgentState;
  orgState: App.OrgState;
}

class NewAgentSummary extends Component<
  NewAgentSummaryProps & ReturnType<typeof mapDispatchToProps>
> {
  updateOperatingSystem(OS: string) {
    this.props.agentActions.updateOperatingSystem(OS);
  }

  selectOS(OS: string) {
    this.props.welcomeActions.selectOS(OS);
    this.props.agentActions.updateOperatingSystem(OS);
  }

  showModal(modalType) {
    this.props.agentActions.showModal(modalType);
  }

  render() {
    const { match } = this.props;
    const { params = {} } = match;
    const { teamId } = params;

    return (
      <div className="grid mb++">
        <Helmet>
          <title>Set Up Scanner</title>
        </Helmet>
        <div className="grid__item col-5-6 mt">
          <AgentSetupBreadcrumbs teamId={teamId} />

          <div className="mt">
            <div className="mb--">Choose your operating system or CI to set up an agent.</div>
            <AgentOSOptions teamId={teamId} selectOS={os => this.selectOS(os)} />
          </div>

          <div className="grid grid--wide mt+">
            <div className="grid__item col-1-1">
              <div className="font--h6">Integration Options</div>
            </div>
            <div className="grid__item col-1-1 mt--">
              <div className="grid">
                {integrationOptions.map(option => {
                  return (
                    <div className="grid__item col-1-3 flex" key={option.title}>
                      <IntegrationOption
                        description={option.description}
                        buttonText={option.title}
                        route={
                          teamId
                            ? `/workspaces/${teamId}/agents/new/${option.routeName}`
                            : `/org/settings/agents/new/${option.routeName}`
                        }
                        imageEnum={option.imageEnum}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    agentState: state.agentState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    agentActions: bindActionCreators(agentActions, dispatch),
    welcomeActions: bindActionCreators(welcomeActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAgentSummary);
