export const SHOW_WELCOME_MODAL = 'SHOW_WELCOME_MODAL';
export const CLOSE_WELCOME_MODAL = 'CLOSE_WELCOME_MODAL';
export const WELCOME_MODAL_BTN_CLICKED = 'WELCOME_MODAL_BTN_CLICKED';

export const showWelcomeModal = modalType => ({
  type: SHOW_WELCOME_MODAL,
  modalType,
});

export const snowplowWelcomeBtnClicked = (inputId, teamId) => ({
  type: WELCOME_MODAL_BTN_CLICKED,
  inputId,
  teamId,
  meta: {
    snowplow: true,
    string1: inputId,
  },
});

export const closeWelcomeModal = () => ({
  type: CLOSE_WELCOME_MODAL,
});
