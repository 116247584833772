export const UPDATE_MATCH_UNMATCH_DROPDOWN = 'UPDATE_MATCH_UNMATCH_DROPDOWN';

export const updateFilterValue = value => ({
  type: UPDATE_MATCH_UNMATCH_DROPDOWN,
  field: 'matched_unmatched_views',
  value,
});

export const resetDropdownMenuState = () => ({
  type: UPDATE_MATCH_UNMATCH_DROPDOWN,
  field: 'matched_unmatched_views',
  value: {
    label: 'Matched Libraries',
    value: 'MATCHED',
  },
});
