import * as actions from '~/actions/reports';

const defaultState = {
  openDropdowns: [],
  expandedQVGenericCollapse: '',
  activeOrSuppressed: 'ACTIVE',
};

const reportState = (state = defaultState, action) => {
  switch (action.type) {
    case actions.REPORT_TOGGLE_DROPDOWN: {
      let newOpenDropdowns = state.openDropdowns.slice(0);

      if (newOpenDropdowns.includes(action.toggleDropdown)) {
        newOpenDropdowns = newOpenDropdowns.filter(x => x !== action.toggleDropdown);
      } else {
        newOpenDropdowns.push(action.toggleDropdown);
      }

      return Object.assign({}, state, {
        openDropdowns: newOpenDropdowns,
      });
    }
    case actions.CLOSE_DROPDOWN: {
      let newOpenDropdowns = state.openDropdowns.slice(0);

      newOpenDropdowns = newOpenDropdowns.filter(dropdown => dropdown !== action.dropdownId);

      return {
        ...state,
        openDropdowns: newOpenDropdowns,
      };
    }
    case actions.UPDATE_EXPANDED_QV_GENERIC_COLLAPSE: {
      let currentIdentifier = state.expandedQVGenericCollapse,
        newIdentifier = action.identifier === currentIdentifier ? '' : action.identifier;
      return Object.assign({}, state, {
        expandedQVGenericCollapse: newIdentifier,
      });
    }
    case actions.TOGGLE_ACTIVE_OR_SUPPRESSED:
      return {
        ...state,
        activeOrSuppressed: action.activeOrSuppressed,
      };
    default:
      return state;
  }
};

export default reportState;
