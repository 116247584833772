import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import OrgPaidStatus from '~/utils/OrgPaidStatus';

import BlockingPage from '~/containers/BlockingPage';

import { RouteComponentProps } from 'react-router-dom';

interface OrgUpgradePageProps extends RouteComponentProps {
  orgState: App.OrgState;
}

class OrgUpgradePage extends React.Component<OrgUpgradePageProps, {}> {
  componentDidMount() {
    const { orgState, history } = this.props;
    const { org } = orgState;
    const { license, permissions = {} } = org;
    const { billing } = permissions;
    const { planType, trialEndDate } = license;

    if (!billing) {
      history.replace('/org/settings/no-owner');
    }

    if (planType === 'ENTERPRISE' || (planType === 'PRO' && !trialEndDate)) {
      history.replace('/org/settings/subscription');
    }
  }

  render() {
    const { orgState } = this.props;
    const { org } = orgState;
    const { license } = org;

    const { status } = license;
    const trialEndDate = license.trialEndDate && moment(license.trialEndDate);
    let trialDaysLeft: number;
    if (trialEndDate) {
      trialDaysLeft = OrgPaidStatus.calculateDaysLeft(trialEndDate);
    }

    const { preHeadline, details } = blockingPageTextForOrgUpgradePage(status, trialDaysLeft);
    return (
      <BlockingPage
        hasSidebar={true}
        renderPreheadline={() => {
          return <span>{preHeadline}</span>;
        }}
        renderEnterpriseDetails={() => {
          return <span>{details}</span>;
        }}
      />
    );
  }
}

function blockingPageTextForOrgUpgradePage(status, trialDaysLeft) {
  if (status === 'TRIAL') {
    return {
      preHeadline: `Your trial will expire in ${trialDaysLeft} days`,
      details:
        'Keep using Enterprise features like Custom Rules and get access to Advanced User Management, new vulnerability notifications, and additional support.',
    };
  } else {
    return {
      preHeadline: 'You are currently on the Personal Plan',
      details:
        'Scale your program with Enterprise features like Advanced User Management, Custom Rules, new vulnerability notifications, and additional support.',
    };
  }
}

function mapStateToProps(state) {
  return {
    orgState: state.orgState,
  };
}

export default connect(mapStateToProps)(OrgUpgradePage);
