import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import AddInviteesToGroupModal from '~/containers/AddInviteesToGroupModal';
import ChooseUserRoles from '~/containers/ChooseUserRoles';
import * as orgUserInviteActions from '~/actions/orgUserInvite';
import * as MODEL from '~/constants/ModelConstants';

interface InviteMoreOptionsModalProps {
  orgUserInviteActions: object;
  orgUserInviteState: object;
  orgState: App.OrgState;
  closeWhenClickOutside?: boolean;
  isOrgAdminOrOwner?: boolean;
  hideAddInviteesToGroupGroupSelection?: boolean;
  hideTabs?: boolean;
  defaultModal?: string;
  onClose: (...args: any[]) => any;
  sendInvites: (...args: any[]) => any;
  showGroups?: boolean;
}
class InviteMoreOptionsModal extends React.Component<InviteMoreOptionsModalProps, {}> {
  componentDidMount() {
    const {
      defaultModal = MODEL.inviteModalTabs.CHOOSE_USER_ROLES,
      isOrgAdminOrOwner,
    } = this.props;

    this.props.orgUserInviteActions.updateActiveModalTab(defaultModal);

    if (!isOrgAdminOrOwner) {
      this.props.orgUserInviteActions.updateActiveModalTab(
        MODEL.inviteModalTabs.ADD_USERS_TO_GROUP
      );
    }
  }

  onClose() {
    this.props.onClose();
  }

  updateInviteStage(inviteStage) {
    this.props.orgUserInviteActions.updateInviteStage(inviteStage);
  }

  updateTab(tab) {
    this.props.orgUserInviteActions.updateActiveModalTab(tab);
  }

  handleInvite() {
    const { orgUserInviteState, orgState } = this.props;
    const { org = {} } = orgState;
    const { license = {} } = org;
    const plan = org.plan || {};
    const { usage = {} } = license;
    const { users } = usage;
    const { userLimit } = plan;
    const { invitees = [] } = orgUserInviteState;
    const invitesRemaining = userLimit - users;
    const invitesToRemove = users + invitees.length - userLimit;

    if (userLimit && invitees.length > invitesRemaining) {
      this.props.orgUserInviteActions.updateSendInviteError(
        `You only have ${invitesRemaining} invite${
          invitesRemaining === 1 ? '' : 's'
        } remaining, and you have entered ${invitees.length} email${
          invitees.length === 1 ? '' : 's'
        }. Please remove ${invitesToRemove} email${invitesToRemove === 1 ? '' : 's'}.`
      );
    } else {
      this.props.sendInvites();
    }
  }

  render() {
    const {
      orgUserInviteState,
      hideTabs = false,
      hideAddInviteesToGroupGroupSelection,
      showGroups,
      isOrgAdminOrOwner,
    } = this.props;
    const { activeModalTab, sendInviteError } = orgUserInviteState;

    return (
      <div className="grid">
        {!hideTabs && (
          <div className="grid__item col-1-1">
            <div className="mb- flex">
              <div>
                <div
                  onClick={
                    showGroups
                      ? () => this.updateTab(MODEL.inviteModalTabs.CHOOSE_USER_ROLES)
                      : undefined
                  }
                  className={classNames('font--h6', {
                    'cursor--pointer':
                      showGroups && !(activeModalTab === MODEL.inviteModalTabs.CHOOSE_USER_ROLES),
                    'text--bold': !showGroups,
                    'bo-b--5 border-color--primary text--bold':
                      activeModalTab === MODEL.inviteModalTabs.CHOOSE_USER_ROLES,
                  })}
                >
                  Choose User Roles
                </div>
              </div>
              {showGroups && (
                <div className="pl">
                  <div
                    onClick={() => this.updateTab(MODEL.inviteModalTabs.ADD_USERS_TO_GROUP)}
                    className={`font--h6 ${
                      activeModalTab === MODEL.inviteModalTabs.ADD_USERS_TO_GROUP
                        ? 'bo-b--5 border-color--primary text--bold'
                        : 'cursor--pointer'
                    }`}
                  >
                    Add to Team
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {!isOrgAdminOrOwner && (
          <div className="grid__item col-1-1">
            <div className="font--h6 text--bold">Add to Team</div>
          </div>
        )}

        <div className="grid__item col-1-1 mt-">
          {activeModalTab === MODEL.inviteModalTabs.CHOOSE_USER_ROLES && <ChooseUserRoles />}
          {activeModalTab === MODEL.inviteModalTabs.ADD_USERS_TO_GROUP && (
            <AddInviteesToGroupModal hideGroupSelection={hideAddInviteesToGroupGroupSelection} />
          )}
        </div>

        <div className={`grid__item col-1-1 ${sendInviteError ? 'is-showing-50' : 'is-hiding'}`}>
          <div className="color--danger mt-">{sendInviteError}</div>
        </div>

        <div className="mt flex flex--justify-content--end col-1-1">
          <button className="pv- col-1-5 mr-" onClick={() => this.onClose()}>
            Cancel
          </button>
          <button
            className="pv- col-1-5 btn--primary-clear mr-"
            onClick={() => this.updateInviteStage('EMAIL_INPUT')}
          >
            Back
          </button>
          <button className="pv- col-1-5 btn--success" onClick={() => this.handleInvite()}>
            Invite
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgUserInviteState: state.orgUserInviteState,
    orgState: state.orgState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orgUserInviteActions: bindActionCreators(orgUserInviteActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteMoreOptionsModal);
