import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { FEATURE_SLUG_MAP } from '~/constants/ModelConstants';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import { withOrgPermissionCheck } from '~/utils/Permissions';

import AgentsList from '~/containers/AgentsList';

import * as orgAgentActions from '~/actions/orgAgent';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';

interface OrgAgentsPageProps extends RouteComponentProps {
  orgAgentActions: object;
  orgAgentState: object;
  orgState: App.OrgState;
  vcPageState: VCPageState;
}

class OrgAgentsPage extends React.Component<OrgAgentsPageProps, {}> {
  componentDidMount() {
    const { orgState, history } = this.props;
    const { org } = orgState;
    const showAgents = FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.ORG_AGENTS, org);
    const isOrgEnterpriseOrTrial = OrgPaidStatus.isOrgEnterpriseOrTrial(org);

    if (!showAgents && !isOrgEnterpriseOrTrial) {
      history.replace(`/org/settings`);
    }

    this.props.orgAgentActions.fetchOrgAgents(org.id);
  }

  handlePaginationClick(page) {
    this.props.orgAgentActions.updateCurrentPage(page);
  }

  updateStringFilter(field, value) {
    this.props.orgAgentActions.updateStringFilter(value);
  }

  render() {
    const {
      orgAgentState,
      vcPageState: { shouldShowVeracodePage },
    } = this.props;
    const {
      orgAgents = [],
      pagination = {},
      sortAgentList = {},
      filteredAgents = [],
      search,
      isFetchingOrgAgents = false,
    } = orgAgentState;
    const agentTitle = shouldShowVeracodePage ? 'Agents' : 'Organization Agents';

    return (
      <div className="mt">
        <Helmet>
          <title>Agents</title>
        </Helmet>
        <h3 data-automation-id="OrgAgentsPage-Title">{agentTitle}</h3>
        <AgentsList
          agents={filteredAgents}
          pagination={pagination}
          sortAgentList={sortAgentList}
          search={search}
          zeroAgents={!orgAgents.length}
          isFetching={isFetchingOrgAgents}
          handlePaginationClick={page => this.handlePaginationClick(page)}
          updateStringFilter={(field, value) => this.updateStringFilter(field, value)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgAgentState: state.orgAgentState,
    orgState: state.orgState,
    vcPageState: state.vcPageState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orgAgentActions: bindActionCreators(orgAgentActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOrgPermissionCheck(OrgAgentsPage, 'agents'));
