import { combineReducers } from 'redux';

import * as actions from '~/actions/welcomeModal';

const welcomeModalState = () => {
  const openedModal = (state = '', action) => {
    switch (action.type) {
      case actions.SHOW_WELCOME_MODAL:
        return action.modalType;
      case actions.CLOSE_WELCOME_MODAL:
        return '';
      default:
        return state;
    }
  };

  return combineReducers({
    openedModal,
  });
};

export default welcomeModalState();
