/**
 * @type {{
  API_URL: String,
  VC_API_URL: String,
  BEARER_DOMAINS: Array.<String>,
  VERACODE_DOMAINS: Array.<String>,
  VC_FEATURES: Array.<object>,
  BILLING_URL: String,
  COLLECTOR_COOKIE_DOMAIN: String,
  COLLECTOR_HOST: String,
  FRONTEND_HOST_URL: String,
  HELP_URL: String,
  RELEASE_ID: String,
  SENTRY_URL: String,
  VERACODE_UIGATEWAY_HOST: String,
  VERACODE_LOGIN_HOST: String,
  WWW_URL: String,
  VC_WWW_URL: String,
  VC_INFO_URL: String,
  REGISTRY_URL: String,
  VC_REGISTRY_URL: String,
  REGISTRY_FRONTEND_DOMAIN: Array.<String>,
  STRIPE_PUBLISHABLE_KEY: String,
  SEPC_DOCKER_URL: String,
  SEP_SERVER_IP: String,
  CSV_REQUEST_RATE: Number,
  CSV_REQUEST_PAGE_SIZE: Number,
  REPORT_DEFAULT_PAGE_SIZE: Number,
  REGION: String,
}}
 */
const config = (window as any).SRCCLR_ENV || {};

export default config;
