import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

interface ReportPaginationProps {
  page: object;
  quickviewId: string;
  quickviewLink: string;
  onPageClick: (...args: any[]) => any;
}

/**
  Use this component whenever you need pagination in your quick view or anywhere.
  Usage as below
  <Pagination onNextPage={} onPrevPage={} currentPage={} totalpage={} />
*/
const ReportPagination: React.FunctionComponent<ReportPaginationProps> = props => {
  const { page, quickviewId, quickviewLink, onPageClick } = props;
  const { number: currentPage = 0, totalPages = 0 } = page;

  function loadFirstPage() {
    onPageClick(quickviewId, quickviewLink, 0);
  }

  function loadPreviousPage() {
    onPageClick(quickviewId, quickviewLink, currentPage - 1);
  }

  function loadNextPage() {
    onPageClick(quickviewId, quickviewLink, currentPage + 1);
  }

  function loadLastPage() {
    onPageClick(quickviewId, quickviewLink, totalPages - 1);
  }

  if (totalPages === 1) return null;

  const hasPreviousPage = currentPage > 0;
  const hasNextPage = currentPage + 1 < totalPages;

  const nextPageClasses = classNames({
    'color--muted': !hasNextPage,
    'cursor--pointer': hasNextPage,
  });
  const prevPageClasses = classNames({
    'color--muted': !hasPreviousPage,
    'cursor--pointer': hasPreviousPage,
  });

  const firstPageEl = (
    <span
      className={`${currentPage !== 0 ? 'cursor--pointer' : 'color--muted'} color--primary pv- ph`}
      onClick={currentPage !== 0 ? loadFirstPage : _.noop}
    >
      <i className={'sci sci--sm sci__caret--left'} />
      <i className={'sci sci--sm sci__caret--left pr-- -ml--'} /> First
    </span>
  );

  const prevPageEl = (
    <span
      className={`${prevPageClasses} color--primary pv- ph`}
      onClick={hasPreviousPage ? loadPreviousPage : _.noop}
    >
      <i className={'sci sci--sm sci__caret--left pr--'} /> Prev
    </span>
  );

  const nextPageEl = (
    <span
      className={`${nextPageClasses} color--primary pv- ph`}
      onClick={hasNextPage ? loadNextPage : _.noop}
    >
      Next <i className={'sci sci--sm sci__caret--right pl--'} />
    </span>
  );

  const lastPageEl = (
    <span
      className={`${
        currentPage === totalPages - 1 ? 'color--muted' : 'cursor--pointer'
      } color--primary pv- ph`}
      onClick={currentPage !== totalPages - 1 ? loadLastPage : _.noop}
    >
      Last <i className={'sci sci--sm sci__caret--right pl-- -mr--8'} />{' '}
      <i className={'sci sci--sm sci__caret--right'} />
    </span>
  );

  return (
    <div className="grid grid--center pt">
      <div className="grid__item col-2-3">
        <div className="bo-t--1 border-color--white-dark pt">
          <div className="grid">
            <div className="grid__item text--right">{firstPageEl}</div>
            <div className="grid__item text--right">{prevPageEl}</div>
            <div className="grid__item text--center">
              Page <span className="text--bold">{currentPage + 1}</span> of{' '}
              <span className="text--bold">{totalPages}</span>
            </div>
            <div className="grid__item">{nextPageEl}</div>
            <div className="grid__item">{lastPageEl}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPagination;
