import React from 'react';
import config from '~/config';
import GenericError from '~/components/Errors/GenericError';

function NoOrgPage() {
  const redirectUrl = `${config.FRONTEND_HOST_URL ? config.FRONTEND_HOST_URL : ''}/login`;
  const errorMessage = 'This link is invalid';
  const contactInfo = 'Please try again or contact an organization administrator.';
  const redirectMessage = 'Take me to login';

  return (
    <div className="container flex align-items--center justify-content--center height--75vh">
      <GenericError
        errorMessage={errorMessage}
        ctaLinkUrl={redirectUrl}
        ctaLinkText={redirectMessage}
      >
        {contactInfo}
      </GenericError>
    </div>
  );
}

export default NoOrgPage;
