import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from 'react-helmet';
import DropdownMenu from 'react-dd-menu';
import _ from 'lodash';

import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as groupActions from '~/actions/group';
import * as orgUserActions from '~/actions/orgUser';
import * as orgUserInviteActions from '~/actions/orgUserInvite';

import OrgPaidStatus from '~/utils/OrgPaidStatus';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import { FEATURE_SLUG_MAP } from '~/constants/ModelConstants';

import AddSelectedUsersToGroupModal from '~/containers/AddSelectedUsersToGroupModal';
import AddSelectedInviteesToGroupModal from '~/containers/AddSelectedInviteesToGroupModal';
import OrgUserInviteModal from '~/containers/OrgUserInviteModal';
import OrgUserPendingInvitesList from '~/containers/OrgUserPendingInvitesList';
import SourceClearModal from '~/components/SourceClearModal';
import OrgUsersList from '~/components/OrgUsersList';

interface OrgUsersPageProps {
  dropdownMenuActions: object;
  groupActions: object;
  orgUserActions: object;
  orgUserInviteActions: object;
  dropdownMenuState: object;
  orgState: App.OrgState;
  orgUserState: object;
}
class OrgUsersPage extends React.Component<OrgUsersPageProps, {}> {
  constructor(props, context) {
    super(props, context);

    this.delayCloseDropdown = _.debounce(id => this.closeDropdown(id), 50);
  }

  componentDidMount() {
    const { orgState } = this.props;
    const { org = {} } = orgState;

    this.props.groupActions.fetchGroups(org.id);
    this.props.orgUserActions.fetchOrgUsers();
  }

  componentWillUnmount() {
    this.props.orgUserActions.resetUserSort();
    this.props.dropdownMenuActions.closeDropdownMenu('users-actions');
    this.props.orgUserInviteActions.resetInviteModal();
    this.props.orgUserActions.resetUserQuickViews();
  }

  handleUserSelection(userId) {
    this.props.orgUserActions.updateSelectedUsers(userId);
  }

  closeDropdown(menuId) {
    this.props.dropdownMenuActions.closeDropdownMenu(menuId);
  }

  openDropdown(menuId) {
    this.props.dropdownMenuActions.openDropdownMenu(menuId);
  }

  handleFilterInput(e) {
    const { target = {} } = e;
    const { value = '' } = target;

    this.props.orgUserActions.filterUsersByInput(value);
  }

  revokeInvitations() {
    const { orgUserState } = this.props;
    const { selectedPendingInvites } = orgUserState;
    const selectedInviteIds = selectedPendingInvites.map(invite => invite.inviteId);

    this.props.orgUserActions.revokeInvitations(selectedInviteIds).then((res = {}) => {
      if (res.success) {
        this.showModal();
      }
    });
  }

  showModal(modalType) {
    this.props.orgUserActions.showModal(modalType);
  }

  updateSort(column) {
    this.props.orgUserActions.updateSort(column);
  }

  toggleQuickView(userId, column) {
    this.props.orgUserActions.toggleUserQuickView(userId, column);
  }

  toggleInviteQuickView(inviteId, column) {
    this.props.orgUserActions.toggleInviteQuickView(inviteId, column);
  }

  render() {
    const { orgUserState, dropdownMenuState, orgState } = this.props;
    const { org } = orgState;
    const samlDomains = org.samlDomains || [];
    const isSaml = !!samlDomains.length;
    const { openedDropdown = {} } = dropdownMenuState;
    const {
      selectedOrgUsers = [],
      filteredOrgUsers = [],
      showModal,
      orgUsers = [],
      pendingInvites = [],
      filteredPendingInvites = [],
      selectedPendingInvites = [],
      sortColumn,
      sortAscending,
      quickViewsByUserId = {},
      quickViewsByInviteId = {},
    } = orgUserState;
    const { license, permissions, sso } = org;
    const { inviteUsers, manageGroupUsers } = permissions;
    const plan = org.plan || {};
    const { usage } = license;
    const { users } = usage;
    const { userLimit } = plan;

    const showGroups =
      FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org) ||
      OrgPaidStatus.isOrgEnterpriseOrTrial(org);

    return (
      <div className="grid mt mb++">
        <Helmet>
          <title>Users</title>
        </Helmet>
        <div className="grid__item col-1-1 flex align-items--baseline">
          <h3 data-e2e="OrgUsersPage-Title">Users</h3>
          {userLimit && (
            <div className="ml font--h4">
              <strong>
                ({users} of {userLimit} seats used)
              </strong>
            </div>
          )}
        </div>
        {/*
         * Pending Invites list
         */}
        {inviteUsers && (
          <OrgUserPendingInvitesList
            pendingInvites={pendingInvites}
            filteredPendingInvites={filteredPendingInvites}
            selectedPendingInvites={selectedPendingInvites}
            quickViewsByInviteId={quickViewsByInviteId}
            showModal={modalType => this.showModal(modalType)}
            toggleInviteQuickView={(inviteId, column) =>
              this.toggleInviteQuickView(inviteId, column)
            }
            showGroups={showGroups}
          />
        )}
        {/*
         * Active Users list
         */}
        <div className="grid__item col-1-1 mt">
          {inviteUsers && <h6 className="mt-">ACTIVE USERS</h6>}
          <div className="bo--1 border-color--muted-dark flex justify-content--space-between p- align-items--center">
            <div className="flex col-3-4">
              <div className="col-1-2">
                <input
                  type="text"
                  name="users-filter"
                  className="control--text col-1-1"
                  placeholder="Filter by email, name, team, or workspace"
                  onChange={e => this.handleFilterInput(e)}
                />
              </div>
            </div>
            <div className="">
              {(manageGroupUsers || inviteUsers) && (
                <DropdownMenu
                  isOpen={!!openedDropdown['users-actions']}
                  close={() => this.delayCloseDropdown('users-actions')}
                  menuAlign="right"
                  textAlign="left"
                  toggle={
                    <button
                      className="btn--success p-"
                      onClick={() => this.openDropdown('users-actions')}
                    >
                      Actions
                    </button>
                  }
                >
                  {inviteUsers && (
                    <li className="pr+ dropdown-menu--li">
                      <button className={`pv--`} onClick={() => this.showModal('INVITE_USERS')}>
                        Invite
                      </button>
                    </li>
                  )}
                  {showGroups && manageGroupUsers && (
                    <li className="pr+ dropdown-menu--li">
                      <button
                        className={`pv-- ${!selectedOrgUsers.length && 'disabled'}`}
                        disabled={!selectedOrgUsers.length}
                        onClick={() => this.showModal('ADD_USERS')}
                      >
                        Add to Team
                      </button>
                    </li>
                  )}
                </DropdownMenu>
              )}
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1">
          <OrgUsersList
            users={orgUsers}
            permissions={permissions}
            filteredUsers={filteredOrgUsers}
            selectedUsers={selectedOrgUsers}
            quickViewsByUserId={quickViewsByUserId}
            handleUserSelection={userId => this.handleUserSelection(userId)}
            updateSort={column => this.updateSort(column)}
            toggleQuickView={(userId, column) => this.toggleQuickView(userId, column)}
            sortAscending={sortAscending}
            sortColumn={sortColumn}
            isSaml={isSaml}
            isSso={sso}
            showGroups={showGroups}
          />
        </div>
        <SourceClearModal
          isOpen={showModal === 'ADD_USERS'}
          title="Add Users to Team"
          onClose={() => this.showModal()}
          width={500}
        >
          <AddSelectedUsersToGroupModal />
        </SourceClearModal>
        <SourceClearModal
          isOpen={showModal === 'INVITE_USERS'}
          title="Invite Users"
          onClose={() => this.showModal()}
          width={500}
        >
          <OrgUserInviteModal
            showModal={modalType => this.showModal(modalType)}
            showGroups={showGroups}
          />
        </SourceClearModal>
        <SourceClearModal
          isOpen={showModal === 'ADD_INVITEES_TO_GROUP'}
          title="Add Invitees to Team"
          onClose={() => this.showModal()}
          width={500}
        >
          <AddSelectedInviteesToGroupModal showModal={modalType => this.showModal(modalType)} />
        </SourceClearModal>
        <SourceClearModal
          isOpen={showModal === 'REVOKE_INVITES'}
          title="Revoke Invitations"
          onClose={() => this.showModal()}
          width={500}
        >
          <div className="grid">
            <div className="grid__item col-1-1 mt-">
              <strong>{`Invitation${
                selectedPendingInvites.length !== 1 ? 's' : ''
              } to be revoked:`}</strong>
            </div>
            <ul className="grid__item col-1-1 m0 pl+">
              {selectedPendingInvites.map(selectedInvite => {
                const foundInvite =
                  pendingInvites.find(invite => invite.id === selectedInvite.inviteId) || {};

                return (
                  <li className="mt-" key={`${foundInvite.id}-${selectedInvite.inviteId}`}>
                    {foundInvite.email}
                  </li>
                );
              })}
            </ul>
            <div className="grid__item col-1-1 mt">
              <strong>Note:</strong> revoking an invitation will revoke all pending invitations for
              that user. If an administrator besides you invited the user to a different team or
              workspace, those administrators will not be notified when you revoke a user's
              invitation. Invitees will see an error page when clicking on the link in their emails.
              You can invite users again at any time.
            </div>
            <div className="grid__item col-1-1 mt flex justify-content--end">
              <button className="mr- col-1-5 pv-" onClick={() => this.showModal()}>
                Cancel
              </button>
              <button className="btn--primary-clear p-" onClick={() => this.revokeInvitations()}>
                {`Revoke Invitation${selectedPendingInvites.length !== 1 ? 's' : ''}`}
              </button>
            </div>
          </div>
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    orgState: state.orgState,
    orgUserState: state.orgUserState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions, dispatch),
    groupActions: bindActionCreators(groupActions, dispatch),
    orgUserActions: bindActionCreators(orgUserActions, dispatch),
    orgUserInviteActions: bindActionCreators(orgUserInviteActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgUsersPage);
