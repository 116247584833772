import * as actions from '~/actions/group';
import Helpers from '~/utils/Helpers';
import produce from 'immer';
import { GroupState } from '~/types/Groups';

export const defaultState: GroupState = {
  activeTab: 'USERS',
  groupUsers: [],
  groupWorkspaces: [],
  filteredGroups: [],
  filteredGroupUsers: undefined, // replaced by the *active* group users
  filteredGroupWorkspaces: undefined, // replaced by the *active* group workspaces
  groups: [],
  groupUsersFilter: '',
  onlyAdminGroupUsers: false,
  selectedGroups: {},
  selectedGroupUsers: {},
  selectedGroupWorkspaces: {},
  showModal: undefined,
  createGroupStage: 0,
  groupData: { name: '', description: '', error: false, validTeamName: true },
  sortColumn: 'name',
  sortAscending: true,
  groupUserSortColumn: 'email',
  groupTeamSortColumn: 'name',
  quickViewsByGroupId: {},
  isFetchingGroupDetails: false,
  isFetchingGroups: false,
  isUpdatingGroups: false,
};

const groupState = (state = defaultState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.UPDATE_GROUPS:
        draft.groups = action.groups;
        draft.filteredGroups = sort(action.groups, state.sortColumn, state.sortAscending);
        break;
      case actions.UPDATE_SELECTED_GROUPS:
        draft.selectedGroups[action.groupId] = !state.selectedGroups[action.groupId];
        break;
      case actions.SHOW_GROUP_MODAL:
        draft.showModal = action.modalType;
        break;
      case actions.UPDATE_CREATE_GROUP_STAGE:
        draft.createGroupStage = action.stage || 0;
        break;
      case actions.FILTER_GROUPS_BY_INPUT:
        draft.filteredGroups = sort(
          filterByInput(state.groups, action.value),
          state.sortColumn,
          state.sortAscending
        );
        break;
      case actions.FILTER_GROUP_USERS_BY_INPUT:
        draft.filteredGroupUsers = sort(
          masterGroupUsersFilter(state.groupUsers, action.value, state.onlyAdminGroupUsers),
          state.groupUserSortColumn,
          state.sortAscending,
          'user'
        );
        break;
      case actions.FILTER_GROUP_WORKSPACES_BY_INPUT:
        draft.filteredGroupWorkspaces = sort(
          filterGroupWorkspacesByInput(state.groupWorkspaces, action.value),
          state.groupTeamSortColumn,
          state.sortAscending,
          'team'
        );
        break;
      case actions.TOGGLE_GROUP_USERS_ADMIN_FILTER: {
        const { groupUsersFilter, onlyAdminGroupUsers, groupUsers = [] } = state;
        draft.filteredGroupUsers = sort(
          masterGroupUsersFilter(groupUsers, groupUsersFilter, !onlyAdminGroupUsers),
          state.groupUserSortColumn,
          state.sortAscending,
          'user'
        );
        draft.onlyAdminGroupUsers = !onlyAdminGroupUsers;
        break;
      }
      case actions.SET_GROUP_DETAILS:
        draft.groupUsers = action.users;
        draft.groupWorkspaces = action.workspaces;
        draft.filteredGroupUsers = sort(
          action.users,
          state.groupUserSortColumn,
          state.sortAscending,
          'user'
        );
        draft.filteredGroupWorkspaces = sort(
          action.workspaces,
          state.groupTeamSortColumn,
          state.sortAscending,
          'team'
        );
        draft.selectedGroupUsers = {};
        draft.selectedGroupWorkspaces = {};
        draft.selectedGroups = {};
        draft.isFetchingGroupDetails = false;
        break;
      case actions.RESET_GROUP_DETAILS:
        draft.groupUsers = [];
        draft.groupWorkspaces = [];
        draft.selectedGroupUsers = [];
        draft.selectedGroupWorkspaces = {};
        draft.filteredGroupUsers = undefined;
        draft.filteredGroupWorkspaces = undefined;
        draft.groupUsersFilter = '';
        draft.isFetchingGroupDetails = false;
        break;
      case actions.UPDATE_IS_FETCHING_GROUP_DETAILS:
        draft.isFetchingGroupDetails = action.bool;
        break;
      case actions.RESET_SELECTED_GROUPS:
        draft.selectedGroups = {};
        break;
      case actions.UPDATE_ACTIVE_TAB:
        draft.activeTab = action.tab;
        break;
      case actions.UPDATE_SELECTED_GROUP_USERS:
        draft.selectedGroupUsers[action.userId] = !state.selectedGroupUsers[action.userId];
        break;
      case actions.UPDATE_SELECTED_GROUP_WORKSPACES:
        draft.selectedGroupWorkspaces[action.workspaceId] = !state.selectedGroupWorkspaces[
          action.workspaceId
        ];
        break;
      case actions.UPDATE_GROUP_USERS_FILTER:
        draft.groupUsersFilter = action.value;
        break;
      case actions.UPDATE_GROUP_DATA:
        draft.groupData[action.field] = action.value;
        draft.groupData.error = action.field === 'error' ? true : false;
        draft.groupData.validTeamName =
          action.field === 'name'
            ? Helpers.validateTeamName(action.value)
            : state.groupData.validTeamName;
        break;
      case actions.SET_GROUP_DATA_TO_ACTIVE_GROUP:
        draft.groupData.name = action.activeGroup.name || '';
        draft.groupData.description = action.activeGroup.description || '';
        break;
      case actions.RESET_GROUP_DATA:
        draft.groupData = { name: '', description: '', error: false, validTeamName: true };
        draft.createGroupStage = 0;
        break;
      case actions.TOGGLE_GROUP_ADMIN_FIELD:
        draft.groups = state.groups.map(group => {
          if (group.id === action.groupId) {
            group.stagedAdminStatus = !group.stagedAdminStatus;
          }
          return group;
        });
        break;
      case actions.UPDATE_ORG_GROUP_SORT: {
        const directionToSort = state.sortColumn === action.column ? !state.sortAscending : true;
        draft.sortColumn = action.column;
        draft.sortAscending = directionToSort;
        draft.filteredGroups = sort(state.filteredGroups, action.column, directionToSort);
        break;
      }
      case actions.UPDATE_GROUP_USER_SORT: {
        const directionToSort =
          state.groupUserSortColumn === action.column ? !state.sortAscending : true;
        draft.groupUserSortColumn = action.column;
        draft.sortAscending = directionToSort;
        draft.filteredGroupUsers = sort(
          state.filteredGroupUsers,
          action.column,
          directionToSort,
          'user'
        );
        break;
      }
      case actions.UPDATE_GROUP_TEAM_SORT: {
        const directionToSort =
          state.groupTeamSortColumn === action.column ? !state.sortAscending : true;
        draft.groupTeamSortColumn = action.column;
        draft.sortAscending = directionToSort;
        draft.filteredGroupWorkspaces = sort(
          state.filteredGroupWorkspaces,
          action.column,
          directionToSort,
          'team'
        );
        break;
      }
      case actions.RESET_GROUP_SORT:
        draft.sortColumn = 'name';
        draft.groupUserSortColumn = 'email';
        draft.sortAscending = true;
        break;
      case actions.TOGGLE_GROUP_QUICK_VIEW:
        draft.quickViewsByGroupId[action.groupId] =
          state.quickViewsByGroupId[action.groupId] === action.column ? 'OFF' : action.column;
        break;
      case actions.RESET_GROUP_QUICK_VIEWS:
        draft.quickViewsByGroupId = {};
        break;
      case actions.UPDATE_GROUPS_REQUEST:
        draft.isUpdatingGroups = true;
        break;
      case actions.UPDATE_GROUPS_SUCCESS:
      case actions.UPDATE_GROUPS_FAILURE:
        draft.isUpdatingGroups = false;
    }
  });
};

export default groupState;

function sort(set, column, sortAscending, type?) {
  set.sort((a, b) => {
    const valueA = type && a[type] ? a[type][column] : a[column];
    const valueB = type && b[type] ? b[type][column] : b[column];
    if (!valueA || !valueB) return 0;

    const formattedValueA = valueA.toLowerCase ? valueA.trim().toLowerCase() : valueA;
    const formattedValueB = valueB.toLowerCase ? valueB.trim().toLowerCase() : valueB;

    if (formattedValueA > formattedValueB) {
      return sortAscending ? 1 : -1;
    }
    if (formattedValueA < formattedValueB) {
      return sortAscending ? -1 : 1;
    }
    return 0;
  });

  return set;
}

/* filter helper functions */

function filterByInput(groups, value) {
  // here we go...
  const trimmedValue = value.trim().toLowerCase();
  groups = groups.filter(group => {
    const { name = '', teams: workspaces = [], users = [] } = group;
    const description = group.description || '';

    // short circuit filter if name or description matches to avoid looping through workspace or user array
    if (
      name.toLowerCase().includes(trimmedValue) ||
      description.toLowerCase().includes(trimmedValue)
    ) {
      return true;
    }

    // check workspace array first since it's probably much shorter
    const workspaceMatch = workspaces.find(workspaceObj => {
      const { team: workspace = {} } = workspaceObj;
      const { name: workspaceName } = workspace;
      return workspaceName.toLowerCase().includes(trimmedValue);
    });

    if (workspaceMatch) {
      return true;
    }

    // last resort - filter users. this could get ugly...
    const userMatch = users.find(userObj => {
      const { user = {} } = userObj;
      const { firstName = '', lastName = '', email = '' } = user;

      if (
        firstName.toLowerCase().includes(trimmedValue) ||
        lastName.toLowerCase().includes(trimmedValue) ||
        email.toLowerCase().includes(trimmedValue)
      ) {
        return true;
      }
    });

    if (userMatch) {
      return true;
    }

    return false;
  });

  return groups;
}

function masterGroupUsersFilter(groupUsers, value, onlyAdmins) {
  const filteredGroupUsers = filterGroupUsersByAdmin(groupUsers, onlyAdmins);
  return filterGroupUsersByInput(filteredGroupUsers, value);
}

function filterGroupUsersByAdmin(groupUsers, onlyAdmins) {
  if (!onlyAdmins) return groupUsers;

  return groupUsers.filter(user => user.admin);
}

function filterGroupUsersByInput(groupUsers, value) {
  const trimmedValue = value.trim().toLowerCase();
  const filteredUsers = groupUsers.filter(userObj => {
    const { user = {} } = userObj;
    const { firstName = '', lastName = '', email = '' } = user;

    if (
      firstName.toLowerCase().includes(trimmedValue) ||
      lastName.toLowerCase().includes(trimmedValue) ||
      email.toLowerCase().includes(trimmedValue)
    ) {
      return true;
    }

    return false;
  });

  return filteredUsers;
}

function filterGroupWorkspacesByInput(groupWorkspaces, value) {
  const trimmedValue = value.trim().toLowerCase();
  const filteredWorkspaces = groupWorkspaces.filter(workspaceObj => {
    const { team: workspace = {} } = workspaceObj;
    const { name = '' } = workspace;

    if (name.toLowerCase().includes(trimmedValue)) {
      return true;
    }

    return false;
  });

  return filteredWorkspaces;
}
