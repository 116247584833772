import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Sidebar from '~/containers/Sidebar';

import * as sidebarActions from '~/actions/sidebar';

interface SidebarWrapperProps {
  id: string;
  title: object;
  sidebarState: object;
  sidebarActions: object;
  pullRight?: boolean;
  sidebarClassName?: string;
  rootClassName?: string;
  overlayClassName?: string;
  contentClassName?: string;
  shadow?: boolean;
}
class SidebarWrapper extends React.Component<SidebarWrapperProps, {}> {
  toggleSidebar = open => {
    const { id } = this.props;
    if (open) {
      this.props.sidebarActions.openSidebar(id);
    } else {
      this.props.sidebarActions.closeSidebar(id);
    }
  };

  render() {
    const {
      id,
      sidebarState = {},
      children,
      title,
      pullRight = false,
      sidebarClassName = '',
      rootClassName = '',
      overlayClassName = '',
      contentClassName = '',
      shadow = false,
    } = this.props;
    const isSidebarOpen = sidebarState[id];

    /**
     * Displays the custom title via title prop & loads the component via children
     */
    const content = (
      <div>
        <div className="grid__item col-1-1" data-automation-id="ProjectScanHistoryWrapper">
          <div className="grid bg-color--white-medium color--black mt- pb- pr">
            <div className="grid__item col-3-4 flex align-items--center">{title}</div>
            <div className="grid__item col-1-4 flex align-items--center justify-content--end">
              <i
                className="sci sci__close cursor--pointer font--h6"
                onClick={() => this.toggleSidebar(false)}
                data-automation-id="ProjectScanHistoryCloseButton"
              />
            </div>
          </div>
        </div>
        <div className="grid__item col-1-1 bg-color--white p0">{children}</div>
      </div>
    );

    const sidebarProps = {
      pullRight,
      sidebarClassName,
      rootClassName,
      overlayClassName,
      contentClassName,
      shadow,
    };

    return (
      <Sidebar
        sidebar={content}
        open={isSidebarOpen}
        onSetOpen={this.toggleSidebar}
        {...sidebarProps}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    sidebarState: state.sidebarState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sidebarActions: bindActionCreators(sidebarActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarWrapper);
